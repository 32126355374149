import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash'
import cn from 'classnames';
import './LatestSection_Products.scss';

const LatestSection_Products = props => {
  const { section } = props;
  const { items } = section;

  const [descriptionExpanded, setDescriptionExpanded] = React.useState(false);
  const toggleDescriptionExpanded = () => setDescriptionExpanded(!descriptionExpanded);

  return (
    <div className='latest-section products'>
      {items.map(item => {
        const { id, brand, subtitle, product, ctaType } = item;

        if (!product) return null;
        const { image, title } = product;

        let actionDisplay, actionFn;
        if (item.ctaType === 'GIFTING') {
          actionDisplay = 'Request Gifting';
          actionFn = () =>
            props.openBrandModal({
              brand
            });
        } else if (ctaType === 'URL') {
          actionDisplay = 'View Product';
          actionFn = () => window.open(item.url, '_blank');
        } else {
          /*
            Unlike Mobile, we do not have a "Learn More" button for products because
            we don't have a product modal. Instead, we open the product page in a new tab.
          */
          actionDisplay = item.url ? 'Learn More' : 'No Product URL';
          actionFn = () => (item.url ? window.open(item.url, '_blank') : null);
        }
        return (
          <div className='product' key={id}>
            <div className='image-container'>
              <img src={image} alt={title} />
            </div>
            <div className='data-container'>
              <div className='brand'>{product.brand}</div>
              <div className='title'>{title}</div>
              {subtitle && (
                <div onClick={toggleDescriptionExpanded} className={cn('subtitle', { expanded: descriptionExpanded })}>
                  {subtitle}
                </div>
              )}
              <div className='action-btns'>
                <div onClick={actionFn} className='action-btn'>
                  {actionDisplay}
                </div>
              </div>
            </div>
            {props.getItemIndicatorBadges(item, 'primary')}
          </div>
        );
      })}
    </div>
  );
};

LatestSection_Products.propTypes = {
  section: PropTypes.object.isRequired,
  openBrandModal: PropTypes.func.isRequired
};

export default LatestSection_Products;
