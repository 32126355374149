import APIClient from './index';
import _ from 'lodash';

export const updateUserManager = (manager, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserManagers/${manager.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteUserManager = manager => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/UserManagers/${manager.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const sendUserManagerInvite = ({ email, permissions, User_id }) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserManagers/invite`, { email, permissions, User_id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getUserManagesNotificationCounts = Manager_id =>
  new Promise((resolve, reject) => {
    APIClient.post(`/UserManagers/user_notifications/${Manager_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
