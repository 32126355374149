import _ from 'lodash';
import slugify from 'slugify';
import moment from 'moment';

export const getDisplayInfoFromFilters = activeFilters => {
  const { department, category, tag } = activeFilters || {};
  const displayInfo = {
    title: 'Best in Beauty',
    seoTitle: 'Top Beauty Products Used By Experts',
    subtitle: `We've pulled together our experts' top-tier recommendations across the beauty and wellness space — from getting ready for the day ahead to getting ready for bed — to help you look and feel your best.`
  };
  if (tag) {
    displayInfo.title = `Best in ${category?.name} | ${tag?.name}`;
    displayInfo.seoTitle = tag.seoTitle || displayInfo.title;
    displayInfo.subtitle =
      tag.seoDescription || `The most highly recommended products in ${category?.name} | ${tag?.name}, as selected by the experts.`;
    displayInfo.createdAt = tag.createdAt;
    displayInfo.updatedAt = tag.updatedAt;
  } else if (category) {
    displayInfo.title = `Best in ${category?.name}`;
    displayInfo.seoTitle = category.seoTitle || displayInfo.title;
    displayInfo.subtitle = category.seoDescription || `The most highly recommended products in ${category?.name}, as selected by the experts.`;
    displayInfo.createdAt = category.createdAt;
    displayInfo.updatedAt = category.updatedAt;
  } else if (department) {
    displayInfo.title = `Best in ${department?.name}`;
    displayInfo.seoTitle = department.seoTitle || displayInfo.title;
    displayInfo.subtitle = department.seoDescription || `The most highly recommended products in ${department?.name}, as selected by the experts.`;
    displayInfo.createdAt = department.createdAt;
    displayInfo.updatedAt = department.updatedAt;
  }

  return {
    title: displayInfo.title,
    titleStub: displayInfo.title.replace(/([Bb]est [Ii]n )+/, ''),
    seoTitle: displayInfo.seoTitle,
    subtitle: displayInfo.subtitle,
    createdAt: displayInfo.createdAt && moment(displayInfo.createdAt).format('YYYY-MM-DD'),
    createdOn: displayInfo.updatedAt && moment(displayInfo.updatedAt).format('YYYY-MM-DD')
  };
};

export const isNonStandardSize = variant => {
  const adjUrl = variant.rawTitle ? variant.rawTitle.toLowerCase() : '';
  return (
    adjUrl.includes(' mini') || adjUrl.includes('mini ') || adjUrl.includes('value size') || adjUrl.includes('maxi size') || adjUrl.includes('travel')
  );
};

export const getVisibleVariantsForProduct = product => {
  /*
    Given a product, determine which of the variants we want to show.
  */
  const { variants } = product || {};
  const orderedVariants = _.orderBy(variants, [isNonStandardSize, v => v.retailer.toLowerCase()]);
  return _.uniqBy(orderedVariants, 'retailer');
};

export const getCatalogObjectsFromProduct = (product, shop) => {
  const { Category_id } = product || {};
  const category = _.get(shop, 'categories', []).find(c => c.id === Category_id);
  const department = _.get(shop, 'departments', []).find(d => category && d.id === category.Department_id);
  return {
    category,
    department
  };
};

export const getCollectionType = c => {
  /*  
    Deprecated as of February 8th 2023 now that we use "Sections"
  */
  if (c.forceType) return c.forceType;
  if (c.social_links && c.social_links.includes('youtube')) return 'shelf';
  if (c.image) return 'post';
  return 'shelf';
};

export const getCollectionSkin = c => c.skinType || 'classic';

export const sortReviews = reviews =>
  _.orderBy(
    reviews,
    review => {
      if (_.get(review, 'user.isAdmin')) return 10e8;
      return review.numLikes;
    },
    'desc'
  );
export const getPriceForProduct = product => {
  /*
    Given a product, determine which price to show according to the following logic.

    1) Avoid non standard sizes
    2) If a standard size price appears more than 2 times, use it
    3) If no repeated price, use lowest price
    4) Fallback on backup price
  */
  const { fallbackPrice, variants } = product || {};
  const visibleVariants = getVisibleVariantsForProduct(product);
  const orderedVariants = _.orderBy(visibleVariants, [isNonStandardSize, 'price'], ['asc', 'asc']);

  // If a standard size appears more than 2 times, use it
  const counts = _.countBy(
    _.filter(variants, v => !isNonStandardSize(v)),
    'price'
  );
  const repeatedPrice = parseFloat(_.reduce(counts, (r, v, k) => (v > 2 && r[1] < v ? [k, v] : r), [0, 0])[0]);

  // Finally pull the low price
  const bestPrice = _.get(orderedVariants, '0.price');
  return repeatedPrice || bestPrice || fallbackPrice;
};

export const getProductStub = product => {
  return slugify(`${product.brand} ${product.title}`).toLowerCase();
};
