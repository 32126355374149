import React from 'react';
import PropTypes from 'prop-types';
import './LinkResultsDisclaimer.scss';

const LinkResultsDisclaimer = props => {
  let disclaimer;
  if (props.groupByMode === 'products')
    disclaimer = `The "By Products" analytics require our team to manually match links to products so this may not be fully representative of the data. The intention is to show trends and insights as opposed to exact performance.`;

  return !disclaimer ? null : (
    <div className='link-results-disclaimer-outer-container'>
      <div className='link-results-disclaimer-inner-container'>
        <div className='speaker'>
          <div className='icon'>S</div>
        </div>
        <div className='body'>
          <div className='disclaimer'>{disclaimer}</div>
        </div>
      </div>
    </div>
  );
};

LinkResultsDisclaimer.propTypes = {
  groupByMode: PropTypes.string.isRequired
};

export default LinkResultsDisclaimer;
