import React from 'react';
import moment from 'moment';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';
import { confirmAlert } from 'react-confirm-alert';

export const hasRequestExpired = request => {
  /* Requests expire after a date as set by the brand. */
  if (!request.expiresOn) return false; // If no expiration date is set, the request is not expired.
  if (request.userAccepted && request.brandAccepted) return false; // If both parties have accepted, the request is not expired.
  if (request.userRejected || request.brandRejected) return false; // If either party has rejected, the request is not expired.
  return (
    moment(request.expiresOn)
      .startOf('day')
      .diff(moment().startOf('day'), 'minutes') < 0
  );
};

export const isRequestOutstanding = request => {
  /* Outstanding Requests can still be accepted or rejected by the user. */
  if (request.userAccepted && request.brandAccepted) return false; // If both parties have accepted, the request is not outstanding.
  if (request.userRejected || request.brandRejected) return false; // If either party has rejected, the request is not outstanding.
  if (hasRequestExpired(request)) return false; // If the request has expired, it is not outstanding.
  return true;
};

export const doesRequestRequireFulfillment = request => {
  return !request.isComplete && request.brandAccepted && request.userAccepted;
};

export const isRequestDismissed = request => {
  return request.brandRejected || request.userRejected;
};

export const isRequestAccepted = request => {
  return request.brandAccepted && request.userAccepted;
};

export const openEditRequestExpirationOverlay = (request, updateRequest) => {
  if (!request || !updateRequest) return console.error('You must pass request and updateRequest to openEditRequestExpirationOverlay.');
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmPrompt
        header='Update Expiration Date'
        subheader='Please enter a new expiration date for this request.'
        allowOverflow
        onCancel={onClose}
        customInputFields={[
          {
            isDate: true,
            preloaded: request.expiresOn && moment(request.expiresOn),
            value: 'expiration_date'
          }
        ]}
        secondaryBtn={
          request.expiresOn && {
            display: 'Remove Expiration Date',
            onClick: () => {
              updateRequest(request, { expiresOn: null });
              onClose();
            }
          }
        }
        onSubmit={async data => {
          const { expiration_date } = data;
          if (!expiration_date) return onClose();
          const newDate = expiration_date.format('YYYY-MM-DD 12:00:00');
          updateRequest(request, { expiresOn: newDate });
        }}
      />
    )
  });
};

// Helper function to format a user object with required fields for the bulk gifting modal.
export const formatUsersForBulkRequests = (users = []) => {
  return users.filter(Boolean).map(user => {
    const { User_id, id, name, image, countryCode } = user || {};

    return {
      id: User_id || id,
      name,
      image,
      countryCode
    };
  });
};
