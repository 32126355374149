import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faUser, faTimes, faComment, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import './MentionResult.scss';

import icons from '../../static/icons';

import { getCollectionUrlFromId } from '../../Helpers/user_helpers';
import { getSocialPlatformForUrl } from '../../Helpers/social_helpers';
import { getPrettyNumber, getPrettyTimeAgoFromNow, getFollowerCountDisplay } from '../../Helpers/formatting';
import { getSmartImage, portalBugOpenUrl } from '../../Helpers/helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { isLoggedIn, isYoutubeUserRequiredForAppCompliance } from '../../Helpers/user_helpers';
import { isMentionAffiliateLinkOnly } from '../../Helpers/mention_helpers';
import { isSubscribedToFeature, blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';

import Tooltip from '../General/Tooltip';
import MentionVideo from './Elements/MentionVideo';
import MentionResultStatDetails from './Elements/MentionResultStatDetails';
import { getGiftingRequestsForUser } from '../../Helpers/brand_helpers';

const MIN_LIKES = 5;
const MIN_COMMENTS = 5;
const MIN_SHARES = 5;
const MIN_SAVES = 5;

const MentionResult = React.forwardRef((props, ref) => {
  const { mention, curFilters, user, ui, analytics, removeMentionIfAllowedTo } = props;
  const {
    platform,
    url,
    image,
    contentPublishedAt,
    video,
    Collection_id,
    Collection_name,
    User_image,
    User_name,
    User_id,
    Brand_name,
    Target_name,
    type,
    subtype,
    estimatedMediaValue,
    estimatedCostOfContent
  } = mention;
  const roi = mention.estimatedCostOfContent ? mention.estimatedMediaValue / mention.estimatedCostOfContent : 0;
  const [hadErrorLoading, setHadErrorLoading] = useState(false);
  const errorOnContentLoad = () => setHadErrorLoading(true);
  const canViewMention = isSubscribedToFeature(user, 'SOCIAL_MENTIONS_FEED') || isAdminControlMode(ui);
  const missingContent = !image && !video;
  const hasBeenGifted = getGiftingRequestsForUser(analytics, { User_id })?.length > 0;

  // Allow non-logged in users to see the page, but not take actions
  const ensureLoggedInThenCall = cb => {
    if (isLoggedIn(props.user)) return cb();
    props.openAuthModal('apply-brands');
  };

  // Download
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadMedia = () =>
    ensureLoggedInThenCall(() => {
      if (!image && !video) return cogoToast.error('No media to download, please go to the source and download from there.');
      setIsDownloading(true);
      fetch(getSmartImage(video || image))
        .then(response => response.blob()) // Convert the response into a Blob
        .then(blob => {
          cogoToast.success('Downloaded Media!');
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `${User_name}_${subtype}` + (video ? '.mp4' : '.jpg');
          a.click();
        })
        .catch(error => {
          cogoToast.error('Could not download media, please go to the source and download from there.');
        })
        .finally(() => {
          setIsDownloading(false);
        });
    });
  const logMention = () => console.info(mention);
  const openShop = () => ensureLoggedInThenCall(() => props.openArtistModal({ id: mention.User_id }));
  const openChat = () =>
    ensureLoggedInThenCall(() => {
      props.openChatOverlay({
        id: mention.User_id,
        name: mention.User_name,
        image: mention.User_image
      });
    });

  // Check if URL is Expired ( Instagram Stories )
  const isUrlExpired = subtype === 'story' && moment().diff(contentPublishedAt, 'minutes') > 24 * 60;
  const mediaUrl = isUrlExpired ? video || image : url;

  const sortBy = curFilters.sortBy;

  let stats = [
    {
      section: 'No Section',
      display: `${Brand_name || Target_name}`,
      visible: !curFilters.Brand_id && !curFilters.Target_id,
      primary: true,
      hideOnDetailPanel: true
    },
    {
      section: 'Performance',
      display: `${getPrettyNumber(mention.views)} Views`,
      visible: !!mention.views,
      primary: sortBy === 'views' || sortBy === 'contentPublishedAt'
    },
    {
      section: 'Performance',
      display: `${getPrettyNumber(mention.likes)} Like${mention.likes === 1 ? '' : 's'}`,
      visible: mention.likes >= MIN_LIKES,
      primary: sortBy === 'likes'
    },
    {
      section: 'Performance',
      display: `${getPrettyNumber(mention.comments)} Comment${mention.comments === 1 ? '' : 's'}`,
      visible: mention.comments >= MIN_COMMENTS,
      primary: sortBy === 'comments'
    },
    {
      section: 'Performance',
      display: `${getPrettyNumber(mention.shares)} Share${mention.shares === 1 ? '' : 's'}`,
      visible: mention.shares >= MIN_SHARES,
      primary: sortBy === 'shares'
    },
    {
      section: 'Performance',
      display: `Analytics Disabled`,
      visible: !mention.isConnectedContent && !mention.views && !mention.likes && !mention.comments && !mention.shares,
      primary: false
    },
    {
      section: 'Performance',
      display: `${getPrettyNumber(mention.saves)} Save${mention.saves === 1 ? '' : 's'}`,
      visible: mention.saves >= MIN_SAVES,
      primary: sortBy === 'saves'
    },
    {
      section: 'Advanced Analytics',
      display: getFollowerCountDisplay(mention, platform),
      visible: !!getFollowerCountDisplay(mention, platform),
      primary: sortBy === 'followers'
    },
    {
      section: 'Advanced Analytics',
      display: `$${getPrettyNumber(Math.round(estimatedMediaValue))} Estimated Media Value`,
      visible: Math.round(estimatedMediaValue) > 0,
      primary: sortBy === 'estimatedMediaValue'
    },
    {
      section: 'Advanced Analytics',
      display: `$${getPrettyNumber(Math.round(estimatedCostOfContent))} Estimated Cost`,
      visible: Math.round(estimatedCostOfContent) > 0,
      primary: sortBy === 'estimatedCostOfContent'
    },
    {
      section: 'Advanced Analytics',
      display: `${roi.toFixed(roi >= 10 ? 0 : 1)}x Estimated ROI`,
      visible: roi >= 0.05,
      primary: sortBy === 'bestROI'
    },
    {
      section: 'Source',
      display: `${mention.tagFound}`,
      visible: mention.tagFound,
      href: mediaUrl
    },
    {
      section: 'Source',
      display: Collection_name && !Collection_name?.includes(' on ') ? Collection_name : `Connected Collection`,
      href: getCollectionUrlFromId(Collection_id),
      visible: !!Collection_id
    },
    {
      section: 'Source',
      display: `Affiliate Link Only`,
      visible: isMentionAffiliateLinkOnly(mention)
    }
  ];
  let primaryStats = stats.filter(s => s.primary && s.visible);
  primaryStats = primaryStats.length ? primaryStats : stats.slice(0, 1);

  // Handle Formatting Based on Content Type
  const aspectRatioClasses = {};
  if (subtype === 'instagrampost' && !video) aspectRatioClasses.square = true;
  if (type === 'instagram' && subtype.includes('igtv') && !video) aspectRatioClasses.square = true;
  if (type === 'youtube' && subtype.includes('video')) {
    aspectRatioClasses.square = true;
    aspectRatioClasses.youtube = true;
  }

  // Block visibility of certain stats
  const additionalClasses = {
    'is-blocked': !canViewMention
  };
  const blockClick = () => blockOnRequiringSubscription(user, 'SOCIAL_MENTIONS_FEED');

  // Allow Badges
  let badges = [];

  hasBeenGifted && badges.push({ className: 'gifted', display: 'Gifted' }); // prettier-ignore
  roi > 3 && badges.push({ className: 'roi', display: 'High ROI' }); // prettier-ignore
  mention.subtype === 'story' && badges.push({ className: 'story', display: 'Story', href: mediaUrl }); // prettier-ignore

  return (
    <div
      ref={ref}
      onClick={canViewMention ? logMention : blockClick}
      className={cn('mention-result-container', aspectRatioClasses, additionalClasses)}
    >
      {canViewMention && (
        <div className='header-section'>
          <div className='icons'>
            {removeMentionIfAllowedTo && (
              <>
                <Tooltip
                  message='Hide Mention From Results'
                  getIconDiv={() => (
                    <div className='remove-icon show-on-hover shiftable-item' onClick={() => removeMentionIfAllowedTo(mention)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                ></Tooltip>
              </>
            )}
            {badges.map((badge, idx) => {
              return badge.href ? (
                <a
                  onClick={portalBugOpenUrl}
                  key={idx}
                  className={cn('badge shiftable-item', badge.className)}
                  href={mediaUrl}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='display'>{badge.display}</div>
                </a>
              ) : (
                <div key={idx} className={cn('badge shiftable-item', badge.className)}>
                  <div className='display'>{badge.display}</div>
                </div>
              );
            })}
            <a
              className={cn('social-icon shiftable-item', { [platform]: !isYoutubeUserRequiredForAppCompliance(user) })}
              href={mediaUrl}
              onClick={portalBugOpenUrl}
              target='_blank'
              rel='noreferrer'
            >
              <Tooltip message={`View on ${getSocialPlatformForUrl(url)}`}>
                <img src={icons.social[platform].white} alt={platform} />
              </Tooltip>
            </a>
            <Tooltip
              message='View Profile'
              getIconDiv={() => (
                <div className='user-icon shiftable-item' onClick={openShop}>
                  <FontAwesomeIcon icon={faUser} />
                </div>
              )}
            ></Tooltip>
            <div className='user-icon show-on-card-hover shiftable-item' onClick={downloadMedia}>
              <Tooltip message='Download Media'>
                <FontAwesomeIcon icon={isDownloading ? faSpinner : faDownload} spin={isDownloading} />
              </Tooltip>
            </div>
            <div className='user-icon show-on-card-hover shiftable-item' onClick={openChat}>
              <Tooltip message='Open Chat'>
                <FontAwesomeIcon icon={faComment} />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {hadErrorLoading || missingContent ? (
        <div className='empty-cover'>
          {User_image ? <img src={getSmartImage(User_image)} alt={User_name} /> : <div className='empty'>S</div>}
          <div className='explainer'>Could not sync content, please click below to view</div>
          <a onClick={portalBugOpenUrl} href={url} target='_blank' rel='noreferrer' className='content-link'>
            View on {getSocialPlatformForUrl(url)}
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
        </div>
      ) : video ? (
        <div className='cover-video-container'>
          <MentionVideo src={video} onError={errorOnContentLoad} />
        </div>
      ) : image ? (
        <div className='cover-image-container'>
          <img onError={errorOnContentLoad} className='cover-image' src={image} alt='Mention Result' />
        </div>
      ) : null}
      {canViewMention && (
        <div className={cn('content', { video: !!video })}>
          <MentionResultStatDetails user={props.user} stats={stats} extraClasses={aspectRatioClasses} />
          <div className='content-inner'>
            <>{!!primaryStats.length && <div className='primary-stat'>{primaryStats.map(s => s.display).join(' • ')}</div>}</>
            <div onClick={openShop} className='user'>
              {User_name}
            </div>
            <a onClick={portalBugOpenUrl} target='_blank' rel='noreferrer' href={url} className='timestamp'>
              {getPrettyTimeAgoFromNow(contentPublishedAt, { longForm: true })}
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
});

MentionResult.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  mention: PropTypes.object.isRequired,
  curFilters: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  removeMentionIfAllowedTo: PropTypes.func
};

export default MentionResult;
