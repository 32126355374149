import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Home.scss';

import {
  BRAND_HOME_ANIMATIONS,
  BRAND_HOME_FAQS,
  BRAND_HOME_STEPS,
  CREATOR_HOME_IMAGES,
  CREATOR_HOME_FAQS,
  BRAND_HOME_CTAS,
  CREATOR_HOME_CTAS,
  HOME_BRAND_QUOTES,
  HOME_CREATOR_QUOTES,
  HOME_EXAMPLES,
  CREATOR_HOME_STEPS
} from '../../Helpers/home_helpers';
import { openAuthModal } from '../../Actions/UIActions';

import HomeHeaderCreators from '../../Components/Home/Panels/HomeHeaderCreators';
import HomeHeaderBrands from '../../Components/Home/Panels/HomeHeaderBrands';
import HomeHeaderStep from '../../Components/Home/Panels/HomeHeaderStep';
import HomeBrandLogos from '../../Components/Home/Panels/HomeBrandLogos';
import HomeAnimations from '../../Components/Home/Panels/HomeAnimations';
import HomeCreatorAnimations from '../../Components/Home/Panels/HomeCreatorAnimations';
import HomePartnerExamples from '../../Components/Home/Panels/HomePartnerExamples';
import HomePartnerTiles from '../../Components/Home/Panels/HomePartnerTiles';
import HomeProductSuite from '../../Components/Home/Panels/HomeProductSuite';
import HomeBrandFeatures from '../../Components/Home/Panels/HomeBrandFeatures';
import HomeBlogPanel from '../../Components/Home/Panels/HomeBlogPanel';
import HomeQuotesPanel from '../../Components/Home/Panels/HomeQuotesPanel';
import HomeFAQ from '../../Components/Home/Panels/HomeFAQ';
import HomeCTA from '../../Components/Home/Panels/HomeCTA';

const Home = props => {
  const params = useParams();
  const step = params?.step;

  // Four major subpages for brands
  const isBrandsLandingPage = !!props.isBrandsLandingPage;
  const isBrandsHome = isBrandsLandingPage && !step;
  const isBrandsDiscover = isBrandsLandingPage && step === 'discover';
  const isBrandsEngage = isBrandsLandingPage && step === 'engage';
  const isBrandsTrack = isBrandsLandingPage && step === 'track';
  const isBrandsAmplify = isBrandsLandingPage && step === 'amplify';
  const isCreatorLandingPage = props.isCreatorLandingPage || !props.isBrandsLandingPage; // Include /home here as well
  const isCreatorShop = isCreatorLandingPage && step === 'shop';
  const isCreatorAffiliate = isCreatorLandingPage && step === 'affiliates';
  const isCreatorPartnerships = isCreatorLandingPage && step === 'partnerships';

  // On changing page, we want to ensure we are scrolled to the top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const openBrandDemoModal = () => props.openAuthModal('apply-brands');
  const openCreatorApplyModal = () => props.openAuthModal('apply');
  return (
    <div className='home-outer-container'>
      {isBrandsHome ? (
        <>
          <HomeHeaderBrands />
          <HomeBrandLogos />
          <HomeAnimations animations={BRAND_HOME_ANIMATIONS.home} />
          <HomeBrandFeatures openBrandDemoModal={openBrandDemoModal} />
          <HomeBlogPanel />
          <HomeQuotesPanel isBrandPanel quotes={HOME_BRAND_QUOTES} />
          <HomeFAQ faqs={BRAND_HOME_FAQS.home} />
          <HomeCTA isBrandCTA ctas={BRAND_HOME_CTAS} openApplyModal={openBrandDemoModal} />
        </>
      ) : isBrandsDiscover ? (
        <>
          <HomeHeaderStep steps={BRAND_HOME_STEPS} />
          <HomeAnimations animations={BRAND_HOME_ANIMATIONS.discover} hideHeader hideNumbers isDarkMode />
          <HomeFAQ faqs={BRAND_HOME_FAQS.discover} />
          <HomeCTA isBrandCTA ctas={BRAND_HOME_CTAS} openApplyModal={openBrandDemoModal} />
        </>
      ) : isBrandsEngage ? (
        <>
          <HomeHeaderStep steps={BRAND_HOME_STEPS} />
          <HomeAnimations animations={BRAND_HOME_ANIMATIONS.engage} hideHeader hideNumbers isDarkMode />
          <HomeFAQ faqs={BRAND_HOME_FAQS.engage} />
          <HomeCTA isBrandCTA ctas={BRAND_HOME_CTAS} openApplyModal={openBrandDemoModal} />
        </>
      ) : isBrandsTrack ? (
        <>
          <HomeHeaderStep steps={BRAND_HOME_STEPS} />
          <HomeAnimations animations={BRAND_HOME_ANIMATIONS.track} hideHeader hideNumbers isDarkMode />
          <HomeFAQ faqs={BRAND_HOME_FAQS.track} />
          <HomeCTA isBrandCTA ctas={BRAND_HOME_CTAS} openApplyModal={openBrandDemoModal} />
        </>
      ) : isBrandsAmplify ? (
        <>
          <HomeHeaderStep steps={BRAND_HOME_STEPS} />
          <HomeAnimations animations={BRAND_HOME_ANIMATIONS.amplify} hideHeader hideNumbers isDarkMode />
          <HomeFAQ faqs={BRAND_HOME_FAQS.amplify} />
          <HomeCTA isBrandCTA ctas={BRAND_HOME_CTAS} openApplyModal={openBrandDemoModal} />
        </>
      ) : isCreatorShop ? (
        <>
          <HomeHeaderStep steps={CREATOR_HOME_STEPS} isOnWhite headerLongForm />
          <HomeAnimations animations={CREATOR_HOME_IMAGES.shop} hideHeader hideNumbers />
          <HomePartnerExamples partners={HOME_EXAMPLES.shops} />
          <HomeFAQ faqs={CREATOR_HOME_FAQS.shop} />
          <HomeCTA ctas={CREATOR_HOME_CTAS} openApplyModal={openCreatorApplyModal} />
        </>
      ) : isCreatorAffiliate ? (
        <>
          <HomeHeaderStep steps={CREATOR_HOME_STEPS} isOnWhite headerLongForm />
          <HomeAnimations animations={CREATOR_HOME_IMAGES.affiliates} hideHeader hideNumbers />
          <HomePartnerExamples partners={HOME_EXAMPLES.affiliates} title='Select Affiliate Partners' />
          <HomeFAQ faqs={CREATOR_HOME_FAQS.affiliates} />
          <HomeCTA ctas={CREATOR_HOME_CTAS} openApplyModal={openCreatorApplyModal} />
        </>
      ) : isCreatorPartnerships ? (
        <>
          <HomeHeaderStep steps={CREATOR_HOME_STEPS} isOnWhite headerLongForm />
          <HomeAnimations animations={CREATOR_HOME_IMAGES.partnerships} hideHeader hideNumbers />
          <HomePartnerTiles />
          <HomeFAQ faqs={CREATOR_HOME_FAQS.partnerships} />
          <HomeCTA ctas={CREATOR_HOME_CTAS} openApplyModal={openCreatorApplyModal} />
        </>
      ) : (
        <>
          <HomeHeaderCreators />
          <HomeCreatorAnimations />
          <HomePartnerTiles />
          <HomeProductSuite />
          <HomePartnerExamples partners={HOME_EXAMPLES.shops} />
          <HomeQuotesPanel quotes={HOME_CREATOR_QUOTES} />
          <HomeFAQ faqs={CREATOR_HOME_FAQS.home} />
          <HomeCTA ctas={CREATOR_HOME_CTAS} openApplyModal={openCreatorApplyModal} />
        </>
      )}
    </div>
  );
};

Home.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired,

  // From outer
  isBrandsLandingPage: PropTypes.bool,
  isCreatorLandingPage: PropTypes.bool
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(Home);
