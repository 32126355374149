import { useEffect } from 'react';

window.loadedCobrowse = false;
const CobrowseSDK = props => {
  useEffect(() => {
    if (window.loadedCobrowse) return;
    if (!window.__IS_PROD__) return;
    const script = document.createElement('script');
    script.src = 'https://js.cobrowse.io/CobrowseIO.js';
    script.async = true;
    script.onload = () => {
      window.CobrowseIO.license = 'g2D51jaroWDpew';
      window.CobrowseIO.client().then(function() {
        window.CobrowseIO.start();
      });
    };
    document.body.appendChild(script);
    window.loadedCobrowse = true;
  }, []);

  return null;
};
export default CobrowseSDK;
