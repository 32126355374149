import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './SectionSelectionModal.scss';

const SectionSelectionModal = props => {
  const { header, sections, subheader } = props;
  return (
    <div className='section-selection-modal-outer-container'>
      <div className='header-container'>
        <div className='header'>{header || 'Select a Shop Section'}</div>
        {subheader && <div className='subheader'>{subheader}</div>}
      </div>
      <div className='sections'>
        {sections.map(section => {
          const select = () => props.selectSection(section);
          return (
            <div key={section.id} onClick={select} className='section'>
              <div className='title'>{_.startCase(section.title)}</div>
            </div>
          );
        })}
      </div>
      <div onClick={props.closeModal} className='close-icn'>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

SectionSelectionModal.propTypes = {
  sections: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectSection: PropTypes.func.isRequired,

  // Optional
  header: PropTypes.string,
  subheader: PropTypes.string
};

export default SectionSelectionModal;
