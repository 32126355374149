import * as ActionTypes from '../Actions/ManagerActions';
import * as UserActionTypes from '../Actions/UserActions';

export const manager = (
  state = {
    currentManager: null, // User object for person being managed
    currentlyManaging: null, // User object for person being managed
    isSwitchingManaging: false, // To ensure we block behavior while loading
    notificationCounts: {}
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.SELECT_USER_TO_MANAGE_REQUEST:
      return {
        ...state,
        currentManager: action.manager,
        currentlyManaging: action.managing,
        isSwitchingManaging: true
      };

    case ActionTypes.SELECT_USER_TO_MANAGE_SUCCESS:
      return {
        ...state,
        isSwitchingManaging: false
      };

    case ActionTypes.SELECT_USER_TO_MANAGE_ERROR:
      return {
        ...state,
        currentManager: null,
        currentlyManaging: null,
        isSwitchingManaging: false
      };

    case ActionTypes.GET_USER_MANAGES_NOTIFICATION_COUNTS_SUCCESS:
      return {
        ...state,
        notificationCounts: action.user_notification_counts
      };

    case UserActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        currentManager: null,
        currentlyManaging: null,
        isSwitchingManaging: false,
        notificationCounts: {}
      };

    case UserActionTypes.SYNC_CURRENT_USER_SUCCESS:
      /*
        We want to always make sure the user manager objects are up to date with the most
        recently loaded manages data. So if the managed user changes permissions we update 
        the redux representation that was locked when switching to that user.
      */
      return {
        ...state,
        currentManager: state.currentManager
          ? {
              ...state.currentManager,
              manages: state.currentManager.manages?.map(m => {
                const freshData = action.user?.managers?.find(m2 => m2.id === m.id);
                return freshData
                  ? {
                      ...m,
                      ...freshData,
                      manager: null // We always need to make sure the manager object does not have the user object
                    }
                  : m;
              })
            }
          : state.currentlyManaging
      };

    default:
      return state;
  }
};
