import APIClient from './index';
import _ from 'lodash';

export const logToSlack = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Alerts/log_to_slack`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
