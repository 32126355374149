import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookProductModalCatalog.scss';
import LookbookProductModalResults from './LookbookProductModalResults';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { faArrowDown, faSearch } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';
import moment from 'moment';

import { getOrderHandledByData } from '../../../../Helpers/lookbook_helpers';

const LookbookProductModalCatalog = props => {
  const {
    lookbook,
    previousLookbookItems,
    searchResults,
    searching,
    addLookbookItem,
    removeSelectedProduct,
    setSelectedFilter,
    selectedFilter,
    setSearch,
    hasMoreResults,
    failedSearch,
    close,
    saving,
    resetSearchAndFilter,
    hasHadFirstResults,
    searchValue,
    fetchingNextPage,
    createEmptyLookbookItem,
    isReplaceMode,
    itemBeingReplaced
  } = props;

  const [openFilter, setOpenFilter] = useState(false);

  const filterOptions = [
    { filter: () => true, label: 'Full Catalog' }
    // { filter: item => !!item?.Lookbook_id, label: 'Previously Added' },
    // { filter: item => !!item?.Lookbook_id && moment(item?.createdAt).isAfter(oneMonthAgo), label: 'Recently Added' },
    // { filter: item => getLookbookItemIsCustom(item), label: 'Custom Products' }
  ];
  const currentFilter = selectedFilter || filterOptions[0];

  const getItems = () => {
    const filterLabel = selectedFilter?.label;
    const uniqueSearchResults = _.uniqBy(searchResults, 'objectID');

    if (filterLabel === 'Previously Added') return previousLookbookItems;
    else if (filterLabel === 'Custom Products') return previousLookbookItems.filter(currentFilter.filter);
    if (filterLabel === 'Recently Added')
      return previousLookbookItems.filter(currentFilter.filter).sort((a, b) => moment(b.createdAt).diff(a.createdAt));
    else return uniqueSearchResults;
  };

  const hasSearchValue = searchValue?.length > 0;
  const hasFilteredResults = currentFilter?.label !== 'Full Catalog';
  const newItemsAdded = lookbook.items.length - previousLookbookItems.length;

  const clickToCreateProduct = () => {
    const handledByData = getOrderHandledByData(props.user, lookbook);

    if (lookbook.items?.length && handledByData.handledBy === 'shopify') {
      confirmAlert({
        title: 'Just Confirming',
        message:
          'By creating a custom lookbook product, we will not be able to automatically place orders for this item in Shopify. If you cannot find the product in our catalog, please reach out to your account manager and we can help you finalize the lookbook.',
        buttons: [
          { label: 'Cancel', className: 'cancel', onClick: () => {} },
          { label: 'Continue', onClick: createEmptyLookbookItem }
        ]
      });
    } else {
      createEmptyLookbookItem();
    }
  };

  return (
    <div className='lookbook-product-modal-catalog-outer'>
      {/* {!!TESTING_BRANDS.length && isAdminControlMode(props.ui) && (
        <select onChange={e => props.setTestingBrand(e.target.value)}>
          <option value=''>Select Brand</option>
          {TESTING_BRANDS.map(brand => (
            <option value={brand}>{brand}</option>
          ))}
        </select>
      )} */}
      <div className='lookbook-product-modal-catalog-inner'>
        <div>
          {isReplaceMode ? (
            <div className='product-modal-title-container'>
              <div className='product-modal-title'>Search Your Catalog</div>
              {!!itemBeingReplaced?.title && (
                <div className='product-modal-replace-container'>
                  Replacing:
                  <div className='product-replace-name'>{itemBeingReplaced.title}</div>
                </div>
              )}
            </div>
          ) : (
            <div className='product-modal-title'>Search Your Catalog to Add Products</div>
          )}
          <div className='filters'>
            <div className='search-container'>
              <FontAwesomeIcon icon={faSearch} />
              <input className='search-input' placeholder='search' onChange={e => setSearch(e.target.value)} value={searchValue} />
            </div>
            <div className='search-filter-spacer'>From</div>
            <div className='filter-container'>
              <div className='filter-label' onClick={() => setOpenFilter(!openFilter)}>
                {currentFilter.label}
                <div className='filter-label-spacer'></div>
                <FontAwesomeIcon icon={faArrowDown} />
              </div>
              {openFilter && (
                <OutsideClickHandler onOutsideClick={() => setOpenFilter(false)}>
                  <div className='filter-dropdown'>
                    {filterOptions.map(option => {
                      const { label } = option;
                      return (
                        <div
                          className={cn('filter-option', { active: _.isEqual(option.label, selectedFilter?.label || 'Full Catalog') })}
                          key={label}
                          onClick={() => {
                            setSelectedFilter(option);
                            setOpenFilter(false);
                          }}
                        >
                          {option.label}
                        </div>
                      );
                    })}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          {!searching && (failedSearch || getItems()?.length === 0) ? (
            <>
              {failedSearch ? (
                <div className='empty-results-message'>
                  <div className='text'>
                    <div>We couldn't find any of your products.</div>
                    {!isReplaceMode && (
                      <button className='clear-filters-button' onClick={clickToCreateProduct}>
                        + Create Product
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className='empty-results-message'>
                  <div className='text'>
                    <div>
                      {hasFilteredResults && !hasSearchValue
                        ? 'No results found. Try a different filter.'
                        : hasFilteredResults && hasSearchValue
                        ? 'No results found. Try a different filter or search.'
                        : !hasFilteredResults && hasSearchValue
                        ? 'No results found. Try a different search.'
                        : 'No products found. Please contact your account manager'}
                    </div>
                    {hasSearchValue || hasFilteredResults ? (
                      <button className='clear-filters-button' onClick={resetSearchAndFilter}>
                        {hasFilteredResults && !hasSearchValue
                          ? 'Clear Filter'
                          : hasFilteredResults && hasSearchValue
                          ? 'Clear Filter and Search'
                          : !hasFilteredResults && hasSearchValue
                          ? 'Clear Search'
                          : 'Clear Search and Filter'}
                      </button>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <LookbookProductModalResults
                lookbook={lookbook}
                previousLookbookItems={previousLookbookItems}
                results={getItems()}
                searchResults={searchResults}
                addLookbookItem={addLookbookItem}
                removeSelectedProduct={removeSelectedProduct}
                openEditModal={props.openEditModal}
                hasMoreResults={hasMoreResults}
                searching={searching}
                failedSearch={failedSearch}
                saving={saving}
                selectedFilter={selectedFilter}
                hasHadFirstResults={hasHadFirstResults}
                fetchingNextPage={fetchingNextPage}
                showAllBrands={props.showAllBrands}
                user={props.user}
                isReplaceMode={isReplaceMode}
              />
            </>
          )}
        </div>

        <div className='product-buttons-footer'>
          {!isReplaceMode && (
            <button className='create-button' onClick={clickToCreateProduct}>
              + Create Product
            </button>
          )}
          <div className='save-container'>
            {newItemsAdded > 0 && !isReplaceMode && (
              <div className='selected-products-count'>
                {newItemsAdded} product{newItemsAdded ? '' : 's'} added
              </div>
            )}
            <button className='save-button' onClick={close}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookProductModalCatalog.propTypes = {
  user: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  previousLookbookItems: PropTypes.array.isRequired,

  searchValue: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  hasHadFirstResults: PropTypes.bool.isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  searchResults: PropTypes.array.isRequired,
  getNextPage: PropTypes.func.isRequired,
  failedSearch: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  fetchingNextPage: PropTypes.bool.isRequired,
  showAllBrands: PropTypes.bool.isRequired,

  addLookbookItem: PropTypes.func.isRequired,
  createEmptyLookbookItem: PropTypes.func.isRequired,
  removeSelectedProduct: PropTypes.func.isRequired,
  setCustomizableProduct: PropTypes.func.isRequired,

  openEditModal: PropTypes.func.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object,
  resetSearchAndFilter: PropTypes.func.isRequired,

  close: PropTypes.func.isRequired,
  isReplaceMode: PropTypes.bool,
  itemBeingReplaced: PropTypes.object
};

export default LookbookProductModalCatalog;
