import React from 'react';
import PropTypes from 'prop-types';
import './UnauthorizedLookbook.scss';

const UnauthorizedLookbook = props => {
  const { brandIsViewing, userIsViewing, isShopmyUser } = props;

  return (
    <div className='unauthorized-lookbook-outer'>
      <div className='unauthorized-lookbook-inner'>
        <div className='unauthorized-lookbook-header'>
          Uh oh! It looks like you don't have access to this lookbook.
          {brandIsViewing && ' Go to your gifting portal to view and create your own lookbooks.'}
          {userIsViewing && isShopmyUser && ' Go to your partner portal to view your lookbooks.'}
          {!isShopmyUser && ' Sign up and start your path to receiving lookbooks.'}
        </div>
        <div className='links'>
          {brandIsViewing && <a href='/requests'>Go to Gifting Portal</a>}
          {userIsViewing && isShopmyUser && <a href='/partners/gifting'>Go to Partner Portal</a>}
          {!isShopmyUser && (
            <>
              <a href='/signup'>Sign Up</a>
              <a href='/home?auth=login'>Log In</a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

UnauthorizedLookbook.propTypes = {
  brandIsViewing: PropTypes.bool.isRequired,
  userIsViewing: PropTypes.bool.isRequired,
  isShopmyUser: PropTypes.bool.isRequired
};

export default UnauthorizedLookbook;
