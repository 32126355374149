import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import './ProductReviews.scss';

import defaultPhoto from '../../static/images/logos/logo.png';

import { isInsider, isAdmin, getProfileFromObject, getUserTier } from '../../Helpers/user_helpers';
import { getSmartImage } from '../../Helpers/helpers';

const MAX_REVIEW_CHARS = 750;
const MIN_REVIEW_CHARS = 40;

const ProductReviews = props => {
  const { product, user, addReview, updateReview, deleteReview } = props;
  const { reviews } = product || {};

  const yourUserObj = getProfileFromObject(user) || {};
  const yourReview = _.find(reviews, review => _.get(review, 'user.id') === yourUserObj.id);

  const [numVisibleContent, updateVisibleContent] = useState(yourReview ? 3 : 4);
  const [isEditing, setIsEditing] = useState(!yourReview);
  const [isSaving, setIsSaving] = useState(false);
  const [newReview, setNewReview] = useState(yourReview ? yourReview.review : '');

  const canReview = isInsider(user) || getUserTier(user)?.tier <= 2 || isAdmin(user);

  if (!reviews.length && !canReview) return null;

  const cancelReview = () => {
    setNewReview(yourReview ? yourReview.review : '');
    setIsEditing(false);
  };

  const updateReviewCount = async review => {
    const count = window.prompt('What like count would you like to apply?');
    if (count && !_.isNaN(parseFloat(count))) {
      await updateReview(review, { numLikes: parseFloat(count) });
    }
  };

  const saveReview = async () => {
    let resp;
    const isDeleting = yourReview && !newReview.length;
    const isEditing = yourReview && !!newReview.length;
    setIsSaving(true);
    if (isDeleting) {
      resp = await deleteReview(yourReview);
      setIsSaving(false);
      if (resp.error) return;
      cogoToast.success('Successfully removed your review.');
    } else if (isEditing) {
      if (newReview.length < MIN_REVIEW_CHARS || newReview.length > MAX_REVIEW_CHARS) {
        cogoToast.error(`Reviews must be between ${MIN_REVIEW_CHARS} and ${MAX_REVIEW_CHARS} characters.`);
        setIsSaving(false);
        return;
      }
      resp = await updateReview(yourReview, { review: newReview });
      setIsSaving(false);
      if (resp.error) return;
      cogoToast.success('Successfully updated your review.');
    } else {
      resp = await addReview({ review: newReview, User_id: yourUserObj.id, Product_id: product.id });
      setIsSaving(false);
      if (resp.error) {
        return;
      }
      cogoToast.success('Thank you for your review - it is now live!');
    }
    setIsEditing(false);
  };

  const otherReviews = _.filter(reviews, r => !yourReview || r.id !== yourReview.id);
  const canCancel = isEditing && (!!yourReview || !!newReview.length);
  const canSave = yourReview ? yourReview.review !== newReview : newReview.length > 0;

  return (
    <div className='product-reviews-outer-container content-outer-container'>
      <div className='content-header-container'>
        <div className='content-header'>WHAT OUR EXPERTS ARE SAYING</div>
      </div>
      <div className='reviews-content-container'>
        {canReview && (
          <div
            className={cn('review', { editing: isEditing || !yourReview, 'only-one': yourReview ? !otherReviews.length : otherReviews.length <= 1 })}
          >
            {yourUserObj.isAdmin ? (
              <div className='user-header'>
                <img src={defaultPhoto} alt={'ShopMy'} />
                <div className='desc'>
                  <div className='name'>ShopMy</div>
                  <div className='title'>Reviews Team</div>
                </div>
              </div>
            ) : (
              <Link to={`/${yourUserObj.username}`} className='user-header'>
                {yourUserObj.image ? <img src={getSmartImage(yourUserObj.image)} alt={yourUserObj.name} /> : <div className='empty-img' />}
                <div className='desc'>
                  <div className='name'>{yourUserObj.name}</div>
                  <div className='title'>{yourUserObj.type}</div>
                </div>
              </Link>
            )}
            {isEditing || !yourReview ? (
              <>
                <div className='text-container'>
                  <textarea
                    className='review-input'
                    placeholder='Add your review of this product.'
                    onChange={({ target }) => {
                      setNewReview(target.value);
                      setIsEditing(true);
                    }}
                    rows={_.get(yourReview, 'review.length', 0) > 250 ? 12 : 8}
                    value={newReview}
                  />
                  <div
                    className={cn('char-count', {
                      okay: newReview.length >= MIN_REVIEW_CHARS,
                      'not-enough': newReview.length < MIN_REVIEW_CHARS,
                      'too-many': newReview.length > MAX_REVIEW_CHARS
                    })}
                  >
                    {newReview.length} of {MAX_REVIEW_CHARS}
                  </div>
                </div>
                <div className='action-btns'>
                  {canCancel && (
                    <div className='cancel-btn' onClick={cancelReview}>
                      Cancel
                    </div>
                  )}
                  {canSave && (
                    <div className={cn('save-btn', { disabled: !canSave })} onClick={saveReview}>
                      {newReview.length ? (isSaving ? 'Saving...' : 'Save') : isSaving ? 'Deleting...' : 'Delete'}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div onClick={() => setIsEditing(!isEditing)} className='content'>
                "{yourReview.review}"
              </div>
            )}
          </div>
        )}
        {otherReviews.slice(0, numVisibleContent).map(reviewObj => {
          const { id, review, user, numLikes } = reviewObj;
          const { username, isAdmin, type, name, image } = user || {};
          return (
            <div className={cn('review', { 'only-one': otherReviews.length === 1 && !canReview })} key={id}>
              <Link to={isAdmin ? '#' : `/${username}`} className={cn('user-header', { 'is-admin': isAdmin })}>
                {image ? <img src={isAdmin ? defaultPhoto : image} alt={isAdmin ? 'ShopMy' : name} /> : <div className='empty-img' />}
                <div className='desc'>
                  <div className='name'>{isAdmin ? 'ShopMy' : name}</div>
                  {type && !isAdmin && <div className='title'>EXPERT IN {type}</div>}
                  {!!yourUserObj.isAdmin && (
                    <div
                      onClick={e => {
                        e.preventDefault();
                        updateReviewCount(reviewObj);
                      }}
                      className='likes'
                    >
                      {numLikes} likes
                    </div>
                  )}
                </div>
              </Link>
              <div className='content'>"{review}"</div>
            </div>
          );
        })}
      </div>
      {otherReviews.length > numVisibleContent && (
        <div onClick={() => updateVisibleContent(numVisibleContent + 4)} className='show-more-btn'>
          Show more
        </div>
      )}
    </div>
  );
};

ProductReviews.propTypes = {
  user: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  addReview: PropTypes.func.isRequired,
  updateReview: PropTypes.func.isRequired,
  deleteReview: PropTypes.func.isRequired
};

export default ProductReviews;
