import React, { useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './ContractProposeTaskCalendarSelection.scss';
import 'react-dates/initialize';
import { SingleDatePicker, DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import Modal from '../../General/Modal';

const ContractProposeTaskCalendarSelection = props => {
  const { closeModal, contract, title, subtitle, save, task, isApprovalDate, isGoLiveDate, isDuration } = props;
  const { taskDuration, submissionDueDate, contentLiveDueDate, submissionDueDateDays, contentLiveDueDateDays } = task;
  const { requiresApproval } = task.template;
  const durationRef = useRef(null);

  const [date, setDate] = useState(
    isDuration
      ? contentLiveDueDate
        ? moment(contentLiveDueDate)
        : null
      : isApprovalDate
      ? submissionDueDate
        ? moment(submissionDueDate)
        : null
      : contentLiveDueDate
      ? moment(contentLiveDueDate)
      : null
  );
  const [duration, setDuration] = useState(
    isDuration ? taskDuration || '' : isApprovalDate ? submissionDueDateDays || '' : contentLiveDueDateDays || ''
  );

  const [focusedInput, setFocusedInput] = useState('startDate');

  // Two types of selections, days or dates
  const [selectionType, setSelectionType] = useState(
    isApprovalDate
      ? submissionDueDateDays
        ? 'days'
        : contract.isCreatedByBrand
        ? 'date'
        : 'days'
      : isDuration
      ? contentLiveDueDate
        ? 'date'
        : contract.isCreatedByBrand
        ? 'date'
        : 'days'
      : contract.isCreatedByBrand
      ? 'date'
      : 'days'
  );
  const isDaysSelection = selectionType === 'days';
  const isDateSelection = selectionType === 'date';
  const makeDaysSelection = () => {
    setSelectionType('days');
    setTimeout(() => durationRef?.current?.focus(), 100);
  };
  const makeDateSelection = () => setSelectionType('date');

  const saveAndClose = async () => {
    await save(isDuration ? { duration, date } : isDaysSelection ? { duration } : { date });
    closeModal();
  };

  return (
    <Modal
      innerClassName='contract-propose-task-calendar-selection-container'
      contentClassName='contract-propose-task-calendar-selection-modal-content'
      showClose
      visible
      isNestedModal
      title={title}
      subtitle={subtitle}
      close={closeModal}
    >
      <div className='section'>
        <div className='selection-type-section'>
          <div onClick={makeDaysSelection} className={cn('selection-type-option', { selected: isDaysSelection })}>
            <div className='selection-type-option-checkbox'>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            {isDuration
              ? 'Set a specific number of days'
              : isApprovalDate || !requiresApproval
              ? 'Due a certain number of days after collaboration accepted'
              : 'Due a certain number of days after approval'}
          </div>
          {isDaysSelection && (
            <div className='date-picker'>
              <input
                ref={durationRef}
                value={duration}
                className='days-after'
                placeholder='Number of Days'
                onChange={e => setDuration(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className='selection-type-section'>
          <div onClick={makeDateSelection} className={cn('selection-type-option', { selected: isDateSelection })}>
            <div className='selection-type-option-checkbox'>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            {isDuration
              ? `Set a specific date range.`
              : `Set a specific date${isGoLiveDate && submissionDueDate ? ` (submission due ${moment(submissionDueDate).format('MMMM D')})` : ''}`}
          </div>
          <div className='date-picker'>
            {isDateSelection ? (
              isDuration ? (
                <DateRangePicker
                  noBorder
                  startDate={date}
                  startDateId='your_unique_start_date_id'
                  endDate={duration && date ? date.clone().add(duration, 'days') : null}
                  endDateId='your_unique_end_date_id'
                  isOutsideRange={day => isInclusivelyBeforeDay(day, moment())}
                  onDatesChange={({ startDate, endDate }) => {
                    setDate(startDate);
                    startDate && endDate && setDuration(endDate.diff(startDate, 'days'));
                  }}
                  keepOpenOnDateSelect
                  numberOfMonths={isMobile ? 1 : 2}
                  focusedInput={focusedInput}
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  minimumNights={1}
                />
              ) : (
                <SingleDatePicker
                  date={date}
                  id='date_selection_picker'
                  onDateChange={date => setDate(date)}
                  focused
                  onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                  isOutsideRange={day => isInclusivelyBeforeDay(day, isGoLiveDate && submissionDueDate ? moment(submissionDueDate) : moment())}
                  numberOfMonths={isMobile ? 1 : 2}
                  noBorder
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      <div className='modal-footer'>
        <div className='footer-actions'>
          <div onClick={closeModal} className='footer-action cancel'>
            Cancel
          </div>
          <div onClick={saveAndClose} className='footer-action'>
            Save
          </div>
        </div>
      </div>
    </Modal>
  );
};

ContractProposeTaskCalendarSelection.propTypes = {
  contract: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ContractProposeTaskCalendarSelection;
