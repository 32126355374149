import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ShopResult.scss';

import { addEvent } from '../../APIClient/events';

import { getVisibleVariantsForProduct, getPriceForProduct, getProductStub } from '../../Helpers/shop_helpers';
import { isSearching, inSearchMode } from '../../Helpers/ui_helpers';
import { isAdmin } from '../../Helpers/user_helpers';
import { getSmartImage } from '../../Helpers/helpers';

class ShopResult extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    shop: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    setVisibleProduct: PropTypes.func.isRequired,
    activeFilters: PropTypes.object.isRequired
  };

  getCategory = () => _.find(this.props.shop.categories, category => category.id === parseFloat(this.props.product.Category_id));

  getRank = () => {
    const { activeFilters, product, ui } = this.props;
    const { department, category, tag } = activeFilters;
    if (inSearchMode(ui)) return product.categoryRank;
    if (tag) return null;
    if (category) return product.categoryRank;
    if (department) return product.departmentRank;
    return product.totalRank;
  };

  clickBuyBox = () => {
    this.setState({ showingOptions: true });
    if (!isAdmin(this.props.user)) {
      addEvent('Shop - Click Buy Box', {
        ...this.props.product
      });
      this.props.setVisibleProduct(this.props.product);
    }
  };

  render() {
    const { product, ui } = this.props;
    const { title, brand, image, totalMentionCount } = product;
    const category = this.getCategory();
    const rank = this.getRank();

    const visibleVariants = getVisibleVariantsForProduct(product);
    const visiblePrice = getPriceForProduct(product);
    const allPrices = _.uniq(_.map(visibleVariants, 'price'));

    const isAvailable = !!visiblePrice;
    return (
      <div className='shop-result-outer-container'>
        <Link to={`/products/${getProductStub(product)}`} className='shop-result-container'>
          <div className='product-wrapper'>
            <img className='product-image' alt={title} src={getSmartImage(image)} />
            <div className='product-data'>
              {!!totalMentionCount ? (
                <div className='artist-count'>
                  <b>{totalMentionCount}</b> creator
                  {totalMentionCount === 1 ? '' : 's'} recommend
                  {totalMentionCount === 1 ? 's' : ''}
                </div>
              ) : (
                <div className='artist-count'>{` `}</div>
              )}
              <h3 className='brand'>{brand}</h3>
              <h4 className='title'>{title}</h4>
              {isAvailable && (
                <div className='price'>
                  {allPrices.length > 1 ? 'from ' : ''}${visiblePrice.toFixed(0)}
                </div>
              )}
            </div>
          </div>
          <div className='rank-badge'>
            {rank &&
              !isSearching(ui) &&
              (inSearchMode(ui) ? (
                <>
                  <div className='ranking'>
                    <div className='num'>#</div>
                    <div>{rank}</div>
                  </div>
                  {category && <div className='label'>in {category.name}</div>}
                </>
              ) : (
                <div className='ranking'>
                  <div className='num'>#</div>
                  <div>{rank}</div>
                </div>
              ))}
          </div>
        </Link>
      </div>
    );
  }
}

export default ShopResult;
