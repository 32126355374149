import * as ActionTypes from '../Actions/LatestActions';

import { getUrlParam } from '../Helpers/helpers';

export const latest = (
  state = {
    newsletter: null,
    activeTab: null,
    activeTag: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_LATEST_SUCCESS:
      const tabFromUrl = getUrlParam('tab');
      const defaultActiveTab = action.newsletter.tabs?.find(tab => tab.title === tabFromUrl) || action.newsletter.tabs?.[0];
      return {
        ...state,
        newsletter: action.newsletter,
        activeTab: state.activeTab || defaultActiveTab
      };

    case ActionTypes.GET_LATEST_ERROR:
      window.ALERT.error(`Could not fetch the latest page, please reload the page.`);
      return {
        ...state,
        newsletter: null
      };

    case ActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };

    case ActionTypes.SET_ACTIVE_TAG:
      return {
        ...state,
        activeTag: action.tag
      };

    default:
      return state;
  }
};
