import APIClient from './index';
import { get400ErrorMessage } from './index';

export const addBlacklistedCode = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlacklistedCodes`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const deleteBlacklistedCode = BlacklistedCode_id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/BlacklistedCodes/${BlacklistedCode_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};
