import _ from 'lodash';

import { getPostByTitleStub, createPost, editPost, deletePost, createBlogElement, editBlogElement, deleteBlogElement } from '../APIClient/blog';
import {
  getAllBlogTabs as getAllBlogTabsAPI,
  createBlogTab as createBlogTabAPI,
  updateBlogTab as updateBlogTabAPI,
  deleteBlogTab as deleteBlogTabAPI,
  addPostToTab as addPostToTabAPI,
  removePostFromTab as removePostFromTabAPI
} from '../APIClient/blog';
import { getAffiliateLinks } from '../APIClient/pins';

export const GET_BLOG_POST_SUCCESS = 'GET_BLOG_POST_SUCCESS';
export const GET_BLOG_POST_FAILURE = 'GET_BLOG_POST_FAILURE';

export const GET_BLOG_POSTS_SUCCESS = 'GET_BLOG_POSTS_SUCCESS';
export const GET_BLOG_POSTS_FAILURE = 'GET_BLOG_POSTS_FAILURE';

export const CREATE_BLOG_POST_SUCCESS = 'CREATE_BLOG_POST_SUCCESS';
export const CREATE_BLOG_POST_FAILURE = 'CREATE_BLOG_POST_FAILURE';
export const DELETE_BLOG_POST_SUCCESS = 'DELETE_BLOG_POST_SUCCESS';
export const DELETE_BLOG_POST_FAILURE = 'DELETE_BLOG_POST_FAILURE';

export const EDIT_BLOG_POST = 'EDIT_BLOG_POST';
export const EDIT_BLOG_POST_SUCCESS = 'EDIT_BLOG_POST_SUCCESS';
export const EDIT_BLOG_POST_FAILURE = 'EDIT_BLOG_POST_FAILURE';

export const CREATE_BLOG_POST_ELEMENT_SUCCESS = 'CREATE_BLOG_POST_ELEMENT_SUCCESS';
export const DELETE_BLOG_POST_ELEMENT_SUCCESS = 'DELETE_BLOG_POST_ELEMENT_SUCCESS';

export const EDIT_BLOG_POST_ELEMENT_SUCCESS = 'EDIT_BLOG_POST_ELEMENT_SUCCESS';
export const EDIT_BLOG_POST_ELEMENT_FAILURE = 'EDIT_BLOG_POST_ELEMENT_FAILURE';

export const GET_BLOG_TABS_SUCCESS = 'GET_BLOG_TABS_SUCCESS';
export const GET_BLOG_TABS_FAILURE = 'GET_BLOG_TABS_FAILURE';

export const CREATE_BLOG_TAB_SUCCESS = 'CREATE_BLOG_TABS_SUCCESS';
export const CREATE_BLOG_TABS_FAILURE = 'CREATE_BLOG_TABS_FAILURE';

export const UPDATE_BLOG_TAB_SUCCESS = 'UPDATE_BLOG_TAB_SUCCESS';
export const UPDATE_BLOG_TAB_FAILURE = 'UPDATE_BLOG_TAB_FAILURE';

export const DELETE_BLOG_TAB_SUCCESS = 'DELETE_BLOG_TAB_SUCCESS';
export const DELETE_BLOG_TAB_FAILURE = 'DELETE_BLOG_TAB_FAILURE';

export const SET_BLOG_POST = 'SET_BLOG_POST';

export const ADD_POST_TO_TAB_SUCCESS = 'ADD_POST_TO_TAB_SUCCESS';
export const ADD_POST_TO_TAB_FAILURE = 'ADD_POST_TO_TAB_FAILURE';

export const REMOVE_POST_FROM_TAB = 'REMOVE_POST_FROM_TAB';
export const REMOVE_POST_FROM_TAB_SUCCESS = 'REMOVE_POST_FROM_TAB_SUCCESS';
export const REMOVE_POST_FROM_TAB_FAILURE = 'REMOVE_POST_FROM_TAB_FAILURE';

export const addBlogPostToTab = (tab, post) => async (dispatch, getState) => {
  try {
    await addPostToTabAPI(tab, post);
    return dispatch({ type: ADD_POST_TO_TAB_SUCCESS, tab, post });
  } catch (error) {
    return dispatch({ type: ADD_POST_TO_TAB_FAILURE, error, tab });
  }
};

export const removeBlogPostFromTab = (tab, post) => async (dispatch, getState) => {
  const currentTabsCopy = [...getState().blog.tabs];
  dispatch({ type: REMOVE_POST_FROM_TAB, tab, post });

  try {
    await removePostFromTabAPI(tab, post);
    return dispatch({ type: REMOVE_POST_FROM_TAB_SUCCESS, tab, post });
  } catch (error) {
    return dispatch({ type: REMOVE_POST_FROM_TAB_FAILURE, previousTabs: currentTabsCopy, tab, error });
  }
};

export const setBlogPost = post => async (dispatch, getState) => {
  return dispatch({ type: SET_BLOG_POST, post });
};

export const getBlogTabs = () => async (dispatch, getState) => {
  try {
    const resp = await getAllBlogTabsAPI();
    const tabs = resp.tabs;

    return dispatch({ type: GET_BLOG_TABS_SUCCESS, tabs });
  } catch (error) {
    return dispatch({ type: GET_BLOG_TABS_FAILURE, error });
  }
};

export const createBlogTab = title => async (dispatch, getState) => {
  try {
    const newTab = await createBlogTabAPI({ title });
    return dispatch({ type: CREATE_BLOG_TAB_SUCCESS, tab: newTab });
  } catch (error) {
    return dispatch({ type: CREATE_BLOG_TABS_FAILURE, error });
  }
};

export const updateBlogTab = (tab, updates) => async (dispatch, getState) => {
  try {
    const newTab = { ...tab, ...updates };

    dispatch({ type: UPDATE_BLOG_TAB_SUCCESS, tab: newTab });
    await updateBlogTabAPI(tab, updates);
    window.ALERT.success('Tab updated successfully');
  } catch (error) {
    window.ALERT.error('Error updating the tab');
    return dispatch({ type: UPDATE_BLOG_TAB_FAILURE, error, tab });
  }
};

export const deleteBlogTab = tab => async (dispatch, getState) => {
  const currentTabsCopy = getState().blog.tabs;

  try {
    dispatch({ type: DELETE_BLOG_TAB_SUCCESS, tab });
    await deleteBlogTabAPI(tab);
    window.ALERT.success('Tab deleted successfully');
  } catch (error) {
    window.ALERT.error('Error deleting the tab');
    return dispatch({ type: DELETE_BLOG_TAB_FAILURE, tabs: currentTabsCopy, error });
  }
};

export const getBlogPost = titleStub => async (dispatch, getState) => {
  try {
    const resp = await getPostByTitleStub(titleStub);
    const post = _.get(resp, 'post', {});
    dispatch({
      type: GET_BLOG_POST_SUCCESS,
      post
    });

    // Augment with affiliate links
    const postPins = _.filter(_.map(post.elements, 'pin'));
    const affiliateLinkResp = postPins.length ? await getAffiliateLinks(postPins) : {};
    const affiliate_links = _.get(affiliateLinkResp, 'data', {});
    return dispatch({
      type: GET_BLOG_POST_SUCCESS,
      post,
      affiliate_links
    });
  } catch (error) {
    return dispatch({ type: GET_BLOG_POST_FAILURE, error });
  }
};

export const createBlogPost = data => async (dispatch, getState) => {
  try {
    const post = await createPost(data);
    return dispatch({ type: CREATE_BLOG_POST_SUCCESS, post: { ...post, isNewPost: true } });
  } catch (e) {
    return dispatch({ type: CREATE_BLOG_POST_FAILURE, error: e });
  }
};

export const deleteBlogPost = post => async (dispatch, getState) => {
  try {
    await deletePost(post);
    return dispatch({ type: DELETE_BLOG_POST_SUCCESS, post });
  } catch (e) {
    return dispatch({ type: DELETE_BLOG_POST_FAILURE, post, error: e });
  }
};

export const editBlogPost = (post, updates) => async (dispatch, getState) => {
  const currentPostCopy = { ...post };
  try {
    dispatch({ type: EDIT_BLOG_POST, post: { ...post, ...updates } });
    const newPost = await editPost(post, updates);
    return dispatch({ type: EDIT_BLOG_POST_SUCCESS, post: newPost });
  } catch (e) {
    return dispatch({ type: EDIT_BLOG_POST_FAILURE, post: currentPostCopy, error: e });
  }
};

export const createBlogPostElement = (post, type) => async (dispatch, getState) => {
  const element = await createBlogElement(post, type);
  return dispatch({
    type: CREATE_BLOG_POST_ELEMENT_SUCCESS,
    post,
    element
  });
};

export const deleteBlogPostElement = element => async (dispatch, getState) => {
  await deleteBlogElement(element);
  return dispatch({
    type: DELETE_BLOG_POST_ELEMENT_SUCCESS,
    element
  });
};

export const editBlogPostElement = (element, updates) => async (dispatch, getState) => {
  const newElement = await editBlogElement(element, updates);
  if (!newElement) {
    return dispatch({
      type: EDIT_BLOG_POST_ELEMENT_FAILURE,
      error: 'We had an issue updating this blog post element.'
    });
  }

  return dispatch({
    type: EDIT_BLOG_POST_ELEMENT_SUCCESS,
    element: newElement
  });
};
