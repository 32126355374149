import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import './OpportunityListingExpectations.scss';

import { getBrand, isBrand as isBrandCheck } from '../../../Helpers/user_helpers';
import { getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';
import {
  opportunityConstants,
  opportunityMentionPlatformOptions,
  getOpportunityRequestAdjustedExpectations
} from '../../../Helpers/opportunity_helpers';

import EditMentionTagsModal from '../../Mentions/Elements/EditMentionTagsModal';

const OpportunityListingExpectations = props => {
  const { user, analytics, opportunity, opportunityRequest, isEditing, updateField } = props;
  const { trackingStartsAt, mentionPlatforms, socialTags } = opportunity;
  const isBrand = isBrandCheck(user);
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = isBrand
    ? opportunity
    : getOpportunityRequestAdjustedExpectations(opportunity, opportunityRequest);

  // Check if the brand has already sent requests for this opportunity
  const acceptedRequests = getOpportunityRequestsForOpportunity(analytics, opportunity).filter(r => r.userAccepted);
  const allMentionsFound = _.filter(_.map(_.flatten(_.map(opportunity.results, 'expectations')), 'mention'));

  // Mention Platforms
  const mentionPlatformsArray = _.filter(mentionPlatforms?.split(','));

  // Mention Social Tags
  const brand = getBrand(user) || opportunityRequest?.brand;
  const socialTagsArray = _.filter(socialTags?.split(','));
  const allPotentialSocialTags = _.filter(brand?.socialTags?.split(',')).filter(tag => !tag.includes(' '));
  const [isEditingTags, setIsEditingTags] = React.useState(false);
  const toggleEditingTags = () => setIsEditingTags(!isEditingTags);

  const expectationTypes = [
    {
      display: `Link${linksExpected === 1 ? '' : 's'}`,
      count: +linksExpected,
      getExplainer: () => {
        const minDaysBefore = opportunityConstants.MAX_DAYS_BEFORE_TRACKING_LINK_CAN_BE_CREATED;
        const minClicks = opportunityConstants.MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION;
        const isPastStartOfTracking = moment().isAfter(moment(trackingStartsAt).subtract(minDaysBefore, 'days'));
        const trackingWindowExplainer = trackingStartsAt
          ? `after ${moment(trackingStartsAt)
              .subtract(minDaysBefore, 'days')
              .format('MMM Do')}`
          : '';
        if (!linksExpected) return `Use this to specify how many links you expect the creator to generate and share over the tracking period.`;
        if (linksExpected === 1)
          return `To meet this expectation, ${
            isBrand ? 'talent' : 'you'
          } will need to generate and share one link within the tracking window. This link must ${
            isPastStartOfTracking ? 'have been' : 'be'
          } created ${trackingWindowExplainer} and generate at least ${minClicks} clicks to be considered valid.`;
        return `To meet this expectation, ${
          isBrand ? 'talent' : 'you'
        } will need to generate and share ${linksExpected} links within the tracking window. These links must ${
          isPastStartOfTracking ? 'have been' : 'be'
        } created ${trackingWindowExplainer} and generate at least ${minClicks} clicks to be considered valid.${
          opportunity.onlyCountQuickLinks ? ` Only quick links will be counted.` : ''
        }`;
      },
      increment: () => updateField('linksExpected', linksExpected + 1),
      decrement: () => updateField('linksExpected', _.max([linksExpected - 1, 0]))
    },
    {
      display: `Linking Day${linkingDaysExpected === 1 ? '' : 's'}`,
      showDaysDisclaimer: true,
      count: +linkingDaysExpected,
      warning: +linksExpected && +linkingDaysExpected ? 'We recommend using either Links or Linking Days to minimize confusion for creators.' : null,
      getExplainer: () => {
        const minDaysBefore = opportunityConstants.MAX_DAYS_BEFORE_TRACKING_LINK_CAN_BE_CREATED;
        const minClicks = opportunityConstants.MINUMUM_LINK_CLICKS_TO_REGISTER_AS_EXPECTATION_IN_SINGLE_DAY;
        const isPastStartOfTracking = moment().isAfter(moment(trackingStartsAt).subtract(minDaysBefore, 'days'));
        const trackingWindowExplainer = trackingStartsAt
          ? `after ${moment(trackingStartsAt)
              .subtract(minDaysBefore, 'days')
              .format('MMM Do')}`
          : '';
        if (!linkingDaysExpected)
          return `Use this to specify how many days you expect the creator to take to generate and share links to your products.`;
        if (linkingDaysExpected === 1)
          return `To meet this expectation, ${
            isBrand ? 'talent' : 'you'
          } will need to generate and share one link within the tracking window. This link must ${
            isPastStartOfTracking ? 'have been' : 'be'
          } created ${trackingWindowExplainer} and generate at least ${minClicks} clicks in the same day to be considered valid.${
            opportunity.onlyCountQuickLinks ? ` Only quick links will be counted.` : ''
          }`;
        return `To meet this expectation, ${
          isBrand ? 'talent' : 'you'
        } will need to share a new link on ${linkingDaysExpected} separate days within the tracking window. These links must ${
          isPastStartOfTracking ? 'have been' : 'be'
        } created ${trackingWindowExplainer} and generate at least ${minClicks} clicks in the same day to be considered valid. We recommend using this if you want to ensure the creator promotes your brand across multiple days.${
          opportunity.onlyCountQuickLinks ? ` Only quick links will be counted.` : ''
        }`;
      },
      increment: () => updateField('linkingDaysExpected', linkingDaysExpected + 1),
      decrement: () => updateField('linkingDaysExpected', _.max([linkingDaysExpected - 1, 0]))
    },
    {
      display: `Social Mention${mentionsExpected === 1 ? '' : 's'}`,
      count: +mentionsExpected,
      getExplainer: () => {
        if (!mentionsExpected) return `Use this to specify how many social mentions you expect the creator to make over the tracking period.`;
        if (mentionsExpected === 1)
          return `To meet this expectation, ${isBrand ? 'talent' : 'you'} will need to make one social mention within the tracking window.`;
        return `To meet this expectation, ${
          isBrand ? 'talent' : 'you'
        } will need to make ${mentionsExpected} social mentions within the tracking window.`;
      },
      increment: () => updateField('mentionsExpected', mentionsExpected + 1),
      decrement: () => updateField('mentionsExpected', _.max([mentionsExpected - 1, 0])),
      showMentionPlatformSelection: !!mentionsExpected,
      showTagSelection: !!mentionsExpected
    },
    {
      display: `Social Mention Day${mentionDaysExpected === 1 ? '' : 's'}`,
      showDaysDisclaimer: true,
      count: +mentionDaysExpected,
      warning:
        +mentionsExpected && +mentionDaysExpected
          ? 'We recommend using either Social Mentions or Mention Days to minimize confusion for creators.'
          : null,
      getExplainer: () => {
        if (!mentionDaysExpected) return `Use this to specify how many days you expect the creator to make social mentions about your product.`;
        if (mentionDaysExpected === 1)
          return `To meet this expectation, ${isBrand ? 'talent' : 'you'} will need to make one social mention within the tracking window.`;
        return `To meet this expectation, ${
          isBrand ? 'talent' : 'you'
        } will need to make a social mention on ${mentionDaysExpected} separate days within the tracking window.`;
      },
      increment: () => updateField('mentionDaysExpected', mentionDaysExpected + 1),
      decrement: () => updateField('mentionDaysExpected', _.max([mentionDaysExpected - 1, 0])),
      showMentionPlatformSelection: !!mentionDaysExpected,
      showTagSelection: !!mentionDaysExpected
    }
  ];

  return (
    <div className='opportunity-listing-section opportunity-listing-expectations-section'>
      <div className='opportunity-listing-section-title-container'>
        <div className='opportunity-listing-section-title'>Expectations</div>
        <div className='opportunity-listing-section-subtitle'>
          {isBrand
            ? `Specify what you expect the creator to provide in order to complete this opportunity. These are automated and the creator will be paid upon
          completion of the expectations. To specify additional expectations, please use the guidelines section.`
            : 'Once you complete these expectations, you will be automatically paid.'}
        </div>
      </div>

      <div className='expectation-type-sections'>
        {expectationTypes.map((expectationTypeData, idx) => {
          const {
            display,
            warning,
            count,
            getExplainer,
            showDaysDisclaimer,
            showMentionPlatformSelection,
            showTagSelection,
            increment,
            decrement
          } = expectationTypeData;
          const additionalClasses = { inactive: !count };
          const hasAdditionalOptions = showMentionPlatformSelection || showTagSelection;
          if (!count && !isEditing) return null;

          // Ensure brands are not editing active expectations
          const cannotEditExpectationQuantities = acceptedRequests.length > 0 && !window.__ADMIN_CONTROL_MODE__;
          const flagCannotEdit = () =>
            window.ALERT.warn(`You cannot edit this expectation because talent have already accepted requests for this opportunity.`);

          return (
            <div key={idx} className={cn('expectation-type-section', additionalClasses)}>
              <div className='quantity'>
                {isEditing && (
                  <div
                    onClick={cannotEditExpectationQuantities ? flagCannotEdit : decrement}
                    className={cn('adjust-quantity less', {
                      disabled: cannotEditExpectationQuantities,
                      inactive: count === 0
                    })}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </div>
                )}
                <div className={cn('counter-number', additionalClasses)}>{count || 0}</div>
                {isEditing && (
                  <div
                    onClick={cannotEditExpectationQuantities ? flagCannotEdit : increment}
                    className={cn('adjust-quantity more', {
                      disabled: cannotEditExpectationQuantities,
                      inactive: count === 0
                    })}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}
              </div>
              <div className={cn('expectation-type', additionalClasses)}>
                <div className='type-container'>
                  <div className='type'>
                    {display}
                    {showDaysDisclaimer && count > 1 && <span className='days-disclaimer'> (distinct days)</span>}
                  </div>
                  {warning && (
                    <div className='warning'>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                      {warning}
                    </div>
                  )}
                </div>
                <div className='explainer'>{getExplainer()}</div>
                {hasAdditionalOptions && (
                  <div className='additional-options'>
                    {showMentionPlatformSelection && (
                      <div className='additional-option'>
                        <div className='display'>Acceptable Channels:</div>
                        <div className='enums'>
                          {(!mentionPlatformsArray.length || isEditing) && (
                            <div
                              className={cn('enum all', { selected: !mentionPlatforms && isEditing })}
                              onClick={() => updateField('mentionPlatforms', '')}
                            >
                              {isEditing ? 'All' : 'Any Channel'}
                            </div>
                          )}
                          {opportunityMentionPlatformOptions.map((option, idx) => {
                            const { display, value } = option;
                            const numCollected = allMentionsFound.filter(m => m.platform?.toLowerCase() === value.toLowerCase()).length;
                            const isSelected = mentionPlatformsArray.includes(value);
                            const toggle = () => {
                              const newPlatforms = isSelected ? mentionPlatformsArray.filter(p => p !== value) : [...mentionPlatformsArray, value];
                              const alreadyHaveMentionPlatforms = _.uniq(allMentionsFound.map(m => m.platform));

                              // If a mention has already been collected for a platform, don't allow it to be removed
                              const alreadySelectedPlatformsMissingFromNew = alreadyHaveMentionPlatforms.filter(p => !newPlatforms.includes(p));
                              if (alreadySelectedPlatformsMissingFromNew.length)
                                return window.ALERT.warn(`You must keep ${alreadySelectedPlatformsMissingFromNew.join(', ')} selected because mentions have already been collected.`); // prettier-ignore
                              updateField('mentionPlatforms', newPlatforms.join(','));
                            };
                            if (!isSelected && !isEditing) return null;
                            return (
                              <div key={idx} onClick={toggle} className={cn('enum', { selected: isSelected })}>
                                {display}
                                {numCollected ? ` (${numCollected})` : ''}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {showMentionPlatformSelection && (
                      <div key={idx} className='additional-option'>
                        <div className='display'>Social Tags To Use:</div>
                        <div className='enums'>
                          {isEditing && (
                            <div className={cn('enum all', { selected: !socialTags })} onClick={() => updateField('socialTags', null)}>
                              Any
                            </div>
                          )}
                          {allPotentialSocialTags.map((tag, idx) => {
                            const isSelected = socialTagsArray.includes(tag);
                            const numCollected = allMentionsFound.filter(m => m.tagFound?.toLowerCase() === tag.toLowerCase()).length;
                            const toggle = () => {
                              if (!isEditing) return;
                              const newTags = isSelected ? socialTagsArray.filter(p => p !== tag) : [...socialTagsArray, tag];
                              const alreadyHaveMentionTags = _.uniq(allMentionsFound.map(m => m.tagFound));

                              // If a mention has already been collected for a tag, don't allow it to be removed
                              const alreadySelectedTagsMissingFromNew = alreadyHaveMentionTags.filter(p => !newTags.includes(p));
                              if (alreadySelectedTagsMissingFromNew.length)
                                return window.ALERT.warn(`You must keep ${alreadySelectedTagsMissingFromNew.join(', ')} selected because mentions have already been collected.`); // prettier-ignore

                              updateField('socialTags', newTags.join(',') || null);
                            };

                            if (!isSelected && !isEditing && socialTagsArray.length) return null;
                            return (
                              <div key={tag + idx} onClick={toggle} className={cn('enum', { selected: isSelected, editable: isEditing })}>
                                {tag}
                                {numCollected ? ` (${numCollected})` : ''}
                              </div>
                            );
                          })}
                          {isEditing && (
                            <div onClick={toggleEditingTags} className='enum add'>
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {isEditingTags && <EditMentionTagsModal close={toggleEditingTags} />}
      </div>
    </div>
  );
};

OpportunityListingExpectations.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  opportunityRequest: PropTypes.object,
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateField: PropTypes.func.isRequired
};

export default OpportunityListingExpectations;
