import _ from 'lodash';
import * as ActionTypes from '../Actions/ChatActions';
import * as UserActionTypes from '../Actions/UserActions';
import * as AnalyticsActionTypes from '../Actions/AnalyticsActions';

export const chats = (
  state = {
    chats: [],
    potentialPartners: [],
    activeChat: null,
    activePotentialPartner: null
  },
  action
) => {
  const chatExists = state.chats.find(c => c.id === action.data?.chat?.id);
  switch (action.type) {
    case ActionTypes.GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.chats,
        potentialPartners: action.potentialPartners
      };

    case ActionTypes.ADD_MESSAGE:
      return {
        ...state,
        chats: chatExists
          ? state.chats.map(chat => (chat.id === action.data?.chat?.id ? action.data.chat : chat))
          : [action.data.chat, ...state.chats], // We don't resort so it doesn't jump around
        potentialPartners: state.potentialPartners.filter(partner => {
          const isBrandPartner = 'logo' in partner;
          return partner?.id !== (isBrandPartner ? action.data.chat.Brand_id : action.data.chat.User_id);
        }),
        activeChat: !state.activeChat
          ? null
          : state.activeChat.id !== action.data.message.Chat_id
          ? state.activeChat
          : {
              ...state.activeChat,
              messages: _.uniqBy(_.orderBy(_.concat(state.activeChat.messages || [], action.data.message || []), 'createdAt', 'desc'), 'id')
            }
      };

    case ActionTypes.EDIT_MESSAGE:
      return {
        ...state,
        chats: state.chats?.map(chat => (chat.id === action.data?.chat?.id ? { ...chat, ...action.data.chat } : chat)),
        activeChat: state.activeChat && {
          ...state.activeChat,
          messages: state.activeChat.messages.map(message => (message.id === action.data.message.id ? action.data.message : message))
        }
      };

    case ActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        chats: state.chats?.map(chat => (chat.id === action.data?.chat?.id ? { ...chat, ...action.data.chat } : chat)),
        activeChat: state.activeChat && {
          ...state.activeChat,
          messages: state.activeChat.messages.filter(message => message.id !== action.data.message.id)
        }
      };

    case ActionTypes.TYPING_MESSAGE:
      // Verify that the typing message is for the active chat
      if (!state.activeChat || state.activeChat.Brand_id !== action.data.Brand_id || state.activeChat.User_id !== action.data.User_id) {
        return state;
      }

      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          typingData: action.data.typingData
        }
      };

    case ActionTypes.UPDATE_CHAT_SUCCESS:
      return {
        ...state,
        chats: state.chats.map(chat => (chat.id === action.chat.id ? action.chat : chat)),
        activeChat:
          state.activeChat?.id === action.chat.id
            ? {
                ...state.activeChat,
                ...action.chat
              }
            : state.activeChat
      };

    case ActionTypes.SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChat: action.chat,
        activePotentialPartner: null
      };

    case ActionTypes.SET_ACTIVE_POTENTIAL_PARTNER:
      return {
        ...state,
        activeChat: null,
        activePotentialPartner: action.partner
      };

    case ActionTypes.DELETE_CHAT_REQUEST:
      return {
        ...state,
        chats: state.chats.filter(chat => chat.id !== action.chat.id),
        activeChat: state.activeChat && state.activeChat.id === action.chat.id ? null : state.activeChat
      };

    case ActionTypes.DELETE_CHAT_ERROR:
      window.ALERT.error('Error deleting chat, please reload and try again.');
      return {
        ...state
      };

    case UserActionTypes.SIMULATE_USER:
    case UserActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        chats: [],
        potentialPartners: [],
        activeChat: null,
        activePotentialPartner: null
      };

    // Handling Updates to Codes and rates
    case AnalyticsActionTypes.SET_CUSTOM_CODE_SUCCESS:
      return {
        ...state,
        activeChat: state.activeChat && {
          ...state.activeChat,
          user: state.activeChat.user && {
            ...state.activeChat.user,
            codes: action.customCodes.filter(code => code.User_id === state.activeChat?.User_id)
          }
        }
      };

    case AnalyticsActionTypes.SET_CUSTOM_COMMISSION_RATE_SUCCESS:
      return {
        ...state,
        activeChat: state.activeChat && {
          ...state.activeChat,
          user: state.activeChat.user && {
            ...state.activeChat.user,
            rates: action.customRates.filter(rate => rate.User_id === state.activeChat?.User_id)
          }
        }
      };

    default:
      return state;
  }
};
