import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './BlogPostElements.scss';

import { BLOG_ELEMENT_TYPES } from '../../Helpers/blog_helpers';

import BlogPostElement from './BlogPostElement';
import SortableList from '../General/SortableList';
import BlogPostModal from './BlogPostModal';
import { copyToClipboard } from '../../Helpers/helpers';
import Tooltip from '../General/Tooltip';

const BlogPostElements = props => {
  const { isEditing, elements, post, isFetching } = props;
  const addElement = type => props.createBlogPostElement(post, type);

  const [blogPostModalIsOpen, setBlogPostModalIsOpen] = useState(false);
  const openBlogPostModal = () => setBlogPostModalIsOpen(true);
  const closeBlogPostModal = () => setBlogPostModalIsOpen(false);

  const blogElementOptions = [
    {
      title: 'Headers',
      elements: [
        { type: BLOG_ELEMENT_TYPES.HEADER, title: 'Small Header' },
        { type: BLOG_ELEMENT_TYPES.HEADER_LARGE, title: 'Large Header' }
      ]
    },
    {
      title: 'Text',
      elements: [
        { type: BLOG_ELEMENT_TYPES.PARAGRAPH, title: 'Paragraph' },
        { type: BLOG_ELEMENT_TYPES.DISCLAIMER, title: 'Disclaimer' }
      ]
    },
    {
      title: 'Text Formatting',
      elements: [
        {
          title: 'Italic Text',
          tooltip:
            'To italicize text, wrap it in double parentheses and add an "i" at the end. For example: ((text to be italicized))i. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((text to be italicized))i', true, 'Italic text template copied to clipboard')
        },
        {
          title: 'Bold Text',
          tooltip:
            'To bold text, wrap it in double parentheses and add a "b" at the end. For example: ((text to be bolded))b. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((text to be bolded))b', true, 'Bold text template copied to clipboard')
        },
        {
          title: 'Bold Italic Text',
          tooltip:
            'To bold and italicize text, wrap it in double parentheses and add a "bi" at the end. For example: ((text to be bolded and italicized))bi. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((text to be bolded and italicized))bi', true, 'Bold italic text template copied to clipboard')
        },
        {
          title: 'Underlined Text',
          tooltip:
            'To underline text, wrap it in double parentheses and add a "u" at the end. For example: ((text to be underlined))u. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((text to be underlined))u', true, 'Underlined text template copied to clipboard')
        },
        {
          title: 'Link',
          tooltip:
            'To add a link, wrap the link text in double parentheses and add an "l" at the end. For example: ((link text))l=https://link.com. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((link text))l=https://link.com', true, 'Link template copied to clipboard')
        },
        {
          title: 'Italic Link',
          tooltip:
            'To italicize a link, wrap the link text in double parentheses and add an "li" at the end. For example: ((link text))il=https://link.com. Click to copy to clipboard.',
          onClick: () => copyToClipboard('((link text))li=https://link.com', true, 'Italic link template copied to clipboard')
        }
      ]
    },
    {
      title: 'Quotes',
      elements: [
        { type: BLOG_ELEMENT_TYPES.QUOTE_HALF, title: 'Half Width Quote' },
        { type: BLOG_ELEMENT_TYPES.QUOTE_FULL, title: 'Full Width Quote' }
      ]
    },
    {
      title: 'User Shops',
      elements: [
        { type: BLOG_ELEMENT_TYPES.SHOP, title: 'Shop Preview' },
        { type: BLOG_ELEMENT_TYPES.COLLECTION_SHELF, title: 'Collection Shelf' },
        { type: BLOG_ELEMENT_TYPES.PIN_HALF, title: 'Half Width Pin' },
        { type: BLOG_ELEMENT_TYPES.PIN_FULL, title: 'Full Width Pin' }
      ]
    },
    {
      title: 'Media',
      elements: [
        { type: BLOG_ELEMENT_TYPES.BANNER_IMAGE, title: 'Banner Image' },
        { type: BLOG_ELEMENT_TYPES.YOUTUBE, title: 'Youtube Video' },
        { type: BLOG_ELEMENT_TYPES.INSTAGRAM, title: 'Instagram Card' },
        { type: BLOG_ELEMENT_TYPES.INSTAGRAM_LARGE, title: 'Instagram Card Large' }
      ]
    },
    {
      title: 'Formatting',
      elements: [
        { type: BLOG_ELEMENT_TYPES.LINE, title: 'Line' },
        { type: BLOG_ELEMENT_TYPES.SPACER, title: 'Spacer' },
        { type: BLOG_ELEMENT_TYPES.SPACER_SMALL, title: 'Small Spacer' }
      ]
    }
  ];

  const getRandomSizeClass = () => {
    const classes = ['small', 'medium', 'large'];
    return classes[Math.floor(Math.random() * classes.length)];
  };

  const getCard = (element, additionalProps = {}) =>
    isFetching ? (
      <div className={cn('blog-post-element-skeleton', getRandomSizeClass())}></div>
    ) : (
      <BlogPostElement
        key={element.id}
        element={element}
        user={props.user}
        isEditing={isEditing}
        rearrangeHandle={additionalProps.rearrangeHandle}
        moveItemToFirstPosition={additionalProps.moveItemToFirstPosition}
        moveItemToLastPosition={additionalProps.moveItemToLastPosition}
        editBlogPostElement={props.editBlogPostElement}
        deleteBlogPostElement={props.deleteBlogPostElement}
      />
    );

  return (
    <div className='blog-post-elements-outer-container'>
      {blogPostModalIsOpen && <BlogPostModal close={closeBlogPostModal} />}
      <SortableList
        isEditing
        containerClassName='blog-post-elements-sortable-container'
        items={isFetching ? new Array(20).fill({}) : elements}
        updateItem={props.editBlogPostElement}
        props={props}
        getCard={getCard}
      />

      {/* ADDING POST ELEMENTS PANEL */}
      {isEditing && (
        <div className={cn('add-component-container', { active: isEditing })}>
          <div className='add-component-header' onClick={openBlogPostModal}>
            Post Admin Actions
          </div>
          <div className='admin-buttons-container'>
            <div className='admin-button' onClick={openBlogPostModal}>
              Edit Post
            </div>
            <div className='admin-button' onClick={props.deleteBlogPost}>
              Delete Post
            </div>
          </div>
          <div className='add-component-header' onClick={props.toggleEditingMode}>
            Add Blog Element
          </div>
          <div className='add-component-subheader'>
            All new elements will be added to the bottom of the post. Rearrange elements by dragging and dropping.
          </div>
          <div className='add-component-options-container'>
            {blogElementOptions.map((optionGroup, idx) => {
              const { title, elements } = optionGroup;

              return (
                <div key={idx} className='add-component-option-group'>
                  <div className='add-component-option-group-title'>{title}</div>
                  <div className='add-component-options'>
                    {elements.map((element, idx) => {
                      const { title, type, tooltip, onClick } = element;

                      return tooltip ? (
                        <Tooltip message={tooltip} key={idx}>
                          <div onClick={() => (onClick ? onClick() : addElement(type))} key={idx} className='add-component-option-container'>
                            <div className='add-component-option'>{title}</div>
                          </div>
                        </Tooltip>
                      ) : (
                        <div onClick={() => (onClick ? onClick() : addElement(type))} key={idx} className='add-component-option-container'>
                          <div className='add-component-option'>{title}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

BlogPostElements.propTypes = {
  post: PropTypes.object.isRequired,
  editBlogPostElement: PropTypes.func.isRequired,
  createBlogPostElement: PropTypes.func,
  deleteBlogPostElement: PropTypes.func,
  isEditing: PropTypes.bool,
  elements: PropTypes.array,
  isFetching: PropTypes.bool.isRequired,
  deleteBlogPost: PropTypes.func.isRequired
};

export default BlogPostElements;
