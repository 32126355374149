import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import icons from '../../static/icons';
import './ConnectSocialsPanel.scss';

import { openConnectInstagramModal } from '../../Actions/UIActions';
import { disconnectSocialAccount, connectInstagram, connectYoutube, connectTiktok } from '../../Actions/UserActions';

import { isBrand, getSocialAccount, getBrandSocialAccount } from '../../Helpers/user_helpers';
import { openInstagramAuthModal, openYoutubeAuthModal, openTikTokAuthModal } from '../../Helpers/social_helpers';

const ConnectSocialsPanel = props => {
  const { user } = props;

  const [isAddingSocialAccountType, setIsAddingSocialAccountType] = React.useState(null);

  const getAccountFn = isBrand(user) ? getBrandSocialAccount : getSocialAccount;
  const instaAccount = getAccountFn(user, 'instagram');
  const youtubeAccount = getAccountFn(user, 'youtube');
  const tiktokAccount = getAccountFn(user, 'tiktok');

  const disconnectAccount = type => {
    const account = getAccountFn(user, type);
    confirmAlert({
      title: 'Just confirming',
      message: isBrand(user)
        ? `Are you sure you want to disconnect this account? This will effect measuring accuracy on your social performance analytics.`
        : `Are you sure you want to disconnect this account? This will remove all reporting on your brand collaborations.`,
      buttons: [
        { label: 'No', onClick: () => {}, className: 'cancel' },
        {
          label: 'Yes',
          onClick: () => props.disconnectSocialAccount(account)
        }
      ]
    });
  };

  const accountConnections = [
    {
      type: 'instagram',
      isVisible: true, // Brands and Creators
      account: instaAccount,
      label: instaAccount?.instagramUsername ? `@${instaAccount.instagramUsername}` : null,
      connectAccount: () => {
        openInstagramAuthModal(
          async authResponse => {
            setIsAddingSocialAccountType('instagram');
            await props.connectInstagram(authResponse);
            setIsAddingSocialAccountType(null);
          },
          () => props.openConnectInstagramModal()
        );
      }
    },
    {
      type: 'youtube',
      isVisible: !isBrand(user), // Creators Only
      account: youtubeAccount,
      connectAccount: openYoutubeAuthModal
    },
    {
      type: 'tiktok',
      isVisible: !isBrand(user), // Creators Only
      account: tiktokAccount,
      connectAccount: openTikTokAuthModal
    }
  ].filter(({ isVisible }) => isVisible);

  return (
    <div className='settings-block connect-socials-panel-container'>
      <div className='label'>Connect Your Social Accounts</div>
      {isBrand(user) ? (
        <div className='sublabel'>Connect your social accounts for a higher level of accuracy on your brand's social mentions.</div>
      ) : (
        <div className='sublabel'>
          Connect your social accounts for more in-depth analytics on your brand collaborations. For more information or help getting set up, see our
          general guide{' '}
          <a href='https://guide.shopmy.us/how-to-link-your-social-media' target='_blank' rel='noopener noreferrer'>
            here
          </a>{' '}
          or Instagram specific guide{' '}
          <span onClick={props.openConnectInstagramModal} className='link'>
            here
          </span>
          .
        </div>
      )}
      <div className='connect-social-btns'>
        {accountConnections.map(connection => {
          const { type, account, label, connectAccount } = connection;
          const isConnecting = isAddingSocialAccountType === type;
          const isConnected = !!account;
          const disconnectAction = () => disconnectAccount(type);
          const connectBtnText = isConnected
            ? `${type.charAt(0).toUpperCase()}${type.slice(1)} Connected`
            : `Connect ${type.charAt(0).toUpperCase()}${type.slice(1)}`;
          const connectBtnClass = isConnected ? `${type} disconnect` : type;
          return (
            <div className='connect-social-btn-outer' key={type}>
              <div onClick={isConnected ? disconnectAction : connectAccount} className={`connect-social-btn ${connectBtnClass}`}>
                <img className='social-icn' src={icons.social[type].dark} alt={`${type.charAt(0).toUpperCase()}${type.slice(1)} Logo`} />
                {isConnecting ? 'Connecting...' : connectBtnText}
                {isConnected && <FontAwesomeIcon icon={faTimes} />}
              </div>
              {label && <div className='connect-social-btn-label'>{label}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ConnectSocialsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired,
  disconnectSocialAccount: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  connectYoutube: PropTypes.func.isRequired,
  connectTiktok: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openConnectInstagramModal,
  disconnectSocialAccount,
  connectInstagram,
  connectYoutube,
  connectTiktok
})(ConnectSocialsPanel);
