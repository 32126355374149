import APIClient, { get400ErrorMessage } from './index';
import _ from 'lodash';

const routePrefix = 'BrandIntegrations';

export const testShopifyIntegration = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/${routePrefix}/test`, { ...data, type: 'shopify' })
      .then(response => resolve(response.data))
      .catch(e => reject(get400ErrorMessage(e)));
  });
};

export const saveShopifyIntegration = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/${routePrefix}`, { ...data, type: 'shopify' })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateBrandIntegration = (integrationId, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/${routePrefix}/${integrationId}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
