import React from 'react';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import { logToSlack } from '../APIClient/alerts';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';

export const getAllChats = chats => _.get(chats, 'chats', []);

// Intercom Specific Handling
const INTERCOM_APP_ID = 'qblcp41j';
export const intitializeIntercom = async user => {
  await new Promise(resolve => {
    (function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
          resolve();
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  });

  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    userId: _.get(user, 'profile.id'),
    name: _.get(user, 'profile.name'),
    email: _.get(user, 'profile.email'),
    username: _.get(user, 'profile.username'),
    is_brand: !!_.get(user, 'profile.Brand_id'),
    is_pro: window.__IS_PRO__,
    is_sms: window.__IS_SMS__
  });
};

export const prepopulateIntercomMessage = msg => {
  window.Intercom && window.Intercom('showNewMessage', msg);
};

export const requestFeedback = async ({ header, subheader, delay, messagePrefix }) => {
  if (delay) await new Promise(resolve => setTimeout(resolve, delay));
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmPrompt
        header={header || 'Request Feedback'}
        subheader={subheader || null}
        onCancel={onClose}
        customInputFields={[{ placeholder: 'Please share feedback here', value: 'message' }]}
        onSubmit={({ message }) => {
          if (!message) return;
          window.ALERT.success('Feedback submitted, thank you!');
          logToSlack({ message: messagePrefix ? `${messagePrefix}: ${message}` : message, channel: 'opportunities' });
        }}
      />
    )
  });
};
