import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import './LatestSection_Collections.scss';

const LatestSection_Collections = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section collections'>
      {items.map(item => {
        const { collection } = item;
        const { id, preview_pins, user } = collection || {};
        if (!collection) return null;
        const collectionUrl = `/collections/${id}`;
        const shopUrl = `/${user.username}`;
        return (
          <div key={id} className='collection'>
            <div>
              <Link to={`/collections/${id}`} className='pin-previews'>
                {preview_pins.map((pin, idx) => {
                  return (
                    <div key={pin.id} className='pin-preview'>
                      <img src={pin.image} alt={`Product ${idx}`} />
                    </div>
                  );
                })}
              </Link>
              <div className='meta'>
                <div className='shelf-shadow' />
                <Link to={shopUrl} className='user'>
                  <img src={user.image} alt='User' />
                  <span className='name'>{user.name}</span>
                </Link>
                <Link to={collectionUrl} className='title'>
                  {collection.name}
                </Link>
                <Link to={collectionUrl} className='view-btn'>
                  View the Collection
                  <FontAwesomeIcon icon={faChevronRight} />
                </Link>
              </div>
            </div>
            {props.getItemIndicatorBadges(item, 'primary')}
          </div>
        );
      })}
    </div>
  );
};

LatestSection_Collections.propTypes = {
  user: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired
};

export default LatestSection_Collections;
