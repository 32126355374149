import _ from 'lodash';
import * as ActionTypes from '../Actions/StoreActions';
import * as UIActionTypes from '../Actions/UIActions';
import * as CollaborationsActionTypes from '../Actions/CollaborationsActions';
import * as SectionActionTypes from '../Actions/SectionActions';
import cogoToast from 'cogo-toast';

import { getCollectionsTitle } from '../Helpers/helpers';

export const store = (
  state = {
    visibleProduct: null,
    visibleCollection: null,
    visibleConsult: null,
    visibleConsultResult: null,
    visibleShop: null,
    lockedCatalogBrand: null,
    failureFetchingVisibleCollection: false,
    merchantData: {},
    consultResponses: {},
    consultResults: [],
    highRateDomains: [],
    customRates: [],
    examples: {}
  },
  action
) => {
  const newState = { ...state };
  const { data, type, visibleShop, visibleCollection, visibleConsult } = action;
  switch (type) {
    // Collection Page
    case ActionTypes.GET_VISIBLE_COLLECTION_REQUEST:
      newState.failureFetchingVisibleCollection = false;
      return newState;

    case ActionTypes.GET_VISIBLE_COLLECTION_FAILURE:
      cogoToast.error(`There was an issue loading this ${getCollectionsTitle()}, please reload the page.`);
      return {
        ...state,
        visibleCollection: null,
        failureFetchingVisibleCollection: true
      };

    case ActionTypes.SET_VISIBLE_COLLECTION:
    case ActionTypes.GET_VISIBLE_COLLECTION_SUCCESS:
      newState.failureFetchingVisibleCollection = false;
      newState.visibleCollection = {
        ...visibleCollection,
        pins: _.filter(_.orderBy(_.get(action, ['visibleCollection', 'pins']), 'sortOrderRank'), p => action.canEdit || !p.isHidden).map(
          (pin, index) => ({
            ...pin,
            index
          })
        )
      };
      return newState;

    // Consult Page
    case ActionTypes.SET_VISIBLE_CONSULT:
    case ActionTypes.GET_VISIBLE_CONSULT_SUCCESS:
      newState.visibleConsult = {
        ...visibleConsult
      };
      return newState;

    // Shop Page
    case ActionTypes.SET_VISIBLE_SHOP:
    case ActionTypes.GET_VISIBLE_SHOP_SUCCESS:
      newState.visibleShop = {
        ...visibleShop,
        collections: _.orderBy(_.get(action, ['visibleShop', 'collections']), ['isArchived', 'sortOrderRank'], ['asc', 'asc']),
        consults: _.orderBy(_.get(action, ['visibleShop', 'consults']), ['sortOrderRank'], ['asc']),
        sections: _.orderBy(_.get(action, ['visibleShop', 'sections']), ['sortOrderRank'], ['asc'])
      };
      return newState;

    case ActionTypes.ADD_CONSULT_SUCCESS:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        consults: _.orderBy(_.concat(_.get(state.visibleShop, 'consults', []), action.element), 'sortOrderRank')
      };
      return newState;

    case ActionTypes.ADD_CONSULT_RESULT_SUCCESS:
      newState.visibleConsultResult = action.result;
      newState.consultResults = [..._.filter(newState.consultResults, result => result.Consult_id !== action.result.Consult_id), action.result];
      return newState;

    case ActionTypes.GET_VISIBLE_CONSULT_RESULT_SUCCESS:
      newState.visibleConsultResult = {
        ...action.result,
        pins: _.orderBy(action.result.pins, 'sortOrderRank')
      };
      return newState;

    case ActionTypes.GET_VISIBLE_CONSULT_RESULT_FAILURE:
      cogoToast.error(
        action.error || 'There was an error loading this result. Please refresh and if this issue continues, reach out to us at team@shopmy.us!',
        { hideAfter: 6 }
      );
      return newState;

    // General Collection Edits
    case ActionTypes.EDIT_COLLECTION_SUCCESS:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        collections: _.orderBy(
          _.map(_.get(state.visibleShop, 'collections', []), collection => {
            return collection.id === action.collection.id ? action.collection : collection;
          }),
          'sortOrderRank'
        )
      };
      return newState;

    case ActionTypes.EDIT_CONSULT_REQUEST:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        consults: _.orderBy(
          _.get(state.visibleShop, 'consults', []).map(consult => (consult.id === action.id ? { ...consult, ...action.updates } : consult)),
          'sortOrderRank'
        )
      };
      newState.visibleConsult =
        newState.visibleConsult && newState.visibleConsult.id === action.id
          ? {
              ...newState.visibleConsult,
              ...action.updates
            }
          : newState.visibleConsult;
      return newState;

    case ActionTypes.EDIT_CONSULT_SUCCESS:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        consults: _.orderBy(
          _.get(state.visibleShop, 'consults', []).map(consult => (consult.id === action.consult.id ? { ...action.consult } : consult)),
          'sortOrderRank'
        )
      };
      newState.visibleConsult =
        newState.visibleConsult && newState.visibleConsult.id === action.consult.id ? action.consult : newState.visibleConsult;
      return newState;

    case ActionTypes.EDIT_CONSULT_RESULT_SUCCESS:
      newState.visibleConsultResult = newState.visibleConsultResult
        ? {
            ...newState.visibleConsultResult,
            ...action.consultResult
          }
        : newState.visibleConsultResult;
      return newState;

    case ActionTypes.DELETE_CONSULT_REQUEST:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        consults: _.filter(_.get(state.visibleShop, 'consults', []), consults => {
          return consults.id !== action.consult.id;
        })
      };
      return newState;

    case ActionTypes.DELETE_CONSULT_FAILURE:
      cogoToast.error('There was an issue deleting this consultation, please try again. If this issue persists, please reach out for assistance.', {
        hideAfter: 6
      });
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        consults: _.orderBy(_.concat(_.get(state.visibleShop, 'consults', []), action.consult), 'sortOrderRank')
      };
      return newState;

    case ActionTypes.DELETE_COLLECTION_REQUEST:
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        collections: _.filter(_.get(state.visibleShop, 'collections', []), collection => {
          return collection.id !== action.collection.id;
        })
      };
      return newState;

    case ActionTypes.DELETE_COLLECTION_FAILURE:
      cogoToast.error(
        action.error?.error ||
          'There was an issue deleting this collection, please try again. If this issue persists, please reach out for assistance.',
        {
          hideAfter: 6
        }
      );
      newState.visibleShop = newState.visibleShop && {
        ...newState.visibleShop,
        collections: _.orderBy(_.concat(_.get(state.visibleShop, 'collections', []), action.collection), 'sortOrderRank')
      };
      return newState;

    case ActionTypes.EDIT_PIN_SUCCESS:
      newState.visibleConsultResult = newState.visibleConsultResult && {
        ...newState.visibleConsultResult,
        pins: _.orderBy(
          (newState.visibleConsultResult.pins || []).map(pin =>
            pin.id === action.id
              ? {
                  ...pin,
                  ...action.updates
                }
              : pin
          ),
          'sortOrderRank'
        )
      };
      return newState;

    case ActionTypes.EDIT_PIN_REQUEST:
      newState.visibleConsultResult = newState.visibleConsultResult && {
        ...newState.visibleConsultResult,
        pins: _.orderBy(
          (newState.visibleConsultResult.pins || []).map(p => (p.id === action.pin.id ? { ...p, ...action.updates } : p)),
          'sortOrderRank'
        )
      };
      return newState;

    case ActionTypes.EDIT_PIN_FAILURE:
      newState.visibleConsultResult = newState.visibleConsultResult && {
        ...newState.visibleConsultResult,
        pins: (newState.visibleConsultResult.pins || []).map(p => (p.id === action.pin.id ? action.pin : p))
      };
      return newState;

    case ActionTypes.DELETE_PIN_REQUEST:
      newState.visibleConsultResult = newState.visibleConsultResult && {
        ...newState.visibleConsultResult,
        pins: (newState.visibleConsultResult.pins || []).filter(pin => pin.id !== action.pin.id)
      };
      return newState;

    case ActionTypes.DELETE_PIN_FAILURE:
      cogoToast.error(`There was an issue deleting this product, please try again.`);
      newState.visibleConsultResult = newState.visibleConsultResult && {
        ...newState.visibleConsultResult,
        pins: [...(newState.visibleConsultResult.pins || []), action.pin]
      };
      return newState;

    case CollaborationsActionTypes.CREATE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        visibleShop: newState.visibleShop && {
          ...newState.visibleShop,
          tasks: action.task?.User_id === newState?.visibleShop?.id ? _.concat(newState.visibleShop?.tasks, action.task) : newState.visibleShop.tasks
        }
      };

    case CollaborationsActionTypes.UPDATE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        visibleShop: newState.visibleShop && {
          ...newState.visibleShop,
          tasks: _.map(newState.visibleShop?.tasks, t => (t.id === action.task?.id ? action.task : t))
        }
      };

    case CollaborationsActionTypes.DELETE_USER_CONTRACT_TASK_SUCCESS:
      return {
        ...newState,
        visibleShop: newState.visibleShop && {
          ...newState.visibleShop,
          tasks: _.filter(newState?.visibleShop?.tasks, t => t.id !== action.task?.id)
        }
      };

    // Merchant Specific Data
    case ActionTypes.UPDATE_MERCHANT_DATA:
      const currentFreshData = _.reduce(
        newState.merchantData,
        (res, data, key) => {
          const isExpired = Date.now() > data.cacheExpires;
          return {
            ...res,
            ...(isExpired ? {} : { [key]: data })
          };
        },
        {}
      );
      const newData = _.reduce(
        data,
        (res, data, key) => {
          return {
            ...res,
            [key]: {
              ...data,
              cacheExpires: Date.now() + 1000 * 60 * 30 // 30 minute cache
            }
          };
        },
        {}
      );
      newState.merchantData = {
        ...currentFreshData,
        ...newData
      };
      return newState;

    case ActionTypes.SAVE_CONSULT_RESPONSE:
      return {
        ...state,
        consultResponses: {
          ...state.consultResponses,
          [action.consult.id]: action.content
        }
      };

    case ActionTypes.GET_EXAMPLE_DATA_SUCCESS:
      return {
        ...state,
        examples: {
          ...action.examples,
          ...(action.resetCache ? { lastFetched: Date.now() } : {})
        }
      };

    case ActionTypes.UPDATE_MERCHANTS_HIGH_RATES:
      return {
        ...state,
        highRateMerchants: action.merchants || []
      };

    case ActionTypes.UPDATE_MERCHANTS_FEATURED:
      return {
        ...state,
        featured: action.featured || []
      };

    case ActionTypes.GET_CUSTOM_RATES_SUCCESS:
      return {
        ...state,
        customRates: action.rates
      };

    case UIActionTypes.SET_BRAND_THEME:
      return {
        ...state,
        lockedCatalogBrand: null
      };

    // Sections
    case SectionActionTypes.ADD_SECTION_SUCCESS:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      return {
        ...state,
        visibleShop: {
          ...state.visibleShop,
          sections: _.concat(state.visibleShop.sections || [], action.section)
        }
      };

    case SectionActionTypes.ADD_SECTION_FAILURE:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      cogoToast.error('We had an issue adding a new section, please try again.');
      return {
        ...state
      };

    case SectionActionTypes.UPDATE_SECTION_REQUEST:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      return {
        ...state,
        visibleShop: {
          ...state.visibleShop,
          sections: _.map(state.visibleShop.sections, s =>
            s.id === action.section.id
              ? {
                  ...s,
                  ...action.updates
                }
              : s
          )
        }
      };

    case SectionActionTypes.UPDATE_SECTION_ERROR:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      cogoToast.error('We had an issue updating your section, please try again.');
      return {
        ...state,
        visibleShop: {
          ...state.visibleShop,
          sections: _.map(state.visibleShop.sections, s => (s.id === action.section.id ? action.section : s))
        }
      };

    case SectionActionTypes.DELETE_SECTION_REQUEST:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      return {
        ...state,
        visibleShop: {
          ...state.visibleShop,
          sections: state.visibleShop.sections.filter(s => s.id !== action.section.id)
        }
      };

    case SectionActionTypes.DELETE_SECTION_FAILURE:
      if (action.section.User_id !== state.visibleShop?.id) return state; // If editing on non shop page
      cogoToast.error('We had an issue deleting your section, please try again.');
      return {
        ...state,
        visibleShop: {
          ...state.visibleShop,
          sections: _.concat(state.visibleShop.sections || [], action.section)
        }
      };

    default:
      return state;
  }
};
