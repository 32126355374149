import {
  updateUserManager as updateUserManagerAPI,
  deleteUserManager as deleteUserManagerAPI,
  getUserManagesNotificationCounts as getUserManagesNotificationCountsAPI
} from '../APIClient/managers';

import { syncCurrentUser, SYNC_CURRENT_USER_SUCCESS } from './UserActions';
import { getCurrentManager } from '../Helpers/manager_helpers';
import { getManagerId } from '../Helpers/user_helpers';

export const UPDATE_USER_MANAGER_REQUEST = 'UPDATE_USER_MANAGER_REQUEST';
export const UPDATE_USER_MANAGER_SUCCESS = 'UPDATE_USER_MANAGER_SUCCESS';
export const UPDATE_USER_MANAGER_ERROR = 'UPDATE_USER_MANAGER_ERROR';

export const DELETE_USER_MANAGER_REQUEST = 'DELETE_USER_MANAGER_REQUEST';
export const DELETE_USER_MANAGER_SUCCESS = 'DELETE_USER_MANAGER_SUCCESS';
export const DELETE_USER_MANAGER_ERROR = 'DELETE_USER_MANAGER_ERROR';

export const SELECT_USER_TO_MANAGE_REQUEST = 'SELECT_USER_TO_MANAGE_REQUEST';
export const SELECT_USER_TO_MANAGE_SUCCESS = 'SELECT_USER_TO_MANAGE_SUCCESS';
export const SELECT_USER_TO_MANAGE_ERROR = 'SELECT_USER_TO_MANAGE_ERROR';

export const GET_USER_MANAGES_NOTIFICATION_COUNTS_SKIP = 'GET_USER_MANAGES_NOTIFICATION_COUNTS_SKIP';
export const GET_USER_MANAGES_NOTIFICATION_COUNTS_SUCCESS = 'GET_USER_MANAGES_NOTIFICATION_COUNTS_SUCCESS';
export const GET_USER_MANAGES_NOTIFICATION_COUNTS_ERROR = 'GET_USER_MANAGES_NOTIFICATION_COUNTS_ERROR';

export const updateUserManager = (userManager, updates) => async (dispatch, getState) => {
  const initialManager = // Grab initial manager object in case of failure
    getState().user.profile.managers?.find(m => m.id === userManager.id) || getState().user.profile.manages?.find(m => m.id === userManager.id);
  dispatch({ type: UPDATE_USER_MANAGER_REQUEST, userManager, updates });
  try {
    await updateUserManagerAPI(userManager, updates);
    return dispatch({
      type: UPDATE_USER_MANAGER_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_MANAGER_ERROR,
      initialManager,
      error
    });
  }
};

export const deleteUserManager = userManager => async (dispatch, getState) => {
  dispatch({ type: DELETE_USER_MANAGER_REQUEST, manager: userManager });
  try {
    await deleteUserManagerAPI(userManager);
    return dispatch({
      type: DELETE_USER_MANAGER_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: DELETE_USER_MANAGER_ERROR,
      manager: userManager,
      error
    });
  }
};

export const selectUserToManage = userToManage => async (dispatch, getState) => {
  let newUsername, newManager, newUserBeingManaged;
  if (userToManage) {
    // If we are changing to Manage someone
    newUsername = userToManage.username;
    newManager = getState().manager?.currentManager || getState()?.user?.profile;
    newUserBeingManaged = userToManage;
  } else {
    // Reset to Manager Account
    newUsername = getState().manager.currentManager.username;
    newManager = null;
    newUserBeingManaged = null;
  }

  try {
    if (userToManage) {
      dispatch({ type: SELECT_USER_TO_MANAGE_REQUEST, manager: newManager, managing: newUserBeingManaged });
      await dispatch(syncCurrentUser(newUsername));
      return dispatch({
        type: SELECT_USER_TO_MANAGE_SUCCESS
      });
    } else {
      const baseUser = getState().manager.currentManager;
      dispatch({ type: SELECT_USER_TO_MANAGE_REQUEST, manager: newManager, managing: newUserBeingManaged });
      dispatch({ type: SYNC_CURRENT_USER_SUCCESS, user: baseUser });
      return dispatch({
        type: SELECT_USER_TO_MANAGE_SUCCESS
      });
    }
  } catch (error) {
    console.error(error);
    return dispatch({
      type: SELECT_USER_TO_MANAGE_ERROR,
      error
    });
  }
};

export const getUserManagesNotificationCounts = userToManage => async (dispatch, getState) => {
  const Manager_id = getCurrentManager(getState().manager)?.id || getManagerId(getState()?.user);
  if (!Manager_id) return dispatch({ type: GET_USER_MANAGES_NOTIFICATION_COUNTS_SKIP });
  try {
    const resp = await getUserManagesNotificationCountsAPI(Manager_id);
    return dispatch({
      type: GET_USER_MANAGES_NOTIFICATION_COUNTS_SUCCESS,
      user_notification_counts: resp.user_notification_counts
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: GET_USER_MANAGES_NOTIFICATION_COUNTS_ERROR,
      error
    });
  }
};
