import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './MentionDateRangePicker.scss';

const MentionDateRangePicker = props => {
  // Use a local copy as well so that we can enforce that we need both values to update
  const [startDate, setStartDate] = useState(props.curFilters.startDate);
  const [endDate, setEndDate] = useState(props.curFilters.endDate);

  const [focusedInput, setFocusedInput] = useState(null);

  const updateDates = (newStartDate, newEndDate) => {
    /*
      Only update the filter state when we have both a start and end date.

      If we only have a start date, we want to update the start date filter, 
      but we want to wait on the end date.
    */
    if (props.blockUsage()) return null;
    const isUpdatingStartDate = !moment(newStartDate).isSame(startDate);
    if (isUpdatingStartDate) {
      setStartDate(newStartDate);
      setEndDate(null);
    } else {
      setEndDate(newEndDate);
    }

    const isClearing = !newStartDate && !newEndDate;
    const isSettingRange = newStartDate && newEndDate && !isUpdatingStartDate;
    const shouldUpdate = isClearing || isSettingRange;
    shouldUpdate &&
      props.setCurFilters({
        ...props.curFilters,
        startDate: newStartDate,
        endDate: newEndDate,
        page: 0
      });
  };

  React.useEffect(() => {
    const { curFilters } = props;
    if (curFilters.startDate && !moment(curFilters.startDate).isSame(startDate)) {
      setStartDate(moment(curFilters.startDate));
    }
    if (curFilters.endDate && !moment(curFilters.endDate).isSame(endDate)) {
      setEndDate(moment(curFilters.endDate));
    }
  }, [props.curFilters.startDate, props.curFilters.endDate]);

  return (
    <div className='mentions-date-range-picker-container'>
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
        displayFormat={startDate && moment().year() !== startDate.year() ? "MMM Do 'YY" : 'MMM Do'}
        onDatesChange={({ startDate, endDate }) => updateDates(startDate, endDate)}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
        noBorder
        minimumNights={0}
        showClearDates
      />
    </div>
  );
};

MentionDateRangePicker.propTypes = {
  curFilters: PropTypes.object.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  blockUsage: PropTypes.func.isRequired
};

export default MentionDateRangePicker;
