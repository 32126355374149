import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './PopularFeatures.scss';

import SortableList from '../../General/SortableList';

import DiscoverFeatureActions from './DiscoverFeatureActions';

const PopularFeatures = props => {
  const { features } = props;
  const getCard = (feature, additionalProps = {}) => {
    const { list } = feature;
    const { title } = list;
    const { rearrangeHandle } = additionalProps;
    return (
      <div className={cn('popular-feature feature', { hidden: feature.isHidden })} key={list.id}>
        <div onClick={() => props.goToList(list)} className='title'>
          {title}
          {rearrangeHandle}
        </div>
        {props.isEditing && (
          <DiscoverFeatureActions
            feature={feature}
            deleteDiscoverFeature={props.deleteDiscoverFeature}
            updateDiscoverFeature={props.updateDiscoverFeature}
          />
        )}
      </div>
    );
  };

  if (!features.length) return null;
  return (
    <SortableList
      isEditing={props.isEditing}
      containerClassName='popular-features'
      items={features}
      updateItem={props.updateDiscoverFeature}
      props={props}
      getCard={getCard}
      useSortableHandleDefault
    />
  );
};

PopularFeatures.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  deleteDiscoverFeature: PropTypes.func.isRequired,
  updateDiscoverFeature: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired
};

export default PopularFeatures;
