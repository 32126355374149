import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
// import _ from 'lodash'
import cn from 'classnames';
import './SubscriptionTabs.scss';

const SubscriptionTabs = props => {
  const { tabs, selectTab } = props;
  return (
    <div className='subscription-tabs-container'>
      <div className='subscription-tabs'>
        {tabs.map(tab => {
          const { title, isActive, hasWarning, hasAlert } = tab;
          const select = () => !isActive && selectTab(tab);
          const additionalClasses = { active: isActive, warning: hasWarning, alert: hasAlert };
          return (
            <div key={title} className={cn('tab-container', additionalClasses)} onClick={select}>
              <div className={cn('tab', additionalClasses)}>
                {(tab.hasWarning || tab.hasAlert) && (
                  <div className={cn('alert-or-warning', additionalClasses)}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </div>
                )}
                <div className='title'>{tab.title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

SubscriptionTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  selectTab: PropTypes.func.isRequired
};

export default SubscriptionTabs;
