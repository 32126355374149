import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './AddPinModalAdminControls.scss';

import { getOpportunityRequests } from '../../../Helpers/user_helpers';
import { getTimingOnOpportunity } from '../../../Helpers/opportunity_helpers';
import { editPin } from '../../../APIClient/pins';

import Select from '../../General/Select';

const AddPinModalAdminControls = props => {
  const { user, pin } = props;
  const { ForceOpportunityResult_id } = pin;
  /*
    Allow admin's to force count an opportunity for a specific result
  */
  const opportunityRequestsWithResults = getOpportunityRequests(user).filter(r => !!r.result?.id);
  const [requestBeingForced, setOpportunityRequestBeingForced] = React.useState(
    opportunityRequestsWithResults.find(r => r.result.id === ForceOpportunityResult_id)
  );
  const opportunitySelectOptions = opportunityRequestsWithResults.map(r => ({
    value: r.result.id,
    label: r.opportunity.title + ' • ' + r.brand.name,
    sublabels: [
      r.brand.name,
      'Tracking ' + moment(r.opportunity.trackingStartsAt).format('MMM Do') + ' - ' + moment(r.opportunity.trackingEndsAt).format('MMM Do, YYYY')
    ]
  }));

  const shouldShowAdminControls = !!opportunityRequestsWithResults.length;
  if (!shouldShowAdminControls) return null;

  return (
    <div className='admin-controls-outer-container'>
      <div className='section-title'>Advanced Admin Controls</div>
      <div className='controls'>
        <div className='control'>
          <div className='header'>
            <div className='label'>Force Count For Opportunity:</div>
            <div className='actions'></div>
          </div>
          <div className='main'>
            <Select
              noStyling
              options={opportunitySelectOptions}
              value={requestBeingForced?.result?.id}
              placeholder='Select Opportunity'
              onChangeValue={value => {
                if (ForceOpportunityResult_id) {
                  const confirm = window.confirm('Are you sure you want to change the forced opportunity?');
                  if (!confirm) return;
                }
                const request = opportunityRequestsWithResults.find(r => r.result.id === value);
                const timing = getTimingOnOpportunity(request);
                setOpportunityRequestBeingForced(request);
                editPin(pin.id, { ForceOpportunityResult_id: request.result.id }).then(resp => {
                  window.ALERT.success(
                    timing.hasEnded
                      ? `This tracking window has closed, please contact the brand support team + engineering to make this count.  Remember that this still must reach the minimum click threshold, this simply forces it into the correct window.`
                      : `Successfully forced this link to count, this will kick in when the next 15 minute check runs. Remember that this still must reach the minimum click threshold, this simply forces it into the correct window.`,
                    { duration: 10_000 }
                  );
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddPinModalAdminControls.propTypes = {
  user: PropTypes.object.isRequired,
  pin: PropTypes.object.isRequired
};

export default AddPinModalAdminControls;
