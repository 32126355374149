import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './RequestResultsOld.scss';

import ConfirmPrompt from '../General/ConfirmPrompt';
import RequestResultOld from './RequestResultOld';
import Loader from '../Loader/Loader';

class RequestResultsOld extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    requests: PropTypes.array.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    openFulfillmentModal: PropTypes.func.isRequired,
    updateRequest: PropTypes.func.isRequired,
    fetchNextPage: PropTypes.func.isRequired,
    isFetchingNextPage: PropTypes.bool.isRequired,
    receivedAllResults: PropTypes.bool.isRequired
  };

  componentDidMount() {
    window.addEventListener('scroll', this.infiniteScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.infiniteScroll);
  }

  infiniteScroll = e => {
    const pixelsFromBottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight;

    if (pixelsFromBottom < 500 && !this.props.isFetchingNextPage && !this.props.receivedAllResults) {
      this.props.fetchNextPage();
    }
  };

  markSent = async request =>
    new Promise(resolve => {
      this.props.openFulfillmentModal({
        params: {
          Request_id: request.id
        },
        closeCallback: () => resolve()
      });
    });

  sendAgain = request => {
    const onSubmit = async additionalMessage => {
      await this.props.updateRequest(request, { isComplete: false });
      await this.props.updateRequest(request, { isComplete: true });
      cogoToast.success(`Successfully sent confirmation email to ${request.name}!`);
    };

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Add a message with your shipping confirmation.'
          subheader='Talent will receive an email letting them know that their package was shipped out. Would you like to add any additional notes to this message? Feel free to add details about what you are sending, or provide shipping tracking information.'
          placeholder='Add your message here.'
          onCancel={onClose}
          onSubmit={onSubmit}
        />
      )
    });
  };

  getRequestAction = request => {
    if (request.isComplete) {
      return {
        action: this.sendAgain,
        action_label: 'Send More'
      };
    } else if (request.userAccepted) {
      return {
        action: this.markSent,
        action_label: 'Mark as Sent'
      };
    } else if (request.userRejected) {
      return {
        action: null,
        action_label: null
      };
    }
    return {
      action: null,
      action_label: null
    };
  };

  render() {
    const { requests, isFetchingNextPage } = this.props;
    return (
      <div className='request-results'>
        <div className='row'>
          <div className='cell'>CREATED ON</div>
          <div className='cell'>NAME</div>
          <div className='cell'>ADDRESS</div>
          <div className='cell'>STATUS</div>
          <div className='cell'>ACTION</div>
        </div>
        {requests.map(request => {
          const { action, action_label } = this.getRequestAction(request);
          return (
            <RequestResultOld
              key={request.id}
              request={request}
              action={action}
              openArtistModal={this.props.openArtistModal}
              action_label={action_label}
            />
          );
        })}
        {isFetchingNextPage && (
          <div className='fetching-next-page-loader'>
            <Loader size={80} />
          </div>
        )}
      </div>
    );
  }
}

export default RequestResultsOld;
