import React from 'react';
import PropTypes from 'prop-types';
import './LookbookOrderModalCart.scss';
import LookbookOrderModalCartItem from './LookbookOrderModalCartItem';

import { getCartItemsForLookbook } from '../../../../Helpers/lookbook_helpers';

const LookbookOrderModalCart = props => {
  const { lookbook, lookbooks } = props;
  const cartItems = getCartItemsForLookbook(lookbooks, lookbook);

  return (
    <div className='lookbook-order-modal-cart-outer'>
      <div className='lookbook-order-modal-cart-inner'>
        {cartItems.map(cartItem => {
          return (
            <LookbookOrderModalCartItem
              key={cartItem?.sibling?.id}
              lookbook={lookbook}
              lookbooks={lookbooks}
              cartItem={cartItem}
              updateLookbookCart={props.updateLookbookCart}
            />
          );
        })}
      </div>
    </div>
  );
};

LookbookOrderModalCart.propTypes = {
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired
};

export default LookbookOrderModalCart;
