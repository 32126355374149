import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faLink, faTag } from '@fortawesome/pro-solid-svg-icons';

import Loader from '../../Loader/Loader';
import { getSmartImage, dismissEvent } from '../../../Helpers/helpers';
import { getPrettyPriceDisplay } from '../../../Helpers/formatting';
import { getAffiliateLink } from '../../../Helpers/attribution_helpers';

const CollectionProductHorizontal = props => {
  const {
    user,
    collection,
    pin,
    code,
    copyCode,
    lastLocation,
    canEdit,
    DragHandle,
    navigateToProduct,
    updateLinkRefAttributes,
    addScrollSubscriber,
    showingActionsPanel,
    getActionsPanel,
    copyLink,
    toggleEditMode
  } = props;
  const { id, index, title, description, image, isHidden } = pin;
  const affiliateLink = getAffiliateLink(pin, user, collection.user, collection);
  const [isFocused, setIsFocused] = useState(index === 0);
  const container = useRef(null);

  useEffect(() => {
    addScrollSubscriber(id, e => {
      const { width, x } = container?.current?.getBoundingClientRect() || {};
      const leftEdge = x + e.target.scrollLeft;
      const rightEdge = leftEdge + width;
      const switchPoint = e.target.scrollLeft + window.innerWidth / 3;
      if (leftEdge <= switchPoint && rightEdge > switchPoint) {
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    });
  }, [id, addScrollSubscriber, setIsFocused]);

  return (
    <>
      <div ref={container} className={cn('collection-product horizontal', { hidden: isHidden })}>
        <a
          ref={ref => ref && updateLinkRefAttributes(ref)}
          href={affiliateLink}
          target='_blank'
          rel='nofollow noopener noreferrer'
          className={cn('collection-product-img-wrapper', {
            smooth: !canEdit && !!lastLocation,
            focused: isFocused
          })}
        >
          {!!isHidden && (
            <div className='hidden-cover'>
              <div className='badge'>HIDDEN</div>
            </div>
          )}
          <img
            alt={title || 'Product'}
            onClick={navigateToProduct}
            className={cn('product-image', image ? '' : 'empty')}
            src={getSmartImage(image)}
          />
        </a>
        {(props.isDeleting || props.isBeingEdited || props.isDuplicating) && (
          <div className='adjusting-product-loader active'>
            <Loader size={100} />
          </div>
        )}
        {canEdit && <DragHandle />}
      </div>
      <div className={cn('meta-container-horizontal', { focused: isFocused })}>
        <a
          ref={ref => ref && updateLinkRefAttributes(ref)}
          href={affiliateLink}
          target='_blank'
          className='title-container'
          rel='nofollow noopener noreferrer'
          onClick={navigateToProduct}
        >
          <h3 className='title'>{title}</h3>
          {code && !!pin.allow_displaying_codes && (
            <div onClick={dismissEvent} className='code-container'>
              <FontAwesomeIcon onClick={copyCode} icon={faTag} />
              <div onClick={copyCode} className='code'>
                USE CODE <span className='display'>{code.displayText}</span> FOR{' '}
                {code.isPercentage ? `${code.rate}% OFF` : `${getPrettyPriceDisplay(code.rate)} OFF`}
              </div>
            </div>
          )}
        </a>
        <h4 className='description'>{description}</h4>
        {canEdit && (
          <div className='action-btn-container'>
            {!showingActionsPanel && <FontAwesomeIcon onClick={copyLink} icon={faLink} />}
            {!showingActionsPanel && <FontAwesomeIcon onClick={toggleEditMode} icon={faPencilAlt} />}
            {getActionsPanel()}
          </div>
        )}
      </div>
    </>
  );
};

CollectionProductHorizontal.propTypes = {
  // Main Data
  user: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  pin: PropTypes.object.isRequired,
  lastLocation: PropTypes.object,

  // Variables
  index: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isDuplicating: PropTypes.bool.isRequired,
  isBeingEdited: PropTypes.bool.isRequired,
  showingActionsPanel: PropTypes.bool,

  // Functions
  addScrollSubscriber: PropTypes.func.isRequired,
  navigateToProduct: PropTypes.func.isRequired,
  updateLinkRefAttributes: PropTypes.func.isRequired,
  copyLink: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  DragHandle: PropTypes.func
};

export default CollectionProductHorizontal;
