import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ActionsOverlay.scss';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-regular-svg-icons';

import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';

/**
 * This is a helper function that can be used in any component that needs a
 * menu button. This component will automatically place itself in the top right
 * corner of a given component and hide the buttons. When it is clicked, it will
 * popup showing a list of buttons. The buttons that should be used can be
 * imported as TripleDotModalButtons from this same file.
 *
 * @typedef {Object} OverlayOption
 * @property {string} display - the visible text describing what the button will do
 * @property {import('@fortawesome/fontawesome-svg-core').IconDefinition} icon - an optional icon that will show up next to the text
 * @property {import('react').MouseEventHandler} onClick - a function that will be called on click of the button
 *
 * @param {Object} props
 * @param {Element[]} props.children - an array of elements that will go beside the triple dot icon
 * @param {boolean} props.openUp - boolean marking whether the menu should open up or down, default false
 * @param {string} props.containerClassName - string classname that will be applied to the wrapper
 * @param {OverlayOption[]} props.options - boolean marking whether all the icons will always be visible, default true
 * @param {string} props.options.display - the visible text describing what the button will do
 * @param {string} props.options.subdisplay - a subdisplay text that will show up below the display text in smaller font
 * @param {string} props.options.tooltip - wraps the entire button in a tooltip with the given text
 * @param {string} props.options.icon - an optional icon that will show up next to the text from FontAwesome
 * @param {boolean} props.options.isPerformingAction - if true, will show a loader next to the icon
 * @param {function} props.options.onClick - a function that will be called on click of the button
 *
 */
const ActionsOverlay = props => {
  const { children, options, containerClassName } = props;
  const [isOpen, setIsOpen] = useState(false);

  // Ensure compatibility with OutsideClickHandler and local state
  const [isChangingVisibility, setIsChangingVisibility] = useState(false);
  const setOpenState = shouldOpen => {
    if (isChangingVisibility) return;
    setIsChangingVisibility(true);
    setIsOpen(shouldOpen);
    setTimeout(() => setIsChangingVisibility(false), 100);
  };
  const open = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpenState(true);
  };
  const close = () => setOpenState(false);
  const toggleOpen = () => (isOpen ? close() : open());

  const wrapInToolTipIfNecessary = (option, el) => {
    return option.tooltip ? <Tooltip message={option.tooltip} getIconDiv={() => el} /> : el;
  };

  return (
    <div
      className={cn('triple-dots-modal-container', containerClassName)}
      onClick={e => {
        e.stopPropagation();
        toggleOpen();
      }}
    >
      {React.cloneElement(children, { onClick: open, className: cn(children.props.className, { 'overlay-open': isOpen }) })}
      {isOpen && (
        <OutsideClickHandler onOutsideClick={close}>
          <div className={cn('overlay', { 'open-up': props.openUp })} onClick={e => e.stopPropagation()}>
            <div className='padding'>
              <div className='overlay-button-container'>
                {options.map(option => {
                  const additionalClasses = {
                    disabled: option.disabled,
                    'is-performing-action': option.isPerformingAction,
                    'is-active': option.isActive,
                    'highlight-on-active': option.highlightOnActive
                  };
                  return wrapInToolTipIfNecessary(
                    option,
                    <div
                      key={option.display}
                      className={cn('overlay-button', additionalClasses)}
                      onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        !option.disabled && option.onClick();
                      }}
                    >
                      {option.icon && (
                        <div className={cn('icon-container', additionalClasses)}>
                          <FontAwesomeIcon className='icon' icon={icons[option.highlightOnActive && option.isActive ? 'faCheck' : option.icon]} />
                          {option.isPerformingAction && <Loader size={24} />}
                        </div>
                      )}
                      <div className='display-container'>
                        <div className={cn('display', additionalClasses)}>{option.display}</div>
                        {option.subdisplay && <div className='subdisplay'>{option.subdisplay}</div>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

ActionsOverlay.propTypes = {
  children: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      subdisplay: PropTypes.string,
      disabled: PropTypes.bool,
      tooltip: PropTypes.string,
      icon: PropTypes.string,
      isPerformingAction: PropTypes.bool,
      isActive: PropTypes.bool,
      highlightOnActive: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })
  ),
  openUp: PropTypes.bool,
  containerClassName: PropTypes.string
};

export { ActionsOverlay };
