import { useEffect } from 'react';
import PropTypes from 'prop-types';

const RequestsPortalData = props => {
  const { tab, Brand_id, loadGiftingRecommendations, loadedRecommendations } = props;

  /******************************************************************************************* */
  // Fetching gifting recommendations
  /******************************************************************************************* */

  useEffect(() => {
    if (tab !== 'recommendations' || loadedRecommendations) return;
    loadGiftingRecommendations();
  }, [Brand_id, tab]);

  return null;
};

RequestsPortalData.propTypes = {
  tab: PropTypes.string.isRequired,
  Brand_id: PropTypes.number,
  loadGiftingRecommendations: PropTypes.func.isRequired,
  loadedRecommendations: PropTypes.bool.isRequired
};

export default RequestsPortalData;
