import React from 'react';
import './SortIcons.scss';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';

const SortIcons = props => {
  const { sortDirection, toggleSort } = props;

  return (
    <div className='sort-direction-icons' onClick={() => toggleSort(sortDirection === 'asc' ? 'desc' : 'asc')}>
      <div className={cn('sort-icon up', { active: sortDirection === 'asc' })}>
        <FontAwesomeIcon icon={faSortUp} />
      </div>
      <div className={cn('sort-icon down', { active: sortDirection === 'desc' })}>
        <FontAwesomeIcon icon={faSortDown} />
      </div>
    </div>
  );
};

export default SortIcons;
