import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import Tooltip from '../../General/Tooltip';
import { getPrettyNumber } from '../../../Helpers/formatting';

const ArtistModalSidebarSocialAccount = ({
  displayNetworkName,
  displayUsername,
  socialLink,
  count,
  isConnected,
  connectedTooltipMessage,
  disconnectedTooltipMessage
}) => (
  <div className='social-count-container'>
    <a href={socialLink} className='social-count' target='_blank' rel='noopener noreferrer'>
      {count ? <span className='count'>{getPrettyNumber(count)}</span> : <span className='handle'>{displayUsername}</span>}
      <span className='network'>
        {' '}
        on {displayNetworkName} {socialLink && <FontAwesomeIcon icon={faExternalLink} />}
      </span>
    </a>
    {isConnected ? (
      <Tooltip message={connectedTooltipMessage}>
        <div className='connection-label connected'>Connected</div>
      </Tooltip>
    ) : (
      <Tooltip message={disconnectedTooltipMessage}>
        <div className='connection-label'>Not Connected</div>
      </Tooltip>
    )}
  </div>
);

export default ArtistModalSidebarSocialAccount;
