import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './ProfileTabs.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { canEditShop } from '../../Helpers/user_helpers';

import Tooltip from '../General/Tooltip';

const ProfileTabs = props => {
  const { store, tabs, visibleElements, user, waitingOnSSR, switchTab } = props;
  const { visibleShop } = store || {};
  const editable = canEditShop(visibleShop, user) && !waitingOnSSR;

  const addNewSection = () => {
    props.openShopSectionsModal({
      addNew: true
    });
  };

  return (
    <div className='profile-tabs-outer-container'>
      <div className='tab empty' /> {/*Due to hydration bug in react snap*/}
      {(tabs.length > 1 || !visibleElements.length) && !window.__IS_SNAP__ && (
        <div className={cn('profile-tabs-inner-container', { 'many-tabs': tabs.length >= 5 })}>
          {tabs.map((tab, idx) => {
            const { id, display, section, shortDisplay, tooltip, badgeCount, isNew, isActive, isHidden } = tab;
            const openSectionsModal = e => {
              props.openShopSectionsModal({
                Section_id: section.id
              });
            };
            return (
              <div
                key={id + idx}
                onClick={() => !isActive && switchTab(tab)}
                className={cn('tab', {
                  active: isActive,
                  hidden: isHidden,
                  compact: tabs.length > 3 && tabs.length <= 5,
                  'very-compact': tabs.length > 5
                })}
              >
                <Tooltip visible position='bottom' message={(editable && tooltip) || ''} key={id}>
                  <span className='long'>{display}</span>
                  <span className='short'>{shortDisplay}</span>
                </Tooltip>
                {editable && !!badgeCount && <div className='count-badge'>{badgeCount}</div>}
                {editable && !!isHidden && section && (
                  <div onClick={openSectionsModal} className='edit-btn'>
                    <FontAwesomeIcon icon={faEyeSlash} />
                  </div>
                )}
                {editable && isActive && section && (
                  <div onClick={openSectionsModal} className='edit-btn'>
                    <FontAwesomeIcon icon={faPencil} />
                  </div>
                )}
                {isNew && <div className='edit'>NEW</div>}
              </div>
            );
          })}
          {editable && (
            <div onClick={addNewSection} className='tab add-new-tab'>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ProfileTabs.propTypes = {
  store: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  tabs: PropTypes.array.isRequired,
  waitingOnSSR: PropTypes.bool.isRequired,
  switchTab: PropTypes.func.isRequired,
  visibleElements: PropTypes.array.isRequired,
  openShopSectionsModal: PropTypes.func.isRequired
};

export default ProfileTabs;
