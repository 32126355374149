import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';
// import cn from 'classnames'
import './ContractProposeSchedule.scss';

import ContractProposeScheduleCard from './Elements/ContractProposeScheduleCard';

const ContractProposeSchedule = props => {
  const { contract, updateContractTask, canEdit } = props;
  return (
    <div className='contract-propose-schedule-outer-container'>
      <ContractProposeScheduleCard canEdit={canEdit} updateContractTask={updateContractTask} contract={contract} />
    </div>
  );
};

ContractProposeSchedule.propTypes = {
  contract: PropTypes.object.isRequired,

  // Editing
  canEdit: PropTypes.bool.isRequired,
  updateContractTask: PropTypes.func
};

export default ContractProposeSchedule;
