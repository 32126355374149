import React, { useState } from 'react';
import _ from 'lodash';
import './CompetitorBrandSettingsPanel.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getBrand, getCompetitorBrands } from '../../Helpers/user_helpers';
import SortableList from '../General/SortableList';
import { updateCompetitorBrand, addCompetitorBrand, deleteCompetitorBrand } from '../../Actions/BrandActions';
import InfiniteScrollSelect from '../General/InfiniteScrollSelect';
import { fetchAlgoliaIndex } from '../../Helpers/search_helpers';
import { getDomainFromUrl, getGMTTime, getPrettyNumber } from '../../Helpers/formatting';
import { isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import ConfirmPrompt from '../General/ConfirmPrompt';

const LIMITED_SIMILAR_BRANDS_COUNT = 3;
const FULL_ACCESS_SIMILAR_BRANDS_COUNT = 20;

const CompetitorBrandSettingsPanel = props => {
  const { user, ui } = props;
  const allCompetitors = getCompetitorBrands(user);
  const brand = getBrand(user);

  const hasFullCompetitorBrandAccess = isSubscribedToFeature(user, 'ADVANCED_TALENT_ANALYTICS') || isAdminControlMode(ui);

  const competitorBrands = _.orderBy(allCompetitors, 'sortOrderRank', 'asc').slice(
    0,
    hasFullCompetitorBrandAccess ? FULL_ACCESS_SIMILAR_BRANDS_COUNT : LIMITED_SIMILAR_BRANDS_COUNT
  );

  const canAddMore = competitorBrands.length < (hasFullCompetitorBrandAccess ? FULL_ACCESS_SIMILAR_BRANDS_COUNT : LIMITED_SIMILAR_BRANDS_COUNT);

  const [showMerchantSearch, setShowMerchantSearch] = useState(false);
  const [isLoadingBrands, setIsLoadingBrands] = useState(false);
  const [algoliaOffset, setAlgoliaOffset] = useState(0);
  const [searchHasNextPage, setSearchHasNextPage] = useState(true);

  const activateSearch = () => {
    if (!canAddMore) return window.ALERT.info('You have reached the limit of similar brands you can add.');
    if (!showMerchantSearch) setShowMerchantSearch(true);
  };

  const loadMoreAlgoliaBrands = async (searchTerm, isNewSearch) => {
    if (!searchHasNextPage && !isNewSearch) return [];
    setIsLoadingBrands(true);

    let results = [];
    const offset = isNewSearch ? 0 : algoliaOffset;
    const PAGE_LENTGH = 20;

    try {
      const merchantsAlgoliaIndex = fetchAlgoliaIndex(!window.__IS_PROD__ ? 'merchants_staging' : 'merchants_production');

      const algoliaResp = await merchantsAlgoliaIndex.search(searchTerm || '', {
        ...(offset ? { offset } : {}),
        length: PAGE_LENTGH
      });

      results = algoliaResp.hits;

      const formattedResults = results.map(r => {
        const sublabels = [];

        if (r.promoter_count) {
          sublabels.push(`${getPrettyNumber(r.promoter_count)} promoters`);
        }

        if (r.domain) {
          sublabels.push(r.domain);
        }

        return {
          value: r.name,
          label: r.name,
          domain: r.domain,
          subLabel: sublabels.join(' • '),
          isDisabled: !r.domain || allCompetitors.find(competitorBrand => competitorBrand.domain === r.domain) || brand?.domain === r.domain
        };
      });

      setAlgoliaOffset(offset + PAGE_LENTGH);

      if (results.length < PAGE_LENTGH) {
        setSearchHasNextPage(false);
      } else {
        setSearchHasNextPage(true);
      }

      return formattedResults;
    } catch (error) {
      console.error('Error on Algolia Lookup: ', error);
      window.ALERT.error('There was an issue searching for brands. Please try again.');
    } finally {
      setIsLoadingBrands(false);
    }

    return [];
  };

  const getCard = (competitorBrand, { rearrangeHandle }) => {
    return (
      <div className='competitor-brand-list-item'>
        <div className='content-left'>
          {rearrangeHandle}
          <div className='details'>
            <div className='name'>{competitorBrand.name}</div>
            <div className='domain'>{competitorBrand.domain}</div>
          </div>
        </div>
        <div className='content-right'>
          <FontAwesomeIcon onClick={() => handleCompetitorBrandDelete(competitorBrand)} icon={faTimes}></FontAwesomeIcon>
        </div>
      </div>
    );
  };

  const handleCompetitorBrandUpdate = async (competitorBrand, updates) => {
    const res = await props.updateCompetitorBrand(competitorBrand, updates);

    if (res.error) {
      window.ALERT.error('There was an issue updating your similar brand configuration. Please try again.');
    }
  };

  const handleCompetitorBrandAdd = async option => {
    const formattedCompetitorBrand = {
      Brand_id: brand.id,
      name: option.label,
      domain: option.domain,
      sortOrderRank: getGMTTime()
    };

    const res = await props.addCompetitorBrand(formattedCompetitorBrand);

    if (res.error) {
      window.ALERT.error('There was an issue adding your similar brand. Please try again.');
    } else {
      window.ALERT.success('Similar brand added successfully! It may take up to 24 hours to see the changes throughout the UI.');
    }

    setShowMerchantSearch(false);
  };

  const handleCompetitorBrandDelete = async competitorBrand => {
    const res = await props.deleteCompetitorBrand(competitorBrand);

    if (res.error) {
      window.ALERT.error('There was an issue deleting your similar brand. Please try again.');
    }
  };

  const handleManualCompetitorBrandAdd = async inputVals => {
    const { name, domain } = inputVals;

    const cleanName = name.trim();
    const cleanDomain = (getDomainFromUrl(domain) || '').trim();

    if (!cleanName || !cleanDomain) {
      window.ALERT.error('Please provide a name and domain.');
      return false;
    }

    const formattedCompetitorBrand = {
      Brand_id: brand.id,
      name: cleanName,
      domain: cleanDomain,
      sortOrderRank: getGMTTime()
    };

    const res = await props.addCompetitorBrand(formattedCompetitorBrand);

    if (res.error) {
      window.ALERT.error('There was an issue adding your similar brand. Please try again.');
    }

    return !res.error;
  };

  const confirmManualCompetitorBrandAdd = async newOptionName => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Configure Similar Brand'
          subheader='Please provide the following information to configure your custom similar brand.'
          onCancel={onClose}
          customInputFields={[
            { display: 'Name', placeholder: 'Brand', value: 'name', isSingleLine: true, preloaded: newOptionName },
            { display: 'Domain', placeholder: 'brand.com', value: 'domain', isSingleLine: true }
          ]}
          onSubmitAwait={handleManualCompetitorBrandAdd}
          submitMustReturnTrueToClose={true}
        />
      )
    });
  };

  return (
    <div className='competitor-brand-settings-panel-container'>
      <div className='label'>Configure Similar Brands</div>
      <div className='sublabel'>
        Similar brands are used for advanced talent analytics and recommendations. Search to add or click{' '}
        <span onClick={() => confirmManualCompetitorBrandAdd()} className='manual-create'>
          here
        </span>{' '}
        to create manually. Drag and drop to reorder.
      </div>
      {!!competitorBrands.length && (
        <div className='competitor-brand-sort-list-container'>
          <SortableList
            isEditing
            containerClassName='competitor-brand-sortable-list'
            items={competitorBrands}
            updateItem={handleCompetitorBrandUpdate}
            getCard={getCard}
          />
        </div>
      )}
      <div className={cn('add-section-container', { searching: showMerchantSearch, disabled: !canAddMore })} onClick={activateSearch}>
        {showMerchantSearch ? (
          <InfiniteScrollSelect
            className='brand-search-select'
            autoFocus={true}
            openMenuOnFocus={true}
            closeMenuOnSelect={true}
            onSelectOptions={handleCompetitorBrandAdd}
            handleBlur={() => setShowMerchantSearch(false)}
            loadMoreOptions={loadMoreAlgoliaBrands}
            menuHeight={'170px'}
            isLoading={isLoadingBrands}
            placeholder={'Search for a brand'}
            isOptionDisabled={option => !!option.isDisabled}
            onNewOptionClick={confirmManualCompetitorBrandAdd}
          />
        ) : (
          <div className='add-text'>Add Similar Brand</div>
        )}
      </div>
    </div>
  );
};

CompetitorBrandSettingsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateCompetitorBrand,
  addCompetitorBrand,
  deleteCompetitorBrand
})(CompetitorBrandSettingsPanel);
