import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import './ContractUploadsCard.scss';

import ContractUploadDrop from './ContractUploadDrop';
import ContractUpload from './ContractUpload';

import { isBrand } from '../../../Helpers/user_helpers';
import { getRecentContractUploads } from '../../../APIClient/collaborations';

const ContractUploadsCard = props => {
  const { contract, user, canEdit } = props;
  const { uploads, id, status, isCreatedByUser } = contract;
  const Brand_id = contract.brand.id;
  const User_id = contract.user.id;
  const isABrand = isBrand(user);
  const isPending = status === 'pending';
  const isProposed = status === 'proposed';
  const [pastUploads, setPastUploads] = useState([]);
  const [showingPastUploads, setShowingPastUploads] = useState(false);
  const [numVisiblePastUploads, setNumVisiblePastUploads] = useState(2);
  const showMorePastUploads = () => setNumVisiblePastUploads(numVisiblePastUploads + 5);
  const showLessPastUploads = () => setNumVisiblePastUploads(_.max([numVisiblePastUploads - 5, 2]));

  useEffect(() => {
    canEdit &&
      getRecentContractUploads(isABrand ? { Brand_id } : { User_id }).then(resp => {
        resp.uploads && setPastUploads(_.orderBy(resp.uploads, 'createdAt'));
      });
  }, [setPastUploads, Brand_id, canEdit, isABrand, User_id, id]);

  const filteredPastUploads = pastUploads.filter(upload => {
    const isSameContract = upload.Contract_id === contract.id;
    const alreadyHasUpload = !!uploads.find(u => upload.url && u.url === upload.url);
    return !isSameContract && !alreadyHasUpload;
  });

  if (!canEdit && !uploads.length) return null;
  return (
    <>
      <div className='contract-uploads-card card'>
        <div className='card-header-container'>
          <div className='card-header'>Uploads</div>
          {canEdit && (
            <>
              {isCreatedByUser ? (
                isPending ? (
                  <div className='card-subheader'>
                    Upload media kits, past content or other files to share more about you and your content with{' '}
                    {contract.brand?.name || 'this brand'}.
                  </div>
                ) : (
                  <div className='card-subheader'>Upload any files under 15MB.</div>
                )
              ) : (
                <div className='card-subheader'>Upload any files under 15MB</div>
              )}
            </>
          )}
        </div>
        <div className='card-body-container'>
          <div className='contract-uploads'>
            {uploads.map(upload => (
              <ContractUpload
                key={upload.id}
                upload={upload}
                canEdit={canEdit}
                deleteContractUpload={props.deleteContractUpload}
                updateContractUpload={props.updateContractUpload}
                user={user}
                contract={contract}
              />
            ))}
          </div>
          {canEdit && (
            <div className='contract-upload-drop-container'>
              <ContractUploadDrop user={user} contract={contract} createContractUpload={props.createContractUpload} />
            </div>
          )}
          {!isProposed && !!filteredPastUploads.length && (
            <>
              <div onClick={() => setShowingPastUploads(!showingPastUploads)} className='past-container-header'>
                <div className='header'>Select from Previous Uploads</div>
                {<FontAwesomeIcon icon={showingPastUploads ? faChevronUp : faChevronDown} />}
              </div>
              {showingPastUploads && (
                <>
                  <div className='contract-past-uploads'>
                    {filteredPastUploads.slice(0, numVisiblePastUploads).map(upload => (
                      <ContractUpload
                        key={upload.id}
                        isTemplate
                        canEdit={false}
                        upload={upload}
                        user={user}
                        contract={contract}
                        createContractUpload={props.createContractUpload}
                      />
                    ))}
                  </div>
                  {numVisiblePastUploads < filteredPastUploads.length && (
                    <div onClick={showMorePastUploads} className='show-more'>
                      Show More
                    </div>
                  )}
                  {numVisiblePastUploads > 5 && (
                    <div onClick={showLessPastUploads} className='show-less'>
                      Show Less
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

ContractUploadsCard.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,

  // Editing
  canEdit: PropTypes.bool.isRequired,
  createContractUpload: PropTypes.func,
  updateContractUpload: PropTypes.func,
  deleteContractUpload: PropTypes.func
};

export default ContractUploadsCard;
