import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './CustomLogin.scss';

import { registerUser } from '../../Actions/UserActions';
import { getBrandTheme } from '../../Actions/UIActions';
import { getReferringUser as getReferringUserAPI } from '../../APIClient/users';
import { addEvent } from '../../APIClient/events';

import LandingPromotionPanel from '../../Components/Landing/LandingPromotionPanel';

class LandingLogin extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    registerUser: PropTypes.func.isRequired,

    // Optional
    isPublic: PropTypes.bool,
    isReferralPage: PropTypes.bool
  };

  componentDidMount() {
    this.props.isReferralPage
      ? getReferringUserAPI(this.props.match?.params?.username).then(
          resp => {
            addEvent(`Referral - Referral Page Sign Up View`, {
              user: resp?.user?.name
            });
            this.setState({ isLoaded: true, referringUser: resp.user });
          },
          err => this.setState({ isLoaded: true })
        )
      : this.props.getBrandTheme(this.props.match?.params?.username).then(
          resp => {
            addEvent(`Brands - Promotion Sign Up View`, {
              brand: resp?.brand?.name
            });
            this.setState({ isLoaded: true });
          },
          err => this.setState({ isLoaded: true })
        );
  }

  state = {
    isLoaded: false,

    // We don't need to store the referring user on the Redux object
    // because it is only used in this component, therefore we load
    // it using a direct API call and store it in state.
    referringUser: null
  };

  getSeo = () => {
    const { ui, isReferralPage } = this.props;
    const { referringUser } = this.state;
    const brandTheme = getBrandTheme(ui);
    if (isReferralPage) {
      return {
        title: `Join ${referringUser?.name || 'me'} on ShopMy`,
        description: `Promote and connect with the top brands in beauty, fashion and home decor.`,
        image: referringUser?.image
      };
    } else if (brandTheme) {
      return {
        title: `Start promoting ${brandTheme.name || 'products'} on ShopMy`,
        description: `Earn commission and complete paid partnerships with ${brandTheme.name}.`,
        image: brandTheme?.logo
      };
    }
    return null;
  };

  render() {
    const { ui, user, isReferralPage, isPublic } = this.props;
    const { isLoaded, referringUser } = this.state;
    const seo = this.getSeo();
    return (
      <div className={cn('custom-login-outer-container', { loading: !isLoaded, promote: !isReferralPage, referral: isReferralPage })}>
        {seo && (
          <MetaTags>
            <title>{seo.title}</title>
            <meta property='og:title' content={seo.title} />
            <meta property='description' content={seo.description} />
            <meta property='og:description' content={seo.description} />
            {seo.image && <meta property='og:image' content={seo.image} />}
          </MetaTags>
        )}
        <div className={cn('custom-login-inner-container', { loading: !isLoaded })}>
          {isLoaded && (
            <LandingPromotionPanel ui={ui} user={user} referringUser={referringUser} isPublic={isPublic} registerUser={this.props.registerUser} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { ui, user } = state;
  return { ui, user };
};

export default connect(mapStateToProps, {
  getBrandTheme,
  registerUser
})(withRouter(LandingLogin));
