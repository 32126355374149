import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './MentionsFeedAnnouncement.scss';

import { getPrettyNumber } from '../../../Helpers/formatting';
import { isSubscribedToFeature } from '../../../Helpers/subscription_helpers';
import { prepopulateIntercomMessage } from '../../../Helpers/chat_helpers';

import ArrowButton from '../../General/Buttons/ArrowButton';

const MentionsFeedAnnouncement = props => {
  const { user, extra } = props;
  const { summary, filterCounts } = extra;

  const canAccessPanel = isSubscribedToFeature(user, 'SOCIAL_MENTIONS_FEED');

  // Get top talent, starting with active users
  const sortedTalent = _.orderBy(filterCounts?.users, u => (u.tier <= 2 ? u.instagramCount || 0 : (u.instagramCount || 0) * 0.001), 'desc');
  const topTalentName1 = sortedTalent[0]?.name;
  const topTalentName2 = sortedTalent[1]?.name;

  const openChat = () => {
    prepopulateIntercomMessage('Could I speak with someone about upgrading to unlock the Social Mentions Feed?');
  };

  return (
    <div className='mentions-feed-announcement-outer-container'>
      <div className='main-panel'>
        {canAccessPanel ? (
          <>
            <div className='badge'>New Feature Alert</div>
            <div className='title'>
              Introducing the
              <br />
              Social Mentions Feed
            </div>
            <div className='body'>
              We are so excited to announce an incredible new feature that captures all organic social content from Instagram, TikTok, and YouTube,
              letting you easily keep track of the value produced by your brand ambassadors.
              <br />
              <br />
              To get a demo of this new feature and how you can use it to grow your program, please reach out to your account manager.
            </div>
            <div className='continue-btn-container'>
              <ArrowButton displayText='Explore Now' onClick={props.closeModal} />
            </div>
          </>
        ) : (
          <>
            <div className='badge'>New Feature Alert</div>
            <div className='title'>
              Introducing the
              <br />
              Social Mentions Feed
            </div>
            <div className='body'>
              We are so excited to announce an incredible new feature that captures all organic social content from Instagram, TikTok, and YouTube,
              letting you easily keep track of the value produced by your brand ambassadors.
              <br />
              <br />
              To date, we found {getPrettyNumber(summary?.totalMentions)} mentions from top creators like {topTalentName1} and {topTalentName2} across
              social channels, amounting to ${getPrettyNumber(summary?.totalEstimatedMediaValue)} in estimated total media value.
              <br />
              <br />
              This feature is only available to Partner and up brands, but you can still preview the feed. If you're interested in upgrading to unlock
              full access to your custom Social Mentions Feed, email us at brands@shopmy.us or chat with us now.
            </div>
            <div className='continue-btn-container'>
              <ArrowButton displayText='Chat With Us' onClick={openChat} />
              <ArrowButton displayText='View Preview' onlyText onClick={props.closeModal} />
            </div>
          </>
        )}
      </div>
      <div className='secondary-panel'>
        <img src='https://static.shopmy.us/Website/Mentions_Feed_Preview.png' alt='Social Mentions Feed' />
      </div>
    </div>
  );
};

MentionsFeedAnnouncement.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  extra: PropTypes.shape({
    summary: PropTypes.object,
    filterCounts: PropTypes.object
  }).isRequired
};

export default MentionsFeedAnnouncement;
