import _ from 'lodash';

import icons from '../static/icons';
import smsLogo from '../static/images/logos/logo.png';
import blognewsletter from '../static/images/collaboration_type_icons/blognewsletter.png';
import content from '../static/images/collaboration_type_icons/content.png';
import instagram from '../static/images/collaboration_type_icons/instagram.png';
import podcast from '../static/images/collaboration_type_icons/podcast.png';
import snapchat from '../static/images/collaboration_type_icons/snapchat.png';
import tiktok from '../static/images/collaboration_type_icons/tiktok.png';

export const isTask = taskOrTemplate => 'ContractTaskTemplate_id' in taskOrTemplate;
export const isBrandOnlyTemplate = template => ['custom', 'addon', 'stipend'].includes(template?.type);

export const getAllTemplates = collaborations => collaborations?.allTaskTemplates || [];

export const getDisplayForCollaborationType = type =>
  ({
    blognewsletter: 'Blog / Newsletter',
    content: 'General Content',
    instagram: 'Instagram',
    snapchat: 'Snapchat',
    podcast: 'Podcast',
    tiktok: 'TikTok',
    youtube: 'YouTube'
  }[type] ||
  _.startCase(type) ||
  'Custom');

export const getRankingForCollaborationType = type => {
  const typeOrder = ['instagram', 'youtube', 'tiktok', 'snapchat', 'podcast', 'blognewsletter', 'content'];
  const order = typeOrder.indexOf(type);
  return order >= 0 ? order : typeOrder.length;
};

export const getImageOptionsForCollaborationType = type =>
  ({
    instagram: [
      'https://static.shopmy.us/Collaborations/Instagram_2.png',
      'https://static.shopmy.us/Collaborations/Instagram_1.png',
      'https://static.shopmy.us/Collaborations/Instagram_3.png',
      'https://static.shopmy.us/Collaborations/Instagram_4.png',
      'https://static.shopmy.us/Collaborations/Instagram_5.png',
      'https://static.shopmy.us/Collaborations/Instagram_6.png',
      'https://static.shopmy.us/Collaborations/Instagram_7.png',
      'https://static.shopmy.us/Collaborations/Instagram_8.png',
      'https://static.shopmy.us/Collaborations/Instagram_9.png'
    ],
    tiktok: [
      'https://static.shopmy.us/Collaborations/Tiktok_2.png',
      'https://static.shopmy.us/Collaborations/Tiktok_1.png',
      'https://static.shopmy.us/Collaborations/Tiktok_3.png',
      'https://static.shopmy.us/Collaborations/Tiktok_4.png',
      'https://static.shopmy.us/Collaborations/Tiktok_5.png',
      'https://static.shopmy.us/Collaborations/Tiktok_6.png',
      'https://static.shopmy.us/Collaborations/Tiktok_7.png'
    ],
    blognewsletter: [
      'https://static.shopmy.us/Collaborations/Blog_2.png',
      'https://static.shopmy.us/Collaborations/Blog_1.png',
      'https://static.shopmy.us/Collaborations/Blog_3.png',
      'https://static.shopmy.us/Collaborations/Blog_4.png',
      'https://static.shopmy.us/Collaborations/Blog_5.png',
      'https://static.shopmy.us/Collaborations/Blog_6.png'
    ],
    content: [
      'https://static.shopmy.us/Collaborations/Content_2.png',
      'https://static.shopmy.us/Collaborations/Content_1.png',
      'https://static.shopmy.us/Collaborations/Content_3.png',
      'https://static.shopmy.us/Collaborations/Content_4.png',
      'https://static.shopmy.us/Collaborations/Content_5.png',
      'https://static.shopmy.us/Collaborations/Content_6.png'
    ],
    snapchat: [
      'https://static.shopmy.us/Collaborations/Snapchat_2.png',
      'https://static.shopmy.us/Collaborations/Snapchat_1.png',
      'https://static.shopmy.us/Collaborations/Snapchat_3.png',
      'https://static.shopmy.us/Collaborations/Snapchat_4.png',
      'https://static.shopmy.us/Collaborations/Snapchat_5.png',
      'https://static.shopmy.us/Collaborations/Snapchat_6.png',
      'https://static.shopmy.us/Collaborations/Snapchat_7.png'
    ],
    podcast: [
      'https://static.shopmy.us/Collaborations/Podcast_2.png',
      'https://static.shopmy.us/Collaborations/Podcast_1.png',
      'https://static.shopmy.us/Collaborations/Podcast_3.png',
      'https://static.shopmy.us/Collaborations/Podcast_4.png',
      'https://static.shopmy.us/Collaborations/Podcast_5.png',
      'https://static.shopmy.us/Collaborations/Podcast_6.png'
    ],
    youtube: [
      // 'https://static.shopmy.us/Collaborations/Youtube_2.png',
      // 'https://static.shopmy.us/Collaborations/Youtube_1.png',
      // 'https://static.shopmy.us/Collaborations/Youtube_3.png',
      'https://static.shopmy.us/Collaborations/Youtube_4.png'
      // 'https://static.shopmy.us/Collaborations/Youtube_5.png',
      // 'https://static.shopmy.us/Collaborations/Youtube_6.png',
      // 'https://static.shopmy.us/Collaborations/Youtube_7.png'
    ]
  }[type] || []);

export const getCollaborationTypeIcon = type =>
  ({
    blognewsletter,
    content,
    instagram,
    podcast,
    snapchat,
    tiktok,
    youtube: icons.social.youtube.primary
  }[type] || smsLogo);

export const getNumStarsForAcceptConfidence = confidence => {
  if (confidence >= 0.8) return 5;
  else if (confidence >= 0.7) return 4.5;
  else if (confidence >= 0.6) return 4;
  else if (confidence >= 0.5) return 3.5;
  else if (confidence >= 0.4) return 3;
  else if (confidence >= 0.3) return 2.5;
  else if (confidence >= 0.2) return 2;
  else if (confidence >= 0.1) return 1.5;
  else if (confidence >= 0.05) return 1;
  else return 0.5;
};
