import React from 'react';
import PropTypes from 'prop-types';
import './ArtistModalPanelEmpty.scss';

const ArtistModalPanelEmpty = props => {
  const { message } = props;
  return (
    <div className='artist-modal-panel-outer'>
      <div className='artist-modal-panel-inner'>
        <div>{message || 'Need to define a message'}</div>
      </div>
    </div>
  );
};

ArtistModalPanelEmpty.propTypes = {
  message: PropTypes.string.isRequired
};

export default ArtistModalPanelEmpty;
