import React, { useRef, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaperclip, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import './ChatOverlay.scss';

import { getTalent } from '../../APIClient/talent';
import { getChatFromIds, getChat } from '../../APIClient/chats';
import {
  toggleBodyScrollLock,
  openChatOverlay,
  closeChatOverlay,
  openArtistModal,
  openFulfillmentModal,
  openCodesModal,
  openRequestModal
} from '../../Actions/UIActions';
import { createSamplesRequest, setCustomCode, updateRequest } from '../../Actions/AnalyticsActions';
import { receivedMessage, ADD_MESSAGE, EDIT_MESSAGE, DELETE_MESSAGE, TYPING_MESSAGE } from '../../Actions/ChatActions';

import { getBrand, isSubscribedBrand } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { isPromoterForOutreachPurposes } from '../../Helpers/talent_helpers';
import { isImageUrl, isPDFUrl } from '../../Helpers/formatting';
import { matchScrollHeight } from '../../Helpers/helpers';

import ChatMessage from './ChatMessage';
import ChatConnect from './ChatConnect';
import DropUploader from '../Uploader/DropUploader';
import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';
import UploadImage from '../General/UploadImage';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

const ANIMATION_DURATION = 500; // Keep this in sync with SCSS

let global_messages = [],
  active_user_id = null; // For some reason need to hold them here...

const ChatOverlay = props => {
  const { user, ui, analytics, toggleBodyScrollLock } = props;
  const { activeChatOverlayTalent } = ui || {};
  const { id, user_image, image, name } = activeChatOverlayTalent || {};
  const brand = getBrand(user);
  const Brand_id = brand?.id;

  const ws = useRef(null);
  const messagesAreaEl = useRef(null);
  const [talent, setTalent] = useState(false);
  const [chat, setChat] = useState(false);
  const [messages, setMessages] = useState([]);
  const [hasNoChat, setHasNoChat] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [curMessage, setCurMessage] = useState('');
  const [sentTyping, setSentTyping] = useState(false);
  const [shouldShowTypingIndicator, setShouldShowTypingIndicator] = useState(false);

  // Handle Scrolling Behavior
  const { length } = messages || {};

  const scrollToBottom = () => {
    if (messagesAreaEl.current) {
      const offset = messagesAreaEl.current.scrollHeight - messagesAreaEl.current.offsetHeight;
      messagesAreaEl.current.scrollTo(0, offset); //, { behavior: 'smooth' });
    }
  };

  useEffect(() => {
    length > 4 && scrollToBottom();
  }, [length, isLoadingChat]);

  useEffect(() => {
    if (shouldShowTypingIndicator) {
      scrollToBottom();
    }
  }, [shouldShowTypingIndicator]);

  /*****************************************************************************
   ************************************ Data ***********************************
   *****************************************************************************/

  useEffect(() => {
    if (id) {
      active_user_id = id;
      toggleBodyScrollLock(true);
      setIsVisible(true);

      // Get chat object
      const chatPromise = new Promise(async (resolve, reject) => {
        const chatFromIdsResp = await getChatFromIds({ Brand_id, User_id: id });
        const { Chat_id } = chatFromIdsResp;
        if (!active_user_id) return resolve(); // In case closing between response
        if (Chat_id) {
          const resp = await getChat(Chat_id);

          if (!active_user_id) return resolve(); // In case closing between response
          const orderedMessages = _.orderBy(resp.messages, 'createdAt', 'asc');

          setTimeout(() => {
            setChat(resp.chat);
            setMessages(orderedMessages);
            global_messages = orderedMessages;
            resolve();
          }, ANIMATION_DURATION / 2); // Slight delay to make fades less choppy
        } else {
          setHasNoChat(true);
          resolve();
        }
      });

      // Get talent object (if we want to use it)
      const talentPromise = new Promise((resolve, reject) => {
        getTalent(Brand_id, { User_id: id })
          .then(resp => setTalent(_.first(resp.talent)))
          .catch(reject)
          .finally(resolve);
      });

      setIsLoadingChat(true);
      Promise.all([chatPromise, talentPromise])
        .then(resp => setIsLoadingChat(false))
        .catch(error => {
          cogoToast.error(`Error fetching chat, please try again.`);
          closePanel();
        });
    } else {
      setHasNoChat(false);
      setChat(null);
      setTalent(null);
      setCurMessage('');
      setIsLoadingChat(false);
      toggleBodyScrollLock(false);
      setIsVisible(false);
      setMessages([]);
      global_messages = [];
      active_user_id = null;
    }
  }, [id]);

  /*****************************************************************************
   ******************************* UI Handling *********************************
   *****************************************************************************/
  const closePanel = () => {
    setIsVisible(false);
    setTimeout(() => {
      props.closeChatOverlay();
    }, ANIMATION_DURATION);
  };

  const viewTalentCard = () => {
    closePanel();
    props.openArtistModal(talent);
  };

  /*****************************************************************************
   ******************************* Sending Messages ****************************
   *****************************************************************************/
  const handleIsTyping = stagedMessage => {
    if (stagedMessage && !sentTyping) {
      setSentTyping(true);
      const typingData = {
        isTyping: true,
        ...(!!Brand_id ? { Brand_id } : { User_id: id })
      };
      typingMessage(typingData);
    } else if (!stagedMessage && sentTyping) {
      setSentTyping(false);
      typingMessage({ isTyping: false });
    }
  };

  const getChatTextArea = () => {
    return (
      <div onClick={clickToTryChatting} className='input-container'>
        <textarea
          autoFocus
          rows={1}
          disabled={!canChat}
          ref={ref => matchScrollHeight(ref)}
          placeholder={uploadProgress ? `Uploading (${uploadProgress.toFixed(0)}%)...` : `Write a message to ${name}`}
          onChange={({ target }) => {
            setCurMessage(target.value);
            handleIsTyping(target.value);
          }}
          onKeyPress={e => e.key === 'Enter' && e.ctrlKey && attemptSend()} /* For debugging */
          value={curMessage}
        />
      </div>
    );
  };

  const attemptSend = e => {
    e && e.preventDefault();

    if (curMessage.length) {
      sendMessage(curMessage);
      setCurMessage('');
      // handle socket typing updates
      if (!!typingMessage) typingMessage({ isTyping: false });
      setSentTyping(false);
    }

    if (filesToUpload.length) {
      filesToUpload.forEach((file, idx) => {
        setTimeout(() => {
          sendMessage(file);
        }, idx * 100 + 100); // stagger the messages so they don't all send at once
      });
      setFilesToUpload([]);
    }
  };

  const sendMessage = msg => {
    ws.current.send(
      JSON.stringify({
        event: ADD_MESSAGE,
        message: msg,
        User_id: id,
        Brand_id
      })
    );
  };

  const editMessage = (msg, newMsg) => {
    ws.current.send(
      JSON.stringify({
        event: EDIT_MESSAGE,
        message: msg,
        newMessage: newMsg,
        User_id: id,
        Brand_id
      })
    );
  };

  const deleteMessage = msg => {
    ws.current.send(
      JSON.stringify({
        event: DELETE_MESSAGE,
        message: msg,
        User_id: id,
        Brand_id
      })
    );
  };

  const typingMessage = typingData => {
    ws.current.send(
      JSON.stringify({
        event: TYPING_MESSAGE,
        User_id: id,
        Brand_id,
        typingData
      })
    );
  };

  const extraClasses = { visible: isVisible };
  const brandCode = chat ? chat.user.codes?.find(code => code.Brand_id === chat.Brand_id) : null;

  const onMessage = resp => {
    // Ensure it's from the right person
    const chatFromUserId = resp.chat?.User_id;
    if (active_user_id !== chatFromUserId) return;

    // Update Chat
    if (!chat) {
      setChat(resp.chat);
      setHasNoChat(false);
    }

    // Update Messages, add to end
    const newMessages = _.concat(global_messages, resp.message);
    setMessages(newMessages);
    global_messages = newMessages; // To simulate redux
    window.newFilesToUpload = null; // To make multi-upload work, due to scoping
  };

  const onEditMessage = resp => {
    // Ensure we have an update for the active chat
    if (!resp?.message) return;
    if (active_user_id !== resp?.message?.User_id || Brand_id !== resp?.message?.Brand_id) return;

    // Update Messages
    const replaceIdx = _.findIndex(global_messages, { id: resp.message.id });
    const newMessages = [...global_messages];
    newMessages[replaceIdx] = resp.message;
    setMessages(newMessages);
    global_messages = newMessages; // To simulate redux
  };

  const onDeleteMessage = resp => {
    // Ensure we have an update for the active chat
    if (!resp?.message) return;
    if (active_user_id !== resp?.message?.User_id || Brand_id !== resp?.message?.Brand_id) return;

    // Update Messages
    const newMessages = global_messages.filter(message => message.id !== resp.message.id);
    setMessages(newMessages);
    global_messages = newMessages; // To simulate redux
  };

  const onTypingMessage = resp => {
    if (active_user_id !== resp?.User_id || Brand_id !== resp?.Brand_id) return;

    const showTypingIndicator =
      resp?.typingData?.isTyping && (!!Brand_id ? resp?.typingData?.User_id === id : resp?.typingData?.Brand_id === Brand_id);

    setShouldShowTypingIndicator(showTypingIndicator);
  };

  /******************************************************************************
   ****************************** Enabled Status ********************************
   ******************************************************************************/
  const userIsPromoter = isPromoterForOutreachPurposes(talent);
  const userHasSentMessage = !!messages?.find(m => m.isUserMessage);
  const canChatWithPromoters = !!talent && isSubscribedToFeature(user, 'CHAT_WITH_PROMOTERS');
  const canChatNoMatterWhat = !!talent && isSubscribedToFeature(user, userIsPromoter ? 'CHAT_WITH_PROMOTERS' : 'UNLIMITED_CHAT');
  const canChat = (userHasSentMessage && isSubscribedBrand(user)) || canChatNoMatterWhat;
  const canGift = isSubscribedToFeature(user, 'GIFTING');
  const canCode = isSubscribedToFeature(user, 'CUSTOM_CODES');
  const cannotChatErrorMsg = !isSubscribedBrand(user)
    ? `Your subscription is currently inactive, please reactivate it on the Invoices & Subscriptions tab`
    : canChatWithPromoters
    ? `You cannot send general chat messages with non promoters with your current subscription, please upgrade to the Communications package to chat with all talent.`
    : `You cannot send general chat messages with your current subscription, please upgrade your package to leverage this feature.`;
  const clickToTryChatting = () => {
    if (userHasSentMessage) return;
    userIsPromoter ? blockOnRequiringSubscription(user, 'CHAT_WITH_PROMOTERS') : blockOnRequiringSubscription(user, 'UNLIMITED_CHAT');
  };

  /******************************************************************************
   ****************************** Gifting Request *******************************
   ******************************************************************************/
  const requestGifting = () => {
    if (blockOnRequiringSubscription(user, 'GIFTING')) return;
    props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests([talent])
      }
    });
  };

  /******************************************************************************
   ******************************** Codes ***************************************
   ******************************************************************************/
  const offerCode = () => {
    if (blockOnRequiringSubscription(user, 'CUSTOM_CODES')) return;
    props.openCodesModal({
      params: {
        User_id: talent.id,
        name: talent.name,
        showTemplates: true
      }
    });
  };

  /******************************************************************************
   ***************************** File Uploading *********************************
   ******************************************************************************/
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const onFileDrop = (acceptedFiles, rejectedFiles) => {
    setIsUploading(true);
    rejectedFiles.length && window.ALERT.error('Only PNG, JPG, JPEG and Gif files are supported.');
    window.totalFilesToUploadCount = acceptedFiles.length + filesToUpload.length;
  };
  const handlePreprocessFileDrop = (file, next) => {
    setIsUploading(true);
    next(file);
  };
  const onUploadFileProgress = progress => {
    setUploadProgress(progress);
    progress < 100 && setIsUploading(true); // In case of multi-upload
  };
  const completeFileUpload = async newImage => {
    const newFilesToUpload = [...(window.newFilesToUpload || filesToUpload), newImage];
    setFilesToUpload(newFilesToUpload);
    setIsUploading(false);
    setUploadProgress(0);
    window.newFilesToUpload = newFilesToUpload.length === window.totalFilesToUploadCount ? null : newFilesToUpload; // To make multi-upload work, due to scoping
  };
  useEffect(() => {
    setFilesToUpload([]);
    window.newFilesToUpload = null;
  }, [id]);

  const hasContent = curMessage.length > 0 || filesToUpload.length > 0;
  const canSend = hasContent && canChat;
  return (
    <div className={cn('chat-overlay-outer-container', extraClasses)}>
      {talent && (
        <ChatConnect
          ui={ui}
          user={user}
          setWebsocket={initializedWS => (ws.current = initializedWS)}
          onConnect={data => setIsConnected(true)}
          onFailedAttempt={data => setIsConnected(false)}
          onFail={data => setIsFailure(true)}
          receivedMessage={props.receivedMessage}
          onEditMessage={onEditMessage}
          onDeleteMessage={onDeleteMessage}
          onMessage={data => onMessage(data)}
          onTypingMessage={onTypingMessage}
        />
      )}

      <div onClick={closePanel} className={cn('background-fade', extraClasses)} />
      <div className={cn('chat-overlay-inner-container', extraClasses)}>
        {
          <DropUploader className='drop-form-wrapper' onDrop={onFileDrop} onProgress={onUploadFileProgress} onUpload={completeFileUpload}>
            <>
              <div className='header'>
                <div className='talent'>
                  <div className='image-container'>
                    {image || user_image ? <img src={image || user_image} alt={name} /> : <div className='empty' />}
                  </div>
                  <div className='data'>
                    <div className='name'>{activeChatOverlayTalent?.name}</div>
                    <div onClick={viewTalentCard} className='view-talent-card'>
                      View Talent Card
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </div>
                </div>
                <div className='actions'>
                  <div onClick={closePanel} className='action'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </div>
              <div className='body'>
                {isLoadingChat ? (
                  <div className='loading-chat'>
                    <Loader size={100} />
                  </div>
                ) : hasNoChat ? (
                  <div className='no-available-chat'>
                    <div className='actions'>
                      <div onClick={requestGifting} className={cn('action', { disabled: !canGift })}>
                        Send Gifting
                      </div>
                      <div onClick={offerCode} className={cn('action', { disabled: !canCode })}>
                        Set Custom Code
                      </div>
                    </div>
                  </div>
                ) : (
                  <div ref={messagesAreaEl} className='chat-messages'>
                    {_.uniqBy(messages, 'id').map((msg, idx) => {
                      const prevMsg = messages[idx - 1];
                      const showTimestamp =
                        !prevMsg ||
                        moment(msg.createdAt)
                          .local()
                          .diff(moment(prevMsg.createdAt).local(), 'hours') > 24;

                      // const clickToViewProfile = () => viewProfile(activeChat.user);
                      return (
                        <Fragment key={msg.id}>
                          {showTimestamp && (
                            <div className='message-timestamp'>
                              {moment(msg.createdAt)
                                .local()
                                .format('dddd, MMMM Do')}
                            </div>
                          )}
                          <ChatMessage
                            ui={ui}
                            user={user}
                            analytics={analytics}
                            manager={props.manager}
                            history={props.history}
                            chat={chat}
                            message={msg}
                            brandCode={brandCode}
                            openFulfillmentModal={(...args) => {
                              props.openFulfillmentModal(...args);
                              closePanel();
                            }}
                            acceptGiftingFromBrand={() => {}} // Don't need it because only for brands
                            requestGiftingToUser={requestGifting}
                            offerCodeToUser={offerCode}
                            editMessage={editMessage}
                            deleteMessage={deleteMessage}
                          />
                        </Fragment>
                      );
                    })}
                    {!!chat && (
                      <div className={cn('chat-typing-wrapper', { active: !!shouldShowTypingIndicator })}>
                        <ChatMessage
                          message={{
                            isUserMessage: !!Brand_id,
                            message: ''
                          }}
                          isTypingIndicator={true}
                          chat={chat}
                          editMessage={() => {}}
                          deleteMessage={() => {}}
                          requestGiftingToUser={() => {}}
                          acceptGiftingFromBrand={() => {}}
                          openFulfillmentModal={() => {}}
                          manager={props.manager}
                          user={user}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='footer'>
                <form onSubmit={attemptSend}>
                  <UploadImage
                    basicContent={
                      <div className='upload-icn'>
                        <FontAwesomeIcon icon={faPaperclip} />
                      </div>
                    }
                    basicUploader
                    onUploadProgress={onUploadFileProgress}
                    completeUpload={completeFileUpload}
                    handlePreprocess={handlePreprocessFileDrop}
                  />
                  {canChat ? (
                    getChatTextArea()
                  ) : (
                    <Tooltip outerClassName='disabled-field' message={!canChat ? cannotChatErrorMsg : null} getIconDiv={getChatTextArea} />
                  )}
                  {filesToUpload.length > 0 && (
                    <div className='uploaded-files'>
                      {filesToUpload.map((url, i) => {
                        const remove = e => {
                          e.stopPropagation();
                          setFilesToUpload(filesToUpload.filter(f => f !== url));
                        };
                        const view = () => window.open(url, '_blank');

                        const isImage = isImageUrl(url);
                        const isPDF = isPDFUrl(url);
                        return (
                          <div onClick={view} key={url} className='file'>
                            {isImage ? <img src={url} alt='uploaded' /> : <div className='file-label'>{isPDF ? 'PDF' : 'File'}</div>}
                            <div onClick={remove} className='remove'>
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {canSend && (
                    <div onClick={attemptSend} className='submit-btn'>
                      SEND
                    </div>
                  )}
                </form>
                {isConnected ? (
                  <div className='status connected'>Connected.</div>
                ) : isFailure ? (
                  <div className='status failure'>Failure Connecting, please refresh.</div>
                ) : (
                  <div className='status connecting'>Connecting</div>
                )}
              </div>
            </>
          </DropUploader>
        }
      </div>
    </div>
  );
};

ChatOverlay.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  closeChatOverlay: PropTypes.func.isRequired,
  toggleBodyScrollLock: PropTypes.func.isRequired,
  receivedMessage: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics, ui, manager } = state;
  return { user, analytics, ui, manager };
};

export default connect(mapStateToProps, {
  toggleBodyScrollLock,
  openChatOverlay,
  closeChatOverlay,
  openArtistModal,
  openCodesModal,
  openRequestModal,
  openFulfillmentModal,
  receivedMessage,
  createSamplesRequest,
  setCustomCode,
  updateRequest
})(withRouter(ChatOverlay));
