import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import './HomeHeaderBrands.scss';

import { openAuthModal } from '../../../Actions/UIActions';

const HomeHeaderBrands = props => {
  const getDemo = () => {
    props.openAuthModal('apply-brands');
  };

  const header = 'Everything you need to build a world-class creator marketing program.';
  const seo_header = 'ShopMy | World Class Creator Marketing';
  const seo_description = 'Everything you need to build a world class creator marketing program.';

  return (
    <div className='home-header-brands-outer-container'>
      <MetaTags>
        <title>{seo_header}</title>
        <meta property='og:title' content={seo_header} />
        <meta property='description' content={seo_description} />
        <meta property='og:description' content={seo_description} />
      </MetaTags>
      <div className='home-header-brands-inner-container'>
        <div className='main-container'>
          <h1 className='title'>{header}</h1>
          <div className='actions'>
            <div onClick={getDemo} className='action primary'>
              Get a Demo
            </div>
            <Link to='/home/creators' className='action secondary'>
              I am a creator
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeHeaderBrands.propTypes = {
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(HomeHeaderBrands);
