import React from 'react';
import PropTypes from 'prop-types';
import './AcceptLookbookInviteModal.scss';

import Modal from '../../General/Modal';

import { sendSamplesRequest } from '../../../APIClient/brands';
import { getUserId, getName } from '../../../Helpers/user_helpers';
import { getFirstName } from '../../../Helpers/formatting';

const AcceptLookbookInviteModal = props => {
  const { user, lookbook } = props;
  const { brand } = lookbook;

  const close = () => window.ALERT.warn('Please accept the invite to view this lookbook');
  const dismiss = () => (window.location.href = '/');

  // to get here there MUST be an invite hash
  const queryParams = new URLSearchParams(window.location.search);
  const LookbookInvite_hash = queryParams.get('invite');

  const [isAccepting, setIsAccepting] = React.useState(false);
  const accept = async () => {
    if (isAccepting) return;
    setIsAccepting(true);
    try {
      await sendSamplesRequest(
        brand.id,
        { id: getUserId(user) },
        {
          Lookbook_id: lookbook.id,
          LookbookInvite_hash
        }
      );
      await props.syncCurrentUser();
    } catch (error) {
      window.ALERT.error('There was an error accepting the invite');
    }
    setIsAccepting(true);
  };

  return (
    <Modal
      visible
      className='accept-lookbook-invite-modal-outer-container'
      innerClassName='accept-lookbook-invite-modal-inner-container'
      contentClassName='accept-lookbook-invite-modal'
      close={close}
    >
      <div className='invite-header'>
        <h1 className='header'>Hi {getFirstName(getName(user))},</h1>
        <h1 className='subheader'>Looks like you already have a ShopMy account! Click "Accept" below to add + view this gifting request.</h1>
      </div>
      <div className='actions'>
        <div onClick={accept} className='action primary'>
          {isAccepting ? 'Accepting...' : 'Accept Request'}
        </div>
        <div onClick={dismiss} className='action secondary'>
          Dismiss
        </div>
      </div>
    </Modal>
  );
};

AcceptLookbookInviteModal.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  syncCurrentUser: PropTypes.func.isRequired
};

export default AcceptLookbookInviteModal;
