import { getSignedUrl } from '../APIClient/uploads';

export const getThumbnailImageFromVideoUrl = videoUrl => {
  const parts = videoUrl.split('.');
  const base = parts.slice(0, parts.length - 1).join('.');
  return {
    fullPath: base + '_thumbnail.jpeg',
    fileOnly: base.split('.com/')[1] + '_thumbnail.jpeg'
  };
};

export const isVideoString = url => {
  return url && (url.includes('.mov') || url.includes('.MOV') || url.includes('.mp4') || url.includes('.wmv'));
};

export const uploadVideoContentsToS3 = async (videoEl, fileName) => {
  const w = videoEl.videoWidth * 0.4;
  const h = videoEl.videoHeight * 0.4;
  const canvas = document.createElement('canvas');
  canvas.width = w;
  canvas.height = h;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(videoEl, 0, 0, w, h);
  const data = canvas.toDataURL('image/jpeg');
  const binary = atob(data.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  const blobData = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  const signedUrl = await getSignedUrl({
    objectName: fileName,
    skipUuid: true,
    contentType: 'image/jpeg'
  });
  await fetch(signedUrl, {
    method: 'PUT',
    body: blobData
  });
};
