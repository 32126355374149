import {
  deleteLookbook as deleteLookbookAPI,
  createLookbookOrder as createLookbookOrderAPI,
  resyncBrandLookbooks as resyncBrandLookbooksAPI
} from '../APIClient/lookbooks';
import { getBrandId } from '../Helpers/user_helpers';

export const DELETE_LOOKBOOK = 'DELETE_LOOKBOOK';
export const DELETE_LOOKBOOK_SUCCESS = 'DELETE_LOOKBOOK_SUCCESS';
export const DELETE_LOOKBOOK_ERROR = 'DELETE_LOOKBOOK_ERROR';

export const UPDATE_LOOKBOOK_CART = 'UPDATE_LOOKBOOK_CART';

export const CREATE_LOOKBOOK_ORDER_SUCCESS = 'CREATE_LOOKBOOK_ORDER_SUCCESS';
export const CREATE_LOOKBOOK_ORDER_ERROR = 'CREATE_LOOKBOOK_ORDER_ERROR';

export const RESYNC_BRAND_LOOKBOOKS = 'RESYNC_BRAND_LOOKBOOKS';

export const resyncBrandLookbooks = () => async (dispatch, getState) => {
  try {
    const Brand_id = getBrandId(getState().user);
    const resp = await resyncBrandLookbooksAPI(Brand_id);
    const lookbooks = resp.lookbooks;

    return dispatch({ type: RESYNC_BRAND_LOOKBOOKS, lookbooks });
  } catch (e) {
    console.error(e);
  }
};

export const deleteLookbook = lookbook => async (dispatch, getState) => {
  dispatch({ type: DELETE_LOOKBOOK, lookbook });
  try {
    await deleteLookbookAPI(lookbook);
    return dispatch({ type: DELETE_LOOKBOOK_SUCCESS, lookbook });
  } catch (e) {
    return dispatch({ type: DELETE_LOOKBOOK_ERROR, lookbook });
  }
};

export const updateLookbookCart = (lookbook, sibling, quantity) => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_LOOKBOOK_CART,
    lookbook,
    sibling,
    quantity
  });
};

export const createLookbookOrder = order => async (dispatch, getState) => {
  try {
    const resp = await createLookbookOrderAPI(order);
    return dispatch({
      type: CREATE_LOOKBOOK_ORDER_SUCCESS,
      order: resp.LookbookOrder
    });
  } catch (errorMsg) {
    return dispatch({
      type: CREATE_LOOKBOOK_ORDER_ERROR,
      errorMsg
    });
  }
};
