import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunityHeaderTitle.scss';

const OpportunityHeaderTitle = props => {
  const { opportunity, showLoading } = props;
  const { title } = opportunity || {};

  // When done loading, set the title
  const [hasLoaded, setHasLoaded] = React.useState(false);
  useEffect(() => {
    if (opportunity && !hasLoaded) {
      setHasLoaded(true);
    }
  }, [opportunity]);

  return (
    <div className={cn('opportunity-header-title-container', { empty: !title })}>
      {showLoading || !opportunity ? (
        <div className='title empty'>Loading Title...</div>
      ) : (
        <div className='title loaded'>{title || 'New Opportunity'}</div>
      )}
    </div>
  );
};

OpportunityHeaderTitle.propTypes = {
  opportunity: PropTypes.object,
  showLoading: PropTypes.bool
};

export default OpportunityHeaderTitle;
