import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { getPrettyScoreDisplay } from '../../Helpers/formatting';
import './ReportSectionCard.scss';
import ProgressCircle from '../General/ProgressCircle';
import ReportSectionScoreComponent from './ReportSectionScoreComponent';

const SECTIONS_ALL = 'SECTIONS_ALL';

const ReportSectionCard = props => {
  const { sectionData, chartData, sectionCompareData } = props;

  const { title, components, subsections } = sectionData;

  const [expandedSections, setExpandedSections] = useState([]);

  const sectionComponents = subsections || [{ key: SECTIONS_ALL, components }];
  const compareSectionComponents = sectionCompareData
    ? sectionCompareData.subsections || [{ key: SECTIONS_ALL, components: sectionCompareData.components }]
    : null;
  const compareSectionComponentsMap = _.keyBy(compareSectionComponents, 'key');

  const getSectionProgressPercent = () => {
    let points = 0;
    let maxPoints = 0;

    sectionComponents.forEach(subsection => {
      const { components } = subsection;
      points += components.reduce((acc, c) => acc + (c.points ?? 0), 0);
      maxPoints += components.reduce((acc, c) => acc + c.max, 0);
    });

    const sectionProgress = Math.round((getPrettyScoreDisplay(points) / maxPoints) * 100);
    let compareSectionProgress = null;

    if (compareSectionComponents) {
      let comparePoints = 0;
      let compareMaxPoints = 0;

      compareSectionComponents.forEach(subsection => {
        const { components } = subsection;
        comparePoints += components.reduce((acc, c) => acc + (c.points ?? 0), 0);
        compareMaxPoints += components.reduce((acc, c) => acc + c.max, 0);
      });

      compareSectionProgress = Math.round((getPrettyScoreDisplay(comparePoints) / compareMaxPoints) * 100);
    }

    return [sectionProgress, getPrettyScoreDisplay(points), maxPoints, compareSectionProgress];
  };

  const handleExpandSection = subsectionKey => {
    const isExpanded = expandedSections.includes(subsectionKey);

    if (isExpanded) {
      setExpandedSections(expandedSections.filter(s => s !== subsectionKey));
    } else {
      setExpandedSections(prevExpandedSections => prevExpandedSections.concat(subsectionKey));
    }
  };

  const [progressPercent, sectionPoints, sectionMax, compareProgressPercent] = getSectionProgressPercent();

  return (
    <div className='report-section-card'>
      <div className='header-container'>
        <div className='header-info-left'>
          <div className='title'>{title}</div>
          <div className='score meta'>
            <span>
              {sectionPoints}/{sectionMax}
            </span>
            points
          </div>
        </div>
        <div
          className={cn('section-score', {
            'compare-better': !_.isNil(compareProgressPercent) && compareProgressPercent > progressPercent,
            'compare-worse': !_.isNil(compareProgressPercent) && compareProgressPercent < progressPercent
          })}
        >
          <span>{progressPercent}%</span>
          <ProgressCircle progress={progressPercent} secondaryProgress={compareProgressPercent} radius={30} stroke={10} shouldDraw={true} />
        </div>
      </div>
      <div className='sections-container'>
        {sectionComponents.map(subsection => {
          const isExpanded = expandedSections.includes(subsection.key) || subsection.key === SECTIONS_ALL;
          const subsectionMax = subsection.components.reduce((acc, c) => acc + c.max, 0);
          const subsectionPoints = subsection.components.reduce((acc, c) => acc + c.points, 0);
          const subsectionProgress = (100 * subsectionPoints) / subsectionMax;
          const compareSubsectionPoints = compareSectionComponentsMap?.[subsection.key]?.components?.reduce((acc, c) => acc + c.points, 0);
          const compareSubsectionMax = compareSectionComponentsMap?.[subsection.key]?.components?.reduce((acc, c) => acc + c.max, 0);
          const compareSubsectionProgress = !_.isNil(compareSubsectionPoints) ? (100 * compareSubsectionPoints) / compareSubsectionMax : null;
          const improved = compareSubsectionProgress && subsectionProgress > compareSubsectionProgress;
          const declined = compareSubsectionProgress && subsectionProgress < compareSubsectionProgress;

          return (
            <div
              className={cn('subsection-container', { expanded: isExpanded, isOnlySection: subsection.key === SECTIONS_ALL })}
              key={subsection.key}
              onClick={() => !isExpanded && handleExpandSection(subsection.key)}
            >
              {subsection.key !== SECTIONS_ALL && (
                <div className='subsection-header' onClick={() => handleExpandSection(subsection.key)}>
                  <div className='subsection-title'>{subsection.title}</div>
                  <div className='expand-btn'>
                    <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
                  </div>
                </div>
              )}
              {isExpanded && (
                <div className='components-container'>
                  {subsection.components.map(component => (
                    <ReportSectionScoreComponent
                      key={component.key}
                      component={component}
                      componentChartData={chartData?.[component.key] || []}
                      compareComponent={compareSectionComponentsMap?.[subsection.key]?.components?.find(c => c.key === component.key)}
                    />
                  ))}
                </div>
              )}
              {!isExpanded && (
                <div className='subsection-score-bar-container'>
                  <div
                    className={cn('subsection-score-bar', { empty: subsectionPoints === 0, improved, declined })}
                    style={{ width: subsectionPoints === 0 ? '12px' : `${subsectionProgress}%` }}
                  />
                  {compareSubsectionProgress > 0 && (
                    <div className={cn('subsection-score-bar compare', { improved })} style={{ width: `${compareSubsectionProgress}%` }} />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ReportSectionCard.propTypes = {
  sectionData: PropTypes.object.isRequired,
  sectionCompareData: PropTypes.object,
  chartData: PropTypes.object
};

export default ReportSectionCard;
