import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import './ContractProgress.scss';

const ContractProgress = props => {
  const { selectStep, steps } = props;
  return (
    <div className='contract-progress-outer-container'>
      <div className='steps'>
        {steps.map((step, idx) => {
          const { title, href, shortTitle, active, count, isTab, complete, past, future, onClickOverride } = step;
          const statusClasses = { active, complete, past, future };
          const select = () => selectStep(step);
          return isTab ? (
            href && !onClickOverride ? (
              <Link key={idx} to={href} className={cn('tab link', statusClasses)}>
                <div className='display'>{title}</div>
                <div className='display short'>{shortTitle}</div>
                <FontAwesomeIcon icon={faExternalLink} />
              </Link>
            ) : (
              <div key={idx} onClick={onClickOverride || select} className={cn('tab', statusClasses)}>
                <div className='display'>
                  {title}
                  {!!count && <div className='count'>({count})</div>}
                </div>
                <div className='display short'>
                  {shortTitle}
                  {!!count && <div className='count'>({count})</div>}
                </div>
              </div>
            )
          ) : (
            <div key={idx} onClick={select} className={cn('step', statusClasses)}>
              <div className='badge'>
                <span className='step-label'>Step </span>
                {idx + 1} of {steps.length}
              </div>
              <div className='display'>{title}</div>
              <div className='display short'>{shortTitle}</div>
              <div className={cn('status-circle', statusClasses)} />
              <div className={cn('meter', statusClasses)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ContractProgress.propTypes = {
  user: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  selectStep: PropTypes.func.isRequired
};

export default ContractProgress;
