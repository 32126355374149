import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './ArtistModalPinMentionDetails.scss';

import Loader from '../Loader/Loader';

import { isPinFromBrand } from '../../Helpers/brand_helpers';
import { getBrand } from '../../Helpers/user_helpers';
import { getRootUrl, portalBugOpenUrl } from '../../Helpers/helpers';

const ArtistModalPinMentionDetails = props => {
  const { user, mention, collection, backFromMention, getMentionTitle, getSocialLinkForMention, getSocialPlatformForMention } = props;

  const brand = getBrand(user);
  const pins = _.filter(_.orderBy(collection?.pins, 'sortOrderRank', 'asc'), p => !p.isHidden);

  const social_link = getSocialLinkForMention(mention);
  const social_platform = getSocialPlatformForMention(mention);

  return (
    <div className='artist-modal-pin-mention-details'>
      <div className='header-container'>
        <div onClick={backFromMention} className='data'>
          <div className='back-to-mentions-icn'>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className='title-container'>
            <div className='title'>{getMentionTitle(mention)}</div>
          </div>
        </div>
        <div className='actions'>
          {!!social_link && !!social_platform && (
            <a onClick={portalBugOpenUrl} href={social_link} className='external-link action'>
              View post on {social_platform}
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          )}
          <a onClick={portalBugOpenUrl} href={`${getRootUrl()}/collections/${mention.id}`} className='external-link action'>
            View full collection
            <FontAwesomeIcon icon={faExternalLink} />
          </a>
        </div>
      </div>
      <div className='collection'>
        {collection ? (
          <>
            <div className='pins'>
              {pins.map(pin => {
                const fromBrand = isPinFromBrand(pin, brand);
                return (
                  <div key={pin.id} className='pin-outer-container'>
                    <div className={cn('pin-inner-container', { 'from-brand': fromBrand })}>
                      <div className='image-container'>
                        <img src={pin.image} alt={pin.title} />
                      </div>
                      <div className='title'>{pin.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className='loading-collection'>
            <Loader size={120} />
          </div>
        )}
      </div>
    </div>
  );
};

ArtistModalPinMentionDetails.propTypes = {
  user: PropTypes.object.isRequired,
  mention: PropTypes.object.isRequired,
  getMentionTitle: PropTypes.func.isRequired,
  backFromMention: PropTypes.func.isRequired,
  getSocialLinkForMention: PropTypes.func.isRequired,
  getSocialPlatformForMention: PropTypes.func.isRequired,
  collection: PropTypes.object
};

export default ArtistModalPinMentionDetails;
