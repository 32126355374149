import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import './ArtistModal.scss';

import Modal from '../General/Modal';
import Loader from '../Loader/Loader';

import ArtistModalSidebar from './ArtistModalSidebar';
import ArtistModalMentions from './ArtistModalMentions';
import ArtistModalClicks from './ArtistModalClicks';
import ArtistModalOrders from './ArtistModalOrders';
import ArtistModalCollaborationTasks from './ArtistModalCollaborationTasks';
import ArtistModalTalentReport from './ArtistModalTalentReport';
import ArtistModalSimilarTalentResults from './Elements/ArtistModalSimilarTalentResults';

import { getTalent, getTalentPromotionDetails } from '../../APIClient/talent';
import { getMentions } from '../../APIClient/mentions';
import { getPins } from '../../APIClient/pins';
import { openChatOverlay, setUIKeyValue, openCodesModal, openRequestModal, openBonusModal } from '../../Actions/UIActions';
import { setCustomCommissionRate, setCustomCode, createSamplesRequest } from '../../Actions/AnalyticsActions';
import { createContractWithUser, createContractFromTask } from '../../Actions/CollaborationsActions';
import { getBrandId } from '../../Helpers/user_helpers';
import { showCompetitorBrandsOnArtistModal } from '../../Helpers/ui_helpers';

const defaultTab = 'report';

const ArtistModal = props => {
  const { ui, user, close, artist_id, tab, analytics } = props;

  // Data
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMentions, setIsFetchingMentions] = useState(false);
  const [talent, setTalent] = useState(null); // General talent description and stats
  const [details, setDetails] = useState(null); // Data highly specific to the brand
  const [mentions, setMentions] = useState(null); // Social Mentions of the brand by the user
  const [pinMentions, setPinMentions] = useState(null); // Mentions of the brand by the user with stats
  const [orders, setOrders] = useState(null); // Orders of the brands products
  const [similarTalent, setSimilarTalent] = useState(null); // Similar talent from ml

  // Tabs
  const [curTab, setCurTab] = useState(defaultTab);
  const [artistHistory, setArtistHistory] = useState([artist_id]);

  const Brand_id = getBrandId(user);
  const hasKeyResults = !!talent && !!details;
  const isFetchingInitial = isFetching && !hasKeyResults;
  const isFetchingNewTalent = isFetching && (talent?.id !== _.last(artistHistory) || artistHistory.length > 1);
  const modalVisible = hasKeyResults || isFetchingInitial;
  const showCompetitorBrands = showCompetitorBrandsOnArtistModal(ui);

  const addUserToHistory = talent => {
    const newArtistHistory = [...artistHistory, talent.id];
    setArtistHistory(newArtistHistory);
  };

  const removeUserFromHistory = () => {
    const artistHistoryClone = [...artistHistory];
    artistHistoryClone.pop();
    setArtistHistory(artistHistoryClone);
  };

  // Data Loading
  const talentLoader = async currentArtist_id => {
    const talentResponse = await getTalent(Brand_id, { User_id: currentArtist_id, getDetailedResults: true });
    const talent = _.first(talentResponse.talent);
    setTalent(talent);
  };

  const socialMentionsLoader = async currentArtist_id => {
    const mentionsResponse = await getMentions({
      limit: 25,
      User_id: currentArtist_id,
      Brand_id: Brand_id,
      mentionsOnly: true,
      ...(showCompetitorBrands ? { showCompetitorBrands: true } : {})
    });
    mentionsResponse.mentions?.length && !mentions && !tab && setCurTab('mentions'); // Default if visible and first load
    setMentions(mentionsResponse.mentions || []);
  };

  const mentionsLoader = async currentArtist_id => {
    const resp = await getPins(
      new URLSearchParams({
        Brand_id: Brand_id,
        limit: 250,
        User_id: currentArtist_id
      }).toString()
    );
    setPinMentions(resp.results);
  };

  const ordersLoader = async currentArtist_id => {
    const ordersResponse = await getPins(
      new URLSearchParams({
        Brand_id,
        limit: 100,
        User_id: currentArtist_id,
        groupByMode: 'orders'
      }).toString()
    );
    setOrders(ordersResponse.results);
  };

  const promotionDetailsLoader = async currentArtist_id => {
    const response = await getTalentPromotionDetails(Brand_id, { User_id: currentArtist_id, showCompetitorBrands });
    setDetails(response.details);
    return response.details;
  };

  const detailedSimilarTalentLoader = async lookalikes => {
    const ids = lookalikes.map(lookalike => lookalike.User_id);
    const talentResponse = ids.length ? await getTalent(Brand_id, { User_ids: ids.join(','), getDetailedResults: true }) : { talent: [] };
    const talentArray = talentResponse.talent || [];
    const sortedIdFullArray = talentArray.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
    setSimilarTalent(sortedIdFullArray);
  };

  const loadDataForCurrentArtist = async currentArtist_id => {
    try {
      setIsFetching(true);
      ordersLoader(currentArtist_id); // This can run async as it is not core to the results
      const loaderResults = await Promise.all([talentLoader(currentArtist_id), promotionDetailsLoader(currentArtist_id)]);
      const details = _.last(loaderResults); // Last promise is promotionDetailsLoader
      details?.lookalikes ? await detailedSimilarTalentLoader(details.lookalikes) : setSimilarTalent([]);
    } catch (error) {
      console.error(error);
      cogoToast.error(`Error fetching data on this talent, please try again.`);
      close();
    } finally {
      setIsFetching(false);
    }
  };

  const loadMentionsForCurrentArtist = async currentArtist_id => {
    try {
      setIsFetchingMentions(true);
      await Promise.all([mentionsLoader(currentArtist_id), socialMentionsLoader(currentArtist_id)]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetchingMentions(false);
    }
  };

  const historyLength = artistHistory.length || 1; // always should have one in history
  const getCurrentArtistId = () => {
    artistHistory[0] !== artist_id && setArtistHistory([artist_id]);
    return artistHistory[0] !== artist_id ? artist_id : artistHistory[artistHistory.length - 1];
  };

  // Data Fetching on changing artist_id
  useEffect(() => {
    const currentArtist_id = getCurrentArtistId();
    if (currentArtist_id) {
      loadDataForCurrentArtist(currentArtist_id);
    } else {
      setCurTab(defaultTab);
      setTalent(null);
      setDetails(null);
      setPinMentions(null);
      setMentions(null);
      setSimilarTalent(null);
      setIsFetching(false);
      setArtistHistory([]);
    }
  }, [historyLength, artist_id]);

  // Data Fetching on changing showCompetitorBrands
  useEffect(() => {
    const currentArtist_id = getCurrentArtistId();
    if (currentArtist_id) {
      loadMentionsForCurrentArtist(currentArtist_id);
    } else {
      setPinMentions(null);
      setMentions(null);
    }
  }, [historyLength, artist_id, showCompetitorBrands]);

  // const numTasks = (details?.tasks?.length || 0) + 1; // 1 for custom
  const tabs = [
    { display: 'Talent Report', variable: 'report' },
    { display: 'Mentions', variable: 'mentions' },
    { display: 'Clicks', variable: 'clicks' },
    { display: 'Orders', variable: 'orders', isHidden: !orders?.length },
    { display: 'Similar Talent', variable: 'similar_talent', isNew: true },
    { display: 'Collaborate', variable: 'collabs' }
  ].filter(t => !t.isHidden);
  const showCollaborations = () => setCurTab('collabs');

  const loaderPanel = (
    <div>
      <Loader />
    </div>
  );

  // When specifically selecting a tab, open there
  useEffect(() => {
    const tabToOpen = tabs.find(t => t.variable === tab);
    if (tabToOpen) setCurTab(tab);
    else if (!tabToOpen && tab)
      console.warn(`${tab} is not a valid option, please select from the following: ${tabs.map(t => t.variable).join(', ')}`);
  }, [tab]);

  // Mentions Panel
  const isMentionsPanelLoading = !mentions || !pinMentions || !hasKeyResults;
  const mentionsPanel = isMentionsPanelLoading ? (
    loaderPanel
  ) : (
    <ArtistModalMentions
      setUIKeyValue={props.setUIKeyValue}
      isFetchingMentions={isFetchingMentions}
      mentions={mentions}
      pinMentions={pinMentions}
      talent={talent}
      details={details}
      user={user}
      ui={ui}
    />
  );

  // Clicks Panel
  const isClicksPanelLoading = !pinMentions || !hasKeyResults;
  const clicksPanel = isClicksPanelLoading ? loaderPanel : <ArtistModalClicks talent={talent} mentions={pinMentions} user={user} />;

  // Orders Panel
  const isOrdersPanelLoading = !orders || !hasKeyResults;
  const ordersPanel = isOrdersPanelLoading ? loaderPanel : <ArtistModalOrders talent={talent} orders={orders} user={user} />;

  // Report Panel
  const isReportPanelLoading = !hasKeyResults || !hasKeyResults;
  const reportPanel = isReportPanelLoading ? (
    loaderPanel
  ) : (
    <ArtistModalTalentReport talent={talent} analytics={analytics} details={details} user={user} ui={ui} />
  );

  // Similar Talent Panel
  const isSimilarTalentPanelLoading = !similarTalent;
  const similarTalentPanel = isSimilarTalentPanelLoading ? (
    loaderPanel
  ) : (
    <ArtistModalSimilarTalentResults
      analytics={analytics}
      user={user}
      ui={ui}
      isFetchingNewTalent={isFetchingNewTalent}
      similarTalent={similarTalent}
      setModalUser={addUserToHistory}
    />
  );

  // Similar Talent Panel
  const isCollabsPanelLoading = !hasKeyResults;
  const collabsPanel = isCollabsPanelLoading ? (
    loaderPanel
  ) : (
    <ArtistModalCollaborationTasks
      user={user}
      talent={talent}
      details={details}
      createContractWithUser={props.createContractWithUser}
      createContractFromTask={props.createContractFromTask}
    />
  );

  return (
    <Modal
      visible={modalVisible}
      close={props.close}
      innerClassName={cn('artist-modal', { fetching: isFetchingInitial })}
      contentClassName='artist-inner-modal'
      showClose={hasKeyResults}
    >
      {hasKeyResults ? (
        <>
          <div className='sidebar-container'>
            <ArtistModalSidebar
              ui={ui}
              user={user}
              analytics={analytics}
              talent={talent}
              details={details}
              isFetchingNewTalent={isFetchingNewTalent}
              openChatOverlay={props.openChatOverlay}
              back={removeUserFromHistory}
              showBackButton={artistHistory.length > 1}
              createSamplesRequest={props.createSamplesRequest}
              close={props.close}
              setCustomCode={props.setCustomCode}
              setCustomCommissionRate={props.setCustomCommissionRate}
              openCodesModal={props.openCodesModal}
              openRequestModal={props.openRequestModal}
              openBonusModal={props.openBonusModal}
              showCollaborations={showCollaborations}
            />
          </div>
          <div className='main-container'>
            <div className='body-tabs'>
              {tabs.map(tab => {
                const { display, variable, isNew } = tab;
                const isSelected = curTab === variable;
                const click = () => setCurTab(variable);
                return (
                  <div key={display} onClick={click} className={cn('body-tab', { selected: isSelected })}>
                    <div className='display'>
                      {display}
                      {isNew && <div className='new-badge'>NEW</div>}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='body'>
              {curTab === 'mentions' && mentionsPanel}
              {curTab === 'clicks' && clicksPanel}
              {curTab === 'orders' && ordersPanel}
              {curTab === 'report' && reportPanel}
              {curTab === 'collabs' && collabsPanel}
              {curTab === 'similar_talent' && similarTalentPanel}
            </div>
          </div>
        </>
      ) : (
        <div className='loading-artist'>
          <Loader size={120} />
        </div>
      )}
    </Modal>
  );
};

ArtistModal.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  createContractWithUser: PropTypes.func.isRequired,
  createContractFromTask: PropTypes.func.isRequired,
  setCustomCommissionRate: PropTypes.func.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  createSamplesRequest: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  setUIKeyValue: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  artist_id: PropTypes.number
};

const mapStateToProps = state => {
  const { ui, user, analytics } = state;
  return { ui, user, analytics };
};

export default connect(mapStateToProps, {
  createContractWithUser,
  createContractFromTask,
  setCustomCommissionRate,
  setCustomCode,
  createSamplesRequest,
  openChatOverlay,
  openCodesModal,
  openRequestModal,
  openBonusModal,
  setUIKeyValue
})(withRouter(ArtistModal));
