import React, { Component } from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import cogoToast from 'cogo-toast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { faPlus, faTimes, faPencilAlt } from '@fortawesome/pro-light-svg-icons';

import Loader from '../../Loader/Loader';
import ProfilePhoto from '../ProfilePhoto';
import UploadImage from '../../General/UploadImage';

import { updateUser as updateUserAPI } from '../../../APIClient/users';

import { getSocialPlatformForUrl, getSocialIconForUrl } from '../../../Helpers/social_helpers';
import { isYoutubeUserRequiredForAppCompliance } from '../../../Helpers/user_helpers';

import './ProfileDataPanel.scss';

class ProfileDataPanel extends Component {
  static propTypes = {
    shop: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    syncProfile: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    analytics: PropTypes.object.isRequired,
    analyticsOn: PropTypes.bool.isRequired,
    shopUsername: PropTypes.string.isRequired,
    fetchingAnalytics: PropTypes.bool.isRequired,
    waitingOnSSR: PropTypes.bool.isRequired
  };

  state = {
    isEditing: false,
    savingProfile: false,

    new_name: _.get(this.props.shop, 'name') || '',
    new_image: _.get(this.props.shop, 'image') || '',
    new_description: _.get(this.props.shop, 'description') || '',
    new_social_links: _.get(this.props.shop, 'social_links') || '',
    new_social_link: ''
  };

  componentDidUpdate(prevProps) {
    const { shop } = this.props;
    if (prevProps.shop.username !== shop.username) {
      this.setState({
        new_name: _.get(shop, 'name') || '',
        new_image: _.get(shop, 'image') || '',
        new_description: _.get(shop, 'description') || '',
        new_social_links: _.get(shop, 'social_links') || '',
        new_social_link: ''
      });
    }

    // Automatically open the editing panel every time someone loads their shop to increase the engagement chances
    if (this.state.forceOpenEditingWhenLoaded) {
      if (this.props.shopUsername === shop.username) {
        this.setState({ forceOpenEditingWhenLoaded: false, isEditing: true });
      }
    }
  }

  getSocialLinkData = link => {
    return {
      site: getSocialPlatformForUrl(link),
      icon: getSocialIconForUrl(link, 'dark')
    };
  };

  getVisibleSocialIcons = () => {
    const userLinks = this.getUserSocialLinks();
    return _.map(userLinks, link => ({
      ...this.getSocialLinkData(link),
      link
    }));
  };

  getUserSocialLinks = () => _.filter(_.split(this.state.new_social_links, ','), str => !!str);

  completeEdit = async shouldSave => {
    const { shop, syncProfile } = this.props;
    const { new_name, new_image, new_description, new_social_links, new_social_link } = this.state;
    if (shouldSave) {
      if (new_social_link.length) {
        this.addLink(null, () => this.completeEdit(shouldSave));
        return;
      }

      if (this.needsSave()) {
        try {
          this.setState({ savingProfile: true });
          const success = await updateUserAPI(
            {
              social_links: new_social_links,
              description: new_description,
              name: new_name,
              image: new_image
            },
            shop.id
          );
          if (!success) throw new Error('Failed Save');
          await syncProfile();
          cogoToast.success(`Profile successfully updated.`, { hideAfter: 1 });
        } catch (error) {
          cogoToast.error(
            'There was an error saving your profile, please reload the page and try again. If this issue persists, please try logging out and logging back in.'
          );
          this.setState({
            new_social_links: shop.social_links,
            new_description: shop.description,
            new_name: shop.name,
            new_image: shop.image
          });
        }
      }
      this.setState({
        new_social_link: '',
        savingProfile: false,
        isEditing: false
      });
    } else {
      this.setState({
        new_social_links: shop.social_links || '',
        new_description: shop.description || '',
        new_name: shop.name || '',
        new_image: shop.image || '',
        isEditing: false
      });
    }
  };

  removeLink = linkToRemove => {
    const newLinks = _.filter(this.getUserSocialLinks(), oldLink => oldLink !== linkToRemove);
    this.setState({
      new_social_links: newLinks.join(',')
    });
  };

  focusOnNewLinkInput = () => {
    setTimeout(() => {
      this.newLinkInput && this.newLinkInput.focus();
    }, 150);
  };

  addLink = (event, successCallback, errorCallback) => {
    const { new_social_link } = this.state;
    event && event.preventDefault();
    if (new_social_link.length < 3) {
      cogoToast.error('Please add a valid URL.');
      return;
    }
    if (!new_social_link.includes('https://')) {
      cogoToast.error('URLs must begin with https://');
      errorCallback && errorCallback();
      return;
    }

    const newLinks = [...this.getUserSocialLinks(), new_social_link];
    this.setState(
      {
        new_social_links: newLinks.join(','),
        new_social_link: ''
      },
      () => {
        if (successCallback) {
          successCallback();
        } else {
          this.focusOnNewLinkInput();
        }
      }
    );
  };

  needsSave = () =>
    this.props.shop.social_links !== this.state.new_social_links ||
    this.props.shop.description !== this.state.new_description ||
    this.props.shop.name !== this.state.new_name ||
    this.props.shop.image !== this.state.new_image;

  render() {
    const { canEdit, shop, analytics, analyticsOn, waitingOnSSR, isFetching, fetchingAnalytics } = this.props;
    const { savingProfile, new_social_link, new_name, new_image, new_description, isEditing } = this.state;
    const { name, description } = shop;
    const visibleSocialIcons = this.getVisibleSocialIcons();
    const missingDescription = !description || !description.length;
    const { num_views } = _.get(analytics, 'shop_stats') || {};
    return (
      <div className='profile-data-panel-outer-container'>
        {!isEditing ? (
          <div className='profile-data-panel'>
            <div className='profile-image'>
              <ProfilePhoto profile={shop} ui={this.props.ui} isFetching={isFetching} />
            </div>
            {missingDescription && !isFetching && (
              <div className='profile-name'>
                <h1>{name}</h1>
                {canEdit && <FontAwesomeIcon onClick={() => this.setState({ isEditing: true })} icon={faPencilAlt} />}
              </div>
            )}
            <div
              className={classnames('main-data-container', {
                'no-description': missingDescription,
                empty: isFetching
              })}
            >
              {!missingDescription && (
                <>
                  <div className='main-data-title-badge-container'>
                    <div
                      className={classnames('main-data-title-badge', {
                        'large-name': name.length > 20
                      })}
                    >
                      <h1 className={classnames({ empty: isFetching })}>{isFetching ? '' : name}</h1>
                      {canEdit && !isFetching && <FontAwesomeIcon onClick={() => this.setState({ isEditing: true })} icon={faPencilAlt} />}
                    </div>
                  </div>
                  <h2
                    className={classnames('main-data-body', {
                      empty: isFetching
                    })}
                  >
                    {isFetching ? '' : description}
                  </h2>
                </>
              )}
              <div className='profile-social-links-icons-container'>
                {!isFetching &&
                  !waitingOnSSR &&
                  _.map(visibleSocialIcons, ({ site, icon, link }, idx) => {
                    return (
                      <a
                        href={link}
                        {...{ target: isMobile ? '_self' : '_blank' }}
                        className={classnames('profile-social-links-icon', {
                          large: isYoutubeUserRequiredForAppCompliance(this.props.user)
                        })}
                        key={idx}
                      >
                        {site ? <img alt='' src={icon} /> : <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>}
                      </a>
                    );
                  })}
              </div>
            </div>
            {canEdit && analyticsOn && !isFetching && (
              <div className='analytics-preview-container loaded'>
                {fetchingAnalytics ? (
                  <Loader size={20} />
                ) : (
                  <div className='header'>
                    {commaNumber(num_views) || '0'} shop view
                    {num_views === 1 ? '' : 's'}
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className='profile-data-panel edit'>
            <div className='profile-image'>
              <UploadImage currentImage={new_image} hiddenHeader completeUpload={newImage => this.setState({ new_image: newImage })} imagesOnly />
            </div>
            <input
              type='text'
              className='new-name-input'
              placeholder='Your Full Name'
              onChange={({ target }) => this.setState({ new_name: target.value })}
              value={new_name}
              ref={input => {
                this.newNameInput = input;
              }}
            />
            <textarea
              type='text'
              rows='4'
              className='new-description-input'
              placeholder='Describe a little bit about who you are.'
              onChange={({ target }) => this.setState({ new_description: target.value })}
              value={new_description}
              ref={input => {
                this.newDescriptionInput = input;
              }}
            />
            <div className={classnames('profile-social-links-edit-panel')}>
              {_.map(visibleSocialIcons, ({ link }, idx) => {
                const { icon, site } = this.getSocialLinkData(link);
                return (
                  <div className='current-social-link' key={idx}>
                    <div className='link-container'>
                      {site ? (
                        <img className='link-example' alt={`${site} Logo`} src={icon} />
                      ) : (
                        <FontAwesomeIcon className='link-example' icon={faLink}></FontAwesomeIcon>
                      )}
                      <div className='current-social-link-url'>{link}</div>
                    </div>
                    <div className='current-social-link-remove-icon' onClick={() => this.removeLink(link)}>
                      <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                    </div>
                  </div>
                );
              })}
              <div className='current-social-link'>
                <div className='link-container'>
                  <form onSubmit={this.addLink}>
                    <input
                      type='text'
                      className='new-link-input'
                      placeholder={visibleSocialIcons.length ? 'Enter another link' : 'Paste a link to your social profiles'}
                      onChange={({ target }) => this.setState({ new_social_link: target.value })}
                      value={new_social_link}
                      ref={input => {
                        this.newLinkInput = input;
                      }}
                    />
                    {!!new_social_link.length && (
                      <div className='current-social-link-add-icon' onClick={this.addLink}>
                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div
              onClick={() => this.completeEdit(true)}
              className={classnames('done-btn btn', {
                disabled: !this.needsSave()
              })}
            >
              {savingProfile ? 'SAVING...' : 'SAVE'}
            </div>
            <div onClick={() => this.completeEdit(false)} className='cancel-btn btn'>
              CANCEL
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ProfileDataPanel;
