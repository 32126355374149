import APIClient from './index';
import _ from 'lodash';

export const addCompetitorBrand = competitorBrand => {
  return new Promise((resolve, reject) => {
    APIClient.post('/CompetitorBrands', competitorBrand)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateCompetitorBrand = (competitorBrand, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/CompetitorBrands/${competitorBrand.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteCompetitorBrand = competitorBrand => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/CompetitorBrands/${competitorBrand.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
