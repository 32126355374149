import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ResultHeader.scss';

import VideoPlayer from '../General/VideoPlayer';

import { getSmartImage } from '../../Helpers/helpers';
import { getNameWithS } from '../../Helpers/user_helpers';
import { getElementsFromContent } from '../../Helpers/consult_helpers';
import { isVideoString } from '../../Helpers/media_helpers';

const ResultHeader = props => {
  const { result, allowEditing, canEdit } = props;
  const [showResponse, setShowResponse] = useState(allowEditing);
  const resultUser = result?.user;
  const elements = getElementsFromContent(result.clientResponse);
  return (
    <div className='result-header-container result-card'>
      {showResponse ? (
        <div className='results-response'>
          <div className='response-header'>{getNameWithS(result).full} Response</div>
          <div className='response-subheader'>Sent by {result.clientEmail}</div>
          {elements.length ? (
            elements.map(element => {
              const { answer, question, options, mediaUrls } = element;
              const optionsAnswer = _.map(
                _.filter(options, o => o.checked),
                o => `${o.question}${o.openEnded ? ` - ${answer}` : ''}`
              ).join(', ');
              const fullAnswer = optionsAnswer || answer;
              return (
                <div className='response-body' key={element.id}>
                  <div className='question'>{question}</div>
                  {mediaUrls ? (
                    mediaUrls.map((url, idx) => {
                      const isVideo = isVideoString(url);
                      return (
                        <div key={idx} className='media-upload'>
                          {isVideo ? (
                            <div key={idx}>
                              <VideoPlayer src={url} />
                            </div>
                          ) : (
                            <a key={idx} target='_blank' rel='noopener noreferrer' href={getSmartImage(url)}>
                              <img src={getSmartImage(url)} alt={`Media Upload ${idx}`} />
                              <div className='hint'>Click to open in new window</div>
                            </a>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className='answer'>{fullAnswer || 'Did not answer'}</div>
                  )}
                </div>
              );
            })
          ) : (
            <div className='response-empty'>No questions asked</div>
          )}
        </div>
      ) : canEdit ? (
        <div className='complete'>
          Great! You have submitted the consult for <b>{result?.clientName}</b> and we have sent over a confirmation email to{' '}
          <b>{result.clientEmail}</b>. If you would like to see the responses again, click{' '}
          <span onClick={() => setShowResponse(true)} className='clickable'>
            here
          </span>
          .
        </div>
      ) : (
        <div className='pending'>
          {result?.consult?.price ? (
            <span>
              Your payment was successful!
              <br />
              <br />{' '}
            </span>
          ) : (
            ''
          )}
          This submission for {result?.consult?.title} is currently being reviewed by <b>{resultUser?.name}</b> and you will be emailed at{' '}
          <b>{result.clientEmail}</b> when your results are ready! We guarantee results within fourteen days or you will receive a full refund for
          your purchase.
          <br />
          <br />
          If you have any further questions, please email us at team@shopmy.us!
        </div>
      )}
    </div>
  );
};

ResultHeader.propTypes = {
  result: PropTypes.object.isRequired,
  allowEditing: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired
};

export default ResultHeader;
