import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './HomeBlogPanel.scss';

import { syncFeaturedItems, updateFeaturedItem } from '../../../Actions/FeaturedActions';

import { isAdminControlMode } from '../../../Helpers/ui_helpers';

const HomeBlogPanel = props => {
  const { featured, ui } = props;
  const { featuredPosts } = featured;
  const primaryFeature = _.first(featuredPosts);
  const featuredPost = primaryFeature?.post;
  const secondaryPosts = _.slice(featuredPosts, 1, 5)
    .map(p => p.post)
    .filter(a => a); // Ensure post still exists

  React.useEffect(() => {
    props.syncFeaturedItems();
  }, []);

  const canChangePosts = isAdminControlMode(ui);
  const changePrimaryPost = e => changePost(e, featuredPost);
  const changePost = (e, post) => {
    if (!canChangePosts) return;
    e.preventDefault();
    e.stopPropagation();
    const featureToUpdate = _.find(featuredPosts, { postID: post.id });
    const newId = window.prompt('Enter the ID of the new primary post', post.id);
    newId && props.updateFeaturedItem(featureToUpdate, { postID: newId, updatedAt: new Date() });
  };
  return (
    <div className='home-blog-panel-outer-container'>
      <div className='home-blog-panel-inner-container'>
        <div className='panel left'>
          <div className='blog-title'>Blog</div>
          {featuredPost ? (
            <Link to={`/blog/${featuredPost.titleStub}`} className='main-post'>
              <div className='image-container'>
                <img onClick={changePrimaryPost} loading='lazy' className='image' src={featuredPost.coverImage} alt={featuredPost.title} />
                <div className='editors-pick'>editor's pick</div>
              </div>
              <div className='data'>
                <div className='badge'>{featuredPost.tag}</div>
                <div className='title'>{featuredPost.title}</div>
              </div>
            </Link>
          ) : (
            <div className='main-post'>
              <div className='image-container'>
                <div className='image empty' />
              </div>
              <div className='data empty'>
                <div className='badge empty el'></div>
                <div className='title empty el'></div>
              </div>
            </div>
          )}
        </div>
        <div className='panel right'>
          <div className='secondary-posts'>
            {secondaryPosts.length
              ? secondaryPosts.map(post => {
                  const change = e => changePost(e, post);
                  return (
                    <Link to={`/blog/${post.titleStub}`} key={post.id} className='secondary-post-link-wrapper'>
                      <div className='secondary-post'>
                        <div className='post'>
                          <img onClick={change} loading='lazy' className='image' src={post.coverImage} alt={post.title} />
                          <div className='content'>
                            <div className='badge'>{post.tag}</div>
                            <div className='title'>{post.title}</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              : [0, 1, 2, 3].map(idx => (
                  <div key={idx} className='secondary-post empty'>
                    <div className='badge empty' />
                    <div className='post'>
                      <div className='image empty'></div>
                      <div className='title empty'></div>
                    </div>
                  </div>
                ))}
          </div>
          <Link to='/blog'>
            <div className='view-all'>More Posts</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

HomeBlogPanel.propTypes = {
  featured: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,

  syncFeaturedItems: PropTypes.func.isRequired,
  updateFeaturedItem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { featured, ui } = state;
  return { featured, ui };
};

export default connect(mapStateToProps, {
  syncFeaturedItems,
  updateFeaturedItem
})(HomeBlogPanel);
