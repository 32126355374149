/*
  This file contains the permissionable scopes that are used in the OAuth2.0 flow.
  IMPORTANT: If you add or remove scopes in this file, you must also edit the corresponding file on the backend: utils/oauth/oauth_scopes.js 
*/

const READ_LINKS = 'read_links';
const WRITE_LINKS = 'write_links';
const READ_COLLECTIONS = 'read_collections';

export const OAUTH_SCOPE_DETAILED_DISPLAY_NAMES = {
  [READ_LINKS]: 'View all of your product links',
  [WRITE_LINKS]: 'Create product links',
  [READ_COLLECTIONS]: 'View all of your collections'
};

export const OAUTH_SCOPE_DISPLAY_NAMES = {
  [READ_LINKS]: 'Read Links',
  [WRITE_LINKS]: 'Write Links',
  [READ_COLLECTIONS]: 'Read Collections'
};
