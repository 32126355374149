import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunityHeaderTabs.scss';

import Tooltip from '../../General/Tooltip';

const OpportunityHeaderTabs = props => {
  const { tabs } = props;
  return (
    <div className='opportunity-header-tabs'>
      {tabs.map(tab => {
        const { select, isActive, value, display, count, isNew, isDisabled, isDisabledTooltip } = tab;
        const additionalClasses = { active: isActive, disabled: isDisabled };
        const tabEl = (
          <div key={value} onClick={select} className={cn('tab-container', additionalClasses)}>
            <div className={cn('tab', additionalClasses)}>
              <div className='display'>{display}</div>
              {!!count && <div className='count'>{count}</div>}
            </div>
            {isNew && <div className='new-badge'>New</div>}
          </div>
        );
        return isDisabled ? (
          <Tooltip key={value} message={isDisabledTooltip}>
            {tabEl}
          </Tooltip>
        ) : (
          tabEl
        );
      })}
    </div>
  );
};

OpportunityHeaderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      select: PropTypes.func.isRequired,
      isActive: PropTypes.bool,
      isNew: PropTypes.bool
    })
  ).isRequired
};

export default OpportunityHeaderTabs;
