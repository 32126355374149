import React from 'react';
import PropTypes from 'prop-types';
import './BulkRequestModalSearchResults.scss';
import cn from 'classnames';

import { getBrandListById } from '../../Helpers/user_helpers';
import { getInitialsForUser } from '../../Helpers/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';

import Loader from '../Loader/Loader';

const BulkRequestModalSearchResults = props => {
  const { isVisible, userSearchResults, listSearchResults, selectedList, setSelectedList, isSearching, curSearchVal, isFetchingTalent } = props;
  const { selectUsers, removeUser, selectedUserIdSet, selectedEmailSet, brandListUsers, openArtistModal } = props;
  const { findIfUserAlreadyReceivedRequestOfType } = props;

  const BrandList_id = selectedList?.id;

  return selectedList ? (
    <div className={cn('bulk-request-modal-search-results-outer', { isVisible })}>
      <div className='bulk-request-modal-search-results-container'>
        <div className='bulk-request-modal-search-results-title'>{selectedList.title}</div>
        <div className='bulk-request-modal-search-results'>
          {isFetchingTalent ? (
            <Loader />
          ) : (
            <>
              {brandListUsers.map(user => {
                getBrandListById(user, BrandList_id);
                const { id, name, image, username, original_image } = user;
                const userIsAlreadySelected = selectedUserIdSet.has(id);
                const userAlreadyReceivedGiftingOfThisType = findIfUserAlreadyReceivedRequestOfType(user);

                return (
                  <div
                    className={cn('bulk-request-modal-search-talent-result', { disabled: userAlreadyReceivedGiftingOfThisType })}
                    onClick={() => (userIsAlreadySelected ? removeUser(user) : selectUsers([{ ...user, brandList: selectedList }]))}
                    key={`brand-list-user-${id}`}
                  >
                    {image || original_image ? (
                      <img src={image || original_image} alt={name} />
                    ) : (
                      <div className='talent-result-no-image'>{getInitialsForUser(user)}</div>
                    )}
                    <div className='talent-result-text-container'>
                      <div className='talent-result-name'>{name}</div>
                      <div
                        onClick={e => {
                          e.stopPropagation();
                          openArtistModal(user);
                        }}
                        className='talent-result-subtitle'
                      >
                        @{username}
                      </div>
                    </div>
                    <div className={cn('result-add-button', { userIsAlreadySelected })}>
                      {userIsAlreadySelected ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={cn('bulk-request-modal-search-results-outer', { isVisible, isSearching })}>
      {!userSearchResults.length && curSearchVal?.includes('@') && (
        <div className='bulk-request-modal-search-results-container'>
          <div className='bulk-request-modal-search-results-title'>Email</div>
          <div className='bulk-request-modal-search-results'>
            {curSearchVal.split(',').map(email => {
              email = email.trim();
              return (
                <div
                  className={cn('bulk-request-modal-search-talent-result off-platform', {
                    disabled: findIfUserAlreadyReceivedRequestOfType({ email })
                  })}
                  onClick={() => (selectedEmailSet.has(email) ? removeUser({ email }) : selectUsers([{ email }]))}
                >
                  <div className='talent-result-name'>{email}</div>
                  <div className={cn('result-add-button', { userIsAlreadySelected: selectedEmailSet.has(email) })}>
                    {selectedEmailSet.has(email) ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {userSearchResults?.length > 0 && curSearchVal.length > 0 && (
        <div className='bulk-request-modal-search-results-container'>
          <div className='bulk-request-modal-search-results-title'>Talent</div>
          <div className='bulk-request-modal-search-results'>
            {userSearchResults.map(user => {
              const { name, email, username, image, original_image } = user;
              const userIsAlreadySelected = selectedUserIdSet.has(user.id) || selectedEmailSet.has(email);
              const userAlreadyReceivedGiftingOfThisType = findIfUserAlreadyReceivedRequestOfType(user);

              return (
                <div
                  className={cn('bulk-request-modal-search-talent-result', { disabled: userAlreadyReceivedGiftingOfThisType })}
                  onClick={() => (userIsAlreadySelected ? removeUser(user) : selectUsers([user]))}
                  key={`user-${user.id}`}
                >
                  {image || original_image ? (
                    <img src={image || original_image} alt={name} />
                  ) : (
                    <div className='talent-result-no-image'>{getInitialsForUser(user)}</div>
                  )}
                  <div className='talent-result-text-container'>
                    <div className='talent-result-name'>{name}</div>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                        openArtistModal(user);
                      }}
                      className='talent-result-subtitle'
                    >
                      @{username}
                    </div>
                  </div>
                  <div className={cn('result-add-button', { userIsAlreadySelected })}>
                    {userIsAlreadySelected ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faPlus} />}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {listSearchResults?.length > 0 && (
        <div className='bulk-request-modal-search-results-container'>
          <div className='bulk-request-modal-search-results-title'>Lists</div>
          <div className='bulk-request-modal-search-results'>
            {listSearchResults.map(list => {
              const { title, users } = list;

              return (
                <div className='bulk-request-modal-search-list-result' onClick={() => setSelectedList(list)} key={`list-${list.id}`}>
                  <div className='list-result-title'>{title}</div>
                  <div className='list-result-item-count'>({users?.length})</div>
                  <div className='result-add-button'>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

BulkRequestModalSearchResults.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  brand: PropTypes.object.isRequired,
  isSearching: PropTypes.bool.isRequired,
  curSearchVal: PropTypes.string,
  isFetchingTalent: PropTypes.bool.isRequired,
  openArtistModal: PropTypes.func.isRequired,

  userSearchResults: PropTypes.array.isRequired,
  listSearchResults: PropTypes.array.isRequired,
  brandListUsers: PropTypes.array.isRequired,
  selectedList: PropTypes.object,
  setSelectedList: PropTypes.func.isRequired,
  findIfUserAlreadyReceivedRequestOfType: PropTypes.func.isRequired,

  selectUsers: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  selectedUserIdSet: PropTypes.object.isRequired,
  selectedEmailSet: PropTypes.object.isRequired
};

export default BulkRequestModalSearchResults;
