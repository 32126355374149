import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import classnames from 'classnames';

import { inSearchMode, getSearchVal } from '../../Helpers/ui_helpers';
import { getDisplayInfoFromFilters } from '../../Helpers/shop_helpers';

import './ShopHeader.scss';

const SCROLL_BREAK = 44;

class ShopHeader extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      çategory: PropTypes.object,
      tag: PropTypes.object
    })
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.setOffset);
    this.setOffset();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setOffset);
  }

  componentDidUpdate(prevProps) {
    const prevTitle = getDisplayInfoFromFilters(prevProps.activeFilters).titleStub;
    const prevSubtitle = getDisplayInfoFromFilters(prevProps.activeFilters).subtitle;
    const newTitle = getDisplayInfoFromFilters(this.props.activeFilters).titleStub;
    if (prevTitle !== newTitle) {
      this.setState({
        prevTitleStub: prevTitle,
        prevSubtitle: prevSubtitle,
        transitioning: true,
        disableAnimations: false
      });
      setTimeout(
        () =>
          this.setState({
            prevTitleStub: null,
            prevSubtitle: null,
            transitioning: false,
            disableAnimations: true
          }),
        500
      );
    }
  }

  setOffset = () => {
    const { isScrolled } = this.state;
    if ((isScrolled && window.pageYOffset < SCROLL_BREAK) || (!isScrolled && window.pageYOffset > SCROLL_BREAK)) {
      this.setState({ isScrolled: !isScrolled });
    }
  };

  state = {
    prevTitleStub: null,
    prevSubtitle: null,
    transitioning: false,
    disableAnimations: true,
    isScrolled: window.pageYOffset > SCROLL_BREAK
  };

  render() {
    const { activeFilters, ui } = this.props;
    const { transitioning, prevTitleStub, disableAnimations, isScrolled } = this.state;
    const { seoTitle, titleStub, subtitle } = getDisplayInfoFromFilters(activeFilters);
    return (
      <div
        className={classnames('shop-header-outer-container', {
          scrolled: isScrolled
        })}
      >
        <MetaTags>
          <title>{seoTitle}</title>
          <meta name='description' content={subtitle}></meta>
          <meta property='og:title' content={seoTitle} />
          <meta property='og:description' content={subtitle}></meta>
        </MetaTags>
        <div
          className={classnames('shop-header-inner-container', {
            scrolled: isScrolled
          })}
        >
          <div className='header-title'>
            <div className='stable'>
              Best in <span className='mobile'>{titleStub}</span>
            </div>
            <div
              className={classnames('rot-outer desktop', {
                transitioning,
                'disable-animation': disableAnimations
              })}
            >
              <div className='rot-inner'>{prevTitleStub || titleStub}</div>
              <div className='rot-inner'>{titleStub}</div>
            </div>
          </div>
          <h2 className={classnames('header-subtitle', { transitioning })}>
            {inSearchMode(ui) ? (
              <span>
                Highest ranked products for <i>{getSearchVal(ui)}</i>
              </span>
            ) : (
              subtitle
            )}
          </h2>
        </div>
      </div>
    );
  }
}

export default ShopHeader;
