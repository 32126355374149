import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import './BrandLookbookProducts.scss';

import LookbookItem from '../LookbookItem';
import SortableList from '../../General/SortableList';

const BrandLookbookProducts = props => {
  const { lookbook, deleteItemFromLookbook, openEditModal, inPublicViewMode, openAddProductsModal, openReplaceProductsModal } = props;

  const getCard = (item, additionalProps = {}) => (
    <LookbookItem
      ui={props.ui}
      item={item}
      user={props.user}
      analytics={props.analytics}
      rearrangeHandle={additionalProps.rearrangeHandle}
      canEdit={!inPublicViewMode}
      lookbook={lookbook}
      lookbooks={props.lookbooks}
      deleteItemFromLookbook={deleteItemFromLookbook}
      updateLookbookCart={props.updateLookbookCart}
      openEditModal={openEditModal}
      openReplaceProductsModal={openReplaceProductsModal}
    />
  );

  return (
    <div className='brand-lookbook-products-outer'>
      <SortableList
        isEditing
        containerClassName='brand-lookbook-products-inner'
        items={lookbook.items}
        updateItem={props.updateLookbookItem}
        props={props}
        getCard={getCard}
        additionalCard={
          inPublicViewMode ? null : (
            <div className='add-product-container' onClick={openAddProductsModal}>
              <FontAwesomeIcon icon={faPlus} />
              <span>Add Product</span>
            </div>
          )
        }
      />
    </div>
  );
};

BrandLookbookProducts.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired,
  deleteItemFromLookbook: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  inPublicViewMode: PropTypes.bool.isRequired,
  openAddProductsModal: PropTypes.func.isRequired,
  openReplaceProductsModal: PropTypes.func.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default BrandLookbookProducts;
