import React from 'react';
import PropTypes from 'prop-types';
import './ContractProposeLinks.scss';

import ContractLinksCard from './Elements/ContractLinksCard';
import ContractUploadsCard from './Elements/ContractUploadsCard';

const ContractProposeLinks = props => {
  const { user, contract, canEdit } = props;
  return (
    <div className='contract-propose-links-outer-container'>
      {!!contract.isCreatedByBrand && (
        <ContractLinksCard
          contract={contract}
          canEdit={canEdit}
          createContractLink={props.createContractLink}
          deleteContractLink={props.deleteContractLink}
          updateContractLink={props.updateContractLink}
        />
      )}
      <ContractUploadsCard
        user={user}
        contract={contract}
        canEdit={canEdit}
        createContractUpload={props.createContractUpload}
        updateContractUpload={props.updateContractUpload}
        deleteContractUpload={props.deleteContractUpload}
      />
    </div>
  );
};

ContractProposeLinks.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,

  // For editing
  canEdit: PropTypes.bool.isRequired,
  createContractLink: PropTypes.func,
  deleteContractLink: PropTypes.func,
  updateContractLink: PropTypes.func,
  createContractUpload: PropTypes.func,
  updateContractUpload: PropTypes.func,
  deleteContractUpload: PropTypes.func
};

export default ContractProposeLinks;
