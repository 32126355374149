import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './MentionAdminFilters.scss';

import { createBrandTarget } from '../../../APIClient/targets';

import SelectOption from '../../General/SelectOption';

const MentionAdminFilters = props => {
  const { setCurFilters, curFilters, brandAndTargetOptions } = props;
  const brandOptions =
    brandAndTargetOptions.brands?.map(brand => ({
      value: { Brand_id: brand.id },
      label: brand.name,
      sublabels: [`${brand.socialTags?.split(',').length || 0} Tags`]
    })) || [];
  const targetOptions =
    brandAndTargetOptions.targets?.map(target => ({
      value: { Target_id: target.id },
      label: target.name,
      sublabels: ['Target', `${target.socialTags?.split(',').length || 0} Tags`]
    })) || [];
  const selectOptions = [...brandOptions, ...targetOptions];
  const updateOption = data => {
    const { value } = data || {};
    setCurFilters({
      ...curFilters,
      Brand_id: value?.Brand_id || null,
      Target_id: value?.Target_id || null,
      page: 0
    });
    // Scroll to top of page with animation
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Allow creation of new target through search
  const [curSearchVal, setCurSearchVal] = React.useState('');
  const onInputChange = inputValue => setCurSearchVal(inputValue);
  const createNewTarget = () => {
    const targetName = window.prompt('Please enter a name for the new Target Brand (ex: Lululemon) ', curSearchVal);
    const targetDomain = window.prompt(`Please enter a domain for the new Target Brand:`, targetName.toLowerCase() + '.com');
    const targetSocialTags = window.prompt(
      `Please enter social tags for the new Target Brand:`,
      targetName.toLowerCase() + ',' + targetName.toLowerCase() + 'partner'
    );
    const tier = window.prompt(`What tier are they? (1: Elite, 2: Great, 3: Good): `, 3);
    const confirm = window.confirm(
      `Are you sure you want to create a new Target Brand with the following information?\nName: ${targetName}\nDomain: ${targetDomain}\nSocial Tags: ${targetSocialTags}\nTier: ${tier}`
    );
    if (confirm) {
      createBrandTarget({
        name: targetName,
        domain: targetDomain,
        socialTags: targetSocialTags,
        tier
      })
        .then(() => {
          cogoToast.success('Successfully created new Target Brand, please wait 24 hours to gather all the mentions.');
        })
        .catch(err => {
          cogoToast.error('Error creating new Target Brand: ' + err);
        });
    }
  };

  return (
    <div className='mention-admin-filters-container'>
      <Select
        isClearable
        placeholder='Select Specific Brand'
        options={selectOptions}
        onChange={updateOption}
        components={{ Option: SelectOption }}
        onInputChange={onInputChange}
        noOptionsMessage={() =>
          selectOptions.length ? (
            <div onClick={createNewTarget}>Click here to create a new Brand Target</div>
          ) : (
            <div>Please select a filter and then unselect it to get all brands in here</div>
          )
        }
        styles={{
          singleValue: base => ({
            ...base,
            fontSize: '12px' // Change font size
          }),
          placeholder: base => ({
            ...base,
            fontSize: '12px' // Change font size
          })
        }}
      />
    </div>
  );
};

MentionAdminFilters.propTypes = {
  curFilters: PropTypes.object.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  brandAndTargetOptions: PropTypes.object.isRequired
};

export default MentionAdminFilters;
