import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import classnames from 'classnames';
import cogoToast from 'cogo-toast';

import { saveCollection as saveCollectionAPI } from '../../../APIClient/collections';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

import { getSocialPlatformForUrl, getSocialIconForUrl } from '../../../Helpers/social_helpers';
import { isYoutubeUserRequiredForAppCompliance } from '../../../Helpers/user_helpers';

import './CollectionSocialLinks.scss';

class CollectionSocialLinks extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    syncCollection: PropTypes.func.isRequired,
    canEdit: PropTypes.bool.isRequired
  };

  state = {
    showingAddPanel: false,
    savingCollection: false,
    cur_social_links: _.get(this.props.collection, 'social_links', ''),
    new_social_link: ''
  };

  getSocialLinkData = link => {
    return {
      site: getSocialPlatformForUrl(link),
      icon: getSocialIconForUrl(link, link.includes('youtube') ? 'dark' : 'light') // Youtube Compliance
    };
  };

  getVisibleSocialIcons = () => {
    const userLinks = this.getUserSocialLinks();
    return _.map(userLinks, link => ({
      ...this.getSocialLinkData(link),
      link
    }));
  };

  getUserSocialLinks = () => _.filter(_.split(this.state.cur_social_links, ','), str => !!str);

  toggleAddPanel = async shouldSave => {
    const { collection, syncCollection } = this.props;
    const { showingAddPanel, cur_social_links } = this.state;
    if (shouldSave) {
      if (this.needsSave()) {
        try {
          this.setState({ savingCollection: true });
          const resp = await saveCollectionAPI(collection.id, {
            social_links: cur_social_links
          });
          if (!resp) throw new Error('Failed Save');
          await syncCollection(); // Needed to refetch collection specific calculations

          if (!resp.image && cur_social_links.includes('instagram')) {
            cur_social_links.includes('instagram') && cur_social_links.includes('stories')
              ? cogoToast.warn(
                  "Currently, Instagram does not enable you to link story content manually. To add stories to your ShopMy, please link your Instagram in 'Account Settings'.",
                  { hideAfter: 10 }
                )
              : cogoToast.warn(
                  `We were unable to pull an image for this link. Unfortunately Instagram only allows us to pull the image if your link is publicly visible and you have a Facebook account connected to your Instagram. If this isn't the case, manually upload an image or add it by connecting your Instagram account and adding it from the Posts tab on your shop page.`,
                  {
                    hideAfter: 10
                  }
                );
          } else {
            cogoToast.success(`Link${this.getUserSocialLinks().length === 1 ? '' : 's'} successfully updated.`, { hideAfter: 1 });
          }
        } catch (error) {
          cogoToast.error('There was an error saving your links, please reload the page and try again.');
          this.setState({
            cur_social_links: collection.social_links
          });
        }
      }
      this.setState({
        showingAddPanel: !showingAddPanel,
        new_social_link: '',
        savingCollection: false
      });
    } else {
      this.setState({
        showingAddPanel: !showingAddPanel,
        new_social_link: ''
      });
    }

    if (!showingAddPanel) {
      this.focusOnNewLinkInput();
    }
  };

  removeLink = linkToRemove => {
    const newLinks = _.filter(this.getUserSocialLinks(), oldLink => oldLink !== linkToRemove);
    this.setState({
      cur_social_links: newLinks.join(',')
    });
  };

  focusOnNewLinkInput = () => {
    setTimeout(() => {
      this.newLinkInput && this.newLinkInput.focus();
    }, 150);
  };

  addLink = event => {
    const { new_social_link } = this.state;
    event.preventDefault();
    if (new_social_link.length < 3) {
      cogoToast.error('Please add a valid URL.');
      return;
    }
    if (!new_social_link.includes('https://')) {
      cogoToast.error('URLs must begin with https://');
      return;
    }

    const newLinks = [...this.getUserSocialLinks(), new_social_link];
    this.setState({
      cur_social_links: newLinks.join(','),
      new_social_link: ''
    });

    this.focusOnNewLinkInput();
  };

  needsSave = () => this.props.collection.social_links !== this.state.cur_social_links;

  render() {
    const { canEdit, collection } = this.props;
    const { showingAddPanel, savingCollection, new_social_link } = this.state;
    const visibleSocialIcons = this.getVisibleSocialIcons();
    if (!canEdit && !visibleSocialIcons.length) return null;
    const isLongForm = collection.skinType === 'longform';
    return (
      <div
        className={classnames('collection-social-links-outer-container', {
          adding: showingAddPanel
        })}
      >
        <OutsideClickHandler onOutsideClick={() => showingAddPanel && this.toggleAddPanel()}>
          {!showingAddPanel && (
            <div className='collection-social-links-icons-container'>
              {!!visibleSocialIcons.length && !isLongForm && <div className='collection-more-label'>MORE ON THIS COLLECTION</div>}
              {_.map(visibleSocialIcons, ({ site, icon, link }, idx) => {
                const hasYoutubeLink = visibleSocialIcons.find(({ site }) => site === 'YouTube');
                return (
                  <a
                    href={link}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={classnames('collection-social-links-icon', { large: isYoutubeUserRequiredForAppCompliance(this.props.user) })}
                    key={idx}
                  >
                    {site ? (
                      <img className={classnames({ 'has-youtube': hasYoutubeLink })} alt={`${site} Logo`} src={icon} />
                    ) : (
                      <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                    )}
                  </a>
                );
              })}
              {canEdit && (
                <div onClick={this.toggleAddPanel} className='collection-social-links-icon add'>
                  {visibleSocialIcons.length ? <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> : 'ADD SOCIAL LINK'}
                </div>
              )}
            </div>
          )}
          {canEdit && showingAddPanel && (
            <div className={classnames('collection-social-links-edit-panel-container')}>
              <div className={classnames('collection-social-links-edit-panel')}>
                {_.map(visibleSocialIcons, ({ link }, idx) => {
                  const { icon, site } = this.getSocialLinkData(link);
                  return (
                    <div className='current-social-link' key={idx}>
                      <div className='link-container'>
                        {site ? (
                          <img className='link-example' alt={`${site} Logo`} src={icon} />
                        ) : (
                          <FontAwesomeIcon className='link-example' icon={faLink}></FontAwesomeIcon>
                        )}
                        <div className='current-social-link-url'>{link}</div>
                      </div>
                      <div className='current-social-link-remove-icon' onClick={() => this.removeLink(link)}>
                        <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                      </div>
                    </div>
                  );
                })}
                <div className='current-social-link'>
                  <div className='link-container'>
                    <form onSubmit={this.addLink}>
                      <input
                        type='text'
                        className='new-link-input'
                        placeholder={visibleSocialIcons.length ? 'Enter another link' : 'Paste a link to your social content'}
                        onChange={({ target }) => this.setState({ new_social_link: target.value })}
                        value={new_social_link}
                        ref={input => {
                          this.newLinkInput = input;
                        }}
                      />
                    </form>
                  </div>
                  {!!new_social_link.length && (
                    <div className='current-social-link-add-icon' onClick={this.addLink}>
                      <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                    </div>
                  )}
                </div>
                <div className='action-btns'>
                  <div onClick={() => this.toggleAddPanel(true)} className='done-btn btn'>
                    {savingCollection ? 'SAVING...' : this.needsSave() ? 'DONE' : 'CANCEL'}
                  </div>
                  <div
                    onClick={this.addLink}
                    className={classnames('add-btn btn', {
                      disabled: !new_social_link.length
                    })}
                  >
                    ADD
                  </div>
                </div>
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    );
  }
}

export default CollectionSocialLinks;
