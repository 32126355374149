import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import './TeamMemberModal.scss';

import Modal from '../../../Components/General/Modal';
import ImageUploader from '../../../Components/General/ImageUploader';
import { DropUploader } from '../../../Components';
import SelectOption from '../../../Components/General/SelectOption';
import Select from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';

const TeamMemberModal = props => {
  const { close, selectedTeamMemberId, teamMembers } = props;
  const { createTeamMember, updateTeamMember } = props;

  const inAddMode = !selectedTeamMemberId;
  const teamMember = inAddMode ? {} : teamMembers.find(member => member.id === selectedTeamMemberId);

  const uniqueDepartmentValues = useMemo(() => {
    const allDepartments = teamMembers.map(member => member.department);
    const uniqueDepartments = [...new Set(allDepartments)];
    return uniqueDepartments;
  }, [teamMembers]);
  const departmentOptions = uniqueDepartmentValues.map(department => ({ value: department, label: department }));
  const activeOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ];

  const [department, setDepartment] = useState(departmentOptions.find(option => option.value === teamMember.department));
  const [badge, setBadge] = useState(teamMember.badge);
  const [User_id, setUser_id] = useState(teamMember.User_id);
  const [name, setName] = useState(teamMember.name);
  const [isActive, setIsActive] = useState(activeOptions.find(option => option.value === !!teamMember.isActive));
  const [image, setImage] = useState(teamMember.image);
  const [isEditingImage, setIsEditingImage] = useState(false);

  const handleClick = () => {
    const data = { department: department?.value, badge, User_id, name, image, isActive: isActive?.value };

    if (inAddMode) createTeamMember(data);
    else updateTeamMember(teamMember, data);
  };

  const ensureValidFile = (_, rejectedFiles) => rejectedFiles.length && window.ALERT.error('Only PNG, JPG, JPEG and Gif files are supported.');
  const onUpload = image_url => setImage(image_url);

  return (
    <Modal visible showClose close={close} className='team-member-modal-outer' innerClassName='team-member-modal-inner'>
      <div className='team-member-modal-content'>
        <div className='team-member-modal-header'>{inAddMode ? 'Add Team Member' : 'Edit Team Member'}</div>

        <div className='team-member-modal-body'>
          <div className='team-member-modal-image-container'>
            <ImageUploader
              isVisible={isEditingImage}
              setIsVisible={setIsEditingImage}
              initialImageUrl={image}
              onSaveCallback={image_url => setImage(image_url)}
            />
            <DropUploader onDrop={ensureValidFile} onUpload={onUpload} isDisabled={isEditingImage} onlyAllowImages>
              {image ? (
                <img src={image} alt={name} />
              ) : (
                <div className='team-member-modal-image-placeholder'>
                  <FontAwesomeIcon icon={faImage} />
                  <span>Drop an image here or click to upload</span>
                </div>
              )}
            </DropUploader>
          </div>
          <div className='team-member-modal-form'>
            <Select
              className='team-member-modal-select'
              value={department}
              onChange={option => setDepartment(option)}
              options={departmentOptions}
              placeholder='Department'
            />
            <Select
              label='Active'
              value={isActive}
              options={activeOptions}
              onChange={option => setIsActive(option)}
              components={{ Option: SelectOption }}
              placeholder='Employee Status'
            />
            <div>
              <div className='team-member-modal-input-label'>Badge</div>
              <input
                className='team-member-modal-input'
                value={badge}
                onChange={e => setBadge(e.target.value)}
                placeholder='"Founder", "Brand Team", "Talent Team", "Software", "Internal"...'
              />
            </div>
            <div>
              <div className='team-member-modal-input-label'>Name</div>
              <input className='team-member-modal-input' value={name} onChange={e => setName(e.target.value)} placeholder='Name' />
            </div>
            <div>
              <div className='team-member-modal-input-label'>User id</div>
              <input className='team-member-modal-input' value={User_id} onChange={e => setUser_id(e.target.value)} placeholder='User_id' />
            </div>
          </div>
        </div>
        <div className='disclaimer'>If you need to find a team member's User_id, search for it using the "Find Admin" query in PopSQL</div>
      </div>
      <div className='team-member-modal-footer'>
        <button onClick={close} className='team-member-modal-button cancel'>
          Cancel
        </button>
        <button onClick={handleClick} className='team-member-modal-button save'>
          {inAddMode ? 'Add' : 'Save'}
        </button>
      </div>
    </Modal>
  );
};

TeamMemberModal.propTypes = {
  close: PropTypes.func.isRequired,
  createTeamMember: PropTypes.func.isRequired,
  updateTeamMember: PropTypes.func.isRequired,
  selectedTeamMemberId: PropTypes.number,
  teamMembers: PropTypes.array.isRequired
};

export default TeamMemberModal;
