import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './DiscoverLists.scss';

import SortableList from '../General/SortableList';

import { openBulkTalentModal, openRequestModal } from '../../Actions/UIActions';
import { updateBrandList, deleteBrandList, augmentBrandLists } from '../../Actions/BrandActions';
import { getBrandLists } from '../../Helpers/user_helpers';

import DiscoverList from './Elements/DiscoverList';

const DiscoverLists = props => {
  const { user, augmentBrandLists } = props;

  // Add additional Data to the lists
  // This needs to run in this manner because the user sync call can take a while and override the data
  const [augmentedData, setAugmentedData] = React.useState({});
  useEffect(() => {
    augmentBrandLists().then(resp => {
      setAugmentedData(_.keyBy(resp.lists, 'id'));
    });
  }, []);
  const lists = getBrandLists(user).map(list => ({
    ...(augmentedData[list.id] || {}),
    ...list
  }));

  const getCard = (list, additionalProps = {}) => {
    return (
      <DiscoverList
        list={list}
        openBulkTalentModal={props.openBulkTalentModal}
        openRequestModal={props.openRequestModal}
        updateBrandList={props.updateBrandList}
        deleteBrandList={props.deleteBrandList}
        key={list.id}
        ui={props.ui}
        {...additionalProps}
      />
    );
  };

  return (
    <div className='discover-lists-container'>
      {lists.length ? (
        <SortableList
          isEditing
          containerClassName='discover-lists'
          items={lists}
          updateItem={props.updateBrandList}
          props={props}
          getCard={getCard}
        />
      ) : (
        <div className='empty-discover-lists-alert'>
          <div className='header'>You do not have any Creator Lists</div>
          <div className='subheader'>
            Lists are a great way to keep track of groups of creators at the same time. You can send them bulk messages or gifting, track social
            mentions and more. To make a list, click on the heart icons when viewing creators throughout the platform.
          </div>
        </div>
      )}
    </div>
  );
};

DiscoverLists.propTypes = {
  user: PropTypes.object.isRequired,
  augmentBrandLists: PropTypes.func.isRequired,
  updateBrandList: PropTypes.func.isRequired,
  deleteBrandList: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  augmentBrandLists,
  updateBrandList,
  deleteBrandList,
  openBulkTalentModal,
  openRequestModal
})(DiscoverLists);
