import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './DiscoverFeatures.scss';

import { updateDiscoverFeature, deleteDiscoverFeature } from '../../Actions/DiscoverActions';
import { openArtistModal, openChatOverlay } from '../../Actions/UIActions';

import Loader from '../Loader/Loader';

import HeroFeatures from './Elements/HeroFeatures';
import PopularFeatures from './Elements/PopularFeatures';
import OtherFeatures from './Elements/OtherFeatures';

const DiscoverFeatures = props => {
  const { activeGroup } = props.discover;
  const { features } = activeGroup || {};

  const visibeFeatures = props.isEditing ? features : features?.filter(f => !f.isHidden);

  const heroFeatures = visibeFeatures?.filter(f => f.isHero);
  const popularFeatures = visibeFeatures?.filter(f => f.isPopular);
  const otherFeatures = visibeFeatures?.filter(f => !f.isPopular && !f.isHero);

  const goToList = list => props.checkSubscriptionLevelThenRunFn(() => props.history.push(`/discover/${list?.id}`));

  return (
    <div className='discover-features-container'>
      {!activeGroup ? (
        <div className='loading'>
          <Loader />
        </div>
      ) : (
        <div className='discover-features'>
          <HeroFeatures
            isEditing={props.isEditing}
            features={heroFeatures}
            updateDiscoverFeature={props.updateDiscoverFeature}
            deleteDiscoverFeature={props.deleteDiscoverFeature}
            goToList={goToList}
          />
          <PopularFeatures
            isEditing={props.isEditing}
            features={popularFeatures}
            updateDiscoverFeature={props.updateDiscoverFeature}
            deleteDiscoverFeature={props.deleteDiscoverFeature}
            goToList={goToList}
          />
          <OtherFeatures
            talent={props.talent}
            isEditing={props.isEditing}
            features={otherFeatures}
            updateDiscoverFeature={props.updateDiscoverFeature}
            deleteDiscoverFeature={props.deleteDiscoverFeature}
            openArtistModal={props.openArtistModal}
            openChatOverlay={props.openChatOverlay}
            goToList={goToList}
          />
        </div>
      )}
    </div>
  );
};

DiscoverFeatures.propTypes = {
  // From outside
  isEditing: PropTypes.bool.isRequired,
  checkSubscriptionLevelThenRunFn: PropTypes.func.isRequired,

  // From Redux
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  discover: PropTypes.object.isRequired,
  updateDiscoverFeature: PropTypes.func.isRequired,
  deleteDiscoverFeature: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, discover, talent } = state;
  return { user, discover, talent };
};

export default connect(mapStateToProps, {
  updateDiscoverFeature,
  deleteDiscoverFeature,
  openArtistModal,
  openChatOverlay
})(withRouter(DiscoverFeatures));
