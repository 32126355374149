import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import SocialCountPills from '../../SocialCountPills/SocialCountPills';
import BrandListsOverlay from '../../General/BrandListsOverlay';
import { getAllBrandListsFoundIn } from '../../../Helpers/brand_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { getSmartArtistBio } from '../../../Helpers/user_helpers';

import './RecommendationResult.scss';
import { formatUsersForBulkRequests } from '../../../Helpers/gifting_helpers';

const RecommendationResult = props => {
  const { recommendation, user, ui, onSendGifting, isRefreshingRecommendations, onRemoveRecommendation } = props;
  const { name, image, products, User_id } = recommendation;

  const [isSelectingBrandList, setIsSelectingBrandList] = useState(false);
  const [isDisplayingFullDescription, setIsDisplayingFullDescription] = useState(false);
  const toggleFullDescription = () => setIsDisplayingFullDescription(!isDisplayingFullDescription);

  const inBrandLists = getAllBrandListsFoundIn(user, { id: User_id }, { hideRecommendationLists: !isAdminControlMode(ui) });
  const isInList = !!inBrandLists.length;

  const sendGifting = async () => {
    props.openRequestModal({
      params: {
        preselectedUsers: formatUsersForBulkRequests([recommendation])
      },
      submitCallback: () => onSendGifting(User_id)
    });
  };

  const handleRemoveRecommendation = async () => {
    await onRemoveRecommendation(User_id);
  };

  const additionalClasses = { 'is-refreshing': isRefreshingRecommendations };

  return (
    <div className={cn('recommendation-outer', additionalClasses)}>
      <div className='recommendation-inner'>
        <div className='recommendation-image-wrapper'>
          <div className={cn('recommendation-image', additionalClasses)}>
            <img src={image} alt={name} />
            <div className={cn('recommendation-image-action-overlay')}>
              <div className='recommendation-image-action-btn'>
                <FontAwesomeIcon icon={isInList ? faHeartSolid : faHeart} onClick={() => setIsSelectingBrandList(true)} />
              </div>
            </div>
          </div>
        </div>
        <div className='recommendation-data-wrapper fade-on-refresh'>
          <div className='recommendation-data'>
            <div className='header-info'>
              <div className='user-name'>{name}</div>
              <SocialCountPills user={recommendation} theme={'dark'} size={'medium'} />
            </div>
            <div className='recommendation-sections'>
              <div className='recommendation-section'>
                <div className='recommendation-header'>Why we're recommending:</div>
                <div onClick={toggleFullDescription} className={cn('recommendation-reasoning-text', { extended: isDisplayingFullDescription })}>
                  {getSmartArtistBio(recommendation)}
                </div>
              </div>
              {products.length ? (
                <div className='recommendation-section'>
                  <div className='recommendation-header'>Relevant products they've driven sales to:</div>
                  <div className='recommendation-products'>
                    {products.map((product, idx) => (
                      <div className='similar-product' key={`${product.Product_id}-${idx}`}>
                        <div className='product-image'>
                          <img src={product.image} alt={product.name} />
                        </div>
                        <div className='product-info'>
                          <div className='product-brand'>{product.brand}</div>
                          <div className='product-title'>{product.title}</div>
                          <div className='product-title'>{product.id}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className='recommendation-actions'>
            <div className='recommendation-actions-top'>
              <button className='recommendation-action-btn primary' onClick={sendGifting}>
                send gifting
              </button>
              <BrandListsOverlay
                syncAfterEdit={null}
                closeOverlay={() => setIsSelectingBrandList(false)}
                selectedUser_id={User_id}
                isActive={isSelectingBrandList}
              >
                <button className='recommendation-action-btn secondary' onClick={() => setIsSelectingBrandList(true)}>
                  add to list
                  <FontAwesomeIcon icon={faHeart} />
                </button>
              </BrandListsOverlay>
            </div>
            <button className='recommendation-action-btn hide-rec' onClick={handleRemoveRecommendation}>
              Remove
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

RecommendationResult.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  recommendation: PropTypes.object.isRequired,
  onSendGifting: PropTypes.func.isRequired,
  isRefreshingRecommendations: PropTypes.bool.isRequired,
  onRemoveRecommendation: PropTypes.func.isRequired
};

export default RecommendationResult;
