import APIClient from './index';
import _ from 'lodash';

export const getConsult = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Consults/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const addConsult = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Consults`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const editConsult = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Consults/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const deleteConsult = consult => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/Consults/${consult.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const getConsultResult = stub => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/ConsultResults/by_stub/${stub}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const addConsultResult = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ConsultResults`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const editConsultResult = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ConsultResults/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const deleteConsultResult = contentResult => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/ConsultResults/${contentResult.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const prepareConsultPayment = consult => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Consults/prepare_payment/${consult.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};
