import React from 'react';
import { Link } from 'react-router-dom';
import './BrandPitchSection.scss';

import insightsIcon from '../../static/images/onboarding/Analytics_Icon.png';
import commissionsIcon from '../../static/images/onboarding/Commissions_Icon.png';
import seedingIcon from '../../static/images/onboarding/Seeding_Icon.png';

import mac from '../../static/images/landing/mac_pro.png';

const BrandPitchSection = props => {
  const talent = [
    {
      img: 'https://static.shopmy.us/uploads/cae618bc-3516-4e7d-ae47-9d9ce413d316_gucci-westman-15.JPG',
      name: 'Gucci Westman',
      username: 'gucciwestman',
      desc: `Born in California and raised in Sweden, Gucci Westman has more than 20 years of professional experience as a major editorial makeup artist, contributing to publications like Vogue and Harper’s Bazaar.`
    },
    {
      img: 'https://static.shopmy.us/uploads/c9ca2cc6-8e96-46a3-a17e-559f5c2ee0d1_shereene-2.png',
      name: 'Dr. Shereene Idriss',
      username: 'shereeneidriss',
      desc: `Dr. Shereene Idriss is an NYC-based Board Certified dermatologist. She’s a leading expert in the field of state-of-the-art
                      cosmetic procedures with specialties in facial aesthetics and rejuvenation.`
    },
    {
      img: 'https://static.shopmy.us/uploads/1eb57e63-b555-4d2e-820f-54d96fc9fb86_adir-2.png',
      name: 'Adir Abergel',
      username: 'adirabergel',
      desc: `Touted as the “Hitmaker of Hollywood Hairdos” by the Wall Street Journal, Adir Abergel is the creative force behind some of the most memorable moments in celebrity hairstyling. His client list includes Jennifer Garner, Reese Witherspoon, Kristen Stewart and Anne Hathaway to name just a few. Adir’s mission has always been to empower women and men through the power of hair.`
    }
  ];

  const newCustomerUpsells = [
    { message: 'Product Seeding', icon: seedingIcon },
    { message: 'Partnerships with Elite Beauty Talent', icon: commissionsIcon },
    { message: 'Brand Performance', icon: insightsIcon }
  ];
  return (
    <div className='brand-pitch-section-outer-container'>
      <div className='section'>
        <div className='section-inner'>
          <div className='section-panel pitch'>
            <h3 className='section-header'>
              Work with <span className='important'>elite talent</span>
            </h3>
            <div className='section-body'>
              Work with top experts and digital creators across YouTube, Instagram and TikTok who frequently talk about products on their channels -
              from the biggest names in the beauty industry to the most popular beauty enthusiasts on social.
            </div>
          </div>
          <div className='section-panel'>
            <div className='elite-talent-containers'>
              {talent.map(({ img, username, name, desc }, idx) => (
                <div to={`/${username}`} key={idx} className='elite-talent-container'>
                  <img src={img} alt={name} className='elite-talent-img' />
                  <div className='elite-talent-meta'>
                    <Link to={`/${username}`} className='elite-talent-name'>
                      {name}
                    </Link>
                    <div className='elite-talent-desc'>{desc}</div>
                  </div>
                </div>
              ))}
              <Link to='/shops' className='see-all-btn'>
                Click here to see hundreds more
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='section-inner'>
          <div className='section-panel'>
            <div className='img-container'>
              <img className='mac' src={mac} alt='Your Computer' />
            </div>
          </div>
          <div className='section-panel pitch'>
            <h3 className='section-header'>
              to <span className='important'>showcase</span> your products
            </h3>
            <div className='section-body'>
              Work with creators who genuinely love your product and offer them a beautiful platform to showcase your products.
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='section-inner'>
          <div className='section-panel pitch'>
            <h3 className='section-header'>
              and <span className='important'>reach new customers</span>
            </h3>
            <div className='section-body'>
              ShopMy enables brands to elevate their approach to creator marketing by connecting them to elite talent.
            </div>
          </div>
          <div className='section-panel'>
            <div className='upsells-container'>
              <div className='upsells'>
                {newCustomerUpsells.map(({ message, icon }, idx) => {
                  return (
                    <div key={idx} className='upsell'>
                      <img src={icon} alt={message} />
                      <div>{message}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPitchSection;
