import {
  fetchHierarchy,
  getProduct as getProductAPI,
  addReview as addReviewAPI,
  updateReview as updateReviewAPI,
  deleteReview as deleteReviewAPI
} from '../APIClient/shop';

export const GET_SHOP_HIERARCHY_SUCCESS = 'GET_SHOP_HIERARCHY_SUCCESS';
export const GET_FEATURED_BEST_IN_BEAUTY_SUCCESS = 'GET_FEATURED_BEST_IN_BEAUTY_SUCCESS';

export const SET_VISIBLE_PRODUCT = 'SET_VISIBLE_PRODUCT';

export const GET_VISIBLE_PRODUCT_REQUEST = 'GET_VISIBLE_PRODUCT_REQUEST';
export const GET_VISIBLE_PRODUCT_SUCCESS = 'GET_VISIBLE_PRODUCT_SUCCESS';
export const GET_VISIBLE_PRODUCT_FAILURE = 'GET_VISIBLE_PRODUCT_FAILURE';

export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS';
export const ADD_REVIEW_FAILURE = 'ADD_REVIEW_FAILURE';
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS';
export const UPDATE_REVIEW_FAILURE = 'UPDATE_REVIEW_FAILURE';
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS';
export const DELETE_REVIEW_FAILURE = 'DELETE_REVIEW_FAILURE';

export const getHierarchy = () => async (dispatch, getState) => {
  const hierarchy = await fetchHierarchy();
  return dispatch({
    type: GET_SHOP_HIERARCHY_SUCCESS,
    ...hierarchy
  });
};

export const setVisibleProduct = product => async (dispatch, getState) => {
  return dispatch({ type: SET_VISIBLE_PRODUCT, product });
};

export const getVisibleProduct = urlStub => async (dispatch, getState) => {
  dispatch({ type: GET_VISIBLE_PRODUCT_REQUEST, urlStub });
  try {
    const productResp = await getProductAPI(urlStub);
    return dispatch({
      type: GET_VISIBLE_PRODUCT_SUCCESS,
      product: productResp.product
    });
  } catch (error) {
    return dispatch({
      type: GET_VISIBLE_PRODUCT_FAILURE,
      error
    });
  }
};

export const addReview = data => async (dispatch, getState) => {
  try {
    const review = await addReviewAPI(data);
    return dispatch({
      type: ADD_REVIEW_SUCCESS,
      review
    });
  } catch (error) {
    return dispatch({
      type: ADD_REVIEW_FAILURE,
      error
    });
  }
};

export const updateReview = (review, updates) => async (dispatch, getState) => {
  try {
    const newReview = await updateReviewAPI(review, updates);
    return dispatch({
      type: UPDATE_REVIEW_SUCCESS,
      review: newReview
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_REVIEW_FAILURE,
      error
    });
  }
};

export const deleteReview = review => async (dispatch, getState) => {
  try {
    await deleteReviewAPI(review);
    return dispatch({
      type: DELETE_REVIEW_SUCCESS,
      review
    });
  } catch (error) {
    return dispatch({
      type: DELETE_REVIEW_FAILURE,
      error
    });
  }
};
