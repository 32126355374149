import React from 'react';
import PropTypes from 'prop-types';
import './AboutMember.scss';

import { connect } from 'react-redux';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';

const AboutMember = props => {
  const { member, ui, openTeamMemberModal } = props;
  const { name, title, image, user } = member;
  const { username } = user;

  return (
    <a href={`/${username}`} className='about-member-outer'>
      <div className='about-member-inner'>
        {isAdminControlMode(ui) && (
          <div className='admin-controls-container'>
            <div
              className='edit-member-button'
              onClick={e => {
                e.preventDefault();
                openTeamMemberModal(member);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </div>
            {props.rearrangeHandle}
          </div>
        )}
        <div className='person-image-container'>
          <img alt={`${name}, ${title}`} src={image} />
          <div className='name'>{name}</div>
          <div className='image-mask'></div>
        </div>
      </div>
    </a>
  );
};

AboutMember.propTypes = {
  member: PropTypes.object.isRequired,
  rearrangeHandle: PropTypes.object,
  openTeamMemberModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {})(AboutMember);
