import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './BlogTabModalTab.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

const BlogTabModalTab = props => {
  const { tab, additionalProps } = props;
  const { updateBlogTab, deleteBlogTab } = props;
  const { isHidden, posts } = tab;

  const [title, setTitle] = useState(tab.title);
  const canSave = title !== tab.title;

  return (
    <div className='blog-tab-modal-tab'>
      <div>
        {canSave ? <FontAwesomeIcon icon={faCheck} onClick={() => updateBlogTab(tab, { title })} /> : <>{additionalProps.rearrangeHandle}</>}
        <input
          type='text'
          placeholder='Update Title'
          value={title}
          onChange={e => {
            let title = e.target.value;
            title = title.replace(/\s/g, '-');
            title = title.toLowerCase();
            setTitle(title);
          }}
        />
      </div>
      <div className='blog-tab-modal-icon-container'>
        <div className='post-count'>
          {posts.length} {posts.length === 1 ? 'Post' : 'Posts'}
        </div>
        <FontAwesomeIcon
          icon={faEyeSlash}
          className={cn('blog-tab-modal-icon', { isHidden })}
          onClick={() => updateBlogTab(tab, { isHidden: !isHidden })}
        />
        <FontAwesomeIcon icon={faTimes} className='blog-tab-modal-icon' onClick={() => deleteBlogTab(tab)} />
      </div>
    </div>
  );
};

BlogTabModalTab.propTypes = {
  tab: PropTypes.object.isRequired,
  additionalProps: PropTypes.object.isRequired,

  // redux actions
  updateBlogTab: PropTypes.func.isRequired,
  deleteBlogTab: PropTypes.func.isRequired
};

export default BlogTabModalTab;
