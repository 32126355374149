import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookProductModal.scss';

import Modal from '../../../General/Modal';
import _ from 'lodash';
import LookbookProductModalCatalog from './LookbookProductModalCatalog';

import LookbookProductModalCustomizeProduct from './LookbookProductModalCustomizeProduct';
import { fetchAllPreviousLookbookItems } from '../../../../APIClient/lookbooks';
import { productIndex } from '../../../../Helpers/search_helpers';
import cogoToast from 'cogo-toast';
import { isAdminControlMode } from '../../../../Helpers/ui_helpers';

let searchDebouncer;

const LookbookProductModal = props => {
  const { ui, user, brand, lookbook, itemIdBeingEdited, updateLookbookItem, openEditModal, close, saving, goBackToCatalog } = props;
  const { addLookbookItemSibling, updateLookbookItemSibling, deleteLookbookItemSibling } = props;
  const { name: Brand_name } = brand;

  const addPinModalRef = useRef(null);

  const itemBeingEdited = lookbook.items.find(item => item.id === itemIdBeingEdited);
  const [previousLookbookItems, setPreviousLookbookItems] = useState([]);
  const [customizableProduct, setCustomizableProduct] = useState(itemBeingEdited);

  const inAddMode = !itemBeingEdited;
  const inCustomizingMode = !_.isNil(customizableProduct);
  const inEditMode = !inAddMode;

  const [hasHadFirstResults, setHasHadFirstResults] = useState(false); // we want a different animation before the first results are loaded
  const [searching, setSearching] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchPage, setSearchPage] = useState(0);
  const [fetchingNextPage, setFetchingNextPage] = useState(false);
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [failedSearch, setFailedSearch] = useState(false);

  const existingProducts = lookbook.items;

  /******************************************************************************************* */
  // DATA FETCHING
  /******************************************************************************************* */

  const showAllBrands = isAdminControlMode(ui) && Brand_name === 'ShopMy';
  const [testingBrand, setTestingBrand] = useState(null);
  useEffect(() => {
    const retailersToFilter = [];
    if (testingBrand) retailersToFilter.push(`retailer:"${testingBrand}"`);
    if (!showAllBrands) retailersToFilter.push(`retailer:"${Brand_name}"`);
    if (brand.altBrands) {
      const altBrands = brand.altBrands?.trim().split(',');
      altBrands.forEach(altBrand => retailersToFilter.push(`retailer:"${altBrand}"`));
    }

    if (searchDebouncer) clearTimeout(searchDebouncer);
    searchDebouncer = setTimeout(() => {
      productIndex
        .search(searchValue, {
          page: searchPage,
          hitsPerPage: 20,
          filters: retailersToFilter.join(' OR ')
        })
        .then(({ hits }) => {
          if (hits.length === 20) setHasMoreResults(true);
          else setHasMoreResults(false);

          hits = hits.reverse();
          if (searchPage === 0) setSearchResults(hits);
          else setSearchResults([...searchResults, ...hits]);
        })
        .catch(() => {
          if (searchResults.length === 0) setFailedSearch(true);
        })
        .finally(() => {
          setSearching(false);
          setFetchingNextPage(false);
          setHasHadFirstResults(true);
        });
    }, 500);
  }, [searchValue, searchPage, testingBrand]);

  // Data Syncing
  const lookbookItemsHash = itemBeingEdited?.siblings
    .map(s =>
      [
        s.title,
        s.price,
        s.image,
        s.id,
        s.shopifyVariantId,
        s.isDefault ? 'default' : null
        // If you want to resync the outer when an item level thing changes, add to this array
      ]
        .filter(Boolean)
        .join('-')
    )
    .join(' | ');

  useEffect(() => {
    setCustomizableProduct(itemBeingEdited);
  }, [lookbookItemsHash]);

  // fetch previously added items from db
  useEffect(() => {
    if (!inAddMode) return;

    fetchAllPreviousLookbookItems(lookbook.Brand_id)
      .then(results => setPreviousLookbookItems(results.lookbook_items || []))
      .catch(() => cogoToast.error('Error fetching previous lookbook items'));
  }, []);

  // Add listener to load more results when scrolling to bottom
  useEffect(() => {
    if (!inAddMode) return;
    else if (!addPinModalRef.current) return;
    if (searching || fetchingNextPage) return;

    const handleScroll = e => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      const pixelsFromBottom = scrollHeight - scrollTop - clientHeight;
      if (pixelsFromBottom < 100) getNextPage();
    };

    addPinModalRef.current.addEventListener('scroll', handleScroll);
    return () => addPinModalRef.current.removeEventListener('scroll', handleScroll);
  }, [selectedFilter, searchPage, searchValue, searching, fetchingNextPage]);

  /******************************************************************************************* */
  // SEARCHING AND FILTERING
  /******************************************************************************************* */

  const getNextPage = () => {
    if (!hasMoreResults) return;
    else if (selectedFilter && selectedFilter?.label !== 'Full Catalog') return console.info('returning because of selected filter');
    else if (searching) return console.info('returning because of searching');
    else if (!hasMoreResults) return console.info('returning because of hasMoreResults');

    setFetchingNextPage(true);
    setSearchPage(searchPage + 1);
  };

  const setSearch = value => {
    setSearching(true);
    setHasMoreResults(true);
    setSearchPage(0);

    setSearchValue(value);
  };

  const resetSearchAndFilter = () => {
    selectedFilter && setSelectedFilter(null);

    searchValue && setSearchValue('');
    searchValue && setSearchPage(0);
    searchValue && setHasMoreResults(true);
  };

  /******************************************************************************************* */
  // LOOKBOOK ITEM ACTIONS
  /******************************************************************************************* */

  const addLookbookItemFromCatalog = async (data, options) => {
    const newItem = await props.addLookbookItem(data, { shouldClose: false });
    if (newItem && !options.fromPreviousItems) setPreviousLookbookItems([...previousLookbookItems, newItem]);
  };

  const createEmptyLookbookItem = async (product, shouldClose) => {
    const newItemData = {
      item: { Lookbook_id: lookbook.id, overrideTitle: '', ...product },
      extraSiblings: [{ isDefault: true }]
    };
    const newItem = await props.addLookbookItem(newItemData, { shouldClose: !!shouldClose });
    setCustomizableProduct(newItem);
    return newItem;
  };

  const removeSelectedProduct = async product => {
    let lookbookItem;
    if (product?.Lookbook_id) lookbookItem = product;
    else lookbookItem = lookbook.items.find(item => item.url === product.url);
    await props.removeLookbookItem(lookbookItem);
  };

  const showCustomizeProductModal = customizableProduct && ((inAddMode && inCustomizingMode) || inEditMode);
  return (
    <Modal
      visible
      showClose
      close={close}
      className='lookbook-product-modal-outer'
      innerClassName='lookbook-product-modal-inner'
      scrollRef={addPinModalRef}
    >
      <div className='lookbook-product-modal'>
        {inAddMode && !inCustomizingMode && (
          <LookbookProductModalCatalog
            user={user}
            ui={ui}
            brand={brand}
            lookbook={lookbook}
            addLookbookItem={addLookbookItemFromCatalog}
            existingProducts={existingProducts}
            searching={searching}
            hasMoreResults={hasMoreResults}
            getNextPage={getNextPage}
            removeSelectedProduct={removeSelectedProduct}
            setSearch={setSearch}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            setCustomizableProduct={setCustomizableProduct}
            failedSearch={failedSearch}
            close={close}
            saving={saving}
            searchResults={searchResults}
            previousLookbookItems={previousLookbookItems}
            openEditModal={openEditModal}
            setTestingBrand={setTestingBrand}
            resetSearchAndFilter={resetSearchAndFilter}
            hasHadFirstResults={hasHadFirstResults}
            searchValue={searchValue}
            fetchingNextPage={fetchingNextPage}
            showAllBrands={showAllBrands}
            key='variant-catalog-search'
            createEmptyLookbookItem={createEmptyLookbookItem}
            isReplaceMode={props.isReplaceMode}
            itemBeingReplaced={props.itemBeingReplaced}
          />
        )}
        {showCustomizeProductModal && (
          <LookbookProductModalCustomizeProduct
            ui={ui}
            user={user}
            brand={brand}
            lookbook={lookbook}
            syncLookbook={props.syncLookbook}
            customizableProduct={customizableProduct}
            updateLookbookItem={updateLookbookItem}
            addLookbookItemSibling={addLookbookItemSibling}
            updateLookbookItemSibling={updateLookbookItemSibling}
            deleteLookbookItemSibling={deleteLookbookItemSibling}
            goBackToCatalog={goBackToCatalog}
            saving={saving}
          />
        )}
      </div>
    </Modal>
  );
};

LookbookProductModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  itemIdBeingEdited: PropTypes.number,
  syncLookbook: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  isReplaceMode: PropTypes.bool,
  itemBeingReplaced: PropTypes.object,

  // lookbook items
  addLookbookItem: PropTypes.func.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  removeLookbookItem: PropTypes.func.isRequired,

  // lookbook item siblings
  addLookbookItemSibling: PropTypes.func.isRequired,
  updateLookbookItemSibling: PropTypes.func.isRequired,
  deleteLookbookItemSibling: PropTypes.func.isRequired,
  goBackToCatalog: PropTypes.func.isRequired,

  close: PropTypes.func.isRequired
};

export default LookbookProductModal;
