import APIClient from './index';
import ReactGA from 'react-ga4';
import TiktokPixel from 'tiktok-pixel';
import LinkedInTag from 'react-linkedin-insight';

/*
  While accessing the redux store directly is not recommended, we do it here because we want to access
  the user object globally without requiring it to be passed down through props. Please do not replicate
  this pattern elsewhere in the codebase unless you are sure you need to.
*/
import storeData from '../configureStore';

import { getDomainFromUrl } from '../Helpers/formatting';
import { LINKEDIN_PARTNER_ID, LINKEDIN_CONVERSION_IDS } from '../Helpers/linkedin_helpers';
import { initializeAmplitude, logAmplitudeEvent } from '../Helpers/amplitude_helpers';
import { isSimulatingUser } from '../Helpers/user_helpers';

// Initialize Amplitude
try {
  initializeAmplitude();
} catch (e) {
  console.error('Failed to initialize Amplitude', e);
}

// Initialize Tiktok Pixel
try {
  if (!window.__IS_SNAP__) {
    TiktokPixel.init(process.env.REACT_APP_TIKTOK_PIXEL_ID);
  }
} catch (e) {
  console.error('Failed to initialize Tiktok Pixel');
}

// Initialize Google Analytics
try {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_4_ID, { testMode: window.__IS_DEV__ });
} catch (e) {
  console.error('Failed to initialize Google Analytics 4');
}

// Initialize LinkedIn Insight Tag
try {
  LinkedInTag.init(LINKEDIN_PARTNER_ID);
} catch (e) {
  console.error('Failed to initialize LinkedIn Insight Tag');
}

const addEvent = async (name, extra_data = {}, logToConsole = false) => {
  // Do not send events from snap
  if (window.__IS_SNAP__) return;

  // Handle Amplitude Eventing - simulating users is handled in the helper
  logAmplitudeEvent(name, extra_data);

  // Do not send events when simulating
  if (extra_data.user && isSimulatingUser(storeData.store.getState().user)) return;

  // Log to console for easy debugging
  logToConsole && console.info('Event: ', name, extra_data); // for easy debugging

  // Send general event to Google Analytics
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  // Specific for Google Ads
  if (name === 'Collections - Add Pin' || name === 'Quick Links - Add Pin') {
    ReactGA.event({
      category: 'Conversion',
      action: 'ADD_PIN',
      ...extra_data
    });
  }

  // Specific for Google Ads
  if (name?.includes('Successfully Registered')) {
    ReactGA.event({
      category: 'Conversion',
      action: 'REGISTERED',
      ...extra_data
    });
    TiktokPixel.track('CompleteRegistration', { email: extra_data.email });
  }

  // Specific for Google Ads
  if (name?.includes('Submitted Application')) {
    ReactGA.event({
      category: 'Conversion',
      action: 'APPLIED',
      ...extra_data
    });
    TiktokPixel.track('SubmitForm', { email: extra_data.email });

    if (extra_data?.brand_website) {
      LinkedInTag.track(LINKEDIN_CONVERSION_IDS.BRAND_APPLICATION);
    }
  }

  if (['PIN_VIEW', 'COLLECTION_VIEW', 'CONSULT_VIEW', 'SHOP_VIEW', 'BLOG_POST_VIEW', 'PRODUCT_VIEW'].includes(name)) {
    const { collectionId, userId, consultId, productId, eventUserId, pinId, pinLink, postId } = extra_data;
    const label = extra_data.pinTitle || extra_data.collectionName || extra_data.shopName || extra_data.title;
    const category = extra_data.shopUsername || extra_data.title || name;
    const domain = pinLink ? getDomainFromUrl(pinLink) : null;

    ReactGA.event({
      category: category,
      action: name,
      label: label,
      ...extra_data
    });

    return await APIClient.post('Events', {
      type: name,
      userId,
      ...(eventUserId ? { eventUserId } : {}),
      ...(domain ? { domain } : {}),
      pinId,
      productId,
      collectionId,
      consultId,
      postId
    })
      .then(response => response.data)
      .catch(err => console.error(err));
  }
};

/* Place this on the global object to avoid having to import this everywhere. */
window.__ADD_EVENT__ = addEvent;

export { addEvent };
