import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './SelectOption.scss';

/**
 * Helper component for react-select package allowing for custom rendering of options
 *
 * Example:
 *
 *  <Select
 *   ...
 *  components={{ Option: SelectOption }}
 * />
 *
 * @param {Object} props
 * @param {boolean} props.isDisabled - Whether the option is disabled
 * @param {Object} props.isSelected - Whether the option is selected
 * @param {Object} props.data - Data object passed to react-select
 * @param {string} props.data.label - Title of the option
 * @param {string} props.data.sublabel - Subtitle of the option
 * @param {string[]} props.data.sublabels - Array of subtitles of the option, each will be separated by a bullet
 */
const SelectOption = props => {
  const { data, isDisabled, isSelected } = props;

  const sublabels = data.sublabel ? [data.sublabel] : data.sublabels || [];
  return (
    <div {...props.innerProps} className={cn('selection-option-outer-container', { disabled: isDisabled, selected: isSelected })}>
      <div className='title'>{data.label}</div>
      {!!sublabels.length && <div className='subtitle'>{sublabels.filter(a => a).join(' • ')}</div>}
    </div>
  );
};

SelectOption.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired, // This is a Title
    sublabel: PropTypes.string, // Created on March 23rd
    sublabels: PropTypes.array // ['New', '10 products']
  }).isRequired
};

export default SelectOption;
