import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './BrandModal.scss';

import { getBrand } from '../../APIClient/brands';
import { closeBrandModal } from '../../Actions/UIActions';
import { getBrandModalStartingParams } from '../../Helpers/ui_helpers';

import BrandHeader from './BrandHeader';
import BrandSales from './BrandSales';
import BrandPromoters from './BrandPromoters';
// import BrandLookbooks from './BrandLookbooks';
import BrandProducts from './BrandProducts';

import Modal from '../General/Modal';

const BrandModal = props => {
  const { ui, user, closeBrandModal } = props;

  // Fetch data
  const [isFetching, setIsFetching] = useState(true);
  const [brand, setBrand] = useState(getBrandModalStartingParams(ui)?.brand);
  useEffect(() => {
    getBrand(brand.id)
      .then(({ brand }) => setBrand(brand))
      .catch(error => {
        window.ALERT.error(`Could not find details on this brand, please try again.`);
        props.closeBrandModal();
      })
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <Modal
      visible
      showClose
      close={closeBrandModal}
      className='brand-modal-outer-container'
      innerClassName='brand-modal-inner-container'
      contentClassName='brand-modal-content'
    >
      <BrandHeader brand={brand} user={user} isFetching={isFetching} closeModal={closeBrandModal} />
      <BrandPromoters brand={brand} isFetching={isFetching} />
      <BrandSales brand={brand} isFetching={isFetching} />
      {/* <BrandLookbooks brand={brand} isFetching={isFetching} /> */} {/* Coming Soon */}
      <BrandProducts brand={brand} isFetching={isFetching} />
    </Modal>
  );
};

BrandModal.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  closeBrandModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  closeBrandModal
})(BrandModal);
