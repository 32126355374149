import axios from 'axios';
import _ from 'lodash';

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
axios.defaults.headers['Content-Type'] = 'application/json';

const APIClient = () => {
  return axios;
};

export const get400ErrorMessage = error => {
  return _.get(error, 'data.msg', false);
};

export default APIClient();
