import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import commaNumber from 'comma-number';
import _ from 'lodash';
import cn from 'classnames';
import './SubscriptionOfferModal.scss';

import Modal from '../General/Modal';
import VideoPlayer from '../General/VideoPlayer';
import Loader from '../Loader/Loader';

import { getSubscriptionOffers, logUpsellOpportunity } from '../../APIClient/subscriptions';

import { subscribeToOffer } from '../../Actions/SubscriptionActions';
import { closeSubscriptionOfferModal } from '../../Actions/UIActions';

import { getSubscriptionOfferModalStartingParams } from '../../Helpers/ui_helpers';
import { getBrandSubscription, getBrandName, getBrandId } from '../../Helpers/user_helpers';
import { dontBubble } from '../../Helpers/helpers';

const SubscriptionOfferModal = props => {
  const { ui, user } = props;

  // Get feature and subscription
  const { feature, offer } = getSubscriptionOfferModalStartingParams(ui);
  const subscription = getBrandSubscription(props.user);
  const wasAttemptingToUseFeature = !!feature;

  // Action State Management
  const [errorMsg, setErrorMsg] = React.useState('');
  const [isSubscribing, setIsSubscribing] = React.useState(false);
  const [isSubscribingToTrial, setIsSubscribingToTrial] = React.useState(false);

  // Modal management
  const closeModal = () => props.closeSubscriptionOfferModal();

  // Video Management
  const [showingVideoOverlay, setShowingVideoOverlay] = React.useState(false);
  const openVideoOverlay = () => setShowingVideoOverlay(true);
  const closeVideoOverlay = () => setShowingVideoOverlay(false);

  // Fetch all offers
  const [allOffers, setAllOffers] = React.useState([]);
  const isLoading = !allOffers.length;
  useEffect(() => {
    getSubscriptionOffers().then(res => setAllOffers(res));
  }, []);

  // Get the offer we need
  let requiredOffer;
  if (offer) {
    // Case one, we have been provided the entire offer object
    requiredOffer = offer;
  } else if (feature) {
    // Case two, we have been provided a feature and need to find the offer
    const offersForFeature = allOffers.filter(offer => {
      if (offer.isHidden) return false;
      const offerFeatures = offer.canAccessEnum?.split(',') || [];
      const offerDeprecatedFeatures = offer.canAccessDeprecatedEnum?.split(',') || [];
      return offerFeatures.includes(feature) || offerDeprecatedFeatures.includes(feature);
    });
    requiredOffer = _.orderBy(offersForFeature, ['isBase', 'isHidden', 'retailPrice'], ['desc', 'asc'])[0];
  }

  // Figure out alternative variations of the offer via VariationOfSubscriptionOffer_id
  const [selectedVariation, setSelectedVariation] = React.useState(null);
  const changeVariation = variation => {
    setErrorMsg('');
    setSelectedVariation(variation);
  };
  const currentlySelectedOffer = selectedVariation || requiredOffer;
  const variations = allOffers.filter(offer => {
    const isSiblingVariation =
      requiredOffer?.VariationOfSubscriptionOffer_id && offer.VariationOfSubscriptionOffer_id === requiredOffer?.VariationOfSubscriptionOffer_id;
    const isRootVariationForOffer = requiredOffer?.VariationOfSubscriptionOffer_id && offer.id === requiredOffer?.VariationOfSubscriptionOffer_id;
    const isVariationOfRequiredOffer = offer.VariationOfSubscriptionOffer_id === requiredOffer?.id;
    const isRequiredVariation = offer.id === requiredOffer?.id;
    return isSiblingVariation || isRootVariationForOffer || isVariationOfRequiredOffer || isRequiredVariation;
  });
  const rootVariation = variations.find(variation => !variation.VariationOfSubscriptionOffer_id);
  const isSubscribedToVariation =
    subscription && variations.some(variation => subscription.items.some(item => item.offer?.id === variation.id && !item.isCancelled));

  // Figure out what offers give this feature
  const requiredBaseOffer = allOffers.find(offer => offer.isBase && offer.module === currentlySelectedOffer?.module);
  const subscriptionItemForOffer = subscription && subscription.items.find(item => item.offer?.id === currentlySelectedOffer?.id);
  const isSubscribedToOffer = !!subscriptionItemForOffer;
  const isSubscribedToBase = subscription && subscription.items.filter(i => !i.isCancelled).some(item => item.offer?.id === requiredBaseOffer?.id);

  // If this is not yet available, log the upsell opportunity
  useEffect(() => {
    if (!isSubscribedToOffer && currentlySelectedOffer && !isLoading) {
      logUpsellOpportunity({
        Brand_id: getBrandId(user),
        SubscriptionOffer_id: currentlySelectedOffer.id,
        feature
      });
    }
  }, [isLoading]);

  // If we cannot find an offer, show an error and close the modal
  if (!currentlySelectedOffer && !isLoading) {
    window.ALERT.error('No offer found for this feature, please contact support');
    closeModal();
    return null;
  }

  // Get data for the Core UI
  const {
    title,
    coverImage,
    videoUrl,
    retailPrice,
    isService,
    isBase,
    addOnRequiresBase,
    offersTrial,
    trialDurationDays,
    minDurationMonths,
    description
  } = currentlySelectedOffer || {};
  const { isCancelled, trialEndsAt, cancelledAt } = subscriptionItemForOffer || {};

  // -- Subtitles
  let subtitles = [];
  wasAttemptingToUseFeature && subtitles.push('Requires Upgrade');
  subtitles.push(`$${commaNumber(retailPrice)} per month`);
  isService && subtitles.push('Service');

  // --  Major Features
  const features = currentlySelectedOffer?.features?.split(',') || [];

  // -- Used By
  const usedByRaw = currentlySelectedOffer?.usedBy?.split(',') || [];
  let [usedByNames, usedByLogos] = [[], []];
  usedByRaw.forEach((item, idx) => (idx % 2 ? usedByLogos.push(item.trim()) : usedByNames.push(item.trim())));
  usedByNames = usedByNames.filter(name => name !== getBrandName(user));

  // Actions
  const mustFirstSubscribeToBase = addOnRequiresBase && !isBase && !isSubscribedToBase;
  const attemptToSubscribeToBase = () => createSubscription(false);
  const attemptToSubscribeToTrial = () => createSubscription(true);
  const createSubscription = async (withTrial = false) => {
    const setStateFn = withTrial ? setIsSubscribingToTrial : setIsSubscribing;
    setStateFn(true);
    setErrorMsg('');
    const resp = await props.subscribeToOffer(currentlySelectedOffer, withTrial);
    if (resp.success) {
      if (withTrial) {
        trialDurationDays
          ? window.ALERT.success(`Successfully began your ${trialDurationDays} day free trial!`)
          : window.ALERT.success(`Successfully subscribed to ${currentlySelectedOffer.title} with a free trial`);
      } else {
        window.ALERT.success(`Successfully subscribed to ${currentlySelectedOffer.title}`);
      }
      closeModal();
    } else {
      setErrorMsg(resp.error);
    }
    setStateFn(false);
  };

  // Actions
  let subscribeAction, subscribeIsDisabled, trialAction, actionDisclaimer;
  if (mustFirstSubscribeToBase && !isSubscribedToBase) {
    subscribeAction = null;
    trialAction = null;
    actionDisclaimer = `You must subscribe to the ${requiredBaseOffer?.title} package before adding this feature`;
  } else if (isCancelled) {
    subscribeAction = 'Re-Subscribe';
    trialAction = null;
    actionDisclaimer = `You cancelled this subscription on ${moment(cancelledAt).format('MMMM Do, YYYY')}. Please click above to re-subscribe.`;
  } else if (isSubscribedToOffer) {
    const inTrial = !!trialEndsAt && moment().isBefore(moment(isSubscribedToOffer.trialEndsAt).add(trialDurationDays, 'days'));
    subscribeAction = 'Currently Subscribed';
    subscribeIsDisabled = true;
    trialAction = null;
    actionDisclaimer = inTrial
      ? `Your trial ends on ${moment(trialEndsAt).format('MMMM Do, YYYY')}`
      : `You subscribed to this package on ${moment(isSubscribedToOffer.startedAt).format('MMMM Do, YYYY')}`;
  } else if (currentlySelectedOffer) {
    subscribeAction = isSubscribedToVariation ? 'Switch Package' : 'Add To My Package';
    if (offersTrial) {
      trialAction = `Start ${trialDurationDays} day Free Trial`;
      actionDisclaimer = minDurationMonths
        ? `You will not be charged until the end of your trial period at which point there will be a ${minDurationMonths} month minimum commitment.`
        : `You will not be charged until the end of your trial period.`;
    } else {
      actionDisclaimer = minDurationMonths ? `This subscription requires a minimum ${minDurationMonths} month commitment.` : '';
    }
  }

  // Other UI
  const additionalClasses = {
    loading: isLoading,
    'is-subscribing': isSubscribing,
    'is-subscribing-to-trial': isSubscribingToTrial
  };

  return (
    <Modal
      visible
      noPadding
      forceOuter
      close={closeModal}
      className='subscription-offer-modal-outer-container'
      innerClassName='subscription-offer-modal-inner-container'
      contentClassName='subscription-offer-modal-content'
    >
      {showingVideoOverlay && (
        <div onClick={closeVideoOverlay} className='video-overlay'>
          <div onClick={dontBubble} className='video-container'>
            <VideoPlayer autoPlay src={videoUrl} />
          </div>
        </div>
      )}
      <>
        <div onClick={openVideoOverlay} className={cn('media-container', additionalClasses)}>
          {coverImage && <img className='cover-image' src={currentlySelectedOffer?.coverImage} alt='offer' />}
          {videoUrl && (
            <div className='play-video-cover'>
              <div className='play-icon'>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </div>
          )}
        </div>
        <div className='body-container'>
          {isLoading ? (
            <div className='content'>
              <div className='section'>
                <div className='subtitles loading'>...</div>
                <div className='title loading'>...</div>
                <div className='description loading'>...</div>
              </div>
            </div>
          ) : (
            <div className='content'>
              <div className='section'>
                <div className='subtitles'>{subtitles.join(' • ')}</div>
                <div className='title'>{title}</div>
                <div className='description'>{description}</div>
              </div>
              {!!features.length && (
                <div className='features-container section'>
                  <div className='section-title'>In this package</div>
                  <div className='features'>
                    {features.map((feature, idx) => (
                      <div className='feature' key={idx}>
                        <div className='icon'>
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                        <div className='text'>{feature}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {!!usedByNames.length && (
                <div className='used-by section'>
                  <div className='section-title'>Used By</div>
                  <div className='used-by-logos'>
                    {usedByNames.map((name, idx) => (
                      <div className='logo-container' key={name}>
                        <img src={usedByLogos[idx]} alt={name} />
                      </div>
                    ))}
                    <div className='and-more'>+ more</div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='actions'>
            {!!subscribeAction && (
              <div onClick={attemptToSubscribeToBase} className={cn('action', additionalClasses, { disabled: subscribeIsDisabled })}>
                {subscribeAction}
                {isSubscribing && <Loader size={48} />}
              </div>
            )}
            {!isLoading && trialAction && (
              <div onClick={attemptToSubscribeToTrial} className={cn('action trial', additionalClasses)}>
                {trialAction}
                {isSubscribingToTrial && <Loader size={48} />}
              </div>
            )}
            {!isLoading && errorMsg && <div className='error'>{errorMsg}</div>}
            {!isLoading && actionDisclaimer && <div className='disclaimer'>{actionDisclaimer}</div>}
            {variations.length > 1 && (
              <div className='variations-container'>
                <div className='variations-header'>Variations</div>
                <div className='variations'>
                  {[...variations].map((variation, idx) => {
                    const isRootVariation = !variation.VariationOfSubscriptionOffer_id;
                    const isRequiredVariation = variation.id === requiredOffer.id;
                    const isSelected = selectedVariation?.id === variation.id || (!selectedVariation && isRequiredVariation);
                    const title = isRootVariation ? 'Standard' : variation.title.replace(`${rootVariation.title} - `, '');
                    const priceDiff = variation.retailPrice - requiredOffer.retailPrice;
                    const select = () => changeVariation(variation);
                    return (
                      <div
                        onClick={select}
                        className={cn('variation', {
                          selected: isSelected
                        })}
                        key={idx}
                      >
                        <div className='main'>
                          <div className='radio' />
                          <div className='title'>{title}</div>
                        </div>
                        {Math.abs(priceDiff) > 0 ? (
                          <div className='price-diff'>
                            {priceDiff > 0 ? '+' : '-'}${commaNumber(Math.abs(priceDiff))}
                          </div>
                        ) : (
                          isSelected && <FontAwesomeIcon icon={faCheck} />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
      <div onClick={showingVideoOverlay ? closeVideoOverlay : closeModal} className='close-icn'>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </Modal>
  );
};

SubscriptionOfferModal.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,

  subscribeToOffer: PropTypes.func.isRequired,
  closeSubscriptionOfferModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui, user } = state;
  return { ui, user };
};

export default connect(mapStateToProps, {
  closeSubscriptionOfferModal,
  subscribeToOffer
})(SubscriptionOfferModal);
