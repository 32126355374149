import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './CommissionResult.scss';

import { formatAmountInCurrency, getPrettyDate } from '../../Helpers/formatting';
import { isSubscribedToFeatureOrAdmin, blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';

import Tooltip from '../General/Tooltip';
import Loader from '../Loader/Loader';

const CommissionResult = props => {
  const { user, commission, action, action_label } = props;
  const {
    order_id,
    order_amount,
    User_id,
    code,
    currency,
    commission_amount,
    isReturningCustomer,
    transaction_date,
    status_badge,
    status_explanation,
    name
  } = commission;

  const [isTakingAction, setIsTakingAction] = useState(false);

  const formattedOrderAmount = formatAmountInCurrency(order_amount, currency);
  const formattedCommissionAmount = formatAmountInCurrency(commission_amount, currency);
  const hasCorePackage = isSubscribedToFeatureOrAdmin(user, 'AFFILIATES');

  const takeAction = async () => {
    setIsTakingAction(true);
    action && (await action(commission));
    setIsTakingAction(false);
  };

  const selectUser = async () => {
    if (blockOnRequiringSubscription(user, 'AFFILIATES')) return null;
    props.openArtistModal({ id: User_id });
  };

  return (
    <div className={cn('commission-result row', { blocked: !hasCorePackage })}>
      <div className='cell'>{getPrettyDate(transaction_date)}</div>
      <div className='cell desktop-only'>{order_id}</div>
      <div className='cell'>{formattedOrderAmount}</div>
      <div className={cn('cell', { 'with-icon': isReturningCustomer })}>
        {formattedCommissionAmount}
        {isReturningCustomer && (
          <Tooltip message='This was a returning customer'>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        )}
      </div>
      {name ? (
        <div onClick={selectUser} className='cell name'>
          {hasCorePackage ? name : 'Upgrade to View'}
        </div>
      ) : (
        <div className='cell'>-</div>
      )}
      <div className='cell desktop-only'>{code || '-'}</div>
      <div
        className={cn('cell desktop-only', {
          'with-icon': status_explanation
        })}
      >
        {status_badge}
        {status_explanation && <Tooltip message={status_explanation} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
      </div>
      <div
        onClick={takeAction}
        className={cn('cell action', {
          enabled: action,
          'taking-action': isTakingAction
        })}
      >
        {action_label}
        {isTakingAction && <Loader size={60} />}
      </div>
    </div>
  );
};

CommissionResult.propTypes = {
  commission: PropTypes.object.isRequired,
  action: PropTypes.func,
  action_label: PropTypes.string.isRequired,
  openArtistModal: PropTypes.func.isRequired
};

export default CommissionResult;
