import React from 'react';
import PropTypes from 'prop-types';
import './ContractAnalyticsTab.scss';

import ContractContentCard from './Elements/ContractContentCard';

import { isBrand } from '../../Helpers/user_helpers';

const ContractAnalyticsTab = props => {
  const { contract } = props;
  const { status } = contract;
  const isClosed = status === 'closed';
  return (
    <div className='contract-analytics-tab-outer-container'>
      <ContractContentCard
        user={props.user}
        contract={contract}
        canEdit={!isBrand(props.user) && !isClosed}
        toggleAddOrEditPinMode={props.toggleAddOrEditPinMode}
        syncContract={props.syncContract}
        deletePin={props.deletePin}
        connectInstagram={props.connectInstagram}
        selectStepByType={props.selectStepByType}
        getContractAnalytics={props.getContractAnalytics}
        openConnectInstagramModal={props.openConnectInstagramModal}
      />
    </div>
  );
};

ContractAnalyticsTab.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  toggleAddOrEditPinMode: PropTypes.func.isRequired,
  syncContract: PropTypes.func.isRequired,
  getContractAnalytics: PropTypes.func.isRequired,
  deletePin: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired,
  selectStepByType: PropTypes.func.isRequired
};

export default ContractAnalyticsTab;
