import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faCheck, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import './BrandSales.scss';

import { getDateStringForSale, hasSaleEnded } from '../../Helpers/formatting';
import { copyToClipboard } from '../../Helpers/helpers';

const BrandSales = props => {
  const sales = props.brand?.sales || [];
  if (!sales.length) return null;

  return (
    <div className='brand-sales-outer-container'>
      <div className='brand-modal-section-header'>Current Promotions</div>
      <div className='brand-sales'>
        {sales.map(item => (
          <Sale item={item} key={item.id} {...props} />
        ))}
      </div>
    </div>
  );
};

const Sale = props => {
  const { item } = props;
  const { subtitle } = item;
  const dateString = getDateStringForSale(item);
  const hasEnded = hasSaleEnded(item);

  // UI Handling
  const [isCopyingBadge, setIsCopyingBadge] = React.useState(false);
  const copyBadge = () => {
    setIsCopyingBadge(true);
    copyToClipboard(item.badge);
    setTimeout(() => setIsCopyingBadge(false), 1000);
  };

  return (
    <div className={cn('sale', { ended: hasEnded })} key={item.id}>
      <div className='date'>
        <FontAwesomeIcon className='icon' icon={faCalendar} />
        {dateString}
      </div>
      {subtitle && <div className='description'>{subtitle}</div>}
      {item.badge && (
        <div className={cn('action-btn', { disabled: hasEnded, copying: isCopyingBadge })} onClick={copyBadge}>
          <div className='display'>{hasEnded ? 'Sale Ended' : isCopyingBadge ? 'Copied' : item.badge}</div>
          <FontAwesomeIcon className='icon' icon={isCopyingBadge ? faCheck : faCopy} />
        </div>
      )}
    </div>
  );
};

BrandSales.propTypes = {
  brand: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default BrandSales;
