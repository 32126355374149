import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import './About.scss';
import '../Basic.scss';

import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { getGMTTime } from '../../../Helpers/formatting';
import { addEvent } from '../../../APIClient/events';
import {
  getAllTeamMembersByDepartment,
  updateTeamMember as updateTeamMemberAPI,
  createTeamMember as createTeamMemberAPI
} from '../../../APIClient/team_members';

import MetaTags from 'react-meta-tags';
import ScrollToTop from '../../../Components/General/ScrollToTop';
import AboutDepartment from './AboutDepartment';
import TeamMemberModal from './TeamMemberModal';

const getImportanceOfDepartment = department => {
  if (department.department === 'Leadership') return 1;
  else if (department.department === 'Creator Growth') return 2;
  else if (department.department === 'Brand Partnerships') return 3;
  else if (department.department === 'Creator Success') return 4;
  else if (department.department === 'Brand Success') return 5;
  else if (department.department === 'Business and Operations') return 6;
  else if (department.department === 'Growth & Marketing') return 7;
  else if (department.department === 'HR/Internal') return 8;
  else if (department.department === 'Product') return 9;
  else return 10;
};

const About = props => {
  const { ui } = props;

  /******************************************************************************************* */
  // State and Variables
  /******************************************************************************************* */

  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMemberId, setSelectedTeamMemberId] = useState(null);
  const [teamMemberModalIsOpen, setTeamMemberModalIsOpen] = useState(false);

  const departments = useMemo(() => {
    const departmentGroups = {};
    for (const teamMember of teamMembers) {
      const { department } = teamMember;
      if (!departmentGroups[department]) departmentGroups[department] = [];
      departmentGroups[department].push(teamMember);
    }

    const departmentsArray = Object.keys(departmentGroups).map(department => ({ department, members: departmentGroups[department] }));
    return _.orderBy(departmentsArray, department => getImportanceOfDepartment(department));
  }, [teamMembers]);

  useEffect(() => {
    !isAdminControlMode(ui) && addEvent('About Us - Page View');
    getAllTeamMembersByDepartment().then(response => {
      setTeamMembers(response.teamMembers);
    });
  }, []);

  /******************************************************************************************* */
  // Helper Functions
  /******************************************************************************************* */

  const closeTeamMemberModal = () => {
    setSelectedTeamMemberId(null);
    setTeamMemberModalIsOpen(false);
  };
  const openTeamMemberModal = teamMember => {
    setSelectedTeamMemberId(teamMember?.id);
    setTeamMemberModalIsOpen(true);
  };
  const scrollToTeamMembers = () => document.querySelector('#team-members').scrollIntoView({ behavior: 'smooth' });

  /******************************************************************************************* */
  // API Calls
  /******************************************************************************************* */

  const createTeamMember = data => {
    const { department, name, badge, image, User_id } = data;
    if (!department || !name || !badge || !image || !User_id) {
      return window.ALERT.error('All fields are required');
    }

    const fullData = { ...data, sortOrderRank: getGMTTime() };
    createTeamMemberAPI(fullData)
      .then(response => {
        window.ALERT.success('Team member created successfully');
        const newTeamMembers = [...teamMembers, response];
        setTeamMembers(newTeamMembers);
        closeTeamMemberModal();
      })
      .catch(() => {
        window.ALERT.error('There was an error creating the team member, please contact an engineer.');
      });
  };

  const updateTeamMember = async (teamMember, updates) => {
    const oldTeamMembers = [...teamMembers];
    const newTeamMembers = oldTeamMembers.map(tm => (tm.id === teamMember.id ? { ...tm, ...updates } : tm));
    setTeamMembers(newTeamMembers);
    closeTeamMemberModal();

    try {
      await updateTeamMemberAPI(teamMember, updates);
      window.ALERT.success('Team member updated successfully');
    } catch (e) {
      window.ALERT.error('There was an error updating the team member, please contact an engineer.');
      setTeamMembers(oldTeamMembers);
    }
  };

  return (
    <div className='about-outer-container'>
      <ScrollToTop />
      <MetaTags>
        <title>Our Story | ShopMy</title>
        <meta
          property='description'
          content='We are a team of engineers, designers and product enthusiasts with driven by a goal to bring authenticity back into the world of product promotion.'
        />
      </MetaTags>

      {teamMembers.length && teamMemberModalIsOpen && (
        <TeamMemberModal
          close={closeTeamMemberModal}
          createTeamMember={createTeamMember}
          teamMembers={teamMembers}
          updateTeamMember={updateTeamMember}
          selectedTeamMemberId={selectedTeamMemberId}
        />
      )}
      <div className='about-intro-container'>
        <h1 className='intro-header'>Our Story</h1>
        <h2 className='intro-summary'>
          We are a team of engineers, designers and product enthusiasts driven by a goal to bring authenticity back into the world of product
          promotion.
          <br />
          <br />
          Our platform offers a wide set of features for both creators and brands, reducing the friction required to find and work with the brands the
          creators genuinely love, not just those who will send them a paycheck.
          <br />
          <br />
          The product started out of a research project at MIT and has evolved to the comprehensive platform it is today through hard work by the
          highly motivated team below. Our team is based in New York, and we work with experts and creatives all over the world.
        </h2>
        <button onClick={scrollToTeamMembers} className='rounded-button'>
          Meet The Team
        </button>
      </div>

      <div className='about-image-container'>
        <img className='about-image' alt='Our Story' src='https://static.shopmy.us/nyfw_2024_full.jpg' />
      </div>

      <div className='about-team-container' id='team-members'>
        <div className='team-header'>
          <h3>Our Team</h3>
          <p>Our team is based in New York, and we work with experts and creatives all over the world.</p>
        </div>
        <div className='departments-container'>
          {departments.map(department => (
            <AboutDepartment
              key={department.department}
              department={department}
              updateTeamMember={updateTeamMember}
              openTeamMemberModal={openTeamMemberModal}
            />
          ))}
        </div>
        {isAdminControlMode(ui) && (
          <div onClick={() => openTeamMemberModal()} className='about-add-member-button'>
            Add New Team Member
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {})(About);
