import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComment, faTimes, faTrashAlt, faGift, faTag } from '@fortawesome/pro-regular-svg-icons';
import {
  faComment as faCommentSolid,
  faHeart as faHeartSolid,
  faGift as faGiftSolid,
  faBadgeCheck,
  faTag as faTagSolid
} from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ListUser.scss';

import BrandListsOverlay from '../General/BrandListsOverlay';

import { updateDiscoverListUser } from '../../APIClient/discover';
import { deleteRecommendedArtist, markUserAsUninterested } from '../../APIClient/recommendations';

import { matchScrollHeight } from '../../Helpers/helpers';
import { getPrettyNumber, getInitialsForUser } from '../../Helpers/formatting';
import { getBrandCodesForUserId, getBrandRequests, getSmartArtistBio, getBrandId } from '../../Helpers/user_helpers';
import { getAllBrandListsFoundIn } from '../../Helpers/brand_helpers';
import { getSocialIconForType } from '../../Helpers/social_helpers';
import { getTalent } from '../../Helpers/talent_helpers';

let debounce;
const ListUser = props => {
  const { user, analytics, discoverOrListUser, isBrandList, isEditing, ensureLoggedInThenCall, updateBrandListUser } = props;
  const { User_id, image, name, description, social_links, instagramCount, youtubeCount, tiktokCount } = discoverOrListUser;

  const talent = getTalent(props.talent).find(talent => talent.id === User_id);
  const { has_chatted } = talent || {};

  // Specific Data
  const request = getBrandRequests(user)?.find(request => request.User_id === User_id);
  const hasCodes = getBrandCodesForUserId(analytics, User_id).length > 0;

  // Main Display Data
  const fallBackDisplay = getSmartArtistBio(discoverOrListUser);
  const [displayText, setDisplayText] = React.useState(description || fallBackDisplay);

  // Social Data
  const instagramUrl = social_links?.split(',').find(url => url.includes('instagram'));
  const tiktokUrl = social_links?.split(',').find(url => url.includes('tiktok'));
  const youtubeUrl = social_links?.split(',').find(url => url.includes('youtube'));

  // Editing Data
  const updateDescription = newDescription => {
    setDisplayText(newDescription);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      isBrandList
        ? updateBrandListUser(discoverOrListUser, { description: newDescription }).then(props.syncExternal)
        : updateDiscoverListUser(discoverOrListUser, { description: newDescription }).then(props.syncExternal);
    }, 750);
  };

  // Brand List stuff
  const [isSelectingList, setIsSelectingList] = React.useState(false);
  const inBrandLists = getAllBrandListsFoundIn(user, { id: User_id });
  const isInList = !!inBrandLists.length;

  // Action Buttons
  const toggleSelectingList = () => ensureLoggedInThenCall(() => setIsSelectingList(!isSelectingList));
  const openCard = () =>
    ensureLoggedInThenCall(() => props.openArtistModal({ id: discoverOrListUser.User_id, name: discoverOrListUser.name }, null, 'Discover Result'));
  const openChat = () => ensureLoggedInThenCall(() => props.openChatOverlay({ id: User_id, name: name, image: image }, 'Discover Result'));
  const openCode = () => ensureLoggedInThenCall(() => props.openCodeOverlay(discoverOrListUser, 'Discover Result'));
  const openGifting = () => ensureLoggedInThenCall(() => props.openGiftingOverlay(discoverOrListUser, 'Discover Result'));
  const removeUser = () =>
    ensureLoggedInThenCall(() => {
      isBrandList
        ? confirmAlert({
            title: 'Just confirming',
            message: `Are you sure you want to remove ${name} from this list?`,
            buttons: [
              { label: 'No', className: 'cancel', onClick: () => {} },
              {
                label: 'Yes',
                onClick: () => {
                  window.__ADD_EVENT__('Discover - Remove Creator from List', {
                    User_id: discoverOrListUser.User_id,
                    Name: discoverOrListUser.name,
                    username: discoverOrListUser.username
                  });
                  props.deleteUser(discoverOrListUser);
                }
              }
            ]
          })
        : props.deleteUser(discoverOrListUser);
    });

  const [isDeleting, setIsDeleting] = React.useState(false);
  const rejectRecommendation = async () => {
    if (isDeleting) return;
    setIsDeleting(true);
    confirmAlert({
      title: 'Just confirming',
      message: isBrandList
        ? `This will remove ${name} from this list and from all of Discover. If you just want to remove them from this list, you should use the trash icon below the talent card.`
        : `This will remove ${name} from all future lists and recommendations. We will use this information to improve our recommendations for you.`,
      onKeypressEscape: () => setIsDeleting(false),
      onClickOutside: () => setIsDeleting(false),
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {
            setIsDeleting(false);
          }
        },
        {
          label: isBrandList ? 'Remove from List & Discover' : 'Remove from Discover',
          onClick: async () => {
            try {
              const isRecommendation = !isBrandList && !!discoverOrListUser?.ArtistRecommendation_id;
              if (isRecommendation) {
                await deleteRecommendedArtist(discoverOrListUser);
              } else {
                isBrandList && (await props.deleteUser(discoverOrListUser));
                await markUserAsUninterested(getBrandId(user), discoverOrListUser.User_id);
              }
              props.syncExternal && (await props.syncExternal());
              window.__ADD_EVENT__(isRecommendation ? 'Discover - Remove Recommendation' : 'Discover - Remove Creator', {
                User_id: discoverOrListUser.User_id,
                Name: discoverOrListUser.name,
                username: discoverOrListUser.username
              });
            } catch (error) {
              console.error(error);
              window.ALERT.error('Could not remove this recommendation, please try again.');
              setIsDeleting(false);
            }
          }
        }
      ]
    });
  };

  return (
    <div className={cn('list-user-container', { deleting: isDeleting })}>
      <div className='inner-container'>
        <div className={cn('image-container', `result-${props.index}`, { editing: isEditing, deleting: isDeleting })}>
          {image ? <img src={image} alt={name} /> : <div className='no-image'>{getInitialsForUser(talent)}</div>}
          <div className='badges'>
            {talent?.isPromoter ? (
              <div className='badge promoter'>
                Currently Promoting
                <FontAwesomeIcon icon={faBadgeCheck} />
              </div>
            ) : null}
            {false && (
              <div className='badge text'>
                ShopMy Recommended
                <FontAwesomeIcon icon={faBadgeCheck} />
              </div>
            )}
          </div>
          {!!(instagramCount || youtubeCount || tiktokCount) && (
            <div className='social-counts'>
              {[
                { count: instagramCount, url: instagramUrl, type: 'instagram' },
                { count: youtubeCount, url: youtubeUrl, type: 'youtube' },
                { count: tiktokCount, url: tiktokUrl, type: 'tiktok' }
              ].map(({ count, url, type }) => {
                return (
                  !!count && (
                    <a
                      key={type}
                      onClick={() =>
                        window.__ADD_EVENT__(`Discover - Result Card - Navigate To Social`, {
                          platform: type
                        })
                      }
                      href={url}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='social-count'
                    >
                      <img alt={`${type} Logo`} src={getSocialIconForType(type, 'white')} />
                      <span className='count'>{getPrettyNumber(count)}</span>
                    </a>
                  )
                );
              })}
            </div>
          )}
          <div className='data-overlay'>
            <div className='data' onClick={() => !isEditing && openCard()}>
              <div className='name'>{name}</div>
              {isEditing ? (
                <textarea
                  placeholder={fallBackDisplay}
                  ref={e => matchScrollHeight(e)}
                  className='description'
                  value={displayText}
                  onChange={e => updateDescription(e.target.value)}
                />
              ) : (
                <div className='description'>{displayText || fallBackDisplay}</div>
              )}
            </div>
            <div className='fade' />
            <div className='hover-fade' />
          </div>
        </div>
        {props.isEditing && props.rearrangeHandle}
        {!props.isEditing && (
          <div className='reject-recommendation-icon show-on-hover'>
            <FontAwesomeIcon icon={faTimes} onClick={rejectRecommendation} />
          </div>
        )}
      </div>
      <div className='action-btns'>
        <div onClick={openCard} className='action-btn'>
          {isBrandList ? 'View Talent' : 'View Talent Card'}
        </div>
        {isEditing && (
          <>
            <div onClick={removeUser} className='action-icn'>
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          </>
        )}
        {isBrandList && (
          <div onClick={removeUser} className='action-icn'>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        )}
        <div onClick={openChat} className={cn('action-icn', { active: has_chatted })}>
          <FontAwesomeIcon icon={has_chatted ? faCommentSolid : faComment} />
        </div>
        <div onClick={openGifting} className={cn('action-icn', { active: request })}>
          <FontAwesomeIcon icon={request ? faGiftSolid : faGift} />
        </div>
        <div onClick={openCode} className={cn('action-icn', { active: hasCodes })}>
          <FontAwesomeIcon icon={hasCodes ? faTagSolid : faTag} />
        </div>
        <BrandListsOverlay closeOverlay={toggleSelectingList} selectedUser_id={User_id} isActive={isSelectingList}>
          <div onClick={toggleSelectingList} className={cn('action-icn', { active: isInList })}>
            <FontAwesomeIcon icon={isInList ? faHeartSolid : faHeart} />
          </div>
        </BrandListsOverlay>
      </div>
    </div>
  );
};

ListUser.propTypes = {
  // Key Data - one is required
  discoverUser: PropTypes.object,
  listUser: PropTypes.object,

  // Always needed after edits
  syncExternal: PropTypes.func.isRequired,

  // For Brand Lists
  updateBrandListUser: PropTypes.func,

  // To allow Public
  ensureLoggedInThenCall: PropTypes.func.isRequired,

  // Redux Data
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,

  // If logged in
  isEditing: PropTypes.bool.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func,
  openGiftingOverlay: PropTypes.func,
  openCodeOverlay: PropTypes.func,

  // If editable
  rearrangeHandle: PropTypes.element,
  deleteUser: PropTypes.func
};

export default ListUser;
