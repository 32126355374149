import { combineReducers } from 'redux';
import { analytics } from './AnalyticsReducer';
import { attribution } from './AttributionReducer';
import { blog } from './BlogReducer';
import { chats } from './ChatReducer';
import { collaborations } from './CollaborationsReducer';
import { directory } from './DirectoryReducer';
import { discover } from './DiscoverReducer';
import { featured } from './FeaturedReducer';
import { latest } from './LatestReducer';
import { lookbooks } from './LookbookReducer';
import { manager } from './ManagerReducer';
import { opportunities } from './OpportunityReducer';
import { shop } from './ShopReducer';
import { store } from './StoreReducer';
import { talent } from './TalentReducer';
import { ui } from './UIReducer';
import { user } from './UserReducer';

const appReducer = combineReducers({
  analytics,
  attribution,
  blog,
  chats,
  collaborations,
  directory,
  discover,
  featured,
  latest,
  lookbooks,
  manager,
  opportunities,
  shop,
  store,
  talent,
  ui,
  user
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
