import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './BrandProducts.scss';

import { portalBugOpenUrl } from '../../Helpers/helpers';

const BrandProducts = props => {
  const { brand, isFetching } = props;
  const products = brand?.products || [];

  return (
    <div className='brand-products-outer-container'>
      <div className='brand-modal-section-header'>Popular Products</div>
      <div className='brand-products'>
        {isFetching ? (
          <>
            <div className='product empty' />
            <div className='product empty' />
            <div className='product empty' />
          </>
        ) : (
          products.map(product => <Product key={product.id} product={product} {...props} />)
        )}
      </div>
    </div>
  );
};

const Product = props => {
  const { product } = props;
  const { image, title, variants } = product;
  const url = variants[0]?.url;

  return (
    <div className='product'>
      <div className='image-container'>
        <img src={image} alt='title' />
        {url && (
          <a onClick={portalBugOpenUrl} href={url} target='_blank' rel='noopener noreferrer' className='external-link'>
            <FontAwesomeIcon icon={faExternalLink} className='heart' />
          </a>
        )}
      </div>
      <div className='data'>
        <div className='title'>{title}</div>
      </div>
    </div>
  );
};

BrandProducts.propTypes = {
  brand: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default BrandProducts;
