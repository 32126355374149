import APIClient from './index';
import _ from 'lodash';

export const getPromoterStatsForOpportunityPlanning = async data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/OpportunityPlans/promoter_monthly_stats', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getRecommendedUserCosts = async data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/OpportunityPlans/recommended_user_costs', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
