import React from 'react';
import PropTypes from 'prop-types';
import './PartnerPortalCode.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { copyToClipboard } from '../../Helpers/helpers';

const PartnerPortalCode = props => {
  const { code } = props;
  const { brand, displayText, isPercentage, rate, integration } = code;
  const { name, mobileCoverImage, logo, domain } = brand;
  const codeDomain = integration?.domain || domain;

  const copyCode = () => copyToClipboard(displayText, true, `Copied ${displayText} to clipboard!`);
  const stopPropagation = e => e.stopPropagation();

  return (
    <div className='partner-portal-code-outer'>
      <div className='partner-portal-code-inner' onClick={copyCode}>
        <div className='background-image-container'>
          <img src={mobileCoverImage} alt={name} />
        </div>

        <div className='background-overlay'></div>

        <div className='code-text-container'>
          <img src={logo} alt={name} className='logo' />
          <div className='code-text'>
            <div className='code-text-header'>
              {displayText}
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <a href={`https://${codeDomain}`} target='_blank' rel='noreferrer' className='code-text-subheader' onClick={stopPropagation}>
              {isPercentage ? `${rate}% off at ${codeDomain}` : `$${rate} off at ${domain}`}
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

PartnerPortalCode.propTypes = {
  code: PropTypes.object.isRequired
};

export default PartnerPortalCode;
