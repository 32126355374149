import React from 'react';
import MetaTags from 'react-meta-tags';
import './Snapshop.scss';

import { connect } from 'react-redux';
import SnapshopExplainerPanel from '../../Components/Partners/SnapshopExplainerPanel';
import { openAuthModal } from '../../Actions/UIActions';

const Snapshop = props => {
  const { user, openAuthModal } = props;

  const seoTitle = 'Snapshop by ShopMy';
  const seoDescription =
    'The newest way to monetize your product recommendations. Simply add to your bookmarks bar and click to snapshot your shopping experience to share with your audience.';
  return (
    <div className='snapshop-outer-container'>
      <MetaTags>
        <title>{seoTitle}</title>
        <meta property='og:title' content={seoTitle} />
        <meta property='description' content={seoDescription} />
        <meta property='og:description' content={seoDescription} />
      </MetaTags>
      <div className='snapshop-inner-container'>
        <SnapshopExplainerPanel fullPanel user={user} openAuthModal={openAuthModal} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, { openAuthModal })(Snapshop);
