import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import commaNumber from 'comma-number';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faTimes, faRedo, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import './OpportunityRequest.scss';

import {
  isOpportunityRequestExpired,
  isOpportunityRequestOutstanding,
  getTimingOnOpportunity,
  getTypeDisplayForOpportunity,
  getCoverImageForOpportunity,
  getOpportunityRequestAdjustedExpectations
} from '../../Helpers/opportunity_helpers';
import { getName } from '../../Helpers/user_helpers';
import { requestFeedback } from '../../Helpers/chat_helpers';
import { getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

const OpportunityRequest = props => {
  const { user, request } = props;
  const { opportunity, brand, payment_tier, userRejected } = request;
  const { fixedFee, adjCommissionRate } = payment_tier || {};
  const { trackingStartsAt, trackingEndsAt, location } = opportunity;
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    request
  );
  const isExpired = isOpportunityRequestExpired(request);
  const isOutstanding = isOpportunityRequestOutstanding(request);
  const timing = getTimingOnOpportunity(opportunity);
  const history = useHistory();

  // UI
  let opportunityBadgeData = [brand.name];
  userRejected
    ? opportunityBadgeData.push('Rejected ' + getPrettyTimeAgoFromNow(request.createdAt, { longForm: true }))
    : isExpired
    ? opportunityBadgeData.push('Expired ' + getPrettyTimeAgoFromNow(request.expiresOn || request.opportunity.trackingEndsAt, { longForm: true }))
    : opportunityBadgeData.push(getPrettyTimeAgoFromNow(request.createdAt, { longForm: true }));

  let opportunityTimingAndLocation = [];
  if (trackingStartsAt)
    opportunityTimingAndLocation.push(moment(trackingStartsAt).format('MMM Do') + ' - ' + moment(trackingEndsAt).format('MMM Do'));
  if (location) opportunityTimingAndLocation.push(location);
  if (request.expiresOn) {
    const expiresInDays = moment(request.expiresOn)
      .endOf('day')
      .diff(moment().endOf('day'), 'days');
    expiresInDays >= 0 &&
      expiresInDays < 7 &&
      opportunityTimingAndLocation.push(`Expires ${expiresInDays === 0 ? 'today' : `in ${expiresInDays} day${expiresInDays > 1 ? 's' : ''}`}`);
  }

  let requirementsAndEarnings = [];
  if (linksExpected) requirementsAndEarnings.push(`${linksExpected} link${linksExpected > 1 ? 's' : ''}`);
  if (linkingDaysExpected) requirementsAndEarnings.push(linkingDaysExpected === 1 ? '1 link' : `${linkingDaysExpected} days with links`);
  if (mentionsExpected) requirementsAndEarnings.push(`${mentionsExpected} mention${mentionsExpected > 1 ? 's' : ''}`);
  if (mentionDaysExpected)
    requirementsAndEarnings.push(mentionDaysExpected ? `1 social mention` : `${mentionDaysExpected} days with social mentions`);
  if (adjCommissionRate) requirementsAndEarnings.push(`${adjCommissionRate}% commission`);

  let badges = [];
  if (opportunity.AutoSend_Lookbook_id) badges.push('Gifted');
  badges.push(getTypeDisplayForOpportunity(opportunity));

  // Actions
  const dismiss = e => {
    e.preventDefault();
    e.stopPropagation();
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to dismiss this opportunity?`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            props.updateOpportunityRequest(request, { userRejected: true });
            requestFeedback({
              header: 'Feedback Requested',
              subheader: `The ShopMy team would like to understand why you dismissed this opportunity so we can make this experience better for you. We will not share this with ${brand.name}.`,
              delay: 500,
              messagePrefix: `${getName(user)} provided feedback on why they rejected "${request.opportunity.title}" from ${request.brand.name}`
            });
          }
        }
      ]
    });
  };
  const accept = e => {
    e.preventDefault();
    e.stopPropagation();
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to accept this opportunity?`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => props.updateOpportunityRequest(request, { userAccepted: true, userAcceptedAt: new Date() }, history)
        }
      ]
    });
  };

  const undoDismissal = e => {
    e.preventDefault();
    e.stopPropagation();
    props.updateOpportunityRequest(request, { userRejected: false, userRejectedAt: null });
  };

  const goToOpportunity = e => {
    props.setActiveOpportunity(opportunity);
  };
  return (
    <Link onClick={goToOpportunity} to={`/opportunity/${opportunity.id}`} className='opportunity-request-container'>
      <div className='cover-img-container'>
        <img src={getCoverImageForOpportunity(opportunity, brand)} alt='Cover' />
      </div>
      <div className='content'>
        <div className='header-section'>
          <div className='badges'>
            <div className='badge'>{badges.join(' • ')}</div>
          </div>
          <div className='action-btns'>
            {isOutstanding && (
              <div className='action-btn accept' onClick={accept}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )}
            {!isExpired && !timing.hasEnded && (
              <div className='action-btn dismiss' onClick={userRejected ? undoDismissal : dismiss}>
                <FontAwesomeIcon icon={userRejected ? faRedo : faTimes} />
              </div>
            )}
          </div>
        </div>
        <div className='main-section'>
          <div className='brand'>{opportunityBadgeData.join(' • ')}</div>
          <div className='title'>{opportunity?.title}</div>
          <div className='timing-location'>{opportunityTimingAndLocation.join(' • ')}</div>
        </div>
        <div className='footer-section'>
          {!!fixedFee && <div className='fee'>${commaNumber(fixedFee)}</div>}
          {!!requirementsAndEarnings.length && <div className='metadata'>{requirementsAndEarnings.join(' • ')}</div>}
        </div>
      </div>
    </Link>
  );
};

OpportunityRequest.propTypes = {
  user: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  setActiveOpportunity: PropTypes.func.isRequired
};

export default OpportunityRequest;
