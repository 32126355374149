import React from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { faTimes, faPlus, faCheck as faCheckSingle, faCheckDouble } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';

import { matchScrollHeight } from '../../../Helpers/helpers';
import { generateContentElementId } from '../../../Helpers/consult_helpers';

import './ConsultElementMultiChoice.scss';

const ConsultElementMultiChoice = props => {
  const { element, canEdit, question, placeholder, answer, options, setField, setFields } = props;

  const addNewOption = () =>
    setField('options', [
      ...options,
      {
        id: generateContentElementId(),
        question: '',
        checked: false
      }
    ]);

  const addOpenEndedOption = () =>
    setField('options', [
      ...options,
      {
        id: generateContentElementId(),
        question: '',
        answer: '',
        openEnded: true,
        checked: false
      }
    ]);

  const removeOption = option =>
    setField(
      'options',
      options.filter(o => o.id !== option.id)
    );

  const toggleOption = (option, onlyOneSelection) =>
    canEdit
      ? cogoToast.warn(`Switch to "View As Public" to complete the form.`)
      : setField(
          'options',
          options.map(o =>
            o.id === option.id ? { ...o, checked: !o.checked } : { ...o, checked: element.onlyOneSelection && !option.checked ? false : o.checked }
          )
        );

  const updateOptionQuestion = (option, question) => {
    setField(
      'options',
      options.map(o => (o.id === option.id ? { ...o, question } : o)),
      250
    );
  };

  const toggleMultiSelect = () => setField('onlyOneSelection', !element.onlyOneSelection);

  const sortedOptions = _.orderBy(options, 'openEnded', 'desc');
  const hasOpenEnded = _.find(options, option => option.openEnded);
  return (
    <div className='element-container multi-choice-container'>
      {canEdit ? (
        <textarea
          rows={1}
          ref={ref => matchScrollHeight(ref)}
          value={question}
          onChange={e => setField('question', e.target.value, 250)}
          placeholder='Ask Your Question Here'
          className='question'
        />
      ) : (
        <>
          <div className='question'>
            {question || '-'}
            {!element.onlyOneSelection && <div className='note'>Select All That Apply</div>}
          </div>
        </>
      )}
      {canEdit ? (
        <>
          {sortedOptions.map(option => (
            <div key={option.id} className='option-container'>
              <div onClick={() => toggleOption(option)} className={cn('option-checkbox', { checked: option.checked })}>
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <input
                value={option.question}
                onChange={e => updateOptionQuestion(option, e.target.value)}
                placeholder='Option Description'
                className='option'
              />
              <div className='remove-btn' onClick={() => removeOption(option)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          ))}
        </>
      ) : (
        sortedOptions.map(option => (
          <div key={option.id} onClick={() => toggleOption(option)} className='option-container'>
            <div className={cn('option-checkbox', { checked: option.checked })}>
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <div className='option'>{option.question || '-'}</div>
          </div>
        ))
      )}
      {!hasOpenEnded ? null : canEdit ? (
        <textarea className='answer placeholder' rows={4} value={placeholder} onChange={e => setField('placeholder', e.target.value, 250)} />
      ) : (
        <textarea
          className='answer'
          value={answer}
          onChange={e => {
            setFields(
              [
                [
                  'options',
                  options.map(o =>
                    o.openEnded
                      ? { ...o, checked: !!e.target.value }
                      : { ...o, checked: e.target.value && element.onlyOneSelection ? false : o.checked }
                  )
                ],
                ['answer', e.target.value]
              ],
              250
            );
          }}
          rows={6}
          placeholder={placeholder}
        />
      )}
      {canEdit && (
        <div className='extra-control-btns'>
          <div onClick={addNewOption} className='add-new-option-btn control-btn'>
            <span className='mobile-only'>New</span>
            <span className='desktop-only'>Add New Option</span>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          {!hasOpenEnded && (
            <div onClick={addOpenEndedOption} className='add-new-option-open-btn control-btn'>
              <span className='mobile-only'>Open Ended</span>
              <span className='desktop-only'>Add Open Ended Option</span>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          )}
          <div onClick={toggleMultiSelect} className='add-new-option-btn control-btn'>
            {element.onlyOneSelection ? 'One Option Allowed' : 'Multiple Options Allowed'}
            <FontAwesomeIcon icon={element.onlyOneSelection ? faCheckSingle : faCheckDouble} />
          </div>
        </div>
      )}
    </div>
  );
};

ConsultElementMultiChoice.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  setField: PropTypes.func.isRequired
};

export default ConsultElementMultiChoice;
