import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { confirmAlert } from 'react-confirm-alert';
import './ListHeaderActions.scss';

import { addDiscoverList } from '../../APIClient/discover';
import { addDiscoverListUser, duplicateDiscoverList } from '../../APIClient/discover';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDiscoverSortOptions, isDiscoverListCurated, getSortFunction } from '../../Helpers/discover_helpers';
import { getGMTTime } from '../../Helpers/formatting';

import SearchContentModal from '../Modals/SearchContentModal';
import Select from '../General/Select';
import SortIcons from '../General/SortIcons';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

const ListHeaderActions = props => {
  const { ui, isEditing, user, list, isBrandList, sortBy, sortDirection } = props;

  /*
    Filtering & Sorting
  */
  const toggleHidingCurrentPromoters = () =>
    props.ensureLoggedInThenCall(() => {
      props.setHideCurrentPromoters(!props.hideCurrentPromoters);
    });

  const groupedSortOptions = getDiscoverSortOptions();

  /*
    Actions
  */
  const selectUser = user => {
    addDiscoverListUser({
      DiscoverList_id: list.id,
      User_id: user.id,
      sortOrderRank: isAddingToTop ? -1 * getGMTTime() : getGMTTime()
    })
      .then(props.syncList)
      .catch(error => {
        window.ALERT.error(error?.message || 'Could not add user to list, please try again.');
      });
  };

  const sendGifting = () =>
    props.ensureLoggedInThenCall(() => {
      isBrandList
        ? props.openRequestModal({
            params: {
              BrandList_id: list.id
            }
          })
        : props.openRequestModal({
            params: {
              preselectedUsers: formatUsersForBulkRequests(list.users)
            }
          });
    });

  const sendMessage = () =>
    props.ensureLoggedInThenCall(() => {
      isBrandList
        ? props.openBulkTalentModal({
            outreachType: 'general',
            BrandList_id: list.id
          })
        : props.openBulkTalentModal({
            outreachType: 'general',
            talent: list.users.map(u => ({ id: u.User_id }))
          });
    });

  /*
    Admin
  */
  const [isSearchingUsers, setIsSearchingUsers] = useState(false);
  const [isAddingToTop, setIsAddingToTop] = useState(false);
  const toggleSearchingUsersAddToTop = () => {
    setIsAddingToTop(true);
    toggleSearchingUsers();
  };
  const toggleSearchingUsersAddToBottom = () => {
    setIsAddingToTop(false);
    toggleSearchingUsers();
  };
  const toggleSearchingUsers = () => {
    if (list.LookalikeUser_id) return window.ALERT.warn('You cannot add users to a lookalike list, please remove the lookalike ID first.');
    setIsSearchingUsers(!isSearchingUsers);
  };

  const duplicateList = () => {
    const isCurated = isDiscoverListCurated(list);
    const toCuratedDuplication = () => duplicate(true);
    const standardDuplication = () => duplicate(false);
    const duplicate = (convertToCurated = false) => {
      const options = {};
      if (convertToCurated) {
        options.convertToCuratedWithUserIds = list.users.map(u => u.User_id);
      }
      duplicateDiscoverList(list, options)
        .then(resp => (window.location.href = `/discover/${resp.id}`))
        .catch(error => window.ALERT.error(error?.message || 'Could not duplicate list, please try again.'));
    };

    isCurated
      ? standardDuplication()
      : confirmAlert({
          title: 'Duplicate List',
          message:
            'This list is not curated. Would you like to duplicate it as a curated list so you can edit it? Please note that this will lock the list and changes in data or tags will no longer impact the results.',
          buttons: [
            {
              label: 'Cancel',
              className: 'cancel',
              onClick: () => {}
            },
            {
              label: 'Duplicate as Dynamic',
              className: 'secondary',
              onClick: standardDuplication
            },
            {
              label: 'Duplicate as Curated',
              onClick: toCuratedDuplication
            }
          ]
        });
  };

  const toggleEditing = () => {
    props.ensureLoggedInThenCall(() => {
      props.setIsEditing(!props.isEditing);
      props.setSortBy(null);
      props.setSortDirection(null);
    });
  };

  const changeSort = sort => {
    const { defaultDirection } = getSortFunction(sort) || {};

    props.ensureLoggedInThenCall(() => {
      props.ensureValidPackageThenCall(() => {
        props.setSortBy(sort);
        props.setSortDirection(defaultDirection || 'desc');
      });
    });
  };

  const showDuplicateBtn = isAdminControlMode(ui) && isEditing && !isBrandList;
  const showEditBtn = isAdminControlMode(ui) || isBrandList;
  const showAddCreatorBtn = isAdminControlMode(ui) && isEditing && !isBrandList;
  const showActions = !isEditing;
  return (
    <div className='list-header-action-btns'>
      {showEditBtn && (
        <div className='radio-btn'>
          <div className='label'>Edit List</div>
          <Switch
            onChange={toggleEditing}
            checked={props.isEditing}
            height={14}
            width={24}
            onColor='#11835a'
            offColor='#999'
            className='switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      )}
      {!isEditing && (
        <div className='radio-btn'>
          <div className='label'>Hide Current Promoters</div>
          <Switch
            onChange={toggleHidingCurrentPromoters}
            checked={props.hideCurrentPromoters}
            height={14}
            width={24}
            onColor='#11835a'
            offColor='#999'
            className='switch'
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      )}
      {!isEditing && (
        <div className='select-row'>
          <div className='label'>Sort By</div>
          <Select value={sortBy} options={groupedSortOptions} onChangeValue={changeSort} />
          {!!sortDirection && <SortIcons sortDirection={sortDirection} toggleSort={props.setSortDirection} />}
        </div>
      )}
      <div className='action-btns'>
        {showActions && (
          <>
            <div onClick={sendMessage} className='action-btn secondary'>
              Send Bulk Message
            </div>
            <div onClick={sendGifting} className='action-btn primary'>
              Send Bulk Gifting
            </div>
          </>
        )}
        {showDuplicateBtn && (
          <div onClick={duplicateList} className='action-btn secondary'>
            Duplicate
          </div>
        )}
        {showAddCreatorBtn && (
          <>
            <div onClick={toggleSearchingUsersAddToTop} className='action-btn primary'>
              + To Top
            </div>
            <div onClick={toggleSearchingUsersAddToBottom} className='action-btn primary'>
              + To Bottom
            </div>
          </>
        )}
      </div>
      {isSearchingUsers && (
        <SearchContentModal
          type='users'
          allowMultiSelect
          closeModal={toggleSearchingUsers}
          user={user}
          selectUser={selectUser}
          alreadySelectedIds={list.users.map(u => u.User_id)}
        />
      )}
    </div>
  );
};

ListHeaderActions.propTypes = {
  user: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  setSortDirection: PropTypes.func.isRequired,
  isBrandList: PropTypes.bool,
  syncList: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  hideCurrentPromoters: PropTypes.bool.isRequired,
  setHideCurrentPromoters: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  ensureLoggedInThenCall: PropTypes.func.isRequired,
  ensureValidPackageThenCall: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired
};

export default ListHeaderActions;
