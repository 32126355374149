export const sendDataToApp = (command, data) => {
  /*
    In many locations on the mobile app we use WebView elements that allow us to
    communicate between the native app and the website via the ReactNativeWebView
    object.
  */
  if (!window.__IS_APP__) return;
  switch (command) {
    case 'LOGIN_USER_SUCCESS':
    case 'LOGOUT_USER_SUCCESS':
    case 'UNIVERSAL_LOGIN':
      window.ReactNativeWebView.postMessage(JSON.stringify({ command, data }));
      break;
    default:
      console.error(`Undefined Command Sent To App.`);
      break;
  }
};

window.APP_COMMANDS = {};
export const registerAppCommand = (command, callback) => {
  /*
    Any component can register a command for the app layer to call as follows:

    In Web:
      registerAppCommand('LOGIN_USER', data => console.info(data.email, data.password))

    In Mobile (webview_helpers.js)
      sendDataToApp(ref, 'LOGIN_USER', { email, password })
  */
  switch (command) {
    case 'LOGOUT_USER':
    case 'LOGIN_USER':
      window.APP_COMMANDS[command] = callback;
      break;
    default:
      console.error(`Undefined App Command.`);
      break;
  }
};
