import React from 'react';
import PropTypes from 'prop-types';
import './OnboardingPanelIntro.scss';

import { getName, getReferringRequest } from '../../../Helpers/user_helpers';
import { getFirstName } from '../../../Helpers/formatting';

import introImage from '../../../static/images/onboarding/onboarding_intro.png';

const OnboardingPanelIntro = props => {
  const { user } = props;

  const lookbookRequest = getReferringRequest(user);
  return (
    <div className='onboarding-panel-intro-container'>
      <img src={introImage} alt='Millions Of Products' />
      <div className='onboarding-header'>Hi, {getFirstName(getName(user))}</div>
      <div className='onboarding-subheader'>
        {lookbookRequest
          ? `Thank you for joining the ShopMy community through your selections with ${lookbookRequest.brand.name}! You're now part of a network of top content creators and influencers who easily monetize their recommendations with world-class brands. Let's get started with a few quick questions to set up your account.`
          : `Welcome to ShopMy, your home for monetizing product recommendations and brand collaborations. To get started, we just have a few questions.`}
      </div>
    </div>
  );
};

OnboardingPanelIntro.propTypes = {
  user: PropTypes.object.isRequired
};

export default OnboardingPanelIntro;
