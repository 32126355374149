import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import { titleCase } from 'title-case';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './CollectionSettings.scss';

import Loader from '../../Loader/Loader';
import SocialSharingTemplatesInstagram from './SocialSharingTemplates/SocialSharingTemplatesInstagram';

import { getCodeRateDisplay, getGMTTime } from '../../../Helpers/formatting';
import { getCollectionSkin } from '../../../Helpers/shop_helpers';
import { copyToClipboard, getRootUrl, getShortUrl, dontBubble } from '../../../Helpers/helpers';
import { editCollection } from '../../../Actions/StoreActions';
import { getCodeForPin } from '../../../Helpers/store_helpers';

class CollectionSettings extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    editCollection: PropTypes.func.isRequired,

    // Passed from outer container
    isVisible: PropTypes.bool.isRequired,
    syncCollection: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired
  };

  state = {
    changingSectionTo: null,
    changingSkinTo: null,
    changingVisibilityTo: null,
    changingAddToTop: null,
    currentTab: 'CollectionSettings', // CollectionSettings, SocialSharingTemplates, EmbeddableComponents, OtherWaysToShare
    socialSharingPlatform: isMobile ? 'general' : 'instagram' // instagram, youtube, general
  };

  // Helpers
  getCollection = () => _.get(this.props, 'store.visibleCollection') || {};
  getSections = () => _.orderBy(_.get(this.props, 'store.visibleCollection.user.sections') || [], 'sortOrderRank');
  canBePost = () => !!_.get(this.getCollection(), 'image');
  applySentenceFormatting = pinTitle => {
    const splitPinTitle = pinTitle.split(' | ');
    const [merchant, ...rest] = splitPinTitle;
    const productTitle = rest?.join(' ');

    if (!productTitle) return titleCase(pinTitle);
    else return `${merchant} | ${titleCase(productTitle)}`;
  };

  getCollectionUrl = () => `${getRootUrl()}/collections/${this.getCollection().id}`;
  getShopUrl = () => `${getRootUrl()}/${_.get(this.getCollection(), 'user.username')}`;

  generateYoutubeCaptionAsJSX = (collection, options) => this.generateYoutubeCaption(collection, options, true);
  generateYoutubeCaption = (collection, options, asJSX = false) => {
    const { spacesBetweenProductUrls } = options;
    const { pins } = collection;

    let finalMsg;
    const collectionUrl = this.getCollectionUrl();
    const shopUrl = this.getShopUrl();
    if (asJSX) {
      return (
        <div>
          Shop everything in this video here:&nbsp;
          <a target='_blank' rel='noopener noreferrer' href={collectionUrl}>
            {collectionUrl}
          </a>
          <br />
          <br />
          {pins.map(pin => {
            const codeForPin = getCodeForPin(pin, collection);
            const { allow_displaying_codes } = pin;
            const shouldDisplayCode = !!allow_displaying_codes && codeForPin;

            return (
              <div className='caption-product-link' key={pin.id}>
                <span>{this.applySentenceFormatting(pin.title)}:&nbsp;</span>
                {!!spacesBetweenProductUrls && <br />}
                <a target='_blank' rel='noopener noreferrer' href={`${getShortUrl()}/p-${pin.id}`}>
                  {getShortUrl()}/p-{pin.id}
                </a>
                {!!shouldDisplayCode && (
                  <span>
                    &nbsp;&nbsp;(use code: {codeForPin?.displayText} for {getCodeRateDisplay(codeForPin)} off)
                  </span>
                )}
                {!!spacesBetweenProductUrls && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </div>
            );
          })}
          <br />
          Shop more of my favorite products here:{' '}
          <a target='_blank' rel='noopener noreferrer' href={shopUrl}>
            {shopUrl}
          </a>
        </div>
      );
    } else {
      finalMsg = `Shop everything mentioned in this video here: ${collectionUrl}\n\n`;
      pins.forEach(pin => {
        const codeForPin = getCodeForPin(pin, collection);
        const { allow_displaying_codes } = pin;
        const shouldDisplayCode = !!allow_displaying_codes && codeForPin;

        finalMsg += `${this.applySentenceFormatting(pin.title)}:${spacesBetweenProductUrls ? '\n' : ' '}${getShortUrl()}/p-${pin.id}${
          shouldDisplayCode ? ` (use code: ${codeForPin?.displayText} for ${getCodeRateDisplay(codeForPin)} off)` : ''
        }\n${spacesBetweenProductUrls ? '\n' : ''}`;
      });
      finalMsg += `\nShop more of my favorite products here: ${shopUrl}\n`;
    }
    return finalMsg;
  };

  getIframeCode = (url, height = 2400) => {
    const collection = this.getCollection();
    return `<iframe title="${collection.name}" src="${url}" style="width: 100%; min-height: ${height}px; border: none;"></iframe>`;
  };
  getSkinExplanation = () => {
    const collection = this.getCollection();
    switch (collection.skinType) {
      case 'simple':
        return `This classic look features a grid of products with descriptions hidden behind an info icon.`;
      case 'longform':
        return `This style resembles a blog post and offers always-visible descriptions.`;
      case 'compact':
        return `Designed to show products as densly as possible for a mobile-first experience.`;
      case 'grid':
        return `Designed to show your products in a grid on both the desktop and mobile experience`;
      case 'gallery':
        return 'Images are shown in a gallery format, images only. Titles and descriptions are hidden.';
      default:
        return `Specify how you want your collection to look.`;
    }
  };

  getSectionOptions = () => {
    const sections = this.getSections();
    return [
      ...sections.map(section => {
        let collectionUpdaates = { Section_id: section.id, isArchived: false, sortOrderRank: -1 * getGMTTime() };
        let stateUpdates = { changingTypeTo: section.id }; // Move to top on transition

        // Flip to editorial when changing to editorial format
        if (section.previewType === 'editorial') {
          collectionUpdaates.skinType = 'longform';
          stateUpdates.changingSkinTo = 'longform';
        }

        return {
          variable: section.id,
          display: section.title,
          onClick: () => this.updateCollection(collectionUpdaates, stateUpdates)
        };
      }),
      {
        variable: 'archive',
        display: 'Archive',
        onClick: () => this.updateCollection({ isArchived: true }, { changingTypeTo: 'archive' })
      }
    ];
  };

  getEmbedUrl = (params = {}) => {
    return `${getRootUrl()}/collections/embed/${this.getCollection().id}${params ? `?${new URLSearchParams(params).toString()}` : ''}`;
  };
  getPublicUrl = (params = {}) => {
    return `${getRootUrl()}/collections/public/${this.getCollection().id}${params ? `?${new URLSearchParams(params).toString()}` : ''}`;
  };

  updateCollection = async (updates, stateChange) => {
    const collection = this.getCollection();

    // Set UI Handling
    if (this.state.changingTypeTo) return;
    this.setState(stateChange);

    const editResp = await this.props.editCollection(collection.id, updates);
    if (_.get(editResp, 'error')) {
      const errorMessage = _.get(editResp, ['error', 'message']);
      cogoToast.error(
        errorMessage ||
          'There was an issue editing this collection, please try again. If this issue persists, please reach out to us for assistance.',
        { hideAfter: 6 }
      );
    } else {
      await this.props.syncCollection();
    }
    this.setState({ changingTypeTo: null, changingSkinTo: null });
  };

  toggleCollectionSkin = async newSkin => {
    const collection = this.getCollection();
    if (this.state.changingSkinTo !== null) return;
    this.setState({ changingSkinTo: newSkin });
    const editResp = await this.props.editCollection(collection.id, { skinType: newSkin });
    if (_.get(editResp, 'error')) {
      const errorMessage = _.get(editResp, ['error', 'message']);
      window.ALERT.error(
        errorMessage ||
          'There was an issue editing this collection, please try again. If this issue persists, please reach out to us for assistance.',
        { hideAfter: 6 }
      );
    } else {
      await this.props.syncCollection();
    }

    this.setState({ changingSkinTo: null });
  };

  toggleCollectionVisibility = async newVisibility => {
    const collection = this.getCollection();
    if (!!newVisibility === !collection.private) return;
    if (this.state.changingVisibilityTo !== null) return;
    this.setState({ changingVisibilityTo: newVisibility });
    const editResp = await this.props.editCollection(collection.id, { private: !newVisibility });
    if (_.get(editResp, 'error')) {
      const errorMessage = _.get(editResp, ['error', 'message']);
      window.ALERT.error(
        errorMessage ||
          'There was an issue editing this collection, please try again. If this issue persists, please reach out to us for assistance.',
        { hideAfter: 6 }
      );
    } else {
      await this.props.syncCollection();
    }

    this.setState({ changingVisibilityTo: null });
  };

  toggleAddToTop = async newAddToTop => {
    const collection = this.getCollection();
    if (!!newAddToTop === !!collection.addPinsToTop) return;
    if (this.state.changingAddToTop !== null) return;
    this.setState({ changingAddToTop: newAddToTop });
    const editResp = await this.props.editCollection(collection.id, { addPinsToTop: newAddToTop });
    if (_.get(editResp, 'error')) {
      const errorMessage = _.get(editResp, ['error', 'message']);
      window.ALERT.error(
        errorMessage ||
          'There was an issue editing this collection, please try again. If this issue persists, please reach out to us for assistance.',
        { hideAfter: 6 }
      );
    } else {
      await this.props.syncCollection();
    }

    this.setState({ changingAddToTop: null });
  };

  toggleViewableTab = tabName => {
    this.setState({ currentTab: tabName });
  };

  toggleSocialSharingPlatform = socialSharingPlatform => {
    this.setState({ socialSharingPlatform });
  };

  render() {
    const { isVisible, closePanel } = this.props;
    const { changingTypeTo, changingSkinTo, changingVisibilityTo, changingAddToTop } = this.state;
    const collection = this.getCollection();
    const { Section_id } = collection || {};
    const skin = getCollectionSkin(collection);
    const sectionOptions = this.getSectionOptions();

    const embedUrl = this.getEmbedUrl();
    const publicGridUrl = this.getPublicUrl({ noHeader: true });
    const roughGridHeight = collection.pins.length > 10 ? 2400 : 1200;
    return (
      <div onClick={closePanel} className={cn('collection-settings-outer-container', { visible: isVisible })}>
        <div onClick={dontBubble} className='collection-settings-inner-container'>
          <div className='close-icn-container' onClick={closePanel}>
            <FontAwesomeIcon className='close-icn' icon={faTimes} />
          </div>
          <div className='tabs'>
            <div
              onClick={() => this.toggleViewableTab('CollectionSettings')}
              className={cn('tab', { selected: this.state.currentTab === 'CollectionSettings' })}
            >
              Collection Settings
            </div>
            <div
              onClick={() => this.toggleViewableTab('SocialSharingTemplates')}
              className={cn('tab', { selected: this.state.currentTab === 'SocialSharingTemplates' })}
            >
              Sharing On Social
            </div>
            <div
              onClick={() => this.toggleViewableTab('EmbeddableComponents')}
              className={cn('tab', { selected: this.state.currentTab === 'EmbeddableComponents' })}
            >
              Embeddable Components
            </div>
          </div>
          {this.state.currentTab === 'CollectionSettings' && (
            <div className='tab-content'>
              <div className='section'>
                <div className='section-header'>Shop Section</div>
                <div className='section-subheader'>Specify under which section this collection will show up on your shop page.</div>
                <div className='section-body'>
                  <div className='radio-btns'>
                    {sectionOptions.map(({ variable, display, disabled, onClick }) => (
                      <div
                        key={variable}
                        onClick={onClick}
                        className={cn('radio-btn', {
                          disabled,
                          changing: changingTypeTo === variable,
                          active:
                            !changingTypeTo && (variable === 'archive' ? collection.isArchived : !collection.isArchived && Section_id === variable)
                        })}
                      >
                        {display?.toUpperCase()}
                        {changingTypeTo === variable && <Loader size={44} />}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className='section'>
                <div className='section-header'>Collection Style</div>
                <div className='section-subheader'>{this.getSkinExplanation()}</div>
                <div className='section-body'>
                  <div className='radio-btns'>
                    {[
                      { variable: 'classic', display: 'Simple' },
                      { variable: 'longform', display: 'Editorial' },
                      { variable: 'compact', display: 'Compact' },
                      { variable: 'grid', display: 'Grid' },
                      { variable: 'gallery', display: 'Gallery' }
                    ].map(({ variable, display }) => (
                      <div
                        key={variable}
                        onClick={() => this.toggleCollectionSkin(variable)}
                        className={cn('radio-btn', {
                          changing: changingSkinTo === variable,
                          active: !changingSkinTo && skin === variable
                        })}
                      >
                        {display}
                        {changingSkinTo === variable && <Loader size={44} />}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='section'>
                <div className='section-header'>{window.__IS_SMS__ ? 'Collection' : 'List'} Visibility</div>
                <div className='section-subheader'>
                  Hiding a {window.__IS_SMS__ ? 'collection' : 'list'} means that it will not show up when people view your shop page.
                </div>
                <div className='section-body'>
                  <div className='radio-btns'>
                    <div
                      onClick={() => this.toggleCollectionVisibility(true)}
                      className={cn('radio-btn', {
                        changing: changingVisibilityTo === true,
                        active: changingVisibilityTo === null && !collection.private
                      })}
                    >
                      Visible
                      {changingVisibilityTo === true && <Loader size={44} />}
                    </div>
                    <div
                      onClick={() => this.toggleCollectionVisibility(false)}
                      className={cn('radio-btn', {
                        changing: changingVisibilityTo === false,
                        active: changingVisibilityTo === null && collection.private
                      })}
                    >
                      Hidden
                      {changingVisibilityTo === false && <Loader size={44} />}
                    </div>
                  </div>
                </div>
              </div>
              <div className='section'>
                <div className='section-header'>Product Adding Behavior</div>
                <div className='section-subheader'>Where should products be added to this collection?</div>
                <div className='section-body'>
                  <div className='radio-btns'>
                    <div
                      onClick={() => this.toggleAddToTop(true)}
                      className={cn('radio-btn', {
                        changing: changingAddToTop === true,
                        active: changingAddToTop === null && collection.addPinsToTop
                      })}
                    >
                      Add Products To Top
                      {changingAddToTop === true && <Loader size={44} />}
                    </div>
                    <div
                      onClick={() => this.toggleAddToTop(false)}
                      className={cn('radio-btn', {
                        changing: changingAddToTop === false,
                        active: changingAddToTop === null && !collection.addPinsToTop
                      })}
                    >
                      Add Products To Bottom
                      {changingAddToTop === false && <Loader size={44} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.currentTab === 'SocialSharingTemplates' && (
            <div className='tab-content'>
              <div className='section'>
                <div className='section-header'>Social Sharing Templates</div>
                <div className='section-subheader'>Use these recommended templates to easily share on your social media platforms.</div>
                <div className='radio-btns' style={{ marginBottom: '1rem' }}>
                  <div
                    onClick={() => this.toggleSocialSharingPlatform('general')}
                    className={cn('radio-btn', { active: this.state.socialSharingPlatform === 'general' })}
                  >
                    General Links
                  </div>
                  {!isMobile && (
                    <div
                      onClick={() => this.toggleSocialSharingPlatform('instagram')}
                      className={cn('radio-btn', { active: this.state.socialSharingPlatform === 'instagram' })}
                    >
                      For Instagram
                    </div>
                  )}
                  <div
                    onClick={() => this.toggleSocialSharingPlatform('youtube')}
                    className={cn('radio-btn', { active: this.state.socialSharingPlatform === 'youtube' })}
                  >
                    For Youtube
                  </div>
                </div>
                {this.state.socialSharingPlatform === 'instagram' && <SocialSharingTemplatesInstagram collection={collection} />}
                {this.state.socialSharingPlatform === 'youtube' && (
                  <>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Link to collection</div>
                      <div className='copy-section-content'>
                        <a href={window.location.href}>{window.location.href}</a>
                        <div className='copy-btn' onClick={() => copyToClipboard(window.location.href, true)}>
                          COPY
                        </div>
                      </div>
                    </div>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Link to All Products - Compact</div>
                      <div className='copy-section-content'>
                        {this.generateYoutubeCaptionAsJSX(collection, { spacesBetweenProductUrls: false })}
                        <div
                          className='copy-btn'
                          onClick={() => copyToClipboard(this.generateYoutubeCaption(collection, { spacesBetweenProductUrls: false }), true)}
                        >
                          COPY
                        </div>
                      </div>
                    </div>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Link to All Products - Spread Out</div>
                      <div className='copy-section-content'>
                        {this.generateYoutubeCaptionAsJSX(collection, { spacesBetweenProductUrls: true })}
                        <div
                          className='copy-btn'
                          onClick={() => copyToClipboard(this.generateYoutubeCaption(collection, { spacesBetweenProductUrls: true }), true)}
                        >
                          COPY
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.socialSharingPlatform === 'general' && (
                  <>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Collection Link</div>
                      <div className='copy-section-content'>
                        {window.location.href}
                        <div className='copy-btn' onClick={() => copyToClipboard(window.location.href, true)}>
                          COPY
                        </div>
                      </div>
                    </div>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Collection Link with No ShopMy Header</div>
                      <div className='copy-section-content'>
                        {`${getRootUrl()}/collections/public/${collection.id}`}
                        <div className='copy-btn' onClick={() => copyToClipboard(`${getRootUrl()}/collections/public/${collection.id}`, true)}>
                          COPY
                        </div>
                      </div>
                    </div>
                    <div className='copy-section'>
                      <div className='copy-section-label'>Link To Your Shop</div>
                      <div className='copy-section-content'>
                        <div>
                          <a target='_blank' rel='noopener noreferrer' href={this.getShopUrl()}>
                            {this.getShopUrl()}
                          </a>
                        </div>
                        <div className='copy-btn' onClick={() => copyToClipboard(this.getShopUrl(), true)}>
                          COPY
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {this.state.currentTab === 'EmbeddableComponents' && (
            <div className='tab-content'>
              <div className='section'>
                <div className='section-header'>Embeddable Components</div>
                <div className='section-subheader'>Embed your collection seamlessly into your blog or website.</div>
                <div className='copy-section'>
                  <div className='copy-section-label'>Product Carousel</div>
                  <iframe title={collection.name} src={embedUrl} />
                  <div className='copy-section-content'>
                    {this.getIframeCode(embedUrl, 340)}
                    <div className='copy-btn' onClick={() => copyToClipboard(this.getIframeCode(embedUrl, 340), true)}>
                      COPY
                    </div>
                  </div>
                </div>
                <div className='copy-section'>
                  <div className='copy-section-label'>Full Collection Embed</div>
                  <div className='copy-section-content'>
                    {this.getIframeCode(publicGridUrl, roughGridHeight)}
                    <div className='copy-btn' onClick={() => copyToClipboard(this.getIframeCode(publicGridUrl, roughGridHeight), true)}>
                      COPY
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.currentTab === 'OtherWaysToShare' && (
            <div className='tab-content'>
              <div className='section'>
                <div className='section-header'>Other Ways To Share</div>
                <div className='copy-section'>
                  <div className='copy-section-label'>QR Code</div>
                  <div className='qr-code'>
                    <QRCode value={window.location.href} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user, store } = state;
  return { user, store };
};

export default connect(mapStateToProps, {
  editCollection
})(CollectionSettings);
