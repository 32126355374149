import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './TaskEditModal.scss';

import Modal from '../General/Modal';
import Loader from '../Loader/Loader';

import { getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getDisplayForCollaborationType } from '../../Helpers/collaboration_helpers';

const TaskEditModal = props => {
  const { closeModal, updateUserContractTask, task } = props;
  const { template, isPriceLocked } = task;
  const { type, title, fee_type } = template || {};
  const [curPrice, setCurPrice] = useState(task.price || '');
  const [curIsPriceLocked, setCurIsPriceLocked] = useState(task.isPriceLocked);
  const [isSaving, setIsSaving] = useState(false);
  const isDurationFee = fee_type === 'duration';

  const priceChange = (parseFloat(task.price) || null) !== (parseFloat(curPrice) || null);
  const lockChange = curIsPriceLocked !== isPriceLocked;
  const updateNeeded = priceChange || lockChange;

  let priceGuidance;
  try {
    priceGuidance = JSON.parse(template.priceGuidance);
  } catch (_) {}

  const setPrice = newPrice => {
    const price = newPrice.replace(/[^0-9.]+/g, '');
    setCurPrice(price);
  };

  const save = async () => {
    setIsSaving(true);
    await updateUserContractTask(task, { price: parseFloat(curPrice) || null, isPriceLocked: curIsPriceLocked });
    setIsSaving(false);
  };

  const saveAndClose = async () => {
    if (isSaving) return;
    if (!updateNeeded) return closeModal();
    await save();
    closeModal();
  };

  return (
    <Modal visible innerClassName='task-edit-outer-modal' contentClassName='task-edit-picker-modal' showClose close={closeModal}>
      <div className='title-container'>
        <div className='title'>
          <span className='primary'>{getDisplayForCollaborationType(type)}</span>
          <span className='bullet'>•</span>
          <span className='secondary'>{title}</span>
          <span className='bullet'>•</span>
          <span className={cn('badge', { set: curPrice })}>
            {curPrice ? `${getPrettyPriceDisplay(curPrice)}${isDurationFee ? ' per day' : ''}` : 'No Price Set'}
          </span>
        </div>
        <div className='subtitle'>
          Enter your standard price for this type of deliverable below. You’ll be able to negotiate with brands on specific proposals later, but
          entering a baseline is helpful for discovery.
        </div>
        <div className='section'>
          <div className='header'>Set your price{isDurationFee ? ' per day' : ''}</div>
          <input
            className='price-input'
            placeholder={`Set a price${isDurationFee ? ' per day' : ''}`}
            onChange={({ target }) => setPrice(target.value)}
            value={curPrice}
          />
        </div>
        <div className='section'>
          <div className='header'>Are you open to brands proposing alternative prices?</div>
          <div className='toggle'>
            <div onClick={() => setCurIsPriceLocked(false)} className={cn('option', { selected: !curIsPriceLocked })}>
              Yes
            </div>
            <div onClick={() => setCurIsPriceLocked(true)} className={cn('option', { selected: curIsPriceLocked })}>
              No
            </div>
          </div>
        </div>
        {priceGuidance && (
          <div className='section'>
            <div className='header'>Price Guidance</div>
            <div className='subheader'>
              {priceGuidance.description ||
                `Use the following as a rough guide to help you price this collaboration. Please note that ranges of prices are meant to account for
              additional factors such as audience engagement and volume of spending. You will be attaching click tracking to your collaboration so
              make sure not to price too high that the brand doesn’t see enough value from your collaboration.`}
            </div>
            <div className='table'>
              <div className='row header-row'>
                {priceGuidance.headers.map((header, idx) => (
                  <div key={idx} className='cell'>
                    {header}
                  </div>
                ))}
              </div>
              {priceGuidance.rows.map((cells, idx) => (
                <div key={idx} className='row'>
                  {cells.map((content, idx) => (
                    <div key={idx} className='cell'>
                      {content}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className='pricing-guidance-explanation'></div>
      <div className='footer'>
        {updateNeeded && (
          <div onClick={closeModal} className='footer-action cancel'>
            Cancel
          </div>
        )}
        <div onClick={saveAndClose} className={cn('footer-action save', { saving: isSaving, 'update-needed': updateNeeded })}>
          {updateNeeded ? 'Save' : 'Done'}
          {isSaving && <Loader size={40} />}
        </div>
      </div>
    </Modal>
  );
};

TaskEditModal.propTypes = {
  task: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateUserContractTask: PropTypes.func.isRequired
};

export default TaskEditModal;
