import React from 'react';
import PropTypes from 'prop-types';
import './MentionResultsSummary.scss';

import { getPrettyNumber } from '../../../Helpers/formatting';

const MentionResultsSummary = props => {
  const { summary } = props;
  const { totalEstimatedMediaValue, totalMentions } = summary;
  return (
    <div className='mention-results-summary-container'>
      <div className='summary-items'>
        {!!totalMentions && (
          <div className='summary-item'>
            <div className='summary-item-title'>Total Mentions</div>
            <div className='summary-item-value'>{getPrettyNumber(totalMentions, { precision: 0 })}</div>
          </div>
        )}
        {!!totalEstimatedMediaValue && (
          <div className='summary-item'>
            <div className='summary-item-title'>Total Estimated Media Value</div>
            <div className='summary-item-value'>${getPrettyNumber(totalEstimatedMediaValue, { precision: 0 })}</div>
          </div>
        )}
      </div>
    </div>
  );
};

MentionResultsSummary.propTypes = {
  summary: PropTypes.object.isRequired
};

export default MentionResultsSummary;
