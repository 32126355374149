import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityResults.scss';

import { duplicateOpportunity, updateOpportunity, deleteOpportunity, setActiveOpportunity } from '../../Actions/OpportunityActions';

import { getOpportunities } from '../../Helpers/user_helpers';
import { hasOpportunityStarted, hasOpportunityEnded, isOpportunityActive } from '../../Helpers/opportunity_helpers';

import OpportunityResult from './OpportunityResult';
import Loader from '../Loader/Loader';

const OpportunityResults = props => {
  const { analytics, user, curSearchVal } = props;

  const opportunities = getOpportunities(user);
  const filteredOpportunities = opportunities.filter(o => !curSearchVal || o.title?.toLowerCase().includes(curSearchVal.toLowerCase()));
  const sortedOpportunities = _.orderBy(filteredOpportunities, ['trackingStartsAt', 'createdAt'], ['desc', 'desc']);
  const unarchivedOpportunities = sortedOpportunities.filter(o => !o.isArchived);
  const isFetchingRequests = analytics.brandAnalytics?.isFetchingAnalytics;

  const opportunitySections = [
    {
      display: 'Active Opportunities',
      opportunities: unarchivedOpportunities.filter(isOpportunityActive)
    },
    {
      display: 'Upcoming Opportunities',
      opportunities: unarchivedOpportunities.filter(o => !hasOpportunityStarted(o))
    },
    {
      display: 'Completed Opportunities',
      opportunities: unarchivedOpportunities.filter(hasOpportunityEnded)
    },
    {
      display: 'Archived Opportunities',
      opportunities: opportunities.filter(o => o.isArchived),
      showToggleIcon: true
    }
  ].filter(section => section.opportunities.length);
  const [hiddenSections, setHiddenSections] = React.useState(['Archived Opportunities']);

  return (
    <div className='opportunity-results-outer-container'>
      <div className='opportunity-result-sections'>
        {opportunitySections.map((section, idx) => {
          const { display, opportunities, showToggleIcon } = section;
          const isHidden = hiddenSections.includes(display);
          const hideSectionHeader = idx === 0 && !isHidden;
          const toggleSection = () => setHiddenSections(isHidden ? hiddenSections.filter(s => s !== display) : [...hiddenSections, display]);
          return (
            <div key={display + idx} className='opportunities-section'>
              {!hideSectionHeader && (
                <div
                  onClick={toggleSection}
                  className={cn('section-title-container', {
                    'show-toggle': showToggleIcon
                  })}
                >
                  <div className='section-title'>
                    {isHidden ? 'Show ' : ''}
                    {section.display}
                  </div>
                  {(showToggleIcon || isHidden) && (
                    <div className='toggle-section'>
                      <FontAwesomeIcon icon={isHidden ? faChevronDown : faChevronUp} />
                    </div>
                  )}
                </div>
              )}
              {!isHidden && (
                <div className='opportunities'>
                  {opportunities.map(opportunity => {
                    return (
                      <OpportunityResult
                        key={opportunity.id}
                        opportunity={opportunity}
                        user={user}
                        analytics={analytics}
                        isFetchingRequests={isFetchingRequests}
                        deleteOpportunity={props.deleteOpportunity}
                        updateOpportunity={props.updateOpportunity}
                        duplicateOpportunity={props.duplicateOpportunity}
                        setActiveOpportunity={props.setActiveOpportunity}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
        {opportunities.length < 1 && <CreateNewOpportunityTile {...props} />}
        {filteredOpportunities.length < 1 && opportunities.length > 0 && (
          <div className='search-results-empty'>No opportunities found with the search term "{curSearchVal}"</div>
        )}
      </div>
    </div>
  );
};

const CreateNewOpportunityTile = props => {
  const [isCreatingOpportunity, setIsCreatingOpportunity] = React.useState(false);
  const createNew = async () => {
    setIsCreatingOpportunity(true);
    await props.createNewOpportunity();
    setIsCreatingOpportunity(false);
  };
  return (
    <div className='opportunity-result-empty' onClick={createNew}>
      {!isCreatingOpportunity ? <FontAwesomeIcon icon={faPlus} /> : <Loader />}
    </div>
  );
};

OpportunityResults.propTypes = {
  analytics: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  duplicateOpportunity: PropTypes.func.isRequired,
  deleteOpportunity: PropTypes.func.isRequired,
  setActiveOpportunity: PropTypes.func.isRequired,

  // From outer
  createNewOpportunity: PropTypes.func.isRequired,
  curSearchVal: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { analytics, user } = state;
  return { analytics, user };
};

export default connect(mapStateToProps, {
  updateOpportunity,
  duplicateOpportunity,
  deleteOpportunity,
  setActiveOpportunity
})(OpportunityResults);
