import React from 'react';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';

import './CollaborationsExplainer.scss';

import { getSiteTitle, scrollToTop } from '../../Helpers/helpers';
import { getBrand, getTasks, getUsername } from '../../Helpers/user_helpers';

const CollaborationsExplainer = props => {
  const { user } = props;
  const tasks = getTasks(user);
  const brand = getBrand(user);
  const hasTasks = !!tasks.length;
  return (
    <div className='collaborations-explainer-outer-container'>
      <div className='explainer-header-container'>
        <div className='explainer-badge'>Introducing</div>
        {brand ? (
          <>
            <div className='explainer-header'>Creator Collaborations</div>
            <div className='explainer-subheader'>
              On this tab you will find all the collaborations proposed by talent as well as any collaborations you have proposed. To propose a
              collaboration, click on the creator's profile and navigate to the "Collaborations" tab. Please reach out to{' '}
              {brand.account_manager ? `${brand.account_manager}, your account manager` : `your account manager`} for a demo on how to get started.
            </div>
          </>
        ) : (
          <>
            <div className='explainer-header'>Brand Collaborations</div>
            <div className='explainer-subheader'>The newest way to earn on {getSiteTitle()}.</div>
            <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/collaborate-with-brands' className='explainer-external-link'>
              SEE HOW IT WORKS
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </>
        )}
      </div>
      {!brand && (
        <div className='explainer-body-container'>
          <div className='explainer-body'>
            <div className={cn('step', { active: true })}>
              <div className='content'>
                <div className='badge'>Step One</div>
                <div className='header'>Configure your offerings</div>
                <div className='subheader'>Let brands know the types of partnerships that you're open to.</div>
              </div>
              <div className='actions'>
                {props.clickGetStarted ? (
                  <div onClick={props.clickGetStarted} className='action'>
                    Get Started
                  </div>
                ) : (
                  <Link onClick={scrollToTop} to={`/${getUsername(user)}/collaborations`} className='action'>
                    Get Started
                  </Link>
                )}
              </div>
            </div>
            <div className={cn('step', { active: hasTasks })}>
              <div className='content'>
                <div className='badge'>Step Two</div>
                <div className='header'>Generate Proposals</div>
                <div className='subheader'>Reach out and propose partnerships with brands.</div>
              </div>
              <div className='actions'>
                {!hasTasks ? (
                  <div onClick={() => cogoToast.warn(`Please specify your collaborations before reaching out to brands`)} className='action'>
                    Search Brands
                  </div>
                ) : props.clickSearchBrands ? (
                  <div onClick={props.clickSearchBrands} className='action'>
                    Search Brands
                  </div>
                ) : (
                  <Link onClick={scrollToTop} to='/collaborations/search' className='action'>
                    Search Brands
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

CollaborationsExplainer.propTypes = {
  user: PropTypes.object.isRequired,

  // If you want to use click events and not links
  clickGetStarted: PropTypes.func,
  clickSearchBrands: PropTypes.func
};

export default CollaborationsExplainer;
