import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './LookbookOrderModalCartItem.scss';

import * as lookbookHelpers from '../../../../Helpers/lookbook_helpers';

import Image from '../../../General/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { faMinus, faTimes } from '@fortawesome/pro-regular-svg-icons';

const LookbookOrderModalCartItem = props => {
  const { cartItem, updateLookbookCart, lookbook, lookbooks } = props;
  const { item, sibling, quantity } = cartItem;

  const image = sibling.image || lookbookHelpers.getLookbookItemStartingImage(item);
  const url = sibling.url || item.url;
  const brand = item.brand || lookbook.brand.name;

  const lookbookCart = lookbookHelpers.getCartItemsForLookbook(lookbooks, lookbook);
  const item_limit = lookbook.item_limit || 1;
  const cartIsAtMaxCapacity = lookbookHelpers.calculateLookbookCartItems(lookbookCart).cartItemCount >= item_limit;

  const addItem = () => updateLookbookCart(lookbook, sibling, quantity + 1);
  const removeItem = () => updateLookbookCart(lookbook, sibling, quantity - 1);

  const showQuantitySelector = quantity > 1 || !cartIsAtMaxCapacity;

  return (
    <div className='lookbook-order-modal-cart-item-outer'>
      <div className='lookbook-order-modal-cart-item-inner'>
        <div className='image-container'>
          <Image src={image} alt={item.title} useGenericFailedStyles />
        </div>
        <div className='text-container'>
          <div className='product-details'>
            <div className='brand'>{brand}</div>
            <div className='title'>{item.title}</div>
            {!!sibling.title && <div className='subtitle'>{sibling.title}</div>}
            <a href={url} target='_blank' rel='noreferrer' className='url'>
              Visit Item
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
          {showQuantitySelector ? (
            <div className='addition-and-subtraction-buttons-container'>
              <div className='addition-and-subtraction-buttons'>
                <button className={cn('addition', { cartIsAtMaxCapacity })} onClick={addItem}>
                  +
                </button>
                <span className='item-count'>{quantity}</span>
                <button className='subtraction' onClick={removeItem}>
                  {quantity > 1 ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </div>
            </div>
          ) : (
            <div onClick={removeItem} className='remove'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LookbookOrderModalCartItem.propTypes = {
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  cartItem: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired
};

export default LookbookOrderModalCartItem;
