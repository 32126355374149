import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './PartnerPortalTopNotification.scss';

import { getUIKeyValuePartners } from '../../Helpers/ui_helpers';

const PartnerPortalTopNotification = props => {
  const { ui, header, subheader, action, secondaryAction, notificationEnum, setUIKeyValue } = props;

  const isDismissed = getUIKeyValuePartners(ui, notificationEnum);
  const dismiss = () => setUIKeyValue(notificationEnum, true, 'partners');
  if (isDismissed) return null;

  return (
    <div className='partner-portal-top-notification'>
      <div className='header-container'>
        <div className='header'>{header}</div>
        {subheader && <div className='subheader'>{subheader}</div>}
      </div>
      {action && (
        <div className='actions-container'>
          {action.link ? (
            <Link to={action.link} className='action primary'>
              {action.display}
            </Link>
          ) : (
            <div onClick={action.onClick} className='action primary'>
              {action.display}
            </div>
          )}
          {secondaryAction &&
            (secondaryAction.link ? (
              <Link to={secondaryAction.link} className='action secondary'>
                {secondaryAction.display}
              </Link>
            ) : (
              <div onClick={secondaryAction.onClick} className='action secondary'>
                {secondaryAction.display}
              </div>
            ))}
          <div onClick={dismiss} className='action dismiss'>
            Dismiss
          </div>
        </div>
      )}
    </div>
  );
};

PartnerPortalTopNotification.propTypes = {
  ui: PropTypes.object.isRequired,
  notificationEnum: PropTypes.string.isRequired,
  setUIKeyValue: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.shape({
    display: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func
  }),
  secondaryAction: PropTypes.shape({
    display: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func
  })
};

export default PartnerPortalTopNotification;
