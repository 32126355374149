import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import cn from 'classnames';
import _ from 'lodash';
import './LookbookItem.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faLinkSlash, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

import { isBrand, getShopifyIntegration, getAllShopifyIntegrations } from '../../Helpers/user_helpers';
import { getGiftingRequestsForLookbook } from '../../Helpers/brand_helpers';
import { getCartForLookbook, getLookbookItemInitialSibling, getDomainsForLookbookItem } from '../../Helpers/lookbook_helpers';

import Tooltip from '../General/Tooltip';
import LookbookItemImages from './Elements/LookbookItemImages';

const LookbookItem = props => {
  const { analytics, lookbooks, lookbook, user, item, openEditModal, canEdit, openReplaceProductsModal } = props;
  const brandChoseManualShipping = lookbook.orderHandledBy === 'brand';
  const brandHasAnyShopifyIntegration = getAllShopifyIntegrations(user).length > 0;
  const isLookbookShopifyConfigured = brandHasAnyShopifyIntegration && !brandChoseManualShipping;

  const { itemDomains, isPartiallyShopifyDisconnected, isFullyShopifyDisconnected } = useMemo(() => {
    const isFullyShopifyDisconnected = item?.siblings?.every(sibling => !sibling.shopifyVariantId);
    const isPartiallyShopifyDisconnected = !isFullyShopifyDisconnected && item?.siblings?.some(sibling => !sibling.shopifyVariantId);

    const domainsRaw = getDomainsForLookbookItem(item);
    const itemDomains = domainsRaw.map(domain => {
      const integration = getShopifyIntegration(user, domain);
      const isShopifyConnected = integration.allowOrderCreation && integration.allowProductSyncing;
      return { domain, isShopifyConnected };
    });

    return { itemDomains, isPartiallyShopifyDisconnected, isFullyShopifyDisconnected };
  }, [item, user]);

  // Remove hidden siblings from reduction
  const reduced_siblings = item.reduced_siblings
    .map(item => ({
      ...item,
      size_variations: item.size_variations.filter(sibling => !sibling.isHidden)
    }))
    .filter(item => !item.isHidden);

  const requests = isBrand(user) ? getGiftingRequestsForLookbook(analytics, lookbook) : null;
  const orders = _.flatten(_.filter(_.map(requests, 'lookbook_order'), o => o?.id));
  const ordersWithItem = _.filter(orders, order => order.items?.some(orderItem => orderItem.LookbookItem_id === item.id));
  const selectedByUserIds = _.uniq(_.map(ordersWithItem, 'User_id'));

  // Handle Two Layers of Siblings
  const initialSibling = getLookbookItemInitialSibling(item);
  const [activeVariantSibling, setActiveVariantSibling] = React.useState(initialSibling);
  const [activeSizeSibling, setActiveSizeSibling] = React.useState(
    initialSibling?.size_variations?.find(sibling => sibling.isAvailable && !sibling.isHidden)
  );
  const activeSibling = activeSizeSibling || activeVariantSibling;
  const visibleSizeVariants = activeVariantSibling?.size_variations?.filter(sibling => !sibling.isHidden);

  // Handle Sizes
  const hasSiblings = reduced_siblings?.length > 0;
  const showSiblingSelection = reduced_siblings?.length > 0 || _.flatten(reduced_siblings.map(sibling => sibling.size_variations)).length > 0;
  const hasActiveSizes = activeVariantSibling?.size_variations?.length > 1;
  const isVariantSiblingActive = sibling => activeVariantSibling?.id === sibling.id;
  const isSizeSiblingActive = sibling => activeSizeSibling?.id === sibling.id;

  // Sizes
  const clickVariantSibling = sibling => {
    if (isVariantSiblingActive(sibling)) return;

    setActiveVariantSibling(sibling);

    // Try to maintain size selection
    const newSize = sibling.size_variations.find(size => size.tag === activeSizeSibling?.tag);
    setActiveSizeSibling(newSize || sibling.size_variations?.[0]);
  };
  const clickSizeSibling = sibling => {
    if (isSizeSiblingActive(sibling)) return;
    setActiveSizeSibling(sibling);
  };

  // Edit Actions
  const deleteItemFromLookbook = () => {
    const confirm = () => props.deleteItemFromLookbook(item);
    if (selectedByUserIds.length) {
      confirmAlert({
        title: 'Are you sure?',
        message:
          'This has been selected previously by creators. Deleting it will not impact the creators but will make it tougher to guage ROI for this lookbook.',
        buttons: [
          { label: 'Cancel', className: 'cancel', onClick: () => {} },
          { label: 'Confirm', onClick: confirm }
        ]
      });
    } else {
      confirm();
    }
  };

  // Cart Actions
  const cart = getCartForLookbook(lookbooks, lookbook);
  const selectedSiblingQuantity = cart[activeSibling?.id] || 0;

  const toggleCartItem = () => {
    if (isBrand(user)) return cogoToast.info(`This is just a preview!`);
    if (!activeSibling) return cogoToast.error('Please select a variation');
    props.updateLookbookCart(lookbook, activeSibling, selectedSiblingQuantity > 0 ? 0 : 1);
  };

  const title = item.title;
  const price = activeSibling?.price || item.price;
  const url = activeSibling?.url || item.url;
  const description = item.description;
  const hideFromPublicCatalog = item.variant?.hideFromPublicCatalog;

  // Page the siblings
  const PAGE_SIZE = 10;
  const PAGE_SIZE_SIZES = 12;
  const [numVisibleSiblings, setNumVisibleSiblings] = React.useState(PAGE_SIZE);
  const [numVisibleSizeSiblings, setNumVisibleSizeSiblings] = React.useState(PAGE_SIZE_SIZES);
  const hasMoreThanOnePage = reduced_siblings.length > PAGE_SIZE;
  const hasMoreSiblings = reduced_siblings.length > numVisibleSiblings;
  const hasMoreThanOnePageOfSizes = visibleSizeVariants?.length > PAGE_SIZE_SIZES;
  const hasMoreSizeSiblings = visibleSizeVariants?.length > numVisibleSizeSiblings;
  const maxOrdersLeft = item.maxNumTimesOrdered - item.numTimesOrdered;
  const hasReachedMaxOrderLimit = item.maxNumTimesOrdered && maxOrdersLeft <= 0;
  const isUnavailable = !activeSibling?.isAvailable || hasReachedMaxOrderLimit;

  const createShopifyDomainString = ({ domain, isShopifyConnected }) => `${domain}${!isShopifyConnected ? ' (not integrated)' : ''}`;

  return (
    <div className='lookbook-item-outer'>
      <div className={cn('lookbook-item-inner', { 'integration-fully-disconnected': isLookbookShopifyConfigured && isFullyShopifyDisconnected })}>
        {isFullyShopifyDisconnected && isLookbookShopifyConfigured && (
          <>
            <div className='item-disconnected-overlay'></div>
            <div className='item-disconnected-options-container'>
              <div className='item-disconnected-options'>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span className='header'>Shopify Disconnected</span>
                <div className='description'>
                  Creators can not currently select {!!title ? <span>"{title}"</span> : 'this product'} as it is not connected to your Shopify
                  integration. Please update this product or set the lookbook to manual order fulfillment.
                </div>
                <div className='actions'>
                  <div className='action-btn primary' onClick={() => openEditModal(item)}>
                    Edit Product
                  </div>
                  <div className='action-btn secondary' onClick={() => openReplaceProductsModal(item)}>
                    Replace
                  </div>
                  <div className='action-btn tertiary' onClick={deleteItemFromLookbook}>
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {canEdit && props.rearrangeHandle}
        {canEdit && isLookbookShopifyConfigured && (
          <div className='shopify-icon-container'>
            {isPartiallyShopifyDisconnected ? (
              <Tooltip
                message={`This item is partially connected to Shopify${itemDomains.length ? ' on ' + createShopifyDomainString(itemDomains[0]) : ''}`}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} className='warn clickable' onClick={() => openEditModal(item)} />
              </Tooltip>
            ) : isFullyShopifyDisconnected ? (
              <Tooltip message='This item is fully disconnected from Shopify'>
                <FontAwesomeIcon icon={faExclamationTriangle} className='error' />
              </Tooltip>
            ) : itemDomains.length > 1 ? (
              <Tooltip message={`${itemDomains.map(domain => createShopifyDomainString(domain)).join(', ')}`}>
                <FontAwesomeIcon icon={faExclamationTriangle} className='error' />
              </Tooltip>
            ) : itemDomains.length === 1 ? (
              <Tooltip message={createShopifyDomainString(itemDomains[0])}>
                {itemDomains[0].isShopifyConnected ? (
                  <img className='shopify-synced-icon' src='https://static.shopmy.us/Icons/shopify.png' alt='shopify-icon' />
                ) : (
                  <FontAwesomeIcon icon={faExclamationTriangle} className='error' />
                )}
              </Tooltip>
            ) : (
              <img className='shopify-synced-icon' src='https://static.shopmy.us/Icons/shopify.png' alt='shopify-icon' />
            )}
          </div>
        )}
        <div className='primary-container'>
          <LookbookItemImages lookbook={lookbook} item={item} activeSibling={activeSibling} />
          {!!selectedByUserIds.length && (
            <div className='creator-count'>
              Selected by {selectedByUserIds.length} creator{selectedByUserIds.length > 1 && 's'}
            </div>
          )}
        </div>
        <div className={cn('data-container', { 'can-edit': canEdit })}>
          <div>
            <div className='item-section'>
              <div className='title-container'>
                <div className='title'>{title}</div>
                {!!url && (
                  <>
                    {hideFromPublicCatalog ? (
                      <a href={url} target='_blank' rel='noreferrer' className={cn('external-link', 'hideFromPublicCatalog')}>
                        <Tooltip message='This item is currently not yet publically available'>
                          <FontAwesomeIcon icon={faExternalLink} />
                        </Tooltip>
                      </a>
                    ) : (
                      <a href={url} target='_blank' rel='noreferrer' className='external-link'>
                        <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    )}
                  </>
                )}
              </div>
              {!!lookbook.price_limit && !!price && <div className='price'>retails for ${price}</div>}
              {description && <div className='description'>{description}</div>}
            </div>
            {showSiblingSelection && (
              <div className='siblings-container'>
                {reduced_siblings.length > 1 && (
                  <div className='item-siblings'>
                    {reduced_siblings.slice(0, numVisibleSiblings).map(sibling => {
                      const { id, tagParts } = sibling;
                      const { nonSizeTag: tag } = tagParts;
                      const active = isVariantSiblingActive(sibling);
                      const handleClick = () => clickVariantSibling(sibling);
                      const hasSizes = sibling.size_variations?.length > 0;
                      const isAvailable = hasSizes ? sibling.size_variations.some(size => size.isAvailable) : sibling.isAvailable;
                      const showShopifyDisconnection =
                        (hasSizes ? sibling.size_variations.some(size => !size.shopifyVariantId) : !sibling.shopifyVariantId) &&
                        isLookbookShopifyConfigured;

                      const classNames = { active };
                      if (showShopifyDisconnection) classNames.disconnected = true;
                      else if (!isAvailable) classNames.unavailable = true;

                      return (
                        <div key={id} className={cn('item-sibling', classNames)} onClick={handleClick}>
                          {tag}
                          {!!showShopifyDisconnection && (
                            <Tooltip message={'Disconnected from Shopify'}>
                              <FontAwesomeIcon icon={faLinkSlash} />
                            </Tooltip>
                          )}
                        </div>
                      );
                    })}
                    {hasMoreThanOnePage && (
                      <div
                        className='item-sibling show-more'
                        onClick={() => setNumVisibleSiblings(hasMoreSiblings ? numVisibleSiblings + PAGE_SIZE : PAGE_SIZE)}
                      >
                        {hasMoreSiblings ? 'Show More' : 'Show Less'}
                      </div>
                    )}
                  </div>
                )}

                {hasActiveSizes && (
                  <>
                    <div className='siblings-container-section-header'>select a size:</div>
                    <div className='item-siblings'>
                      {visibleSizeVariants.slice(0, numVisibleSizeSiblings).map(sibling => {
                        const { id, tagParts, isAvailable } = sibling;
                        const { sizeTag: tag } = tagParts;
                        const active = isSizeSiblingActive(sibling);
                        const handleClick = () => clickSizeSibling(sibling);
                        const showShopifyDisconnection = !sibling.shopifyVariantId && isLookbookShopifyConfigured;

                        const classNames = { active };
                        if (showShopifyDisconnection) classNames.disconnected = true;
                        else if (!isAvailable) classNames.unavailable = true;

                        return (
                          <div key={id} className={cn('item-sibling', classNames)} onClick={handleClick}>
                            {tag}
                            {!!showShopifyDisconnection && (
                              <Tooltip message={'Disconnected from Shopify'}>
                                <FontAwesomeIcon icon={faLinkSlash} />
                              </Tooltip>
                            )}
                          </div>
                        );
                      })}
                      {hasMoreThanOnePageOfSizes && (
                        <div
                          className='item-sibling show-more'
                          onClick={() => setNumVisibleSizeSiblings(hasMoreSizeSiblings ? numVisibleSizeSiblings + PAGE_SIZE_SIZES : PAGE_SIZE_SIZES)}
                        >
                          {hasMoreSizeSiblings ? 'Show More' : 'Show Less'}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className={cn('actions', { hidden: isLookbookShopifyConfigured && isFullyShopifyDisconnected })}>
            {canEdit ? (
              <div className='primary' onClick={() => openEditModal(item)}>
                Edit Product
              </div>
            ) : hasReachedMaxOrderLimit && isBrand(user) ? (
              <div className='primary disabled' onClick={() => openEditModal(item)}>
                Reached Max Orders
              </div>
            ) : isUnavailable ? (
              <div className='primary disabled' onClick={() => openEditModal(item)}>
                Unavailable
              </div>
            ) : !hasSiblings ? (
              <div className={cn('primary', { disabled: true })} onClick={toggleCartItem}>
                {window.__ADMIN_CONTROL_MODE__ ? 'No Siblings Available' : 'Unavailable'}
              </div>
            ) : selectedSiblingQuantity > 0 ? (
              <div className='primary active' onClick={toggleCartItem}>
                Currently Selected
              </div>
            ) : (
              <div className='primary remove' onClick={toggleCartItem}>
                Select Product
              </div>
            )}
            {canEdit && (
              <div className='secondary' onClick={deleteItemFromLookbook}>
                <FontAwesomeIcon icon={faTrash} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookItem.propTypes = {
  user: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  ui: PropTypes.object,

  // If User
  lookbooks: PropTypes.object,

  // If Brand
  analytics: PropTypes.object,
  DragHandle: PropTypes.func,
  rearrangeHandle: PropTypes.object,
  openEditModal: PropTypes.func,
  deleteItemFromLookbook: PropTypes.func,
  openReplaceProductsModal: PropTypes.func
};

export default LookbookItem;
