import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import commaNumber from 'comma-number';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faComment, faTrash, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityLeaderboardCardInactive.scss';

import { alertChangeInOpportunityRequestOffer } from '../../../APIClient/opportunities';

import ConfirmDelete from '../../General/ConfirmDelete';
import ConfirmPrompt from '../../General/ConfirmPrompt';
import Tooltip from '../../General/Tooltip';

import {
  hasOpportunityEnded,
  getStatusDataForOpportunityRequest,
  getSpendDataOnOpportunityRequest,
  getOpportunityRequestAdjustedExpectations,
  isOpportunityRequestExpired
} from '../../../Helpers/opportunity_helpers';
import { formatUsersForBulkRequests, openEditRequestExpirationOverlay } from '../../../Helpers/gifting_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';
import { isSMSAffiliatePartnerBrand } from '../../../Helpers/user_helpers';
import { getBrandCommissionRateForUser, getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';

const OpportunityLeaderboardCardInactive = props => {
  const { user, request, analytics, opportunity } = props;
  const { id, OpportunityPaymentTier_id, userRejected, expiresOn } = request;
  const adjustedExpectations = getOpportunityRequestAdjustedExpectations(opportunity, request);
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = adjustedExpectations;
  const payment_tier = _.find(opportunity.payment_tiers, { id: OpportunityPaymentTier_id });
  const { fixedFee, adjCommissionRate } = payment_tier || {};
  const hasTrackingEnded = hasOpportunityEnded(opportunity);
  const standardRate = getBrandCommissionRateForUser(user);
  const statusData = getStatusDataForOpportunityRequest(request);
  const spendData = getSpendDataOnOpportunityRequest(request, opportunity);
  const metadata = _.filter([
    spendData.total ? '$' + getPrettyNumber(spendData.total) : '',
    adjCommissionRate ? `${adjCommissionRate}%` : '',
    statusData.actionDisplay,
    ...adjustedExpectations.adjustmentLabels
  ]);
  const additionalClasses = { expired: statusData.isExpired, dismissed: statusData.isDismissed, 'tracking-ended': hasTrackingEnded };
  const isExpired = isOpportunityRequestExpired(request);

  const openProfile = () => props.openArtistModal(request.user);
  const openChat = () => props.openChatOverlay(request.user);
  const removeRequest = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Are you sure?'
          subheader={`We will remove this request and notify ${request.user.name}.`}
          onCancel={onClose}
          hideCancel={false}
          onDelete={() => {
            onClose();
            props.removeOpportunityRequest(request).then(resp => {
              window.ALERT.success('Request removed!');
            });
          }}
        />
      )
    });
  };
  const changeOffer = () => {
    if (hasTrackingEnded) return window.ALERT.error('You cannot change the offer because the tracking period has ended.');
    let inputs = [];

    inputs.push({
      display: 'Fixed Fee',
      preloaded: fixedFee,
      placeholder: fixedFee ? `$${commaNumber(fixedFee)}` : '$1000',
      value: 'fixedFee',
      isSingleLine: true
    });

    inputs.push({
      display: 'Commission Rate',
      placeholder: 'Default Rate',
      preloaded: adjCommissionRate,
      value: 'adjCommissionRate',
      isSingleLine: true,
      disabledMsg: isSMSAffiliatePartnerBrand(user) ? null : `Commission Rate is fixed at ${standardRate}%`
    });

    if (opportunity.linksExpected > 1) {
      inputs.push({
        display: 'Links Expected',
        placeholder: opportunity.linksExpected,
        preloaded: linksExpected,
        value: 'links',
        isSingleLine: true
      });
    }

    if (opportunity.linkingDaysExpected > 1) {
      inputs.push({
        display: 'Linking Days Expected',
        placeholder: opportunity.linkingDaysExpected,
        preloaded: linkingDaysExpected,
        value: 'linkingDays',
        isSingleLine: true
      });
    }

    if (opportunity.mentionsExpected > 1) {
      inputs.push({
        display: 'Mentions Expected',
        placeholder: opportunity.mentionsExpected,
        preloaded: mentionsExpected,
        value: 'mentions',
        isSingleLine: true
      });
    }

    if (opportunity.mentionDaysExpected > 1) {
      inputs.push({
        display: 'Mention Days Expected',
        placeholder: opportunity.mentionDaysExpected,
        preloaded: mentionDaysExpected,
        value: 'mentionDays',
        isSingleLine: true
      });
    }

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Update Offer'
          onCancel={onClose}
          submitMustReturnTrueToClose
          customInputFields={inputs}
          onSubmitAwait={async responseValues => {
            const newFixedFee = responseValues.fixedFee ? parseInt(String(responseValues.fixedFee).replace(/\D/g, '')) || null : null;
            const newAdjRate = responseValues.adjCommissionRate
              ? parseFloat(String(responseValues.adjCommissionRate).replace('%', '')) || null
              : null;

            const failedSubmission = msg => {
              window.ALERT.error(msg);
              return false;
            };
            const doNothing = () => true;

            if (!newFixedFee && !newAdjRate) return failedSubmission('You must enter a fixed fee or commission rate.');

            // Check for adjustments
            let adjustments = {};
            const { links, linkingDays, mentions, mentionDays } = responseValues;

            // Check Validity
            const isInvalid = (value, expected) => +value <= 0 || !_.isNumber(+value);
            const invalidMsg = (value, type) => `${value} is an invalid entry - ${type} must be greater than 0.`;
            if (isInvalid(links, linksExpected)) return failedSubmission(invalidMsg(links, 'links'));
            if (isInvalid(linkingDays, linkingDaysExpected)) return failedSubmission(invalidMsg(linkingDays, 'linking days'));
            if (isInvalid(mentions, mentionsExpected)) return failedSubmission(invalidMsg(mentions, 'mentions'));
            if (isInvalid(mentionDays, mentionDaysExpected)) return failedSubmission(invalidMsg(mentionDays, 'mention days'));

            // Set adjustments
            if (+links) adjustments.links = links - opportunity.linksExpected;
            if (+linkingDays) adjustments.linkingDays = linkingDays - opportunity.linkingDaysExpected;
            if (+mentions) adjustments.mentions = mentions - opportunity.mentionsExpected;
            if (+mentionDays) adjustments.mentionDays = mentionDays - opportunity.mentionDaysExpected;
            adjustments = _.omitBy(adjustments, v => !v);

            // If they are the same, just return
            const hasChangedFee = newFixedFee !== fixedFee;
            const hasChangedRate = newAdjRate !== adjCommissionRate;
            const hasChangedTier = hasChangedFee || hasChangedRate;
            const hasChangedAdjustments = !_.isEqual(adjustments, JSON.parse(request.expectationAdjustments || '{}'));
            if (!hasChangedTier && !hasChangedAdjustments) return doNothing();

            // Ensure we update the tiers if needed
            if (hasChangedTier) {
              const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
              const isOnlyRequestUsingExistingPaymentTier =
                requests.filter(request => request.OpportunityPaymentTier_id === OpportunityPaymentTier_id).length === 1;
              const existingPaymentTierWithNewData = opportunity.payment_tiers.find(tier => {
                if (newFixedFee && tier.fixedFee !== newFixedFee) return false;
                if (!newFixedFee && tier.fixedFee) return false;
                if (newAdjRate && tier.adjCommissionRate !== newAdjRate) return false;
                if (!newAdjRate && tier.adjCommissionRate) return false;
                return true;
              });

              // Case 1a: The current payment tier is only used by this request and we can't find a match so we update it
              if (isOnlyRequestUsingExistingPaymentTier && !existingPaymentTierWithNewData) {
                await props.updateOpportunityPaymentTier(payment_tier, {
                  id: OpportunityPaymentTier_id,
                  fixedFee: newFixedFee,
                  adjCommissionRate: newAdjRate
                });
              }

              // Case 1b: The current payment tier is only used by this request and we can find a match so we update it and delete this one
              if (isOnlyRequestUsingExistingPaymentTier && existingPaymentTierWithNewData) {
                await props.updateOpportunityRequest(request, {
                  OpportunityPaymentTier_id: existingPaymentTierWithNewData.id
                });
                await props.deleteOpportunityPaymentTier(payment_tier);
              }

              // Case 2a: The current payment tier is used by multiple requests and we can find a match so we update it and leave this one
              if (!isOnlyRequestUsingExistingPaymentTier && existingPaymentTierWithNewData) {
                await props.updateOpportunityRequest(request, {
                  OpportunityPaymentTier_id: existingPaymentTierWithNewData.id
                });
              }

              // Case 2b: The current payment tier is used by multiple requests and we can't find a match so we create a new one but leave this one
              if (!isOnlyRequestUsingExistingPaymentTier && !existingPaymentTierWithNewData) {
                const { payment_tier } = await props.createOpportunityPaymentTier({
                  Opportunity_id: opportunity.id,
                  fixedFee: newFixedFee,
                  adjCommissionRate: newAdjRate
                });
                payment_tier && (await props.updateOpportunityRequest(request, { OpportunityPaymentTier_id: payment_tier.id }));
              }
            }

            // Update the adjustments
            if (hasChangedAdjustments) {
              await props.updateOpportunityRequest(request, { expectationAdjustments: JSON.stringify(adjustments) });
            }

            await alertChangeInOpportunityRequestOffer({
              OpportunityRequest_id: request.id,
              previousFixedFee: fixedFee,
              newFixedFee,
              previousAdjCommissionRate: adjCommissionRate,
              newAdjCommissionRate: newAdjRate,
              newExpectationAdjustments: adjustments,
              previousExpectationAdjustments: JSON.parse(request.expectationAdjustments || '{}')
            });
            window.ALERT.success('Offer Updated!');
            return true;
          }}
        />
      )
    });
  };

  const deleteAndResend = () => {
    if (hasTrackingEnded) return window.ALERT.error('You cannot resend this request because the tracking period has ended.');
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Just Confirming'
          subheader='Are you sure you want to remove this request and resend it?'
          onCancel={onClose}
          onDelete={async () => {
            await props.removeOpportunityRequest(request);
            props.openRequestModal({
              params: {
                type: 'opportunities',
                Opportunity_id: opportunity.id,
                OpportunityPaymentTier_id: request.OpportunityPaymentTier_id,
                warningOnClose: 'You have not re-sent this opportunity request yet. Are you sure you want to close this modal?',
                preselectedUsers: formatUsersForBulkRequests([request.user])
              }
            });
            onClose();
          }}
        />
      )
    });
  };

  const updateExpiration = () => {
    openEditRequestExpirationOverlay(request, props.updateOpportunityRequest);
  };
  return (
    <div key={id} className={cn('opportunity-leaderboard-card-inactive', additionalClasses)}>
      <div onClick={openProfile} className={cn('media-container', additionalClasses)}>
        <div className={cn('user-image-container', additionalClasses)}>
          <div className='image-badge-wrapper' />
          <img src={request.user.image} alt={request.user.name} />
        </div>
      </div>
      <div className={cn('main-container', additionalClasses)}>
        <div className='section user-info'>
          <div className='section-data'>
            <div onClick={openProfile} className='name'>
              {request.user.name}
            </div>
            <div className='metadata'>{metadata.join(' • ')}</div>
          </div>
        </div>
        <div className='actions'>
          <Tooltip message='Remove Request'>
            <div onClick={removeRequest} className='action icon'>
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </Tooltip>
          <Tooltip message={expiresOn ? `Update Expiration from ${moment(expiresOn).format('MMM Do, YYYY')}` : 'Set Expiration Date'}>
            <div
              onClick={updateExpiration}
              className={cn('action icon', {
                inactive: !expiresOn
              })}
            >
              <FontAwesomeIcon icon={faCalendar} />
            </div>
          </Tooltip>
          <Tooltip message='View Profile'>
            <div onClick={openProfile} className='action icon'>
              <FontAwesomeIcon icon={faUser} />
            </div>
          </Tooltip>
          <Tooltip message='Open Chat'>
            <div onClick={openChat} className='action icon'>
              <FontAwesomeIcon icon={faComment} />
            </div>
          </Tooltip>
          {userRejected ? (
            <div onClick={deleteAndResend} className={cn('action primary', additionalClasses)}>
              Resend With New Offer
            </div>
          ) : isExpired ? (
            <div onClick={updateExpiration} className={cn('action primary', additionalClasses)}>
              Update Expiration Date
            </div>
          ) : (
            <div onClick={changeOffer} className={cn('action primary', additionalClasses)}>
              {fixedFee ? `Change $${commaNumber(fixedFee)} Offer` : adjCommissionRate ? `Change ${adjCommissionRate}% Offer` : 'Change Offer'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

OpportunityLeaderboardCardInactive.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  updateOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default OpportunityLeaderboardCardInactive;
