import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './DiscoverUsersResults.scss';

import Loader from '../Loader/Loader';
import DiscoverUsersResult from './DiscoverUsersResult';

const DiscoverUsersResults = props => {
  const { fetching, fetchingMore, activeQueries, searchVal, results } = props;

  return results.length ? (
    <>
      <div className={cn('discover-user-results-container', { fetching, 'fetching-more': fetchingMore })}>
        {results.map(artist => {
          return (
            <DiscoverUsersResult
              analytics={props.analytics}
              user={props.user}
              ui={props.ui}
              openArtistModal={props.openArtistModal}
              removeRecommendation={props.removeRecommendation}
              removeRecommendationRejection={props.removeRecommendationRejection}
              openChatOverlay={props.openChatOverlay}
              searchVal={props.searchVal}
              toggleQuery={props.toggleQuery}
              activeQueries={props.activeQueries}
              syncTags={props.syncTags}
              result={artist}
              key={artist.id}
            />
          );
        })}
      </div>
      {fetchingMore && <Loader size={120} />}
    </>
  ) : fetching ? (
    <Loader size={120} />
  ) : (
    <div className='discover-user-results-no-results'>
      {searchVal ? (
        activeQueries.length ? (
          <span>
            No results with the search term <i>{searchVal}</i> and those active filters. Please remove a filter or adjust your search.
          </span>
        ) : (
          <span>
            No results with the search term <i>{searchVal}</i>. You can search by name, username or bio.
          </span>
        )
      ) : activeQueries.length ? (
        `No results with those filters. Please remove at least one filter above to show results.`
      ) : (
        'No Results'
      )}
    </div>
  );
};

DiscoverUsersResults.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  toggleQuery: PropTypes.func.isRequired,
  syncTags: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  removeRecommendation: PropTypes.func.isRequired,
  removeRecommendationRejection: PropTypes.func.isRequired,
  activeQueries: PropTypes.array.isRequired,
  searchVal: PropTypes.string.isRequired,
  results: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  fetchingMore: PropTypes.bool.isRequired,
  openChatOverlay: PropTypes.func.isRequired
};

export default DiscoverUsersResults;
