import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import './ArtistModalClicks.scss';

import ArtistModalPanelEmpty from './ArtistModalPanelEmpty';

import { portalBugOpenUrl, getRootSMSUrl } from '../../Helpers/helpers';
import { getDomainFromUrl, getPrettyNumber, getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getBrand } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';

const ArtistModalClicks = props => {
  const { talent, mentions, user } = props;
  const brand = getBrand(user);

  return (
    <div className='artist-modal-clicks-container'>
      <div className='artist-modal-mentions'>
        {!mentions.length ? (
          <ArtistModalPanelEmpty message={`${talent.name} has not yet created links to your products.`} />
        ) : (
          mentions.map(mention => {
            const {
              id,
              createdAt,
              link,
              image,
              merchant_data,
              Collection_id,
              Collection_name,
              Consult_id,
              Consult_title,
              Contract_id,
              Contract_title,
              Product_title,
              ordersTotal,
              orderVolumeTotal,
              title,
              views
            } = mention;
            const domain = getDomainFromUrl(link);
            const dateDisplay =
              moment().format('YYYY') === moment(createdAt).format('YYYY')
                ? moment(createdAt).format('MMMM Do')
                : moment(createdAt).format('MMMM Do, YYYY');
            const titleDisplay = Product_title || title;
            const merchantDisplay = merchant_data?.name || domain;
            const collectionUrl = Collection_id && `${getRootSMSUrl()}/collections/${Collection_id}`;
            const consultUrl = Consult_id && `${getRootSMSUrl()}/consults/${Consult_id}`;
            const contractUrl = Contract_id && `${getRootSMSUrl()}/collaboration/${Contract_id}`;
            const contentUrl = collectionUrl || consultUrl || contractUrl;
            const contentDisplay = Collection_name || Contract_title || Consult_title || (id ? 'Quick Link' : '-');

            const isCrossRetailer = domain && !domain.includes(brand.domain) && !brand.altDomains?.includes(domain);
            const canViewCrossRetailer = isSubscribedToFeature(user, 'CROSS_RETAILER_ANALYTICS');
            const hideDueToPermissions = isCrossRetailer && !canViewCrossRetailer;
            const blockedAlert = () => blockOnRequiringSubscription(user, 'CROSS_RETAILER_ANALYTICS');
            return (
              <div key={id} className='mention'>
                <div className='main'>
                  <div className='image-container'>{image ? <img src={image} alt={title} /> : <div className='empty' />}</div>
                  <div className='data'>
                    <div className='created'>{dateDisplay}</div>
                    {contentUrl ? (
                      <a onClick={portalBugOpenUrl} href={contentUrl} target='_blank' rel='noopener noreferrer' className='content'>
                        {contentDisplay}
                      </a>
                    ) : (
                      <div className='content'>{titleDisplay}</div>
                    )}
                    <div className='title'>{title}</div>
                    <a onClick={portalBugOpenUrl} href={link} target='_blank' rel='noopener noreferrer' className='merchant'>
                      {merchantDisplay}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  </div>
                </div>
                {hideDueToPermissions ? (
                  <>
                    <div onClick={blockedAlert} className='blocked-stats'>
                      <div className='msg'>Blocked</div>
                      <div className='learn-more'>Learn More</div>
                    </div>
                  </>
                ) : (
                  <div className='stats'>
                    <div className='stat'>
                      <div className='value'>{views ? getPrettyNumber(views) : '-'}</div>
                      <div className='metric'>Clicks</div>
                    </div>
                    <div className='stat'>
                      <div className='value'>{ordersTotal || '-'}</div>
                      <div className='metric'>orders</div>
                    </div>
                    <div className='stat'>
                      <div className='value'>{orderVolumeTotal ? getPrettyPriceDisplay(orderVolumeTotal, { precision: 0 }) : '-'}</div>
                      <div className='metric'>volume</div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

ArtistModalClicks.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired
};

export default ArtistModalClicks;
