import APIClient from './index';
import _ from 'lodash';

const routePrefix = 'TeamMembers';

export const getAllTeamMembersByDepartment = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/${routePrefix}/active_by_department`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const createTeamMember = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/${routePrefix}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateTeamMember = (teamMember, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/${routePrefix}/${teamMember.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
