import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ContractHeader.scss';

import { getStatusDisplayForContract, getStatusTimeDisplayForContract } from '../../Helpers/contract_helpers';
import { getInitialsForUser, getInitialsForBrand, getPrettyPriceDisplay } from '../../Helpers/formatting';
import { matchScrollHeight } from '../../Helpers/helpers';

let debounce;
const ContractHeader = props => {
  const { contract, canEdit, updateContract } = props;
  const { brand, status, price } = contract;
  const isPending = status === 'pending';

  const [title, setTitle] = useState(contract.title);

  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      updateContract(contract, { title: newTitle });
    }, 1000);
  };
  return (
    <div className='contract-header-outer-container'>
      <div className='status'>{getStatusDisplayForContract(contract)}</div>
      {canEdit && isPending ? (
        <textarea
          rows={1}
          value={title}
          className='title'
          ref={ref => matchScrollHeight(ref)}
          placeholder={'Name this collaboration'}
          onChange={e => updateTitle(e.target.value)}
        />
      ) : (
        <h1 className='title'>{contract.title}</h1>
      )}
      <div className='info-row'>
        <div className='pictures'>
          <div className='image empty'>{getInitialsForBrand(brand)}</div>
          {contract.user.image ? (
            <img onClick={props.openUserModal} className='user image' alt={contract.user.name} src={contract.user.image} />
          ) : (
            <div onClick={props.openUserModal} className='image empty'>
              {getInitialsForUser(contract.user)}
            </div>
          )}
        </div>
        {!!price && (
          <>
            <div className='bullet'>•</div>
            <div className='date'>{getPrettyPriceDisplay(price)}</div>
          </>
        )}
        <div className='bullet'>•</div>
        <div className='date'>{getStatusTimeDisplayForContract(contract)}</div>
      </div>
    </div>
  );
};

ContractHeader.propTypes = {
  contract: PropTypes.object.isRequired,
  openUserModal: PropTypes.func.isRequired,
  updateContract: PropTypes.func.isRequired
};

export default ContractHeader;
