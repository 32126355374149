import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './InviteTalentOffPlatformModal.scss';
import Modal from '../General/Modal';
import Mousetrap from 'mousetrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import cogoToast from 'cogo-toast';

const InviteTalentOffPlatformModal = props => {
  const { close, saveCallback } = props;

  const [talentToAdd, setTalentToAdd] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const saveList = () => saveCallback(talentToAdd);

  const addTalentToInviteList = () => {
    if (!name || !email) return cogoToast.warn('Please fill out all fields.');
    else if (talentToAdd.find(t => t.email === email)) return cogoToast.warn('This talent is already on the list.');
    setTalentToAdd([...talentToAdd, { name, email, offPlatform: true }]);
    setName('');
    setEmail('');
  };

  useEffect(() => {
    Mousetrap.bind('enter', addTalentToInviteList);
    return () => Mousetrap.unbind('enter');
  }, [name, email]);

  return (
    <Modal
      close={close}
      visible={true}
      showClose={true}
      className='invite-talent-off-platform-modal-outer'
      contentClassName='invite-talent-off-platform-modal-inner'
      smallForm
    >
      <div className='off-platform-modal-content'>
        <div className='off-platform-modal-header'>Invite Your Own Talent</div>
        <div className='off-platform-modal-body'>
          <div className='input-container'>
            <input
              type='text'
              placeholder='Name'
              value={name}
              onChange={e => setName(e.target.value)}
              className='off-platform-modal-body-content-inputs-input'
              onKeyDown={e => e.key === 'Enter' && addTalentToInviteList()}
              autoComplete='off'
            />
            <input
              type='text'
              placeholder='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              className='off-platform-modal-body-content-inputs-input'
              onKeyDown={e => e.key === 'Enter' && addTalentToInviteList()}
              autoComplete='off'
            />
            <div id='add' onClick={addTalentToInviteList}>
              Add User <kbd>enter</kbd>
            </div>
          </div>
          {talentToAdd.length > 0 && (
            <div className='invited-talent-container'>
              {talentToAdd.map((talent, i) => (
                <div key={talent.email} className='invited-talent' onClick={() => setTalentToAdd(talentToAdd.filter(t => t.email !== talent.email))}>
                  {talent.name}
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='off-platform-modal-footer'>
          <div id='cancel' onClick={close}>
            Cancel
          </div>
          <div id='add' onClick={addTalentToInviteList}>
            Add Talent <kbd>enter</kbd>
          </div>
          <div id='save' onClick={saveList}>
            Save ({talentToAdd.length})
          </div>
        </div>
      </div>
    </Modal>
  );
};

InviteTalentOffPlatformModal.propTypes = {
  close: PropTypes.func.isRequired,
  saveCallback: PropTypes.func.isRequired
};

export default InviteTalentOffPlatformModal;
