import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ProductTopArtists.scss';

import { getNameWithS } from '../../Helpers/user_helpers';

const ProductTopArtists = props => {
  const { product, expertElRef } = props;
  const { top_artists } = product;
  const [numVisibleArtists, updateVisibleArtistCount] = useState(3);
  if (!top_artists || !top_artists.length) return null;
  return (
    <div ref={expertElRef} className='product-top-artists-outer-container content-outer-container'>
      <div className='content-header-container'>
        <div className='content-header'>Experts Recommending This Product</div>
      </div>
      <div className='top-artists-container'>
        {top_artists.slice(0, numVisibleArtists).map(artist => {
          const { name, image, description, username } = artist;
          return (
            <div key={name} className='top-artist'>
              {image ? <img src={image} alt={name} /> : <div className='empty' />}
              <div className='user-data'>
                <div className='name'>{name}</div>
                <div className='description'>{description || 'Add artist bio'}</div>
                <Link to={`/${username}`} className='see-all-link'>
                  <div className='see-all-link'>VIEW {getNameWithS(artist).full} RECOMMENDATIONS</div>
                </Link>
              </div>
            </div>
          );
        })}
        {top_artists.length > numVisibleArtists && (
          <div onClick={() => updateVisibleArtistCount(numVisibleArtists + 3)} className='show-more-btn'>
            Show more
          </div>
        )}
      </div>
    </div>
  );
};

ProductTopArtists.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductTopArtists;
