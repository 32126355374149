import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import _ from 'lodash'
// import cn from 'classnames'

import './DiscoverBrands.scss';

const DiscoverBrands = props => {
  return (
    <div className='discover-brands-outer-container'>
      <div className='discover-brands-inner-container'>
        <div>Discover Brands Coming Soon</div>
      </div>
    </div>
  );
};

DiscoverBrands.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(DiscoverBrands);
