import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import './ChatPotentialPartner.scss';

import {
  isBrand,
  getShopUrlFromUser,
  hasProvenPromotionAbility,
  isSMSAffiliatePartnerBrand,
  getOutreachesRemaining,
  getUserTier,
  isUserInAllowedOutreachCountryCodes
} from '../../Helpers/user_helpers';
import { getDefaultImage } from '../../Helpers/ui_helpers';
import { getSocialPlatformForUrl, getSocialIconForUrl } from '../../Helpers/social_helpers';
import { isUserIdPromoterForOutreachPurposes } from '../../Helpers/talent_helpers';
import { isBannedFromChat, isBanned } from '../../Helpers/user_helpers';
import { isSubscribedToFeature } from '../../Helpers/subscription_helpers';
import { getSmartImage } from '../../Helpers/helpers';

import Tooltip from '../General/Tooltip';

const ChatPotentialPartner = props => {
  const { activePotentialPartner, ui, user, chats } = props;
  const { name, domain, image, social_links, logo, offersGifting, offersCollaborations, offersCodes, offersChat } = activePotentialPartner;
  const shopURL = getShopUrlFromUser(activePotentialPartner);
  const socialLinks = (social_links || '').split(',').filter(a => a);
  const socialData = _.orderBy(
    socialLinks.map(url => ({
      url,
      type: getSocialPlatformForUrl(url),
      icon: getSocialIconForUrl(url, 'dark')
    })),
    'type'
  );
  const isSMSAffiliatePartner = isSMSAffiliatePartnerBrand(user);

  /************************************************************************************************
   ************************************ Brand Specific *********************************************
   ************************************************************************************************/
  const userIsPromoter = isUserIdPromoterForOutreachPurposes(props.talent, activePotentialPartner?.id);
  const brandCanOfferCodes = isSubscribedToFeature(user, 'CUSTOM_CODES') && (userIsPromoter || isSubscribedToFeature(user, 'UNLIMITED_CHAT'));
  const brandCannotOfferCodesMessage = !userIsPromoter && `You cannot offer codes to non-promoters with your current subscription.`;
  const brandCanOfferRate = isSubscribedToFeature(user, 'CUSTOM_RATES') && (userIsPromoter || isSubscribedToFeature(user, 'UNLIMITED_CHAT'));
  const brandCannotOfferRatesMessage = !userIsPromoter && `You cannot offer custom rates to non-promoters with your current subscription.`;
  const brandCanGift = isSubscribedToFeature(user, 'GIFTING');
  const brandCanChat = isSubscribedToFeature(user, userIsPromoter ? 'CHAT_WITH_PROMOTERS' : 'UNLIMITED_CHAT');
  const brandCannotChatMessage = !userIsPromoter && `You cannot chat with non-promoters with your current subscription.`;
  const brandCanProposeCollaboration = isSubscribedToFeature(user, 'COLLABORATE');

  /************************************************************************************************
   ************************************ User Specific **********************************************
   ************************************************************************************************/
  const userHasProvenPromotionAbility = hasProvenPromotionAbility(user, activePotentialPartner.id);
  const outreachesRemaining = getOutreachesRemaining(user, chats);
  const userIsBannedFromChatting = isBannedFromChat(user) || isBanned(user);
  const hasOutreachesRemaining = outreachesRemaining > 0;
  const userInAllowedOutreachCountryCodes = isUserInAllowedOutreachCountryCodes(user, activePotentialPartner);

  const userCanOutreach =
    (userHasProvenPromotionAbility && hasOutreachesRemaining && !userIsBannedFromChatting && userInAllowedOutreachCountryCodes) ||
    window.__ADMIN_CONTROL_MODE__;

  let userCannotOutreachMessage = '';

  if (userIsBannedFromChatting) {
    userCannotOutreachMessage = `Your account is currently under review due to suspiscious activity. You cannot reach out to new brands or retailers at this time.`;
  } else if (userHasProvenPromotionAbility && !hasOutreachesRemaining) {
    userCannotOutreachMessage = `You have reached the maximum number of daily outreaches (3) for ${
      getUserTier(user).tier === 2 ? 'the Trendsetter' : 'your current'
    } tier. You can reach out to more brands and retailers tomorrow.`;
  } else if (!userHasProvenPromotionAbility) {
    userCannotOutreachMessage = `You must reach the Trendsetter tier before you can reach out to new brands or retailers. Go to your "Account Settings" for more detail on how tiers work.`;
  } else if (!userInAllowedOutreachCountryCodes) {
    userCannotOutreachMessage = `${name} does not currently support outreach in your country.`;
  }

  // Codes
  const userCanRequestDiscountCode = userCanOutreach && offersCodes;
  const userCannotRequestDiscountCodeWarning = offersCodes ? userCannotOutreachMessage : `${name} does not offer discount codes.`;

  // Gifting
  const userCanRequestGift = userCanOutreach && offersGifting;
  const userCannotRequestGiftWarning = offersGifting ? userCannotOutreachMessage : `${name} does not offer gifting.`;

  // Chat
  const userCanChat = userCanOutreach && offersChat;
  const userCannotRequestChatWarning = offersChat ? userCannotOutreachMessage : `${name} does not offer chat.`;

  // Collab
  const userCanProposeCollaboration = userCanOutreach && offersCollaborations;
  const userCannotProposeCollaborationWarning = offersCollaborations ? userCannotOutreachMessage : `${name} does not offer collaborations`;

  const clickProfileImage = () => {
    if (isBrand(user)) return;
    props.openBrandModal({ brand: activePotentialPartner });
  };

  return (
    <div className='chat-potential-partner-outer-container'>
      <div className={cn('shop-preview', { 'is-potential-brand': !isBrand(user) })}>
        <div className='shop-image-container'>
          <img
            onClick={clickProfileImage}
            className='shop-image'
            alt={name}
            src={image || logo ? getSmartImage(image || logo) : getDefaultImage(ui)}
          />
        </div>
        {isBrand(user) ? (
          <div className='metadata'>
            <a href={shopURL} target='_blank' rel='noopener noreferrer' className='name'>
              {activePotentialPartner.name}
            </a>
            <div className='description'>
              {activePotentialPartner.description || `Joined ${moment(activePotentialPartner.createdAt).format('MMMM YYYY')}`}
            </div>
            <div className={cn('social-links', { empty: !socialData.length })}>
              {socialData.map(({ icon, type, url }, idx) => (
                <a href={url} target='_blank' rel='noopener noreferrer' key={idx} className='social-link'>
                  {icon ? <img alt={type} src={icon} /> : <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>}
                </a>
              ))}
            </div>
          </div>
        ) : (
          <div className='metadata'>
            <div className='name'>{name}</div>
            <a href={`https://${domain}`} target='_blank' rel='noopener noreferrer' className='domain'>
              {domain}
            </a>
          </div>
        )}
        {isBrand(user) ? (
          <div className='actions'>
            <div
              onClick={brandCanChat ? props.requestPartnership : () => window.ALERT.warn(brandCannotChatMessage)}
              className={cn('action', { disabled: !brandCanChat })}
            >
              Begin Chat{userIsPromoter ? ` With Promoter` : ''}
            </div>
            <div onClick={props.requestGiftingToUser} className={cn('action', { disabled: !brandCanGift })}>
              Offer Gifting
            </div>
            <div
              onClick={() => (brandCanOfferCodes ? props.offerCodeToUser() : window.ALERT.warn(brandCannotOfferCodesMessage))}
              className={cn('action', { disabled: !brandCanOfferCodes })}
            >
              <div className='action-item'>Offer Discount Code</div>
            </div>
            {isSMSAffiliatePartner && (
              <div
                onClick={() => (brandCanOfferRate ? props.setCustomRate() : window.ALERT.warn(brandCannotOfferRatesMessage))}
                className={cn('action', { disabled: !brandCanOfferRate })}
              >
                <div className='action-item'>Offer Custom Rate</div>
              </div>
            )}
            <div
              onClick={() => (brandCanProposeCollaboration ? props.createCollaborationWithUser(activePotentialPartner.id) : null)}
              className={cn('action', { disabled: !brandCanProposeCollaboration })}
            >
              Propose Collaboration
            </div>
          </div>
        ) : (
          <div className='actions'>
            {userCanRequestGift ? (
              <div onClick={() => props.requestPartnership('gifting')} className='action'>
                Request Gifting
              </div>
            ) : (
              <Tooltip message={userCannotRequestGiftWarning}>
                <div className='action disabled'>Request Gifting</div>
              </Tooltip>
            )}
            {userCanRequestDiscountCode ? (
              <div onClick={() => props.requestPartnership('code')} className='action'>
                Request Discount Code
              </div>
            ) : (
              <Tooltip message={userCannotRequestDiscountCodeWarning}>
                <div className='action disabled'>Request Discount Code</div>
              </Tooltip>
            )}
            {userCanProposeCollaboration ? (
              <div onClick={() => props.createCollaborationWithBrand(activePotentialPartner.id)} className='action'>
                Propose Collaboration
              </div>
            ) : (
              <Tooltip message={userCannotProposeCollaborationWarning}>
                <div className='action disabled'>Propose Collaboration</div>
              </Tooltip>
            )}
            {userCanChat ? (
              <div onClick={() => props.requestPartnership()} className='action'>
                Begin Chat
              </div>
            ) : (
              <Tooltip message={userCannotRequestChatWarning}>
                <div className='action disabled'>Begin Chat</div>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ChatPotentialPartner.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  chats: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  activePotentialPartner: PropTypes.object.isRequired,
  openBrandModal: PropTypes.func.isRequired,
  requestPartnership: PropTypes.func.isRequired,
  createCollaborationWithUser: PropTypes.func.isRequired,
  createCollaborationWithBrand: PropTypes.func.isRequired,
  requestGiftingToUser: PropTypes.func.isRequired
};

export default ChatPotentialPartner;
