import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash'
// import cn from 'classnames'
import './LatestSection_Template.scss';

const LatestSection_Template = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section templates'>
      {items.map(item => (
        <div key={item.id}>{JSON.stringify(item).slice(0, 140)}</div>
      ))}
    </div>
  );
};

LatestSection_Template.propTypes = {
  section: PropTypes.object.isRequired
};

export default LatestSection_Template;
