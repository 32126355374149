import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import instagramIcon from '../../static/images/social_icons/instagram_light.png';
import youtubeIcon from '../../static/images/social_icons/youtube_light.png';
import tiktokIcon from '../../static/images/social_icons/tiktok_light.png';

import './LandingExamplesPanel.scss';

class LandingExamplesPanel extends Component {
  render() {
    return (
      <div className='landing-examples-panel-outer-container'>
        <div className='landing-examples-panel-container'>
          <div className='landing-examples-header-container'>
            <h3>
              Check out some our favorite <br />
              recently added shops
            </h3>
            <div className='accent' />
          </div>
          <div className='examples-container'>
            {ARTISTS.map((data, idx) => (
              <div key={idx} className='example-container'>
                <Link onClick={() => setTimeout(() => window.scrollTo(0, 0), 0)} to={`/${data.username}`} className='link-wrapper'>
                  <img className='artist-img' alt={data.name} src={data.image} />
                  <div className='data-container'>
                    <div className='name-container'>
                      <div className='name'>{data.name}</div>
                      <div className='seperator'>|</div>
                      <div className='tagline'>{data.tagline}</div>
                    </div>
                    {/* <div className='joined'>Joined {data.joined}</div> */}
                  </div>
                </Link>
                <div className='social-links'>
                  {data.social.map((link, idx) => (
                    <a href={link.url} target='_blank' rel='noopener noreferrer' key={idx} className='social-link'>
                      <img alt='Social Icon' src={link.icon} />
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default LandingExamplesPanel;

const ARTISTS = [
  {
    image: 'https://static.shopmy.us/uploads/575e23f0-09a2-4fd4-9501-15cd55fb96d4_p21.png',
    name: 'Ava Lee',
    username: 'glowwithava',
    tagline: 'Skincare',
    joined: 'December 2020',
    social: [
      {
        icon: instagramIcon,
        url: 'https://www.instagram.com/glowwithava/'
      },
      {
        icon: youtubeIcon,
        url: 'https://www.youtube.com/glowwithava'
      },
      {
        icon: tiktokIcon,
        url: 'https://www.tiktok.com/@glowwithava'
      }
    ]
  },
  {
    image: 'https://static.shopmy.us/uploads/img-user-deres-1232-1635797229640',
    name: 'Allie Glines',
    username: 'allieglines',
    tagline: 'Makeup',
    joined: 'April 2021',
    social: [
      {
        icon: instagramIcon,
        url: 'https://www.instagram.com/allieglines/'
      },
      {
        icon: youtubeIcon,
        url: 'https://www.youtube.com/channel/UCLF42C7y73FKA8ye_5Nn-Kw'
      }
    ]
  },
  {
    image: 'https://static.shopmy.us/uploads/056350ce-4900-4b7f-8daf-afea7353d099_Untitleddesign7.png',
    name: 'Ivelisse Vasquez',
    username: 'lafashionistarealista',
    tagline: 'Skincare',
    joined: 'April 2021',
    social: [
      {
        icon: instagramIcon,
        url: 'https://www.instagram.com/lafashionistarealista/'
      },
      {
        icon: youtubeIcon,
        url: 'https://www.youtube.com/channel/UCwwrFsf-HRKPHXq7KjXwPZA'
      }
    ]
  }
];
