import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import moment from 'moment';
import './EarningsPortalMonthlyBreakdown.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';

import { getPrettyDate } from '../../Helpers/formatting';

const EarningsPortalMonthlyBreakdown = props => {
  const { user, payoutsAreLoading } = props;
  const payoutSummaries = _.get(user, 'payouts.payout_summaries.months', {});

  const [monthsVisible, setMonthsVisible] = useState(5);

  const monthlyTotals = useMemo(() => {
    // Transform the payout summaries into an array of monthly total objects
    const transformedTotals = Object.entries(payoutSummaries).map(([date, payoutSummary]) => {
      const { user_payout_total, user_payout_total_locked, user_payout_total_paid } = payoutSummary;
      const lockedPayoutsAreIncomplete = user_payout_total !== user_payout_total_locked && user_payout_total_locked > 0;

      return {
        date,
        amount: user_payout_total,
        lockedAmount: user_payout_total_locked,
        lockedAndNotPaidAmount: user_payout_total_locked - user_payout_total_paid,
        paidAmount: user_payout_total_paid,
        lockedTooltip: lockedPayoutsAreIncomplete
          ? 'Some of this months payouts have not yet locked. Commissions can take up to 105 days to pass the retailers return window and consult payouts must pass a seven day refund window.'
          : null
      };
    });

    const sortedTotals = _.orderBy(transformedTotals, ({ date }) => new Date(date), 'desc');
    return sortedTotals;
  }, [payoutSummaries]);
  const hasMoreMonths = monthlyTotals.length > monthsVisible;
  const gridTemplateColumns = '1fr 1fr 1fr 1fr';

  return (
    <div className='earnings-portal-monthly-breakdown-outer'>
      <div className='earnings-portal-monthly-breakdown-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Monthly Breakdown</div>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='earnings-portal-table-cell'>Date</div>
            <div className='earnings-portal-table-cell'>Total Amount</div>
            <div className='earnings-portal-table-cell'>Amount Locked</div>
            <div className='earnings-portal-table-cell'>Amount Paid</div>
          </div>

          <div className='earnings-portal-table-body'>
            {payoutsAreLoading
              ? new Array(5).fill(0).map((_, index) => {
                  return (
                    <div key={index} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                      <div className='earnings-portal-table-cell skeleton-cell'></div>
                      <div className='earnings-portal-table-cell skeleton-cell'></div>
                      <div className='earnings-portal-table-cell skeleton-cell'></div>
                      <div className='earnings-portal-table-cell skeleton-cell'></div>
                    </div>
                  );
                })
              : monthlyTotals.slice(0, monthsVisible).map(({ date, amount, lockedAmount, paidAmount, lockedTooltip }, idx) => {
                  return (
                    <div key={idx} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                      <div className='earnings-portal-table-cell'>{getPrettyDate(date, "MMMM 'YY", "MMMM 'YY")}</div>
                      <div className='earnings-portal-table-cell'>{amount ? `$${commaNumber(amount.toFixed(2))}` : '—'}</div>
                      <div className='earnings-portal-table-cell'>
                        {lockedAmount ? `$${commaNumber(lockedAmount.toFixed(2))}` : '—'}
                        {lockedTooltip && (
                          <Tooltip message={lockedTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
                        )}
                      </div>
                      <div className='earnings-portal-table-cell'>{paidAmount ? `$${commaNumber(paidAmount.toFixed(2))}` : '—'}</div>
                    </div>
                  );
                })}

            {monthlyTotals.length > 5 && !payoutsAreLoading && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns: '1fr' }}>
                <div className='show-more' onClick={() => (hasMoreMonths ? setMonthsVisible(monthsVisible + 5) : setMonthsVisible(5))}>
                  {hasMoreMonths ? 'Show More' : 'Show Less'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalMonthlyBreakdown.propTypes = {
  user: PropTypes.object.isRequired,
  payoutsAreLoading: PropTypes.bool.isRequired
};

export default EarningsPortalMonthlyBreakdown;
