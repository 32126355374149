import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './ArtistModalTalentReportAudienceStats.scss';

import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../../Helpers/subscription_helpers';
import { getCountryDisplayFromCode } from '../../../Helpers/geo_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';

const ArtistModalTalentReportAudienceStats = props => {
  const { stats, ui } = props;
  const geos = JSON.parse(stats?.audienceGeos || {});
  const device_brands = JSON.parse(stats?.audienceDevices || {})?.device_brands || null;
  const device_types = JSON.parse(stats?.audienceDevices || {})?.device_types || null;
  const os_names = JSON.parse(stats?.audienceDevices || {})?.os_names || null;

  const sections = [
    {
      display: 'Audience Geography',
      data: geos,
      getKeyDisplay: display => getCountryDisplayFromCode(display),
      isVisible: true
    },
    {
      display: 'Audience Device Brands',
      data: device_brands,
      isVisible: false // Just for testing
    },
    {
      display: 'Device Types',
      data: device_types,
      isVisible: false // Just for testing
    },
    {
      display: 'OS Names',
      data: os_names,
      isVisible: false // Just for testing
    }
  ];

  const canView = isSubscribedToFeature(props.user, 'ADVANCED_TALENT_ANALYTICS') || isAdminControlMode(ui);
  const blockIfNeeded = () => blockOnRequiringSubscription(props.user, 'ADVANCED_TALENT_ANALYTICS');

  if (_.isEmpty(geos)) return null;
  return (
    <>
      {sections.map(({ display, data, getKeyDisplay, isVisible }) => {
        const data_sorted = _.orderBy(_.keys(data), key => data[key], 'desc');
        const total = _.sum(_.values(data));
        const topSections = data_sorted.slice(0, 4);
        const otherCount = _.sum(data_sorted.slice(4).map(key => data[key]));
        const sections = otherCount > 0 ? topSections.concat('Other') : topSections;
        if (!isVisible) return null;
        return (
          <div onClick={blockIfNeeded} key={display} className='report-section'>
            <div className='section-header-section'>
              <div className='section-header'>{display}</div>
            </div>
            <div className='section-body talent-report-audience-stats'>
              {sections.map(key => {
                const isOther = key === 'Other';
                const display = _.isFunction(getKeyDisplay) && !isOther ? getKeyDisplay(key) : key;
                const count = isOther ? otherCount : data[key];
                const percentage = (100 * count) / total;
                return (
                  <div key={key} className={cn('stats-row', { blocked: !canView })}>
                    <div className='bar-container'>
                      <div className='bar'>
                        <div className='active-bar' style={{ width: `${percentage}%` }} />
                      </div>
                      <div className='percentage'>{percentage.toFixed(0)}%</div>
                    </div>
                    <div className='display'>{canView ? display : 'Blocked'}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

ArtistModalTalentReportAudienceStats.propTypes = {
  ui: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired
};

export default ArtistModalTalentReportAudienceStats;
