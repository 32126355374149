import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';

import './BrandPartner.scss';

const BrandPartner = props => {
  const { brand } = props;
  const { name, domain, description, tags } = brand;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isCreatingContract, setIsCreatingContract] = useState(false);

  /******************************************************************************
   ************************** Collaborations (User) *****************************
   ******************************************************************************/
  const createCollaboration = () => {
    if (isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    setIsCreatingContract(true);
    props.createContractWithBrand(brand.id).then(resp => {
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      } else {
        setIsCreatingContract(false);
      }
    });
  };

  return (
    <div className='brand-partner-outer-container'>
      <div className='brand-partner-inner-container'>
        <div className='image-container'>
          <img className='logo' src={brand.logo} alt={brand.name} />
        </div>
        <div className='main-container'>
          <div>
            <div className='tags'>
              {tags.map(tag => {
                if (tag.type === 'brandpromoter') return null;
                return (
                  <div key={tag.id} className='tag'>
                    {tag.value}
                  </div>
                );
              })}
            </div>
            <div className='title'>{name}</div>
            <div className='domain'>
              <a href={`https://${domain}`} target='_blank' rel='noopener noreferrer'>
                {domain}
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </div>
            <div className={cn('description', { all: showFullDescription })}>{description}</div>
            {description && (
              <div onClick={() => setShowFullDescription(!showFullDescription)} className='read-more'>
                {showFullDescription ? 'Show Less' : 'Show More'}
              </div>
            )}
          </div>
          <div className='actions'>
            <div onClick={createCollaboration} className='action'>
              Propose Collaboration
            </div>
            <Link to={`/Chat?query=${brand.name}`} className='action basic'>
              Chat
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

BrandPartner.propTypes = {
  brand: PropTypes.object.isRequired,
  createContractWithBrand: PropTypes.func.isRequired
};

export default BrandPartner;
