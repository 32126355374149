import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CheckboxButton.scss';

const CheckboxButton = props => {
  const { label, description, isChecked, onChange } = props;

  return (
    <div className={cn('checkbox-button-container', { selected: isChecked })} onClick={onChange}>
      <div className='check'>{isChecked && <FontAwesomeIcon icon={faCheck} />}</div>
      <div className='check-text'>
        <div className='check-label'>{label}</div>
        {description && <div className='check-description'>{description}</div>}
      </div>
    </div>
  );
};

CheckboxButton.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CheckboxButton;
