import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import './ContractLinksCard.scss';

import ContractSearchLinks from './ContractSearchLinks';
import ContractLink from './ContractLink';

import { getRecentContractLinksForBrand } from '../../../APIClient/collaborations';

const ContractLinksCard = props => {
  const { contract, canEdit } = props;
  const { links, id, brand, isCreatedByBrand } = contract;
  const Brand_id = brand.id;
  const inCatalog = !!brand.scraper;
  const [pastLinks, setPastLinks] = useState([]);
  const [showingPastLinks, setShowingPastLinks] = useState(false);
  const [numVisiblePastLinks, setNumVisiblePastLinks] = useState(2);
  const showMorePastLinks = () => setNumVisiblePastLinks(numVisiblePastLinks + 5);
  const showLessPastLinks = () => setNumVisiblePastLinks(_.max([numVisiblePastLinks - 5, 2]));

  useEffect(() => {
    canEdit &&
      isCreatedByBrand &&
      getRecentContractLinksForBrand(Brand_id).then(resp => {
        resp.links && setPastLinks(_.orderBy(resp.links, 'createdAt', 'desc'));
      });
  }, [setPastLinks, Brand_id, id, canEdit, isCreatedByBrand]);

  const filteredPastLinks = pastLinks.filter(link => {
    const isSameContract = link.Contract_id === contract.id;
    const alreadyHasLink = !!links.find(l => (link.url && l.url === link.url) || (l.Product_id && l.Product_id === link.Product_id));
    return !isSameContract && !alreadyHasLink;
  });

  if (!canEdit && !links.length) return null;
  return (
    <>
      <div className='contract-links-card card'>
        <div className='card-header-container'>
          <div className='card-header'>{inCatalog ? 'Product & Links' : 'Tracking Links'}</div>
          {canEdit && (
            <div className='card-subheader'>
              These urls will autogenerate tracking links for {contract.user.name} to track performance. Add links to any specific products or to your
              home page if it is a generalized campaign.
              {!inCatalog && ' If you would like your products to be included in the catalog, please reach out to us directly.'}
            </div>
          )}
        </div>
        <div className='card-body-container'>
          <div className='contract-links'>
            {links.map(link => (
              <ContractLink
                key={link.id}
                link={link}
                canEdit={canEdit}
                deleteContractLink={props.deleteContractLink}
                updateContractLink={props.updateContractLink}
                contract={contract}
              />
            ))}
          </div>
          {canEdit && (
            <div className='contract-links-search-container'>
              <ContractSearchLinks contract={contract} createContractLink={props.createContractLink} />
            </div>
          )}
          {!!filteredPastLinks.length && (
            <>
              <div onClick={() => setShowingPastLinks(!showingPastLinks)} className='past-container-header'>
                <div className='header'>Select from Previous Contracts</div>
                {<FontAwesomeIcon icon={showingPastLinks ? faChevronUp : faChevronDown} />}
              </div>
              {showingPastLinks && (
                <>
                  <div className='contract-past-links'>
                    {filteredPastLinks.slice(0, numVisiblePastLinks).map(link => (
                      <ContractLink
                        key={link.id}
                        link={link}
                        canEdit={false}
                        isTemplate
                        deleteContractLink={props.deleteContractLink}
                        updateContractLink={props.updateContractLink}
                        createContractLink={props.createContractLink}
                        contract={contract}
                      />
                    ))}
                  </div>
                  {numVisiblePastLinks < filteredPastLinks.length && (
                    <div onClick={showMorePastLinks} className='show-more'>
                      Show More
                    </div>
                  )}
                  {numVisiblePastLinks > 5 && (
                    <div onClick={showLessPastLinks} className='show-less'>
                      Show Less
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

ContractLinksCard.propTypes = {
  contract: PropTypes.object.isRequired,

  // For editing
  canEdit: PropTypes.bool.isRequired,
  createContractLink: PropTypes.func,
  deleteContractLink: PropTypes.func,
  updateContractLink: PropTypes.func
};

export default ContractLinksCard;
