import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faLink, faTag } from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import Loader from '../../Loader/Loader';
import { getSmartImage, matchScrollHeight, dismissEvent } from '../../../Helpers/helpers';
import { getAffiliateLink } from '../../../Helpers/attribution_helpers';
import { getPrettyPriceDisplay } from '../../../Helpers/formatting';

const CollectionProductLongform = props => {
  const {
    user,
    index,
    collection,
    pin,
    code,
    copyCode,
    lastLocation,
    canEdit,
    DragHandle,
    navigateToProduct,
    updateLinkRefAttributes,
    updatePinAttributes,
    showingActionsPanel,
    copyLink,
    toggleEditMode
  } = props;
  const { pins } = collection;
  const { image, isHidden } = pin;

  const [description, setDescription] = useState(pin?.description || '');
  const [title, setTitle] = useState(pin?.title || '');

  const affiliateLink = getAffiliateLink(pin, user, collection.user, collection);

  const isLongGuide = pins.length > 10;
  return (
    <div className={cn('collection-product longform', { hidden: isHidden, 'long-guide': isLongGuide, odd: index % 2 })}>
      <a
        ref={ref => ref && updateLinkRefAttributes(ref)}
        href={affiliateLink}
        target='_blank'
        rel='nofollow noopener noreferrer'
        className={cn('collection-product-img-wrapper', {
          smooth: !canEdit && !!lastLocation
        })}
      >
        {!!isHidden && (
          <div className='hidden-cover'>
            <div className='badge'>HIDDEN</div>
          </div>
        )}
        <LazyLoad height={200} offset={window.__IS_SNAP__ ? 2000 : 480}>
          <img
            alt={title || 'Product'}
            onClick={navigateToProduct}
            className={cn('product-image', image ? '' : 'empty')}
            src={getSmartImage(image)}
          />
        </LazyLoad>
      </a>
      <div className='meta-container'>
        {canEdit && (
          <div className='action-btn-container'>
            {!showingActionsPanel && <FontAwesomeIcon onClick={copyLink} icon={faLink} />}
            {!showingActionsPanel && <FontAwesomeIcon onClick={toggleEditMode} icon={faPencilAlt} />}
            {props.getActionsPanel()}
          </div>
        )}
        {canEdit ? (
          <textarea
            rows={1}
            value={title}
            className='title-input-container'
            ref={ref => matchScrollHeight(ref)}
            placeholder='Product title'
            onChange={e => {
              setTitle(e.target.value);
              updatePinAttributes({
                title: e.target.value,
                description
              });
            }}
          />
        ) : (
          <h3 className='title'>{title}</h3>
        )}
        {canEdit ? (
          <textarea
            rows={1}
            value={description}
            className='description-input-container'
            ref={ref => matchScrollHeight(ref)}
            placeholder={`Add a description here`}
            onChange={e => {
              setDescription(e.target.value);
              updatePinAttributes({
                description: e.target.value,
                title
              });
            }}
          />
        ) : (
          description && <h4 className='description'>{description}</h4>
        )}
        {code && !!pin.allow_displaying_codes && (
          <div onClick={dismissEvent} className='code-container'>
            <FontAwesomeIcon onClick={copyCode} icon={faTag} />
            <div onClick={copyCode} className='code'>
              USE CODE <span className='display'>{code.displayText}</span> FOR{' '}
              {code.isPercentage ? `${code.rate}% OFF` : `${getPrettyPriceDisplay(code.rate)} OFF`}
            </div>
          </div>
        )}
        <a
          ref={ref => ref && updateLinkRefAttributes(ref)}
          className='link-to-product'
          href={affiliateLink}
          target='_blank'
          rel='nofollow noopener noreferrer'
          onClick={navigateToProduct}
        >
          SHOP NOW
          <FontAwesomeIcon icon={faChevronRight} />
        </a>
      </div>
      {(props.isDeleting || props.isBeingEdited || props.isDuplicating) && (
        <div className='adjusting-product-loader active'>
          <Loader size={100} />
        </div>
      )}
      {canEdit && <DragHandle />}
    </div>
  );
};

CollectionProductLongform.propTypes = {
  // Main Data
  user: PropTypes.object.isRequired,
  collection: PropTypes.object.isRequired,
  pin: PropTypes.object.isRequired,
  lastLocation: PropTypes.object,

  // Variables
  index: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  isBeingEdited: PropTypes.bool,
  showingActionsPanel: PropTypes.bool.isRequired,

  // Functions
  navigateToProduct: PropTypes.func.isRequired,
  updateLinkRefAttributes: PropTypes.func.isRequired,

  // If editing
  getActionsPanel: PropTypes.func,
  copyLink: PropTypes.func.isRequired,
  toggleEditMode: PropTypes.func.isRequired,
  DragHandle: PropTypes.func
};

export default CollectionProductLongform;
