import React from 'react';
import MetaTags from 'react-meta-tags';
import './FAQ.scss';
import './Basic.scss';

import ScrollToTop from '../../Components/General/ScrollToTop';

const FAQ = props => {
  const Title = 'ShopMy Professional';

  const faqs = [
    {
      question: `How do I earn money using ${Title}?`,
      answer: `Our platform integrates directly with retailer and brand affiliate programs, so when you add products to your lists we automatically convert them into affiliate links. Commissions for the products typically range from 10 - 30% depending on the retailer. Once you share a list, we track commissions from all the retailers and pay you with one weekly payout via PayPal or Stripe.`
    },
    {
      question: `How do payouts work?`,
      answer: `Payments are administered after the retailer return window closes, which can take up to 105 days or more. Unfortunately this is just how affiliate networks work — they have to offer the brand or retailer the ability to adjust for any cancelled or returned orders. After the return window passes, the commissions are converted from “Pending” to “Locked” Earnings to be paid out the following week (payouts occur every Friday afternoon). As a note, you just need $10 in confirmed earnings in order to be paid out.`
    },
    {
      question: `How long is the cookie window for each click to a store?`,
      answer: `Between 7 and 30 days, depending on the retailer. In order for a purchase to be attributed to your shop, your link needs to be the last affiliate link a shopper clicks prior to making a purchase. If you have a question about a specific retailer, don’t hesitate to ask a member of our team! `
    },
    {
      question: `How do I change my list to a shoppable post?`,
      answer: `In order to add your social posts to your lists, you just need to click "ADD SOCIAL LINK" on any given list and copy and paste the link to your post.`
    },
    {
      question: 'What is a consult?',
      answer: `${Title} now offers an exciting new feature whereby ${Title} members can offer custom consults for members of their audience! You can create a variety of different types of offerings to live on your shop — from pre-made templates like nighttime skincare routines, personal fragrance roundups, and clean beauty overhauls, or you can create a consult from scratch. You’ll also have the opportunity to set the price of your consults, so you can control how much you earn.\n\nYour audience will be able to request consults and pay for this service from right within your shop (your list of available consults will be available next to the “SHOP LISTS” category. After they fill out a brief form about themselves and their needs, you will be notified over email so you can start on their custom list of products. As is the case with your typical lists, you’ll be able to earn affiliate revenue — on top of the flat consult fee that you set — and track that progress within the “Links” tab.`
    },
    {
      question: 'How do consult payouts work?',
      answer: `As is the case with your regular shop, this service operates on a revenue share model. In this case, it’s 80/20, meaning that you receive 80% of the consult fee, and ${Title} would receive 20%. The affiliate revenue earned will follow the typical model.`
    },
    {
      question: 'How long do I have to answer a consult request?',
      answer: `Upon receiving a consult request, you have fourteen days to respond. If fourteen days pass and no action is taken, we will refund the requestor. We make it super easy to stay on top of these requests, as we email you as they come in, as well as notify you via badge icon when you’re logged in to your shop.`
    },
    {
      question: 'When will I receive the earnings from a consult?',
      answer: `We offer a 7 day window after the consult is submitted for them to ask for a refund, which we will offer on a case-by-case basis. Then the consult locks, and you will be paid in your standard weekly payment on Friday afternoon. That means you’ll receive all ${Title} payments in one convenient lump sum!`
    },
    {
      question: `I’m expecting a purchase from one of my followers, but it doesn’t appear to be showing up in My Earnings. Why is that?`,
      answer: `It often takes at least 48 hours for most purchases to populate in our system, but it can depend on the retailer. If there is a specific purchase you’re looking for, pass along the name of the product, the retailer, and the link through which the item was purchased to us and we can keep an eye out for the purchase internally and be on the lookout for any issues.`
    },
    {
      question: `I noticed the commission rate for a certain retailer went up/down. Why is that?`,
      answer: `A lot of retailers change their rates regularly. To counteract this, our system has partnerships with many affiliate networks so we always get you the best rate available. In addition, we are always negotiating with brands and retailers on your behalf to get and maintain the best, most stable rates possible. `
    },
    {
      question: `Someone made a purchase using a link on my shop, but did not purchase the actual product that they clicked on. Will I still receive credit for this purchase?`,
      answer: `Yes! As long as the purchase was made through your link, and your link was the last affiliate link the shopper clicked on before checking out, you’ll receive commission. In fact, you earn commission on the entire purchase, not only the item that was clicked.`
    },
    {
      question: `What commission rates does ${Title} offer? How can I see which retailers offer the highest commission rates?`,
      answer: `The commission rates vary by retailer, so we would recommend taking a look in our catalog to check out the rates. You'll notice the “Find the highest commission rates” option in the catalog — that will give you the opportunity to browse rates easily by searching for the retailers that interest you. In terms of how competitive our rates are, we are able to negotiate on behalf of the entire platform so brands and retailers are eager to give us rates that ensure you direct your traffic to their sites. This means we get rates higher than one individual could get on their own and usually the highest you will find on any platform.`
    },
    {
      question: `How do I know which retailers are the best to choose to link to in my shop?`,
      answer: `The retailers you choose to link to from our catalog are entirely up to you. Whether it's retailers you frequent, retailers you know your audience shops at, or if you just want to choose whatever is highest for each product that's fine too. Totally up to you! If you’d like any recommendations based on your content, feel free to ask a member of our team for their point of view.`
    },
    {
      question: `What is the best way to drive traffic to my shop?`,
      answer: `The best way to drive traffic is to leverage it along with content that you would usually share on your social channels. Things along the lines of "get the look" content, tutorials, curated favorites lists, and things like that are always a great type of content to align with. Also, giving your audience a place to go is huge. Including a call to action in your content or content caption telling your audience to head to the link in your bio (or your Linktree, however you’d like to integrate your ${Title} into your social) to check out your shop or a specific list is a great way to streamline their consumer journey.`
    },
    {
      question: `A retailer I love isn’t in the catalog. What do I do?`,
      answer: `If you have any suggestions of retailers to add to the catalog, feel free to pass them along to our team. We are always looking for ways to improve and build our catalog, and as users of the tool, your suggestions are important to us! If you’re not seeing a favorite retailer in the catalog, we also recommend trying to add a link from there using a URL to see if our platform can detect if they have their own affiliate program. If they do, the tool will let you know. Just because a retailer is not in our platform, does not mean they don’t have a program of their own! It’s always worth a shot to see what may populate :)`
    },
    {
      question: `How does ${Title} make money?`,
      answer: `We operate on a 82/18 revenue share model. In simple terms, that means if you made $100 from commissions in a week, you'd keep $82, we'd keep $18. The rates you see on the platform already factor that in, so the rate you see is the rate you earn. Also, the earnings you see in your “My Earnings” reflect what you receive after the revenue share.`
    },
    {
      question: `Am I able to use my own affiliate links if I have a better rate for a specific retailer, or if I have a rate with a retailer you don’t have in the catalog?`,
      answer: `Absolutely. Just make sure if you’re adding your own links by URL and are turning link monetization OFF, since you’ll be earning through your own separate program.`
    },
    {
      question: `Is Amazon part of ${Title}'s affiliate network?`,
      answer: `Amazon prefers to work with influencers/creators directly, so if you're not a part of their program already, you can sign up for the Amazon Associates Program for free. That way, you can include those links in your shop. Again, just make sure if you’re adding your own links by URL, you are turning link monetization OFF, since you’ll be earning through your own separate program.`
    },
    {
      question: `Does ${Title} have a mobile app?`,
      answer: `At this time, we don't have a mobile app available for download, but not to worry — our site's interface is totally mobile-compatible!`
    }
  ];
  return (
    <div className='faq-outer-container basic-outer-container'>
      <MetaTags>
        <title>FAQ | {Title}</title>
        <meta property='description' content='Some answers to our users most frequently asked questions' />
      </MetaTags>
      <ScrollToTop />
      <div className='basic-header-label'>Frequently Asked Questions</div>
      <div className='about-body-container basic-body-container'>
        <div className='basic-body-section narrow'>
          {faqs.map(({ question, answer }, idx) => {
            return (
              <div key={idx} className='basic-body-desc'>
                <div className='basic-body-desc-header'>{question}</div>
                <div className='basic-body-desc-body'>{answer}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
