import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './BulkTalentModalFooter.scss';

import SelectOption from '../General/SelectOption';
import UploadImage from '../General/UploadImage';

import { getSmartImage } from '../../Helpers/helpers';

const BulkTalentModalFooter = props => {
  const { curMessage, curRate, validSelectedTalent, outreachOptions, outreachType, filesToUpload, setFilesToUpload } = props;
  const selectOutreachType = data => {
    data.onSelectOverride ? data.onSelectOverride() : props.setOutreachTypeEnum(data.value.type);
  };
  const noneRemaining = outreachType.numRemaining === 0;
  const hasMessage = curMessage.length >= 2 || filesToUpload.length > 0;
  const hasValidTalent = validSelectedTalent.length > 0;
  const hasRate = curRate.length > 0;
  const needsRateAndDoesntHave = outreachType.showRate && !hasRate;
  const isDisabled = hasRate ? !hasValidTalent || needsRateAndDoesntHave : !hasMessage || !hasValidTalent || noneRemaining || outreachType.isDisabled;

  const note = outreachType.disabledMessage || outreachType.warningMessage;

  /******************************************************************************
   ***************************** File Uploading *********************************
   ******************************************************************************/
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const handlePreprocessFileDrop = (file, next) => {
    setIsUploading(true);
    next(file);
  };
  const onUploadFileProgress = progress => {
    setUploadProgress(progress);
    progress < 100 && setIsUploading(true); // In case of multi-upload
  };
  const completeFileUpload = async newImage => {
    setFilesToUpload([...filesToUpload, getSmartImage(newImage)]);
    setIsUploading(false);
    setUploadProgress(0);
  };
  React.useEffect(() => {
    setFilesToUpload([]);
  }, []);

  return (
    <div className='bulk-talent-modal-footer'>
      <div className='attachments'>
        <div className='add-new'>
          <UploadImage
            basicContent={
              isUploading ? (
                <div className='upload-progress'>{uploadProgress.toFixed(0)}%</div>
              ) : (
                <div className='upload-icn'>
                  <FontAwesomeIcon icon={faPaperclip} />
                </div>
              )
            }
            basicUploader
            onUploadProgress={onUploadFileProgress}
            completeUpload={completeFileUpload}
            handlePreprocess={handlePreprocessFileDrop}
          />
        </div>
      </div>
      <div className='actions'>
        <Select
          unstyled
          placeholder='Outreach Type'
          classNamePrefix='outreach-select'
          menuPlacement='top'
          onChange={selectOutreachType}
          className='outreach-select'
          isOptionDisabled={option => !!option.value.disabledMessage}
          options={outreachOptions}
          value={outreachOptions.find(o => o.value.type === outreachType.type)}
          components={{ Option: SelectOption }}
        />
        <div onClick={() => outreachType.clickSend(validSelectedTalent)} className={cn('send-btn', { disabled: isDisabled })}>
          {outreachType.actionLabel(validSelectedTalent.length)}
        </div>
      </div>
      {note && (
        <div className='num-remaining-container'>
          <div className={cn('num-remaining', { warn: false })}>{note}</div>
        </div>
      )}
    </div>
  );
};

BulkTalentModalFooter.propTypes = {
  curMessage: PropTypes.string.isRequired,
  curRate: PropTypes.string.isRequired,
  validSelectedTalent: PropTypes.array.isRequired,
  outreachOptions: PropTypes.array.isRequired,
  outreachType: PropTypes.object.isRequired,
  setOutreachTypeEnum: PropTypes.func.isRequired,
  filesToUpload: PropTypes.array.isRequired,
  setFilesToUpload: PropTypes.func.isRequired
};

export default BulkTalentModalFooter;
