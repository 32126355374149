export const getDiscoverGroups = discover => discover?.groups || [];
export const getActiveDiscoverGroup = discover => discover?.activeGroup || null;

export const getDiscoverResultsLimit = () => 15;

export const isDiscoverListCurated = list => {
  if (list.recommendationListType) return false;
  if (list.LookalikeUser_id) return false;
  if (list.tagValues?.length) return false;
  return list.users.length > 0;
};

export const getSortFunction = sortBy =>
  /*
    This is used on the List page to sort users by a given metric without requiring a call
    to the server. Some of the sorting metrics are more advanced and we want to match it up with
    the server logic in db/userStats.js
  */
  ({
    score: { fn: user => user.score, defaultDirection: 'desc' },
    followers: { fn: user => user.instagramCount + user.tiktokCount + user.youtubeCount, defaultDirection: 'desc' },
    'followers-instagram': { fn: user => user.instagramCount, defaultDirection: 'desc' },
    'followers-tiktok': { fn: user => user.tiktokCount, defaultDirection: 'desc' },
    'followers-youtube': { fn: user => user.youtubeCount, defaultDirection: 'desc' },
    volume: { fn: user => user.peakMonthlyVolume, defaultDirection: 'desc' },
    traffic: { fn: user => user.monthlyPinClicks, defaultDirection: 'desc' },
    'gifting-acceptance': { fn: user => user.giftsAccepted / Math.max(user.codesReceived, 10), defaultDirection: 'desc' },
    'gifting-promotion': { fn: user => user.giftsPromoted / Math.max(user.giftsAccepted, 10), defaultDirection: 'desc' },
    'code-promotion': { fn: user => user.codesPromoted / Math.max(user.codesReceived, 10), defaultDirection: 'desc' }
  }[sortBy]);

export const getDiscoverSortOptions = () => [
  {
    label: 'General',
    options: [{ value: 'score', label: 'Creator Score', sublabel: 'Based on ability to drive sales, responsiveness and more.' }]
  },
  {
    label: 'Social Following',
    options: [
      { value: 'followers', label: 'Total Followers', sublabel: 'Total followers across all social platforms.' },
      { value: 'followers-instagram', label: 'Instagram Followers' },
      { value: 'followers-tiktok', label: 'Tiktok Followers' },
      { value: 'followers-youtube', label: 'Youtube Followers' }
    ]
  },
  {
    label: 'Performance',
    options: [
      {
        value: 'volume',
        label: 'Total Sales',
        sublabel: 'Total sales across the entire platform.',
        requiredFeature: 'ADVANCED_TALENT_ANALYTICS'
      },
      {
        value: 'traffic',
        label: 'Traffic Generated',
        sublabel: 'Total clicks driven to brand/retailer websites.',
        requiredFeature: 'ADVANCED_TALENT_ANALYTICS'
      }
    ]
  },
  {
    label: 'Gifting',
    options: [
      {
        value: 'gifting-acceptance',
        label: 'Gifting Acceptance',
        sublabel: 'Likelihood of accepting gifting.',
        requiredFeature: 'ADVANCED_TALENT_ANALYTICS'
      },
      {
        value: 'gifting-promotion',
        label: 'Gifting Promotion',
        sublabel: 'Likelihood of promoting after receiving gifting.',
        requiredFeature: 'ADVANCED_TALENT_ANALYTICS'
      },
      {
        value: 'gifting-volume',
        label: 'Gifting Volume',
        sublabel: 'Volume driven as a result of gifting.',
        requiredFeature: 'ADVANCED_TALENT_ANALYTICS'
      }
    ]
  },
  {
    label: 'Discount Codes',
    options: [
      { value: 'code-promotion', label: 'Code Promotion', sublabel: 'Percentage of codes promoted.', requiredFeature: 'ADVANCED_TALENT_ANALYTICS' },
      { value: 'code-volume', label: 'Code Volume', sublabel: 'Volume driven after receiving code.', requiredFeature: 'ADVANCED_TALENT_ANALYTICS' }
    ]
  }
];

export const recommendationListTypes = [
  {
    value: 'collaboration',
    display: 'Collaboration'
  },
  {
    value: 'gifting',
    display: 'Gifting'
  },
  {
    value: 'codes',
    display: 'Codes'
  },
  {
    value: 'rates',
    display: 'Rates'
  },
  {
    value: 'outreach',
    display: 'Outreach'
  },
  {
    value: 'general',
    display: 'General'
  }
];
