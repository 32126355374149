import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import _ from 'lodash';
import cn from 'classnames';
import './OpportunityFeed.scss';

import { updateMention } from '../../APIClient/mentions';

import { updateOpportunityExpectation } from '../../Actions/OpportunityActions';
import { openArtistModal, openChatOverlay, openAuthModal } from '../../Actions/UIActions';

import { isMentionAffiliateLinkOnly } from '../../Helpers/mention_helpers';

import MentionResult from '../Mentions/MentionResult';
import Loader from '../Loader/Loader';

const OpportunityFeed = props => {
  const { feed, opportunities, getNextFeedPage, isFetchingFirstFeedPage } = props;

  let columns = [[], [], []];
  const columnChunks = _.chunk(feed, 3);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0].push(chunk[0]);
    chunk[1] && columns[1].push(chunk[1]);
    chunk[2] && columns[2].push(chunk[2]);
  });

  const lastItemRefs = React.useRef(columns.map(() => React.createRef()));
  const observersRef = React.useRef([]);
  const immediateRefetchPause = React.useRef(false);

  React.useEffect(() => {
    // Clear previous observers
    observersRef.current.forEach(observer => observer.disconnect());

    observersRef.current = lastItemRefs.current.map((ref, i) => {
      const observer = new IntersectionObserver(entries => {
        const justFetched = immediateRefetchPause.current;
        if (entries[0].isIntersecting && !justFetched) {
          getNextFeedPage();
          immediateRefetchPause.current = true;
          setTimeout(() => (immediateRefetchPause.current = false), 1000);
        }
      });
      if (ref.current) observer.observe(ref.current);
      return observer;
    });

    return () => observersRef.current.forEach(observer => observer.disconnect());
  }, [lastItemRefs, getNextFeedPage]);

  const emptyResultContainer = (
    <div className='fetching-container feed-result-container empty'>
      <Loader size={96} />
    </div>
  );

  // Allow the user to remove a mention
  const allMentionExpectations = _.flatten(_.map(opportunities?.activeOpportunity?.results, 'expectations')).filter(e => e.Mention_id);
  const getExpectationForMention = mention => allMentionExpectations.find(exp => exp.Mention_id === mention.id);
  const removeMentionExpectation = mention => {
    const expectation = getExpectationForMention(mention);

    if (expectation) {
      const isAffiliateLinkOnly = isMentionAffiliateLinkOnly(mention);
      confirmAlert({
        title: 'Why are you removing this mention?',
        message: isAffiliateLinkOnly
          ? 'If you believe the mention is incorrectly collected or does not meet the guidelines of the opportunity, you can remove it.'
          : 'If you believe the mention does not meet the guidelines of the opportunity, you can remove it.',
        buttons: [
          {
            label: 'Not My Brand',
            onClick: () => {
              updateMention(mention, { isHidden: true });
              props.updateOpportunityExpectation(expectation, {
                hasBeenMarkedInvalid: true
              });
            }
          },
          {
            label: 'Does Not Meet Guidelines',
            onClick: () => {
              props.updateOpportunityExpectation(expectation, {
                hasBeenMarkedInvalid: true
              });
            }
          }
        ]
      });
    }
  };

  const hasNoResults = !props.isFetchingFeed && !feed.length;
  return (
    <div className='opportunity-feed-outer-container'>
      {hasNoResults && (
        <div className='feed-empty-container'>
          <div className='feed-empty-message'>When creators start to share on social, their posts will appear here.</div>
        </div>
      )}
      <div className='feed-columns'>
        {columns.map((column, columnIdx) => {
          return (
            <div key={columnIdx} className='feed-column'>
              {column.map((mention, idx) => {
                const expectation = getExpectationForMention(mention);
                const isFinalItem = column.length === idx + 1;
                const isRemoved = !expectation || !!expectation?.hasBeenMarkedInvalid;
                return isFetchingFirstFeedPage ? (
                  <div key={mention.id} className='fetching-container mention-result-container empty'>
                    <Loader size={96} />
                  </div>
                ) : (
                  <div key={mention.id} className={cn('mention-result-outer-container', { removed: isRemoved })}>
                    <MentionResult
                      key={mention.id}
                      user={props.user}
                      ui={props.ui}
                      analytics={props.analytics}
                      ref={isFinalItem ? lastItemRefs.current[columnIdx] : null}
                      mention={mention}
                      openAuthModal={props.openAuthModal}
                      openArtistModal={props.openArtistModal}
                      openChatOverlay={props.openChatOverlay}
                      curFilters={{}}
                      removeMentionIfAllowedTo={removeMentionExpectation}
                    />
                    {isRemoved && (
                      <div className='removed-overlay'>
                        <div className='removed-overlay-inner'>
                          <div className='removed-overlay-text'>Removed</div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {props.isFetchingFeed && (
                <>
                  {!isFetchingFirstFeedPage && (
                    <>
                      {emptyResultContainer}
                      {emptyResultContainer}
                    </>
                  )}
                  {emptyResultContainer}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunityFeed.propTypes = {
  // From Inside
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,

  updateOpportunityExpectation: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,

  // From Outside
  opportunity: PropTypes.object.isRequired,
  feed: PropTypes.array.isRequired,
  feedPage: PropTypes.number.isRequired,
  getNextFeedPage: PropTypes.func.isRequired,
  hasFetchedAllFeed: PropTypes.bool.isRequired,
  isFetchingFeed: PropTypes.bool.isRequired,
  isFetchingFirstFeedPage: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { user, ui, opportunities, analytics } = state;
  return { user, ui, opportunities, analytics };
};

export default connect(mapStateToProps, {
  updateOpportunityExpectation,
  openArtistModal,
  openChatOverlay,
  openAuthModal
})(OpportunityFeed);
