import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './LookbookReportControls.scss';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

const LookbookReportControls = props => {
  const { startDate, endDate, updateDates } = props;

  const [focusedInput, setFocusedInput] = useState(null);
  return (
    <div className='lookbook-report-controls-outer'>
      <div className='lookbook-report-controls-inner'>
        <div className='lookbook-report-date-range-container'>
          <DateRangePicker
            startDate={startDate}
            startDateId='lookbookReportStartDate'
            endDate={endDate}
            endDateId='lookbookReportEndDate'
            displayFormat={startDate && moment().year() !== startDate.year() ? "MMM Do 'YY" : 'MMM Do'}
            onDatesChange={({ startDate, endDate }) => updateDates(startDate, endDate)}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
            noBorder
            minimumNights={0}
            showClearDates
          />
        </div>
      </div>
    </div>
  );
};

LookbookReportControls.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  updateDates: PropTypes.func.isRequired
};

export default LookbookReportControls;
