import React from 'react';
import './ErrorBanner.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const ErrorBanner = props => {
  const { children } = props;

  return (
    <div className='error-banner'>
      <div className='icon-wrapper'>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
      <div className='banner-children-wrapper'>{children}</div>
    </div>
  );
};

ErrorBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBanner;
