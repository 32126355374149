import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import './OpenSpotlightOptionsOverlay.scss';

import { setShowSpotlightOptionsUi } from '../../Actions/UIActions';
import { isEmbedPage, isPublicPage } from '../../Helpers/helpers';

const OpenSpotlightOptionsOverlay = props => {
  const { setShowSpotlightOptionsUi } = props;

  // Hide on embedded pages
  if (isEmbedPage() || isPublicPage()) return null;

  const clickToOpen = () => setShowSpotlightOptionsUi(true);
  return (
    <>
      <div onClick={clickToOpen} className={cn('spotlight-options-toggle-btn')}>
        <div className='tooltip show-on-hover'>Help</div>
        <div target='_blank' rel='noopener noreferrer' className='spotlight-options-toggle-icon-container'>
          <FontAwesomeIcon className='icon' icon={faQuestion} />
        </div>
      </div>
    </>
  );
};

OpenSpotlightOptionsOverlay.propTypes = {
  setShowSpotlightOptionsUi: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  setShowSpotlightOptionsUi
})(OpenSpotlightOptionsOverlay);
