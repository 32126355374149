export const MAX_401_RETRY = 3;

// KEEP THIS UP TO DATE WITH apiUpload.js
export const IMAGE_ACCEPT_TYPES = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'].join(', ');
export const FORMAL_ACCEPT_TYPES = ['application/pdf'].join(', ');
export const ALL_ACCEPT_TYPES = [
  ...IMAGE_ACCEPT_TYPES.split(', '),
  ...FORMAL_ACCEPT_TYPES.split(', '),
  'video/mp4',
  'video/x-ms-wmv',
  'video/quicktime',
  'text/csv'
].join(', ');

export const US_STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  { name: 'Outside of US', abbreviation: 'NONUS' }
];

export const DEFAULT_PRO_LICENSE_TYPE = { value: 'Esthetician', label: 'Esthetician' };
export const PRO_LICENSE_TYPES = [
  { value: 'Esthetician', label: 'Esthetician' },
  { value: 'Cosmetologist', label: 'Cosmetologist' },
  { value: 'Dietitian', label: 'Dietitian' },
  { value: 'Injector', label: 'Injector' },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Nurse Practitioner', label: 'Nurse Practitioner' },
  { value: 'Nutritionist', label: 'Nutritionist' },
  { value: 'Physician', label: 'Physician' },
  { value: 'Physician Assistant', label: 'Physician Assistant' },
  { value: 'Other Licensed Medical Professional', label: 'Other Licensed Medical Professional' }
];
export const PRO_LICENSE_TYPES_NCEA = [
  { value: 'Esthetician', label: 'Esthetician' },
  { value: 'Cosmetologist', label: 'Cosmetologist' }
];

// KEEP THIS UP TO DATE WITH shopify_helpers.js
export const SHOPIFY_ERRORS = {
  OUT_OF_STOCK: 'Unable to reserve inventory'
};

export const ONBOARDING_MODAL_TABS = {
  INTRO: 'intro',
  SOCIAL: 'social',
  BIO: 'bio',
  TERMS_AND_PRIVACY: 'terms_and_privacy',
  TAGS: 'tags',
  TIERS: 'tiers',
  TASKS: 'tasks'
};

export const ONBOARDING_MODAL_TABS_ORDER = [
  ONBOARDING_MODAL_TABS.INTRO,
  ONBOARDING_MODAL_TABS.SOCIAL,
  ONBOARDING_MODAL_TABS.BIO,
  ONBOARDING_MODAL_TABS.TERMS_AND_PRIVACY,
  ONBOARDING_MODAL_TABS.TAGS,
  ONBOARDING_MODAL_TABS.TIERS,
  ONBOARDING_MODAL_TABS.TASKS
];
