import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMousePointer } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ContractUploadDrop.scss';

import UploadImage from '../../General/UploadImage';
import ConfirmPrompt from '../../General/ConfirmPrompt';

import { isBrand } from '../../../Helpers/user_helpers';

const ContractUploadDrop = props => {
  const { contract, user } = props;
  const [fileName, setFileName] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const uploadComplete = async url => {
    await props.createContractUpload({ url, title: fileName, uploadedByBrand: isBrand(user), uploadedByUser: !isBrand(user) }, contract);
    setIsUploading(false);
  };

  const uploadViaUrl = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Please paste a URL to your content'
          placeholder={'https://domain.com/content.mov'}
          onCancel={onClose}
          isSingleLine
          onSubmit={url => {
            props.createContractUpload(
              { url, title: _.last(url.split('/')), uploadedByBrand: isBrand(user), uploadedByUser: !isBrand(user) },
              contract
            );
          }}
        />
      )
    });
  };

  const handlePreprocess = (file, next) => {
    const tenMB = 1000 * 1000 * 20;
    if (file.size > tenMB) {
      return cogoToast.warn('Uploaded file is over 20 MB maximum.');
    }
    const nameParts = file.name.split('.');
    setFileName(nameParts.slice(0, nameParts.length - 1).join('.'));
    setIsUploading(true);
    next(file);
  };
  return (
    <div className='content-upload-drop-container'>
      <UploadImage
        basicUploader
        basicUploadClass='upload-drop-zone-outer'
        basicContent={
          <div className='upload-drop-zone'>
            <div className='explainer'>
              {isUploading ? (progress > 99 ? `Finalizing Upload...` : `Uploading ${fileName}...`) : `Click here or drop any file to upload`}
            </div>
            {isUploading && (
              <div className='progress-meter'>
                <div
                  className='progress-meter-complete'
                  style={{
                    width: `${progress}%`
                  }}
                />
              </div>
            )}
          </div>
        }
        handlePreprocess={handlePreprocess}
        onUploadProgress={(progress, status) => {
          setIsUploading(true);
          setProgress(progress);
        }}
        completeUpload={uploadComplete}
      />
      <div onClick={uploadViaUrl} className='upload-via-url-btn'>
        <div>Upload Via URL</div>
        {<FontAwesomeIcon icon={faMousePointer} />}
      </div>
    </div>
  );
};

ContractUploadDrop.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  createContractUpload: PropTypes.func.isRequired
};

export default ContractUploadDrop;
