import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './ArtistModalCollaborationTasks.scss';

import { getPrettyPriceDisplay, enhanceHTML } from '../../Helpers/formatting';
import { getDisplayForCollaborationType, getImageOptionsForCollaborationType } from '../../Helpers/collaboration_helpers';

const ArtistModalCollaborationTasks = props => {
  const { talent, details, createContractWithUser, createContractFromTask } = props;

  const [isCreatingContract, setIsCreatingContract] = useState(null);

  const groupedTasksMap = _.groupBy(details?.tasks, t => t.template?.type);
  const groupedTasks = _.orderBy(_.values(groupedTasksMap, tasks => tasks[0].sortOrderRank));

  const createCollaborationWithUser = () => {
    if (isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    setIsCreatingContract(true);
    createContractWithUser(talent.id).then(resp => {
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      } else {
        setIsCreatingContract(false);
      }
    });
  };

  const selectTask = task => {
    if (isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    setIsCreatingContract(true);
    createContractFromTask(task).then(resp => {
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      } else {
        setIsCreatingContract(false);
      }
    });
  };

  return (
    <div className='artist-modal-collaboration-task-groups'>
      {groupedTasks.map(tasks => {
        const templates = _.map(tasks, 'template');
        const type = templates[0]?.type;
        const coverImageUrl = _.find(tasks, t => t.coverImageUrl)?.coverImageUrl || getImageOptionsForCollaborationType(type)[0];
        const description = _.find(tasks, t => t.description)?.description;
        return (
          <div className='task-group' key={type}>
            <div className='cover'>
              <img src={coverImageUrl} alt={type} />
            </div>
            <div className='body'>
              <div className='task-type'>{getDisplayForCollaborationType(type)}</div>
              {description && <div className='task-description'>{enhanceHTML(description)}</div>}
              <div className='tasks'>
                {tasks.map(task => {
                  const { id, template } = task;
                  const { title, fee_type } = template;
                  const select = () => selectTask(task);
                  return (
                    <div onClick={select} key={id} className='task'>
                      <div className='title'>{title}</div>
                      {task.price && <div className='bullet'>•</div>}
                      {task.price && (
                        <div className='price'>
                          {getPrettyPriceDisplay(task.price)}
                          {fee_type === 'duration' ? ' per day' : ''}
                        </div>
                      )}
                      <div className='add'>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <div className='task-group'>
        <div className='cover'>
          <div className='custom'>s</div>
        </div>
        <div className='body'>
          <div className='task-type'>CUSTOM</div>
          <div className='tasks'>
            <div onClick={createCollaborationWithUser} className='task'>
              <div className='title'>Custom Collaboration</div>
              <div className='add'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ArtistModalCollaborationTasks.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  createContractWithUser: PropTypes.func.isRequired,
  createContractFromTask: PropTypes.func.isRequired
};

export default ArtistModalCollaborationTasks;
