import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import ReactS3Uploader from 'react-s3-uploader';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import { IMAGE_ACCEPT_TYPES, ALL_ACCEPT_TYPES, FORMAL_ACCEPT_TYPES } from '../../Helpers/constants';

import './UploadImage.scss';

const S3_URL = process.env.REACT_APP_S3_URL;

class UploadImage extends Component {
  static propTypes = {
    completeUpload: PropTypes.func.isRequired,
    currentImage: PropTypes.string,

    // File Acceptance Configuration
    imagesOnly: PropTypes.bool,
    formalOnly: PropTypes.bool,

    // UI Adjustments
    hiddenHeader: PropTypes.bool,

    // Basic Handling
    basicUploader: PropTypes.bool,
    basicTextExplainer: PropTypes.string,
    basicContent: PropTypes.object,

    // Post upload Handling
    postUploadMessageWhileCompleting: PropTypes.string,

    // Listeners for progress
    onUploadProgress: PropTypes.func
  };

  state = {
    isUploadingFile: false,
    uploadedProgress: null,
    uploadStatus: null,
    image: this.props.currentImage
  };

  handlePreprocess = (file, next) => {
    this.props.handlePreprocess ? this.props.handlePreprocess(file, next) : next(file);
  };

  handleFinishedUpload = async info => {
    await this.props.completeUpload(`${S3_URL}/${info.filename}`);
    this.setState({ isUploadingFile: false });
    this.uploadInput.value = ''; // Allow uploading the same file again
  };

  showDisabledMessage = () => {
    cogoToast.warn(this.props.disabledMessage);
  };

  render() {
    const {
      currentImage,
      imagesOnly,
      formalOnly,
      disabledMessage,
      basicUploader,
      basicContent,
      basicUploadClass,
      postUploadMessageWhileCompleting,
      basicTextExplainer,
      onUploadProgress,
      hiddenHeader
    } = this.props;
    const { isUploadingFile, uploadedProgress, uploadStatus } = this.state;
    if (basicUploader) {
      return (
        <div className={cn('upload-basic-container', basicUploadClass)} onClick={e => e.stopPropagation()}>
          <ReactS3Uploader
            preprocess={this.handlePreprocess}
            onFinish={this.handleFinishedUpload}
            accept={imagesOnly ? IMAGE_ACCEPT_TYPES : formalOnly ? FORMAL_ACCEPT_TYPES : ALL_ACCEPT_TYPES}
            onProgress={(progress, status) => {
              this.setState({
                isUploadingFile: true,
                uploadStatus: status,
                uploadedProgress: progress
              });
              onUploadProgress && onUploadProgress(progress, status);
            }}
            server={process.env.REACT_APP_API_URL}
            signingUrl='/s3/sign'
            inputRef={cmp => (this.uploadInput = cmp)}
          />
          <div onClick={() => this.uploadInput && this.uploadInput.click()}>
            {basicContent
              ? basicContent
              : isUploadingFile
              ? uploadedProgress === 100
                ? postUploadMessageWhileCompleting
                : `${uploadStatus} ... ${uploadedProgress}%`
              : basicTextExplainer}
          </div>
          {disabledMessage && <div onClick={this.showDisabledMessage} className='disabled-cover' />}
        </div>
      );
    }

    return (
      <div className='upload-image-outer-container' onClick={e => e.stopPropagation()}>
        {!hiddenHeader && (
          <div className='upload-header'>
            <div className='header-text'>Upload Profile Photo</div>
            <ReactS3Uploader
              onFinish={this.handleFinishedUpload}
              accept={imagesOnly ? IMAGE_ACCEPT_TYPES : ALL_ACCEPT_TYPES}
              onProgress={(progress, status) => {
                if (status === 'Upload completed') {
                  this.setState({
                    isUploadingFile: false,
                    uploadStatus: status,
                    uploadedProgress: progress
                  });
                } else {
                  this.setState({
                    isUploadingFile: true,
                    uploadStatus: status,
                    uploadedProgress: progress
                  });
                }
              }}
              server={process.env.REACT_APP_API_URL}
              signingUrl='/s3/sign'
              inputRef={cmp => (this.uploadInput = cmp)}
              style={{ display: 'none' }}
            />
            <FontAwesomeIcon icon={faFileUpload} className='icon' onClick={() => this.uploadInput && this.uploadInput.click()}></FontAwesomeIcon>
          </div>
        )}
        <div className='upload-image-inner-container'>
          {isUploadingFile ? (
            <div className='uploading-overlay'>{`${uploadStatus} ... ${uploadedProgress}%`}</div>
          ) : (
            <>
              <ReactS3Uploader
                onFinish={this.handleFinishedUpload}
                accept={imagesOnly ? IMAGE_ACCEPT_TYPES : ALL_ACCEPT_TYPES}
                onProgress={(progress, status) => {
                  if (status === 'Upload completed') {
                    this.setState({
                      isUploadingFile: false,
                      uploadStatus: status,
                      uploadedProgress: progress
                    });
                  } else {
                    this.setState({
                      isUploadingFile: true,
                      uploadStatus: status,
                      uploadedProgress: progress
                    });
                  }
                }}
                server={process.env.REACT_APP_API_URL}
                signingUrl='/s3/sign'
              />
              {currentImage ? (
                <img src={currentImage} alt='Headshot' className='uploaded-image' />
              ) : (
                <div className='click-msg'>Click to Upload Photo</div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(UploadImage);
