import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './LinksPagination.scss';

class LinksPagination extends Component {
  static propTypes = {
    meta: PropTypes.object.isRequired,
    goToPage: PropTypes.func.isRequired
  };

  render() {
    const { meta, goToPage } = this.props;
    const { cur_page, total_pages } = meta;
    return (
      <div className='links-pagination-container'>
        {cur_page > 2 && (
          <div onClick={() => goToPage(1)} className='page-num'>
            1
          </div>
        )}
        {cur_page > 3 && <div className='ellipsis'>...</div>}
        {cur_page > 1 && (
          <div onClick={() => goToPage(cur_page - 1)} className='page-num'>
            {cur_page - 1}
          </div>
        )}
        <div className='page-num selected'>{cur_page}</div>
        {cur_page < total_pages && (
          <div onClick={() => goToPage(cur_page + 1)} className='page-num'>
            {cur_page + 1}
          </div>
        )}
        {cur_page + 2 < total_pages && <div className='ellipsis'>...</div>}
        {cur_page + 1 < total_pages && (
          <div onClick={() => goToPage(total_pages - 1)} className='page-num'>
            {total_pages}
          </div>
        )}
      </div>
    );
  }
}

export default LinksPagination;
