import APIClient from './index';
import _ from 'lodash';

export const getFeatured = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Featured/`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const updateFeaturedItem = (featured, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Featured/${featured.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getShopExamples = params => {
  return new Promise((resolve, reject) => {
    APIClient.get('Featured/shop_examples')
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
