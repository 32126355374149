import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './HomePartnerTiles.scss';

import { HOME_BRAND_TILE_GROUPS } from '../../../Helpers/home_helpers';

const INITIAL_DELAY_FOR_TRANSITION = 2000;
const DELAY_FOR_TRANSITION = 5000;

const HomePartnerTiles = props => {
  const [activeGroupIdx, setActiveGroupIdx] = React.useState(0);
  const [isTransitioningUp, setIsTransitioningUp] = React.useState(false);
  const [isTransitioningDown, setIsTransitioningDown] = React.useState(false);
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const wasManuallyTransitioned = React.useRef(false);

  // Handle group changes
  const activeGroup = HOME_BRAND_TILE_GROUPS[activeGroupIdx];
  const activeGroupIdxRef = React.useRef(activeGroupIdx);
  const wait = ms => new Promise(resolve => setTimeout(resolve, ms));
  const groupUp = async (fromAuto = false) => groupChange(-1, fromAuto);
  const groupDown = async (fromAuto = false) => groupChange(1, fromAuto);
  const groupChange = async (change, fromAuto = false) => {
    if (!fromAuto) {
      wasManuallyTransitioned.current = true;
      clearInterval(transitionDebounce.current);
    }
    setIsTransitioning(true);
    change > 0 ? setIsTransitioningDown(true) : setIsTransitioningUp(true);
    change > 0 ? setIsTransitioningUp(false) : setIsTransitioningDown(false);
    await wait(20); // To allow CSS animations
    setIsTransitioning(false);
    const newIndex =
      activeGroupIdxRef.current + change < 0
        ? HOME_BRAND_TILE_GROUPS.length - 1
        : activeGroupIdxRef.current + change >= HOME_BRAND_TILE_GROUPS.length
        ? 0
        : activeGroupIdxRef.current + change;
    setActiveGroupIdx(newIndex);
    activeGroupIdxRef.current = newIndex;
  };

  // Handle auto group changes on scroll into view
  const panelRef = React.useRef(null);
  const initialTransitionDebounce = React.useRef(null);
  const transitionDebounce = React.useRef(null);
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        clearInterval(initialTransitionDebounce.current);
        clearInterval(transitionDebounce.current);
        if (entry.isIntersecting && !wasManuallyTransitioned.current) {
          initialTransitionDebounce.current = setTimeout(() => {
            groupDown(true);
            transitionDebounce.current = setInterval(() => groupDown(true), DELAY_FOR_TRANSITION);
          }, INITIAL_DELAY_FOR_TRANSITION);
        }
      });
    });
    panelRef.current && observer.observe(panelRef.current);
    return () => panelRef.current && observer.unobserve(panelRef.current);
  }, []);

  const additionalClasses = { transitioning: isTransitioning, 'transitioning-up': isTransitioningUp, 'transitioning-down': isTransitioningDown };
  return (
    <div ref={panelRef} className='home-partner-tiles-outer-container'>
      <div className='main-container container'>
        <div className='main-container-inner'>
          <div className='header'>Some of our amazing brand partners in</div>
          <div className='group-toggle'>
            <div onClick={() => groupUp()} className='scroll up'>
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
            <div className={cn('active-title', additionalClasses)}>
              <div className='text'>{activeGroup.title}</div>
            </div>
            <div onClick={() => groupDown()} className='scroll down'>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
      </div>
      <div className='tiles-container container'>
        {activeGroup.tiles.slice(0, 12).map((tile, idx) => {
          const { cover, logo } = tile;
          return (
            <div className={cn('tile', additionalClasses)} key={logo + idx}>
              <img className={cn('primary', additionalClasses)} alt={`Partner ${idx}`} src={cover} />
              <div className={cn('cover', additionalClasses)} />
              <img className={cn('logo', additionalClasses)} alt={`Logo ${idx}`} src={logo} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomePartnerTiles;
