import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { postInstagramProfile, getUsersNeedingScrape } from '../../APIClient/extensions';
import cn from 'classnames';

import cogoToast from 'cogo-toast';
import './InstagramExtension.scss';

const InstagramExtension = props => {
  // Declan, put this on extension:
  /*
    const handleDataFromWebIframe = async request => {
      const { command } = request;
      switch (command) {
        case 'TEMPLATE':
          console.error('GOT DATA!!')
        default:
          break;
      }
    }
     eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
     eventer = window[eventMethod];
     messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
     eventer(messageEvent, function({ data }) {
        if (!window.fromShopMyWeb) return null
        handleDataFromWebIframe(data)
     });
  */

  const [panelOpen, setPanelOpen] = useState(false);
  const [usersToOpen, setUsersToOpen] = useState([]);

  const handleRequest = async request => {
    /*
      From browser:
        const iframe = document.getElementById('test').contentWindow
        iframe.postMessage({command: 'COMMAND', fromQuickLinksExtension: true, ...}, '*')
    */
    const submitData = data => window.parent.postMessage({ ...data, command, fromShopMyWeb: true }, '*');

    const { command } = request;
    const { instagram_profile } = request;

    switch (command) {
      case 'PAGE_OPEN':
        cogoToast.success('Panel is open');
        setPanelOpen(true);
        break;
      case 'POST_INSTAGRAM_PROFILE':
        const response = await postInstagramProfile({ instagram_profile });

        if (!response.success) {
          cogoToast.error('Error recording user');
          break;
        }

        cogoToast.success('Successfully recorded user');
        break;
      case 'TEMPLATE':
        // @param {string} href - the location of the page searching from

        /*
          iframe.postMessage({
            command: 'TEMPLATE', 
            fromQuickLinksExtension: true,
            ...
          }, '*')
        */
        return submitData({});
      default:
        break;
    }
  };

  const fetchUsers = async () => {
    const { success, users } = await getUsersNeedingScrape();
    success ? setUsersToOpen(users) : setUsersToOpen([]);
  };

  // Set up Listener for Messages from Web Page
  useEffect(() => {
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, function({ data }) {
      const isnt_relevant = !data?.fromQuickLinksExtension;
      if (isnt_relevant) return;

      handleRequest(data);
    });

    window.parent.postMessage({ command: 'ALLOW_REQUESTS', fromShopMyWeb: true }, '*');
    fetchUsers();
  }, []);

  return (
    <div className='instagram-extension-outer-container'>
      <div className='instagram-extension-inner-container'>
        <div className={cn('panel', { open: panelOpen })}>{panelOpen ? 'You are connected.' : 'Wait to browse instagram.'}</div>
        <div className='users'>
          {usersToOpen.map(user => {
            const { id, instagram_url, completed } = user;

            return (
              <div key={id} className={cn('user', { completed: !!completed })}>
                <div>{instagram_url}</div>
              </div>
            );
          })}
        </div>
        <div className='buttons'>
          <div className='button' onClick={() => fetchUsers()}>
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

InstagramExtension.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, store } = state;
  return { user, store };
};

export default connect(mapStateToProps, {})(InstagramExtension);
