import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Commissions.scss';

import { updateAffiliateCommission } from '../../APIClient/commissions';
import { openArtistModal } from '../../Actions/UIActions';

import { getBrandId } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import CommissionResults from '../../Components/Commissions/CommissionResults';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import Loader from '../../Components/Loader/Loader';
import CommissionData from '../../Components/Commissions/CommissionData';
import CommissionControls from '../../Components/Commissions/CommissionControls';

let debouncer;

const Commissions = props => {
  const { user } = props;
  const Brand_id = getBrandId(user);

  const [commissions, setCommissions] = useState([]);
  const [page, setPage] = useState(0);
  const [curSearchVal, setCurSearchVal] = useState('');
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [receivedAllResults, setReceivedAllResults] = useState(false);

  // Date range picker state
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Invoice select state
  const [allInvoices, setAllInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const changeSearchVal = e => {
    setIsFetchingResults(true);
    const newSearchVal = e.target.value;
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      setCurSearchVal(newSearchVal);
      setPage(0);
      setReceivedAllResults(false);
      setStartDate(null);
      setEndDate(null);
      setSelectedInvoice(null);
    }, 1000);
  };

  const updateDates = (startDate, endDate) => {
    setPage(0);
    setCurSearchVal('');
    setSelectedInvoice(null);
    setReceivedAllResults(false);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  if (!Brand_id) return <RequiresBrandLoginPanel />;

  const updateCommission = async (commission, updates) => {
    const newCommission = await updateAffiliateCommission(commission.id, updates);
    setCommissions(commissions.map(c => (c.id === newCommission.id ? newCommission : c)));
  };

  const isFetchingFirstPage = isFetchingResults && !commissions.length && !curSearchVal && page === 0;
  const isFetchingNextPage = isFetchingResults && page > 0;

  return (
    <div className='commissions-outer-container'>
      <CommissionData
        user={user}
        commissions={commissions}
        page={page}
        startDate={startDate}
        endDate={endDate}
        selectedInvoice={selectedInvoice}
        curSearchVal={curSearchVal}
        isFetchingNextPage={isFetchingNextPage}
        receivedAllResults={receivedAllResults}
        setAllInvoices={setAllInvoices}
        setIsFetchingResults={setIsFetchingResults}
        setReceivedAllResults={setReceivedAllResults}
        setCommissions={setCommissions}
        setPage={setPage}
      />
      {insertMetaTags({
        title: 'Orders Log',
        description: '',
        image: ''
      })}
      <div className='commissions-inner-container'>
        {isFetchingFirstPage ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <CommissionControls
              allInvoices={allInvoices}
              user={user}
              changeSearchVal={changeSearchVal}
              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
              startDate={startDate}
              endDate={endDate}
              curSearchVal={curSearchVal}
              updateDates={updateDates}
            />
            {commissions.length ? (
              <div className={cn('commissions-container', { isFetchingResults })}>
                <CommissionResults
                  updateCommission={updateCommission}
                  user={user}
                  openArtistModal={props.openArtistModal}
                  commissions={commissions}
                  isFetchingNextPage={isFetchingNextPage}
                  receivedAllResults={receivedAllResults}
                />
              </div>
            ) : (
              <div className='empty-results'>No commissions found</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Commissions.propTypes = {
  user: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openArtistModal
})(Commissions);
