import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faTimes, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faCommentsAlt, faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';

import './DiscoverUsersResult.scss';

import BrandListsOverlay from '../General/BrandListsOverlay';

import { getSmartImage } from '../../Helpers/helpers';
import { getInitialsForUser } from '../../Helpers/formatting';
import { getSocialPlatformForUrl, getSocialIconForUrl } from '../../Helpers/social_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getArtistBio, isSimulatingUser } from '../../Helpers/user_helpers';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';
import { getCodesForUser, getCustomRateForUser, getAllBrandListsFoundIn } from '../../Helpers/brand_helpers';

const DiscoverUsersResult = props => {
  const { result, user, analytics, activeQueries, searchVal, openArtistModal, toggleQuery } = props;
  const { user_image, social_links, tier, score } = result;
  const [isSelectingList, setIsSelectingList] = useState(false);
  const toggleIsSelectingList = () => setIsSelectingList(!isSelectingList);

  const socialLinks = _.orderBy(
    (social_links || '').split(',').map(url => ({
      url,
      type: getSocialPlatformForUrl(url),
      icon: getSocialIconForUrl(url, 'white')
    })),
    'type'
  );
  const getTagSearchIndex = tag => tag.value.toLowerCase().indexOf(searchVal.toLowerCase());
  const isTagSelected = tag =>
    !!_.find(activeQueries, q => {
      const tagIdMatch = q.Tag_id && q.Tag_id === tag.Tag_id; // Standard Tags
      const nonTagTypeMatch = !q.Tag_id && q.value === tag.value && q.type === tag.type; // Recommended, Promoter tags
      return tagIdMatch || nonTagTypeMatch;
    });
  const isTagSearched = tag => !!searchVal.length && getTagSearchIndex(tag) >= 0;
  const isTagHighlighted = tag => isTagSelected(tag) || isTagSearched(tag);

  const tags = _.orderBy(
    _.uniqBy(
      _.concat(
        _.filter(result.tags, t => t.type !== 'brandpromoter' || (getTagSearchIndex(t) >= 0 && searchVal.length >= 3)),
        activeQueries.filter(query => result.tags.find(t => t.Tag_id === query.Tag_id)),
        result.gifting_recommendation && !result.has_been_gifted ? [{ type: 'recommendedforgifting', value: 'Recommended For Gifting' }] : [],
        result.collab_recommendation && !result.has_been_collabed ? [{ type: 'recommendedforcollabs', value: 'Recommended For Collaboration' }] : [],
        result.codes_recommendation && !result.has_been_collabed ? [{ type: 'recommendedforcodes', value: 'Recommended For Discount Codes' }] : [],
        result.is_promoter ? [{ type: 'mypromoter', value: 'Our Talent' }] : []
      ),
      t => t.Tag_id + t.type
    ), // Ensuring we still show brand promoter tags
    [isTagSelected, tag => _.indexOf(['website', 'general', 'catalog', 'social', 'activity', 'behavior', 'internal'], tag.type)],
    ['desc', 'asc']
  );

  const description = getArtistBio(result);
  const customCodes = getCodesForUser(analytics, result);
  const customRate = getCustomRateForUser(analytics, result);

  const inBrandLists = getAllBrandListsFoundIn(user, result, { hideRecommendationLists: !isAdminControlMode(props.ui) });
  const isInList = !!inBrandLists.length;

  return (
    <div className='discover-users-result-outer-container'>
      <div className='discover-users-result-inner-container'>
        <div className='image-section'>
          {user_image ? (
            <img className='profile-image image' src={getSmartImage(user_image)} alt={result.name} />
          ) : (
            <div onClick={props.openUserModal} className='image empty'>
              {getInitialsForUser(result)}
            </div>
          )}
          <div className='header-controls-background' />
          <div className='header-controls'>
            <div onClick={() => props.openChatOverlay(result)} className='chat'>
              <FontAwesomeIcon icon={faCommentsAlt} />
            </div>
            <div className='social-links'>
              {socialLinks?.map(({ icon, type, url }, idx) => (
                <a href={url} target='_blank' rel='noopener noreferrer' key={idx} className='social-link'>
                  {icon ? <img alt={type} src={icon} /> : <FontAwesomeIcon icon={faLink} />}
                </a>
              ))}
            </div>
          </div>
          {(customRate || customCodes.length) && (
            <>
              <div className='footer-stats-background' />
              <div className='footer-stats'>
                <div className='footer-stat'>{customRate ? customRate?.rate + '%' : ''}</div>
                <div className='footer-stat'>{customCodes?.[0]?.displayText}</div>
              </div>
            </>
          )}
        </div>
        <div className='main-section'>
          <div className='profile-section'>
            <div className='name'>{result.name}</div>
            <div className='description'>{description}</div>
          </div>
          <div className='tags-section'>
            <div className='tags'>
              {tags.map((tag, idx) => {
                const selected = isTagSelected(tag);
                const highlighted = isTagHighlighted(tag);
                return (
                  <div
                    key={tag.Tag_id + String(idx)}
                    onClick={() => toggleQuery(tag)}
                    className={cn(
                      'tag',
                      { highlighted },
                      tag.type,
                      tag.value
                        .toLowerCase()
                        .split(' ')
                        .join('')
                    )}
                  >
                    {tag.type === 'brandpromoter' ? 'Promotes ' : ''}
                    {tag.value}
                    {selected && <FontAwesomeIcon icon={faTimes} />}
                  </div>
                );
              })}
              {isSimulatingUser(user) && (
                <div className={cn('tag')}>
                  {getUserTierDisplay(tier)} ({Math.round(score)})
                </div>
              )}
            </div>
            <div className='primary-actions'>
              <div onClick={() => openArtistModal(result)} className='select-btn'>
                View Talent Card
              </div>
              <BrandListsOverlay
                syncAfterEdit={props.syncTags}
                closeOverlay={toggleIsSelectingList}
                selectedUser_id={result.id}
                isActive={isSelectingList}
              >
                <div onClick={toggleIsSelectingList} className={cn('select-list-btn', { active: isInList })}>
                  {isInList ? <FontAwesomeIcon icon={faHeartSolid} /> : <FontAwesomeIcon icon={faHeart} />}
                  {inBrandLists.length > 1 && <div className='list-count-badge'>{inBrandLists.length}</div>}
                </div>
              </BrandListsOverlay>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DiscoverUsersResult.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  toggleQuery: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  removeRecommendation: PropTypes.func.isRequired,
  removeRecommendationRejection: PropTypes.func.isRequired,
  activeQueries: PropTypes.array.isRequired,
  searchVal: PropTypes.string.isRequired,
  openChatOverlay: PropTypes.func.isRequired
};

export default DiscoverUsersResult;
