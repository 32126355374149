import _ from 'lodash';
import * as ActionTypes from '../Actions/CollaborationsActions';
import * as StoreActionTypes from '../Actions/StoreActions';
import cogoToast from 'cogo-toast';

export const collaborations = (
  state = {
    allTaskTemplates: [],
    visibleContract: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_CONTRACT_TASK_TEMPLATES_SUCCESS:
      return {
        ...state,
        allTaskTemplates: _.filter(action.templates, taskTemplate => !taskTemplate.isArchived)
      };

    // Contracts
    case ActionTypes.GET_CONTRACT_REQUEST:
      return {
        ...state,
        visibleContract: null
      };

    case ActionTypes.GET_CONTRACT_SUCCESS:
      return {
        ...state,
        visibleContract: action.contract
      };

    case ActionTypes.GET_CONTRACT_FAILURE:
      cogoToast.error(typeof action.error === 'string' ? action.error : `There was an issue finding this collaboration, please reload the page.`);
      return {
        ...state
      };

    case ActionTypes.GET_CONTRACT_ANALYTICS_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.id
            ? {
                ...state.visibleContract,
                analytics: action.analytics,
                pins: state.visibleContract.pins.map(pin => ({
                  ...pin,
                  analytics: _.get(action.analytics.pins, pin.id, {})
                })),
                collections: state.visibleContract.collections.map(collection => ({
                  ...collection,
                  analytics: _.get(action.analytics.collections, collection.id, {}),
                  pins: collection.pins.map(pin => ({
                    ...pin,
                    analytics: _.get(action.analytics.pins, pin.id, {})
                  }))
                })),
                consults: state.visibleContract.consults.map(consult => ({
                  ...consult,
                  analytics: _.get(action.analytics.consults, consult.id, {})
                })),
                media: state.visibleContract.media.map(media => ({
                  ...media,
                  analytics: _.get(action.analytics.media, media.id, {})
                }))
              }
            : state.visibleContract
      };

    case ActionTypes.GET_CONTRACT_ANALYTICS_FAILURE:
      cogoToast.error(action.error || `There was an issue generating analytics on this collaboration, please reload the page.`);
      return {
        ...state
      };

    case ActionTypes.CREATE_CONTRACT_SUCCESS:
      return {
        ...state
      };

    case ActionTypes.CREATE_CONTRACT_BLOCKED:
      cogoToast.warn(action.error || `You cannot currently perform this action.`, { hideAfter: 6 });
      return {
        ...state
      };

    case ActionTypes.CREATE_CONTRACT_FAILURE:
      cogoToast.error(action.error || `There was an error creating this collaboration, please try again.`);
      return {
        ...state
      };

    case ActionTypes.UPDATE_CONTRACT_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.contract?.id
            ? {
                ...state.visibleContract,
                ...action.contract
              }
            : state.visibleContract
      };
    case ActionTypes.UPDATE_CONTRACT_FAILURE:
      cogoToast.error(action.error || `There was an error updating this collaboration, please try again.`);
      return {
        ...state
      };

    // Contract Tasks
    case ActionTypes.CREATE_CONTRACT_TASK_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.task?.Contract_id
            ? {
                ...state.visibleContract,
                tasks: [...state.visibleContract.tasks, action.task]
              }
            : state.visibleContract
      };

    case ActionTypes.CREATE_CONTRACT_TASK_FAILURE:
      cogoToast.error(`There was an error adding this deliverable, please try again.`);
      return {
        ...state
      };

    case ActionTypes.UPDATE_CONTRACT_TASK_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.task?.Contract_id
            ? {
                ...state.visibleContract,
                tasks: state.visibleContract.tasks.map(t => (t.id === action.task.id ? action.task : t))
              }
            : state.visibleContract
      };

    case ActionTypes.UPDATE_CONTRACT_TASK_FAILURE:
      cogoToast.error(`There was an error updating this deliverable, please try again.`);
      return {
        ...state
      };

    case ActionTypes.DELETE_CONTRACT_TASK_REQUEST:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.task?.Contract_id
            ? {
                ...state.visibleContract,
                tasks: state.visibleContract.tasks.filter(t => t.id !== action.task.id)
              }
            : state.visibleContract
      };

    case ActionTypes.DELETE_CONTRACT_TASK_FAILURE:
      cogoToast.error(`There was an error deleting this deliverable, please try again.`);
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.task?.Contract_id
            ? {
                ...state.visibleContract,
                tasks: [...state.visibleContract.tasks, action.task]
              }
            : state.visibleContract
      };

    // Contract Links
    case ActionTypes.CREATE_CONTRACT_LINK_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.link?.Contract_id
            ? {
                ...state.visibleContract,
                links: [...state.visibleContract.links, action.link]
              }
            : state.visibleContract
      };

    case ActionTypes.CREATE_CONTRACT_LINK_FAILURE:
      cogoToast.error(`There was an error adding this link, please try again.`);
      return {
        ...state
      };

    case ActionTypes.UPDATE_CONTRACT_LINK_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.link?.Contract_id
            ? {
                ...state.visibleContract,
                links: state.visibleContract.links.map(l => (l.id === action.link.id ? action.link : l))
              }
            : state.visibleContract
      };

    case ActionTypes.UPDATE_CONTRACT_LINK_FAILURE:
      cogoToast.error(`There was an error updating this link, please try again.`);
      return {
        ...state
      };

    case ActionTypes.DELETE_CONTRACT_LINK_REQUEST:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.link?.Contract_id
            ? {
                ...state.visibleContract,
                links: state.visibleContract.links.filter(l => l.id !== action.link.id)
              }
            : state.visibleContract
      };

    case ActionTypes.DELETE_CONTRACT_LINK_FAILURE:
      cogoToast.error(`There was an error deleting this link, please try again.`);
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.link?.Contract_id
            ? {
                ...state.visibleContract,
                links: [...state.visibleContract.links, action.link]
              }
            : state.visibleContract
      };

    // Contract Uploads
    case ActionTypes.CREATE_CONTRACT_UPLOAD_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.upload?.Contract_id
            ? {
                ...state.visibleContract,
                uploads: [...state.visibleContract.uploads, action.upload]
              }
            : state.visibleContract
      };

    case ActionTypes.CREATE_CONTRACT_UPLOAD_FAILURE:
      cogoToast.error(`There was an error completing this upload, please try again.`);
      return {
        ...state
      };

    case ActionTypes.UPDATE_CONTRACT_UPLOAD_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.upload?.Contract_id
            ? {
                ...state.visibleContract,
                uploads: state.visibleContract.uploads.map(u => (u.id === action.upload.id ? action.upload : u))
              }
            : state.visibleContract
      };

    case ActionTypes.UPDATE_CONTRACT_UPLOAD_FAILURE:
      cogoToast.error(`There was an error updating this upload, please try again.`);
      return {
        ...state
      };

    case ActionTypes.DELETE_CONTRACT_UPLOAD_REQUEST:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.upload?.Contract_id
            ? {
                ...state.visibleContract,
                uploads: state.visibleContract.uploads.filter(u => u.id !== action.upload.id)
              }
            : state.visibleContract
      };

    case ActionTypes.DELETE_CONTRACT_UPLOAD_FAILURE:
      cogoToast.error(`There was an error deleting this upload, please try again.`);
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.upload?.Contract_id
            ? {
                ...state.visibleContract,
                uploads: [...state.visibleContract.uploads, action.upload]
              }
            : state.visibleContract
      };

    // Contract Rates Codes
    case ActionTypes.CREATE_CONTRACT_RATE_CODE_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.rate_code?.Contract_id
            ? { ...state.visibleContract, rate_code: action.rate_code }
            : state.visibleContract
      };

    case ActionTypes.CREATE_CONTRACT_RATE_CODE_FAILURE:
      cogoToast.error(`There was an error updating this rate/code combination, please try again.`);
      return {
        ...state
      };

    case ActionTypes.UPDATE_CONTRACT_RATE_CODE_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.rate_code?.Contract_id
            ? { ...state.visibleContract, rate_code: action.rate_code }
            : state.visibleContract
      };

    case ActionTypes.UPDATE_CONTRACT_RATE_CODE_FAILURE:
      cogoToast.error(`There was an error updating this rate/code combination, please try again.`);
      return {
        ...state
      };

    case ActionTypes.DELETE_CONTRACT_RATE_CODE_REQUEST:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.rate_code?.Contract_id ? { ...state.visibleContract, rate_code: null } : state.visibleContract
      };

    case ActionTypes.DELETE_CONTRACT_RATE_CODE_FAILURE:
      cogoToast.error(`There was an error deleting this rate/code combination, please try again.`);
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.rate_code?.Contract_id
            ? { ...state.visibleContract, rate_code: action.rate_code }
            : state.visibleContract
      };

    // Contract Pins
    case StoreActionTypes.EDIT_PIN_SUCCESS:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.pin?.Contract_id
            ? {
                ...state.visibleContract,
                pins: state.visibleContract.pins.map(p => (p.id === action.pin.id ? action.pin : p))
              }
            : state.visibleContract
      };

    case StoreActionTypes.DELETE_PIN_REQUEST:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.pin?.Contract_id
            ? {
                ...state.visibleContract,
                pins: state.visibleContract.pins.filter(p => p.id !== action.pin.id)
              }
            : state.visibleContract
      };

    case StoreActionTypes.DELETE_PIN_FAILURE:
      return {
        ...state,
        visibleContract:
          state.visibleContract?.id === action.pin?.Contract_id
            ? {
                ...state.visibleContract,
                pins: [...state.visibleContract.pins, action.pin]
              }
            : state.visibleContract
      };

    default:
      return state;
  }
};
