import React from 'react';
import PropTypes from 'prop-types';

import './BrandApplyHeader.scss';

const BrandApplyHeader = props => {
  const imagesDark = [
    'https://static.shopmy.us/uploads/e0847a72-85c9-40e1-88d1-615c98b2b061_ash1.png',
    'https://static.shopmy.us/uploads/cd7a0176-8aab-471c-b9d8-5b107b6d6912_ashunta-3.png',
    'https://static.shopmy.us/uploads/b59ae852-f6cd-41db-9dec-17df0b579a35_cesar-2.png',
    'https://static.shopmy.us/uploads/79679e51-8c1b-4fea-be29-2ccd49589f6c_yannis2.png',
    'https://static.shopmy.us/uploads/39f0d064-4eff-4b08-ac36-26145b7d4603_fekkaiupdated2.png',
    'https://static.shopmy.us/uploads/0b8c4163-d95f-4e66-85f5-426692d48bc2_JaleesaJaikaran-2.png',
    'https://static.shopmy.us/uploads/8ccbc494-5bdd-4846-b6f0-9c60ac2e79c8_jennycho-3.png',
    'https://static.shopmy.us/uploads/d5d80405-f80d-4d57-ada3-a7c0bead4b88_katelee-2.png', // pretty good
    'https://static.shopmy.us/uploads/d67d100c-5635-45f2-b631-6234b5401fca_ksynnott3.png', // pretty good
    'https://static.shopmy.us/uploads/f1274b59-5d62-4cf5-8021-031852583f0c_LAURA-2.png', // b&w
    'https://static.shopmy.us/uploads/a64e4484-7511-4529-894c-ccb5bb6fb330_mararoszak-2.png',
    'https://static.shopmy.us/uploads/2cb3e0ba-e7a8-494e-9e5b-c2e5338b780f_peter-2.png', // b&w
    'https://static.shopmy.us/uploads/f9b91bdc-5488-46a0-9465-e30092ba8cd3_quinnm-2.png', // b&w
    'https://static.shopmy.us/uploads/b3384d71-7607-4259-8b2e-fda0285bf31e_scott1.png',
    'https://static.shopmy.us/uploads/f4f3258e-0d1a-4425-a967-6468f3f4add1_ouran2.png',
    'https://static.shopmy.us/uploads/65e383d5-73a4-4f03-8720-7d0d19bc6302_yolondafrederick-3.png',
    'https://static.shopmy.us/uploads/67662d29-8594-4e6b-948f-6c21752793f5_georgie-2.png', //maybe
    'https://static.shopmy.us/uploads/fdf3ab37-1d69-4cf0-a329-4310cfb7c87e_renee1.png'
  ];
  return (
    <div className='brand-apply-header-outer-container'>
      <div className='brand-apply-header-inner-container'>
        <div className='left panel'>
          <h1>Partner with the world's most trusted experts.</h1>
          <h2>Get your products in the hands of the most trusted names in beauty, skincare and more.</h2>
          <div className='apply-btn' onClick={props.scrollToAuth}>
            Apply
          </div>
        </div>
        <div className='right panel'>
          <div className='creator-grid'>
            {imagesDark.slice(0, 9).map((img, idx) => (
              <div key={idx} className='creator-container'>
                <img alt='Popular Artist' src={imagesDark[idx + 9]} />
                <img alt='Popular Artist' src={img} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BrandApplyHeader.propTypes = {
  scrollToAuth: PropTypes.func.isRequired
};

export default BrandApplyHeader;
