import APIClient from './index';
import _ from 'lodash';

export const getBrandBudget = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandBudgets/for_brand/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const sendBudgetInvoice = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BrandBudgetInvoices`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
