import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import './TalentResultActions.scss';

import { getBrand, getBrandSettings, getBrandLists, getBrandContractFromId } from '../../../Helpers/user_helpers';
import { getVisibleTalentActionEnum } from '../../../Helpers/talent_helpers';
import { getPrettyPriceDisplay } from '../../../Helpers/formatting';

import Loader from '../../Loader/Loader';
import PartershipStatusActions from '../../PartnershipStatus/PartershipStatusActions';

const TalentResultActions = props => {
  const { recentRequest, result, curListId, curStatusEnum, curActionEnum, user } = props;
  const { actionEnums, code, rate, Contract_id } = result;
  const curList = curListId && getBrandLists(user).find(l => l.id === curListId);
  const brand = getBrand(user);
  const settings = getBrandSettings(user);
  const contract = Contract_id && getBrandContractFromId(user, Contract_id);

  const [isTakingPrimaryAction, setIsTakingPrimaryAction] = useState(false);
  const [isTakingSecondaryAction, setIsTakingSecondaryAction] = useState(false);
  let primaryAction;
  let secondaryAction;
  let primaryActionDisplay;
  let secondaryActionDisplay;
  let noActionStatusDisplay;

  // Decide which enum to show based on filter state
  let actionEnumToDisplay = getVisibleTalentActionEnum({ actionEnums, curStatusEnum, curActionEnum });
  // let actionEnumToDisplay = 'PROPOSE_GIFTING';

  /***************************************************************************************************
   ********************************* Action Priority Overrides ***************************************
   **************************************************************************************************/

  // If filtered by recommendation list, we want to show the status most relevant to that list.
  if (curList && result.recommendation) {
    switch (result.recommendation.type) {
      case 'gifting':
        actionEnumToDisplay =
          (rate
            ? actionEnums.find(s => !s.includes('RECOMMENDATION_GIFTING') && s.includes('GIFTING'))
            : actionEnums.find(s => s.includes('GIFTING'))) || actionEnumToDisplay;
        break;
      case 'rates':
        actionEnumToDisplay =
          (rate
            ? actionEnums.find(s => !s.includes('RECOMMENDATION_RATES') && s.includes('RATES')) || actionEnumToDisplay
            : actionEnums.find(s => s.includes('RATES'))) || actionEnumToDisplay;
        break;
      case 'codes':
        actionEnumToDisplay =
          (code
            ? actionEnums.find(s => !s.includes('RECOMMENDATION_CODES') && s.includes('CODES')) || actionEnumToDisplay
            : actionEnums.find(s => s.includes('CODES'))) || actionEnumToDisplay;
        break;
      case 'collaboration':
        actionEnumToDisplay =
          (code
            ? actionEnums.find(s => !s.includes('RECOMMENDATION_COLLABORATION') && s.includes('OLLABORATION')) || actionEnumToDisplay
            : actionEnums.find(s => s.includes('OLLABORATION'))) || actionEnumToDisplay;
        break;
      case 'outreach':
        actionEnumToDisplay = actionEnums.find(s => s.includes('OUTREACH')) || actionEnumToDisplay;
        break;
      default:
        break;
    }
  }

  switch (actionEnumToDisplay) {
    /***************************************************************************************************
     ********************************************* Gifting ***********************************************
     **************************************************************************************************/
    case 'RESPOND_TO_GIFTING_REQUEST':
      // Not currently used as creators cannot directly request gifting at the moment
      // primaryAction = props.acceptGifting;
      // secondaryAction = props.dismissGiftingRequest;
      // primaryActionDisplay = 'Send Gifting Request';
      // secondaryActionDisplay = 'Dismiss';
      break;

    case 'RECOMMENDATION_GIFTING':
      primaryAction = props.sendGifting;
      secondaryAction = props.dismissRecommendation;
      primaryActionDisplay = 'Send Gifting Request';
      secondaryActionDisplay = 'Dismiss';
      break;

    case 'RECOMMENDATION_OUTREACH':
      primaryAction = props.replyToChat;
      secondaryAction = recentRequest ? props.sendGifting : !code ? props.offerCode : props.proposeCollaboration;
      primaryActionDisplay = 'Send Message';
      secondaryActionDisplay = recentRequest ? 'Send Gifting Request' : !code ? 'Offer Code' : 'View Collab Rates';
      break;

    case 'WAITING_ON_USER_TO_ACCEPT_GIFTING':
      noActionStatusDisplay = 'Waiting for response to Gifting';
      break;

    case 'GIFTING_REQUEST_NEEDS_SHIPPING':
      primaryAction = props.markGiftingSent;
      primaryActionDisplay = 'Fulfill Order';
      break;

    case 'GIFTING_REQUEST_AWAITING_SHOPIFY_FULFILLMENT':
      noActionStatusDisplay = `Awaiting Fulfillment on Shopify`;
      break;

    case 'WAITING_ON_USER_TO_TRY_PRODUCT':
      const DAYS_UNTIL_FOLLOW_UP_ON_GIFTING = settings?.durationSendGiftingFollowup || 7;
      const giftingSentDaysAgo = moment().diff(moment(recentRequest?.isCompleteAt), 'days');
      const daysUntilFollowup = DAYS_UNTIL_FOLLOW_UP_ON_GIFTING - giftingSentDaysAgo;
      // const DAYS_UNTIL_CONSIDER_EXPIRED = brand.durationSendGiftingConsiderExpired || 120; // TESTING
      // const DAYS_UNTIL_FOLLOW_UP_ON_GIFTING = brand.durationSendGiftingFollowup || 300; TESTING
      // const DAYS_UNTIL_CONSIDER_EXPIRED = brand.durationSendGiftingConsiderExpired || 400; TESTING
      noActionStatusDisplay =
        daysUntilFollowup <= 1 ? `Gifting sent, follow up tomorrow.` : `Gifting sent, wait ${daysUntilFollowup} more days for followup.`;
      break;

    case 'SHOULD_FOLLOW_UP_ON_GIFTING':
      primaryAction = props.followUpOnGifting;
      secondaryAction = props.dismissGiftingFollowup;
      primaryActionDisplay = 'Follow Up';
      secondaryActionDisplay = 'Dismiss';
      break;

    case 'WAITING_ON_USER_TO_TRY_PRODUCT_AFTER_DISMISSING_FOLLOWUP':
      noActionStatusDisplay = `Gifting sent on ${moment(recentRequest?.isCompleteAt).format('MMMM Do, YYYY')}`;
      break;

    case 'WAITING_ON_USER_TO_TRY_PRODUCT_AFTER_FOLLOWUP':
      noActionStatusDisplay = `Followed up on ${moment(recentRequest?.hasFollowedUpAt).format('MMMM Do, YYYY')}`;
      break;

    case 'SHOULD_CONSIDER_GIFTING_FAILED':
      noActionStatusDisplay = `Gifting sent on ${moment(recentRequest?.isCompleteAt).format('MMMM Do, YYYY')}`;
      break;

    /***************************************************************************************************
     ********************************************* Codes ***********************************************
     **************************************************************************************************/
    case 'RECOMMENDATION_CODES':
      primaryAction = props.offerCode;
      secondaryAction = props.dismissRecommendation;
      primaryActionDisplay = 'Offer Code';
      secondaryActionDisplay = 'Dismiss';
      break;

    case 'WAITING_ON_USER_TO_USE_CODE':
      const DAYS_UNTIL_FOLLOW_UP_ON_CODE = brand.durationSendCodeFollowup || 21;
      const codeSetDaysAgo = moment().diff(moment(code?.createdAt), 'days');
      const daysUntilCodeFollowup = DAYS_UNTIL_FOLLOW_UP_ON_CODE - codeSetDaysAgo;
      noActionStatusDisplay =
        daysUntilCodeFollowup <= 1 ? `Code sent, follow up tomorrow.` : `Code sent, wait ${daysUntilCodeFollowup} more days for followup.`;
      break;

    case 'SHOULD_FOLLOW_UP_ON_CODE':
      primaryAction = props.followUpOnCode;
      secondaryAction = props.dismissCodeFollowup;
      primaryActionDisplay = 'Follow Up';
      secondaryActionDisplay = 'Dismiss';
      break;

    case 'WAITING_ON_USER_TO_USE_CODE_AFTER_FOLLOWUP':
      noActionStatusDisplay = `Followed up on ${moment(code?.hasFollowedUpAt).format('MMMM Do, YYYY')}`;
      break;

    case 'WAITING_ON_USER_TO_USE_CODE_AFTER_DISMISSING_FOLLOWUP':
      noActionStatusDisplay = `Code sent on ${moment(code?.createdAt).format('MMMM Do, YYYY')}`;
      break;

    case 'SHOULD_CONSIDER_CODE_FAILED':
      noActionStatusDisplay = `Code sent on ${moment(code?.createdAt).format('MMMM Do, YYYY')}`;
      break;

    /***************************************************************************************************
     ********************************************* Rates ***********************************************
     **************************************************************************************************/
    case 'RECOMMENDATION_RATES':
      primaryAction = props.adjustRate;
      secondaryAction = props.dismissRecommendation;
      primaryActionDisplay = 'Adjust Rate';
      secondaryActionDisplay = 'Dismiss';
      break;

    /***************************************************************************************************
     ********************************************* Collabs ***********************************************
     **************************************************************************************************/
    case 'RECOMMENDATION_COLLABORATION':
      primaryAction = props.proposeCollaboration;
      secondaryAction = props.dismissRecommendation;
      primaryActionDisplay = 'View Collab Rates';
      secondaryActionDisplay = 'Dismiss';
      break;

    case 'RESPOND_TO_COLLABORATION_PROPOSAL':
      primaryAction = props.viewCollaboration;
      secondaryAction = props.dismissCollaboration;
      primaryActionDisplay = contract?.price ? `${getPrettyPriceDisplay(contract?.price, { precision: 0 })} | View Proposal` : `View Proposal`;
      secondaryActionDisplay = 'Dismiss';
      break;
    /***************************************************************************************************
     ***************************************** Opportunities *******************************************
     **************************************************************************************************/
    case 'WAITING_ON_USER_TO_ACCEPT_OPPORTUNITY':
      noActionStatusDisplay = 'Waiting for response to Opportunity';
      break;

    /***************************************************************************************************
     ********************************************* Chats ***********************************************
     **************************************************************************************************/
    case 'REPLY_TO_CHAT':
      primaryAction = props.replyToChat;
      secondaryAction = props.markChatRead;
      primaryActionDisplay = 'Reply';
      secondaryActionDisplay = 'Mark As Read';
      break;

    /***************************************************************************************************
     ***************************************** Promoters ***********************************************
     **************************************************************************************************/
    case 'PROPOSE_OPPORTUNITY':
      // primaryAction = props.proposeCollaboration;
      // primaryActionDisplay = 'Propose Collab';
      break;

    case 'PROPOSE_CODE':
      break;

    case 'PROPOSE_GIFTING':
      break;

    default:
      return (
        <div>
          <div>{_.startCase(actionEnumToDisplay.split('_').join(' '))}</div>
        </div>
      );
  }

  const performPrimary = async () => {
    setIsTakingPrimaryAction(true);
    try {
      await primaryAction();
    } catch (_) {}
    setIsTakingPrimaryAction(false);
  };

  const performSecondary = async () => {
    setIsTakingSecondaryAction(true);
    try {
      await secondaryAction();
    } catch (_) {}
    setIsTakingSecondaryAction(false);
  };

  const hasRecommendedActions = !!primaryAction || !!secondaryAction;

  return (
    <div className='talent-result-actions-container'>
      <div className='relationship-actions'>
        <PartershipStatusActions User_id={result.id} />
      </div>
      {hasRecommendedActions && (
        <div className='recommended-actions'>
          {secondaryAction && (
            <div onClick={performSecondary} className={cn('secondary result-action', { 'in-progress': isTakingSecondaryAction })}>
              {secondaryActionDisplay}
              {isTakingSecondaryAction && <Loader size={44} />}
            </div>
          )}
          {primaryAction && (
            <div onClick={performPrimary} className={cn('primary result-action', { 'in-progress': isTakingPrimaryAction })}>
              {primaryActionDisplay}
              {isTakingPrimaryAction && <Loader size={44} />}
            </div>
          )}
          {noActionStatusDisplay && <div className='no-action-status-display'>{noActionStatusDisplay}</div>}
        </div>
      )}
    </div>
  );
};

TalentResultActions.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
  recentRequest: PropTypes.object,
  curListId: PropTypes.number,
  curStatusEnum: PropTypes.string,
  curActionEnum: PropTypes.string,

  // Recommendations
  dismissRecommendation: PropTypes.func.isRequired,

  // Gifting
  sendGifting: PropTypes.func.isRequired,
  acceptGifting: PropTypes.func.isRequired,
  dismissGiftingRequest: PropTypes.func.isRequired,
  markGiftingSent: PropTypes.func.isRequired,
  goToGiftingPortal: PropTypes.func.isRequired,
  followUpOnGifting: PropTypes.func.isRequired,
  dismissGiftingFollowup: PropTypes.func.isRequired,

  // Codes
  offerCode: PropTypes.func.isRequired,
  followUpOnCode: PropTypes.func.isRequired,
  dismissCodeFollowup: PropTypes.func.isRequired,

  // Rates:
  adjustRate: PropTypes.func.isRequired,

  // Opportunities
  sendOpportunity: PropTypes.func.isRequired,

  // Collabs
  proposeCollaboration: PropTypes.func.isRequired,
  viewCollaboration: PropTypes.func.isRequired,
  dismissCollaboration: PropTypes.func.isRequired,

  // Chat
  replyToChat: PropTypes.func.isRequired,
  markChatRead: PropTypes.func.isRequired,
  dismissChat: PropTypes.func.isRequired
};

export default TalentResultActions;
