import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';
import cn from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faBars } from '@fortawesome/pro-light-svg-icons';
import { SortableElement, SortableContainer, sortableHandle } from 'react-sortable-hoc';

import './Consult.scss';

import ScrollToTop from '../../Components/General/ScrollToTop';
import ConsultHeader from '../../Components/Consults/ConsultHeader';
import ConsultSubmissionModal from '../../Components/Consults/ConsultSubmissionModal';
import ConsultElementPicker from '../../Components/Consults/ConsultElementPicker';
import ConsultSettings from '../../Components/Consults/ConsultSettings';
import ConsultElement from '../../Components/Consults/ConsultElement';

import { getVisibleConsult, saveConsultResponse, addConsultResult, editConsult, getShopExamples } from '../../Actions/StoreActions';
import { copyToClipboard } from '../../Helpers/helpers';
import { canEditConsult } from '../../Helpers/user_helpers';
import { addEvent } from '../../APIClient/events';
import {
  getElementsFromContent,
  updateElementInContent,
  addElementToContent,
  deleteElementFromContent,
  hasNewContent,
  moveElementIndices
} from '../../Helpers/consult_helpers';

const Consult = props => {
  const { store, user, editConsult, match, getShopExamples, getVisibleConsult } = props;
  const { visibleConsult, consultResponses, consultResults } = store || {};
  const [curContent, setCurContent] = useState(visibleConsult?.content);
  const [viewAsPublic, setViewAsPublic] = useState(false);
  const [liveView, setLiveView] = useState(false);
  const [addElementModalVisible, setAddElementModalVisible] = useState(false);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [submissionModalVisible, setSubmissionModalVisible] = useState(false);
  const consult = String(visibleConsult?.id) === String(match.params.id) ? visibleConsult : null;
  const canEdit = canEditConsult(consult, user);
  const previouslyCompletedResult = consultResults && consultResults.find(r => r.Consult_id === consult?.id);
  const noneRemaining = consult?.numRemaining === 0;

  // Set the response based in Redux Store, unless it has changed, in which case reset it.
  const storedResponse = _.get(consultResponses, consult?.id);
  const response = !storedResponse || hasNewContent(storedResponse, consult?.content) ? consult?.content : storedResponse;

  const syncConsult = useCallback(async () => {
    const consult = await getVisibleConsult(match.params.id);
    return _.get(consult, 'visibleConsult');
  }, [match.params.id, getVisibleConsult]);

  useEffect(() => {
    syncConsult().then(resp => {
      setCurContent(resp?.content);
      resp &&
        !canEdit &&
        addEvent('CONSULT_VIEW', {
          consultId: resp.id,
          userId: resp.User_id,
          consultTitle: resp.title,
          shopUsername: resp.user?.username,
          shopName: resp.user?.name
        });
    });
  }, [syncConsult, canEdit]);

  // Resets on unmount
  useEffect(() => resetSortableGlobalState, []);
  useEffect(() => {
    clearTimeout(window.liveSync);
    window.liveSync = liveView && setInterval(syncConsult, 2000);
    liveView && setViewAsPublic(true);
    return () => {
      clearTimeout(window.liveSync);
    };
  }, [liveView, syncConsult]);

  const toggleAddElementModal = () => setAddElementModalVisible(!addElementModalVisible);
  const toggleSubmissionModal = () => {
    setSubmissionModalVisible(!submissionModalVisible);
    !submissionModalVisible &&
      consult &&
      addEvent('Consult - Click Submission Button', {
        title: consult.title,
        user: consult.user
      });
  };
  const toggleSettingsModalVisible = () => setSettingsModalVisible(!settingsModalVisible);

  const setResponse = newContent => {
    props.saveConsultResponse(visibleConsult, newContent);
  };

  const performAtomicEdit = newContent => {
    setCurContent(newContent);
    if (!consult) {
      console.error('Somehow this is not visible.', { visibleConsult, consult });
    } else {
      editConsult(consult.id, { content: newContent });
    }
    setResponse(newContent); // Reset Response On Any Edits
  };

  window.curContent = curContent; // Necessary hack due to draggable components
  const addElement = element => {
    const newContent = addElementToContent(element, window.curContent);
    performAtomicEdit(newContent);
    resetSortableGlobalState();
  };

  const deleteElement = element => {
    const newContent = deleteElementFromContent(element, window.curContent);
    performAtomicEdit(newContent);
    resetSortableGlobalState();
  };

  const updateElement = (element, updates) => {
    const newContent = updateElementInContent(element, updates, curContent);
    performAtomicEdit(newContent);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newContent = moveElementIndices(curContent, oldIndex, newIndex);
    performAtomicEdit(newContent);
    resetSortableGlobalState();
  };

  const resetSortableGlobalState = () => {
    window.SortableItem = null; // Slight hack but avoids rerendering which allows for editing to occur
    window.SortableList = null; // Slight hack but avoids rerendering which allows for editing to occur
  };

  const updateResponse = (element, updates) => {
    if (noneRemaining) return cogoToast.warn(`This consult is currently out of stock.`);
    const newContent = updateElementInContent(element, updates, response);
    setResponse(newContent);
  };

  const resetResponse = (element, updates) => {
    setResponse(consult.content);
  };

  const SortableItem =
    window.SortableItem ||
    SortableElement(({ value }) => {
      const element = value;
      const DragHandle = sortableHandle(() => (
        <div className='reorder-icn icn'>
          <FontAwesomeIcon icon={faBars} />
        </div>
      ));
      return (
        <ConsultElement
          dragHandle={<DragHandle />}
          // key={element.id}
          consult={consult}
          element={element}
          editConsult={editConsult}
          deleteElement={deleteElement}
          updateElement={updateElement}
        />
      );
    });
  window.SortableItem = SortableItem; // Slight hack but avoids rerendering

  const SortableList =
    window.SortableList ||
    SortableContainer(({ items }) => {
      return (
        <div>
          {items.map((element, idx) => (
            <SortableItem axis='y' key={`item-${element.id}`} index={idx} value={{ ...element, idx }} />
          ))}
        </div>
      );
    });
  window.SortableList = SortableList; // This ensures no double renader

  const elements = getElementsFromContent(curContent);
  const responseElements = getElementsFromContent(response);

  if (visibleConsult && !consult) return null; // Ensure we don't show the wrong consult
  return (
    <div className='consult-outer-container'>
      <ScrollToTop />
      {consult && (
        <MetaTags>
          {consult.title && <title>{consult.title}</title>}
          {consult.title && <meta property='og:title' content={consult.title} />}
          {consult.description && <meta property='description' content={consult.description} />}
          {consult.description && <meta property='og:description' content={consult.description} />}
        </MetaTags>
      )}
      <div className='consult-inner-container'>
        {consult && (
          <ConsultHeader
            user={user}
            consult={consult}
            setViewAsPublic={setViewAsPublic}
            viewAsPublic={viewAsPublic}
            setLiveView={setLiveView}
            liveView={liveView}
            canEdit={canEdit}
            editConsult={editConsult}
            openSettingsPanel={toggleSettingsModalVisible}
          />
        )}
        {canEdit && !viewAsPublic ? (
          <SortableList axis='y' items={elements} onSortEnd={onSortEnd} useDragHandle />
        ) : (
          responseElements.map((element, idx) => {
            return (
              <ConsultElement
                // test={'test'}
                key={element.id}
                consult={consult}
                element={element}
                updateElement={updateElement}
                updateResponse={updateResponse}
              />
            );
          })
        )}
        {canEdit && !viewAsPublic ? (
          <div className='next-step-btns'>
            <div onClick={toggleAddElementModal} className='add-element-btn btn'>
              ADD {!elements.length ? 'FIRST ' : ''}ELEMENT
              <FontAwesomeIcon icon={faPlus} />
            </div>
            {!responseElements.length ? null : consult.price ? (
              <div onClick={() => copyToClipboard(window.location.href, true, 'Copied URL to clipboard!')} className='outline-btn btn'>
                COPY SHAREABLE URL
              </div>
            ) : (
              <div onClick={toggleSettingsModalVisible} className='outline-btn btn'>
                SET PRICE
              </div>
            )}
          </div>
        ) : canEdit ? (
          <div className='next-step-btns'>
            <div
              onClick={() => {
                setViewAsPublic(!viewAsPublic);
                window.scrollTo(0, 0, { behavior: 'smooth' });
              }}
              className='dark-btn btn'
            >
              CONTINUE EDITING
            </div>
            <div onClick={() => copyToClipboard(window.location.href, true, 'Copied URL to clipboard!')} className='outline-btn btn'>
              COPY SHAREABLE URL
            </div>
            <div onClick={() => (noneRemaining ? null : toggleSubmissionModal())} className={cn('outline-btn btn', { disabled: noneRemaining })}>
              {noneRemaining ? 'CURRENTLY UNAVAILABLE' : 'SIMULATE RESPONSE'}
            </div>
          </div>
        ) : (
          <div className='next-step-btns'>
            <div onClick={() => (noneRemaining ? null : toggleSubmissionModal())} className={cn('submit-result-btn', { disabled: noneRemaining })}>
              {noneRemaining ? 'CURRENTLY UNAVAILABLE' : 'SUBMIT RESPONSE'}
              {!noneRemaining && <FontAwesomeIcon icon={faCheck} />}
              {previouslyCompletedResult && (
                <div className='already-completed-alert'>
                  You may already have a response{` `}
                  <Link to={`/consults/results/${previouslyCompletedResult.stub}`}>here</Link>.
                </div>
              )}
            </div>
          </div>
        )}
        {consult && (
          <>
            {canEdit && (
              <ConsultElementPicker
                getShopExamples={getShopExamples}
                visible={addElementModalVisible}
                closeModal={toggleAddElementModal}
                store={store}
                user={user}
                addElement={addElement}
              />
            )}
            {canEdit && (
              <ConsultSettings consult={consult} editConsult={editConsult} visible={settingsModalVisible} closeModal={toggleSettingsModalVisible} />
            )}
            {(!canEdit || viewAsPublic) && (
              <ConsultSubmissionModal
                addConsultResult={props.addConsultResult}
                consult={consult}
                user={user}
                visible={submissionModalVisible}
                resetResponse={resetResponse}
                closeModal={toggleSubmissionModal}
                response={response}
              />
            )}
          </>
        )}
      </div>
      {canEdit && !viewAsPublic ? null : (
        <div className='consult-disclaimer'>
          Please be advised that the product recommendations and advice featured here is not intended to provide diagnosis, treatment or medical
          advice. Please consult with a physician or other healthcare professional regarding any medical or health related diagnosis or treatment
          options. Information on this site should not be considered a substitute for advice from a healthcare professional.
        </div>
      )}
    </div>
  );
};

Consult.propTypes = {
  user: PropTypes.object.isRequired,
  addConsultResult: PropTypes.func.isRequired,
  saveConsultResponse: PropTypes.func.isRequired,
  getVisibleConsult: PropTypes.func.isRequired,
  getShopExamples: PropTypes.func.isRequired,
  editConsult: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, store } = state;
  return { user, store };
};

export default connect(mapStateToProps, {
  getVisibleConsult,
  addConsultResult,
  saveConsultResponse,
  getShopExamples,
  editConsult
})(withLastLocation(withRouter(Consult)));
