import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { withLastLocation } from 'react-router-last-location';
import { withRouter, Link } from 'react-router-dom';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCog } from '@fortawesome/pro-regular-svg-icons';

import './ConsultHeader.scss';

import { isPublicPage, matchScrollHeight, copyToClipboard } from '../../Helpers/helpers';
import { getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getNameWithS, isYou, isAdmin } from '../../Helpers/user_helpers';
import { formatPublicConsultDisplay, getConsultUrl } from '../../Helpers/consult_helpers';

class ConsultHeader extends Component {
  state = {
    consultTitleOverride: null,
    consultDescriptionOverride: null
  };

  componentDidMount() {
    this.updateFields = AwesomeDebouncePromise(async () => {
      await this.props.editConsult(this.props.consult.id, {
        title: this.state.consultTitleOverride === null ? this.props.consult.title : this.state.consultTitleOverride,
        description: this.state.consultDescriptionOverride === null ? this.props.consult.description : this.state.consultDescriptionOverride
      });
    }, 400);
  }

  updateName = newTitle => {
    this.setState({ consultTitleOverride: newTitle });
    this.updateFields();
    const newConsultUrl = getConsultUrl({ ...this.props.consult, title: newTitle });
    this.props.history.replace(newConsultUrl);
  };

  updateDescription = newDescription => {
    this.setState({ consultDescriptionOverride: newDescription });
    this.updateFields();
  };

  render() {
    const { user, consult, canEdit, setViewAsPublic, viewAsPublic, liveView, setLiveView } = this.props;
    const { consultTitleOverride, consultDescriptionOverride } = this.state;
    const pluralizedName = getNameWithS(consult?.user);
    const you = isYou(consult?.user?.username, user);
    const lastLocation = _.get(this.props.lastLocation, 'pathname');
    const comingFromShop = _.includes(lastLocation, `/${consult?.user?.username}`) || _.includes(lastLocation, `/public/${consult?.user?.username}`);
    const comingFromColab = _.includes(lastLocation, `/collaboration`);
    return (
      <div itemScope className='consult-header-outer-container'>
        {canEdit && (
          <div className='management-btns'>
            {isAdmin(user) && (
              <div className='live-view-switch'>
                <div>Live View</div>
                <Switch
                  onChange={() => setLiveView(!liveView)}
                  checked={liveView}
                  height={14}
                  width={24}
                  onColor='#333'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            )}
            <div className='view-as-public-switch'>
              <div>View As Public</div>
              <Switch
                onChange={() => setViewAsPublic(!viewAsPublic)}
                checked={viewAsPublic}
                height={14}
                width={24}
                onColor='#333'
                className='switch'
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
            <div onClick={() => copyToClipboard(window.location.href, true, 'Copied URL to clipboard!')} className='share-btn'>
              Copy URL
            </div>
          </div>
        )}
        <div itemScope className='consult-header-container'>
          <div className='top-row'>
            {comingFromShop ? (
              <div onClick={() => this.props.history.goBack()} className='back-link'>
                <FontAwesomeIcon icon={faArrowLeft} className='back' />
                Back to {you ? 'your shop' : pluralizedName ? `${pluralizedName.full} shop` : 'shop'}
              </div>
            ) : comingFromColab ? (
              <div onClick={() => this.props.history.goBack()} className='back-link'>
                <FontAwesomeIcon icon={faArrowLeft} className='back' />
                Back to collaboration
              </div>
            ) : (
              <Link to={`${isPublicPage() ? '/public' : ''}/${consult?.user?.username}/consults`} className='back-link'>
                <FontAwesomeIcon icon={faArrowLeft} className='back' />
                Back to {you ? 'your shop' : pluralizedName ? `${pluralizedName.full} shop` : 'shop'}
              </Link>
            )}
          </div>
          <div itemScope className='consult-header-inner-container'>
            {canEdit && !viewAsPublic ? (
              <>
                <textarea
                  value={_.isNil(consultTitleOverride) ? consult.title || '' : consultTitleOverride}
                  className='title-input-container'
                  placeholder={`Name your Consultation`}
                  ref={ref => matchScrollHeight(ref)}
                  rows={1}
                  onChange={e => this.updateName(e.target.value)}
                />
                <textarea
                  value={_.isNil(consultDescriptionOverride) ? consult.description || '' : consultDescriptionOverride}
                  className='description-input-container'
                  placeholder={`Describe the consultation you are offering.`}
                  ref={ref => matchScrollHeight(ref)}
                  rows={1}
                  onChange={e => this.updateDescription(e.target.value)}
                />
              </>
            ) : (
              <>
                <h1 itemProp='name' className='consult-title'>
                  {consult.title || '    '}
                </h1>
                {consult.description && (
                  <h2 itemProp='description' className='consult-description'>
                    {formatPublicConsultDisplay(consult.description)}
                  </h2>
                )}
              </>
            )}
          </div>
          <div className='meta-data-container'>
            <div onClick={canEdit && !viewAsPublic ? this.props.openSettingsPanel : null} className='section'>
              {consult.price ? getPrettyPriceDisplay(consult.price) : 'Free'}
            </div>
            {_.isNil(consult.numRemaining) ? null : consult.numRemaining ? (
              <div onClick={canEdit && !viewAsPublic ? this.props.openSettingsPanel : null} className='section'>
                {consult.numRemaining} remaining
              </div>
            ) : (
              <div onClick={canEdit && !viewAsPublic ? this.props.openSettingsPanel : null} className='section'>
                Not Currently Available
              </div>
            )}
            {canEdit && !viewAsPublic && (
              <div className='section'>
                <FontAwesomeIcon className='edit-icon' onClick={this.props.openSettingsPanel} icon={faCog} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ConsultHeader.propTypes = {
  user: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  viewAsPublic: PropTypes.bool.isRequired,
  setViewAsPublic: PropTypes.func.isRequired,
  editConsult: PropTypes.func.isRequired,
  openSettingsPanel: PropTypes.func.isRequired,
  consult: PropTypes.object.isRequired
};

export default withLastLocation(withRouter(ConsultHeader));
