import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './LookbookEmphasizedNote.scss';

const LookbookEmphasizedNote = props => {
  const { lookbook } = props;
  let { brandNote, brand } = lookbook;

  return (
    <div className='lookbook-emphasized-note-outer-container'>
      <div
        className={cn('lookbook-emphasized-note-inner-container', {
          'extra-short': brandNote.length < 50,
          short: brandNote.length >= 50 && brandNote.length < 200,
          medium: brandNote.length >= 200 && brandNote.length < 400,
          long: brandNote.length >= 400 && brandNote.length < 800,
          'extra-long': brandNote.length >= 800
        })}
      >
        <div className='brand-logo'>
          <img src={brand.logo} alt='brand-logo' className='icon' />
        </div>
        <div className='brand-note'>
          <div className='note'>{brandNote}</div>
        </div>
        <div className='dismiss-button' onClick={props.onDismiss}>
          Make Selections
        </div>
        <div onClick={props.onDismiss} className='close-icon'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

LookbookEmphasizedNote.propTypes = {
  lookbook: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired
};

export default LookbookEmphasizedNote;
