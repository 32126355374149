import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { confirmAlert } from 'react-confirm-alert';
import icons from '../../../static/icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityListingHeader.scss';

import ImageUploader from '../../General/ImageUploader';
import ConfirmPrompt from '../../General/ConfirmPrompt';
import Tooltip from '../../General/Tooltip';

import { opportunityTypeData, getCoverImageForOpportunity } from '../../../Helpers/opportunity_helpers';
import { getBrand } from '../../../Helpers/user_helpers';
import { getPrettyDate, getPrettyNumber } from '../../../Helpers/formatting';
import { matchScrollHeight } from '../../../Helpers/helpers';

const OpportunityListingHeader = props => {
  const { user, opportunity, opportunityRequest, performBulkUpdates, updateField, isEditing, canEdit } = props;
  const { title, type, payment_tiers, coverImage, location, trackingStartsAt, trackingEndsAt, eventStartsAt, eventEndsAt } = opportunity;
  const brand = getBrand(user) || opportunity.brand || opportunityRequest?.brand;
  const isExperientialOpportunity = type === 'experience';

  // Images
  const [isUploadingImage, setIsUploadingImage] = React.useState(false);
  const fallbackImage = getCoverImageForOpportunity(opportunity, brand);
  const clickToUploadImage = () => setIsUploadingImage(true);

  // Types
  const curOppTypeData = opportunityTypeData.find(data => data.value === type);

  // Event Events
  const eventDate = eventStartsAt
    ? getPrettyDate(eventStartsAt) === getPrettyDate(eventEndsAt)
      ? getPrettyDate(eventStartsAt)
      : `${getPrettyDate(eventStartsAt)} - ${getPrettyDate(eventEndsAt)}`
    : null;
  const editEventDate = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Set Event Time'
          subheader='This window specifies when the event or experience will take place. If the event is a single date, set the start date and end date to the same date. You can specify the exact time of day in the "About" section.'
          allowOverflow
          onCancel={onClose}
          customInputFields={[
            {
              isDateRange: true,
              value_date_range: ['eventStartsAt', 'eventEndsAt'],
              preloaded_date_range: [eventStartsAt && moment(eventStartsAt), eventEndsAt && moment(eventEndsAt)]
            }
          ]}
          onSubmit={async data => {
            const { eventStartsAt, eventEndsAt } = data;
            performBulkUpdates({
              eventStartsAt: eventStartsAt.format('YYYY-MM-DD 12:00:00'),
              eventEndsAt: eventEndsAt.format('YYYY-MM-DD 12:00:00')
            });
          }}
        />
      )
    });
  };

  // Tracking
  const trackingWindow =
    trackingStartsAt && trackingEndsAt
      ? trackingStartsAt === trackingEndsAt
        ? getPrettyDate(trackingStartsAt)
        : `${getPrettyDate(trackingStartsAt)} - ${getPrettyDate(trackingEndsAt)}`
      : null;
  const editTrackingDate = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Set Tracking Window'
          subheader='This window specifies in what time period the talent is able to complete the expecations of this opportunity. We will only count content created or links shared within this window.'
          allowOverflow
          onCancel={onClose}
          customInputFields={[
            {
              isDateRange: true,
              value_date_range: ['trackingStartsAt', 'trackingEndsAt'],
              preloaded_date_range: [trackingStartsAt && moment(trackingStartsAt), trackingEndsAt && moment(trackingEndsAt)]
            }
          ]}
          onSubmit={async data => {
            const { trackingStartsAt, trackingEndsAt } = data;
            performBulkUpdates({
              trackingStartsAt: trackingStartsAt.format('YYYY-MM-DD 12:00:00'),
              trackingEndsAt: trackingEndsAt.format('YYYY-MM-DD 12:00:00')
            });
          }}
        />
      )
    });
  };

  // Payments
  let feeDisplay, commissionDisplay;

  if (opportunityRequest) {
    // For users
    const fixedFee = opportunityRequest.payment_tier.fixedFee;
    const adjCommissionRate = opportunityRequest.payment_tier.adjCommissionRate;
    feeDisplay = fixedFee ? `$${commaNumber(fixedFee)}` : null;
    commissionDisplay = _.isNumber(adjCommissionRate) ? `${adjCommissionRate}%` : null;
  } else {
    const minFixedFee = _.minBy(payment_tiers, 'fixedFee')?.fixedFee;
    const maxFixedFee = _.maxBy(payment_tiers, 'fixedFee')?.fixedFee;
    feeDisplay = !minFixedFee
      ? null
      : minFixedFee === maxFixedFee
      ? `$${getPrettyNumber(minFixedFee)}`
      : `$${getPrettyNumber(minFixedFee)} - $${getPrettyNumber(maxFixedFee)}`;
    const minAdjCommissionRate = _.minBy(payment_tiers, 'adjCommissionRate')?.adjCommissionRate;
    const maxAdjCommissionRate = _.maxBy(payment_tiers, 'adjCommissionRate')?.adjCommissionRate;
    commissionDisplay = !_.isNumber(maxAdjCommissionRate)
      ? null
      : minAdjCommissionRate === maxAdjCommissionRate
      ? `${minAdjCommissionRate}%`
      : `${minAdjCommissionRate} - ${maxAdjCommissionRate}%`;
  }

  const additionalClasses = {
    editing: isEditing,
    public: !isEditing
  };

  const flagRequiresEditMode = () => canEdit && window.ALERT.warn(`Please click the edit button to edit this field`);

  return (
    <div className='listing-header-container'>
      <div onClick={clickToUploadImage} className={cn('listing-image-container', { 'image-set': !!coverImage }, additionalClasses)}>
        {isEditing ? (
          coverImage ? (
            <img className='cover-image' src={coverImage} alt='cover' />
          ) : fallbackImage ? (
            <img className='cover-image fallback' src={fallbackImage} alt='cover' />
          ) : (
            <div className='cover-image empty' />
          )
        ) : (
          <img className='cover-image public' src={coverImage || fallbackImage} alt='cover' />
        )}
        {isEditing && (
          <div className='upload-image-overlay-description'>
            <div className='description-text'>Click to upload a cover image</div>
          </div>
        )}
      </div>
      <div className='listing-data-container'>
        {/* Types */}
        <div className={cn('listing-main', additionalClasses)}>
          <div className='type-selection-container section'>
            {isEditing && <div className='section-label'>Type:</div>}
            <div className={cn('type-options', additionalClasses)}>
              {opportunityTypeData.map(data => {
                const { display, value } = data;
                const isActive = type === value;
                const select = () => !isActive && updateField('type', value);
                if (!isActive && !isEditing) return null;
                return (
                  <div onClick={select} key={value} className={cn('type-option', { active: isActive }, additionalClasses)}>
                    {display}
                  </div>
                );
              })}
            </div>
            {isEditing && <div className='type-description'>{curOppTypeData?.description}</div>}
          </div>

          {/* Title */}
          <div className='header-data-container section'>
            <div className='section-body'>
              {isEditing ? (
                <div className='header-input-container'>
                  <textarea
                    autoFocus={!title}
                    rows={1}
                    placeholder='Enter Title Here'
                    ref={ref => matchScrollHeight(ref)}
                    className='header'
                    value={title}
                    onChange={e => updateField('title', e.target.value)}
                  />
                </div>
              ) : (
                <h1 className='header'>{opportunity.title}</h1>
              )}
            </div>
          </div>
        </div>

        <div className='listing-secondary'>
          <div className='location-and-tracking'>
            {/* Location of Event  */}
            {isExperientialOpportunity && (
              <div className='location-container container'>
                <div className='icon'>
                  <img src={icons.misc.map_pin} alt='map' />
                </div>
                {isEditing ? (
                  <textarea
                    rows={1}
                    placeholder='Enter Location Here'
                    ref={ref => matchScrollHeight(ref)}
                    className='location value editable'
                    value={location}
                    onChange={e => updateField('location', e.target.value)}
                  />
                ) : (
                  <div onClick={flagRequiresEditMode} className='location value'>
                    {location || 'No Location Specified'}
                  </div>
                )}
              </div>
            )}
            {isExperientialOpportunity && (
              <Tooltip
                message={isEditing ? 'This is the day of the event, if you need to specify an exact hour, do that in the About section below.' : ''}
              >
                <div className={cn('event-time-container container', additionalClasses, { empty: !eventDate })}>
                  {isEditing ? (
                    <>
                      {eventDate && (
                        <div className='icon'>
                          <img src={icons.misc.calendar} alt='calendar' />
                        </div>
                      )}
                      <div
                        onClick={editEventDate}
                        className={cn('event-time value clickable', additionalClasses, { empty: !eventDate, required: true })}
                      >
                        {!eventDate && <img src={icons.misc.calendar} alt='calendar' />}
                        {eventDate || 'Set Event Date'}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='icon'>
                        <img src={icons.misc.calendar} alt='calendar' />
                      </div>
                      <div onClick={flagRequiresEditMode} className={cn('event-time value', { empty: !eventDate })}>
                        {eventDate || 'No Event Date Specified'}
                      </div>
                    </>
                  )}
                </div>
              </Tooltip>
            )}
            <Tooltip
              message={
                isEditing
                  ? isExperientialOpportunity
                    ? 'This is the window in which the creator can complete the expectations of this opportunity. Since this is an experiential opportunity, we recommend adding at least a week after the event completes to ensure you capture all the ROI.'
                    : 'This is the window in which the creator can complete the expectations of this opportunity.'
                  : ''
              }
            >
              <div className={cn('tracking-window-container container', additionalClasses, { empty: !trackingWindow })}>
                {isEditing ? (
                  <>
                    {trackingWindow && (
                      <div className='icon'>
                        <img src={isExperientialOpportunity ? icons.misc.track_performance : icons.misc.calendar} alt='calendar' />
                      </div>
                    )}
                    <div
                      onClick={editTrackingDate}
                      className={cn('tracking-window value clickable', additionalClasses, { empty: !trackingWindow, required: true })}
                    >
                      {!trackingWindow && <img src={isExperientialOpportunity ? icons.misc.track_performance : icons.misc.calendar} alt='calendar' />}
                      {trackingWindow || 'Set Tracking Window'}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='icon'>
                      <img src={isExperientialOpportunity ? icons.misc.track_performance : icons.misc.calendar} alt='calendar' />
                    </div>
                    <div onClick={flagRequiresEditMode} className={cn('tracking-window value', { empty: !trackingWindow })}>
                      {trackingWindow
                        ? isExperientialOpportunity
                          ? `Tracking from ${trackingWindow}`
                          : trackingWindow
                        : 'No Tracking Window Specified'}
                    </div>
                  </>
                )}
              </div>
            </Tooltip>
          </div>
          {!isEditing && (
            <div className='payment-overview'>
              {feeDisplay && (
                <div className={cn('overview-stat', { 'is-single': !commissionDisplay })}>
                  <div className='value'>{feeDisplay}</div>
                  <div className='stat'>Fixed Fee</div>
                </div>
              )}
              {commissionDisplay && (
                <div className={cn('overview-stat', { 'is-single': !feeDisplay })}>
                  <div className='value'>{commissionDisplay}</div>
                  <div className='stat'>Commission Rate</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ImageUploader
        isVisible={isUploadingImage}
        setIsVisible={setIsUploadingImage}
        onSaveCallback={img => updateField('coverImage', img)}
        initialImageUrl={coverImage}
      />
    </div>
  );
};

OpportunityListingHeader.propTypes = {
  // General
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  opportunityRequest: PropTypes.object,

  // Actions & Config
  updateField: PropTypes.func.isRequired,
  performBulkUpdates: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,

  // Data
  title: PropTypes.string,
  coverImage: PropTypes.string,
  type: PropTypes.string
};

export default OpportunityListingHeader;
