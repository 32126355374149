import React from 'react';
import PropTypes from 'prop-types';
import './OpportunitiesAnnouncement.scss';

import ArrowButton from '../../General/Buttons/ArrowButton';

const OpportunitiesAnnouncement = props => {
  const { announcement } = props;
  const pressActionOne = () => props.closeModal();
  const pressActionTwo = () => props.openChat(`Could we schedule a demo of Discover 2.0?`);
  return (
    <div className='opportunities-announcement-outer-container'>
      <div className='main-panel'>
        <div className='badge'>Amplify your program with</div>
        <div className='title'>Opportunities</div>
        <div className='body'>
          Opportunities bring more stability to your marketing program by offering guaranteed creator coverage on social in return for a small fee
          paid to creators. They are intended to replace your current campaigns with a more automated and scalable solution.
          <br />
          <br />
          To learn more about amplifying your program with Opportunities, check out{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://brands.shopmy.us/opportunities'>
            the brand guide
          </a>{' '}
          or chat with us to schedule a demo with your ShopMy team.
        </div>
        <div className='continue-btn-container'>
          <ArrowButton displayText='Explore Now' onClick={pressActionOne} />
          <ArrowButton displayText='Chat with Us' onlyText onClick={pressActionTwo} />
        </div>
      </div>
      <img src={announcement.image} alt={announcement.type} />
    </div>
  );
};

OpportunitiesAnnouncement.propTypes = {
  announcement: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired
};

export default OpportunitiesAnnouncement;
