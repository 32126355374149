import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './ConfirmDelete.scss';

const ConfirmDelete = props => {
  /*
    Examples:

      Multi-line:

        import { confirmAlert } from 'react-confirm-alert';
        import ConfirmDelete from '../General/ConfirmDelete';

        confirmAlert({
          customUI: ({ onClose }) => (
            <ConfirmDelete
              header='Header'
              subheader='Details'
              onCancel={onClose}
              hideCancel={false}
              onDelete={() => {
                onClose();
                handleDelete();
              }}
              additionalActions={[
                { label: 'Action 1', onClick: () => console.info('Action 1'), type: 'primary, secondary or text' },
                ...
              ]}
            />
          )
        });
  */
  const { header, subheader, onDelete, additionalActions, hideCancel, deleteBtnText } = props;

  return (
    <div className='confirm-delete-container'>
      <FontAwesomeIcon icon={faExclamationCircle} />
      <div className='confirm-delete-message'>
        <div className='confirm-delete-header'>{header}</div>
        <div className='confirm-delete-subheader'>{subheader}</div>
      </div>
      <div className='action-btns'>
        <div className='action-btn primary' onClick={onDelete}>
          {deleteBtnText || 'Yes, delete'}
        </div>
        {additionalActions &&
          additionalActions.map((action, i) => (
            <div
              key={i}
              className={cn('action-btn', {
                primary: action.type === 'primary',
                secondary: action.type === 'secondary',
                text: action.type === 'text'
              })}
              onClick={action.onClick}
            >
              {action.label || 'Yes, delete'}
            </div>
          ))}
        {!hideCancel && (
          <div className='action-btn text' onClick={props.onCancel}>
            Cancel
          </div>
        )}
      </div>
    </div>
  );
};

ConfirmDelete.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,

  // Optional
  hideCancel: PropTypes.bool,
  deleteBtnText: PropTypes.string,
  additionalActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      type: PropTypes.oneOf(['primary', 'secondary', 'text']).isRequired
    })
  )
};

export default ConfirmDelete;
