import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './BlacklistedCodesPanel.scss';

import { addBlacklistedCode, deleteBlacklistedCode } from '../../Actions/BrandActions';
import { getBrand, getBrandBlacklistedCodes } from '../../Helpers/user_helpers';

import TagTypeInput from '../General/TagTypeInput';

const BlacklistedCodesPanel = props => {
  const { user, addBlacklistedCode, deleteBlacklistedCode } = props;
  const brand = getBrand(user);
  const blacklistedCodes = getBrandBlacklistedCodes(user);
  const tags = blacklistedCodes.map(code => code.code);

  const handleSaveTag = tags => {
    if (tags.length > blacklistedCodes.length) {
      const newTag = tags.pop();
      if (tags.includes(newTag)) return window.ALERT.error('This code is already blacklisted.');
      addBlacklistedCode(brand, newTag);
    } else {
      const deletedTag = blacklistedCodes.find(code => !tags.includes(code.code));
      if (!deletedTag) return window.ALERT.error('Failed to delete blacklisted code.');
      deleteBlacklistedCode(deletedTag);
    }
  };

  return (
    <div className='blacklisted-codes-panel-outer'>
      <div className='blacklisted-codes-panel-inner'>
        <div className='setting-container'>
          <div className='setting-label'>Blacklist Discount Codes</div>
          <div className='setting-description'>
            Add discount codes here that you want to be ignored when we process commissions. All codes are case sensitive.
          </div>
          <TagTypeInput tags={tags} handleSingleSave={handleSaveTag} showDescription={false} placeholder='New Blacklisted Code' />
        </div>
      </div>
    </div>
  );
};

BlacklistedCodesPanel.propTypes = {
  user: PropTypes.object.isRequired,
  addBlacklistedCode: PropTypes.func.isRequired,
  deleteBlacklistedCode: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  addBlacklistedCode,
  deleteBlacklistedCode
})(BlacklistedCodesPanel);
