import APIClient from './index';
import _ from 'lodash';

export const getSubscriptionOffers = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/SubscriptionOffers?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getSubscriptionBundles = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/SubscriptionBundles?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const subscribeToOffer = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/SubscriptionOffers/subscribe', data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateSubscriptionItem = (item, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/SubscriptionItems/${item.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const cancelSubscriptionItem = item => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/SubscriptionItems/${item.id}/cancel`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const logUpsellOpportunity = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/SubscriptionOffers/upsell_opportunity', data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
