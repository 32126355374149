import APIClient from './index';
import _ from 'lodash';

export const getAllDiscoverLists = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverLists?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getDiscoverListsForBrand = brand => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverLists/for_brand/${brand.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getDiscoverListPreviews = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverLists/admin_previews?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getDiscoverList = (list_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverLists/${list_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getDiscoverListMentions = (list_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverLists/${list_id}/mentions?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addDiscoverList = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverLists`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateDiscoverList = (list, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/DiscoverLists/${list.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteDiscoverList = list => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/DiscoverLists/${list.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const duplicateDiscoverList = (list, data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverLists/duplicate/${list.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addDiscoverListUser = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverListUsers`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateDiscoverListUser = (user, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/DiscoverListUsers/${user.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteDiscoverListUser = listUser => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/DiscoverListUsers/${listUser.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addDiscoverListSimilarList = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverListSimilarLists`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateDiscoverListSimilarList = (similarList, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/DiscoverListSimilarLists/${similarList.SimilarList_id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteDiscoverListSimilarList = similarList => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/DiscoverListSimilarLists/${similarList.SimilarList_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getDiscoverGroups = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverGroups?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
export const getDiscoverGroup = (group, params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/DiscoverGroups/${group.id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const createDiscoverGroup = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverGroups`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateDiscoverGroup = (group, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/DiscoverGroups/${group.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteDiscoverGroup = group => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/DiscoverGroups/${group.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const createDiscoverFeature = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/DiscoverFeatures`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateDiscoverFeature = (feature, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/DiscoverFeatures/${feature.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteDiscoverFeature = feature => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/DiscoverFeatures/${feature.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const discoverUsers = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Discover/users`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const discoverTags = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Discover/tags`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
