import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './LatestSection.scss';

import LatestSection_Template from './Sections/LatestSection_Template';
import LatestSection_Webinars from './Sections/LatestSection_Webinars';
import LatestSection_Brands from './Sections/LatestSection_Brands';
import LatestSection_Sales from './Sections/LatestSection_Sales';
import LatestSection_Products from './Sections/LatestSection_Products';
import LatestSection_Rates from './Sections/LatestSection_Rates';
import LatestSection_Shops from './Sections/LatestSection_Shops';
import LatestSection_Collections from './Sections/LatestSection_Collections';
import LatestSection_Articles from './Sections/LatestSection_Articles';

import { getDiffBetweenServerAndLocalDateStrings } from '../../Helpers/helpers';

const LatestSection = props => {
  const { section } = props;
  const { type, title, subtitle, isHidden } = section;

  let SectionItems;
  if (type === 'BRANDS') SectionItems = LatestSection_Brands;
  else if (type === 'SALES') SectionItems = LatestSection_Sales;
  else if (type === 'PRODUCTS') SectionItems = LatestSection_Products;
  else if (type === 'RATES') SectionItems = LatestSection_Rates;
  else if (type === 'WEBINARS') SectionItems = LatestSection_Webinars;
  else if (type === 'SHOPS') SectionItems = LatestSection_Shops;
  else if (type === 'COLLECTIONS') SectionItems = LatestSection_Collections;
  else if (type === 'ARTICLES') SectionItems = LatestSection_Articles;
  else SectionItems = LatestSection_Template;

  // Indicating when sections are hidden
  const badges = [];
  if (isHidden) badges.push('Hidden');

  // Indicating when items are hidden
  const getItemIndicatorBadges = (
    item,
    additionalClasses // additionalClass options =  yield, primary, white (default dark)
  ) => {
    let badges = [];

    // Show hidden badge
    if (item.isHidden) badges.push({ display: 'Hidden' });

    // Show new badge
    const isSectionNew = !section.isHidden && getDiffBetweenServerAndLocalDateStrings(section.publishedOn, props.lastViewedContent) > 0;
    const isItemNew = !item.isHidden && getDiffBetweenServerAndLocalDateStrings(item.publishedOn, props.lastViewedContent) > 0;
    const isNew = isSectionNew || isItemNew;
    if (isNew) badges.push({ display: 'New' });

    return (
      badges.length > 0 && (
        <div className={cn('item-indicators', additionalClasses)}>
          {badges.map(badge => {
            return (
              <div key={badge.display} className={cn('indicator', additionalClasses)}>
                {badge.display}
              </div>
            );
          })}
        </div>
      )
    );
  };

  // Paging
  const sectionTab = section.tabs.find(t => t.id === props.activeTab.id);
  const defaultItemsVisible = sectionTab._pivot_defaultItemsVisible;
  const [numVisible, setNumVisible] = React.useState(defaultItemsVisible);
  const showMore = () => setNumVisible(1000);
  const showLess = () => setNumVisible(defaultItemsVisible);
  const adjustedSection = { ...section, items: section.items.slice(0, numVisible) };
  const hasMore = section.items.length > defaultItemsVisible;
  const showingMore = numVisible > defaultItemsVisible;

  React.useEffect(() => {
    setNumVisible(sectionTab._pivot_defaultItemsVisible);
  }, [sectionTab.id]);

  return (
    <div className='latest-section'>
      <div className='latest-section-header-container'>
        <div className='title-container'>
          {badges.length > 0 && <div className='badges'>{badges.join('•')}</div>}
          <div className='title'>{title}</div>
          <div className='subtitle'>{subtitle}</div>
          {hasMore}
        </div>
        {hasMore && (
          <div className='paging-button'>
            <div onClick={showingMore ? showLess : showMore} className='paging-button-text'>
              {showingMore ? 'SHOW LESS' : 'VIEW ALL'}
              <FontAwesomeIcon icon={showingMore ? faChevronUp : faChevronDown} />
            </div>
          </div>
        )}
      </div>
      <SectionItems {...props} section={adjustedSection} getItemIndicatorBadges={getItemIndicatorBadges} />
    </div>
  );
};

LatestSection.propTypes = {
  user: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  activeTab: PropTypes.object.isRequired,
  lastViewedContent: PropTypes.any,
  openBrandModal: PropTypes.func.isRequired
};

export default LatestSection;
