import APIClient from './index';
import _ from 'lodash';

/**
 * api that fetches all the information about talent from a brand. You can either
 * specify the users that you are trying to target, or you can leave it blank and
 * it will fetch all the recommended talent automoatically.
 *
 * @param {string|number} Brand_id - shopmy brand that is being looked under
 * @param {Object} params
 * @param {string=} params.User_id - singular user id if you are trying to target a user
 * @param {string=} params.User_ids - string with multiple User_ids separated by commas
 */
export const getTalent = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Talent/${Brand_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getTalentPromotionDetails = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Talent/${Brand_id}/promotion_details?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// export const postData = data => {
//   return new Promise((resolve, reject) => {
//     APIClient.post(`/Template`, data)
//       .then(response => resolve(response.data))
//       .catch(error => reject(_.get(error, ['data', 'error'])));
//   });
// };
//
// export const putData = (template, data) => {
//   return new Promise((resolve, reject) => {
//     APIClient.put(`/Template/${template.id}`, data)
//       .then(response => resolve(response.data))
//       .catch(error => reject(_.get(error, ['data', 'error'])));
//   });
// };
