import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './OnboardingPanelTags.scss';

import { cleanTag } from '../../../Helpers/tag_helpers';
import { getTags } from '../../../Helpers/user_helpers';

let debouncer;
const OnboardingPanelTags = props => {
  const { onboardingTags, addTagsToUser, deleteTagsForUser, user } = props;
  const [selectedTags, setSelectedTags] = useState(getTags(user));
  const isTagSelected = tag => !!selectedTags.find(t => t.id === tag.id);
  const toggleTag = tag => {
    const tagsToAdd = isTagSelected(tag) ? _.filter(selectedTags, t => t.id !== tag.id) : [...selectedTags, tag];
    const tagsToDelete = onboardingTags.filter(tag => !tagsToAdd.find(t => t.id === tag.id));
    setSelectedTags(tagsToAdd);
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      tagsToAdd.length && addTagsToUser(tagsToAdd);
      tagsToDelete.length && deleteTagsForUser(tagsToDelete);
    }, 500);
  };

  // Group tags
  let geoTags = [];
  let generalTags = [];
  let otherTags = [];
  onboardingTags.forEach(tag => {
    if (tag.type === 'geo') geoTags.push(cleanTag(tag));
    else if (tag.type === 'general') generalTags.push(cleanTag(tag));
    else otherTags.push(cleanTag(tag));
  });

  const getTag = tag => {
    const toggle = () => toggleTag(tag);
    const selected = isTagSelected(tag);
    return (
      <div key={tag.id} onClick={toggle} className={cn('tag', { selected })}>
        {tag.value}
      </div>
    );
  };

  return (
    <div className='onboarding-panel-tags-container'>
      <div className='onboarding-header'>Get discovered by brands</div>
      <div className='onboarding-subheader'>
        Select the tags that fits you best. This will help boost your discoverablity when brands search for creators on our platform.
      </div>
      <div className='tag-sections'>
        <div className='tag-section'>
          <div className='section-header'>Where are you based?</div>
          <div className='tags'>{geoTags.map(getTag)}</div>
        </div>
        <div className='tag-section'>
          <div className='section-header'>Are you any of the following?</div>
          <div className='tags'>{generalTags.map(getTag)}</div>
        </div>
        <div className='tag-section'>
          <div className='section-header'>What best describes your content?</div>
          <div className='tags'>{otherTags.map(getTag)}</div>
        </div>
      </div>
    </div>
  );
};

OnboardingPanelTags.propTypes = {
  user: PropTypes.object.isRequired,
  onboardingTags: PropTypes.array.isRequired,
  addTagsToUser: PropTypes.func.isRequired,
  deleteTagsForUser: PropTypes.func.isRequired
};

export default OnboardingPanelTags;
