import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import icons from '../../static/icons';
import './CollectionPreviewAddNew.scss';

import FacebookSDK from '../../Components/General/FacebookSDK';
import Spotlight from '../../Components/Spotlight/Spotlight';

import { getCollaborationTypeIcon } from '../../Helpers/collaboration_helpers';
import { spotlightKeys } from '../../Helpers/spotlight_helpers';

class CollectionPreviewAddNew extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    isPost: PropTypes.bool.isRequired,
    posts: PropTypes.array.isRequired,
    openMediaSearch: PropTypes.func.isRequired,
    addCollectionToTop: PropTypes.func.isRequired
  };

  state = {
    isHovered: false
  };

  onMouseOver = () => {
    this.removeHover && clearTimeout(this.removeHover);
    !this.state.isHovered && this.setState({ isHovered: true });
  };
  onMouseOut = () => {
    this.removeHover = setTimeout(() => {
      this.setState({ isHovered: false });
    }, 50); // If you want a fade delay
  };

  render() {
    const { posts, openMediaSearch, addCollectionToTop, isPost, tabType } = this.props;
    const { isHovered } = this.state;
    if (!isPost) return null;
    return (
      <Spotlight spotlightKey={spotlightKeys.CREATE_POST}>
        <div onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} className={cn('profile-element-preview-shell-container posts', tabType)}>
          <FacebookSDK />
          <div className={cn('collection-post-preview-container fluid add-new', { hovered: isHovered })}>
            <div className='add-new-header'>ADD {posts.length ? 'NEW' : 'FIRST'} POST</div>
            <div className='add-new-types'>
              <div className='add-new-type' onClick={() => openMediaSearch('instagram')}>
                <img className='social-icn' src={getCollaborationTypeIcon('instagram')} alt='Instagram Logo' />
                <div className='label'>Instagram</div>
              </div>
              <div className='add-new-type' onClick={() => openMediaSearch('youtube')}>
                <img className='social-icn' src={icons.social.youtube.white} alt='Youtube Logo' />
                <div className='label'>Youtube</div>
              </div>
              <div className='add-new-type' onClick={() => openMediaSearch('tiktok')}>
                <img className='social-icn' src={getCollaborationTypeIcon('tiktok')} alt='Tiktok Logo' />
                <div className='label'>Tiktok</div>
              </div>
              <div className='add-new-type' onClick={addCollectionToTop}>
                <FontAwesomeIcon icon={faPlus} />
                <div className='label'>Manual</div>
              </div>
            </div>
            <div className='add-new-header-cover'>
              <FontAwesomeIcon icon={faPlus} />
              <div className='label'>ADD {posts.length ? 'NEW' : 'FIRST'} POST</div>
            </div>
          </div>
        </div>
      </Spotlight>
    );
  }
}

export default CollectionPreviewAddNew;
