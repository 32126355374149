import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import SectionSelectionModal from '../Components/General/SectionSelectionModal';

export const getSectionTemplates = existingSections => {
  return [
    {
      display: 'Gift Guides',
      section: {
        previewType: 'editorial',
        title: 'Gift Guides'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('gift'))
    },
    {
      display: 'Shelves',
      section: {
        previewType: 'shelf',
        title: 'Shelves'
      },
      isVisible: !existingSections.find(s => s.previewType === 'shelf')
    },
    {
      display: 'Fashion',
      section: {
        previewType: 'editorial',
        title: 'Closet'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('loset'))
    },
    {
      display: 'Home',
      section: {
        previewType: 'editorial',
        title: 'Home'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('home'))
    },
    {
      display: 'Instagram Feed',
      section: {
        previewType: 'post',
        title: 'Instagram',
        socialSources: 'instagram'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('instagram'))
    },
    {
      display: 'Tiktok Feed',
      section: {
        previewType: 'post_vertical',
        title: 'Tiktok',
        socialSources: 'tiktok'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('tiktok'))
    },
    {
      display: 'Youtube Feed',
      section: {
        previewType: 'post_horizontal',
        title: 'Youtube',
        socialSources: 'youtube'
      },
      isVisible: !existingSections.find(s => s.title?.toLowerCase().includes('youtube'))
    },
    {
      display: 'Custom Section',
      section: {
        previewType: 'shelf'
      },
      isVisible: true
    }
  ].filter(s => s.isVisible);
};

export const getSectionPreviewTypeTemplates = () => {
  return [
    {
      title: 'Shelves',
      subtitle: "Perfect for beauty, skincare or other products you'd typically keep on a shelf.",
      previewType: 'shelf'
    },
    // {
    //   title: 'Large Preview',
    //   subtitle: 'Description TBD',
    //   previewType: 'large_preview',
    {
      title: 'Editorial',
      subtitle: 'Used for content with longer descriptions such as gift guides.',
      previewType: 'editorial'
    },
    {
      title: 'Square Posts',
      subtitle: 'Resembles a standard Instagram page.',
      previewType: 'post'
    },
    {
      title: 'Vertical Posts',
      subtitle: 'Used for video content such as Tiktok or Instagram Reels.',
      previewType: 'post_vertical'
    },
    {
      title: 'Horizontal Posts',
      subtitle: 'Resembles the video formatting on Youtube.',
      previewType: 'post_horizontal'
    }
  ];
};

export const selectSectionFromModal = (sections, { header, subheader }) =>
  new Promise(resolve =>
    confirmAlert({
      customUI: ({ onClose }) => {
        const selectSection = section => {
          onClose();
          resolve(section);
        };
        const closeModal = () => {
          onClose();
          resolve(null);
        };
        return (
          <SectionSelectionModal header={header} subheader={subheader} sections={sections} closeModal={closeModal} selectSection={selectSection} />
        );
      }
    })
  );
