import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './Image.scss';

const Image = props => {
  const { src, alt, className, failedText = 'N/A', useGenericFailedStyles, useGenericImageStyles } = props;
  const [imageFailed, setImageFailed] = useState(false);

  const onClick = props.onClick ? props.onClick : () => {};

  return imageFailed || !src ? (
    <div
      onClick={onClick}
      style={{ pointerEvents: 'none' }}
      className={cn(className, 'no-image', 'safe-image-container', { 'use-generic-styles': useGenericFailedStyles })}
    >
      {failedText}
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      onClick={onClick}
      className={cn(className, 'safe-image-container', { 'use-generic-styles': useGenericImageStyles })}
      onError={() => setImageFailed(true)}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  failedText: PropTypes.string,
  onClick: PropTypes.func,
  useGenericFailedStyles: PropTypes.bool,
  useGenericImageStyles: PropTypes.bool
};

export default Image;
