import { getChats as getChatsAPI, getChat as getChatAPI, updateChat as updateChatAPI, deleteChat as deleteChatAPI } from '../APIClient/chats';

export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_ERROR = 'GET_CHATS_ERROR';
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS';
export const UPDATE_CHAT_ERROR = 'UPDATE_CHAT_ERROR';
export const DELETE_CHAT_REQUEST = 'DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_ERROR = 'DELETE_CHAT_ERROR';
export const STATUS_UPDATE = 'STATUS_UPDATE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const EDIT_MESSAGE = 'EDIT_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const TYPING_MESSAGE = 'TYPING_MESSAGE';

export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const SET_ACTIVE_POTENTIAL_PARTNER = 'SET_ACTIVE_POTENTIAL_PARTNER';

export const getChats = params => async (dispatch, getState) => {
  try {
    const resp = await getChatsAPI(params);
    return dispatch({
      type: GET_CHATS_SUCCESS,
      chats: resp.chats,
      potentialPartners: resp.potentialPartners
    });
  } catch (error) {
    return dispatch({
      type: GET_CHATS_ERROR
    });
  }
};

export const updateChat = (chat, updates) => async (dispatch, getState) => {
  try {
    const newChat = await updateChatAPI(chat, updates);
    return dispatch({
      type: UPDATE_CHAT_SUCCESS,
      chat: newChat
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CHAT_ERROR
    });
  }
};

export const deleteChat = chat => async (dispatch, getState) => {
  dispatch({
    type: DELETE_CHAT_REQUEST,
    chat
  });
  try {
    await deleteChatAPI(chat);
    return dispatch({
      type: DELETE_CHAT_SUCCESS,
      chat
    });
  } catch (error) {
    return dispatch({
      type: DELETE_CHAT_ERROR
    });
  }
};

export const receivedMessage = data => async (dispatch, getState) => {
  return dispatch({
    data,
    type: ADD_MESSAGE
  });
};

export const editedMessage = data => async (dispatch, getState) => {
  return dispatch({
    data,
    type: EDIT_MESSAGE
  });
};

export const deletedMessage = data => async (dispatch, getState) => {
  return dispatch({
    data,
    type: DELETE_MESSAGE
  });
};

export const typedMessage = data => async (dispatch, getState) => {
  return dispatch({
    data,
    type: TYPING_MESSAGE
  });
};

export const setActiveChat = chat => async (dispatch, getState) => {
  dispatch({
    type: SET_ACTIVE_CHAT,
    chat
  });
  const resp = await getChatAPI(chat.id);
  return dispatch({
    type: SET_ACTIVE_CHAT,
    chat: resp.chat
  });
};

export const setActivePotentialPartner = partner => async (dispatch, getState) => {
  return dispatch({
    type: SET_ACTIVE_POTENTIAL_PARTNER,
    partner
  });
};
