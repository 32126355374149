import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import './LatestSection_Articles.scss';

const LatestSection_Articles = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section articles'>
      {items.map(item => {
        const { id, blog_post } = item;
        const title = item.title || blog_post?.title;
        const subtitle = item.subtitle || blog_post?.subtitle;
        const image = item.image || blog_post?.coverImage;
        const url = item.url || (blog_post ? `/blog/${blog_post.titleStub}` : null);
        const actionDisplay = blog_post ? 'View Blog' : 'View More';
        const isExternal = !blog_post && url?.includes('http');

        const content = (
          <>
            {image && <img className='background-image' src={image} alt={title} />}
            <div className='background' />
            <div className='meta'>
              <div className='title-container'>
                <div className='title'>{title}</div>
                <div className='subtitle'>{subtitle}</div>
                <div className='action-display'>
                  {actionDisplay}
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            </div>
            {props.getItemIndicatorBadges(item, 'primary')}
          </>
        );

        return isExternal ? (
          <a href={url} key={id} className='article' target='_blank' rel='noopener noreferrer'>
            {content}
          </a>
        ) : (
          <Link to={url} key={id} className='article'>
            {content}
          </Link>
        );
      })}
    </div>
  );
};

LatestSection_Articles.propTypes = {
  section: PropTypes.object.isRequired
};

export default LatestSection_Articles;
