import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import classnames from 'classnames';
import './EmailPanel.scss';

import { isValidEmail } from '../../Helpers/formatting';
import { hasJoinedEmailList } from '../../Helpers/ui_helpers';
import { joinMailingList } from '../../APIClient/users';
import { addEvent } from '../../APIClient/events';

class EmailPanel extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    setJoinedMailingList: PropTypes.func.isRequired,
    inFooter: PropTypes.bool
  };

  state = {
    emailVal: '',
    isSigningUp: false
  };

  submitEmail = event => {
    const { emailVal, isSigningUp } = this.state;
    event.stopPropagation();
    event.preventDefault();
    if (isSigningUp) return;
    const isValid = isValidEmail(emailVal);
    if (!isValid) {
      cogoToast.error('Please input a valid email.');
      return;
    }
    this.setState({ isSigningUp: true });
    joinMailingList({ email: emailVal })
      .then(
        resp => {
          this.props.setJoinedMailingList(true);
          addEvent('Home - Join Mailing List', { email: emailVal });
        },
        err => {
          cogoToast.error(_.get(err, 'error') || 'There was an issue signing up with that email, please try again.');
          addEvent('Home - Failure Joining Mailing List', {
            error: err,
            email: emailVal
          });
        }
      )
      .finally(() => {
        this.setState({ isSigningUp: false });
      });
  };

  render() {
    const { ui, inFooter } = this.props;
    const { emailVal, isSigningUp } = this.state;

    return (
      <div className={classnames('email-container', { footer: inFooter })}>
        {!hasJoinedEmailList(ui) ? (
          <form onSubmit={this.submitEmail}>
            <input type='email' placeholder='Email' onChange={({ target }) => this.setState({ emailVal: target.value })} value={emailVal} />
            <button onClick={this.submitEmail} className='submit-btn'>
              {isSigningUp ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
            </button>
          </form>
        ) : (
          <div onClick={() => this.props.setJoinedMailingList(false)} className='join-again'>
            You're subscribed! Click to join with another email
          </div>
        )}
      </div>
    );
  }
}

export default EmailPanel;
