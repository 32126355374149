import APIClient from './index';
import _ from 'lodash';

export const getTags = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Tags?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getOnboardingTags = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Tags/onboarding`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getTagsForOpportunities = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Tags/for_opportunities?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addTagsToUser = (tags, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`UserTags/multiple_for_user`, { tagids: tags.map(t => t.id), User_id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteTagsForUser = (tags, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`UserTags/delete_multiple_for_user`, { tagids: tags.map(t => t.id), User_id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
