import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import './HomeCTA.scss';

import icons from '../../../static/icons';
import { CTA_TITLES, CTA_SUBTITLES } from '../../../Helpers/home_helpers';

const HomeCTA = props => {
  const { ctas, isBrandCTA } = props;

  const to = _.last(window.location.pathname.split('/'));
  const title = CTA_TITLES[isBrandCTA ? 'brand' : 'creator'][to] || CTA_TITLES[isBrandCTA ? 'brand' : 'creator'].home;
  const subtitle = CTA_SUBTITLES[isBrandCTA ? 'brand' : 'creator'][to] || CTA_SUBTITLES[isBrandCTA ? 'brand' : 'creator'].home;

  const other_ctas = ctas.filter(cta => cta.to !== window.location.pathname);

  const ctaSection = (
    <div className='ctas'>
      {other_ctas.map((cta, index) => {
        return (
          <Link to={cta.to} key={index} className={cn('cta')}>
            <div className='title'>{cta.title}</div>
            <img src={icons.misc.arrowRight} alt='Arrow Right' />
          </Link>
        );
      })}
    </div>
  );

  const applySection = props.openApplyModal && (
    <div onClick={props.openApplyModal} className='demo-btn'>
      {isBrandCTA ? 'Request a Demo' : 'Apply Now'}
    </div>
  );

  return (
    <div className='home-cta-outer-container'>
      <div className='home-cta-inner-container'>
        <div className='main-statement'>
          <h4 className='title'>{title}</h4>
        </div>
        <div className='secondary'>
          {isBrandCTA ? (
            <>
              {applySection}
              <div className='spacer' />
              <div className='badge'>{subtitle}</div>
              {ctaSection}
            </>
          ) : (
            <>
              <div className='badge'>{subtitle}</div>
              {ctaSection}
              <div className='or'>OR</div>
              {applySection}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

HomeCTA.propTypes = {
  ctas: PropTypes.array.isRequired,
  openApplyModal: PropTypes.func.isRequired,

  // Optional
  isBrandCTA: PropTypes.bool
};

export default HomeCTA;
