import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './LatestSection_Brands.scss';

import { getDisplayForCtaType } from '../../../Helpers/latest_helpers';

const LatestSection_Brands = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section brands'>
      {items.map(item => (
        <Brand item={item} {...props} key={item.id} />
      ))}
    </div>
  );
};

const Brand = props => {
  const { item } = props;
  const { brand } = item;
  if (!brand) return null;

  const openBrand = () => props.openBrandModal({ brand });
  const coverImage = item.image || brand.mobileCoverImage;
  return (
    <div onClick={openBrand} className='brand'>
      <div className='header-section'>{coverImage ? <img src={coverImage} alt={brand.name} /> : <div className='empty' />}</div>
      <div className='body-section'>
        <div className='main'>
          <div className='title'>{brand.name}</div>
          <a href={'https://' + brand.domain} target='_blank' rel='noopener noreferrer' className='domain'>
            {brand.domain}
          </a>
          <div className='description'>{brand.description}</div>
        </div>
        <div className='action'>
          {getDisplayForCtaType(item.ctaType)}
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      {props.getItemIndicatorBadges(item, 'primary')}
    </div>
  );
};

LatestSection_Brands.propTypes = {
  section: PropTypes.object.isRequired,
  openBrandModal: PropTypes.func.isRequired
};

export default LatestSection_Brands;
