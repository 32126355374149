import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SingleDatePicker, DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './DateSelector.scss';

const DateSelector = props => {
  return props.isSingleDate ? <SingleDateSelector {...props} /> : <DateRangeSelector {...props} />;
};

const SingleDateSelector = props => {
  const { date, onDateChange } = props;
  const [focused, setFocused] = useState(false);
  return (
    <div className='single-date-selector date-selector'>
      <SingleDatePicker
        noBorder
        placeholder={props.placeholder || 'Select a date'}
        displayFormat={props.displayFormat || 'MMMM Do, YYYY'}
        date={date}
        onDateChange={onDateChange}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id='date-picker'
      />
    </div>
  );
};

const DateRangeSelector = props => {
  const { startDate, endDate, onDateRangeChange, allowAllDates } = props;
  const [focusedInput, setFocusedInput] = useState(false);
  return (
    <div className='date-range-selector date-selector'>
      <DateRangePicker
        noBorder
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
        displayFormat={props.displayFormat || 'MMMM Do, YYYY'}
        onDatesChange={({ startDate, endDate }) => onDateRangeChange(startDate, endDate)}
        isOutsideRange={day => (allowAllDates ? false : isInclusivelyBeforeDay(day, moment()))}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        minimumNights={0}
      />
    </div>
  );
};

DateSelector.propTypes = {
  isSingleDate: PropTypes.bool,
  displayFormat: PropTypes.string
};

SingleDateSelector.propTypes = {
  date: PropTypes.object,
  onDateChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

DateRangeSelector.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDateRangeChange: PropTypes.func.isRequired,

  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  allowAllDates: PropTypes.bool
};

export default DateSelector;
