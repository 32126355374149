import React from 'react';
import cogoToast from 'cogo-toast';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './ListMentions.scss';

import { updateMention } from '../../APIClient/mentions';

import { getBrandId } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

import MentionResult from '../Mentions/MentionResult';

const ListMentions = props => {
  const { user, ui, mentions } = props;

  if (!mentions.length) return null;

  let columns = [[], [], []];
  const columnChunks = _.chunk(mentions, 3);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0]?.push(chunk[0]);
    chunk[1] && columns[1]?.push(chunk[1]);
    chunk[2] && columns[2]?.push(chunk[2]);
  });

  const removeMentionIfAllowedTo = isAdminControlMode(ui)
    ? async mention => {
        const confirm = window.confirm('Are you sure you want to remove this mention?');
        if (confirm) {
          await updateMention(mention, { isHidden: true });
          cogoToast.success('Mention Removed');
          props.syncList();
        }
      }
    : null;

  const goToSocialMentionsFeed = () => {
    props.ensureLoggedInThenCall(() => {
      props.history.push('/mentions');
    });
  };

  return (
    <div className='list-mentions-container'>
      <div className='section-header-container'>
        <div className='section-header'>See them in Action</div>
        <div className='section-subheader'>
          {props.isBrandList
            ? `A quick peek into the content these creators are posting about your brand`
            : `A quick peek into the brands these creators are talking about`}
        </div>
      </div>
      <div className='mention-results-columns'>
        {columns.map((column, columnIdx) => {
          return (
            <div key={columnIdx} className='mention-results-column'>
              {column.map((mention, idx) => {
                return (
                  <MentionResult
                    key={mention.id}
                    mention={mention}
                    user={user}
                    ui={ui}
                    curFilters={{ Brand_id: getBrandId(user) }}
                    openArtistModal={props.openArtistModal}
                    openChatOverlay={props.openChatOverlay}
                    openAuthModal={props.openAuthModal}
                    removeMentionIfAllowedTo={removeMentionIfAllowedTo}
                  />
                );
              })}
            </div>
          );
        })}
        <div onClick={goToSocialMentionsFeed} className='view-more-mention-results'>
          <div className='alert'>
            View all mentions in your social mentions feed
            <FontAwesomeIcon icon={faExternalLink} className='arrow-right' />
          </div>
        </div>
      </div>
    </div>
  );
};

ListMentions.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isBrandList: PropTypes.bool,
  syncList: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  ensureLoggedInThenCall: PropTypes.func.isRequired
};

export default withRouter(ListMentions);
