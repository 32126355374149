import { useEffect } from 'react';
import { isBrand } from '../../Helpers/user_helpers';

const ShopGuide = props => {
  useEffect(() => {
    window.location.href = isBrand(props.user) ? 'https://brands.shopmy.us' : 'https://guide.shopmy.us';
  }, []);
  return null;
};

export default ShopGuide;
