import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ShopSectionsModal.scss';

import Modal from '../General/Modal';
import ShopSections from './ShopSections';
import EditShopSection from './EditShopSection';

import { editCollection } from '../../Actions/StoreActions';
import { addSection, deleteSection, updateSection } from '../../Actions/SectionActions';
import { getSections, getCollections } from '../../Helpers/user_helpers';
import { selectSectionFromModal } from '../../Helpers/section_helpers';

const ShopSectionsModal = props => {
  const { initConfig, sectionsUser, updateSection, addSection } = props;

  const [activeSectionId, setActiveSectionId] = useState(initConfig.Section_id);

  // We need to grab from the sectionsUser object and not the user object because admins can manage anyones shop
  const sections = getSections(sectionsUser);
  const selectedSection = sections.find(s => s.id === activeSectionId);
  const clearActiveSection = () => setActiveSectionId(null);

  // Delete requires custom checking to ensure the section is empty
  const deleteSection = async section => {
    const remainingSections = sections.filter(s => s.id !== section.id);
    const collections = getCollections(sectionsUser).filter(c => c.Section_id === section.id);
    const collectionsArchived = collections.filter(s => s.isArchived);
    const hasCollections = collections.length;

    if (!remainingSections.length) return cogoToast.error(`You must have at least one section.`);

    if (hasCollections) {
      const migratingToSection = await selectSectionFromModal(remainingSections, {
        header: 'Need to migrate collections.',
        subheader: `We found ${collections.length} collection${collections.length === 1 ? '' : 's'} that are currently in this section${
          collectionsArchived.length ? ` or in the archive with this section selected` : ''
        }. Where would you like to move those?`
      });

      if (!migratingToSection) return;

      let hadErrorUploading = false;
      for (let [idx, collection] of collections.entries()) {
        const resp = await props.editCollection(collection.id, { Section_id: migratingToSection.id });
        if (resp?.collection) {
          cogoToast.success(
            `${idx + 1}/${collections.length} - Moved ${collection.name || 'collection'} to ${migratingToSection.title.toUpperCase() ||
              'new section.'}`,
            { hideAfter: 0.5 }
          );
          const hasManyCollections = collections.length > 10;
          await new Promise(r => setTimeout(r, hasManyCollections ? 50 : 200));
        } else {
          hadErrorUploading = true;
          cogoToast.error(`Error Moving ${collection.name || 'collection'} to ${migratingToSection.title || 'new section.'}. Please try again.`, {
            hideAfter: 5
          });
        }
      }
      if (!hadErrorUploading) {
        props.deleteSection(section);
      }
    } else {
      confirmAlert({
        title: 'Just Confirming',
        message: 'Are you sure you would like to remove this from your shop?',
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => {}
          },
          {
            label: 'Delete',
            onClick: () => {
              props.deleteSection(section);
            }
          }
        ]
      });
    }
  };

  // Force reload on shop section change to allow for componentDidMount on EditShopSection
  const [forceEditReload, setForceEditReload] = useState(false);
  useEffect(() => {
    if (activeSectionId) {
      setForceEditReload(true);
      setTimeout(() => setForceEditReload(false), 50);
    }
  }, [activeSectionId]);

  const additionalClasses = { 'section-selected': !!selectedSection };
  return (
    <Modal visible close={props.close} innerClassName='shop-sections-modal-outer-container' showClose>
      <div className='shop-sections-modal-inner-container'>
        <div className={cn('sidebar', additionalClasses)}>
          <ShopSections
            sectionsUser={sectionsUser}
            sections={sections}
            addSection={addSection}
            deleteSection={deleteSection}
            updateSection={updateSection}
            activeSectionId={activeSectionId}
            setActiveSectionId={setActiveSectionId}
          />
        </div>
        <div className={cn('main-panel', additionalClasses)}>
          {!forceEditReload && (
            <EditShopSection
              close={props.close}
              section={selectedSection}
              addSection={addSection}
              deleteSection={deleteSection}
              updateSection={updateSection}
              clearActiveSection={clearActiveSection}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

ShopSectionsModal.propTypes = {
  addSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  editCollection: PropTypes.func.isRequired,

  // From Outer
  sectionsUser: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  initConfig: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  addSection,
  deleteSection,
  updateSection,
  editCollection
})(ShopSectionsModal);
