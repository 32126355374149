import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import './AddPinModalLanding.scss';

import searchCatalogBtnBeauty from '../../../static/images/add_pin_modal/search_catalog_beauty.png';
import searchCatalogBtnFashion from '../../../static/images/add_pin_modal/search_catalog_fashion.png';
import searchCatalogBtnSkincare from '../../../static/images/add_pin_modal/search_catalog_skincare.png';
import addByUrlBtnBeauty from '../../../static/images/add_pin_modal/add_by_url_beauty.png';
import addByUrlBtnFashion from '../../../static/images/add_pin_modal/add_by_url_fashion.png';
import addByUrlBtnSkincare from '../../../static/images/add_pin_modal/add_by_url_skincare.png';
import searchHighestRatesBtnBeauty from '../../../static/images/add_pin_modal/search_rates_beauty.png';
import searchHighestRatesBtnFashion from '../../../static/images/add_pin_modal/search_rates_fashion.png';
import searchHighestRatesBtnSkincare from '../../../static/images/add_pin_modal/search_rates_skincare.png';
import searchPreviousConsultsBtnBeauty from '../../../static/images/add_pin_modal/search_consults_beauty.png';
import searchPreviousConsultsBtnFashion from '../../../static/images/add_pin_modal/search_consults_fashion.png';
import searchPreviousConsultsBtnSkincare from '../../../static/images/add_pin_modal/search_consults_skincare.png';
import searchYourProductsBtnBeauty from '../../../static/images/add_pin_modal/search_shop_beauty.png';
import searchYourProductsBtnFashion from '../../../static/images/add_pin_modal/search_shop_fashion.png';
import searchYourProductsBtnSkincare from '../../../static/images/add_pin_modal/search_shop_skincare.png';

import SnapshopExplainerPanel from '../../Partners/SnapshopExplainerPanel';

import { getUserCategoryType, hasUsedSnapShop } from '../../../Helpers/user_helpers';

const AddPinModalLanding = props => {
  const { user, setMode, lockedCatalogBrand, Editing_ConsultResult_id } = props;
  /*
    Select which type of user to customize this panel.
  
    These tags should remain in sync with the onboarding tags.
  */
  const { searchCatalogBtn, addByUrlBtn, searchHighestRatesBtn, searchPreviousConsultsBtn, searchYourProductsBtn } = {
    Beauty: {
      searchCatalogBtn: searchCatalogBtnBeauty,
      addByUrlBtn: addByUrlBtnBeauty,
      searchHighestRatesBtn: searchHighestRatesBtnBeauty,
      searchPreviousConsultsBtn: searchPreviousConsultsBtnBeauty,
      searchYourProductsBtn: searchYourProductsBtnBeauty
    },
    Fashion: {
      searchCatalogBtn: searchCatalogBtnFashion,
      addByUrlBtn: addByUrlBtnFashion,
      searchHighestRatesBtn: searchHighestRatesBtnFashion,
      searchPreviousConsultsBtn: searchPreviousConsultsBtnFashion,
      searchYourProductsBtn: searchYourProductsBtnFashion
    },
    Skincare: {
      searchCatalogBtn: searchCatalogBtnSkincare,
      addByUrlBtn: addByUrlBtnSkincare,
      searchHighestRatesBtn: searchHighestRatesBtnSkincare,
      searchPreviousConsultsBtn: searchPreviousConsultsBtnSkincare,
      searchYourProductsBtn: searchYourProductsBtnSkincare
    }
  }[getUserCategoryType(user)];

  const getRightArrow = () => (
    <div className='arrow-right'>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
  const getBadge = text => <div className='badge'>{text}</div>;

  return (
    <div className='add-pin-modal-landing-container'>
      <div className='set-mode-header'>
        <div>Add New Product</div>
        <FontAwesomeIcon onClick={props.closeModal} icon={faTimes} />
      </div>
      <div className='set-mode-options'>
        <div className='set-mode-option-container'>
          <div className='set-mode-option button' onClick={() => setMode('search')}>
            {getBadge('Most Popular')}
            <div className='image-container'>
              <img alt='Pin Mode' src={searchCatalogBtn} />
            </div>
            <div className='body-container'>
              <div className='body'>
                <div className='header'>Search Catalog</div>
                <div className='subheader'>
                  {lockedCatalogBrand
                    ? `Search the ${lockedCatalogBrand.name} catalog`
                    : 'Search millions of products in beauty, fashion, home decor, and more'}
                </div>
              </div>
              {getRightArrow()}
            </div>
          </div>
        </div>
        <div className='set-mode-option-container'>
          <div className='set-mode-option button' onClick={() => setMode('url')}>
            <div className='image-container'>
              <img alt='URL Mode' src={addByUrlBtn} />
            </div>
            <div className='body-container'>
              <div className='body'>
                <div className='header'>Add By URL</div>
                <div className='subheader'>
                  Copy and paste a product URL from {lockedCatalogBrand ? `the ${lockedCatalogBrand.name} website` : 'any online retailer'}
                </div>
              </div>
              {getRightArrow()}
            </div>
          </div>
        </div>
        {!lockedCatalogBrand && (
          <div className='set-mode-option-container'>
            <div className='set-mode-option button' onClick={() => setMode('rates')}>
              <div className='image-container wide'>
                <img alt='Rates Mode' src={searchHighestRatesBtn} />
              </div>
              <div className='body-container'>
                <div className='body'>
                  <div className='header'>Search Commission Rates</div>
                  <div className='subheader'>Find retailers with the highest commission rates</div>
                </div>
                {getRightArrow()}
              </div>
            </div>
          </div>
        )}
        {Editing_ConsultResult_id ? (
          <div className='set-mode-option-container'>
            <div className='set-mode-option button' onClick={() => setMode('consultPins')}>
              <div className='image-container'>
                <img alt='Consults Mode' src={searchPreviousConsultsBtn} />
              </div>
              <div className='body-container'>
                <div className='body'>
                  <div className='header'>Search Previous Consultations</div>
                  <div className='subheader'>Search your previous consult responses</div>
                </div>
                {getRightArrow()}
              </div>
            </div>
          </div>
        ) : (
          <div className='set-mode-option-container'>
            <div className='set-mode-option button' onClick={() => setMode('pin')}>
              <div className='image-container wide'>
                <img alt='Your Products Mode' src={searchYourProductsBtn} />
              </div>
              <div className='body-container'>
                <div className='body'>
                  <div className='header'>Search Your Products</div>
                  <div className='subheader'>Search your most frequently added products</div>
                </div>
                {getRightArrow()}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isMobile && !hasUsedSnapShop(user) && (
        <div className='set-mode-footer'>
          <div className='snapshop-explainer'>
            <SnapshopExplainerPanel user={user} />
          </div>
        </div>
      )}
    </div>
  );
};

AddPinModalLanding.propTypes = {
  user: PropTypes.object.isRequired,
  setMode: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  lockedCatalogBrand: PropTypes.string,
  Editing_ConsultResult_id: PropTypes.number
};

export default AddPinModalLanding;
