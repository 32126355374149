import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import './LookbookOrderDetails.scss';

import { getLookbook, getLookbookOrder } from '../../APIClient/lookbooks';

import { getBrand, getUserId, getUsername } from '../../Helpers/user_helpers';
import { calculateOrderTotalItems, calculateOrderTotalPrice, getLookbookOrderStatusDisplay } from '../../Helpers/lookbook_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { copyToClipboard } from '../../Helpers/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

import UserLookbookOrderDetailsItems from '../../Components/Lookbooks/LookbookOrderDetails/UserLookbookOrderDetailsItems';
import UpdateLookbookOrderModal from '../../Components/Lookbooks/LookbookOrderDetails/UpdateLookbookOrderModal';
import Loader from '../../Components/Loader/Loader';
import ScrollToTop from '../../Components/General/ScrollToTop';

const LookbookOrderDetails = props => {
  const { user, ui } = props;
  const { LookbookOrder_id } = useParams();
  const history = useHistory();
  const lastLocation = useLastLocation();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [lookbook, setLookbook] = useState(null);
  const [updateLookbookOrderModalIsOpen, setUpdateLookbookOrderModalIsOpen] = useState(false);
  const openLookbookOrderModal = () => setUpdateLookbookOrderModalIsOpen(true);
  const closeLookbookOrderModal = () => setUpdateLookbookOrderModalIsOpen(false);

  useEffect(() => {
    const fetchLookbookData = async () => {
      setLoading(true);

      let lookbookOrder;
      try {
        lookbookOrder = await getLookbookOrder(LookbookOrder_id);
        setOrder(lookbookOrder);
      } catch (e) {
        cogoToast.error(e?.message || 'Could not fetch lookbook order');
      }

      if (lookbookOrder?.Lookbook_id) {
        try {
          const data = { returnAllSiblings: true };
          const lookbookResponse = await getLookbook(lookbookOrder.Lookbook_id, data);
          setLookbook(lookbookResponse.lookbook);
        } catch (e) {
          cogoToast.error(e?.message || 'Could not fetch lookbook order');
        }
      }

      setLoading(false);
    };

    fetchLookbookData();
  }, []);

  const brand = order?.brand;

  const brandIsViewingPage = !!(getBrand(user)?.id === brand?.id || (getBrand(user)?.id && isAdminControlMode(ui)));
  const userIsViewingPage = !!(getUserId(user) === order?.user?.id || (getUserId(user) && isAdminControlMode(ui)));
  const pageIsAuthorized = brandIsViewingPage || userIsViewingPage || isAdminControlMode(ui);

  const shopifyMustFulfillOrder = order?.shopifyOrderId;
  const brandMustFulfillOrder = order?.isManuallyShipped;
  const shopmyMustFulfillOrder = !shopifyMustFulfillOrder && !brandMustFulfillOrder;

  const lookbookStatus = order?.id ? getLookbookOrderStatusDisplay(order) : null;
  const totalItemCount = calculateOrderTotalItems(order?.items);
  const totalPrice = calculateOrderTotalPrice(order?.items);
  const Brand_name = brand?.name;
  const expectedDeliveryDateFormat = 'MMMM Do, YYYY';
  const shopifyOrderId = order?.shopifyOrderId;
  const shouldShowShopifyOrderId = shopifyOrderId && (brandIsViewingPage || isAdminControlMode(ui));

  const { address, trackingNumber, trackingUrl, expectedDeliveryDate, items, trackingSource } = order || {};
  const { firstName, lastName, name, address1, address2, city, state, zip, ward, prefecture, country } = address ? JSON.parse(address) : {};

  const { coverImage, title } = lookbook || {};
  const { mobileCoverImage } = brand || {};

  const updateLookbookOrderCallback = updates => setOrder({ ...order, ...updates });

  const headBack = () => {
    const brandPage = '/gifting';
    const userPage = '/partners';

    if (brandIsViewingPage && lastLocation?.pathname?.includes(brandPage)) return history.goBack();
    else if (userIsViewingPage && lastLocation?.pathname?.includes(userPage)) return history.goBack();
    else return history.push(brandIsViewingPage ? '/lookbooks' : '/partners/gifting');
  };

  if (!pageIsAuthorized || (!loading && !order)) {
    return (
      <div className='lookbook-order-details-outer'>
        <div className='lookbook-order-details-inner'>
          <div className='lookbook-order-details-no-permission'>You do not have permission to view this page</div>
        </div>
      </div>
    );
  }

  return (
    <div className='lookbook-order-details-outer'>
      <ScrollToTop />
      <div className='lookbook-order-details-inner'>
        {loading ? (
          <div className='loading-container'>
            <Loader />
          </div>
        ) : (
          <>
            <div className='lookbook-header-outer'>
              {coverImage && <div className='cover-image-background'></div>}
              <img src={coverImage || mobileCoverImage} alt={title || 'Deleted Lookbook'} />
              <div className='lookbook-header-inner'>
                <div className='lookbook-header-text-container'>
                  <div className='backlink'>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    <div onClick={headBack}>back to my gifting portal</div>
                  </div>
                  <div className='lookbook-title'>{title}</div>
                </div>
              </div>
            </div>

            <div className='order-container'>
              <div className='order-status-container'>
                <div>
                  <div className='order-status-container-header'>Order Status</div>
                  <div className='order-status-container-value'>
                    {parseInt(totalItemCount) ? (
                      <>
                        {totalItemCount} {totalItemCount === 1 ? 'item' : 'items'}
                      </>
                    ) : null}
                    {!!lookbook?.price_limit && parseInt(totalPrice) && <>&nbsp;&nbsp;•&nbsp;&nbsp; ${totalPrice}</>}
                  </div>
                  <div className='order-status-container-value order-update'>
                    {!_.isNil(lookbookStatus) && (
                      <>
                        <FontAwesomeIcon icon={lookbookStatus.icon} />
                        {lookbookStatus.display}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className='order-status-container-header'>Shipping To</div>
                  <div className='order-status-container-value' id='order-name'>
                    {!!firstName && !!lastName ? `${firstName} ${lastName}` : name}
                  </div>
                  <div className='order-status-container-value'>
                    <div>
                      {address1}
                      {address2 ? `, ${address2}` : ''}
                    </div>
                    <div>
                      {city}, {state} {zip}
                    </div>
                    <div>
                      {ward ? `${ward}, ` : ''}
                      {prefecture ? `${prefecture}` : ''}
                    </div>
                    <div>{country}</div>
                  </div>
                </div>
                <div>
                  <div className='order-status-container-header'>Shipping Details</div>
                  {trackingNumber ? (
                    <>
                      {trackingUrl ? (
                        <a className='order-status-container-value' href={trackingUrl} target='_blank' rel='noopener noreferrer'>
                          {trackingSource ? `${trackingSource} ` : `${trackingUrl}`}
                          <FontAwesomeIcon icon={faExternalLink} />
                        </a>
                      ) : (
                        <>
                          <div className='order-status-container-subheader'>Tracking Number</div>
                          <div className='order-status-container-value'>{trackingNumber}</div>
                        </>
                      )}
                      {expectedDeliveryDate && (
                        <>
                          <div className='order-status-container-subheader'>Estimated Delivery</div>
                          <div className='order-status-container-value'>{moment(expectedDeliveryDate).format(expectedDeliveryDateFormat)}</div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className='order-status-container-value'>No Tracking Information Yet Available</div>
                  )}
                </div>
                <div>
                  <div className='order-status-container-header'>Actionables</div>
                  <div className='order-status-container-value'>
                    {brandIsViewingPage && brandMustFulfillOrder ? (
                      <div className='order-status-container-button' onClick={openLookbookOrderModal}>
                        Update Order Status
                      </div>
                    ) : brandIsViewingPage && !brandMustFulfillOrder ? (
                      <div className='order-status-container-value' style={{ maxWidth: '200px' }}>
                        No action required. {shopmyMustFulfillOrder ? 'The ShopMy team' : 'Shopify'} is handling this order.
                      </div>
                    ) : (
                      <a href={`/chat?query=${Brand_name}`}>
                        <div className='order-status-container-button'>Chat With Brand</div>
                      </a>
                    )}
                    {shouldShowShopifyOrderId && (
                      <>
                        <div className='order-status-container-value' id='shopify-order-id'>
                          Shopify Order ID
                        </div>
                        <div
                          className='order-status-container-value'
                          onClick={() => copyToClipboard(shopifyOrderId, true, 'Copied Order ID')}
                          id='shopify-order-id-value'
                        >
                          {shopifyOrderId}
                          <FontAwesomeIcon icon={faCopy} />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='next-steps'>
                <div className='next-steps-title'>
                  {brandIsViewingPage
                    ? brandMustFulfillOrder
                      ? 'Whats Next: Fulfill Order'
                      : 'Whats Next: Sit Tight'
                    : 'Whats Next: Promote and Earn'}
                  {/* Whats Next: Promote and Earn */}
                </div>
                <div className='next-steps-subtitle'>
                  {brandIsViewingPage
                    ? brandMustFulfillOrder
                      ? 'Once you have shipped the products, please update the order status above. If you have any questions, please reach out to your account manager'
                      : `This order fulfillment is being taken care of. ${
                          shopmyMustFulfillOrder ? 'The ShopMy team' : 'Your Shopify store'
                        } has the order and this page will update with any updates to the order. You can also check in periodically to your Gifting Portal Dashboard for any updates. The talent will be automatically notified every step of the way.`
                    : `If you're inclined to share after you receive your products, click below to generate a commissionable link.`}
                </div>
                <UserLookbookOrderDetailsItems items={items} user={user} lookbook={lookbook} />
              </div>
              {userIsViewingPage && (
                <>
                  <div className='other-actions'>
                    <div id='invite-other-creators'>
                      <div className='other-action-title'>Invite Creators to ShopMy</div>
                      <div className='other-action-body'>
                        For everyone you invite to join ShopMy, you both receive a 10% bonus on all of their commissions and collaborations for the
                        first 6 months after they join!
                      </div>
                      <a className='other-action-button' href={`/invite/${getUsername(user)}`}>
                        Refer a Friend
                      </a>
                    </div>

                    <div id='link-socials'>
                      <div className='other-action-title'>Link Your Socials</div>
                      <div className='other-action-body'>
                        Your social media handles will appear on your public shop header. Linking your accounts will allow you to share your analytics
                        for brand collaborations—we highly recommend it. We do not share your data without your consent.
                      </div>
                      <a className='other-action-button' href='/settings'>
                        Go To Account Settings
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>
            {updateLookbookOrderModalIsOpen && (
              <UpdateLookbookOrderModal order={order} close={closeLookbookOrderModal} onUpdateCallback={updateLookbookOrderCallback} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

LookbookOrderDetails.propTypes = {
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, store, analytics, ui } = state;
  return { user, store, analytics, ui };
};

export default connect(mapStateToProps, {})(LookbookOrderDetails);
