import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';

import { getBrandId } from '../../Helpers/user_helpers';

import { fetchBrandInvoiceStats } from '../../APIClient/invoices';
import { getAffiliateCommissionsForBrandId } from '../../APIClient/commissions';

const CommissionData = props => {
  const { user, commissions, page, startDate, endDate, selectedInvoice, curSearchVal, isFetchingNextPage, receivedAllResults } = props;
  const { setAllInvoices, setIsFetchingResults, setReceivedAllResults, setCommissions, setPage } = props;
  const Brand_id = getBrandId(user);

  useEffect(() => {
    // Fetch all invoices for this brand
    if (Brand_id) {
      fetchBrandInvoiceStats(Brand_id)
        .then(resp => setAllInvoices(resp.invoices))
        .catch(() => cogoToast.error(`Could not fetch commissions, please reload and try again.`));
    }
  }, []);

  // Main commission fetching logic
  useEffect(() => {
    if (Brand_id) {
      const PAGE_LIMIT = 50;
      setIsFetchingResults(true);
      const Invoice_id = _.get(selectedInvoice, 'value', null);
      const queryData = Invoice_id ? { Invoice_id } : { page, limit: PAGE_LIMIT, query: curSearchVal, startDate, endDate };

      getAffiliateCommissionsForBrandId(Brand_id, _.omitBy(queryData, _.isNil))
        .then(resp => {
          setCommissions([...commissions.slice(0, page * PAGE_LIMIT), ...resp.commissions]);
          if (resp.commissions.length !== PAGE_LIMIT) setReceivedAllResults(true);
        })
        .catch(error => {
          cogoToast.error(`Could not fetch commissions, please reload and try again.`);
        })
        .finally(() => {
          setIsFetchingResults(false);
        });
    }
  }, [Brand_id, page, curSearchVal, startDate, endDate, selectedInvoice]);

  // infiniteScroll functionality
  useEffect(() => {
    const infiniteScroll = e => {
      const pixelsFromBottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight;

      if (pixelsFromBottom < 500 && !isFetchingNextPage && !receivedAllResults) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', infiniteScroll);
    return () => window.removeEventListener('scroll', infiniteScroll);
  });

  const fetchNextPage = async () => {
    setPage(page + 1);
  };

  return null;
};

CommissionData.propTypes = {
  user: PropTypes.object.isRequired,

  commissions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  selectedInvoice: PropTypes.object,
  isFetchingNextPage: PropTypes.bool.isRequired,
  receivedAllResults: PropTypes.bool.isRequired,

  setAllInvoices: PropTypes.func.isRequired,
  setIsFetchingResults: PropTypes.func.isRequired,
  setReceivedAllResults: PropTypes.func.isRequired,
  setCommissions: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

export default CommissionData;
