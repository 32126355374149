import React from 'react';
import PropTypes from 'prop-types';
import './ConsultElementLongQuestion.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';

const ConsultElementLongQuestion = props => {
  const { canEdit, question, answer, placeholder, setField } = props;

  return (
    <div className='element-container long-question-container'>
      {canEdit ? (
        <textarea
          rows={1}
          ref={ref => matchScrollHeight(ref)}
          value={question}
          onChange={e => setField('question', e.target.value, 250)}
          placeholder='Ask Your Question Here'
          className='question'
        />
      ) : (
        <div className='question'>{question || '-'}</div>
      )}
      {canEdit ? (
        <textarea
          className='answer placeholder'
          placeholder='Add Placeholder Text'
          rows={6}
          value={placeholder}
          onChange={e => setField('placeholder', e.target.value, 250)}
        />
      ) : (
        <textarea className='answer' value={answer} onChange={e => setField('answer', e.target.value, 250)} rows={6} placeholder={placeholder} />
      )}
    </div>
  );
};

ConsultElementLongQuestion.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  setField: PropTypes.func.isRequired
};

export default ConsultElementLongQuestion;
