import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './ChatFilters.scss';

import { getLookbooks, isBrand, getBrandLists, getOpportunities, getUserCountryCode } from '../../Helpers/user_helpers';
import { getBrandRequests, sortBrandListsByRecency, getOpportunityRequests } from '../../Helpers/brand_helpers';
import { getCountryDisplayFromCode } from '../../Helpers/geo_helpers';

import InputActions from '../General/InputActions';

const ChatFilters = props => {
  /*
    Currently only a brand feature.
  */
  const { user, talent, allChats, analytics, activeFilterObjects } = props;
  const newChats = allChats.filter(chat => chat.hasNewMessagesForBrand && !chat.isDismissedByBrand);

  // Allow toggling all or some in a section in case of very long lists
  const [isShowingAllByKey, setIsShowingAllByKey] = React.useState({});
  const isShowingAllForKey = key => isShowingAllByKey[key] || false;
  const showAllForKey = key => setIsShowingAllByKey({ ...isShowingAllByKey, [key]: true });
  const showLessForKey = key => setIsShowingAllByKey({ ...isShowingAllByKey, [key]: false });
  const NUM_TO_SHOW = 10;

  const chatFilter = ({ key, display, filter }) => ({
    key,
    display,
    filter,
    countFilter(chatList) {
      return chatList.filter(this.filter).length;
    },
    get count() {
      delete this.count;
      return (this.count = this.countFilter(allChats));
    },
    get newCount() {
      delete this.newCount;
      return (this.newCount = this.countFilter(newChats));
    }
  });

  // List filters
  const lists = isBrand(user) ? sortBrandListsByRecency(getBrandLists(user)) : [];
  const getFilterForList = list => {
    const listUserIdsSet = new Set(list.users.map(u => u.User_id));
    return chatFilter({
      key: `list-${list.id}`,
      display: list.title,
      filter: chat => listUserIdsSet.has(chat.User_id)
    });
  };

  // Lookbook filters
  const requests = getBrandRequests(analytics);
  const requestsByLookbook = _.groupBy(requests, 'Lookbook_id');
  const lookbooks = _.orderBy(
    getLookbooks(user).filter(lookbook => !!requestsByLookbook[lookbook.id]),
    'createdAt',
    'desc'
  );
  const getFilterForLookbook = lookbook => {
    const lookbookRequests = requestsByLookbook[lookbook.id] || [];
    const requestUserIdSet = new Set(lookbookRequests.map(r => r.User_id));
    return chatFilter({
      key: `lookbook-${lookbook.id}`,
      display: lookbook.title,
      filter: chat => requestUserIdSet.has(chat.User_id)
    });
  };

  // Opportunities filters
  const opportunities = _.orderBy(getOpportunities(user), 'createdAt', 'desc');
  const allOpportunityRequests = getOpportunityRequests(analytics);
  const getFilterForOpportunity = opportunity => {
    const opportunityRequests = allOpportunityRequests.filter(r => r.Opportunity_id === opportunity.id);
    const requestUserIdSet = new Set(opportunityRequests.map(r => r.User_id));
    return chatFilter({
      key: `opportunity-${opportunity.id}`,
      display: opportunity.title,
      filter: chat => requestUserIdSet.has(chat.User_id)
    });
  };

  // Gifting status filters
  const acceptedGiftingUserIds = new Set(requests.filter(r => r.userAccepted && r.brandAccepted).map(r => r.User_id));
  const awaitingGiftingResponseUserIds = new Set(requests.filter(r => r.brandAccepted && !r.userAccepted && !r.userRejected).map(r => r.User_id));
  const giftingStatusFilters = [
    chatFilter({
      key: 'gifting-accepted',
      display: 'Accepted Gifting',
      filter: chat => acceptedGiftingUserIds.has(chat.User_id)
    }),
    chatFilter({
      key: 'gifting-awaiting-response',
      display: 'Awaiting Gifting Response',
      filter: chat => awaitingGiftingResponseUserIds.has(chat.User_id)
    })
  ];
  const getFilterForGiftingStatus = giftingStatus => giftingStatusFilters.find(f => f.key === giftingStatus.key);

  // Geo Filters
  const GEO_FILTER_COHORT_THRESHOLD = 5;
  const defaultCountryCode = getUserCountryCode(user) || 'US';
  const countryCodes = [
    defaultCountryCode,
    ..._(allChats) // top country codes above the threshold
      .filter(chat => chat.user.countryCode && chat.user.countryCode !== defaultCountryCode)
      .countBy(chat => chat.user.countryCode)
      .omitBy(count => count < GEO_FILTER_COHORT_THRESHOLD)
      .toPairs()
      .sortBy(1)
      .slice(-5)
      .reverse()
      .map(0)
      .value()
  ];
  const geoFilters = countryCodes.map(countryCode =>
    chatFilter({
      key: `geo-${countryCode}`,
      display: getCountryDisplayFromCode(countryCode),
      filter: chat => chat.user.countryCode === countryCode
    })
  );
  const getFilterForGeo = geo => geoFilters.find(f => f.key === geo.key);

  // Talent Filters
  const talentById = _.keyBy(talent.talent, 'id');
  const talentStatuses = [
    { display: 'Has Discount Code', key: 'HAS_CODE' },
    { display: 'Has Custom Rate', key: 'HAS_RATE' },
    { display: 'Currently Promoting', key: 'IS_PROMOTING' },
    { display: 'Onboarded By You', key: 'ONBOARDED_BY_BRAND' }
  ];
  const talentFilters = talentStatuses.map(status =>
    chatFilter({
      key: `talent-${status.key}`,
      display: status.display,
      filter: chat => talentById[chat.User_id]?.statusEnums?.includes(status)
    })
  );

  // Other filters
  const otherFilters = [
    chatFilter({
      key: 'other-dismissed',
      display: 'Dismissed Chats',
      filter: chat => chat.isDismissedByBrand
    })
  ];

  const [curSearchVal, setCurSearchVal] = React.useState('');
  const [isSearching, setIsSearching] = React.useState(false);
  const passesSearch = object => !curSearchVal || (object.title || object.display).toLowerCase().includes(curSearchVal.toLowerCase());
  const closeSearch = () => {
    setCurSearchVal('');
    setIsSearching(false);
  };

  const getSection = ({ title, objects, keyPrefix, getFilter, key }) => {
    const validObjects = objects.filter(object => !!getFilter(object).count);
    const isShowingMore = isShowingAllForKey(keyPrefix);
    const canShowMore = validObjects.length > NUM_TO_SHOW && !curSearchVal;

    const hasObjectMatchingSearch = validObjects.some(object => passesSearch(object));

    if (!validObjects.length) return null;
    if (!hasObjectMatchingSearch) return null;
    return (
      <div className='filter-section'>
        <div className='filter-section-header'>{title}</div>
        <div className='filters'>
          {validObjects.slice(0, isShowingMore || curSearchVal ? validObjects.length : NUM_TO_SHOW).map(object => {
            const filter = getFilter(object);
            const active = props.isFilterActive(filter);
            const applyFilter = () => {
              if (active) return props.setFilters(activeFilterObjects.filter(f => f.key !== filter.key));
              const newSetOfFilters = [...activeFilterObjects, filter].filter(f => {
                if (f.key === filter.key) return true;
                if (f.key.includes(keyPrefix)) return false; // Hide all other list filters
                return true;
              });
              props.setFilters(newSetOfFilters);
              props.closePanel();
            };
            if (!passesSearch(filter)) return null;

            return (
              <div title={filter.display} className={cn('filter', { active })} onClick={applyFilter} key={filter.display}>
                <div className='title' onClick={applyFilter}>
                  {filter.display}
                </div>
                {filter.newCount ? <div className='count new'>{filter.newCount} NEW</div> : <div className='count'>{filter.count}</div>}
              </div>
            );
          })}
          {canShowMore && (
            <div className='filter toggle-more-less' onClick={isShowingMore ? () => showLessForKey(keyPrefix) : () => showAllForKey(keyPrefix)}>
              {isShowingMore ? 'Show Less' : 'Show More'}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className='chat-filters-outer-container'>
      <div className='panel-header'>
        {isSearching ? (
          <div className='search-container with-search has-actions'>
            <input
              autoFocus
              value={curSearchVal}
              onBlur={() => !curSearchVal && setIsSearching(false)}
              onChange={e => setCurSearchVal(e.target.value)}
              className='search'
              placeholder='Search'
            />
            <InputActions onCancel={closeSearch} searchVal={curSearchVal} showSearchIcon />
          </div>
        ) : (
          <div className='search-container'>
            <div className='title'>Filters</div>
            <FontAwesomeIcon onClick={() => setIsSearching(true)} icon={faSearch} />
          </div>
        )}
        <div onClick={() => (isSearching ? closeSearch() : props.closePanel())} className='close-btn'>
          Done
        </div>
      </div>
      <div className='chat-filters-inner-container'>
        <div className='filter-sections'>
          {/* Gifting Status */}
          {getSection({
            title: 'Gifting Status',
            objects: giftingStatusFilters,
            keyPrefix: 'gifting-',
            getFilter: getFilterForGiftingStatus
          })}
          {/* Lists */}
          {getSection({ title: 'Lists', objects: lists, keyPrefix: 'list-', getFilter: getFilterForList })}
          {/* Lookbooks */}
          {getSection({
            title: 'Opportunities',
            objects: opportunities,
            keyPrefix: 'opportunity-',
            getFilter: getFilterForOpportunity
          })}
          {/* Lookbooks */}
          {getSection({
            title: 'Lookbooks',
            objects: lookbooks,
            keyPrefix: 'lookbook-',
            getFilter: getFilterForLookbook
          })}
          {/* Geo Filters */}
          {getSection({ title: 'Geo', objects: geoFilters, keyPrefix: 'geo-', getFilter: getFilterForGeo })}
          {/* Talent Filters */}
          {getSection({ title: 'Talent', objects: talentFilters, keyPrefix: 'talent-', getFilter: f => f })}
          {/* Other Filters */}
          {getSection({ title: 'Other', objects: otherFilters, keyPrefix: 'other-', getFilter: f => f })}
        </div>
      </div>
    </div>
  );
};

ChatFilters.propTypes = {
  // From outsid
  allChats: PropTypes.array.isRequired,
  isFilterActive: PropTypes.func.isRequired,
  activeFilterObjects: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  turnOnNewOnlyFilter: PropTypes.func.isRequired,
  turnOffNewOnlyFilter: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,

  // From inside
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, analytics, talent } = state;
  return { user, analytics, talent };
};

export default connect(mapStateToProps, {
  // functions go here
})(ChatFilters);
