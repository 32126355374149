import React from 'react';
import _ from 'lodash';
import './SectionTemplate_Editorial.scss';

const SectionTemplate_Editorial = props => {
  return (
    <div className='section-template editorial'>
      {_.range(3).map(idx => {
        return (
          <div className='collection' key={idx}>
            <div className='image' />
            <div className='data'>
              <div className='title' />
              <div className='subtitle' />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SectionTemplate_Editorial;
