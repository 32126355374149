import _ from 'lodash';
import moment from 'moment';

import { getUserTier, getProfileFromObject, getValidReferrals } from './user_helpers';
import { getPrettyNumber } from './formatting';

export const DAYS_BEFORE_DEMOTING_TIER = 14;

export const getUserTierDisplay = tier =>
  _.get(
    getUserTiers().find(t => t.tier === tier),
    'label'
  );
export const getUserTiers = () => [
  {
    label: 'Enthusiast',
    minScore: 0,
    explainer: 'Just Getting Started',
    tier: 4,
    unlocked: ['Digital storefront', 'Millions of commissionable products', 'Over 50k merchant partners']
  },
  {
    label: 'Ambassador',
    minScore: 30,
    explainer: 'Trusted For Product Advice',
    tier: 3,
    unlocked: ['$10 cash bonus', "Increased visibility in brands' search results"]
  },
  {
    label: 'Trendsetter',
    minScore: 70,
    explainer: 'Highly Trusted for Product Advice',
    tier: 2,
    unlocked: [
      window.__IS_REDUCED_CREATOR_TIER_BONUS__ ? '$50 cash bonus' : '$100 Bonus',
      'Ability to initiate chat with brands',
      'Ability to request gifting and discount codes',
      'Priority consideration for Opportunities'
    ]
  },
  {
    label: 'Icon',
    minScore: 85,
    explainer: 'World Class Product Advice',
    tier: 1,
    unlocked: [
      window.__IS_REDUCED_CREATOR_TIER_BONUS__ ? '$100 Bonus' : '$250 Bonus',
      'Access to our Creator Strategy Team for shop support',
      'Invitations to exclusive ShopMy events',
      'Priority Recommendation to Brands for Collaborations'
    ]
  }
];

export const hasUnlockedIncreasedReferralBonuses = user => {
  /*
    We want to encourage our top creators 
  */
  const tier = getUserTier(user);
  if (tier.tier === 1) return true;
  if (window.__ADMIN_CONTROL_MODE__) return true;
  return false;
};

export const isUserAtRiskOfTierDemotion = user => {
  /*
    We offer a buffer period for a user to get back into the higher tier so that we don't
    have people jumping tiers too frequently when they are just on the cusp of another tier.
  */
  const userTier = getUserTier(user);
  const tiers = getUserTiers();
  const minScoreForExistingTier = tiers.find(t => t.tier === userTier.tier).minScore;
  return userTier.score < minScoreForExistingTier;
};

export const getDaysUntilTierDemotion = user => {
  const userTier = getUserTier(user);
  const daysSinceLastConfirmation = moment().diff(moment(userTier.confirmedTierDate), 'days');
  return _.max([DAYS_BEFORE_DEMOTING_TIER - daysSinceLastConfirmation, 1]);
};

/*
  Meter Data Objects should return in the following format:

  {
    score: 75, // Current score out of 100
    scoreLabel: '20k clicks', // Current Score Label
    values: [
      {
        value: 10, // out of 100
        valueLabel: null, // To put a spot on the circle itself
        label: '10k clicks'
      }
      ...
    ]
  }
*/
export const getTierMeterData = user => {
  const userTier = getUserTier(user);
  const scoreInt = Math.floor(userTier.score);
  return {
    points: userTier.score,
    pointsInt: scoreInt,
    score: scoreInt,
    maxPoints: 100,
    values: [...getUserTiers().map(tier => ({ valueLabel: tier.minScore, value: tier.minScore })), { valueLabel: 100, value: 100 }]
  };
};

export const getProfileScoreMeterData = user => {
  const userProfile = getProfileFromObject(user);
  const userTier = getUserTier(user);
  const rawResponse = JSON.parse(userTier?.raw || '{}');
  const TOTAL_POINTS = 10;
  const components = [
    {
      label: 'None',
      shortLabel: 'None',
      complete: true
    },
    {
      label: 'Photo Added',
      shortLabel: 'Photo',
      complete: userProfile.image?.length > 0
    },
    {
      label: 'Added Bio',
      shortLabel: 'Bio',
      complete: userProfile.description?.length > 0
    },
    {
      label: 'Quick Link',
      shortLabel: 'Quick Link',
      complete: rawResponse['Quick Link (2)'] > 0,
      getLinkToFromUserObject: user => `/links`
    },
    {
      label: 'Connected Social',
      shortLabel: 'Social',
      complete: rawResponse['Social (2)'] > 0
    },
    {
      label: 'Recent Collection',
      shortLabel: 'Shelves',
      complete: rawResponse['Recent Collections (2)'] > 0
    }
  ];
  const orderedComponents = _.orderBy(components, 'complete', 'desc');
  const augmentedComponents = orderedComponents.map((d, idx) => ({
    ...d,
    value: Math.round((100 * idx) / (components.length - 1)),
    valueLabel: (TOTAL_POINTS * idx) / (components.length - 1)
  }));

  const score = (100 * (components.filter(i => i.complete).length - 1)) / (components.length - 1);
  return {
    score,
    points: (TOTAL_POINTS * score) / 100,
    pointsInt: (TOTAL_POINTS * score) / 100,
    maxPoints: TOTAL_POINTS,
    values: augmentedComponents
  };
};

export const getActivityScoreMeterData = user => {
  const userTier = getUserTier(user);
  const rawResponse = JSON.parse(userTier?.raw || '{}');
  const TOTAL_POINTS = 15;
  const points = rawResponse['Activity (15)'];
  const pointsInt = Math.round(points);
  return {
    points,
    pointsInt,
    score: (100 * points) / TOTAL_POINTS,
    maxPoints: TOTAL_POINTS,
    values: [
      {
        label: 'None',
        shortLabel: 'None',
        value: 0,
        valueLabel: 0
      },
      {
        label: 'Low Activity',
        shortLabel: 'Low Activity',
        value: 33,
        valueLabel: 5
      },
      {
        label: 'Active',
        shortLabel: 'Active',
        value: 66,
        valueLabel: 10
      },
      {
        label: 'Highly Active',
        shortLabel: 'Highly Active',
        value: 100,
        valueLabel: 15
      }
    ]
  };
};
export const getTrafficScoreMeterData = user => {
  const userTier = getUserTier(user);
  const rawResponse = JSON.parse(userTier?.raw || '{}');
  const TOTAL_POINTS = 25;
  const points = rawResponse['Monthly Traffic (25)'];
  const pointsInt = Math.round(points);
  const monthlyTraffic = Math.round(rawResponse['Monthly Traffic']);
  return {
    points,
    pointsInt,
    score: (100 * points) / TOTAL_POINTS,
    pointsLabel: `${getPrettyNumber(monthlyTraffic)} click${monthlyTraffic === 1 ? '' : 's'}`,
    maxPoints: TOTAL_POINTS,
    values: [
      {
        label: 'None',
        shortLabel: 'None',
        value: 0,
        valueLabel: 0
      },
      {
        label: '20 clicks',
        shortLabel: '20',
        value: 20,
        valueLabel: 5
      },
      {
        label: '100 clicks',
        shortLabel: '100',
        value: 40,
        valueLabel: 10
      },
      {
        label: '500 clicks',
        shortLabel: '500',
        value: 60,
        valueLabel: 15
      },
      {
        label: '2k clicks',
        shortLabel: '2k',
        value: 80,
        valueLabel: 20
      },
      {
        label: '10k clicks',
        shortLabel: '10k',
        value: 100,
        valueLabel: 25
      }
    ]
  };
};

export const getVolumeScoreMeterData = user => {
  const userTier = getUserTier(user);
  const rawResponse = JSON.parse(userTier?.raw || '{}');
  const TOTAL_POINTS = 50;
  const points = rawResponse['Monthly Volume (50)'];
  const pointsInt = Math.round(points);
  const monthlyVolume = Math.round(rawResponse['Monthly Volume']);
  return {
    points,
    pointsInt,
    score: (100 * points) / TOTAL_POINTS,
    pointsLabel: `$${getPrettyNumber(monthlyVolume)}`,
    maxPoints: TOTAL_POINTS,
    values: [
      {
        label: 'None',
        shortLabel: 'None',
        value: 0,
        valueLabel: 0
      },
      {
        label: '$25',
        shortLabel: '$25',
        value: 20,
        valueLabel: 10
      },
      {
        label: '$250',
        shortLabel: '$250',
        value: 40,
        valueLabel: 20
      },
      {
        label: '$1k',
        shortLabel: '$1k',
        value: 60,
        valueLabel: 30
      },
      {
        label: '$5k',
        shortLabel: '$5k',
        value: 80,
        valueLabel: 40
      },
      {
        label: '$25k',
        shortLabel: '$25k',
        value: 100,
        valueLabel: 50
      }
    ]
  };
};

export const getReferralScoreMeterData = user => {
  const userTier = getUserTier(user);
  const referrals = getValidReferrals(user);
  const rawResponse = JSON.parse(userTier?.raw || '{}');
  const TOTAL_POINTS = 20;
  const points = rawResponse['Referral Bonus (20)'] || 0;
  const pointsInt = Math.round(points);
  return {
    points,
    pointsInt,
    pointsLabel: `${referrals.length} Referral${referrals.length === 1 ? '' : 's'}`,
    score: (100 * points) / TOTAL_POINTS,
    maxPoints: TOTAL_POINTS,
    values: [
      {
        label: 'None',
        shortLabel: 'None',
        value: 0,
        valueLabel: 0
      },
      {
        label: '2 Referrals',
        shortLabel: '2 Referrals',
        value: 20,
        valueLabel: 4
      },
      {
        label: '5 Referrals',
        shortLabel: '5 Referrals',
        value: 40,
        valueLabel: 8
      },
      {
        label: '10 Referrals',
        shortLabel: '10 Referrals',
        value: 60,
        valueLabel: 12
      },
      {
        label: '20 Referrals',
        shortLabel: '20 Referrals',
        value: 80,
        valueLabel: 16
      },
      {
        label: '50 Referrals',
        shortLabel: '50 Referrals',
        value: 100,
        valueLabel: 20
      }
    ]
  };
};
