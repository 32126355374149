import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import './BrandListsOverlay.scss';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { faHeart, faSearchMinus } from '@fortawesome/pro-solid-svg-icons';
import defaultPhoto from '../../static/images/logos/logo.png';

import Loader from '../Loader/Loader';
import ConfirmPrompt from '../General/ConfirmPrompt';
import PartnerActionTemplates from '../General/PartnerActionTemplates';

import { toggleBodyScrollLock } from '../../Actions/UIActions';
import { addBrandList, updateBrandList, deleteBrandList, augmentBrandLists, addBrandListUser, deleteBrandListUser } from '../../Actions/BrandActions';
import { getAllDiscoverLists, deleteDiscoverListUser, addDiscoverListUser, updateDiscoverListUser } from '../../APIClient/discover';
import { getUserPastRecommendationDescriptions } from '../../APIClient/recommendations';

import { openUpdateListModal } from '../../Helpers/list_helpers';
import { sortBrandListsByRecency, getListUserFromList } from '../../Helpers/brand_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { getBrandLists, getDiscoverListPreviewsForAdmins } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

class BrandListsOverlay extends Component {
  static propTypes = {
    // From Redux Connect
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    toggleBodyScrollLock: PropTypes.func.isRequired,
    augmentBrandLists: PropTypes.func.isRequired,
    addBrandList: PropTypes.func.isRequired,
    updateBrandList: PropTypes.func.isRequired,
    deleteBrandList: PropTypes.func.isRequired,
    addBrandListUser: PropTypes.func.isRequired,
    deleteBrandListUser: PropTypes.func.isRequired,

    // From Outer Container
    closeOverlay: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
    selectedUser_id: PropTypes.number.isRequired,
    syncAfterEdit: PropTypes.func
  };

  componentDidMount() {
    document.body.onclick = e =>
      (window.lastClick = {
        x: e.x,
        y: e.y,
        e
      });
  }

  componentDidUpdate(prevProps) {
    // Becoming active
    const isNowActive = this.props.isActive && !prevProps.isActive;
    const isNowInactive = !this.props.isActive && prevProps.isActive;

    if (isNowActive) {
      // Handle Positioning
      const WIDTH = 320;
      const shouldOpenRight = window.lastClick.x < WIDTH;
      this.overlayRef.style.width = `${WIDTH}px`;
      this.overlayRef.style.top = `${window.lastClick.y + 5}px`;
      this.overlayRef.style.left = `${window.lastClick.x - (shouldOpenRight ? 0 : WIDTH) - 2}px`;
      this.overlayRef.style[`border-top-${shouldOpenRight ? 'left' : 'right'}-radius`] = `0px`;
      this.props.toggleBodyScrollLock(true);
      setTimeout(() => this.setState({ isOverlayVisible: true }), 20); // Fade in

      // Handle Data Fetching
      this.props.augmentBrandLists().then(resp => {
        this.setState({ hasAugmentedBrandUsers: true });
      });

      this.syncDiscoverLists();

      // Establish a sort order
      this.setState({ listIdSortOrder: this.getListIdSortOrder() });

      // Default to the Discover List Tab if it's recently updated
      if (window.__ADMIN_CONTROL_MODE__) {
        const discoverListPreviews = getDiscoverListPreviewsForAdmins(this.props.user);
        const brandLists = getBrandLists(this.props.user);
        const lastUpdatedDiscoverList = _.maxBy(discoverListPreviews, 'lastUpdatedAt');
        const lastUpdatedBrandList = _.maxBy(brandLists, 'lastUpdatedAt');
        const minutesAgoDiscoverLists = !lastUpdatedDiscoverList ? 1e6 : moment().diff(moment(lastUpdatedDiscoverList?.lastUpdatedAt), 'minutes');
        const minutesAgoBrandLists = !lastUpdatedBrandList ? 1e6 : moment().diff(moment(lastUpdatedBrandList.lastUpdatedAt), 'minutes');
        this.setState({ visibleListSection: minutesAgoDiscoverLists < minutesAgoBrandLists ? 'discover-lists' : 'brand-lists' });
      }
    }
    if (isNowInactive) {
      this.setState({ isOverlayVisible: false, hasAugmentedBrandUsers: false });
      this.props.toggleBodyScrollLock(false);
    }
  }

  state = {
    newListVal: '',
    isOverlayVisible: false,
    listIdSortOrder: [],

    // UI
    isOtherModalOpen: false,
    visibleListSection: 'brand-lists',

    // For admins
    discoverLists: []
  };

  getListSections = () => [
    {
      variable: 'brand-lists',
      title: 'Brand Lists',
      getLists: () => {
        const { user } = this.props;
        const brandLists = getBrandLists(user);
        const filteredBrandLists = _.filter(brandLists, list => {
          if (window.__ADMIN_CONTROL_MODE__ && list.isRecommendationList) return false; // Hide recommendation lists from users so they can't configure them
          return true;
        });
        const sortedBrandLists = _.orderBy(filteredBrandLists, list => _.indexOf(this.state.listIdSortOrder, list.id));
        const listsToShow = [...sortedBrandLists.slice(0, 2), null, ...sortedBrandLists.slice(2)]; // Null is an empty one for adding new lists
        return listsToShow;
      }
    },
    {
      variable: 'new-and-for-you',
      title: 'New & For You',
      requiresExplanation: true,
      getLists: () => {
        const { user } = this.props;
        const sortedDiscoverListPreviews = _.orderBy(getDiscoverListPreviewsForAdmins(user), 'lastUpdatedAt', 'desc');
        const listsToShow = sortedDiscoverListPreviews;
        return listsToShow;
      }
    },
    {
      variable: 'discover-lists',
      title: 'All Discover',
      getLists: () => this.state.discoverLists
    }
  ];
  isBrandListSectionActive = () => this.state.visibleListSection === 'brand-lists';
  isDiscoverListSectionActive = () => this.state.visibleListSection === 'new-and-for-you' || this.state.visibleListSection === 'discover-lists';

  getVisibleListSection = () => this.getListSections().find(section => section.variable === this.state.visibleListSection);

  getListIdSortOrder = () => {
    // We want to return a consistent sort order to get started
    const brandLists = getBrandLists(this.props.user);
    const sortedBrandLists = _.orderBy(sortBrandListsByRecency(brandLists), list => !!getListUserFromList(list, this.props.selectedUser_id), 'desc');
    return sortedBrandLists.map(list => list.id);
  };

  // Establish a sort order on editing and adjust that accordingly
  addList = () => {
    const { newListVal } = this.state;
    if (blockOnRequiringSubscription(this.props.user, 'LISTS')) {
      this.setState({ isOverlayVisible: false });
      return null;
    }
    if (!newListVal) return cogoToast.warn(`Please enter a title.`);

    this.props.addBrandList(newListVal).then(resp => {
      resp.list && this.addListUser(resp.list);
      window.__ADD_EVENT__('General - Add New List', { List_title: newListVal });
    });
    this.setState({ newListVal: '' });
  };

  syncData = () => {
    this.props.syncAfterEdit && this.props.syncAfterEdit();
    this.props.augmentBrandLists();
    this.syncDiscoverLists();
  };

  syncDiscoverLists = () => {
    if (window.__ADMIN_CONTROL_MODE__) {
      getAllDiscoverLists({
        featuredOnly: true,
        visibleOnly: true,
        nonBrandSpecificOnly: true,
        findUser_id: this.props.selectedUser_id
      }).then(resp => {
        if (this.state.discoverLists.length) {
          // Make sure to sort the new lists by the order of the old lists
          const newListsPerListId = _.keyBy(resp, 'id');
          this.setState({
            discoverLists: this.state.discoverLists.map(list => newListsPerListId[list.id] || list)
          });
        } else {
          this.setState({ discoverLists: resp });
        }
      });
    }
  };

  deleteList = list => {
    const confirm = () => this.props.deleteBrandList(list).then(this.syncData);
    const close = () => this.setState({ isOtherModalOpen: false });
    this.setState({ isOtherModalOpen: true });
    list.users.length
      ? confirmAlert({
          title: 'Just confirming',
          message: 'Are you sure you want to delete this list?',
          buttons: [
            { label: 'No', className: 'cancel', onClick: close },
            {
              label: 'Yes',
              onClick: () => {
                confirm();
                close();
                window.__ADD_EVENT__('General - Remove List', { List_title: list.title });
              }
            }
          ]
        })
      : confirm();
  };

  removeListUser = listUser => {
    if (this.isDiscoverListSectionActive()) {
      deleteDiscoverListUser(listUser).then(this.syncData);
    } else {
      this.props.deleteBrandListUser(listUser).then(this.syncData);

      window.__ADD_EVENT__('General - Remove User from List', {
        BrandList_id: listUser.BrandList_id,
        User_id: listUser.User_id
      });
    }
  };

  addListUserAndClose = list => this.addListUser(list, { enableAutoclose: true });
  addListUser = async (list, options = {}) => {
    const { selectedUser_id } = this.props;
    const { enableAutoclose } = options;
    const section = this.getVisibleListSection();
    if (this.isDiscoverListSectionActive()) {
      const newDiscoverListUser = await addDiscoverListUser({
        DiscoverList_id: list.id,
        User_id: selectedUser_id
      });

      enableAutoclose && this.props.closeOverlay();
      section.requiresExplanation && (await this.requestExplanationOfSelection(newDiscoverListUser));
      this.syncData(); // No need to await, can happen in background while explaining
    } else {
      this.props.addBrandListUser(list, selectedUser_id).then(this.syncData);
      enableAutoclose && this.props.closeOverlay();

      window.__ADD_EVENT__('General - Add User to List', {
        BrandList_id: list.id,
        User_id: selectedUser_id
      });
    }
  };

  requestExplanationOfSelection = async newDiscoverListUser => {
    const { selectedUser_id } = this.props;
    const pastRecommendationDescriptions = await getUserPastRecommendationDescriptions(selectedUser_id);
    const pastDescriptions = pastRecommendationDescriptions.past.map(data => ({
      ...data,
      header: `Used ${data.count} time${data.count === 1 ? '' : 's'}, last for ${data.type}`
    }));

    await new Promise(resolve => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ConfirmPrompt
            header='Explain Recommendation'
            subheader='Explain this recommendation to the brand. This will be reusable for future recommendations for this user.'
            secondaryPanel={
              !!pastDescriptions.length && (
                <PartnerActionTemplates
                  user={this.props.user}
                  talent={{ id: selectedUser_id }}
                  templatesOne={pastDescriptions}
                  sectionHeaderOne='Past Explanations'
                />
              )
            }
            onCancel={() => {
              onClose();
              resolve();
            }}
            customInputFields={[{ value: 'description', numRows: 4 }]}
            onSubmitAwait={async responseValues => {
              const { description } = responseValues;
              await updateDiscoverListUser(newDiscoverListUser, { description });
              resolve();
            }}
          />
        )
      });
    });
  };

  render() {
    const { ui, children, isActive, selectedUser_id, closeOverlay } = this.props;
    const { isOverlayVisible, newListVal, hasAugmentedBrandUsers, isOtherModalOpen } = this.state;

    // If not active, simply pass through the children object
    if (!isActive) {
      return children;
    }

    // Sections
    const showDiscoverLists = this.isDiscoverListSectionActive();
    const listSections = this.getListSections();
    const activeSection = this.getVisibleListSection();
    const listsToShow = activeSection.getLists();

    const overlay = (
      <div className='brand-lists-overlay-outer-container'>
        <div onClick={closeOverlay} className={cn('brand-lists-overlay-fade', { visible: isOverlayVisible, 'other-modal-open': isOtherModalOpen })} />
        <div
          ref={ref => (this.overlayRef = ref)}
          className={cn('brand-lists-container', { visible: isOverlayVisible, 'other-modal-open': isOtherModalOpen })}
        >
          {isAdminControlMode(ui) && (
            <div className='toggle-discover-vs-brand-lists'>
              {listSections.map(section => {
                const active = section.variable === this.state.visibleListSection;
                return (
                  <div
                    key={section.variable}
                    className={cn('toggle', { active })}
                    onClick={() => this.setState({ visibleListSection: section.variable })}
                  >
                    {section.title}
                  </div>
                );
              })}
            </div>
          )}
          {listsToShow.map(list => {
            if (!list) {
              const submitForm = e => {
                e.stopPropagation();
                e.preventDefault();
                this.addList();
              };
              return (
                <div key='empty' className='brand-list empty'>
                  <div className='main'>
                    <div className='image-container add'>
                      {!newListVal ? <FontAwesomeIcon icon={faPlus} /> : <img src={defaultPhoto} alt='New List' />}
                    </div>
                    <form onSubmit={submitForm} className='data'>
                      <input
                        className='title'
                        placeholder={'Create a New List'}
                        onChange={({ target }) => this.setState({ newListVal: target.value })}
                        value={newListVal}
                      />
                    </form>
                  </div>
                  <div className='secondary'>
                    {newListVal.length > 0 && (
                      <div onClick={this.addList} className={cn('action', { inactive: !newListVal })}>
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                    )}
                  </div>
                </div>
              );
            }

            const { title, users, num_users, list_user, preview_images, last_updated } = list;

            const listUser = list_user || users?.find(u => u.User_id === selectedUser_id);

            const user_image = preview_images?.[0];
            const removeL = () => this.deleteList(list);
            const updateL = () => {
              // Need to ensure we push the modal down the z-index stack to ensure it is visible
              this.setState({ isOtherModalOpen: true });
              openUpdateListModal(list, this.props.updateBrandList, this.props.deleteBrandList, this.syncData, () => {
                this.setState({ isOtherModalOpen: false });
              });
            };
            const removeLU = () => this.removeListUser(listUser);
            // const addLU = () => (this.isDiscoverListSectionActive() ? this.addListUser(list) : this.addListUserAndClose(list)); // This is the old way
            const addLU = () => this.addListUser(list);

            return (
              <div className={cn('brand-list', { 'adding-other': newListVal })} key={list.id}>
                <div className='main'>
                  <div className='image-container'>
                    {!hasAugmentedBrandUsers && !user_image ? (
                      <div className='loader'>
                        <Loader size={40} />
                      </div>
                    ) : (
                      <img src={user_image || defaultPhoto} alt={title} />
                    )}
                  </div>
                  <div className='data'>
                    <div className='title'>{title}</div>
                    <div className='meta'>
                      <span className='count'>{num_users ? `${num_users} creator${num_users === 1 ? '' : 's'}` : 'No Creators Added'}</span>
                      {last_updated && <span className='last-updated'>• {moment(last_updated).format('MMMM Do')}</span>}
                      {showDiscoverLists ? (
                        <>
                          <span className='bullet'>•</span>
                          <a target='_blank' rel='noopener noreferrer' href={`/discover/${list.id}`}>
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </>
                      ) : (
                        <>
                          <span className='bullet'>•</span>
                          <span onClick={updateL} className='edit'>
                            Edit
                          </span>
                          <span className='bullet'>•</span>
                          <span onClick={removeL} className='delete'>
                            Delete
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className='secondary'>
                  <div onClick={listUser ? removeLU : addLU} className={cn('action', { added: !!listUser })}>
                    {listUser ? <FontAwesomeIcon icon={showDiscoverLists ? faSearchMinus : faHeart} /> : <FontAwesomeIcon icon={faPlus} />}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <>
        {children}
        {ReactDOM.createPortal(overlay, document.querySelector('body'))}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  toggleBodyScrollLock,
  augmentBrandLists,
  addBrandList,
  updateBrandList,
  deleteBrandList,
  addBrandListUser,
  deleteBrandListUser
})(BrandListsOverlay);
