import {
  subscribeToOffer as subscribeToOfferAPI,
  cancelSubscriptionItem as cancelSubscriptionItemAPI,
  updateSubscriptionItem as updateSubscriptionItemAPI
} from '../APIClient/subscriptions';

import { getBrandId } from '../Helpers/user_helpers';

export const SUBSCRIBE_TO_OFFER_SUCCESS = 'SUBSCRIBE_TO_OFFER_SUCCESS';
export const SUBSCRIBE_TO_OFFER_FAILURE = 'SUBSCRIBE_TO_OFFER_FAILURE';

export const CANCEL_SUBSCRIPTION_ITEM_SUCCESS = 'CANCEL_SUBSCRIPTION_ITEM_SUCCESS';
export const CANCEL_SUBSCRIPTION_ITEM_FAILURE = 'CANCEL_SUBSCRIPTION_ITEM_FAILURE';

export const UPDATE_SUBSCRIPTION_ITEM_SUCCESS = 'UPDATE_SUBSCRIPTION_ITEM_SUCCESS';
export const UPDATE_SUBSCRIPTION_ITEM_FAILURE = 'UPDATE_SUBSCRIPTION_ITEM_FAILURE';

export const subscribeToOffer = (offer, withTrial = false) => async (dispatch, getState) => {
  try {
    const resp = await subscribeToOfferAPI({
      Brand_id: getBrandId(getState().user),
      SubscriptionOffer_id: offer.id,
      withTrial
    });
    return dispatch({
      success: true,
      type: SUBSCRIBE_TO_OFFER_SUCCESS,
      subscription: resp.subscription
    });
  } catch (error) {
    return dispatch({
      type: SUBSCRIBE_TO_OFFER_FAILURE,
      error
    });
  }
};

export const cancelSubscriptionItem = item => async (dispatch, getState) => {
  try {
    const resp = await cancelSubscriptionItemAPI(item);
    return dispatch({
      success: true,
      type: CANCEL_SUBSCRIPTION_ITEM_SUCCESS,
      subscription: resp.subscription
    });
  } catch (error) {
    return dispatch({
      type: CANCEL_SUBSCRIPTION_ITEM_FAILURE,
      error
    });
  }
};

export const updateSubscriptionItem = (item, updates) => async (dispatch, getState) => {
  try {
    const newItem = await updateSubscriptionItemAPI(item, updates);
    return dispatch({
      type: UPDATE_SUBSCRIPTION_ITEM_SUCCESS,
      item: newItem
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_SUBSCRIPTION_ITEM_FAILURE,
      error
    });
  }
};
