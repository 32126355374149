import APIClient from './index';
import _ from 'lodash';

export const getRequestsForBrandId = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Requests/for_brand/${Brand_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateRequest = (request, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Requests/${request.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const createOpportunityRequest = request => {
  return new Promise((resolve, reject) => {
    APIClient.post('/OpportunityRequests', request)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateOpportunityRequest = (request, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityRequests/${request.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteOpportunityRequest = request => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityRequests/${request.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
