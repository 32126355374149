import React, { useState } from 'react';
import './LoginForm.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Loader from '../../Loader/Loader';
import InputActions from '../../General/InputActions';
import { loginUser, LOGIN_USER_FAILURE } from '../../../Actions/UserActions';
import ErrorBanner from '../../General/ErrorBanner';

const LoginForm = props => {
  const { formHeader, loginUser } = props;

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault(); // Prevents page from reloading on form submit
    setIsLoggingIn(true);

    const result = await loginUser({ email, password });

    if (result?.type === LOGIN_USER_FAILURE) {
      setLoginFailed(true);
    }

    setIsLoggingIn(false);
  };

  return (
    <div className='login-form-wrapper'>
      <div className='auth-header'>{formHeader}</div>
      <form onSubmit={handleSubmit}>
        <input type='email' className='login-input' placeholder='Email' onChange={({ target }) => setEmail(target.value)} value={email} />
        <div className='input-container has-actions'>
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            className='login-input'
            placeholder='Password'
            onChange={({ target }) => setPassword(target.value)}
            value={password}
          />
          <InputActions
            searchValHidden={!isPasswordVisible}
            searchVal={password}
            onToggleVisibility={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </div>
        {loginFailed && (
          <ErrorBanner>
            Sorry, the email or password you have entered does not match any account. Try again or <a href={'/home'}>sign up here.</a>
          </ErrorBanner>
        )}
        <div className='login-btn-container'>
          <input
            type='submit'
            className={cn('login-btn', {
              loading: isLoggingIn
            })}
            value='LOGIN'
          />
          {isLoggingIn && <Loader size={70} />}
        </div>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  formHeader: PropTypes.string.isRequired,
  loginUser: PropTypes.func.isRequired
};

export default connect(null, { loginUser })(LoginForm);
