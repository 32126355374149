import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './NYFW.scss';

import { openAuthModal } from '../../Actions/UIActions';

const NYFW = props => {
  const openBrandDemoModal = () => props.openAuthModal('apply-brands');
  const openCreatorApplyModal = () => props.openAuthModal('apply');

  return (
    <div className='nyfw-outer'>
      <div className='nyfw-inner'>
        <img src='https://askemma-static-public.s3.us-east-2.amazonaws.com/Home/nyfw_landing.jpg' alt='NYFW' className='nyfw-image' />
        <div className='nyfw-overlay' />

        <div className='nyfw-content'>
          <h1 className='nyfw-title'>Apply To ShopMy</h1>
          <div className='nyfw-buttons'>
            <button className='nyfw-button' onClick={openCreatorApplyModal}>
              I am a creator
            </button>
            <button className='nyfw-button' onClick={openBrandDemoModal}>
              I am a brand
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

NYFW.propTypes = {
  ui: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {
  openAuthModal
})(NYFW);
