import React from 'react';
import PropTypes from 'prop-types';
import './ContractReview.scss';

import ContractProposeOverviewCard from './Elements/ContractProposeOverviewCard';
import ContractProposeReceiptCard from './Elements/ContractProposeReceiptCard';
import ContractProposeScheduleCard from './Elements/ContractProposeScheduleCard';
import ContractLinksCard from './Elements/ContractLinksCard';
import ContractUploadsCard from './Elements/ContractUploadsCard';
import ContractProposeRatesCodes from './ContractProposeRatesCodes';

const ContractReview = props => {
  const { user, contract, analytics, collaborations } = props;
  return (
    <div className='contract-review-outer-container'>
      <ContractProposeOverviewCard contract={contract} canEdit={false} />
      <ContractProposeScheduleCard contract={contract} canEdit={false} />
      <ContractLinksCard user={user} contract={contract} canEdit={false} />
      <ContractUploadsCard user={user} contract={contract} canEdit={false} />
      <ContractProposeRatesCodes user={user} contract={contract} analytics={analytics} canEdit={false} />
      <ContractProposeReceiptCard user={user} contract={contract} collaborations={collaborations} canEdit={false} />
    </div>
  );
};

ContractReview.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  collaborations: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired
};

export default ContractReview;
