import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faTimes, faExternalLink, faEyeSlash, faBan, faPlus, faCopy } from '@fortawesome/pro-light-svg-icons';
import { faLinkSlash } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import cn from 'classnames';
import './LookbookProductModalSiblings.scss';

import SortableList from '../../../General/SortableList';

import ImageUploader from '../../../General/ImageUploader';
import ConfirmPrompt from '../../../General/ConfirmPrompt';
import Loader from '../../../Loader/Loader';
import Tooltip from '../../../General/Tooltip';

import * as lookbookHelpers from '../../../../Helpers/lookbook_helpers';
import { portalBugOpenUrl } from '../../../../Helpers/helpers';
import DropUploader from '../../../Uploader/DropUploader';
import { isAdminControlMode } from '../../../../Helpers/ui_helpers';

const LookbookProductModalSiblings = props => {
  const { user, ui, item, lookbook } = props;

  // Store to local state and update behind the scenes
  const [variations, setVariations] = useState(item.reduced_siblings);
  const orderHandledByData = lookbookHelpers.getOrderHandledByData(user, lookbook);

  const syncVariations = async () => {
    const resp = await props.syncLookbook();
    const newItem = resp?.items?.find(i => i.id === item.id);
    newItem && setVariations(newItem.reduced_siblings);
  };

  // Ensure they want to change Shopify states
  const confirmTheyWantToUpdateShopify = (cb, msgOverride) => {
    const needsConfirmation = orderHandledByData.handledBy === 'shopify';
    if (!needsConfirmation) return cb();

    confirmAlert({
      title: 'Are you sure?',
      message:
        msgOverride ||
        'Because this lookbook is integrated with Shopify, manual edits to variations on this product will not be connected to Shopify. Disconnected items will not be available for creators to select.',
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        { label: 'Continue', onClick: () => cb() }
      ]
    });
  };

  // Variations
  const [newVariation, setNewVariation] = useState('');
  const [isAddingVariation, setIsAddingVariation] = useState(false);
  const addVariation = () =>
    confirmTheyWantToUpdateShopify(async () => {
      const titleAlreadyExists = !newVariation || variations.some(v => v.title === newVariation);
      if (titleAlreadyExists) return cogoToast.warn('Title already exists');
      setNewVariation('');
      setIsAddingVariation(true);
      const resp = await props.addLookbookItemSibling({
        LookbookItem_id: item.id,
        title: newVariation
      });
      resp.item && setVariations(resp.item.reduced_siblings);
      setIsAddingVariation(false);
      await syncVariations();
    });

  const submitNewVariation = e => {
    e.preventDefault();
    addVariation();
  };

  // Sizes
  const addSizeForVariation = async (variation, sizeTitle) => {
    // called with confirmTheyWantToUpdateShopify in SiblingSizes
    const resp = await props.addLookbookItemSibling({
      LookbookItem_id: item.id,
      title: `${variation.tagParts.nonSizeTag || ''} - ${sizeTitle}`
    });
    resp.item && setVariations(resp.item.reduced_siblings);
  };

  const addSizesInBulk = async (variation, sizes) => {
    const objectsToAdd = sizes.map(size => ({
      LookbookItem_id: item.id,
      title: `${variation.tagParts.nonSizeTag} - ${size}`
    }));
    const resp = await props.addLookbookItemSibling(objectsToAdd);
    resp.item && setVariations(resp.item.reduced_siblings);
  };

  const openSiblingEditor = (sibling, isSize) => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          isSingleLine
          header={isSize ? 'Update size' : 'Update variation'}
          onCancel={onClose}
          customInputFields={[
            {
              display: 'Title',
              placeholder: isSize ? 'Small, Medium, ...' : 'Color, Shade, etc.',
              value: 'title',
              preloaded: isSize ? sibling.tagParts.sizeTag : sibling.tagParts.nonSizeTag,
              isSingleLine: true
            },
            ...(isSize ? [] : [{ display: 'Is Default', preloaded: sibling.isDefault, value: 'isDefault', isBoolean: true }]),
            ...(isAdminControlMode(ui)
              ? [{ display: 'Shopify Variant ID', preloaded: sibling.shopifyVariantId, value: 'shopifyVariantId', isSingleLine: true }]
              : []),
            ...(isAdminControlMode(ui) && orderHandledByData.handledBy === 'brand'
              ? [{ display: 'Extra Information For Google Sheet', preloaded: sibling.extra, value: 'extra', isSingleLine: true }]
              : []),
            { display: 'Is Hidden', preloaded: sibling.isHidden, value: 'isHidden', isBoolean: true }
          ]}
          onSubmit={async responseValues => {
            let newTitle;
            const newParts = { ...sibling.tagParts };
            if (isSize) {
              newParts.sizeTag = responseValues.title;
              newTitle = newParts.nonSizeTag ? `${newParts.nonSizeTag} - ${newParts.sizeTag}` : newParts.sizeTag;
            } else {
              newParts.nonSizeTag = responseValues.title;
              newTitle = newParts.sizeTag ? `${newParts.nonSizeTag} - ${newParts.sizeTag}` : newParts.nonSizeTag;
            }

            // Update the current sibling
            const updates = {
              isDefault: responseValues.isDefault,
              isHidden: responseValues.isHidden,
              title: newTitle,
              shopifyVariantId: !_.isNil(responseValues.shopifyVariantId) ? responseValues.shopifyVariantId || null : sibling.shopifyVariantId,
              extra: responseValues.extra
            };

            const getUpdatedVariantsState = (sibling, updates, localUpdates = {}) => {
              return variations.map(v => ({
                ...v,
                ...(v.id === sibling.id ? { ...updates, ...localUpdates } : {}),
                size_variations: v.size_variations?.map(sv => (sv.id === sibling.id ? { ...sv, ...updates, ...localUpdates } : sv))
              }));
            };

            let newVariantsState = getUpdatedVariantsState(sibling, updates, { tagParts: newParts });

            // Update all the other siblings if needed
            const updateSizeVariationPromises = [];

            if (!isSize) {
              const otherSiblings = sibling.size_variations?.filter(sv => sv.id !== sibling.id);
              otherSiblings.forEach(sv => {
                let newTitle;
                const newParts = { ...sv.tagParts };
                newParts.nonSizeTag = responseValues.title;
                newTitle = newParts.sizeTag ? `${newParts.nonSizeTag} - ${newParts.sizeTag}` : newParts.nonSizeTag;

                const otherSiblingUpdates = {
                  isHidden: responseValues.isHidden,
                  title: newTitle
                };

                newVariantsState = getUpdatedVariantsState(sv, otherSiblingUpdates, { tagParts: newParts });
                const updateSizeVariationPromise = props.updateLookbookItemSibling(sv, otherSiblingUpdates);
                updateSizeVariationPromises.push(updateSizeVariationPromise);
              });
            }

            // update local variant state first
            setVariations(newVariantsState);
            await props.updateLookbookItemSibling(sibling, updates);
            await Promise.all(updateSizeVariationPromises);

            // If setting default, reset all other defaults
            const updateDefaultPromises = [];

            if (responseValues.isDefault) {
              const otherSiblingsWithDefault = item.siblings.filter(s => s.id !== sibling.id && s.isDefault);

              otherSiblingsWithDefault.forEach(s => {
                const otherSiblingUpdates = { isDefault: false };

                newVariantsState = getUpdatedVariantsState(s, otherSiblingUpdates);

                const updateDefaultPromise = props.updateLookbookItemSibling(s, otherSiblingUpdates);
                updateDefaultPromises.push(updateDefaultPromise);
              });
            }

            // update local variant state first
            setVariations(newVariantsState);
            await Promise.all(updateDefaultPromises);

            // Always resync just to be safe
            syncVariations();
          }}
        />
      )
    });
  };

  const deleteSiblingSize = sibling => deleteSibling(sibling, true);
  const deleteSibling = async (sibling, isSize = false) => {
    const callback = async () => {
      if (item.siblings.length === 1) return cogoToast.warn('You must have at least one variation');

      setVariations(
        isSize
          ? variations.map(v => ({
              ...v,
              size_variations: v.size_variations?.filter(sv => sv.id !== sibling.id)
            }))
          : variations.filter(v => v.id !== sibling.id)
      );

      await props.deleteLookbookItemSibling(sibling);

      if (!isSize) {
        const sizeVariationsToDelete = sibling.size_variations.filter(sv => sv.id !== sibling.id);
        await Promise.all(sizeVariationsToDelete.map(sv => props.deleteLookbookItemSibling(sv)));
      }
      syncVariations();
    };

    if (!sibling.shopifyVariantId && orderHandledByData.handledBy === 'shopify') {
      await callback();
    } else {
      confirmTheyWantToUpdateShopify(
        callback,
        "Because this item is connected to Shopify, we'd recommend you hide it instead of deleting it if you ever think you'll want to add it back to this Lookbook. You can hide it by clicking the variant name."
      );
    }
  };

  const updateSibling = async (sibling, updates, localUpdates = {}) => {
    setVariations(
      variations.map(v => ({
        ...v,
        ...(v.id === sibling.id ? { ...updates, ...localUpdates } : {}),
        size_variations: v.size_variations?.map(sv => (sv.id === sibling.id ? { ...sv, ...updates, ...localUpdates } : sv))
      }))
    );
    await props.updateLookbookItemSibling(sibling, updates);
  };

  const [disableDrag, setDisableDrag] = useState(false);

  return (
    <div className='lookbook-product-modal-siblings-container'>
      <div className='back-button' onClick={props.navigateBackToItem}>
        <FontAwesomeIcon icon={faArrowLeft} />
        Back to item
      </div>
      <div className='product-modal-title'>
        Edit Variations
        <div className='product-modal-subtitle'>
          Add variations on colors, shades, materials, and sizes and set specific images, links or pricing.
          {' ' + orderHandledByData.display + '.'}
        </div>
      </div>
      <div className='table'>
        <div className='row header'>
          <div className='cell sm'>Image</div>
          <div className='cell md'>Variation</div>
          <div className='cell lg'>Sizing</div>
          <div className='cell sm'>Price</div>
          <div className='cell md'>Custom URL</div>
        </div>
        {variations.map((variation, index) => {
          return (
            <DropUploader
              key={`variation-${variation.id}`}
              onlyAllowImages
              onUpload={url => {
                updateSibling(variation, { image: url });
                window.ALERT.success('Image uploaded successfully.');
              }}
              onDrop={(acceptedFiles, rejectedFiles) => {
                if (rejectedFiles.length) return window.ALERT.error('Only image files are supported.');
              }}
              isDisabled={disableDrag}
            >
              <div key={variation.id} className='row'>
                <div className='cell sm'>
                  <SiblingImage item={item} variation={variation} updateSibling={updateSibling} setDisableDrag={setDisableDrag} />
                </div>
                <div className='cell md'>
                  <Sibling sibling={variation} deleteSibling={deleteSibling} openSiblingEditor={openSiblingEditor} user={user} lookbook={lookbook} />
                </div>
                <div className='cell lg'>
                  <SiblingSizes
                    lookbook={lookbook}
                    user={user}
                    item={item}
                    deleteSiblingSize={deleteSiblingSize}
                    addSizeForVariation={addSizeForVariation}
                    confirmTheyWantToUpdateShopify={confirmTheyWantToUpdateShopify}
                    openSiblingEditor={openSiblingEditor}
                    variation={variation}
                    addSizesInBulk={addSizesInBulk}
                    updateSibling={updateSibling}
                  />
                </div>
                <div className='cell sm'>
                  <SiblingPrice item={item} variation={variation} updateSibling={updateSibling} />
                </div>
                <div className='cell md'>
                  <SiblingUrl item={item} variation={variation} updateSibling={updateSibling} />
                </div>
              </div>
            </DropUploader>
          );
        })}
        {isAddingVariation && (
          <div className='row add'>
            <div className='cell sm' />
            <div className='cell md loading-variant'>
              <Loader size={32} />
            </div>
            <div className='cell lg' />
            <div className='cell sm' />
            <div className='cell md' />
          </div>
        )}
        <div className='row add'>
          <div className='cell sm' />
          <div className='cell md'>
            <form className='new-variation-form' onSubmit={submitNewVariation}>
              <input type='text' placeholder='New Variation' value={newVariation || ''} onChange={e => setNewVariation(e.target.value)} />
            </form>
          </div>
          <div className='cell lg' />
          <div className='cell sm' />
          <div className='cell md' />
        </div>
      </div>
      <div className='footer-actions'>
        <div onClick={props.navigateBackToItem} className='footer-action primary'>
          Done
        </div>
      </div>
    </div>
  );
};

const SiblingImage = props => {
  const { variation, item, updateSibling, setDisableDrag } = props;
  const { isDefault } = variation;
  const imageUploaderRef = React.useRef(null);

  const image = variation.image || item.image;

  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const uploadImage = () => setIsUploadingImage(true);

  useEffect(() => {
    if (isUploadingImage) setDisableDrag(true);
    else setDisableDrag(false);

    return () => setDisableDrag(false);
  }, [isUploadingImage, setDisableDrag]);

  return (
    <>
      <ImageUploader
        ref={imageUploaderRef}
        isVisible={isUploadingImage}
        setIsVisible={setIsUploadingImage}
        initialImageUrl={variation.image}
        onSaveCallback={image_url => {
          updateSibling(variation, { image: image_url });
        }}
      />
      <div className='sibling-image' onClick={uploadImage}>
        <img alt={variation.title} src={image} />
        {!!isDefault && (
          <div className='default-badge-container'>
            <div className='default-badge'>Default</div>
          </div>
        )}
      </div>
    </>
  );
};

const SiblingPrice = props => {
  const { variation, item } = props;
  const isNew = variation.price !== item.price;
  const [price, setPrice] = useState(isNew ? variation.price : null);
  const priceDebounce = React.useRef(null);
  const changePrice = newPrice => {
    const cleanedPrice = newPrice.replace(/[^0-9.]/g, '');
    setPrice(newPrice);
    clearTimeout(priceDebounce.current);
    priceDebounce.current = setTimeout(() => {
      if (!_.isNumber(parseFloat(cleanedPrice))) return;
      props.updateSibling(variation, { price: parseFloat(cleanedPrice) });
    }, 500);
  };
  return (
    <div className='sibling-price'>
      <input type='text' placeholder={item.price ? `$${item.price}` : '-'} value={price || ''} onChange={e => changePrice(e.target.value)} />
    </div>
  );
};

const SiblingUrl = props => {
  const { variation, item } = props;
  const isNew = variation.url && variation.url !== item.url;
  const editLink = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          isSingleLine
          header='Add a URL to this variation'
          placeholder='Enter URL here'
          preloaded={variation.url}
          onCancel={onClose}
          onSubmit={newUrl => {
            props.updateSibling(variation, { url: newUrl });
          }}
        />
      )
    });
  };
  return (
    <div className='sibling-url'>
      <div onClick={editLink}>{isNew ? 'Edit Link' : '-'}</div>
      {isNew && (
        <a onClick={portalBugOpenUrl} href={variation.url} target='_blank' rel='noopener noreferrer' className='link'>
          <FontAwesomeIcon icon={faExternalLink} />
        </a>
      )}
    </div>
  );
};

const SiblingSizes = props => {
  const { lookbook, user, item, variation, deleteSiblingSize, addSizeForVariation, confirmTheyWantToUpdateShopify, addSizesInBulk } = props;
  const size_variations = _.filter(variation.size_variations || [], v => v.tagParts.sizeTag);

  const getCard = (sibling, additionalProps = {}) => (
    <Sibling
      lookbook={lookbook}
      user={user}
      sibling={sibling}
      isSizeVariation
      deleteSiblingSize={deleteSiblingSize}
      openSiblingEditor={props.openSiblingEditor}
      rearrangeHandle={additionalProps.rearrangeHandle}
    />
  );

  return (
    <SortableList
      isEditing
      containerClassName='lookbook-product-modal-sortable-sizes'
      items={size_variations}
      updateItem={props.updateSibling}
      props={props}
      getCard={getCard}
      additionalCard={
        <AddNewSizeSibling
          lookbook={lookbook}
          addSizesInBulk={addSizesInBulk}
          item={item}
          variation={variation}
          addSizeForVariation={addSizeForVariation}
          confirmTheyWantToUpdateShopify={confirmTheyWantToUpdateShopify}
        />
      }
    />
  );
};

const Sibling = props => {
  const { lookbook, user, sibling, isSizeVariation, deleteSiblingSize, deleteSibling } = props;
  const { tagParts, shopifyVariantId } = sibling;
  const isHidden = isSizeVariation || !sibling.size_variations.length ? sibling.isHidden : sibling.size_variations?.every(sv => sv.isHidden);
  const isAvailable = isSizeVariation || !sibling.size_variations.length ? sibling.isAvailable : sibling.size_variations?.some(sv => sv.isAvailable);
  const isShopifyDisconnected =
    isSizeVariation || !sibling.size_variations.length
      ? !sibling.shopifyVariantId
      : sibling.size_variations?.some(sv => !sv.shopifyVariantId) || !sibling.shopifyVariantId;

  const orderHandledByData = lookbookHelpers.getOrderHandledByData(user, lookbook);
  const showShopifyDisconnection = !!isShopifyDisconnected && orderHandledByData.handledBy === 'shopify';

  const edit = () => props.openSiblingEditor(sibling, isSizeVariation);
  const remove = () => (isSizeVariation ? deleteSiblingSize(sibling) : deleteSibling(sibling));
  const display = isSizeVariation ? tagParts.sizeTag || '-' : tagParts.nonSizeTag || 'Default';

  const classNames = { synced: shopifyVariantId, 'is-size': isSizeVariation };

  if (showShopifyDisconnection) classNames.disconnected = true;
  else {
    if (!isAvailable) classNames.unavailable = true;
    if (isHidden) classNames.hidden = true;
  }

  return (
    <div className={cn('sibling', classNames)}>
      <div className='display' onClick={edit}>
        {showShopifyDisconnection && <Tooltip message='Disconnected from Shopify' getIconDiv={() => <FontAwesomeIcon icon={faLinkSlash} />} />}
        {!isAvailable && !showShopifyDisconnection && (
          <Tooltip
            message={
              shopifyVariantId
                ? 'Currently out of stock based on Shopify inventory - this is currently synced overnight or upon request'
                : 'Currently out of stock'
            }
            getIconDiv={() => <FontAwesomeIcon icon={faBan} />}
          />
        )}
        {!!isHidden && !showShopifyDisconnection && <Tooltip message='Currently Hidden' getIconDiv={() => <FontAwesomeIcon icon={faEyeSlash} />} />}
        {display}
      </div>
      <div className='actions'>
        <div className='action' onClick={remove}>
          <FontAwesomeIcon icon={faTimes} />
          {!!isSizeVariation && props.rearrangeHandle}
        </div>
      </div>
    </div>
  );
};

const AddNewSizeSibling = props => {
  const { lookbook, item, variation, addSizeForVariation, confirmTheyWantToUpdateShopify } = props;
  const size_variations = _.filter(variation.size_variations || [], v => v.tagParts.sizeTag);

  const hasSizes = size_variations.length > 0;
  const sizesFromAbove = hasSizes ? null : lookbookHelpers.getFirstLookbookItemSizes(item);
  const standardSizes = lookbookHelpers.getLookbookStandardSizes(lookbook.items);
  const [showStandardSizes, setShowStandardSizes] = useState(false);

  const [isAddingSize, setIsAddingSize] = useState(false);
  const [newSize, setNewSize] = useState('');

  const addSize = () =>
    confirmTheyWantToUpdateShopify(async () => {
      const titleAlreadyExists = variation.size_variations.some(v => v.title === newSize);
      if (!newSize) return;
      else if (titleAlreadyExists) return cogoToast.warn('Title already exists');
      setNewSize('');

      setIsAddingSize(true);
      await addSizeForVariation(variation, newSize);
      setIsAddingSize(false);
    }, 'Since this lookbook is integrated with Shopify, this new custom size variation will not be connected to Shopify and will not be available to creators to select.');

  const submitNewSize = e => {
    e.preventDefault();
    addSize();
  };

  const handleSizeSetClick = sizes => {
    confirmTheyWantToUpdateShopify(async () => {
      if (isAddingSize) return;
      setIsAddingSize(true);
      await props.addSizesInBulk(variation, sizes);
      setIsAddingSize(false);
    });
  };

  if (isAddingSize)
    return (
      <div className='cell loading-size'>
        <Loader size={28} />
      </div>
    );

  return (
    <div>
      <form className='new-size-form' onSubmit={submitNewSize}>
        <input type='text' placeholder='New Size' defaultValue={newSize || ''} onChange={e => setNewSize(e.target.value)} />
        {!hasSizes && !newSize && (
          <>
            {showStandardSizes ? (
              <div className='standard-sizing-container'>
                <FontAwesomeIcon icon={faTimes} onClick={() => setShowStandardSizes(false)} />
                <div className='standard-sizing-options'>
                  {standardSizes.map(standardSizeSet => (
                    <Tooltip key={standardSizeSet.join(', ')} message={standardSizeSet.join(', ')}>
                      <button
                        onClick={e => {
                          e.preventDefault(); // prevent form submission
                          handleSizeSetClick(standardSizeSet);
                        }}
                      >
                        {standardSizeSet.join(', ')}
                      </button>
                    </Tooltip>
                  ))}
                </div>
              </div>
            ) : (
              <>
                {!!sizesFromAbove && (
                  <button
                    onClick={e => {
                      e.preventDefault(); // prevent form submission
                      handleSizeSetClick(sizesFromAbove);
                    }}
                  >
                    <Tooltip message={sizesFromAbove.join(', ')}>
                      <FontAwesomeIcon icon={faCopy} /> <span>Copy From Above</span>
                    </Tooltip>
                  </button>
                )}
                {!!standardSizes.length && (
                  <button onClick={() => setShowStandardSizes(true)}>
                    <FontAwesomeIcon icon={faPlus} /> <span>Standard Sizes</span>
                  </button>
                )}
              </>
            )}
          </>
        )}
      </form>
    </div>
  );
};

LookbookProductModalSiblings.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  syncLookbook: PropTypes.func.isRequired,
  addLookbookItemSibling: PropTypes.func.isRequired,
  updateLookbookItemSibling: PropTypes.func.isRequired,
  deleteLookbookItemSibling: PropTypes.func.isRequired
};

export default LookbookProductModalSiblings;
