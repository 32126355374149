import React from 'react';
import PropTypes from 'prop-types';
import './AboutDepartment.scss';

import SortableList from '../../../Components/General/SortableList';
import AboutMember from './AboutMember';

const AboutDepartment = props => {
  const { openTeamMemberModal } = props;
  const { department, members } = props.department;

  const getCard = (member, additionalProps = {}) => (
    <AboutMember member={member} rearrangeHandle={additionalProps.rearrangeHandle} openTeamMemberModal={openTeamMemberModal} />
  );

  return (
    <div className='about-department-outer'>
      <div className='about-department-inner'>
        <div className='department-name'>{department}</div>
        <SortableList
          isEditing
          containerClassName='department-members'
          items={members}
          updateItem={props.updateTeamMember}
          props={props}
          getCard={getCard}
        />
      </div>
    </div>
  );
};

AboutDepartment.propTypes = {
  department: PropTypes.object.isRequired,
  updateTeamMember: PropTypes.func.isRequired,
  openTeamMemberModal: PropTypes.func.isRequired
};

export default AboutDepartment;
