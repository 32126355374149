import _ from 'lodash';

import { getTalent as getTalentAPI } from '../APIClient/talent';
import { getBrandId } from '../Helpers/user_helpers';

export const GET_TALENT_REQUEST = 'GET_TALENT_REQUEST';
export const GET_TALENT_SUCCESS = 'GET_TALENT_SUCCESS';
export const GET_TALENT_FAILURE = 'GET_TALENT_FAILURE';

export const SYNC_TALENT_RESULT_SUCCESS = 'SYNC_TALENT_RESULT_SUCCESS';
export const SYNC_TALENT_RESULT_FAILURE = 'SYNC_TALENT_RESULT_FAILURE';
export const SYNC_TALENT_RESULT_REMOVE = 'SYNC_TALENT_RESULT_REMOVE';

export const getTalent = ({ isFetchingInitial = false } = {}) => async (dispatch, getState) => {
  dispatch({ type: GET_TALENT_REQUEST, isFetchingInitial: isFetchingInitial });
  try {
    const resp = await getTalentAPI(getBrandId(getState().user));
    return dispatch({
      type: GET_TALENT_SUCCESS,
      talent: resp.talent
    });
  } catch (error) {
    return dispatch({
      type: GET_TALENT_FAILURE,
      error
    });
  }
};

export const syncTalentResult = result => async (dispatch, getState) => {
  const { user } = getState();
  try {
    const resp = await getTalentAPI(getBrandId(user), { User_id: result.id });
    const newResult = _.first(resp.talent);
    if (newResult) {
      return dispatch({
        type: SYNC_TALENT_RESULT_SUCCESS,
        result: newResult
      });
    } else {
      return dispatch({
        type: SYNC_TALENT_RESULT_REMOVE,
        resultToRemove: result
      });
    }
  } catch (error) {
    return dispatch({
      type: SYNC_TALENT_RESULT_FAILURE,
      error
    });
  }
};
