import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './LatestSections.scss';

import LatestSection from './LatestSection';

const LatestSections = props => {
  const { user } = props;
  const { newsletter, activeTab, activeTag } = props.latest;

  let visibleSections = _.orderBy(newsletter.sections, 'sortOrderRank', 'asc').filter(section => {
    const sectionVisibleByTab = section.tabs.find(tab => tab.id === activeTab?.id);
    const sectionVisibleByTag = !activeTag || section.tags.find(tag => tag.id === activeTag?.id);
    const sectionShouldBeVisible = sectionVisibleByTab && sectionVisibleByTag;

    return sectionShouldBeVisible;
  });
  return (
    <div className='latest-sections-container'>
      <div className='latest-sections'>
        {visibleSections.map(section => {
          return (
            <LatestSection
              key={section.id}
              section={section}
              user={user}
              openBrandModal={props.openBrandModal}
              lastViewedContent={props.lastViewedContent}
              activeTab={activeTab}
            />
          );
        })}
      </div>
    </div>
  );
};

LatestSections.propTypes = {
  user: PropTypes.object.isRequired,
  latest: PropTypes.object.isRequired,
  lastViewedContent: PropTypes.any, // object or string as date
  openBrandModal: PropTypes.func.isRequired
};

export default LatestSections;
