import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './ArtistModalOrders.scss';

import { getPrettyPriceDisplay } from '../../Helpers/formatting';
import { portalBugOpenUrl, getRootSMSUrl } from '../../Helpers/helpers';
import { getBrand } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeature } from '../../Helpers/subscription_helpers';

const ArtistModalOrders = props => {
  const { user, orders } = props;
  const brand = getBrand(user);

  return (
    <div className='artist-modal-orders-container'>
      <div className='artist-modal-orders'>
        {orders.map(order => {
          const {
            id,
            transaction_date,
            pin,
            domain,
            merchant,
            Collection_id,
            Collection_name,
            Consult_id,
            Contract_id,
            Contract_title,
            Consult_title,
            commission_amount,
            order_amount
          } = order;
          const { image, title } = pin || {};
          const dateDisplay =
            moment().format('YYYY') === moment(transaction_date).format('YYYY')
              ? moment(transaction_date).format('MMMM Do')
              : moment(transaction_date).format('MMMM Do, YYYY');
          const titleDisplay = title || 'Deleted Link or Code';
          const collectionUrl = Collection_id && `${getRootSMSUrl()}/collections/${Collection_id}`;
          const consultUrl = Consult_id && `${getRootSMSUrl()}/consults/${Consult_id}`;
          const contractUrl = Contract_id && `${getRootSMSUrl()}/collaboration/${Contract_id}`;
          const contentUrl = collectionUrl || consultUrl || contractUrl;
          const contentDisplay = Collection_name || Contract_title || Consult_title || (pin ? 'Quick Link' : '-');

          const isCrossRetailer = domain && domain !== brand.domain && !brand.altDomains?.includes(domain);
          const canViewCrossRetailer = isSubscribedToFeature(user, 'CROSS_RETAILER_ANALYTICS');
          const hideDueToPermissions = isCrossRetailer && !canViewCrossRetailer;
          const blockedAlert = () => blockOnRequiringSubscription(user, 'CROSS_RETAILER_ANALYTICS');

          return (
            <div key={id} className='order'>
              <div className='main'>
                <div className='image-container'>{image ? <img src={image} alt={title} /> : <div className='empty' />}</div>
                <div className='data'>
                  <div className='created'>{dateDisplay}</div>
                  {contentUrl ? (
                    <a onClick={portalBugOpenUrl} href={contentUrl} target='_blank' rel='noopener noreferrer' className='content'>
                      {contentDisplay}
                    </a>
                  ) : (
                    <div className='content'>{titleDisplay}</div>
                  )}
                  <div className='title'>{titleDisplay}</div>
                  {isCrossRetailer && <div className='merchant'>{hideDueToPermissions ? 'Another Retailer' : merchant}</div>}
                </div>
              </div>
              {hideDueToPermissions ? (
                <>
                  <div onClick={blockedAlert} className='blocked-stats'>
                    <div className='msg'>Blocked</div>
                    <div className='learn-more'>Learn More</div>
                  </div>
                </>
              ) : (
                <>
                  <div className='stats'>
                    <div className='stat'>
                      <div className='value'>{getPrettyPriceDisplay(order_amount)}</div>
                      <div className='metric'>amount</div>
                    </div>
                    <div className='stat'>
                      <div className='value'>{getPrettyPriceDisplay(commission_amount)}</div>
                      <div className='metric'>commission</div>
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ArtistModalOrders.propTypes = {
  user: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired
};

export default ArtistModalOrders;
