import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import _ from 'lodash';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './Reducers/index';

// Attempt to grab the state from local storage
const parseObj = root => {
  let parsed;
  try {
    parsed = JSON.parse(root);
  } catch (error) {
    return root;
  }
  return _.reduce(
    parsed,
    (r, v, k) => ({
      ...r,
      [k]: typeof v === 'object' ? v : v && parseObj(v)
    }),
    {}
  );
};
const localStore = localStorage && localStorage.getItem('persist:root');
const preloadedStateLocalStorage = localStore && parseObj(localStore);
// const localStoreExtra = localStorage && localStorage.getItem('persist:root_extra');
// const preloadedStateLocalStorageExtra = localStoreExtra && parseObj(localStoreExtra);
// console.info({ preloadedStateLocalStorage, preloadedStateLocalStorageExtra });

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['talent', 'analytics', 'chats', 'latest', 'featured'] // reducers that are considered too big to be persisted, make sure to reload these on page load
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, preloadedStateLocalStorage || preloadedState, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store);

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: {
    ...store.getState(),
    _persist: undefined
  }
});

const result = { store, persistor };

export default result;
