import React, { useState } from 'react';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './ProductSocialContent.scss';

import { getYoutubeVideoIdFromUrl } from '../../Helpers/social_helpers';

const ProductSocialContent = props => {
  const { product } = props;
  const { social } = product;
  const [numVisibleContent, updateVisibleContent] = useState(4);

  const getVideoIdFromSocialLinks = social_links => {
    const links = social_links.split(',');
    const youtubeVidId = _.first(_.filter(_.map(links, getYoutubeVideoIdFromUrl)));
    return youtubeVidId;
  };

  const validSocial = _.filter(social, socialPost => getVideoIdFromSocialLinks(socialPost.social_links));
  if (!validSocial.length) return null;
  return (
    <div className='product-social-content-outer-container content-outer-container'>
      <div className='content-header-container'>
        <div className='content-header'>This product appears in</div>
      </div>
      <div className='social-content-container'>
        {social && !window.__IS_SNAP__ && (
          <>
            {validSocial.slice(0, numVisibleContent).map(content => {
              const { name, social_links, Collection_name, Collection_id, username } = content;
              const youtubeVidId = getVideoIdFromSocialLinks(social_links);
              return (
                <div key={name} className={cn('social-content', { 'only-one': validSocial.length === 1 })}>
                  <div className='youtube-video'>
                    <YouTube
                      videoId={youtubeVidId}
                      className='youtube-video'
                      onPlay={({ target, data }) => {}}
                      onEnd={({ target, data }) => {}}
                      onPause={({ target, data }) => {}}
                      opts={{
                        width: '100%',
                        playerVars: {
                          modestbrand: 1,
                          rel: 0
                        }
                      }}
                    />
                  </div>
                  <div className='social-user'>
                    <div className='featured-tag'>FEATURED IN</div>
                    <Link to={`/collections/${Collection_id}`} className='collection'>
                      {Collection_name}
                    </Link>
                    <Link to={`/${username}`} className='name'>
                      by {name}
                    </Link>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {validSocial.length > numVisibleContent && (
        <div onClick={() => updateVisibleContent(numVisibleContent + 3)} className='show-more-btn'>
          Show more
        </div>
      )}
    </div>
  );
};

ProductSocialContent.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductSocialContent;
