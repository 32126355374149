import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import './Press.scss';
import './Basic.scss';

class Press extends Component {
  render() {
    return (
      <div className='press-outer-container basic-outer-container'>
        <MetaTags>
          <title>Press | ShopMy</title>
          <meta property='description' content='For all press inquiries, please contact press@shopmy.us' />
        </MetaTags>
        <div className='basic-header-container narrow'>
          <div className='basic-header-title-badge-container'>
            <div className='basic-header-title-badge'>PRESS</div>
          </div>
          <h2 className='basic-header-body simple'>
            For all press inquiries, please contact <span className='email'>press@shopmy.us</span>.
          </h2>
        </div>
      </div>
    );
  }
}

export default Press;
