import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './RequestResultOld.scss';

import Tooltip from '../General/Tooltip';
import Loader from '../Loader/Loader';

const RequestResultOld = props => {
  const { request, action, action_label } = props;
  const { createdAt, userAccepted, isComplete, userRejected, brandRejected, brandAccepted, User_id, address, phone, name, brandMessage } = request;

  const [isTakingAction, setIsTakingAction] = useState(false);

  const takeAction = async () => {
    setIsTakingAction(true);
    action && (await action(request));
    setIsTakingAction(false);
  };

  const status = isComplete
    ? 'Sent Product'
    : userRejected
    ? 'Not Currently Interested'
    : brandRejected
    ? 'Rejected Request'
    : brandAccepted && !userAccepted
    ? 'Awaiting Response'
    : brandAccepted && userAccepted
    ? 'Accepted, Awaiting Send'
    : '-';
  const statusClass = isComplete
    ? 'sent'
    : userRejected
    ? 'rejected'
    : brandRejected
    ? 'rejected'
    : brandAccepted && !userAccepted
    ? 'pending'
    : brandAccepted && userAccepted
    ? 'pending'
    : 'unknown';

  const needsAction = ['Accepted, Awaiting Send'].includes(status);

  const selectUser = async () => props.openArtistModal({ id: User_id });

  return (
    <div className={cn('request-result row', { 'needs-action': needsAction })}>
      <div className='cell'>{moment(createdAt).format('MMM Do, YYYY')}</div>
      <div onClick={selectUser} className='cell name'>
        {name}
      </div>
      <div className='cell address'>
        {userAccepted ? address || '-' : '-'}
        {userAccepted && phone ? ` (phone: ${phone})` : '-'}
      </div>
      <div className={cn('cell', 'status', statusClass, { 'with-icon': brandMessage })}>
        {status}
        {brandMessage && (
          <Tooltip
            message={`Sent the following message: ${brandMessage}`}
            getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>}
          />
        )}
      </div>
      <div
        onClick={takeAction}
        className={cn('cell action', {
          enabled: action,
          'taking-action': isTakingAction,
          'needs-action': needsAction
        })}
      >
        {action_label || '-'}
        {isTakingAction && <Loader size={60} />}
      </div>
    </div>
  );
};

RequestResultOld.propTypes = {
  request: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  action: PropTypes.func,
  action_label: PropTypes.string
};

export default RequestResultOld;
