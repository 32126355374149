import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookReportProducts.scss';
import _ from 'lodash';
import moment from 'moment';

import { getAllLookbookOrderItems, getLookbookItemOrderSiblingBreakdown, getLookbookRequests } from '../../../../Helpers/lookbook_helpers';
import LookbookReportProduct from './LookbookReportProduct';
import { lookbookReportItemFilter } from '../../../../Helpers/lookbook_helpers';
import { downloadCSVFromArray } from '../../../../Helpers/helpers';

const LookbookReportProducts = props => {
  const { lookbook, analytics, startDate, endDate } = props;
  const lookbookRequests = getLookbookRequests(analytics, lookbook);
  const requests = lookbookRequests.filter(r => lookbookReportItemFilter(r, 'request', startDate, endDate));

  const [viewAllProducts, setViewAllProducts] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const allLookbookOrderItems = getAllLookbookOrderItems(requests);
  const formattedOrderItems = lookbook.items
    .map(item => {
      const orderItems = allLookbookOrderItems.filter(orderItem => orderItem.LookbookItem_id === item.id);
      if (!orderItems.length) return null;

      const totalQuantity = _.sumBy(orderItems, 'quantity');
      const siblingBreakdown = getLookbookItemOrderSiblingBreakdown(item, requests);
      let displaySibling;
      if (siblingBreakdown.length === 1) displaySibling = siblingBreakdown[0].sibling;
      else if (siblingBreakdown.length > 1) {
        // get the sibling with the most orders
        const siblingsSortedByOrders = _.orderBy(siblingBreakdown, 'quantity', 'desc');
        displaySibling = siblingsSortedByOrders[0].sibling;
      } else {
        displaySibling = item;
      }

      return { ...item, totalQuantity, orderItems, siblingBreakdown, displaySibling };
    })
    .filter(item => !!item)
    .sort((a, b) => b.totalQuantity - a.totalQuantity);

  const downloadLookbookProductReport = async () => {
    setIsDownloading(true);
    const { items } = lookbook;

    const csvRows = [];
    formattedOrderItems.forEach(item => {
      const { title, url, price, siblingBreakdown, numTimesOrdered } = item;

      for (const sibling of siblingBreakdown) {
        csvRows.push({
          Title: title,
          Variation: sibling?.sibling?.tag || 'Default',
          Orders: sibling?.quantity,
          Url: sibling?.sibling?.url || url,
          Price: price
        });
      }

      if (!siblingBreakdown.length) {
        csvRows.push({
          Title: title,
          Variation: 'Default',
          Orders: numTimesOrdered,
          Url: url,
          Price: price
        });
      }
    });

    // now iterate through items without orders
    for (const item of items) {
      for (const sibling of item.reduced_siblings) {
        const title = item.title;
        const tag = sibling.tag || 'Default';
        if (!csvRows.find(row => row.Title === title && row.Variation === tag)) {
          csvRows.push({
            Title: title,
            Variation: tag,
            Orders: 0,
            Url: sibling.url || item.url,
            Price: item.price
          });
        }
      }
    }

    downloadCSVFromArray(csvRows, `${moment().format('MMM DD')} ${lookbook.title} Product Report .csv`);
    setIsDownloading(false);
  };

  return (
    <div className='lookbook-report-products-outer'>
      <div className='lookbook-report-products-inner'>
        <div className='lookbook-report-product-button-container'>
          <div className='lookbook-report-download-button' onClick={downloadLookbookProductReport}>
            {isDownloading ? 'Downloading...' : 'Download'}
          </div>
        </div>
        {formattedOrderItems.map((product, i) => {
          // if not viewing all products only show one row (4 products)
          if (!viewAllProducts && i > 3) return null;
          return <LookbookReportProduct lookbook={lookbook} product={product} key={`lookbook-report-product-${product.id}`} />;
        })}

        {formattedOrderItems.length > 4 && (
          <div className='view-more-button' onClick={() => setViewAllProducts(!viewAllProducts)}>
            {viewAllProducts ? 'View Less' : 'View More'}
          </div>
        )}
      </div>
    </div>
  );
};

LookbookReportProducts.propTypes = {
  lookbook: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default LookbookReportProducts;
