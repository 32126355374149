import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import './DiscoverFeatureActions.scss';

import { ActionsOverlay } from '../../Modals/ActionsOverlay';

const DiscoverFeatureActions = props => {
  const { feature } = props;

  const actionOptions = [
    {
      display: 'Delete Feature',
      icon: 'faTrash',
      onClick: () => props.deleteDiscoverFeature(feature)
    },
    {
      display: feature.isHidden ? 'Make Visible' : 'Hide',
      icon: feature.isHidden ? 'faEye' : 'faEyeSlash',
      onClick: () => props.updateDiscoverFeature(feature, { isHidden: !feature.isHidden })
    },
    ...(feature.isHero || feature.isPopular
      ? [
          {
            display: 'Make Regular',
            icon: 'faUser',
            onClick: () => props.updateDiscoverFeature(feature, { isHero: false, isPopular: false })
          }
        ]
      : [
          {
            display: 'Make Hero',
            icon: 'faStar',
            onClick: () => props.updateDiscoverFeature(feature, { isHero: true, isPopular: false })
          },
          {
            display: 'Make Popular',
            icon: 'faFire',
            onClick: () => props.updateDiscoverFeature(feature, { isHero: false, isPopular: true })
          }
        ])
  ];
  return (
    <div className='discover-feature-actions-container'>
      <ActionsOverlay openUp containerClassName='action-button' options={actionOptions}>
        <FontAwesomeIcon icon={faEllipsisH} />
      </ActionsOverlay>
    </div>
  );
};

DiscoverFeatureActions.propTypes = {
  feature: PropTypes.object.isRequired,
  deleteDiscoverFeature: PropTypes.func.isRequired,
  updateDiscoverFeature: PropTypes.func.isRequired
};

export default DiscoverFeatureActions;
