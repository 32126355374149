import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './Checkbox.scss';

const Checkbox = props => {
  return (
    <div
      className={cn('checkbox-outer-container', props.className || '', {
        selected: props.selected,
        disabled: props.disabled
      })}
      onClick={props.onToggle}
    >
      <div className='checkbox'>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <div className='checkbox-label-container'>
        <div className='checkbox-label-container-label'>{props.label}</div>
        {props.sublabel && <div className='checkbox-label-container-sublabel'>{props.sublabel}</div>}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  className: PropTypes.string
};

export default Checkbox;
