import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './LatestHeader.scss';

import { setUrlParam, removeUrlParam } from '../../Helpers/helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getPossibleTagsForTab } from '../../Helpers/latest_helpers';

const LatestHeader = props => {
  const { ui, latest } = props;
  const { newsletter, activeTab, activeTag } = latest;
  const tabs = _.orderBy(newsletter?.tabs || [], 'sortOrderRank');
  const isFetching = !newsletter;
  const possibleTagsForTab = getPossibleTagsForTab(newsletter, activeTab);

  // Used for animation controls
  const [isInitialLoad, setIsInitialLoad] = React.useState(!newsletter);
  React.useEffect(() => {
    setTimeout(() => setIsInitialLoad(false), 1000);
  }, []);

  // Handle Admin Reloads
  const [isReloading, setIsReloading] = React.useState(false);
  const reloadPage = async () => {
    setIsReloading(true);
    await props.syncNewsletter();
    setIsReloading(false);
  };

  const selectTab = tab => {
    selectTag(null);
    props.setActiveTab(tab);
    setUrlParam('tab', tab.title);
    window.__ADD_EVENT__('Latest - Click Tab', { tab: tab.title });
  };

  const selectTag = tag => {
    props.setActiveTag(tag);
    tag ? setUrlParam('tag', tag.title) : removeUrlParam('tag');
    window.__ADD_EVENT__('Latest - Click Tag', { tag });
  };

  const additionalClasses = { fetching: isFetching, 'initial-load': isInitialLoad };
  return (
    <div className='latest-header-container'>
      {isAdminControlMode(ui) && (
        <div className='reload-btn' onClick={reloadPage}>
          {isReloading ? 'Reloading' : 'Reload'}
          <FontAwesomeIcon icon={faSync} spin={isReloading} />
        </div>
      )}
      <div className={cn('header-container', additionalClasses)}>
        <div className='badge'>{moment().format('MMMM Do')}</div>
        <div className={cn('header', additionalClasses)}>Latest on ShopMy</div>
      </div>

      <div className='tabs'>
        {isFetching ? (
          <>
            {new Array(7).fill(0).map((_, i) => (
              <div key={i} className='tab-container empty'>
                <div className='tab'>Loading</div>
              </div>
            ))}
          </>
        ) : (
          tabs.map(tab => {
            const { id, title } = tab;
            const isActive = activeTab?.id === id;

            return (
              <div key={id} onClick={() => selectTab(tab)} className={cn('tab-container', { active: isActive, ...additionalClasses })}>
                <div className={isActive ? 'tab active' : 'tab'}>{title}</div>
              </div>
            );
          })
        )}
      </div>

      <div className='divider' />

      {!!possibleTagsForTab?.length && (
        <div className='latest-tag-container'>
          <div onClick={() => selectTag(null)} className={cn('tag', { active: !activeTag?.id })}>
            All
          </div>

          {possibleTagsForTab.map(tag => {
            const isActive = activeTag?.id === tag.id;

            return (
              <div key={`tag-${tag.id}`} onClick={() => selectTag(tag)} className={cn('tag', { active: isActive })}>
                {tag.title}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

LatestHeader.propTypes = {
  ui: PropTypes.object.isRequired,
  latest: PropTypes.object.isRequired,
  syncNewsletter: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setActiveTag: PropTypes.func.isRequired,
  activeTab: PropTypes.object
};

export default LatestHeader;
