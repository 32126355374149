import React, { useState, useEffect } from 'react';
import './PlacesAutocompleteLoader.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Loader from '../Loader/Loader';

const PlacesAutocompleteLoader = props => {
  const { searchValue, onSearchChange, onAddressSelect, searchOptions } = props;

  /*
   * IMPORTANT
   * Google Maps JS API must be loaded before using PlacesAutocomplete. It is required for react-places-autocomplete package
   * Track in state, load it, then render PlacesAutocomplete
   */
  const [isLoadingGoogleMapsApi, setIsLoadingGoogleMapsApi] = useState(true);
  const [apiLoadSuccessful, setApiLoadSuccessful] = useState(false);

  const handleSelectAddress = async address => {
    const results = await geocodeByAddress(address);
    const components = results[0].address_components;

    onAddressSelect(components);
  };

  useEffect(() => {
    loadGoogleMapsAPI();
  }, []);

  const loadGoogleMapsAPI = async () => {
    try {
      const mapsLoader = new GoogleMapsLoader({
        apiKey: 'AIzaSyDF4L1YomRa_ZqquPlbj38A_ld0zbvxNaM',
        version: 'weekly',
        libraries: ['places']
      });

      await mapsLoader.importLibrary('places');
      setApiLoadSuccessful(true);
    } catch (e) {
      console.error('Error loading Google Maps API: ', e);
      cogoToast.error('Error loading address search, please try again soon.', { hideAfter: 5 });
      setApiLoadSuccessful(false);
    } finally {
      setIsLoadingGoogleMapsApi(false);
    }
  };

  return (
    <div className='places-autocomplete-loader-container'>
      {isLoadingGoogleMapsApi && <Loader size={60} />}
      {!isLoadingGoogleMapsApi && !apiLoadSuccessful && <div className='error'>There is an issue loading address search, please try again soon.</div>}
      {!isLoadingGoogleMapsApi && apiLoadSuccessful && (
        <PlacesAutocomplete value={searchValue} onChange={onSearchChange} onSelect={handleSelectAddress} searchOptions={searchOptions}>
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className='autocomplete-container'>
              <input
                {...getInputProps({
                  placeholder: 'Search Addresses...',
                  className: 'location-search-input'
                })}
              />
              {suggestions.length > 0 && (
                <div className={cn('autocomplete-dropdown-container', { loading })}>
                  {suggestions.map(suggestion => {
                    return (
                      <div
                        key={suggestion.index}
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active ? 'autocomplete-item active' : 'autocomplete-item'
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  );
};

PlacesAutocompleteLoader.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  searchOptions: PropTypes.object
};

export default PlacesAutocompleteLoader;
