import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import './MentionFilters.scss';

import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getDisplayForSocialPlatform } from '../../Helpers/social_helpers';
import { getOpportunities } from '../../Helpers/user_helpers';

import Loader from '../Loader/Loader';
import InputActions from '../General/InputActions';

import MentionAdminFilters from './Elements/MentionAdminFilters';
import MentionResultsSummary from './Elements/MentionResultsSummary';

const BASE_NUM_VISIBLE = 5;
const NUM_PER_FILTER_PAGE = 15;

const MentionFilters = props => {
  const { ui, user, curFilters, setCurFilters, filterCounts, isFetchingFirstPage } = props;
  const { users, lists, tags, platforms, subtypes, lookbooks, opportunities } = filterCounts;
  const setFilter = (key, value) => {
    if (props.blockUsage()) return null;
    const firstPageFilters = { ...curFilters, page: 0 };
    setCurFilters(value ? { ...firstPageFilters, [key]: value } : _.omit(firstPageFilters, key));
  };
  const setFilters = newFilters => {
    if (props.blockUsage()) return null;
    setCurFilters({ ...curFilters, ...newFilters, page: 0 });
  };
  const [numVisiblePerDisplay, setNumVisiblePerDisplay] = useState({});
  const setNumVisible = (section, numVisible) => {
    setNumVisiblePerDisplay({ ...numVisiblePerDisplay, [section.display]: numVisible });
  };
  const [curSearchVal, setCurSearchVal] = useState('');

  const sections = [
    {
      title: 'Platforms',
      values: platforms,
      filterVariable: 'platform',
      getValueDisplay: v => getDisplayForSocialPlatform(v.platform)
    },
    {
      title: 'Content Type',
      values: subtypes,
      filterVariable: 'subtype',
      getValueDisplay: v => v.title
    },
    {
      title: 'Tags / Source',
      onPressEdit: props.toggleEditingTags,
      // subtitle: (
      //   <span onClick={props.toggleEditingTags}>
      //     Click <span className='open-modal-btn'>here</span> to edit your tags.
      //   </span>
      // ),
      values: tags,
      // filterVariable: 'tagFound', // All custom, so not needed
      getValueDisplay: v => {
        if (v.tagFound) return v.tagFound;
        if (v.Collection_id) return 'Shoppable Collection';
        if (v.QuickLinkPin_id) return 'Only Affiliate Link';
        return 'Unknown Source';
      },
      customIsSelected: value => {
        if (value.tagFound) return curFilters.tagFound && value.tagFound === curFilters.tagFound;
        if (value.Collection_id) return curFilters.viaCollectionsNoTag;
        if (value.QuickLinkPin_id) return curFilters.viaQuickLinksOnly;
      },
      customIsAnotherSelected: value => {
        if (value.tagFound) return curFilters.tagFound && curFilters.tagFound !== value.tagFound;
        if (value.Collection_id) return curFilters.tagFound || curFilters.viaQuickLinksOnly;
        if (value.QuickLinkPin_id) return curFilters.tagFound || curFilters.viaCollectionsNoTag;
      },
      customSetFilter: value => {
        if (value.tagFound) {
          const isSelected = curFilters.tagFound && value.tagFound === curFilters.tagFound;
          return setFilter('tagFound', isSelected ? null : value.tagFound);
        } else if (value.Collection_id) {
          setFilter('viaCollectionsNoTag', !curFilters.viaCollectionsNoTag);
        } else if (value.QuickLinkPin_id) {
          setFilter('viaQuickLinksOnly', !curFilters.viaQuickLinksOnly);
        }
      }
    },
    {
      title: 'Talent',
      values: users,
      filterVariable: 'User_id',
      getValueDisplay: v => v.name
    },
    {
      title: 'Opportunities',
      values: opportunities,
      filterVariable: 'Opportunity_id',
      getValueDisplay: v => v.title,
      customSetFilter: value => {
        const isSelected = curFilters.Opportunity_id && value.Opportunity_id === curFilters.Opportunity_id;
        const opportunity = getOpportunities(user).find(o => o.id === value.Opportunity_id);
        const isStartTrackingWindowBeforeNow = moment(opportunity.trackingStartsAt).isBefore(moment());
        const isEndTrackingWindowBeforeNow = moment(opportunity.trackingEndsAt).isBefore(moment());

        const updates = {};
        if (isSelected) {
          updates.Opportunity_id = null;
          updates.startDate = null;
          updates.endDate = null;
        } else {
          updates.Opportunity_id = value.Opportunity_id;
          if (isStartTrackingWindowBeforeNow) updates.startDate = moment(opportunity.trackingStartsAt).format('YYYY-MM-DD');
          if (isEndTrackingWindowBeforeNow) updates.endDate = moment(opportunity.trackingEndsAt).format('YYYY-MM-DD');
          (isStartTrackingWindowBeforeNow || isEndTrackingWindowBeforeNow) && window.ALERT.info(`Automatically set date ranges to tracking window.`);
        }
        setFilters(updates);
      }
    },
    {
      title: 'Content From Your Lists',
      values: lists,
      filterVariable: 'BrandList_id',
      getValueDisplay: v => v.title
    },
    {
      title: 'Lookbooks',
      values: lookbooks,
      filterVariable: 'Lookbook_id',
      getValueDisplay: v => v.title
    }
  ]
    .filter(s => !s.isHidden)
    .map(s => ({
      ...s,
      values: _.filter(s.values || [], v => {
        // Ensure it passes search filter
        if (curSearchVal) {
          return s
            .getValueDisplay(v)
            .toLowerCase()
            .includes(curSearchVal.toLowerCase());
        }
        return true;
      }),
      numVisible: numVisiblePerDisplay[s.display] || BASE_NUM_VISIBLE
    }))
    .filter(s => !!s.values.length);

  return (
    <div className='mention-filters-outer-container'>
      <div className='mentions-filter-search-input-container has-actions'>
        <input className='search-input' placeholder='Search Filters' onChange={e => setCurSearchVal(e.target.value)} value={curSearchVal} />
        <InputActions searchVal={curSearchVal} onCancel={() => setCurSearchVal('')} />
      </div>
      <div className='mention-filters-inner-container'>
        <div className='sections'>
          {sections.length
            ? sections.map(section => {
                const {
                  title,
                  subtitle,
                  onPressEdit,
                  values,
                  filterVariable,
                  getValueDisplay,
                  numVisible,
                  emptyDisplay,
                  customSetFilter,
                  customIsSelected,
                  customIsAnotherSelected
                } = section;
                const hasMoreThanBaseVisible = BASE_NUM_VISIBLE < values.length;
                const canShowLess = BASE_NUM_VISIBLE !== numVisible;
                const canShowMore = numVisible < values.length;
                const toggleLess = () => setNumVisible(section, numVisible - NUM_PER_FILTER_PAGE);
                const toggleMore = () => setNumVisible(section, numVisible + NUM_PER_FILTER_PAGE);
                const toggleAll = () => setNumVisible(section, values.length);
                return (
                  <div key={section.title} className='section'>
                    <div className='section-title-container'>
                      <div onClick={onPressEdit} className='section-title'>
                        {title}
                        {onPressEdit && (
                          <div className='edit-btn'>
                            <FontAwesomeIcon icon={faPencil} className='section-title-icon' />
                          </div>
                        )}
                      </div>
                      {subtitle && <div className='section-subtitle'>{subtitle}</div>}
                    </div>
                    <div className='values'>
                      {values.slice(0, numVisible || 1e6).map(value => {
                        const { count } = value;
                        const isSelected = customIsSelected ? customIsSelected(value) : curFilters[filterVariable] && value[filterVariable] === curFilters[filterVariable]; // prettier-ignore
                        const isAnotherSelected = customIsAnotherSelected
                          ? customIsAnotherSelected(value)
                          : !!curFilters[filterVariable] && !isSelected;
                        const toggleValue = () =>
                          customSetFilter ? customSetFilter(value) : setFilter(filterVariable, isSelected ? null : value[filterVariable]);
                        const displayValue = getValueDisplay(value);
                        const extraClasses = { selected: isSelected, 'another-selected': isAnotherSelected, fetching: isFetchingFirstPage };
                        return (
                          <div key={displayValue} onClick={toggleValue} className={cn('value', extraClasses)}>
                            <div className={cn('display', extraClasses)}>{displayValue || emptyDisplay}</div>
                            <div className={cn('count', extraClasses)}>
                              {count}
                              {isFetchingFirstPage ? <Loader size={24} /> : null}
                            </div>
                          </div>
                        );
                      })}
                      {hasMoreThanBaseVisible && (
                        <div className='toggle-num-visible'>
                          {canShowLess && (
                            <div onClick={toggleLess} className='toggle'>
                              SHOW LESS
                            </div>
                          )}
                          {canShowMore && (
                            <div onClick={toggleMore} className='toggle'>
                              SHOW MORE
                            </div>
                          )}
                          {canShowMore && canShowLess && (
                            <div onClick={toggleAll} className='toggle'>
                              SHOW ALL
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            : !props.isFetchingMentions && (
                <div className='section empty'>
                  <div className='empty-filters-message'>
                    {curSearchVal ? `There are no filters with the search term "${curSearchVal}"` : 'No filters to display'}
                  </div>
                </div>
              )}
          {props.summary && !curSearchVal && !!props.mentions?.length && (
            <div className='section'>
              <div className='section-title-container'>
                <div className='section-title'>SUMMARY</div>
              </div>
              <MentionResultsSummary summary={props.summary} />
            </div>
          )}
          {isAdminControlMode(ui) && !curSearchVal && (
            <div className='section'>
              <div className='section-title-container'>
                <div className='section-title'>ADMIN CONTROLS</div>
              </div>
              <MentionAdminFilters curFilters={curFilters} setCurFilters={setCurFilters} brandAndTargetOptions={props.brandAndTargetOptions} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MentionFilters.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  isFetchingMentions: PropTypes.bool.isRequired,
  summary: PropTypes.object,
  curFilters: PropTypes.object.isRequired,
  filterCounts: PropTypes.object.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  setFilterCounts: PropTypes.func.isRequired,
  toggleEditingTags: PropTypes.func.isRequired,
  brandAndTargetOptions: PropTypes.object.isRequired,
  blockUsage: PropTypes.func.isRequired
};

export default MentionFilters;
