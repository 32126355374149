import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomeQuotesPanel.scss';

import icons from '../../../static/icons';

const HomeQuotesPanel = props => {
  const { quotes } = props;
  const [activeQuoteIdx, setActiveQuoteIdx] = React.useState(0);
  if (!quotes.length) return null;
  const quote = quotes[activeQuoteIdx];
  return (
    <div className='home-quotes-panel-outer-container'>
      <div className='home-quotes-panel-inner-container'>
        <div className='quote-container'>
          <div className='quotation-mark'>
            <img src={icons.misc.quote2} alt='quotation mark' />
          </div>
          <div className='quote-and-speaker'>
            <div className='quote'>{quote.quote}</div>
            {quote.to ? (
              <Link to={quote.to} className='speaker'>
                {quote.speaker}
              </Link>
            ) : (
              <div className='speaker'>{quote.speaker}</div>
            )}
            <div className='speaker-bio'>{quote.speaker_bio}</div>
          </div>
        </div>
        {quotes.length > 1 && (
          <div className='quote-selector-dots'>
            {quotes.map((quote, idx) => (
              <div key={idx} className={cn('quote-selector-dot', { active: activeQuoteIdx === idx })} onClick={() => setActiveQuoteIdx(idx)} />
            ))}
          </div>
        )}
      </div>
      <div className='home-quotes-panel-background'>
        <img
          src={props.isBrandPanel ? 'https://static.myshlf.us/Home/Brand_Quotes.png' : 'https://static.myshlf.us/Home/Quotes.png'}
          alt='ShopMy Talent'
        />
      </div>
    </div>
  );
};

HomeQuotesPanel.propTypes = {
  quotes: PropTypes.array.isRequired,
  isBrandPanel: PropTypes.bool
};

export default HomeQuotesPanel;
