import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './BulkRequestModalSearch.scss';
import cn from 'classnames';
import _ from 'lodash';

import BulkRequestModalSearchResults from './BulkRequestModalSearchResults';

import { fetchAlgoliaIndex } from '../../Helpers/search_helpers';
import { getTalent as getTalentAPI } from '../../APIClient/talent';
import { sortBrandListsByRecency } from '../../Helpers/brand_helpers';
import { copyToClipboard } from '../../Helpers/helpers';
import { createLookbookInvite } from '../../APIClient/lookbook_invites';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';

const BulkRequestModalSearch = props => {
  const { brand, lists, talent, openArtistModal, findIfUserAlreadyReceivedRequestOfType, selectedLookbookId } = props;
  const { selectUsers, removeUser, selectedUserIdSet, selectedEmailSet } = props;
  const { curSearchVal, setCurSearchVal, isFocused, handleFocused, handleBlurred } = props;
  const shopmyUserIndex = fetchAlgoliaIndex('sms_users_production');

  const basicListFilter = list => list?.users?.length;

  const [isSearching, setIsSearching] = useState(false);
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [listSearchResults, setListSearchResults] = useState([
    ...sortBrandListsByRecency(lists)
      .filter(basicListFilter)
      .slice(0, 10)
  ]);

  const [isFetchingTalent, setIsFetchingTalent] = useState(false);
  const [brandListUsers, setBrandListUsers] = useState([]);

  const [selectedList, setSelectedList] = useState(null);
  const resetSelectedList = () => setSelectedList(null);

  const searchForTalent = async searchValue => (await shopmyUserIndex.search(searchValue, { hitsPerPage: 10 })).hits;
  const searchInLists = async searchValue => lists.filter(basicListFilter).filter(list => list.title.toLowerCase().includes(searchValue.toLowerCase())).slice(0, 10); // prettier-ignore

  const [generatedLinks, setGeneratedLinks] = useState([]);
  const [showGenerateLinks, setShowGenerateLinks] = useState(false);
  const openGenerateLinks = () => setShowGenerateLinks(true);
  const closeGenerateLinks = () => setShowGenerateLinks(false);

  const generateInvite = async () => {
    const invite = await createLookbookInvite({ Lookbook_id: selectedLookbookId });
    setGeneratedLinks([...generatedLinks, invite]);
    copyToClipboard(`https://shopmy.us/lookbooks/${selectedLookbookId}?invite=${invite.hash}`, true, 'Copied Invite URL ');
  };

  const addSpecifiedUsers = users => {
    const filteredUsers = users.filter(user => !findIfUserAlreadyReceivedRequestOfType(user));
    for (const user of filteredUsers) {
      if (user.id && !selectedUserIdSet.has(user.id)) selectUsers([user]);
      else if (user.id && selectedUserIdSet.has(user.id)) removeUser(user);
      else if (user.email && !selectedEmailSet.has(user.email)) selectUsers([user]);
      else if (user.email && selectedEmailSet.has(user.email)) removeUser(user);
    }
  };

  const handleEnter = () => {
    if (selectedList) return addEveryoneInList();
    else if (!userSearchResults.length && !listSearchResults.length && !curSearchVal.includes('@')) return;
    else if (userSearchResults.length) return addSpecifiedUsers(userSearchResults.slice(0, 1));
    else if (listSearchResults.length) return setSelectedList(listSearchResults[0]);
    else if (curSearchVal.includes('@')) return addSpecifiedUsers(curSearchVal.split(',').map(email => ({ email })));
  };

  useEffect(() => {
    if (!selectedList) return;

    const getBrandListUsers = async () => {
      const userIdsNeeded = selectedList?.users?.map(listUser => listUser.User_id);
      const usersFromTalentObject = talent.filter(t => userIdsNeeded.includes(t.id));
      const userIdsFoundSet = new Set(usersFromTalentObject.map(u => u.id));

      let finalUsers = [...usersFromTalentObject];
      const needToFetchMoreUsers = userIdsNeeded.length > userIdsFoundSet.size;

      if (needToFetchMoreUsers) {
        setIsFetchingTalent(true);
        const missingIds = userIdsNeeded.filter(id => !userIdsFoundSet.has(id)).join(',');

        try {
          const resp = await getTalentAPI(brand.id, { User_ids: missingIds });
          finalUsers = [...finalUsers, ...resp.talent];
        } catch (error) {
          window.ALERT.warn('Had an issue auto-selecting those users, please try again.');
        } finally {
          setIsFetchingTalent(false);
        }
      }
      setBrandListUsers(_.orderBy(finalUsers, 'score', 'desc'));
    };

    getBrandListUsers();
  }, [selectedList]);

  const debounce = React.useRef(null);
  const getSearchResults = async e => {
    const searchValue = e.target.value || '';
    setCurSearchVal(searchValue);
    setIsSearching(true);
    clearTimeout(debounce.current);

    debounce.current = setTimeout(async () => {
      try {
        const [talent, lists] = await Promise.all([searchForTalent(searchValue), searchInLists(searchValue)]);
        setUserSearchResults(talent);
        setListSearchResults(lists);
      } catch (e) {
        cogoToast.error('Error searching for talent.');
        setUserSearchResults([]);
        setListSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    }, 500);
  };

  const addEveryoneInList = () => {
    if (!selectedList) return;
    const usersToAdd = brandListUsers
      .filter(user => !findIfUserAlreadyReceivedRequestOfType(user))
      .map(user => ({ ...user, brandList: selectedList }));
    selectUsers(usersToAdd);
  };

  return (
    <div className='bulk-request-modal-search-outer' onClick={handleFocused}>
      <div className={cn('bulk-request-modal-search-inner', { isFocused })}>
        <div className={cn('bulk-request-modal-search-input-outer', { 'no-results': !selectedUserIdSet.size, isFocused })}>
          <input
            className={cn('bulk-request-modal-search-input', { 'no-results': !selectedUserIdSet.size })}
            placeholder={isFocused ? 'Search for Recipient or List or Add Email' : '+ Add Recipient or List'}
            value={curSearchVal}
            onChange={getSearchResults}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                handleEnter();
              }
            }}
          />
          {!isFocused && selectedLookbookId && selectedUserIdSet.size === 0 && selectedEmailSet.size === 0 && props.isGiftingRequestType && (
            <>
              <span className='generate-invite-link-filler-text'>OR</span>
              <button
                className='generate-invite-link-button'
                onClick={e => {
                  e.stopPropagation();
                  showGenerateLinks ? closeGenerateLinks() : openGenerateLinks();
                }}
              >
                Generate Invite Link
              </button>
            </>
          )}
          <div className={cn('bulk-request-modal-search-buttons', { isFocused })}>
            {selectedList && (
              <div className='bulk-request-modal-search-button' onClick={addEveryoneInList}>
                Add All Results
              </div>
            )}
            <div className='bulk-request-modal-search-button done' onClick={selectedList ? resetSelectedList : handleBlurred}>
              {selectedList ? 'Back' : 'Done'}
            </div>
          </div>
        </div>
        {showGenerateLinks && !isFocused && (
          <div className='generate-invite-container' onClick={e => e.stopPropagation()}>
            <div className='generate-invite-container-title'>Generate Invite Link</div>
            <div className='generate-invite-container-subtitle'>
              These invitation links can be used to send your lookbook to talent who are not currently on the ShopMy platform. Each link can only be
              used once to accept the lookbook. These do not count towards your request sending limit until they are either viewed by existing ShopMy
              users or accepted by non-ShopMy users.
            </div>

            <div className='generated-links-container'>
              {generatedLinks.map(invite => {
                return (
                  <div
                    className='generated-link'
                    key={`generated-invite-id-${invite.id}`}
                    onClick={() =>
                      copyToClipboard(`https://shopmy.us/lookbooks/${selectedLookbookId}?invite=${invite.hash}`, true, 'Copied Invite URL')
                    }
                  >
                    <div className='generated-link'>{`https://shopmy.us/lookbooks/${selectedLookbookId}?invite=${invite.hash}`}</div>
                    <FontAwesomeIcon icon={faCopy} />
                  </div>
                );
              })}
            </div>
            <button className='generate-invite-link-button' onClick={generateInvite}>
              Generate Invite Link
            </button>

            <button className='generate-invite-link-button done-button' onClick={closeGenerateLinks}>
              Done
            </button>
          </div>
        )}
        <BulkRequestModalSearchResults
          isVisible={isFocused}
          listSearchResults={listSearchResults}
          userSearchResults={userSearchResults}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          talent={talent}
          brand={brand}
          isSearching={isSearching}
          selectUsers={selectUsers}
          removeUser={removeUser}
          selectedUserIdSet={selectedUserIdSet}
          selectedEmailSet={selectedEmailSet}
          curSearchVal={curSearchVal}
          brandListUsers={brandListUsers}
          isFetchingTalent={isFetchingTalent}
          openArtistModal={openArtistModal}
          findIfUserAlreadyReceivedRequestOfType={findIfUserAlreadyReceivedRequestOfType}
        />
      </div>
    </div>
  );
};

BulkRequestModalSearch.propTypes = {
  brand: PropTypes.object.isRequired,
  lists: PropTypes.array.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  findIfUserAlreadyReceivedRequestOfType: PropTypes.func.isRequired,

  selectUsers: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  selectedUserIdSet: PropTypes.object.isRequired,
  selectedEmailSet: PropTypes.object.isRequired,
  selectedLookbookId: PropTypes.number,

  curSearchVal: PropTypes.string,
  setCurSearchVal: PropTypes.func.isRequired,
  isFocused: PropTypes.bool.isRequired,
  handleFocused: PropTypes.func.isRequired,
  handleBlurred: PropTypes.func.isRequired,

  isGiftingRequestType: PropTypes.bool.isRequired,
  isOpportunityRequestType: PropTypes.bool.isRequired
};

export default BulkRequestModalSearch;
