import APIClient from './index';
import _ from 'lodash';

export const createBrandTarget = target => {
  return new Promise((resolve, reject) => {
    APIClient.post(`BrandTargets`, target)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const updateBrandTarget = (target, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BrandTargets/${target.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
