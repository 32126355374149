import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import _ from 'lodash';
import './ListUsers.scss';

import { getGMTTime } from '../../Helpers/formatting';
import { isDiscoverListCurated, getSortFunction } from '../../Helpers/discover_helpers';
import { updateDiscoverListUser, deleteDiscoverListUser } from '../../APIClient/discover';
import { getTalent } from '../../Helpers/talent_helpers';

import ListUser from './ListUser';
import { formatUsersForBulkRequests } from '../../Helpers/gifting_helpers';

const ListUsers = props => {
  const { user, talent, analytics, list, isEditing, isBrandList, sortBy, sortDirection } = props;
  const [tempUsers, setTempUsers] = useState(null);

  // Sort users by sort order specified externally
  const { fn: sortFunction } = getSortFunction(sortBy) || {};
  const sortedUsers = sortFunction
    ? _.orderBy(list?.users, [sortFunction, u => u.score], [sortDirection, 'desc'])
    : _.orderBy(list?.users, 'sortOrderRank', 'asc');

  // Filter out promoter users if necessary
  const users = (tempUsers || sortedUsers).filter(user => {
    const talent = getTalent(props.talent).find(talent => talent.id === user.User_id);
    if (props.hideCurrentPromoters && talent?.isPromoter) return false;
    return true;
  });

  const isCurated = isDiscoverListCurated(list);

  // Action Buttons
  const deleteUser = user => {
    if (!isCurated) return cogoToast.warn('You cannot remove users from a non-curated list.');
    setTempUsers(users.filter(u => u.User_id !== user.User_id));

    isBrandList
      ? props
          .deleteBrandListUser(user)
          .then(props.syncList)
          .catch(error => {
            cogoToast.error(error?.message || 'Could not add user to list, please try again.');
          })
          .finally(() => setTimeout(() => setTempUsers(null), 500)) // Let the update propagate
      : deleteDiscoverListUser(user)
          .then(props.syncList)
          .catch(error => {
            cogoToast.error(error?.message || 'Could not add user to list, please try again.');
          })
          .finally(() => setTimeout(() => setTempUsers(null), 500)); // Let the update propagate
  };
  const openCodeOverlay = user =>
    props.ensureLoggedInThenCall(() => {
      props.openCodesModal({
        params: {
          User_id: user.User_id,
          name: user.name,
          showTemplates: true
        }
      });
    });
  const openGiftingOverlay = user =>
    props.ensureLoggedInThenCall(() => {
      props.openRequestModal({
        params: {
          preselectedUsers: formatUsersForBulkRequests([user])
        }
      });
    });

  const DragHandle = sortableHandle(() => (
    <div className='sortable-handle'>
      <FontAwesomeIcon icon={faBars} />
    </div>
  ));
  const SortableItem = SortableElement(({ value, props }) => (
    <div className='list-user-outer-container'>
      <ListUser
        talent={talent}
        user={user}
        analytics={analytics}
        discoverOrListUser={value}
        isEditing={isEditing}
        isBrandList={isBrandList}
        syncExternal={props.syncList}
        openArtistModal={props.openArtistModal}
        openChatOverlay={props.openChatOverlay}
        openCodeOverlay={openCodeOverlay}
        openGiftingOverlay={openGiftingOverlay}
        deleteBrandListUser={props.deleteBrandListUser}
        updateBrandListUser={props.updateBrandListUser}
        deleteUser={deleteUser}
        rearrangeHandle={<DragHandle />}
        ensureLoggedInThenCall={props.ensureLoggedInThenCall}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ items, props }) => {
    return (
      <div className={cn('list-users-container', { syncing: props.isSyncingList })}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.User_id}`} axis='xy' index={index} value={value} props={props} />
        ))}
      </div>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    // Ensure we can rearrange
    if (oldIndex === newIndex) return;
    if (!isCurated) return cogoToast.warn(`You cannot rearrange a non-curated list, please change the sort instead.`);

    // Perform UI Update
    setTempUsers(arrayMove(users, oldIndex, newIndex));

    // Perform Database Update
    const movingForward = newIndex > oldIndex;
    const lowerBound = movingForward ? newIndex : newIndex - 1;
    const upperBound = movingForward ? newIndex + 1 : newIndex;
    const lowerBoundSort = users[lowerBound]?.sortOrderRank || -1 * getGMTTime();
    const upperBoundSort = users[upperBound]?.sortOrderRank || getGMTTime();
    const newSort = (lowerBoundSort + upperBoundSort) / 2;
    isBrandList
      ? await props.updateBrandListUser(users[oldIndex], { sortOrderRank: newSort }).then(() => {
          props.syncList().then(resp => {
            setTimeout(() => setTempUsers(null), 1000); // Let update propagate
          });
        })
      : await updateDiscoverListUser(users[oldIndex], { sortOrderRank: newSort }).then(() => {
          props.syncList().then(resp => {
            setTimeout(() => setTempUsers(null), 1000); // Let update propagate
            setTempUsers(null);
          });
        });
  };

  return isEditing ? (
    <SortableList
      axis='xy'
      items={users}
      distance={1}
      onSortEnd={onSortEnd}
      props={props}
      shouldCancelStart={e => !e.target.closest('.sortable-handle')}
      handle='.sortable-handle'
    />
  ) : (
    <div className={cn('list-users-container', { syncing: props.isSyncingList })}>
      {users.map((value, index) => (
        <div key={value.User_id} className='list-user-outer-container'>
          <ListUser
            index={index}
            list={list}
            talent={talent}
            user={user}
            analytics={analytics}
            discoverOrListUser={value}
            isBrandList={isBrandList}
            syncExternal={props.syncList}
            isEditing={isEditing}
            deleteUser={deleteUser}
            openArtistModal={props.openArtistModal}
            openChatOverlay={props.openChatOverlay}
            openCodeOverlay={openCodeOverlay}
            openGiftingOverlay={openGiftingOverlay}
            deleteBrandListUser={props.deleteBrandListUser}
            updateBrandListUser={props.updateBrandListUser}
            ensureLoggedInThenCall={props.ensureLoggedInThenCall}
          />
        </div>
      ))}
    </div>
  );
};

ListUsers.propTypes = {
  talent: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  isBrandList: PropTypes.bool.isRequired,
  isSyncingList: PropTypes.bool.isRequired,
  hideCurrentPromoters: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  syncList: PropTypes.func.isRequired,
  ensureLoggedInThenCall: PropTypes.func.isRequired,
  deleteBrandListUser: PropTypes.func.isRequired,
  updateBrandListUser: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  sortDirection: PropTypes.string
};

export default ListUsers;
