import APIClient from './index';
import _ from 'lodash';

// Opportunities
export const getOpportunity = (id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Opportunities/${id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getOpportunityFeed = (id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Opportunities/${id}/feed?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const createOpportunity = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Opportunities`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const duplicateOpportunity = opportunity => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Opportunities/${opportunity.id}/duplicate`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunity = (opportunity, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Opportunities/${opportunity.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunity = opportunity => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Opportunities/${opportunity.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Opportunity Uploads
export const createOpportunityUpload = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityUploads`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityUpload = (opportunityUpload, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityUploads/${opportunityUpload.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunityUpload = opportunityUpload => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityUploads/${opportunityUpload.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Opportunity Expectations
export const createOpportunityExpectation = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityExpectations`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityExpectation = (expectation, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityExpectations/${expectation.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunityExpectation = expectation => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityExpectations/${expectation.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Opportunity Payment Tiers
export const createOpportunityPaymentTier = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityPaymentTiers`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityPaymentTier = (opportunityPaymentTier, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityPaymentTiers/${opportunityPaymentTier.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunityPaymentTier = opportunityPaymentTier => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityPaymentTiers/${opportunityPaymentTier.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Results
export const getOpportunityResult = (id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/OpportunityResults/${id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityResult = (result, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityResults/${result.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Opportunity Plans
export const createOpportunityPlan = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityPlans`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const createSmartOpportunityPlan = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityPlans/multiple`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityPlan = (plan, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityPlans/${plan.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunityPlan = plan => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityPlans/${plan.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Opportunity Plan Users
export const createOpportunityPlanUser = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityPlanUsers`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateOpportunityPlanUser = (planUser, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/OpportunityPlanUsers/${planUser.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const deleteOpportunityPlanUser = planUser => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/OpportunityPlanUsers/${planUser.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

// Miscellaneous
export const alertChangeInOpportunityRequestOffer = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/OpportunityRequests/alert_change_in_offer`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getOpportunityResults = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/OpportunityResults?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
