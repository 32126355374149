import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import './OnboardingPanelSocial.scss';

import InputActions from '../../General/InputActions';
import FacebookSDK from '../../General/FacebookSDK';

import { getSocialLinks, getSocialLinksArray, getUserId } from '../../../Helpers/user_helpers';
import {
  getSocialIconForType,
  isPlatformUrl,
  get_username_from_social_url,
  openInstagramAuthModal,
  openTikTokAuthModal,
  openYoutubeAuthModal
} from '../../../Helpers/social_helpers';

const OnboardingPanelSocial = props => {
  const { user, connectInstagram, updateCurrentUser, setCannotProceedMessage } = props;

  // Instagram
  const preloadedUserSocialIG = getSocialLinksArray(user).filter(link => isPlatformUrl(link, 'instagram'));
  const [hasCompletedInstagram, setHasCompletedInstagram] = useState(!!preloadedUserSocialIG);
  const [isConnectingInstagram, setIsConnectingInstagram] = useState(false);
  const [instagram, setInstagram] = useState('');

  // Tiktok
  const preloadedUserSocialTT = getSocialLinksArray(user).find(link => isPlatformUrl(link, 'tiktok'));
  const [hasCompletedTiktok, setHasCompletedTiktok] = useState(!!preloadedUserSocialTT);
  const [tiktok, setTiktok] = useState('');

  // Youtube
  const preloadedUserSocialYT = getSocialLinksArray(user).find(link => isPlatformUrl(link, 'youtube'));
  const [hasCompletedYoutube, setHasCompletedYoutube] = useState(!!preloadedUserSocialYT);
  const [youtube, setYoutube] = useState('');

  // Instagram
  const preloadedUserSocialOther = getSocialLinksArray(user).find(link => isPlatformUrl(link, 'other'));
  const [hasCompletedOther, setHasCompletedOther] = useState(!!preloadedUserSocialOther);
  const [other, setOther] = useState('');

  // Update completion on any server updates to social links
  const socialLinks = getSocialLinks(user);
  useEffect(() => {
    setHasCompletedInstagram(socialLinks?.includes('instagram'));
    setHasCompletedTiktok(socialLinks?.includes('tiktok'));
    setHasCompletedYoutube(socialLinks?.includes('youtube'));
  }, [socialLinks]);

  /// Saving to User Object in the database
  const saveToSocialLinks = async (value, type) => {
    const socialLinks = getSocialLinksArray(user);
    let newSocialLink;
    if (type === 'instagram') newSocialLink = `https://instagram.com/${value}`;
    if (type === 'tiktok') newSocialLink = `https://tiktok.com/@${value}`;
    if (type === 'youtube') newSocialLink = value;
    if (type === 'other') newSocialLink = value;
    const new_social_links = [...socialLinks.filter(l => !isPlatformUrl(l, type)), newSocialLink].join(',');
    await updateCurrentUser({ social_links: new_social_links }, getUserId(user));
  };

  // Displaying the various places to upload
  const sections = [
    {
      value: instagram,
      type: 'instagram',
      displayLabel: 'Instagram Handle',
      displayType: 'Instagram',
      placeholder: 'Enter Instagram Handle',
      isComplete: hasCompletedInstagram,
      isConnecting: isConnectingInstagram,
      updateVal: newVal => {
        const usernameFromUrl = newVal.includes('http') ? get_username_from_social_url(newVal) : null;
        setInstagram((usernameFromUrl || newVal).replace(/[@\s://]/g, ''));
      },
      completeManual: e => {
        e.preventDefault();
        setHasCompletedInstagram(true);
        saveToSocialLinks(instagram, 'instagram');
      },
      resetCompleted: () => {
        setInstagram('');
        setHasCompletedInstagram(false);
      },
      link: () => {
        openInstagramAuthModal(
          authResponse => {
            setIsConnectingInstagram(true);
            connectInstagram(authResponse)
              .then(resp => {
                if (resp.account) {
                  setHasCompletedInstagram(true);
                  setInstagram(resp.username);
                  saveToSocialLinks(resp.username, 'instagram');
                }
              })
              .catch(errorMessage => cogoToast.error(errorMessage))
              .finally(() => {
                setIsConnectingInstagram(false);
              });
          },
          () => {
            cogoToast.warn(
              `Could not connect your account, please make sure you have an Instagram Creator or Business account connected to a Facebook Page. This is required for sharing advanced analytics with brands. If you do not, you can simply input your handle.`,
              { hideAfter: 10 }
            );
          }
        );
      }
    },
    {
      value: tiktok,
      type: 'tiktok',
      displayLabel: 'TikTok Handle',
      displayType: 'TikTok',
      placeholder: 'Enter TikTok Handle',
      isComplete: hasCompletedTiktok,
      isConnecting: false, // Full redirect, no popup
      updateVal: newVal => {
        const usernameFromUrl = newVal.includes('http') ? get_username_from_social_url(newVal) : null;
        setTiktok((usernameFromUrl || newVal).replace(/[@\s://]/g, '').toLowerCase());
      },
      completeManual: e => {
        e.preventDefault();
        setHasCompletedTiktok(true);
        saveToSocialLinks(tiktok, 'tiktok');
      },
      resetCompleted: () => {
        setTiktok('');
        setHasCompletedTiktok(false);
      },
      link: () => openTikTokAuthModal({ useFullUrl: true })
    },
    {
      value: youtube,
      type: 'youtube',
      displayLabel: 'Youtube URL',
      displayType: 'Youtube',
      placeholder: 'Enter Youtube URL',
      isComplete: hasCompletedYoutube,
      isConnecting: false, // Full redirect, no popup
      updateVal: newVal => {
        setYoutube(newVal);
      },
      completeManual: e => {
        e.preventDefault();
        if (!youtube?.includes('https://')) return cogoToast.error('Please paste the full URL including https://');
        setHasCompletedYoutube(true);
        saveToSocialLinks(youtube, 'youtube');
      },
      resetCompleted: () => {
        setYoutube('');
        setHasCompletedYoutube(false);
      },
      link: () => openYoutubeAuthModal({ useFullUrl: true })
    },
    {
      value: other,
      type: 'other',
      displayLabel: 'Other (Podcasts, Personal Website, etc.)',
      displayType: 'Other (Podcasts, Personal Website, etc.)',
      placeholder: 'Enter URLs (separate multiple URLs with commas)',
      isComplete: hasCompletedOther,
      isConnecting: false, // No modals
      updateVal: newVal => {
        setOther(newVal);
      },
      completeManual: e => {
        e.preventDefault();
        if (!other?.includes('https://')) return cogoToast.error('Please paste the full URL including https://');
        setHasCompletedOther(true);
        saveToSocialLinks(other, 'other');
      },
      resetCompleted: () => {
        setOther('');
        setHasCompletedOther(false);
      }
    }
  ];

  // Ensure they must enter at least one value
  useEffect(() => {
    const canProceed = hasCompletedInstagram || hasCompletedTiktok || hasCompletedYoutube;
    setCannotProceedMessage(canProceed ? null : `You must add at least one social account.`);
  }, [setCannotProceedMessage, hasCompletedInstagram, hasCompletedTiktok, hasCompletedYoutube]);

  return (
    <div className='onboarding-panel-social-container'>
      <FacebookSDK />
      <div className='onboarding-header'>Link your socials</div>
      <div className='onboarding-subheader'>
        Your social media handles will appear on your public shop header. Linking your accounts will allow you to share your analytics for brand
        collaborations—we highly recommend it. We do not share your data without your consent.
        <br />
        <br />
        Please add at least one social account.
      </div>
      <div className='social-link-sections'>
        {sections.map(section => {
          const {
            type,
            displayLabel,
            displayType,
            isComplete,
            value,
            completeManual,
            resetCompleted,
            placeholder,
            updateVal,
            link,
            isConnecting
          } = section;
          return (
            <div key={type} className={cn('social-link-section', { complete: isComplete })}>
              {!isComplete && (
                <div className='section-header'>
                  <div className='type'>{displayLabel}</div>
                </div>
              )}
              <div className='section-actions'>
                {!isComplete && (
                  <>
                    <div className={cn('input-container has-actions', { complete: isComplete })}>
                      <form onSubmit={completeManual}>
                        <input value={value} className={type} placeholder={placeholder} onChange={e => updateVal(e.target.value)} />
                        <InputActions searchVal={value} onCancel={() => updateVal('')} />
                      </form>
                    </div>
                    {link && !value && <div className='or'>OR</div>}
                  </>
                )}
                {value && !isComplete ? (
                  <>
                    <div onClick={completeManual} className={`link-btn from-input ${type}`}>
                      <img alt={`${displayType} Logo`} src={getSocialIconForType(type, 'white')} />
                      CONNECT
                    </div>
                  </>
                ) : !link ? (
                  isComplete && (
                    <>
                      <div onClick={resetCompleted} className={cn('link-btn', type, { complete: isComplete })}>
                        <FontAwesomeIcon className='check' icon={faCheck} />
                        {link ? `${displayType} Connected` : 'Other Links Connected'}
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div onClick={isComplete ? resetCompleted : link} className={cn('link-btn', type, { complete: isComplete })}>
                      {isComplete ? (
                        <>
                          <FontAwesomeIcon className='check' icon={faCheck} />
                          {displayType} Connected
                        </>
                      ) : isConnecting ? (
                        'Connecting...'
                      ) : (
                        <>
                          <img alt={`${displayType} Logo`} src={getSocialIconForType(type, 'white')} />
                          Connect {displayType}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OnboardingPanelSocial.propTypes = {
  user: PropTypes.object.isRequired,
  setCannotProceedMessage: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired
};

export default OnboardingPanelSocial;
