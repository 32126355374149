import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import './OpportunitySetupGuideTags.scss';

import InputActions from '../../General/InputActions';

const OpportunitySetupGuideTags = props => {
  const { results, setResults } = props;

  const [curTagVal, setCurTagVal] = React.useState('');

  const pressEnter = e => {
    e.preventDefault();
    if (curTagVal) {
      setResults(_.uniq([...results, { tag: curTagVal }]));
      setCurTagVal('');
    }
  };

  return (
    <div className='opportunity-setup-guide-tags'>
      <div className='results-section'>
        <div className='results-section-title'>Selected Social Tags:</div>
        {!results.length ? (
          <div className='empty-results'>
            <div className='main-message'>None Selected</div>
          </div>
        ) : (
          <div className='active-results result-pills'>
            {results.map((result, idx) => {
              const { tag } = result;
              const remove = () => setResults(results.filter((_, i) => i !== idx));
              return (
                <div key={tag} onClick={remove} className='active-result active result-pill'>
                  <div className='content'>
                    <div className='title'>{tag}</div>
                  </div>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className='results-section'>
        <div className='results-section-title'>Add New Tags:</div>
        <form onSubmit={pressEnter} className='input-container has-actions'>
          <input type='text' value={curTagVal} onChange={e => setCurTagVal(e.target.value)} placeholder='Add new search term' />
          <InputActions searchVal={curTagVal} onCancel={() => setCurTagVal('')} />
        </form>
      </div>
    </div>
  );
};

OpportunitySetupGuideTags.propTypes = {
  results: PropTypes.array.isRequired,
  setResults: PropTypes.func.isRequired
};

export default OpportunitySetupGuideTags;
