import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './UserCollaborationsPanel.scss';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import { SortableContainer } from '../../Helpers/sort_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import smsLogo from '../../static/images/logos/logo_sm.png';

import UserCollaborationPreview from '../Collaborations/UserCollaborationPreview';
import CollaborationsPotentialTasks from '../Collaborations/CollaborationsPotentialTasks';
import TaskEditModal from '../Collaborations/TaskEditModal';
import RequiresPermissions from '../Managers/RequiresPermissions';

import {
  getContractTaskTemplates,
  createUserContractTask,
  updateUserContractTask,
  deleteUserContractTask,
  createContractFromTask,
  createContractWithUser
} from '../../Actions/CollaborationsActions';

import { canEditShop, getUserId, isLoggedIn } from '../../Helpers/user_helpers';
import { getGMTTime } from '../../Helpers/formatting';
import { getAllTemplates, getDisplayForCollaborationType } from '../../Helpers/collaboration_helpers';

const TEST_PRICING_MODAL = false;

const UserCollaborationsPanel = props => {
  const { collaborationUser, user, collaborations, getContractTaskTemplates } = props;
  const { tasks } = collaborationUser;
  const loggedIn = isLoggedIn(user);
  const editable = canEditShop(collaborationUser, user);
  const initialTypes = _.uniqBy(_.map(tasks, 'template.type'));
  const [activeTypes, setActiveTypes] = useState(initialTypes); // To ensure we don't close after they already started editing
  const [sortOrderRanks, setSortOrderRanks] = useState(_.reduce(tasks, (r, task) => ({ ...r, [task?.template?.type]: task?.sortOrderRank }), {})); // To ensure stability when reordering
  const [taskBeingEdited, setTaskBeingEdited] = useState(TEST_PRICING_MODAL ? tasks[0] || null : null); // To ensure we don't close after they already started editing
  const [viewingAsBrand, setViewingAsBrand] = useState(!!tasks?.length && editable);
  const [isCreatingContract, setIsCreatingContract] = useState(false);
  const potentialTasksEl = useRef(null);
  const explainerEl = useRef(null);

  /*
    Special handling for reordering.

    Unfortunately the react-sortable-hoc library has terrible handling for changing props
    and there is a forced rerender on every prop change. This means that we cannot perform
    actions such as delayed saving of text fields, and any other actions that would require
    a stable, mounted component.

    As a fix we use the isReordering and ensure that the user can specify whether they are
    reordering and we cancel this on every click away.
  */
  const [isReordering, setIsReordering] = useState(false);
  useEffect(() => {
    const event = e => {
      const isReorder = !!e.target.closest('.reorder-icn') || !!e.target.closest('.reorder-alert');
      !isReorder && setIsReordering(false);
    };
    document.addEventListener('mousedown', event);
    return () => {
      window.removeEventListener('mousedown', event);
    };
  }, [isReordering]);

  const canEdit = editable && !viewingAsBrand;
  const taskTemplates = canEdit
    ? getAllTemplates(collaborations).filter(t => !['custom', 'addon', 'stipend'].includes(t.type))
    : _.map(tasks, 'template');
  const getTaskForTemplate = template => template && _.find(tasks, task => task.template?.type === template?.type);
  const getActiveTypeForTemplate = template => _.find(activeTypes, type => type === template?.type);

  const sortedTaskTemplates = _.orderBy(taskTemplates, template => sortOrderRanks[template?.type]);
  const sortedTaskGroups = _.values(_.groupBy(sortedTaskTemplates, 'type'));
  const templateGroups = _.values(sortedTaskGroups);

  const taskTemplatesActive = sortedTaskTemplates.filter(template => getActiveTypeForTemplate(template));
  const sortedTaskGroupsActive = _.values(_.groupBy(taskTemplatesActive, 'type'));
  const templateGroupsActive = _.values(sortedTaskGroupsActive);

  const hasActiveTasks = !!templateGroupsActive.length;

  // Sync all data
  useEffect(() => {
    loggedIn && getContractTaskTemplates();
  }, [getContractTaskTemplates, loggedIn]);

  // API Handling
  const deleteTask = async task => {
    const remove = () => props.deleteUserContractTask(task);
    const needsConfirmation = !!task.price;
    needsConfirmation
      ? confirmAlert({
          title: 'Just confirming',
          message: `Are you sure you want to remove ${task.template.title}? This will remove the price that you set.`,
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: remove
            }
          ]
        })
      : remove();
  };

  const selectTask = async task => {
    if (viewingAsBrand) return cogoToast.info(`This is how a brand would propose a collaboration.`);
    if (isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    setIsCreatingContract(true);
    try {
      const resp = await props.createContractFromTask(task);
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      }
    } catch (_) {}
    setIsCreatingContract(false);
  };

  const startContractWithoutTask = async () => {
    if (isCreatingContract) return cogoToast.info(`Already in the process of creating a collaboration.`);
    setIsCreatingContract(true);
    try {
      const resp = await props.createContractWithUser(collaborationUser.id);
      if (resp.contract) {
        window.location.href = `/collaboration/${resp?.contract?.id}`;
      }
    } catch (_) {}
    setIsCreatingContract(false);
  };

  const removeAllTasksFromType = async type => {
    const typeTasks = tasks.filter(task => task?.template?.type === type);
    const remove = () => {
      setActiveTypes(activeTypes.filter(t => t !== type));
      sortOrderRanks[type] = null;
      typeTasks.forEach(props.deleteUserContractTask);
    };

    const hasPriceSet = typeTasks.find(task => !!task.price);
    const needsConfirmation = hasPriceSet || typeTasks.length > 1;
    needsConfirmation
      ? confirmAlert({
          title: 'Just confirming',
          message: `Are you sure you want to remove ${getDisplayForCollaborationType(type)}?${
            hasPriceSet ? ` This will remove the price you have set.` : ''
          }`,
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: remove
            }
          ]
        })
      : remove();
  };

  const createTaskFromTemplate = (template, additionalFields = {}) => {
    const alternativeActiveTask = getTaskForTemplate(template);
    const newSortRank = alternativeActiveTask?.sortOrderRank || getGMTTime();
    props
      .createUserContractTask({
        User_id: getUserId(collaborationUser),
        ContractTaskTemplate_id: template.id,
        sortOrderRank: newSortRank,
        ...additionalFields
      })
      .then(resp => {
        setActiveTypes([...activeTypes, template?.type]);
        setSortOrderRanks({
          ...sortOrderRanks,
          [template?.type]: sortOrderRanks[template?.type] || newSortRank
        });
      });
  };
  const getPreviewFromTemplates = (templates, { DragHandle } = {}) => {
    const type = templates[0]?.type;
    const startReordering = () => setIsReordering(true);
    const toggleViewingAsBrand = () => setViewingAsBrand(!viewingAsBrand);
    return (
      <UserCollaborationPreview
        key={type}
        user={user}
        templates={templates}
        deleteTask={deleteTask}
        startReordering={startReordering}
        dragHandle={DragHandle ? <DragHandle /> : null}
        createTaskFromTemplate={createTaskFromTemplate}
        updateUserContractTask={props.updateUserContractTask}
        collaborationUser={collaborationUser}
        canEdit={canEdit}
        viewingAsBrand={viewingAsBrand}
        toggleViewingAsBrand={toggleViewingAsBrand}
        editTask={setTaskBeingEdited}
        selectTask={selectTask}
        removeAllTasksFromType={removeAllTasksFromType}
      />
    );
  };

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((templates, idx) => (
          <SortableItem axis='xy' key={`item-${templates[0].id}`} index={idx} value={{ templates, idx }} />
        ))}
      </div>
    );
  });

  const SortableItem = SortableElement(({ value }) => {
    const { templates } = value;
    const type = _.get(templates, '0.type');
    const DragHandle = sortableHandle(() => (
      <div className={cn(`reorder-icn`, type)}>
        <FontAwesomeIcon icon={faBars} />
      </div>
    ));
    return getPreviewFromTemplates(templates, { DragHandle });
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsReordering(false);
    if (oldIndex === newIndex) return;
    const curSortRanks = sortedTaskGroupsActive.map(templates => templates[0].type);
    const typeToUpdate = curSortRanks[oldIndex];
    const movingBack = newIndex > oldIndex;

    // Update local sorting
    const newIndexLow =
      getTaskForTemplate(_.first(sortedTaskGroupsActive[movingBack ? newIndex : newIndex - 1]))?.sortOrderRank ||
      Math.floor(_.min(_.values(sortOrderRanks)) / 2);
    const newIndexHigh = getTaskForTemplate(_.first(sortedTaskGroupsActive[movingBack ? newIndex + 1 : newIndex]))?.sortOrderRank || getGMTTime();
    const updatedSortRank = newIndexLow + (newIndexHigh - newIndexLow) / 2;
    setSortOrderRanks({
      ...sortOrderRanks,
      [typeToUpdate]: updatedSortRank
    });

    // Update all relevant tasks behind the scenes
    const allTasks = tasks.filter(task => task?.template?.type === typeToUpdate);
    allTasks.forEach(task => {
      props.updateUserContractTask(task, {
        sortOrderRank: updatedSortRank
      });
    });
  };

  const scrollToExplainer = () => {
    const curTop = explainerEl.current.getBoundingClientRect().top;
    window.scrollTo({ top: window.scrollY + curTop - 100, behavior: 'smooth' });
  };

  return (
    <div className='user-collaborations-panel-outer-container'>
      <RequiresPermissions permission='canAnswerCollaborations'>
        {/* Main Active Tasks */}
        {hasActiveTasks && isReordering ? (
          <SortableList axis='y' items={templateGroupsActive} onSortEnd={onSortEnd} useDragHandle />
        ) : hasActiveTasks ? (
          templateGroupsActive.map(getPreviewFromTemplates)
        ) : (
          !canEdit && (
            <div className='add-custom-container'>
              <>
                <div className='add-custom-header'>{collaborationUser.name} has not yet specified any collaborations</div>
                <div className='add-custom-subheader'>Click below to build a collaboration from scratch.</div>
              </>
              <div onClick={startContractWithoutTask} className='add-custom-button'>
                <div className='main'>
                  <img src={smsLogo} alt='Custom Logo' />
                  <div className='title'>CREATE CUSTOM COLLABORATION</div>
                </div>
                <div className='actions'>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
          )
        )}
        {/* Potential Tasks */}
        {canEdit && (
          <>
            {' '}
            <div ref={potentialTasksEl}></div>{' '}
            <CollaborationsPotentialTasks
              tasks={tasks}
              groups={templateGroups}
              getTaskForTemplate={getTaskForTemplate}
              createTaskFromTemplate={createTaskFromTemplate}
              removeAllTasksFromType={removeAllTasksFromType}
              scrollToExplainer={scrollToExplainer}
            />
          </>
        )}
        {editable && hasActiveTasks && (
          <div className='actions-container'>
            <div className='actions'>
              <div
                onClick={() => {
                  setViewingAsBrand(!viewingAsBrand);
                  !viewingAsBrand &&
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    });
                }}
                className='action done'
              >
                {viewingAsBrand ? 'Edit My Collaborations' : 'Done Editing'}
              </div>
              <Link to={`/collaborations/new`} className='action search'>
                Search Brands
                <FontAwesomeIcon icon={faSearch} />
              </Link>
            </div>
          </div>
        )}
        {/* Additional Modals */}
        {taskBeingEdited && (
          <TaskEditModal task={taskBeingEdited} updateUserContractTask={props.updateUserContractTask} closeModal={() => setTaskBeingEdited(null)} />
        )}
      </RequiresPermissions>
    </div>
  );
};

UserCollaborationsPanel.propTypes = {
  // From outside
  collaborationUser: PropTypes.object.isRequired,

  // From Redux Connection
  collaborations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getContractTaskTemplates: PropTypes.func.isRequired,
  createUserContractTask: PropTypes.func.isRequired,
  updateUserContractTask: PropTypes.func.isRequired,
  deleteUserContractTask: PropTypes.func.isRequired,
  createContractFromTask: PropTypes.func.isRequired,
  createContractWithUser: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, collaborations } = state;
  return { user, collaborations };
};

export default connect(mapStateToProps, {
  getContractTaskTemplates,
  createUserContractTask,
  updateUserContractTask,
  deleteUserContractTask,
  createContractFromTask,
  createContractWithUser
})(UserCollaborationsPanel);
