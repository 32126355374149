import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './UserLookbook.scss';
import { useHistory } from 'react-router-dom';

import UserLookbookHeader from './UserLookbookHeader';
import UserLookbookBody from './UserLookbookBody';
import LookbookOrderModal from '../LookbookModals/LookbookOrderModal/LookbookOrderModal';
import AcceptLookbookInviteModal from './AcceptLookbookInviteModal';

import { updateLookbookCart } from '../../../Actions/LookbookActions';
import { syncCurrentUser } from '../../../Actions/UserActions';
import { openAuthModal } from '../../../Actions/UIActions';

import { getRequestForLookbook, isLoggedIn } from '../../../Helpers/user_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';

const UserLookbook = props => {
  const { user, lookbooks, lookbook, updateLookbookCart, ui } = props;
  const request = getRequestForLookbook(user, lookbook);
  const userHasAlreadyOrdered = !!request?.lookbook_order?.id;
  const history = useHistory();

  React.useEffect(() => {
    if (userHasAlreadyOrdered) history.push(`/lookbooks/order/${request?.lookbook_order?.id}`);
  }, [userHasAlreadyOrdered]);

  // This case occurs when the user clicks an invite link but is already logged in
  const cannotFindRequestForLoggedInUser = !request && isLoggedIn(user);

  return (
    <div className='user-lookbook-outer'>
      <div className='user-lookbook-inner'>
        <UserLookbookHeader lookbook={lookbook} userHasAlreadyOrdered={userHasAlreadyOrdered} user={user} />
        <UserLookbookBody user={user} lookbook={lookbook} lookbooks={lookbooks} updateLookbookCart={updateLookbookCart} />
        <LookbookOrderModal lookbook={lookbook} />
        {cannotFindRequestForLoggedInUser && !isAdminControlMode(ui) && (
          <AcceptLookbookInviteModal user={user} lookbook={lookbook} syncCurrentUser={props.syncCurrentUser} />
        )}
      </div>
    </div>
  );
};

UserLookbook.propTypes = {
  // From Outside
  lookbook: PropTypes.object.isRequired,

  // From Inside
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  syncCurrentUser: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  updateLookbookCart: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, lookbooks, store, ui } = state;
  return { user, lookbooks, store, ui };
};

export default connect(mapStateToProps, {
  syncCurrentUser,
  openAuthModal,
  updateLookbookCart
})(UserLookbook);
