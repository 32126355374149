import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './LookbookPreviews.scss';

import LookbookPreview from './LookbookPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import { getFirstName } from '../../../Helpers/formatting';
import { getCalendlyLinkForBrand } from '../../../Helpers/brand_helpers';

const LookbookPreviews = props => {
  const { lookbooks, brand, analytics, deleteLookbook, duplicateLookbook, createNewEmptyLookbook } = props;
  const account_manager = brand?.account_manager;

  // Has less than two lookbooks and before November 24th
  const showDemoOption = lookbooks.length < 2 && new Date() < new Date('2023-11-12'); // Remove after this day
  const calendlyLink = getCalendlyLinkForBrand(brand, 'lookbooks');
  return (
    <div className='lookbook-previews-outer'>
      <div className='lookbook-previews-inner'>
        <div className='lookbook-previews-grid'>
          {showDemoOption && (
            <div className='get-demo-placeholder' onClick={createNewEmptyLookbook}>
              <div className='header-container'>
                <div className='header'>Want to learn more?</div>
                <div className='subheader'>
                  ShopMy's new standard in gifting creates a seamless experience for talent + saves your team hours in execution. To start utilizing
                  our automated gifting feature, please schedule a call with your Success Manager below.
                </div>
              </div>
              <a href={calendlyLink} target='_blank' rel='noopener noreferrer' className='button'>
                {account_manager ? `Schedule Demo With ${getFirstName(account_manager.name)}` : 'Schedule Demo'}
              </a>
            </div>
          )}
          {!lookbooks.length && (
            <div className='add-new-lookbook-placeholder' onClick={createNewEmptyLookbook}>
              <div className='text-container'>
                <FontAwesomeIcon icon={faPlus} />
                <div>New Lookbook</div>
              </div>
            </div>
          )}
          {_.orderBy(lookbooks, 'createdAt', 'desc').map(lookbook => (
            <LookbookPreview
              analytics={analytics}
              lookbook={lookbook}
              brand={brand}
              key={`lookbook-preview-${lookbook.id}`}
              deleteLookbook={deleteLookbook}
              duplicateLookbook={duplicateLookbook}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

LookbookPreviews.propTypes = {
  lookbooks: PropTypes.array,
  brand: PropTypes.object,
  analytics: PropTypes.object.isRequired,
  deleteLookbook: PropTypes.func.isRequired,
  duplicateLookbook: PropTypes.func.isRequired,
  createNewEmptyLookbook: PropTypes.func.isRequired
};

export default LookbookPreviews;
