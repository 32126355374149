import APIClient from './index';
import _ from 'lodash';

export const updateInventoryLocations = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ShopifyInventoryLocations`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
