import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import './HomeProductSuite.scss';

const HomeProductSuite = props => {
  const products = [
    {
      title: 'Web',
      subtitle: 'Your command center—customize your digital storefront, access key insights and get your management team on board.',
      image: 'https://static.shopmy.us/Home/Products/Home_Product_Web.png',
      to: '/home/creators/shop'
    },
    {
      title: 'App',
      subtitle: 'Chat with brands, accept gifts, create links in seconds - and never miss an opportunity.',
      image: 'https://static.shopmy.us/Home/Products/Home_Product_App.png',
      href: 'https://apps.apple.com/us/app/shopmy/id6443850511'
    },
    {
      title: 'Browser Extension',
      subtitle: 'Use our SnapShop extension to add commissionable links to your shop as you browse online - just with a single tap.',
      image: 'https://static.shopmy.us/Home/Products/Home_Product_Extension.png',
      to: '/home/creators/affiliates'
    }
  ];

  // Animate in when it hits the window
  const panelRef = React.useRef(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => entries.forEach(entry => entry.isIntersecting && !isLoaded && setIsLoaded(true)));
    panelRef.current && observer.observe(panelRef.current);
    return () => panelRef.current && observer.unobserve(panelRef.current);
  }, [isLoaded]);

  const additionalClasses = { loaded: isLoaded };
  return (
    <div className='home-product-suite-outer-container'>
      <div className='home-product-suite-inner-container'>
        <div className='home-product-suite-title-container'>
          <h3 className='standard-home-header'>A full product suite to monetize wherever you are</h3>
        </div>
        <div ref={panelRef} className='home-suite-options'>
          {products.map((product, index) => {
            const { title, subtitle, image, to, href, onActionClick } = product;
            return (
              <div key={title} className={cn('home-suite-option', additionalClasses)}>
                <div className={cn('image-container', additionalClasses)}>
                  <img src={image} alt={title} />
                </div>
                <div className='text-container'>
                  <h4 className='title'>{title}</h4>
                  <p className='subtitle'>{subtitle}</p>
                </div>
                <div className='action-btn-container'>
                  {onActionClick ? (
                    <div onClick={onActionClick} className='action-btn'>
                      Learn More
                    </div>
                  ) : to ? (
                    <Link to={to} className='action-btn'>
                      Learn More
                    </Link>
                  ) : href ? (
                    <a href={href} className='action-btn'>
                      Learn More
                    </a>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeProductSuite;
