import APIClient from './index';
import _ from 'lodash';

export const getMentions = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Mentions?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const updateMention = (mention, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Mentions/${mention.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const removeMentionsForBrandMentionTag = (brand, tag) => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Mentions/${brand.id}/${tag}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

export const getMentionBrandsAndTargets = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Mentions/brands_and_targets?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
