import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import './CodesModal.scss';

import { getBrandId, getBrandCodesForUserId, getBrandCodes, getAllShopifyIntegrations } from '../../Helpers/user_helpers';
import { getCodesModalStartingParams, getCodesModalCloseCallback, getCodesModalSubmitCallback } from '../../Helpers/ui_helpers';
import { getCodeRateDisplay, isValidPercentageOrDollarAmount } from '../../Helpers/formatting';
import { getCodeDisplayForUserWithFormat, isCodeFormattingDefault } from '../../Helpers/code_helpers';

import { getTalentPromotionDetails } from '../../APIClient/talent';
import { setCustomCode } from '../../Actions/AnalyticsActions';
import { closeCodesModal } from '../../Actions/UIActions';

import PartnerActionTemplates from '../General/PartnerActionTemplates';
import Tooltip from '../General/Tooltip';

import Modal from '../General/Modal';

import { getFollowupTemplates } from '../../APIClient/brands';
import { SHOPIFY_CODE_TYPE_DISPLAY_NAMES, AMOUNT_OFF_ORDER, AMOUNT_OFF_PRODUCTS, SHOPIFY_CODE_TYPE_TIP } from '../../Helpers/code_constants';
import { getShopifyCollections } from '../../APIClient/shopify';
import InfiniteScrollSelect from '../General/InfiniteScrollSelect';
import SelectOption from '../General/SelectOption';

const CodesModal = props => {
  const { user, ui, analytics } = props;
  const { User_id, name, showTemplates, id: initialCodeId } = getCodesModalStartingParams(ui) || {};

  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCode, setSelectedCode] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [rate, setRate] = useState('');
  const [brandMessage, setBrandMessage] = useState('');
  const [shopifyCodeType, setShopifyCodeType] = useState(null);

  // shopify collections state
  const [collectionsHasNextPage, setCollectionsHasNextPage] = useState(true);
  const [lastCursor, setLastCursor] = useState(null);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [isLoadingCollections, setIsLoadingCollections] = useState(false);

  // shopify integrations state
  const [selectedIntegrationIds, setSelectedIntegrationIds] = useState(new Set());
  const [codesIntegrations, setCodesIntegrations] = useState([]);
  const [allIntegrations, setAllIntegrations] = useState([]);
  const hasShopifyCodesIntegration = codesIntegrations.length > 0;

  // general talent stats
  const [promoterDetails, setPromoterDetails] = useState({});
  useEffect(() => {
    User_id &&
      getTalentPromotionDetails(getBrandId(user), { User_id }).then(resp => {
        setPromoterDetails(resp.details);
      });
  }, [User_id]);

  // go back state when adding another code
  const [showGoBack, setShowGoBack] = useState(false);

  const closeCallback = getCodesModalCloseCallback(ui);
  const submitCallback = getCodesModalSubmitCallback(ui);

  const closeModal = () => {
    closeCallback && closeCallback();
    props.closeCodesModal();
  };

  const [templates, setTemplates] = useState([]);
  useEffect(() => {
    if (showTemplates) {
      getFollowupTemplates({ Brand_id: getBrandId(user), User_id, isFollowup: false, type: 'code' }).then(resp => {
        setTemplates(resp.templates);
      });
    }

    initExistingCodes();
  }, []);

  useEffect(() => {
    // init shopify code integrations
    const shopifyIntegrations = getAllShopifyIntegrations(user);
    setAllIntegrations(shopifyIntegrations);

    let targetIntegrations = shopifyIntegrations.filter(i => i.allowCustomCodes);

    if (selectedCode?.BrandIntegration_id) {
      // if selected code has a shopify integration, only show that integration
      const selectedIntegration = targetIntegrations.find(i => i.id === selectedCode.BrandIntegration_id);

      if (selectedIntegration) {
        targetIntegrations = [selectedIntegration];
      } else {
        targetIntegrations = [];
      }
    }

    if (targetIntegrations.length && selectedCode && !selectedCode.BrandIntegration_id) {
      // legacy code from before shopify integration was added, do not display shopify details
      targetIntegrations = [];
    }

    setCodesIntegrations(targetIntegrations);
    const selectedIntegrationIds = new Set(targetIntegrations.map(i => i.id));
    setSelectedIntegrationIds(selectedIntegrationIds);

    // init shopifyCodeType based on existing code or shopify integration
    if (selectedCode?.shopifyCodeType && targetIntegrations.length) {
      setShopifyCodeType(selectedCode.shopifyCodeType);
    } else if (targetIntegrations.length) {
      setShopifyCodeType(AMOUNT_OFF_ORDER);
    }

    if (!selectedCode) return;

    setDisplayText(selectedCode.displayText);
    setRate(getCodeRateDisplay(selectedCode));
    setBrandMessage('');

    // init selected shopify collections if needed
    if (targetIntegrations.length && selectedCode.shopifyCodeType === AMOUNT_OFF_PRODUCTS) {
      const selectedCollectionIds = JSON.parse(selectedCode.shopifyCollectionIds);
      selectedCollectionIds?.length && loadSelectedCollections(selectedCollectionIds, Array.from(selectedIntegrationIds)[0]);
    }
  }, [selectedCode]);

  const initExistingCodes = () => {
    const existingCodes = getBrandCodesForUserId(analytics, User_id);

    if (existingCodes.length) {
      const shopifyIntegrations = getAllShopifyIntegrations(user);
      const formattedCodes = existingCodes.map(c => ({
        ...c,
        integrationDomain: shopifyIntegrations.find(i => i.id === c.BrandIntegration_id)?.domain || null
      }));

      setCodeOptions(formattedCodes);
      const initialCode = _.find(formattedCodes, c => c.id === initialCodeId);
      setSelectedCode(initialCode || formattedCodes[0]);
    }
  };

  const cancelCreateNew = () => {
    initExistingCodes();
    setShowGoBack(false);
  };

  const clearForCreateNew = () => {
    setSelectedCode(null);
    setDisplayText('');
    setRate('');
    setBrandMessage('');
    setSelectedCollections([]);
    setCodeOptions([]);
    setShowGoBack(true);
  };

  const loadSelectedCollections = async (collectionIds, integrationId) => {
    try {
      setIsLoadingCollections(true);
      const data = {
        collectionIds,
        BrandIntegration_id: integrationId
      };

      const response = await getShopifyCollections(data);
      const formattedCollections = response.collections.filter(Boolean).map(c => ({
        label: c.title,
        value: +c.id.split('/').pop(),
        subLabel: `${c.productsCount} products`
      }));
      setSelectedCollections(formattedCollections);
    } catch (e) {
      console.error('Error fetching selected shopify collections: ', e);
    } finally {
      setIsLoadingCollections(false);
    }
  };

  const loadMoreCollections = async (searchTerm, isNewSearch = false) => {
    if (!collectionsHasNextPage && !isNewSearch) return [];

    const data = {
      BrandIntegration_id: Array.from(selectedIntegrationIds)[0]
    };

    if (searchTerm) {
      data.searchTerm = searchTerm;
    }

    if (!isNewSearch && lastCursor) {
      data.lastCursor = lastCursor;
    }

    let response;
    try {
      setIsLoadingCollections(true);
      response = await getShopifyCollections(data);
    } catch (e) {
      console.error(e);
      cogoToast.error('There was an error loading your shopify collections, please try again.');
      setIsLoadingCollections(false);
      return [];
    }

    const collections = response.collections?.edges || [];
    const hasNextPage = response.collections?.pageInfo?.hasNextPage || false;
    const formattedCollections = collections.map(cEdge => ({
      label: cEdge.node.title,
      value: +cEdge.node.id.split('/').pop(),
      subLabel: `${cEdge.node.productsCount} products`
    }));
    if (hasNextPage) {
      const lastCollection = _.last(collections);
      setLastCursor(lastCollection?.cursor || null);
    }

    setCollectionsHasNextPage(hasNextPage);
    setIsLoadingCollections(false);
    return formattedCollections;
  };

  const pressSave = async () => {
    if (isSaving) return;
    if (!isValidPercentageOrDollarAmount(rate)) return window.ALERT.error(rate ? 'Please enter a rate' : 'Rate must be a valid number');
    if (!displayText) return window.ALERT.error('You must specify the code');

    let shopifyCollectionIds = shopifyCodeType === AMOUNT_OFF_PRODUCTS && selectedCollections.length ? selectedCollections.map(c => c.value) : null;

    if (selectedCode?.shopifyProductIds) {
      // For now, if shopify product IDs have been set on shopify side, set collection IDs to null
      shopifyCollectionIds = null;
    } else if (shopifyCodeType === AMOUNT_OFF_PRODUCTS && !shopifyCollectionIds) {
      return window.ALERT.error('Please select at least one shopify collection');
    }

    setIsSaving(true);

    try {
      const data = {
        id: selectedCode?.id,
        displayText,
        shopifyCodeType,
        shopifyCollectionIds,
        rateDisplay: rate,
        brandMessage: brandMessage || ''
      };

      // if shopify code integrations enabled, enforce which integrations to create codes for
      if (selectedIntegrationIds.size > 0) {
        data.brandIntegrationIds = Array.from(selectedIntegrationIds);
      }

      await props.setCustomCode(User_id, data);
      window.ALERT.success(selectedCode ? `Successfully updated the custom code.` : `Successfully created discount code and sent email.`);
      closeModal();
    } catch (error) {
      if (_.isString(error) && error.toLowerCase().startsWith('this code was already applied to')) {
        window.ALERT.error(error, { hideAfter: 10 });
      } else {
        window.ALERT.error(error || `There was an error ${selectedCode ? 'updating' : 'setting'} this custom code, please try again.`);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const [isRemoving, setIsRemoving] = useState(false);
  const removeCode = async () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to remove this code? We will notify ${name || 'the content creator'} that the code has been removed.`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: async () => {
            try {
              setIsRemoving(true);
              const data = {
                id: selectedCode.id,
                rateDisplay: '0',
                displayText: selectedCode.displayText,
                shopifyCodeType: null
              };
              await props.setCustomCode(User_id, data);
              window.ALERT.success(`Successfully removed the custom code.`);
              setIsRemoving(false);
              submitCallback && submitCallback();
              closeModal();
            } catch (error) {
              window.ALERT.error('There was an error removing this custom code, please try again.');
              setIsRemoving(false);
            }
          }
        }
      ]
    });
  };

  const handleIntegrationSelect = (integrationId, isChecked) => {
    if (selectedCode) return;

    const newSelectedIntegrationIds = new Set(selectedIntegrationIds);
    if (isChecked) {
      newSelectedIntegrationIds.add(integrationId);
    } else {
      newSelectedIntegrationIds.delete(integrationId);
    }

    setSelectedIntegrationIds(newSelectedIntegrationIds);

    // if multiple integrations selected or none selected, set shopifyCodeType to order, code type selector will also disable
    if (newSelectedIntegrationIds.size > 1 || newSelectedIntegrationIds.size === 0) {
      setShopifyCodeType(AMOUNT_OFF_ORDER);
      // clear out selected collections
      setSelectedCollections([]);
    }
  };

  const getShopifyCodeIntegrationDisplay = () => {
    if (!hasShopifyCodesIntegration) return null;
    const disabledShopifyIntegrations = allIntegrations.filter(i => !i.allowCustomCodes);

    return (
      <>
        {codesIntegrations.map(integration => {
          return (
            <div className='text-section-list-item' key={integration.id}>
              <input
                type='checkbox'
                disabled={!!selectedCode}
                checked={selectedIntegrationIds.has(integration.id)}
                onChange={evt => handleIntegrationSelect(integration.id, evt.target.checked)}
              />
              <span>{integration.domain}</span>
            </div>
          );
        })}
        {!selectedCode &&
          disabledShopifyIntegrations.length > 0 &&
          disabledShopifyIntegrations.map(integration => {
            return (
              <div className='text-section-list-item disabled' key={integration.id}>
                <input type='checkbox' disabled={true} />
                <span>{`${integration.domain} (codes disabled)`}</span>
              </div>
            );
          })}
      </>
    );
  };

  // For now, do not show shopify collections dropdown if shopify product IDs have been set on shopify side. Only support shopify collections currently
  const shouldShowShopifyCollectionsDropdown =
    hasShopifyCodesIntegration && shopifyCodeType === AMOUNT_OFF_PRODUCTS && !selectedCode?.shopifyProductIds;

  const hasMultipleCodes = codeOptions.length > 1;
  const isSubmitDisabled = isSaving || isRemoving || (hasShopifyCodesIntegration && selectedIntegrationIds.size === 0);

  // Advaced UX for auto-filling placeholders
  const mostRecentCode = _.orderBy(getBrandCodes(analytics), 'createdAt', 'desc')[0];
  const mostRecentCodeRate = mostRecentCode?.rate || 20;
  const preferredDisplayFormat = promoterDetails?.settings?.preferredCodeFormat;
  const isNonDefaultPreferred = preferredDisplayFormat && !isCodeFormattingDefault(preferredDisplayFormat);
  const displayPlaceholder =
    selectedCode?.displayText ||
    (preferredDisplayFormat && getCodeDisplayForUserWithFormat(promoterDetails, preferredDisplayFormat, mostRecentCodeRate));
  const ratePlaceholder = selectedCode ? getCodeRateDisplay(selectedCode) : getCodeRateDisplay(mostRecentCode) || '20%';
  const applyDisplayPlaceholder = () => setDisplayText(displayPlaceholder);
  const applyRatePlaceholder = () => setRate(ratePlaceholder);
  const hasDisplay = !!displayText;
  const hasRate = !!rate;

  return (
    <Modal
      visible
      saving={isSaving || isRemoving}
      showClose
      close={closeModal}
      className='codes-modal'
      innerClassName='codes-modal-inner'
      contentClassName='codes-modal-content'
    >
      <div className='content-wrapper'>
        <div className='content-header'>
          Custom Code{hasMultipleCodes ? 's' : ''} for {name}
        </div>
        <div className='body-sections'>
          {hasMultipleCodes && !!selectedCode && (
            <div className='section'>
              <div className='header-container'>
                <div className='header'>Active Codes</div>
              </div>
              <div className='select-field'>
                <Select
                  value={{ value: selectedCode.id, label: selectedCode.displayText }}
                  options={codeOptions.map(c => ({ value: c.id, label: c.displayText, sublabel: c.integrationDomain }))}
                  onChange={option => {
                    const code = codeOptions.find(c => c.id === option.value);
                    setSelectedCode(code);
                  }}
                  styles={{
                    singleValue: provided => ({
                      ...provided,
                      fontWeight: 'bold',
                      fontSize: '14px'
                    })
                  }}
                  components={{ Option: SelectOption }}
                />
              </div>
            </div>
          )}
          {hasShopifyCodesIntegration && allIntegrations.length > 1 && (
            <div className='text-section'>
              <div className='header'>Shopify Integration</div>
              {selectedCode ? 'This code is ' : codesIntegrations.length > 1 ? 'Codes will be created and ' : 'This code will be '}
              synced with the following Shopify domains:
              <div className='text-section-list'>{getShopifyCodeIntegrationDisplay()}</div>
            </div>
          )}
          <div className='section'>
            <div className='header-container'>
              <div className='header'>Code Information</div>
            </div>
            <div className='section-fields'>
              <div className='section-field'>
                <div className={cn('field-label', { active: hasDisplay })}>Code:</div>
                <div className='field-input'>
                  <input value={displayText} onChange={e => setDisplayText(e.target.value)} placeholder={displayPlaceholder} />
                </div>
                {!hasDisplay && (
                  <div onClick={applyDisplayPlaceholder} className='apply-placeholder'>
                    Apply This Format
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                )}
                {isNonDefaultPreferred && displayPlaceholder !== displayText && (
                  <div className='preferred-format-explanation'>
                    {displayPlaceholder} is the preferred code format for {name}.
                  </div>
                )}
              </div>
              <div className='section-field'>
                <div className={cn('field-label', { active: hasRate })}>Discount Amount:</div>
                <div className='field-input'>
                  <input value={rate} onChange={e => setRate(e.target.value)} placeholder={ratePlaceholder} />
                </div>
                {!hasRate && (
                  <div onClick={applyRatePlaceholder} className='apply-placeholder'>
                    Apply This Rate
                    <FontAwesomeIcon icon={faChevronUp} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {hasShopifyCodesIntegration && (
            <div className='section'>
              <div className='header-container'>
                <div className='header'>Code Type</div>
                <Tooltip message={SHOPIFY_CODE_TYPE_TIP} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />
              </div>
              {selectedIntegrationIds.size > 1 && (
                <div className='warning-label'>
                  Only order level discount codes are supported when multiple Shopify integrations are selected above
                </div>
              )}
              {selectedIntegrationIds.size === 0 && <div className='warning-label'>Please select a Shopify integration above</div>}
              {Object.keys(SHOPIFY_CODE_TYPE_DISPLAY_NAMES).map(codeType => {
                const isChecked = codeType === shopifyCodeType;
                const isDisabled = !!selectedCode || selectedIntegrationIds.size !== 1;

                return (
                  <div
                    key={codeType}
                    className={cn('radio-select-row', { disabled: isDisabled })}
                    onClick={() => {
                      !isDisabled && setShopifyCodeType(codeType);
                    }}
                  >
                    <input
                      className='code-type-radio-input'
                      disabled={isDisabled}
                      type='radio'
                      name='shopifyCodeType'
                      checked={isChecked}
                      onChange={() => {
                        setShopifyCodeType(codeType);
                      }}
                    />
                    <span>{SHOPIFY_CODE_TYPE_DISPLAY_NAMES[codeType]}</span>
                  </div>
                );
              })}
              <div className={cn('subsection-expand', { expanded: shouldShowShopifyCollectionsDropdown })}>
                {shouldShowShopifyCollectionsDropdown && (
                  <>
                    <div className='subheader'>Apply Shopify Collections</div>
                    <div className='sublabel'>Choose collections of products that this discount code applies to.</div>
                    <InfiniteScrollSelect
                      isMultiple={true}
                      closeMenuOnSelect={false}
                      onSelectOptions={selected => {
                        setSelectedCollections(selected || []);
                      }}
                      value={selectedCollections}
                      loadMoreOptions={loadMoreCollections}
                      menuHeight={'170px'}
                      isLoading={isLoadingCollections}
                      placeholder={'Select Collections'}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          <div className='section'>
            <div className='header-container'>
              <div className='header'>Custom Message</div>
            </div>
            <textarea
              rows={6}
              value={brandMessage}
              onChange={e => setBrandMessage(e.target.value)}
              placeholder={
                selectedCode
                  ? `Feel free to include details about the updated discount code including the terms and expiration date.`
                  : `Feel free to include details about the discount code including the terms and expiration date.`
              }
            />
          </div>
        </div>
        <div className='actions'>
          <div
            onClick={() => {
              if (isSubmitDisabled) return;
              pressSave();
            }}
            className={cn('action primary', { disabled: isSubmitDisabled })}
          >
            {selectedCode ? (isSaving ? 'Saving...' : 'Save') : isSaving ? 'Creating...' : 'Create Code'}
          </div>
          {showGoBack && (
            <div onClick={cancelCreateNew} className='action secondary'>
              Go Back
            </div>
          )}
          {selectedCode && (
            <>
              <div onClick={removeCode} className='action secondary'>
                Remove Code
              </div>
              <div onClick={clearForCreateNew} className='action secondary'>
                Add Another Code
              </div>
            </>
          )}
        </div>
      </div>
      {templates.length > 0 && (
        <div className='codes-modal-templates'>
          <PartnerActionTemplates
            user={user}
            talent={{
              id: User_id,
              name
            }}
            templatesOne={templates.filter(t => t.isShopMy)}
            templatesTwo={templates.filter(t => !t.isShopMy)}
            sectionHeaderOne='Template from the ShopMy Team'
            sectionHeaderTwo='Recently Sent Messages Related To Codes'
          />
        </div>
      )}
    </Modal>
  );
};

CodesModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  setCustomCode: PropTypes.func.isRequired,
  closeCodesModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics, talent } = state;
  return { user, ui, analytics, talent };
};

export default connect(mapStateToProps, {
  setCustomCode,
  closeCodesModal
})(CodesModal);
