import _ from 'lodash';

export const cleanAlgoliaOtherImageData = other_image_data => {
  /*
    The other_image_data field on the Algolia search results contains all the information we have about siblings
    including sizes, shades, colors, etc. In the UI however we don't want to show variations in size, so we handle
    the deduplication here instead of in the Algolia index.
  */

  /*
    Step One: Remove duplicates based on sizing information.
  */
  let results = other_image_data;

  results = other_image_data.map(data => {
    let cleanedTag = data.tag.trim();

    // If tag includes numbers, but isn't just numbers, remove the numbers
    const tagWithoutNumbers = cleanedTag.replace(/[0-9.]/g, '').trim();
    if (tagWithoutNumbers.length > 0) cleanedTag = tagWithoutNumbers;

    // Remove leading and trailing special characters
    cleanedTag = cleanedTag.replace(/^[^a-zA-Z0-9]+/, '').replace(/[^a-zA-Z0-9]+$/, '');

    return {
      ...data,
      tag: cleanedTag
    };
  });

  /*
    Step Two: Remove options that do not have images in the case that it is Shopify.
  */
  results = results.filter(data => {
    if (!data.image_url) return false;
    return true;
  });

  return _.uniqBy(results, 'tag');
};

export const cleanSiblingTags = siblings => {
  /*
    We only want to show siblings that differ in a field other than size. This function
    groups the siblings by non-size based tag and then groups all the sizes into a 
    size_variation array.

    input:
      siblings = [ {tag: <tag>, image_url: <image>, ...} ]

    output:
      siblings = [ {tag: <tag>, image_url: <image>, size_variations: [ {tag: <tag>, image_url: <image>, ...} ] } ]
  */

  // Augment tags with their constituent parts
  let results = siblings.map(data => ({ ...data, tagParts: getTagParts(data.tag) }));

  // Group by non size tag
  results = _.values(_.groupBy(results, 'tagParts.nonSizeTag'))
    .map(tag_group => {
      const representativeTag = tag_group.find(tag => tag.image_url) || tag_group[0];
      return {
        ...representativeTag,
        tag: representativeTag.tagParts.nonSizeTag,
        size_variations: tag_group.map(data => ({
          ...data,
          tag: data.tagParts.sizeTag
        }))
      };
    })
    // Remove any tags that don't have an image
    .filter(data => data.image_url);

  return results;
};

/*************************************************************
 ******************* Local Helpers ***************************
 ************************************************************/
const getTagParts = rawTag => {
  /*
    Given a tag, break it out into its constituent parts. For example:

    Example:
      7.5 / Black => { nonSizeTag: 'Black', sizeTag: '7.5' }
  */
  const nonSizeTag = getNonSizeTag(rawTag);
  const sizeTag = getSizeTag(rawTag);
  return { nonSizeTag, sizeTag };
};

const getNonSizeTag = rawTag => {
  let tag = rawTag.trim();

  // If tag includes numbers, but isn't just numbers, remove the numbers
  const tagWithoutNumbers = tag.replace(/[0-9.$]/g, '').trim();
  if (tagWithoutNumbers.length > 0) tag = tagWithoutNumbers;

  // Sizes are non numerical for clothing
  const sizes = ['xxs', 'xs', 's', 'm', 'l', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl', 'xxxxxl', 'xxxxxxl', 'p'];
  const sizesWithCasingVariations = [...sizes, ...sizes.map(size => size.toUpperCase()), ...sizes.map(size => size[0].toUpperCase() + size.slice(1))];
  sizesWithCasingVariations.forEach(size => {
    tag = tag.replace(new RegExp(`\\b${size}\\b`, 'gi'), '');
  });

  // Remove leading and trailing special characters
  tag = tag.replace(/^[^a-zA-Z0-9$]+/, '').replace(/[^a-zA-Z0-9$]+$/, '');

  return tag;
};

const getSizeTag = rawTag => {
  // Remove non size tag from the tag
  const nonSizeTag = getNonSizeTag(rawTag);
  let sizeTag = rawTag.replace(nonSizeTag, '').trim();

  // Remove leading and trailing special characters
  sizeTag = sizeTag.replace(/^[^a-zA-Z0-9]+/, '').replace(/[^a-zA-Z0-9]+$/, '');

  return sizeTag;
};
