import _ from 'lodash';
import moment from 'moment';

import { isAdmin, getUserId, getBrand, getBrandId, getBrandBudgetRemaining } from './user_helpers';
import { getPrettyNumber, getPrettyPriceDisplay } from './formatting';

export const DAYS_UNTIL_CONTRACT_INVOICES_DUE = 14;

export const canEditContract = (user, contract) => {
  if (!contract) return false;
  return (
    isAdmin(user) ||
    (contract.Brand_id === getBrandId(user) && !!contract.isCreatedByBrand) ||
    (contract.User_id === getUserId(user) && !!contract.isCreatedByUser)
  );
};

export const getStatusDisplayForContract = contract =>
  ({
    pending: 'Editing',
    review: 'In Review', // Not currently used but can be if we start to see too many bad proposals
    accepted: 'In Progress',
    active: 'Active',
    closed: 'Closed',
    rejected: 'Dismissed',
    expired: 'Expired'
  }[contract.status] || _.startCase(contract.status));

export const getStatusTimeDisplayForContract = contract => {
  let time, display;
  switch (contract.status) {
    case 'review':
      display = 'Proposed On';
      time = contract.proposedDate;
      break;
    case 'proposed':
      display = 'Proposed On';
      time = contract.proposedDate;
      break;
    case 'accepted':
      display = 'Accepted On';
      time = contract.acceptedDate;
      break;
    case 'active':
      display = 'Started On';
      time = contract.activeDate;
      break;
    case 'closed':
      display = 'Closed On';
      time = contract.closedDate;
      break;
    case 'expiredDate':
      display = 'Expired On';
      time = contract.expiredDate;
      break;
    case 'rejected':
      display = 'Dismissed On';
      time = contract.rejectedDate;
      break;
    case 'pending':
    default:
      display = 'Created On';
      time = contract.createdAt;
      break;
  }
  return `${display} ${moment(time).format('MMMM D, YYYY')}`;
};

export const getAnalyticsDisplay = contract => {
  const { stats, tasks } = contract;
  const { views, engagement, clicks, orderVolume } = stats || {};
  let displays = [];
  const singleTask = tasks.length === 1 && tasks[0];
  if (views) displays.push(`${getPrettyNumber(views)} view${views === 1 ? '' : 's'}`);
  if (engagement) displays.push(`${getPrettyNumber(engagement)} ${getEngagementWordForType(singleTask)}${engagement === 1 ? '' : 's'}`);
  if (clicks) displays.push(`${getPrettyNumber(clicks)} click${clicks === 1 ? '' : 's'}`);
  if (orderVolume) displays.push(`${getPrettyPriceDisplay(orderVolume)} in orders`);
  return displays;
};

export const getEngagementWordForType = type => {
  return (
    {
      instagram: 'engagement',
      tiktok: 'engagement',
      youtube: 'like'
    }[type] || 'engagement'
  );
};

export const getTitleForMedia = media => {
  return (
    media.title ||
    _.get(
      {
        instagram: {
          CAROUSEL_ALBUM: 'Instagram Post',
          IMAGE: 'Instagram Post',
          VIDEO: 'Instagram Video',
          REELS: 'Instagram Reel'
        }
      },
      [media.type, media.subtype],
      `${_.startCase(media.type)} Content`
    )
  );
};

export const getSubmissionDueDateDaysRemaining = (task, contract) => {
  const { submissionDueDate, submissionDueDateDays } = task;
  if (!submissionDueDate && !submissionDueDateDays) return null;
  const days = submissionDueDate
    ? moment(submissionDueDate).diff(moment(), 'days')
    : moment(contract.acceptedDate)
        .add(submissionDueDateDays, 'days')
        .diff(moment(), 'days');
  return days;
};

export const getGoLiveDueDateDaysRemaining = (task, contract) => {
  const { contentLiveDueDate, contentLiveDueDateDays } = task;
  if (!contentLiveDueDate && !contentLiveDueDateDays) return null;
  const days = contentLiveDueDate
    ? moment(contentLiveDueDate).diff(moment(), 'days')
    : moment(contract.acceptedDate)
        .add(contentLiveDueDateDays, 'days')
        .diff(moment(), 'days');
  return days;
};

export const getPriceForTask = task => task.price * (task.taskDuration || 1);
export const getContractPrice = contract => contract.price || _.sum(_.map(contract.tasks, getPriceForTask));

export const getPlatformFeeRate = (user, contract) => {
  /*
    Crucial! This logic must stay in sync with the server logic.
  */
  const { sms_rate_override } = contract || {};

  let rate;
  if (sms_rate_override) {
    rate = sms_rate_override;
  } else {
    rate = 8;
  }

  return rate;
};

export const getPlatformFee = (user, contract) => {
  const platformFee = getPlatformFeeRate(user, contract);
  const budgetRemaining = getBrandBudgetRemaining(user);
  const price = _.sum(
    _.map(contract.tasks, task => {
      if (task.template.subtype === 'stipend') return 0;
      return getPriceForTask(task);
    })
  );
  const priceOwed = _.max([0, price - budgetRemaining]);
  return (priceOwed * platformFee) / 100;
};
