import React from 'react';
import './MobileLanding.scss';

const MobileLanding = props => {
  return (
    <div className='mobile-landing-outer-container'>
      <div className='mobile-landing-inner-container'>
        <div className='left-panel'>
          <div className='header-section'>
            <h1>
              Introducing the
              <br />
              ShopMy App
            </h1>
            <h2>Everything you love about ShopMy, made easier. Chat with brands. Accept gifts. Create links in seconds.</h2>
            <a href='https://apps.apple.com/us/app/shopmy/id6443850511' target='_blank' rel='noopener noreferrer' className='app-store-btn'>
              <img src='https://static.shopmy.us/app_store.png' alt='App Mockup' />
            </a>
          </div>
        </div>
        <div className='right-panel'>
          <img src='https://static.shopmy.us/Home/app_mockup.png' alt='App Mockup' />
        </div>
      </div>
    </div>
  );
};

export default MobileLanding;
