import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './BrandInvoices.scss';

import { getInvoiceTableData } from '../../Helpers/subscription_helpers';

const BrandInvoices = props => {
  const { invoices } = props;

  const unpaidInvoices = invoices.filter(i => i.payment_status === 'unpaid' || i.status === 'unpaid');
  const sortedInvoices = _.orderBy(
    invoices,
    [i => i.payment_status === 'unpaid' || i.status === 'unpaid', i => i.sentOn || i.createdAt],
    ['desc', 'desc']
  );

  // Handle Paging
  const numVisibleToStart = unpaidInvoices.length || 8;
  const [numVisible, setNumVisible] = React.useState(numVisibleToStart);
  const visibleInvoices = sortedInvoices.slice(0, numVisible);
  const showLess = () => setNumVisible(numVisible - 8);
  const showMore = () => setNumVisible(numVisible + 8);
  const showMoreButton = numVisible < sortedInvoices.length;
  const showLessButton = numVisible > numVisibleToStart;
  const showPagingButtons = showMoreButton || showLessButton;

  // Show data
  const isPastDue = invoice =>
    (invoice.payment_status === 'unpaid' || invoice.status === 'unpaid') && invoice.dueOn && new Date(invoice.dueOn) < new Date();
  const isWarning = invoice => (invoice.payment_status === 'unpaid' || invoice.status === 'unpaid') && !isPastDue(invoice);
  const data = getInvoiceTableData();

  return (
    <div className={cn('brand-invoices-container', { 'on-dark': props.isDark })}>
      <div className='brand-invoices'>
        <React.Fragment>
          {data.map(d => (
            <div className='header cell' key={d.header}>
              {d.header}
            </div>
          ))}
        </React.Fragment>
        {visibleInvoices.map(invoice => {
          return (
            <React.Fragment key={invoice.id}>
              {data.map(d => (
                <div
                  className={cn('cell', {
                    warning: isWarning(invoice),
                    alert: isPastDue(invoice)
                  })}
                  key={d.header}
                >
                  {d.getValue(invoice)}
                </div>
              ))}
            </React.Fragment>
          );
        })}
      </div>
      {showPagingButtons && (
        <div className='show-more-container'>
          {showLessButton && (
            <div className='show-btn' onClick={showLess}>
              Show Less
            </div>
          )}
          {showMoreButton && (
            <div className='show-btn' onClick={showMore}>
              Show More
            </div>
          )}
        </div>
      )}
    </div>
  );
};
BrandInvoices.propTypes = {
  invoices: PropTypes.array.isRequired

  // Optional UI
};

export default BrandInvoices;
