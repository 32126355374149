import _ from 'lodash';
import { getPrettyNumber } from './formatting';
import { getUserTiers } from './tier_helpers';

export const getTalent = talent => _.get(talent, 'talent') || [];
export const getTalentById = (talent, id) => getTalent(talent).find(t => t.id === id);
export const isFetchingInitialTalent = talent => _.get(talent, 'isFetchingInitial') || false;

const talentActionAndStatusEnumTypes = ['CHAT', 'GIFTING', 'CODE', 'COLLABORATION'];

export const isTopTalent = talent => {
  /*
    This is used to scope the UI to only the talent that matter as to reduce the overhead for
    brands who don't have enough time to respond.

    !! Critical - this is used in the API to determine which talent to show to brands.
    If you change this, you must also change the API (dbHelpers version of user.js file)
  */
  // Icons and Trendsetters
  if (talent.score >= 70) return true;

  // One value high enough to justify a response
  if (['highest', 'higher', 'high'].includes(getTalentStatLevel(talent, 'social_following'))) return true;

  return false;
};

export const isTopTalentExplanation = 'Top Talent have a large social following or have driven a significant amount of sales.';

export const getVisibleTalentStatusEnum = ({ statusEnums, curStatusEnum, curActionEnum }) => {
  /*
    Returns the primary status enumeration to display. If there is a selected status enumeration,
    we choose that, if there is a selected action enumeration, we try to choose the most relevant status.
    Otherwise, we default to the primary one selected by the API.
  */
  if (curStatusEnum) {
    // If filtered to a specific status, show that
    return statusEnums.find(se => se === curStatusEnum) || statusEnums[0];
  } else if (curActionEnum) {
    // If filtered to a specific action, show the most relevant status
    let matchingStatus;
    talentActionAndStatusEnumTypes.forEach(type => {
      if (curActionEnum.includes(type)) {
        matchingStatus = matchingStatus || statusEnums.find(se => se.includes(type));
      }
    });
    if (matchingStatus) return matchingStatus;
  }
  return statusEnums[0];
};

export const getVisibleTalentActionEnum = ({ actionEnums, curActionEnum, curStatusEnum }) => {
  /*
    Returns the primary action enumeration to display. If there is a selected action enumeration,
    we choose that, if there is a selected status enumeration, we try to choose the most relevant action.
    Otherwise, we default to the primary one selected by the API.
  */
  if (curActionEnum) {
    // If filtered to a specific status, show that
    return actionEnums.find(se => se === curActionEnum) || actionEnums[0];
  } else if (curStatusEnum) {
    // If filtered to a specific status, show the most relevant action
    let matchingAction;
    talentActionAndStatusEnumTypes.forEach(type => {
      if (curStatusEnum.includes(type)) {
        matchingAction = matchingAction || actionEnums.find(se => se.includes(type));
      }
    });
    if (matchingAction) return matchingAction;
  }
  return actionEnums[0];
};

export const getTalentStatusEnumDetails = statusEnum =>
  ({
    // Recommendation Phase
    RECOMMENDATION_GIFTING: {
      display: 'Recommended for Gifting',
      rank: 4,
      showAlert: false,
      isVisible: true,
      section: 'recommending',
      getComment: talent => talent.recommendation?.description
    },
    RECOMMENDATION_RATES: {
      display: 'Recommended for Rate Increases',
      rank: 5,
      showAlert: false,
      isVisible: true,
      section: 'recommending',
      getComment: talent => talent.recommendation?.description
    },
    RECOMMENDATION_CODES: {
      display: 'Recommended for Codes',
      rank: 6,
      showAlert: false,
      isVisible: true,
      section: 'recommending',
      getComment: talent => talent.recommendation?.description
    },
    RECOMMENDATION_OUTREACH: {
      display: 'Recommended for Outreach',
      rank: 7.5,
      showAlert: false,
      isVisible: true,
      section: 'recommending',
      getComment: talent => talent.recommendation?.description
    },
    RECOMMENDATION_COLLABORATION: {
      display: 'Recommended for Collabs',
      rank: 7,
      showAlert: false,
      isVisible: true,
      section: 'recommending',
      getComment: talent => talent.recommendation?.description
    },
    LIST_USER: {
      display: 'In Created List',
      rank: 8,
      showAlert: false,
      isVisible: true,
      section: 'lists'
    },
    // Pre-Gifting Options
    REQUESTING_GIFTING: {
      display: 'Requesting Gifting',
      rank: 9,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    REQUESTED_GIFTING: {
      display: 'Sent Gifting Request',
      rank: 10,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    GIFTING_ACCEPTED: {
      display: 'Accepted Gifting',
      rank: 11,
      showAlert: false,
      isVisible: true,
      section: 'recruiting',
      getComment: talent => {
        const lastMessage = talent.chat?.lastMessage;
        return lastMessage;
      }
    },
    // Post-Gifting Options
    TRYING_GIFTING: {
      display: 'Trying Product',
      rank: 12,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    TRYING_GIFTING_AFTER_FOLLOWUP: {
      display: 'Followed Up On Gifting',
      rank: 13,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    DISMISSED_GIFTING_FOLLOWUP: {
      display: 'Dismissed Gifting Followup',
      rank: 14,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    TRIED_GIFTING_NOT_PROMOTING: {
      display: 'Tried Product, Not Promoting',
      rank: 15,
      showAlert: false,
      isVisible: false,
      section: 'internal'
    },
    // Code Options
    HAS_CODE: {
      display: 'Has Discount Code',
      rank: 16,
      showAlert: false,
      isVisible: true,
      section: 'optimizing',
      // To ensure we show everyone with a code regardless of their other stats
      // This is only used in extreme edge cases, please only use it if absolutely necessary.
      // Typically you can solve it by ensuring the status or action is in the returned array
      talentFilterForceOverride: talent => !!talent.has_code
    },
    HAS_CODE_AFTER_FOLLOWUP: {
      display: 'Followed Up On Code',
      rank: 17,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    DISMISSED_CODE_FOLLOWUP: {
      display: 'Dismissed Code Followup',
      rank: 18,
      showAlert: false,
      isVisible: false,
      section: 'optimizing'
    },
    HAS_CODE_NOT_PROMOTING: {
      display: 'Has Code, Not Promoting',
      rank: 19,
      showAlert: false,
      isVisible: false,
      section: 'optimizing'
    },
    PROMOTING_WITH_CODE: {
      display: 'Driving Code Volume',
      rank: 20,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    // Rate Options
    HAS_RATE: {
      display: 'Has Custom Rate',
      rank: 20.5,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    // Collaboration Options
    PROPOSED_COLLABORATION: {
      display: 'Proposed Collaboration',
      rank: 21,
      showAlert: false,
      isVisible: false,
      section: 'contacting',
      getComment: talent => talent.chat?.lastMessage,
      getCommentAuthor: talent => talent
    },
    // Chat Options
    UNREAD_CHAT: {
      display: 'Unread Chat Message',
      rank: 22,
      showAlert: false,
      isVisible: false,
      section: 'contacting',
      getComment: talent => talent.chat?.lastMessage,
      getCommentAuthor: talent => talent
    },
    // Promoting Options
    // DRIVING_DTC_VOLUME: {
    //   display: 'Driving DTC Volume',
    //   rank: 23,
    //   showAlert: false,
    //   isVisible: true,
    //   section: 'collaborating',
    // },
    // DRIVING_INDIRECT_VOLUME: {
    //   display: 'Driving Non-DTC Volume',
    //   rank: 24,
    //   showAlert: false,
    //   isVisible: true,
    //   section: 'optimizing',
    // },
    IS_PROMOTING: {
      display: 'Currently Promoting',
      rank: 25,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    ONBOARDED_BY_BRAND: {
      display: 'Onboarded By You',
      rank: 25.5,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    DRIVING_VOLUME: {
      display: 'Driving Volume',
      rank: 26,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    DRIVING_VOLUME_LAST_30: {
      display: 'Driving Volume in Last 30 Days',
      rank: 26.5,
      showAlert: false,
      isVisible: true,
      section: 'optimizing'
    },
    SENT_OPPORTUNITY: {
      display: 'Sent Opportunity',
      rank: 27,
      showAlert: false,
      isVisible: true,
      section: 'recruiting'
    },
    NOT_IN_PIPELINE: {
      display: 'Unknown Status',
      rank: 28,
      showAlert: false,
      isVisible: window.__IS_DEV__,
      section: 'optimizing'
    }
  }[statusEnum] || {
    display: statusEnum || 'Unknown',
    rank: 1e6,
    showAlert: false,
    isVisible: true,
    section: 'internal'
  });

export const getTalentActionEnumDetails = actionEnum =>
  ({
    LIST_USER: {
      display: 'In Brand List',
      rank: 5,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    // Gifting
    RESPOND_TO_GIFTING_REQUEST: {
      display: 'Respond To Gifting Request',
      rank: 6,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },
    WAITING_ON_USER_TO_ACCEPT_GIFTING: {
      display: 'Wait On User To Accept Gifting',
      rank: 7,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    GIFTING_REQUEST_NEEDS_SHIPPING: {
      display: 'Send Product',
      rank: 8,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },
    WAITING_ON_USER_TO_TRY_PRODUCT: {
      display: 'Wait on User To Try Product',
      rank: 9,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    SHOULD_FOLLOW_UP_ON_GIFTING: {
      display: 'Follow Up On Recent Gifting',
      rank: 10,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },
    WAITING_ON_USER_TO_TRY_PRODUCT_AFTER_FOLLOWUP: {
      display: 'Wait on User To Try Product',
      rank: 11,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    WAITING_ON_USER_TO_TRY_PRODUCT_AFTER_DISMISSING_FOLLOWUP: {
      display: 'Wait on User to Try Product',
      rank: 12,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    SHOULD_CONSIDER_GIFTING_FAILED: {
      display: 'Nothing to do, Gifting Failed',
      rank: 13,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },

    // Codes
    WAITING_ON_USER_TO_USE_CODE: {
      display: 'Wait On Code Usage',
      rank: 14,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    SHOULD_FOLLOW_UP_ON_CODE: {
      display: 'Follow Up On Code',
      rank: 15,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },
    WAITING_ON_USER_TO_USE_CODE_AFTER_FOLLOWUP: {
      display: 'Wait on User to Use Code',
      rank: 16,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    WAITING_ON_USER_TO_USE_CODE_AFTER_DISMISSING_FOLLOWUP: {
      display: 'Wait on User to Use Code',
      rank: 17,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    SHOULD_CONSIDER_CODE_FAILED: {
      display: 'Nothing to Do, Code Failed',
      rank: 18,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },
    // Rates
    // None

    // Collaborations
    RESPOND_TO_COLLABORATION_PROPOSAL: {
      display: 'Respond To Collab Proposal',
      rank: 19,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },

    // Chats
    REPLY_TO_CHAT: {
      display: 'Reply To Chat',
      rank: 20,
      showAlert: true,
      isVisible: true,
      hasAction: true
    },

    // Recommendations
    RECOMMENDATION_GIFTING: {
      display: 'Recommended For Gifting',
      rank: 21,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    RECOMMENDATION_RATES: {
      display: 'Recommended For Rate Increase',
      rank: 22,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    RECOMMENDATION_CODES: {
      display: 'Recommended For Discount Codes',
      rank: 23,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    RECOMMENDATION_COLLABORATION: {
      display: 'Recommended For Collaboration',
      rank: 24,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },

    // Opportunities
    WAITING_ON_USER_TO_ACCEPT_OPPORTUNITY: {
      display: 'Waiting On User To Accept Opportunity',
      rank: 25,
      showAlert: false,
      isVisible: false,
      hasAction: false
    },

    // Promoters
    PROPOSE_COLLABORATION: {
      display: 'Propose Collaboration',
      rank: 26,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    PROPOSE_CODE: {
      display: 'Propose Code',
      rank: 27,
      showAlert: false,
      isVisible: false,
      hasAction: true
    },
    PROPOSE_GIFTING: {
      display: 'Propose Gifting',
      rank: 28,
      showAlert: false,
      isVisible: false,
      hasAction: true
    }
  }[actionEnum] || {
    display: actionEnum,
    rank: 1e6,
    showAlert: false, // WHILE DEBUGGING SET THIS TO TRUE
    isVisible: false // WHILE DEBUGGING SET THIS TO TRUE
  });

export const getTalentStatBadge = level =>
  ({
    highest: {
      display: {
        general: 'Elite',
        following: 'Elite',
        engagement: 'Elite',
        frequency: 'Elite',
        acceptance_rate: 'Elite',
        promotion_rate: 'Elite',
        dtc_volume: '100%',
        amount: 'Luxury'
      }
    },
    higher: {
      display: {
        general: 'Great',
        following: 'Macro',
        engagement: 'Great',
        frequency: 'Daily',
        promotion_rate: 'Very High',
        acceptance_rate: 'Very High',
        dtc_volume: '75-95%',
        amount: 'Substantial'
      }
    },
    high: {
      display: {
        general: 'Good',
        following: 'Mid-Tier',
        engagement: 'Good',
        frequency: 'Semiweekly',
        promotion_rate: 'High',
        acceptance_rate: 'High',
        dtc_volume: 'high',
        amount: 'Sizeable'
      }
    },
    medium: {
      display: {
        general: 'Okay',
        following: 'Micro',
        engagement: 'Okay',
        frequency: 'Weekly',
        promotion_rate: 'Medium',
        acceptance_rate: 'Average',
        dtc_volume: 'medium',
        amount: 'Standard'
      }
    },
    low: {
      display: {
        general: 'Low',
        following: 'Nano',
        engagement: 'Low',
        frequency: 'Monthly',
        promotion_rate: 'Low',
        acceptance_rate: 'Low',
        dtc_volume: 'low',
        amount: 'Economical'
      }
    },
    lowest: {
      display: {
        general: 'Poor',
        following: 'Small',
        engagement: 'Very Low',
        frequency: 'Infrequently',
        promotion_rate: 'Low',
        acceptance_rate: 'Poor',
        dtc_volume: 'lowest',
        amount: 'Minimal'
      }
    },
    na: {
      display: {
        general: 'N/A',
        following: 'N/A',
        engagement: 'N/A',
        frequency: 'N/A',
        promotion_rate: 'N/A',
        acceptance_rate: 'N/A',
        dtc_volume: 'N/A',
        amount: 'N/A'
      }
    }
  }[level] || {
    display: {
      general: 'Unknown'
    }
  });

export const getPrimarySocialPlatform = talent => {
  const { adj_instagram_count, adj_tiktok_count, adj_youtube_count } = talent;
  // In cases where we have a hybrid of social platform stats, show the primary stat

  if (!adj_instagram_count && !adj_tiktok_count && !adj_youtube_count) return 'na';

  const platformMapping = {
    instagram: adj_instagram_count,
    tiktok: adj_tiktok_count,
    youtube: adj_youtube_count
  };

  // return key of largest count
  const primaryPlatform = Object.keys(platformMapping).reduce((a, b) => (platformMapping[a] > platformMapping[b] ? a : b));
  return primaryPlatform;
};

export const getTalentStatDisplayValue = (talent, type) => {
  const mappings = {
    social_following: () => {
      const primaryPlatform = getPrimarySocialPlatform(talent);
      const displayValues = {
        instagram: `${getPrettyNumber(talent.instagramCount)} on IG`,
        tiktok: `${getPrettyNumber(talent.tiktokCount)} on TT`,
        youtube: `${getPrettyNumber(talent.youtubeCount)} on YT`
      };
      return displayValues[primaryPlatform] || '-';
    },
    social_engagement: () => {
      const primaryPlatform = getPrimarySocialPlatform(talent);
      const displayValues = {
        instagram: `${getPrettyNumber(Math.round(talent.instagramAverageLikes + talent.instagramAverageComments))} / IG Post`,
        tiktok: `${getPrettyNumber(Math.round(talent.tiktokAverageLikes + talent.tiktokAverageComments))} / TT Post`,
        youtube: `${getPrettyNumber(Math.round(talent.youtubeAverageViews))} / YT Vid`
      };
      return displayValues[primaryPlatform] || '-';
    }
  };

  return mappings[type] ? mappings[type]() : '-';
};

export const getTalentStatLevel = (talent, type) => {
  /*
    See full queries at end of page.
  */
  const {
    instagramCount,
    instagramAverageStoryViews,
    youtubeCount,
    tiktokCount,
    totalPinOrders,
    volume,
    trueScore,
    monthly_order_volume,
    indirect_volume_ratio,
    dtc_volume_ratio,
    conversion_rate,
    instagram_like_engagement,
    tiktok_like_engagement,
    youtube_view_engagement,
    posts_per_week,
    giftsAccepted,
    giftsReceived,
    giftsPromoted,
    gifting_promotion_rate,
    gifting_acceptance_rate,
    codesReceived,
    codesPromoted,
    codes_promotion_rate,
    averageOrderAmount
  } = talent;
  return (
    (
      {
        social_following: () => {
          if (instagramCount > 650000) return 'highest';
          if (youtubeCount > 1000000) return 'highest';
          if (tiktokCount > 1000000) return 'highest';

          if (instagramCount > 300000) return 'higher';
          if (youtubeCount > 100000) return 'higher';
          if (tiktokCount > 727600) return 'higher';

          if (instagramCount > 100000) return 'high';
          if (youtubeCount > 20000) return 'high';
          if (tiktokCount > 250000) return 'high';

          if (instagramCount > 25000) return 'medium';
          if (youtubeCount > 5000) return 'medium';
          if (tiktokCount > 75000) return 'medium';

          if (instagramCount > 6000) return 'low';
          if (youtubeCount > 1000) return 'low';
          if (tiktokCount > 10000) return 'low';

          if (instagramCount > 0) return 'lowest';
          if (youtubeCount > 0) return 'lowest';
          if (tiktokCount > 0) return 'lowest';

          return 'na';
        },
        social_engagement: () => {
          const primaryPlatform = getPrimarySocialPlatform(talent);
          const engagementField = {
            instagram: instagram_like_engagement,
            tiktok: tiktok_like_engagement,
            youtube: youtube_view_engagement
          };
          const engagement = engagementField[primaryPlatform];

          if (engagement >= 200) return 'highest';
          if (engagement >= 110) return 'higher';
          if (engagement >= 65) return 'high';
          if (engagement >= 20) return 'medium';
          if (engagement >= 7) return 'low';
          if (engagement > 0) return 'lowest';

          return 'na';
        },
        score: () => {
          if (trueScore > getUserTiers()[3].minScore) return 'highest';
          if (trueScore > getUserTiers()[2].minScore) return 'high';
          if (trueScore > getUserTiers()[1].minScore) return 'medium';
          return 'lowest';
        },
        social_post_frequency: () => {
          if (posts_per_week > 11.5) return 'highest';
          if (posts_per_week >= 5) return 'higher';
          if (posts_per_week >= 2) return 'high';
          if (posts_per_week >= 0.75) return 'medium';
          if (posts_per_week >= 0.25) return 'low';
          if (posts_per_week > 0) return 'lowest';
          return 'na';
        },
        monthly_order_volume: () => {
          if (monthly_order_volume > 30000) return 'highest';
          if (monthly_order_volume > 10000) return 'higher';
          if (monthly_order_volume > 3000) return 'high';
          if (monthly_order_volume > 500) return 'medium';
          if (monthly_order_volume > 0) return 'low';
          return 'na';
        },
        conversion_rate: () => {
          if (!totalPinOrders) return 'na';

          if (conversion_rate > 0.03) return 'highest';
          if (conversion_rate > 0.02) return 'higher';
          if (conversion_rate > 0.01) return 'high';
          if (conversion_rate > 0.004) return 'medium';
          if (conversion_rate > 0) return 'low';

          return 'na';
        },
        gifting_acceptance_rate: () => {
          if (talent.giftsAccepted || talent.giftsRejected) {
            // Have accepted, consider ratio valid
            if (gifting_acceptance_rate >= 90 && giftsReceived > 10) return 'highest';
            if (gifting_acceptance_rate >= 80) return 'higher';
            if (gifting_acceptance_rate >= 65) return 'high';
            if (gifting_acceptance_rate >= 35) return 'medium';
            if (gifting_acceptance_rate > 0) return 'low';
            return 'lowest';
          } else {
            // Have accepted, consider ratio valid
            if (talent.giftsReceived >= 5) return 'lowest';
            if (talent.giftsReceived >= 2) return 'low';
          }
          return 'na';
        },
        gifting_promotion_rate: () => {
          if (giftsAccepted) {
            // Have accepted, consider ratio valid
            if (gifting_promotion_rate >= 20 && giftsPromoted >= 2) return 'highest';
            if (gifting_promotion_rate >= 15) return 'higher';
            if (gifting_promotion_rate >= 10) return 'high';
            if (gifting_promotion_rate > 0) return 'medium';
            if (gifting_promotion_rate === 0 && giftsAccepted >= 5) return 'lowest';
            if (gifting_promotion_rate === 0 && giftsAccepted >= 1) return 'low';
            return 'na';
          }
          return 'na';
        },
        codes_conversion_rate: () => {
          if (codesReceived) {
            // Have received, consider ratio valid
            if (codes_promotion_rate >= 30 && codesPromoted > 2) return 'highest';
            if (codes_promotion_rate >= 30) return 'higher';
            if (codes_promotion_rate >= 15) return 'high';
            if (codes_promotion_rate >= 4) return 'medium';
            if (codes_promotion_rate === 0 && codesReceived >= 5) return 'lowest';
            if (codes_promotion_rate === 0 && codesReceived >= 2) return 'low';
            return 'na';
          }
          return 'na';
        },
        indirect_volume: () => {
          if (!volume?.total) return 'na'; // Volume requires detailed API call

          if (indirect_volume_ratio === 100) return 'highest';
          if (indirect_volume_ratio >= 75) return 'higher';
          if (indirect_volume_ratio >= 50) return 'high';
          if (indirect_volume_ratio >= 25) return 'medium';
          if (indirect_volume_ratio >= 25) return 'low';
          return 'lowest';
        },
        dtc_volume: () => {
          if (!volume?.total) return 'na'; // Volume requires detailed API call

          if (dtc_volume_ratio === 100) return 'highest';
          if (dtc_volume_ratio >= 75) return 'higher';
          if (dtc_volume_ratio >= 50) return 'high';
          if (dtc_volume_ratio >= 25) return 'medium';
          if (dtc_volume_ratio >= 25) return 'low';
          return 'lowest';
        },
        brand_fit: () => 'na',
        instagram_story_views: () => {
          if (instagramAverageStoryViews >= 20000) return 'highest';
          if (instagramAverageStoryViews >= 5000) return 'higher';
          if (instagramAverageStoryViews >= 1000) return 'high';
          if (instagramAverageStoryViews >= 400) return 'medium';
          if (instagramAverageStoryViews >= 1) return 'low';
          return 'na';
        },
        average_order_amount: () => {
          if (averageOrderAmount > 300) return 'highest';
          else if (averageOrderAmount > 100) return 'high';
          else if (averageOrderAmount > 60) return 'medium';
          else if (averageOrderAmount > 20) return 'low';
          else if (averageOrderAmount > 0) return 'lowest';
          return 'na';
        }
      }[type] || (() => {})
    )() || 'na'
  );
};

export const isUserIdPromoterForOutreachPurposes = (talent, id) => isPromoterForOutreachPurposes(getTalentById(talent, id));
export const isPromoterForOutreachPurposes = talent => {
  /*
    Brands can only chat with and perform certain actions with their promoters. This is defined
    as either promoting their products or coming through a reference.
  */
  return talent?.isPromoter || talent?.isOnboardedByBrand || talent?.hasSentMessage || talent?.has_request;
};

export const isPromoterOrRecommended = talent => {
  /*
    Returns true if the user is promoting, has been gifted, has a gifting request, has a code, is in a list, or any other action.

    This means they will show up in the Talent Tab. This should NOT be used for permission checking on whether
    someone can be reached out to via chat and should ONLY be used to show things like advanced analytics.
  */
  const isPromoterOrCanReachOut = isPromoterForOutreachPurposes(talent);
  if (isPromoterOrCanReachOut) return true;

  const isRecommendation = !!talent.recommendation;
  if (isRecommendation) return true;

  return false;
};
