import React from 'react';
import PropTypes from 'prop-types';
import './OpportunitySetupGuideBetaDisclaimer.scss';

const OpportunitySetupGuideBetaDisclaimer = props => {
  return (
    <div className='planning-panel-only-message-container'>
      <div className='main-message'>
        <div className='beta-disclaimer-header'>Experimental Feature</div>
        <div className='beta-disclaimer-body'>
          We are building a new way to help you plan your opportunity by recommending talent to work with along with suggested payments. This feature
          is in beta and may not be perfect. We would love to hear your feedback.
        </div>
        <div className='actions'>
          <div onClick={props.closeToOverview} className='action'>
            Skip For Now
          </div>
          <div onClick={props.close} className='action primary'>
            Try It Out
          </div>
        </div>
      </div>
    </div>
  );
};

OpportunitySetupGuideBetaDisclaimer.propTypes = {
  close: PropTypes.object.isRequired,
  closeToOverview: PropTypes.object.isRequired
};

export default OpportunitySetupGuideBetaDisclaimer;
