import APIClient from './index';
import _ from 'lodash';

export const addMedia = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Media`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateMedia = (id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Media/${id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteMedia = media => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Media/${media.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
