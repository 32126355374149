import APIClient, { get400ErrorMessage } from './index';

export const getLookbookInviteByHash = LookbookInvite_hash => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/LookbookInvites/by_hash/${LookbookInvite_hash}`)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

export const getBrandLookbookInvites = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/LookbookInvites/brand/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

export const createLookbookInvite = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookInvites`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

/**
 * @param {Object} data
 * @param {string} data.email - email of the user to be invited
 * @param {string} data.name - full name of the user to be invited
 * @param {string} data.brandMessage - message to be sent to the user
 * @param {string} data.Lookbook_id - id of the lookbook to be sent
 */
export const sendLookbookOffPlatformInvitation = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookInvites/send_invite`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

export const markInviteAsViewed = invite => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookInvites/mark_viewed/${invite.hash}`, {})
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};
