import { addSection as addSectionAPI, deleteSection as deleteSectionAPI, updateSection as updateSectionAPI } from '../APIClient/sections';

export const ADD_SECTION_REQUEST = 'ADD_SECTION_REQUEST';
export const ADD_SECTION_SUCCESS = 'ADD_SECTION_SUCCESS';
export const ADD_SECTION_FAILURE = 'ADD_SECTION_FAILURE';

export const UPDATE_SECTION_REQUEST = 'UPDATE_SECTION_REQUEST';
export const UPDATE_SECTION_ERROR = 'UPDATE_SECTION_ERROR';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS';

export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST';
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS';
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE';

export const addSection = data => async (dispatch, getState) => {
  try {
    const section = await addSectionAPI(data);
    return dispatch({
      type: ADD_SECTION_SUCCESS,
      section
    });
  } catch (error) {
    return dispatch({ type: ADD_SECTION_FAILURE });
  }
};

export const updateSection = (section, updates) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_SECTION_REQUEST,
      section,
      updates
    });
    const newSection = await updateSectionAPI(section, updates);
    return dispatch({
      type: UPDATE_SECTION_SUCCESS,
      section: newSection,
      updates
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_SECTION_ERROR,
      section
    });
  }
};

export const deleteSection = section => async (dispatch, getState) => {
  dispatch({ type: DELETE_SECTION_REQUEST, section });
  try {
    await deleteSectionAPI(section);
    return dispatch({ type: DELETE_SECTION_SUCCESS, section });
  } catch (error) {
    return dispatch({
      type: DELETE_SECTION_FAILURE,
      section
    });
  }
};
