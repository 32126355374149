import _ from 'lodash';
import * as ActionTypes from '../Actions/DirectoryActions';

export const directory = (
  state = {
    insiders: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_INSIDER_USERS_SUCCESS:
      return {
        ...state,
        insiders: _.get(action, 'insiders', [])
      };
    default:
      return state;
  }
};
