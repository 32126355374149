import {
  getOpportunity as getOpportunityAPI,
  getOpportunityResult as getOpportunityResultAPI,
  createOpportunity as createOpportunityAPI,
  duplicateOpportunity as duplicateOpportunityAPI,
  deleteOpportunity as deleteOpportunityAPI,
  updateOpportunity as updateOpportunityAPI,
  createOpportunityUpload as createOpportunityUploadAPI,
  updateOpportunityUpload as updateOpportunityUploadAPI,
  deleteOpportunityUpload as deleteOpportunityUploadAPI,
  updateOpportunityResult as updateOpportunityResultAPI,
  createOpportunityPaymentTier as createOpportunityPaymentTierAPI,
  updateOpportunityPaymentTier as updateOpportunityPaymentTierAPI,
  deleteOpportunityPaymentTier as deleteOpportunityPaymentTierAPI,
  createOpportunityExpectation as createOpportunityExpectationAPI,
  updateOpportunityExpectation as updateOpportunityExpectationAPI,
  deleteOpportunityExpectation as deleteOpportunityExpectationAPI,
  createOpportunityPlan as createOpportunityPlanAPI,
  createSmartOpportunityPlan as createSmartOpportunityPlanAPI,
  updateOpportunityPlan as updateOpportunityPlanAPI,
  deleteOpportunityPlan as deleteOpportunityPlanAPI,
  createOpportunityPlanUser as createOpportunityPlanUserAPI,
  updateOpportunityPlanUser as updateOpportunityPlanUserAPI,
  deleteOpportunityPlanUser as deleteOpportunityPlanUserAPI
} from '../APIClient/opportunities';

import { getBrandId } from '../Helpers/user_helpers';

// Opportunities
export const SET_ACTIVE_OPPORTUNITY = 'SET_ACTIVE_OPPORTUNITY';
export const SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_SUCCESS = 'SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_SUCCESS';
export const SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_FAILURE = 'SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_FAILURE';

export const CREATE_OPPORTUNITY_SUCCESS = 'CREATE_OPPORTUNITY_SUCCESS';
export const CREATE_OPPORTUNITY_FAILURE = 'CREATE_OPPORTUNITY_FAILURE';
export const DUPLICATE_OPPORTUNITY_SUCCESS = 'DUPLICATE_OPPORTUNITY_SUCCESS';
export const DUPLICATE_OPPORTUNITY_FAILURE = 'DUPLICATE_OPPORTUNITY_FAILURE';
export const UPDATE_OPPORTUNITY_REQUEST = 'UPDATE_OPPORTUNITY_REQUEST';
export const UPDATE_OPPORTUNITY_SUCCESS = 'UPDATE_OPPORTUNITY_SUCCESS';
export const UPDATE_OPPORTUNITY_FAILURE = 'UPDATE_OPPORTUNITY_FAILURE';
export const DELETE_OPPORTUNITY_REQUEST = 'DELETE_OPPORTUNITY_REQUEST';
export const DELETE_OPPORTUNITY_SUCCESS = 'DELETE_OPPORTUNITY_SUCCESS';
export const DELETE_OPPORTUNITY_FAILURE = 'DELETE_OPPORTUNITY_FAILURE';

// Results
export const UPDATE_OPPORTUNITY_RESULT_REQUEST = 'UPDATE_OPPORTUNITY_RESULT_REQUEST';
export const UPDATE_OPPORTUNITY_RESULT_SUCCESS = 'UPDATE_OPPORTUNITY_RESULT_SUCCESS';
export const UPDATE_OPPORTUNITY_RESULT_FAILURE = 'UPDATE_OPPORTUNITY_RESULT_FAILURE';

// Payment Tiers
export const CREATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS = 'CREATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS';
export const CREATE_OPPORTUNITY_PAYMENT_TIER_FAILURE = 'CREATE_OPPORTUNITY_PAYMENT_TIER_FAILURE';
export const UPDATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS = 'UPDATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS';
export const UPDATE_OPPORTUNITY_PAYMENT_TIER_FAILURE = 'UPDATE_OPPORTUNITY_PAYMENT_TIER_FAILURE';
export const DELETE_OPPORTUNITY_PAYMENT_TIER_REQUEST = 'DELETE_OPPORTUNITY_PAYMENT_TIER_REQUEST';
export const DELETE_OPPORTUNITY_PAYMENT_TIER_SUCCESS = 'DELETE_OPPORTUNITY_PAYMENT_TIER_SUCCESS';
export const DELETE_OPPORTUNITY_PAYMENT_TIER_FAILURE = 'DELETE_OPPORTUNITY_PAYMENT_TIER_FAILURE';

// Uploads
export const CREATE_OPPORTUNITY_UPLOAD_SUCCESS = 'CREATE_OPPORTUNITY_UPLOAD_SUCCESS';
export const CREATE_OPPORTUNITY_UPLOAD_FAILURE = 'CREATE_OPPORTUNITY_UPLOAD_FAILURE';
export const DELETE_OPPORTUNITY_UPLOAD_REQUEST = 'DELETE_OPPORTUNITY_UPLOAD_REQUEST';
export const DELETE_OPPORTUNITY_UPLOAD_SUCCESS = 'DELETE_OPPORTUNITY_UPLOAD_SUCCESS';
export const DELETE_OPPORTUNITY_UPLOAD_FAILURE = 'DELETE_OPPORTUNITY_UPLOAD_FAILURE';
export const UPDATE_OPPORTUNITY_UPLOAD_REQUEST = 'UPDATE_OPPORTUNITY_UPLOAD_REQUEST';
export const UPDATE_OPPORTUNITY_UPLOAD_SUCCESS = 'UPDATE_OPPORTUNITY_UPLOAD_SUCCESS';
export const UPDATE_OPPORTUNITY_UPLOAD_FAILURE = 'UPDATE_OPPORTUNITY_UPLOAD_FAILURE';

// Expectations
export const CREATE_OPPORTUNITY_EXPECTATION_SUCCESS = 'CREATE_OPPORTUNITY_EXPECTATION_SUCCESS';
export const CREATE_OPPORTUNITY_EXPECTATION_FAILURE = 'CREATE_OPPORTUNITY_EXPECTATION_FAILURE';
export const UPDATE_OPPORTUNITY_EXPECTATION_SUCCESS = 'UPDATE_OPPORTUNITY_EXPECTATION_SUCCESS';
export const UPDATE_OPPORTUNITY_EXPECTATION_FAILURE = 'UPDATE_OPPORTUNITY_EXPECTATION_FAILURE';
export const DELETE_OPPORTUNITY_EXPECTATION_REQUEST = 'DELETE_OPPORTUNITY_EXPECTATION_REQUEST';
export const DELETE_OPPORTUNITY_EXPECTATION_SUCCESS = 'DELETE_OPPORTUNITY_EXPECTATION_SUCCESS';
export const DELETE_OPPORTUNITY_EXPECTATION_FAILURE = 'DELETE_OPPORTUNITY_EXPECTATION_FAILURE';

// Plans
export const CREATE_OPPORTUNITY_PLAN_SUCCESS = 'CREATE_OPPORTUNITY_PLAN_SUCCESS';
export const CREATE_OPPORTUNITY_PLAN_FAILURE = 'CREATE_OPPORTUNITY_PLAN_FAILURE';
export const CREATE_OPPORTUNITY_PLANS_SUCCESS = 'CREATE_OPPORTUNITY_PLANS_SUCCESS';
export const CREATE_OPPORTUNITY_PLANS_FAILURE = 'CREATE_OPPORTUNITY_PLANS_FAILURE';
export const UPDATE_OPPORTUNITY_PLAN_SUCCESS = 'UPDATE_OPPORTUNITY_PLAN_SUCCESS';
export const UPDATE_OPPORTUNITY_PLAN_FAILURE = 'UPDATE_OPPORTUNITY_PLAN_FAILURE';
export const DELETE_OPPORTUNITY_PLAN_REQUEST = 'DELETE_OPPORTUNITY_PLAN_REQUEST';
export const DELETE_OPPORTUNITY_PLAN_SUCCESS = 'DELETE_OPPORTUNITY_PLAN_SUCCESS';
export const DELETE_OPPORTUNITY_PLAN_FAILURE = 'DELETE_OPPORTUNITY_PLAN_FAILURE';

// Plan Users
export const CREATE_OPPORTUNITY_PLAN_USER_SUCCESS = 'CREATE_OPPORTUNITY_PLAN_USER_SUCCESS';
export const CREATE_OPPORTUNITY_PLAN_USER_FAILURE = 'CREATE_OPPORTUNITY_PLAN_USER_FAILURE';
export const CREATE_OPPORTUNITY_PLAN_USERS_SUCCESS = 'CREATE_OPPORTUNITY_PLAN_USERS_SUCCESS';
export const CREATE_OPPORTUNITY_PLAN_USERS_FAILURE = 'CREATE_OPPORTUNITY_PLAN_USERS_FAILURE';
export const UPDATE_OPPORTUNITY_PLAN_USER_REQUEST = 'UPDATE_OPPORTUNITY_PLAN_USER_REQUEST';
export const UPDATE_OPPORTUNITY_PLAN_USER_SUCCESS = 'UPDATE_OPPORTUNITY_PLAN_USER_SUCCESS';
export const UPDATE_OPPORTUNITY_PLAN_USER_FAILURE = 'UPDATE_OPPORTUNITY_PLAN_USER_FAILURE';
export const DELETE_OPPORTUNITY_PLAN_USER_REQUEST = 'DELETE_OPPORTUNITY_PLAN_USER_REQUEST';
export const DELETE_OPPORTUNITY_PLAN_USER_SUCCESS = 'DELETE_OPPORTUNITY_PLAN_USER_SUCCESS';
export const DELETE_OPPORTUNITY_PLAN_USER_FAILURE = 'DELETE_OPPORTUNITY_PLAN_USER_FAILURE';

// Opportunity Requests / Results
export const SET_ACTIVE_OPPORTUNITY_REQUEST = 'SET_ACTIVE_OPPORTUNITY_REQUEST';

export const SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_SUCCESS = 'SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_SUCCESS';
export const SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_FAILURE = 'SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_FAILURE';

export const setActiveOpportunity = opportunity => ({
  type: SET_ACTIVE_OPPORTUNITY,
  opportunity
});

export const syncActiveOpportunityFullData = id => async dispatch => {
  /*
    Fetch all the more detailed information that was not returned from the base analytics endpoint
    due to the data being too large.
  */
  try {
    const opportunity = await getOpportunityAPI(id);
    return dispatch({
      type: SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_SUCCESS,
      opportunity
    });
  } catch (error) {
    return dispatch({
      type: SYNC_ACTIVE_OPPORTUNITY_FULL_DATA_FAILURE,
      error: error || 'Failure syncing opportunity data'
    });
  }
};

export const createOpportunity = opportunity => async (dispatch, getState) => {
  try {
    const newOpportunity = await createOpportunityAPI({
      ...opportunity,
      Brand_id: getBrandId(getState()?.user)
    });
    return dispatch({
      type: CREATE_OPPORTUNITY_SUCCESS,
      opportunity: newOpportunity
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_FAILURE,
      error: error || 'Failure creating opportunity'
    });
  }
};

export const duplicateOpportunity = opportunity => async (dispatch, getState) => {
  try {
    const newOpportunity = await duplicateOpportunityAPI(opportunity);
    return dispatch({
      type: DUPLICATE_OPPORTUNITY_SUCCESS,
      opportunity: newOpportunity
    });
  } catch (error) {
    return dispatch({
      type: DUPLICATE_OPPORTUNITY_FAILURE,
      error: error || 'Failure creating opportunity'
    });
  }
};

export const updateOpportunityLocallyForDebounceImmediateUpdate = (opportunity, updates) => async dispatch => {
  return dispatch({ type: UPDATE_OPPORTUNITY_REQUEST, opportunity, updates });
};

export const updateOpportunity = (opportunity, updates) => async (dispatch, getState) => {
  dispatch({ type: UPDATE_OPPORTUNITY_REQUEST, opportunity, updates });
  try {
    const updatedOpportunity = await updateOpportunityAPI(opportunity, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_SUCCESS,
      opportunity: updatedOpportunity
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_FAILURE,
      opportunity,
      error: error || 'Failure updating opportunity'
    });
  }
};

export const deleteOpportunity = opportunity => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_REQUEST, opportunity });
    await deleteOpportunityAPI(opportunity);
    return dispatch({ type: DELETE_OPPORTUNITY_SUCCESS });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_FAILURE,
      opportunity,
      error: error || 'Failure deleting opportunity'
    });
  }
};

// Opportunity Payment Tiers
export const createOpportunityPaymentTier = data => async dispatch => {
  try {
    const newOpportunityPaymentTier = await createOpportunityPaymentTierAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS,
      payment_tier: newOpportunityPaymentTier
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_PAYMENT_TIER_FAILURE,
      error: error || 'Failure creating opportunity payment tier'
    });
  }
};

export const updateOpportunityPaymentTier = (payment_tier, updates) => async dispatch => {
  try {
    const updatedPaymentTier = await updateOpportunityPaymentTierAPI(payment_tier, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_PAYMENT_TIER_SUCCESS,
      payment_tier: updatedPaymentTier,
      success: true
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_PAYMENT_TIER_FAILURE,
      payment_tier,
      error: error || 'Failure updating opportunity payment tier'
    });
  }
};

export const deleteOpportunityPaymentTier = payment_tier => async dispatch => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_PAYMENT_TIER_REQUEST, payment_tier });
    await deleteOpportunityPaymentTierAPI(payment_tier);
    return dispatch({ type: DELETE_OPPORTUNITY_PAYMENT_TIER_SUCCESS, success: true });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_PAYMENT_TIER_FAILURE,
      payment_tier,
      error: error || 'Failure deleting opportunity payment tier'
    });
  }
};

// Opportunity Uploads
export const createOpportunityUpload = data => async dispatch => {
  try {
    const newOpportunityUpload = await createOpportunityUploadAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_UPLOAD_SUCCESS,
      upload: newOpportunityUpload
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_UPLOAD_FAILURE,
      error: error || 'Failure creating opportunity upload'
    });
  }
};

export const updateOpportunityUpload = (upload, updates) => async dispatch => {
  dispatch({ type: UPDATE_OPPORTUNITY_UPLOAD_REQUEST, upload, updates });
  try {
    const updatedUpload = await updateOpportunityUploadAPI(upload, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_UPLOAD_SUCCESS,
      upload: updatedUpload
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_UPLOAD_FAILURE,
      upload,
      error: error || 'Failure updating opportunity upload'
    });
  }
};

export const deleteOpportunityUpload = upload => async dispatch => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_UPLOAD_REQUEST, upload });
    await deleteOpportunityUploadAPI(upload);
    return dispatch({ type: DELETE_OPPORTUNITY_UPLOAD_SUCCESS });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_UPLOAD_FAILURE,
      upload,
      error: error || 'Failure deleting opportunity upload'
    });
  }
};

// Opportunity Requests
export const setActiveOpportunityRequest = request => ({
  type: SET_ACTIVE_OPPORTUNITY_REQUEST,
  request
});

export const syncActiveOpportunityResultFullData = id => async dispatch => {
  /*
    Fetch all the more detialed information that was not returned from the base users endpoint
    due to the data being too large.
  */
  try {
    const result = await getOpportunityResultAPI(id);
    return dispatch({
      type: SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_SUCCESS,
      result
    });
  } catch (error) {
    return dispatch({
      type: SYNC_ACTIVE_OPPORTUNITY_RESULT_FULL_DATA_FAILURE,
      error: error || 'Failure syncing opportunity result'
    });
  }
};

// Results
export const updateOpportunityResult = (result, updates) => async dispatch => {
  try {
    dispatch({ type: UPDATE_OPPORTUNITY_RESULT_REQUEST, result, updates });
    const updatedResult = await updateOpportunityResultAPI(result, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_RESULT_SUCCESS,
      result: updatedResult
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_RESULT_FAILURE,
      result,
      error: error || 'Failure updating opportunity result'
    });
  }
};

// Expectations
export const createOpportunityExpectation = data => async dispatch => {
  try {
    const newOpportunityExpectation = await createOpportunityExpectationAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_EXPECTATION_SUCCESS,
      expectation: newOpportunityExpectation
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_EXPECTATION_FAILURE,
      error: error || 'Failure creating opportunity expectation'
    });
  }
};

export const updateOpportunityExpectation = (expectation, updates) => async dispatch => {
  try {
    const updatedExpectation = await updateOpportunityExpectationAPI(expectation, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_EXPECTATION_SUCCESS,
      expectation: updatedExpectation
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_EXPECTATION_FAILURE,
      expectation,
      error: error || 'Failure updating opportunity expectation'
    });
  }
};

export const deleteOpportunityExpectation = expectation => async dispatch => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_EXPECTATION_REQUEST, expectation });
    await deleteOpportunityExpectationAPI(expectation);
    return dispatch({ type: DELETE_OPPORTUNITY_EXPECTATION_SUCCESS });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_EXPECTATION_FAILURE,
      expectation,
      error: error || 'Failure deleting opportunity expectation'
    });
  }
};

// Plans
export const createOpportunityPlan = data => async dispatch => {
  try {
    const newOpportunityPlan = await createOpportunityPlanAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_PLAN_SUCCESS,
      plan: newOpportunityPlan
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_PLAN_FAILURE,
      error: error || 'Failure creating opportunity plan'
    });
  }
};

export const createSmartOpportunityPlan = data => async dispatch => {
  /* Fires off planning phase, server just returns with success and then we use webhooks to update the plan. */
  try {
    await createSmartOpportunityPlanAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_PLANS_SUCCESS
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_PLANS_FAILURE,
      error: error || 'Failure creating opportunity plan'
    });
  }
};

export const updateOpportunityPlan = (plan, updates) => async dispatch => {
  try {
    const updatedPlan = await updateOpportunityPlanAPI(plan, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_PLAN_SUCCESS,
      plan: updatedPlan
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_PLAN_FAILURE,
      plan,
      error: error || 'Failure updating opportunity plan'
    });
  }
};

export const deleteOpportunityPlan = plan => async dispatch => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_PLAN_REQUEST, plan });
    await deleteOpportunityPlanAPI(plan);
    return dispatch({ type: DELETE_OPPORTUNITY_PLAN_SUCCESS, plan });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_PLAN_FAILURE,
      plan,
      error: error || 'Failure deleting opportunity plan'
    });
  }
};

// Plan Users
export const createOpportunityPlanUser = data => async dispatch => {
  try {
    const user = await createOpportunityPlanUserAPI(data);
    return dispatch({
      type: CREATE_OPPORTUNITY_PLAN_USER_SUCCESS,
      user
    });
  } catch (error) {
    return dispatch({
      type: CREATE_OPPORTUNITY_PLAN_USER_FAILURE,
      error: error || 'Failure adding user to plan'
    });
  }
};

export const updateOpportunityPlanUser = (user, updates) => async dispatch => {
  try {
    dispatch({ type: UPDATE_OPPORTUNITY_PLAN_USER_REQUEST, user, updates });
    const updatedUser = await updateOpportunityPlanUserAPI(user, updates);
    return dispatch({
      type: UPDATE_OPPORTUNITY_PLAN_USER_SUCCESS,
      user: updatedUser
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_OPPORTUNITY_PLAN_USER_FAILURE,
      user,
      error: error || 'Failure updating opportunity plan user'
    });
  }
};

export const deleteOpportunityPlanUser = user => async dispatch => {
  try {
    dispatch({ type: DELETE_OPPORTUNITY_PLAN_USER_REQUEST, user });
    await deleteOpportunityPlanUserAPI(user);
    return dispatch({ type: DELETE_OPPORTUNITY_PLAN_USER_SUCCESS });
  } catch (error) {
    return dispatch({
      type: DELETE_OPPORTUNITY_PLAN_USER_FAILURE,
      user,
      error: error || 'Failure deleting opportunity plan user'
    });
  }
};
