import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './BrandReportCompareDropdown.scss';

import { getExamplePrograms } from '../../APIClient/brands';
import {
  OTHER_PROGRAMS_OPTION_GROUP,
  OTHER_ACCOUNT_MANAGER_PROGRAMS_OPTION_GROUP,
  PERFORMANCE_COMPARE_OPTION_GROUP,
  PERFORMANCE_COMPARE_OPTIONS,
  PERFORMANCE_COMPARE_OPTIONS_DISPLAY
} from '../../Helpers/brand_tier_helpers';
import { getBrandId, isSimulatingUser } from '../../Helpers/user_helpers';

import SelectOption from '../General/SelectOption';

const BrandReportCompareDropdown = props => {
  const { user, onCompareChange } = props;
  const brandId = getBrandId(user);

  const [selectedCompare, setSelectedCompare] = useState(null);
  const [compareOptions, setCompareOptions] = useState([]);

  useEffect(() => {
    loadCompareOptions();
  }, []);

  const loadCompareOptions = async () => {
    /*
      Compare options:
        1) Other exemplary programs we define
        2) Other programs that this account manager manages (admin only)
    */

    const response = await getExamplePrograms();
    const { examplePrograms, otherAccountManagerPrograms } = response;

    // Add the standard past performance options
    const groupedOptions = [
      {
        label: PERFORMANCE_COMPARE_OPTION_GROUP,
        options: PERFORMANCE_COMPARE_OPTIONS.map(option => ({
          label: PERFORMANCE_COMPARE_OPTIONS_DISPLAY[option],
          value: option
        }))
      }
    ];

    if (isSimulatingUser(user)) {
      // Hide the current brand from the list of example programs
      const validExamplePrograms = examplePrograms?.filter(ep => ep.Brand_id !== brandId) || [];
      if (validExamplePrograms.length) {
        groupedOptions.push({
          label: OTHER_PROGRAMS_OPTION_GROUP,
          options: validExamplePrograms.map(program => ({
            label: program.displayName,
            value: program.Brand_id
          }))
        });
      }

      if (otherAccountManagerPrograms?.length) {
        groupedOptions.push({
          label: OTHER_ACCOUNT_MANAGER_PROGRAMS_OPTION_GROUP,
          options: otherAccountManagerPrograms.map(program => ({
            label: `${program.name} (${program.score.toFixed(1)})`,
            value: program.id
          }))
        });
      }
    }

    setCompareOptions(groupedOptions);
  };

  const handleCompareWithChange = async selectedOption => {
    if (!selectedOption) {
      onCompareChange(null);
      setSelectedCompare(null);
      return;
    }

    // Find the group of the selected option
    const optionGroup = compareOptions.find(group => group.options.some(option => option.value === selectedOption.value)).label;

    const result = await onCompareChange({ type: optionGroup, value: selectedOption.value });
    if (!result?.success) return;

    setSelectedCompare(selectedOption);
  };

  return (
    <div className='compare-select'>
      <Select
        isClearable={true}
        value={selectedCompare}
        onChange={handleCompareWithChange}
        options={compareOptions}
        components={{ Option: SelectOption }}
        styles={{
          singleValue: (provided, state) => ({
            ...provided,
            fontSize: '14px'
          })
        }}
      />
    </div>
  );
};

BrandReportCompareDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  onCompareChange: PropTypes.func.isRequired
};

export default BrandReportCompareDropdown;
