import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faDownload } from '@fortawesome/pro-regular-svg-icons';
import './ChatMessage.scss';

import RequiresPermissions from '../Managers/RequiresPermissions';
import ChatMessageOpportunityPreview from './Elements/ChatMessageOpportunityPreview';

import { isBrand, getRequestFromId, getBrandContracts, getOpportunityRequestById } from '../../Helpers/user_helpers';
import { getDefaultImage } from '../../Helpers/ui_helpers';
import { hasRequestExpired } from '../../Helpers/gifting_helpers';
import { getBrandRequestById, getBrandOpportunityRequestById } from '../../Helpers/brand_helpers';
import { getCurrentManager } from '../../Helpers/manager_helpers';
import { getSmartImage, matchScrollHeight } from '../../Helpers/helpers';
import { enhanceHTML, getRelativePrettyDate, getPrettyNumber, isImageUrl, isPDFUrl } from '../../Helpers/formatting';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDelete from '../General/ConfirmDelete';

const ChatMessage = props => {
  const {
    user,
    analytics,
    ui,
    chat,
    manager,
    message,
    brandCode,
    acceptGiftingFromBrand,
    requestGiftingToUser,
    offerCodeToUser,
    editMessage,
    deleteMessage,
    isTypingIndicator
  } = props;
  const { isUserMessage, type, isAutomated, Contract_id, Manager_id, Request_id, OpportunityRequest_id } = message;

  // edit state
  const [isEditing, setIsEditing] = useState(false);
  const [displayMessage, setDisplayMessage] = useState(message.message);

  // handle message socket updates
  useEffect(() => {
    if (isEditing) {
      return;
    }

    setDisplayMessage(message.message);
  }, [message]);

  // Check who is sending the message
  const currentManager = getCurrentManager(manager);
  const managerSendingMessage = _.find(_.concat(chat.managers, currentManager || []), m => m?.id === Manager_id);
  const request = Request_id ? (isBrand(user) ? getBrandRequestById(analytics, Request_id) : getRequestFromId(user, Request_id)) : null;
  const opportunityRequest = OpportunityRequest_id
    ? isBrand(user)
      ? getBrandOpportunityRequestById(analytics, OpportunityRequest_id)
      : getOpportunityRequestById(user, OpportunityRequest_id)
    : null;
  const sender = isUserMessage ? chat.user : chat.brand;
  const isYou = (isBrand(user) && !isUserMessage) || (!isBrand(user) && isUserMessage);

  // edit and delete enabled only for brand messages
  const canEditOrDelete = !!isYou && isBrand(user) && !isAutomated && !isEditing;

  // Gifting Requests
  const canBrandSendGiftingConfirmation = isBrand(user) && type === 'gifting' && !!isUserMessage && !!request && !request?.isComplete;
  const isUndecidedGifting = !isBrand(user) && type === 'gifting' && !!request && !request?.userAccepted && !request?.userRejected;
  const contract = Contract_id && getBrandContracts(user).find(c => c.id === Contract_id);
  const hasGiftingRequestExpired = request && hasRequestExpired(request);
  const canUserAcceptGifting = isUndecidedGifting && !request.Lookbook_id && !hasGiftingRequestExpired;
  const showLinkToLookbook = isUndecidedGifting && !!request.Lookbook_id && !hasGiftingRequestExpired;
  const showOpportunityCard =
    opportunityRequest && !opportunityRequest.userAccepted && !opportunityRequest.userRejected && !!opportunityRequest.opportunity;

  // Custom Discount Codes
  const isPartnershipRequestFromUser = isBrand(user) && type === 'partnership' && isAutomated;

  // Collaborations
  const isCollaborationProposal = type === 'collaboration' && Contract_id;

  const goToLookbook = () => {
    window.location.href = `/lookbooks/${request.Lookbook_id}`;
  };

  const cancelEdit = () => {
    setDisplayMessage(message.message);
    setIsEditing(false);
  };

  const enterEditMode = () => {
    setIsEditing(true);
  };

  const handleEditMessage = async () => {
    if (displayMessage === message.message) {
      return setIsEditing(false);
    }

    if (displayMessage) {
      await editMessage(message, displayMessage);
      setIsEditing(false);
    } else {
      handleDeleteMessage();
    }
  };

  const handleDeleteMessage = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header='Are you sure you want to delete this message?'
          subheader={`Once deleted, this message will no longer be visible to you or ${chat.user.name}.`}
          onCancel={onClose}
          onDelete={() => {
            onClose();
            deleteMessage(message);
          }}
        />
      )
    });
  };

  const getEditedAtTimestamp = () => {
    if (!message.isEdited) return null;
    return `Updated ${getRelativePrettyDate(message.updatedAt)}`;
  };

  const editedTimestamp = useMemo(() => getEditedAtTimestamp(), [message.updatedAt]);

  // Check if the message is an attachment
  const messageParts = displayMessage.trim().split(' ');
  const isStandalone = messageParts.length === 1;
  const isImage = isImageUrl(displayMessage);
  const isPDF = isPDFUrl(displayMessage);
  const isStandaloneImage = isStandalone && isImage;
  const isStandalonePDF = isStandalone && isPDF;
  const isAttachment = isStandaloneImage || isStandalonePDF;

  const getMessageContent = () => {
    if (isStandaloneImage) {
      const href = displayMessage;
      const openFile = () => window.open(href, '_blank');
      return <img onClick={openFile} className='chat-message-image' src={href} alt='Uploaded' />;
    }

    if (isStandalonePDF) {
      const href = displayMessage;
      const openFile = () => window.open(href, '_blank');
      return (
        <div onClick={openFile} className='chat-message-pdf'>
          PDF
          <FontAwesomeIcon icon={faDownload} />
        </div>
      );
    }

    if (isTypingIndicator) {
      return <Loader size={75} />;
    }

    return enhanceHTML(displayMessage);
  };

  const additionalClasses = { you: isYou, them: !isYou, brand: !message.isUserMessage };

  return isAutomated ? (
    <div className='automated-chat-message-container'>
      <div className='automated-chat-message'>{message.message}</div>
      {canBrandSendGiftingConfirmation && (
        <div onClick={() => props.openFulfillmentModal({ params: { Request_id: request.id } })} className='automated-chat-message-action-items'>
          <div className='action-item'>Fulfill Order</div>
        </div>
      )}
      {showLinkToLookbook && (
        <RequiresPermissions permission='canAcceptGifting' hideMessage>
          <div onClick={() => goToLookbook(request.lookbook)} className='automated-chat-message-action-items'>
            <div className='action-item'>View Lookbook</div>
          </div>
        </RequiresPermissions>
      )}
      {showOpportunityCard && (
        <RequiresPermissions permission='canAnswerOpportunities' hideMessage>
          <div className='pretty-card'>
            <ChatMessageOpportunityPreview request={opportunityRequest} />
          </div>
        </RequiresPermissions>
      )}
      {canUserAcceptGifting && (
        <RequiresPermissions permission='canAcceptGifting' hideMessage>
          <div onClick={() => acceptGiftingFromBrand(request)} className='automated-chat-message-action-items'>
            <div className='action-item'>Accept Gifting</div>
          </div>
        </RequiresPermissions>
      )}
      {hasGiftingRequestExpired && (
        <div
          onClick={() =>
            window.ALERT.warn(
              isBrand(user)
                ? 'This gifting request has expired, please resend this request or extend the window.'
                : 'This gifting request has expired and can no longer be accepted.'
            )
          }
          className='automated-chat-message-action-items'
        >
          <div className='action-item disabled'>Gifting Request Expired</div>
        </div>
      )}
      {isCollaborationProposal && (
        <RequiresPermissions permission='canAnswerCollaborations' hideMessage>
          <div className='automated-chat-message-action-items'>
            <Link to={`/collaboration/${Contract_id}`} target='_blank' className='action-item'>
              View Collaboration
            </Link>
          </div>
        </RequiresPermissions>
      )}
      {isPartnershipRequestFromUser && (
        <div className='automated-chat-message-action-items'>
          <div onClick={requestGiftingToUser} className='action-item'>
            Send Gifting Request
          </div>
          {!brandCode && (
            <div onClick={offerCodeToUser} className='action-item'>
              Offer Discount Code
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className={cn('chat-message-container', additionalClasses)}>
      {isBrand(user) && !isYou ? (
        <div onClick={props.viewProfile} className='image-container clickable'>
          <img alt={sender.name} src={sender.image || sender.logo ? getSmartImage(sender.image || sender.logo) : getDefaultImage(ui)} />
        </div>
      ) : (
        <div className='image-container'>
          <img alt={sender.name} src={sender.image || sender.logo ? getSmartImage(sender.image || sender.logo) : getDefaultImage(ui)} />
        </div>
      )}
      {!isEditing ? (
        <div
          onClick={() => window.__ADMIN_CONTROL_MODE__ && console.info('message:', message)}
          className={cn('message-block', {
            'is-attachment': isAttachment
          })}
        >
          <div className='content'>
            {getMessageContent()}
            {!!editedTimestamp && <div className='edited-at'>{editedTimestamp}</div>}
            <div className='chat-message-action-wrapper'>
              {canUserAcceptGifting && (
                <RequiresPermissions permission='canAcceptGifting' hideMessage>
                  <div onClick={() => acceptGiftingFromBrand(request)} className='chat-message-action-items'>
                    <div className='action-item'>Accept Gifting</div>
                  </div>
                </RequiresPermissions>
              )}
              {isCollaborationProposal && (
                <RequiresPermissions permission='canAnswerCollaborations' hideMessage>
                  <div className='chat-message-action-items'>
                    <Link to={`/collaboration/${Contract_id}`} target='_blank' className='action-item'>
                      View {contract?.price ? `$${getPrettyNumber(contract.price)} ` : ''}Collaboration Proposal
                    </Link>
                  </div>
                </RequiresPermissions>
              )}
              {canEditOrDelete && (
                <div className='edit-delete-actions'>
                  <div onClick={enterEditMode} className='icon-btn edit'>
                    Edit
                    <FontAwesomeIcon icon={faEdit} />
                  </div>
                  <div className='icon-btn delete' onClick={handleDeleteMessage}>
                    Delete
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {managerSendingMessage && (
            <div className={cn('manager-note', additionalClasses)}>
              Sent by {managerSendingMessage.name} on behalf of {chat.user.name}
            </div>
          )}
        </div>
      ) : (
        <div className='message-block editing'>
          <div className='edit-message-wrapper'>
            <textarea
              autoFocus={true}
              ref={ref => matchScrollHeight(ref)}
              className='chat-message-edit-area'
              value={displayMessage}
              onChange={evt => setDisplayMessage(evt.target.value)}
              onBlur={cancelEdit}
            />
            {!!editedTimestamp && <div className='edited-at'>{editedTimestamp}</div>}
            <div className='update-message-btn' onMouseDown={handleEditMessage}>
              Update Message
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ChatMessage.propTypes = {
  user: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,

  // Optional Components
  viewProfile: PropTypes.func,
  isTypingIndicator: PropTypes.bool,

  // Actions
  brandCode: PropTypes.object,
  openFulfillmentModal: PropTypes.func.isRequired,
  acceptGiftingFromBrand: PropTypes.func.isRequired,
  requestGiftingToUser: PropTypes.func.isRequired,
  editMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired
};

export default ChatMessage;
