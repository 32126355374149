import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';

import { getTiktokVideoIdFromUrl, getYoutubeVideoIdFromUrl } from '../../../Helpers/social_helpers';
import { isVideoString } from '../../../Helpers/media_helpers';
import VideoPlayer from '../../General/VideoPlayer';
import TiktokPlayer from '../../General/TiktokPlayer';

import './CollectionMedia.scss';

class CollectionMedia extends Component {
  static propTypes = {
    collection: PropTypes.object.isRequired,
    removeImage: PropTypes.func,
    canEdit: PropTypes.bool
  };

  getYoutubeVideoIds = () => {
    const { collection } = this.props;
    const allLinks = _.split(collection.social_links, ',');
    const youtubeVidIds = _.map(allLinks, getYoutubeVideoIdFromUrl);
    const validIds = _.filter(youtubeVidIds);
    return validIds;
  };

  getTiktokVideoIds = () => {
    const { collection } = this.props;
    const allLinks = _.split(collection.social_links, ',');
    const getTiktokVideoIds = _.map(allLinks, getTiktokVideoIdFromUrl);
    const validIds = _.filter(getTiktokVideoIds);
    return validIds;
  };

  videoStart = ({ target, data }) => {};
  videoStop = ({ target, data }) => {};

  render() {
    const { collection, removeImage, canEdit } = this.props;
    const { image } = collection;
    const youtubeIds = this.getYoutubeVideoIds();
    const tiktokIds = this.getTiktokVideoIds();

    const isYoutubeVideo = !!youtubeIds.length;
    const isTiktokVideo = !!tiktokIds.length && !isMobile; // hide on mobile for now
    const isGenericVideo = isVideoString(image);
    const isImage = !isYoutubeVideo && !isTiktokVideo && !isGenericVideo && !!image;
    return (
      <div className={cn('collection-media-outer-container', collection.skinType)}>
        {!window.__IS_SNAP__ &&
          isYoutubeVideo &&
          _.map(youtubeIds, vidId => {
            return (
              <div key={vidId}>
                <YouTube
                  videoId={vidId}
                  className='youtube-video'
                  onPlay={this.videoStart}
                  onPause={this.videoStop}
                  onEnd={this.videoStop}
                  opts={{
                    width: '100%',
                    playerVars: {
                      modestbrand: 1,
                      rel: 0
                    }
                  }}
                />
              </div>
            );
          })}
        {!window.__IS_SNAP__ &&
          isTiktokVideo &&
          _.map(tiktokIds, vidId => {
            return <TiktokPlayer key={vidId} videoId={vidId} />;
          })}
        {isGenericVideo && (
          <div className='social-post-image-container'>
            <div className='video-container'>
              <VideoPlayer src={image} />
              {canEdit && (
                <div onClick={removeImage} className='remove-icon'>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
          </div>
        )}
        {isImage && (
          <div className='social-post-image-container'>
            <div className='image-container'>
              <img alt={canEdit ? 'Please refresh to see image' : 'Social Post'} src={image} />
              {canEdit && (
                <div onClick={removeImage} className='remove-icon'>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CollectionMedia;
