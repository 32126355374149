import React from 'react';
import PropTypes from 'prop-types';
import './LookbookProductModalResults.scss';
import cn from 'classnames';

import LookbookProductModalResult from './LookbookProductModalResult';
import Loader from '../../../Loader/Loader';

const LookbookProductModalResults = props => {
  const { lookbook, results, addLookbookItem, removeSelectedProduct, searching, saving, hasHadFirstResults, fetchingNextPage, isReplaceMode } = props;

  return (
    <div className='lookbook-product-modal-results-outer'>
      <div className={cn('lookbook-product-modal-results-inner', { searching })}>
        {results.map(result => (
          <LookbookProductModalResult
            key={result.objectID}
            lookbook={lookbook}
            result={result}
            addLookbookItem={addLookbookItem}
            removeSelectedProduct={removeSelectedProduct}
            openEditModal={props.openEditModal}
            showAllBrands={props.showAllBrands}
            saving={saving}
            user={props.user}
            isReplaceMode={isReplaceMode}
          />
        ))}
        {!results?.length &&
          !hasHadFirstResults &&
          Array.from([...new Array(20).fill(0)]).map((s, i) => {
            return (
              <div key={`${i}`} className='skeleton'>
                <Loader />
              </div>
            );
          })}
        {fetchingNextPage && (
          <div className='lookbook-product-modal-results-loading-container'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

LookbookProductModalResults.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  hasHadFirstResults: PropTypes.bool.isRequired,
  results: PropTypes.array.isRequired,
  addLookbookItem: PropTypes.func.isRequired,
  removeSelectedProduct: PropTypes.func.isRequired,
  openEditModal: PropTypes.func.isRequired,
  failedSearch: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  fetchingNextPage: PropTypes.bool.isRequired,
  showAllBrands: PropTypes.bool.isRequired,

  hasMoreResults: PropTypes.bool.isRequired,
  searching: PropTypes.bool.isRequired,
  isReplaceMode: PropTypes.bool
};

export default LookbookProductModalResults;
