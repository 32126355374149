import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunityListingUploads.scss';

import { createOpportunityUpload, updateOpportunityUpload, deleteOpportunityUpload } from '../../../Actions/OpportunityActions';

import OpportunityListingUpload from './OpportunityListingUpload';
import DropUploader from '../../Uploader/DropUploader';

const OpportunityListingUploads = props => {
  const { isEditing, opportunity } = props;
  const { uploads } = opportunity;
  const hasUploads = uploads && uploads.length > 0;

  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const onUploadFileProgress = progress => {
    setUploadProgress(progress);
    progress < 100 && setIsUploading(true); // In case of multi-upload
  };
  const onDrop = file => {
    setIsUploading(true);
  };
  const completeFileUpload = file => {
    props.createOpportunityUpload({
      Opportunity_id: opportunity.id,
      url: file,
      uploadedByBrand: true
    });
    setIsUploading(false);
  };

  const showUploads = isEditing || hasUploads;
  if (!showUploads) return null;

  return (
    <div className='opportunity-listing-section opportunity-listing-uploads-section'>
      <div className='opportunity-listing-section-title-container'>
        <div className='opportunity-listing-section-title'>Additional Materials</div>
      </div>
      {hasUploads ? (
        <div className={cn('uploads-container', { editing: isEditing })}>
          {uploads.map(upload => (
            <OpportunityListingUpload
              key={upload.id}
              upload={upload}
              isEditing={isEditing}
              updateOpportunityUpload={props.updateOpportunityUpload}
              deleteOpportunityUpload={props.deleteOpportunityUpload}
            />
          ))}
        </div>
      ) : (
        !isEditing && (
          <div className='opportunity-listing-content'>
            <div className='content empty'>There are no additional uploads for this opportunity.</div>
          </div>
        )
      )}
      {isEditing && (
        <DropUploader allowClicks dropMessage='' onProgress={onUploadFileProgress} onUpload={completeFileUpload} onDrop={onDrop}>
          <div className='opportunity-listing-dropzone'>
            <div className='opportunity-listing-dropzone-title'>{isUploading ? `Uploading ${uploadProgress}%` : 'Drag and drop files here'}</div>
          </div>
        </DropUploader>
      )}
    </div>
  );
};

OpportunityListingUploads.propTypes = {
  // From Outside
  isEditing: PropTypes.bool.isRequired,
  opportunity: PropTypes.object.isRequired,

  // From Inside
  user: PropTypes.object.isRequired,
  createOpportunityUpload: PropTypes.func.isRequired,
  updateOpportunityUpload: PropTypes.func.isRequired,
  deleteOpportunityUpload: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

// We connect this directly to the store to minimize prop drilling and making it easier to access
// the redux layer.
export default connect(mapStateToProps, {
  createOpportunityUpload,
  updateOpportunityUpload,
  deleteOpportunityUpload
})(OpportunityListingUploads);
