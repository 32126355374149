import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';

import './ConsultPreviewFooter.scss';

const ConsultPreviewFooter = props => {
  const { canEdit, consult, exampleOnly, toggleExample, showCompleted } = props;
  const { results } = consult;
  const [numVisible, setNumVisible] = useState(15);

  if (exampleOnly) {
    return (
      <div className='consult-preview-footer-container example-only'>
        <div className='banner'>
          <Link to={`/consults/results/${consult?.example?.stub}`} className='example-link'>
            {canEdit ? 'SEE AN EXAMPLE' : 'SEE AN EXAMPLE'}
          </Link>
          {canEdit && (
            <div onClick={() => toggleExample(consult?.example)} className='remove-btn'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
    );
  }

  const filteredResults = results.filter(result => showCompleted || !result.completedAt); // Always show pending
  const orderedResults = _.orderBy(filteredResults, [r => r.completedAt, r => r.createdAt], ['desc', 'asc']);
  const visibleResults = orderedResults.slice(0, numVisible);
  return (
    <div className='consult-preview-footer-container'>
      {!!visibleResults.length && (
        <div className='body'>
          {visibleResults.map(result => {
            const isExample = consult.ExampleConsultResult_Id === result.id;
            const isRefunded = !!result.paymentRefundedAt;
            const isComplete = !!result.completedAt;
            return (
              <div key={result.id} className='result'>
                <div className='data'>
                  <div className='name'>{result.clientName}</div>
                  <div className='date'>
                    {isRefunded ? 'Refunded' : isComplete ? 'Completed' : result.paymentAmount ? `Paid on` : 'Submitted'}{' '}
                    {moment(result.completedAt || result.createdAt).format('MMMM Do')}
                  </div>
                </div>
                <div className='action-btns'>
                  {isComplete && (
                    <div className='make-example-btn' onClick={() => toggleExample(result)}>
                      {isExample ? 'Remove As Example' : 'Make Example'}
                    </div>
                  )}
                  <Link to={`/consults/results/${result.stub}`} className={cn('action-btn', { faded: isComplete })}>
                    VIEW
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {orderedResults.length > visibleResults.length && showCompleted && (
        <div onClick={() => setNumVisible(numVisible + 10)} className='toggle-show-all-btn'>
          SHOW MORE
        </div>
      )}
    </div>
  );
};

ConsultPreviewFooter.propTypes = {
  consult: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  exampleOnly: PropTypes.bool,
  showCompleted: PropTypes.bool,
  toggleExample: PropTypes.func
};

export default ConsultPreviewFooter;
