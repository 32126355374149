import {
  getRecommendedArtists as getRecommendedArtistsAPI,
  addRecommendedArtist as addRecommendedArtistAPI,
  updateRecommendedArtist as updateRecommendedArtistAPI,
  deleteRecommendedArtist as deleteRecommendedArtistAPI,
  deleteRecommendedArtistRejection as deleteRecommendedArtistRejectionAPI
} from '../APIClient/recommendations';
import { getBrand } from '../Helpers/user_helpers';
import { getCurrentTimeForRanking } from '../Helpers/formatting';

export const GET_RECOMMENDED_ARTISTS_SUCCESS = 'GET_RECOMMENDED_ARTISTS_SUCCESS';
export const GET_RECOMMENDED_ARTISTS_ERROR = 'GET_RECOMMENDED_ARTISTS_ERROR';

export const ADD_RECOMMENDED_ARTIST_SUCCESS = 'ADD_RECOMMENDED_ARTIST_SUCCESS';
export const ADD_RECOMMENDED_ARTIST_ERROR = 'ADD_RECOMMENDED_ARTIST_ERROR';

export const DELETE_RECOMMENDED_ARTIST_REQUEST = 'DELETE_RECOMMENDED_ARTIST_REQUEST';
export const DELETE_RECOMMENDED_ARTIST_SUCCESS = 'DELETE_RECOMMENDED_ARTIST_SUCCESS';
export const DELETE_RECOMMENDED_ARTIST_ERROR = 'DELETE_RECOMMENDED_ARTIST_ERROR';

export const DELETE_RECOMMENDED_ARTIST_REJECTION_REQUEST = 'DELETE_RECOMMENDED_ARTIST_REJECTION_REQUEST';
export const DELETE_RECOMMENDED_ARTIST_REJECTION_SUCCESS = 'DELETE_RECOMMENDED_ARTIST_REJECTION_SUCCESS';
export const DELETE_RECOMMENDED_ARTIST_REJECTION_ERROR = 'DELETE_RECOMMENDED_ARTIST_REJECTION_ERROR';

export const UPDATE_RECOMMENDED_ARTIST_SUCCESS = 'UPDATE_RECOMMENDED_ARTIST_SUCCESS';
export const UPDATE_RECOMMENDED_ARTIST_ERROR = 'UPDATE_RECOMMENDED_ARTIST_ERROR';

export const getRecommendedArtists = user => async (dispatch, getState) => {
  const brand = getBrand(getState().user);
  const resp = await getRecommendedArtistsAPI({ Brand_id: brand?.id });
  return resp.recommendations
    ? dispatch({
        type: GET_RECOMMENDED_ARTISTS_SUCCESS,
        recommendations: resp?.recommendations
      })
    : dispatch({
        type: GET_RECOMMENDED_ARTISTS_ERROR
      });
};

export const addRecommendedArtist = artist => async (dispatch, getState) => {
  const brand = getBrand(getState().user);
  try {
    const newArtist = await addRecommendedArtistAPI({ Brand_id: brand?.id, User_id: artist.id, rankScore: getCurrentTimeForRanking() });
    dispatch({
      type: ADD_RECOMMENDED_ARTIST_SUCCESS,
      recommendation: newArtist
    });
  } catch (error) {
    dispatch({
      type: ADD_RECOMMENDED_ARTIST_ERROR
    });
  }
};

export const deleteRecommendedArtist = recommendation => async (dispatch, getState) => {
  dispatch({
    type: DELETE_RECOMMENDED_ARTIST_REQUEST,
    recommendation
  });
  try {
    await deleteRecommendedArtistAPI(recommendation);
    return dispatch({
      type: DELETE_RECOMMENDED_ARTIST_SUCCESS,
      recommendation
    });
  } catch (error) {
    return dispatch({
      type: DELETE_RECOMMENDED_ARTIST_ERROR,
      recommendation
    });
  }
};

export const deleteRecommendedArtistRejection = rejection => async (dispatch, getState) => {
  dispatch({
    type: DELETE_RECOMMENDED_ARTIST_REJECTION_REQUEST,
    rejection
  });
  try {
    await deleteRecommendedArtistRejectionAPI(rejection);
    return dispatch({
      type: DELETE_RECOMMENDED_ARTIST_REJECTION_SUCCESS,
      rejection
    });
  } catch (error) {
    return dispatch({
      type: DELETE_RECOMMENDED_ARTIST_REJECTION_ERROR,
      rejection
    });
  }
};

export const updateRecommendedArtist = (artist, updates) => async (dispatch, getState) => {
  try {
    await updateRecommendedArtistAPI(artist, updates);
    dispatch({
      type: UPDATE_RECOMMENDED_ARTIST_SUCCESS,
      recommendation: artist,
      updates
    });
  } catch (error) {
    dispatch({
      type: UPDATE_RECOMMENDED_ARTIST_ERROR
    });
  }
};
