import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import './DiscoverUsersControls.scss';

import { getBrandTags, getSimilarBrands } from '../../Helpers/user_helpers';

const DiscoverUsersControls = props => {
  const { activeQueries, toggleQuery, searchVal, setSearchVal, user } = props;
  const brandTags = getBrandTags(user);
  const similarBrands = getSimilarBrands(user);
  const isSelectedQuery = q => !!activeQueries.find(t => t.value === q.value);
  const isRecommendedQuery = q => !!brandTags.find(t => t.value === q.value);
  const allSelectedAreRecommended = activeQueries.filter(isRecommendedQuery).length === activeQueries.length;
  const showRecommendedTags = !activeQueries.length || allSelectedAreRecommended;
  return (
    <div className='discover-users-controls-container'>
      <div className='tags'>
        {!!searchVal.length && (
          <div onClick={() => setSearchVal('')} className='tag'>
            {searchVal}
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {!!activeQueries.length &&
          activeQueries.map(query => {
            return (
              <div key={query.value} onClick={() => toggleQuery(query)} className='tag'>
                {query.type === 'brandpromoter' ? 'Promotes ' : ''}
                {query.value}
                <FontAwesomeIcon icon={faTimes} />
              </div>
            );
          })}
        {brandTags.length || similarBrands.length ? (
          showRecommendedTags && (
            <>
              {!activeQueries.length && !searchVal.length && !!brandTags.length && <div className='recommended-msg'>Recommended Filters</div>}
              {brandTags.map(tag => {
                const query = { Tag_id: tag.id, type: tag.type, value: tag.value }; // Matches implementation in sidebar
                return (
                  !isSelectedQuery(query) && (
                    <div key={tag.value} onClick={() => toggleQuery(query)} className='tag inactive'>
                      {tag.type === 'brandpromoter' ? 'Promotes ' : ''}
                      {tag.value}
                    </div>
                  )
                );
              })}
            </>
          )
        ) : (
          <div className='empty-msg'>Select filters from the panel on the left</div>
        )}
      </div>
      <div className='toggle-promoters-container'>
        ONLY SHOW NEW TALENT
        <Switch
          onChange={props.toggleShowingMyPromoters}
          checked={props.hideMyPromoters}
          height={14}
          width={24}
          onColor='#333'
          offColor='#ccc'
          className='switch'
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>
    </div>
  );
};

DiscoverUsersControls.propTypes = {
  user: PropTypes.object.isRequired,
  activeQueries: PropTypes.array.isRequired,
  searchVal: PropTypes.string.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  hideMyPromoters: PropTypes.bool.isRequired,
  toggleShowingMyPromoters: PropTypes.func.isRequired,
  toggleQuery: PropTypes.func.isRequired
};

export default DiscoverUsersControls;
