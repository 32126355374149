import APIClient from './index';
import _ from 'lodash';

export const connectInstagram = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/connect_instagram`, data)
      .then(response => resolve(response.data))
      .catch(error => {
        window.a = error; // This is for debugging
        reject(error?.data?.error);
      });
  });
};

export const removeSocialAccount = account => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/UserSocialAccounts/${account.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const searchInstagram = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/search_instagram`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const connectYoutube = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/connect_youtube`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getYoutubeAuthUrl = redirect_url => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/youtube_auth_url`, { redirect_url })
      .then(response => resolve(response.data))
      .catch(error => reject(error?.data?.error));
  });
};

export const searchYoutube = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/search_youtube`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error?.data?.error));
  });
};

export const connectTiktok = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/connect_tiktok_v2`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getTiktokAuthUrl = redirect_url => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/tiktok_auth_url_v2`, { redirect_url })
      .then(response => resolve(response.data))
      .catch(error => reject(error?.data?.error));
  });
};

export const searchTiktok = params => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserSocialAccounts/search_tiktok`, params)
      .then(response => resolve(response.data))
      .catch(error => reject(error?.data?.error));
  });
};
