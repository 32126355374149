import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './UserTagsPanel.scss';

import { getOnboardingTags } from '../../APIClient/tags';

import { getTags } from '../../Helpers/user_helpers';
import { cleanTag } from '../../Helpers/tag_helpers';

const UserTagsPanel = props => {
  // Store tags in state as opposed to the user redux object so we can edit them more easily
  const currentUserTags = getTags(props.user)
    .filter(tag => tag.isOnboarding)
    .map(cleanTag);
  const [selectedTags, setSelectedTags] = useState(currentUserTags);
  const [allTags, setAllTags] = useState([]);

  // Group tags. This is the same as in OnboardingPanelTags.js
  const allTagGroups = { geo: [], general: [], other: [] };
  allTags.forEach(tag => {
    if (tag.type === 'geo') allTagGroups.geo.push(tag);
    else if (tag.type === 'general') allTagGroups.general.push(tag);
    else allTagGroups.other.push(tag);
  });

  // Handle editing and saving
  const [isEditing, setIsEditing] = useState(false);
  const editTags = () => {
    getOnboardingTags().then(tags => setAllTags(tags));
    setIsEditing(true);
  };
  const doneEditing = async () => {
    // Check if anything changed
    if (_.isEqual(selectedTags, currentUserTags)) return setIsEditing(false);

    // Since stored locally, you can end editing prior to saving
    setIsEditing(false);

    // Save new tags
    const newTags = selectedTags.filter(tag => !currentUserTags.find(t => t.id === tag.id));
    const deletedTags = currentUserTags.filter(tag => !selectedTags.find(t => t.id === tag.id));
    await Promise.all([newTags.length && props.addTagsToUser(newTags), deletedTags.length && props.deleteTagsForUser(deletedTags)]);
  };

  // UI Handling
  const isTagSelected = tag => !!selectedTags.find(t => t.id === tag.id);
  const toggleTag = tag => setSelectedTags(isTagSelected(tag) ? _.filter(selectedTags, t => t.id !== tag.id) : [...selectedTags, tag]);
  const getTag = tag => {
    const toggle = () => isEditing && toggleTag(tag);
    const selected = isTagSelected(tag);
    return (
      <div key={tag.id} onClick={toggle} className={cn('tag', { selected, toggleable: isEditing })}>
        {cleanTag(tag).value}
      </div>
    );
  };

  return (
    <div className='user-tags-panel-container settings-block'>
      <div className='title-container'>
        <div className='label'>Your Creator Tags</div>
        <div className='sublabel'>These tags will help brands to find you when they are discovering new creators to work with.</div>
        <div className='tags-container'>
          {isEditing ? (
            <div className='tag-sections'>
              <div className='tag-section'>
                <div className='section-header'>Where are you based?</div>
                <div className='tags'>{allTagGroups.geo.map(getTag)}</div>
              </div>
              <div className='tag-section'>
                <div className='section-header'>Are you any of the following?</div>
                <div className='tags'>{allTagGroups.general.map(getTag)}</div>
              </div>
              <div className='tag-section'>
                <div className='section-header'>What best describes your content?</div>
                <div className='tags'>{allTagGroups.other.map(getTag)}</div>
              </div>
            </div>
          ) : selectedTags.length ? (
            <div className='tag-sections'>
              <div className='tag-section'>
                <div className='tags'>{selectedTags.map(getTag)}</div>
              </div>
            </div>
          ) : (
            <div className='no-tags-selected'>No tags selected</div>
          )}
        </div>
        <div className='action-btns'>
          {isEditing ? (
            <div onClick={doneEditing} className='done'>
              Done
            </div>
          ) : (
            <div onClick={editTags} className='edit-btn'>
              Edit
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

UserTagsPanel.propTypes = {
  user: PropTypes.object.isRequired,
  addTagsToUser: PropTypes.func.isRequired,
  deleteTagsForUser: PropTypes.func.isRequired
};

export default UserTagsPanel;
