import _ from 'lodash';
import * as BlogTypes from '../Actions/BlogActions';

export const blog = (
  state = {
    posts: [],
    tabs: [],
    blogTab: null,
    blogPost: null,
    hasMoreResults: true
  },
  action
) => {
  switch (action.type) {
    case BlogTypes.SET_BLOG_POST:
      return {
        ...state,
        blogPost: action.post
      };

    case BlogTypes.GET_BLOG_TABS_SUCCESS:
      return {
        ...state,
        tabs: _.orderBy(action.tabs, 'sortOrderRank', 'asc')
      };

    case BlogTypes.GET_BLOG_TABS_FAILURE:
      return {
        ...state,
        tabs: []
      };

    case BlogTypes.ADD_POST_TO_TAB_SUCCESS:
      return {
        ...state,
        tabs: _.orderBy(
          _.map(state.tabs, tab => (tab.id === action.tab.id ? { ...tab, posts: [...tab.posts, action.post] } : tab)),
          'sortOrderRank',
          'asc'
        ),
        blogPost: {
          ...state.blogPost,
          tabs: [...state.blogPost.tabs, action.tab]
        }
      };

    case BlogTypes.REMOVE_POST_FROM_TAB:
      return {
        ...state,
        tabs: _.orderBy(
          _.map(state.tabs, tab => (tab.id === action.tab.id ? { ...tab, posts: _.filter(tab.posts, post => post.id !== action.post.id) } : tab)),
          'sortOrderRank',
          'asc'
        ),
        blogPost: {
          ...state.blogPost,
          tabs: _.filter(state.blogPost.tabs, tab => tab.id !== action.tab.id)
        }
      };

    case BlogTypes.REMOVE_POST_FROM_TAB_FAILURE:
      return {
        ...state,
        tabs: _.orderBy(action.previousTabs, 'sortOrderRank', 'asc'),
        blogPost: {
          ...state.blogPost,
          tabs: _.map(state.blogPost.tabs, tab => (tab.id === action.tab.id ? { ...tab, posts: [...tab.posts, action.post] } : tab))
        }
      };

    case BlogTypes.CREATE_BLOG_TAB_SUCCESS:
      return {
        ...state,
        tabs: _.orderBy([...state.tabs, action.tab], 'sortOrderRank', 'asc')
      };

    case BlogTypes.UPDATE_BLOG_TAB_SUCCESS:
      return {
        ...state,
        tabs: _.orderBy(
          _.map(state.tabs, tab => (tab.id === action.tab.id ? action.tab : tab)),
          'sortOrderRank',
          'asc'
        )
      };

    case BlogTypes.UPDATE_BLOG_TAB_FAILURE:
      return {
        ...state,
        tabs: _.orderBy(
          _.map(state.tabs, tab => (tab.id === action.tab.id ? action.tab : tab)),
          'sortOrderRank',
          'asc'
        )
      };

    case BlogTypes.DELETE_BLOG_TAB_SUCCESS:
      return {
        ...state,
        tabs: _.filter(state.tabs, tab => tab.id !== action.tab.id)
      };

    case BlogTypes.DELETE_BLOG_TAB_FAILURE:
      return {
        ...state,
        tabs: action.tabs
      };

    case BlogTypes.GET_BLOG_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.page > 1 ? [...state.posts, ...action.posts] : action.posts,
        blogTab: action.tab || state.blogTab,
        hasMoreResults: action.posts && action.posts.length === 15
      };

    case BlogTypes.GET_BLOG_POST_SUCCESS:
      return {
        ...state,
        blogPost: {
          ...action.post,
          elements: _.map(_.orderBy(action.post.elements, 'sortOrderRank'), element => ({
            ...element,
            pin: element.pin
              ? {
                  ...element.pin,
                  affiliate_link: _.get(action.affiliate_links, element.pin.id)
                }
              : null
          }))
        }
      };

    case BlogTypes.GET_BLOG_POST_FAILURE:
      return {
        ...state,
        blogPost: null
      };

    // Blog Posts
    case BlogTypes.CREATE_BLOG_POST_SUCCESS:
      return {
        ...state,
        blogPost: action.post
      };

    case BlogTypes.DELETE_BLOG_POST_SUCCESS:
      return {
        ...state,
        blogPost: null,
        tabs: _.map(state.tabs, tab => ({
          ...tab,
          posts: _.filter(tab.posts, post => post.id !== action.post.id)
        }))
      };

    case BlogTypes.DELETE_BLOG_POST_FAILURE:
      return state;

    case BlogTypes.EDIT_BLOG_POST:
      return {
        ...state,
        blogPost: state.blogPost?.id === action.post.id ? action.post : state.blogPost,
        tabs: _.map(state.tabs, tab => ({
          ...tab,
          posts: _.map(tab.posts, post => (post.id === action.post.id ? action.post : post))
        }))
      };

    case BlogTypes.EDIT_BLOG_POST_SUCCESS:
      return {
        ...state,
        blogPost: state.blogPost?.id ? state.blogPost : null
      };

    case BlogTypes.EDIT_BLOG_POST_FAILURE:
      return {
        ...state,
        blogPost: state.blogPost?.id === action.post.id ? action.post : state.blogPost,
        tabs: _.map(state.tabs, tab => ({
          ...tab,
          posts: _.map(tab.posts, post => (post.id === action.post.id ? action.post : post))
        }))
      };

    // Blog Post Elements
    case BlogTypes.CREATE_BLOG_POST_ELEMENT_SUCCESS:
      return {
        ...state,
        blogPost: !state.blogPost
          ? null
          : state.blogPost.id !== action.post.id
          ? state.blogPost
          : {
              ...state.blogPost,
              elements:
                action.post.id === state.blogPost.id
                  ? _.orderBy([...state.blogPost.elements, action.element], 'sortOrderRank')
                  : state.blogPost.elements
            }
      };

    case BlogTypes.DELETE_BLOG_POST_ELEMENT_SUCCESS:
      return {
        ...state,
        blogPost: !state.blogPost
          ? null
          : state.blogPost.id !== action.element.Post_id
          ? state.blogPost
          : {
              ...state.blogPost,
              elements:
                action.element.Post_id === state.blogPost.id
                  ? _.orderBy(
                      _.filter(state.blogPost.elements, element => element.id !== action.element.id),
                      'sortOrderRank'
                    )
                  : state.blogPost.elements
            }
      };

    case BlogTypes.EDIT_BLOG_POST_ELEMENT_SUCCESS:
      return {
        ...state,
        blogPost: !state.blogPost
          ? null
          : state.blogPost.id !== action.element.Post_id
          ? state.blogPost
          : {
              ...state.blogPost,
              elements:
                action.element.Post_id === state.blogPost.id
                  ? _.orderBy(
                      _.map(state.blogPost.elements, element => (element.id === action.element.id ? action.element : element)),
                      'sortOrderRank'
                    )
                  : state.blogPost.elements
            }
      };
    default:
      return state;
  }
};
