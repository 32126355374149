import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './BlogTabModal.scss';

import { createBlogTab, updateBlogTab, deleteBlogTab } from '../../Actions/BlogActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';

import Modal from '../General/Modal';
import SortableList from '../General/SortableList';
import BlogTabModalTab from './BlogTabModalTab';

const BlogTabModal = props => {
  const { blog, createBlogTab, updateBlogTab, deleteBlogTab } = props;
  const { tabs } = blog;

  const [addTabTitle, setAddTabTitle] = useState('');

  const createTab = () => {
    createBlogTab(addTabTitle);
    setAddTabTitle('');
  };

  const deleteTab = tab => {
    if (!tab) return;
    else if (!tab.posts?.length) return deleteBlogTab(tab);
    else {
      confirmAlert({
        title: 'Delete Tab',
        message: `Are you sure you want to delete the tab "${tab.title}"? Deleting this tab will not delete the posts within it, but will instead remove all associations with them.`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => deleteBlogTab(tab)
          },
          {
            label: 'No',
            onClick: () => null
          }
        ]
      });
    }
  };

  const handleKeyPress = e => (e.key === 'Enter' ? createTab() : null);
  const getCard = (tab, additionalProps = {}) => (
    <BlogTabModalTab tab={tab} additionalProps={additionalProps} updateBlogTab={updateBlogTab} deleteBlogTab={deleteTab} />
  );

  return (
    <Modal visible={true} innerClassName='blog-tab-modal-outer' showClose={true} close={props.close}>
      <div className='blog-tab-modal-title'>Edit Tabs</div>
      <SortableList isEditing containerClassName='blog-tab-modal-tabs' items={tabs} updateItem={updateBlogTab} props={props} getCard={getCard} />
      <div className='blog-tab-modal-add-container'>
        <div className='blog-tab-modal-tab' id='add-blog-tab'>
          <div>
            <FontAwesomeIcon icon={faPlus} onClick={createTab} />
            <input
              type='text'
              placeholder='New Tab'
              value={addTabTitle}
              onChange={e => {
                let title = e.target.value;
                title = title.replace(/\s/g, '-');
                title = title.toLowerCase();
                setAddTabTitle(title);
              }}
              onKeyDown={handleKeyPress}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

BlogTabModal.propTypes = {
  close: PropTypes.func.isRequired,

  blog: PropTypes.object.isRequired,
  createBlogTab: PropTypes.func.isRequired,
  updateBlogTab: PropTypes.func.isRequired,
  deleteBlogTab: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog } = state;
  return { blog };
};

export default connect(mapStateToProps, {
  createBlogTab,
  updateBlogTab,
  deleteBlogTab
})(BlogTabModal);
