import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import './MentionResultStatDetails.scss';

const { isLoggedIn } = require('../../../Helpers/user_helpers');

const MentionResultStatDetails = props => {
  const { user, stats, extraClasses } = props;
  const visibleStats = stats.filter(s => s.visible && !s.hideOnDetailPanel);
  const groups = _.groupBy(visibleStats, 'section');
  const hideResults = !isLoggedIn(user);
  const groupSections = [
    {
      header: 'Source',
      className: 'source'
    },
    {
      header: 'Performance',
      className: 'performance'
    },
    {
      header: 'Advanced Analytics',
      className: 'advanced-analytics'
    }
  ];

  return (
    <div className={cn('mention-result-stat-details', extraClasses)}>
      <div className='groups'>
        {groupSections.map(({ header, className }, idx) => {
          const stats = groups[header] || [];
          if (!stats.length) return null;
          return (
            <div className='group' key={header}>
              <div className='header'>{header}</div>
              <div className='stats'>
                {stats.map((s, idx) => {
                  const displayParts = s.display.split(' ');
                  return s.href ? (
                    <a className='stat' key={idx} href={s.href} target='_blank' rel='noopener noreferrer'>
                      {s.display}
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                  ) : (
                    <div className='stat-parts' key={idx}>
                      {displayParts.map((part, idx) => {
                        // Figure out if there is a number in the part
                        const num = part.match(/\d+/);
                        return (
                          <div className={cn('part', { number: num, blur: num && hideResults })} key={idx}>
                            {part}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MentionResultStatDetails.propTypes = {
  stats: PropTypes.array.isRequired
};

export default MentionResultStatDetails;
