import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Lookbooks.scss';
import {
  getLookbook,
  updateLookbook as updateLookbookAPI,
  deleteLookbook as deleteLookbookAPI,
  addLookbookItem as addLookbookItemAPI,
  updateLookbookItem as updateLookbookItemAPI,
  deleteLookbookItem as deleteLookbookItemAPI,
  addLookbookItemSibling as addLookbookItemSiblingAPI,
  updateLookbookItemSibling as updateLookbookItemSiblingAPI,
  deleteLookbookItemSibling as deleteLookbookItemSiblingAPI
  // getUserLookbookOrder as getUserLookbookOrderAPI
} from '../../APIClient/lookbooks';
import { resyncBrandLookbooks } from '../../Actions/LookbookActions';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBrand, getRequests, getUserId, isBrand, isLoggedIn } from '../../Helpers/user_helpers';
import { useHistory } from 'react-router-dom';
// import _ from 'lodash';

import UnauthorizedLookbook from '../../Components/Lookbooks/UnauthorizedLookbook/UnauthorizedLookbook';
import BrandLookbook from '../../Components/Lookbooks/BrandLookbook/BrandLookbook';
import cogoToast from 'cogo-toast';
import UserLookbook from '../../Components/Lookbooks/UserLookbook/UserLookbook';
import Loader from '../../Components/Loader/Loader';
import ScrollToTop from '../../Components/General/ScrollToTop';
import { openAddressModal, openAuthModal } from '../../Actions/UIActions';
import { getLookbookInviteByHash, markInviteAsViewed } from '../../APIClient/lookbook_invites';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

// lookbooks/:lookbookId
const Lookbooks = props => {
  const { user, ui, resyncBrandLookbooks } = props;
  const { id: Lookbook_id } = useParams();
  const brand = getBrand(user);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [saving, setSaving] = useState(false);
  const [lookbook, setLookbook] = useState(null);
  const [request, setRequest] = useState([]);
  const sortBySortOrderRank = (a, b) => a.sortOrderRank - b.sortOrderRank;

  const LookbookInvite_hash = queryParams.get('invite');

  const brandIsViewing = isBrand(user);
  const correctBrandIsViewing = brandIsViewing && lookbook?.Brand_id === brand?.id;
  const userIsViewing = !isBrand(user);
  const isShopmyUser = isLoggedIn(user);
  const pageIsAuthorized = isAdminControlMode(ui) || !!(correctBrandIsViewing || LookbookInvite_hash || request?.User_id === getUserId(user));

  // fetch the lookbook
  const syncLookbook = async () => {
    if (userIsViewing && isShopmyUser) {
      const requests = getRequests(user);
      const matchingRequest = requests.find(r => r.Lookbook_id === parseInt(Lookbook_id));
      setRequest(matchingRequest);
    }

    let lookbook;
    try {
      const queryData = LookbookInvite_hash ? { invite: LookbookInvite_hash } : {};

      if (brandIsViewing) {
        queryData.returnAllSiblings = true;
      }

      const lookbookResponse = await getLookbook(Lookbook_id, queryData);
      lookbook = lookbookResponse.lookbook;
      setLookbook(lookbook);
    } catch (e) {
      brandIsViewing ? history.push('/lookbooks') : history.push('/partners/gifting');
      cogoToast.error('Error fetching lookbook.');
    } finally {
      setIsInitialLoad(false);
    }
    return lookbook;
  };

  useEffect(() => {
    if (LookbookInvite_hash) {
      getLookbookInviteByHash(LookbookInvite_hash)
        .then(lookbookInvite => {
          const sendToBasePage = (message, openLogin = false) => {
            cogoToast.error(message);
            return openLogin ? history.push('/home?auth=login') : history.push('/home');
          };

          if (!lookbookInvite?.id) return sendToBasePage('Invalid lookbook invite.');
          else if (lookbookInvite?.Lookbook_id !== parseInt(Lookbook_id)) return sendToBasePage('Invalid lookbook invite.');
          else if (lookbookInvite.uses >= lookbookInvite.useLimit)
            return sendToBasePage('This lookbook invite has already been used, please request a new one.');

          if (!lookbookInvite.hasViewed) markInviteAsViewed(lookbookInvite).catch(e => console.error(e));
          syncLookbook();
        })
        .catch(() => cogoToast.error('Invalid lookbook invite.'));
    } else if (isShopmyUser) {
      syncLookbook();
    } else {
      setIsInitialLoad(false); // displays unauthorized page
    }
  }, []);

  /******************************************************************************************* */
  // LOOKBOOK OPERATIONS
  /******************************************************************************************* */

  const updateLookbook = async updates => {
    if (!isBrand(user)) return;

    const currentLookbook = { ...lookbook };

    try {
      setLookbook({ ...currentLookbook, ...updates });
      await updateLookbookAPI(Lookbook_id, updates);
      await resyncBrandLookbooks(); // reset redux store for gifting portal page
    } catch (e) {
      cogoToast.error('Failed to update lookbook. Please try again.');
      setLookbook(currentLookbook);
    }
  };

  const deleteLookbook = async () => {
    if (!isBrand(user)) return;
    // delete the lookbook then redirect to /lookbooks
    await deleteLookbookAPI(Lookbook_id);
    cogoToast.success('Lookbook deleted successfully.');
    history.push('/lookbooks');
  };

  /******************************************************************************************* */
  // LOOKBOOK ITEM OPERATIONS
  /******************************************************************************************* */

  // not doing update to lookbook items before API call because it's not in the form of a LookbookItem yet
  const addLookbookItem = async data => {
    if (!lookbook.id) return cogoToast.error('Error adding item to lookbook. Please try again.');
    data.item.Lookbook_id = lookbook.id;

    // for testing
    // return console.info('adding', { data });

    let newItem = null;
    setSaving(true);
    try {
      const result = await addLookbookItemAPI(data);
      newItem = result.LookbookItem;

      if (newItem) {
        setLookbook({ ...lookbook, items: [...lookbook.items, newItem] });
        return newItem;
      }
    } catch (e) {
      cogoToast.error(typeof e === 'string' ? e : 'Error adding item to lookbook');
    } finally {
      setSaving(false);
    }

    return newItem;
  };

  const updateLookbookItem = async (item, updates) => {
    setSaving(true);

    let updatesCopy = { ...updates };

    if (updates?.overridePrice && Number(updates.overridePrice)) updatesCopy.price = updates.overridePrice;
    else if (updates?.overrideTitle) updatesCopy.title = updates.overrideTitle;
    else if (updates?.overrideUrl) updatesCopy.url = updates.overrideUrl;
    else if (updates?.overrideImage) updatesCopy.image = updates.overrideImage;

    const lookbookItemsBeforeUpdate = [...lookbook.items];
    const lookbookItemsWithUpdatedItem = lookbookItemsBeforeUpdate
      .map(existingItem => (existingItem.id === item.id ? { ...existingItem, ...updatesCopy } : existingItem))
      .sort(sortBySortOrderRank);

    setLookbook({ ...lookbook, items: lookbookItemsWithUpdatedItem });

    try {
      await updateLookbookItemAPI(item, updates);
    } catch (e) {
      cogoToast.error('Failed to update item in lookbook. Please try again.');
      setLookbook({ ...lookbook, items: lookbookItemsBeforeUpdate });
    } finally {
      setSaving(false);
    }

    return { ...item, ...updates };
  };

  const deleteLookbookItem = async item => {
    if (!isBrand(user)) return;
    const currentLookbookItems = [...lookbook.items];
    setLookbook({ ...lookbook, items: lookbook.items.filter(i => i.id !== item.id) });
    try {
      await deleteLookbookItemAPI(item);
    } catch (e) {
      cogoToast.error('Failed to delete item from lookbook. Please try again.');
      setLookbook({ ...lookbook, items: currentLookbookItems });
    }
  };

  const replaceLookbookItem = async (oldItem, newItemData) => {
    if (!isBrand(user)) return;
    setSaving(true);

    try {
      const oldSortOrderRank = oldItem.sortOrderRank;
      await deleteLookbookItemAPI(oldItem);

      const addData = {
        ...newItemData,
        item: { ...newItemData.item, sortOrderRank: oldSortOrderRank, Lookbook_id: lookbook.id }
      };
      const result = await addLookbookItemAPI(addData);
      setLookbook({ ...lookbook, items: [...lookbook.items.filter(i => i.id !== oldItem.id), result.LookbookItem].sort(sortBySortOrderRank) });
    } catch (e) {
      cogoToast.error('Failed to replace item in lookbook. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  /******************************************************************************************* */
  // LOOKBOOK ITEM SIBLING OPERATIONS
  /******************************************************************************************* */

  const addLookbookItemSibling = async data => {
    try {
      const resp = await addLookbookItemSiblingAPI(data);
      const updatedLookbook = { ...lookbook, items: lookbook.items.map(item => (item.id === data.LookbookItem_id ? resp.item : item)) };
      setLookbook(updatedLookbook);
      return resp;
    } catch (e) {
      cogoToast.error('Failed to add sibling to item in lookbook. Please try again.');
      return null;
    }
  };

  const updateLookbookItemSibling = async (sibling, updates) => {
    if (updates?.price && !Number(updates.price)) return cogoToast.error('Price must be a number.');

    // for testing
    // return console.info('updating', { sibling, updates });

    try {
      const resp = await updateLookbookItemSiblingAPI(sibling, updates);
      const updatedLookbook = { ...lookbook, items: lookbook.items.map(item => (item.id === sibling.LookbookItem_id ? resp.item : item)) };
      setLookbook(updatedLookbook);
      return resp;
    } catch (e) {
      cogoToast.error(e);
      return false;
    }
  };

  const deleteLookbookItemSibling = async sibling => {
    // for testing
    // return console.info('deleting', { sibling });

    try {
      const resp = await deleteLookbookItemSiblingAPI(sibling);
      const updatedLookbook = { ...lookbook, items: lookbook.items.map(item => (item.id === sibling.LookbookItem_id ? resp.item : item)) };
      setLookbook(updatedLookbook);
      return true;
    } catch (e) {
      cogoToast.error('Failed to delete sibling from lookbook. Please try again.');
      return false;
    }
  };

  return (
    <div className='lookbooks-outer'>
      <ScrollToTop />
      <div className='lookbooks-inner'>
        {isInitialLoad ? (
          <>
            <div className='loading-container'>
              <Loader />
            </div>
          </>
        ) : !pageIsAuthorized ? (
          <UnauthorizedLookbook
            brandIsViewing={brandIsViewing}
            userIsViewing={userIsViewing}
            isShopmyUser={isShopmyUser}
            key='unauthorized-lookbook'
          />
        ) : userIsViewing ? (
          <UserLookbook lookbook={lookbook} />
        ) : (
          <BrandLookbook
            lookbook={lookbook}
            deleteLookbook={deleteLookbook}
            updateLookbook={updateLookbook}
            addLookbookItem={addLookbookItem}
            updateLookbookItem={updateLookbookItem}
            deleteItemFromLookbook={deleteLookbookItem}
            addLookbookItemSibling={addLookbookItemSibling}
            updateLookbookItemSibling={updateLookbookItemSibling}
            deleteLookbookItemSibling={deleteLookbookItemSibling}
            replaceLookbookItem={replaceLookbookItem}
            syncLookbook={syncLookbook}
            saving={saving}
          />
        )}
      </div>
    </div>
  );
};

Lookbooks.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { ui, user } = state;
  return { ui, user };
};

export default connect(mapStateToProps, {
  openAddressModal,
  openAuthModal,
  resyncBrandLookbooks
})(Lookbooks);
