import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './SearchContentModal.scss';
import defaultPhoto from '../../static/images/logos/logo.png';

import Modal from '../General/Modal';
import InputActions from '../General/InputActions';
import Loader from '../Loader/Loader';

import { searchContent } from '../../APIClient/search';
import { getUserId } from '../../Helpers/user_helpers';
import { getDomainFromUrl } from '../../Helpers/formatting';

let debounce;
const SearchContentModal = props => {
  const { type, user, contract, Searching_User_id } = props;
  const [searchVal, setSearchVal] = useState('');
  const [isSearching, setIsSearching] = useState(true);
  const [users, setUsers] = useState([]);
  const [pins, setPins] = useState([]);
  const [collections, setCollections] = useState([]);
  const [consults, setConsults] = useState([]);

  const searchEl = useRef(null);

  const performSearch = (val = '') => {
    searchContent({ query: val, User_id: Searching_User_id || getUserId(user) }).then(resp => {
      setPins(resp.pins || []);
      setCollections(resp.collections || []);
      setConsults(resp.consults || []);
      setUsers(resp.users || []);
      setIsSearching(false);
    });
  };

  const updateSearch = newVal => {
    setIsSearching(true);
    setSearchVal(newVal);
    clearTimeout(debounce);
    debounce = setTimeout(() => performSearch(newVal), 500);
  };

  const results = {
    pins: pins,
    collections: collections,
    consults: consults,
    users: users
  }[type];

  const selectResult = result => {
    if (contract && result.Contract_id) {
      if (result.Contract_id === contract.id) return cogoToast.warn(`This ${type} is already linked to this collaboration.`);
      if (result.Contract_id !== contract.id) return cogoToast.warn(`This ${type} is linked to another collaboration.`);
    }
    const fn = {
      pins: props.selectPin,
      collections: props.selectCollection,
      consults: props.selectConsult,
      users: props.selectUser
    }[type];
    !props.allowMultiSelect && props.closeModal();
    fn(result);
  };

  // Rework this to useCallbacks if you'd like
  useEffect(() => performSearch(), []);

  const sortedResults = _.orderBy(results, 'created', 'desc');

  const emptyOverride = {
    pins: `You do not have any Links`,
    users: `Could not locate any users`
  }[type];

  const placeholderOverride = {
    pins: 'Search for Links',
    users: 'Search by name, email or username'
  }[type];

  const displayOverride = {
    pins: 'Search Your Links',
    users: 'Search Existing Creators'
  }[type];

  const alreadySelectedDisplay = {
    users: 'Selected',
    contracts: 'Linked'
  }[type];

  const getFallbackName = result => {
    if (type === 'pins') {
      return `Link #${commaNumber(result.id)}`;
    }
    return null;
  };

  return (
    <Modal
      visible
      close={props.closeModal}
      title={displayOverride || `Search Your ${_.startCase(type)}`}
      innerClassName='search-content-modal-outer-container'
      contentClassName='search-content-modal-outer-container'
      showClose
      isNestedModal
    >
      <div className='input-container has-actions'>
        <input
          autoFocus
          ref={searchEl}
          placeholder={placeholderOverride || `Search for ${_.startCase(type)}`}
          onChange={({ target }) => updateSearch(target.value)}
          value={searchVal}
        />
        <InputActions searchVal={searchVal} onCancel={() => updateSearch('')} />
      </div>
      <div className='results-container'>
        {isSearching ? (
          <div className='loader'>
            <Loader />
          </div>
        ) : results.length ? (
          <div className='results'>
            {sortedResults.map(result => {
              const image = result.image || result.coverImage;
              const title = result.name || result.title || getFallbackName(result);
              const link = type === 'users' ? `https://shopmy.us/${result.username}` : result.link;
              const linkDisplay = type === 'users' ? link : getDomainFromUrl(link);
              const dateDisplay = type === 'users' ? 'Joined' : 'Created';
              const alreadySelected = contract
                ? contract.id === result?.Contract_id || contract.pins?.find(p => result.id === p.id)
                : type === 'users'
                ? props.alreadySelectedIds?.includes(result.id)
                : false;
              return (
                <div key={result.id} className={cn('result', type)}>
                  <div className='main'>
                    <div className='image-container'>
                      <img src={image || defaultPhoto} alt={title || 'ShopMy'} />
                    </div>
                    <div className='data'>
                      <div className='title'>{title}</div>
                      <a href={link} target='_blank' rel='noopener noreferrer' className='domain'>
                        {linkDisplay}
                        <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                      <div className='date'>
                        {dateDisplay} {moment(result.createdAt).format('MMMM Do, YYYY')}
                      </div>
                    </div>
                  </div>
                  <div className='secondary'>
                    <div className='link-actions'>
                      {alreadySelected ? (
                        <div className='link-action active'>{alreadySelectedDisplay}</div>
                      ) : type === 'users' ? (
                        <div onClick={() => selectResult(result)} className='link-action'>
                          <FontAwesomeIcon icon={faPlus} />
                          ADD
                        </div>
                      ) : (
                        <div onClick={() => selectResult(result)} className='link-action'>
                          <FontAwesomeIcon icon={faPlus} />
                          ADD
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : searchVal ? (
          <div className='empty-msg'>We could not find any results for the search term {searchVal}</div>
        ) : (
          <div className='empty-msg'>{emptyOverride || `You do not have any ${_.startCase(type)}`}</div>
        )}
      </div>
    </Modal>
  );
};

SearchContentModal.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['pins', 'collections', 'consults', 'users']),

  // Must select one of the following
  selectUser: PropTypes.func,
  selectPin: PropTypes.func,
  selectCollection: PropTypes.func,
  selectConsult: PropTypes.func,

  // Optional
  contract: PropTypes.object,
  Searching_User_id: PropTypes.number,
  allowMultiSelect: PropTypes.bool,
  alreadySelectedIds: PropTypes.array // For users only
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(SearchContentModal);
