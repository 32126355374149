import APIClient from './index';
import _ from 'lodash';

export const fetchHierarchy = params => {
  return new Promise((resolve, reject) => {
    APIClient.get('/Catalog/hierarchy')
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getProducts = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Products?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getProduct = stub =>
  new Promise((resolve, reject) => {
    APIClient.get(`/Products/from_stub/${stub}`)
      .then(response => resolve(_.get(response, 'data')))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });

export const addReview = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ProductReviews`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
export const updateReview = (review, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ProductReviews/${review.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteReview = review => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/ProductReviews/${review.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
