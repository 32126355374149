import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './BulkTalentModalTalent.scss';

import Loader from '../../Loader/Loader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faComment } from '@fortawesome/pro-regular-svg-icons';
import { getSmartImage } from '../../../Helpers/helpers';
import { getCustomRateForUser, getStandardRateForArtist } from '../../../Helpers/brand_helpers';
import { getInitialsForUser } from '../../../Helpers/formatting';

const BulkTalentModalTalent = props => {
  const { user, analytics, talent, status, outreachType } = props;
  const { image, name } = talent;
  const { showRate } = outreachType;
  const extraClasses = status ? status : {};

  const talentIsNotOnPlatform = talent.offPlatform;
  const { name: offPlatformName, email: offPlatformEmail } = talent;

  const rate = getCustomRateForUser(analytics, talent)?.rate || getStandardRateForArtist(user, talent);

  const disabledMessage = outreachType.getDisabledMessage(talent);
  const errorMessage = outreachType.getErrorMessage(talent);
  const oosMessage = outreachType.getOOSMessage(talent);

  const hasError = status === 'error';
  const isOutOfRequests = status === 'out-of-requests';
  const isSuccess = status === 'success';

  let action, icon, statusEl;
  switch (status) {
    case 'progress':
      statusEl = (
        <div className='loading-container'>
          <Loader size={48} />
        </div>
      );
      break;
    case 'success':
    case 'complete':
      icon = faCheck;
      break;
    default:
      if (talentIsNotOnPlatform) action = () => props.removeTalentByOffPlatformEmail(talent.email);
      else action = () => props.removeTalentById(talent.id);
      icon = faTimes;
      break;
  }

  const openTalentCard = () => !talentIsNotOnPlatform && props.openArtistModal(talent);
  const openChat = () => !talentIsNotOnPlatform && props.openChatOverlay(talent);

  return talentIsNotOnPlatform ? (
    <div className={cn('bulk-talent-modal-talent', extraClasses)}>
      <div className='main'>
        <div className={cn('meta', { talentIsNotOnPlatform })}>
          <div onClick={openChat} className='name'>
            {offPlatformName}
            <span className='off-platform'>(off platform)</span>
          </div>
          <div className='email'>{offPlatformEmail}</div>
          {hasError && <div className='error'>{errorMessage}</div>}
          {isOutOfRequests && <div className='oos'>{oosMessage}</div>}
        </div>
      </div>
      <div className={cn('action-btn', extraClasses)} onClick={action}>
        {statusEl || <FontAwesomeIcon icon={icon} />}
      </div>
      {disabledMessage && (
        <div className='disabled-overlay'>
          <div className='message'>{disabledMessage}</div>
        </div>
      )}
    </div>
  ) : (
    <div className={cn('bulk-talent-modal-talent', extraClasses)}>
      <div className='main'>
        <div className='image-container'>
          {image ? <img src={getSmartImage(image)} alt={name} /> : <div className='empty'>{getInitialsForUser(talent)}</div>}
        </div>
        <div className='meta'>
          <div onClick={openChat} className='name'>
            {isSuccess && <FontAwesomeIcon icon={faComment} />}
            {talent.name}
          </div>
          <div className='stats-and-actions'>
            {showRate && <div className='stat'>{rate}% •</div>}
            <div onClick={openTalentCard} className='action'>
              View Talent Card •
            </div>
            <div onClick={openChat} className='action'>
              View Chat
            </div>
          </div>
          {hasError && <div className='error'>{errorMessage}</div>}
          {isOutOfRequests && <div className='oos'>{oosMessage}</div>}
        </div>
      </div>
      <div className={cn('action-btn', extraClasses)} onClick={action}>
        {statusEl || <FontAwesomeIcon icon={icon} />}
      </div>
      {disabledMessage && (
        <div className='disabled-overlay'>
          <div className='message'>{disabledMessage}</div>
        </div>
      )}
    </div>
  );
};

BulkTalentModalTalent.propTypes = {
  talent: PropTypes.object.isRequired,
  status: PropTypes.string,
  removeTalentById: PropTypes.func.isRequired,
  removeTalentByOffPlatformEmail: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  outreachType: PropTypes.object.isRequired
};

export default BulkTalentModalTalent;
