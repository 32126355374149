import React from 'react';
import PropTypes from 'prop-types';
import './PartnerTile.scss';

import partnerBadgeWhite from '../../static/images/misc/partner_badge_white.png';

import { getAdjPayoutRate } from '../../Helpers/user_helpers';

const PartnerTile = props => {
  const { user, idx, partner, animateWaterfall } = props;
  const { name, mobileCoverImage, logo, merchant } = partner;
  const rate = merchant ? getAdjPayoutRate(user, merchant) : 0;
  return (
    <div key={name} onClick={props.onClick} className='partner-tile'>
      <img
        className='cover'
        alt={`${name} Logo`}
        src={mobileCoverImage}
        style={{
          animationDelay: !animateWaterfall || idx > 16 ? '0ms' : `${300 + 75 * idx}ms`
        }}
      />
      <div className='cover-container'>
        <div className='cover-fade' />
        <div className='logo-container'>
          <img className='logo' alt={name + ' Logo'} src={logo} />
        </div>
        <div className='footer-container'>
          <div className='partner-badge-container'>
            <img src={partnerBadgeWhite} alt='ShopMy Partner' />
            ShopMy Partner
          </div>
          {!!rate && (
            <div className='rate-container'>
              <div>{rate.toFixed(0)}%</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PartnerTile.propTypes = {
  user: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,

  // Optional UI
  animateWaterfall: PropTypes.bool
};

export default PartnerTile;
