import APIClient from './index';
import _ from 'lodash';

export const getShopifyCollections = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Shopify/Collections`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getShopifyLocations = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Shopify/Locations`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const syncShopifyScopesForBrandId = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Brands/${Brand_id}/sync_shopify_scopes`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
