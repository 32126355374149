import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OpportunityListingPaymentTiers.scss';

import { isSMSAffiliatePartnerBrand } from '../../../Helpers/user_helpers';
import { getStandardRateForArtist } from '../../../Helpers/brand_helpers';
import { getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';
import { isOpportunityExperiential } from '../../../Helpers/opportunity_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';

import Tooltip from '../../General/Tooltip';
import Loader from '../../Loader/Loader';

const OpportunityListingPaymentTiers = props => {
  const { analytics, user, opportunity, canEdit } = props;

  // We need to store this locally due to the debouncing
  const [payment_tiers, set_payment_tiers] = React.useState(opportunity.payment_tiers || []);
  const numTiers = opportunity.payment_tiers?.length;
  React.useEffect(() => {
    set_payment_tiers(opportunity.payment_tiers);
  }, [numTiers]);

  // UI
  const needsTier = !payment_tiers.length;
  const isExperience = isOpportunityExperiential(opportunity);

  // Actions
  const [isAddingTier, setIsAddingTier] = React.useState(false);
  const addNewTier = async () => {
    if (isAddingTier) return;
    setIsAddingTier(true);
    await props.createOpportunityPaymentTier({ Opportunity_id: opportunity.id });
    setIsAddingTier(false);
  };

  // Update
  const debounceUpdater = React.useRef({});
  const updateExistingTier = async (tier, updates) => {
    set_payment_tiers(payment_tiers.map(t => (t.id === tier.id ? { ...t, ...updates } : t)));
    clearTimeout(debounceUpdater.current[tier.id]);
    debounceUpdater.current[tier.id] = setTimeout(async () => {
      const resp = await props.updateOpportunityPaymentTier(tier, updates);
      if (!resp.success) set_payment_tiers(payment_tiers.map(t => (t.id === tier.id ? tier : t)));
    }, 750);
  };

  // Delete
  const deleteExistingTier = async tier => {
    set_payment_tiers(payment_tiers.filter(t => t.id !== tier.id));
    const resp = await props.deleteOpportunityPaymentTier(tier);
    if (!resp.success) set_payment_tiers([...payment_tiers, tier]);
  };

  const allRequests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const getNumRequestsSentForTier = tier => allRequests.filter(r => r.OpportunityPaymentTier_id === tier.id).length;
  const canEditOrDeleteTier = tier => !getNumRequestsSentForTier(tier);
  const gridData = [
    {
      display: 'Fixed Fee',
      hasValue: payment_tier => !!payment_tier.fixedFee,
      getValue: payment_tier => (payment_tier.fixedFee ? `$${getPrettyNumber(+payment_tier.fixedFee)}` : 'No Fee'),
      getEditingElement: payment_tier => {
        const { fixedFee } = payment_tier;
        const updateField = e => updateExistingTier(payment_tier, { fixedFee: e.target.value.replace(/\D/g, '') });
        const maxCurrentPayment = Math.max(...payment_tiers.map(t => +t.fixedFee || 0));
        return (
          <div className='input-wrapper'>
            <input
              autoFocus={!fixedFee}
              placeholder={`$${maxCurrentPayment ? commaNumber(maxCurrentPayment + 1000) : 1000}`}
              value={fixedFee ? `$${commaNumber(fixedFee)}` : ''}
              onChange={updateField}
            />
          </div>
        );
      }
    },
    {
      display: 'Commission Rate',
      hasValue: payment_tier => !!payment_tier.adjCommissionRate,
      getValue: payment_tier => (payment_tier.adjCommissionRate ? `${payment_tier.adjCommissionRate}%` : 'Default'),
      getEditingElement: payment_tier => {
        const defaultRate = getStandardRateForArtist(user);
        const updateField = e =>
          updateExistingTier(payment_tier, {
            adjCommissionRate: e.target.value.replace('%', '') || null
          });
        const displayValue = payment_tier.adjCommissionRate ? `${payment_tier.adjCommissionRate}%` : '';
        const canSetRate = isSMSAffiliatePartnerBrand(user);
        return (
          <div className='input-wrapper'>
            <input placeholder={canSetRate ? `${defaultRate || 20}%` : '-'} disabled={!canSetRate} value={displayValue} onChange={updateField} />
          </div>
        );
      }
    },
    {
      display: 'Total Sent',
      hasValue: payment_tier => !!getNumRequestsSentForTier(payment_tier),
      getValue: payment_tier => getNumRequestsSentForTier(payment_tier),
      getEditingElement: () => null
    },
    ...(canEdit
      ? [
          {
            display: 'Actions',
            hasValue: tier => true,
            getValue: tier => {
              const canDelete = canEditOrDeleteTier(tier);
              const remove = () => deleteExistingTier(tier);
              return (
                <div className='actions'>
                  {canDelete ? (
                    <div onClick={remove} className='action delete'>
                      DELETE
                    </div>
                  ) : (
                    <Tooltip message='Cannot delete tier with requests' position='top'>
                      <div className='action delete disabled'>DELETE</div>
                    </Tooltip>
                  )}
                </div>
              );
            },
            getEditingElement: () => null
          }
        ]
      : [])
  ];

  return (
    <div className='opportunity-listing-section listing-payment-tiers-container'>
      <div className='opportunity-listing-section-title-container'>
        <div className='opportunity-listing-section-title'>{isExperience ? 'Additional Benefits' : 'Payment Tiers'}</div>
        {(canEdit || needsTier) && (
          <div className='opportunity-listing-section-subtitle'>
            {isExperience
              ? `We highly recommend increasing your commission rate for the tracking period following your event in order to increase the likelihood of promotion. If you would like to offer an additional fixed payment you can do that here as well.`
              : `Specify the various payments and rates you want to offer to talent. If you do not explicitly set a commission rate, the creator will
            receive your standard commission rate.`}
          </div>
        )}
      </div>
      {needsTier ? null : (
        <div className={cn(`opportunity-payment-tiers-grid`, `cols-${gridData.length}`)}>
          {gridData.map(({ display }) => (
            <div key={display} className='cell header'>
              {display}
            </div>
          ))}
          {payment_tiers.map(tier => {
            const canEdit = canEditOrDeleteTier(tier);
            return (
              <React.Fragment key={tier.id}>
                {gridData.map(({ hasValue, getValue, getEditingElement }, idx) => {
                  const editingElement = getEditingElement ? getEditingElement(tier) : null;
                  return canEdit && editingElement ? (
                    <div className='cell' key={idx}>
                      {editingElement}
                    </div>
                  ) : (
                    <div className='cell' key={idx}>
                      {hasValue(tier) ? getValue(tier) : '-'}
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      )}
      {canEdit && (
        <div onClick={addNewTier} className={cn('add-new-tier', { 'needs-tier': needsTier, adding: isAddingTier })}>
          <FontAwesomeIcon icon={faPlus} />
          {needsTier ? 'Add Tier' : 'Add New Tier'}
          {isAddingTier && <Loader size={60} />}
        </div>
      )}
    </div>
  );
};

OpportunityListingPaymentTiers.propTypes = {
  analytics: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  updateOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired
};

export default OpportunityListingPaymentTiers;
