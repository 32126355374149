import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faExclamationTriangle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import './ContractProposeScheduleCard.scss';

import ContractProposeTaskCalendarSelection from './ContractProposeTaskCalendarSelection';

import { getDisplayForCollaborationType } from '../../../Helpers/collaboration_helpers';

const ContractProposeScheduleCard = props => {
  const { contract, canEdit, updateContractTask } = props;
  const visibleTasks = canEdit
    ? contract.tasks
    : contract.tasks.filter(task => {
        const hasDueDate = task.submissionDueDate || task.submissionDueDateDays;
        const hasLiveDate = task.contentLiveDueDate || task.contentLiveDueDateDays;
        const hasDuration = task.taskDuration;
        if (!canEdit && !hasDueDate && !hasLiveDate && !hasDuration) return false;
        return true;
      });
  if (!visibleTasks.length && !canEdit) return null;
  return (
    <div className='contract-propose-schedule-card card'>
      <div className='card-header-container'>
        <div className='card-header'>Schedule</div>
        {canEdit && <div className='card-subheader'>Set due dates for all the deliverables. This is optional.</div>}
      </div>
      <div className='card-body-container'>
        <div className='tasks'>
          {visibleTasks.map(task => (
            <ContractProposedTask key={task.id} canEdit={canEdit} updateContractTask={updateContractTask} task={task} contract={contract} />
          ))}
        </div>
      </div>
    </div>
  );
};

let descriptionDebouncers = {};
const ContractProposedTask = props => {
  const { task, contract, canEdit, updateContractTask } = props;
  const { template, id, taskDuration, submissionDueDate, submissionDueDateDays, contentLiveDueDate, contentLiveDueDateDays } = task;
  const { type, isAddOn, fee_type, requiresApproval } = template || {};
  const isDuration = fee_type === 'duration';
  const [description, setDescription] = useState(task.description || '');
  const [isProposingSchedule, setIsProposingSchedule] = useState(false);
  const [proposingScheduleSettings, setProposingScheduleSettings] = useState(null);
  const isSubmissionSet = submissionDueDate || submissionDueDateDays;
  const isContentLiveSet = contentLiveDueDate || contentLiveDueDateDays;

  const removeApprovalDate = e => {
    e.stopPropagation();
    updateContractTask(task, { submissionDueDateDays: null, submissionDueDate: null });
  };
  const removeContentLiveDate = e => {
    e.stopPropagation();
    updateContractTask(task, { contentLiveDueDateDays: null, contentLiveDueDate: null });
  };
  const removeDuration = e => {
    e.stopPropagation();
    updateContractTask(task, { taskDuration: null });
  };

  const updateDescription = newDescription => {
    setDescription(newDescription);
    clearTimeout(descriptionDebouncers[id]);
    descriptionDebouncers[id] = setTimeout(() => {
      updateContractTask(task, { description: newDescription });
    }, 500);
  };

  const hasScheduling = requiresApproval || !isAddOn || isDuration;
  if (!hasScheduling) return null;

  const answerRequired = !taskDuration && isDuration;

  return (
    <>
      <div className='task'>
        <div className='main'>
          <div className='title'>{task.title}</div>
          <div className='subtitle'>{getDisplayForCollaborationType(type)}</div>
          <div className='description'>
            {canEdit ? (
              <textarea
                rows={2}
                value={description}
                className='description'
                placeholder='Add more detail on the scheduling for this deliverable'
                onChange={e => updateDescription(e.target.value)}
              />
            ) : (
              description
            )}
          </div>
        </div>
        <div className='secondary'>
          {!!requiresApproval && (isSubmissionSet || canEdit) && (
            <div
              onClick={() => {
                if (!canEdit) return;
                setProposingScheduleSettings({
                  isApprovalDate: true,
                  save: async ({ date, start, end, duration }) => {
                    date
                      ? await updateContractTask(task, { submissionDueDateDays: null, submissionDueDate: date.format('YYYY-MM-DD 12:00:00') })
                      : await updateContractTask(task, { submissionDueDate: null, submissionDueDateDays: parseFloat(duration) });
                  },
                  title: 'Set submission for approval due date',
                  subtitle: 'Set the date by which a submission must be sent in for approval.'
                });
                setIsProposingSchedule(true);
              }}
              className={cn('approval-date date', { set: isSubmissionSet })}
            >
              <div className='text'>
                {submissionDueDate
                  ? `Content must be submitted for approval by ${moment(submissionDueDate).format('MMMM Do, YYYY')}.`
                  : submissionDueDateDays
                  ? `Content must be submitted for approval within ${submissionDueDateDays} day${
                      submissionDueDateDays === 1 ? '' : 's'
                    } after this collaboration is accepted.`
                  : 'No due date set for when content must be submitted for approval'}
              </div>
              {canEdit && (
                <div className='edit-actions'>
                  <div className='action edit'>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  {isSubmissionSet && (
                    <div onClick={removeApprovalDate} className='action delete'>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {!isAddOn && (isContentLiveSet || canEdit) && (
            <div
              onClick={() => {
                if (!canEdit) return;
                setProposingScheduleSettings({
                  isGoLiveDate: true,
                  isApprovalDate: false,
                  save: async ({ date, start, end, duration }) => {
                    date
                      ? await updateContractTask(task, { contentLiveDueDateDays: null, contentLiveDueDate: date.format('YYYY-MM-DD 12:00:00') })
                      : await updateContractTask(task, { contentLiveDueDate: null, contentLiveDueDateDays: parseFloat(duration) });
                  },
                  title: 'Set a date when the content should go live by',
                  subtitle: 'Select the date for when you want the content to go live.'
                });
                setIsProposingSchedule(true);
              }}
              className={cn('go-live-date date', { set: isContentLiveSet })}
            >
              <div className='text'>
                {contentLiveDueDate
                  ? `Content to go live on ${moment(contentLiveDueDate).format('MMMM Do, YYYY')}.`
                  : contentLiveDueDateDays
                  ? `Content to go live within ${contentLiveDueDateDays} day${contentLiveDueDateDays === 1 ? '' : 's'} ${
                      requiresApproval ? 'after content has been approved' : 'after collaboration has been accepted'
                    }.`
                  : 'No date set for when content must go live.'}
              </div>
              {canEdit && (
                <div className='edit-actions'>
                  <div className='action edit'>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  {isContentLiveSet && (
                    <div onClick={removeContentLiveDate} className='action delete'>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {isDuration && (taskDuration || canEdit) && (
            <div
              onClick={() => {
                if (!canEdit) return;
                setProposingScheduleSettings({
                  isDuration: true,
                  save: async ({ date, duration }) => {
                    if (!duration) return cogoToast.error(`You must return a date or duration to make this work`); // REMOVE BEFORE LANDING
                    await updateContractTask(task, {
                      taskDuration: parseFloat(duration),
                      contentLiveDueDate: date ? date.format('YYYY-MM-DD 12:00:00') : null
                    });
                  },
                  title: 'Set a duration for this deliverable',
                  subtitle: 'Either set a duration that begins when the collaboration goes live, or set a specific start date.'
                });
                setIsProposingSchedule(true);
              }}
              className={cn('go-live-date date', { set: taskDuration, required: answerRequired })}
            >
              <div className='text'>
                {taskDuration
                  ? contentLiveDueDate
                    ? `Content live from ${moment(contentLiveDueDate).format('MMMM Do')} to ${moment(contentLiveDueDate)
                        .add(taskDuration, 'days')
                        .format('MMMM Do')}.`
                    : `Content to last for ${taskDuration} day${taskDuration === 1 ? '' : 's'}`
                  : '(Required) No duration set for this deliverable'}
              </div>
              {canEdit && (
                <div className='edit-actions'>
                  <div className='action edit'>
                    <FontAwesomeIcon icon={answerRequired ? faExclamationTriangle : faCalendar} />
                  </div>
                  {isContentLiveSet && (
                    <div onClick={removeDuration} className='action delete'>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {isProposingSchedule && (
          <ContractProposeTaskCalendarSelection
            contract={contract}
            save={proposingScheduleSettings.save}
            isDuration={proposingScheduleSettings.isDuration}
            isApprovalDate={proposingScheduleSettings.isApprovalDate}
            isGoLiveDate={proposingScheduleSettings.isGoLiveDate}
            title={proposingScheduleSettings.title}
            subtitle={proposingScheduleSettings.subtitle}
            task={task}
            closeModal={() => {
              setIsProposingSchedule(false);
              setProposingScheduleSettings(null);
            }}
          />
        )}
      </div>
    </>
  );
};

ContractProposeScheduleCard.propTypes = {
  contract: PropTypes.object.isRequired,

  // Editing
  canEdit: PropTypes.bool.isRequired,
  updateContractTask: PropTypes.func
};

export default ContractProposeScheduleCard;
