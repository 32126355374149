import APIClient from './index';
import _ from 'lodash';

export const getChats = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Chats?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getChat = (id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Chats/${id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getChatFromIds = ({ User_id, Brand_id }) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Chats/chat_id_from_ids`, { User_id, Brand_id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateChat = (chat, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Chats/${chat.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteChat = chat => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Chats/${chat.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const sendChatMessage = data => {
  /*
    Normally just use a websocket via ChatConnect, we currently only use this in the
    bulk sending modal.
  */
  return new Promise((resolve, reject) => {
    APIClient.post(`/ChatMessages`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
