import React from 'react';
import PropTypes from 'prop-types';
import './BulkRequestModalSelectedUsers.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';
import cn from 'classnames';
import cogoToast from 'cogo-toast';

import { getInitialsForUser } from '../../Helpers/formatting';

const BulkRequestModalSelectedUsers = props => {
  const {
    platformUsersToAdd,
    emailUsersToAdd,
    removeUser,
    selectedUsersApiState,
    openArtistModal,
    findIfUserAlreadyReceivedRequestOfType,
    invalidLocationUserIds
  } = props;

  return (
    <div className='bulk-request-selected-users'>
      {platformUsersToAdd.map(user => {
        const { id, name, image, brandList, countryCode } = user;
        const isLoading = selectedUsersApiState[id]?.loading;
        const isSuccess = selectedUsersApiState[id]?.success;
        const isErrored = selectedUsersApiState[id]?.error;
        const isDisabled = findIfUserAlreadyReceivedRequestOfType(user);

        const canRemoveUser = !isLoading && !isSuccess;
        const isInvalidLocationUser = !!invalidLocationUserIds?.includes(id);
        const showLocationTip = isInvalidLocationUser && !!countryCode;

        const renderSelectedUser = () => {
          return (
            <div
              className={cn('bulk-request-selected-user', { isDisabled, invalidLocation: isInvalidLocationUser })}
              onClick={() =>
                canRemoveUser ? removeUser(user) : cogoToast.warn('This user is still being added to the list. Please wait a moment and try again.')
              }
            >
              <div className={cn('bulk-request-selected-user-loading-overlay', { isLoading })}>
                <Loader size={40} />
              </div>
              {image ? (
                <img className='bulk-request-selected-user-image' src={image} alt={name} />
              ) : (
                <div className='bulk-request-selected-user-image'>{getInitialsForUser(user)}</div>
              )}
              <div className='bulk-request-selected-user-text-container'>
                <div className='bulk-request-selected-user-name'>{user.name}</div>
                <div className='bulk-request-selected-user-subtitle'>
                  <span
                    className='view-talent'
                    onClick={e => {
                      e.stopPropagation();
                      openArtistModal(user);
                    }}
                  >
                    View Talent
                  </span>
                  {brandList && <span>&nbsp;•&nbsp;{brandList.title}</span>}
                </div>
                {/* {brandList && <div className='bulk-request-selected-user-subtitle'>{brandList.title}</div>} */}
              </div>
              <div className={cn('bulk-request-remove-user', { isSuccess, isErrored })}>
                <FontAwesomeIcon icon={isSuccess ? faCheck : faTimes} />
              </div>
            </div>
          );
        };

        return (
          <div className='bulk-request-selected-user-container' key={id}>
            {showLocationTip ? <Tooltip message={`Lives in ${countryCode}`}>{renderSelectedUser()}</Tooltip> : renderSelectedUser()}
          </div>
        );
      })}
      {emailUsersToAdd.map(user => {
        const { email } = user;
        const isLoading = selectedUsersApiState[email]?.loading;
        const isSuccess = selectedUsersApiState[email]?.success;
        const isErrored = selectedUsersApiState[email]?.error;
        const isDisabled = findIfUserAlreadyReceivedRequestOfType(user);

        const canRemoveUser = !isLoading && !isSuccess;

        return (
          <div
            className={cn('bulk-request-selected-user off-platform', { isDisabled })}
            key={email}
            onClick={() =>
              canRemoveUser ? removeUser(user) : cogoToast.warn('This user is still being added to the list. Please wait a moment and try again.')
            }
          >
            <div className={cn('bulk-request-selected-user-loading-overlay', { isLoading })}>
              <Loader size={40} />
            </div>
            <div className='bulk-request-selected-user-text-container'>
              <div className='bulk-request-selected-user-name'>{email}</div>
              <div className='bulk-request-selected-user-subtitle'>off platform</div>
            </div>
            <div className={cn('bulk-request-remove-user', { isSuccess, isErrored })}>
              {isSuccess ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
            </div>
          </div>
        );
      })}
    </div>
  );
};

BulkRequestModalSelectedUsers.propTypes = {
  platformUsersToAdd: PropTypes.array.isRequired,
  emailUsersToAdd: PropTypes.array.isRequired,
  removeUser: PropTypes.func.isRequired,

  selectedUsersApiState: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  findIfUserAlreadyReceivedRequestOfType: PropTypes.func.isRequired,
  invalidLocationUserIds: PropTypes.array
};

export default BulkRequestModalSelectedUsers;
