import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChartLine, faTag, faGift, faPercent, faTimes, faRedo, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { faTag as faTagSolid, faGift as faGiftSolid, faPercent as faPercentSolid } from '@fortawesome/pro-solid-svg-icons';
import { faHandshake } from '@fortawesome/pro-light-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import './ChatMessageHeader.scss';

import {
  isBrand,
  getRequests,
  isSMSAffiliatePartnerBrand,
  getCodeForBrandId,
  getBrandContracts,
  getRateForBrandId
} from '../../Helpers/user_helpers';
import { getBrandRequests, getCustomCodeForUserId, getCustomRateForUserId } from '../../Helpers/brand_helpers';
import { getInitialsForUser } from '../../Helpers/formatting';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import { getUserTierDisplay } from '../../Helpers/tier_helpers';

import Tooltip from '../General/Tooltip';

const ChatMessageHeader = props => {
  const {
    user,
    analytics,
    activeChat,
    openFulfillmentModal,
    requestGiftingToUser,
    offerCodeToUser,
    setCustomRate,
    createCollaborationWithUser,
    createCollaborationWithBrand,
    openArtistModal
  } = props;
  const { User_id, isDismissedByBrand, isDismissedByUser } = activeChat;

  const requests = isBrand(user) ? getBrandRequests(analytics) : getRequests(user);
  const relevantRequests = requests.filter(r => (isBrand(user) ? r.User_id === activeChat.User_id : r.Brand_id === activeChat.Brand_id));
  const hasGifted = !!relevantRequests.length;
  const unfulfilledRequest = relevantRequests.find(r => r.userAccepted && r.brandAccepted && !r.isComplete);

  const brandCode = isBrand(user) ? getCustomCodeForUserId(analytics, activeChat.User_id) : getCodeForBrandId(user, activeChat.Brand_id);
  const customRate = isBrand(user) ? getCustomRateForUserId(analytics, activeChat.User_id) : getRateForBrandId(user, activeChat.Brand_id);
  const isSMSAffiliatePartner = isSMSAffiliatePartnerBrand(user);

  const clickToViewInfo = () => {
    if (isBrand(user)) openArtistModal({ id: User_id });
    else
      props.openBrandModal({
        brand: activeChat.brand
      });
  };

  const isDismissed = isBrand(user) ? isDismissedByBrand : isDismissedByUser;
  const actions = isDismissed
    ? [
        {
          display: 'Reactivate Chat',
          displayTooltip: 'Add this chat back to your inbox',
          isPrimary: true,
          icon: faRedo,
          onClick: () => props.updateChat(activeChat, { [isBrand(user) ? 'isDismissedByBrand' : 'isDismissedByUser']: false })
        }
      ]
    : isBrand(user)
    ? [
        {
          display: unfulfilledRequest ? 'Fulfill Gift' : hasGifted ? 'Gifted' : 'Gifting',
          isActive: !!relevantRequests.length,
          icon: relevantRequests.length ? faGiftSolid : faGift,
          onClick: () => {
            if (blockOnRequiringSubscription(user, 'GIFTING')) return null;
            if (unfulfilledRequest) openFulfillmentModal({ params: { Request_id: unfulfilledRequest.id } });
            else requestGiftingToUser();
          }
        },
        {
          display: brandCode ? brandCode.displayText?.slice(0, 12) + (brandCode.displayText?.length > 12 ? '...' : '') : 'Code',
          isActive: !!brandCode,
          icon: brandCode ? faTagSolid : faTag,
          onClick: () => {
            if (blockOnRequiringSubscription(user, 'CUSTOM_CODES')) return null;
            offerCodeToUser(brandCode);
          }
        },
        {
          display: customRate
            ? customRate.rate_returning && customRate.rate_returning !== customRate.rate
              ? `${customRate.rate} / ${customRate.rate_returning}`
              : customRate.rate
            : 'Rate',
          isActive: !!customRate,
          disabled: !isSMSAffiliatePartner,
          icon: customRate ? faPercentSolid : faPercent,
          onClick: () => {
            if (blockOnRequiringSubscription(user, 'CUSTOM_RATES', { directIntegrationRequired: true })) return null;
            setCustomRate(customRate);
          }
        },
        {
          display: 'Opportunity',
          icon: faChartLine,
          onClick: () => {
            if (blockOnRequiringSubscription(user, 'OPPORTUNITIES')) return null;
            props.requestOpportunityToUser();
          }
        },
        {
          display: 'Collab',
          icon: faHandshake,
          onClick: () => {
            if (blockOnRequiringSubscription(user, 'COLLABORATE')) return null;

            const confirm = () => createCollaborationWithUser(User_id);
            const existingContract = getBrandContracts(user).find(c => c.User_id === User_id && ['pending', 'proposed'].includes(c.status));

            if (existingContract) {
              // This will prompt the user from the reducer
              confirm();
            } else {
              confirmAlert({
                title: 'Just confirming',
                message: `Are you sure you want to start a collaboration proposal with ${activeChat?.user?.name}?`,
                buttons: [
                  { label: 'No', className: 'cancel', onClick: () => {} },
                  { label: 'Yes', onClick: confirm }
                ]
              });
            }
          }
        },
        {
          display: 'Bonus',
          icon: faDollarSign,
          onClick: () => {
            props.openBonusModal({ params: { User_id: User_id } });
          }
        },
        {
          display: 'Dismiss',
          displayTooltip: 'Dismissing this chat will remove it from your inbox.',
          icon: faTimes,
          onClick: () => {
            const confirm = () => props.updateChat(activeChat, { isDismissedByBrand: true, hasNewMessagesForBrand: false });
            const hasSentMessage = activeChat.messages.some(m => !m.isUserMessage);
            if (!hasSentMessage) confirm();
            else
              confirmAlert({
                title: 'Just confirming',
                message: `Are you sure you want dismiss this chat? This will remove it from your inbox and future chats from ${activeChat?.user?.name} will be hidden.`,
                buttons: [
                  { label: 'No', className: 'cancel', onClick: () => {} },
                  { label: 'Yes', onClick: confirm }
                ]
              });
          }
        }
      ]
    : [
        {
          display: 'Collab',
          icon: faHandshake,
          onClick: () => createCollaborationWithBrand(activeChat.Brand_id)
        },
        {
          display: 'Dismiss',
          displayTooltip: 'Dismissing this chat will move it to the end of your inbox.',
          icon: faTimes,
          onClick: () => {
            const confirm = () => props.updateChat(activeChat, { isDismissedByUser: true, hasNewMessagesForUser: false });
            const hasSentMessage = activeChat.messages.some(m => m.isUserMessage);
            if (!hasSentMessage) confirm();
            else
              confirmAlert({
                title: 'Just confirming',
                message: `Are you sure you want dismiss this chat? This will remove it from your inbox and future chats from ${activeChat?.brand?.name} will be hidden.`,
                buttons: [
                  { label: 'No', className: 'cancel', onClick: () => {} },
                  { label: 'Yes', onClick: confirm }
                ]
              });
          }
        }
      ];

  const additionalClasses = { user: isBrand(user), brand: !isBrand(user) };
  return (
    <div className={cn('chat-message-header-outer-container', {})}>
      <div onClick={clickToViewInfo} className='profile-section'>
        {isBrand(user) ? (
          activeChat.user?.image ? (
            <img className={cn(additionalClasses)} src={activeChat.user?.image} alt={activeChat.user?.name} />
          ) : (
            <div className='empty'>{getInitialsForUser(activeChat.user)}</div>
          )
        ) : (
          <img className={cn(additionalClasses)} src={activeChat.brand?.logo} alt={activeChat.brand?.name} />
        )}
        <div className='info'>
          <div className='name'>{isBrand(user) ? activeChat.user?.name : activeChat.brand?.name}</div>
          <div className='profile'>
            {isBrand(user) ? `${getUserTierDisplay(activeChat.user?.tier?.tier)} • ` : ''}
            {isBrand(user) ? 'View Talent Card' : 'View Brand Profile'}
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
      <div className='actions'>
        {actions.map((action, i) => {
          return (
            <Tooltip key={i} message={action.displayTooltip || ''}>
              <div
                onClick={action.onClick}
                key={i}
                className={cn('action', { active: action.isActive, primary: action.isPrimary, disabled: action.isDisabled })}
              >
                {action.icon && (
                  <div className='icon'>
                    <FontAwesomeIcon icon={action.icon} />
                  </div>
                )}
                {!!action.display && <div className='display'>{action.display}</div>}
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

ChatMessageHeader.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  activeChat: PropTypes.object.isRequired,

  // Functions
  updateChat: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired,
  requestGiftingToUser: PropTypes.func.isRequired,
  requestOpportunityToUser: PropTypes.func.isRequired,
  offerCodeToUser: PropTypes.func.isRequired,
  setCustomRate: PropTypes.func.isRequired,
  createCollaborationWithUser: PropTypes.func.isRequired,
  createCollaborationWithBrand: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openBrandModal: PropTypes.func.isRequired,
  openBonusModal: PropTypes.func.isRequired
};

export default ChatMessageHeader;
