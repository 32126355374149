import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookPreview.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { faCopy, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
// import Switch from 'react-switch';
import { confirmAlert } from 'react-confirm-alert';
import cogoToast from 'cogo-toast';

import { getGiftingRequestsForLookbook } from '../../../Helpers/brand_helpers';
import { getRelativePrettyDate } from '../../../Helpers/formatting';

const LookbookPreview = props => {
  const { analytics, lookbook, brand } = props;
  const { coverImagePreview, coverImage, title, item_limit, price_limit, createdAt } = lookbook;
  const { mobileCoverImage } = brand || {};
  const history = useHistory();
  const coverImageToUse = coverImagePreview || coverImage || mobileCoverImage;

  const [showOptionsPanel, setShowOptionsPanel] = useState(false);
  const redirectToLookbook = () => history.push(`/lookbooks/${lookbook.id}`);

  const editLookbook = () => redirectToLookbook();
  const duplicateLookbook = () => props.duplicateLookbook(lookbook);
  const deleteLookbook = async () => {
    await confirmAlert({
      title: 'Delete Lookbook?',
      message: 'This will permanently delete it and cannot be undone. Are you sure you want to delete this lookbook?',
      buttons: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {}
        },
        {
          label: 'Delete',
          onClick: async () => {
            try {
              await props.deleteLookbook(lookbook);
            } catch (error) {
              cogoToast.error(error.message);
            }
          }
        }
      ]
    });
  };

  const requests = getGiftingRequestsForLookbook(analytics, lookbook);
  const userInviteCount = requests?.length || 0;
  const acceptedCount = requests?.filter(request => request.userAccepted)?.length || 0;
  const acceptedPercentage = userInviteCount > 0 ? Math.round((acceptedCount / userInviteCount) * 100) : 0;

  return (
    <div className='lookbook-preview-outer' onClick={redirectToLookbook}>
      <div className='lookbook-preview-background'>
        <img src={coverImageToUse} alt={lookbook.name} />
        <div className='lookbook-preview-background-overlay'></div>
      </div>
      <div className='lookbook-preview-inner'>
        <div className='options-panel' onClick={e => e.stopPropagation() && e.preventDefault()}>
          <div className='triple-dot'>
            <FontAwesomeIcon icon={faEllipsisH} onClick={() => setShowOptionsPanel(true)} />
            {showOptionsPanel && (
              <OutsideClickHandler onOutsideClick={() => setShowOptionsPanel(false)}>
                <div className='triple-dot-options'>
                  <div className='option' onClick={editLookbook}>
                    <FontAwesomeIcon icon={faEdit} />
                    Edit
                  </div>
                  {!acceptedCount && (
                    <div className='option' onClick={deleteLookbook}>
                      <FontAwesomeIcon icon={faTrash} />
                      Delete
                    </div>
                  )}
                  <div className='option' onClick={duplicateLookbook}>
                    <FontAwesomeIcon icon={faCopy} />
                    Duplicate
                  </div>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        </div>
        <div className='text-container'>
          {userInviteCount > 0 && (
            <div className='lookbook-data-container'>
              <div className='lookbook-data-item'>
                <div className='lookbook-data-item-value'>{userInviteCount}</div>
                <div className='lookbook-data-item-label'>sent</div>
              </div>
              <div className='lookbook-data-item'>
                <div className='lookbook-data-item-value'>{acceptedPercentage.toFixed()}%</div>
                <div className='lookbook-data-item-label'>accepted</div>
              </div>
            </div>
          )}
          <div className='lookbook-title'>{title || 'Untitled Lookbook'}</div>
          <div className='lookbook-subtitle'>
            <span>Created {getRelativePrettyDate(createdAt)}</span>
            {item_limit && (
              <>
                <span>&bull;</span>
                <span>{item_limit} item limit</span>
              </>
            )}
            {price_limit && (
              <>
                <span>&bull;</span>
                <span>${price_limit} price limit</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookPreview.propTypes = {
  analytics: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  deleteLookbook: PropTypes.func.isRequired,
  brand: PropTypes.object.isRequired,
  duplicateLookbook: PropTypes.func.isRequired
};

export default LookbookPreview;
