import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faInfoCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';

import { getDisplayForCollaborationType, getCollaborationTypeIcon, getRankingForCollaborationType } from '../../Helpers/collaboration_helpers';

import './CollaborationsPotentialTasks.scss';

const CollaborationsPotentialTasks = props => {
  const { groups, tasks, createTaskFromTemplate, removeAllTasksFromType, getTaskForTemplate } = props;
  const sortedGroups = _.orderBy(groups, templates => getRankingForCollaborationType(templates[0].type));
  const hasTasks = tasks && !!tasks.length;
  return (
    <div className='collaborations-potential-tasks-container'>
      <div className='potential-header-container'>
        <div className='potential-header'>{hasTasks ? 'Add More Offerings' : 'Build Your Collaborations Profile'}</div>
        <div className='potential-subheader'>
          Let brands know the types of partnerships that you're open to. Note that this tab is only visible to brands on the platform, it is not
          visible to the public. Add below the types of sponsored content you are interested in offering. Listing your baseline prices is recommended
          but optional.
        </div>
        {hasTasks ? (
          <Link to='/collaborations/new' className='potential-action'>
            <FontAwesomeIcon icon={faSearch} />
            Search For Brand Partners
          </Link>
        ) : (
          <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/collaborate-with-brands' className='potential-action'>
            <FontAwesomeIcon icon={faInfoCircle} />
            Learn More
          </a>
        )}
      </div>
      <div className='collaborations-potential-tasks'>
        {sortedGroups.map(templates => {
          const representativeTemplate = _.first(templates);
          const representativeTask = getTaskForTemplate(representativeTemplate);
          const { type, id } = representativeTemplate;
          const create = () => createTaskFromTemplate(representativeTemplate);
          const remove = () => removeAllTasksFromType(type);
          const display = getDisplayForCollaborationType(type);
          const img = getCollaborationTypeIcon(type);
          const active = !!representativeTask;
          return (
            <div className='collaborations-potential-task-container' key={id}>
              <div onClick={active ? remove : create} className={cn('collaborations-potential-task', { active, inactive: !active })}>
                <div className='main'>
                  <img className={type} src={img} alt={display} />
                  <div className='title'>{display}</div>
                  {active && <div className='badge'>ACTIVE</div>}
                </div>
                <div className='actions'>{representativeTask ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} />}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CollaborationsPotentialTasks.propTypes = {
  groups: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  createTaskFromTemplate: PropTypes.func.isRequired,
  removeAllTasksFromType: PropTypes.func.isRequired,
  getTaskForTemplate: PropTypes.func.isRequired
};

export default CollaborationsPotentialTasks;
