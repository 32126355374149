import _ from 'lodash';
import { fetchUsersOfType } from '../APIClient/users';

export const GET_INSIDER_USERS_SUCCESS = 'GET_INSIDER_USERS_SUCCESS';
export const GET_INSIDER_USERS_FAILURE = 'GET_INSIDER_USERS_FAILURE';

export const getInsiderUsers = () => async (dispatch, getState) => {
  const usersResp = await fetchUsersOfType('all_insiders');
  if (!usersResp) {
    return dispatch({
      type: GET_INSIDER_USERS_FAILURE,
      error: 'Failure fetching insiders'
    });
  }
  return dispatch({
    type: GET_INSIDER_USERS_SUCCESS,
    insiders: _.get(usersResp, 'users', [])
  });
};
