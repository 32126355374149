import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ReportSectionChart.scss';
import * as d3 from 'd3';
import { getPrettyNumber } from '../../Helpers/formatting';

const ReportSectionChart = props => {
  const { data } = props;
  const svgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const prevDimensionsRef = useRef(dimensions);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const element = entries[0].target; // observing single element

      if (element) {
        const { width, height } = element.getBoundingClientRect();
        setDimensions({ width, height });
      }
    });

    if (svgRef.current) {
      resizeObserver.observe(svgRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [svgRef]);

  useEffect(() => {
    const min = Math.min(...data);
    const max = Math.max(...data);
    const range = max - min;

    const xScale = d3
      .scaleLinear()
      .domain([0, data.length - 1])
      .range([0, dimensions.width]);

    const yScale = d3
      .scaleLinear()
      .domain([min, max])
      .range([dimensions.height, 0]);

    const svg = d3.select(svgRef.current);
    const line = d3
      .line()
      .x((d, i) => xScale(i))
      .y(d => yScale(d))
      .curve(d3.curveLinear);

    // Path data
    const pathD = line(data);
    let shouldAnimate = true;

    // do not animate on dimensions change
    if (
      (prevDimensionsRef.current.width && prevDimensionsRef.current.width !== dimensions.width) ||
      (prevDimensionsRef.current.height && prevDimensionsRef.current.height !== dimensions.height)
    ) {
      shouldAnimate = false;
    }

    prevDimensionsRef.current = dimensions;

    if (shouldAnimate) {
      // Remove any existing paths
      svg.selectAll('path').remove();

      // Draw line with animation
      const path = svg
        .append('path')
        .attr('d', pathD)
        .attr('fill', 'none')
        .attr('stroke', '#11835a')
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', function() {
          const length = this.getTotalLength(); // Get the length of the path
          return `${length} ${length}`;
        })
        .attr('stroke-dashoffset', function() {
          return this.getTotalLength();
        });

      path
        .transition()
        .duration(500) // half second draw
        .attr('stroke-dashoffset', 0);
    } else {
      // Redraw line without animation (dimensions changed)
      svg
        .selectAll('path')
        .data([data])
        .join('path')
        .attr('d', pathD)
        .attr('fill', 'none')
        .attr('stroke', '#11835a')
        .attr('stroke-width', 2);
    }

    const hoverLine = svg
      .append('line')
      .style('stroke', '#767676')
      .style('stroke-width', 1)
      .style('display', 'none');

    const hoverLabel = svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '14px')
      .style('display', 'none');

    const handleMouseMove = event => {
      const mouseX = d3.pointer(event)[0];
      const mouseY = d3.pointer(event)[1];
      const nearestIndex = Math.round(xScale.invert(mouseX));

      // clamp within bounds of data
      const clampedIndex = Math.max(0, Math.min(data.length - 1, nearestIndex));

      // ignore first and last data points, labels already show those
      if (clampedIndex === 0 || clampedIndex === data.length - 1) {
        return hideHoverElements();
      }

      const yValue = data[clampedIndex];
      const yCoordinate = yScale(yValue);

      hoverLine
        .attr('x1', xScale(clampedIndex))
        .attr('y1', yCoordinate)
        .attr('x2', xScale(clampedIndex))
        .attr('y2', mouseY)
        .style('display', null);

      hoverLabel
        .attr('x', xScale(clampedIndex))
        .attr('y', mouseY)
        .attr('dy', mouseY < yCoordinate ? '-8px' : '28px')
        .text(getPrettyNumber(yValue) ?? 0)
        .style('display', null);
    };

    const hideHoverElements = () => {
      hoverLine.style('display', 'none');
      hoverLabel.style('display', 'none');
    };

    // Add rect to capture mouse events
    svg
      .append('rect')
      .attr('width', dimensions.width)
      .attr('height', dimensions.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mousemove', handleMouseMove)
      .on('mouseout', hideHoverElements);

    const pointsForLabels = range === 0 ? [data[data.length - 1]] : [data[0], data[data.length - 1]];

    // Add labels for only the first and last data points
    svg
      .selectAll('.permanent-point-label')
      .data(pointsForLabels)
      .join('text')
      .attr('class', 'permanent-point-label')
      .attr('x', (d, i) => xScale(i === 0 ? 0 : data.length - 1))
      .attr('y', d => yScale(d))
      .attr('dx', (d, i) => (i === 0 ? '-4px' : '4px'))
      .attr('text-anchor', (d, i) => (i === 0 ? 'end' : 'start'))
      .text(d => getPrettyNumber(d || 0) ?? 0)
      .attr('font-size', '14px');
  }, [data, dimensions]);

  return (
    <div className='report-section-chart'>
      <svg width={'100%'} height={'100%'} ref={svgRef}></svg>
    </div>
  );
};

ReportSectionChart.propTypes = {
  data: PropTypes.array.isRequired
};

export default ReportSectionChart;
