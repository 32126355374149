import React from 'react';
import './EmptyShopResult.scss';

const EmptyShopResult = props => {
  return (
    <div className='shop-result-outer-container empty-shop-result-outer-container'>
      <div className='shop-result-container'>
        <div className='product-wrapper'>
          <div className='empty-img'>
            <div className='cover' />
          </div>
          <div className='product-data'>
            <h3 className='brand'>
              <div className='cover' />
            </h3>
            <h4 className='title'>
              <div className='cover' />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyShopResult;
