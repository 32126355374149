import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import './OpportunityListing.scss';

import {
  updateOpportunity,
  updateOpportunityLocallyForDebounceImmediateUpdate,
  createOpportunityPaymentTier,
  updateOpportunityPaymentTier,
  deleteOpportunityPaymentTier
} from '../../Actions/OpportunityActions';
import { updateOpportunityRequest } from '../../Actions/UserActions';
import { isBrand as isBrandCheck, getName } from '../../Helpers/user_helpers';
import { requestFeedback } from '../../Helpers/chat_helpers';
import {
  checkValidityOfOpportunity,
  isOpportunityRequestAccepted,
  isOpportunityRequestDismissed,
  isOpportunityRequestExpired,
  getStatusDataForOpportunityRequest,
  isOpportunityExperiential
} from '../../Helpers/opportunity_helpers';

import OpportunityListingHeader from './Elements/OpportunityListingHeader';
import OpportunityListingExplanation from './Elements/OpportunityListingExplanation';
import OpportunityListingUploads from './Elements/OpportunityListingUploads';
import OpportunityListingPaymentTiers from './Elements/OpportunityListingPaymentTiers';
import OpportunityListingExpectations from './Elements/OpportunityListingExpectations';
import OpportunityListingAdvancedSettings from './Elements/OpportunityListingAdvancedSettings';

const OpportunityListing = props => {
  const { user, analytics, opportunity, opportunityRequest, canEdit, isEditing, setIsEditing } = props;

  const isBrand = isBrandCheck(user);
  const isUser = !isBrandCheck(user);
  const isExperience = isOpportunityExperiential(opportunity);
  const history = useHistory();

  // Data Fields
  const [description, setDescription] = React.useState(opportunity.description || '');
  const [additionalPerks, setAdditionalPerks] = React.useState(opportunity.additionalPerks || '');
  const [guidelines, setGuidelines] = React.useState(opportunity.guidelines || '');
  const [daysToReviewUponCompletion, setDaysToReviewUponCompletion] = React.useState(opportunity.daysToReviewUponCompletion || '');
  const [acceptableDomains, setAcceptableDomains] = React.useState(opportunity.acceptableDomains || '');
  const [successMetrics, setSuccessMetrics] = React.useState(opportunity.successMetrics || '');
  const [maxBudget, setMaxBudget] = React.useState(opportunity.maxBudget || '');
  const [AutoSend_Lookbook_id, setAutoSendLookbook_id] = React.useState(opportunity.AutoSend_Lookbook_id || null);

  // Update local state once full sync returns
  const [hasLoadedFullOpportunity, setHasLoadedFullOpportunity] = React.useState(false);
  React.useEffect(() => {
    const hasFullOpportunity = 'description' in opportunity; // This is a way to check if we have the full opportunity data
    if (hasFullOpportunity && !hasLoadedFullOpportunity) {
      setDescription(opportunity.description);
      setGuidelines(opportunity.guidelines);
      setAdditionalPerks(opportunity.additionalPerks);
      setHasLoadedFullOpportunity(true);
    }
  }, [opportunity.description]);

  // Updating
  const debounces = React.useRef({});
  const updateFieldLocally = (field, value) => updateField(field, value, { localOnly: true });
  const updateField = (field, value, options) => {
    const { localOnly, debounceDuration } = options || {};
    if (field === 'description') setDescription(value);
    if (field === 'guidelines') setGuidelines(value);
    if (field === 'additionalPerks') setAdditionalPerks(value);
    if (field === 'daysToReviewUponCompletion') setDaysToReviewUponCompletion(value);
    if (field === 'maxBudget') setMaxBudget(value ? parseInt(value) : null);
    if (field === 'acceptableDomains') setAcceptableDomains(value);
    if (field === 'successMetrics') setSuccessMetrics(value);
    if (field === 'AutoSend_Lookbook_id') setAutoSendLookbook_id(value);

    clearTimeout(debounces.current[field]);
    !localOnly && props.updateOpportunityLocallyForDebounceImmediateUpdate(opportunity, { [field]: value });
    debounces.current[field] = setTimeout(() => {
      !localOnly && props.updateOpportunity(opportunity, { [field]: value });
    }, debounceDuration || 750);
  };
  const performBulkUpdates = updates => {
    Object.keys(updates).forEach(field => updateFieldLocally(field, updates[field]));
    props.updateOpportunity(opportunity, updates);
  };

  // Major Actions for Users
  const user_requestIsAccepted = opportunityRequest && isOpportunityRequestAccepted(opportunityRequest);
  const user_requestIsExpired = opportunityRequest && isOpportunityRequestExpired(opportunityRequest);
  const user_requestIsDismissed = opportunityRequest && isOpportunityRequestDismissed(opportunityRequest);
  const opportunityRequestStatus = opportunityRequest && getStatusDataForOpportunityRequest(opportunityRequest);
  if (isUser && !opportunityRequest) return <div>CANNOT ACCESSS THIS</div>;

  // Actions
  const dismissRequest = e => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to dismiss this opportunity?`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            props.updateOpportunityRequest(opportunityRequest, { userRejected: true });
            requestFeedback({
              header: 'Feedback Requested',
              subheader: `The ShopMy team would like to understand why you dismissed this opportunity so we can make this experience better for you. We will not share this with ${opportunityRequest.brand.name}.`,
              delay: 500,
              messagePrefix: `${getName(user)} provided feedback on why they rejected "${opportunityRequest.opportunity.title}" from ${
                opportunityRequest.brand.name
              }`
            });
          }
        }
      ]
    });
  };

  const acceptRequest = e => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to accept this opportunity?`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => props.updateOpportunityRequest(opportunityRequest, { userAccepted: true, userAcceptedAt: new Date() }, history)
        }
      ]
    });
  };

  const undoDismissal = e => {
    props.updateOpportunityRequest(opportunityRequest, { userRejected: false, userRejectedAt: null, userAccepted: false, userAcceptedAt: null });
  };

  // UI updates
  const invalidFields = isBrand ? checkValidityOfOpportunity(opportunity, user).invalidFields : [];
  const isFieldInvalid = field => invalidFields.includes(field);
  const toggleEditing = () => setIsEditing(!isEditing);

  return (
    <div className={cn('opportunity-listing-outer-container', { editing: isEditing })}>
      <div className={cn('opportunity-listing-section-card', { editing: isEditing })}>
        <OpportunityListingHeader
          user={user}
          opportunity={opportunity}
          opportunityRequest={opportunityRequest}
          isEditing={isEditing}
          canEdit={canEdit}
          updateField={updateField}
          performBulkUpdates={performBulkUpdates}
          isFieldInvalid={isFieldInvalid}
        />
        <OpportunityListingExplanation
          user={user}
          analytics={analytics}
          opportunity={opportunity}
          opportunityRequest={opportunityRequest}
          isEditing={isEditing}
          canEdit={canEdit}
          description={isEditing ? description : opportunity.description}
          guidelines={isEditing ? guidelines : opportunity.guidelines}
          additionalPerks={isEditing ? additionalPerks : opportunity.additionalPerks}
          AutoSend_Lookbook_id={AutoSend_Lookbook_id}
          updateField={updateField}
          hasLoadedFullOpportunity={hasLoadedFullOpportunity}
        />
        <OpportunityListingUploads opportunity={opportunity} isEditing={isEditing} canEdit={canEdit} />
        {canEdit && (
          <OpportunityListingPaymentTiers
            user={user}
            analytics={analytics}
            canEdit={canEdit}
            isEditing={isEditing}
            opportunity={opportunity}
            toggleEditing={toggleEditing}
            createOpportunityPaymentTier={props.createOpportunityPaymentTier}
            updateOpportunityPaymentTier={props.updateOpportunityPaymentTier}
            deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
          />
        )}
        {(!isExperience || isEditing) && (
          <OpportunityListingExpectations
            user={user}
            analytics={analytics}
            opportunity={opportunity}
            opportunityRequest={opportunityRequest}
            isEditing={isEditing}
            canEdit={canEdit}
            updateField={updateField}
          />
        )}
      </div>
      {canEdit && isEditing && (
        <div className='opportunity-listing-section-card'>
          <OpportunityListingAdvancedSettings
            isEditing={isEditing}
            canEdit={canEdit}
            opportunity={opportunity}
            maxBudget={maxBudget}
            daysToReviewUponCompletion={daysToReviewUponCompletion}
            acceptableDomains={acceptableDomains}
            successMetrics={successMetrics}
            updateField={updateField}
          />
        </div>
      )}
      <div className='opportunity-listing-action-buttons'>
        {isUser && (
          <Link to={`/chat?query=${opportunityRequest.brand.name}`} className='opportunity-listing-action-button secondary'>
            Chat with {opportunityRequest?.brand?.name || 'Brand'}
          </Link>
        )}
        {isUser ? (
          user_requestIsAccepted || user_requestIsDismissed ? (
            window.__ADMIN_CONTROL_MODE__ ? (
              <>
                <div onClick={undoDismissal} className='opportunity-listing-action-button secondary'>
                  Undo Response
                </div>
              </>
            ) : (
              <>
                <div className='opportunity-listing-action-button text disabled'>{opportunityRequestStatus.actionDisplay}</div>
              </>
            )
          ) : !user_requestIsExpired ? (
            <>
              <div onClick={dismissRequest} className='opportunity-listing-action-button secondary'>
                Dismiss
              </div>
              <div onClick={acceptRequest} className='opportunity-listing-action-button primary'>
                Accept Opportunity
              </div>
            </>
          ) : (
            <div>
              <div className='opportunity-listing-action-button text disabled'>{opportunityRequestStatus.actionDisplay}</div>
            </div>
          )
        ) : (
          canEdit && (
            <div onClick={toggleEditing} className='opportunity-listing-action-button'>
              {isEditing ? 'Done' : 'Edit Opportunity'}
            </div>
          )
        )}
      </div>
    </div>
  );
};

OpportunityListing.propTypes = {
  // Inside
  analytics: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  updateOpportunityLocallyForDebounceImmediateUpdate: PropTypes.func.isRequired,

  // Outside
  opportunity: PropTypes.object.isRequired,
  opportunityRequest: PropTypes.object, // If user
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { analytics, user } = state;
  return { analytics, user };
};

export default connect(mapStateToProps, {
  updateOpportunity,
  updateOpportunityRequest,
  updateOpportunityLocallyForDebounceImmediateUpdate,
  createOpportunityPaymentTier,
  updateOpportunityPaymentTier,
  deleteOpportunityPaymentTier
})(OpportunityListing);
