import {
  getContractTaskTemplates as getContractTaskTemplatesAPI,
  createUserContractTask as createUserContractTaskAPI,
  updateUserContractTask as updateUserContractTaskAPI,
  deleteUserContractTask as deleteUserContractTaskAPI,
  getContract as getContractAPI,
  getContractAnalytics as getContractAnalyticsAPI,
  createContract as createContractAPI,
  createContractFromTask as createContractFromTaskAPI,
  updateContract as updateContractAPI,
  createContractTaskFromUserTask as createContractTaskFromUserTaskAPI,
  createContractTaskFromTaskTemplate as createContractTaskFromTaskTemplateAPI,
  updateContractTask as updateContractTaskAPI,
  deleteContractTask as deleteContractTaskAPI,
  createContractLink as createContractLinkAPI,
  updateContractLink as updateContractLinkAPI,
  deleteContractLink as deleteContractLinkAPI,
  createContractUpload as createContractUploadAPI,
  updateContractUpload as updateContractUploadAPI,
  deleteContractUpload as deleteContractUploadAPI,
  createContractRateCode as createContractRateCodeAPI,
  updateContractRateCode as updateContractRateCodeAPI,
  deleteContractRateCode as deleteContractRateCodeAPI
} from '../APIClient/collaborations';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getBrandId, getUserId, getContracts, getBrandContracts, hasProvenPromotionAbility, getBrand } from '../Helpers/user_helpers';
import { isTask } from '../Helpers/collaboration_helpers';
import { isSubscribedToFeature, blockOnRequiringSubscription } from '../Helpers/subscription_helpers';

export const GET_CONTRACT_TASK_TEMPLATES_SUCCESS = 'GET_CONTRACT_TASK_TEMPLATES_SUCCESS';
export const GET_CONTRACT_TASK_TEMPLATES_FAILURE = 'GET_CONTRACT_TASK_TEMPLATES_FAILURE';

export const CREATE_USER_CONTRACT_TASK_SUCCESS = 'CREATE_USER_CONTRACT_TASK_SUCCESS';
export const CREATE_USER_CONTRACT_TASK_FAILURE = 'CREATE_USER_CONTRACT_TASK_FAILURE';

export const UPDATE_USER_CONTRACT_TASK_SUCCESS = 'UPDATE_USER_CONTRACT_TASK_SUCCESS';
export const UPDATE_USER_CONTRACT_TASK_FAILURE = 'UPDATE_USER_CONTRACT_TASK_FAILURE';

export const DELETE_USER_CONTRACT_TASK_SUCCESS = 'DELETE_USER_CONTRACT_TASK_SUCCESS';
export const DELETE_USER_CONTRACT_TASK_FAILURE = 'DELETE_USER_CONTRACT_TASK_FAILURE';

export const GET_CONTRACT_REQUEST = 'GET_CONTRACT_REQUEST';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';

export const GET_CONTRACT_ANALYTICS_SUCCESS = 'GET_CONTRACT_ANALYTICS_SUCCESS';
export const GET_CONTRACT_ANALYTICS_FAILURE = 'GET_CONTRACT_ANALYTICS_FAILURE';

export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_NEVERMIND = 'CREATE_CONTRACT_NEVERMIND';
export const CREATE_CONTRACT_BLOCKED = 'CREATE_CONTRACT_BLOCKED';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';

export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILURE = 'UPDATE_CONTRACT_FAILURE';

export const CREATE_CONTRACT_TASK_SUCCESS = 'CREATE_CONTRACT_TASK_SUCCESS';
export const CREATE_CONTRACT_TASK_FAILURE = 'CREATE_CONTRACT_TASK_FAILURE';
export const UPDATE_CONTRACT_TASK_SUCCESS = 'UPDATE_CONTRACT_TASK_SUCCESS';
export const UPDATE_CONTRACT_TASK_FAILURE = 'UPDATE_CONTRACT_TASK_FAILURE';
export const DELETE_CONTRACT_TASK_REQUEST = 'DELETE_CONTRACT_TASK_REQUEST';
export const DELETE_CONTRACT_TASK_SUCCESS = 'DELETE_CONTRACT_TASK_SUCCESS';
export const DELETE_CONTRACT_TASK_FAILURE = 'DELETE_CONTRACT_TASK_FAILURE';

export const CREATE_CONTRACT_LINK_SUCCESS = 'CREATE_CONTRACT_LINK_SUCCESS';
export const CREATE_CONTRACT_LINK_FAILURE = 'CREATE_CONTRACT_LINK_FAILURE';
export const UPDATE_CONTRACT_LINK_SUCCESS = 'UPDATE_CONTRACT_LINK_SUCCESS';
export const UPDATE_CONTRACT_LINK_FAILURE = 'UPDATE_CONTRACT_LINK_FAILURE';
export const DELETE_CONTRACT_LINK_REQUEST = 'DELETE_CONTRACT_LINK_REQUEST';
export const DELETE_CONTRACT_LINK_SUCCESS = 'DELETE_CONTRACT_LINK_SUCCESS';
export const DELETE_CONTRACT_LINK_FAILURE = 'DELETE_CONTRACT_LINK_FAILURE';

export const CREATE_CONTRACT_UPLOAD_SUCCESS = 'CREATE_CONTRACT_UPLOAD_SUCCESS';
export const CREATE_CONTRACT_UPLOAD_FAILURE = 'CREATE_CONTRACT_UPLOAD_FAILURE';
export const UPDATE_CONTRACT_UPLOAD_SUCCESS = 'UPDATE_CONTRACT_UPLOAD_SUCCESS';
export const UPDATE_CONTRACT_UPLOAD_FAILURE = 'UPDATE_CONTRACT_UPLOAD_FAILURE';
export const DELETE_CONTRACT_UPLOAD_REQUEST = 'DELETE_CONTRACT_UPLOAD_REQUEST';
export const DELETE_CONTRACT_UPLOAD_SUCCESS = 'DELETE_CONTRACT_UPLOAD_SUCCESS';
export const DELETE_CONTRACT_UPLOAD_FAILURE = 'DELETE_CONTRACT_UPLOAD_FAILURE';

export const CREATE_CONTRACT_RATE_CODE_SUCCESS = 'CREATE_CONTRACT_RATE_CODE_SUCCESS';
export const CREATE_CONTRACT_RATE_CODE_FAILURE = 'CREATE_CONTRACT_RATE_CODE_FAILURE';
export const UPDATE_CONTRACT_RATE_CODE_SUCCESS = 'UPDATE_CONTRACT_RATE_CODE_SUCCESS';
export const UPDATE_CONTRACT_RATE_CODE_FAILURE = 'UPDATE_CONTRACT_RATE_CODE_FAILURE';
export const DELETE_CONTRACT_RATE_CODE_REQUEST = 'DELETE_CONTRACT_RATE_CODE_REQUEST';
export const DELETE_CONTRACT_RATE_CODE_SUCCESS = 'DELETE_CONTRACT_RATE_CODE_SUCCESS';
export const DELETE_CONTRACT_RATE_CODE_FAILURE = 'DELETE_CONTRACT_RATE_CODE_FAILURE';

/* Contracts */
export const getContract = (id, options = {}) => async (dispatch, getState) => {
  const { noReset } = options;
  try {
    !noReset && dispatch({ type: GET_CONTRACT_REQUEST });
    const contract = await getContractAPI(id);
    return dispatch({
      type: GET_CONTRACT_SUCCESS,
      contract
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: GET_CONTRACT_FAILURE,
      error
    });
  }
};

export const getContractAnalytics = (id, options = {}) => async (dispatch, getState) => {
  /*
    Augment the visibleContract object with analytics.
    We keep this as a separate call because it takes a bit of time to return.
  */
  try {
    const resp = await getContractAnalyticsAPI(id);
    return dispatch({
      id,
      type: GET_CONTRACT_ANALYTICS_SUCCESS,
      analytics: resp.analytics
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: GET_CONTRACT_ANALYTICS_FAILURE,
      error
    });
  }
};

export const createContractWithUser = User_id => async (dispatch, getState) => {
  /*
    Brand => User

    Brands can create generic collaboration proposals via Chat or the "Create Custom" button on the shop
    collaborations tab.
  */
  const confirmNoneExisting = () =>
    new Promise((resolve, reject) => {
      const contracts = getBrandContracts(getState().user);
      const existing = contracts.find(c => c.User_id === User_id && ['pending', 'proposed'].includes(c.status));
      if (!existing) return resolve();

      confirmAlert({
        title: 'Found a proposal',
        message: existing.isCreatedByUser
          ? `You have an existing proposal from ${existing.user?.name ||
              'this creator'}. If you want to make changes to that proposal please discuss with them over chat and they can make edits, or you can dismiss their initial proposal and create a new one.`
          : 'We found an existing proposal, would you like to edit that or create a new one?',
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => {
              reject();
            }
          },
          {
            label: 'Create New',
            className: 'cancel',
            onClick: () => {
              resolve();
            }
          },
          {
            label: 'Go To Existing',
            onClick: () => {
              window.location.href = `/collaboration/${existing.id}`;
              reject();
            }
          }
        ]
      });
    });

  if (blockOnRequiringSubscription(getState().user, 'COLLABORATE'))
    return dispatch({
      type: CREATE_CONTRACT_NEVERMIND
    });

  try {
    await confirmNoneExisting();
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_NEVERMIND
    });
  }

  try {
    const contract = await createContractAPI({
      User_id,
      Brand_id: getBrandId(getState().user),
      isCreatedByBrand: true
    });
    return dispatch({
      type: CREATE_CONTRACT_SUCCESS,
      contract: contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_FAILURE,
      error
    });
  }
};

export const createContractWithBrand = Brand_id => async (dispatch, getState) => {
  /*
    User => Brand

    Users can create contracts with brands via the Chat panel or via the Collaborations page.

    We must ensure that:
    
      1) They are creating a contract with a promoter (if access package)
  */
  const confirmCanPropose = async () => {
    const canPromote = hasProvenPromotionAbility(getState().user, Brand_id);
    if (!canPromote) {
      throw new Error(
        `You must reach the Trendsetter tier before you can create collaboration proposals. Go to your "Account Settings" for more detail on how tiers work.`
      );
    }
  };

  const confirmNoneExisting = () =>
    new Promise((resolve, reject) => {
      const contracts = getContracts(getState().user);
      const existing = contracts.find(c => c.Brand_id === Brand_id && ['pending', 'proposed'].includes(c.status));
      if (!existing) return resolve();

      confirmAlert({
        title: 'Found a proposal',
        message: existing.isCreatedByBrand
          ? `You have an existing proposal from ${existing.brand?.name ||
              'this brand'}. If you want to make changes to that proposal please discuss with them over chat and they can make edits, or you can dismiss their initial proposal and create a new one.`
          : 'We found an existing proposal, would you like to edit that or create a new one?',
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => {
              reject();
            }
          },
          {
            label: 'Create New',
            className: 'cancel',
            onClick: () => {
              resolve();
            }
          },
          {
            label: 'Go To Existing',
            onClick: () => {
              window.location.href = `/collaboration/${existing.id}`;
              reject();
            }
          }
        ]
      });
    });

  try {
    await confirmCanPropose();
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_BLOCKED,
      error: error.message
    });
  }

  try {
    await confirmNoneExisting();
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_NEVERMIND
    });
  }

  try {
    const contract = await createContractAPI({
      Brand_id,
      User_id: getUserId(getState().user),
      isCreatedByUser: true
    });
    return dispatch({
      type: CREATE_CONTRACT_SUCCESS,
      contract: contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_FAILURE,
      error
    });
  }
};

export const createContractFromTask = task => async (dispatch, getState) => {
  /*
    Brand => User

    Brands can click on User Tasks to create new contracts with one task automatically selected.

    We must ensure that:

      1) They are creating a contract with a promoter (if access package)
  */
  const confirmCanPropose = async () => {
    if (isSubscribedToFeature(getState().user, 'COLLABORATE')) return;
    throw new Error(`Please sign up for the campaigns feature to propose collaborations to talent.`);
  };

  try {
    await confirmCanPropose();
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_BLOCKED,
      error: error.message
    });
  }

  const confirmNoneExisting = () =>
    new Promise((resolve, reject) => {
      const contracts = getBrandContracts(getState().user);
      const existing = contracts.find(c => c.User_id === task.User_id && ['pending', 'proposed'].includes(c.status));
      if (!existing) return resolve();

      confirmAlert({
        title: 'Found a proposal',
        message: existing.isCreatedByUser
          ? `You have an existing proposal from ${existing.user?.name ||
              'this creator'}. If you want to make changes to that proposal please discuss with them over chat and they can make edits, or you can dismiss their initial proposal and create a new one.`
          : 'We found an existing proposal, would you like to edit that or create a new one?',
        buttons: [
          {
            label: 'Cancel',
            className: 'cancel',
            onClick: () => {
              reject();
            }
          },
          {
            label: 'Create New',
            className: 'cancel',
            onClick: () => {
              resolve();
            }
          },
          {
            label: 'Go To Existing',
            onClick: () => {
              window.location.href = `/collaboration/${existing.id}`;
              reject();
            }
          }
        ]
      });
    });

  try {
    await confirmNoneExisting();
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_NEVERMIND
    });
  }

  try {
    const contract = await createContractFromTaskAPI({
      Task_id: task?.id,
      Brand_id: getBrandId(getState().user),
      isCreatedByBrand: true
    });
    return dispatch({
      type: CREATE_CONTRACT_SUCCESS,
      contract: contract
    });
  } catch (error) {
    console.error(error);
    return dispatch({
      type: CREATE_CONTRACT_FAILURE,
      error
    });
  }
};

export const updateContract = (contract, updates) => async (dispatch, getState) => {
  try {
    const newContract = await updateContractAPI(contract, updates);
    return dispatch({
      type: UPDATE_CONTRACT_SUCCESS,
      contract: newContract
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CONTRACT_FAILURE,
      error
    });
  }
};

/* User Contract Tasks */
export const getContractTaskTemplates = options => async (dispatch, getState) => {
  try {
    const templates = await getContractTaskTemplatesAPI();
    return dispatch({
      type: GET_CONTRACT_TASK_TEMPLATES_SUCCESS,
      templates: templates || []
    });
  } catch (error) {
    return dispatch({
      type: GET_CONTRACT_TASK_TEMPLATES_FAILURE
    });
  }
};

export const createUserContractTask = data => async (dispatch, getState) => {
  try {
    const task = await createUserContractTaskAPI(data);
    return dispatch({
      type: CREATE_USER_CONTRACT_TASK_SUCCESS,
      task: task
    });
  } catch (error) {
    return dispatch({
      type: CREATE_USER_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

export const updateUserContractTask = (task, updates) => async (dispatch, getState) => {
  try {
    const newTask = await updateUserContractTaskAPI(task, updates);
    return dispatch({
      type: UPDATE_USER_CONTRACT_TASK_SUCCESS,
      task: newTask
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_USER_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

export const deleteUserContractTask = task => async (dispatch, getState) => {
  try {
    await deleteUserContractTaskAPI(task);
    return dispatch({
      type: DELETE_USER_CONTRACT_TASK_SUCCESS,
      task
    });
  } catch (error) {
    return dispatch({
      type: DELETE_USER_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

/* Contract Tasks */
export const createContractTask = (userTaskOrTaskTemplate, contract) => async (dispatch, getState) => {
  try {
    const newContractTask = isTask(userTaskOrTaskTemplate)
      ? await createContractTaskFromUserTaskAPI(userTaskOrTaskTemplate, contract)
      : await createContractTaskFromTaskTemplateAPI(userTaskOrTaskTemplate, contract);
    return dispatch({
      type: CREATE_CONTRACT_TASK_SUCCESS,
      task: newContractTask,
      contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

export const updateContractTask = (task, updates) => async (dispatch, getState) => {
  try {
    const newContractTask = await updateContractTaskAPI(task, updates);
    return dispatch({
      type: UPDATE_CONTRACT_TASK_SUCCESS,
      task: newContractTask
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

export const deleteContractTask = task => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CONTRACT_TASK_REQUEST,
      task
    });
    await deleteContractTaskAPI(task);
    return dispatch({
      type: DELETE_CONTRACT_TASK_SUCCESS,
      task
    });
  } catch (error) {
    return dispatch({
      type: DELETE_CONTRACT_TASK_FAILURE,
      error
    });
  }
};

/* Contract Links */
export const createContractLink = (data, contract) => async (dispatch, getState) => {
  // data = {url, title, image, Product_id}
  try {
    const newLink = await createContractLinkAPI(data, contract);
    return dispatch({
      type: CREATE_CONTRACT_LINK_SUCCESS,
      link: newLink,
      contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_LINK_FAILURE,
      error
    });
  }
};

export const updateContractLink = (link, updates) => async (dispatch, getState) => {
  try {
    const newLink = await updateContractLinkAPI(link, updates);
    return dispatch({
      type: UPDATE_CONTRACT_LINK_SUCCESS,
      link: newLink
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CONTRACT_LINK_FAILURE,
      error
    });
  }
};

export const deleteContractLink = link => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CONTRACT_LINK_REQUEST,
      link
    });
    await deleteContractLinkAPI(link);
    return dispatch({
      type: DELETE_CONTRACT_LINK_SUCCESS,
      link
    });
  } catch (error) {
    return dispatch({
      type: DELETE_CONTRACT_LINK_FAILURE,
      error
    });
  }
};

/* Contract Uploads */
export const createContractUpload = (data, contract) => async (dispatch, getState) => {
  // data = {url, title, image, Product_id}
  try {
    const newUpload = await createContractUploadAPI(data, contract);
    return dispatch({
      type: CREATE_CONTRACT_UPLOAD_SUCCESS,
      upload: newUpload,
      contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_UPLOAD_FAILURE,
      error
    });
  }
};

export const updateContractUpload = (upload, updates) => async (dispatch, getState) => {
  try {
    const newUpload = await updateContractUploadAPI(upload, updates);
    return dispatch({
      type: UPDATE_CONTRACT_UPLOAD_SUCCESS,
      upload: newUpload
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CONTRACT_UPLOAD_FAILURE,
      error
    });
  }
};

export const deleteContractUpload = upload => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CONTRACT_UPLOAD_REQUEST,
      upload
    });
    await deleteContractUploadAPI(upload);
    return dispatch({
      type: DELETE_CONTRACT_UPLOAD_SUCCESS,
      upload
    });
  } catch (error) {
    return dispatch({
      type: DELETE_CONTRACT_UPLOAD_FAILURE,
      error
    });
  }
};

/* Contract Rates Codes */
export const createContractRateCode = (data, contract) => async (dispatch, getState) => {
  // data = {codeRate, codeIsPercentage, codeCurrency, codeDisplayText, rateRate}
  try {
    const newRateCode = await createContractRateCodeAPI(data, contract);
    return dispatch({
      type: CREATE_CONTRACT_RATE_CODE_SUCCESS,
      rate_code: newRateCode,
      contract
    });
  } catch (error) {
    return dispatch({
      type: CREATE_CONTRACT_RATE_CODE_FAILURE,
      error
    });
  }
};

export const updateContractRateCode = (rate_code, updates) => async (dispatch, getState) => {
  try {
    const newRateCode = await updateContractRateCodeAPI(rate_code, updates);
    return dispatch({
      type: UPDATE_CONTRACT_RATE_CODE_SUCCESS,
      rate_code: newRateCode
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_CONTRACT_RATE_CODE_FAILURE,
      error
    });
  }
};

export const deleteContractRateCode = rate_code => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_CONTRACT_RATE_CODE_REQUEST,
      rate_code
    });
    await deleteContractRateCodeAPI(rate_code);
    return dispatch({
      type: DELETE_CONTRACT_RATE_CODE_SUCCESS,
      rate_code
    });
  } catch (error) {
    return dispatch({
      type: DELETE_CONTRACT_RATE_CODE_FAILURE,
      error
    });
  }
};
