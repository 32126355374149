export const SHOPIFY_INVENTORY_BEHAVIOR_OPTIONS = [
  {
    value: 'decrement_obeying_policy',
    label: 'Decrement obeying policy',
    sublabel: "Follow the product's Shopify inventory policy and claim inventory if possible. Default behavior."
  },
  {
    value: 'decrement_ignoring_policy',
    label: 'Decrement ignoring policy',
    sublabel: "Ignore the product's Shopify inventory policy and claim inventory even if out of stock."
  },
  {
    value: 'bypass',
    label: 'Bypass',
    sublabel:
      'Bypasses some Shopify inventory checks but still requires inventory to be available. However, this setting does not claim the inventory.'
  }
];
