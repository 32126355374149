import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './OpportunityPlans.scss';

import {
  updateOpportunity,
  createOpportunityPlan,
  updateOpportunityPlan,
  deleteOpportunityPlan,
  createOpportunityPaymentTier,
  deleteOpportunityPaymentTier,
  updateOpportunityPlanUser,
  deleteOpportunityPlanUser
} from '../../Actions/OpportunityActions';
import { openArtistModal, openRequestModal } from '../../Actions/UIActions';
import { getOpportunityRequestsForOpportunity } from '../../Helpers/brand_helpers';

import OpportunityPlanHeader from './Elements/OpportunityPlanHeader';
import OpportunityPlanTalent from './Elements/OpportunityPlanTalent';
import OpportunityPlanPlanningOptions from './Elements/OpportunityPlanPlanningOptions';

const OpportunityPlans = props => {
  const { user, opportunity, activePlan, setActivePlan } = props;
  const { plans } = opportunity;

  const removeActivePlan = () => removePlan(activePlan);
  const removePlan = plan => {
    props.deleteOpportunityPlan(plan);
    setActivePlan(_.first(plans.filter(p => p.id !== plan.id)));
  };

  const [isExecutingOnActivePlan, setIsExecutingOnActivePlan] = React.useState(false);
  const executeOnActivePlan = async () => {
    if (isExecutingOnActivePlan) return;
    setIsExecutingOnActivePlan(true);

    // Update the expectations to the same as the plan
    await props.updateOpportunity(opportunity, {
      linksExpected: activePlan.linksExpected || 0,
      mentionsExpected: activePlan.mentionsExpected || 0,
      linkingDaysExpected: activePlan.linkingDaysExpected || 0,
      mentionDaysExpected: activePlan.mentionDaysExpected || 0
    });

    // Get non-backup users without an existing request
    const allRequestsForOpportunity = getOpportunityRequestsForOpportunity(props.analytics, opportunity);
    const planUsers = activePlan.users.filter(planUser => !planUser.isBackup && !allRequestsForOpportunity.find(r => r.User_id === planUser.User_id));

    // Establish which tiers we need to add
    let newTiersNeeded = [];
    planUsers.forEach(user => {
      const hasTier = opportunity.payment_tiers.find(tier => tier.fixedFee === user.recommendedFixedFee);
      if (!hasTier) newTiersNeeded.push({ fixedFee: user.recommendedFixedFee, Opportunity_id: opportunity.id });
    });
    newTiersNeeded = _.uniqBy(newTiersNeeded, 'fixedFee');

    // Add those tiers
    let failedTierCreation = false;
    const newTiersAdded = [];
    await Promise.all(
      newTiersNeeded.map(async tier => {
        const resp = await props.createOpportunityPaymentTier(tier);
        if (!resp.payment_tier) failedTierCreation = true;
        else newTiersAdded.push(resp.payment_tier);
      })
    );

    // Need to delete the ones we created since they all didn't work
    const deleteRecentlyAdded = async () => Promise.all(newTiersAdded.map(tier => props.deleteOpportunityPaymentTier(tier)));
    if (failedTierCreation) {
      await deleteRecentlyAdded();
      window.ALERT.error(`We had an issue, please try again.`);
      return setIsExecutingOnActivePlan(false);
    }

    // Now we need to open the modal with the new users
    const talentForModal = planUsers.map(planUser => {
      const payment_tier =
        newTiersAdded.find(tier => tier.fixedFee === planUser.recommendedFixedFee) ||
        opportunity.payment_tiers.find(tier => tier.fixedFee === planUser.recommendedFixedFee);

      return {
        id: planUser.User_id,
        name: planUser.user.name,
        image: planUser.user.image,
        OpportunityPaymentTier_id: payment_tier.id
      };
    });

    props.openRequestModal({
      params: {
        Opportunity_id: opportunity.id,
        type: 'opportunities',
        preselectedUsers: talentForModal
      },
      closeCallback: deleteRecentlyAdded
    });

    setIsExecutingOnActivePlan(false);
  };

  return (
    <div className='opportunity-plans-container'>
      {activePlan ? (
        <>
          <div className='opportunity-plan-header-container'>
            <OpportunityPlanHeader
              opportunity={opportunity}
              user={user}
              plans={plans}
              activePlan={activePlan}
              openNewPlanModal={props.openNewPlanModal}
              createOpportunityPlan={props.createOpportunityPlan}
              updateOpportunityPlan={props.updateOpportunityPlan}
              setActivePlan={setActivePlan}
              removePlan={removePlan}
              openPlanner={props.openPlanner}
            />
          </div>
          <div className='opportunity-plan-results-container'>
            <OpportunityPlanTalent
              user={user}
              analytics={props.analytics}
              opportunity={opportunity}
              activePlan={activePlan}
              createOpportunityPaymentTier={props.createOpportunityPaymentTier}
              deleteOpportunityPaymentTier={props.deleteOpportunityPaymentTier}
              openRequestModal={props.openRequestModal}
              removeActivePlan={removeActivePlan}
              executeOnActivePlan={executeOnActivePlan}
              isExecutingOnActivePlan={isExecutingOnActivePlan}
              openArtistModal={props.openArtistModal}
              updateOpportunityPlanUser={props.updateOpportunityPlanUser}
              deleteOpportunityPlanUser={props.deleteOpportunityPlanUser}
              openNewPlanModal={props.openNewPlanModal}
              openPlanner={props.openPlanner}
            />
          </div>
        </>
      ) : (
        <OpportunityPlanPlanningOptions openPlanner={props.openPlanner} openSmartPlanner={props.openNewPlanModal} />
      )}
    </div>
  );
};

OpportunityPlans.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  updateOpportunity: PropTypes.func.isRequired,
  updateOpportunityPlan: PropTypes.func.isRequired,
  createOpportunityPlan: PropTypes.func.isRequired,
  deleteOpportunityPlan: PropTypes.func.isRequired,
  createOpportunityPaymentTier: PropTypes.func.isRequired,
  deleteOpportunityPaymentTier: PropTypes.func.isRequired,
  updateOpportunityPlanUser: PropTypes.func.isRequired,
  deleteOpportunityPlanUser: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,

  // From outside
  opportunity: PropTypes.object.isRequired,
  openNewPlanModal: PropTypes.func.isRequired,
  openPlanner: PropTypes.func.isRequired,
  activePlan: PropTypes.object,
  setActivePlan: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  updateOpportunity,
  createOpportunityPlan,
  updateOpportunityPlan,
  deleteOpportunityPlan,
  updateOpportunityPlanUser,
  deleteOpportunityPlanUser,
  createOpportunityPaymentTier,
  deleteOpportunityPaymentTier,
  openArtistModal,
  openRequestModal
})(OpportunityPlans);
