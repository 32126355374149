import APIClient from './index';
import _ from 'lodash';

import { getUserId, getBrandId } from '../Helpers/user_helpers';

export const goToStripeBillingDashboard = user => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Stripe/create-brand-portal-session`, { Brand_id: getBrandId(user), return_url: window.location.href })
      .then(response => resolve(response.data))
      .catch(error => reject(error?.data || error));
  });
};

export const connectStripeAccount = User_id => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Stripe/connect`, { User_id })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const goToStripeOnboarding = user => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Stripe/create-user-onboarding-session`, { User_id: getUserId(user), return_url: window.location.href })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getStripeOnboardingStatus = user => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Stripe/get-user-onboarding-status`, { User_id: getUserId(user) })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
