import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomeCreatorAnimationPhones.scss';

import { HOME_CREATOR_ANIMATION_STEPS } from '../../../../Helpers/home_helpers';

const HomeCreatorAnimationPhones = props => {
  const { stepInFocus } = props;

  const nonFixedPhonesRefStart = React.useRef(null);
  const fixedPhonesRef = React.useRef(null);
  const nonFixedPhonesRefEnd = React.useRef(null);

  const [isZoomed, setIsZoomed] = React.useState(false);
  const scrollHandler = () => {
    const topOfNonFixed = nonFixedPhonesRefStart.current.getBoundingClientRect().top;
    const topOfFixed = fixedPhonesRef.current.getBoundingClientRect().top;
    const bottomOfFixed = fixedPhonesRef.current.getBoundingClientRect().bottom;
    const bottomOfNonFixed = nonFixedPhonesRefEnd.current.getBoundingClientRect().bottom;

    const isPastStart = topOfNonFixed <= topOfFixed;
    const isPastEnd = bottomOfNonFixed <= bottomOfFixed;

    if (isZoomed) {
      if (!isPastStart || isPastEnd) setIsZoomed(false);
    } else {
      if (isPastStart && !isPastEnd) setIsZoomed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [isZoomed]);

  const images = [
    [
      'https://static.shopmy.us/Home/Creators/Home_Phone_1a.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_1b.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_1c.png'
    ],
    [
      'https://static.shopmy.us/Home/Creators/Home_Phone_2a.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_2b.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_2c.png'
    ],
    [
      'https://static.shopmy.us/Home/Creators/Home_Phone_3a.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_3b.png',
      'https://static.shopmy.us/Home/Creators/Home_Phone_3c.png'
    ]
  ];

  const isZoomedMode = isZoomed;

  const additionalClasses = { zoomed: isZoomed };
  return (
    <>
      <div className={cn('home-creator-animation-phones-outer-container start', additionalClasses)}>
        {/* Non Fixed */}
        <div ref={nonFixedPhonesRefStart} className={cn('phones', additionalClasses, { visible: !isZoomedMode })}>
          {images[0].map((image, idx) => (
            <div key={idx} className={cn('phone', additionalClasses)}>
              <img className='phone-image visible' src={image} alt='phone' />
            </div>
          ))}
        </div>
      </div>
      <div className={cn('home-creator-animation-phones-outer-container fixed', additionalClasses)}>
        {/* Fixed */}
        <div ref={fixedPhonesRef} className={cn('phones', additionalClasses, { visible: isZoomedMode })}>
          {images[0].map((image, idx) => (
            <div key={idx} className={cn('phone', additionalClasses)}>
              <img className={cn('phone-image', { visible: stepInFocus === 0 })} src={image} alt='phone' />
              <img className={cn('phone-image', { visible: stepInFocus === 1 })} src={images[1][idx]} alt='phone' />
              <img className={cn('phone-image', { visible: stepInFocus === 2 })} src={images[2][idx]} alt='phone' />
            </div>
          ))}
        </div>
      </div>
      <div className={cn('home-creator-animation-phones-outer-container end', additionalClasses)}>
        {/* Non Fixed */}
        <div ref={nonFixedPhonesRefEnd} className={cn('phones', additionalClasses, { visible: !isZoomedMode })}>
          {images[2].map((image, idx) => (
            <div key={idx} className={cn('phone', additionalClasses)}>
              <img className='phone-image visible' src={image} alt='phone' />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

HomeCreatorAnimationPhones.propTypes = {
  stepInFocus: PropTypes.number.isRequired
};

export default HomeCreatorAnimationPhones;
