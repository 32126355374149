import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, Link, useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { useLastLocation } from 'react-router-last-location';
import './BlogPost.scss';

import { isAdminControlMode } from '../../Helpers/ui_helpers';

import { deleteBlogPost, setBlogPost, createBlogPostElement, editBlogPostElement, deleteBlogPostElement } from '../../Actions/BlogActions';
import { fetchMoreStories, getPostByTitleStub } from '../../APIClient/blog';

import Image from '../General/Image';
import BlogPostElements from './BlogPostElements';
import Loader from '../Loader/Loader';
import ScrollToTop from '../General/ScrollToTop';
import { getBlogBackupPosts } from '../../Helpers/blog_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const BlogPost = props => {
  const { blog, ui, createBlogPostElement, editBlogPostElement, deleteBlogPostElement, setBlogPost } = props;
  const params = useParams();
  const history = useHistory();
  const lastLocation = useLastLocation();

  const { blogPost } = blog;
  const { title, titleStub, subtitle, elements, coverImage, user, tag } = blogPost || {};
  const { post_url_stub } = params || {};

  if (!post_url_stub) setBlogPost(null);
  else if (post_url_stub !== titleStub) setBlogPost({ titleStub: post_url_stub });

  const [moreStories, setMoreStories] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const isFetching = typeof elements !== 'object'; // will be array once fully loaded
  const isFetchingAllOfPost = isFetching && !title;

  const goBackToMainBlogPage = () => {
    // only want to go back if we came from blog preview page
    const shouldUseHistory = lastLocation && lastLocation.pathname.includes('/blog') && !lastLocation.pathname.includes('/post');
    if (shouldUseHistory) history.goBack();
    else history.push('/blog');
  };

  useEffect(() => {
    if (!post_url_stub) setBlogPost(null);
    else if (post_url_stub !== titleStub) setBlogPost({ titleStub: post_url_stub });
    else if (elements?.length) console.info('Page already fully loaded. No need to fetch elements.');
    else {
      getPostByTitleStub(post_url_stub)
        .then(resp => setBlogPost(resp.post))
        .catch(() => {
          window.ALERT.error('There was an error fetching the blog post.');
          goBackToMainBlogPage();
        });
    }

    blogPost?.id &&
      fetchMoreStories(blogPost.id)
        .then(resp => setMoreStories(resp.posts))
        .catch(() => {
          const nonSmartBackupPosts = getBlogBackupPosts(blog);
          setMoreStories(nonSmartBackupPosts.slice(0, 5));
        });
  }, [titleStub]);

  const labels = [];
  if (user?.name) labels.push(`By ${user.name}`);
  if (tag) labels.push(tag);

  const deleteBlogPost = () =>
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to delete this blog post?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteBlogPost(blogPost) }
      ]
    });

  return (
    <div className='blog-post-outer'>
      <ScrollToTop />
      <div className='blog-post-inner'>
        <div className='back-to-blog-button' onClick={goBackToMainBlogPage}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back To Blog
        </div>
        <div className='post-header'>
          {isFetchingAllOfPost ? <div className='post-header-title-skeleton'></div> : <div className='post-header-title'>{title}</div>}
          <div className='post-header-description'>
            {isFetchingAllOfPost ? <div className='post-header-description-skeleton'></div> : <div className='description'>{subtitle}</div>}
            {isFetchingAllOfPost ? null : <div className='labels'>{labels.join(' • ')}</div>}
          </div>
        </div>
        {isFetchingAllOfPost ? (
          <div className='post-cover-image-skeleton'></div>
        ) : !!coverImage ? (
          <Image src={coverImage} className='post-cover-image' alt={`${titleStub} cover image`} />
        ) : null}
        <div className='blog-post-body'>
          <BlogPostElements
            isFetching={isFetching}
            elements={elements}
            editBlogPostElement={editBlogPostElement}
            createBlogPostElement={createBlogPostElement}
            post={blogPost}
            deleteBlogPostElement={deleteBlogPostElement}
            isEditing={isEditing}
            deleteBlogPost={deleteBlogPost}
          />

          <div className='blog-post-more-stories-container'>
            <div className='more-stories-header'>More Stories</div>
            {moreStories.length > 0 ? (
              <div className='more-stories'>
                {moreStories.map(post => (
                  <Link
                    to={`/blog/post/${post.titleStub}`}
                    key={post.id}
                    className='more-story'
                    onClick={() => {
                      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                      setBlogPost(post);
                    }}
                  >
                    {post.title}
                  </Link>
                ))}
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
        {!isFetching && isAdminControlMode(ui) && (
          <div className='edit-blog-post-button' onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Done Editing' : 'Edit Post'}
          </div>
        )}
      </div>
    </div>
  );
};

BlogPost.propTypes = {
  blog: PropTypes.object.isRequired,

  createBlogPostElement: PropTypes.func.isRequired,
  editBlogPostElement: PropTypes.func.isRequired,
  deleteBlogPostElement: PropTypes.func.isRequired,
  setBlogPost: PropTypes.func.isRequired,
  deleteBlogPost: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog, ui } = state;
  return { blog, ui };
};

export default connect(mapStateToProps, {
  createBlogPostElement,
  editBlogPostElement,
  deleteBlogPostElement,
  setBlogPost,
  deleteBlogPost
})(BlogPost);
