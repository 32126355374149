import React from 'react';
import PropTypes from 'prop-types';
import './ShopSearchBar.scss';

import InputActions from '../General/InputActions';

const ShopSearchBar = props => {
  const { searchVal, updateSearchVal } = props;
  return (
    <div className='shop-search-outer-container'>
      <div className='search-input-container has-actions'>
        <input
          className='search-input'
          placeholder='Search Products, Brands or Categories'
          onChange={({ target }) => updateSearchVal(target.value)}
          value={searchVal}
        />
        <InputActions searchVal={searchVal} onCancel={() => updateSearchVal('')} />
      </div>
    </div>
  );
};

ShopSearchBar.propTypes = {
  searchVal: PropTypes.string.isRequired,
  updateSearchVal: PropTypes.func.isRequired
};

export default ShopSearchBar;
