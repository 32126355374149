import { getFirstName } from './formatting';

export const getCodeFormattingOptions = () => {
  /*
    These settings allow creators to specify the format that they would like their codes to be in.
    This does not force brands to use this format, but alerts the brand that it is their preferred format.
  */

  return [
    {
      getDisplay: user => `${getFirstName(user.name).toUpperCase()}20`,
      subdisplay: 'First Name + Rate',
      value: '{{FIRSTNAME}}{{RATE}}',
      isDefault: true
    },
    {
      getDisplay: user => `${user.name.toUpperCase().replace(/\s/g, '')}20`,
      subdisplay: 'Full Name + Rate',
      value: '{{FULLNAME}}{{RATE}}'
    },
    {
      getDisplay: user => `${user.username.toUpperCase()}20`,
      subdisplay: 'Username + Rate',
      value: '{{USERNAME}}{{RATE}}'
    }
  ];
};
export const getDefaultCodeFormattingOption = () => getCodeFormattingOptions().find(option => option.isDefault);

export const isCodeFormattingDefault = format => {
  /*
    Checks if a code format is the default format or the user has explicitly set it.
  */
  if (!format) return true;
  return format === getDefaultCodeFormattingOption().value;
};

export const getCodeDisplayForUserWithFormat = (user, format, rate) => {
  /*
    Take a user, like Gucci Westman, and a format from their settings, like '{{FIRSTNAME}}{{RATE}}', and return the code that would be generated.

    Example: '{{FIRSTNAME}}{{RATE}}' => 'GUCCI20'
  */

  const defaultFormat = getDefaultCodeFormattingOption().value;

  return (format || defaultFormat)
    .replace('{{FIRSTNAME}}', getFirstName(user.name))
    .replace('{{FULLNAME}}', user.name.replace(/\s/g, ''))
    .replace('{{USERNAME}}', user.username)
    .replace('{{RATE}}', rate)
    .replace(/[^A-Za-z0-9-]/g, '')
    .toUpperCase();
};

export const createFormatFromInputText = (text, user) => {
  /*
    Take a user's input text, like 'GUCCI20', and return the format that would generate that code.

    Example: 'GUCCI20' => '{{FULLNAME}}{{RATE}}'

  */
  const firstName = getFirstName(user.name);
  const fullName = user.name.replace(/\s/g, '');
  const username = user.username;
  const rateRegex = /([1-9][0-9])/; // 10 - 99
  return text
    .toUpperCase()
    .replace(/[^A-Z0-9-]/g, '')
    .replace(firstName, '{{FIRSTNAME}}')
    .replace(fullName, '{{FULLNAME}}')
    .replace(username, '{{USERNAME}}')
    .replace(rateRegex, '{{RATE}}');
};
