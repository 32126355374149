import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './BulkRequestModalMessage.scss';

import { matchScrollHeight, getSmartImage } from '../../Helpers/helpers';
import { getBrandSettings } from '../../Helpers/user_helpers';

import SelectOption from '../General/SelectOption';
import UploadImage from '../General/UploadImage';

const BulkRequestModalMessage = props => {
  const { user, message, setMessage, files, setFiles, isGiftingRequestType, isOpportunityRequestType } = props;
  const settings = getBrandSettings(user);

  /*
    Templates Handling
  */
  const [isUsingTemplate, setIsUsingTemplate] = useState(false);
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false);
  const isDifferentThanTemplate = (settings.sendGiftingTemplate || '') !== message;
  const updateTemplate = async () => {
    setIsUpdatingTemplate(true);
    await props.updateBrandSettings({ sendGiftingTemplate: message });
    setIsUpdatingTemplate(false);
  };

  /*
    Variables Handling
  */
  const [curCursorLocation, setCurCursorLocation] = useState(0);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keyup', e => setCurCursorLocation(inputRef.current?.selectionStart));
      inputRef.current.addEventListener('mouseup', e => setCurCursorLocation(inputRef.current?.selectionStart));
    }
  }, [inputRef]);
  const variableOptions = [
    { value: '{{FIRSTNAME}}', label: 'First Name' },
    { value: '{{FULLNAME}}', label: 'Full Name' },
    { value: '{{BRANDNAME}}', label: 'Brand Name' }
  ];
  const selectVariableToAdd = data => {
    const newMessage = message.slice(0, curCursorLocation) + data.value + message.slice(curCursorLocation);
    props.setMessage(newMessage);
  };

  /*
    Upload Management
  */
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const handlePreprocessFileDrop = (file, next) => {
    setIsUploading(true);
    next(file);
  };
  const onUploadFileProgress = progress => {
    setUploadProgress(progress);
    progress < 100 && setIsUploading(true); // In case of multi-upload
  };
  const completeFileUpload = async upload => {
    setFiles([...props.files, getSmartImage(upload)]);
    setIsUploading(false);
    setUploadProgress(0);
  };

  return (
    <div className='bulk-request-message-container'>
      <div className='message-template'>
        <div></div>
        <textarea
          rows={6}
          placeholder={
            isGiftingRequestType
              ? 'Feel free to include details about the gifting request'
              : isOpportunityRequestType
              ? 'Feel free to include additional details about the opportunity'
              : ''
          }
          className='bulk-request-message'
          onChange={e => setMessage(e.target.value)}
          value={message}
          ref={ref => matchScrollHeight(ref)}
        />
      </div>
      {!!files.length && (
        <div className='files'>
          {files.map((file, idx) => {
            const displayName = _.last(file.split('/'))
              .split('_')
              .slice(1)
              .join('_');
            return (
              <div className='file' key={file + idx}>
                <div className='name'>{displayName}</div>
                <div className='actions'>
                  <div className='action view' onClick={() => window.open(file, '_blank')}>
                    <FontAwesomeIcon icon={faExternalLink} />
                  </div>
                  <div className='action delete' onClick={() => setFiles(files.filter(f => f !== file))}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {isGiftingRequestType && (
        <div className='action-btns'>
          <div className='add-new-file'>
            <UploadImage
              basicContent={
                isUploading ? (
                  <div className='upload-progress'>{uploadProgress.toFixed(0)}%</div>
                ) : (
                  <div className='upload-icn'>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </div>
                )
              }
              basicUploader
              onUploadProgress={onUploadFileProgress}
              completeUpload={completeFileUpload}
              handlePreprocess={handlePreprocessFileDrop}
            />
          </div>
          {!isUsingTemplate && (
            <div
              className={cn('use-template action-btn', { secondary: isUsingTemplate })}
              onClick={() => {
                setIsUsingTemplate(!isUsingTemplate);
                setMessage(settings.sendGiftingTemplate || '');
              }}
            >
              Use Gifting Template
            </div>
          )}
          {isUsingTemplate && (
            <>
              {isDifferentThanTemplate && (
                <div onClick={updateTemplate} className='action-btn update-template secondary'>
                  {isUpdatingTemplate ? 'Updating...' : 'Update Template'}
                </div>
              )}
              <div className='select'>
                <Select
                  unstyled
                  placeholder='Add Variable'
                  classNamePrefix='variable-select'
                  onChange={selectVariableToAdd}
                  className='variable-select'
                  options={variableOptions}
                  value={null}
                  components={{ Option: SelectOption }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

BulkRequestModalMessage.propTypes = {
  user: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  updateBrandSettings: PropTypes.func.isRequired,
  isGiftingRequestType: PropTypes.bool.isRequired,
  isOpportunityRequestType: PropTypes.bool.isRequired
};

export default BulkRequestModalMessage;
