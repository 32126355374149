import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';
// import _ from 'lodash'
// import cn from 'classnames'
import './DiscoverAddFeatureList.scss';

import { getAllDiscoverLists } from '../../../APIClient/discover';
import { getActiveDiscoverGroup } from '../../../Helpers/discover_helpers';
import { getBrandName, getBrandId } from '../../../Helpers/user_helpers';

import SelectOption from '../../General/SelectOption';

const DiscoverAddFeatureList = props => {
  const { discover } = props;
  const activeGroup = getActiveDiscoverGroup(discover);

  const [allLists, setAllLists] = useState([]);
  const listOptions = allLists
    .filter(l => l.title)
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    .map(list => {
      const alreadyFeatured = activeGroup?.features?.find(f => f.DiscoverList_id === list.id);
      return {
        value: list.id,
        label: list.title || '-',
        sublabels: [
          ...(list.Brand_name ? [list.Brand_name] : []),
          alreadyFeatured ? 'Already Featured' : `${moment(list.createdAt).format('MMM Do, YYYY')}`
        ]
      };
    });

  useEffect(() => {
    getAllDiscoverLists().then(lists => setAllLists(lists));
  }, []);

  const addListFromSelect = newList => {
    const { allowBrandOnlyLists, allowGeneralLists } = activeGroup;

    const isGeneral = window.prompt(
      `1 = General List for Everyone, 2 = Only show it for ${getBrandName(props.user)}.`,
      allowBrandOnlyLists ? '2' : '1'
    );
    const wantsToMakeBrandList = isGeneral === '2';
    if (!isGeneral) return;

    // Ensure they can add it
    if (wantsToMakeBrandList && !allowBrandOnlyLists) return window.ALERT.error(`You cannot add brand only lists to ${activeGroup.title}.`);
    if (!wantsToMakeBrandList && !allowGeneralLists) return window.ALERT.error(`You cannot add general lists to ${activeGroup.title}.`);

    props
      .createDiscoverFeature({
        DiscoverGroup_id: activeGroup.id,
        DiscoverList_id: newList.value,
        ...(wantsToMakeBrandList ? { Brand_id: getBrandId(props.user) } : {})
      })
      .then(() => props.syncActiveDiscoverGroup(activeGroup));
  };

  const goToList = newList => {
    window.location.href = `/discover/${newList.value}`;
  };

  return (
    <div className='discover-add-feature-list-container'>
      <Select
        value={null}
        onChange={addListFromSelect}
        className='select'
        classNamePrefix='select'
        options={listOptions}
        placeholder='Add featured list'
        components={{ Option: SelectOption }}
      />
      <Select
        value={null}
        onChange={goToList}
        className='select'
        classNamePrefix='select'
        options={listOptions}
        placeholder='Go To List'
        components={{ Option: SelectOption }}
      />
    </div>
  );
};

DiscoverAddFeatureList.propTypes = {
  discover: PropTypes.object.isRequired,
  createDiscoverFeature: PropTypes.func.isRequired
};

export default DiscoverAddFeatureList;
