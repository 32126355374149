import _ from 'lodash';

import { prepopulateIntercomMessage } from './chat_helpers';

export const getSelectedSpotlightKey = ui => _.get(ui, 'spotlightKey') || null;
export const showingSpotlightTasksModal = ui => _.get(ui, 'spotlightTasksVisible');
export const spotlightKeys = {
  CREATE_PRODUCT_LINK: 'create-link',
  CREATE_SHELF: 'create-shelf',
  CREATE_CLOSET: 'create-closet',
  CREATE_HOME: 'create-home',
  CREATE_POST: 'create-post',
  CREATE_CONSULT: 'create-consult',
  CHAT_WITH_BRANDS: 'chat-with-brands',
  CREATE_COLLABORATION: 'create-collaboration',
  RSVP_FOR_EVENTS: 'rsvp',
  SELECT_SHOP_MANAGER: 'select-shop-manager'
};

export const getUrlForSpotlightKey = (key, username) =>
  ({
    [spotlightKeys.CREATE_PRODUCT_LINK]: `/links`,
    [spotlightKeys.CREATE_SHELF]: `/${username}/shelves`,
    [spotlightKeys.CREATE_CLOSET]: `/${username}/closet`,
    [spotlightKeys.CREATE_HOME]: `/${username}/home`,
    [spotlightKeys.CREATE_POST]: `/${username}/posts`,
    [spotlightKeys.CREATE_CONSULT]: `/${username}/consults`,
    [spotlightKeys.CHAT_WITH_BRANDS]: `/chat`,
    [spotlightKeys.CREATE_COLLABORATION]: `/collaborations`
  }[key]);

export const getActionForSpotlightKey = (key, username) =>
  ({
    [spotlightKeys.RSVP_FOR_EVENTS]: () => {
      prepopulateIntercomMessage(`Hi! What are some of the upcoming events I can attend?`);
    },
    [spotlightKeys.SELECT_SHOP_MANAGER]: () => {
      prepopulateIntercomMessage(`Hi! Could you assist me in selecting my shop manager?`);
    }
  }[key]);
