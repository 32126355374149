import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './RequiresPermissions.scss';

import { isCurrentlyManaging, getManagerPermission, getUsersManagerPermissionRestriction, isSwitchingManaging } from '../../Helpers/manager_helpers';

const RequiresPermissions = props => {
  const { user, manager, permission, hideMessage, children } = props;

  const isSwitching = isSwitchingManaging(manager);
  const isManaging = isCurrentlyManaging(manager);
  const userRestriction = getUsersManagerPermissionRestriction(user, manager, permission);

  // If we are not managing bypass this check
  if (!isManaging && !userRestriction) return children;

  // Ensure we have the permission in our system
  const permissionObject = isManaging ? getManagerPermission(manager, permission) : userRestriction;
  if (!permissionObject) {
    console.warn(`Cannot find permission named ${permission}, please check spelling.`);
    return children;
  }

  // If they have manager access, continue.
  if (isManaging && permissionObject?.selected && !isSwitching) return children;

  const clickNoPermission = () => hideMessage && cogoToast.error(permissionObject.error);
  return (
    <div onClick={clickNoPermission} className='no-permission-outer-wrapper'>
      <div className='no-permission-wrapper'>{children}</div>
      {!hideMessage && (
        <div className='no-permission-msg-wrapper'>
          <div className='no-permission-msg'>{isSwitching ? 'Checking Permissions...' : permissionObject.error}</div>
        </div>
      )}
    </div>
  );
};

RequiresPermissions.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,

  // Optional
  hideMessage: PropTypes.bool
};

const mapStateToProps = state => {
  const { user, manager } = state;
  return { user, manager };
};

export default connect(mapStateToProps, {
  // functions go here
})(RequiresPermissions);
