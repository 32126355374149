import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './LatestSection_Shops.scss';

const LatestSection_Shops = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section shops'>
      {items.map(item => {
        const { user } = item;
        if (!user) return null;
        return <Shop key={item.id} item={item} {...props} />;
      })}
    </div>
  );
};

const Shop = props => {
  const { item } = props;
  const { user, subtitle } = item;

  const url = item.url?.split('.us')[1] || `/${user.username}`;

  return (
    <Link to={url} className='shop'>
      <img src={item.image || user.image} alt={user.name} />
      <div className='data'>
        <div className='title-container'>
          <div className='name'>{user.name}</div>
          {subtitle && <div className='subtitle'>{item.subtitle}</div>}
          <div className='action-display'>
            View Shop
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </Link>
  );
};

LatestSection_Shops.propTypes = {
  section: PropTypes.object.isRequired
};

export default LatestSection_Shops;
