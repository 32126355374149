import APIClient from './index';
import _ from 'lodash';

/**
 * @param {Object} params
 * @param {string} params.Brand_id - (REQUIRED) The id of the brand to fetch invoices / commissions for
 * @param {string} params.lastInvoiceId - For pagination, the id of the last invoice fetched. If not provided, will fetch the most recent invoices.
 * @param {string} params.startDate - Optional, YYYY-MM-DD, the start date of the date range in which to fetch invoices / commissions for
 * @param {string} params.endDate - Optional, the end date of the date range to fetch invoices / commissions for
 * @param {string} params.Invoice_id - Optional, an id of an invoice to fetch. Will also return all commissions associated with that invoice.
 * @param {string} params.stripeInvoiceId - Optional, stripe id is on the invoice object so will act the same as Invoice_id
 * @param {string} params.Commission_id - Optional, an id of a commission to fetch. Will also return the invoice associated with that commission.
 * @param {string} params.search - Optional, a string to try matching: order_id, user name, code
 */
export const fetchBrandInvoicesAndCommissions = async params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/AffiliateInvoices/for_brand?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

/**
 * @param {string} Brand_id
 */
export const fetchBrandInvoiceStats = async Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/AffiliateInvoices/for_brand?${new URLSearchParams({ Brand_id }).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchInvoiceById = async Invoice_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/AffiliateInvoices/${Invoice_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
