import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as constants from './social_template_constants';
import shopMyLogoWhite from '../../../../static/images/logos/shop_my_white.png';
import './SocialSharingOffsetPinGrid.scss';

import SocialSharingTemplateControls from './SocialSharingTemplateControls';
import { filterAndSortPinsForSocialSharing } from '../../../../Helpers/social_sharing_template_helpers';

const SocialSharingOffsetPinGrid = props => {
  const { elementId, collection } = props;
  const { ASPECT_RATIO } = constants;
  const { pins, name } = collection;

  const [backgroundColor, setBackgroundColor] = useState('#171919');
  const [textColor, setTextColor] = useState('#ffffff');
  const [logoColor, setLogoColor] = useState('#ffffff');
  const logoBrightness = logoColor === '#ffffff' ? 1 : 0;
  const logoHidden = logoColor === 'transparent';

  // FILTER PINS HERE
  const tempPins = filterAndSortPinsForSocialSharing(pins);
  const topPins = [];
  for (let i of _.range(10)) {
    const modIndex = i % tempPins.length;
    tempPins[modIndex] && topPins.push(tempPins[modIndex]);
  }

  const leftPins = topPins.slice(0, 3);
  const centerPins = topPins.slice(3, 7);
  const rightPins = topPins.slice(7, 10);

  if (!topPins.length) return null;
  return (
    <div className='social-sharing-template'>
      <div className='downloadable-content-container social-sharing-offset-pin-grid-outer-container'>
        <div className='downloadable-content' id={elementId} style={{ aspectRatio: ASPECT_RATIO, background: backgroundColor, color: textColor }}>
          <div className='downloadable-content-inner'>
            <div className='pin-image-grid'>
              <div className='pin-image-column first'>
                {leftPins.map((pin, idx) => {
                  const { image, title } = pin || {};
                  return <img key={idx} alt={title} src={image} />;
                })}
              </div>
              <div className='pin-image-column second'>
                {centerPins.map((pin, idx) => {
                  const { image, title } = pin || {};
                  return <img key={idx} alt={title} src={image} />;
                })}
              </div>
              <div className='pin-image-column third'>
                {rightPins.map((pin, idx) => {
                  const { image, title } = pin || {};
                  return <img key={idx} alt={title} src={image} />;
                })}
              </div>
            </div>

            <div className='background-container' style={{ background: `linear-gradient(transparent, transparent, #171919)` }} />
            <div className='title-container'>
              <div className='medium-title'>{name}</div>
            </div>

            {!logoHidden && (
              <img style={{ filter: `brightness(${logoBrightness})` }} height={15} className='logo' src={shopMyLogoWhite} alt='shopmy_logo' />
            )}
          </div>
        </div>
      </div>
      <SocialSharingTemplateControls
        collection={collection}
        elementId={elementId}
        backgroundColor={backgroundColor}
        setBackgroundColor={setBackgroundColor}
        textColor={textColor}
        setTextColor={setTextColor}
        logoColor={logoColor}
        setLogoColor={setLogoColor}
      />
    </div>
  );
};

SocialSharingOffsetPinGrid.propTypes = {
  elementId: PropTypes.string.isRequired,
  collection: PropTypes.object.isRequired
};

export default SocialSharingOffsetPinGrid;
