import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import commaNumber from 'comma-number';
import classnames from 'classnames';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faInfoCircle, faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';

import { getAdjPayoutRate, isAdmin } from '../../Helpers/user_helpers';
import { getDisclaimerForMerchant } from '../../Helpers/merchant_helpers';

import './CollectionAnalytics.scss';

class CollectionAnalytics extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    collection: PropTypes.object.isRequired,
    pin: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    setAnalyticsMode: PropTypes.func.isRequired,
    fetchingAnalytics: PropTypes.bool.isRequired,
    adminControlMode: PropTypes.bool.isRequired
  };

  state = {
    range: 'total',
    switchingTimeRange: false
  };

  render() {
    const { analytics, pin, user, store, collection, adminControlMode, setAnalyticsMode, fetchingAnalytics } = this.props;
    const { range, switchingTimeRange } = this.state;
    const { disable_monetization, geolinks } = pin || {};
    const { pin_data } = analytics || {};
    const pinAnalytics = _.get(pin_data, pin.id, {});
    const { merchant_data } = pinAnalytics;
    const hasAnalytics = !_.isEmpty(analytics);
    const admin = isAdmin(user);
    const { name, rateType, fullPayout, source } = merchant_data || {};
    const { customRates } = store || {};
    const shopAdjPayout = getAdjPayoutRate(collection.user, merchant_data, customRates);
    const disclaimer = getDisclaimerForMerchant(merchant_data, user);
    const timeRangeDisplay = this.timeRangeDisplays()[range];
    let data = [
      {
        title: 'Clicks',
        val: commaNumber(_.get(pinAnalytics, `${range}_views`))
      }
    ];
    const commissionMsg = disable_monetization
      ? 'Disabled'
      : rateType === 'percentage'
      ? `${shopAdjPayout.toFixed(shopAdjPayout < 1 ? 1 : 0)}%`
      : `$${shopAdjPayout.toFixed(shopAdjPayout < 1 ? 1 : 0)}`;

    if (shopAdjPayout) {
      data = [
        ...data,
        {
          title: 'Orders',
          val: commaNumber(_.get(pinAnalytics, `${range}_purchases`))
        },
        { title: 'Retailer', val: name },
        { title: 'Commission', val: commissionMsg, infoTooltip: disclaimer },
        ...(admin && adminControlMode
          ? [
              {
                title: 'Full Commission',
                val: rateType === 'percentage' ? `${fullPayout}%` : `$${fullPayout}`
              }
            ]
          : []),
        ...(admin && adminControlMode ? [{ title: 'Source', val: source }] : []),
        ...(geolinks.length ? [{ title: 'Active Geo Links', val: geolinks.length }] : []),
        {
          title: 'Earnings',
          val: `$${(_.get(pinAnalytics, `${range}_payout`) || 0).toFixed(2)}`,
          bold: true
        }
      ];
    } else {
      data = [
        ...data,
        {
          title: 'Commission Rate',
          val: 'None',
          infoTooltip: 'Please know that shortened links are not handled by our system, and that not every link has an affiliate program.'
        }
      ];
    }

    return (
      <div
        onClick={() => setAnalyticsMode(false)}
        className={classnames('collection-analytics-outer-container', collection.skinType, { mobile: isMobile })}
      >
        {(!hasAnalytics || fetchingAnalytics) && <Loader inverted={!hasAnalytics} size={120} />}
        {hasAnalytics && (
          <div onClick={e => e.stopPropagation()} className={classnames('collection-analytics-details-container')}>
            <div className='collection-analytics-date-selection'>
              <div onClick={() => this.setState({ switchingTimeRange: !switchingTimeRange })} className='header'>
                <FontAwesomeIcon icon={faCalendarAlt} className='calendar-icon' />
                <div>{timeRangeDisplay}</div>
                <FontAwesomeIcon icon={switchingTimeRange ? faChevronUp : faChevronDown} className='chevron-icon' />
              </div>
              {switchingTimeRange && (
                <div className={classnames('range-options')}>
                  {_.map(this.timeRangeDisplays(), (display, newRange) => {
                    const isSelected = newRange === range;
                    return (
                      <div
                        key={display}
                        onClick={() =>
                          this.setState({
                            range: newRange,
                            switchingTimeRange: false
                          })
                        }
                        className={classnames('range-option', {
                          selected: isSelected
                        })}
                      >
                        {display}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className='collection-analytics-details-rows'>
              {_.map(data, ({ title, val, infoTooltip, bold }) => {
                return (
                  <div
                    key={title}
                    className={classnames('collection-analytics-details-row', {
                      bold: !!bold
                    })}
                  >
                    <div className='collection-analytics-details-cell title'>{title}</div>
                    <div className='collection-analytics-details-cell value'>
                      <div>{val}</div>
                      {infoTooltip && <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  timeRangeDisplays = () => ({
    total: 'ALL TIME',
    yearly: 'THIS YEAR',
    monthly: 'THIS MONTH',
    weekly: 'THIS WEEK',
    daily: 'TODAY'
  });
}

export default CollectionAnalytics;
