import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './CodeResults.scss';

import CodeResult from './CodeResult';
import Loader from '../Loader/Loader';

import { sendCodeToUser } from '../../Helpers/code_helpers';

class CodeResults extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    codes: PropTypes.array.isRequired,
    setCustomCode: PropTypes.func.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    openCodesModal: PropTypes.func.isRequired,
    syncDataForCode: PropTypes.func.isRequired,
    fetchNextPage: PropTypes.func.isRequired,
    isFetchingNextPage: PropTypes.bool.isRequired,
    receivedAllResults: PropTypes.bool.isRequired
  };

  componentDidMount() {
    window.addEventListener('scroll', this.infiniteScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.infiniteScroll);
  }

  infiniteScroll = e => {
    const pixelsFromBottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight;

    if (pixelsFromBottom < 500 && !this.props.isFetchingNextPage && !this.props.receivedAllResults) {
      this.props.fetchNextPage();
    }
  };

  getAction = code => {
    return {
      action: null,
      action_label: null
    };
  };

  updateCode = code => {
    this.props.openCodesModal({
      params: {
        id: code.id,
        User_id: code.User_id,
        name: code.name,
        showTemplates: false // Whether or not to show code sending templates
      },
      closeCallback: () => this.props.syncDataForCode(code)
    });
  };

  render() {
    const { user, analytics, codes, isFetchingNextPage, syncDataForCode } = this.props;

    return (
      <div className='code-results'>
        <div className='row'>
          <div className='cell'>CREATED ON</div>
          <div className='cell'>NAME</div>
          <div className='cell'>CODE</div>
          <div className='cell'>RATE</div>
          <div className='cell'>STATUS</div>
          <div className='cell'>ACTION</div>
        </div>
        {codes.map(code => {
          const editCode = () => this.updateCode(code);
          return (
            <CodeResult
              key={code.id}
              user={user}
              code={code}
              analytics={analytics}
              openArtistModal={this.props.openArtistModal}
              editCode={editCode}
              syncDataForCode={syncDataForCode}
            />
          );
        })}
        {isFetchingNextPage && (
          <div className='fetching-next-page-loader'>
            <Loader size={80} />
          </div>
        )}
      </div>
    );
  }
}

export default CodeResults;
