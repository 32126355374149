import { orderBy, uniqBy } from 'lodash';

export const getDisplayForCtaType = type =>
  ({
    ABOUT: 'Learn More',
    GIFTING: 'Request Gifting',
    CODES: 'Request Discount Code',
    COLLABS: 'Propose Collaborations',
    CHAT: 'Chat With Them',
    URL: 'Visit Webpage'
  }[type]);

export const getPossibleTagsForTab = (newsletter, activeTab) => {
  if (!newsletter || !activeTab) return [];
  else if (!newsletter.tags?.length) return [];
  else if (!newsletter.tabs?.length) return [];
  else if (!newsletter.sections?.length) return [];

  const allTags = newsletter.tags;
  const visibleSections = newsletter.sections.filter(section => section.tabs.find(tab => tab.id === activeTab.id));

  const possibleTagsForTab = [];
  for (const tag of allTags) {
    if (tag.isHidden) continue;

    const tagIsPartOfAllActiveSections = visibleSections.every(section => section.tags.find(sectionTag => sectionTag.id === tag.id));
    if (tagIsPartOfAllActiveSections) continue;

    const tagIsPartOfAtLeastOneActiveSection = visibleSections.some(section => section.tags.find(sectionTag => sectionTag.id === tag.id));
    if (tagIsPartOfAtLeastOneActiveSection) possibleTagsForTab.push(tag);
  }

  return orderBy(uniqBy(possibleTagsForTab, 'id'), 'sortOrderRank');
};
