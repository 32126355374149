import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import './ImageUploader.scss';

import UploadImage from './UploadImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import DropUploader from '../Uploader/DropUploader';

/**
 * This is a modal that allows for the user to upload an image or enter an image URL.
 * When they click save, whichever image option they have selected will be returned
 * in a callback function. In that callback function, you can define how you want to
 * handle the image.
 */

const ImageUploaderComponent = (props, ref) => {
  const { initialImageUrl, isVisible, setIsVisible, onSaveCallback, previewAspectRatio } = props;

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [image, setImage] = useState();
  const [url, setUrl] = useState(initialImageUrl);

  const hasImage = image || url;
  const isAbleToClear = !hasImage && initialImageUrl;

  const handleUrlInput = event => setUrl(event.target.value);

  useImperativeHandle(ref, () => ({
    getImageFile: () => image,
    getImageUrl: () => url
  }));

  const clearInputs = () => {
    setImage('');
    setUrl('');
  };

  const saveAndExit = async () => {
    if (uploading) return;
    await onSaveCallback(image || url);
    setIsVisible(false);
  };

  const completeUpload = url => {
    setImage(url);
    setUploading(false);
  };

  const onUploadProgress = progress => {
    setUploading(true);
    setUploadProgress(progress);
  };

  const handleDragUpload = fileUrl => {
    setUrl(fileUrl);
    window.ALERT.success('Image uploaded successfully.');
  };

  const handleDragDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) return window.ALERT.error('Only image files are supported.');
    setUploading(false);
  };

  if (!isVisible) return null;
  return (
    <div
      className='image-uploader-outer'
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setIsVisible(false);
      }}
    >
      <div
        className='image-uploader-inner'
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <DropUploader onDrop={handleDragDrop} onUpload={handleDragUpload} onlyAllowImages>
          <div className='heading'>
            <div className='image-uploader-header'>Enter An Image</div>
            <div className='image-uploader-sub-header'>Only .jpg, .jpeg, .png, and .webp files are supported.</div>
          </div>
          {/* <input type='file' accept='image/*' onChange={handleImageUpload} onClick={e => e.stopPropagation()} /> */}
          <UploadImage
            basicContent={
              image ? (
                <div className='upload-image-container'>
                  Upload Complete
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : (
                <div className='upload-image-container'>
                  Upload Image
                  <FontAwesomeIcon icon={faFileUpload} />
                  {uploading && <span> ({uploadProgress})</span>}
                </div>
              )
            }
            basicUploader={true}
            onUploadProgress={onUploadProgress}
            completeUpload={completeUpload}
            imagesOnly={true}
          />
          <input type='text' className='image-uploader-input' placeholder='Or enter an image URL' onChange={handleUrlInput} value={url} />
          {hasImage && <img src={image || url} alt='user input' style={{ aspectRatio: previewAspectRatio ? `${previewAspectRatio}` : 'unset' }} />}
          {(hasImage || isAbleToClear) && (
            <div className='buttons'>
              {hasImage ? (
                <button className='clear-button' onClick={clearInputs}>
                  Clear
                </button>
              ) : null}
              <button className='save-button' onClick={saveAndExit}>
                Save
              </button>
            </div>
          )}
        </DropUploader>
      </div>
    </div>
  );
};

const ImageUploader = forwardRef(ImageUploaderComponent);
ImageUploader.propTypes = {
  initialImageUrl: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  previewAspectRatio: PropTypes.number
};
export default ImageUploader;
