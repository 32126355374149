import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './Contract.scss';

import Loader from '../../Components/Loader/Loader';
import ConfirmPrompt from '../../Components/General/ConfirmPrompt';
import ContractHeader from '../../Components/Contracts/ContractHeader';
import ContractProgress from '../../Components/Contracts/ContractProgress';
import ContractProposeTasks from '../../Components/Contracts/ContractProposeTasks';
import ContractProposeSchedule from '../../Components/Contracts/ContractProposeSchedule';
import ContractProposeLinks from '../../Components/Contracts/ContractProposeLinks';
import ContractProposeRatesCodes from '../../Components/Contracts/ContractProposeRatesCodes';
import ContractUploadsTab from '../../Components/Contracts/ContractUploadsTab';
import ContractTasksTab from '../../Components/Contracts/ContractTasksTab';
import ContractAnalyticsTab from '../../Components/Contracts/ContractAnalyticsTab';
import ContractReview from '../../Components/Contracts/ContractReview';
import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import AddPinModal from '../../Components/AddPinModal/AddPinModal';
import RequiresPermissions from '../../Components/Managers/RequiresPermissions';

import {
  getContract,
  getContractAnalytics,
  updateContract,
  createContractTask,
  deleteContractTask,
  updateContractTask,
  getContractTaskTemplates,
  createContractLink,
  deleteContractLink,
  updateContractLink,
  createContractUpload,
  updateContractUpload,
  deleteContractUpload,
  createContractRateCode,
  updateContractRateCode,
  deleteContractRateCode
} from '../../Actions/CollaborationsActions';
import { deletePin } from '../../Actions/StoreActions';
import { toggleBodyScrollLock, openChatOverlay, openArtistModal, openBrandBudgetModal, openConnectInstagramModal } from '../../Actions/UIActions';
import { connectInstagram } from '../../Actions/UserActions';
import { getBrandAnalytics } from '../../Actions/AnalyticsActions';
import { isLoggedIn, isBrand, isAdmin, getNameWithS } from '../../Helpers/user_helpers';
import { canEditContract } from '../../Helpers/contract_helpers';
import { blockOnRequiringBudget } from '../../Helpers/subscription_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

const Contract = props => {
  const { collaborations, user, analytics, getContract, getBrandAnalytics, updateContract, ui } = props;
  const { visibleContract } = collaborations || {};
  const { status, isCreatedByBrand } = visibleContract || {};
  const loggedIn = isLoggedIn(user);

  // init react router hooks
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const visibleContractId = params.id;
  const canEdit = canEditContract(user, visibleContract);
  const isResponding = !canEdit;
  const isPending = status === 'pending';
  const isProposed = status === 'proposed';
  const isAccepted = status === 'accepted';
  const isActive = status === 'active';
  const isClosed = status === 'closed';
  const isRejected = status === 'rejected';

  // Editing Artist Rates
  const openUserModal = () => {
    props.openArtistModal(visibleContract?.user, () => {
      // Resync data in case we adjusted rates or codes
      getBrandAnalytics();
    });
  };

  // Editing Pins
  const [pinBeingEdited, setPinBeingEdited] = useState(null);
  const [inAddMode, setInAddMode] = useState(null);
  const toggleAddOrEditPinMode = (newPinBeingEdited = null) => {
    const toggleOn = !pinBeingEdited && !inAddMode;
    setPinBeingEdited(newPinBeingEdited);
    setInAddMode(toggleOn);
    props.toggleBodyScrollLock(toggleOn);
  };

  const resetContractToEditing = () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to edit this collaboration? You will have to resubmit in order for ${chatName} to accept.`,
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            props.updateContract(visibleContract, {
              status: 'pending'
            });
          }
        }
      ]
    });
  };
  const submitContract = () => {
    const durationTaskWithoutADuration = visibleContract.tasks.find(task => task.template.fee_type === 'duration' && !task.taskDuration);
    if (durationTaskWithoutADuration) {
      cogoToast.warn(`You must set a duration for ${durationTaskWithoutADuration.title} before submitting this proposal.`);
      selectStepByType('schedule');
      return;
    }

    const inAdminControlMode = isAdminControlMode(ui);
    const allContractsHaveAPrice = visibleContract.tasks.find(task => !!task.price);
    const totalContractPrice = _.sum(visibleContract.tasks.map(t => (t.price ? t.price * (t.taskDuration || 1) : 0)));

    if (inAdminControlMode && (!allContractsHaveAPrice || totalContractPrice < 50)) {
      const msg = 'You are submitting a collaboration that would not normally be allowed, Are you sure you want to proceed?';
      const adminOverride = window.confirm(msg);
      if (!adminOverride) return;
    } else if (!allContractsHaveAPrice) {
      cogoToast.warn(`You must set a price for one of the deliverables to submit this request.`);
      selectStepByType('tasks');
      return;
    } else if (totalContractPrice < 50) {
      cogoToast.warn(`We require a minimum collaboration of $50, please adjust your prices and resubmit.`);
      selectStepByType('tasks');
      return;
    }

    if (blockOnRequiringBudget(user, totalContractPrice, props.openBrandBudgetModal)) return;

    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Add a Message'
          subheader={`We will attach this note alongside the collaboration request.`}
          placeholder={isCreatedByBrand ? 'We love your content and want to collaborate!' : 'I love your products and want to collaborate!'}
          onCancel={onClose}
          submitBtnDisplay='Send Proposal'
          onSubmit={async msg => {
            await props.updateContract(visibleContract, {
              status: 'proposed',
              additionalMsg: msg
            });
            scrollToTop();
          }}
        />
      )
    });
  };
  const rejectContract = async () => {
    await props.updateContract(visibleContract, {
      status: 'rejected'
    });
    scrollToTop();
  };
  const undoRejection = async () => {
    await props.updateContract(visibleContract, {
      status: 'proposed',
      rejectedDate: null
    });
    scrollToTop();
  };
  const undoClose = async () => {
    await props.updateContract(visibleContract, {
      status: 'active',
      closedDate: null
    });
    scrollToTop();
  };
  const acceptContract = () => {
    if (blockOnRequiringBudget(user, visibleContract?.price, props.openBrandBudgetModal)) return;
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Add a Message'
          subheader={`We will attach this note alongside the accepted proposal.`}
          onCancel={onClose}
          submitBtnDisplay='Accept Proposal'
          onSubmit={async msg => {
            await props.updateContract(visibleContract, {
              status: 'accepted',
              additionalMsg: msg
            });
            scrollToTop();
          }}
        />
      )
    });
  };
  const completeContract = () => {
    props.updateContract(visibleContract, {
      status: 'active'
    });
  };
  const closeContract = () => {
    props.updateContract(visibleContract, {
      status: 'closed'
    });
  };

  const openChat = () => {
    props.openChatOverlay(visibleContract.user);
  };

  /* Handle navigating the steps. */
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  const selectStepByType = type => {
    const step = steps.find(step => step.type === type);
    step ? selectStep(step) : cogoToast.warn(`No step found for ${type}`);
  };

  const selectStep = step => {
    setActiveStepIndex(step.idx);
    scrollToTop();

    let newUrl;

    if (params.type) {
      const shortPath = location.pathname.split(params.type)[0];
      newUrl = `${shortPath}${step.type}`;
    } else {
      newUrl = `${location.pathname}/${step.type}`;
    }

    history.replace(newUrl);
  };

  let steps = [];
  const chatHref = `/Chat?query=${isBrand(user) ? visibleContract?.user.name : visibleContract?.brand.name}`;
  const chatName = isBrand(user) ? visibleContract?.user.name : visibleContract?.brand.name;
  if (isPending) {
    steps = canEdit
      ? isBrand(user)
        ? [
            { title: 'Establish Deliverables', shortTitle: 'Deliverables', type: 'tasks' },
            { title: 'Set Schedule', shortTitle: 'Schedule', type: 'schedule' },
            { title: 'Link Materials', shortTitle: 'Links', type: 'links' },
            { title: 'Rates & Codes', shortTitle: 'Rates & Codes', type: 'rates' },
            { title: 'Final Review', shortTitle: 'Review', type: 'review' }
          ]
        : [
            { title: 'Establish Deliverables', shortTitle: 'Deliverables', type: 'tasks' },
            { title: 'Set Schedule', shortTitle: 'Schedule', type: 'schedule' },
            { title: 'Upload Materials', shortTitle: 'Uploads', type: 'links' },
            { title: 'Final Review', shortTitle: 'Review', type: 'review' }
          ]
      : [{ title: 'Final Review', shortTitle: 'Review', type: 'review' }];
  } else if (isProposed) {
    steps = [{ title: 'Final Review', shortTitle: 'Review', type: 'review' }];
  } else if (isAccepted) {
    steps = [
      { title: 'Deliverables', shortTitle: 'Deliverables', type: 'livetasks', isTab: true },
      { title: 'Uploads', shortTitle: 'Uploads', type: 'uploads', count: visibleContract?.uploads?.length, isTab: true },
      { title: 'Links & Analytics', shortTitle: 'Links & Analytics', type: 'analytics', isTab: true },
      { title: 'Overview', shortTitle: 'Overview', type: 'review', isTab: true },
      { title: 'Chat', shortTitle: 'Chat', href: chatHref, onClickOverride: isBrand(user) ? openChat : null, type: 'chat', isTab: true }
    ];
  } else if (isActive) {
    steps = [
      { title: 'Links & Analytics', shortTitle: 'Links & Analytics', type: 'analytics', isTab: true },
      { title: 'Uploads', shortTitle: 'Uploads', type: 'uploads', count: visibleContract?.uploads?.length, isTab: true },
      { title: 'Overview', shortTitle: 'Overview', type: 'review', isTab: true },
      { title: 'Chat', shortTitle: 'Chat', href: chatHref, onClickOverride: isBrand(user) ? openChat : null, type: 'chat', isTab: true }
    ];
  } else if (isRejected) {
    steps = [];
  } else if (isClosed) {
    steps = [
      { title: 'Analytics', shortTitle: 'Analytics', type: 'analytics', isTab: true },
      { title: 'Overview', shortTitle: 'Overview', type: 'review', isTab: true },
      { title: 'Chat', shortTitle: 'Chat', href: chatHref, onClickOverride: isBrand(user) ? openChat : null, type: 'chat', isTab: true }
    ];
  } else {
    steps = [{ title: 'Final Review', shortTitle: 'Review', type: 'review' }];
  }

  steps = steps.map((s, i) => ({
    ...s,
    idx: i,
    active: i === activeStepIndex,
    past: i < activeStepIndex,
    future: i > activeStepIndex
  }));

  // Fetch the initial data
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    loggedIn &&
      getContract(visibleContractId).then(() => {
        setIsFetching(false);
      });
  }, [visibleContractId, setIsFetching, getContract, loggedIn]);

  const syncContractAndAnalytics = () => {
    getContract(visibleContract.id, { noReset: true }).then(() => {
      props.getContractAnalytics(visibleContract.id);
    });
  };

  // Allow indexing into a specific step
  const indexOfPreSelectedStep = _.findIndex(steps, step => step.type === params.type);
  useEffect(() => {
    indexOfPreSelectedStep > 0 && setActiveStepIndex(indexOfPreSelectedStep);
  }, [indexOfPreSelectedStep, setActiveStepIndex]);

  const activeStep = steps[activeStepIndex] || steps[0];
  if (!loggedIn) return <RequiresLoginPanel />;

  let alertMsg;
  if (isProposed && canEdit) {
    alertMsg = (
      <div className='alert-message'>
        <div>
          {isBrand(user)
            ? `You successfully proposed this collaboration and we have notified ${chatName}.`
            : `You successfully proposed this collaboration and we have notified ${chatName}. To increase your chances of being accepted, make sure to
          create and share links for your favorite ${chatName} products. ${chatName} is able to see all the volume and traffic you've driven to their
          site.`}
        </div>
        <div className='alert-actions'>
          <div onClick={resetContractToEditing} className='alert-action basic'>
            Edit Collaboration
          </div>
          {isBrand(user) ? (
            <div onClick={openChat} className='alert-action go-to-chat'>
              Go to chat
            </div>
          ) : (
            <Link to={chatHref} className='alert-action go-to-chat'>
              Go to chat
            </Link>
          )}
        </div>
      </div>
    );
  }
  if (isPending && !canEdit) {
    alertMsg = (
      <div className='alert-message'>
        <div>{chatName} is currently editing this proposal.</div>
        <div className='alert-actions'>
          {isBrand(user) ? (
            <div onClick={openChat} className='alert-action go-to-chat'>
              Go to chat
            </div>
          ) : (
            <Link to={chatHref} className='alert-action go-to-chat'>
              Go to chat
            </Link>
          )}
        </div>
      </div>
    );
  }
  if (isRejected) {
    alertMsg = (
      <div className='alert-message'>
        {isResponding ? (
          <div>You dismissed this collaboration proposal.</div>
        ) : (
          <div>{chatName} is not currently interested in this collaboration.</div>
        )}
        {isResponding && (
          <div className='alert-actions'>
            <div className='alert-action undo-rejection' onClick={undoRejection}>
              Undo
            </div>
          </div>
        )}
      </div>
    );
  }

  if (isClosed) {
    alertMsg = (
      <div className='alert-message'>
        <div>This collaboration was closed and analytics stopped tracking on {moment(visibleContract.closedDate).format('MMMM Do')}.</div>
        <div className='alert-actions'>
          {isBrand(user) ? (
            <div onClick={openChat} className='alert-action basic go-to-chat'>
              Go to chat
            </div>
          ) : (
            <Link to={chatHref} className='alert-action basic go-to-chat'>
              Go to chat
            </Link>
          )}
          <div className='alert-action undo-rejection' onClick={undoClose}>
            Continue Tracking
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <RequiresPermissions permission='canAnswerCollaborations'>
        <div className='contract-outer-container'>
          <div className='contract-inner-container'>
            {isFetching ? (
              <div className='loading-contract'>
                <Loader />
              </div>
            ) : (
              visibleContract && (
                <>
                  <ContractHeader canEdit={canEdit} openUserModal={openUserModal} updateContract={updateContract} contract={visibleContract} />
                  {steps.length > 1 && <ContractProgress selectStep={selectStep} user={user} canEdit={canEdit} steps={steps} openChat={openChat} />}
                  {alertMsg}
                  {activeStep?.type === 'tasks' && (
                    <ContractProposeTasks
                      user={user}
                      contract={visibleContract}
                      collaborations={collaborations}
                      canEdit={canEdit}
                      updateContract={updateContract}
                      createContractTask={props.createContractTask}
                      updateContractTask={props.updateContractTask}
                      deleteContractTask={props.deleteContractTask}
                      getContractTaskTemplates={props.getContractTaskTemplates}
                    />
                  )}
                  {activeStep?.type === 'schedule' && (
                    <ContractProposeSchedule canEdit={canEdit} contract={visibleContract} updateContractTask={props.updateContractTask} />
                  )}
                  {activeStep?.type === 'links' && (
                    <ContractProposeLinks
                      user={user}
                      contract={visibleContract}
                      canEdit={canEdit}
                      createContractLink={props.createContractLink}
                      deleteContractLink={props.deleteContractLink}
                      updateContractLink={props.updateContractLink}
                      createContractUpload={props.createContractUpload}
                      updateContractUpload={props.updateContractUpload}
                      deleteContractUpload={props.deleteContractUpload}
                    />
                  )}
                  {activeStep?.type === 'rates' && (
                    <ContractProposeRatesCodes
                      user={user}
                      contract={visibleContract}
                      analytics={analytics}
                      canEdit={canEdit}
                      openUserModal={openUserModal}
                      getBrandAnalytics={getBrandAnalytics}
                      createContractRateCode={props.createContractRateCode}
                      updateContractRateCode={props.updateContractRateCode}
                      deleteContractRateCode={props.deleteContractRateCode}
                    />
                  )}
                  {activeStep?.type === 'review' && (
                    <ContractReview user={user} contract={visibleContract} collaborations={collaborations} analytics={analytics} canEdit={canEdit} />
                  )}
                  {activeStep?.type === 'livetasks' && (
                    <ContractTasksTab
                      user={user}
                      contract={visibleContract}
                      updateContractTask={props.updateContractTask}
                      completeContract={completeContract}
                      selectStepByType={selectStepByType}
                      getContractAnalytics={props.getContractAnalytics}
                      deletePin={props.deletePin}
                      toggleAddOrEditPinMode={toggleAddOrEditPinMode}
                      syncContract={syncContractAndAnalytics}
                      connectInstagram={props.connectInstagram}
                      openConnectInstagramModal={props.openConnectInstagramModal}
                    />
                  )}
                  {activeStep?.type === 'uploads' && (
                    <ContractUploadsTab
                      user={user}
                      contract={visibleContract}
                      createContractUpload={props.createContractUpload}
                      updateContractUpload={props.updateContractUpload}
                      deleteContractUpload={props.deleteContractUpload}
                    />
                  )}
                  {activeStep?.type === 'analytics' && (
                    <ContractAnalyticsTab
                      user={user}
                      contract={visibleContract}
                      getContractAnalytics={props.getContractAnalytics}
                      deletePin={props.deletePin}
                      toggleAddOrEditPinMode={toggleAddOrEditPinMode}
                      selectStepByType={selectStepByType}
                      syncContract={syncContractAndAnalytics}
                      connectInstagram={props.connectInstagram}
                      openConnectInstagramModal={props.openConnectInstagramModal}
                    />
                  )}
                  {activeStep && !activeStep?.isTab ? (
                    <div className='footer-actions'>
                      {activeStep.idx > 0 && (
                        <div className='footer-action back' onClick={() => selectStep(steps[activeStepIndex - 1])}>
                          Back
                        </div>
                      )}
                      {activeStep.idx < steps.length - 1 && (
                        <div className='footer-action next' onClick={() => selectStep(steps[activeStepIndex + 1])}>
                          Next
                        </div>
                      )}
                      {activeStep.idx !== steps.length - 1 ? null : canEdit ? (
                        isPending ? (
                          <div className='footer-action done' onClick={submitContract}>
                            Submit Proposal
                          </div>
                        ) : (
                          isAdmin(user) && (
                            <div onClick={openUserModal} className='footer-action profile'>
                              {getNameWithS(visibleContract.user).first} Profile
                            </div>
                          )
                        )
                      ) : (
                        isProposed && (
                          <>
                            {isBrand(user) ? (
                              <div onClick={openChat} className='footer-action chat'>
                                Chat with {chatName}
                              </div>
                            ) : (
                              <Link to={chatHref} className='footer-action chat'>
                                Chat with {chatName}
                              </Link>
                            )}
                            {isBrand(user) && !isCreatedByBrand && (
                              <div onClick={openUserModal} className='footer-action profile'>
                                {getNameWithS(visibleContract.user).first} Profile
                              </div>
                            )}
                            <div className='footer-action reject' onClick={rejectContract}>
                              Dismiss Proposal
                            </div>
                            <div className='footer-action done' onClick={acceptContract}>
                              Accept Proposal
                            </div>
                          </>
                        )
                      )}
                    </div>
                  ) : (
                    isActive &&
                    isBrand(user) && (
                      <div>
                        <div className='footer-actions'>
                          <div className='footer-action done' onClick={closeContract}>
                            Close Collaboration
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )
            )}
          </div>
        </div>
      </RequiresPermissions>
      {(pinBeingEdited || inAddMode) && (
        <AddPinModal
          isQuickLinkMode
          inAddMode={!pinBeingEdited}
          pinBeingEdited={pinBeingEdited}
          toggleAddingMode={toggleAddOrEditPinMode}
          syncExternalState={syncContractAndAnalytics}
          Editing_User_id={visibleContract?.user?.id}
          Editing_Contract_id={visibleContract?.id}
        />
      )}
    </>
  );
};

Contract.propTypes = {
  user: PropTypes.object.isRequired,
  collaborations: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  getContract: PropTypes.func.isRequired,
  getContractAnalytics: PropTypes.func.isRequired,
  updateContract: PropTypes.func.isRequired,
  createContractTask: PropTypes.func.isRequired,
  updateContractTask: PropTypes.func.isRequired,
  deleteContractTask: PropTypes.func.isRequired,
  getContractTaskTemplates: PropTypes.func.isRequired,
  createContractLink: PropTypes.func.isRequired,
  deleteContractLink: PropTypes.func.isRequired,
  updateContractLink: PropTypes.func.isRequired,
  createContractUpload: PropTypes.func.isRequired,
  updateContractUpload: PropTypes.func.isRequired,
  deleteContractUpload: PropTypes.func.isRequired,
  createContractRateCode: PropTypes.func.isRequired,
  updateContractRateCode: PropTypes.func.isRequired,
  deleteContractRateCode: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openBrandBudgetModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, collaborations, analytics, ui } = state;
  return { user, collaborations, analytics, ui };
};

export default connect(mapStateToProps, {
  getContract,
  getContractAnalytics,
  updateContract,
  getContractTaskTemplates,
  createContractTask,
  updateContractTask,
  deleteContractTask,
  createContractLink,
  deleteContractLink,
  updateContractLink,
  createContractUpload,
  updateContractUpload,
  deleteContractUpload,
  createContractRateCode,
  updateContractRateCode,
  deleteContractRateCode,
  deletePin,
  getBrandAnalytics,
  toggleBodyScrollLock,
  connectInstagram,
  openConnectInstagramModal,
  openArtistModal,
  openChatOverlay,
  openBrandBudgetModal
})(Contract);
