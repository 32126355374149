import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './HomeBrandFeatures.scss';

import { BRAND_HOME_FEATURES } from '../../../Helpers/home_helpers';

const HomeBrandFeatures = props => {
  const videoRef = React.useRef(null);

  // Start playing the video 1s after it comes into view
  React.useEffect(() => {
    const DELAY_BEFORE_STARTING = 250;
    const observer = new IntersectionObserver(entries =>
      entries.forEach(entry => entry.isIntersecting && setTimeout(() => videoRef.current.play(), DELAY_BEFORE_STARTING))
    );
    videoRef.current && observer.observe(videoRef.current);
    return () => videoRef.current && observer.unobserve(videoRef.current);
  }, []);
  return (
    <div className='home-brand-features-outer-container'>
      <div className='home-brand-features-inner-container'>
        <div className='features-content'>
          <h4 className='features-title'>A single platform to streamline your creator partnerships</h4>
          <div className='features'>
            {BRAND_HOME_FEATURES.map((feature, idx) => {
              const click = () => props.openBrandDemoModal();
              return (
                <div onClick={click} className='feature' key={idx}>
                  <div className='feature-text'>{feature}</div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              );
            })}
          </div>
        </div>
        <div className='feature-animation'>
          <video ref={videoRef} loop muted playsInline src='https://static.myshlf.us/Home/Animations/home_brands_platform.mp4' type='video/mp4' />
        </div>
      </div>
    </div>
  );
};

HomeBrandFeatures.propTypes = {
  openBrandDemoModal: PropTypes.func.isRequired
};

export default HomeBrandFeatures;
