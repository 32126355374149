import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './HeaderSubpagePanel.scss';

const HeaderSubpagePanel = props => {
  const { subpages } = props;

  const [isHidingFocus, setIsHidingFocus] = React.useState(false);
  const hideFocus = () => {
    setIsHidingFocus(true);
    setTimeout(() => setIsHidingFocus(false), 100);
  };

  // Hide on navigation changes
  React.useEffect(() => {
    const hide = () => setIsHidingFocus(true);
    window.addEventListener('popstate', hide);
    return () => {
      window.removeEventListener('popstate', hide);
    };
  }, []);
  if (isHidingFocus) return null;

  return (
    <div className='header-subpage-panel'>
      <div className='header-subpage-panel-inner'>
        <div className='subpages'>
          {subpages.map(page => {
            return (
              <Link key={page.to} to={page.to} onClick={hideFocus} className='subpage'>
                <div className='subpage-header'>
                  <img src={page.icon || 'https://via.placeholder.com/20'} alt='placeholder' />
                  <div className='subpage-title'>{page.header}</div>
                </div>
                <div className='subpage-description'>{page.subheader}</div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HeaderSubpagePanel.propTypes = {
  subpages: PropTypes.array.isRequired
};

export default HeaderSubpagePanel;
