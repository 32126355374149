import APIClient from './index';
import _ from 'lodash';
import { get400ErrorMessage } from './index';

export const getPostByTitleStub = titleStub => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BlogPosts/${titleStub}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getAllBlogTabs = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BlogTabs/all_tabs`)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const createBlogTab = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogTabs`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const updateBlogTab = (tab, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BlogTabs/${tab.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const deleteBlogTab = tab => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/BlogTabs/${tab.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const searchForPosts = query => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BlogPosts/posts/search?query=${query}`)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const getPosts = () => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BlogPosts`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createPost = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogPosts`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const editPost = (post, body) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BlogPosts/${post.id}`, body)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'], 'There was an error editing this blog post.')));
  });
};

export const deletePost = (post, body) => {
  return APIClient.delete(`/BlogPosts/${post.id}`)
    .then(response => response.data)
    .catch(error => console.error(error));
};

export const createBlogElement = (post, type) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogElements`, { Post_id: post.id, type })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const editBlogElement = (element, body) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BlogElements/${element.id}`, body)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'], 'There was an error editing this blog element.')));
  });
};

export const deleteBlogElement = (element, body) => {
  return APIClient.delete(`/BlogElements/${element.id}`)
    .then(response => response.data)
    .catch(error => console.error(error));
};

export const searchForElements = (query, type) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogElements/find`, { query, type })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const fetchMoreStories = postId => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BlogPosts/posts/more_stories?postId=${postId}&cacheBuster=${Date.now()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const addPostToTab = (tab, post) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogTabs/add_post`, { BlogPost_id: post.id, BlogTab_id: tab.id })
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};

export const removePostFromTab = (tab, post) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BlogTabs/remove_post`, { BlogPost_id: post.id, BlogTab_id: tab.id })
      .then(response => resolve(response.data))
      .catch(error => reject(get400ErrorMessage(error)));
  });
};
