import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './OpportunitySetupGuideSidebar.scss';

const OpportunitySetupGuideSidebar = props => {
  const { steps } = props;
  return (
    <div className='opportunity-setup-guide-sidebar'>
      <div className='guide-title-container'>
        <div className='guide-title'>Setup Guide</div>
      </div>
      <div className='guide-steps'>
        {steps.map(step => {
          const { isLast, title, completeDisplayTitle, index, isActive, isComplete, isUpcoming } = step;
          const select = () => props.selectStep(step);
          const additionalClasses = { active: isActive, complete: isComplete, upcoming: isUpcoming };
          return (
            <React.Fragment key={title}>
              <div onClick={select} className={cn('guide-step', additionalClasses)}>
                <div className={cn('step-circle', additionalClasses)}>
                  {isComplete && !isUpcoming ? <FontAwesomeIcon icon={faCheck} /> : index + 1}
                </div>
                <div className={cn('step-title', additionalClasses)}>{isUpcoming || isActive ? title : completeDisplayTitle || title}</div>
              </div>
              {!isLast && <div className={cn('step-connector', additionalClasses)} />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

OpportunitySetupGuideSidebar.propTypes = {
  steps: PropTypes.array.isRequired,
  selectStep: PropTypes.func.isRequired
};

export default OpportunitySetupGuideSidebar;
