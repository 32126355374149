import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import cn from 'classnames';
import './ArtistModalSimilarTalentResults.scss';

import Loader from '../../Loader/Loader';
import ArtistModalSimilarTalentResult from './ArtistModalSimilarTalentResult';

import { isSubscribedToFeature } from '../../../Helpers/subscription_helpers';

const ArtistModalSimilarTalentResults = props => {
  const { ui, user, analytics, similarTalent, isFetchingNewTalent } = props;
  const [isDetailedAnalytics, setIsDetailedAnalytics] = useState(false);

  const setModalUser = talent => props.setModalUser(talent);

  const noResults = similarTalent && similarTalent.length === 0;

  const onlyShowPreviewOfFeature = !isSubscribedToFeature(user, 'LOOKALIKES');
  return (
    <div className='similar-talent-container'>
      <div className='analytics-toggles'>
        {!noResults && (
          <div className='analytics-toggle'>
            <div onClick={() => setIsDetailedAnalytics(!isDetailedAnalytics)}>Show Detailed Analytics</div>
            <Switch
              onChange={() => setIsDetailedAnalytics(!isDetailedAnalytics)}
              checked={isDetailedAnalytics}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        )}
      </div>
      <div className={cn('similar-talent-results-container', noResults ? 'empty' : isDetailedAnalytics ? 'list' : 'grid')}>
        {isFetchingNewTalent || !similarTalent ? (
          <div className='loading-results'>
            <Loader size={80} />
          </div>
        ) : noResults ? (
          <div className='empty-results-msg-container'>
            <div className='empty-results-msg'>We do not yet have enough data to show similar talent</div>
          </div>
        ) : (
          <>
            {onlyShowPreviewOfFeature && (
              <div className='only-preview-message-container'>
                <div className='header'>Discover Similar Talent</div>
                <div className='message'>
                  This feature analyzes public social data, sales and conversion performance on a per category and per brand level as well as advanced
                  machine learning techniques to help you discover new talent to partner with.
                  <br />
                  <br />
                  This is a preview, for full access you must upgrade your subscription.
                </div>
              </div>
            )}
            <div className={cn('similar-talent-results', isDetailedAnalytics ? 'list' : 'grid')}>
              {similarTalent.map((talent, index) => (
                <ArtistModalSimilarTalentResult
                  talent={talent}
                  analytics={analytics}
                  user={user}
                  ui={ui}
                  index={index}
                  onlyShowPreviewOfFeature={onlyShowPreviewOfFeature}
                  setModalUser={setModalUser}
                  key={`talent-${talent.id}`}
                  isDetailedAnalytics={isDetailedAnalytics}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ArtistModalSimilarTalentResults.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  similarTalent: PropTypes.array.isRequired,
  isFetchingNewTalent: PropTypes.bool.isRequired,
  setModalUser: PropTypes.func.isRequired
};

export default ArtistModalSimilarTalentResults;
