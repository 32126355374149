import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faExclamationTriangle, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './UserTierPanel.scss';

import { getUserTier } from '../../Helpers/user_helpers';
import { getPrettyNumber } from '../../Helpers/formatting';
import {
  getUserTiers,
  isUserAtRiskOfTierDemotion,
  getDaysUntilTierDemotion,
  getTierMeterData,
  getProfileScoreMeterData,
  getActivityScoreMeterData,
  getTrafficScoreMeterData,
  getVolumeScoreMeterData,
  getReferralScoreMeterData
} from '../../Helpers/tier_helpers';
import { REFERRAL_PAYOUT_RATE, REFERRAL_LENGTH_MONTHS_DISPLAY } from '../../Helpers/referral_helpers';
import { getDaysSinceJoining, getValidReferralCount, getInvalidReferralCount } from '../../Helpers/user_helpers';

const UserTierPanel = props => {
  const { user } = props;
  const userTiers = getUserTiers();
  const userTier = getUserTier(user);
  const invalidReferralCount = getInvalidReferralCount(user);
  const validReferralCount = getValidReferralCount(user);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const daysSinceJoining = getDaysSinceJoining(user);

  if (!userTier) return null;

  const getMeter = ({ score, values, pointsInt, pointsLabel }) => {
    /*
      See tier_helpers.js formatting descriptions.
    */
    const cappedScore = Math.min(score, 100);
    return (
      <div className='meter'>
        <div className='spine' />
        <div className={cn('spine active')} style={{ width: `calc(${cappedScore}% + 24px)` }} />
        {values.map((val, idx) => {
          const to = val.getLinkToFromUserObject ? val.getLinkToFromUserObject(user) : null;
          return (
            <div
              key={val.value}
              className={cn('mark-container', { complete: cappedScore >= val.value })}
              style={{ left: `calc(${_.min([val.value, 100])}% - 12px)` }}
            >
              <div className='mark'>{val.valueLabel}</div>
              {to ? (
                <>
                  {val.label && (
                    <Link to={to} className='mark-label desktop-only'>
                      {val.label}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  )}
                  {val.shortLabel && (
                    <Link to={to} className='mark-label mobile-only'>
                      {val.shortLabel}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {val.label && <div className='mark-label desktop-only'>{val.label}</div>}
                  {val.shortLabel && <div className='mark-label mobile-only'>{val.shortLabel}</div>}
                </>
              )}
            </div>
          );
        })}
        <div className='mark-container' style={{ left: `calc(${cappedScore}% - 12px)` }}>
          <div className='mark current'>{pointsInt}</div>
          {pointsLabel && <div className='mark-label-pointer' />}
          {pointsLabel && <div className='mark-label current'>{pointsLabel}</div>}
        </div>
      </div>
    );
  };

  // Meter representing their aggregate score
  const mainMeterData = getTierMeterData(user);

  // Meter representing their score components
  const profileMeterData = getProfileScoreMeterData(user);
  const activityMeterData = getActivityScoreMeterData(user);
  const trafficMeterData = getTrafficScoreMeterData(user);
  const volumeMeterData = getVolumeScoreMeterData(user);
  const referralMeterData = getReferralScoreMeterData(user);

  // Warnings
  const isAtRiskOfDemotion = isUserAtRiskOfTierDemotion(user);
  const daysUntilDemotion = getDaysUntilTierDemotion(user);

  return (
    <div className='user-tier-panel-outer-container'>
      <div className='title-container'>
        <div className='title'>
          <div>Your account tier</div>
          <div onClick={toggleExpanded} className='expand-btn'>
            Learn More
            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
          </div>
        </div>
        <div className='subtitle'>
          This tier impacts the functionality unlocked on the platform. For more explanation on the rankings,{' '}
          {isExpanded ? '' : `click "Learn More" or `}check out our guide{` `}
          <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/creator-tiers'>
            here
          </a>
          .
        </div>
        {!isExpanded && isAtRiskOfDemotion && (
          <div className='warning-msg'>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            You still have {daysUntilDemotion} day{daysUntilDemotion === 1 ? '' : 's'} to get your points back up above your tier minumum before we
            will update it.
          </div>
        )}
      </div>
      <div className='meter-container'>
        {getMeter(mainMeterData)}
        <div className='sections'>
          {userTiers.map((ut, idx) => {
            const nextTier = userTiers[idx + 1];
            const isActive = ut.tier === userTier.tier;
            return (
              <div key={ut.label} className={cn('section', { active: isActive })} style={{ width: `${(nextTier?.minScore || 100) - ut.minScore}%` }}>
                <div className={cn('label', { 'desktop-only': !isActive })}>{ut.label}</div>
                {isActive && <div className={cn('sublabel', { 'desktop-only': !isActive })}>{ut.explainer}</div>}
              </div>
            );
          })}
        </div>
      </div>
      {isExpanded && (
        <>
          <div className='secondary-title-container'>
            <div className='title'>
              <div>What You've Unlocked</div>
            </div>
            <div className='subtitle'></div>
          </div>
          <div className='unlocked-grid'>
            {userTiers.map(ut => {
              return (
                <div
                  key={ut.label}
                  className={cn('unlocked-grid-column', {
                    unlocked: userTier.tier < ut.tier,
                    current: userTier.tier === ut.tier,
                    locked: userTier.tier > ut.tier
                  })}
                >
                  <div className='header cell'>{ut.label}</div>
                  {ut?.unlocked.map(unlock => (
                    <div key={unlock} className='cell'>
                      <span className='bullet'>•</span>
                      {unlock}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </>
      )}
      {isExpanded && (
        <>
          <div className='secondary-title-container'>
            <div className='title'>
              <div>How We Calculated Your Tier</div>
            </div>
            <div className='subtitle'>
              We calculate your tier based on a 100 point scale of where you fall on the following traits. To read more on how tiering works, check
              out our tiering explainer guide{' '}
              <a target='_blank' rel='noopener noreferrer' href='https://guide.shopmy.us/creator-tiers'>
                here
              </a>
              .
            </div>
          </div>
          <div className='meter-section'>
            <div className='meter-explainer-container'>
              <div className='meter-title'>
                Your Profile
                <span className='meter-points'>
                  {getPrettyNumber(profileMeterData.points)} out of {profileMeterData.maxPoints}
                </span>
              </div>
              <div className='meter-desc'>
                Brands want to learn as much as possible about who you are, the products you talk about and what you offer.
              </div>
            </div>
            <div className='meter-container'>{getMeter(profileMeterData)}</div>
          </div>
          <div className='meter-section'>
            <div className='meter-explainer-container'>
              <div className='meter-title'>
                Recent Activity
                <span className='meter-points'>
                  {getPrettyNumber(activityMeterData.points)} out of {activityMeterData.maxPoints}
                </span>
              </div>
              <div className='meter-desc'>
                Brands want to make sure you are actively generating links and responding to gifting requests on the platform.
              </div>
            </div>
            <div className='meter-container'>{getMeter(activityMeterData)}</div>
          </div>
          <div className='meter-section'>
            <div className='meter-explainer-container'>
              <div className='meter-title'>
                Monthly Traffic ( Product / Link Clicks )
                <span className='meter-points'>
                  {getPrettyNumber(trafficMeterData.points)} out of {trafficMeterData.maxPoints}
                </span>
              </div>
              <div className='meter-desc'>
                {daysSinceJoining >= 90
                  ? `Brands want to make sure you drive traffic to products on a consistent basis. This is calculated over the past 3 months.`
                  : `Brands want to make sure you drive traffic to products on a consistent basis. This is calculated over the past 3 months. Since you joined ${daysSinceJoining} day${
                      daysSinceJoining === 1 ? '' : 's'
                    } ago, this is calculated as your daily average of clicks multiplied by 30.`}
              </div>
            </div>
            <div className='meter-container'>{getMeter(trafficMeterData)}</div>
          </div>
          <div className='meter-section'>
            <div className='meter-explainer-container'>
              <div className='meter-title'>
                Monthly Order Volume
                <span className='meter-points'>
                  {getPrettyNumber(volumeMeterData.points)} out of {volumeMeterData.maxPoints}
                </span>
              </div>
              <div className='meter-desc'>
                {daysSinceJoining >= 90
                  ? `Brands want to make sure your audience shops your recommendations. This is calculated over the past 3 months.`
                  : `Brands want to make sure your audience shops your recommendations. This is calculated over the past 3 months. Since you joined ${daysSinceJoining} day${
                      daysSinceJoining === 1 ? '' : 's'
                    } ago, this is calculated as your daily average of order volume multiplied by 30.`}
              </div>
            </div>
            <div className='meter-container'>{getMeter(volumeMeterData)}</div>
          </div>
          <div className='meter-section'>
            <div className='meter-explainer-container'>
              <div className='meter-title'>
                Growing The Community
                <span className='meter-points'>
                  {getPrettyNumber(referralMeterData.points)} out of {referralMeterData.maxPoints} bonus points
                </span>
              </div>
              <div className='meter-desc'>
                As the ShopMy community grows, your opportunities will grow! This won't negatively impact your score but can act as bonus points to
                get you to the next tier - also, we'll send you an additional {REFERRAL_PAYOUT_RATE}% of their earnings for the first{' '}
                {REFERRAL_LENGTH_MONTHS_DISPLAY}
                {` `}
                months!
                {invalidReferralCount
                  ? ` Please note that in order for a referral to count, they must maintain a score of 6 or higher after the first 30 days - currently only ${validReferralCount} of your ${invalidReferralCount +
                      validReferralCount} referrals fit this metric.`
                  : ''}
              </div>
            </div>
            <div className='meter-container'>{getMeter(referralMeterData)}</div>
          </div>
        </>
      )}
      <div onClick={toggleExpanded} className={cn('expand-footer-btn', { expanded: isExpanded })}>
        {isExpanded ? 'Hide Details' : 'See how we calculate your tier'}
        <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
      </div>
    </div>
  );
};

UserTierPanel.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserTierPanel;
