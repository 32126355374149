import React, { useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './ContractTasksTab.scss';

import ContractTask from './Elements/ContractTask';
import ContractContentCard from './Elements/ContractContentCard';

import { isBrand } from '../../Helpers/user_helpers';

const ContractTasksTab = props => {
  const { contract, completeContract } = props;
  const { tasks, status } = contract;
  const contractStatus = contract.status;
  const isClosed = status === 'closed';

  const visibleTasks = tasks.filter(task => !task.template.isAddOn);
  const statuses = _.uniq(_.map(visibleTasks, 'status'));

  useEffect(() => {
    const allComplete = !statuses.find(s => s !== 'complete');
    if (allComplete && contractStatus !== 'complete') {
      completeContract();
    }
  }, [statuses, contractStatus, completeContract]);

  return (
    <>
      <div className='contract-tasks-tab-outer-container card'>
        {visibleTasks.map(task => (
          <ContractTask
            key={task.id}
            task={task}
            user={props.user}
            selectStepByType={props.selectStepByType}
            contract={props.contract}
            updateContract={props.updateContract}
            updateContractTask={props.updateContractTask}
          />
        ))}
      </div>
      {!isBrand(props.user) && !isClosed && (
        <ContractContentCard
          user={props.user}
          contract={contract}
          canEdit
          isPreviewMode
          selectStepByType={props.selectStepByType}
          getContractAnalytics={props.getContractAnalytics}
          toggleAddOrEditPinMode={props.toggleAddOrEditPinMode}
          syncContract={props.syncContract}
          deletePin={props.deletePin}
          connectInstagram={props.connectInstagram}
          openConnectInstagramModal={props.openConnectInstagramModal}
        />
      )}
    </>
  );
};

ContractTasksTab.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  updateContractTask: PropTypes.func.isRequired,
  completeContract: PropTypes.func.isRequired,
  getContractAnalytics: PropTypes.func.isRequired,
  selectStepByType: PropTypes.func.isRequired,
  connectInstagram: PropTypes.func.isRequired,
  openConnectInstagramModal: PropTypes.func.isRequired
};

export default ContractTasksTab;
