import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import './AddPinModalEmpty.scss';

import PartnerTile from '../Partners/PartnerTile';
import SnapshopExplainerPanel from '../Partners/SnapshopExplainerPanel';

class AddPinModalEmpty extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    isUrl: PropTypes.bool.isRequired,
    isPin: PropTypes.bool.isRequired,
    isSearch: PropTypes.bool.isRequired,
    isRates: PropTypes.bool.isRequired,
    selectPopularBrand: PropTypes.func.isRequired,
    lockedCatalogBrand: PropTypes.object
  };

  render() {
    const { user, isUrl, isSearch, isRates, store, lockedCatalogBrand } = this.props;
    const { featured, highRateMerchants } = store;
    return (
      <div className='add-pin-modal-empty-outer-container'>
        {isUrl ? (
          <div className='explainer-panel'>
            <SnapshopExplainerPanel fullPanel user={user} />
          </div>
        ) : isSearch ? (
          lockedCatalogBrand ? null : (
            <>
              <div className={classnames('add-pin-modal-empty-header-left', 'search')}>Trending Brands</div>
              <div className='featured-brands'>
                {_.map(featured, (partner, idx) => (
                  <div key={partner.name} className='featured-container'>
                    <PartnerTile
                      animateWaterfall
                      idx={idx}
                      user={user}
                      partner={partner}
                      onClick={() => this.props.selectPopularBrand(partner.name)}
                    />
                  </div>
                ))}
              </div>
            </>
          )
        ) : isRates ? (
          <>
            <div className={classnames('add-pin-modal-empty-header-left', 'search')}>Partners with High Commission Rates</div>
            <div className='featured-brands'>
              {_.map(highRateMerchants, (partner, idx) => (
                <div key={partner.name} className='featured-container'>
                  <PartnerTile animateWaterfall idx={idx} user={user} partner={partner} onClick={() => this.props.selectPopularBrand(partner.name)} />
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

export default AddPinModalEmpty;
