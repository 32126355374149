import React from 'react';
import PropTypes from 'prop-types';

import './ContractUploadsTab.scss';

import ContractUploadsCard from './Elements/ContractUploadsCard';

const ContractUploadsTab = props => {
  return (
    <div className='contract-uploads-tab-outer-container'>
      <ContractUploadsCard
        user={props.user}
        contract={props.contract}
        canEdit
        createContractUpload={props.createContractUpload}
        updateContractUpload={props.updateContractUpload}
        deleteContractUpload={props.deleteContractUpload}
      />
    </div>
  );
};

ContractUploadsTab.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,

  // For editing
  createContractUpload: PropTypes.func,
  updateContractUpload: PropTypes.func,
  deleteContractUpload: PropTypes.func
};

export default ContractUploadsTab;
