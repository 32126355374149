import APIClient from './index';
import _ from 'lodash';

export const getNewsletter = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Newsletters/latest?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
