import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Select from 'react-select';
import _ from 'lodash';
import './BulkTalentModalBody.scss';

import { matchScrollHeight } from '../../Helpers/helpers';
import { getBrandSettings, getBrand } from '../../Helpers/user_helpers';
import { getBrandCommissionRate } from '../../Helpers/brand_helpers';

import SelectOption from '../General/SelectOption';

const BulkTalentModalBody = props => {
  const { user, curMessage, curRate, outreachType } = props;
  const settings = getBrandSettings(user);
  const brand = getBrand(user);

  /*
    Templates Handling
  */
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [updatingTemplate, setUpdatingTemplate] = useState(false);
  const canChangeTemplate = selectedTemplate && (settings[selectedTemplate.value] || '') !== curMessage;
  const templatesOptions = [
    {
      label: 'Outreach Template',
      value: 'sendOutreachTemplate'
    }
    // {
    //   label: 'Send Gifting Template',
    //   value: 'sendGiftingTemplate'
    // }
  ];
  const updateTemplate = () => {
    setUpdatingTemplate(true);
    props.updateBrandSettings({ [selectedTemplate.value]: curMessage }).then(() => {
      setUpdatingTemplate(false);
    });
  };
  const selectTemplate = data => {
    setSelectedTemplate(data);
    props.setCurMessage(settings[data.value] || '');
    setCurCursorLocation(0);
  };

  /*
    Variables Handling
  */
  const [curCursorLocation, setCurCursorLocation] = useState(0);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keyup', e => setCurCursorLocation(inputRef.current?.selectionStart));
      inputRef.current.addEventListener('mouseup', e => setCurCursorLocation(inputRef.current?.selectionStart));
    }
  }, [inputRef]);
  const variableOptions = [
    { value: '{{FIRSTNAME}}', label: 'First Name' },
    { value: '{{FULLNAME}}', label: 'Full Name' },
    { value: '{{BRANDNAME}}', label: 'Brand Name' }
  ];
  const selectVariableToAdd = data => {
    const newMessage = curMessage.slice(0, curCursorLocation) + data.value + curMessage.slice(curCursorLocation);
    props.setCurMessage(newMessage);
  };

  return (
    <div className='bulk-talent-modal-body'>
      <div className='header-controls'>
        <Select
          unstyled
          placeholder='Use Templates'
          classNamePrefix='template-select'
          onChange={selectTemplate}
          className='template-select'
          isOptionDisabled={option => option.disabled}
          options={templatesOptions}
          value={selectedTemplate}
          components={{ Option: SelectOption }}
        />
        <Select
          unstyled
          placeholder='Add Variable'
          classNamePrefix='variable-select'
          onChange={selectVariableToAdd}
          className='variable-select'
          options={variableOptions}
          value={null}
          components={{ Option: SelectOption }}
        />
      </div>
      <div className='message-template'>
        {outreachType.showRate && (
          <input
            className='text-input'
            placeholder={`${getBrandCommissionRate(brand) || 20}%`}
            onChange={({ target }) => props.setCurRate(target.value)}
            rows={12}
            value={curRate}
          />
        )}
        <textarea
          className='text-input'
          placeholder='Enter the message you would like to send.'
          onChange={({ target }) => props.setCurMessage(target.value)}
          rows={12}
          ref={ref => {
            matchScrollHeight(ref);
            inputRef.current = ref;
          }}
          value={curMessage}
        />
        <div className='action-btns'>
          {canChangeTemplate && (
            <div onClick={updateTemplate} className='action-btn update-template'>
              {updatingTemplate ? 'Updating...' : 'Update Template'}
            </div>
          )}
        </div>
        {props.filesToUpload.length > 0 && (
          <div className='attachments'>
            <div className='attachments-title'>Attachments</div>
            <div className='attachments-list'>
              {props.filesToUpload.map((file, index) => {
                const name =
                  _.last(file.split('/'))
                    .split('_')
                    .slice(1)
                    .join('') || 'File';
                const remove = () => props.setFilesToUpload(_.without(props.filesToUpload, file));
                return (
                  <div key={index} className='attachment'>
                    <div className='attachment-name'>{name}</div>
                    <div className='attachment-remove' onClick={remove}>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BulkTalentModalBody.propTypes = {
  user: PropTypes.object.isRequired,
  curMessage: PropTypes.string.isRequired,
  setCurMessage: PropTypes.func.isRequired,
  curRate: PropTypes.string.isRequired,
  setCurRate: PropTypes.func.isRequired,
  updateBrandSettings: PropTypes.func.isRequired,
  outreachType: PropTypes.object.isRequired,
  filesToUpload: PropTypes.array.isRequired,
  setFilesToUpload: PropTypes.func.isRequired
};

export default BulkTalentModalBody;
