import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import './DiscoverUsers.scss';

import DiscoverUsersSidebar from './DiscoverUsersSidebar';
import DiscoverUsersControls from './DiscoverUsersControls';
import DiscoverUsersResults from './DiscoverUsersResults';

import { searchArtists } from '../../APIClient/brands';
import { addEvent } from '../../APIClient/events';

import { openArtistModal } from '../../Actions/UIActions';
import { getTags } from '../../Actions/DiscoverActions';
import { updateBrandList, deleteBrandList } from '../../Actions/BrandActions';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';
import {
  addRecommendedArtist,
  deleteRecommendedArtist,
  deleteRecommendedArtistRejection,
  updateRecommendedArtist
} from '../../Actions/RecommendationActions';
import { openChatOverlay } from '../../Actions/UIActions';

import { getBrandId } from '../../Helpers/user_helpers';

class DiscoverUsers extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    analytics: PropTypes.object.isRequired,
    discover: PropTypes.object.isRequired,
    openArtistModal: PropTypes.func.isRequired,
    deleteRecommendedArtist: PropTypes.func.isRequired,
    deleteRecommendedArtistRejection: PropTypes.func.isRequired,
    updateBrandList: PropTypes.func.isRequired,
    deleteBrandList: PropTypes.func.isRequired,
    openChatOverlay: PropTypes.func.isRequired
  };

  componentDidMount = () => {
    this.searchDebouncer = AwesomeDebouncePromise(this.fetchData, 250);
    this.fetchData();

    this.setupInfiniteScroll();
  };

  state = {
    // Loading UI Handling
    page: 0,
    isFetching: false,
    isSearchingMore: false,
    receivedAllResults: false,

    // UI Handling
    hideMyPromoters: false,
    searchVal: '',
    activeQueries: [],

    // API results
    results: []
  };

  setupInfiniteScroll = () => {
    window.addEventListener('scroll', e => {
      const pixelsFromBottom = document.body.scrollHeight - window.pageYOffset - window.innerHeight;

      if (pixelsFromBottom < 500 && !this.state.isFetching && !this.state.receivedAllResults) {
        this.setState({ page: this.state.page + 1, isSearchingMore: true }, this.fetchData);
      }
    });
  };

  syncTags = () => this.fetchData({ tagsOnly: true });
  fetchAllData = () => this.fetchData({ allPages: true });
  fetchData = (fetchOptions = {}) => {
    /*
      Grab all artist results.

      Optional:
        allPages - Use this if you need to update data in place, as it confirms that all visible results will be updated,
          not just the most recent page. (imagine you scroll way down, then back to top and make an edit to a result)

    */
    const { searchVal, hideMyPromoters, activeQueries, page } = this.state;
    const { tagsOnly, allPages } = fetchOptions;

    // Grab relevant pieces from active queries
    const tagids = _.uniq(_.filter(_.map(activeQueries, 'Tag_id')));
    const listids = _.uniq(_.filter(_.map(activeQueries, 'BrandList_id')));
    const brands = _.map(
      _.filter(activeQueries, q => q.type === 'brand'),
      'value'
    );
    const onlyRecommendedForGifting = !!_.find(activeQueries, q => q.type === 'recommendedforgifting');
    const onlyRecommendedForCodes = !!_.find(activeQueries, q => q.type === 'recommendedforcodes');
    const onlyRecommendedForCollabs = !!_.find(activeQueries, q => q.type === 'recommendedforcollabs');
    const onlyMyPromoters = !!_.find(activeQueries, q => q.type === 'mypromoter');

    // Format POST body
    const LIMIT = 20;
    let options = { Brand_id: getBrandId(this.props.user), page: allPages ? 0 : page, limit: allPages ? LIMIT * (page + 1) : LIMIT };
    if (searchVal) options.query = searchVal;
    if (tagids.length) options.tagids = tagids;
    if (listids.length) options.listids = listids;
    if (brands.length) options.brands = brands;
    if (onlyMyPromoters) options.onlyMyPromoters = true;
    if (hideMyPromoters) options.hideMyPromoters = true;
    if (onlyRecommendedForGifting) options.onlyRecommendedForGifting = true;
    if (onlyRecommendedForCodes) options.onlyRecommendedForCodes = true;
    if (onlyRecommendedForCollabs) options.onlyRecommendedForCollabs = true;

    if (!tagsOnly) {
      this.setState({ isFetching: true });
      searchArtists(options)
        .then(resp => {
          const newArtistResults = allPages ? resp.results : _.concat(this.state.results.slice(0, LIMIT * page), resp.results);
          this.setState({
            results: newArtistResults,
            receivedAllResults: !resp.hasMoreResults
          });
        })
        .catch(error => {
          cogoToast.error('There was an error completing this search.');
        })
        .finally(() => {
          this.setState({ isFetching: false, isSearchingMore: false });
        });
    }
    this.props.getTags({
      tagids,
      listids,
      ...(hideMyPromoters ? { hideMyPromoters: true } : {}),
      ...(onlyMyPromoters ? { onlyMyPromoters: true } : {}),
      ...(onlyRecommendedForGifting ? { onlyRecommendedForGifting: true } : {}),
      ...(onlyRecommendedForCodes ? { onlyRecommendedForCodes: true } : {}),
      ...(onlyRecommendedForCollabs ? { onlyRecommendedForCollabs: true } : {})
    });
  };

  updateSearch = newVal => {
    this.setState({ searchVal: newVal, isFetching: true, page: 0, receivedAllResults: false }, this.searchDebouncer);
    newVal && addEvent('Discover - Perform Search', { query: newVal, user: this.props.user });
  };

  toggleActiveQuery = newQuery => {
    if (blockOnRequiringSubscription(this.props.user, 'DISCOVER')) return;

    const { activeQueries, searchVal } = this.state;
    const isActive = activeQueries.find(q => q.value === newQuery.value);

    let newActiveQueries = [];

    if (isActive) {
      newActiveQueries = activeQueries.filter(q => q.value !== newQuery.value);
    } else {
      // Only allow one of the recommendation types
      if (newQuery.type === 'recommendedforgifting') {
        newActiveQueries = [...activeQueries.filter(q => !['recommendedforcollabs', 'recommendedforcodes'].includes(q.type)), newQuery];
      } else if (newQuery.type === 'recommendedforcodes') {
        newActiveQueries = [...activeQueries.filter(q => !['recommendedforgifting', 'recommendedforcollabs'].includes(q.type)), newQuery];
      } else if (newQuery.type === 'recommendedforcollabs') {
        newActiveQueries = [...activeQueries.filter(q => !['recommendedforgifting', 'recommendedforcodes'].includes(q.type)), newQuery];
      } else if (newQuery.type === 'brandlist') {
        newActiveQueries = [...activeQueries.filter(q => q.type !== 'brandlist'), newQuery];
      } else {
        newActiveQueries = [...activeQueries, newQuery];
      }
    }

    this.setState(
      {
        activeQueries: newActiveQueries,
        searchVal: isActive ? searchVal : '',
        page: 0,
        receivedAllResults: false
      },
      this.fetchData
    );
    addEvent('Discover - Apply Filter', { filter: newQuery?.value, user: this.props.user });
  };

  toggleShowingMyPromoters = () => {
    this.setState({ hideMyPromoters: !this.state.hideMyPromoters, receivedAllResults: false, page: 0 }, this.fetchData);
    addEvent('Discover - Toggle Only Show New Talent', { user: this.props.user });
  };

  removeRecommendation = recommendation => {
    this.props
      .deleteRecommendedArtist(recommendation)
      .then(this.fetchAllData)
      .catch(() => {});
  };

  removeRecommendationRejection = rejection => {
    this.props.deleteRecommendedArtistRejection(rejection).then(() => {
      cogoToast.success(`Recommendation rejection undone.`);
      this.fetchAllData();
    });
  };

  render() {
    const { user, ui, analytics, discover } = this.props;
    const { isFetching, searchVal, activeQueries, page, hideMyPromoters, results } = this.state;
    const fetchingMore = isFetching && page > 0;
    return (
      <>
        <div className='discover-users-outer-container'>
          <div className='discover-users-inner-container'>
            <div className='discover-users-left-container'>
              <DiscoverUsersSidebar
                discover={discover}
                user={user}
                ui={ui}
                fetching={isFetching}
                searchVal={searchVal}
                activeQueries={activeQueries}
                updateBrandList={this.props.updateBrandList}
                deleteBrandList={this.props.deleteBrandList}
                setSearchVal={this.updateSearch}
                toggleQuery={this.toggleActiveQuery}
                syncTags={this.syncTags}
              />
            </div>
            <div className='discover-users-main-container'>
              <DiscoverUsersControls
                activeQueries={activeQueries}
                searchVal={searchVal}
                hideMyPromoters={hideMyPromoters}
                toggleShowingMyPromoters={this.toggleShowingMyPromoters}
                setSearchVal={this.updateSearch}
                toggleQuery={this.toggleActiveQuery}
                user={user}
              />
              <DiscoverUsersResults
                searchVal={searchVal}
                toggleQuery={this.toggleActiveQuery}
                openArtistModal={this.props.openArtistModal}
                removeRecommendation={this.removeRecommendation}
                removeRecommendationRejection={this.removeRecommendationRejection}
                activeQueries={activeQueries}
                results={results}
                ui={ui}
                fetching={isFetching}
                fetchingMore={fetchingMore}
                user={user}
                analytics={analytics}
                syncTags={this.syncTags}
                openChatOverlay={this.props.openChatOverlay}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user, ui, analytics, discover } = state;
  return { user, ui, analytics, discover };
};

export default connect(mapStateToProps, {
  getTags,
  updateRecommendedArtist,
  deleteRecommendedArtist,
  deleteRecommendedArtistRejection,
  addRecommendedArtist,
  updateBrandList,
  deleteBrandList,
  openArtistModal,
  openChatOverlay
})(DiscoverUsers);
