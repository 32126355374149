import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './MentionResults.scss';

import MentionResult from './MentionResult';
import Loader from '../Loader/Loader';

const MentionResults = props => {
  const { mentions, curFilters, getNextPage, isFetchingFirstPage } = props;

  let columns = [[], [], []];
  const columnChunks = _.chunk(mentions, 3);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0].push(chunk[0]);
    chunk[1] && columns[1].push(chunk[1]);
    chunk[2] && columns[2].push(chunk[2]);
  });

  const lastItemRefs = useRef(columns.map(() => React.createRef()));
  const observersRef = useRef([]);

  useEffect(() => {
    // Clear previous observers
    observersRef.current.forEach(observer => observer.disconnect());

    observersRef.current = lastItemRefs.current.map((ref, i) => {
      const observer = new IntersectionObserver(entries => {
        entries[0].isIntersecting && getNextPage();
      });
      if (ref.current) observer.observe(ref.current);
      return observer;
    });

    return () => observersRef.current.forEach(observer => observer.disconnect());
  }, [lastItemRefs, curFilters, getNextPage]);

  const emptyResultContainer = (
    <div className='fetching-container mention-result-container empty'>
      <Loader size={96} />
    </div>
  );

  const hasNoResults = !props.isFetchingMentions && !mentions.length;
  return (
    <div className='mention-results-outer-container'>
      {hasNoResults && (
        <div className='mention-results-empty-container'>
          <div className='mention-results-empty-message'>
            {curFilters.startDate || curFilters.startDate
              ? `We did not find any social mentions in that time window, please try expanding your search.`
              : `We did not find any social mentions.`}
          </div>
        </div>
      )}
      <div className='mention-results-columns'>
        {columns.map((column, columnIdx) => {
          return (
            <div key={columnIdx} className='mention-results-column'>
              {column.map((mention, idx) => {
                const isFinalItem = column.length === idx + 1;
                return isFetchingFirstPage ? (
                  <div key={mention.id} className='fetching-container mention-result-container empty'>
                    <Loader size={96} />
                  </div>
                ) : (
                  <MentionResult
                    key={mention.id}
                    user={props.user}
                    ui={props.ui}
                    analytics={props.analytics}
                    ref={isFinalItem ? lastItemRefs.current[columnIdx] : null}
                    mention={mention}
                    curFilters={curFilters}
                    openAuthModal={props.openAuthModal}
                    openArtistModal={props.openArtistModal}
                    openChatOverlay={props.openChatOverlay}
                    removeMentionIfAllowedTo={props.removeMentionIfAllowedTo}
                  />
                );
              })}
              {props.isFetchingMentions && (
                <>
                  {isFetchingFirstPage && (
                    <>
                      {emptyResultContainer}
                      {emptyResultContainer}
                    </>
                  )}
                  {emptyResultContainer}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

MentionResults.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  curFilters: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  getNextPage: PropTypes.func.isRequired,
  removeMentionIfAllowedTo: PropTypes.func
};

export default MentionResults;
