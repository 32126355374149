/*
  Helper functions related to the user redux store
*/
import _ from 'lodash';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { isPublicPage, getRootSMSUrl } from './helpers';
import { hasPermission } from './manager_helpers';
import { doesTakePercentageFromUser } from './brand_helpers';
import { isOpportunityRequestExpired } from './opportunity_helpers';
import { getFirstName } from './formatting';
import * as giftingHelpers from './gifting_helpers';

export const isShopifyUserRequiredForAppAcceptance = user => getUsername(user) === 'shopify'; // SHOPIFY_REMOVE_THIS_ONCE_ACCEPTED
export const isYoutubeUserRequiredForAppCompliance = user => getUsername(user) === 'youtubetest'; // SHOPIFY_REMOVE_THIS_ONCE_ACCEPTED
export const getProfileFromObject = user => _.get(user, 'profile', user);
export const getUserFromObject = user => (user.profile ? user : { profile: user });
export const getUserId = user => _.get(getProfileFromObject(user), 'id') || null;
export const getBrandId = user => _.get(getProfileFromObject(user), 'brand.id');
export const getBrandName = user => _.get(getProfileFromObject(user), 'brand.name');
export const getName = user => _.get(getProfileFromObject(user), 'name');
export const getUsername = user => _.get(getProfileFromObject(user), 'username');
export const getBio = user => _.get(getProfileFromObject(user), 'description');
export const getUserHash = user => _.get(getProfileFromObject(user), 'userHash');
export const getEmail = user => _.get(getProfileFromObject(user), 'email');
export const getImage = user => _.get(getProfileFromObject(user), 'image');
export const getUserTier = user => _.get(getProfileFromObject(user), 'tier');
export const getRequests = user => _.get(getProfileFromObject(user), 'requests') || [];
export const getDaysSinceJoining = user => _.max([moment().diff(moment(_.get(getProfileFromObject(user), 'createdAt')), 'days'), 1]);
export const getAnnouncements = user =>
  _.concat(getProfileFromObject(user)?.announcements || [], getProfileFromObject(user)?.brand_announcements || []);
export const getBrandRequests = user => _.get(getProfileFromObject(user), 'brand_requests') || [];
export const getBrandRequestsEmail = user => (_.get(getProfileFromObject(user), 'requestsEmail') || '').split(',')[0] || getEmail(user);
export const getBrandBudget = user => _.get(getBrand(user), 'budget', null);
export const getBrandBudgetRemaining = user => getBrandBudget(user)?.amountRemaining || 0;
export const getBrandBudgetFronted = user => _.sum(_.map(getUnpaidBrandBudgetInvoices(user).filter(i => i.wasFronted), 'brand_amount')); // prettier-ignore
export const getBrandBudgetPending = user => _.sum(_.map(getUnpaidBrandBudgetInvoices(user).filter(i => !i.wasFronted), 'brand_amount')); // prettier-ignore
export const getBrandBudgetIncreaseTakeRate = user => getBrandSettings(user)?.budgetIncreaseTakeRate || 10;
export const getBrandTags = user => _.get(getBrand(user), 'tags', []);
export const getSimilarBrands = user => (_.get(getBrand(user), 'similarBrands') || '').split(',').map(b => b.trim());
export const getCompetitorBrands = user => _.get(getBrand(user), 'competitor_brands', []);
export const getConsults = user => _.get(getProfileFromObject(user), 'consults') || [];
export const getContracts = user => _.get(getProfileFromObject(user), 'contracts', []);
export const getBrandContracts = user => _.get(getProfileFromObject(user), 'brand_contracts', []);
export const getBrandContractFromId = (user, Contract_id) => _.find(getBrandContracts(user), c => c.id === Contract_id);
export const getReferringBrand = user => _.get(getProfileFromObject(user), 'referring_brand') || null;
export const getReferringUser = user => _.get(getProfileFromObject(user), 'referring_user') || null;
export const getReferringLookbookId = user => _.get(getProfileFromObject(user), 'ReferringLookbook_id') || null;
export const getReferringRequest = user => getRequests(user).find(r => r.Lookbook_id && r.Lookbook_id === getReferringLookbookId(user));
export const getCustomRates = user => _.get(getProfileFromObject(user), 'rates') || [];
export const getAddress = user => _.get(getProfileFromObject(user), 'address');
export const getManagers = user => _.get(getProfileFromObject(user), 'managers') || [];
export const getManages = user => _.get(getProfileFromObject(user), 'manages') || [];
export const getOpportunityRequests = user => _.get(getProfileFromObject(user), 'opportunity_requests') || [];
export const getOpportunityRequestById = (user, id) => getOpportunityRequests(user).find(r => r.id === id);
export const getOpportunityRequestForOpportunityId = (user, Opportunity_id) =>
  getOpportunityRequests(user).find(r => r.Opportunity_id === Opportunity_id);
export const getReferrals = user => _.get(getProfileFromObject(user), 'referrals') || [];
export const getValidReferrals = user => getReferrals(user).filter(r => r.is_valid);
export const getValidReferralCount = user => getValidReferrals(user).length || 0;
export const getInvalidReferralCount = user => getReferrals(user).filter(r => !r.is_valid).length || 0;
export const getStats = user => _.get(getProfileFromObject(user), 'stats');
export const getSections = user => _.orderBy(_.get(getProfileFromObject(user), 'sections'), 'sortOrderRank');
export const getSettings = user => _.get(getProfileFromObject(user), 'settings');
export const getBrandSettings = user => _.get(getProfileFromObject(user), 'brand.settings');
export const getUserCountryCode = user => _.get(getProfileFromObject(user), 'countryCode') || null;

// Subscriptions & Invoices
export const getBrandSubscription = user => _.get(getProfileFromObject(user), 'brand.subscription');

// Subscriptions
export const getBrandSubscriptionInvoices = user => _.get(getProfileFromObject(user), 'brand.subscription.invoices') || [];
export const getUnpaidBrandSubscriptionInvoices = user => _.filter(getBrandSubscriptionInvoices(user), i => i.payment_status === 'unpaid');
export const getUnpaidBrandSubscriptionInvoicesPastDue = user =>
  _.filter(getUnpaidBrandSubscriptionInvoices(user), i => i.dueOn && moment(i.dueOn).isBefore(moment()));

// Affiliate Commissions
export const getBrandAffiliateInvoices = user => _.get(getProfileFromObject(user), 'brand.affiliate_invoices') || [];
export const getUnpaidBrandAffiliateInvoices = user => getBrandAffiliateInvoices(user).filter(i => i.status === 'unpaid');
export const getUnpaidBrandAffiliateInvoicesPastDue = user =>
  getUnpaidBrandAffiliateInvoices(user).filter(invoice => {
    return invoice.dueOn && moment(invoice.dueOn).isBefore(moment());
  });

// Brand Budget Invoices
export const getBrandBudgetInvoices = user => _.get(getProfileFromObject(user), 'brand.budget_invoices') || [];
export const getUnpaidBrandBudgetInvoices = user => getBrandBudgetInvoices(user).filter(i => i.status === 'unpaid');
export const getUnpaidBrandBudgetInvoicesPastDue = user =>
  getUnpaidBrandBudgetInvoices(user).filter(invoice => {
    return invoice.dueOn && moment(invoice.dueOn).isBefore(moment());
  });

export const getNumUnpaidInvoices = user =>
  [...getUnpaidBrandSubscriptionInvoices(user), ...getUnpaidBrandAffiliateInvoices(user), ...getUnpaidBrandBudgetInvoices(user)].length;
export const getNumUnpaidInvoicesPastDue = user =>
  [...getUnpaidBrandSubscriptionInvoicesPastDue(user), ...getUnpaidBrandAffiliateInvoicesPastDue(user), ...getUnpaidBrandBudgetInvoicesPastDue(user)]
    .length;

export const getBrandUtmParams = user => JSON.parse(_.get(getProfileFromObject(user), 'brand.settings.utmParams') || '{}');
export const getBrandInvitationCode = user => _.get(getProfileFromObject(user), 'brand.invitation_code');
export const getOpportunities = user => _.get(getProfileFromObject(user), 'brand.opportunities') || [];
export const getLookbooks = user => _.get(getProfileFromObject(user), 'brand.lookbooks');
export const getLookbookById = (user, Lookbook_id) => _.find(getLookbooks(user), { id: Lookbook_id });
export const getPrefferredCodeFormat = user => _.get(getProfileFromObject(user), 'settings.preferredCodeFormat');
export const getRequestForLookbook = (user, lookbook) => _.find(getRequests(user), { Lookbook_id: lookbook.id });
export const getRequestForLookbookId = (user, Lookbook_id) => _.find(getRequests(user), { Lookbook_id });
export const getRequestFromId = (user, Request_id) => _.find(getRequests(user), request => request.id === Request_id);
export const getRequestStatus = request => {
  if (request.isComplete) return 'Fulfilled';
  else if (request.userRejected || request.brandRejected) return 'Declined';
  else if (request.userAccepted && request.brandAccepted) return 'Accepted';
  else if (request.userAccepted && !request.brandAccepted) return 'Pending';
  else return 'Sent';
};
export const getSocialLinks = user => _.get(getProfileFromObject(user), 'social_links');
export const getSocialLinksArray = user =>
  getSocialLinks(user)
    ?.split(',')
    .filter(a => a) || [];
export const getSocialAccounts = user => _.get(getProfileFromObject(user), 'social_accounts');
export const getSocialAccount = (user, type) => _.find(getSocialAccounts(user), a => a.type === type);
export const getBrandSocialAccounts = user => _.get(getBrand(user), 'social_accounts');
export const getBrandSocialAccount = (user, type) => _.find(getBrandSocialAccounts(user), a => a.type === type);
export const getStripeAccountId = user => _.get(getProfileFromObject(user), 'stripeAccountId');
export const getStripeOnboardingComplete = user => _.get(getProfileFromObject(user), 'stripeOnboardingComplete');
export const getLastLoggedIn = user => _.get(getProfileFromObject(user), 'lastLoggedIn');
export const getTokens = user => _.get(getProfileFromObject(user), 'tokens');
export const hasCompletedOnboarding = user => _.get(getProfileFromObject(user), 'settings.hasCompletedOnboarding');
export const hasAgreedToTermsAndConditions = user => _.get(getProfileFromObject(user), 'settings.hasAgreedToTermsAndConditions');
export const hasAgreedToPrivacyPolicy = user => _.get(getProfileFromObject(user), 'settings.hasAgreedToPrivacyPolicy');
export const hasUsedSnapShop = user => !!_.get(getProfileFromObject(user), 'hasSnapshop');
export const hasMobileApp = user => !!_.get(getProfileFromObject(user), 'hasMobileApp');
export const needsToCompleteStripeOnboarding = user => !!getStripeAccountId(user) && !getStripeOnboardingComplete(user);
export const getAmazonCode = user => _.get(getProfileFromObject(user), 'amazonCode');
export const getRecommendedArtists = user => user?.recommendations || [];
export const getRecommendedArtistsMap = user => user?.recommendationsMap || {};
export const getTags = user => _.get(getProfileFromObject(user), 'tags') || [];
export const getTasks = user => _.get(getProfileFromObject(user), 'tasks') || [];
export const isBrand = user => !!_.get(getProfileFromObject(user), 'brand');
export const isAdmin = user => !!_.get(getProfileFromObject(user), 'isAdmin');
export const isAgent = user => !!_.get(getProfileFromObject(user), 'isAgent');
export const isBanned = user => !!_.get(getProfileFromObject(user), 'isBanned');
export const isBannedFromChat = user => !!_.get(getProfileFromObject(user), 'isBannedFromChat');
export const isTalent = user => !isBrand(user) && !isManager(user);
export const isManager = user => !!_.get(getProfileFromObject(user), 'isManager');
export const getManagerId = user => (isManager(user) || !!getManages(user).length ? getUserId(user) : null);
export const isBrandTag = (tag, user) => !!_.find(getBrandTags(user), t => t.id === tag.id);
export const isBrandInCatalog = user => !!_.get(getBrand(user), 'scraper');
export const isInsider = user => !!_.get(getProfileFromObject(user), 'isInsider');
export const isPro = user => !!_.get(getProfileFromObject(user), 'isPro');
export const isProUser = user => isPro(user);
export const isSimulatingUser = user => !!_.get(user, 'simulatedUsername');
export const isYou = (username, user) => username && getUsername(user) && username === getUsername(user);
export const isYourCollection = (collection, me) => _.get(collection, 'User_id') === getUserId(me);
export const isYourConsult = (consult, me) => _.get(consult, 'User_id') === getUserId(me);
export const getRecommendedArtistForUser = (artist, user) => getRecommendedArtistsMap(user)[artist.id];
// export const hasAgent = user => !!_.get(getProfileFromObject(user), 'Agent_id');
export const hasPromoterOverride = user => !!_.get(getProfileFromObject(user), 'isPromoterOverride');
export const isClient = (user, me) => !!_.find(_.get(me, 'clients'), client => client.id === getUserId(user));
export const isClientCollection = (collection, me) => !!_.find(_.get(me, 'clients'), client => client.id === _.get(collection, 'User_id'));
export const isClientConsult = (consult, me) => !!_.find(_.get(me, 'clients'), client => client.id === _.get(consult, 'User_id'));
export const isLoggedIn = user => !!_.get(user, 'isLoggedIn');
export const managesUsers = user => !!getManages(user).length;
export const hasManagers = user => !!getManagers(user).length;
export const canEditShop = (user, me) => {
  return user && !!user.id && !isPublicPage() && isLoggedIn(me) && (isAdmin(me) || isYou(getUsername(user), me) || isClient(user, me));
};
export const getBrandLists = user => {
  const allLists = _.get(getBrand(user), 'lists', []);
  return allLists.filter(list => (list.isChatOnlyList ? window.location.href.includes('/chat') : true));
};
export const getBrandLookbooks = user => _.get(getBrand(user), 'lookbooks', []);
export const getRequestLookbookOrders = user =>
  _.orderBy(
    getRequests(user)
      .filter(r => r.lookbook_order?.id)
      .map(l => l.lookbook_order),
    'createdAt',
    'desc'
  );

export const getDiscoverListPreviewsForAdmins = user => getBrand(user)?.discoverListPreviews || [];
export const getBrandListById = (user, BrandList_id) => getBrandLists(user).find(list => list.id === BrandList_id);
export const getArtistBio = artist => {
  return artist
    ? artist.description || artist.User_description || `${artist.name} joined ShopMy in ${moment(artist.createdAt).format('MMMM of YYYY')}.`
    : '';
};
export const getSmartArtistBio = artist => {
  // Allows us to get a bio for an artist, tries to choose the most relevant one
  return (
    artist?.summary_custom ||
    artist?.analysis?.summary_custom ||
    artist?.summary_recommendation ||
    artist?.analysis?.summary_recommendation ||
    artist?.description ||
    getArtistBio(artist)
  );
};

export const getRootUrlForUser = () => getRootSMSUrl();

export const getInviteLink = user => `${getRootUrlForUser(user)}/join/${getUsername(user)}`;
export const hasProvenPromotionAbility = (user, forSpecificBrandId) => {
  // Allow users to contact the brands they joined through
  const isBrandPromoter = getReferringBrand(user)?.id === forSpecificBrandId;
  if (isBrandPromoter) return true;

  // Allow Trendsetters and Icons to promote
  const userTier = getUserTier(user);
  const meetsTierLevel = userTier?.tier <= 2;
  if (meetsTierLevel) return true;

  // Allow admin overrides to promote
  if (hasPromoterOverride(user)) return true;

  return false;
};
export const getOutreachesRemaining = (user, chats) => {
  /*
    Users are limited to only 3 outreach messages per day as Ambassadors.
  */

  const userTier = getUserTier(user);

  // Allow admin overrides
  if (hasPromoterOverride(user)) return 1e6;

  // Allow admins to chat for testing purposes
  if (window.__ADMIN_CONTROL_MODE__) return 1e6;

  // Enthusiasts/Ambassadors can not reach out
  if (userTier?.tier >= 3) return 0;

  // Trendsetters can reach out unlimited times (for now)
  if (userTier?.tier === 2) return 1e6;

  // Icons can reach out unlimited times
  if (userTier?.tier === 1) return 1e6;

  // // Ambassadors can only reach out 3 times per 24 hours, currently disabled
  // const allChats = _.get(chats, 'chats', []); // From redux object
  // const chatsLastDay = allChats.filter(c => {
  //   const hoursAgoCreated = moment().diff(moment(c.createdAt), 'hours');
  //   return c.isCreatedByUser && hoursAgoCreated <= 24;
  // });
  // return 3 - chatsLastDay.length;
};
export const getOutstandingOpportunityRequests = user =>
  getOpportunityRequests(user).filter(r => !r.userAccepted && !r.userRejected && !isOpportunityRequestExpired(r));
export const getIncompleteConsultResults = user => _.filter(_.flatten(_.map(getConsults(user), c => c.results || [])), r => !r?.completedAt);
export const getNewRequests = user => _.filter(getRequests(user), giftingHelpers.isRequestOutstanding);
export const getNewContractProposals = user =>
  isBrand(user)
    ? _.filter(getBrandContracts(user), c => c.status === 'proposed' && c.isCreatedByUser)
    : _.filter(getContracts(user), c => c.status === 'proposed' && c.isCreatedByBrand);
export const getUnreadChatNotifications = user =>
  _.filter(_.get(getProfileFromObject(user), isBrand(user) ? 'brand_chat_notifications' : 'chat_notifications'), r =>
    isBrand(user) ? r.hasNewMessagesForBrand : r.hasNewMessagesForUser
  );
export const getNotificationCount = (user, manager) => {
  /*
    Used for the notification count pill in the top right corner, we must keep this in sync
    with the getNotificationCountForUser function in notification_helpers.js on the server.
  */
  let count = 0;

  // New Gifting Requests
  if (hasPermission(user, manager, 'canAcceptGifting')) {
    count += getNewRequests(user).length;
  }

  // New Opportunity Requests
  if (hasPermission(user, manager, 'canAnswerOpportunities')) {
    count += getOutstandingOpportunityRequests(user).length;
  }

  // Incomplete Consult Results
  if (hasPermission(user, manager, 'canEditShop')) {
    count += getIncompleteConsultResults(user).length;
  }

  // Unread Chats
  if (hasPermission(user, manager, 'canAnswerChats')) {
    count += getUnreadChatNotifications(user).length;
  }

  // New Contract Proposals
  if (hasPermission(user, manager, 'canAnswerCollaborations')) {
    count += getNewContractProposals(user).length;
  }

  return count;
};
export const canEditCollection = (collection, me) =>
  !isPublicPage() && (isYourCollection(collection, me) || isClientCollection(collection, me) || isAdmin(me));
export const canEditConsult = (consult, me) => !isPublicPage() && (isYourConsult(consult, me) || isClientConsult(consult, me) || isAdmin(me));
export const getCodes = user => _.get(getProfileFromObject(user), 'codes', []);
export const getRates = user => _.get(getProfileFromObject(user), 'rates', []);
export const getCodeForBrandId = (user, Brand_id) => _.find(getCodes(user), c => c.Brand_id === Brand_id);
export const getRateForBrandId = (user, Brand_id) => _.find(getRates(user), r => r.Brand_id === Brand_id);
export const getBrandCodes = analytics => _.get(analytics, 'brandAnalytics.customCodes', []);
export const getBrandCodesForUserId = (analytics, User_id) => getBrandCodes(analytics).filter(r => r.User_id === User_id);
export const getBrand = user => (user?.domain ? user : _.get(getProfileFromObject(user), 'brand'));
export const getBrandBlacklistedCodes = user => _.get(getBrand(user), 'blacklisted_codes', []);
export const getBrandUserShopifyShopName = user => _.get(getBrand(user), 'shopifyShopName');
export const isSMSAffiliatePartnerBrand = user => {
  const brand = getBrand(user);
  const smsMerchant = _.find(brand?.merchants, m => m.source === 'shopmyshelf');
  return !!smsMerchant;
};
export const isOtherAffiliatePartnerBrand = user => {
  const brand = getBrand(user);
  const merchant = _.find(brand?.merchants, m => m.isSMSWinner);
  const acceptedIntoLegitimateProgram = merchant?.source && !['shopmyshelf', 'viglink', 'skimlink', 'digidip'].includes(merchant?.source);
  return acceptedIntoLegitimateProgram;
};
export const isLegacyPaypalBrand = user => {
  return _.get(
    _.find(getUserPaymentAccounts(user), acct => acct.source.includes('PAYPAL_SUBSCRIPTION')),
    'createdAt'
  );
};
export const getUserPaymentAccounts = user => _.get(getProfileFromObject(user), 'payment_accounts', []);
export const getCollections = user => _.get(getProfileFromObject(user), 'collections', []);
export const isSubscribedBrand = user => !['unsubscribed', 'cancelled'].includes(getBrandSubscription(user)?.status);

export const getAdjPayoutRate = (user, merchantDetails) => {
  const customRate =
    merchantDetails &&
    _.find(getCustomRates(user), rate => merchantDetails.domain === rate?.brand?.domain || rate?.brand?.altDomains?.includes(merchantDetails.domain));
  const payout = customRate ? customRate.rate : _.get(merchantDetails, 'fullPayout', 0);
  const user_percentage = _.get(getProfileFromObject(user), 'user_percentage', 82);
  const applyPercentageAdjustment = (rate, applied_percentage) => (rate * applied_percentage) / 100;

  // Custom handling for SMS User Types
  if (_.get(merchantDetails, 'source') === 'shopmyshelf') {
    const brand = JSON.parse(_.get(merchantDetails, 'raw'));
    const { commission_rate } = brand;
    return applyPercentageAdjustment(customRate ? customRate.rate : commission_rate, doesTakePercentageFromUser(brand) ? user_percentage : 100);
  }
  return applyPercentageAdjustment(payout, user_percentage);
};
export const getShopUrlFromUser = user => `${getRootSMSUrl()}/${user?.username}`;
export const getShopUrlFromUsername = username => `${getRootSMSUrl()}/${username}`;
export const getCollectionUrlFromId = id => `${getRootSMSUrl()}/collections/${id}`;
export const getNameWithS = object => {
  const wholeName = _.get(object, 'name', '') || _.get(object, 'clientName', '') || _.get(object, 'ConsultResult_clientName', '');
  const storeUserFirstName = getFirstName(wholeName);
  const firstEndsInS = storeUserFirstName.charAt(storeUserFirstName.length - 1) === 's';
  const wholeEndsInS = wholeName.charAt(wholeName.length - 1) === 's';
  return {
    first: !storeUserFirstName ? "User's" : `${storeUserFirstName}${firstEndsInS ? "'" : "'s"}`,
    full: !wholeName ? "User's" : `${wholeName}${wholeEndsInS ? "'" : "'s"}`
  };
};
export const validateUsername = username => {
  if (!username) {
    cogoToast.error('Username cannot be left blank.');
    return false;
  }
  if (username.length < 3) {
    cogoToast.error('Username must be at least 3 characters.');
    return false;
  }
  if (username.includes(' ')) {
    cogoToast.error('Username cannot contain spaces.');
    return false;
  }
  if (username.includes('!')) {
    cogoToast.error('Username cannot contain exclamation marks.');
    return false;
  }
  if (username.includes('@')) {
    cogoToast.error('Username cannot contain @.');
    return false;
  }
  if (username.includes('?')) {
    cogoToast.error('Username cannot contain question marks.');
    return false;
  }
  if (username.includes('#')) {
    cogoToast.error('Username cannot contain hash tags.');
    return false;
  }
  if (username.includes('$')) {
    cogoToast.error('Username cannot contain dollar signs.');
    return false;
  }
  return true;
};

export const getTypeDataForUser = ({ isPro }) => {
  if (isPro) {
    return {
      label: 'Professional',
      class: 'pro'
    };
  } else {
    return {
      label: 'ShopMy',
      class: 'shopmy'
    };
  }
};

export const getUserCategoryType = user => {
  /*
    We want to customize the experience depending on the "type" of user we are working with. To do so,
    we can leverage the tags they selected during onboarding.

    To get all the tags, run the following query:
      select type, count(1), value from Tags inner join UserTags on UserTags.Tag_id=Tags.id where isOnboarding=1 and type != 'geo' group by Tags.id order by type, count(1) desc;
  */
  let type;
  const tags = getTags(user);
  const generalTags = tags.filter(t => t.type === 'general');
  const catalogTags = tags.filter(t => t.type === 'catalog');

  // First, check the general tags for their line of profession
  let isBeauty = generalTags.find(t => ['Makeup Artist', 'Hairstylist'].includes(t.value));
  let isFashion = generalTags.find(t => ['Model', 'Stylist', 'Lifestyle Influencer'].includes(t.value));
  let isSkincare = generalTags.find(t => ['Dermatologist', 'Esthetician'].includes(t.value));
  if (isBeauty) {
    type = 'Beauty';
  } else if (isFashion) {
    type = 'Fashion';
  } else if (isSkincare) {
    type = 'Skincare';
  }
  if (type) return type;

  // Second, check the catalog tags for what they want to promote
  isBeauty = catalogTags.find(t => ['Makeup', 'Hair Care'].includes(t.value));
  isFashion = catalogTags.find(t => ['Fashion', 'Lifestyle'].includes(t.value));
  isSkincare = catalogTags.find(t => ['Skincare'].includes(t.value));
  if (isBeauty) {
    type = 'Beauty';
  } else if (isFashion) {
    type = 'Fashion';
  } else if (isSkincare) {
    type = 'Skincare';
  }

  // return 'Fashion';
  // return 'Beauty';
  // return 'Skincare'
  return type || 'Skincare'; // Fallback to Skincare as the most ubiquitous option
};

export const getBrandIntegrations = user => _.get(getProfileFromObject(user), 'brand.integrations') || [];
export const getShopifyIntegration = (user, domain) => {
  const integrations = getBrandIntegrations(user);
  const shopifyIntegrations = integrations.filter(i => i.type === 'shopify');
  const targetDomain = domain === undefined ? _.get(getProfileFromObject(user), 'brand.domain') : domain;

  return shopifyIntegrations.find(i => i.domain === targetDomain) || {};
};
export const getAllShopifyIntegrations = user => {
  const integrations = getBrandIntegrations(user);
  const shopifyIntegrations = integrations.filter(i => i.type === 'shopify');
  return shopifyIntegrations;
};
export const doAllShopifyIntegrationsHavePermission = (user, permission) => {
  const shopifyIntegrations = getAllShopifyIntegrations(user);
  return shopifyIntegrations.every(i => i.enabledScopes.includes(permission));
};

/**
 * function that parses the db user table "social_links" column and searches for common
 * social media links inside of it. then it turns the links found into an object that holds
 * each link individually listed
 *
 * @param {string} social_link comma separated list of a user's social links
 * @returns object with each social individually listed
 */
export const splitDbSocialLinksIntoIndividualLinks = social_link => {
  const links = {
    youtube_url: null,
    instagram_url: null,
    tiktok_url: null,
    twitter_url: null,
    facebook_url: null,
    website_url: null
  };

  const youtube_regex = /.*(youtube|youtu\.be).*/gi;
  const instagram_regex = /.*(instagram).*/gi;
  const tiktok_regex = /.*(tiktok).*/gi;
  const twitter_regex = /.*(twitter).*/gi;
  const facebook_regex = /.*(facebook).*/gi;

  if (!social_link) return links;
  const split_links = social_link.split(',');

  for (let link of split_links) {
    switch (true) {
      case link.match(youtube_regex) !== null:
        links.youtube_url = link;
        break;
      case link.match(instagram_regex) !== null:
        links.instagram_url = link;
        break;
      case link.match(tiktok_regex) !== null:
        links.tiktok_url = link;
        break;
      case link.match(twitter_regex) !== null:
        links.twitter_url = link;
        break;
      case link.match(facebook_regex) !== null:
        links.facebook_url = link;
        break;
      default:
        // because user could have multiple other websites, append to array and add all
        if (!links.website_url) links.website_url = [link];
        else links.website_url.push(link);
    }
  }

  return links;
};

/**
 * Function to return if a user is in the allowed outreach country codes for a brand.
 * Used to determine if a user can reach out to a brand for communication/requests (chat, gifting, discount codes, etc.)
 * Brands can set allowed outreach country codes (currently only via Admin Brands panel) in BrandSettings to limit outreach to users in specific countries.
 * @param {Object} user
 * @param {Object} brandSettings
 * @returns {boolean} whether the user is in the allowed outreach country codes for the brand
 */
export const isUserInAllowedOutreachCountryCodes = (user, brandSettings) => {
  const userCountryCode = getUserCountryCode(user);
  const userInAllowedOutreachCountryCodes = brandSettings.allowedOutreachCountryCodes?.length
    ? !userCountryCode || brandSettings.allowedOutreachCountryCodes.split(',').includes(userCountryCode)
    : true;

  return userInAllowedOutreachCountryCodes;
};

export const isEUUser = user => {
  const userCountryCode = getUserCountryCode(user);
  const euCountryCodes = [
    'AT', // Austria
    'BE', // Belgium
    'BG', // Bulgaria
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DE', // Germany
    'DK', // Denmark
    'EE', // Estonia
    'ES', // Spain
    'FI', // Finland
    'FR', // France
    'GR', // Greece
    'HR', // Croatia
    'HU', // Hungary
    'IE', // Ireland
    'IT', // Italy
    'LT', // Lithuania
    'LU', // Luxembourg
    'LV', // Latvia
    'MT', // Malta
    'NL', // Netherlands
    'PL', // Poland
    'PT', // Portugal
    'RO', // Romania
    'SE', // Sweden
    'SI', // Slovenia
    'SK' // Slovakia
  ];
  return euCountryCodes.includes(userCountryCode);
};
