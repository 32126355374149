import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import './ArrowButton.scss';

const ArrowButton = props => {
  /*
    Large Branded Buttons used primarily for landing pages and other 
    branded materials.

    Standard: 
      <ArrowButton displayText='Click' onClick={onClick}/>

    No Lines: 
      <ArrowButton displayText='Click' onlyArrowAndText onClick={onClick}/>

    Only an arrow left:
      <ArrowButton onlyArrow arrowLeft onClick={onClick}/>
  */

  const { displayText, displayTextMobile, onClick, href } = props;
  const extraClasses = {
    inverted: props.inverted,
    disabled: props.disabled,
    'only-arrow': props.onlyArrow,
    'only-text': props.onlyText,
    'arrow-left': props.arrowLeft,
    'only-arrow-and-text': props.onlyArrowAndText,
    standard: !props.inverted
  };
  const displayTextFinal = isMobile ? displayTextMobile || displayText : displayText;
  const inner = (
    <>
      {displayTextFinal && <div className={cn('left', extraClasses)}>{displayTextFinal}</div>}
      <div className={cn('right', extraClasses)}>
        <FontAwesomeIcon className={cn(extraClasses)} icon={faArrowRight} />
      </div>
    </>
  );
  return onClick ? (
    <div onClick={onClick} className={cn('arrow-btn', extraClasses)}>
      {inner}
    </div>
  ) : (
    <Link to={href} className={cn('arrow-link', extraClasses)}>
      {inner}
    </Link>
  );
};

ArrowButton.propTypes = {
  displayText: PropTypes.string,
  displayTextMobile: PropTypes.string,

  // UI
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  onlyArrow: PropTypes.bool,
  onlyText: PropTypes.bool,
  onlyArrowAndText: PropTypes.bool,
  arrowLeft: PropTypes.bool,

  // Must set one of the following
  onClick: PropTypes.func,
  href: PropTypes.string
};

export default ArrowButton;
