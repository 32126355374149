import React from 'react';
import { connect } from 'react-redux';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import './OpportunityRequestProgress.scss';

import OpportunityExpectations from './Elements/OpportunityExpectations';

import {
  opportunityConstants,
  getTimingOnOpportunity,
  isOpportunityRequestCompleted,
  isOpportunityRequestPaid,
  isOpportunityRequestInReview,
  getOpportunityRequestAdjustedExpectations,
  isOpportunityExperiential
} from '../../Helpers/opportunity_helpers';
import { getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

const OpportunityRequestProgress = props => {
  const { opportunity, opportunityRequest } = props;
  const { daysToReviewUponCompletion } = opportunity;
  const { result, payment_tier } = opportunityRequest || {};
  const { statsLastUpdatedAt } = result || {};
  const { mentionsExpected, linksExpected, linkingDaysExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    opportunityRequest
  );
  const { fixedFee } = payment_tier || {};
  const stats = result?.stats ? JSON.parse(result.stats) : {};
  const isExperience = isOpportunityExperiential(opportunity);

  // Make sure we have the request
  if (!opportunityRequest) return null;

  // Make sure we have a result
  if (!opportunityRequest.result) return null;

  // Make sure we've augmented the result
  const hasLoadedFullData = 'expectations' in opportunityRequest.result;
  if (!hasLoadedFullData) return null;

  // Get details on the expectations
  const timing = getTimingOnOpportunity(opportunity);
  const isCompleted = isOpportunityRequestCompleted(opportunityRequest);
  const isPaid = isOpportunityRequestPaid(opportunityRequest);
  const isInReview = isOpportunityRequestInReview(opportunityRequest);

  // Generate Header
  const getFocusSpan = text => <span className='focus'>{text}</span>;
  const remainingMentions = mentionsExpected - stats.mentions - stats.missingMentions;
  const remainingMentionDays = mentionDaysExpected - stats.mentionDays - stats.missingMentionDays;
  const remainingLinks = linksExpected - stats.links - stats.missingLinks;
  const remainingLinkDays = linkingDaysExpected - stats.linkingDays - stats.missingLinkingDays;
  const hasRemainingLinks = remainingLinks > 0;
  const hasRemainingLinkingDays = remainingLinkDays > 0;
  const hasRemainingMentions = remainingMentions > 0;
  const hasRemainingMentionDays = remainingMentionDays > 0;

  let statusParts = [];
  let smallFontParts = [];
  const addToStatus = el => statusParts.push(el);
  const addToSmallFont = el => smallFontParts.push(el);

  if (timing.isNotYetStarted) {
    // Upcoming
    if (isExperience) {
      timing.eventStartsInDays >= 0
        ? addToStatus(`${timing.eventTimelineLabel} We will begin tracking any content you create after the event.`)
        : addToStatus(`We will begin tracking any content you create after the event.`);
    } else {
      if (timing.startsThisWeek) {
        addToStatus(
          <>
            Tracking starts in {getFocusSpan(timing.startsInDays)} day{timing.startsInDays === 1 ? '' : 's'}.
          </>
        );
      } else {
        addToStatus(<>Tracking starts on {getFocusSpan(moment(opportunity.trackingStartsAt).format('MMMM Do'))}.</>);
        (hasRemainingLinks || hasRemainingLinkingDays) &&
          addToStatus(
            ` Please make sure you create the links for this opportunity within ${opportunityConstants.MAX_DAYS_BEFORE_TRACKING_LINK_CAN_BE_CREATED} days of the start of the opportunity tracking window.`
          );
      }
    }
  } else if (timing.isActive) {
    // Actively Tracking
    if (isCompleted) {
      if (isExperience) {
        addToStatus(`The event has ended, we are now tracking any content you create associated with the event.`);
      } else {
        fixedFee
          ? addToStatus(`You have completed all your expectations and the $${commaNumber(fixedFee)} payment has been added to your account!`)
          : addToStatus('You have completed all your expectations!');
      }
    } else if (isInReview) {
      addToStatus(
        <>
          You have completed all your expectations and are in the {daysToReviewUponCompletion} day review period specified by{' '}
          {opportunityRequest.brand.name}, which ends on{` `}
          {getFocusSpan(
            moment(opportunityRequest.reviewStart)
              .add(daysToReviewUponCompletion, 'days')
              .format('MMMM Do')
          )}
          . Once the review period ends, a payment of ${commaNumber(fixedFee)} will be added to your account.
        </>
      );
    } else {
      const hasRemaining = hasRemainingLinks || hasRemainingLinkingDays || hasRemainingMentions || hasRemainingMentionDays;
      if (hasRemaining) {
        addToStatus(
          <>
            You have{' '}
            {hasRemainingLinks && (
              <>
                {getFocusSpan(remainingLinks)} link{remainingLinks === 1 ? '' : 's'}{' '}
              </>
            )}
            {hasRemainingLinkingDays && (
              <>
                {hasRemainingLinks ? 'and ' : ''}
                {getFocusSpan(remainingLinkDays)} linking day{remainingLinkDays === 1 ? '' : 's'}{' '}
              </>
            )}
            {hasRemainingMentions && (
              <>
                {hasRemainingLinks || hasRemainingLinkingDays ? 'and ' : ''}
                {getFocusSpan(remainingMentions)} social mention{remainingMentions === 1 ? '' : 's'}{' '}
              </>
            )}
            {hasRemainingMentionDays && (
              <>
                {hasRemainingLinks || hasRemainingLinkingDays || hasRemainingMentions ? 'and ' : ''}
                {getFocusSpan(remainingMentionDays)} social mention day{remainingMentionDays === 1 ? '' : 's'}{' '}
              </>
            )}
            remaining.
          </>
        );
      }

      if (isExperience) {
        if (timing.eventStartsInDays === 0) {
          addToStatus(
            `The event is today - during and after the event we will be tracking any content you create associated with the event to share with ${opportunityRequest.brand.name}.`
          );
        } else {
          addToStatus(
            `During and after the event we will be tracking any content you create associated with the event to share with ${opportunityRequest.brand.name}.`
          );
        }
      } else {
        // Add tracking update if it is a bonus or promotional opportunity
        addToStatus(<>Tracking ends in {getFocusSpan(timing.endsInDays)} day{timing.endsInDays === 1 ? '' : 's'}.</>); // prettier-ignore

        // Add update on timing
        if (statsLastUpdatedAt) addToSmallFont(`We last checked your progress ${getPrettyTimeAgoFromNow(statsLastUpdatedAt, { longForm: true })}.`);
        else addToSmallFont('We have not yet checked your progress and our system will update your progress soon.');
      }
    }
  } else {
    // Completed Opportunities
    if (isPaid) {
      addToStatus(`You have completed all your expectations and the $${commaNumber(fixedFee)} payment has been added to your account!`);
    } else if (isExperience) {
      addToStatus(`This event has ended and we are no longer tracking any content you create associated with the event.`);
    } else {
      addToStatus(
        'Tracking has ended and you unfortunately did not complete your expectations on time. If you think this is an error, please reach out to the ShopMy team via chat.'
      );
    }
  }

  // Add a space between each part
  statusParts = statusParts.map((part, idx) => (
    <span key={idx} className='progress-alert-part'>
      {part}
      {` `}
    </span>
  ));

  // Add a space between each part
  smallFontParts = smallFontParts.map((part, idx) => (
    <span key={idx} className='small-font-part'>
      {part}
      {` `}
    </span>
  ));

  return (
    <div className='opportunity-request-progress-outer-container'>
      <div className='progress-alert-container'>
        <div className='progress-alert-speaker'>
          <div className='progress-alert-speaker-inner'>S</div>
        </div>
        <div className='progress-alert-body'>
          <div className='progress-alert'>{statusParts}</div>
        </div>
        <div className='small-parts'>{smallFontParts}</div>
      </div>
      <OpportunityExpectations opportunity={opportunity} request={opportunityRequest} />
    </div>
  );
};

OpportunityRequestProgress.propTypes = {
  // From outside
  opportunity: PropTypes.object.isRequired,
  opportunityRequest: PropTypes.object.isRequired,

  // From inside
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(OpportunityRequestProgress);
