import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute, faVolumeUp, faRedoAlt, faPause, faPlay } from '@fortawesome/pro-regular-svg-icons';

import './MentionVideo.scss';

const MentionVideo = props => {
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.play();
            setIsPlaying(true);
          } else {
            entry.target.pause();
            setIsPlaying(false);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const handleToggleMute = () => {
    const newMuted = !muted;
    setMuted(newMuted);
    videoRef.current.muted = newMuted;
  };

  const handleTogglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleRestartVideo = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
    setIsPlaying(true);
    setProgress(0);
  };

  const handleVideoClick = event => {
    const rect = event.target.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const width = rect.width;
    const seekTime = (clickX / width) * videoRef.current.duration;
    videoRef.current.currentTime = seekTime;
  };

  useEffect(() => {
    const progressInterval = setInterval(() => {
      const currentProgress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(currentProgress);
    }, 200);

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div className='mention-video-container'>
      <video {...props} ref={videoRef} muted={muted} loop onClick={handleVideoClick} />
      <div className='action-btns'>
        <div className='action-btn' onClick={handleToggleMute}>
          <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} />
        </div>
        <div className='action-btn' onClick={handleTogglePlay}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </div>
        <div className='action-btn' onClick={handleRestartVideo}>
          <FontAwesomeIcon icon={faRedoAlt} />
        </div>
      </div>
      <div onClick={handleVideoClick} className='progress-bar-container'>
        <div className='progress-bar' style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

MentionVideo.propTypes = {
  src: PropTypes.string.isRequired
};

export default MentionVideo;
