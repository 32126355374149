import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import _ from 'lodash';
import './OpportunitySetupGuideFinalizing.scss';

import OpportunitiesLoader from '../../Loader/OpportunitiesLoader';
import ChatConnect from '../../Chat/ChatConnect';

const SPEED_MULTIPLIER = 1.5;
const TRANSITION_DURATION = 1000 / SPEED_MULTIPLIER;
const DELAY_BEFORE_SHOWING_FINAL_BUTTONS = 800 / SPEED_MULTIPLIER;
const MIN_DURATION_FOR_STATUS_DISPLAY = 2000 / SPEED_MULTIPLIER;

const OpportunitySetupGuideFinalizing = props => {
  const { opportunity } = props;

  // UI
  const [header, setHeader] = React.useState('');
  const [subheader, setSubheader] = React.useState('');
  const [isTransitioning, setIsTransitioning] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);
  const [hasFailed, setHasFailed] = React.useState(false);

  // Transition the header and subheader, ensuring enough time has passed for the transition to be noticeable
  const animationStartsForDisplay = React.useRef({});
  const changeHeaderSubheader = async (newHeader, newSubheader, { hasFailed = false, isComplete = false } = {}) => {
    let timeToDelayForOtherAnimations = 0;
    _.values(animationStartsForDisplay.current).forEach(animationStart => {
      const timeSinceAnimation = Date.now() - animationStart;
      const additionalTimeToWait = _.max([0, MIN_DURATION_FOR_STATUS_DISPLAY - timeSinceAnimation]);
      timeToDelayForOtherAnimations += additionalTimeToWait;
    });

    // Store the time the animation started or will start
    animationStartsForDisplay.current[newHeader] = Date.now() + timeToDelayForOtherAnimations;

    // Wait
    await new Promise(resolve => setTimeout(resolve, timeToDelayForOtherAnimations));

    // Store the time the animation started
    animationStartsForDisplay.current[newHeader] = Date.now();

    setIsTransitioning(true);
    setTimeout(() => {
      setHeader(newHeader);
      setSubheader(newSubheader);

      if (isComplete) {
        props.syncActiveOpportunityFullData(opportunity.id);
        setTimeout(() => setIsComplete(true), DELAY_BEFORE_SHOWING_FINAL_BUTTONS);
      } else if (hasFailed) {
        setHasFailed(true);
      }
    }, TRANSITION_DURATION);
    setTimeout(() => setIsTransitioning(false), TRANSITION_DURATION);
  };

  const ws = React.useRef(null);
  const onStatusUpdate = data => {
    // Make sure the status update is for the current request
    if (data.Job_id !== props.jobIdForPlanning) return;

    // Handle the status update
    switch (data.status_enum) {
      case 'PROCESSING':
        changeHeaderSubheader(data.status_display, data.subheader || '');
        break;
      case 'COMPLETE':
        changeHeaderSubheader(
          'Plan is ready for your review',
          'We have put together a plan for your opportunity. Review it and make any changes you need.',
          { isComplete: true }
        );
        break;
      case 'FAILURE':
        changeHeaderSubheader(data.status_display || 'Something went wrong', data.status_subdisplay, { hasFailed: true });
        break;
      default:
        console.error('Unknown status_enum: ', data.status_enum);
        break;
    }
  };

  const onWebsocketConnect = () => {
    if (!hasFailed && !isComplete) {
      props.generatePlan();
    }
  };

  const onWebsocketFailure = () => {
    window.ALERT.error('There was an error connecting to our servers, please try again.');
    props.backToSteps();
  };

  const additionalClasses = { transitioning: isTransitioning, complete: isComplete, failed: hasFailed };
  return (
    <div className='opportunity-setup-guide-finalizing-outer'>
      <ChatConnect
        user={props.user}
        setWebsocket={initializedWS => (ws.current = initializedWS)}
        onConnect={onWebsocketConnect}
        onFailedAttempt={onWebsocketFailure}
        onStatusUpdate={onStatusUpdate}
      />
      <div className='opportunity-setup-guide-finalizing-inner'>
        <div className={cn('media-container', additionalClasses)}>
          {hasFailed ? (
            <div>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
          ) : isComplete ? (
            <div>
              <FontAwesomeIcon icon={faCheck} />
            </div>
          ) : (
            <OpportunitiesLoader size={240} />
          )}
        </div>
        <div className={cn('content-container', additionalClasses)}>
          <div className='header'>{header}</div>
          <div className='subheader'>{subheader}</div>
        </div>
        <div className={cn('actions', additionalClasses)}>
          {hasFailed ? (
            <>
              <div onClick={props.closeToOverview} className='action secondary'>
                View Listing
              </div>
              <div onClick={props.backToSteps} className='action primary'>
                Go Back to Try Again
              </div>
            </>
          ) : (
            <>
              <div onClick={props.closeToOverview} className='action secondary'>
                View Listing
              </div>
              <div onClick={props.closeToPlans} className='action primary'>
                View Recommended Plan
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

OpportunitySetupGuideFinalizing.propTypes = {
  jobIdForPlanning: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  closeToOverview: PropTypes.func.isRequired,
  closeToPlans: PropTypes.func.isRequired,
  generatePlan: PropTypes.func.isRequired,
  backToSteps: PropTypes.func.isRequired,
  syncActiveOpportunityFullData: PropTypes.func.isRequired
};

export default OpportunitySetupGuideFinalizing;
