import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './BrandHeader.scss';

import { getAdjPayoutRate } from '../../Helpers/user_helpers';
import { getRateDisplay } from '../../Helpers/formatting';
import { portalBugOpenUrl } from '../../Helpers/helpers';

const BrandHeader = props => {
  const { user, brand, isFetching } = props;
  const { name, domain, mobileBadgeImage, merchant, description } = brand;
  const location = useLocation();
  const image = mobileBadgeImage;
  const rate = getAdjPayoutRate(user, merchant);
  const subdisplay = merchant ? `${getRateDisplay(rate, merchant)} at ${domain}` : domain;
  const chatUrl = `/chat?query=${name}`;
  return (
    <div className='brand-header-outer-container'>
      <div className='image-container'>{image ? <img src={image} alt={name} /> : <div className='empty' />}</div>
      <div className={cn('title', { fetching: !name })}>{name}</div>
      <div className={cn('description', { fetching: !description && isFetching })}>{description}</div>
      <a
        onClick={portalBugOpenUrl}
        target='_blank'
        rel='noopener noreferrer'
        href={`https://${domain}`}
        title='Go to brand website'
        className={cn('subdisplay', { fetching: !rate && isFetching })}
      >
        {subdisplay}
      </a>
      {!location.pathname?.includes('/chat') && (
        <div className='action-btns'>
          <Link onClick={props.closeModal} to={chatUrl} className='action-btn'>
            Chat
            <FontAwesomeIcon icon={faComment} />
          </Link>
        </div>
      )}
    </div>
  );
};

BrandHeader.propTypes = {
  user: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default BrandHeader;
