import _ from 'lodash';

import cogoToast from 'cogo-toast';
import * as ActionTypes from '../Actions/TalentActions';
import * as UserActionTypes from '../Actions/UserActions';

export const talent = (
  state = {
    all: [],
    isFetchingInitial: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_TALENT_REQUEST:
      return {
        ...state,
        isFetchingInitial: action.isFetchingInitial
      };
    case ActionTypes.GET_TALENT_SUCCESS:
      return {
        ...state,
        talent: action.talent,
        isFetchingInitial: false
      };

    case ActionTypes.GET_TALENT_FAILURE:
      cogoToast.error(action.error || `Could not sync talent, please check your internet connection and reload the page.`);
      return {
        ...state,
        isFetchingInitial: false
      };

    case ActionTypes.SYNC_TALENT_RESULT_SUCCESS:
      return {
        ...state,
        talent: _.map(state.talent, talent => (talent.id === action.result.id ? action.result : talent))
      };

    case ActionTypes.SYNC_TALENT_RESULT_REMOVE:
      return {
        ...state,
        talent: _.filter(state.talent, talent => talent.id !== action.resultToRemove.id)
      };

    case ActionTypes.SYNC_TALENT_RESULT_FAILURE:
      cogoToast.error(action.error || `Could not sync talent response, please check your internet connection and reload the page.`);
      return {
        ...state
      };

    case UserActionTypes.LOGOUT_USER_SUCCESS:
      return {
        all: []
      };

    default:
      return state;
  }
};
