import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';

import { getRootSMSUrl } from '../../Helpers/helpers';
import { getEmail } from '../../Helpers/user_helpers';

import './ProfileHeaderAlert.scss';

const ProfileHeaderAlert = props => {
  const { user } = props;
  const [isClosed, setIsClosed] = useState(false);
  const close = () => setIsClosed(true);
  if (isClosed) return null;

  if (window.__IS_PRO__) {
    return (
      <div className='profile-header-alert-outer-container'>
        <div className='profile-header-alert-inner-container'>
          <div className='header'>Shop My Shelf Professional is now ShopMy!</div>
          <div className='subheader'>
            We're moving! Very soon, all Shop My Shelf Professional URLs will redirect to ShopMy. Not to worry — all of your existing links will still
            work, and everything from your Shop My Shelf Professional account will be carried over to the new website. Feel free to reach out to our
            team if you have any questions about this transition!
          </div>
          <div className='actions'>
            <a className='login-btn' href={`${getRootSMSUrl()}/home/creators?auth=login&email=${getEmail(user)}`}>
              Log In to ShopMy
            </a>
          </div>
          <div onClick={close} className='close-btn'>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      </div>
    );
  }
  return null;
};

ProfileHeaderAlert.propTypes = {
  user: PropTypes.object.isRequired
};

export default ProfileHeaderAlert;
