import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './TagPill.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const TagPill = props => {
  const { tag, onRemove, hideRemove } = props;

  return (
    <div className={cn('tagpill-container', { removeHidden: hideRemove })}>
      <div className='tag-text'>{tag}</div>
      <div onClick={onRemove} className='remove-button'>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

TagPill.propTypes = {
  tag: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  hideRemove: PropTypes.bool
};

export default TagPill;
