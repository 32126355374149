import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './LandingLogin.scss';

import { addEvent } from '../../APIClient/events';
import { getUsername, isLoggedIn } from '../../Helpers/user_helpers';
import { loginUser, registerUser, applyToWaitlist } from '../../Actions/UserActions';
import { setBrandTheme, openAuthModal } from '../../Actions/UIActions';

import LandingHeaderPanel from '../../Components/Landing/LandingHeaderPanel';
import LandingStepsPanel from '../../Components/Landing/LandingStepsPanel';
import LandingExamplesPanel from '../../Components/Landing/LandingExamplesPanel';
import LandingApplyPanel from '../../Components/Landing/LandingApplyPanel';

class LandingLogin extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    loginUser: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    setBrandTheme: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, history } = this.props;

    if (_.includes(location.hash, 'applytab')) {
      setTimeout(() => this.setState({ panel: 2 }), 400);
    } else if (_.includes(location.hash, 'apply')) {
      setTimeout(() => this.scrollToAuth(2), 400);
    } else if (_.includes(location.hash, 'register')) {
      setTimeout(() => this.scrollToAuth(0), 400);
    } else if (_.includes(location.hash, 'login')) {
      setTimeout(() => this.scrollToAuth(1), 400);
    }

    if (location.hash) {
      const newUrl = location.pathname + (location.search || '');
      history.replace(newUrl);
    }
    addEvent(`Pro - Login View`);
    this.props.setBrandTheme();
  }

  state = {
    panel: window.__IS_PRO__ ? 0 : 2
  };

  scrollToAuth = panelIdx => {
    this.authForm && window.scrollTo({ top: this.authForm?.getBoundingClientRect()?.top + window.scrollY, behavior: 'smooth' });
    if (!_.isNil(panelIdx)) {
      this.setState({ panel: panelIdx });
    }
  };

  render() {
    const { user, openAuthModal } = this.props;
    const { panel } = this.state;

    if (isLoggedIn(user)) {
      return <Redirect to={{ pathname: `/${getUsername(this.props.user)}` }} />;
    }

    return (
      <div className='shop-list-login-outer-container'>
        <LandingHeaderPanel openAuthModal={openAuthModal} />
        <LandingStepsPanel />
        {!window.__IS_PRO__ && <LandingExamplesPanel />}
        <LandingApplyPanel
          panel={panel}
          updatePanel={newPanel => {
            this.setState({ panel: newPanel });
            addEvent('Landing Page - Switched Tab', { tab: newPanel });
          }}
          setPanelRef={ref => (this.authForm = ref)}
          loginUser={this.props.loginUser}
          registerUser={this.props.registerUser}
          applyToWaitlist={this.props.applyToWaitlist}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  applyToWaitlist,
  loginUser,
  registerUser,
  openAuthModal,
  setBrandTheme
})(withRouter(LandingLogin));
