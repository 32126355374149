import React, { useState, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomePartnerExamples.scss';

import HomePartnerExample from './Elements/HomePartnerExample';

const HomePartnerExamples = props => {
  const { partners, title } = props;

  const outerScrollRef = useRef(null);
  const scrollRef = useRef(null);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const scrollIntervalRef = useRef(null);
  const startTouchPosition = useRef({ x: 0, y: 0 });
  const horizontalScrollThreshold = 3; // Effectively a pixel velocity threshold

  // Auto scroll logic
  const SCROLL_INTERVAL = 30; // How often to scroll in ms
  const SCROLL_PIXEL_STEP = 1.5; // How many pixels to scroll per interval
  const stopAutoScroll = () => clearInterval(scrollIntervalRef.current);
  const startAutoScroll = () => {
    if (isMobile) return;
    const scrollElement = outerScrollRef.current;
    if (!isManualScroll && scrollElement) {
      scrollIntervalRef.current = setInterval(() => {
        const hasMoreScrollLeft = scrollElement.scrollLeft < scrollElement.scrollWidth - scrollElement.clientWidth;
        if (hasMoreScrollLeft) scrollElement.scrollLeft += SCROLL_PIXEL_STEP;
        else stopAutoScroll();
      }, SCROLL_INTERVAL);
    }
  };

  // Stop auto scroll when user scrolls horizontally a certain number of pixels
  useEffect(() => {
    const onUserScroll = event => {
      let horizontalDistance = 0;

      if (event.type === 'wheel') {
        horizontalDistance = Math.abs(event.deltaX); // For wheel events
      } else if (event.type === 'touchmove') {
        // For touch events
        if (event.touches && event.touches.length > 0) {
          const currentTouch = event.touches[0];
          horizontalDistance = Math.abs(currentTouch.pageX - startTouchPosition.current.x);
        }
      }

      // Check if the horizontal scroll exceeds the threshold
      if (horizontalDistance > horizontalScrollThreshold) {
        setIsManualScroll(true);
        stopAutoScroll();
      }
    };

    const onTouchStart = event => {
      if (event.touches && event.touches.length > 0) {
        const touch = event.touches[0];
        startTouchPosition.current = { x: touch.pageX, y: touch.pageY };
      }
    };

    // Add event listeners
    const currentElement = outerScrollRef.current;
    currentElement.addEventListener('wheel', onUserScroll, { passive: true });
    currentElement.addEventListener('touchmove', onUserScroll, { passive: true });
    currentElement.addEventListener('touchstart', onTouchStart, { passive: true });

    // Cleanup function
    return () => {
      stopAutoScroll();
      currentElement.removeEventListener('wheel', onUserScroll);
      currentElement.removeEventListener('touchmove', onUserScroll);
      currentElement.removeEventListener('touchstart', onTouchStart);
    };
  }, [isManualScroll]);

  // Start auto scroll when panel comes into view with an observer and stop when out of view
  useEffect(() => {
    const scrollElement = outerScrollRef.current;
    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          entry.isIntersecting ? startAutoScroll() : stopAutoScroll();
        }),
      { root: null, rootMargin: '0px' }
    );
    outerScrollRef.current && observer.observe(scrollElement);
    return () => outerScrollRef.current && observer.unobserve(scrollElement);
  }, []);

  return (
    <div className='home-partner-examples-outer-container'>
      <div className='home-partner-examples-inner-container'>
        <div className='home-partner-examples-title-container'>
          <h3 className='standard-home-header'>{title || 'Top creators thrive with ShopMy'} </h3>
        </div>
        <div ref={outerScrollRef} className='home-partner-examples'>
          <div ref={scrollRef} className={cn('home-partner-inner-scroll', { center: partners.length <= 3 })}>
            {partners.map((partner, index) => {
              return <HomePartnerExample partner={partner} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

HomePartnerExamples.propTypes = {
  partners: PropTypes.array.isRequired,
  title: PropTypes.string
};

export default HomePartnerExamples;
