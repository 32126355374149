import React from 'react';
import PropTypes from 'prop-types';
import { getPrettyNumber } from '../../Helpers/formatting';
import { getSocialIconForType } from '../../Helpers/social_helpers';
import './SocialCountPills.scss';

const SocialCountPills = props => {
  const { user, theme = 'dark', size = 'medium' } = props;
  const { social_links, instagramCount, tiktokCount, youtubeCount } = user;

  const instagramUrl = social_links?.split(',').find(url => url.includes('instagram'));
  const tiktokUrl = social_links?.split(',').find(url => url.includes('tiktok'));
  const youtubeUrl = social_links?.split(',').find(url => url.includes('youtube'));

  const imgHeight = size === 'small' ? '10px' : size === 'medium' ? '12px' : '14px';
  const countFontSize = size === 'small' ? '10px' : size === 'medium' ? '12px' : '14px';

  return (
    <div className='social-count-pills'>
      {!!instagramCount && (
        <a href={instagramUrl} target='_blank' rel='noopener noreferrer' className='social-count'>
          <img alt={'Instagram Logo'} src={getSocialIconForType('instagram', theme)} height={imgHeight} />
          <span className='count' style={{ fontSize: countFontSize }}>
            {getPrettyNumber(instagramCount)}
          </span>
        </a>
      )}
      {!!youtubeCount && (
        <a href={youtubeUrl} target='_blank' rel='noopener noreferrer' className='social-count'>
          <img alt={'Youtube Logo'} src={getSocialIconForType('youtube', theme)} height={imgHeight} />
          <span className='count' style={{ fontSize: countFontSize }}>
            {getPrettyNumber(youtubeCount)}
          </span>
        </a>
      )}
      {!!tiktokCount && (
        <a href={tiktokUrl} target='_blank' rel='noopener noreferrer' className='social-count'>
          <img alt={'Tiktok Logo'} src={getSocialIconForType('tiktok', theme)} height={imgHeight} />
          <span className='count' style={{ fontSize: countFontSize }}>
            {getPrettyNumber(tiktokCount)}
          </span>
        </a>
      )}
    </div>
  );
};

SocialCountPills.propTypes = {
  user: PropTypes.object.isRequired,
  theme: PropTypes.oneOf(['white', 'light', 'dark']).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired
};

export default SocialCountPills;
