import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { connect } from 'react-redux';
import classnames from 'classnames';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Shop.scss';

import ShopHeader from '../../Components/Shop/ShopHeader';
import ShopSearchBar from '../../Components/Shop/ShopSearchBar';
import ShopHierarchy from '../../Components/Shop/ShopHierarchy';
import ShopResults from '../../Components/Shop/ShopResults';
import ScrollToTop from '../../Components/General/ScrollToTop';

import { getSeoSchemaForSearchResultsPage } from '../../Helpers/seo_helpers';
import { isAdmin } from '../../Helpers/user_helpers';
import { getUrlParam, setUrlParam } from '../../Helpers/helpers';

import { addEvent } from '../../APIClient/events';
import { getProducts } from '../../APIClient/shop';
import { getHierarchy, setVisibleProduct } from '../../Actions/ShopActions';
import { toggleBodyScrollLock } from '../../Actions/UIActions';

class Shop extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    shop: PropTypes.object.isRequired,
    getHierarchy: PropTypes.func.isRequired,
    toggleBodyScrollLock: PropTypes.func.isRequired
  };

  componentDidMount() {
    // Initialize state from URL stub
    this.setInitialFiltersFromStub();
    this.props.getHierarchy().then(this.setInitialFiltersFromStub);

    this.syncProducts({ fromUrlStub: true });
    if (!isAdmin(this.props.user)) {
      // Ensure its a real user
      addEvent('Shop - Page View', {
        urlStub: _.get(this.props, 'match.params.urlStub')
      });
    }

    // If query is in the search, load it right away

    const query = getUrlParam('query');
    query && setTimeout(() => this.updateSearch(query), 150);
  }

  componentDidUpdate(prevProps) {
    // changes in url stub filters
    if (prevProps.match.params.urlStub !== this.props.match.params.urlStub) {
      this.setInitialFiltersFromStub();
      this.syncProducts({ fromUrlStub: true });
    }
  }

  state = {
    products: [],
    topArtists: [],
    activeFilters: {
      department: _.find(_.get(this.props.shop, 'departments'), department => department.urlStub === _.get(this.props, 'match.params.urlStub')),
      category: _.find(_.get(this.props.shop, 'categories'), category => category.urlStub === _.get(this.props, 'match.params.urlStub')),
      tag: _.find(_.get(this.props.shop, 'tags'), tag => tag.urlStub === _.get(this.props, 'match.params.urlStub'))
    },
    seoSchema: null,
    isSearching: false,
    searchVal: '',
    isHierarchyHiddenOnMobile: true
  };

  setInitialFiltersFromStub = () => {
    const { shop } = this.props;
    const { activeFilters } = this.state;
    const { departments, categories, tags } = shop || {};
    const urlStub = _.get(this.props, 'match.params.urlStub');
    const departmentMatch = _.find(departments, department => department.urlStub === urlStub);
    const categoryMatch = !departmentMatch && _.find(categories, category => category.urlStub === urlStub);
    const tagMatch = !departmentMatch && !categoryMatch && _.find(tags, tag => tag.urlStub === urlStub);
    if (departmentMatch) {
      this.setState({
        activeFilters: {
          ...activeFilters,
          department: departmentMatch
        }
      });
    } else if (categoryMatch) {
      this.setState({
        activeFilters: {
          ...activeFilters,
          department: _.find(departments, d => d.id === categoryMatch.Department_id),
          category: categoryMatch
        }
      });
    } else if (tagMatch) {
      this.setState({
        activeFilters: {
          ...activeFilters,
          department: _.find(departments, d => d.id === tagMatch.Department_id),
          category: _.find(categories, c => c.id === tagMatch.Category_id),
          tag: tagMatch
        }
      });
    }
  };

  syncProducts = (options = {}) => {
    const { activeFilters, searchVal } = this.state;
    const { fromUrlStub, clearResults } = options;
    const urlStub = _.get(this.props, 'match.params.urlStub');

    const params = {
      onlyRanked: isAdmin(this.props.user) ? false : true
    };
    if (searchVal) {
      params.query = searchVal;
      params.onlyRanked = false;
    } else if (fromUrlStub) {
      if (urlStub) params.urlStub = urlStub;
    } else {
      if (activeFilters.department) params.Department_id = activeFilters.department.id;
      if (activeFilters.category) params.Category_id = activeFilters.category.id;
      if (activeFilters.tag) params.Tag_id = activeFilters.tag.id;
      if (activeFilters.brand) params.brand = activeFilters.brand;
    }

    clearResults && this.setState({ products: [], topArtists: [] });
    getProducts(params)
      .then(
        resp => {
          const { products, top_artists } = resp;
          this.setState({
            products: _.orderBy(products || resp, ['totalRank', 'departmentRank', 'categoryRank']), // || resp for backward compatibility
            topArtists: top_artists && top_artists.length ? top_artists : null,
            seoSchema: getSeoSchemaForSearchResultsPage(this.props.shop, products, this.state.activeFilters)
          });
          searchVal &&
            addEvent('Shop - Search For Product', {
              query: searchVal,
              numResults: _.get(products, 'length')
            });
        },
        err => {
          console.error(err);
        }
      )
      .finally(() => {
        this.setState({ isSearching: false });
      });
  };

  updateUrlOnFilterState = () => {
    const { history } = this.props;
    const { activeFilters } = this.state;

    const urlStub = _.get(activeFilters.tag || activeFilters.category || activeFilters.department, 'urlStub', 'BASE');
    history.push(urlStub === 'BASE' ? '/shop' : `/shop/${urlStub}`);
  };

  setActiveFilters = newFilters => {
    const { activeFilters } = this.state;
    this.setState(
      {
        activeFilters: {
          ...activeFilters,
          ...newFilters
        }
      },
      () => {
        this.updateUrlOnFilterState();
        this.syncProducts({ clearResults: true });
      }
    );
  };

  toggleMobileHidden = () => {
    const { isHierarchyHiddenOnMobile } = this.state;
    isMobile && this.props.toggleBodyScrollLock(isHierarchyHiddenOnMobile);
    isMobile && addEvent('Shop - Show Mobile Menu');
    this.setState({ isHierarchyHiddenOnMobile: !isHierarchyHiddenOnMobile });
    window.scrollTo(0, 0);
  };

  updateSearch = val => {
    setUrlParam('query', val);
    this.setState({
      searchVal: val,
      isSearching: true,
      activeFilters: {
        department: null,
        category: null,
        tag: null
      }
    });
    window.scrollY &&
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    this.searchDebouncer = this.searchDebouncer || AwesomeDebouncePromise(this.syncProducts, 600);
    this.searchDebouncer();
  };

  render() {
    const { shop, user, ui } = this.props;
    const { products, topArtists, searchVal, activeFilters, seoSchema, isSearching, isHierarchyHiddenOnMobile } = this.state;
    return (
      <div className='shop-outer-container'>
        <MetaTags>
          {seoSchema}
          {_.map(products.slice(0, 3), product => (
            <meta key={product.id} property='og:image' content={product.image} />
          ))}
        </MetaTags>
        <ScrollToTop from={['/home']} />
        <div className='shop-header-container'>
          <ShopHeader shop={shop} ui={ui} activeFilters={activeFilters} />
          <ShopSearchBar searchVal={searchVal} updateSearchVal={this.updateSearch} />
        </div>
        <div
          className={classnames('shop-body-container', {
            fetching: isSearching
          })}
        >
          <ShopHierarchy
            shop={shop}
            ui={ui}
            setActiveFilters={this.setActiveFilters}
            activeFilters={activeFilters}
            isHiddenOnMobile={isHierarchyHiddenOnMobile}
            toggleMobileHidden={this.toggleMobileHidden}
          />
          <ShopResults
            shop={shop}
            user={user}
            ui={ui}
            topArtists={topArtists}
            products={products}
            isFetching={isSearching}
            setVisibleProduct={this.props.setVisibleProduct}
            activeFilters={activeFilters}
          />
          <div onClick={this.toggleMobileHidden} className='see-other-lists-btn'>
            See all categories
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { shop, user, ui } = state;
  return { shop, user, ui };
};

export default connect(mapStateToProps, {
  getHierarchy,
  toggleBodyScrollLock,
  setVisibleProduct
})(withRouter(Shop));
