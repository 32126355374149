import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './FulfillmentModal.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { updateRequest } from '../../Actions/AnalyticsActions';
import { closeFulfillmentModal } from '../../Actions/UIActions';

import { getFulfillmentModalStartingParams } from '../../Helpers/ui_helpers';
import { getBrandRequests } from '../../Helpers/brand_helpers';
import { getLookbookOrderItemParts } from '../../Helpers/lookbook_helpers';
import { getFirstName } from '../../Helpers/formatting';

import Modal from '../General/Modal';
import { getAddress } from '../../Helpers/user_helpers';

const FulfillmentModal = props => {
  /*
    There are a few types of fulfillment:

    1) Standard Gifting Requests
      - The brand must send the product to the user and use this panel to input the tracking number

    2) Lookbook Gifting Requests
      - The brand must send the product to the user and use this panel to input the tracking number

    3) Lookbook Gifting Requests (handled by Shopify)
      - The brand cannot take any action as this order is handled by Shopify
  */
  const { ui, analytics } = props;
  const params = getFulfillmentModalStartingParams(ui);

  const close = () => props.closeFulfillmentModal();
  const closeAfterSubmission = () => props.closeFulfillmentModal(true);

  // Get the request
  const requests = getBrandRequests(analytics);
  const request = _.find(requests, r => r.id === params.Request_id);
  const isFulfilledByShopify = !!request?.lookbook_order?.shopifyOrderId;

  const { user, lookbook_order } = request || {};
  const items = lookbook_order?.items || [];
  const orderUser = lookbook_order && props.talent.talent?.find(t => t.id === lookbook_order.User_id);

  const [trackingNumber, setTrackingNumber] = React.useState('');
  const [trackingUrl, setTrackingUrl] = React.useState('');
  const [trackingSource, setTrackingSource] = React.useState('');
  const [additionalMessage, setAdditionalMessage] = React.useState('');

  const submit = async e => {
    e?.preventDefault();
    if (isFulfilledByShopify) {
      return window.ALERT.warn('Please fulfill this order on Shopify and this form will automitically be filled out.');
    }

    if (trackingNumber && !trackingSource) {
      return window.ALERT.warn('Please enter a tracking source (Fedex, UPS, USPS, ...)');
    }

    const complete = async () => {
      await props.updateRequest(request, {
        isComplete: true,
        trackingNumber,
        trackingUrl,
        trackingSource,
        brandMessage: additionalMessage
      });
      closeAfterSubmission();
    };

    trackingNumber
      ? complete()
      : confirmAlert({
          title: 'Just Confirming',
          message:
            'You did not add a tracking number - we display these to creators in order to more easily track the status of their packages. Are you sure you want to mark this as fulfilled?',
          buttons: [
            { label: 'Cancel', className: 'cancel', onClick: () => {} },
            { label: 'Yes', onClick: complete }
          ]
        });
  };

  const formatAddress = () => {
    const rawAddress = lookbook_order?.address || user.address.raw;
    if (!rawAddress)
      return getAddress(user)?.address ? (
        <>
          <div className='user-name'>{user.name}</div>
          <div>{getAddress(user).address}</div>
        </>
      ) : (
        <>
          <span className='user-name'>{user.name}</span>
          <div>No address provided</div>
        </>
      );

    const address = JSON.parse(rawAddress);
    const { name, address1, address2, city, state, zip, country } = address;

    return (
      <span>
        <div className='user-name'>{name}</div>
        <div>
          {address1} {address2 ? `, ${address2}` : ''}
        </div>
        <div>
          {city}, {state} {zip}
        </div>
        <div>{country}</div>
      </span>
    );
  };

  if (!request) return null;
  return (
    <Modal visible smallForm showClose close={close} className='fulfillment-modal-outer-container' innerClassName='fulfillment-modal-inner-container'>
      <div className='fulfillment-modal-content'>
        <div className='title-container'>
          <div className='title'>{isFulfilledByShopify ? 'Fulfilled on Shopify' : 'Fulfill Order'}</div>
          <div className='subtitle'>
            {isFulfilledByShopify
              ? `This order is linked with a Shopify order, please fulfill it on Shopify and this form will be automatically filled out.`
              : `Please fulfill the order and fill out the information below - we will notify ${
                  user.name ? getFirstName(user.name) : 'the creator'
                }.`}
          </div>
        </div>
        <div className='sections'>
          {!isFulfilledByShopify && (
            <div className='address-section section'>
              <div className='section-header-container'>
                <div className='header'>Send to:</div>
              </div>
              <div className='address'>
                {/* <span className='user-name'>{user.name}</span>
              <br />
              {formatAddress() || 'Unknown Address'} */}
                {formatAddress()}
              </div>
            </div>
          )}
          {lookbook_order?.additionalNote?.length > 0 && orderUser?.id && (
            <div className='note-section section'>
              <div className='additional-note'>
                <div className='profile-picture'>
                  {orderUser.image ? (
                    <img src={orderUser.image} alt={orderUser.name} />
                  ) : (
                    <div className='initials'>{orderUser.name ? orderUser.name[0] : 'N/A'}</div>
                  )}
                </div>
                <div className='text'>{lookbook_order.additionalNote}</div>
              </div>
            </div>
          )}
          {!!items.length && (
            <div className='items-section section'>
              <div className='section-header-container'>
                <div className='header'>Selections:</div>
              </div>
              <div className='items'>
                {items.map((item, i) => {
                  const { id, url, image, title, quantity } = item;
                  const { name, siblingName } = getLookbookOrderItemParts(item);

                  return (
                    <div className='item' key={id}>
                      <img src={image} alt={title} />
                      <div className='text'>
                        <div className='title'>
                          {name}
                          {url && (
                            <a href={url} target='_blank' rel='noopener noreferrer' className='link'>
                              <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                          )}
                        </div>
                        <div className='subtitle'>
                          {quantity > 1 ? `${quantity} x ` : null}
                          {siblingName}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <form onSubmit={submit} className='form-section section'>
            <div className='section-header'>Shipment Details:</div>
            <input
              disabled={isFulfilledByShopify}
              type='text'
              placeholder='Tracking Number'
              value={trackingNumber}
              onChange={e => setTrackingNumber(e.target.value)}
            />
            <input
              disabled={isFulfilledByShopify}
              type='text'
              placeholder='Tracking URL'
              value={trackingUrl}
              onChange={e => setTrackingUrl(e.target.value)}
            />
            <input
              disabled={isFulfilledByShopify}
              type='text'
              placeholder='Tracking Source (Fedex, UPS, USPS, ...)'
              value={trackingSource}
              onChange={e => setTrackingSource(e.target.value)}
            />
            <textarea
              disabled={isFulfilledByShopify}
              rows={4}
              placeholder='Additional Message'
              value={additionalMessage}
              onChange={e => setAdditionalMessage(e.target.value)}
            />
          </form>
        </div>
      </div>
      <div className='actions'>
        {isFulfilledByShopify ? (
          <div className='action primary disabled' onClick={submit}>
            Please Fulfill on Shopify
          </div>
        ) : (
          <div className='action primary' onClick={submit}>
            Mark Fulfilled
          </div>
        )}
        <div className='action secondary' onClick={close}>
          Cancel
        </div>
      </div>
    </Modal>
  );
};

FulfillmentModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  updateRequest: PropTypes.func.isRequired,
  closeFulfillmentModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui, analytics, talent } = state;
  return { user, ui, analytics, talent };
};

export default connect(mapStateToProps, {
  closeFulfillmentModal,
  updateRequest
})(FulfillmentModal);
