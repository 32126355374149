import React from 'react';
import './LandingStepsPanel.scss';

const LandingStepsPanel = props => {
  const steps = [
    {
      header: 'Lose the inventory — build a digital storefront',
      subheader:
        'Let your clients seamlessly shop your recommended products. We work with thousands of merchants — from independent brands to big retailers.'
    },
    {
      header: 'Create custom product lists for your clients.',
      subheader: 'Use our product consult builder to create and share personalized product recommendations for your clients.'
    },
    {
      header: 'Hassle free — earn as your clients shop',
      subheader: 'Earn commission as your clients shop your recommendations without the hassle of inventory or drop shipping'
    }
  ];
  return (
    <div className='landing-steps-panel-outer-container'>
      <div className='landing-steps-panel-container'>
        {steps.map(({ header, subheader }, idx) => (
          <div key={idx} className='landing-steps-step-container'>
            <h3>{header}</h3>
            <h4>{subheader}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingStepsPanel;
