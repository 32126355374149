import _ from 'lodash';
import cogoToast from 'cogo-toast';
import * as ActionTypes from '../Actions/ShopActions';

import { sortReviews } from '../Helpers/shop_helpers';

export const shop = (
  state = {
    departments: [],
    categories: [],
    tags: [],
    visibleProduct: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_SHOP_HIERARCHY_SUCCESS:
      return {
        ...state,
        departments: _.filter(action.departments || state.departments, t => !t.isHidden),
        categories: _.filter(action.categories || state.categories, c => !c.isHidden),
        tags: _.filter(action.tags || state.tags, d => !d.isHidden)
      };

    case ActionTypes.SET_VISIBLE_PRODUCT:
      return {
        ...state,
        visibleProduct: action.product
      };

    case ActionTypes.GET_VISIBLE_PRODUCT_REQUEST:
      return {
        ...state,
        visibleProduct: _.get(state, 'visibleProduct.urlStub') !== action.urlStub ? null : action.product
      };

    case ActionTypes.GET_VISIBLE_PRODUCT_SUCCESS:
      return {
        ...state,
        visibleProduct: {
          ...action.product,
          reviews: sortReviews(action.product.reviews)
        }
      };

    case ActionTypes.GET_VISIBLE_PRODUCT_FAILURE:
      cogoToast.error(action.error || 'There was an issue finding this product. Please reload the page to try again.');
      return {
        ...state
      };

    case ActionTypes.ADD_REVIEW_SUCCESS:
      return {
        ...state,
        visibleProduct: state.visibleProduct && {
          ...state.visibleProduct,
          reviews: sortReviews([..._.get(state.visibleProduct, 'reviews', []), action.review])
        }
      };

    case ActionTypes.ADD_REVIEW_FAILURE:
      cogoToast.error(action.error || 'There was an issue adding your review. Please try again.');
      return {
        ...state
      };

    case ActionTypes.UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        visibleProduct: state.visibleProduct && {
          ...state.visibleProduct,
          reviews: sortReviews(_.get(state.visibleProduct, 'reviews', []).map(review => (review.id === action.review.id ? action.review : review)))
        }
      };

    case ActionTypes.UPDATE_REVIEW_FAILURE:
      cogoToast.error(action.error || 'There was an issue updating your review. Please try again.');
      return {
        ...state
      };

    case ActionTypes.DELETE_REVIEW_SUCCESS:
      return {
        ...state,
        visibleProduct: state.visibleProduct && {
          ...state.visibleProduct,
          reviews: _.filter(state.visibleProduct, 'reviews', []).map(review => review.id !== action.review.id)
        }
      };

    case ActionTypes.DELETE_REVIEW_FAILURE:
      cogoToast.error(action.error || 'There was an issue removing your review. Please try again.');
      return {
        ...state
      };

    default:
      return state;
  }
};
