import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { getDefaultImage } from '../../Helpers/ui_helpers';
import { getSmartImage } from '../../Helpers/helpers';

import './ProfilePhoto.scss';

class ProfilePhoto extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    badge: PropTypes.bool,
    basic: PropTypes.bool,
    isFetching: PropTypes.bool
  };

  render() {
    const { profile, ui, badge, basic, isFetching } = this.props;
    const { image, logo, name } = profile || {};
    return basic ? (
      <img className='profile-image' alt={name} src={image || logo ? getSmartImage(image || logo) : getDefaultImage(ui)} />
    ) : (
      <div className={classnames('profile-image-container', { badge })}>
        <div
          className={classnames('profile-image-background loading-fade', {
            loaded: !isFetching
          })}
        >
          <img
            className={cn('profile-image el', { placeholder: !image })}
            alt={isFetching ? '' : name}
            src={isFetching ? null : image ? getSmartImage(image) : getDefaultImage(ui)}
          />
        </div>
      </div>
    );
  }
}

export default ProfilePhoto;
