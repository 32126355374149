import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import ReportSectionChart from './ReportSectionChart';
import { getPrettyNumber, getPrettyScoreDisplay } from '../../Helpers/formatting';
import {
  BINARY_YES,
  BINARY_NO,
  PERFORMANCE_COMPARE_OPTIONS,
  THREE_MONTHS_COMPARE,
  ONE_MONTHS_COMPARE,
  ONE_WEEK_COMPARE
} from '../../Helpers/brand_tier_helpers';
import './ReportSectionScoreComponent.scss';

const ReportSectionScoreComponent = props => {
  const { component, componentChartData, compareComponent } = props;

  const [isCharting, setIsCharting] = useState(false);
  const [chartFilter, setChartFilter] = useState(THREE_MONTHS_COMPARE);

  const chartDataPoints = useMemo(() => {
    let afterDate = null;

    switch (chartFilter) {
      case THREE_MONTHS_COMPARE:
        afterDate = moment().subtract(3, 'months');
        break;
      case ONE_MONTHS_COMPARE:
        afterDate = moment().subtract(1, 'months');
        break;
      case ONE_WEEK_COMPARE:
        afterDate = moment().subtract(1, 'weeks');
        break;
      default:
        break;
    }

    if (!afterDate) return componentChartData.map(cd => cd.value);

    const sliceIdx = componentChartData.findIndex(cd => moment(cd.date).isAfter(afterDate));
    const filteredData = componentChartData.slice(sliceIdx);
    return filteredData.map(d => d.value);
  }, [componentChartData, chartFilter]);

  const percentComplete = (100 * component.points) / component.max;
  const comparePercentComplete = compareComponent ? (100 * compareComponent.points) / compareComponent.max : -1;
  const improved = comparePercentComplete >= 0 && percentComplete > comparePercentComplete;
  const declined = comparePercentComplete >= 0 && percentComplete < comparePercentComplete;

  const scoreStyle = {
    width: component.points === 0 ? '8px' : `${percentComplete}%`
  };

  const compareStyle = {
    width: comparePercentComplete >= 1 ? `${comparePercentComplete}%` : '0px'
  };

  return (
    <div className={'component'} key={component.key}>
      <div className='header-container'>
        <div className='component-name'>{component.title}</div>
        <div className='score'>
          <span>
            {getPrettyScoreDisplay(component.points)}/{component.max}
          </span>
          points
        </div>
      </div>
      <div className='explainer-container'>
        <div className='explainer'>{component.explainer}</div>
        {!component.isBinaryScoring && componentChartData.length > 0 && (
          <div
            className='graph-action'
            onClick={() => {
              setIsCharting(!isCharting);
            }}
          >
            {isCharting ? 'Hide graph' : 'See graph'}
          </div>
        )}
      </div>
      {component.isBinaryScoring ? (
        <div
          className={cn('score-icon-container', { completed: component.markValue, highlight: !component.markValue && compareComponent?.markValue })}
        >
          <FontAwesomeIcon icon={component.markValue ? faCheckCircle : faMinusCircle} />
          {component.markValue ? BINARY_YES : BINARY_NO}
        </div>
      ) : (
        <div className='score-bar-footer'>
          <div className='score-bar-container'>
            <div className={cn('score-bar', { empty: component.points === 0, improved, declined })} style={scoreStyle} />
            {comparePercentComplete > 0 && <div className={cn('score-bar compare', { improved })} style={compareStyle} />}
          </div>
          <div className='marks'>
            {component.marks.map((mark, idx) => {
              const reached = component.markValue >= mark;
              return (
                <div className={cn('mark', { reached })} key={idx}>
                  {getPrettyNumber(mark) || 0}
                </div>
              );
            })}
            {!!component.markValue && (
              <div style={{ left: `${(component.points / component.max) * 100}%` }} className={cn('mark current', { declined })}>
                <div className='mark-line' />
                {getPrettyNumber(component.markValue) || 0}
              </div>
            )}
          </div>
        </div>
      )}
      {isCharting && (
        <div className='chart-footer'>
          <div className='chart-wrapper'>
            <ReportSectionChart data={chartDataPoints} />
          </div>
          <div className='chart-filter-options'>
            {PERFORMANCE_COMPARE_OPTIONS.map(option => (
              <div className={cn('filter-option', { selected: chartFilter === option })} key={option} onClick={() => setChartFilter(option)}>
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ReportSectionScoreComponent.propTypes = {
  component: PropTypes.object.isRequired,
  componentChartData: PropTypes.array.isRequired,
  compareComponent: PropTypes.object
};

export default ReportSectionScoreComponent;
