import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import commaNumber from 'comma-number';
import './EarningsPortalCommissions.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';
import Image from '../General/Image';

import { downloadCommissions as downloadCommissionsAPI } from '../../APIClient/payouts';

import { getUserId } from '../../Helpers/user_helpers';
import { downloadCsvFromUrl } from '../../Helpers/helpers';
import { getPrettyDate } from '../../Helpers/formatting';

const EarningsPortalCommissions = props => {
  const { user, payoutsAreLoading } = props;

  const [payoutsVisible, setPayoutsVisible] = useState(5);
  const [isDownloading, setIsDownloading] = useState(false);

  const payouts = useMemo(() => {
    const { user } = props;
    const items = _.get(user, 'payouts.payout_summaries.payouts', []);
    const positiveEarningItems = _.filter(items, item => item && item.commission_amount);
    const orderedItems = _.orderBy(positiveEarningItems, item => new Date(item.transaction_date), 'desc');
    return orderedItems.map(item => {
      const { statusDisplay, isLocked, isPaid, isInReview } = item;
      return {
        ...item,
        statusDisplay: statusDisplay,
        infoTooltip: isPaid
          ? null
          : isLocked
          ? 'This payout is locked and will be paid to you at the end of the week.'
          : isInReview
          ? `This payout is currently under review to ensure it is valid. We have a review for large orders to ensure merchants are correctly reporting commissions.`
          : `Pending means this is what you are on track to earn, but the order hasn't passed the retailer's return window (this can be up to 105 days). If the order is not cancelled or returned, you should receive this commission.`
      };
    });
  }, [user]);
  const hasMorePayouts = payouts.length > payoutsVisible;

  const downloadCommissions = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    const offsetFromUTC = new Date().getTimezoneOffset();
    try {
      const resp = await downloadCommissionsAPI({ User_id: getUserId(props.user), timezoneOffset: offsetFromUTC });
      downloadCsvFromUrl(resp.url);
      window.ALERT.success('Successfully Downloaded!');
    } catch (error) {
      window.ALERT.error(error || `Error downloading, please try again.`);
    }

    setIsDownloading(false);
  };

  const largeScreen = window.innerWidth > 768;
  const gridTemplateColumns = largeScreen ? '1fr 3fr 1fr 1fr 1fr 1fr 1fr' : '1fr 2fr 1fr 1fr';

  return (
    <div className='earnings-portal-commissions-outer'>
      <div className='earnings-portal-commissions-inner'>
        <div className='earnings-portal-section-header'>
          <div>
            <div className='earnings-portal-section-title'>Commissions</div>
          </div>
          <div>
            <button className='earnings-portal-section-button' onClick={downloadCommissions}>
              Download
            </button>
          </div>
        </div>

        <div className='earnings-portal-table'>
          <div className='earnings-portal-table-header' style={{ gridTemplateColumns }}>
            <div className='left earnings-portal-table-cell'>Date</div>
            <div className='left earnings-portal-table-cell'>Product</div>
            <div className='desktop-only earnings-portal-table-cell'>Merchant</div>
            <div className='desktop-only earnings-portal-table-cell'>Order Total</div>
            <div className='earnings-portal-table-cell'>Commission</div>
            <div className='desktop-only earnings-portal-table-cell'>Code</div>
            <div className='earnings-portal-table-cell'>Status</div>
          </div>

          <div className='earnings-portal-table-body'>
            {payoutsAreLoading ? (
              new Array(5).fill(0).map((_, index) => {
                return (
                  <div key={index} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                    <div className='skeleton-cell earnings-portal-table-cell'></div>
                  </div>
                );
              })
            ) : payouts.length ? (
              payouts.slice(0, payoutsVisible).map((payout, idx) => {
                const {
                  Collection_id,
                  title,
                  merchant,
                  code,
                  statusDisplay,
                  amountEarned,
                  infoTooltip,
                  transaction_date,
                  order_amount,
                  locked_user_percentage,
                  image
                } = payout;
                const bonusTooltip = locked_user_percentage && locked_user_percentage > 100 ? `Includes a Bonus` : null;

                return (
                  <div key={idx} className='earnings-portal-table-row' style={{ gridTemplateColumns }}>
                    <div className='left earnings-portal-table-cell'>{getPrettyDate(transaction_date)}</div>
                    {/* {title ? ( */}
                    <div className='image-container left payout-title'>
                      <Image src={image} className='commission-image desktop-only' failedText='S' useGenericFailedStyles />
                      <a
                        href={Collection_id ? `/collections/${Collection_id}` : '/links'}
                        target='_blank'
                        rel='noopener nofollow noreferrer'
                        className='left'
                      >
                        {title}
                      </a>
                    </div>
                    {/* ) : ( */}
                    {/* <div className='left earnings-portal-table-cell'>—</div> */}
                    {/* )} */}
                    <div className='desktop-only earnings-portal-table-cell'>{merchant}</div>
                    <div className='desktop-only earnings-portal-table-cell'>${order_amount && order_amount.toFixed(2)}</div>
                    <div className='earnings-portal-table-cell'>
                      ${amountEarned && commaNumber(amountEarned.toFixed(2))}
                      {bonusTooltip && <Tooltip message={bonusTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                    </div>
                    <div className='desktop-only earnings-portal-table-cell'>{code || '—'}</div>
                    <div className='earnings-portal-table-cell'>
                      {statusDisplay}
                      {infoTooltip && <Tooltip message={infoTooltip} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>} />}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='earnings-portal-empty-message'>PURCHASES MADE THROUGH YOUR SHOP & LINKS WILL APPEAR HERE</div>
            )}

            {payouts.length > 5 && !payoutsAreLoading && (
              <div className='earnings-portal-table-row' style={{ gridTemplateColumns: '1fr' }}>
                <div className='show-more' onClick={() => (hasMorePayouts ? setPayoutsVisible(payoutsVisible + 5) : setPayoutsVisible(5))}>
                  {hasMorePayouts ? 'Show More' : 'Show Less'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EarningsPortalCommissions.propTypes = {
  user: PropTypes.object.isRequired,
  payoutsAreLoading: PropTypes.bool.isRequired
};

export default EarningsPortalCommissions;
