import React from 'react';
import PropTypes from 'prop-types';
import './OpportunityHeaderActionButtons.scss';
import cn from 'classnames';

import { checkValidityOfOpportunity } from '../../../Helpers/opportunity_helpers';

import Tooltip from '../../General/Tooltip';

const OpportunityHeaderActionButtons = props => {
  const { user, opportunity, isEditing, setIsEditing, selectTabByValue, canEdit } = props;

  const { canSendOpportunity, invalidMessage } = canEdit ? checkValidityOfOpportunity(opportunity, user) : {};

  const actions = isEditing
    ? [
        {
          display: 'Done',
          className: 'primary',
          onClick: () => setIsEditing(false)
        }
      ]
    : canEdit
    ? [
        {
          display: 'Edit',
          className: 'secondary',
          onClick: () => {
            setIsEditing(true);
            selectTabByValue('overview');
          }
        },
        {
          display: 'Invite Talent',
          className: 'primary' + (canSendOpportunity ? '' : ' disabled'),
          tooltip: canSendOpportunity ? null : invalidMessage,
          onClick: () =>
            canSendOpportunity
              ? props.openRequestModal({
                  params: {
                    Opportunity_id: opportunity.id,
                    type: 'opportunities'
                  }
                })
              : null
        }
      ]
    : [];
  if (!actions.length) return null;
  return (
    <div className='opportunity-header-actions-container'>
      {actions.map(action => {
        const { display, tooltip, className, onClick } = action;
        return tooltip ? (
          <Tooltip key={display} message={tooltip}>
            <div className={cn('action', className)} onClick={onClick}>
              {display}
            </div>
          </Tooltip>
        ) : (
          <div className={cn('action', className)} key={display} onClick={onClick}>
            {display}
          </div>
        );
      })}
    </div>
  );
};
OpportunityHeaderActionButtons.propTypes = {
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  selectTabByValue: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  showLoading: PropTypes.bool.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default OpportunityHeaderActionButtons;
