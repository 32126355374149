import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as constants from './social_template_constants';
import shopMyLogoWhite from '../../../../static/images/logos/shop_my_white.png';
import './SocialSharingBigPin.scss';

import SocialSharingTemplateControls from './SocialSharingTemplateControls';
import { filterAndSortPinsForSocialSharing } from '../../../../Helpers/social_sharing_template_helpers';

const SocialSharingBigPin = props => {
  const { elementId, collection } = props;
  const { ASPECT_RATIO } = constants;
  const { pins, name } = collection;

  const [backgroundColor, setBackgroundColor] = useState('#171919');
  const [textColor, setTextColor] = useState('#ffffff');
  const [logoColor, setLogoColor] = useState('#ffffff');
  const logoBrightness = logoColor === '#ffffff' ? 1 : 0;
  const logoHidden = logoColor === 'transparent';

  const topPins = filterAndSortPinsForSocialSharing(pins).slice(0, 1);
  if (!topPins.length) return null;
  return (
    <div className='social-sharing-template'>
      <div className='downloadable-content-container social-sharing-big-pin-outer-container'>
        <div className='downloadable-content' id={elementId} style={{ aspectRatio: ASPECT_RATIO, background: backgroundColor, color: textColor }}>
          <div className='downloadable-content-inner'>
            <div className='big-pin-container'>
              {topPins.map((pin, idx) => {
                const { image, title } = pin;
                return <img key={idx} className='pin-image' alt={title} src={image} />;
              })}
            </div>
            <div className='title-container'>
              <div className='big-title'>{name}</div>
            </div>
            {!logoHidden && (
              <img style={{ filter: `brightness(${logoBrightness})` }} height={15} className='logo' src={shopMyLogoWhite} alt='shopmy_logo' />
            )}
          </div>
        </div>
      </div>
      <SocialSharingTemplateControls
        collection={collection}
        elementId={elementId}
        backgroundColor={backgroundColor}
        setBackgroundColor={setBackgroundColor}
        textColor={textColor}
        setTextColor={setTextColor}
        logoColor={logoColor}
        setLogoColor={setLogoColor}
      />
    </div>
  );
};

SocialSharingBigPin.propTypes = {
  elementId: PropTypes.string.isRequired,
  collection: PropTypes.object.isRequired
};

export default SocialSharingBigPin;
