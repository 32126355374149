import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import commaNumber from 'comma-number';
import cn from 'classnames';
import './BonusModal.scss';

import Modal from '../General/Modal';

import { sendBonusToTalent } from '../../APIClient/brands';

import { closeBonusModal, closeArtistModal, openBrandBudgetModal } from '../../Actions/UIActions';
import { syncBrandBudget } from '../../Actions/BrandActions';

import { getBonusModalStartingParams } from '../../Helpers/ui_helpers';
import { getBrandId } from '../../Helpers/user_helpers';
import { getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getBrandBudgetRemaining, getBrandBudgetPending, getUnpaidBrandBudgetInvoices } from '../../Helpers/user_helpers';

const BonusModal = props => {
  const { user, ui } = props;
  const { name, User_id } = getBonusModalStartingParams(ui);

  // UI
  const [bonusAmount, setBonusAmount] = React.useState('');
  const [additionalMessage, setAdditionalMessage] = React.useState('');
  const [isSendingBonus, setIsSendingBonus] = React.useState(false);
  const [hasSuccessfullySentBonus, setHasSuccessfullySentBonus] = React.useState(false);
  const onBonusChange = e => setBonusAmount(e.target.value.replace(/[^0-9.]/g, ''));
  const onAdditionalMessageChange = e => setAdditionalMessage(e.target.value);

  // Budget
  const budgetRemaining = getBrandBudgetRemaining(user);
  const budgetPending = getBrandBudgetPending(user);
  const budgetInvoicesUnpaid = getUnpaidBrandBudgetInvoices(user);
  const isBudgetExceded = bonusAmount && parseFloat(bonusAmount) > budgetRemaining;
  const warningDisplay = isBudgetExceded
    ? budgetPending
      ? `The bonus amount exceeds your active budget. ${
          budgetInvoicesUnpaid.length
            ? ` You have ${getPrettyPriceDisplay(budgetPending)} in pending budget via ${budgetInvoicesUnpaid.length} unpaid budget invoice${
                budgetInvoicesUnpaid.length > 1 ? 's' : ''
              }. Please pay ${budgetInvoicesUnpaid.length > 1 ? 'these' : 'this'} in order to send this bonus.`
            : ''
        }`
      : `The bonus amount exceeds your remaining budget. You must upload funds to your budget in order to send this bonus.`
    : null;

  // Actions
  const close = () => props.closeBonusModal();
  const closeAllPanels = () => {
    props.closeBonusModal();
    props.closeArtistModal();
  };
  const openBudgetModal = () => props.openBrandBudgetModal();
  const sendBonus = async () => {
    if (isSendingBonus) return;
    setIsSendingBonus(true);
    try {
      await sendBonusToTalent({
        Brand_id: getBrandId(user),
        User_id,
        amount: parseFloat(bonusAmount),
        additionalMessage
      });
      await props.syncBrandBudget();
      setIsSendingBonus(false);
      setHasSuccessfullySentBonus(true);
    } catch (error) {
      setIsSendingBonus(false);
      window.ALERT.error(error || `There was an error sending the bonus, please try again.`);
    }
  };

  // Ensure Data is in sync
  React.useEffect(() => {
    props.syncBrandBudget();

    // Sync every 2 seconds while this panel is open in order to catch it if the brand makes a payment and returns
    const pollingSync = setInterval(() => document.hasFocus() && props.syncBrandBudget(), 2000);
    return () => clearInterval(pollingSync);
  }, []);

  if (!User_id) {
    window.ALERT.error('No User_id provided');
    close();
    return null;
  }

  return (
    <Modal
      showClose
      visible
      fitContent
      smallForm
      close={close}
      forceOuter
      className='bonus-modal-outer'
      innerClassName='bonus-modal-inner'
      contentClassName='bonus-modal-content'
    >
      <div className='modal-header'>
        {hasSuccessfullySentBonus ? (
          <>
            <div className='header'>Bonus Sent!</div>
            <div className='subheader'>
              Your bonus has been sent to {name || 'this talent'}. They have been alerted and will be paid out on the next payout cycle.
            </div>
          </>
        ) : (
          <>
            <div className='header'>Send Bonus to {name || 'Talent'}</div>
            <div className='subheader'>
              {budgetRemaining
                ? `You are about to send a bonus to ${name || 'this talent'}. Please enter the amount you would like to send.`
                : 'You have no budget remaining, please upload funds to send a bonus.'}
            </div>
          </>
        )}
      </div>
      <div className='modal-body sections'>
        {hasSuccessfullySentBonus ? (
          bonusAmount && (
            <div className='section'>
              <div className='label'>Bonus Sent</div>
              <div className='value'>${commaNumber(bonusAmount)}</div>
            </div>
          )
        ) : (
          <>
            <div className='section'>
              <div className='label'>{budgetPending ? 'Active Budget Remaining:' : 'Budget Remaining:'}</div>
              <div className='value'>${commaNumber(budgetRemaining)}</div>
            </div>
            {!!budgetPending && (
              <div className='section'>
                <div className='label'>Budget Pending Payment:</div>
                <div className='sublabel'>
                  You have {budgetInvoicesUnpaid.length} outstanding budget invoice
                  {budgetInvoicesUnpaid.length > 1 ? 's' : ''}. Click{' '}
                  <Link onClick={closeAllPanels} to='/subscriptions/invoices'>
                    here
                  </Link>{' '}
                  to view invoices.
                </div>
                <div className='value'>${commaNumber(budgetPending)}</div>
              </div>
            )}
            <div className='section'>
              <div className='label'>Bonus Amount:</div>
              <input autoFocus placeholder={`$500`} value={commaNumber(bonusAmount)} onChange={onBonusChange} className='budget-input' />
            </div>
            <div className='section'>
              <div className='label'>Additional Message:</div>
              <textarea
                rows={3}
                placeholder='Any comments on what this bonus is for?'
                value={additionalMessage}
                onChange={onAdditionalMessageChange}
                className='message-input'
              />
            </div>
          </>
        )}
      </div>
      {hasSuccessfullySentBonus ? (
        <div className='actions-and-warning'>
          <div className='warning' />
          <div className='actions'>
            <div onClick={close} className='action done'>
              Done
            </div>
          </div>
        </div>
      ) : (
        <div className='actions-and-warning'>
          <div className='warning'>
            {warningDisplay ? (
              <>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <div>{warningDisplay}</div>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className='actions'>
            {isBudgetExceded || !budgetRemaining ? (
              <div onClick={openBudgetModal} className='action budget'>
                Add Budget
              </div>
            ) : (
              <div onClick={sendBonus} className={cn('action done', { disabled: !bonusAmount })}>
                {isSendingBonus ? 'Sending...' : `Send ${getPrettyPriceDisplay(bonusAmount)} Bonus`}
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

BonusModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,

  // Actions
  closeBonusModal: PropTypes.func.isRequired,
  closeArtistModal: PropTypes.func.isRequired,
  openBrandBudgetModal: PropTypes.func.isRequired,

  // Data Syncing
  syncBrandBudget: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  closeBonusModal,
  openBrandBudgetModal,
  closeArtistModal,
  syncBrandBudget
})(BonusModal);
