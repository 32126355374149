import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ArtistModalSocialMentions.scss';

import { updateMention } from '../../APIClient/mentions';
import { openArtistModal, openChatOverlay, openAuthModal } from '../../Actions/UIActions';
import { getBrandId } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

import MentionResult from '../Mentions/MentionResult';

const ArtistModalSocialMentions = props => {
  const { user, ui, mentions } = props;

  const [removedIds, setRemovedIds] = React.useState([]);

  const hasManyMentions = mentions.length > 10;
  let columns = hasManyMentions ? [[], [], []] : [[], []];
  const filteredMentions = mentions.filter(mention => !removedIds.includes(mention.id));
  const columnChunks = _.chunk(filteredMentions, hasManyMentions ? 3 : 2);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0]?.push(chunk[0]);
    chunk[1] && columns[1]?.push(chunk[1]);
    chunk[2] && columns[2]?.push(chunk[2]);
  });

  const removeMentionIfAllowedTo = isAdminControlMode(ui)
    ? async mention => {
        const confirm = window.confirm('Are you sure you want to remove this mention?');
        if (confirm) {
          await updateMention(mention, { isHidden: true });
          window.ALERT.success('Mention Removed');
          setRemovedIds([...removedIds, mention.id]);
        }
      }
    : null;

  return (
    <div className='artist-modal-social-mentions-container'>
      <div className='mention-results-columns'>
        {columns.map((column, columnIdx) => {
          return (
            <div key={columnIdx} className='mention-results-column'>
              {column.map((mention, idx) => {
                return (
                  <MentionResult
                    key={mention.id}
                    mention={mention}
                    user={user}
                    ui={ui}
                    curFilters={{ Brand_id: getBrandId(user) }}
                    openArtistModal={props.openArtistModal}
                    openChatOverlay={props.openChatOverlay}
                    openAuthModal={props.openAuthModal}
                    removeMentionIfAllowedTo={removeMentionIfAllowedTo}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ArtistModalSocialMentions.propTypes = {
  // From Outside
  mentions: PropTypes.array.isRequired,

  // From inside
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired,
  removeMentionIfAllowedTo: PropTypes.func
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openAuthModal
})(ArtistModalSocialMentions);
