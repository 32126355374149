import React from 'react';
import PropTypes from 'prop-types';
import './BrandReportAnnouncement.scss';

import ArrowButton from '../../General/Buttons/ArrowButton';

const BrandReportAnnouncement = props => {
  const { closeModal, openChat } = props;

  const handleExploreNow = () => closeModal();
  const handleScheduleDemo = () => openChat(`Could we schedule a demo of the Brand Report?`);

  return (
    <div className='brand-report-announcement-outer-container'>
      <div className='main-panel'>
        <div className='badge'>New Feature!</div>
        <div className='title'>Program Report</div>
        <div className='body'>
          Our new program report feature makes it easier than ever to view your performance on the ShopMy platform and identify ways to improve your
          influencer strategy.
          <br />
          <br />
          The program report is a new way to measure ongoing success and develop new strategies with your team and Success Manager. The scoring system
          will monitor performance, help the ShopMy team train and guide you on the platform, scale your influencer strategy and retain new and
          existing creators.
        </div>
        <div className='continue-btn-container'>
          <ArrowButton displayText='Explore Now' onClick={handleExploreNow} />
          <ArrowButton displayText='Book Demo With Us' onlyText onClick={handleScheduleDemo} />
        </div>
      </div>
      <img src='https://static.shopmy.us/Website/BrandReporting_Square.png' alt='brand-report' />
    </div>
  );
};

BrandReportAnnouncement.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired
};

export default BrandReportAnnouncement;
