import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faLevelDown, faCopy } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowRight,
  faArrowDown,
  faChevronDown,
  faChevronUp,
  faBolt,
  faSearch,
  faExternalLink,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import cn from 'classnames';
import './MovePinModal.scss';

import RequiresPermissions from '../Managers/RequiresPermissions';

import { createCollection } from '../../APIClient/collections';
import { editPin, duplicatePin } from '../../APIClient/pins';

import { syncCurrentUser } from '../../Actions/UserActions';
import { clearPinsToMove, togglePinToMove, toggleBodyScrollLock } from '../../Actions/UIActions';
import { getCollections, getUserId, getSections } from '../../Helpers/user_helpers';
import { matchesSearchTerm } from '../../Helpers/search_helpers';
import { selectSectionFromModal } from '../../Helpers/section_helpers';
import { getPinsBeingMoved, getPinCompletionFn } from '../../Helpers/ui_helpers';
import { getSmartImage, getCollectionsTitle, isLinksPage, copyToClipboard, getShortUrl } from '../../Helpers/helpers';
import { getGMTTime } from '../../Helpers/formatting';

const SLIDE_ANIMATION_SPEED = 500; // Ensure this matches the scss variable

const MovePinModal = props => {
  const { clearPinsToMove, ui, togglePinToMove, toggleBodyScrollLock, syncCurrentUser, user } = props;
  const pins = getPinsBeingMoved(ui);
  const { length } = pins || {};

  const defaultToMultiSelect = isLinksPage();

  const [isFullScreen, setIsFullScreen] = useState(!defaultToMultiSelect);
  const [visible, setVisible] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  // New collections
  const newCollectionBaseName = `New ${getCollectionsTitle({ uppercase: true })}`;
  const [showNewCollectionOption, setShowNewCollectionOption] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');

  // Quick Collections
  const [showQuickCollectionOption, setShowQuickCollectionOption] = useState(false);
  const [quickCollectionId, setQuickCollectionId] = useState(null);
  useEffect(() => {
    setShowQuickCollectionOption(false);
    setQuickCollectionId(null);
  }, [length]);

  // Confirming
  const [confirmationMsg, setConfirmationMsg] = useState(null);
  const [confirmationFn, setConfirmationFn] = useState(null);
  const [isProcessingConfirmation, setIsProcessingConfirmation] = useState(null);

  const inputRef = useRef(null);

  const collections = _.orderBy(
    _.filter(getCollections(user), collection => matchesSearchTerm(collection.name, searchVal)),
    [
      collection => window.location.href.includes(`/${collection.id}`), // is currently visible
      'createdAt'
    ],
    ['desc', 'desc']
  );

  const closeModal = () => {
    setVisible(false);
    toggleBodyScrollLock(false);
    setTimeout(() => clearPinsToMove(), SLIDE_ANIMATION_SPEED);
  };

  // Fade it in
  useEffect(() => {
    setTimeout(() => setVisible(true), 100);
  }, []);

  useEffect(() => {
    toggleBodyScrollLock(isFullScreen);
  }, [isFullScreen, toggleBodyScrollLock]);

  // Load the user object again in case we need new collections
  useEffect(() => {
    syncCurrentUser();
  }, [syncCurrentUser]);

  const generateQuickCollection = async () => {
    setShowQuickCollectionOption(true);

    const newCollection = await createCollection({
      User_id: getUserId(user),
      isQuick: true,
      skinType: 'compact'
    });
    for (let i = 0; i < pins.length; i++) {
      const pin = pins[i];
      try {
        await duplicatePin(pin.id, { Collection_id: newCollection.id, sortOrderRank: getGMTTime() + 1000 * i, isQuick: true }); // Set rank to ensure we can subsequently sort
      } catch (error) {
        cogoToast.error(`There was an error moving one of the products, please try again.`);
        setIsProcessingConfirmation(false);
        return;
      }
    }
    setQuickCollectionId(newCollection.id);
  };

  const clearConfirmation = () => {
    setConfirmationMsg(null);
    setConfirmationFn(null);
  };

  const cleanUpAfterCompletedAction = successMsg => {
    closeModal();
    setTimeout(() => {
      cogoToast.success(successMsg);
      getPinCompletionFn(ui)();
      syncCurrentUser();
    }, SLIDE_ANIMATION_SPEED);
  };

  const selectToMove = async collection => {
    if (collection) {
      setConfirmationMsg(`Are you sure you want to move ${pins.length === 1 ? 'this product' : 'these products'} into ${collection.name}?`);
    } else {
      setConfirmationMsg(
        `Are you sure you want to move ${pins.length === 1 ? 'this product' : 'these products'} into a new ${getCollectionsTitle()}${
          newCollectionName ? ` called ${newCollectionName}?` : '?'
        }?`
      );
    }

    setConfirmationFn(() => async () => {
      setIsProcessingConfirmation(true);

      // Perform action
      let collectionToMatch = collection;
      if (!collection) {
        const section = await selectSectionFromModal(getSections(user), {
          header: 'Which Shop Section?',
          subheader: 'Select a shop section for this new collection to live in. It will start hidden.'
        });
        if (!section) {
          setIsProcessingConfirmation(false);
          return;
        }
        collectionToMatch = await createCollection({
          name: newCollectionName || newCollectionBaseName,
          User_id: getUserId(user),
          Section_id: section.id,
          private: true,
          sortOrderRank: -1 * getGMTTime()
        });
      }

      for (let i = 0; i < pins.length; i++) {
        const pin = pins[i];
        try {
          await editPin(pin.id, { Collection_id: collectionToMatch.id });
        } catch (error) {
          cogoToast.error(`There was an error moving one of the products, please try again.`);
          setIsProcessingConfirmation(false);
          return;
        }
      }
      cleanUpAfterCompletedAction(`Succesfully moved ${pins.length} product${pins.length === 1 ? '' : 's'}`);
    });
  };

  const selectToDuplicate = collection => {
    if (collection) {
      setConfirmationMsg(
        `Are you sure you want to duplicate ${pins.length} ${pins.length === 1 ? 'this product' : 'products'} into ${collection.name}?`
      );
    } else {
      setConfirmationMsg(
        `Are you sure you want to duplicate ${pins.length} ${pins.length === 1 ? 'this product' : 'products'} into new ${getCollectionsTitle()}?`
      );
    }

    setConfirmationFn(() => async () => {
      setIsProcessingConfirmation(true);

      // Perform action
      let collectionToMatch = collection;
      if (!collection) {
        const section = await selectSectionFromModal(getSections(user), {
          header: 'Which Shop Section?',
          subheader: 'Select a shop section for this new collection to live in. It will start hidden.'
        });
        if (!section) {
          setIsProcessingConfirmation(false);
          return;
        }
        collectionToMatch = await createCollection({
          name: newCollectionName || newCollectionBaseName,
          User_id: getUserId(user),
          private: true,
          sortOrderRank: -1 * getGMTTime()
        });
      }

      for (let i = 0; i < pins.length; i++) {
        const pin = pins[i];
        try {
          await duplicatePin(pin.id, { Collection_id: collectionToMatch.id, sortOrderRank: getGMTTime() + 1000 * i }); // Set rank to ensure we can subsequently sort
        } catch (error) {
          cogoToast.error(`There was an error moving one of the products, please try again.`);
          setIsProcessingConfirmation(false);
          return;
        }
      }
      cleanUpAfterCompletedAction(`Succesfully duplicated ${pins.length} product${pins.length === 1 ? '' : 's'}`);
    });
  };

  const quickUrl = quickCollectionId && `${getShortUrl()}/q-${quickCollectionId}`;
  const quickCollectionDiv = (
    <div className='collection quick'>
      <div className='main'>
        <div className='empty-img'>
          <FontAwesomeIcon icon={faBolt} />
        </div>
        <div className='data'>
          <div className='title normal-case'>{quickCollectionId ? quickUrl : 'Generating...'}</div>
        </div>
      </div>
      <div className='actions'>
        <div onClick={() => copyToClipboard(quickUrl, true)} className='action'>
          <FontAwesomeIcon icon={faCopy} />
          <div className='label'>Copy Link</div>
        </div>
      </div>
    </div>
  );

  const newCollectionDiv = (
    <div className='collection new'>
      <div className='main'>
        <div
          onClick={() =>
            cogoToast.warn(
              `Click the buttons to the right to create a new collection and move or duplicate the selected product${
                pins.length === 1 ? '' : 's'
              } into it.`,
              { hideAfter: 8 }
            )
          }
          className='empty-img'
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div className='data'>
          <input
            ref={inputRef}
            placeholder={isMobile ? `${getCollectionsTitle({ uppercase: true })}Name` : `Name your new collection`}
            onChange={({ target }) => setNewCollectionName(target.value)}
            value={newCollectionName}
          />
        </div>
      </div>
      <div className='actions'>
        <div onClick={() => selectToMove(null)} className='action'>
          <FontAwesomeIcon icon={faLevelDown} />
          <div className='label'>Move</div>
        </div>
        <div onClick={() => selectToDuplicate(null)} className='action'>
          <FontAwesomeIcon icon={faCopy} />
          <div className='label'>Duplicate</div>
        </div>
      </div>
    </div>
  );

  const showNewCollectionAtFront = showNewCollectionOption && !searchVal.length;

  return (
    <div className={cn('move-pin-modal-outer-container', { visible, fullscreen: isFullScreen })}>
      <div onClick={() => (defaultToMultiSelect ? setIsFullScreen(false) : closeModal())} className='move-pin-modal-background-fade'></div>
      <RequiresPermissions permission='canEditShop' hideMessage>
        <div className={cn('move-pin-modal-inner-container', { confirming: confirmationMsg })}>
          <div className='main-modal-action'>
            <div onClick={() => setIsFullScreen(!isFullScreen)} className='action'>
              <div className='explainer'>{isFullScreen ? 'Close' : 'Make'} Full Screen</div>
              <FontAwesomeIcon icon={isFullScreen ? faChevronDown : faChevronUp} />
            </div>
          </div>
          <div className='sections'>
            <div className='section pins'>
              <div className='header'>{`${pins.length} Product${pins.length === 1 ? '' : 's'} Selected`}</div>
              {pins.map(pin => {
                const { image, id, title, createdAt } = pin;
                return (
                  <div key={id} className='pin'>
                    <div className='main'>
                      {image ? <img alt={title || `Pin ${id}`} src={getSmartImage(image)} /> : <div className='empty-img' />}
                      <div className='data'>
                        <div className='title'>{title || `Link #${commaNumber(id)}`}</div>
                        <div className='subtitle'>Created {moment(createdAt).format('MMMM Do, YYYY')}</div>
                      </div>
                    </div>
                    <div className='actions'>
                      <div onClick={() => togglePinToMove(pin)} className='action'>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  </div>
                );
              })}
              {pins.length === 1 && isFullScreen && (
                <div onClick={() => setIsFullScreen(!isFullScreen)} className='select-more-msg'>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Click Here To Select More Products
                </div>
              )}
            </div>
            <div className='section separator'>
              <FontAwesomeIcon className='right' icon={faArrowRight} />
              <FontAwesomeIcon className='down' icon={faArrowDown} />
            </div>
            <div className='section collections'>
              <div className='header'>
                {isSearching ? (
                  <div className='input-container'>
                    <input
                      ref={inputRef}
                      placeholder={`Search Your ${getCollectionsTitle({ uppercase: true, plural: true })}`}
                      onChange={({ target }) => setSearchVal(target.value)}
                      value={searchVal}
                    />
                    <div
                      onClick={() => {
                        setSearchVal('');
                        setIsSearching(false);
                      }}
                      className='clear-input'
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                ) : (
                  <div className='collection-actions'>
                    <div
                      className='collection-action'
                      onClick={() => {
                        setIsSearching(true);
                        setTimeout(() => inputRef && inputRef.current?.focus(), 250);
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      <div className='label'>SEARCH</div>
                    </div>
                    <div className='collection-action' onClick={() => setShowNewCollectionOption(!showNewCollectionOption)}>
                      <FontAwesomeIcon icon={faPlus} />
                      <div className='label'>ADD {getCollectionsTitle()}</div>
                    </div>
                    <div className='collection-action' onClick={generateQuickCollection}>
                      <FontAwesomeIcon icon={faBolt} />
                      <div className='label'>QUICK {getCollectionsTitle()}</div>
                    </div>
                  </div>
                )}
              </div>
              {showQuickCollectionOption && quickCollectionDiv}
              {showNewCollectionAtFront && newCollectionDiv}
              {collections.map(collection => {
                const { name, id, createdAt } = collection;
                const image = collection.image || _.first(_.filter(collection.pins, p => !!p.image))?.image;
                const allPinsAreInCollection = !_.find(pins, pin => pin.Collection_id !== id);
                return (
                  <div key={id} className='collection'>
                    <div className='main'>
                      {image ? <img alt={name || `Collection ${id}`} src={getSmartImage(image)} /> : <div className='empty-img' />}
                      <div className='data'>
                        <div className='title'>{name || `Collection #${commaNumber(id)}`}</div>
                        <div className='subtitle'>
                          Created {moment(createdAt).format('MMMM Do, YYYY')}{' '}
                          <a href={`collections/${id}`} target='_blank' rel='noopener noreferrer'>
                            <FontAwesomeIcon icon={faExternalLink} />
                          </a>
                        </div>
                      </div>
                    </div>
                    {allPinsAreInCollection ? (
                      <div className='no-actions-msg'>Current</div>
                    ) : (
                      <div className='actions'>
                        <div onClick={() => selectToMove(collection)} className='action'>
                          <FontAwesomeIcon icon={faLevelDown} />
                          <div className='label'>Move</div>
                        </div>
                        <div onClick={() => selectToDuplicate(collection)} className='action'>
                          <FontAwesomeIcon icon={faCopy} />
                          <div className='label'>Duplicate</div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              {!showNewCollectionAtFront && newCollectionDiv}
            </div>
          </div>
          <div className='modal-actions'>
            <div onClick={() => (defaultToMultiSelect && isFullScreen ? setIsFullScreen(false) : closeModal())} className='action-icn close'>
              <FontAwesomeIcon icon={defaultToMultiSelect && isFullScreen ? faChevronDown : faTimes} />
            </div>
          </div>
          {confirmationMsg && (
            <div className='confirmation-overlay'>
              <div className='confirmation-msg'>{confirmationMsg}</div>
              <div className='confirmation-actions'>
                <div onClick={() => clearConfirmation()} className='confirmation-action cancel'>
                  Cancel
                </div>
                <div onClick={() => !isProcessingConfirmation && confirmationFn()} className='confirmation-action confirm'>
                  {isProcessingConfirmation ? 'Processing' : 'Yes'}
                </div>
              </div>
            </div>
          )}
        </div>
      </RequiresPermissions>
    </div>
  );
};

MovePinModal.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  toggleBodyScrollLock: PropTypes.func.isRequired,
  syncCurrentUser: PropTypes.func.isRequired,
  togglePinToMove: PropTypes.func.isRequired,
  clearPinsToMove: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  syncCurrentUser,
  toggleBodyScrollLock,
  clearPinsToMove,
  togglePinToMove
})(MovePinModal);
