import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import './Directory.scss';
import './Basic.scss';

import { getSmartImage } from '../../Helpers/helpers';
import { formatUserTypeTag } from '../../Helpers/formatting';
import { addEvent } from '../../APIClient/events';
import { getInsiderUsers } from '../../Actions/DirectoryActions';

import ScrollToTop from '../../Components/General/ScrollToTop';

class Directory extends Component {
  static propTypes = {
    directory: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.getInsiderUsers();
    addEvent('Directory - Page View');
  }

  state = {
    highlightTypeLocked: !!new URLSearchParams(this.props.location.search).get('type'),
    highlightedType: new URLSearchParams(this.props.location.search).get('type') || 'ALL'
  };

  hoverClick = type => {
    const { history } = this.props;
    history.replace(`/shops?type=${type}`);

    this.setState({
      highlightTypeLocked: type !== this.state.highlightedType ? true : !this.state.highlightTypeLocked,
      highlightedType: type
    });
  };

  hoverIn = type => {
    !this.state.highlightTypeLocked && this.setState({ highlightedType: type });
  };

  hoverOut = e => {
    const inHoverSpace = e.relatedTarget && !!e.relatedTarget.closest('.directory-body-container');
    !inHoverSpace && !this.state.highlightTypeLocked && this.setState({ highlightedType: 'ALL' });
  };

  navigateToShop = (event, user) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.history.push(`/${user.username}`);
    window.scrollTo(0, 0);
  };

  render() {
    const { directory } = this.props;
    const { highlightedType } = this.state;
    const { insiders } = directory;
    const orderedInsiders = _.orderBy(insiders, 'name');
    const types = _.orderBy(
      _.uniq(_.map(insiders, 'type')),
      tag => _.indexOf(['MAKEUP', 'SKIN', 'HAIR', 'NAILS', 'GROOMING', 'MODELS & CELEBS'], formatUserTypeTag(tag)) + 1 || 20
    );
    const allFilterSelected = highlightedType === 'ALL';
    return (
      <div className='directory-outer-container'>
        <MetaTags>
          <title>All Experts | ShopMy</title>
          <meta
            property='description'
            content='Leading beauty experts, skincare professionals and celebrities have pulled together the products they use on their clients and themselves. Check out their profiles to view their tried-and-true product recommendations.'
          />
        </MetaTags>
        <ScrollToTop from={['/forbrands']} />
        <div className='directory-header-outer-container'>
          <div className='directory-header-container'>
            <div className='directory-header-inner-container'>
              <div className='directory-header-tag'>shop my</div>
              <h1 className='directory-header-title'>Experts</h1>
              <div className='directory-header-line' />
              <h2 className='directory-header-subtitle'>
                Below are some of the world’s best makeup artists, hair stylists, dermatologists and more. Check out their profiles to view their
                tried-and-true product recommendations.
              </h2>
              <div className='directory-insider-type-selection-container'>
                <div className='directory-insider-type-selection' onClick={() => this.hoverClick('ALL')} onMouseEnter={() => this.hoverIn('ALL')}>
                  <div
                    className={classnames('type-text', {
                      active: allFilterSelected
                    })}
                  >
                    ALL
                  </div>
                </div>
                {_.map(types, type => {
                  return (
                    <div
                      key={type}
                      className='directory-insider-type-selection'
                      onClick={() => this.hoverClick(type)}
                      onMouseEnter={() => this.hoverIn(type)}
                    >
                      <div
                        className={classnames('type-text', {
                          active: type === highlightedType
                        })}
                      >
                        {formatUserTypeTag(type)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div onMouseOut={this.hoverOut} className='directory-body-container'>
          <div className='directory-insiders'>
            {_.map(orderedInsiders, insider => {
              const { image, username, type, name } = insider;
              const longName = name && name.length > 15;
              const veryLongName = name && name.length > 22;
              const isInactive = !allFilterSelected && highlightedType && highlightedType !== type;
              return (
                <Link
                  to={`/${username}`}
                  key={username}
                  onClick={event => this.navigateToShop(event, insider)}
                  className={classnames('directory-insider', {
                    inactive: isInactive
                  })}
                >
                  <div className='user-img-wrapper'>
                    <div className='cover' />
                    <img className='user-img' alt={`${name}`} src={getSmartImage(image)} />
                  </div>
                  <div
                    className={classnames('user-name', {
                      long: longName,
                      'very-long': veryLongName
                    })}
                  >
                    {name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { directory } = state;
  return { directory };
};

export default connect(mapStateToProps, {
  getInsiderUsers
})(withRouter(Directory));
