import React from 'react';
import PropTypes from 'prop-types';
import './Discover2Announcement.scss';

import ArrowButton from '../../General/Buttons/ArrowButton';

const Discover2Announcement = props => {
  const pressActionOne = () => props.closeModal();
  const pressActionTwo = () => props.openChat(`Could we schedule a demo of Discover 2.0?`);
  return (
    <div className='discover2-announcement-outer-container'>
      <div className='main-panel'>
        <div className='badge'>Welcome to ShopMy's Redesigned</div>
        <div className='title'>Discover 2.0</div>
        <div className='body'>
          It's now easier than ever to source talent for your brand. Explore hundreds of curated lists by the experts on the ShopMy agency team.
          Search and filter our talent database yourself using new advanced sorting options based on sales data.
          <br />
          <br />
          To learn more about Discover 2.0, check out{' '}
          <a target='_blank' rel='noopener noreferrer' href='https://brands.shopmy.us/discover'>
            the brand guide
          </a>{' '}
          or chat with us to schedule a demo with your ShopMy team.
        </div>
        <div className='continue-btn-container'>
          <ArrowButton displayText='Explore Now' onClick={pressActionOne} />
          <ArrowButton displayText='Chat with Us' onlyText onClick={pressActionTwo} />
        </div>
      </div>
      <img src='https://static.shopmy.us/Announcements/Discover_2.png' alt='Discover 2.0' />
    </div>
  );
};

Discover2Announcement.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired
};

export default Discover2Announcement;
