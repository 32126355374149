/*
  This component is powered by our Examples Shop. This shop has the username "examples" and has a variety
  of consults with tags in the format {{TagName}} that is used to categorize them into groupings. The order
  designates which order the template groups appear.

  To add a new template:

  1) Log in to the Examples account - tiffany+examples@shopmyshelf.us 
  2) Add a new Consult
  3) Add {{TemplateGroup}} to the description, for example: {{Skincare}}
*/

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import './ConsultTemplatesModal.scss';

import Modal from '../General/Modal';
import Loader from '../Loader/Loader';
import ConsultPreview from './ConsultPreview';

import { getTagFromString } from '../../Helpers/consult_helpers';

const ConsultTemplatesModal = props => {
  const { getShopExamples, store, addConsult } = props;
  const [addingNew, setAddingNew] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const examples = store?.examples?.consults || [];
  useEffect(() => {
    getShopExamples();
  }, [getShopExamples]);

  let [filters, groups] = [[], {}];
  const sortedExamples = _.orderBy(examples, 'sortOrderRank');
  sortedExamples.forEach(example => {
    const tag = getTagFromString(example.description);
    if (tag) {
      filters = tag ? _.uniq([...filters, tag]) : filters;
      groups[tag] = [...(groups[tag] || []), example];
    }
  });

  const onPreviewClick = async consult => {
    setAddingNew(true);
    await addConsult(consult);
    setAddingNew(false);
  };

  return (
    <Modal
      innerClassName='consult-templates-outer-modal'
      contentClassName='consult-templates-modal'
      showClose
      visible={props.visible}
      close={props.closeModal}
    >
      <div className='section-selection'>
        <div onClick={() => setSelectedFilter(null)} className={cn('filter', { selected: !selectedFilter })}>
          All Templates
        </div>
        {filters.map(filter => (
          <div key={filter} onClick={() => setSelectedFilter(filter)} className={cn('filter', { selected: selectedFilter === filter })}>
            {filter}
          </div>
        ))}
      </div>
      <div className={cn('sections', { adding: addingNew })}>
        <div className='header'>{selectedFilter || 'Choose a Template'}</div>
        {!selectedFilter && <ConsultPreview previewOnly blankPreview onPreviewClick={onPreviewClick} canEdit={false} />}
        {filters.map(filter =>
          selectedFilter && selectedFilter !== filter ? null : (
            <div key={filter} className='filter-section'>
              {!selectedFilter && <div className='filter-section-header'>{filter}</div>}
              {groups[filter].map((example, idx) => (
                <ConsultPreview key={example.id} previewOnly onPreviewClick={onPreviewClick} canEdit={false} consult={example} />
              ))}
            </div>
          )
        )}
      </div>
      {addingNew && (
        <div className='adding-new-loader'>
          <Loader inverted size={70} />
        </div>
      )}
    </Modal>
  );
};

ConsultTemplatesModal.propTypes = {
  store: PropTypes.object.isRequired,
  getShopExamples: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  addConsult: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

export default ConsultTemplatesModal;
