import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './Guide.scss';

import { addEvent } from '../../APIClient/events';
import { toggleBodyScrollLock } from '../../Actions/UIActions';

import ShopGuide from '../../Components/ShopGuide/ShopGuide';
const Guide = props => {
  useEffect(() => {
    addEvent('Shop Guide - Page View');
  }, []);
  return (
    <div className='guide-outer-container'>
      <ShopGuide toggleBodyScrollLock={props.toggleBodyScrollLock} ui={props.ui} user={props.user} />
    </div>
  );
};

Guide.propTypes = {
  // user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { ui, user } = state;
  return { ui, user };
};

export default connect(mapStateToProps, {
  // functions go here
  toggleBodyScrollLock
})(Guide);
