import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import './AllProducts.scss';

import { getSEOProducts } from '../../APIClient/products';

const AllProducts = props => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    getSEOProducts().then(resp => {
      const orderedProducts = _.orderBy(resp.products, [p => p.brand.toLowerCase(), p => p.title.toLowerCase()], ['asc', 'asc']);
      setProducts(orderedProducts);
    });
  }, []);
  return (
    <div className='all-products-outer-container'>
      <div className='all-products-inner-container'>
        {products.map(product => {
          return (
            <Link to={`/products/${product.urlStub}`} className='product' key={product.id}>
              {product.brand} | {product.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default AllProducts;
