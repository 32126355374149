import APIClient, { get400ErrorMessage } from './index';
// import _ from 'lodash';

export const getLookbook = (Lookbook_id, data) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Lookbooks/${Lookbook_id}?${new URLSearchParams(data).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const getLookbookOrder = LookbookOrder_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/LookbookOrders/${LookbookOrder_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const resyncBrandLookbooks = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Lookbooks/for_brand/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const updateLookbookOrder = (LookbookOrder_id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/LookbookOrders/${LookbookOrder_id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const getUserLookbookOrder = (Lookbook_id, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/LookbookOrders/${Lookbook_id}/${User_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const createLookbookOrder = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookOrders`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

export const createTestShopifyOrder = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookOrders/shopify/test`, { Brand_id })
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const createLookbook = lookbook => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Lookbooks`, lookbook)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const duplicateLookbook = lookbook => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Lookbooks/duplicate/${lookbook.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const updateLookbook = (Lookbook_id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Lookbooks/${Lookbook_id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const deleteLookbook = lookbook => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Lookbooks/${lookbook.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const fetchAllPreviousLookbookItems = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Lookbooks/previous_items/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const addLookbookItem = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookItems/`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(get400ErrorMessage(err)));
  });
};

export const updateLookbookItem = (item, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/LookbookItems/${item.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const deleteLookbookItem = item => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/LookbookItems/${item.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const addLookbookItemSibling = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/LookbookItemSiblings/`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const updateLookbookItemSibling = (sibling, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/LookbookItemSiblings/${sibling.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};

export const deleteLookbookItemSibling = sibling => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/LookbookItemSiblings/${sibling.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err?.data?.error));
  });
};
