import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './LatestSection_Webinars.scss';

const LatestSection_Webinars = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section webinars'>
      {items.map(item => {
        const { activeStartDate, title, subtitle, url } = item;
        const startDateDisplay = activeStartDate && moment(activeStartDate).format('MMMM Do');
        const daysFromNow =
          activeStartDate &&
          moment(activeStartDate)
            .startOf('day')
            .diff(moment().startOf('day'), 'days');
        const daysFromNowDisplay = !activeStartDate
          ? null
          : daysFromNow > 30
          ? null
          : daysFromNow === 0
          ? 'Today'
          : daysFromNow === 1
          ? 'Tomorrow'
          : daysFromNow > -1
          ? `${daysFromNow} days from now`
          : null;
        const isPast = !activeStartDate || daysFromNow < 0;
        const additionalClasses = { past: isPast };
        return (
          <div className={cn('webinar', additionalClasses)} key={item.id}>
            <div className='meta'>
              {startDateDisplay && <div className='date'>{startDateDisplay}</div>}
              <div className='title'>{title}</div>
              {subtitle && <div className='subtitle'>{subtitle}</div>}
            </div>
            <a href={url} target='_blank' rel='noopener noreferrer' className={cn('action-display', additionalClasses)}>
              {isPast ? 'Watch the Webinar' : 'Register'}
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
            {daysFromNowDisplay && <div className='days-from-now'>{daysFromNowDisplay}</div>}
            {props.getItemIndicatorBadges(item, 'primary')}
          </div>
        );
      })}
    </div>
  );
};

LatestSection_Webinars.propTypes = {
  section: PropTypes.object.isRequired
};

export default LatestSection_Webinars;
