import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import './LatestSection_Rates.scss';

import { getRateDisplay } from '../../../Helpers/formatting';
import { getAdjPayoutRate } from '../../../Helpers/user_helpers';

const LatestSection_Rates = props => {
  const { section } = props;
  const { items } = section;
  return (
    <div className='latest-section rates'>
      {items.map(item => (
        <Rate item={item} {...props} key={item.id} />
      ))}
    </div>
  );
};

const Rate = props => {
  const { item, user } = props;
  const { merchant, subtitle, domain } = item;

  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const toggleFullDescription = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowFullDescription(!showFullDescription);
  };

  if (!merchant) return null;

  const rate = getAdjPayoutRate(user, merchant);
  const rateDisplay = getRateDisplay(rate, merchant);
  const url = `https://${domain}`;
  return (
    <a href={url} key={item.id} rel='noopener noreferrer' target='_blank' className='rate'>
      <div className='percentage'>
        <div className='value'>{rateDisplay || '-'}</div>
      </div>
      <div className='data-container'>
        <div className='brand'>{merchant.name}</div>
        <div className='domain'>
          {domain?.toLowerCase()}
          <FontAwesomeIcon icon={faExternalLinkAlt} className='icon' />
        </div>
        {subtitle && (
          <div
            onClick={toggleFullDescription}
            className={cn('description', {
              expanded: showFullDescription
            })}
          >
            {subtitle}
          </div>
        )}
      </div>
      {props.getItemIndicatorBadges(item, 'primary')}
    </a>
  );
};

LatestSection_Rates.propTypes = {
  user: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired
};

export default LatestSection_Rates;
