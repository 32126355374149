import APIClient from './index';
import _ from 'lodash';

export const authorizeUserForDeveloper = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserAuthenticationTokens`, data)
      .then(response => resolve(response.data))
      .catch(error => {
        reject({ error: _.get(error, ['data', 'error']), status: error?.status });
      });
  });
};

export const updateUserAuthenticationToken = (id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserAuthenticationTokens/${id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => {
        reject({ error: _.get(error, ['data', 'error']), status: error?.status });
      });
  });
};

export const deleteUserAuthenticationToken = id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/UserAuthenticationTokens/${id}`)
      .then(response => resolve(response.data))
      .catch(error => {
        reject({ error: _.get(error, ['data', 'error']), status: error?.status });
      });
  });
};
