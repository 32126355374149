import React, { /*useEffect,*/ useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';
import './BrandApply.scss';

import { registerUser, applyToWaitlist } from '../../Actions/UserActions';

import BrandApplyHeader from '../../Components/Landing/BrandApplyHeader';
import BrandPitchSection from '../../Components/Landing/BrandPitchSection';
import BrandApplyPanel from '../../Components/Landing/BrandApplyPanel';

const BrandApply = props => {
  const authFormRef = useRef(null);

  const scrollToAuth = panelIdx => {
    const top = _.get(authFormRef, 'current.offsetTop');
    top &&
      window.scrollTo({
        top: top - 128,
        behavior: 'smooth'
      });
  };

  return (
    <div className='brand-apply-outer-container'>
      <MetaTags>
        <title>The World's Most Trusted Experts.</title>
        <meta property='og:title' content="The World's Most Trusted Experts." />
        <meta property='description' content='Get your products in the hands of the most trusted names in beauty, skincare and more.' />
        <meta property='og:description' content='Get your products in the hands of the most trusted names in beauty, skincare and more..' />
      </MetaTags>
      <BrandApplyHeader scrollToAuth={scrollToAuth} />
      <BrandPitchSection />
      <BrandApplyPanel user={props.user} authFormRef={authFormRef} registerUser={props.registerUser} applyToWaitlist={props.applyToWaitlist} />
    </div>
  );
};

BrandApply.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  applyToWaitlist,
  registerUser
})(BrandApply);
