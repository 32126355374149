import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { addEvent } from '../../APIClient/events';
import { searchForPosts as searchForPostsAPI } from '../../APIClient/blog';

import { getBlogTabs, getBlogPost } from '../../Actions/BlogActions';

let searchDebouncer;

const BlogData = props => {
  const { getBlogTabs } = props;
  const { searchValue, setSearchResults, setIsLoading } = props;

  useEffect(() => {
    addEvent('Blog - View');
    getBlogTabs();
  }, []);

  useEffect(() => {
    if (searchValue) {
      setIsLoading(true);
      if (searchDebouncer) clearTimeout(searchDebouncer);
      searchDebouncer = setTimeout(() => {
        searchForPostsAPI(searchValue)
          .then(results => setSearchResults(results.posts))
          .catch(() => window.ALERT.error('There was an error searching for blog posts.'))
          .finally(() => setIsLoading(false));
      }, 500);
    }
  }, [searchValue]);

  return null;
};

BlogData.propTypes = {
  blog: PropTypes.object.isRequired,
  searchValue: PropTypes.string,

  setSearchResults: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,

  // fetching functions
  getBlogTabs: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog } = state;
  return { blog };
};

export default connect(mapStateToProps, {
  getBlogTabs,
  getBlogPost
})(BlogData);
