import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './PartnerPortalRequestModal.scss';
import _ from 'lodash';

import Modal from '../General/Modal';

import { openAddressModal } from '../../Actions/UIActions';
import { updateRequest } from '../../Actions/UserActions';
import { getAddress, getAdjPayoutRate } from '../../Helpers/user_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import { getLookbookOrderStatusDisplay } from '../../Helpers/lookbook_helpers';

const PartnerPortalRequestModal = props => {
  const { request, close, openAddressModal, updateRequest, user, store } = props;

  const { brand, userAccepted, userRejected, brandMessage, isComplete, lookbook, lookbook_order } = request;
  const { name: Brand_name, mobileCoverImage, logo } = brand;
  const { coverImage, brandNote } = lookbook || {};

  const { merchantData } = store;
  const merchant = merchantData[brand.domain];
  const commissionRate = getAdjPayoutRate(user, merchant) || brand.commission_rate;
  const brandDescription = brand.description;
  const brandWebsite = `https://${brand.domain}`;

  const trackingNumber = lookbook_order?.trackingNumber || request?.trackingNumber;
  const trackingUrl = lookbook_order?.trackingUrl || request?.trackingUrl;
  const trackingSource = lookbook_order?.trackingSource || request?.trackingSource;
  const lookbookOrderDisplay = getLookbookOrderStatusDisplay(lookbook_order);

  const formatAddress = () => {
    const rawAddress = lookbook_order?.address || getAddress(user)?.raw;
    if (!rawAddress) return getAddress(user)?.address || 'No address provided';
    const address = JSON.parse(rawAddress);
    const { name, address1, address2, city, state, zip, country } = address;

    return (
      <span>
        <div>{name}</div>
        <div>
          {address1} {address2 ? `, ${address2}` : ''}
        </div>
        <div>
          {city}, {state} {zip}
        </div>
        <div>{country}</div>
      </span>
    );
  };

  const userAddressObject = getAddress(user);
  const userHasToConfirmAddress = userAddressObject && !userAddressObject.isConfirmed;
  const userDecided = userAccepted || userRejected;

  const acceptGifting = () => {
    if (userHasToConfirmAddress) return openAddressModal();
    updateRequest(request, { userAccepted: true, userRejected: false });
  };
  const resetGiftingRequest = () => updateRequest(request, { userAccepted: false, userRejected: false });
  const dismissGiftingRequest = () => {
    return confirmAlert({
      title: 'Dismiss Gifting Request',
      message: 'Are you sure you want to dismiss this gifting request?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => updateRequest(request, { userRejected: true })
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const modalClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Modal
      visible
      showClose
      close={close}
      className='partner-portal-request-modal-outer'
      innerClassName='partner-portal-request-modal-inner'
      noPadding
    >
      <div className='request-modal-header-image-container'>
        <img src={coverImage || mobileCoverImage} alt='' className='header-cover-image' />
        <div className='full-image-overlay'></div>
        <div className='header-logo-container'>
          <img src={logo} alt='' className='header-logo' />
        </div>
      </div>

      <div className='request-modal-text-container'>
        <div className='gifting-request-container'>
          {userDecided ? <div className='request-modal-heading'>Brand Gifting</div> : null}
          {!userDecided && <div className='request-still-deciding-message'>{Brand_name} has sent you a gifting request:</div>}
          {!userDecided ? (
            !!(brandMessage || brandNote) && <div className='request-modal-message'>{brandNote || brandMessage}</div>
          ) : userAccepted ? (
            isComplete ? (
              <div className='request-modal-message'>{Brand_name} has marked your gifting request as shipped. Check chat for more information!</div>
            ) : (
              <div className='request-modal-message'>
                {(trackingNumber && trackingUrl && trackingSource) || !_.isEmpty(lookbook_order) ? (
                  <>
                    {lookbookOrderDisplay.display !== 'Unknown' && (
                      <div className='lookbook-order-status-icon-container'>
                        <FontAwesomeIcon icon={lookbookOrderDisplay.icon} />
                        {lookbookOrderDisplay.display}
                      </div>
                    )}
                    <div className='request-order-details'>
                      <div className='request-order-detail'>
                        <div className='request-order-detail-header'>Shipping To</div>
                        <div className='request-order-detail-body'>{formatAddress()}</div>
                      </div>
                      <div className='request-order-detail'>
                        <div className='request-order-detail-header'>Shipping By</div>
                        <div className='request-order-detail-body'>
                          {trackingNumber ? (
                            <>
                              {trackingSource ? (
                                <>
                                  {trackingSource}
                                  <br />
                                  <br />
                                </>
                              ) : null}
                              <a onClick={modalClick} href={trackingUrl} target='_blank' rel='noopener noreferrer'>
                                {trackingNumber}
                              </a>
                            </>
                          ) : (
                            'Waiting on tracking information'
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {Brand_name} handles their own gifting so we are now waiting on them to ship your package. We will update this page when we have
                    more information!
                  </>
                )}
              </div>
            )
          ) : userRejected ? (
            <div className='request-modal-message'>
              You have dismissed this gifting request, gifting will not be available for this brand until you reset your dismissal.
            </div>
          ) : null}

          {userAccepted ? (
            <div className='request-modal-button-container'>
              <button className='request-modal-button close' onClick={close}>
                Close
              </button>
            </div>
          ) : userRejected ? (
            <div className='request-modal-button-container'>
              <button className='request-modal-button reset-dismissal' onClick={resetGiftingRequest}>
                Reset Dismissal
              </button>
              <button className='request-modal-button close' onClick={close}>
                Close
              </button>
            </div>
          ) : (
            <div className='request-modal-button-container'>
              {lookbook ? (
                <a onClick={modalClick} href={`/lookbooks/${lookbook.id}`} className='request-modal-button view-lookbook'>
                  View Lookbook
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              ) : (
                <button className='request-modal-button accept-gifting' onClick={acceptGifting}>
                  Accept Gifting
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              )}
              <button className='request-modal-button dismiss-gifting' onClick={dismissGiftingRequest}>
                Dismiss Gifting
              </button>
            </div>
          )}
        </div>
        <div className='brand-description-container'>
          {parseInt(commissionRate) > 0 && (
            <div className='brand-description-section'>
              <div className='brand-description-header'>Commission</div>
              <div className='brand-description-body commission'>{commissionRate}%</div>
            </div>
          )}
          {brandDescription && (
            <div className='brand-description-section'>
              <div className='brand-description-header'>About</div>
              <div className='brand-description-body'>
                {brandDescription}
                <br />
                <br />
                <a onClick={modalClick} href={brandWebsite} target='_blank' rel='noopener noreferrer'>
                  {brand.domain}
                </a>
                <br />
                <br />
                <a onClick={modalClick} href={`/chat?query=${Brand_name}`} className='request-modal-chat-with-brand'>
                  Chat with {Brand_name}
                  <FontAwesomeIcon icon={faChevronRight} />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

PartnerPortalRequestModal.propTypes = {
  close: PropTypes.func.isRequired,
  request: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  openAddressModal: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, store, ui } = state;
  return { user, store, ui };
};

export default connect(mapStateToProps, {
  updateRequest,
  openAddressModal
})(PartnerPortalRequestModal);
