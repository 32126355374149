import APIClient from './index';
import _ from 'lodash';

export const getPins = urlParams => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Pins?${urlParams}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createPin = body => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Pins`, body)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const editPin = (id, body) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Pins/${id}`, body)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'], 'There was an error editing this product.')));
  });
};

export const deletePin = id => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/Pins/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.data));
  });
};
export const duplicatePin = (id, overrides = {}) => {
  return new Promise((resolve, reject) => {
    return APIClient.post(`/Pins/duplicate/${id}`, overrides)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const searchForPins = options => {
  return new Promise((resolve, reject) => {
    return APIClient.post(`/Pins/search`, options)
      .then(response => resolve(response.data))
      .catch(error => reject(error.data));
  });
};

export const getMetaFromUrl = url => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Pins/get_meta_data`, { url })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, ['data', 'error'])));
  });
};

export const getAlternativeImages = query => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Pins/get_alternative_images?query=${query}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const getExistingPinImages = url => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Pins/get_existing_pin_images?url=${url}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const getMerchants = urls => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Pins/get_merchant_data`, { urls })
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const getProductFromUrl = url => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Products/from_url?url=${url}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const createGeoLink = (pin, link, countryDomain) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`GeoLinks`, { Pin_id: pin.id, link, countryDomain })
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const removeGeoLink = geolink => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`GeoLinks/${geolink.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const getAffiliateLinks = pins => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Pins/get_affiliate_links`, { pinIds: pins.map(p => p.id) })
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};
