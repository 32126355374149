import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import './ConsultElement.scss';

import ConsultElementLongQuestion from './elements/ConsultElementLongQuestion';
import ConsultElementMediaUpload from './elements/ConsultElementMediaUpload';
import ConsultElementMultiChoice from './elements/ConsultElementMultiChoice';
import ConsultElementShortQuestion from './elements/ConsultElementShortQuestion';

const ConsultElement = props => {
  const { element, deleteElement, dragHandle } = props;
  const { type } = element;
  const canEdit = !!dragHandle;

  const [question, setQuestion] = useState(element.question || '');
  const [placeholder, setPlaceholder] = useState(element.placeholder || '');
  const [answer, setAnswer] = useState(element.answer || '');
  const [options, setOptions] = useState(element.options || []);
  const [mediaUrls, setMediaUrls] = useState(element.mediaUrls || []);

  const saveFields = keyValuePairs =>
    props[canEdit ? 'updateElement' : 'updateResponse'](
      element,
      _.reduce(keyValuePairs, (r, kvp) => ({ ...r, [kvp[0]]: kvp[1] }), {})
    );

  const setField = (key, value, autoSaveTimeout = 0) => {
    window.saveFieldDebouncer && clearTimeout(window.saveFieldDebouncer);
    window.saveFieldDebouncer = setTimeout(() => saveFields([[key, value]]), autoSaveTimeout);
    const setFn = {
      question: setQuestion,
      placeholder: setPlaceholder,
      answer: setAnswer,
      options: setOptions,
      mediaUrls: setMediaUrls
    }[key];
    return setFn && setFn(value);
  };

  const setFields = (keyValuePairs, autoSaveTimeout = 0) => {
    window.saveFieldDebouncer && clearTimeout(window.saveFieldDebouncer);
    window.saveFieldDebouncer = setTimeout(() => saveFields(keyValuePairs), autoSaveTimeout);
    keyValuePairs.forEach(([key, value], idx) => {
      const setFn = {
        question: setQuestion,
        placeholder: setPlaceholder,
        answer: setAnswer,
        options: setOptions,
        mediaUrls: setMediaUrls
      }[key];
      return setFn && setFn(value);
    });
  };

  // Used while editing
  const visibleQuestion = canEdit ? question : element.question || '';
  const visiblePlaceholder = canEdit ? placeholder : element.placeholder || '';
  const visibleOptions = canEdit ? options : element.options || [];
  const visibleMediaUrls = canEdit ? mediaUrls : element.mediaUrls || [];

  // Used only for responses
  const visibleAnswer = answer;

  let elementDiv = null;
  if (type === 'SHORT_QUESTION') {
    elementDiv = (
      <ConsultElementShortQuestion
        canEdit={canEdit}
        question={visibleQuestion}
        answer={visibleAnswer}
        placeholder={visiblePlaceholder}
        setField={setField}
      />
    );
  } else if (type === 'LONG_QUESTION') {
    elementDiv = (
      <ConsultElementLongQuestion canEdit={canEdit} question={visibleQuestion} answer={visibleAnswer} placeholder={placeholder} setField={setField} />
    );
  } else if (type === 'MULTI_CHOICE_QUESTION') {
    elementDiv = (
      <ConsultElementMultiChoice
        element={element}
        canEdit={canEdit}
        question={visibleQuestion}
        placeholder={visiblePlaceholder}
        answer={visibleAnswer}
        options={visibleOptions}
        setField={setField}
        setFields={setFields}
      />
    );
  } else if (type === 'MEDIA_UPLOAD') {
    elementDiv = <ConsultElementMediaUpload canEdit={canEdit} question={visibleQuestion} mediaUrls={visibleMediaUrls} setField={setField} />;
  }

  return (
    <div className='consult-element-outer-container'>
      <div>{elementDiv || element.type}</div>
      {canEdit && (
        <div className='action-els'>
          <div onClick={() => deleteElement(element)} className='delete-icn icn'>
            <FontAwesomeIcon icon={faTrash} />
          </div>
          {dragHandle}
        </div>
      )}
    </div>
  );
};

ConsultElement.propTypes = {
  element: PropTypes.object.isRequired,
  dragHandle: PropTypes.object,
  updateElement: PropTypes.func,
  updateResponse: PropTypes.func
};

export default ConsultElement;
