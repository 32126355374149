import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomePartnerExample.scss';

const HomePartnerExample = props => {
  const { partner } = props;
  const { image, image_overlay, name, rate, isShop, to } = partner;

  const children = (
    <>
      <div className='image-container'>
        <img className='primary' src={image} alt={name} />
        <div className='cover' />
        {image_overlay && <img className='overlay' src={image_overlay} alt={name} />}
        {isShop ? (
          <div className='data shop'>
            <div className='label'>Curated By</div>
            <div className={cn('name', { long: name.length > 16 })}>{name}</div>
          </div>
        ) : (
          <div className='data commission'>
            <div className='commission'>Commission</div>
            <div className='rate'>{rate}%</div>
          </div>
        )}
      </div>
    </>
  );

  return to ? (
    <Link target='_blank' to={to} className='home-partner-example'>
      {children}
    </Link>
  ) : (
    <div className='home-partner-example not-clickable'>{children}</div>
  );
};

HomePartnerExample.propTypes = {
  partner: PropTypes.object.isRequired
};

export default HomePartnerExample;
