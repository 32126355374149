import APIClient from './index';
import _ from 'lodash';

export const getCodesForBrandId = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/CustomCodes/for_brand/${Brand_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
