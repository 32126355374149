import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './LookbookItemImages.scss';

import Image from '../../General/Image';

const LookbookItemImages = props => {
  const { lookbook, item, activeSibling } = props;
  const { title } = item;
  const image = activeSibling?.image || item?.image;

  // Grab images from the selected item
  const images = !activeSibling ? [image] : _.filter(_.concat(activeSibling.image, activeSibling.alt_images?.split(',')));

  // Allow switching images
  const [selectedImage, setSelectedImage] = React.useState(image);
  const currentImageIndex = _.indexOf(images, selectedImage);
  const nextImage = () => setSelectedImage(images[currentImageIndex + 1]);
  const prevImage = () => setSelectedImage(images[currentImageIndex - 1]);

  // If the active sibling changes, reset the selected image
  React.useEffect(() => {
    setSelectedImage(image);
  }, [activeSibling?.id]);

  // UI Handling
  const showDots = images.length > 1;
  const canGoNext = currentImageIndex < images.length - 1;
  const canGoPrev = currentImageIndex > 0;

  return (
    <div className='lookbook-item-images-container'>
      <Image src={selectedImage} alt={title || 'Default'} className={cn('lookbook-item-image', lookbook.imageStyle)} failedText='Product Image' />

      {/* Dots */}
      {showDots && (
        <div className='dots'>
          {_.map(images, (image, index) => (
            <div key={index} className={cn('dot', { active: selectedImage === image })} onClick={() => setSelectedImage(image)} />
          ))}
        </div>
      )}

      {/* Arrows */}
      <div className='arrows'>
        {canGoPrev ? (
          <div onClick={prevImage} className='previous arrow'>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
        ) : (
          <div />
        )}
        {canGoNext ? (
          <div onClick={nextImage} className='next arrow'>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

LookbookItemImages.propTypes = {
  lookbook: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  activeSibling: PropTypes.object.isRequired
};

export default LookbookItemImages;
