const errorMessageBlockList = [
  '_AutofillCallbackHandler' // from ios instagram web view https://developers.facebook.com/community/threads/320013549791141/
];

export const blockBeforeSend = (event, hint) => {
  const errorMessage = hint.originalException?.message;
  if (!errorMessage) return event;

  const isBlocked = errorMessageBlockList.some(block => errorMessage.includes(block));

  if (isBlocked) {
    return null;
  }

  return event;
};
