import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import './RequestResult.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink, faCommentAlt, faCalendar, faUser, faChevronRight, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCalendar as faCalendarSolid } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import cn from 'classnames';
import _ from 'lodash';

import { getRelativePrettyDate, getPrettyDate } from '../../../Helpers/formatting';
import { isSimulatingUser } from '../../../Helpers/user_helpers';
import { getLookbookOrderItemParts } from '../../../Helpers/lookbook_helpers';
import {
  formatUsersForBulkRequests,
  hasRequestExpired,
  isRequestOutstanding,
  isRequestAccepted,
  openEditRequestExpirationOverlay
} from '../../../Helpers/gifting_helpers';
import { isOpportunityRequestExpired, isOpportunityRequestOutstanding, isOpportunityRequestAccepted } from '../../../Helpers/opportunity_helpers';
import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { copyToClipboard } from '../../../Helpers/helpers';
import { isSubscribedToFeatureOrAdmin } from '../../../Helpers/subscription_helpers';

import ConfirmDelete from '../../General/ConfirmDelete';
import Tooltip from '../../General/Tooltip';

const RequestResult = props => {
  const { request, lookbook, opportunity, largeFormat, talent, signedInUser, featureRequired, ui } = props;
  const { lookbook_order, expiresOn, user } = request;
  const isGiftingRequest = 'Lookbook_id' in request;
  const isOpportunityRequest = 'Opportunity_id' in request;
  const hasRequiredFeature = featureRequired ? isSubscribedToFeatureOrAdmin(props.user, featureRequired) : true;
  const { id: User_id, image, name } = user || {};
  const { isManuallyShipped, items, shopifyOrderId } = lookbook_order || {};
  const { title: Lookbook_title, id: Lookbook_id } = lookbook || {};

  const userFromTalent = _.find(talent, { id: request.User_id });

  const { address } = userFromTalent || {};
  const hasExpired = isGiftingRequest ? hasRequestExpired(request) : isOpportunityRequestExpired(request);
  const isOutstanding = isGiftingRequest ? isRequestOutstanding(request) : isOpportunityRequestOutstanding(request);
  const isAccepted = isGiftingRequest ? isRequestAccepted(request) : isOpportunityRequestAccepted(request);
  const canEditExpiration = hasExpired || isOutstanding;

  const waitingOnUserResponse = request.brandAccepted && !request.userAccepted && !request.userRejected;
  const isShopmyAdmin = isSimulatingUser(signedInUser);
  const adminControlMode = isAdminControlMode(ui);
  const history = useHistory();

  const INITIAL_LOOKBOOK_ORDER_ITEMS_TO_SHOW = 3;
  const [seeAllLookbookOrderItems, setSeeAllLookbookOrderItems] = useState(false);

  const openChatOverlay = () => props.openChatOverlay({ id: User_id, name, image });
  const openArtistModal = () => props.openArtistModal({ id: User_id });
  const markSent = () => props.markSent(request);
  const attemptDeletion = () => {
    let message, header;

    if (waitingOnUserResponse) {
      header = 'Are you sure you want to delete this request?';
      message = `We will remove this request and notify ${name}.`;
    } else if (lookbook_order?.items?.length) {
      header = 'Are you sure you want to delete this order?';
      message = shopifyOrderId
        ? `We will delete this order and you will be able to resend if you would like, however you will need to delete the order with ID ${shopifyOrderId} manually on Shopify.`
        : lookbook_order
        ? 'By deleting this order you will be able to resend a request to this lookbook.'
        : 'By confirming, we will remove this accepted request, allowing you to resend if necessary.';
    } else if (lookbook && !lookbook_order?.items) {
      header = 'Are you sure?';
      message =
        'It looks like this lookbook request does not have associated items, meaning the user has an old version of the app or something else happened. Please continue to delete and resubmit the lookbook request.';
    } else {
      header = 'Are you sure you want to delete this request?';
      message = `We will remove this request and notify ${name}.`;
    }
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmDelete
          header={header}
          subheader={message}
          onCancel={onClose}
          onDelete={async () => {
            isGiftingRequest ? await props.removeSamplesRequest(request) : await props.removeOpportunityRequest(request);
            onClose();
          }}
          additionalActions={[
            {
              label: 'Delete and Resend',
              onClick: async () => {
                isGiftingRequest ? await props.removeSamplesRequest(request) : await props.removeOpportunityRequest(request);
                onClose();
                props.openRequestModal({
                  params: {
                    type: isGiftingRequest ? 'gifting' : 'opportunities',
                    Lookbook_id: lookbook?.id,
                    Opportunity_id: opportunity?.id,
                    OpportunityPaymentTier_id: request.OpportunityPaymentTier_id,
                    warningOnClose: isGiftingRequest
                      ? 'You have not re-sent this gifting request yet. Are you sure you want to close this modal?'
                      : 'You have not re-sent this opportunity request yet. Are you sure you want to close this modal?',
                    preselectedUsers: formatUsersForBulkRequests([user])
                  }
                });
              },
              type: 'secondary'
            }
          ]}
        />
      )
    });
  };

  const updateExpirationDate = () => {
    openEditRequestExpirationOverlay(request, isGiftingRequest ? props.updateRequest : props.updateOpportunityRequest);
  };

  const hasLookbookOrder = !_.isEmpty(lookbook_order);
  const needsFulfillment = isGiftingRequest && !request.isComplete && request.brandAccepted && request.userAccepted;
  const talentIsNotInterested = request.userRejected;
  const isComplete = isGiftingRequest && request.isComplete;

  const getHeader = () => name;
  const getSubHeader = () => {
    let parts = [];

    // Apply to both types
    if (needsFulfillment) parts.push(`Accepted ${getRelativePrettyDate(request.userAcceptedAt)}`);
    else if (talentIsNotInterested) parts.push(`Not Currently Interested`);
    else if (isComplete) parts.push(`Order Fulfilled ${getRelativePrettyDate(request.isCompleteAt)}`);
    else if (hasExpired) parts.push(`Request Expired ${getRelativePrettyDate(request.expiresOn)}`);
    else if (isAccepted) parts.push(`Accepted ${getRelativePrettyDate(request.userAcceptedAt)}`);
    else parts.push(`Sent ${getRelativePrettyDate(request.createdAt)}${expiresOn ? ` • Expires ${getPrettyDate(request.expiresOn)}` : ''}`);

    if (isGiftingRequest) {
      if (Lookbook_id) {
        parts.push(' • ');
        parts.push(
          <a onClick={e => e.stopPropagation()} href={`/lookbooks/${Lookbook_id}`}>
            {Lookbook_title}
          </a>
        );
      } else if (hasLookbookOrder) parts.push('• Deleted Lookbook');
      else parts.push('• General Gifting Request');
    } else if (isOpportunityRequest) {
      if (opportunity) {
        parts.push(' • ');
        parts.push(
          <a onClick={e => e.stopPropagation()} href={`/opportunity/${opportunity.id}`}>
            {opportunity.title}
          </a>
        );
      } else {
        parts.push('• Deleted Opportunity');
      }
    }
    return parts;
  };

  const getTrackingButtonInformation = () => {
    const brandMustHandleFullfillment = isManuallyShipped || !Lookbook_id;
    const shopifyMustHandleFullfillment = !!lookbook_order?.shopifyOrderId;
    const shopmyMustHandleFullfillment = !brandMustHandleFullfillment && !shopifyMustHandleFullfillment;

    // brand handling
    if (shopifyMustHandleFullfillment)
      return {
        text: 'Awaiting Fulfillment on Shopify >',
        isDisabled: true,
        clickable: true,
        onClick: () => history.push(`/Lookbooks/order/${lookbook_order.id}`)
      };
    // shopmy handling
    else if (brandMustHandleFullfillment && isShopmyAdmin && !adminControlMode)
      return { text: 'Awaiting Brand Fulfillment', isDisabled: true, clickable: false, onClick: () => {} };
    else if (brandMustHandleFullfillment && (!isShopmyAdmin || adminControlMode))
      return { text: 'Add Tracking Information', isDisabled: false, clickable: true, onClick: markSent };
    else if (shopmyMustHandleFullfillment && isShopmyAdmin)
      return { text: 'Add Tracking Information', isDisabled: false, clickable: true, onClick: markSent };
    else if (shopmyMustHandleFullfillment && !isShopmyAdmin)
      return { text: 'Shopmy Handling Order Creation', isDisabled: true, clickable: false, onClick: () => {} };
  };

  const trackingButtonData = getTrackingButtonInformation();

  const additionalClasses = { 'cannot-access': !hasRequiredFeature };
  return largeFormat ? (
    <div className={cn('request-result-outer', additionalClasses)}>
      <div className={cn('request-result-inner large-form')}>
        <div className='large-form-image-container'>
          <div className='action-icons'>
            <FontAwesomeIcon icon={faCommentAlt} onClick={openChatOverlay} />
            <FontAwesomeIcon icon={faUser} onClick={openArtistModal} />
            <FontAwesomeIcon icon={faTimes} onClick={attemptDeletion} />
          </div>
          {image ? <img src={image} alt={name} /> : <div className='no-image'>{user?.name?.[0]}</div>}
          <div className='large-form-image-container-text' onClick={openArtistModal}>
            <div className='request-result-header'>{getHeader()}</div>
            <div className='request-result-subheader'>{getSubHeader()}</div>
          </div>
        </div>
        <div className='large-form-order-details-container'>
          <div>
            <div className='large-form-order-details-section'>
              {address ? (
                <div className='address-container'>
                  <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboard(address, true, 'Copied Address')} />
                  <div className='address-header'>Address</div>
                  <div className='address-body'>{address}</div>
                </div>
              ) : (
                <div className='address-container'>No Address Provided</div>
              )}
            </div>
            <div className='large-form-order-details-section'>
              {hasLookbookOrder ? (
                <div className='lookbook-order-items-container'>
                  {items.map((orderItem, i) => {
                    const { id, quantity, image, title, url } = orderItem;
                    const { name, siblingName } = getLookbookOrderItemParts(orderItem);

                    if (!seeAllLookbookOrderItems && i >= INITIAL_LOOKBOOK_ORDER_ITEMS_TO_SHOW) return null;
                    return (
                      <div className='lookbook-order-item' key={`lookbook-order-item-${id}`}>
                        {image ? <img src={image} alt={title} /> : <div className='no-image'>N/A</div>}
                        <div className='lookbook-order-item-text'>
                          <div className='lookbook-order-item-title'>
                            {name}
                            <a href={url} target='_blank' rel='noopener noreferrer' className='lookbook-order-item-link'>
                              <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                          </div>
                          <div className='lookbook-order-item-subtitle'>
                            {quantity > 1 ? `${quantity} x ` : null}
                            {siblingName ? siblingName : 'Default'}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {items.length > INITIAL_LOOKBOOK_ORDER_ITEMS_TO_SHOW && (
                    <div className='see-all-items-button' onClick={() => setSeeAllLookbookOrderItems(!seeAllLookbookOrderItems)}>
                      {seeAllLookbookOrderItems ? 'See Less' : 'See All'}
                    </div>
                  )}
                </div>
              ) : lookbook ? (
                <span className='general-gifting-request-message'>
                  It appears as if this user is using an old version of the mobile app prior to the lookbooks feature going live. Please contact them
                  to update the app and resubmit their request.
                </span>
              ) : (
                <span className='general-gifting-request-message'>
                  No product selections as this was a General Gifting Request and not attached to a lookbook
                </span>
              )}
            </div>
          </div>
          <button
            className={cn('order-details-button', { disabled: trackingButtonData.isDisabled, clickable: trackingButtonData.clickable })}
            onClick={trackingButtonData.onClick}
          >
            {trackingButtonData.text}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={cn('request-result-outer small-form', { talentIsNotInterested, ...additionalClasses })}>
      <div className={cn('request-result-inner small-form ')}>
        <div onClick={openArtistModal} className='main-information'>
          <div className='small-form-image-container'>
            {image ? <img src={image} alt={name} /> : <div className='no-image'>{user?.name?.[0]}</div>}
          </div>
          <div className='small-form-text-container'>
            <div className='request-result-header'>{getHeader()}</div>
            <div className='request-result-subheader'>{getSubHeader()}</div>
          </div>
        </div>
        {lookbook_order?.trackingUrl && lookbook_order?.trackingNumber && (
          <div className='small-form-tracking-information'>
            <span
              onClick={e => {
                e.stopPropagation();
                copyToClipboard(lookbook_order.trackingUrl || lookbook_order.trackingNumber, true, 'Copied Tracking Information');
              }}
            >
              {lookbook_order.trackingSource || lookbook_order.trackingNumber || ''}
              <FontAwesomeIcon icon={faCopy} />
            </span>
            <a href={`/Lookbooks/order/${lookbook_order.id}`} target='_blank' rel='noopener noreferrer' onClick={e => e.stopPropagation()}>
              View Lookbook Order
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>
        )}
        <div className='action-icons'>
          {canEditExpiration && (
            <Tooltip
              message={
                !request.expiresOn
                  ? 'Set Expiration Date'
                  : `${hasExpired ? 'Expired' : 'Expires on'} on ${getPrettyDate(request.expiresOn)}, click to update.`
              }
            >
              <FontAwesomeIcon
                className={cn('action-icon expiration', {
                  active: expiresOn
                })}
                icon={expiresOn ? faCalendarSolid : faCalendar}
                onClick={updateExpirationDate}
              />
            </Tooltip>
          )}
          <FontAwesomeIcon className='action-icon delete' icon={faTimes} onClick={attemptDeletion} />
        </div>
      </div>
    </div>
  );
};

RequestResult.propTypes = {
  talent: PropTypes.array,
  ui: PropTypes.object,
  request: PropTypes.object.isRequired,
  lookbook: PropTypes.object,
  opportunity: PropTypes.object,
  user: PropTypes.object,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  removeSamplesRequest: PropTypes.func.isRequired,
  removeOpportunityRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  updateOpportunityRequest: PropTypes.func.isRequired,
  markSent: PropTypes.func.isRequired,
  signedInUser: PropTypes.object.isRequired
};

export default RequestResult;
