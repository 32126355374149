import { getFeatured, updateFeaturedItem as updateFeaturedItemAPI } from '../APIClient/featured';
import { getAllJobs as getAllJobsAPI } from '../APIClient/jobs';

// landing page
export const GET_FEATURED_ITEMS_SUCCESS = 'GET_FEATURED_ITEMS_SUCCESS';
export const GET_FEATURED_ITEMS_ERROR = 'GET_FEATURED_ITEMS_ERROR';
export const UPDATE_FEATURED_ITEM_SUCCESS = 'UPDATE_FEATURED_ITEM_SUCCESS';
export const UPDATE_FEATURED_ITEM_ERROR = 'UPDATE_FEATURED_ITEM_ERROR';

// jobs
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS';
export const GET_ALL_JOBS_FAILURE = 'GET_ALL_JOBS_FAILURE';

export const syncFeaturedItems = () => async (dispatch, getState) => {
  try {
    const featured = await getFeatured();
    return dispatch({
      type: GET_FEATURED_ITEMS_SUCCESS,
      featured
    });
  } catch (error) {
    return dispatch({
      type: GET_FEATURED_ITEMS_ERROR
    });
  }
};

export const updateFeaturedItem = (featuredItem, updates) => async (dispatch, getState) => {
  try {
    const resp = await updateFeaturedItemAPI(featuredItem, updates);
    dispatch(syncFeaturedItems()); // Since this is an admin only UI, we can handle the full reload
    return dispatch({
      type: UPDATE_FEATURED_ITEM_SUCCESS,
      item: resp
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_FEATURED_ITEM_ERROR,
      error: error
    });
  }
};

export const getAllJobs = () => async (dispatch, getState) => {
  try {
    const jobsResponse = await getAllJobsAPI();
    return dispatch({
      type: GET_ALL_JOBS_SUCCESS,
      jobs: jobsResponse.jobs
    });
  } catch (error) {
    return dispatch({
      type: GET_ALL_JOBS_FAILURE
    });
  }
};
