import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import './VideoPlayer.scss';

const VideoPlayer = props => {
  const { src, isIframe, title } = props;

  const [isPlaying, setIsPlaying] = React.useState(false);
  const onPlay = () => {
    setIsPlaying(true);
    props.onPlay && props.onPlay();
  };
  const onPause = () => {
    setIsPlaying(false);
    props.onPaused && props.onPause();
  };

  return (
    <div className={cn('video-player-container', { iframe: isIframe })}>
      {isIframe ? (
        <iframe frameBorder='none' allowFullScreen title={title} src={src} />
      ) : (
        <Video
          autoPlay={props.autoPlay}
          ref={props.videoRef}
          onPlay={onPlay}
          onPause={onPause}
          crossOrigin='true'
          controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
        >
          <source src={src} type='video/mp4' />
          <source src={src} type='video/ogg' />
        </Video>
      )}
      {props.hasCover && (
        <div className={cn('video-cover', { playing: isPlaying })}>
          <div className='video-cover-header'>{props.coverHeader}</div>
          <div className='video-cover-subheader'>{props.coverSubheader}</div>
        </div>
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  isIframe: PropTypes.bool,
  title: PropTypes.string,

  // Optional Header
  hasCover: PropTypes.bool,
  coverHeader: PropTypes.string,
  coverSubheader: PropTypes.string,

  // Video Management
  autoPlay: PropTypes.bool,
  videoRef: PropTypes.object,
  onPlay: PropTypes.func,
  onPause: PropTypes.func
};

export default VideoPlayer;
