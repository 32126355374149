import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import cn from 'classnames';
import './UserLookbookHeader.scss';

import { getUserId } from '../../../Helpers/user_helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const UserLookbookHeader = props => {
  const { lookbook, userHasAlreadyOrdered, user } = props;
  const { coverImage, title } = lookbook;
  const userIsOnPlatform = getUserId(user);

  const history = useHistory();
  const lastLocation = useLastLocation();

  let headBack, headBackLabel;
  if (lastLocation?.pathname?.includes('/partners')) {
    headBack = () => history.goBack();
    headBackLabel = 'back to my gifting portal';
  } else if (lastLocation?.pathname?.includes('/opportunity')) {
    headBack = () => history.goBack();
    headBackLabel = 'back to opportunity';
  } else {
    headBack = () => history.push('/partners/gifting');
    headBackLabel = 'back to my gifting portal';
  }

  return (
    <div className={cn('user-lookbook-header-outer', { 'has-cover-image': !!coverImage })}>
      {coverImage && <img className='cover-image' alt='cover' src={coverImage} />}
      {coverImage && <div className='cover-image-background'></div>}
      <div className={cn('user-lookbook-header-inner', { 'has-cover-image': !!coverImage })}>
        <div className={cn('text-container', { userHasAlreadyOrdered })}>
          <div id='backlink-and-title'>
            {userIsOnPlatform && (
              <div className='backlink'>
                <FontAwesomeIcon icon={faArrowLeft} />
                <div onClick={headBack}>{headBackLabel}</div>
              </div>
            )}
            <div className='lookbook-title'>{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserLookbookHeader.propTypes = {
  lookbook: PropTypes.object.isRequired,
  userHasAlreadyOrdered: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

export default UserLookbookHeader;
