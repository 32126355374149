import _ from 'lodash';
import cogoToast from 'cogo-toast';
import * as ActionTypes from '../Actions/FeaturedActions';

export const featured = (
  state = {
    featuredShops: [],
    featuredPosts: [],
    jobs: []
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_FEATURED_ITEMS_SUCCESS:
      return {
        ...state,
        featuredShops: _.orderBy(
          _.filter(action.featured, f => f.content_type === 'Shop'),
          'ranking'
        ),
        featuredPosts: _.orderBy(
          _.filter(action.featured, f => f.content_type === 'Post'),
          'ranking'
        )
      };

    case ActionTypes.UPDATE_FEATURED_ITEM_SUCCESS:
      // Redux update handled by a GET/Featured call immediately upon success
      return {
        ...state
      };

    case ActionTypes.UPDATE_FEATURED_ITEM_ERROR:
      cogoToast.error(`There was an error updating this item, please see the console.`);
      return {
        ...state
      };

    case ActionTypes.GET_ALL_JOBS_SUCCESS:
      return {
        ...state,
        jobs: action.jobs
      };
    case ActionTypes.GET_ALL_JOBS_FAILURE:
      window.ALERT.error(`There was an error fetching jobs, please see the console.`);
      return state;

    default:
      return state;
  }
};
