import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { faTimes, faArrowUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './UserCollaborationPreview.scss';

import UploadImage from '../General/UploadImage';
import Loader from '../Loader/Loader';

import { matchScrollHeight } from '../../Helpers/helpers';
import { enhanceHTML, getPrettyPriceDisplay } from '../../Helpers/formatting';
import { getDisplayForCollaborationType, getImageOptionsForCollaborationType } from '../../Helpers/collaboration_helpers';

let debounceSave;
const UserCollaborationPreview = props => {
  const { deleteTask, startReordering, createTaskFromTemplate, editTask, selectTask, templates, collaborationUser, canEdit } = props;
  const representativeTemplate = _.first(templates);
  const { type } = representativeTemplate;
  const tasks = collaborationUser?.tasks?.filter(task => task?.template?.type === type);
  const displayVal = getDisplayForCollaborationType(type);

  const shuffleOptions = getImageOptionsForCollaborationType(type);
  const [curDescription, setCurDescription] = useState(_.first(tasks)?.description || '');
  const [curImage, setCurImage] = useState(_.first(tasks.filter(t => t.coverImageUrl))?.coverImageUrl || shuffleOptions[0]);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const mostRecentTask = _.last(_.orderBy(tasks, 'updatedAt'));
  const minutesSinceLastTaskAdded = moment().diff(mostRecentTask?.updatedAt, 'minutes');
  const [isShowingOtherOptions, setIsShowingOtherOptions] = useState(minutesSinceLastTaskAdded < 5 * 1); // Within the same default them to on
  const nextShuffleOption = shuffleOptions[_.indexOf(shuffleOptions, curImage) + 1] || shuffleOptions[0];
  const showOtherOptions = isShowingOtherOptions || !tasks.length;

  const shuffleImage = () => {
    setIsLoadingImage(true);
    setCurImage(nextShuffleOption);
    saveData({ image: nextShuffleOption, description: curDescription });
  };

  const updateImage = newImage => {
    setCurImage(newImage);
    saveData({ description: curDescription, image: newImage });
  };

  const updateDescription = newDescription => {
    setCurDescription(newDescription);
    saveData({ description: newDescription, image: curImage });
  };

  const saveData = ({ image, description }) => {
    clearTimeout(debounceSave);
    debounceSave = setTimeout(() => {
      tasks.forEach(task => {
        props.updateUserContractTask &&
          props.updateUserContractTask(task, {
            coverImageUrl: image || null,
            description: description || null
          });
      });
    }, 1500);
  };

  const activeTemplates = templates.filter(template => {
    const activeTask = _.find(tasks, task => task.ContractTaskTemplate_id === template.id);
    return !!activeTask;
  });

  return (
    <div key={type} className='user-collaboration-preview-outer-container'>
      <div className='user-collaboration-preview-container'>
        <div className='user-collaboration-preview-inner-container'>
          <div className='main'>
            <div className='content'>
              <div className='header-content'>
                <h2 className='title'>{displayVal} Collaborations</h2>
                {canEdit ? (
                  <textarea
                    rows={2}
                    value={curDescription}
                    className='description-input-container'
                    ref={ref => {
                      matchScrollHeight(ref);
                    }}
                    onChange={({ target }) => updateDescription(target.value)}
                    placeholder='Click here to describe a little more about what you can offer. Add offerings to tell brands the type of sponsored content you are open to creating. Setting prices is optional but recommended.'
                  />
                ) : (
                  <div className='subtitle'>{curDescription ? enhanceHTML(curDescription) : 'I am open to the following collaborations:'}</div>
                )}
              </div>
              <div className='templates-section'>
                {!activeTemplates.length ? (
                  <div className='empty-selection'>Please select one of the options below:</div>
                ) : (
                  <div className='templates'>
                    {activeTemplates.map(template => {
                      const { title, id, fee_type } = template;
                      const task = _.find(tasks, task => task.ContractTaskTemplate_id === template.id);
                      const select = () => selectTask(task);
                      const edit = () => editTask(task);
                      const remove = e => {
                        e.stopPropagation();
                        deleteTask(task);
                      };
                      return canEdit ? (
                        <div onClick={edit} key={id} className='template editable'>
                          <div className='title'>{title}</div>
                          <div className='bullet'>•</div>
                          {task.price ? (
                            <div className='price'>
                              {getPrettyPriceDisplay(task.price)}
                              {fee_type === 'duration' ? ' per day' : ''}
                            </div>
                          ) : (
                            <div className='price empty'>SET PRICE</div>
                          )}
                          <div onClick={remove} className='remove'>
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                      ) : (
                        <div onClick={select} key={id} className='template active'>
                          <div className='title'>{title}</div>
                          {task.price && <div className='bullet'>•</div>}
                          {task.price && (
                            <div className='price'>
                              {getPrettyPriceDisplay(task.price)}
                              {fee_type === 'duration' ? ' per day' : ''}
                            </div>
                          )}
                          <div className='add'>
                            <FontAwesomeIcon icon={faPlus} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {(canEdit || props.viewingAsBrand) && (
                <div onClick={props.toggleViewingAsBrand} className='edit-btn'>
                  {props.viewingAsBrand ? 'EDIT' : 'DONE'}
                </div>
              )}
            </div>
            <div className='image-container'>
              <img onLoad={() => setIsLoadingImage(false)} src={curImage} alt={displayVal} className='cover-image' />
              {isLoadingImage && (
                <div className='uploading-image-loader'>
                  <Loader size={80} />
                </div>
              )}
              {canEdit && (
                <div className='image-change-controls'>
                  <UploadImage
                    basicUploader
                    basicContent={
                      <div className='control upload'>
                        <FontAwesomeIcon icon={faUpload} />
                      </div>
                    }
                    onUploadProgress={(progress, status) => {
                      setIsLoadingImage(true);
                    }}
                    completeUpload={imgUrl => {
                      updateImage(imgUrl);
                    }}
                  />
                  {!!shuffleOptions.length && nextShuffleOption !== curImage && (
                    <div onClick={shuffleImage} className='control'>
                      <FontAwesomeIcon icon={faSyncAlt} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {canEdit && (
            <div className='action-icons'>
              {props.dragHandle ? (
                props.dragHandle
              ) : (
                <div onMouseDown={startReordering} className='reorder-alert'>
                  MOVE
                </div>
              )}
              <FontAwesomeIcon className='remove-icn' onClick={() => props.removeAllTasksFromType(type)} icon={faTimes} />
            </div>
          )}
        </div>
      </div>
      {canEdit && (
        <div className={cn('footer', { expanded: showOtherOptions })}>
          <div onClick={() => setIsShowingOtherOptions(!showOtherOptions)} className='footer-header'>
            {showOtherOptions ? `Select ${tasks.length > 1 ? 'other' : 'the'} offerings you are open to` : 'See all the offerings'}
            {showOtherOptions ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
          {showOtherOptions && (
            <div className='templates'>
              {templates.map(template => {
                const activeTask = _.find(tasks, task => task.ContractTaskTemplate_id === template.id);
                const add = () => {
                  createTaskFromTemplate(template, {
                    ...(curImage ? { coverImageUrl: curImage } : {}),
                    ...(curDescription ? { description: curDescription } : {})
                  });
                };
                const remove = () => deleteTask(activeTask);
                return (
                  <div onClick={activeTask ? remove : add} className={cn('template', { active: activeTask })} key={template.id}>
                    {template.title}
                    <div className='actions'>
                      <div className='action add'>
                        <FontAwesomeIcon icon={activeTask ? faTimes : faPlus} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserCollaborationPreview.propTypes = {
  user: PropTypes.object.isRequired,
  collaborationUser: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired,
  deleteTask: PropTypes.func.isRequired,
  removeAllTasksFromType: PropTypes.func.isRequired,
  startReordering: PropTypes.func.isRequired,
  updateUserContractTask: PropTypes.func.isRequired,
  createTaskFromTemplate: PropTypes.func.isRequired,
  editTask: PropTypes.func.isRequired,
  viewingAsBrand: PropTypes.bool.isRequired,
  toggleViewingAsBrand: PropTypes.func.isRequired,
  selectTask: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  dragHandle: PropTypes.object
};

export default UserCollaborationPreview;
