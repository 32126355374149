import APIClient from './index';
import _ from 'lodash';

export const getGiftingRecommendations = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Recommendations/gifting?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const removeGiftingRecommendation = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Recommendations/gifting/remove`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getRecommendedArtists = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/ArtistRecommendations?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const decideSmartRecommendation = (recommendation, data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ArtistRecommendations/decide/${recommendation.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addRecommendedArtist = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ArtistRecommendations`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateRecommendedArtist = (recommendation, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ArtistRecommendations/${recommendation.recommendationId || recommendation.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteRecommendedArtist = recommendation => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ArtistRecommendations/${recommendation.recommendationId || recommendation.ArtistRecommendation_id || recommendation.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteRecommendedArtistRejection = rejection => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ArtistRecommendationRejections/${rejection.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getUserPastRecommendationDescriptions = User_id => {
  return new Promise((resolve, reject) => {
    return APIClient.get(`/ArtistRecommendationDecisions/get_past_descriptions/${User_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const markUserAsUninterested = (Brand_id, User_id) => {
  return new Promise((resolve, reject) => {
    return APIClient.post(`/ArtistRecommendationDecisions`, {
      Brand_id,
      User_id,
      isAccepted: false,
      type: 'general'
    })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
