import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';
import algoliasearch from 'algoliasearch';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';

import AddPinModalFinal from './AddPinModalFinal';
import AddPinModalEmpty from './AddPinModalEmpty';
import AddPinModalProducts from './AddPinModalProducts';
import AddPinModalLanding from './Elements/AddPinModalLanding';
import Loader from '../Loader/Loader';

import { updateMerchantData, getHighRateMerchants, getFeaturedMerchants, editPin } from '../../Actions/StoreActions';

import { isProduction, copyToClipboard, getShortUrl } from '../../Helpers/helpers';
import { customSearchResultsRanking, cleanAutoCompleteResults } from '../../Helpers/search_helpers';
import { getLockedCatalogBrand } from '../../Helpers/store_helpers';
import { filterResultsWithLockedBrand } from '../../Helpers/brand_helpers';
import { getShortPinLink } from '../../Helpers/attribution_helpers';
import { isBrand, isYou, isBanned, isAdmin, getUsername, getName } from '../../Helpers/user_helpers';
import { isUrlString, getDomainFromUrl } from '../../Helpers/formatting';
import { addEvent } from '../../APIClient/events';
import {
  createPin as createPinAPI,
  deletePin as deletePinAPI,
  getMetaFromUrl as getMetaFromUrlAPI,
  searchForPins,
  createGeoLink,
  removeGeoLink
} from '../../APIClient/pins';
import { searchMerchants } from '../../APIClient/merchants';

import './AddPinModal.scss';

class AddPinModal extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    updateMerchantData: PropTypes.func.isRequired,
    getHighRateMerchants: PropTypes.func.isRequired,
    getFeaturedMerchants: PropTypes.func.isRequired,
    editPin: PropTypes.func.isRequired,

    // Passed from outer container
    Editing_User_id: PropTypes.number.isRequired, // The id of the user who owns the shop being edited, used to set the correct User ID on the pin
    Editing_Collection_id: PropTypes.number, // The id of the collection being edited, only set when editing from the collection panel
    Editing_ConsultResult_id: PropTypes.number, // The id of the consult result being edited, only set when editing from the consult result panel
    Editing_Contract_id: PropTypes.number, // The id of the contract being edited, only set when editing from the contract page
    toggleAddingMode: PropTypes.func.isRequired,
    inAddMode: PropTypes.bool.isRequired,
    adminControlMode: PropTypes.bool,
    isQuickLinkMode: PropTypes.bool,
    pinBeingEdited: PropTypes.object,
    forceLockedCatalogBrand: PropTypes.object,

    TEST_SEARCH_TERM: PropTypes.string,
    TEST_YOUR_PINS: PropTypes.bool
  };

  componentWillUnmount() {
    Mousetrap.unbind('esc');
  }
  componentDidMount() {
    Mousetrap.bind('esc', () => {
      if (this.props.inAddMode || this.props.pinBeingEdited) {
        this.closeModal();
      }
    });

    this.initializeSearchDebouncer();
    this.addScrollListenerToProductResults();

    this.getUrlMetaDebounced = AwesomeDebouncePromise(async value => {
      let results = {};
      if (value.length >= 2) {
        try {
          results = await getMetaFromUrlAPI(value.trim());
        } catch (err) {
          console.error('Error fetching URL meta data: ', err);
        }
      }
      return results;
    }, 100);

    setTimeout(() => this.props.getHighRateMerchants(), 200); // Delay to allow login - NEED TO CLEAN THIS UP EVENTUALLY, A BIT SLOPPY
    setTimeout(() => this.props.getFeaturedMerchants(), 200); // Delay to allow login - NEED TO CLEAN THIS UP EVENTUALLY, A BIT SLOPPY
    this.props.TEST_SEARCH_TERM && setTimeout(() => this.searchChange(this.props.TEST_SEARCH_TERM), 400);
    this.props.TEST_YOUR_PINS && setTimeout(() => this.setMode('pin'), 400);

    if (this.props.pinBeingEdited) {
      this.initializePinBeingEdited();
    }
  }

  addScrollListenerToProductResults = e => {
    document.addEventListener(
      'scroll',
      e => {
        const inFinalProductView = !!this.state.finalProduct;
        if (!this.modalInnerContainer || inFinalProductView) return;
        const maxScroll = this.modalInnerContainer.scrollHeight - this.modalInnerContainer.getBoundingClientRect().height;
        const curScroll = this.modalInnerContainer.scrollTop;
        const pixelsFromBottom = maxScroll - curScroll;
        if (pixelsFromBottom < 500 && this.state.results.length > 0 && !this.state.loadingSearch && this.state.hasMoreResults) {
          this.setState({ page: this.state.page + 1 }, () => {
            this.searchChangeNewPage(this.state.inputValue);
          });
        }
      },
      '.add-pin-modal-inner-container'
    );
  };

  componentDidUpdate(prevProps) {
    if (prevProps.addingModeEnum !== this.props.addingModeEnum) {
      this.setState({
        inputValue: '',
        results: [],
        pinResults: [],
        consultPinResults: [],
        finalProduct: null,
        addingModeEnum: null
      });
    }

    if (!prevProps.pinBeingEdited && this.props.pinBeingEdited) {
      this.initializePinBeingEdited();
    }
  }

  state = {
    addingType: null, // url, search
    results: [], // results from algolia search
    pinResults: [], // results from server search
    consultPinResults: [], // results from server consult result search
    merchantResults: [], // results from server merchant search
    autoCompleteResults: [], // results from algolia search
    autoCompleteResultHighlighted: null,
    facets: {}, // results from algolia search
    inputValue: '',

    // Search Relevant Features
    page: 0,
    brandFilters: [],
    retailerFilters: [],

    // UI Handling
    isAddingOrEditingProduct: false,
    showAutoComplete: false,
    linksInProgress: [],
    addingModeEnum: this.props.TEST_SEARCH_TERM ? 'search' : this.props.TEST_YOUR_PINS ? 'addingModeEnum' : null,
    loadingCustomSearch: false,
    loadingCustomSearchSlowly: false,
    loadingCustomSearchVerySlowly: false,
    loadingCustomSearchWayTooSlowly: false,
    isPinLinkWebRisk: false
  };

  initializePinBeingEdited = () => {
    const { pinBeingEdited } = this.props;
    this.setState({
      finalProduct: {
        title: pinBeingEdited.title || '',
        brand: null,
        link: pinBeingEdited.link,
        redirect_url: pinBeingEdited.link,
        description: pinBeingEdited.description || '',
        retailer: _.get(pinBeingEdited, ['merchant_data', 'name']),
        image: pinBeingEdited.image,
        all_images: _.uniq([pinBeingEdited.image, ...(pinBeingEdited.original_image ? [pinBeingEdited.original_image] : [])]),
        all_images_augmented: [],
        disable_monetization: !!pinBeingEdited.disable_monetization,
        allow_optimization: !!pinBeingEdited.allow_optimization,
        allow_deep_link_to_app: !!pinBeingEdited.allow_deep_link_to_app,
        allow_displaying_codes: !!pinBeingEdited.allow_displaying_codes,
        Product_id: pinBeingEdited.Product_id,
        geolinks: pinBeingEdited.geolinks
      }
    });
  };

  fetchAutoComplete = AwesomeDebouncePromise(async value => {
    if (!this.autoCompleteIndex) {
      // Initialize everything
      this.autoCompleteIndex = this.algoliaClient.initIndex(`production_autocomplete`);
      document.onkeydown = e => {
        const { autoCompleteResults, autoCompleteResultHighlighted } = this.state;
        const searchAddMode = this.isCatalogSearchMode();
        if (searchAddMode) {
          const index = _.findIndex(autoCompleteResults, r => r.query === autoCompleteResultHighlighted.query);
          if (!autoCompleteResults.length) return;
          switch (e.key) {
            case 'ArrowDown':
              this.setState({ autoCompleteResultHighlighted: autoCompleteResults[index + 1] || _.last(autoCompleteResults) });
              e.preventDefault();
              break;
            case 'Tab':
              e.shiftKey
                ? this.setState({ autoCompleteResultHighlighted: autoCompleteResults[index - 1] || _.first(autoCompleteResults) })
                : this.setState({ autoCompleteResultHighlighted: autoCompleteResults[index + 1] || _.last(autoCompleteResults) });
              e.preventDefault();
              break;
            case 'ArrowUp':
              this.setState({ autoCompleteResultHighlighted: autoCompleteResults[index - 1] || _.first(autoCompleteResults) });
              e.preventDefault();
              break;
            case 'Enter':
              autoCompleteResultHighlighted && this.selectAutoCompleteResult(autoCompleteResultHighlighted);
              e.preventDefault();
              break;
            default:
              break;
          }
        }
      };
    }
    const { hits } = await this.autoCompleteIndex.search(value);
    const results = cleanAutoCompleteResults(hits, value);
    value === this.state.inputValue && this.setState({ autoCompleteResults: results.slice(0, 12), autoCompleteResultHighlighted: _.first(results) });
  }, 250);

  initializeSearchDebouncer = () => {
    this.algoliaClient = algoliasearch('BLB7XGVAAJ', '69dbc6317a3a5f2a8106abf1b73de68a');
    const index = this.algoliaClient.initIndex(`sms_products_${isProduction() ? 'production' : 'staging'}`);
    index.setSettings({ attributesForFaceting: ['retailer', 'brand', 'url', 'Variant_id', 'hideFromPublicCatalog'] });

    this.searchProductsDebounced = AwesomeDebouncePromise(async value => {
      const MAX_RESULTS_PER_PAGE = 30;
      const { brandFilters, retailerFilters } = this.state;
      const coreSearchParams = {
        hitsPerPage: MAX_RESULTS_PER_PAGE,
        page: this.state.page,
        highlightPreTag: '<em>',
        highlightPostTag: '</em>',
        getRankingInfo: true,
        facets: ['retailer', 'brand', 'url', 'Variant_id', 'hideFromPublicCatalog'],
        // facetFilters: 'hideFromPublicCatalog:false',
        facetFilters: '',
        ...(brandFilters.length || retailerFilters.length
          ? {
              optionalFilters: [
                ...(retailerFilters.length ? [retailerFilters.map(filter => `retailer:${filter}`)] : []),
                ...(brandFilters.length ? [brandFilters.map(filter => `brand:${filter}`)] : [])
              ]
            }
          : {}),
        attributesToSnippet: ['title', 'brand']
      };

      let augmentFacets = facets => {
        _.forEach(facets, (data, type) => {
          _.forEach(data, (count, retailer) => {
            resultFacets[type] = {
              ...(resultFacets[type] || {}),
              [retailer]: _.get(resultFacets, [type, retailer], 0) + count
            };
          });
        });
      };

      let primaryResults = [];
      let secondaryResults = [];
      let resultFacets = {};

      // Primary results are a near direct match
      const fetchPrimary = async () => {
        try {
          const { hits, facets } = await index.search(value, coreSearchParams);
          primaryResults = value.length ? hits : [];
          augmentFacets(facets);
        } catch (err) {
          console.error('Error fetching primary results: ', err);
        }
      };

      // We always want to show results so we also fetch a backup set of results
      // This set does not use paging
      const fetchSecondary = async () => {
        try {
          const { hits } = await index.search(value, {
            similarQuery: value,
            ...coreSearchParams,
            page: 0
          });
          secondaryResults = value.length ? hits : [];
        } catch (err) {
          console.error('Error fetching secondary results: ', err);
        }
      };

      await Promise.all([fetchPrimary(), fetchSecondary()]);

      // If we receive the limit of primary results, we don't need to show secondary results and instead
      // can wait for the subsequent page.
      let newResults, allResults, hasMoreResults;
      if (primaryResults.length === MAX_RESULTS_PER_PAGE) {
        newResults = primaryResults;
        allResults = _.concat(this.state.results.slice(0, this.state.page * MAX_RESULTS_PER_PAGE), newResults);
        hasMoreResults = true;
      } else {
        newResults = [...primaryResults, ...secondaryResults];
        allResults = _.concat(this.state.results.slice(0, this.state.page * MAX_RESULTS_PER_PAGE), newResults);
        hasMoreResults = false;
      }

      const filteredResults = window.LOCK_CATALOG_MATCHES_ONLY ? filterResultsWithLockedBrand(allResults, this.getLockedCatalogBrand()) : allResults;
      const uniqueResults = _.uniqBy(filteredResults, r => r.objectID + r._id);
      return {
        results: customSearchResultsRanking(uniqueResults),
        facets: resultFacets,
        hasMoreResults
      };
    }, 500);

    this.searchPinsDebounced = AwesomeDebouncePromise(async (value, options = {}) => {
      return new Promise(async resolve => {
        const results = await searchForPins({ query: value, ...options });
        const uniqResults = _.uniqBy(results, result => result.title + _.get(result, 'user.username'));
        resolve(uniqResults);
      });
    }, 500);

    this.getMerchantResultsDebouncer = AwesomeDebouncePromise(async value => {
      return new Promise(async resolve => {
        const merchants = value.length < 2 ? [] : await searchMerchants(value.trim());
        const validMerchants = _.filter(merchants, m => {
          // For now, only show merchants with root domains
          const coreDomain = m.domain.split('us.').join(''); // allow us.
          return coreDomain.split('.').length <= 2;
        });
        resolve(validMerchants);
      });
    }, 500);
  };

  toggleFilter = (value, type) => {
    const stateType = type === 'retailer' ? 'retailerFilters' : 'brandFilters';
    const stateValue = this.state[stateType];
    this.setState(
      {
        [stateType]: stateValue.includes(value) ? _.without(stateValue, value) : [...stateValue, value]
      },
      () => {
        this.searchChange(this.state.inputValue);
      }
    );
  };

  mainInputChange = e => {
    let url = e?.target?.value;
    if (typeof url !== 'string') return;
    this.searchChange(url);
    this.setState({ showAutoComplete: true });
  };
  mainInputFocus = () => this.setState({ showAutoComplete: true });
  mainInputBlur = () => setTimeout(() => this.setState({ showAutoComplete: false }), 150); // let click happen
  selectAutoCompleteResult = result => {
    this.searchChange(result.query);
    this.setState({ showAutoComplete: false, autoCompleteResultHighlighted: null });
  };
  getLockedCatalogBrand = () => this.props.forceLockedCatalogBrand || getLockedCatalogBrand(this.props.store);
  searchChangeNewPage = value => this.searchChange(value, true);

  searchChange = async (value, isNewPage = false) => {
    if (!isNewPage && this.state.page !== 0) {
      this.setState({ page: 0 });
    }
    if (!value.length) {
      this.setState({
        brandFilters: [],
        retailerFilters: [],
        autoCompleteResults: []
      });
    } else {
      !this.getLockedCatalogBrand() && this.fetchAutoComplete(value);
    }

    // Reset everything
    this.clearUrlLoadingTimeouts();

    if (this.isCatalogSearchMode()) {
      this.setState({ inputValue: value, loadingSearch: true, hasMoreResults: true });
      const { results, facets, hasMoreResults } = await this.searchProductsDebounced(value);
      this.setState({ results, facets, hasMoreResults, loadingSearch: false });
      const searchResultMerchants = _.map(results, ({ url }) => getDomainFromUrl(url));
      this.props.updateMerchantData(searchResultMerchants);
    } else if (this.isPinSearchMode()) {
      this.setState({ inputValue: value, loadingSearch: true, hasMoreResults: true });
      const pinResults = await this.searchPinsDebounced(value, {
        User_id: this.props.Editing_User_id
      });
      const pinResultMerchants = _.map(pinResults, ({ link }) => getDomainFromUrl(link));
      this.props.updateMerchantData(pinResultMerchants);
      this.setState({ pinResults, loadingSearch: false });
    } else if (this.isConsultPinSearchMode()) {
      this.setState({ inputValue: value, loadingSearch: true, hasMoreResults: true });
      const consultPinResults = await this.searchPinsDebounced(value, {
        fromConsultResults: true,
        User_id: this.props.Editing_User_id
      });
      const pinResultMerchants = _.map(consultPinResults, ({ link }) => getDomainFromUrl(link));
      this.props.updateMerchantData(pinResultMerchants);
      this.setState({ consultPinResults, loadingSearch: false });
    } else if (this.isHighRatesMode()) {
      this.setState({ inputValue: value, loadingSearch: true, hasMoreResults: true });
      const merchantResults = await this.getMerchantResultsDebouncer(value);
      this.setState({ merchantResults, loadingSearch: false });
    } else {
      let url = value;
      if (typeof url === 'string' && !/^https?:\/\//gi.test(url)) url = `https://${url}`;

      const validUrl = isUrlString(url);
      if (this.props.isQuickLinkMode && validUrl) {
        this.submitQuickLink({ link: url });
        return;
      }
      this.setState({
        inputValue: url,
        loadingCustomSearch: validUrl,
        loadingCustomSearchSlowly: false,
        loadingCustomSearchVerySlowly: false,
        loadingCustomSearchWayTooSlowly: false
      });

      if (validUrl) {
        this.slowLoadMessage = setTimeout(() => this.setState({ loadingCustomSearchSlowly: true }), 5000);
        this.verySlowLoadMessage = setTimeout(
          () =>
            this.setState({
              loadingCustomSearchSlowly: false,
              loadingCustomSearchVerySlowly: true
            }),
          13000
        );
        this.tooSlowLoadMessage = setTimeout(
          () =>
            this.setState({
              loadingCustomSearchSlowly: false,
              loadingCustomSearchVerySlowly: false,
              loadingCustomSearchWayTooSlowly: true
            }),
          18000
        );
        this.resetLoad = setTimeout(this.fallbackToBlankResult, 28000);
        const urlMetaResults = await this.getUrlMetaDebounced(url);

        this.clearUrlLoadingTimeouts();
        const valueChanged = this.state.inputValue !== url;
        if (valueChanged) {
          console.warn(`Got a response but the value changed, returning early.`);
          return; // in the case they cancelled the request realy
        }
        this.selectCustomResult(urlMetaResults);
      }
    }
  };

  clearUrlLoadingTimeouts = () => {
    this.setState({ loadingCustomSearch: false });
    this.slowLoadMessage && clearTimeout(this.slowLoadMessage);
    this.verySlowLoadMessage && clearTimeout(this.verySlowLoadMessage);
    this.tooSlowLoadMessage && clearTimeout(this.tooSlowLoadMessage);
    this.resetLoad && clearTimeout(this.resetLoad);
  };

  closeModal = () => {
    this.setState({
      addingModeEnum: null,
      inputValue: '',
      facets: {},
      autoCompleteResults: [],
      brandFilters: [],
      retailerFilters: [],
      results: [],
      finalProduct: null,
      isAddingOrEditingProduct: false,
      isPinLinkWebRisk: false
    });
    this.props.toggleAddingMode();
  };

  scrollToTop = () => this.modalInnerContainer && this.modalInnerContainer.scrollTo(0, 0);
  isEditingProduct = () => !!this.props.pinBeingEdited;
  isURLSearchMode = () => this.state.addingModeEnum === 'url';
  isCatalogSearchMode = () => this.state.addingModeEnum === 'search';
  isPinSearchMode = () => this.state.addingModeEnum === 'pin';
  isConsultPinSearchMode = () => this.state.addingModeEnum === 'consultPins';
  isHighRatesMode = () => this.state.addingModeEnum === 'rates';
  setMode = mode => {
    setTimeout(() => {
      if (this.productInput) {
        this.props.inAddMode ? this.productInput.focus() : this.productInput.blur();
      }
    }, 300);
    if (mode) {
      this.setState(
        {
          inputValue: '',
          addingModeEnum: mode
        },
        () => {
          // Perform initial lookup in some cases
          mode === 'consultPins' && this.searchChange('');
          mode === 'pin' && this.searchChange('');
        }
      );
      addEvent(`Collections - Select Add Mode `, { mode });
    } else {
      this.setState({
        inputValue: '',
        results: [],
        pinResults: [],
        consultPinResults: [],
        merchantResults: [],
        facets: {},
        autoCompleteResults: [],
        brandFilters: [],
        retailerFilters: [],
        finalProduct: null,
        addingModeEnum: null
      });
    }
    // Ensure we stop all loading
    this.clearUrlLoadingTimeouts();
  };

  handleGeoLinkUpdates = async pin => {
    /*
      Updates geo links on the pin by comparing the new links
      vs the existing ones and adding / deleting. Instead of editing,
      we simply delete and add a new one, to keep it simple.

      Returns: true if an update was made, false otherwise
    */
    const { finalProduct } = this.state;
    const { geolinks } = finalProduct;
    let updateMade = false;

    if (this.isEditingProduct()) {
      const existingLinks = _.get(pin, 'geolinks', []);

      // Remove links that no longer exist
      for (let i = 0; i < existingLinks.length; i++) {
        const doesLinkStillExist = !!_.find(
          geolinks,
          link => existingLinks[i].link === link.link && existingLinks[i].countryDomain === link.countryDomain
        );
        if (!doesLinkStillExist) {
          await removeGeoLink(existingLinks[i]);
          updateMade = true;
        }
      }

      // Add links that are new
      for (let i = 0; i < geolinks.length; i++) {
        const doesLinkAlreadyExist = !!_.find(
          existingLinks,
          link => geolinks[i].link === link.link && geolinks[i].countryDomain === link.countryDomain
        );
        if (!doesLinkAlreadyExist) {
          await createGeoLink(pin, geolinks[i].link, geolinks[i].countryDomain);
          updateMade = true;
        }
      }
    } else {
      // Add links
      for (let i = 0; i < geolinks.length; i++) {
        await createGeoLink(pin, geolinks[i].link, geolinks[i].countryDomain);
        updateMade = true;
      }
    }
    return updateMade;
  };

  submitQuickLink = async data => {
    const { image, title, link, brand, Product_id } = data;
    const { user, Editing_Contract_id } = this.props;
    const pin = {
      link,
      ...(title ? (brand ? { title: `${brand.toUpperCase()} | ${title}` } : { title }) : {}),
      ...(image ? { image } : {}),
      ...(Product_id ? { Product_id } : {}),
      ...(Editing_Contract_id ? { Contract_id: Editing_Contract_id } : {}),
      allow_displaying_codes: true,
      disable_monetization: isBrand(user) || isBanned(user) ? true : false,
      User_id: this.props.Editing_User_id
    };
    try {
      const resp = await createPinAPI(pin);
      if (!resp || !resp.pin) {
        cogoToast.error('There was an issue adding this product, please try again.');
      } else {
        addEvent(`Quick Links - Add Pin`, {
          username: getUsername(user),
          name: getName(user),
          isAdmin: isAdmin(user),
          pinTitle: title
        });
        this.closeModal();
        await this.props.syncExternalState(true); // This is required, as the external API call fetches crucial merchant data

        if (!Editing_Contract_id) {
          const shortLink = getShortPinLink(resp.pin);

          copyToClipboard(shortLink);
          cogoToast.success('Successfully added new product and copied link to the clipboard.', { hideAfter: 2 });
        }
      }
    } catch (error) {
      cogoToast.error(
        _.get(
          error,
          'message',
          'There was an issue adding this product, please try again. If the issue continues you may need to log out and log back in.'
        ),
        { hideAfter: 4 }
      );
    }
  };

  submitProduct = async () => {
    const { pinBeingEdited, isQuickLinkMode } = this.props;
    const { finalProduct, isAddingOrEditingProduct, isPinLinkWebRisk } = this.state;
    const {
      title,
      description,
      Product_id,
      image,
      link,
      crop,
      disable_monetization,
      allow_optimization,
      allow_deep_link_to_app,
      allow_displaying_codes
    } = finalProduct;

    if (isPinLinkWebRisk) return;
    if (!title && !isQuickLinkMode) return cogoToast.error('Please add a title.');
    if (!link) return cogoToast.error('Please add a link to the product.');
    if (!link.includes('https://')) return cogoToast.error(`URLs must be secure. Please choose a product URL that starts with https://`);
    if (!image && !isQuickLinkMode) return cogoToast.error('Please add an image URL.');
    if (image?.includes('http://')) return cogoToast.error(`Image URLs must be secure. Please choose an image with https:// and not http://`);
    if (pinBeingEdited && link.includes(`${getShortUrl()}/p-${pinBeingEdited.id}`))
      return cogoToast.error(`This url links to itself and will not work, please be careful when using ${getShortUrl()} links as they may not work!`);

    this.setState({ isAddingOrEditingProduct: true });
    if (isAddingOrEditingProduct) return;

    if (this.isEditingProduct()) {
      const geoUpdateMade = await this.handleGeoLinkUpdates(pinBeingEdited);
      const editsToMake = _.reduce(
        {
          title: title && title.trim(),
          link: link && link.trim(),
          ...(image ? { image: image && image.trim() } : {}),
          crop,
          description,
          Product_id,
          disable_monetization,
          allow_optimization,
          allow_deep_link_to_app,
          allow_displaying_codes
        },
        (res, val, key) => {
          const prevVal = pinBeingEdited[key];
          const isSame = prevVal === val || (prevVal === 1 && val === true) || (prevVal === 0 && val === false); // Handling for odd SQL boolean handling
          if (isSame) {
            return res;
          } else {
            return {
              ...res,
              [key]: val
            };
          }
        },
        {}
      );
      const hasEditsToMake = !!_.keys(editsToMake).length;

      if (!hasEditsToMake && !geoUpdateMade) {
        this.closeModal();
        return;
      }

      try {
        if (hasEditsToMake) {
          this.props.editPin(pinBeingEdited, editsToMake).then(
            async resp => {
              const { error } = resp;
              if (resp.error) {
                if (error.includes('web risk')) {
                  cogoToast.error('This link has been flagged as potentially harmful. Please double check the URL and try again.', { hideAfter: 10 });
                  this.setState({ isPinLinkWebRisk: true });
                } else {
                  cogoToast.error(
                    error.message || 'There was an issue editing this product. If this issue continues, you may need to log out and log back in.',
                    {
                      hideAfter: 4
                    }
                  );
                }
              } else {
                this.closeModal();
                await this.props.syncExternalState(); // This is required, as the collection API call fetches crucial merchant data
                cogoToast.success('Updated product successfully', {
                  hideAfter: 1
                });
              }
            },
            err => {
              console.error('Error updating pin: ', err);
              cogoToast.error('There was an issue editing this product. If this issue continues, you may need to log out and log back in.', {
                hideAfter: 4
              });
            }
          );
        } else {
          this.closeModal();
          await this.props.syncExternalState(); // This is required, as the collection API call fetches crucial merchant data
          cogoToast.success('Updated product links successfully', {
            hideAfter: 1
          });
        }
      } catch (error) {
        cogoToast.error('There was an issue editing this product. If this issue continues, you may need to log out and log back in.', {
          hideAfter: 4
        });
      } finally {
        this.setState({ isAddingOrEditingProduct: false });
      }
    } else {
      this.createPin({
        title,
        link,
        crop,
        disable_monetization,
        allow_optimization,
        allow_displaying_codes,
        allow_deep_link_to_app,
        ...(image ? { image: image } : {}),
        ...(description ? { description } : {}),
        ...(Product_id ? { Product_id } : {}),
        User_id: this.props.Editing_User_id,
        ...(this.props.Editing_Collection_id ? { Collection_id: this.props.Editing_Collection_id } : {}),
        ...(this.props.Editing_ConsultResult_id ? { ConsultResult_id: this.props.Editing_ConsultResult_id } : {}),
        ...(this.props.Editing_Contract_id ? { Contract_id: this.props.Editing_Contract_id } : {})
      });
    }
  };

  createPin = async (pin, options = { closeAfterAdding: true, allowGeoLinkUpdates: true }) => {
    const { user, isQuickLinkMode } = this.props;
    const { closeAfterAdding, messageAfterAdding, allowGeoLinkUpdates } = options;
    const {
      title,
      link,
      image,
      crop,
      disable_monetization,
      allow_optimization,
      allow_displaying_codes,
      allow_deep_link_to_app,
      description,
      Product_id
    } = pin;
    if (!('title' in pin)) return cogoToast.error('You must define a valid title.');
    if (!('link' in pin)) return cogoToast.error('You must define a valid link.');
    if (!('image' in pin) && !isQuickLinkMode) return cogoToast.error('You must define a valid image.');

    const newPin = {
      title,
      link,
      crop,
      ...(image ? { image } : {}),
      disable_monetization: _.isNil(disable_monetization) ? (isBrand(user) || isBanned(user) ? true : false) : disable_monetization,
      allow_optimization: _.isNil(allow_optimization) ? true : allow_optimization,
      allow_deep_link_to_app: _.isNil(allow_deep_link_to_app) ? false : allow_deep_link_to_app,
      allow_displaying_codes: _.isNil(allow_displaying_codes) ? true : allow_displaying_codes,
      ...(description ? { description } : {}),
      ...(Product_id ? { Product_id } : {}),
      User_id: this.props.Editing_User_id,
      ...(this.props.Editing_Collection_id ? { Collection_id: this.props.Editing_Collection_id } : {}),
      ...(this.props.Editing_ConsultResult_id ? { ConsultResult_id: this.props.Editing_ConsultResult_id } : {}),
      ...(this.props.Editing_Contract_id ? { Contract_id: this.props.Editing_Contract_id } : {})
    };
    try {
      this.setLinkInProgress(link);
      const resp = await createPinAPI(newPin);
      if (!resp?.pin) {
        this.setState({ isAddingOrEditingProduct: false });
        cogoToast.error('There was an issue adding this product, please try again.');
      } else {
        allowGeoLinkUpdates && (await this.handleGeoLinkUpdates(resp.pin));
        await this.props.syncExternalState(true); // This is required, as the collection API call fetches crucial merchant data
        closeAfterAdding && this.closeModal();
        messageAfterAdding && cogoToast.success(messageAfterAdding);
        addEvent(`Collections - Add Pin`, {
          username: getUsername(user),
          name: getName(user),
          isAdmin: isAdmin(user),
          pinTitle: _.get(resp, 'pin.title')
        });
      }
    } catch (error) {
      if (error.includes('web risk')) {
        cogoToast.error('This link has been flagged as potentially harmful. Please double check the URL and try again.', { hideAfter: 10 });
        this.setState({ isPinLinkWebRisk: true });
      } else {
        cogoToast.error(_.get(error, 'message', 'There was an issue adding this product, please try again.'), { hideAfter: 4 });
      }
    } finally {
      this.setState({ isAddingOrEditingProduct: false });
      this.removeLinkInProgress(link);
    }
  };

  setLinkInProgress = link => this.setState({ linksInProgress: [...this.state.linksInProgress, link] });
  removeLinkInProgress = link => this.setState({ linksInProgress: _.filter(this.state.linksInProgress, l => l !== link) });

  removePin = async pin => {
    this.setLinkInProgress(pin.link);
    try {
      await deletePinAPI(pin.id);
      await this.props.syncExternalState(true); // This is required, as the collection API call fetches crucial merchant data
    } catch (error) {
      cogoToast.error(_.get(error, 'message', 'There was an issue adding this product, please try again.'), { hideAfter: 4 });
    } finally {
      this.removeLinkInProgress(pin.link);
    }
  };

  selectPinResult = pin => {
    const { user, isQuickLinkMode } = this.props;
    const {
      title,
      image,
      link,
      Product_id,
      description,
      disable_monetization,
      allow_optimization,
      allow_displaying_codes,
      allow_deep_link_to_app
    } = pin;
    const you = isAdmin(user) || isYou(pin.user?.username, user);
    if (isQuickLinkMode) {
      this.submitQuickLink({
        link,
        title,
        image,
        Product_id
      });
    } else {
      this.setState({
        finalProduct: {
          title: title || '',
          description: you ? description || '' : '',
          link,
          redirect_url: link,
          image,
          all_images: [image],
          all_images_augmented: [],
          disable_monetization: !!disable_monetization,
          allow_optimization: !!allow_optimization,
          allow_deep_link_to_app: !!allow_deep_link_to_app,
          allow_displaying_codes: !!allow_displaying_codes,
          geolinks: []
        }
      });
    }
    this.scrollToTop();
  };

  selectSearchResultProduct = async product => {
    const { user, isQuickLinkMode } = this.props;
    const { title, brand, Product_id, primary_image_data, retailer, other_image_data, url } = product;
    const { image_url } = primary_image_data || {};
    if (isQuickLinkMode) {
      this.submitQuickLink({
        link: url,
        image: image_url,
        Product_id,
        title,
        brand
      });
    } else {
      this.setState({
        finalProduct: {
          title: `${brand && brand.toUpperCase()} | ${title}`,
          brand: brand,
          link: url,
          redirect_url: url,
          description: '',
          retailer: retailer,
          image: image_url,
          all_images: [image_url],
          all_images_augmented: other_image_data,
          disable_monetization: isBrand(user) || isBanned(user) ? true : false,
          allow_optimization: true,
          allow_deep_link_to_app: true,
          allow_displaying_codes: true,
          geolinks: []
        }
      });
      this.scrollToTop();
    }
  };

  selectQuickPinResult = async pin => {
    const { title, image, link, Product_id, disable_monetization, allow_displaying_codes } = pin;
    this.createPin(
      {
        title,
        link,
        image,
        disable_monetization: !!disable_monetization,
        allow_displaying_codes: !!allow_displaying_codes,
        ...(Product_id ? { Product_id } : {})
      },
      {
        closeAfterAdding: false,
        messageAfterAdding: this.props.isQuickLinkMode ? `Successfully added new link.` : null,
        allowGeoLinkUpdates: false
      }
    );
  };

  selectQuickSearchResultProduct = async product => {
    const { title, brand, Product_id, url, primary_image_data } = product;
    const { image_url } = primary_image_data || {};

    this.createPin(
      {
        title: `${brand && brand.toUpperCase()} | ${title}`,
        link: url,
        image: image_url,
        ...(Product_id ? { Product_id } : {})
      },
      {
        closeAfterAdding: false,
        messageAfterAdding: this.props.isQuickLinkMode ? `Successfully added new link.` : null,
        allowGeoLinkUpdates: false
      }
    );
  };

  fallbackToBlankResult = errorMsg => {
    const { user } = this.props;
    const { inputValue } = this.state;
    this.setState({
      finalProduct: {
        title: '',
        link: inputValue,
        redirect_url: inputValue,
        image: null,
        all_images: [],
        all_images_augmented: [],
        disable_monetization: isBrand(user) || isBanned(user) ? true : false,
        allow_optimization: true,
        allow_displaying_codes: true,
        allow_deep_link_to_app: true,
        description: '',
        retailer: '',
        geolinks: [],
        error: {
          message: errorMsg
        }
      },
      loadingCustomSearch: false,
      loadingCustomSearchSlowly: false,
      loadingCustomSearchVerySlowly: false
    });
  };

  selectCustomResult = urlMetaResults => {
    const { user } = this.props;
    const { inputValue } = this.state;
    const { title, images, redirect_url, other_image_data, error } = urlMetaResults;
    let link = inputValue;
    if (inputValue.includes('myshlf')) link = redirect_url;
    if (inputValue.includes('go.shopmy.us')) link = redirect_url;
    this.setState({
      finalProduct: {
        title: title || '',
        link,
        redirect_url,
        image: _.first(images),
        all_images: images ? images.slice(0, 3) : [],
        all_images_augmented: other_image_data || [],
        disable_monetization: isBrand(user) || isBanned(user) ? true : false,
        allow_optimization: true,
        allow_displaying_codes: true,
        allow_deep_link_to_app: true,
        description: '',
        retailer: '',
        geolinks: [],
        error
      },
      loadingCustomSearch: false,
      loadingCustomSearchSlowly: false,
      loadingCustomSearchVerySlowly: false
    });
  };

  backFromFinalPanel = () => {
    const { inputValue } = this.state;
    this.setState({
      finalProduct: null,
      inputValue: this.isURLSearchMode() ? '' : inputValue
    });
  };

  render() {
    const { inAddMode, pinBeingEdited, store, toggleAddingMode, user, ui, collaborations, adminControlMode, isQuickLinkMode } = this.props;
    const {
      inputValue,
      loadingSearch,
      loadingCustomSearch,
      results,
      pinResults,
      consultPinResults,
      merchantResults,
      isAddingOrEditingProduct,
      showAutoComplete,
      autoCompleteResults,
      autoCompleteResultHighlighted,
      addingModeEnum,
      finalProduct,
      loadingCustomSearchSlowly,
      loadingCustomSearchVerySlowly,
      loadingCustomSearchWayTooSlowly
    } = this.state;
    const inEditMode = !!pinBeingEdited;
    const pinAddMode = this.isPinSearchMode();
    const consultPinAddMode = this.isConsultPinSearchMode();
    const urlAddMode = this.isURLSearchMode();
    const searchAddMode = this.isCatalogSearchMode();
    const highRatesMode = this.isHighRatesMode();
    const lockedCatalogBrand = this.getLockedCatalogBrand();

    const atRootPanel = !addingModeEnum && inAddMode;

    // Empty search panel shows up for everything except pins which always has results
    const isSearchEmpty = !inputValue.length;
    const showResultsOnEmptySearch = pinAddMode || consultPinAddMode;
    const loadingResults = loadingSearch || loadingCustomSearch;

    const isAutoCompleteVisible = searchAddMode && showAutoComplete && !!autoCompleteResults.length && !!inputValue.length;
    if (!inAddMode && !pinBeingEdited) return null;
    return (
      <div className='add-pin-modal-outer-container'>
        <div
          className={classnames('add-pin-modal-container', {
            'add-mode': inAddMode,
            'edit-mode': inEditMode
          })}
        >
          <div
            onClick={() => {
              this.setMode(null);
              this.props.toggleAddingMode();
            }}
            className={classnames('adding-item-fade-overlay', {
              active: inAddMode || inEditMode
            })}
          />
          <div
            ref={ref => {
              this.modalInnerContainer = ref;
            }}
            className={classnames('add-pin-modal-inner-container', {
              'autocomplete-visible': isAutoCompleteVisible
            })}
          >
            {isAutoCompleteVisible && <div className='auto-complete-fade' />}
            {!finalProduct && (
              <div className={classnames('add-product-input-container-wrapper', inAddMode && addingModeEnum ? 'visible' : '')}>
                <div className='go-back-icn icn' onClick={() => this.setMode(null)}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>
                <div className='add-product-input-container'>
                  <input
                    type='text'
                    role='searchbox'
                    autoComplete='off'
                    placeholder={
                      highRatesMode
                        ? 'Search by Brand or Website'
                        : urlAddMode
                        ? lockedCatalogBrand
                          ? `Paste a URL from ${lockedCatalogBrand.domain}`
                          : 'URL'
                        : lockedCatalogBrand
                        ? `Search the ${lockedCatalogBrand.name} Catalog${window.LOCK_CATALOG_MATCHES_ONLY ? ' and More' : ''}`
                        : 'Search'
                    }
                    className={classnames('add-product-search-input', { 'has-autocomplete': isAutoCompleteVisible })}
                    onChange={this.mainInputChange}
                    onFocus={this.mainInputFocus}
                    onBlur={this.mainInputBlur}
                    value={inputValue}
                    ref={input => {
                      this.productInput = input;
                    }}
                  />
                  {isAutoCompleteVisible && (
                    <div className={classnames('auto-complete-results', { fetching: loadingResults })}>
                      {autoCompleteResults.map(result => (
                        <div
                          key={result.query}
                          onClick={() => this.selectAutoCompleteResult(result)}
                          className={classnames('auto-complete-result', { highlighted: autoCompleteResultHighlighted?.query === result.query })}
                        >
                          {result.query}
                          {!isMobile && <div className='enter-to-select-btn'>ENTER TO SELECT</div>}
                        </div>
                      ))}
                    </div>
                  )}
                  {!isMobile && isAutoCompleteVisible && <div className='enter-to-search-btn'>CLICK ENTER TO SEARCH</div>}
                </div>
                <div
                  className='close-icn icn'
                  onClick={() => {
                    if (inputValue) {
                      this.searchChange('');
                      this.productInput.focus();
                    } else {
                      this.closeModal();
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            )}
            {finalProduct ? (
              <AddPinModalFinal
                ui={ui}
                user={user}
                store={store}
                collaborations={collaborations}
                inEditMode={inEditMode}
                finalProduct={finalProduct}
                updateMerchantData={this.props.updateMerchantData}
                goBack={this.backFromFinalPanel}
                closeModal={this.closeModal}
                submitProduct={this.submitProduct}
                isAddingOrEditing={isAddingOrEditingProduct}
                Editing_Collection_id={this.props.Editing_Collection_id}
                Editing_ConsultResult_id={this.props.Editing_ConsultResult_id}
                Editing_Contract_id={this.props.Editing_Contract_id}
                lockedCatalogBrand={lockedCatalogBrand}
                pinBeingEdited={pinBeingEdited}
                updateGlobalModalState={newFields => this.setState(newFields)}
                isPinLinkWebRisk={this.state.isPinLinkWebRisk}
                clearWebRiskStatus={() => this.setState({ isPinLinkWebRisk: false })}
              />
            ) : (
              <>
                {atRootPanel && (
                  <AddPinModalLanding
                    user={user}
                    setMode={this.setMode}
                    closeModal={toggleAddingMode}
                    lockedCatalogBrand={lockedCatalogBrand}
                    Editing_ConsultResult_id={this.props.Editing_ConsultResult_id}
                  />
                )}
                {isSearchEmpty && !showResultsOnEmptySearch ? (
                  <AddPinModalEmpty
                    isUrl={urlAddMode}
                    isPin={pinAddMode}
                    isConsultPin={consultPinAddMode}
                    isSearch={searchAddMode}
                    isRates={highRatesMode}
                    store={store}
                    user={user}
                    selectPopularBrand={val => {
                      // Always search when selecting a brand
                      this.setState({ addingModeEnum: 'search' }, () => {
                        this.scrollToTop();
                        this.searchChange(val);
                      });
                    }}
                    lockedCatalogBrand={lockedCatalogBrand}
                  />
                ) : (
                  <div
                    className={classnames('product-results-outer-container', {
                      'autocomplete-visible': isAutoCompleteVisible
                    })}
                  >
                    <AddPinModalProducts
                      results={results}
                      pinResults={pinResults}
                      consultPinResults={consultPinResults}
                      merchantResults={merchantResults}
                      loadingResults={loadingResults}
                      page={this.state.page}
                      isQuickLinkMode={isQuickLinkMode}
                      linksInProgress={this.state.linksInProgress}
                      switchMode={(mode, maintainValue = true) => {
                        this.setMode(mode);
                        setTimeout(() => {
                          this.searchChange(maintainValue ? inputValue : '');
                        }, 20);
                      }}
                      isUrlAddMode={urlAddMode}
                      isPinAddMode={pinAddMode}
                      isConsultPinAddMode={consultPinAddMode}
                      isHighRatesMode={highRatesMode}
                      isSearchAddMode={searchAddMode}
                      searchValue={inputValue}
                      store={store}
                      collaborations={collaborations}
                      user={user}
                      facets={this.state.facets}
                      brandFilters={this.state.brandFilters}
                      retailerFilters={this.state.retailerFilters}
                      toggleFilter={this.toggleFilter}
                      adminControlMode={adminControlMode}
                      selectSearchResult={this.selectSearchResultProduct}
                      selectQuickSearchResult={this.selectQuickSearchResultProduct}
                      selectPinResult={this.selectPinResult}
                      selectQuickPinResult={this.selectQuickPinResult}
                      removePin={this.removePin}
                      Editing_Collection_id={this.props.Editing_Collection_id}
                      Editing_ConsultResult_id={this.props.Editing_ConsultResult_id}
                      Editing_Contract_id={this.props.Editing_Contract_id}
                      lockedCatalogBrand={lockedCatalogBrand}
                    />
                  </div>
                )}
              </>
            )}
            {loadingResults && (
              <div className='search-loader-container'>
                <Loader message={this.getLoaderMessage()} />
                {loadingCustomSearchSlowly || loadingCustomSearchVerySlowly || loadingCustomSearchWayTooSlowly ? (
                  <div onClick={this.fallbackToBlankResult} className='force-failure-btn'>
                    Click To Fill In Manually
                  </div>
                ) : (
                  isQuickLinkMode &&
                  urlAddMode && (
                    <div className='force-failure-btn' onClick={() => this.submitQuickLink({ link: inputValue })}>
                      Add URL without image
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getLoaderMessage = () => {
    const { loadingCustomSearch, loadingCustomSearchSlowly, loadingCustomSearchVerySlowly, loadingCustomSearchWayTooSlowly } = this.state;
    if (loadingCustomSearch) {
      return loadingCustomSearchSlowly
        ? 'Some sites take longer to load, hang tight.'
        : loadingCustomSearchVerySlowly
        ? 'The connection to this site is a bit slow, almost there.'
        : loadingCustomSearchWayTooSlowly
        ? "Looks like this site might be blocking us, you may have to enter the data manually if this doesn't finish in the next few seconds."
        : 'Searching for product...';
    }
    return null;
  };
}

const mapStateToProps = state => {
  const { user, store, collaborations, ui } = state;
  return { user, store, collaborations, ui };
};

export default connect(mapStateToProps, {
  editPin,
  updateMerchantData,
  getHighRateMerchants,
  getFeaturedMerchants
})(AddPinModal);
