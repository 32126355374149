import React from 'react';
import PropTypes from 'prop-types';
import './ConsultElementShortQuestion.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';

const ConsultElementShortQuestion = props => {
  const { canEdit, question, answer, placeholder, setField } = props;

  return (
    <div className='element-container short-question-container'>
      {canEdit ? (
        <textarea
          rows={1}
          ref={ref => matchScrollHeight(ref)}
          value={question}
          onChange={e => setField('question', e.target.value, 250)}
          placeholder='Ask Your Question Here'
          className='question'
        />
      ) : (
        <div className='question'>{question || '-'}</div>
      )}
      {canEdit ? (
        <input className='answer placeholder' value={placeholder} onChange={e => setField('placeholder', e.target.value, 250)} />
      ) : (
        <input className='answer' value={answer} onChange={e => setField('answer', e.target.value, 250)} placeholder={placeholder} />
      )}
    </div>
  );
};

ConsultElementShortQuestion.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  setField: PropTypes.func.isRequired
};

export default ConsultElementShortQuestion;
