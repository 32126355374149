import cogoToast from 'cogo-toast';
import _ from 'lodash';

import * as ActionTypes from '../Actions/AnalyticsActions';

export const analytics = (
  state = {
    brandAnalytics: {
      requests: [],
      customRates: [],
      customCodes: [],
      isFetchingAnalytics: true
    }
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_BRAND_ANALYTICS_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          isFetchingAnalytics: true
        }
      };
    case ActionTypes.GET_BRAND_ANALYTICS_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...action.analytics,
          isFetchingAnalytics: false
        }
      };

    case ActionTypes.GET_BRAND_ANALYTICS_FAILURE:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          isFetchingAnalytics: false
        }
      };

    case ActionTypes.SET_CUSTOM_COMMISSION_RATE_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customRates: action.customRates
        }
      };

    case ActionTypes.SET_CUSTOM_COMMISSION_RATE_FAILURE:
      cogoToast.error(action.error);
      return {
        ...state
      };

    case ActionTypes.SET_CUSTOM_CODE_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          customCodes: action.customCodes
        }
      };
    case ActionTypes.SET_CUSTOM_CODE_FAILURE:
      return {
        ...state
      };

    case ActionTypes.CREATE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          requests: [...(state.brandAnalytics.requests || []), action.request]
        }
      };
    case ActionTypes.CREATE_SAMPLES_REQUEST_FAILURE:
      console.error(action); // Leave for debugging
      window.ALERT.error(action.error || 'There was an issue submitting your gifting request.');
      return {
        ...state
      };
    case ActionTypes.REMOVE_SAMPLES_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          requests: _.filter(state.brandAnalytics.requests, r => r.id !== action.request.id)
        }
      };
    case ActionTypes.REMOVE_SAMPLES_REQUEST_FAILURE:
      window.ALERT.error('There was an issue removing your gifting request.');
      return {
        ...state
      };

    case ActionTypes.CREATE_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: [...(state.brandAnalytics.opportunity_requests || []), action.request]
        }
      };

    case ActionTypes.CREATE_OPPORTUNITY_REQUEST_FAILURE:
      return {
        ...state
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? { ...r, ...action.updates } : r))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_SUCCESS:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? { ...r, ...action.request } : r))
        }
      };

    case ActionTypes.UPDATE_OPPORTUNITY_REQUEST_FAILURE:
      window.ALERT.error('There was an issue updating this opportunity request.');
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: state.brandAnalytics.opportunity_requests.map(r => (r.id === action.request.id ? action.request : r))
        }
      };

    case ActionTypes.REMOVE_OPPORTUNITY_REQUEST_REQUEST:
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: _.filter(state.brandAnalytics.opportunity_requests, r => r.id !== action.request.id)
        }
      };

    case ActionTypes.REMOVE_OPPORTUNITY_REQUEST_FAILURE:
      window.ALERT.error('There was an issue removing this opportunity request.');
      return {
        ...state,
        brandAnalytics: {
          ...state.brandAnalytics,
          opportunity_requests: [...(state.brandAnalytics.opportunity_requests || []), action.request]
        }
      };

    default:
      return state;
  }
};
