import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import './SectionTemplate.scss';

import SectionTemplate_Shelf from './SectionTemplate_Shelf';
import SectionTemplate_Editorial from './SectionTemplate_Editorial';
import SectionTemplate_Post from './SectionTemplate_Post';
import SectionTemplate_PostVertical from './SectionTemplate_PostVertical';
import SectionTemplate_PostHorizontal from './SectionTemplate_PostHorizontal';

const SectionTemplate = props => {
  const { template, section } = props;
  const { title, subtitle, previewType } = template;
  const isSelected = section.previewType === previewType;
  const TemplateComponent = {
    shelf: SectionTemplate_Shelf,
    editorial: SectionTemplate_Editorial,
    post: SectionTemplate_Post,
    post_vertical: SectionTemplate_PostVertical,
    post_horizontal: SectionTemplate_PostHorizontal
  }[template.previewType];

  const select = () => props.selectTemplate(template);

  return (
    <div onClick={select} className={cn('section-template-container', { selected: isSelected })}>
      <div className='section-template-header-container'>
        <div className='section-template-header'>{title}</div>
        <div className='section-template-subheader'>{subtitle}</div>
      </div>
      <TemplateComponent {...props} />
      {isSelected && (
        <div className='selected-badge'>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      )}
    </div>
  );
};

SectionTemplate.propTypes = {
  section: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired,
  selectTemplate: PropTypes.func.isRequired
};

export default SectionTemplate;
