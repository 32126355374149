import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import Mousetrap from 'mousetrap';
import { useStripe, useElements, CardElement, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import './ConsultSubmissionModal.scss';

import { getStripeTransactionFee } from '../../Helpers/payment_helpers';
import { getEmptyConsultResponse } from '../../Helpers/consult_helpers';
import { addEvent } from '../../APIClient/events';
import { copyToClipboard } from '../../Helpers/helpers';
import { isValidEmail, getPrettyPriceDisplay } from '../../Helpers/formatting';
import { prepareConsultPayment } from '../../APIClient/consults';

import Modal from '../General/Modal';

const ConsultSubmissionModal = props => {
  const { consult, response, visible, user, addConsultResult, resetResponse, closeModal, history } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allowFree, setAllowFree] = useState(false);

  // Form Setup
  const [paymentIntentObject, setIntentObject] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  // Stripe Setup
  const stripe = useStripe();
  const elements = useElements();
  const [settingUpWallet, setSettingUpWallet] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const getSubmissionWarning = () => {
    if (name.length < 5) {
      return 'Please enter your full name.';
    } else if (!isValidEmail(email)) {
      return 'Please submit a valid email address.';
    }
    return null;
  };

  const submissionWarning = getSubmissionWarning();

  const submitPerk = e => submit(e, null, true);

  // Submission
  const submit = useCallback(
    async (event, ev = null, isPerk = false) => {
      event && event.preventDefault();

      if (submissionWarning) return cogoToast.warn(submissionWarning);

      // Begin Payout
      setIsSubmitting(true);

      // Handle Payments
      let paymentDetails = {};
      if (consult.price && !isPerk) {
        const intentResp = paymentIntentObject || (await prepareConsultPayment(consult));
        setIntentObject(intentResp);
        const result = await stripe.confirmCardPayment(intentResp.client_secret, {
          payment_method: ev
            ? ev.paymentMethod.id
            : {
                card: elements.getElement(CardElement),
                billing_details: { name }
              },
          receipt_email: email
        });
        if (result.error) {
          setIsSubmitting(false);
          console.error(result);
          ev && ev.complete('fail');
          return cogoToast.error(result.error.message || 'Unknown Error on Checkout, please try again.');
        }
        ev && ev.complete('success');
        paymentDetails = {
          paymentId: result.paymentIntent.id
        };
      }

      if (consult.price && isPerk) {
        paymentDetails.paymentId = 'FREE';
      }

      const object = {
        Consult_id: consult.id,
        User_id: consult.User_id,
        clientName: name,
        clientEmail: email,
        clientResponse: response || getEmptyConsultResponse(), // Fall back on sending the base result
        ...paymentDetails
      };

      addConsultResult(object)
        .then(resp => {
          const { stub } = resp?.result || {};
          if (stub) {
            resetResponse();
            closeModal();
            history.push(`/consults/results/${stub}`);
            addEvent('Consult - Submit Result', {
              title: consult.title,
              price: consult.price || 'FREE',
              user: consult.user
            });
          } else {
            addEvent('Consult - Submit Result Error', {
              title: consult.title,
              price: consult.price || 'FREE',
              user: consult.user,
              clientName: name,
              clientEmail: email,
              clientResponse: response || getEmptyConsultResponse()
            });
            cogoToast.error('There was an error, please contact us for assistance at team@shopmy.us.');
          }
        })
        .catch(error => {
          cogoToast.error(typeof error === 'string' ? error : 'There was an error, please contact us for assistance at team@shopmy.us.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    },
    [consult, name, email, addConsultResult, submissionWarning, elements, history, paymentIntentObject, resetResponse, closeModal, response, stripe]
  );

  useEffect(() => {
    if (visible && stripe && consult.price && !settingUpWallet) {
      setSettingUpWallet(true);
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Consultation',
          amount: Math.round((consult.price + getStripeTransactionFee(consult.price)) * 100)
        }
      });
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    } else if (paymentRequest) {
      // Need to re-initialize this every time so it can access the name/email scope
      paymentRequest.removeListener('paymentmethod');
      paymentRequest.on('paymentmethod', async ev => {
        !isSubmitting && submit(null, ev);
      });
    }
  }, [stripe, visible, consult.price, submit, settingUpWallet, paymentRequest, name, email, isSubmitting]);

  useEffect(() => {
    Mousetrap.bind('ctrl+a', () => setAllowFree(!allowFree));
    return () => Mousetrap.unbind('ctrl+a');
  }, [setAllowFree, allowFree, user]);

  const transactionFee = consult.price && getStripeTransactionFee(consult.price);
  const subtotalDisplay = consult.price && getPrettyPriceDisplay(consult.price);
  const feeDisplay = consult.price && getPrettyPriceDisplay(transactionFee);
  const totalDisplay = consult.price && getPrettyPriceDisplay(consult.price + transactionFee, { useUSD: true });

  return (
    <Modal innerClassName='consult-submission-modal' showClose visible={props.visible} close={closeModal}>
      <div className='submission-header'>Your Consult Submission</div>
      <div className='submission-subheader'>Final Confirmation</div>
      <form onSubmit={submit} className='section'>
        <div className='section'>
          <label className='section-header'>Full Name</label>
          <div className='input-wrapper'>
            <input type='text' value={name} onChange={e => setName(e.target.value)} placeholder='Full Name' />
          </div>
        </div>
        <div className='section'>
          <label className='section-header'>Email To Send Results</label>
          <div className='input-wrapper'>
            <input type='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email Address' />
          </div>
        </div>
        {consult.price ? (
          <div className='section'>
            <div onClick={() => !window.__IS_PROD__ && copyToClipboard('4242424242424242', true)} className='section-header'>
              Order Summary
            </div>
            <div className='order-confirmation-card'>
              <div className='line-item'>
                <div className='desc'>Subtotal</div>
                <div className='value'>{subtotalDisplay}</div>
              </div>
              <div className='line-item'>
                <div className='desc'>
                  Processing Fees<div className='desc-explainer'>Stripe Charges 2.9% + $0.30</div>
                </div>
                <div className='value'>{feeDisplay}</div>
              </div>
              <div className='line-item'>
                <div className='desc'>Total</div>
                <div className='value'>{totalDisplay}</div>
              </div>
              <div className='credit-card-payment'>
                <CardElement
                  options={{
                    style: {
                      base: {
                        color: '#32325d',
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                          color: '#aab7c4'
                        }
                      },
                      invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a'
                      }
                    }
                  }}
                />
              </div>
              <div className='submit-btns'>
                {allowFree && (
                  <button onClick={submitPerk} className='submit-btn-card sms-perk btn'>
                    {isSubmitting ? 'Submitting...' : 'SMS Perk'}
                  </button>
                )}
                <button onClick={submit} className='submit-btn-card btn'>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <button onClick={submit} className='submit-btn-general'>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        )}
        {paymentRequest && (
          <div className='wallet-container'>
            <div className='or-container'>
              <div className='or'>OR</div>
            </div>
            <>
              <PaymentRequestButtonElement options={{ paymentRequest }} />
              {submissionWarning && <div className='submission-protection' onClick={() => cogoToast.warn(submissionWarning)} />}
            </>
          </div>
        )}
      </form>
    </Modal>
  );
};

ConsultSubmissionModal.propTypes = {
  addConsultResult: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  consult: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  resetResponse: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default withRouter(ConsultSubmissionModal);
