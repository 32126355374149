import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencil, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './ContractMedia.scss';

import { getDisplayForCollaborationType } from '../../../Helpers/collaboration_helpers';
import { getTitleForMedia, getEngagementWordForType } from '../../../Helpers/contract_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';

const ContractMedia = props => {
  const { media, canEdit, createdAt, isPreviewMode } = props;
  const { name, type, analytics, url, image } = media;
  const { views, engagement, error } = analytics || {};
  return (
    <div className='contract-content-element media'>
      <div className='main'>
        <div className='image-container'>{image ? <img src={image} alt={name} /> : <div className='empty-img' />}</div>
        <div className='data'>
          <div className='title'>{getTitleForMedia(media)}</div>
          {analytics ? (
            <div className={cn('performance-analytics', { error })}>
              {getDisplayForCollaborationType(media.type)}
              {!!views && (
                <>
                  <span className='bullet'>•</span>
                  {getPrettyNumber(views)} view{views === 1 ? '' : 's'}
                </>
              )}
              {!!engagement && (
                <>
                  <span className='bullet'>•</span>
                  {getPrettyNumber(engagement)} {getEngagementWordForType(type)}
                  {engagement === 1 ? '' : 's'}
                </>
              )}
              {error ? (
                <>
                  <span className='bullet'>•</span>
                  There was an issue calculating analytics: {error}. You will have to upload the results manually by clicking "Report Custom Results"
                  below
                </>
              ) : !views ? (
                canEdit ? (
                  <>
                    <span className='bullet'>•</span>
                    Created On {moment(createdAt).format('MMMM Do')}
                  </>
                ) : (
                  <>
                    <span className='bullet'>•</span>
                    No click activity yet
                  </>
                )
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className='performance-analytics'>Calculating Performance...</div>
          )}
        </div>
      </div>
      <div className='secondary'>
        {canEdit ? (
          !isPreviewMode && (
            <>
              <div className='link-actions'>
                <a href={url} target='_blank' rel='noopener noreferrer' className='link-action link'>
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
                <div onClick={() => props.editMedia(media)} className='link-action delete'>
                  <FontAwesomeIcon icon={faPencil} />
                </div>
                <div onClick={() => props.deleteMedia(media)} className='link-action delete'>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            </>
          )
        ) : (
          <div className='link-actions'>
            <a href={url} target='_blank' rel='noopener noreferrer' className='link-action link'>
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

ContractMedia.propTypes = {
  media: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  deleteMedia: PropTypes.func.isRequired,
  editMedia: PropTypes.func.isRequired,

  isPreviewMode: PropTypes.bool
};

export default ContractMedia;
