import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import _ from 'lodash';

import { getMentions } from '../../APIClient/mentions';
import { downloadCsvFromUrl } from '../../Helpers/helpers';
import { getServerFormattedDate } from '../../Helpers/formatting';

const MentionsData = props => {
  const { mentions, curFilters, hasMoreResults, isFetchingMentions, setIsDownloading, isDownloading } = props;

  // If we are an admin with no filters, we can only see the last week of mentions
  const requiresTimeRange = !curFilters.Brand_id && !curFilters.Target_id;

  const apiFilters = {
    limit: 12,
    ...curFilters,
    ...(requiresTimeRange ? { startDate: getServerFormattedDate(moment().subtract(1, 'week').startOf('day')) } : {}), // prettier-ignore
    ...(curFilters.sort ? { startDate: getServerFormattedDate(curFilters.startDate) } : {}),
    ...(curFilters.startDate ? { startDate: getServerFormattedDate(curFilters.startDate) } : {}),
    ...(curFilters.endDate ? { endDate: getServerFormattedDate(curFilters.endDate) } : {}),
    ...(curFilters.topTalentOnly ? { topTalentOnly: true } : { topTalentOnly: null }),
    ...(curFilters.viaCollectionsNoTag ? { viaCollectionsNoTag: true } : { viaCollectionsNoTag: null }),
    ...(curFilters.viaQuickLinksOnly ? { viaQuickLinksOnly: true } : { viaQuickLinksOnly: null })
  };

  // Fetch data on filter changes or paging
  useEffect(() => {
    if (isFetchingMentions || !hasMoreResults) return;
    props.setIsFetchingMentions(true);
    getMentions(_.omitBy(apiFilters, _.isNil))
      .then(resp => {
        apiFilters.page ? props.setMentions([...mentions, ...resp.mentions]) : props.setMentions(resp.mentions);
        props.setFilterCounts(resp.filter_counts);
        props.setSortOptions(resp.sort_options);
        props.setSummary(resp.summary);
        props.setBrandAndTargetOptions(resp.brand_and_target_options);
        props.setHasMoreResults(resp.mentions.length === apiFilters.limit);
      })
      .catch(errorMsg => {
        props.setErrorMsg(errorMsg);
        cogoToast.error(errorMsg);
      })
      .finally(() => props.setIsFetchingMentions(false));
  }, [curFilters, hasMoreResults]);

  // Fetch data if we are clicking download
  useEffect(() => {
    if (isDownloading) {
      getMentions(_.omitBy({ ...apiFilters, downloadToCsv: true }, _.isNil))
        .then(resp => {
          downloadCsvFromUrl(resp.url);
        })
        .catch(errorMsg => {
          cogoToast.error('Could not download results, please try again.');
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  }, [isDownloading]);

  // If we are resetting the page to 0, we need to unrestrict the paging experience
  useEffect(() => {
    if (curFilters.page === 0) {
      props.setHasMoreResults(true);
    }
  }, [curFilters]);

  return null;
};

MentionsData.propTypes = {
  ui: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  curFilters: PropTypes.object.isRequired,
  isFetchingMentions: PropTypes.bool.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  setMentions: PropTypes.func.isRequired,
  setFilterCounts: PropTypes.func.isRequired,
  setSortOptions: PropTypes.func.isRequired,
  setSummary: PropTypes.func.isRequired,
  setBrandAndTargetOptions: PropTypes.func.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  setIsFetchingMentions: PropTypes.func.isRequired,
  setIsDownloading: PropTypes.func.isRequired
};

export default MentionsData;
