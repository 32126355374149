import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Mousetrap from 'mousetrap';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Loader from '../Loader/Loader';

import { toggleBodyScrollLock } from '../../Actions/UIActions';

import './Modal.scss';

const Modal = props => {
  /*
    Modals must live on the outermost level unless you add the isNestedModal prop.
  */
  const { visible, close, isNestedModal, toggleBodyScrollLock, smallForm, fitContent } = props;

  // if someone can clean this up, we have a lot of cases where we are passing dynamic functions that are redefined on every call
  // ie: closeModal={() => do something } forces a rerender every time which blocks the ability
  // to use the Mousetrap binding below
  const closeModal = useCallback(() => close(), []);

  useEffect(() => {
    if (!visible) return;
    Mousetrap.bind('esc', () => closeModal());
    return () => {
      Mousetrap.unbind('esc');
      toggleBodyScrollLock(false);
    };
  }, [visible, closeModal, toggleBodyScrollLock]);

  useEffect(() => {
    toggleBodyScrollLock(visible);
  }, [visible, toggleBodyScrollLock]);

  const stopProp = e => {
    e.stopPropagation();
  };

  const modal = (
    <div
      onClick={props.close}
      className={cn('modal-outer-container', props.className || '', { visible: props.visible, 'force-outer': props.forceOuter })}
    >
      {props.visible && (
        <div onClick={stopProp} className={cn('modal-container', props.innerClassName || '', { 'fit-content': fitContent, smallForm })}>
          <div className='modal-inner-container'>
            <div ref={props.scrollRef} className={cn('modal-content', { noPadding: !!props.noPadding }, props.contentClassName || '')}>
              {props.title && (
                <div className='modal-header-section'>
                  <h1 className='modal-header'>{props.title}</h1>
                  {props.subtitle && <h2 className='modal-subheader'>{props.subtitle}</h2>}
                </div>
              )}
              {props.children}
            </div>
            {props.showClose && <FontAwesomeIcon onClick={props.close} icon={faTimes} className='close-icn' />}
            <div className={cn('loader-container', { saving: props.saving })}>
              <Loader size={70} />
            </div>
          </div>
        </div>
      )}
    </div>
  );

  // There is a bug where Portal links are not linking to the product due to a bug in the React Portal
  // implentation. This should fix the issue. If you figure it out please remove this effect.
  //
  // You can also solve this by using portalBugOpenUrl in helpers.js, see that function for details.
  useEffect(() => {
    if (isNestedModal) {
      setTimeout(() => {
        Array.from(document.querySelectorAll('.modal-outer-container a')).forEach(a => {
          a.onclick = () => window.open(a.href, a.target);
        });
      }, 200);
    }
  }, [isNestedModal]);

  return isNestedModal ? ReactDOM.createPortal(modal, document.querySelector('body')) : modal;
};

Modal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.any,

  // Optional
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  title: PropTypes.string,
  saving: PropTypes.bool,
  showClose: PropTypes.bool,
  scrollRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isNestedModal: PropTypes.bool,
  forceOuter: PropTypes.bool, // Make the top modal in case of multiple modals at once
  noPadding: PropTypes.bool,
  fitContent: PropTypes.bool,
  smallForm: PropTypes.bool
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  toggleBodyScrollLock
})(Modal);
