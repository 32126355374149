import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import './SocialAuth.scss';

import Loader from '../Loader/Loader';

import { connectYoutube, connectTiktok } from '../../Actions/UserActions';
import { getRedirectUrlForType } from '../../Helpers/social_helpers';

const SocialAuth = props => {
  const { match, ui, connectYoutube, connectTiktok } = props;
  const { type } = match?.params || {};
  const { authRedirectUrl } = ui || {};
  const [completedConnecting, setCompletedConnecting] = useState(false);

  useEffect(() => {
    let code, scopes, state;
    switch (type) {
      case 'youtube':
        code = new URLSearchParams(window.location.search).get('yt_code');
        connectYoutube({ code, redirect_url: getRedirectUrlForType('youtube') })
          .catch(error => cogoToast.error(error || 'We had an error, please try again!'))
          .finally(() => setCompletedConnecting(true));
        break;
      case 'tiktok':
        code = new URLSearchParams(window.location.search).get('tiktok_code');
        scopes = new URLSearchParams(window.location.search).get('scopes');
        state = new URLSearchParams(window.location.search).get('state');
        // backend requires matching redirect_uri used to get the code to obtain access token
        const redirect_uri = getRedirectUrlForType('tiktok', window.__IS_DEV__ ? 'shopmy.us' : null);
        connectTiktok({ code, scopes, state, redirect_uri })
          .catch(err => cogoToast.error(err || 'We had an error, please try again!'))
          .finally(() => setCompletedConnecting(true));
        break;
      default:
        break;
    }
  }, [type, authRedirectUrl, connectYoutube, connectTiktok]);

  if (completedConnecting) {
    const redirect_url = ui.authRedirectUrl || '/settings';
    return <Redirect to={{ pathname: redirect_url }} />;
  }
  return (
    <div className='social-auth-outer-container'>
      <div>Connecting your Account</div>
      <Loader size={120} />
    </div>
  );
};

SocialAuth.propTypes = {
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {
  connectYoutube,
  connectTiktok
})(withRouter(SocialAuth));
