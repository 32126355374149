import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunitySetupGuideIntro.scss';

const OpportunitySetupGuideIntro = props => {
  const [isClosing, setIsClosing] = React.useState(false);
  const smoothBegin = () => {
    setIsClosing(true);
    setTimeout(() => props.beginSetup(), 1000); // Keep this in sync with scss
  };

  const welcomeBadge = 'Welcome To Opportunities';
  const welcomeMessage = `Let's build your first Opportunity.`;

  const aboutQuestion = 'What are Opportunities?';
  const aboutAnswerParagraphs = [
    'An Opportunity is the simplest way to get guaranteed promotion from creators. You send a guaranteed payment to creators in return for coverage during your specified tracking window.',
    `All tracking of deliverables and creator payments are handled automatically.`
  ];

  const actionBtnDisplay = 'Get Started';

  return (
    <div className={cn('opportunity-setup-guide-intro-outer', { closing: isClosing })}>
      <div className='opportunity-setup-guide-intro-inner'>
        <div className='welcome-section'>
          <div className='welcome-badge'>{welcomeBadge}</div>
          <div className='welcome-message'>{welcomeMessage}</div>
        </div>
        <div className='about-section'>
          <div className='about-question'>{aboutQuestion}</div>
          <div className='about-answer'>
            {aboutAnswerParagraphs.map((paragraph, idx) => (
              <>
                {paragraph}
                {idx === aboutAnswerParagraphs.length - 1 ? null : (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </>
            ))}
          </div>
        </div>
        <div className='setup-actions'>
          <div className='setup-action' onClick={smoothBegin}>
            {actionBtnDisplay}
          </div>
        </div>
      </div>
    </div>
  );
};

OpportunitySetupGuideIntro.propTypes = {
  user: PropTypes.object.isRequired,
  beginSetup: PropTypes.func.isRequired
};

export default OpportunitySetupGuideIntro;
