import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './PartnerPortalRequests.scss';

import PartnerPortalRequest from './PartnerPortalRequest';

import { hasRequestExpired, isRequestOutstanding } from '../../Helpers/gifting_helpers';

const PartnerPortalRequests = props => {
  const { selectedFilter, curSearchVal, resetSearchAndFilters, openRequestModal, updateRequest } = props;

  const giftingRequests = _.orderBy(props.giftingRequests, 'createdAt', 'desc');

  const requestSearchMatching = r =>
    r.brand.name.toLowerCase().includes(curSearchVal.toLowerCase()) || r.brand.domain.toLowerCase().includes(curSearchVal.toLowerCase());

  const outstandingRequests = giftingRequests.filter(isRequestOutstanding);
  const expiredRequests = giftingRequests.filter(hasRequestExpired);
  const restOfRequests = giftingRequests.filter(r => !isRequestOutstanding(r) && !hasRequestExpired(r));
  const shouldShowEmptyResultsMessage = !!(giftingRequests.length === 0 && (selectedFilter || curSearchVal));
  const shouldShowNoResultsMessage = !!(giftingRequests.length === 0 && !selectedFilter && !curSearchVal);

  const filteredRequests = () => {
    let filteredRequests = [...giftingRequests];

    if (curSearchVal) filteredRequests = filteredRequests.filter(requestSearchMatching);
    if (selectedFilter) filteredRequests = filteredRequests.filter(selectedFilter.filter);

    return filteredRequests;
  };

  const isSearching = !!curSearchVal;
  const showResults = !shouldShowNoResultsMessage && !shouldShowEmptyResultsMessage;
  const showResultsAsGroups = (!selectedFilter || selectedFilter?.label === 'Show All') && !isSearching;
  const requestGroups = [
    {
      title: 'Needs Response',
      requests: outstandingRequests,
      visible: showResultsAsGroups
    },
    {
      title: 'Previous Requests',
      requests: restOfRequests,
      visible: showResultsAsGroups
    },
    {
      title: 'Expired Requests',
      requests: expiredRequests,
      visible: showResultsAsGroups
    },
    {
      title: selectedFilter?.label || 'Search Results',
      requests: filteredRequests(),
      visible: !showResultsAsGroups
    }
  ];

  return (
    <div className='partner-portal-requests-outer'>
      <div className='partner-portal-requests-inner'>
        {shouldShowNoResultsMessage && (
          <div className='empty-results-message'>
            <div className='text'>
              <div>No gifting activity yet.</div>
            </div>
          </div>
        )}
        {shouldShowEmptyResultsMessage && (
          <>
            <div className='empty-results-message'>
              <div className='text'>
                <div>No results found. Clear your search or try a different filter.</div>
                <button className='clear-filters-button' onClick={resetSearchAndFilters}>
                  Clear All Filters
                </button>
              </div>
            </div>
          </>
        )}
        {showResults &&
          requestGroups.map(group => {
            const { title, requests, visible } = group;
            if (!requests.length || !visible) return null;
            return (
              <React.Fragment key={title}>
                <div className='results-section-header'>
                  <div className='text'>
                    <div>{title}</div>
                    <span className='request-count'>{requests.length}</span>
                  </div>
                  <div className='spacer'></div>
                </div>
                <div className='requests-grid-container'>
                  {requests.map(request => {
                    return (
                      <PartnerPortalRequest key={request.id} request={request} openRequestModal={openRequestModal} updateRequest={updateRequest} />
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

PartnerPortalRequests.propTypes = {
  giftingRequests: PropTypes.array.isRequired,
  selectedFilter: PropTypes.object,
  curSearchVal: PropTypes.string,
  resetSearchAndFilters: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired
};

export default PartnerPortalRequests;
