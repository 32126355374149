import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import './ShopHierarchy.scss';

import { addEvent } from '../../APIClient/events';
import { inSearchMode } from '../../Helpers/ui_helpers';

class ShopHierarchy extends Component {
  static propTypes = {
    shop: PropTypes.object.isRequired,
    setActiveFilters: PropTypes.func.isRequired,
    activeFilters: PropTypes.shape({
      department: PropTypes.object,
      çategory: PropTypes.object,
      tag: PropTypes.object
    }),
    isHiddenOnMobile: PropTypes.bool,
    toggleMobileHidden: PropTypes.func
  };

  toggleDepartment = department => {
    const turningOff = department.id === _.get(this.props, 'activeFilters.department.id');
    this.props.setActiveFilters({
      department: turningOff ? null : department,
      category: null,
      tag: null
    });
    !turningOff && addEvent('Shop - Select Department', { category: department.name });
    addEvent('Shop - Select Department');
  };

  toggleCategory = category => {
    const turningOff = category.id === _.get(this.props, 'activeFilters.category.id');
    this.props.setActiveFilters({
      category: turningOff ? null : category,
      tag: null
    });
    addEvent('Shop - Select Category');
    !turningOff && addEvent('Shop - Select Category', { category: category.name });
    this.props.toggleMobileHidden();
  };

  toggleTag = (tag, category) => {
    const turningOff = tag.id === _.get(this.props, 'activeFilters.tag.id');
    this.props.setActiveFilters({
      tag: turningOff ? null : tag,
      category
    });
    !turningOff && addEvent('Shop - Select Tag', { tag: tag.name });
    this.props.toggleMobileHidden();
  };

  clearFilters = () => {
    this.props.setActiveFilters({
      department: null,
      category: null,
      tag: null
    });
    this.props.toggleMobileHidden();
  };

  render() {
    const { shop, ui, activeFilters, isHiddenOnMobile } = this.props;
    const activeDepartment = activeFilters.department;
    const activeCategory = activeFilters.category;
    const activeTag = activeFilters.tag;
    const { departments, categories, tags } = shop;
    return (
      <>
        <div className='see-lists-header-mobile'>
          <div className='show-more' onClick={this.props.toggleMobileHidden}>
            See all categories
          </div>
        </div>
        <div className={classnames('hide-fade', { hidden: isHiddenOnMobile })} onClick={this.props.toggleMobileHidden} />
        <nav
          className={classnames('shop-hierarchy-outer-container', {
            hidden: isHiddenOnMobile,
            searching: inSearchMode(ui)
          })}
        >
          <div
            onClick={this.clearFilters}
            className={classnames('row header', {
              active: _.isEmpty(activeFilters)
            })}
          >
            <div className='main'>Best in Beauty</div>
          </div>
          {departments &&
            _.orderBy(departments, 'name').map(department => {
              const { name, id, urlStub } = department;
              const depCats = _.orderBy(
                categories.filter(category => category.Department_id === id),
                'name'
              );
              const active = activeDepartment && activeDepartment.id === id;
              return (
                <Fragment key={name}>
                  <div
                    onClick={() => this.toggleDepartment(department)}
                    className={classnames('row level-one', {
                      active: active,
                      'another-active': !active && activeDepartment
                    })}
                  >
                    {urlStub ? (
                      <Link onClick={e => e.preventDefault()} className='main' to={`/shop/${urlStub}`}>
                        <div>{name}</div>
                        <FontAwesomeIcon icon={active ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                      </Link>
                    ) : (
                      name
                    )}
                  </div>
                  {active &&
                    depCats.map(category => {
                      const { name, id, urlStub } = category;
                      const catTags = _.orderBy(
                        tags.filter(tag => tag.Category_id === id),
                        'name'
                      );
                      const isCategoryActive = activeCategory && activeCategory.id === id;
                      return (
                        <Fragment key={name}>
                          <div
                            key={name}
                            onClick={() => this.toggleCategory(category)}
                            className={classnames('row level-two', {
                              active: isCategoryActive,
                              'another-active': !isCategoryActive && activeCategory
                            })}
                          >
                            {urlStub ? (
                              <Link onClick={e => e.preventDefault()} className='main' to={`/shop/${urlStub}`}>
                                {name}
                              </Link>
                            ) : (
                              name
                            )}
                          </div>
                          {isCategoryActive &&
                            catTags.map(tag => {
                              const { name, id, urlStub } = tag;
                              const active = activeTag && activeTag.id === id && isCategoryActive;
                              return (
                                <div
                                  key={name}
                                  onClick={() => this.toggleTag(tag, category)}
                                  className={classnames('row level-three', {
                                    active: active,
                                    'another-active': !active && activeTag
                                  })}
                                >
                                  {urlStub ? (
                                    <Link onClick={e => e.preventDefault()} className='main' to={`/shop/${urlStub}`}>
                                      {name}
                                    </Link>
                                  ) : (
                                    name
                                  )}
                                </div>
                              );
                            })}
                        </Fragment>
                      );
                    })}
                </Fragment>
              );
            })}
        </nav>
      </>
    );
  }
}

export default ShopHierarchy;
