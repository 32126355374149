import _ from 'lodash';
import * as ActionTypes from '../Actions/LookbookActions';
import { SHOPIFY_ERRORS } from '../Helpers/constants';

export const lookbooks = (
  state = {
    carts: {} // Lookbook ID to Sibling IDs to quantities
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOOKBOOK_CART:
      return {
        ...state,
        carts: {
          ...state.carts,
          [action.lookbook.id]: _.omitBy(
            {
              ...(state.carts[action.lookbook.id] || {}),
              [action.sibling.id]: action.quantity
            },
            q => !q
          )
        }
      };
    case ActionTypes.CREATE_LOOKBOOK_ORDER_SUCCESS:
      return {
        ...state,
        carts: {
          ...state.carts,
          [action.order.Lookbook_id]: null
        }
      };

    case ActionTypes.CREATE_LOOKBOOK_ORDER_ERROR:
      if (action.errorMsg === SHOPIFY_ERRORS.OUT_OF_STOCK) {
        window.ALERT.error(
          'One or more of the selected items are currently out of stock. Please select another product and we will notify the brand.',
          {
            hideAfter: 20
          }
        );
      } else {
        window.ALERT.error(
          `There was an issue creating this order. ${
            action.errorMsg ? action.errorMsg : 'We have been notified of this issue and will get back to you shortly.'
          }`,
          { hideAfter: 15 }
        );
      }
      return {
        ...state
      };

    default:
      return state;
  }
};
