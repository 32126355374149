import APIClient from './index';
import _ from 'lodash';

/* Contracts */
export const getContract = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Contracts/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
export const getContracts = (params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Contracts?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createContractFromTask = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Contracts/from_task`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createContract = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Contracts`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContract = (contract, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Contracts/${contract.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteContract = contract => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Contracts/${contract.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
export const getContractAnalytics = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Contracts/${id}/analytics`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

/* User Contract Tasks */
export const getContractTaskTemplates = params => {
  return new Promise((resolve, reject) => {
    APIClient.get('/ContractTaskTemplates')
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createUserContractTask = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserContractTasks`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateUserContractTask = (task, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserContractTasks/${task.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteUserContractTask = task => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/UserContractTasks/${task.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

/* Contract Tasks */
export const getRecentContracts = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Contracts/recent`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createContractTaskFromUserTask = (userTask, contract) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractTasks/from_user_task`, { UserContractTask_id: userTask.id, Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const createContractTaskFromTaskTemplate = (taskTemplate, contract) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractTasks/from_task_template`, { ContractTaskTemplate_id: taskTemplate.id, Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContractTask = (task, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ContractTasks/${task.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteContractTask = task => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ContractTasks/${task.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

/* Contract Links */
export const createContractLink = (data, contract) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractLinks`, { ...data, Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContractLink = (link, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ContractLinks/${link.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteContractLink = link => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ContractLinks/${link.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getRecentContractLinksForBrand = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/ContractLinks/recent_for_brand/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getContractLinkOptionsFromSearchQuery = (query, Brand_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractLinks/options_from_query`, { query, Brand_id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

/* Contract Uploads */
export const createContractUpload = (data, contract) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractUploads`, { ...data, Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContractUpload = (upload, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ContractUploads/${upload.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteContractUpload = upload => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ContractUploads/${upload.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getRecentContractUploads = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractUploads/recent`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

/* Contract Rates & Codes */
export const createContractRateCode = (data, contract) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/ContractRatesCodes`, { ...data, Contract_id: contract.id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateContractRateCode = (rate_code, data) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/ContractRatesCodes/${rate_code.id}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteContractRateCode = rate_code => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/ContractRatesCodes/${rate_code.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
