import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './ConsultElementMediaUpload.scss';

import UploadImage from '../../General/UploadImage';
import VideoPlayer from '../../General/VideoPlayer';

import { isVideoString } from '../../../Helpers/media_helpers';
import { getSmartImage, matchScrollHeight } from '../../../Helpers/helpers';

const ConsultElementMediaUpload = props => {
  const { canEdit, question, mediaUrls, setField } = props;

  const completeMediaUpload = url => setField('mediaUrls', [...mediaUrls, url]);
  const removeMedia = url =>
    setField(
      'mediaUrls',
      mediaUrls.filter(u => u !== url)
    );

  return (
    <div className='element-container media-upload-container'>
      {canEdit ? (
        <textarea
          rows={1}
          ref={ref => matchScrollHeight(ref)}
          value={question}
          onChange={e => setField('question', e.target.value, 250)}
          placeholder='What would you like them to upload?'
          className='question'
        />
      ) : (
        <div className='question'>{question || '-'}</div>
      )}
      {
        <div className='media-uploads'>
          {mediaUrls.map((url, idx) => {
            const isVideo = isVideoString(url);
            return (
              <div key={idx} className='media-upload'>
                {isVideo ? (
                  <div key={idx}>
                    <VideoPlayer src={url} />
                  </div>
                ) : (
                  <img key={idx} src={getSmartImage(url)} alt={`Media Upload ${idx}`} />
                )}
                {!canEdit && (
                  <div onClick={() => removeMedia(url)} className='remove-icon'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
              </div>
            );
          })}

          <div className={cn('upload-new-media', { disabled: canEdit })}>
            <div className='upload-new-media-container'>
              <div className='add-new-btn-icn'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            <UploadImage
              basicUploader
              basicTextExplainer={'NEW Photo / Video'}
              postUploadMessageWhileCompleting='Finalizing Upload...'
              completeUpload={completeMediaUpload}
              disabledMessage={canEdit ? 'Click "View As Public" To Test Uploading Media' : null}
            />
          </div>
        </div>
      }
    </div>
  );
};

ConsultElementMediaUpload.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  mediaUrls: PropTypes.array.isRequired,
  setField: PropTypes.func.isRequired
};

export default ConsultElementMediaUpload;
