import APIClient from './index';
import _ from 'lodash';

export const getBrandLists = Brand_id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandLists/v2/for_brand/${Brand_id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const addBrandList = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BrandLists`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const getBrandList = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/BrandLists/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateBrandList = (list, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/BrandLists/${list.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteBrandList = list => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/BrandLists/${list.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const addBrandListUser = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/BrandListUsers`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateBrandListUser = (listUser, updates) => {
  return new Promise((resolve, reject) => {
    return APIClient.put(`/BrandListUsers/${listUser.BrandListUser_id || listUser.id}`, updates) // Some use BrandListUser_id for more specificity
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteBrandListUser = listUser => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/BrandListUsers/${listUser.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const rejectBrandListUserRecommendation = listUser => {
  return new Promise((resolve, reject) => {
    return APIClient.post(`/BrandListUsers/reject_recommendation/${listUser.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
