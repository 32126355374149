import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faSearch, faPencil, faChevronUp, faEyeSlash, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faBars, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { SortableElement } from 'react-sortable-hoc';
import { sortableHandle } from 'react-sortable-hoc';
import { SortableContainer } from '../../Helpers/sort_helpers';
import { getGMTTime } from '../../Helpers/formatting';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import arrayMove from 'array-move';
import './DiscoverUsersSidebar.scss';

import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { isBrandTag } from '../../Helpers/user_helpers';
import { openUpdateListModal } from '../../Helpers/list_helpers';

const DiscoverUsersSidebar = props => {
  const { discover, fetching, toggleQuery, user, ui, activeQueries } = props;
  const { lists, num_gifting_recommendations, num_code_recommendations, num_collab_recommendations } = discover || {};
  const nonRecommendationLists = _.filter(lists, l => !l.isRecommendationList);
  const recommendationLists = _.filter(lists, l => l.isRecommendationList);
  const [tempSections, setTempSections] = useState(null);
  const adminControlMode = isAdminControlMode(ui);
  const tags = _.filter(
    _.orderBy(discover?.tags || [], ['type', t => isBrandTag(t, user), t => t.value], ['asc', 'desc']),
    t => adminControlMode || !t.isHidden
  );
  const [expandedSections, setExpandedSections] = useState([]);

  const isSectionExpanded = s => !!expandedSections.find(se => se.header === s.header);
  const toggleSectionExpanded = s =>
    setExpandedSections(isSectionExpanded(s) ? expandedSections.filter(se => se.header !== s.header) : [...expandedSections, s]);
  const isQuerySelected = q => !!activeQueries.find(qu => qu.value === q.value);

  const augmentTagsWithQuery = tags =>
    tags.map(tag => ({
      ...tag,
      query: { Tag_id: tag.id, type: tag.type, value: tag.value } // Matches implementation in control bar
    }));

  const deleteList = list => {
    const confirm = () => props.deleteBrandList(list).then(props.syncTags);
    list.count
      ? confirmAlert({
          title: 'Just confirming',
          message: 'Are you sure you want to delete this list?',
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: confirm
            }
          ]
        })
      : confirm();
  };

  const sections = [
    {
      header: 'Our Talent',
      headerAsTag: true,
      headerQuery: { type: 'mypromoter', value: 'Our Talent' } // Matches implementation in DiscoverUsersResult
    },
    ...(!nonRecommendationLists?.length
      ? []
      : [
          {
            header: 'Our Lists',
            allowSort: true,
            tags: nonRecommendationLists.map(list => ({
              ...list,
              value: list.title,
              query: {
                BrandList_id: list.id,
                type: 'brandlist',
                value: list.title
              },
              clickEdit: e => {
                e.stopPropagation();
                openUpdateListModal(list, props.updateBrandList, props.deleteBrandList, props.syncTags);
              },
              clickSetType: e => {
                e.stopPropagation();
                let updates = {};
                const confirm = window.prompt('Is this a recommendation list? yes/no', list.isRecommendationList ? 'yes' : 'no');
                if (!confirm) return;
                updates.isRecommendationList = confirm === 'yes';

                if (updates.isRecommendationList) {
                  const type = window.prompt(
                    'What type of recommendations? codes/gifting/collabs/rates/outreach',
                    list.recommendationListType || 'gifting'
                  );
                  if (!type || !['codes', 'gifting', 'collabs', 'rates', 'outreach'].includes(type))
                    return type && cogoToast.warn('Invalid type, try again.');
                  updates.recommendationListType = type;
                } else {
                  updates.recommendationListType = null;
                }

                props.updateBrandList(list, updates);
              },
              clickDelete: !list.count // Only show delete on empty lists
                ? e => {
                    e.stopPropagation();
                    deleteList(list);
                  }
                : null
            }))
          }
        ]),
    ...(!recommendationLists?.length
      ? []
      : [
          {
            header: 'ShopMy Recommendations',
            allowSort: true,
            tags: recommendationLists.map(list => ({
              ...list,
              value: list.title,
              query: {
                BrandList_id: list.id,
                type: 'brandlist',
                value: list.title
              },
              clickEdit: e => {
                e.stopPropagation();
                openUpdateListModal(list, props.updateBrandList, props.deleteBrandList, props.syncTags);
              },
              clickSetType: e => {
                e.stopPropagation();
                let updates = {};
                const confirm = window.prompt('Is this a recommendation list? yes/no', list.isRecommendationList ? 'yes' : 'no');
                if (!confirm) return;
                updates.isRecommendationList = confirm === 'yes';

                if (updates.isRecommendationList) {
                  const type = window.prompt(
                    'What type of recommendations? codes/gifting/collabs/rates/outreach',
                    list.recommendationListType || 'gifting'
                  );
                  if (!type || !['codes', 'gifting', 'collabs', 'rates', 'outreach'].includes(type))
                    return type && cogoToast.warn('Invalid type, try again.');
                  updates.recommendationListType = type;
                } else {
                  updates.recommendationListType = null;
                }

                props.updateBrandList(list, updates);
              },
              clickDelete: !list.count // Only show delete on empty lists
                ? e => {
                    e.stopPropagation();
                    deleteList(list);
                  }
                : null
            }))
          }
        ]),
    // {
    //   header: 'Recommendations',
    //   tags: [
    //     {
    //       value: 'For Gifting',
    //       query: {
    //         type: 'recommendedforgifting',
    //         value: 'Recommended For Gifting'
    //       }
    //     },
    //     {
    //       value: 'For Discount Codes',
    //       query: {
    //         type: 'recommendedforcodes',
    //         value: 'Recommended For Discount Codes'
    //       }
    //     },
    //     {
    //       value: 'For Collaborations',
    //       query: {
    //         type: 'recommendedforcollabs',
    //         value: 'Recommended For Collaborations'
    //       }
    //     }
    //   ]
    // },
    {
      header: 'User Types',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'general'))
    },
    {
      header: 'Collaborations',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'collaboration'))
    },
    {
      header: 'Geography',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'geo'))
    },
    {
      header: 'Social',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'social'))
    },
    {
      header: 'Product Types',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'catalog'))
    },
    {
      header: 'Activity',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'activity'))
    },
    ...(adminControlMode
      ? [
          {
            header: 'Behavior',
            tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'behavior'))
          },
          {
            header: 'Internal',
            tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'internal'))
          }
        ]
      : []),
    {
      header: 'Brands They Promote',
      tags: augmentTagsWithQuery(tags.filter(tag => tag.type === 'brandpromoter'))
    }
  ];

  const isTagEmpty = tag => !tag.count && !props.searchVal;
  return (
    <div className='discover-users-sidebar-container'>
      <div className='search-container'>
        <input placeholder='Search by keyword' onChange={({ target }) => props.setSearchVal(target.value)} value={props.searchVal} />
        {props.searchVal.length ? (
          <FontAwesomeIcon className='clear' onClick={() => props.setSearchVal('')} icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faSearch} />
        )}
      </div>
      <div className='tag-sections-container'>
        {(tempSections || sections).map((section, sectionIdx) => {
          const { header, allowSort, headerAsTag, headerQuery, tags } = section;
          const VISIBLE_TAGS_COLLAPSED = 5;
          const expanded = isSectionExpanded(section);
          const hasManyResults = tags?.length > VISIBLE_TAGS_COLLAPSED;
          const DragHandle = allowSort
            ? sortableHandle(() => (
                <div className='reorder-icn action'>
                  <FontAwesomeIcon icon={faBars} />
                </div>
              ))
            : null;

          const getTagElement = (tag, idx) => {
            const { query, clickSetType, clickEdit, clickDelete } = tag;
            const selected = isQuerySelected(query);
            const empty = isTagEmpty(tag);
            let count = tag.count;

            const typeButton =
              clickSetType && adminControlMode ? (
                <div onClick={clickSetType} className='edit-icn action'>
                  <FontAwesomeIcon icon={faGift} />
                </div>
              ) : null;

            const editBtn = clickEdit ? (
              <div onClick={clickEdit} className='edit-icn action'>
                <FontAwesomeIcon icon={faPencil} />
              </div>
            ) : null;

            const deleteBtn = clickDelete ? (
              <div onClick={clickDelete} className='delete-icn action'>
                <FontAwesomeIcon icon={faTrash} />
              </div>
            ) : null;
            const hasActions = !!DragHandle || !!editBtn || !!deleteBtn;

            if (!tag.id) {
              // We are working with a non-tag based filter
              // Need the number check due to unknown bug with redux loading an empty object as opposed to 0
              if (tag.value === 'For Gifting') count = typeof num_gifting_recommendations === 'number' ? num_gifting_recommendations : 0;
              if (tag.value === 'For Discount Codes') count = typeof num_code_recommendations === 'number' ? num_code_recommendations : 0;
              if (tag.value === 'For Collaborations') count = typeof num_collab_recommendations === 'number' ? num_collab_recommendations : 0;
            }
            return (
              <div onClick={() => toggleQuery(tag.query)} key={tag.id || idx} className={cn('discover-users-sidebar-tag', { selected })}>
                <div className={cn('value', { empty })}>
                  {hasActions && (
                    <div className='actions'>
                      {typeButton}
                      {deleteBtn}
                      {editBtn}
                      {DragHandle && <DragHandle />}
                    </div>
                  )}
                  {tag.value}
                  {!!count && !props.searchVal.length && <span className={cn('count', { fetching })}>{count}</span>}
                  {!!tag.isHidden && <FontAwesomeIcon className='hidden' icon={faEyeSlash} />}
                </div>
                <div className={cn('checkbox', { selected })}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            );
          };

          const SortableItem = SortableElement(({ value }) => getTagElement(value));
          const SortableList = SortableContainer(({ items }) => {
            return (
              <div className='products-list-wrapper'>
                {items.map((item, idx) => (
                  <SortableItem axis='xy' key={`item-${item.id}`} index={idx} value={item} />
                ))}
              </div>
            );
          });

          const onSortEnd = async ({ newIndex, oldIndex }) => {
            /*
              We sort these ascending which is different from the Pins, Collections, BlogPostElements, etc. 
              This is because we want to put any new list at the top.
            */
            if (newIndex === oldIndex) return;
            setTempSections(sections.map((s, i) => (i !== sectionIdx ? s : { ...s, tags: arrayMove(s.tags, oldIndex, newIndex) })));

            const movingDown = newIndex > oldIndex;
            const stampBeforeRank = _.get(tags, [movingDown ? newIndex : newIndex - 1, 'sortOrderRank']);
            const stampAfterRank = _.get(tags, [movingDown ? newIndex + 1 : newIndex, 'sortOrderRank'], getGMTTime());

            let newStampRank;
            if (stampBeforeRank) {
              newStampRank = (stampAfterRank + stampBeforeRank) / 2;
            } else {
              newStampRank = stampAfterRank + 1000; // Put it 1s higher than the first one
            }
            await props.updateBrandList(tags[oldIndex], { sortOrderRank: newStampRank }).then(props.syncTags);
            setTimeout(() => setTempSections(null), 1000); // Reset temporary sorting
          };

          const visibleTags = tags?.slice(0, expanded ? tags.length : VISIBLE_TAGS_COLLAPSED);
          return (
            <div key={section.header} className='section'>
              <div
                onClick={() => (headerAsTag ? toggleQuery(headerQuery) : hasManyResults && toggleSectionExpanded(section))}
                className={cn('section-header', { selectable: headerQuery })}
              >
                {header}
                {headerAsTag ? (
                  <div className={cn('checkbox clear', { selected: isQuerySelected(headerQuery) })}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                ) : (
                  expanded && (
                    <div className={cn('toggle-section', { expanded })}>
                      <FontAwesomeIcon icon={faChevronUp} />
                    </div>
                  )
                )}
              </div>
              {!visibleTags?.length ? null : allowSort ? (
                <div className='section-tags'>
                  <SortableList key={section.header} axis='xy' items={visibleTags} onSortEnd={onSortEnd} useDragHandle />
                  {hasManyResults && (
                    <div onClick={() => toggleSectionExpanded(section)} className='toggle-more'>
                      SHOW {expanded ? 'LESS' : 'MORE'}
                    </div>
                  )}
                </div>
              ) : (
                <div className='section-tags'>
                  {visibleTags.map(getTagElement)}
                  {hasManyResults && (
                    <div onClick={() => toggleSectionExpanded(section)} className='toggle-more'>
                      SHOW {expanded ? 'LESS' : 'MORE'}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

DiscoverUsersSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  discover: PropTypes.object.isRequired,
  activeQueries: PropTypes.array.isRequired,
  searchVal: PropTypes.string.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  toggleQuery: PropTypes.func.isRequired,
  syncTags: PropTypes.func.isRequired,
  updateBrandList: PropTypes.func.isRequired,
  deleteBrandList: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired
};

export default DiscoverUsersSidebar;
