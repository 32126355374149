/*
  This component is powered by our Examples Shop. This shop has the username "examples" and has a single
  hidden consult called ""{{Questions}}"" that contains all the templated questions. Each question has
  a tag in the format {{ElementGroup}} that is used to categorize them into groupings. The order designates 
  which order the element groups appear.

  To add a new question:

  1) Log in to the Examples account - tiffany+examples@shopmy.us 
  2) Find the {{Questions}} consult
  3) Add {{ElementGroup}} to the description, for example: {{Skincare}}
*/
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import './ConsultElementPicker.scss';
import cn from 'classnames';
import _ from 'lodash';

import {
  getTemplateElements,
  getElementsFromContent,
  formatPublicConsultDisplay,
  getIconForType,
  getTagFromString
} from '../../Helpers/consult_helpers';
import { getConsults } from '../../Helpers/user_helpers';
import Modal from '../General/Modal';

const ConsultElementPicker = props => {
  const { addElement, getShopExamples, store, user, closeModal } = props;
  const [selectedFilter, setSelectedFilter] = useState(null);
  const questionExample = _.find(store?.examples?.consults || [], e => e.title === '{{Questions}}');
  const exampleElements = getElementsFromContent(questionExample?.content);

  // Fetch all example question groups
  let [filters, groups] = [[], {}];
  const sortedExamples = _.orderBy(exampleElements, 'sortOrderRank');
  sortedExamples.forEach(example => {
    const tag = getTagFromString(example.question);
    if (tag) {
      filters = tag ? _.uniq([...filters, tag]) : filters;
      groups[tag] = [...(groups[tag] || []), example];
    }
  });

  // Add the user examples
  const allUserElements = _.flatten(getConsults(user).map(c => getElementsFromContent(c.content)));
  const elementCounts = _.groupBy(allUserElements, 'question');
  const uniqueUserElements = _.uniqBy(allUserElements, 'question');
  const sortedUserElements = _.orderBy(uniqueUserElements, el => elementCounts[el.question]?.length || 0, 'desc');
  filters.push('Your Questions');
  groups['Your Questions'] = _.filter(sortedUserElements, el => el.question);

  useEffect(() => {
    getShopExamples();
  }, [getShopExamples]);

  const selectElement = el => {
    addElement(el);
    closeModal();
  };

  const templateElements = getTemplateElements();

  const getElementOption = (element, idx) => {
    const { type, extra, display, question } = element;
    const icon = getIconForType(type);
    const displayQuestion = formatPublicConsultDisplay(display || question);

    let preloadedData = {};
    const isBlankTemplate = !!extra;
    if (isBlankTemplate) {
      preloadedData = extra;
    } else {
      if (element.question) preloadedData.question = displayQuestion;
      if (element.placeholder) preloadedData.placeholder = element.placeholder;
      if (element.onlyOneSelection) preloadedData.onlyOneSelection = element.onlyOneSelection;
      if (element.options) preloadedData.options = element.options;
    }

    return (
      <div key={idx} onClick={() => selectElement({ type, ...preloadedData })} className={cn('element-container', { blank: isBlankTemplate })}>
        <div className='main'>
          {icon && <FontAwesomeIcon className='display' icon={icon} />}
          <div className='name'>{displayQuestion}</div>
        </div>
        <FontAwesomeIcon className='add' icon={faPlus} />
      </div>
    );
  };

  return (
    <Modal
      innerClassName='consult-element-picker-outer-modal'
      contentClassName='consult-element-picker-modal'
      showClose
      visible={props.visible}
      close={closeModal}
    >
      <div className='section-selection'>
        <div onClick={() => setSelectedFilter(null)} className={cn('filter', { selected: !selectedFilter })}>
          All Questions
        </div>
        {filters.map(filter => (
          <div key={filter} onClick={() => setSelectedFilter(filter)} className={cn('filter', { selected: selectedFilter === filter })}>
            {filter}
          </div>
        ))}
      </div>
      <div className='sections'>
        <div className='header'>{selectedFilter || 'Select A Question To Add'}</div>
        {!selectedFilter && (
          <div className='spacer-container top'>
            <div className='label'>BLANK QUESTIONS</div>
          </div>
        )}
        {!selectedFilter && <div className='filter-section blank'>{templateElements.map(getElementOption)}</div>}
        {!selectedFilter && (
          <div className='spacer-container'>
            <div className='label'>OR USE AN EXAMPLE QUESTION</div>
          </div>
        )}
        {filters.map(filter =>
          selectedFilter && selectedFilter !== filter ? null : (
            <Fragment key={filter}>
              {filter === 'Your Questions' && !selectedFilter && (
                <div className='spacer-container'>
                  <div className='label'>OR USE ONE OF YOUR PAST QUESTIONS</div>
                </div>
              )}
              <div key={filter} className='filter-section'>
                {!selectedFilter && <div className='filter-section-header'>{filter}</div>}
                {groups[filter].map(getElementOption)}
              </div>
            </Fragment>
          )
        )}
      </div>
    </Modal>
  );
};

ConsultElementPicker.propTypes = {
  store: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  addElement: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  getShopExamples: PropTypes.func.isRequired
};

export default ConsultElementPicker;
