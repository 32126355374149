import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import './EmptyShopIntro.scss';

import consultsIntro1 from '../../static/images/onboarding/consults_intro_1.png';
import consultsIntro2 from '../../static/images/onboarding/consults_intro_2.png';
import consultsIntro3 from '../../static/images/onboarding/consults_intro_3.png';

import { getRootUrl } from '../../Helpers/helpers';
import { isYou } from '../../Helpers/user_helpers';
import { spotlightKeys } from '../../Helpers/spotlight_helpers';

import Spotlight from '../Spotlight/Spotlight';

const EmptyShopIntro = props => {
  const { isConsultsTab, user, visibleShop, getStarted } = props;

  const sections = [
    {
      headline: 'Create a consult form and set a price.',
      exampleUrl: '/consults/3/Nighttime-Skincare-Routine-Review',
      image: consultsIntro1
    },
    {
      headline: 'Your client fills out the form and pays the associated fee.',
      image: consultsIntro2
    },
    {
      headline: 'You build them a custom list of products.',
      exampleUrl: '/consults/results/TKX7UzB9I',
      image: consultsIntro3
    }
  ];

  const clickNotInterested = () => {
    if (!isYou(visibleShop?.username, user)) return window.ALERT.warn('You can only update Shop Settings from universal login.');
    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you do not want to offer consultations? This will completely remove the Consults tab from your shop.',
      buttons: [
        { label: 'Cancel', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => props.updateUserSettings({ hideConsultsFromShop: true })
        }
      ]
    });
  };

  return (
    <div className={cn('empty-shop-intro-outer-container', { consults: isConsultsTab })}>
      <div className='intro-header-container'>
        <div className='intro-header'>Introducing Consults</div>
        <div className='intro-subheader'>Allow your audience to purchase your product recommendations.</div>
      </div>
      <div className='intro-body'>
        <div className='how-it-works'>HOW CONSULTS WORK</div>
        <div className='intro-sections'>
          {sections.map(({ headline, exampleUrl, image }, idx) => (
            <div key={idx} className='intro-section'>
              <div className='section-main'>
                <div className='section-count'>{idx + 1}</div>
                <div className='section-text'>
                  <div className='section-headline'>{headline}</div>
                  {exampleUrl && (
                    <a target='_blank' rel='noopener noreferrer' href={`${getRootUrl()}${exampleUrl}`} className='section-example-link'>
                      SEE EXAMPLE
                      <FontAwesomeIcon icon={faChevronRight} />
                    </a>
                  )}
                </div>
              </div>
              <div className='section-image'>
                <img src={image} alt='Create Consult Form' />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='footer-container'>
        <Spotlight spotlightKey={spotlightKeys.CREATE_CONSULT}>
          <div className='get-started-btn' onClick={getStarted}>
            GET STARTED
          </div>
        </Spotlight>
      </div>
      <div className='get-started-btns-top'>
        <div className='get-started-btn-top' onClick={clickNotInterested}>
          NOT INTERESTED
        </div>
        <div className='get-started-btn-top' onClick={getStarted}>
          GET STARTED
        </div>
      </div>
    </div>
  );
};

EmptyShopIntro.propTypes = {
  user: PropTypes.object.isRequired,
  visibleShop: PropTypes.object.isRequired,
  getStarted: PropTypes.func.isRequired,
  isConsultsTab: PropTypes.bool
};

export default EmptyShopIntro;
