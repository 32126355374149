import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import Switch from 'react-switch';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import './ArtistModalMentions.scss';

import Tooltip from '../General/Tooltip';
import ArtistModalPinMention from './Elements/ArtistModalPinMention';
import ArtistModalPinMentionDetails from './ArtistModalPinMentionDetails';
import ArtistModalSocialMentions from './ArtistModalSocialMentions';
import ArtistModalPanelEmpty from './ArtistModalPanelEmpty';

import { getCollection } from '../../APIClient/collections';
import { getSocialPlatformForUrl } from '../../Helpers/social_helpers';
import { showCompetitorBrandsOnArtistModal, isAdminControlMode } from '../../Helpers/ui_helpers';
import { getCompetitorBrands } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription } from '../../Helpers/subscription_helpers';

const ArtistModalMentions = props => {
  const { pinMentions, mentions, talent, user, ui, details } = props;
  const { social_accounts } = details;
  const pinMentionDetails = details.mentions;

  // Two types of mentions to display
  const [curSubTab, setCurSubTab] = useState(!mentions.length && pinMentions.length ? 'mentions-collections' : 'mentions-social');
  const subtabs = [
    { display: 'On Social', variable: 'mentions-social' },
    { display: 'Via Product Collections', variable: 'mentions-collections' }
  ];
  const isShowingSocialMentions = curSubTab === 'mentions-social';

  // Collection Mentions
  const isShowingCollectionMentions = curSubTab === 'mentions-collections';
  const [selectedMention, setSelectedMention] = useState(null);
  const [collection, setCollection] = useState(null);

  const getMentionTitle = mention => {
    const formatSocialLinksTitle = (socialPrefix, name) => (name ? `${socialPrefix} • ${name}` : socialPrefix);

    if (mention.social_links?.includes('instagram')) return formatSocialLinksTitle('Instagram', mention.name);
    if (mention.social_links?.includes('youtube')) return formatSocialLinksTitle('YouTube', mention.name);
    if (mention.social_links?.includes('tiktok')) return formatSocialLinksTitle('TikTok', mention.name);
    return mention.name;
  };

  const getSocialLinkForMention = mention => mention.social_links?.split(',')[0];
  const getSocialPlatformForMention = mention => mention.social_links && getSocialPlatformForUrl(getSocialLinkForMention(mention));

  const selectMention = mention => {
    setSelectedMention(mention);
    mention &&
      window.__ADD_EVENT__('Talent Card - Select Mention', {
        talent_name: talent.name,
        talent_username: talent.name,
        mention_name: mention.name,
        mention_username: mention.username,
        user
      });
  };
  const backFromMention = () => selectMention(null);

  useEffect(() => {
    if (selectedMention) {
      getCollection(selectedMention.id)
        .then(resp => {
          setCollection(resp);
        })
        .catch(error => {
          cogoToast.error(`Could not load collection, please try again.`);
          backFromMention();
        });
    } else {
      setCollection(null);
    }
  }, [selectedMention]);

  // Handle Competitors
  const competitorBrands = getCompetitorBrands(user);
  const competitorBrandMessage = competitorBrands.length
    ? competitorBrands.map(b => b.name).join(', ')
    : `You do not have any similar brands specified, please reach out to your Account Manager.`;
  const showCompetitorBrands = showCompetitorBrandsOnArtistModal(ui);
  const toggleCompetitorBrands = () => props.setUIKeyValue('showCompetitorBrandsOnArtistModal', !showCompetitorBrands, 'general');
  const clickToggle = () => {
    if (!isAdminControlMode(ui) && blockOnRequiringSubscription(user, 'COMPETITOR_BRANDS')) return;
    competitorBrands.length ? toggleCompetitorBrands() : cogoToast.warn(competitorBrandMessage);
  };
  return (
    <>
      <div className='artist-modal-mentions-container'>
        <div className='header-actions'>
          <div className='main'>
            {!!subtabs?.length && (
              <div className='subtabs'>
                {subtabs.map(subtab => {
                  const { display, variable } = subtab;
                  const isSelected = curSubTab === variable;
                  const click = () => setCurSubTab(variable);
                  return (
                    <div key={display} onClick={click} className={cn('subtab', { selected: isSelected })}>
                      <div className='display'>{display}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className='secondary'>
            <div className='analytics-toggle'>
              <div className='label'>
                Show Mentions of Similar Brands
                {!!competitorBrands.length && <Tooltip message={competitorBrandMessage} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle} />} />}
              </div>
              <Switch
                onChange={clickToggle}
                checked={showCompetitorBrands}
                height={14}
                width={24}
                onColor='#333'
                offColor='#ccc'
                className='switch'
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          </div>
        </div>
        <div className={cn('artist-modal-mentions', { fetching: props.isFetchingMentions })}>
          {isShowingSocialMentions ? (
            !mentions.length ? (
              <ArtistModalPanelEmpty
                message={
                  social_accounts.length
                    ? `${talent.name} has not yet mentioned you${showCompetitorBrands ? ' or similar brands to yours' : ''} in their social content.`
                    : `${talent.name} does not have any connected social accounts, meaning we cannot currently track social mentions.`
                }
              />
            ) : (
              <ArtistModalSocialMentions mentions={mentions} />
            )
          ) : isShowingCollectionMentions ? (
            !pinMentionDetails.length ? (
              <ArtistModalPanelEmpty message={`${talent.name} has not yet mentioned you in their content.`} />
            ) : (
              pinMentionDetails.map(mention => {
                const pinsWithStats = pinMentions.filter(p => p.Collection_id === mention.id);
                return (
                  <ArtistModalPinMention
                    key={mention.id}
                    user={user}
                    mention={mention}
                    pinsWithStats={pinsWithStats}
                    selectMention={selectMention}
                    getMentionTitle={getMentionTitle}
                    getSocialLinkForMention={getSocialLinkForMention}
                    getSocialPlatformForMention={getSocialPlatformForMention}
                  />
                );
              })
            )
          ) : null}
        </div>
      </div>
      {selectedMention && (
        <ArtistModalPinMentionDetails
          user={user}
          mention={selectedMention}
          collection={collection}
          getMentionTitle={getMentionTitle}
          backFromMention={backFromMention}
          getSocialLinkForMention={getSocialLinkForMention}
          getSocialPlatformForMention={getSocialPlatformForMention}
        />
      )}
    </>
  );
};

ArtistModalMentions.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isFetchingMentions: PropTypes.bool.isRequired,
  pinMentions: PropTypes.array.isRequired,
  talent: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  setUIKeyValue: PropTypes.func.isRequired
};

export default ArtistModalMentions;
