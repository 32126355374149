import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import './SearchMediaModal.scss';

import Modal from '../General/Modal';
import Loader from '../Loader/Loader';

import { getImageFromYoutubeUrl } from '../../Helpers/social_helpers';
import { searchInstagram, searchYoutube, searchTiktok } from '../../APIClient/social';
import { getUserId } from '../../Helpers/user_helpers';

const SEARCH_LIMIT = 12;

class SearchMediaModal extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    selectResult: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['instagram', 'youtube', 'tiktok']),

    // Optional
    contract: PropTypes.object,
    Searching_User_id: PropTypes.number,
    addCollection: PropTypes.func
  };

  componentDidMount() {
    this.performSearch();
    this.resultsScrollEl.addEventListener('scroll', e => {
      const el = this.resultsScrollEl;
      const pixelsFromBottom = el.scrollHeight - el.scrollTop - el.getBoundingClientRect().height;
      pixelsFromBottom < 100 &&
        !this.state.isSearching &&
        this.state.hasMoreResults &&
        this.setState({ page: this.state.page + 1 }, () => {
          this.performSearch();
        });
    });
  }

  performSearch = () => {
    const { user, Searching_User_id, type } = this.props;
    const { isSearching, hasMoreResults, results, page, next_page } = this.state;
    if (!hasMoreResults || (isSearching && page > 0)) return;
    this.setState({ isSearching: true });

    if (type === 'instagram') {
      searchInstagram({ User_id: Searching_User_id || getUserId(user), limit: SEARCH_LIMIT, page_url: next_page })
        .then(resp => {
          const stories = resp.stories?.length ? resp.stories.map(story => ({ ...story, media_type: 'STORY' })) : [];
          const mediaContent = resp.content;
          // media response content is a single page of results, concat with previous results. Stories fetched once
          const newResults = [...results, ...mediaContent, ...stories];
          this.setState({
            results: newResults,
            hasMoreResults: !!resp.next_page,
            next_page: resp.next_page
          });
        })
        .catch(error => {
          console.error(error);
          window.ALERT.warn(
            'We were unable to find any results for your search. You may need to unlink and relink your Instagram in the Account Settings page',
            {
              duration: 6
            }
          );
        })
        .finally(() => {
          this.setState({ isSearching: false });
        });
    } else if (type === 'youtube') {
      searchYoutube({ User_id: Searching_User_id || getUserId(user), offset: page * SEARCH_LIMIT, limit: SEARCH_LIMIT, token: next_page })
        .then(resp => {
          const newResults = [...results.slice(0, page * SEARCH_LIMIT), ...resp.content, ...results.slice((page + 1) * SEARCH_LIMIT)];
          this.setState({
            results: newResults,
            hasMoreResults: newResults.length < resp.totalResults,
            next_page: resp.nextPageToken
          });
        })
        .catch(error => {
          console.error(error);
          cogoToast.error(error);
        })
        .finally(() => {
          this.setState({ isSearching: false });
        });
    } else if (type === 'tiktok') {
      searchTiktok({ User_id: Searching_User_id || getUserId(user), offset: page * SEARCH_LIMIT, limit: SEARCH_LIMIT, cursor: next_page })
        .then(resp => {
          const newResults = [...results.slice(0, page * SEARCH_LIMIT), ...resp.content, ...results.slice((page + 1) * SEARCH_LIMIT)];
          this.setState({
            results: newResults,
            hasMoreResults: resp.has_more,
            next_page: resp.nextPageCursor
          });
        })
        .catch(error => {
          window.a = error;
          console.error(error.response);
          cogoToast.error(error);
        })
        .finally(() => {
          this.setState({ isSearching: false });
        });
    }
  };

  state = {
    page: 0,
    results: [],
    hasFetchedInstagramStories: false,
    isSearching: true,
    hasMoreResults: true,
    next_page: null
  };

  render() {
    const { contract, type, addCollection } = this.props;
    const { page, results, isSearching } = this.state;

    const sortedResults = _.orderBy(results, result => result.timestamp || result.publishedAt, 'desc');

    const isInstagram = type === 'instagram';
    const isYoutube = type === 'youtube';
    const isTiktok = type === 'tiktok';
    return (
      <Modal
        visible
        close={this.props.closeModal}
        innerClassName='search-media-modal-outer-container'
        contentClassName='search-media-modal-outer-container'
        scrollRef={ref => (this.resultsScrollEl = ref)}
        showClose
        isNestedModal
      >
        {!isSearching && (
          <>
            {isInstagram && (
              <div className='results-header-container'>
                <div className='results-header'>Add from your recent Instagram content</div>
                <div className='results-subheader'>
                  {addCollection ? (
                    <>
                      {` `}Click{' '}
                      <span onClick={addCollection} className='link'>
                        here
                      </span>{' '}
                      to create a manual post.
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </>
        )}
        <div className='results-container'>
          {isSearching && page === 0 ? (
            <div className='loader'>
              <Loader />
            </div>
          ) : results.length ? (
            <div className='results'>
              {sortedResults.map(result => {
                let badge, image, createdAt, url, title, isAlreadyLinkedToContract;
                if (isInstagram) {
                  badge = {
                    STORY: 'Story',
                    IMAGE: 'Post',
                    CAROUSEL_ALBUM: 'Post Carousel',
                    VIDEO: 'Video',
                    REELS: 'Reel'
                  }[result.media_type];
                  image = result.thumbnail_url || result.media_url;
                  createdAt = result.timestamp;
                  title = result.caption;
                  isAlreadyLinkedToContract = contract && _.find(contract.media, m => m.mediaId === result.id);
                  url = result.permalink || result.media_url;
                } else if (isYoutube) {
                  badge = 'Video';
                  createdAt = result.publishedAt;
                  title = result.title;
                  isAlreadyLinkedToContract = contract && _.find(contract.media, m => m.mediaId === result.videoId);
                  url = `https://youtube.com/watch?v=${result.videoId}`;
                  image = result.thumbnails?.high?.url || getImageFromYoutubeUrl(url);
                } else if (isTiktok) {
                  badge = 'Video';
                  image = result.cover_image_url;
                  createdAt = result.create_time * 1000;
                  title = result.title;
                  isAlreadyLinkedToContract = contract && _.find(contract.media, m => m.mediaId === result.id);
                  url = result.embed_link;
                }
                const preview = () => window.open(url, '_blank');
                return (
                  <div key={result.id + createdAt} className='result-wrapper'>
                    <div className='result'>
                      <div className='body'>
                        <div className='image-container'>{image ? <img src={image} alt={title} /> : <div className='empty-img' />}</div>
                        <div className='main'>
                          <div className='header'>
                            <div className='type-badge'>{badge}</div>
                            <div onClick={preview} target='_blank' rel='noopener noreferrer' className='link'>
                              <FontAwesomeIcon icon={faExternalLink} />
                            </div>
                          </div>
                          <div className='title'>{title}</div>
                          <div className='date'>{moment(createdAt).format('MMMM Do, YYYY')}</div>
                        </div>
                      </div>
                      <div className='link-actions'>
                        {isAlreadyLinkedToContract ? (
                          <div className='link-action active'>Linked</div>
                        ) : (
                          <div
                            onClick={() =>
                              this.props.selectResult(
                                {
                                  ...result,
                                  image,
                                  title,
                                  url,
                                  createdAt
                                },
                                type
                              )
                            }
                            className='link-action'
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            ADD
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {isSearching && <Loader />}
            </div>
          ) : (
            <div className='empty-msg'>
              We could not find any recent content, if you expect to see results, please unlink and relink your account in account settings.
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {})(SearchMediaModal);
