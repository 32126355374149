import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToBottom } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import 'react-dates/initialize'; // React picker
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './LinkControls.scss';

import { getBrandRetailers } from '../../APIClient/brands';
import { getDiscoverListsForBrand } from '../../APIClient/discover';

import Spotlight from '../Spotlight/Spotlight';
import SelectOption from '../General/SelectOption';
import ShopMySelect from '../General/Select';
import InputActions from '../General/InputActions';

import { getGiftingRequestsForLookbookId, getGiftingRequestsForLookbook, getOpportunityRequestsForOpportunity } from '../../Helpers/brand_helpers';
import { isBrand, getLookbooks, getOpportunities, getBrandLists, getBrand } from '../../Helpers/user_helpers';
import { blockOnRequiringSubscription, isSubscribedToFeatureOrAdmin } from '../../Helpers/subscription_helpers';
import { getBrandDomain } from '../../Helpers/brand_helpers';
import { spotlightKeys } from '../../Helpers/spotlight_helpers';

const LinkControls = props => {
  const {
    user,
    analytics,
    searchVal,
    updateSearchVal,
    showSpecificRetailerDomains,
    updateDates,
    toggleAddingMode,
    setGroupByMode,
    isSuperAdminMode,
    groupByMode,
    selectedList,
    startDate,
    endDate
  } = props;

  const [downloading, setDownloading] = React.useState(false);
  const [focusedInput, setFocusedInput] = React.useState(null);

  const download = async () => {
    setDownloading(true);
    await props.downloadCsv();
    setDownloading(false);
  };
  const showLists = isBrand(user);

  const tabs = (isBrand(user)
    ? [
        { key: 'users', label: 'by Promoters' },
        { key: 'mentions', label: 'by Mentions' },
        { key: 'orders', label: 'by Orders' },
        { key: 'products', label: 'by Products' }
      ]
    : [
        { key: 'mentions', label: 'My Links' },
        { key: 'domains', label: 'Performance By Website', mobileLabel: 'By Website' },
        { key: 'creator-orders', label: 'All Orders' }
      ]
  ).map(tab => ({ ...tab, active: groupByMode === tab.key }));

  /* Allow filtering by lists. */
  const [discoverLists, setDiscoverLists] = React.useState([]);
  const lookbooks = getLookbooks(user);
  const opportunities = getOpportunities(user);
  const listOptions = [
    {
      label: 'Your Lists',
      options: [
        { value: null, label: 'All Promoters' },
        ...getBrandLists(user).map(list => ({
          label: list.title || 'Unknown',
          sublabel: `${list.users.length} creator${list.users.length === 1 ? '' : 's'}`,
          value: {
            BrandList_id: list.id, // Required for API call
            ...list
          }
        }))
      ]
    },
    ...(!discoverLists.length
      ? []
      : [
          {
            label: 'Curated By ShopMy',
            options: discoverLists.map(list => ({
              label: list.title || 'Unknown',
              sublabel: `${list.num_users} creator${list.num_users === 1 ? '' : 's'}`,
              value: {
                DiscoverList_id: list.id, // Required for API call
                ...list
              }
            }))
          }
        ]),
    ...(!opportunities?.length
      ? []
      : [
          {
            label: 'Opportunities',
            options: opportunities.map(opportunity => {
              const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
              const acceptedRequests = _.filter(requests, 'userAccepted');
              return {
                label: opportunity.title || 'Unknown',
                sublabels: [`${acceptedRequests.length} requests accepted`],
                value: {
                  Opportunity_id: opportunity.id, // Required for API call
                  ...opportunity
                }
              };
            })
          }
        ]),
    ...(!lookbooks?.length
      ? []
      : [
          {
            label: 'Lookbooks',
            options: lookbooks.map(lookbook => {
              const requests = getGiftingRequestsForLookbook(analytics, lookbook);
              const acceptedRequests = _.filter(requests, 'userAccepted');
              return {
                label: lookbook.title || 'Unknown',
                sublabels: [`${acceptedRequests.length} requests accepted`],
                value: {
                  Lookbook_id: lookbook.id, // Required for API call
                  ...lookbook
                }
              };
            })
          }
        ])
  ];

  /* Allow filtering by retailers */
  const [retailers, setRetailers] = React.useState([]);
  const canSelectRetailer = isSubscribedToFeatureOrAdmin(user, 'CROSS_RETAILER_ANALYTICS');
  const retailersToShow = _.orderBy(retailers, 'promoter_count', 'desc');
  const isBrandRetailer = retailer => retailer.domain === getBrandDomain(user);
  const retailerOptions = [
    { value: null, label: 'Show All Retailers', isDisabled: !canSelectRetailer },
    ...retailersToShow.map(retailer => ({
      value: retailer.domain,
      label: `${retailer.name}`,
      isDisabled: !canSelectRetailer,
      sublabel: [
        isBrandRetailer(retailer) && `DTC`,
        retailer.domain,
        retailer.promoter_count && `${retailer.promoter_count} promoter${retailer.promoter_count === 1 ? '' : 's'}`
      ]
        .filter(a => a)
        .join(' • ')
    }))
  ];
  const changeRetailerOption = option => {
    props.setShowSpecificRetailerDomains(option ? [option] : []);
  };

  /* Allow filtering by lists */
  const changeSelectedListOrOtherFilter = newVal => {
    const { value } = newVal;

    if (value?.Lookbook_id && blockOnRequiringSubscription(user, 'LOOKBOOKS')) return null;
    else if (value?.Opportunity_id && blockOnRequiringSubscription(user, 'OPPORTUNITIES')) return null;
    else if (value?.DiscoverList_id && blockOnRequiringSubscription(user, 'DISCOVER_2.0')) return null;
    else if (value?.BrandList_id && blockOnRequiringSubscription(user, 'LISTS')) return null;

    if (value?.Lookbook_id) {
      // If we are switching to a lookbook, we want to set the start date to date the first lookbook request was sent
      const lookbookRequests = getGiftingRequestsForLookbookId(analytics, value.Lookbook_id);
      const acceptedRequests = _.filter(lookbookRequests, 'userAcceptedAt');
      const firstAcceptedAt = _.orderBy(acceptedRequests, 'userAcceptedAt')[0]?.userAcceptedAt;
      if (firstAcceptedAt) {
        window.ALERT.info(
          `Setting analytics start date to ${moment(firstAcceptedAt).format(
            'MMM Do, YYYY'
          )}, the first time a creator accepted a product from this lookbook.`
        );
        updateDates(moment(firstAcceptedAt), null);
      }
    } else if (value?.Opportunity_id) {
      // If we are switching to an opportunity, we want to set the start date to date the first opportunity request was sent
      const opportunity = opportunities.find(opportunity => opportunity.id === value.Opportunity_id);
      const trackingStartsInThePast = moment(opportunity.trackingStartsAt).isBefore(moment());
      const trackingEndsInThePast = moment(opportunity.trackingEndsAt).isBefore(moment());
      if (trackingStartsInThePast || trackingEndsInThePast) {
        window.ALERT.info(`Setting analytics date ranges to the tracking period of the opportunity.`);
        updateDates(
          trackingStartsInThePast ? moment(opportunity.trackingStartsAt) : null,
          trackingEndsInThePast ? moment(opportunity.trackingEndsAt) : null
        );
      }
    } else if (startDate && (selectedList?.Lookbook_id || selectedList?.Opportunity_id)) {
      // Uf we are switching away from an opportunity or lookbook, and the filters are the same as when we switched to it, we want to reset the dates
      updateDates(null, null);
    }
    props.selectList(value);
  };

  const activeValue = _.flatten(listOptions.map(({ options }) => options)).find(({ value }) => {
    if (!value) return !selectedList;
    else if (value.BrandList_id && selectedList?.BrandList_id) return value.BrandList_id === selectedList?.BrandList_id;
    else if (value.DiscoverList_id && selectedList?.DiscoverList_id) return value.DiscoverList_id === selectedList?.DiscoverList_id;
    else if (value.Lookbook_id && selectedList?.Lookbook_id) return value.Lookbook_id === selectedList?.Lookbook_id;
    return false;
  });

  const selectTab = newTab => {
    if (isBrand(user) && blockOnRequiringSubscription(user, 'ANALYTICS')) return null;
    setGroupByMode(newTab);
  };

  // Get data to power filtering
  const brand = getBrand(user);
  React.useEffect(() => {
    if (brand) {
      getDiscoverListsForBrand(brand).then(resp => setDiscoverLists(resp));
      getBrandRetailers(brand.id).then(retailers => setRetailers(retailers));
    }
  }, []);

  return (
    <div className='link-controls-outer-container'>
      <div className='tabs'>
        {tabs.map(({ key, label, mobileLabel, active }) => (
          <div key={key} onClick={() => groupByMode !== key && selectTab(key)} className={cn('tab-container', { active })}>
            <div className={cn('tab desktop-only', { active })}>{label}</div>
            <div className={cn('tab mobile-only', { active })}>{mobileLabel || label}</div>
          </div>
        ))}
      </div>
      <div className='link-controls-container'>
        <div className='main'>
          <div className='input-container has-actions'>
            <FontAwesomeIcon icon={faSearch} />
            <input
              placeholder={
                groupByMode === 'users'
                  ? 'Search promoters'
                  : groupByMode === 'orders'
                  ? 'Search orders'
                  : groupByMode === 'products'
                  ? 'Search products'
                  : 'Search your links'
              }
              onChange={({ target }) => updateSearchVal(target.value)}
              value={searchVal}
            />
            <InputActions searchVal={searchVal} onCancel={() => updateSearchVal('')} />
          </div>
        </div>
        <div className='secondary'>
          {showLists && (
            <div>
              <Select
                className='list-select'
                classNamePrefix='list-select'
                options={listOptions}
                value={activeValue}
                onChange={changeSelectedListOrOtherFilter}
                components={{ Option: SelectOption }}
              />
            </div>
          )}
          <div className='date-picker'>
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              displayFormat={startDate && moment().year() !== startDate.year() ? "MMM Do 'YY" : 'MMM Do'}
              onDatesChange={({ startDate, endDate }) => updateDates(startDate, endDate)}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              noBorder
              minimumNights={0}
              showClearDates
            />
          </div>
          <div onClick={download} className='download-csv-btn desktop-only'>
            {downloading ? (
              'DOWNLOADING...'
            ) : (
              <>
                <FontAwesomeIcon icon={faArrowAltToBottom} />
                DOWNLOAD
              </>
            )}
          </div>
          {!isBrand(user) && !isSuperAdminMode && (
            <Spotlight spotlightKey={spotlightKeys.CREATE_PRODUCT_LINK}>
              <div onClick={toggleAddingMode} className='new-link-btn'>
                <span className='desktop-only'>CREATE NEW LINK</span>
                <span className='mobile-only'>NEW LINK</span>
              </div>
            </Spotlight>
          )}
        </div>
        {isBrand(user) && (
          <div className='show-other-retailers-switch desktop-only'>
            <ShopMySelect
              isDark
              placeholder={canSelectRetailer ? 'Show All Retailers' : 'Show DTC Only'}
              className='list-select'
              classNamePrefix='list-select'
              options={retailerOptions}
              value={showSpecificRetailerDomains?.join(',') || null}
              onChangeValue={changeRetailerOption}
              components={{ Option: SelectOption }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

LinkControls.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  searchVal: PropTypes.string.isRequired,
  hideOtherRetailers: PropTypes.bool.isRequired,
  showSpecificRetailerDomains: PropTypes.array.isRequired,
  setShowSpecificRetailerDomains: PropTypes.func.isRequired,
  toggleShowOtherRetailersMode: PropTypes.func.isRequired,
  updateSearchVal: PropTypes.func.isRequired,
  downloadCsv: PropTypes.func.isRequired,
  toggleAddingMode: PropTypes.func.isRequired,
  updateDates: PropTypes.func.isRequired,
  isSuperAdminMode: PropTypes.bool.isRequired,
  groupByMode: PropTypes.string.isRequired,
  setGroupByMode: PropTypes.func.isRequired,
  selectList: PropTypes.func.isRequired,
  selectedList: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default LinkControls;
