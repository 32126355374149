import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import './BlogPostPreview.scss';

import { connect } from 'react-redux';
import { deleteBlogPost, setBlogPost, editBlogPost } from '../../Actions/BlogActions';

import { isContentRendered } from '../../Helpers/helpers';
// import { getTimeStampForServer } from '../../Helpers/formatting';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Image from '../General/Image';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Tooltip from '../General/Tooltip';

const BlogPostPreview = props => {
  const { post, size = 'small', deleteBlogPost, setBlogPost, resetSearch, ui } = props;
  const { title, tag, titleStub, coverImage, isHidden } = post;

  const canEdit = isContentRendered() && isAdminControlMode(ui);

  const deletePost = () => {
    if (!canEdit) return window.ALERT.error('Only Admins are allowed to delete blog posts');

    confirmAlert({
      title: 'Just confirming',
      message: 'Are you sure you want to delete this blog post?',
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: async () => {
            const response = deleteBlogPost(post);
            if (response.error) window.ALERT.error('Error deleting post');
            else window.ALERT.success('Post deleted successfully');
          }
        }
      ]
    });
  };

  const updatePostVisibility = async () => {
    if (isHidden) {
      confirmAlert({
        title: 'Publish Post?',
        message: `Are you sure you want to publish this post? By doing so, all parts of this post will be immediately viewable to the public.`,
        buttons: [
          { label: 'No', className: 'cancel', onClick: () => {} },
          { label: 'Yes', onClick: () => props.editBlogPost(post, { isHidden: false, publishedAt: moment().format('YYYY-MM-DD') }) }
        ]
      });
    } else {
      props.editBlogPost(post, { isHidden: true });
    }
  };

  return (
    <Link
      className={cn('blog-post-preview-outer', { large: size === 'large', isHidden })}
      to={`/blog/post/${titleStub}`}
      onClick={() => {
        resetSearch();
        setBlogPost(post);
      }}
    >
      <div className='blog-post-preview-inner'>
        {canEdit && (
          <div
            className='blog-post-icon-container'
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Tooltip message={isHidden ? 'Publish Post' : 'Hide Post'}>
              <FontAwesomeIcon icon={isHidden ? faEyeSlash : faEye} onClick={updatePostVisibility} />
            </Tooltip>
            <Tooltip message='Delete Post'>
              <FontAwesomeIcon icon={faTrash} onClick={deletePost} />
            </Tooltip>
          </div>
        )}
        <Image alt='preview-cover-image' className='blog-post-preview-image' src={coverImage} />
        <div className='blog-post-preview-text'>
          <div className='blog-post-preview-tag'>
            <span>{tag || 'General'}</span>
            <span className='line'></span>
          </div>
          <div className='blog-post-preview-title'>{title}</div>
        </div>
      </div>
    </Link>
  );
};

BlogPostPreview.propTypes = {
  post: PropTypes.object.isRequired,
  size: PropTypes.string,

  // redux
  deleteBlogPost: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog, ui } = state;
  return { blog, ui };
};

export default connect(mapStateToProps, {
  deleteBlogPost,
  setBlogPost,
  editBlogPost
})(BlogPostPreview);
