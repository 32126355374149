import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classnames from 'classnames';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import './AddPinModalImageSelection.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

import Loader from '../../Loader/Loader';

import { getName } from '../../../Helpers/user_helpers';
import { cleanAlgoliaOtherImageData } from '../../../Helpers/sibling_helpers';
import { getSmartImage } from '../../../Helpers/helpers';

import { getAlternativeImages, getExistingPinImages } from '../../../APIClient/pins';

class AddPinModalImageSelection extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    updateData: PropTypes.func.isRequired,
    inEditMode: PropTypes.bool.isRequired,
    productMatch: PropTypes.object,
    variantMatch: PropTypes.object,
    variantMatches: PropTypes.array,
    pinMatches: PropTypes.array
  };

  componentDidMount() {
    this.getGoogleImagesDebounce = AwesomeDebouncePromise(async value => {
      value.length < 3
        ? this.setState({ googleImages: [], fetchingGoogleImages: false })
        : this.state.wantsToSearchGoogleImages && this.fetchGoogleImages(value);
    }, 1200);
    this.fetchPinImages();
  }

  fetchGoogleImages = searchTerm => {
    this.setState({
      fetchingGoogleImages: true,
      wantsToSearchGoogleImages: true
    });
    getAlternativeImages(searchTerm).then(
      ({ images, error }) => {
        this.setState({
          googleImages: images || [],
          fetchingGoogleImages: false,
          errorFetchingGoogleImages: !!error
        });
      },
      err => {
        this.setState({
          googleImages: [],
          fetchingGoogleImages: false,
          errorFetchingGoogleImages: true
        });
      }
    );
  };

  fetchPinImages = () => {
    const { product } = this.props;
    getExistingPinImages(product.link).then(
      ({ pins, error }) => {
        const { all_images, all_images_augmented } = product;
        const relevantPins = _.filter(
          _.uniqBy(pins, pin => `${pin.image}${pin.name}`),
          pin => !_.includes([...all_images, ...all_images_augmented], pin.image)
        );
        this.setState({ existingPinImages: relevantPins });
      },
      err => {
        this.setState({ existingPinImages: [] });
      }
    );
  };

  getCurrentTitle = () => this.props.product.title;

  updateSearch = ({ target }) => {
    this.setState({
      fetchingGoogleImages: this.state.wantsToSearchGoogleImages ? true : false,
      imageSearchVal: target.value
    });
    this.getGoogleImagesDebounce(target.value);
  };

  getImagesWithMetadata = () => {
    const { all_images, all_images_augmented } = this.props.product;

    const hasSiblings = !!all_images_augmented.length;
    return hasSiblings
      ? cleanAlgoliaOtherImageData(
          _.map(all_images_augmented, data => ({
            ...data,
            image_url: getSmartImage(data.image_url, false)
          }))
        )
      : _.map(all_images, image => ({ image_url: getSmartImage(image) }));
  };

  getWarning = () => {
    /* We used to use this when we showed siblings without images, we are now hiding that case.*/
    return null;
  };

  state = {
    imageSearchVal: this.getCurrentTitle(),
    googleImages: [],
    existingPinImages: [],
    fetchingGoogleImages: false,
    errorFetchingGoogleImages: false,
    wantsToSearchGoogleImages: false
  };

  render() {
    const { product, user, inEditMode, productMatch, variantMatch, variantMatches, pinMatches } = this.props;
    const {
      imageSearchVal,
      googleImages,
      existingPinImages,
      fetchingGoogleImages,
      errorFetchingGoogleImages,
      wantsToSearchGoogleImages
    } = this.state;
    const images_with_metadata = this.getImagesWithMetadata();
    const alternativeVariants = _.filter(variantMatches, match => !variantMatch || match.id !== variantMatch.id);

    const productPinMatchInsiderImages = _.filter(pinMatches, pinUser => pinUser.isInsider);
    const pinImages = _.uniqBy(_.concat(productMatch || [], productPinMatchInsiderImages, existingPinImages || []), 'name');

    const hasPinImages = _.get(pinImages, 'length', 0) > 0;
    const hasAlternativeVariantImages = _.get(alternativeVariants, 'length', 0) > 0;
    const hasImages = _.get(images_with_metadata, 'length', 0) > 0;
    const warning = this.getWarning();
    return (
      <div className='add-pin-modal-image-selection-outer-container'>
        <div className='image-selection-header'>SELECT AN IMAGE</div>
        {hasImages && (
          <div className='image-selection-initial-set-container'>
            {!inEditMode && (
              <div className='image-set-header-container'>
                <div className='image-set-header'>
                  {images_with_metadata.length > 1 ? "If you'd like, select a more specific option:" : 'Select an image from this site:'}
                </div>
              </div>
            )}
            {warning && (
              <div className='image-set-header-warning'>
                <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>
                <div className='image-set-warning'>{warning}</div>
              </div>
            )}

            <div className='initial-set-options'>
              {_.map(images_with_metadata, ({ image_url, tag, page_url }, idx) => (
                <div
                  key={image_url + (tag || idx)}
                  className={classnames('image-set-option', {
                    selected: product.image === image_url
                  })}
                  onClick={() => {
                    let newTitleBase;

                    if (product.brand) {
                      // Remove brand to add new ending
                      newTitleBase = !tag ? product.title : _.first(_.last(product.title.split(' | ')).split(' - '));
                    } else {
                      // Remove base to add new ending
                      newTitleBase = !tag ? product.title : _.first(product.title.split(' - '));
                    }

                    this.props.updateData({
                      ...(page_url ? { link: page_url } : {}),
                      ...(!image_url.includes('data:') ? { image: image_url } : {}), // Images should not be base encoded
                      ...(tag
                        ? {
                            title: product?.brand ? `${product.brand.toUpperCase()} | ${newTitleBase} - ${tag}` : `${newTitleBase} - ${tag}`
                          }
                        : {})
                    });
                  }}
                >
                  <img alt='Site Option' src={image_url} />
                  <div className='image-tag'>{tag}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {hasPinImages && (
          <div className='image-selection-initial-set-container'>
            <div className='image-set-header-container'>
              <div className='image-set-header'>
                {hasImages ? 'Or use' : 'Use'} an image another {window.__IS_PRO__ ? 'professional' : window.__IS_SMS__ ? 'artist' : 'creator'}{' '}
                selected for this product:
              </div>
            </div>
            <div className='initial-set-options'>
              {_.map(pinImages, ({ name, image }, idx) => (
                <div
                  key={image + name + idx}
                  className={classnames('image-set-option', {
                    selected: product.image === image
                  })}
                  onClick={() => this.props.updateData({ image: image })}
                >
                  <img alt='Alternative Pin Option' src={image} />
                  <div className='image-tag other-user'>Used by {getName(user) === name ? 'you' : name || 'ShopMy'}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {hasAlternativeVariantImages && (
          <div className='image-selection-initial-set-container'>
            <div className='image-set-header-container'>
              <div className='image-set-header'>{hasImages || hasPinImages ? 'Or use' : 'Use'} an image from another retailer:</div>
            </div>
            <div className='initial-set-options'>
              {_.map(alternativeVariants, ({ retailer, image }, idx) => (
                <div
                  key={image + retailer + idx}
                  className={classnames('image-set-option', {
                    selected: product.image === image
                  })}
                  onClick={() => this.props.updateData({ image: image })}
                >
                  <img alt='Alternative Pin Option' src={image} />
                  <div className='image-tag other-user'>From {retailer}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='image-selection-google-set-container'>
          <div className='image-set-header-container'>
            <div className='image-set-header'>
              {hasImages ? 'Or search other images' : "We couldn't extract an image from that site. Please search and select one here."}
            </div>
            <div className='image-set-subheader'>Powered by Google Images</div>
          </div>
          <div
            className={classnames('image-set-google-container', {
              enabled: wantsToSearchGoogleImages
            })}
          >
            <input value={imageSearchVal} type='text' placeholder='Search for an image' className='input' onChange={this.updateSearch} />
            {wantsToSearchGoogleImages ? (
              <div
                className={classnames('initial-set-options google', {
                  fetching: fetchingGoogleImages
                })}
              >
                {errorFetchingGoogleImages ? (
                  <div className='fetch-error-msg'>
                    Google limits the number of calls we can make per minute. Please try this search again in a few minutes.
                  </div>
                ) : (
                  _.map(googleImages, image => (
                    <div
                      key={image}
                      className={classnames('image-set-option', {
                        selected: product.image === image
                      })}
                    >
                      <img alt='Google Option' src={image} onClick={() => this.props.updateData({ image })} />
                    </div>
                  ))
                )}
                {fetchingGoogleImages && <Loader />}
              </div>
            ) : (
              <button className='start-google-image-search' onClick={() => this.fetchGoogleImages(this.getCurrentTitle())}>
                Search
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default AddPinModalImageSelection;
