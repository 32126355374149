import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import cn from 'classnames';
import './HeroFeatures.scss';

import DiscoverFeatureActions from './DiscoverFeatureActions';
import SortableList from '../../General/SortableList';

const HeroFeatures = props => {
  const { features } = props;
  const [activeFeatureIndex, setActiveFeatureIndex] = React.useState(0);

  const visibleFeatures = features.length ? (props.isEditing ? features : [features[activeFeatureIndex]]) : [];

  const getCard = (feature, additionalProps = {}) => (
    <HeroFeature key={feature.list.id} feature={feature} additionalProps={additionalProps} {...props} />
  );
  const goLeft = () => {
    setActiveFeatureIndex(_.max([activeFeatureIndex - 1, 0]));
  };
  const goRight = () => {
    setActiveFeatureIndex(_.min([activeFeatureIndex + 1, features.length - 1]));
  };
  const canGoLeft = activeFeatureIndex > 0;
  const canGoRight = activeFeatureIndex < features.length - 1;

  if (!visibleFeatures?.length) return null;
  return (
    <div className='hero-features-wrapper'>
      <SortableList
        isEditing={props.isEditing}
        containerClassName={cn('hero-features', { editing: props.isEditing })}
        items={visibleFeatures}
        updateItem={props.updateDiscoverFeature}
        props={props}
        getCard={getCard}
        useSortableHandleDefault
      />
      {!props.isEditing && (
        <div className='nav'>
          <div className={cn('nav-icn left', { visible: canGoLeft })}>
            <FontAwesomeIcon icon={faChevronLeft} onClick={goLeft} />
          </div>
          <div className={cn('nav-icn right', { visible: canGoRight })}>
            <FontAwesomeIcon icon={faChevronRight} onClick={goRight} />
          </div>
        </div>
      )}
      {!props.isEditing && features.length > 1 && (
        <div className='dots'>
          {features.map((feature, index) => {
            const active = index === activeFeatureIndex;
            const click = () => setActiveFeatureIndex(index);
            return (
              <div key={feature.id} onClick={click} className='dot-wrapper'>
                <div className={cn('dot', { active })}></div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const HeroFeature = props => {
  const { feature, additionalProps } = props;
  const { list, preview_users } = feature;
  const { title, description } = list;
  const { rearrangeHandle } = additionalProps;
  const valid_preview_users = preview_users?.filter(user => user.image);
  const num_valid_preview_users = valid_preview_users?.length;

  const [curFeature, setCurFeature] = React.useState(0);

  const [frontImages, setFrontImages] = React.useState([null, null, null]);
  const [backImages, setBackImages] = React.useState([null, null, null]);
  const [frontImagesVisible, setFrontImagesVisible] = React.useState(true);
  const [backImagesVisible, setBackImagesVisible] = React.useState(false);

  const DELAY_BETWEEN_TRANSITIONS = 5000;
  const wait = ms => new Promise(r => setTimeout(r, ms));
  const cycleImages = async () => {
    (async () => {
      setFrontImages([
        valid_preview_users[curFeature].image,
        valid_preview_users[(curFeature + 1) % num_valid_preview_users].image,
        valid_preview_users[(curFeature + 2) % num_valid_preview_users].image
      ]);
      await wait(100);
      setFrontImagesVisible(true);
      await wait(DELAY_BETWEEN_TRANSITIONS);
      setFrontImagesVisible(false);
      await wait(100);
      await wait(DELAY_BETWEEN_TRANSITIONS);
      setCurFeature((curFeature + 1) % num_valid_preview_users);
    })();

    (async () => {
      await wait(100);
      setBackImagesVisible(false);
      await wait(DELAY_BETWEEN_TRANSITIONS);
      setBackImages([
        valid_preview_users[(curFeature + 2) % num_valid_preview_users].image,
        valid_preview_users[(curFeature + 3) % num_valid_preview_users].image,
        valid_preview_users[(curFeature + 4) % num_valid_preview_users].image
      ]);
      await wait(100); //Let image load just a touch
      setBackImagesVisible(true);
      await wait(DELAY_BETWEEN_TRANSITIONS);
    })();
  };

  const hasFeatures = valid_preview_users?.length > 1;
  React.useEffect(() => {
    hasFeatures && cycleImages();
  }, [hasFeatures, curFeature]);

  return (
    <div className={cn('hero-feature', { hidden: feature.isHidden })}>
      <div className='main'>
        <div className='title'>{title}</div>
        {description && <div className='description'>{description}</div>}
        <div onClick={() => props.goToList(list)} className='view-btn'>
          View The List
        </div>
        {rearrangeHandle}
      </div>
      <div className='secondary'>
        {valid_preview_users ? (
          <>
            {num_valid_preview_users >= 2 && (
              <>
                <div className={cn('image-container first front', { visible: frontImagesVisible })}>
                  {frontImages[0] && <img src={frontImages[0]} className='image' alt={'Talent One'} />}
                </div>

                <div className={cn('image-container first back', { visible: backImagesVisible })}>
                  {backImages[0] && <img src={backImages[0]} className='image' alt={'Talent Two'} />}
                </div>
              </>
            )}
            {num_valid_preview_users >= 3 && (
              <>
                <div className={cn('image-container second front', { visible: frontImagesVisible })}>
                  {frontImages[1] && <img src={frontImages[1]} className='image' alt={'Talent One'} />}
                </div>

                <div className={cn('image-container second back', { visible: backImagesVisible })}>
                  {backImages[1] && <img src={backImages[1]} className='image' alt={'Talent Two'} />}
                </div>
              </>
            )}
            {num_valid_preview_users >= 4 && (
              <>
                <div className={cn('image-container third front', { visible: frontImagesVisible })}>
                  {frontImages[2] && <img src={frontImages[2]} className='image' alt={'Talent One'} />}
                </div>

                <div className={cn('image-container third back', { visible: backImagesVisible })}>
                  {backImages[2] && <img src={backImages[2]} className='image' alt={'Talent Two'} />}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className='image-container empty first' />
            <div className='image-container empty second' />
            <div className='image-container empty third' />
          </>
        )}
      </div>
      {props.isEditing && (
        <DiscoverFeatureActions
          feature={feature}
          deleteDiscoverFeature={props.deleteDiscoverFeature}
          updateDiscoverFeature={props.updateDiscoverFeature}
        />
      )}
    </div>
  );
};

HeroFeatures.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  deleteDiscoverFeature: PropTypes.func.isRequired,
  updateDiscoverFeature: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired
};

export default HeroFeatures;
