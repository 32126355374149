import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './PartnerPortalControls.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';

import { isOpportunityRequestOutstanding } from '../../Helpers/opportunity_helpers';
import { getCodes, getRequests, getOpportunityRequests } from '../../Helpers/user_helpers';
import * as giftingHelpers from '../../Helpers/gifting_helpers';

const PartnerPortalControls = props => {
  const { user, tab, changeTab, selectedFilter, setSelectedFilter, curSearchVal, setCurSearchVal, filterOptions } = props;

  // Get
  const requests = getRequests(user);
  const codes = getCodes(user);
  const opportunityRequests = getOpportunityRequests(user);

  // Get new codes in the last 3 days
  const newCodes = codes.filter(c => c.created_at > Date.now() - 3 * 24 * 60 * 60 * 1000);
  const openRequests = requests.filter(giftingHelpers.isRequestOutstanding);
  const newOpportunities = opportunityRequests.filter(isOpportunityRequestOutstanding);

  const [openFilter, setOpenFilter] = useState(false);
  const currentFilter = _.find(filterOptions, { label: selectedFilter?.label }) || filterOptions[0];

  const changeFilter = option => {
    setCurSearchVal('');
    setSelectedFilter(option);
    setOpenFilter(false);
  };

  const resetFilters = e => {
    e.stopPropagation();
    changeFilter(filterOptions[0]);
  };

  const changeSearchVal = e => {
    setSelectedFilter(null);
    setCurSearchVal(e.target.value);
  };

  const tabs = [
    { display: 'Gifting', key: 'gifting', count: requests.length, newCount: openRequests.length },
    { display: 'Discount Codes', key: 'codes', count: codes.length, newCount: newCodes.length },
    { display: 'Opportunities', key: 'opportunities', count: opportunityRequests.length, newCount: newOpportunities.length }
  ];

  return (
    <div className='partner-portal-controls-outer'>
      <div className='partner-portal-controls-inner'>
        <div className='control-bar-section tabs'>
          {tabs.map(({ display, key, newCount, count }) => (
            <div key={display} className={cn('tab-container', { active: tab === key })} onClick={() => changeTab(key)}>
              <div className='tab'>
                {display}
                {newCount ? <span className='count new'>{newCount} NEW</span> : count ? <span className='count'>{count}</span> : null}
              </div>
            </div>
          ))}
        </div>
        {!_.every(filterOptions, { count: 0 }) && (
          <div className='control-bar-section' id='search-and-filter'>
            {tab === 'gifting' && (
              <OutsideClickHandler onOutsideClick={() => setOpenFilter(false)}>
                <div className='filter-container'>
                  <div className='filter-label' onClick={() => setOpenFilter(!openFilter)}>
                    {currentFilter?.label !== 'Show All' && <FontAwesomeIcon onClick={resetFilters} icon={faTimesCircle} />}
                    {currentFilter?.label !== 'Show All' ? currentFilter?.label : <>Filter Status&nbsp;&nbsp;&nbsp;&nbsp;+</>}
                  </div>
                  {openFilter && (
                    <div className='filter-dropdown'>
                      {filterOptions.map(option => {
                        const { label, count } = option;

                        if (!count) return null;
                        return (
                          <div
                            className={cn('filter-option', {
                              active: _.isEqual(option.label, selectedFilter?.label) || (!selectedFilter?.label && label === 'Show All')
                            })}
                            key={label}
                            onClick={() => changeFilter(option)}
                          >
                            {option.label} ({option.count})
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            )}
            <div className='search-container'>
              <input type='text' value={curSearchVal} className='gifting-search-input' onChange={changeSearchVal} placeholder='Search' />
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PartnerPortalControls.propTypes = {
  tab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,

  curSearchVal: PropTypes.string.isRequired,
  setCurSearchVal: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object,
  setSelectedFilter: PropTypes.func.isRequired,
  filterOptions: PropTypes.array.isRequired
};

export default PartnerPortalControls;
