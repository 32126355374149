import React, { useState } from 'react';

import cn from 'classnames';
import './ArtistModalSimilarTalentResult.scss';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faHeart as faHeartSolid, faGift } from '@fortawesome/pro-solid-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';

import TalentResultStats from '../../Talent/Elements/TalentResultStats';
import BrandListsOverlay from '../../General/BrandListsOverlay';
import Tooltip from '../../General/Tooltip';

import { getSmartImage, portalBugOpenUrl } from '../../../Helpers/helpers';
import { getSocialIconForType } from '../../../Helpers/social_helpers';
import { getPrettyNumber, getInitialsForUser, getPrettyDate } from '../../../Helpers/formatting';
import { getAllBrandListsFoundIn, getGiftingRequestsForUser } from '../../../Helpers/brand_helpers';
import { blockOnRequiringSubscription } from '../../../Helpers/subscription_helpers';

const ArtistModalSimilarTalentResult = props => {
  const { talent, user, analytics, ui, index, setModalUser, isDetailedAnalytics } = props;
  const { isAdminControlMode } = ui;
  const { social_links, image, name, instagramCount, youtubeCount, tiktokCount, description = '' } = talent;
  const giftingRequests = getGiftingRequestsForUser(analytics, talent);
  const mostRecentGiftingRequest = _.orderBy(giftingRequests, 'createdAt', 'desc')[0];
  const hasGifted = !!mostRecentGiftingRequest;

  const [isSelectingBrandList, setIsSelectingBrandList] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleShowingFullDescription = () => setShowFullDescription(!showFullDescription);

  const instagramUrl = social_links?.split(',').find(url => url.includes('instagram'));
  const tiktokUrl = social_links?.split(',').find(url => url.includes('tiktok'));
  const youtubeUrl = social_links?.split(',').find(url => url.includes('youtube'));

  const inBrandLists = getAllBrandListsFoundIn(user, talent, { hideRecommendationLists: !isAdminControlMode });
  const isInList = !!inBrandLists.length;

  const updateModalToNewUser = () => {
    window.__ADD_EVENT__('Talent Card - Select Similar User', {
      similar_user_name: talent.name,
      similar_user_username: talent.username,
      user
    });
    setModalUser(talent);
  };

  const extraClasses = { simple: !isDetailedAnalytics };

  const isBlockedByPackage = index > 3 && props.onlyShowPreviewOfFeature;
  const blockedAlert = () => blockOnRequiringSubscription(user, 'LOOKALIKES');

  return (
    <div className={cn('recommended-talent-card', { blocked: isBlockedByPackage })} key={`talent-${talent.id}`}>
      {isBlockedByPackage && <div onClick={blockedAlert} className='blocked-by-package-cover' />}

      <div className={cn('image-container', !isDetailedAnalytics && 'big')}>
        {image ? <img alt={name} src={getSmartImage(image)} /> : <div className='empty-img'>{getInitialsForUser(talent)}</div>}
        <div className='actions'>
          {hasGifted && (
            <Tooltip message={`Last sent gifting on ${getPrettyDate(mostRecentGiftingRequest.createdAt)}`}>
              <div className='action'>
                <FontAwesomeIcon icon={faGift} />
              </div>
            </Tooltip>
          )}
          <BrandListsOverlay closeOverlay={() => setIsSelectingBrandList(false)} selectedUser_id={talent.id} isActive={isSelectingBrandList}>
            <div onClick={() => setIsSelectingBrandList(true)} className={cn('select-list-btn action', { active: isInList })}>
              {isInList ? <FontAwesomeIcon icon={faHeartSolid} /> : <FontAwesomeIcon icon={faHeart} />}
            </div>
          </BrandListsOverlay>
        </div>
      </div>
      <div className='body'>
        <div className='body-inner'>
          <div className='main'>
            <div className={'content'}>
              <div className={cn('info', extraClasses)}>
                <div onClick={updateModalToNewUser} className='name'>
                  {name}
                </div>
                <div onClick={updateModalToNewUser} className='open-card-btn'>
                  View Talent Card
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                {!isDetailedAnalytics && (
                  <div onClick={toggleShowingFullDescription} className={cn('description', { full: showFullDescription })}>
                    {description}
                  </div>
                )}
                {!!(instagramCount || youtubeCount || tiktokCount) && (
                  <div className='social-counts section'>
                    {!!instagramCount && (
                      <a onClick={portalBugOpenUrl} href={instagramUrl} className='social-count'>
                        <img alt={'Instagram Logo'} src={getSocialIconForType('instagram', 'dark')} />
                        <span className='count'>{getPrettyNumber(instagramCount)}</span>
                      </a>
                    )}
                    {!!youtubeCount && (
                      <a onClick={portalBugOpenUrl} href={youtubeUrl} className='social-count'>
                        <img alt={'Youtube Logo'} src={getSocialIconForType('youtube', 'dark')} />
                        <span className='count'>{getPrettyNumber(youtubeCount)}</span>
                      </a>
                    )}
                    {!!tiktokCount && (
                      <a onClick={portalBugOpenUrl} href={tiktokUrl} className='social-count'>
                        <img alt={'Tiktok Logo'} src={getSocialIconForType('tiktok', 'dark')} />
                        <span className='count'>{getPrettyNumber(tiktokCount)}</span>
                      </a>
                    )}
                  </div>
                )}
              </div>
              {isDetailedAnalytics && (
                <div className='stats'>
                  <TalentResultStats result={talent} user={user} ui={props.ui} rows={2} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ArtistModalSimilarTalentResult.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  talent: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onlyShowPreviewOfFeature: PropTypes.bool.isRequired,
  setModalUser: PropTypes.func.isRequired,
  isDetailedAnalytics: PropTypes.bool.isRequired
};

export default ArtistModalSimilarTalentResult;
