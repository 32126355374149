import React from 'react';
import PropTypes from 'prop-types';
import './UserLookbookOrderDetailsItems.scss';
import UserLookbookOrderDetailsItem from './UserLookbookOrderDetailsItem';

const UserLookbookOrderDetailsItems = props => {
  const { items, user, lookbook } = props;
  return (
    <div className='user-lookbook-order-details-items-outer'>
      <div className='user-lookbook-order-details-items-inner'>
        {items.map(item => (
          <UserLookbookOrderDetailsItem item={item} key={`UserLookbookOrderDetailsItem-${item.id}`} user={user} lookbook={lookbook} />
        ))}
      </div>
    </div>
  );
};

UserLookbookOrderDetailsItems.propTypes = {
  items: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object
};

export default UserLookbookOrderDetailsItems;
