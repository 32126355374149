import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './ContractProposeOverviewCard.scss';

import { enhanceHTML } from '../../../Helpers/formatting';
import { matchScrollHeight } from '../../../Helpers/helpers';
import { getRecentContracts } from '../../../APIClient/collaborations';

let debounce;
let descriptionRef;
const ContractProposeOverviewCard = props => {
  const { contract, canEdit, updateContract } = props;
  const { id, isCreatedByBrand } = contract;
  const [description, setDescription] = useState(contract.description || '');
  const [pastContracts, setPastContracts] = useState([] || '');
  const [showingPastContracts, setShowingPastContracts] = useState(false);
  const [numPastContractsVisible, setNumPastContractsVisible] = useState(2);
  const Brand_id = contract.brand.id;
  const User_id = contract.user.id;

  const updateDescription = newDescription => {
    setDescription(newDescription);
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      updateContract(contract, { description: newDescription });
    }, 1000);
  };

  useEffect(() => {
    canEdit &&
      getRecentContracts(isCreatedByBrand ? { Brand_id } : { User_id }).then(resp => {
        if (resp.contracts) {
          const contractsWithDescriptions = resp.contracts.filter(c => c.description && c.description.length > 10 && c.id !== id);
          const uniqueContracts = _.uniqBy(contractsWithDescriptions, c => c.description.trim());
          const sortedContracts = _.orderBy(uniqueContracts, 'createdAt', 'desc');
          setPastContracts(sortedContracts);
        }
      });
  }, [setPastContracts, isCreatedByBrand, canEdit, id, Brand_id, User_id]);

  const makeOverview = newDescription => {
    const confirmed = () => {
      updateDescription(newDescription);
      matchScrollHeight(descriptionRef);
      setShowingPastContracts(false);
    };
    const needsConfirmation = description !== newDescription && description?.length > 10;
    needsConfirmation
      ? confirmAlert({
          title: 'Just confirming',
          message: 'Are you sure you want to override your current overview?',
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            { label: 'Yes', onClick: confirmed }
          ]
        })
      : confirmed();
  };

  if (!canEdit && !description) return null;
  return (
    <>
      <div className='contract-propose-overview-card card'>
        <div className='card-header-container'>
          <div className='card-header'>Overview</div>
          {canEdit && (
            <div className='card-subheader'>
              {isCreatedByBrand
                ? `Describe what you would like to accomplish with this collaboration`
                : `Describe what you can offer in this collaboration`}
            </div>
          )}
        </div>
        <div className='card-body-container'>
          {canEdit ? (
            <textarea
              rows={4}
              value={description}
              className='overview'
              ref={ref => {
                matchScrollHeight(ref);
                descriptionRef = ref;
              }}
              placeholder={
                isCreatedByBrand
                  ? 'Describe in detail what you are asking for, please be as specific as possible.'
                  : `Describe in detail what you can do for ${contract.brand.name}. Please be as specific as possible and make sure to include: an introduction of yourself and your content, reason why you think you are a good fit for a collaboration with this brand, and a brief summary of the type and subject of the content you’d like to create for the brand.`
              }
              onChange={e => updateDescription(e.target.value)}
            />
          ) : (
            <div className='overview'>{enhanceHTML(description)}</div>
          )}
        </div>
      </div>
      {canEdit && !!pastContracts.length && (
        <div className='past-contracts-container'>
          <div onClick={() => setShowingPastContracts(!showingPastContracts)} className='past-contracts-container-header'>
            <div className='header'>Copy Overview From Recent Collaborations</div>
            {<FontAwesomeIcon icon={showingPastContracts ? faChevronUp : faChevronDown} />}
          </div>
          {showingPastContracts && (
            <div className='past-contracts'>
              {pastContracts.slice(0, numPastContractsVisible).map(contract => {
                const active = contract.description === description;
                return (
                  <div key={contract.id} className='past-contract'>
                    <div className='main'>
                      <div className='title'>
                        From {contract.title}
                        <a href={`/collaboration/${contract.id}`} target='_blank' rel='noopener noreferrer'>
                          {<FontAwesomeIcon icon={faExternalLink} />}
                        </a>
                      </div>
                      <div className='description'>{contract.description}</div>
                    </div>
                    <div onClick={() => makeOverview(contract.description)} className={cn('copy-btn', { active })}>
                      {!active && <FontAwesomeIcon icon={faCopy} />}
                      {active ? 'Current Overview' : 'Make Overview'}
                    </div>
                  </div>
                );
              })}
              {numPastContractsVisible < pastContracts.length && (
                <div onClick={() => setNumPastContractsVisible(numPastContractsVisible + 5)} className='show-more'>
                  Show More
                </div>
              )}
              {numPastContractsVisible >= pastContracts.length && numPastContractsVisible > 5 && (
                <div onClick={() => setNumPastContractsVisible(numPastContractsVisible - 5)} className='show-less'>
                  Show Less
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

ContractProposeOverviewCard.propTypes = {
  contract: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,

  // For editing
  updateContract: PropTypes.func
};

export default ContractProposeOverviewCard;
