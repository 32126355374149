import { useEffect } from 'react';
// import { connect } from 'react-redux'
import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';

import { isSimulatingUser, getUserId } from '../../Helpers/user_helpers';
import { ADD_MESSAGE, EDIT_MESSAGE, DELETE_MESSAGE, TYPING_MESSAGE, STATUS_UPDATE } from '../../Actions/ChatActions';

let MAX_RECONNECT_ATTEMPTS = 15;
const WEBSOCKET_UNMOUNT_CODE = 3001;
let wsGlobal;

const ChatConnect = props => {
  const { setWebsocket, user, receivedMessage, onEditMessage, onDeleteMessage, onTypingMessage, onStatusUpdate } = props;
  const { jwtIdToken } = user || {};

  const connectToWSS = (attempt = 0) => {
    console.info('Attempting connection to message server.');

    // This is to fix a bug with env variables, please investigate at some point
    const websocketURL = process.env.REACT_APP_WEBSOCKET_URL || 'wss://chat.shopmy.us';

    const ws = isSimulatingUser(user)
      ? new WebSocket(websocketURL + `?SimulatedUser_id=${getUserId(user)}`)
      : new WebSocket(websocketURL + `?token=${jwtIdToken}`);
    wsGlobal = ws;

    ws.onopen = () => {
      console.info('Connected to message server.');
      props.onConnect && props.onConnect();
    };
    ws.onclose = ({ code, reason }) => {
      // Try Reconnecting
      if (code === WEBSOCKET_UNMOUNT_CODE) {
        console.info(`Disconnected from chat due to dismount.`);
      } else {
        console.info(`Disconnected from message server: ${reason || 'Unknown Reason'} (${code})`);
        MAX_RECONNECT_ATTEMPTS > attempt && setTimeout(() => connectToWSS(attempt + 1), 1000);
      }
    };
    ws.onerror = () => {
      console.info(`Disconnected from message server ${attempt}/${MAX_RECONNECT_ATTEMPTS}.`);
      props.onFailedAttempt && props.onFailedAttempt();
      if (attempt === MAX_RECONNECT_ATTEMPTS) {
        props.onFail && props.onFail();
        cogoToast.error(`There was an error connecting to the chat server and your messages will not send, please refresh the page to use chat.`, {
          hideAfter: 10
        });
      }
    };
    ws.onmessage = handleSocketMessage;

    setWebsocket(ws);
  };

  const handleSocketMessage = socketMessage => {
    const parsedMessage = JSON.parse(socketMessage.data);

    switch (parsedMessage.event) {
      case STATUS_UPDATE:
        onStatusUpdate && onStatusUpdate(parsedMessage);
        break;
      case ADD_MESSAGE:
        receivedMessage(parsedMessage).then(({ data: { chat, message } }) => {
          props.onMessage && props.onMessage({ chat, message });
        });
        break;
      case EDIT_MESSAGE:
        onEditMessage && onEditMessage(parsedMessage);
        break;
      case DELETE_MESSAGE:
        onDeleteMessage && onDeleteMessage(parsedMessage);
        break;
      case TYPING_MESSAGE:
        onTypingMessage && onTypingMessage(parsedMessage);
        break;
      default:
        console.error('Unknown Socket Message Event: ', parsedMessage.event);
        break;
    }
  };

  useEffect(() => {
    connectToWSS();

    return () => {
      wsGlobal && wsGlobal.close(WEBSOCKET_UNMOUNT_CODE, 'Component Dismounted');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // On Mount Simulation
  return null;
};

ChatConnect.propTypes = {
  user: PropTypes.object.isRequired,
  setWebsocket: PropTypes.func.isRequired,
  receivedMessage: PropTypes.func.isRequired,

  // Optional Events
  onConnect: PropTypes.func,
  onFailedAttempt: PropTypes.func,
  onFail: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  onMessage: PropTypes.func,
  onEditMessage: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  onTypingMessage: PropTypes.func
};

export default ChatConnect;
