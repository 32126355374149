import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfirmPrompt from '../../General/ConfirmPrompt';
import './ContractTask.scss';

import { getDisplayForCollaborationType } from '../../../Helpers/collaboration_helpers';
import { isBrand } from '../../../Helpers/user_helpers';
import { getInitialsForUser, getInitialsForBrand } from '../../../Helpers/formatting';

const ContractTask = props => {
  const { contract, task, user } = props;
  const { acceptedDate, uploads } = contract;
  const {
    title,
    template,
    status,
    taskDuration,
    submissionDueDate,
    submissionDueDateDays,
    contentLiveDueDate,
    contentLiveDueDateDays,
    inReviewDate,
    approvedDate,
    activeDate,
    completeDate
  } = task;
  const { type, requiresApproval } = template;
  const liveDate = activeDate || completeDate;

  const userUploads = _.filter(uploads, upload => upload.uploadedByUser);

  const changeStatus = nextStatus => {
    const confirm = msg => {
      props.updateContractTask(task, {
        status: nextStatus,
        additionalMsg: msg
      });
    };
    const isHeadingToReview = nextStatus === 'inreview';
    const mightBeMissingContent = isHeadingToReview && !userUploads.length;
    nextStatus === 'pending'
      ? confirm()
      : confirmAlert({
          customUI: ({ onClose }) => (
            <ConfirmPrompt
              header={mightBeMissingContent ? 'Did you upload the content?' : 'Add a Message'}
              subheader={
                mightBeMissingContent
                  ? "It doesn't look like you uploaded any files to the Uploads tab, you can still continue onwards if you shared the conent in another manner or have agreed to skip this step."
                  : null
              }
              placeholder={isHeadingToReview ? 'Add any additional notes about your content here.' : 'Add any additional notes here'}
              onCancel={onClose}
              submitBtnDisplay={nextStatus === 'inreview' ? 'Submit For Review' : nextStatus === 'approved' ? 'Approve Content' : 'Submit'}
              onSubmit={msg => confirm(msg)}
            />
          )
        });
  };

  const isPendingComplete = requiresApproval ? !!inReviewDate : taskDuration ? !!activeDate : !!completeDate;
  const isApprovalComplete = !!approvedDate;
  const isLive = !!liveDate;
  const isComplete = !!completeDate;
  let phases = [
    {
      status: 'pending',
      required: true,
      complete: isPendingComplete,
      userDisplay: isPendingComplete
        ? requiresApproval
          ? 'Content Submitted For Approval'
          : 'Content Live'
        : requiresApproval
        ? 'Submit Content For Approval'
        : 'Go Live With Content',
      brandDisplay: isPendingComplete
        ? requiresApproval
          ? `Content Submitted By ${contract?.user?.name}`
          : 'Content Live'
        : requiresApproval
        ? 'Waiting On Content Submission'
        : 'Waiting On Content To Go Live',
      userActionDisplay: requiresApproval ? 'Submit For Approval' : 'Mark as Complete',
      brandActionDisplay: null,
      dateDisplay: isPendingComplete
        ? inReviewDate
          ? `Submitted on ${moment(inReviewDate).format('MMMM Do')}`
          : `Live on ${moment(activeDate || completeDate).format('MMMM Do')}`
        : requiresApproval
        ? submissionDueDate
          ? `Due ${moment(submissionDueDate).format('MMMM Do')}`
          : submissionDueDateDays
          ? `Due ${moment(acceptedDate)
              .add(submissionDueDateDays, 'days')
              .format('MMMM Do')}`
          : ''
        : null,
      brandStepLink: null,
      brandStepLinkDisplay: null,
      userStepLink: requiresApproval ? 'uploads' : isPendingComplete ? null : 'analytics',
      userStepLinkDisplay: requiresApproval ? (userUploads.length ? 'See Uploads' : 'Upload File') : isPendingComplete ? null : 'Link Content'
    },
    {
      status: 'inreview',
      required: requiresApproval,
      complete: isApprovalComplete,
      brandDisplay: isApprovalComplete ? 'Content Approved' : 'Approve Content',
      userDisplay: isApprovalComplete ? `${contract.brand.name} Approved Content` : 'Waiting On Content Approval',
      brandActionDisplay: 'Approve Content',
      userActionDisplay: null,
      dateDisplay: isApprovalComplete
        ? `Approved on ${moment(approvedDate).format('MMMM Do')}`
        : !isBrand(user)
        ? null
        : contentLiveDueDate
        ? `Due by ${moment(contentLiveDueDate).format('MMMM Do')}`
        : contentLiveDueDateDays
        ? `Due by ${moment(inReviewDate)
            .add(submissionDueDateDays, 'days')
            .format('MMMM Do')}`
        : '',
      brandStepLink: contract.uploads.length ? `uploads` : null,
      brandStepLinkDisplay: contract.uploads.length ? 'See Uploads' : null,
      userStepLink: null,
      userStepLinkDisplay: null
    },
    {
      status: 'approved',
      required: requiresApproval,
      complete: isLive,
      brandDisplay: isLive ? `${contract.user.name} Published Content` : 'Waiting On Content To Go Live',
      userDisplay: isLive ? 'Content Published' : 'Publish Content',
      brandActionDisplay: null,
      userActionDisplay: 'Mark as Complete',
      dateDisplay: isLive
        ? `Marked as live on ${moment(liveDate).format('MMMM Do')}`
        : contentLiveDueDate
        ? `Due by ${moment(contentLiveDueDate).format('MMMM Do')}`
        : contentLiveDueDateDays
        ? `Due by ${moment(inReviewDate)
            .add(submissionDueDateDays, 'days')
            .format('MMMM Do')}`
        : '',
      userStepLink: isLive ? null : 'analytics',
      userStepLinkDisplay: isLive ? null : 'Link Content'
    },
    {
      status: 'active',
      complete: isComplete,
      required: taskDuration,
      brandDisplay: liveDate
        ? `Content Live Until ${moment(liveDate)
            .add(taskDuration, 'days')
            .format('MMMM Do')}`
        : `Content Live For ${taskDuration} days`,
      userDisplay: liveDate
        ? `Content Live Until ${moment(liveDate)
            .add(taskDuration, 'days')
            .format('MMMM Do')}`
        : `Content Live For ${taskDuration} days`,
      brandActionDisplay: 'Mark As Done',
      userActionDisplay: null,
      dateDisplay: null
    }
  ];

  phases = phases.map((phase, idx) => ({
    ...phase,
    active: phase.status === status
  }));

  const requiredPhases = phases.filter(p => p.required);

  return (
    <div className='contract-task-container card'>
      <div className='contract-task-title-container'>
        <div onClick={() => changeStatus('pending')} className='contract-task-title'>
          {title}
        </div>
        <div className='contract-task-subtitle'>{getDisplayForCollaborationType(type)}</div>
      </div>
      <div className='phases'>
        {requiredPhases.map((phase, idx) => {
          const { complete, active, dateDisplay } = phase;
          const nextStatus = requiredPhases[idx + 1]?.status || 'complete';
          const upcoming = !complete && !active;

          const display = isBrand(user) ? phase.brandDisplay : phase.userDisplay;
          const actionDisplay = isBrand(user) ? phase.brandActionDisplay : phase.userActionDisplay;
          const stepLink = isBrand(user) ? phase.brandStepLink : phase.userStepLink;
          const stepLinkDisplay = isBrand(user) ? phase.brandStepLinkDisplay : phase.userStepLinkDisplay;
          const mine = !!actionDisplay;

          const classes = { complete, active, upcoming, mine };
          return (
            <div key={phase.status} className={cn('phase', classes)}>
              <div className='main'>
                <div className={cn('checkbox', classes)}>
                  {complete ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : phase.brandActionDisplay ? (
                    <div className='initials'>{getInitialsForBrand(contract.brand)}</div>
                  ) : contract.user.image ? (
                    <img src={contract.user.image} alt={contract.user.name} />
                  ) : (
                    <div className='initials'>{getInitialsForUser(contract.user)}</div>
                  )}
                </div>
                <div className={cn('content', classes)}>
                  <div className='title'>{display}</div>
                  {dateDisplay && <div className='date'>{dateDisplay}</div>}
                </div>
              </div>
              {active && !complete && actionDisplay && (
                <div className='actions'>
                  {stepLink && (
                    <div onClick={() => props.selectStepByType(stepLink)} className='action link'>
                      {stepLinkDisplay || `Go to ${stepLink}`}
                    </div>
                  )}
                  {actionDisplay && (
                    <div onClick={() => changeStatus(nextStatus)} className='action'>
                      {actionDisplay}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ContractTask.propTypes = {
  task: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  selectStepByType: PropTypes.func.isRequired,
  updateContractTask: PropTypes.func.isRequired
};

export default ContractTask;
