import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { syncCurrentUser, simulateUser } from '../../Actions/UserActions';
import { toggleAdminControlMode } from '../../Actions/UIActions';
import { isAdmin, getUsername } from '../../Helpers/user_helpers';
import { sendDataToApp } from '../../Helpers/mobile_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

import './SimulatedUserControl.scss';

const SimulatedUserControl = props => {
  const { user } = props;

  if (!isAdmin(user) && !user.simulatedUsername) return null;

  const changeUser = async () => {
    const previousUsername = user.simulatedUsername || getUsername(user);
    const username = window.prompt(`Enter a username`, user.simulatedUsername || '');
    if (!username) return;

    await props.simulateUser(username || null);
    sendDataToApp('UNIVERSAL_LOGIN', { username });

    try {
      await props.syncCurrentUser();
    } catch (error) {
      window.ALERT.error(`Invalid Username, try again`);
      await props.simulateUser(previousUsername);
      sendDataToApp('UNIVERSAL_LOGIN', { username: previousUsername });
    }
  };

  const toggleMode = () => {
    props.toggleAdminControlMode();
  };

  return (
    <div className={cn('simulated-user-control-outer-container')}>
      <div className='username' onClick={changeUser}>
        {user.simulatedUsername ? <div>Logged in as {user.simulatedUsername}</div> : <div>Simulate</div>}
      </div>
      <div className='mode-toggle' onClick={toggleMode}>
        {isAdminControlMode(props.ui) ? '✓' : '-'}
      </div>
    </div>
  );
};

SimulatedUserControl.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  simulateUser,
  syncCurrentUser,
  toggleAdminControlMode
})(SimulatedUserControl);
