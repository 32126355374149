import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faEyeSlash, faEye } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './InputActions.scss';

const InputActions = props => {
  /*
    Please make sure to add "has-actions" to the input wrapper container.

    Example:
      <div className='search-input-container has-actions'>
        <input
          className='class-input'
          placeholder='placeholder'
          onChange={({ target }) => updateSearchVal(target.value)}
          value={searchVal}
        />
        <InputActions searchVal={searchVal} onCancel={() => updateSearch('')} />
      </div>
  */
  const { searchVal, showSearchIcon } = props;
  const ref = useRef(null);

  const clickAction = () => {
    if (props.onToggleVisibility) {
      props.onToggleVisibility();
    } else if (props.searchVal) {
      props.onCancel();
    } else {
      // Focus on input
      const inputEl = ref.current.parentElement.querySelector('input');
      inputEl && inputEl.focus();
    }
  };

  let hidden;
  if (props.onCancel && !searchVal && !showSearchIcon) hidden = true;

  return (
    <div ref={ref} className={cn('input-actions-container', { hidden })}>
      <div onClick={clickAction} className='icn'>
        {props.onCancel && <FontAwesomeIcon icon={props.searchVal ? faTimes : faSearch} />}
        {props.onToggleVisibility && <FontAwesomeIcon icon={props.searchValHidden ? faEyeSlash : faEye} />}
      </div>
    </div>
  );
};

InputActions.propTypes = {
  // If you want to use it to cancel the field
  onCancel: PropTypes.func,
  searchVal: PropTypes.string,
  showSearchIcon: PropTypes.bool,

  // If you want to use it to toggle visibility
  onToggleVisibility: PropTypes.func,
  searchValHidden: PropTypes.bool
};

export default InputActions;
