import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import './BrandPartners.scss';
import './Basic.scss';

import { addEvent } from '../../APIClient/events';

class BrandPartners extends Component {
  componentDidMount() {
    addEvent('Brand Partners - Page View');
  }

  render() {
    return (
      <div className='contact-outer-container basic-outer-container'>
        <MetaTags>
          <title>Contact Us</title>
          <meta property='description' content='To get in touch with us, send an email to team@shopmy.us' />
        </MetaTags>
        <div className='basic-header-container narrow'>
          <div className='basic-header-title-badge-container'>
            <h1 className='basic-header-title-badge'>PARTNER WITH US</h1>
          </div>
          <h2 className='basic-header-body simple'>
            If you're a beauty or wellness brand interested in learning more about how to work with us and our network of talent, please email 
            <span className='email'>partners@shopmy.us</span>. We have a wide range of offerings that include gifting to our network, affiliate
            partnerships, and more.
          </h2>
        </div>
      </div>
    );
  }
}

export default BrandPartners;
