import { useEffect } from 'react';

const FacebookSDK = props => {
  useEffect(() => {
    if (window.fbAsyncInit) return; // Ensure we only load once
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: window.__IS_PROD__ ? '1845349495613114' : '519222783165320',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v19.0'
      });
    };
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    document.body.appendChild(script);
  }, []);
  return null;
};

export default FacebookSDK;
