import React from 'react';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import './OpportunityPlannerControls.scss';

import { getBudgetForPlan } from '../../../Helpers/planning_helpers';
import { getTimingOnOpportunity } from '../../../Helpers/opportunity_helpers';
import { getGiftingRequestsForLookbook, getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';
import { getLookbooks, getOpportunities, getBrandLists } from '../../../Helpers/user_helpers';

import InputActions from '../../General/InputActions';
import Select from '../../General/Select';

const OpportunityPlannerControls = props => {
  const { user, analytics, activePlan, planningStrategy, curSearchVal, curFilters } = props;
  const budget = getBudgetForPlan(activePlan);

  // Searching
  const changeSearch = newValue => {
    props.setCurSearchVal(newValue);
    props.setPage(0);
    props.setHasAllResults(false);
  };

  // Filtering
  const toggleFilterValue = value => {
    /*
      We allow filtering on opportunities, lists, and lookbooks.

      Filters look like: 
        {  
          lists: [{ id: 1, name: 'List 1' }, { id: 2, name: 'List 2' }],
          opportunities: [{ id: 1, name: 'Opportunity 1' }],
          lookbooks: [{ id: 1, name: 'Lookbook 1' }]
        }
    */
    let isActive = isFilterValueActive(value);
    const newLists = isActive ? _.filter(curFilters.lists, list => list.id !== value.list?.id) : _.filter(_.concat(curFilters.lists, value.list)); // prettier-ignore
    const newOpportunities = isActive ? _.filter(curFilters.opportunities, op => op.id !== value.opportunity?.id) : _.filter(_.concat(curFilters.opportunities, value.opportunity)); // prettier-ignore
    const newLookbooks = isActive ? _.filter(curFilters.lookbooks, lookbook => lookbook.id !== value.lookbook?.id) : _.filter(_.concat(curFilters.lookbooks, value.lookbook)); // prettier-ignore
    props.setCurFilters({
      lists: newLists,
      opportunities: newOpportunities,
      lookbooks: newLookbooks
    });
    props.setPage(0);
    props.setHasAllResults(false);
  };

  const isFilterValueActive = value => {
    let isActive = false;
    if (value.opportunity) isActive = _.includes(curFilters.opportunities?.map(op => op.id), value.opportunity.id); // prettier-ignore
    else if (value.list) isActive = _.includes(curFilters.lists?.map(list => list.id), value.list.id); // prettier-ignore
    else if (value.lookbook) isActive = _.includes(curFilters.lookbooks?.map(lookbook => lookbook.id), value.lookbook.id); // prettier-ignore
    return isActive;
  };

  const lookbooks = _.orderBy(getLookbooks(user), 'createdAt', 'desc');
  const opportunities = _.orderBy(getOpportunities(user), 'trackingStartsAt', 'desc');
  const lists = _.orderBy(getBrandLists(user), 'createdAt', 'desc');
  const showFilters = !!planningStrategy.visibleFilterOptions?.length;
  const showOpportunityFilters = showFilters && planningStrategy.visibleFilterOptions.includes('opportunities');
  const showListFilters = showFilters && planningStrategy.visibleFilterOptions.includes('lists');
  const showLookbookFilters = showFilters && planningStrategy.visibleFilterOptions.includes('lookbooks');
  const filterOptions = !showFilters
    ? []
    : [
        ...(!opportunities?.length || !showOpportunityFilters
          ? []
          : [
              {
                label: 'Past Opportunities',
                options: opportunities
                  .filter(opportunity => {
                    const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
                    const acceptedRequests = _.filter(requests, 'userAccepted');
                    if (!acceptedRequests.length) return false;
                    return true;
                  })
                  .map(opportunity => {
                    const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
                    const timing = getTimingOnOpportunity(opportunity);
                    const acceptedRequests = _.filter(requests, 'userAccepted');
                    return {
                      label: opportunity.title || 'Unknown',
                      sublabels: [timing.durationLabel, `Accepted by ${acceptedRequests.length} creator${acceptedRequests.length === 1 ? '' : 's'}`],
                      value: { opportunity }
                    };
                  })
              }
            ]),
        ...(!lists.length || !showListFilters
          ? []
          : [
              {
                label: 'Your Lists',
                options: [
                  ...getBrandLists(user)
                    .filter(list => list.users.length)
                    .map(list => ({
                      label: list.title || 'Unknown',
                      sublabel: `${list.users.length} creator${list.users.length === 1 ? '' : 's'}`,
                      value: { list }
                    }))
                ]
              }
            ]),
        ...(!lookbooks?.length || !showLookbookFilters
          ? []
          : [
              {
                label: 'Lookbooks',
                options: lookbooks
                  .filter(lookbook => {
                    const requests = getGiftingRequestsForLookbook(analytics, lookbook);
                    const acceptedRequests = _.filter(requests, 'userAccepted');
                    return acceptedRequests.length;
                  })
                  .map(lookbook => {
                    const requests = getGiftingRequestsForLookbook(analytics, lookbook);
                    const acceptedRequests = _.filter(requests, 'userAccepted');
                    return {
                      label: lookbook.title || 'Unknown',
                      sublabels: [`${acceptedRequests.length} requests accepted`],
                      value: { lookbook: lookbook }
                    };
                  })
              }
            ])
      ];

  return (
    <div className='opportunity-planner-controls'>
      <div className='main-controls'>
        <div className='actions-container'>
          <div className='input-container has-actions'>
            <input type='text' placeholder='Search' value={curSearchVal} onChange={e => changeSearch(e.target.value)} />
            <InputActions searchVal={curSearchVal} onCancel={() => changeSearch('')} />
          </div>
          {filterOptions.length > 0 && (
            <div className='select-container'>
              <Select placeholder='Apply Filters' noStyling options={filterOptions} value={null} onChangeValue={value => toggleFilterValue(value)} />
            </div>
          )}
        </div>
        <div className='budget-container'>
          <div className='budget'>${commaNumber(budget)}</div>
          <div className='budget-label'>Currently Allocated</div>
        </div>
      </div>
      <div className='filters-container'>
        <div className='filters'>
          {_.map(curFilters, (filters, key) =>
            filters.map(object => {
              const name = object.title || object.name || object.label;
              const singleKey = {
                opportunities: 'opportunity',
                lists: 'list',
                lookbooks: 'lookbook'
              }[key];
              const toggle = () => toggleFilterValue({ [singleKey]: object });
              return (
                <div className='filter' onClick={toggle} key={key + object.id}>
                  <div className='label'>{name}</div>
                  <div className='close'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

OpportunityPlannerControls.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  planningStrategy: PropTypes.object.isRequired,
  activePlan: PropTypes.object,
  curSearchVal: PropTypes.string.isRequired,
  setCurSearchVal: PropTypes.func.isRequired,
  curFilters: PropTypes.object.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setHasAllResults: PropTypes.func.isRequired
};

export default OpportunityPlannerControls;
