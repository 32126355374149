import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faLinkSlash } from '@fortawesome/pro-solid-svg-icons';
import './LookbookProductModalCustomizeProduct.scss';

import * as lookbookHelpers from '../../../../Helpers/lookbook_helpers';
import { matchScrollHeight } from '../../../../Helpers/helpers';
import LookbookProductModalSiblings from './LookbookProductModalSiblings';
import LookbookProductModalImages from './LookbookProductModalImages';
import { getAllShopifyIntegrations } from '../../../../Helpers/user_helpers';
import Tooltip from '../../../General/Tooltip';

const LookbookProductModalCustomizeProduct = props => {
  const { ui, user, lookbook, customizableProduct, updateLookbookItem, saving, goBackToCatalog } = props;
  const brandChoseManualShipping = lookbook.orderHandledBy === 'brand';
  const brandHasAnyShopifyIntegration = getAllShopifyIntegrations(user).length > 0;
  const isLookbookShopifyConfigured = brandHasAnyShopifyIntegration && !brandChoseManualShipping;

  const { price_limit } = lookbook;
  const priceIsRequired = price_limit > 0;
  const { variant = {}, numTimesOrdered } = customizableProduct;
  const itemIsCustom = lookbookHelpers.getLookbookItemIsCustom(customizableProduct);
  const itemIsStillEmpty = itemIsCustom && !customizableProduct.overrideTitle && !customizableProduct.overrideImage;

  // Core Data
  const [title, setTitle] = useState(customizableProduct?.title || '');
  const [description, setDescription] = useState(customizableProduct?.description || '');
  const [price, setPrice] = useState(customizableProduct?.price || '');
  const [selectedSiblingId, setSelectedSiblingId] = useState(lookbookHelpers.getLookbookItemInitialSibling(customizableProduct)?.id);
  const [url, setUrl] = useState(customizableProduct?.url || '');
  const [image, setImage] = useState(lookbookHelpers.getLookbookItemStartingImage(customizableProduct));

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [maxNumTimesOrdered, setMaxNumTimesOrdered] = useState(customizableProduct?.maxNumTimesOrdered);

  // UI Adjustments
  const itemUrlRef = useRef(null);
  const [siblingModalIsOpen, setSiblingModalIsOpen] = useState(false);

  const variationsButtonClick = () => openSiblingModal();

  const openSiblingModal = () => setSiblingModalIsOpen(true);
  const closeSiblingModal = () => setSiblingModalIsOpen(false);

  const saveItem = async openSiblingsAfter => {
    if (saving) return;

    const numberPrice = parseInt(String(price)?.replace(/[^0-9.]/g, ''));
    const maxNumTimesOrderedIsValid = maxNumTimesOrdered ? maxNumTimesOrdered > 0 : true;
    if (!maxNumTimesOrderedIsValid) return cogoToast.error('Please enter a valid number for max orders');
    else if (maxNumTimesOrdered > 0 && customizableProduct?.numTimesOrdered > maxNumTimesOrdered)
      return cogoToast.error(
        `This product has already been ordered ${customizableProduct.numTimesOrdered} times. Please enter a number greater than that.`
      );

    // validation
    if (!title || !title.length) return cogoToast.error('Please enter a title');
    else if (priceIsRequired && price !== '' && !numberPrice) return cogoToast.error('Please enter a valid price');

    const data = {};
    if (title !== customizableProduct?.title) data.overrideTitle = variant.rawTitle !== title ? title : null;
    if (description !== customizableProduct?.description) data.overrideDescription = variant.description !== description ? description : null;
    if (price !== customizableProduct?.price) data.overridePrice = variant.price !== price ? (isNaN(numberPrice) ? null : String(numberPrice)) : null;
    if (image !== customizableProduct?.image) data.overrideImage = url?.length > 0 && variant.url !== url ? image : null;
    if (url !== customizableProduct?.url) data.overrideUrl = url?.length > 0 && variant.url !== url ? url : null;
    if (maxNumTimesOrdered !== customizableProduct?.maxNumTimesOrdered) data.maxNumTimesOrdered = maxNumTimesOrdered ? maxNumTimesOrdered : null;

    return await updateLookbookItem(customizableProduct, data);
  };

  if (siblingModalIsOpen)
    return (
      <LookbookProductModalSiblings
        ui={ui}
        user={user}
        lookbook={lookbook}
        item={customizableProduct}
        addLookbookItemSibling={props.addLookbookItemSibling}
        updateLookbookItemSibling={props.updateLookbookItemSibling}
        deleteLookbookItemSibling={props.deleteLookbookItemSibling}
        syncLookbook={props.syncLookbook}
        navigateBackToItem={closeSiblingModal}
      />
    );

  return (
    <div className='lookbook-product-modal-customize-product-outer'>
      <div className='lookbook-product-modal-customize-product-inner'>
        <div className='back-button' onClick={goBackToCatalog}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Go to catalog
        </div>
        <div className='product-modal-title'>Edit Product</div>
        <div className='product-container'>
          <LookbookProductModalImages
            lookbook={lookbook}
            item={customizableProduct}
            selectedSiblingId={selectedSiblingId}
            lookbookItemImage={image}
            setLookbookItemImage={setImage}
            updateLookbookItemSibling={props.updateLookbookItemSibling}
          />
          <div className='product-info'>
            {showAdvancedOptions ? (
              <>
                <div className='settings-back-button' onClick={() => setShowAdvancedOptions(false)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  Back to General Settings
                </div>

                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header'>Set Max Orders</div>
                    <div className='product-section-subheader'>
                      If you want to limit the total number of times this product can be selected by talent, enter a number. Leave it blank for no
                      limit.{' '}
                      {numTimesOrdered === 0
                        ? 'This product has not been ordered yet.'
                        : !customizableProduct?.maxNumTimesOrdered || numTimesOrdered < customizableProduct?.maxNumTimesOrdered
                        ? `This product has been ordered ${numTimesOrdered} ${numTimesOrdered === 1 ? 'time' : 'times'}.`
                        : 'This product is currently at its max limit.'}
                    </div>
                    <input
                      id='maxNumTimesOrdered'
                      type='number'
                      defaultValue={maxNumTimesOrdered}
                      onChange={e => {
                        if (!e.target.value || !parseInt(e.target.value)) setMaxNumTimesOrdered();
                        setMaxNumTimesOrdered(parseInt(e.target.value));
                      }}
                      placeholder='100'
                      className='advanced-settings-input'
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header'>Product Name</div>
                    <textarea
                      id='title'
                      defaultValue={title}
                      onChange={e => setTitle(e.target.value)}
                      placeholder='Title here'
                      rows={1}
                      ref={matchScrollHeight}
                    />
                  </div>
                </div>

                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header'>Description</div>
                    <textarea
                      id='description'
                      defaultValue={description}
                      onChange={e => setDescription(e.target.value)}
                      placeholder='Desription here (optional)'
                      rows={1}
                      ref={matchScrollHeight}
                    />
                  </div>
                </div>

                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header'>Price</div>
                    <input
                      id='price'
                      type='number'
                      defaultValue={price}
                      onChange={e => setPrice(e.target.value)}
                      placeholder={`$25${!lookbook.price_limit ? ' (optional)' : ''}`}
                    />
                  </div>
                </div>

                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header'>Url</div>
                    <textarea
                      id='url'
                      defaultValue={url}
                      onChange={e => setUrl(e.target.value)}
                      placeholder='https://yourwebsite.com/products/1 (optional)'
                      rows={1}
                      ref={ref => {
                        matchScrollHeight(ref);
                        itemUrlRef.current = ref;
                      }}
                    />
                  </div>
                </div>

                <div className='product-section-container'>
                  <div>
                    <div className='product-section-header' onClick={variationsButtonClick}>
                      Variations <span className='variations-edit-button'>edit</span>
                    </div>
                    <div className='siblings-container'>
                      {customizableProduct?.reduced_siblings?.map(sibling => {
                        const { title, size_variations } = sibling;
                        const hasSizes = sibling.size_variations?.length > 0;
                        const showShopifyDisconnection =
                          (hasSizes
                            ? sibling.size_variations.some(size => !size.shopifyVariantId) || !sibling.shopifyVariantId
                            : !sibling.shopifyVariantId) && isLookbookShopifyConfigured;
                        const numVariations = size_variations.length;
                        const isSelected = selectedSiblingId === sibling.id;
                        const select = () => setSelectedSiblingId(sibling.id);
                        return (
                          <div
                            onClick={select}
                            key={sibling.id}
                            className={cn('sibling', { selected: isSelected, disconnected: showShopifyDisconnection })}
                          >
                            {title || 'Default'}
                            {numVariations > 1 ? ` - ${sibling.size_variations.length} sizes` : ''}
                            {!!showShopifyDisconnection && isLookbookShopifyConfigured && (
                              <Tooltip message={'Disconnected from Shopify'}>
                                <FontAwesomeIcon icon={faLinkSlash} />
                              </Tooltip>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className='product-section-container'>
                  <div className='advanced-settings-button' onClick={() => setShowAdvancedOptions(true)}>
                    View Advanced Settings
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {itemIsCustom && <div className='disclaimer'>Manually created products will need to be shipped by your team.</div>}
        <div className='buttons'>
          <button className={cn('create', { saving })} onClick={() => saveItem(false)}>
            {itemIsStillEmpty ? 'Done' : 'Update Product'}
          </button>
        </div>
      </div>
    </div>
  );
};

LookbookProductModalCustomizeProduct.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  customizableProduct: PropTypes.object.isRequired,
  updateLookbookItem: PropTypes.func.isRequired,
  syncLookbook: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  goBackToCatalog: PropTypes.func.isRequired,

  addLookbookItemSibling: PropTypes.func.isRequired,
  updateLookbookItemSibling: PropTypes.func.isRequired,
  deleteLookbookItemSibling: PropTypes.func.isRequired
};

export default LookbookProductModalCustomizeProduct;
