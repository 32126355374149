import React from 'react';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import './BrandFAQ.scss';
import './Basic.scss';

import ScrollToTop from '../../Components/General/ScrollToTop';

const BrandFAQ = props => {
  const sections = [
    {
      header: 'Membership Packages',
      faqs: [
        {
          question: 'Can we change partnership levels after we sign up?',
          answer: (
            <span>
              Yes, you can change partnership levels at any point after signing up. To change your partnership level or cancel your membership, please
              email <span className='email'>brands@shopmy.us</span>.
            </span>
          )
        },
        {
          question: 'If we want to cancel our membership, how does that work?',
          answer: (
            <span>
              If you want to cancel or change your membership, please email <span className='email'>brands@shopmy.us</span>.
            </span>
          )
        }
      ]
    },
    {
      header: 'Commission Rates',
      faqs: [
        {
          question: 'How do I change the base commission rate?',
          answer: (
            <span>
              If you are working with us through one of the major affiliate networks (CJ, Impact, Rakuten, etc.), extend us a custom commission rate
              there, and please let us know so we can accept and make the update in our system. If you are on our network and would like to adjust
              your base commission rate, please go to your <Link to='settings'>Account Settings</Link> page.
            </span>
          )
        },
        {
          question: 'How do I give an influencer a custom commission rate?',
          answer: (
            <span>
              You can assign a custom commission rate to an influencer through your <Link to='dashboard'>Brand Portal</Link>. Simply click on the
              "Customize" option on the bottom right corner of any influencer then input a custom commission rate and click Save. Note that this is
              only available to partners on the ShopMy Network, not partners we work with through other affiliate networks (CJ, Impact, Rakuten, AWIN,
              etc.).
            </span>
          )
        }
      ]
    },
    {
      header: 'Gifting',
      faqs: [
        {
          question: 'Is gifting to talent available with every membership package?',
          answer: 'No, gifting is only available to premium partners.'
        },
        {
          question: 'How does the gifting program work?',
          answer:
            'The ShopMy platform facilitates the process of getting the talent’s mailing address, and you handle shipping your products out to them. You send requests to the talent you’d like to gift to, and when they accept or reject their addresses will start to populate in your dashboard.'
        },
        {
          question: 'When we send a gifted product to talent, will they receive a notification?',
          answer:
            'Yes, when you request to gift to talent on the ShopMy platform, they receive a notification prompting them to accept or reject. If they accept, they will receive an automated email indicating that their gifted products have been shipped. This email also includes a reminder that if they are inclined to share their gifted products with their audience, they can add the products easily to their shelves from our catalog or create individual affiliate links to the products.'
        },
        {
          question: 'How do I request specific talent to gift to?',
          answer: (
            <span>
              You can request specific talent to gift to through your <Link to='dashboard'>Brand Portal</Link>. On your main dashboard, click on the
              Artists Tab at the top of the page to search for an influencer. Once you have selected someone, click on the Customize option on the
              bottom right corner of their tab, then click “Select for Samples” to request to gift products to them.
            </span>
          )
        },
        {
          question: 'Does ShopMy recommend which experts would be a good fit for our brand?',
          answer:
            'Yes. When you click “Find Talent” the content creators that appear at the top are the selected talent we think would be a good fit for your brand. This is based on talent that have added products from similar brands. These recommendations are updated on a monthly basis.'
        },
        {
          question: 'How many people can I gift to?',
          answer:
            'There is no limit on how many influencers you can gift to on the ShopMy platform. You can request to gift to as few or as many people as you’d like!'
        },
        {
          question: 'What should I send?',
          answer:
            'It’s totally up to you what you’d like to gift to talent. Oftentimes, brands will send a selection of their bestsellers, or if they are trying to promote a new launch, they will send that. We definitely recommend including a note card in the package reminding the talent that if they are inclined to share, they can tap into your affiliate program on our platform.'
        },
        {
          question: 'Are experts required to plug or tag the products we gift them?',
          answer:
            'No, experts are not required to plug or tag gifted products. We encourage them to share if they like the gifted product, but they are not required to.'
        }
      ]
    },
    {
      header: 'Discount Codes',
      faqs: [
        {
          question: 'Is management of discount codes available to all brand partners?',
          answer:
            'Direct management of discount codes is available to all brand partners that are integrated with the ShopMy network. If you are working with us through another network (such as AWIN, Rakuten, CJ, etc.), you can’t do this directly, but we are happy to work with you to arrange custom discount codes for interested talent. Please reach out to your account manager to arrange.'
        },
        {
          question: 'I am on the ShopMy network. How do I create and manage discount codes?',
          answer: (
            <span>
              First, create a talent specific discount code (such as “KATE15” or “NATALIE20”) in Shopify or whichever POS system you use. Afterwards,
              attach that discount code to the user in your <Link to='dashboard'>Brand Portal</Link> by clicking “Customize” then paste in the
              discount code where it says “CUSTOM CODE.” Make sure not to include spaces or quotes, just include the text of the code exactly as you
              have created it in your POS system.
              <br />
              <br />
              When you add the code to the associated talent, they receive an email notification letting them know the code has been created.`
            </span>
          )
        }
      ]
    },
    {
      header: 'Contact Us',
      faqs: [
        {
          question: 'I have a question. Who can I reach out to for help?',
          answer: (
            <span>
              Please email us at <span class='email'>partners@shopmy.us</span> with any questions!
            </span>
          )
        }
      ]
    }
  ];
  return (
    <div className='brand-faq-outer-container basic-outer-container'>
      <MetaTags>
        <title>Brands FAQ</title>
        <meta property='description' content={`Some answers to our brand's most frequently asked questions`} />
      </MetaTags>
      <ScrollToTop />
      <div className='basic-header-label'>Frequently Asked Questions</div>
      <div className='about-body-container basic-body-container'>
        <div className='basic-body-section narrow'>
          {sections.map(({ header, faqs }, idx) => (
            <div className='section'>
              <h2 className='section-header'>{header}</h2>
              {faqs.map(({ question, answer }, idx) => {
                return (
                  <div key={idx} className='basic-body-desc'>
                    <div className='basic-body-desc-header'>{question}</div>
                    <div className='basic-body-desc-body'>{answer}</div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandFAQ;
