import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faComment, faGift } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './DiscoverList.scss';

import { isAdminControlMode } from '../../../Helpers/ui_helpers';
import { openUpdateListModal } from '../../../Helpers/list_helpers';

const DiscoverList = props => {
  const { list, rearrangeHandle } = props;
  const { preview_images, recommendationListType } = list;

  const editList = () => {
    openUpdateListModal(list, props.updateBrandList, props.deleteBrandList, null, null, isAdminControlMode(props.ui));
  };

  const deleteList = () => {
    const confirm = () => props.deleteBrandList(list);
    list.users.length
      ? confirmAlert({
          title: 'Just confirming',
          message: 'Are you sure you want to delete this list?',
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: confirm
            }
          ]
        })
      : confirm();
  };

  const openBulkModal = () => {
    props.openBulkTalentModal({ BrandList_id: list.id });
  };

  const openBulkGifting = () => {
    props.openRequestModal({
      params: {
        BrandList_id: list.id
      }
    });
  };

  return (
    <div className='discover-list'>
      <div className='main'>
        <div className='user-previews'>
          <div className='user-preview'>{preview_images?.[2] && <img alt='Preview 1' src={preview_images[2]} />}</div>
          <div className='user-preview'>{preview_images?.[1] && <img alt='Preview 2' src={preview_images[1]} />}</div>
          <div className='user-preview'>{preview_images?.[0] && <img alt='Preview 3' src={preview_images[0]} />}</div>
        </div>
        <div className='title-container'>
          <div className='count'>
            {list.users.length} creator{list.users.length === 1 ? '' : 's'}
            {recommendationListType ? ` • ShopMy ${recommendationListType} RECS` : ''}
          </div>
          <div className='title'>{list.title}</div>
          {list.description && <div className='description'>{list.description}</div>}
        </div>
      </div>
      <div className='actions'>
        <div onClick={deleteList} className='action icon delete show-on-hover'>
          <FontAwesomeIcon icon={faTrash} />
        </div>
        <div onClick={editList} className='action icon edit show-on-hover'>
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div onClick={openBulkModal} className='action icon message'>
          <FontAwesomeIcon icon={faComment} />
        </div>
        <div onClick={openBulkGifting} className='action icon gift'>
          <FontAwesomeIcon icon={faGift} />
        </div>
        <Link to={`/list/${list.id}`} className='action primary'>
          View List
        </Link>
      </div>
      <div className='secondary-actions show-on-hover'>{rearrangeHandle}</div>
    </div>
  );
};
DiscoverList.propTypes = {
  ui: PropTypes.object.isRequired,
  deleteBrandList: PropTypes.func.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  rearrangeHandle: PropTypes.object
};

export default DiscoverList;
