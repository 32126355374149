import React, { useState, useEffect } from 'react';
import './PermissionScopes.scss';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { connect } from 'react-redux';
import { OAUTH_SCOPE_DETAILED_DISPLAY_NAMES } from '../Constants/OAuthScopes';
import { authorizeUserForDeveloper as authorizeUserForDeveloperAPI } from '../../../APIClient/user_authentication_tokens';

const PermissionScopes = props => {
  const { application, user, scopes, redirectUrl } = props;
  const { appName, id: application_id } = application;
  const [validScopes, setValidScopes] = useState([]);
  const [permissionedScopes, setPermissionedScopes] = useState([]);

  useEffect(() => {
    const validScopes = scopes.filter(scope => OAUTH_SCOPE_DETAILED_DISPLAY_NAMES[scope]);
    setValidScopes(validScopes);
  }, [scopes]);

  const handleScopeChange = scope => {
    if (permissionedScopes.includes(scope)) {
      // remove scope
      const newScopes = permissionedScopes.filter(s => s !== scope);
      setPermissionedScopes(newScopes);
    } else {
      // add scope
      const newScopes = [...permissionedScopes, scope];
      setPermissionedScopes(newScopes);
    }
  };

  const provideAccess = async () => {
    try {
      const response = await authorizeUserForDeveloperAPI({
        OAuthApplication_id: application_id,
        User_id: user.profile.id,
        scopes: permissionedScopes,
        redirect_url: redirectUrl
      });

      // redirect to redirect_url with token provided by response
      const { redirect_url } = response;
      window.location.href = redirect_url;
    } catch (e) {
      let msg = 'Error providing access, please try again.';

      if (e?.status === 409) {
        msg = 'You have already provided access to this application. You can manage access in your ShopMy settings.';
      }

      cogoToast.error(msg, { hideAfter: 5 });
    }
  };

  const isProvideAccessDisabled = !permissionedScopes.length;

  return (
    <div className='permission-scopes-wrapper'>
      The following permissions will be granted to {appName}. Please review before providing access.
      <div className='permission-scopes-list'>
        {validScopes.map((scope, idx) => {
          const isChecked = permissionedScopes.includes(scope);

          return (
            <div className='permission-scope-row' key={scope} onClick={() => handleScopeChange(scope)}>
              <div className='row-left'>
                <input type='checkbox' name={scope} checked={isChecked} onChange={() => handleScopeChange(scope)} />
                {OAUTH_SCOPE_DETAILED_DISPLAY_NAMES[scope]}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={cn('permission-scopes-submit-btn', { disabled: isProvideAccessDisabled })}
        onClick={isProvideAccessDisabled ? () => null : provideAccess}
      >
        Provide Access
      </div>
    </div>
  );
};

PermissionScopes.propTypes = {
  scopes: PropTypes.array.isRequired,
  application: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  redirectUrl: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps)(PermissionScopes);
