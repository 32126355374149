import React from 'react';
import _ from 'lodash';
import './SectionTemplate_Shelf.scss';

const SectionTemplate_Shelf = props => {
  return (
    <div className='section-template shelf'>
      {_.range(2).map(idx => {
        return (
          <div className='collection' key={idx}>
            <div className='shelf'>
              <div className='product' />
              <div className='product' />
            </div>
            <div className='data'>
              <div className='title' />
              <div className='subtitle' />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SectionTemplate_Shelf;
