import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Discover.scss';

import RequiresLoginPanel from '../../Components/General/RequiresLoginPanel';
import DiscoverBrands from '../../Components/DiscoverOld/DiscoverBrands';
import DiscoverUsers from '../../Components/DiscoverOld/DiscoverUsers';

import { addEvent } from '../../APIClient/events';
import { isBrand, isLoggedIn } from '../../Helpers/user_helpers';

const Discover = props => {
  const { user } = props;
  const hasInitializedEvent = useRef(false);

  useEffect(() => {
    !hasInitializedEvent.current && addEvent('Discover - Page View', { user });
    hasInitializedEvent.current = true;
  }, [user]);

  if (!isLoggedIn(user)) return <RequiresLoginPanel />;
  return <div className='discover-outer-container'>{isBrand(user) ? <DiscoverUsers /> : <DiscoverBrands />}</div>;
};

Discover.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(Discover);
