import React from 'react';
import _ from 'lodash';

import { confirmAlert } from 'react-confirm-alert';
import ConfirmPrompt from '../Components/General/ConfirmPrompt';
import cogoToast from 'cogo-toast';

export const openUpdateListModal = (list, updateBrandList, deleteBrandList, syncData, afterCloseFn, adminControlMode) => {
  confirmAlert({
    customUI: ({ onClose }) => (
      <ConfirmPrompt
        header='Update List'
        placeholder='List Title'
        onCancel={() => {
          onClose();
          afterCloseFn && afterCloseFn();
        }}
        customInputFields={[
          { display: 'Title', placeholder: 'List Title', value: 'title', preloaded: list.title, isSingleLine: true },
          { display: 'Description', placeholder: 'Describe this list', value: 'description', preloaded: list.description, numRows: 3 },
          ...(adminControlMode
            ? [
                {
                  display: 'Recommendation List Type',
                  placeholder: 'gifting/collaboration/codes/rates/outreach',
                  value: 'recommendationListType',
                  preloaded: list.recommendationListType,
                  isSingleLine: true
                }
              ]
            : []),
          { label: 'Only Show List in Chat:', value: 'isChatOnlyList', preloaded: !!list.isChatOnlyList, isBoolean: true },
          {
            label: 'Show List on Talent Tab:',
            value: 'isVisibleOnTalentTab',
            preloaded: !!list.isVisibleOnTalentTab || !!list.isRecommendationList,
            isBoolean: true,
            disabledMsg: list.isRecommendationList ? 'All ShopMy Recommendation lists are visible in the Talent tab.' : null
          },
          { label: 'Is High Priority:', value: 'isHighPriority', preloaded: !!list.isHighPriority, isBoolean: true }
        ]}
        submitBtnDisplay='Update List'
        secondaryBtn={{
          display: 'Delete List',
          onClick: async () => {
            const confirm = async () => {
              await deleteBrandList(list).then(syncData);
              onClose();
              afterCloseFn && afterCloseFn();
            };
            list.count || list.users.length
              ? confirmAlert({
                  title: 'Just confirming',
                  message: 'Are you sure you want to delete this list?',
                  buttons: [
                    { label: 'No', className: 'cancel', onClick: () => {} },
                    {
                      label: 'Yes',
                      onClick: confirm
                    }
                  ]
                })
              : confirm();
          }
        }}
        onSubmit={responseValues => {
          const { title, description, isChatOnlyList, isVisibleOnTalentTab, isHighPriority } = responseValues;
          const recommendationListType = responseValues.recommendationListType || null;

          if (isChatOnlyList && isVisibleOnTalentTab) {
            cogoToast.error('Please select either "Only Show List in Chat" or "Show List on Talent Tab." You cannot choose both.');
            return;
          }

          let updates = {};
          if (!_.isNil(title) && title !== list.title) updates.title = title;
          if (!_.isNil(description) && description !== list.description) updates.description = description;
          if (isChatOnlyList !== list.isChatOnlyList) updates.isChatOnlyList = isChatOnlyList;
          if (isVisibleOnTalentTab !== list.isVisibleOnTalentTab) updates.isVisibleOnTalentTab = isVisibleOnTalentTab;
          if (isHighPriority !== list.isHighPriority) updates.isHighPriority = isHighPriority;
          if (recommendationListType !== list.recommendationListType) updates.recommendationListType = recommendationListType;
          !_.isEmpty(updates) && updateBrandList(list, updates).then(syncData);
          afterCloseFn && afterCloseFn();
        }}
      />
    )
  });
};

export const sortListsByLastEdited = lists => {
  return _.orderBy(
    lists,
    list => {
      const sortedUsers = _.orderBy(list.users, 'createdAt', 'desc');
      return sortedUsers[0]?.createdAt || '1993-08-23';
    },
    'desc'
  );
};
