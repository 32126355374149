import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { getSiteTitle, getCollectionsTitle } from '../../Helpers/helpers';
import { getUsername, isBrand } from '../../Helpers/user_helpers';

import './EmptyLinkResults.scss';

class EmptyLinkResults extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    toggleAddingMode: PropTypes.func.isRequired,
    updateSearchVal: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    searchVal: PropTypes.string.isRequired,
    groupByMode: PropTypes.string.isRequired,
    onlyShowBrandTargets: PropTypes.bool.isRequired,
    onlyShowShopMyBrands: PropTypes.bool.isRequired,
    selectList: PropTypes.func.isRequired,
    selectedList: PropTypes.object
  };

  clickToCreateNewLink = () => {
    this.props.toggleAddingMode();
    this.props.updateSearchVal('');
  };

  clearBrandList = () => {
    this.props.selectList(null);
  };

  render() {
    const { meta, user, searchVal, selectedList, groupByMode } = this.props;
    const { total_no_filters } = meta || {};

    let emptyMessage;
    if (groupByMode === 'users') {
      emptyMessage = selectedList
        ? `Could not find any current promoters from the list "${selectedList.title}"`
        : `Could not find any current promoters`;
    } else if (groupByMode === 'orders') {
      emptyMessage = selectedList ? `Could not find any orders from the list "${selectedList.title}"` : `Could not find any orders`;
    } else if (groupByMode === 'products') {
      emptyMessage = selectedList
        ? `Could not find any products being promoted by creators from the list "${selectedList.title}"`
        : `Could not find any products being promoted`;
    } else if (groupByMode === 'domains') {
      emptyMessage = searchVal
        ? `Could not find any domains being promoted for search term "${searchVal}"`
        : this.props.onlyShowBrandTargets
        ? 'You are not currently promoting any brands eligible for the referral program'
        : this.props.onlyShowShopMyBrands
        ? 'You are not currently promoting any ShopMy Partners'
        : `Could not find any domains being promoted.`;
    } else if (groupByMode === 'mentions') {
      emptyMessage = selectedList
        ? `Could not find any mentions from the creators in the list "${selectedList.title}"`
        : `Could not find any mentions`;
    } else if (groupByMode === 'creator-orders') {
      emptyMessage = `You have not yet driven any sales`;
    }

    return (
      <div className='empty-link-results-outer-container'>
        {total_no_filters ? (
          isBrand(user) ? null : (
            <div className={cn('add-new-link-msg-container', window.__ROOT_SCSS__)}>
              <div className='nothing-found-header'>
                No existing links found for search term <i>{searchVal}</i>.
              </div>
              <div onClick={this.clickToCreateNewLink} className='add-link-btn'>
                CREATE NEW LINK
              </div>
            </div>
          )
        ) : selectedList ? (
          <div className={cn('add-new-link-msg-container', window.__ROOT_SCSS__)}>
            <div className='nothing-found-header'>{emptyMessage}</div>
            <div onClick={this.clearBrandList} className='add-link-btn'>
              Show All Talent
            </div>
          </div>
        ) : isBrand(user) || emptyMessage ? (
          <div className={cn('add-new-link-msg-container', window.__ROOT_SCSS__)}>
            <div className='nothing-found-header'>{emptyMessage}</div>
          </div>
        ) : (
          <div className={cn('nothing-found-msg-container', window.__ROOT_SCSS__)}>
            <div className='nothing-found-header'>Welcome to {getSiteTitle()}!</div>
            <div className='nothing-found-body'>
              Here is where you will be able to access any links you create. To create your first link, either click the "CREATE NEW LINK" button
              above, or head to <a href={`/${getUsername(user)}`}>your shop page</a> and start building your first{' '}
              {getCollectionsTitle({ branded: true })}.
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default EmptyLinkResults;
