import React from 'react';
import PropTypes from 'prop-types';
import './SocialSharingTemplatesInstagram.scss';

import SocialSharingOffsetPinGrid from './SocialSharingOffsetPinGrid';
import SocialSharingPinSquare from './SocialSharingPinSquare';
import SocialSharingBigPin from './SocialSharingBigPin';
import SocialSharingMiddleText from './SocialSharingMiddleText';

const SocialSharingTemplatesInstagram = props => {
  const { collection } = props;
  return (
    <div className='social-sharing-templates-instagram downloadable-content-containers'>
      <SocialSharingOffsetPinGrid collection={collection} elementId='instagram-template-0' />
      <SocialSharingMiddleText collection={collection} elementId='instagram-template-3' />
      <SocialSharingPinSquare collection={collection} elementId='instagram-template-1' />
      <SocialSharingBigPin collection={collection} elementId='instagram-template-2' />
    </div>
  );
};

SocialSharingTemplatesInstagram.propTypes = {
  collection: PropTypes.object.isRequired
};

export default SocialSharingTemplatesInstagram;
