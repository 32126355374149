import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import './Blog.scss';

import { createBlogPost, deleteBlogPost, editBlogPost } from '../../Actions/BlogActions';

import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { getAmpScriptTagForBlogPost } from '../../Helpers/seo_helpers';

import MetaTags from 'react-meta-tags';
import ScrollToTop from '../../Components/General/ScrollToTop';
import BlogData from '../../Components/Blog/BlogData';
import BlogPreviewContainer from '../../Components/Blog/BlogPreviewContainer';
import BlogTabModal from '../../Components/Blog/BlogTabModal';
import BlogPostModal from '../../Components/Blog/BlogPostModal';
import BlogPost from '../../Components/Blog/BlogPost';

const Blog = props => {
  const { blog, ui } = props;
  const { blogPost } = blog;
  const params = useParams();
  const { post_url_stub } = params || {};

  const [blogTabModalIsOpen, setBlogTabModalIsOpen] = useState(false);
  const [blogPostModalIsOpen, setBlogPostModalIsOpen] = useState(false);
  const closeModals = () => {
    setBlogTabModalIsOpen(false);
    setBlogPostModalIsOpen(false);
  };

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const resetSearch = () => {
    setSearchValue('');
    setSearchResults(null);
  };

  const showBlogPost = !searchValue && !!post_url_stub;

  return (
    <div className='blog-outer'>
      <BlogData setIsLoading={setIsLoading} setSearchResults={setSearchResults} searchValue={searchValue} />
      <ScrollToTop />
      <MetaTags>
        <title>{blogPost?.title ? blogPost.title : 'Blog | ShopMy'}</title>
        <meta property='og:title' content={blogPost?.title ? blogPost.title : 'Blog | ShopMy'} />
        <meta
          property='description'
          content={blogPost?.description ? blogPost.description : 'Taking a deeper dive into the products used by industry leaders.'}
        />
        <meta
          property='og:description'
          content={blogPost?.description ? blogPost.description : 'Taking a deeper dive into the products used by industry leaders.'}
        />
        {blogPost?.coverImage && <meta property='og:image' content={blogPost.coverImage} />}
        {blogPost?.id && getAmpScriptTagForBlogPost(blogPost)}
      </MetaTags>
      <div className='blog-inner'>
        {showBlogPost ? (
          <BlogPost />
        ) : (
          <BlogPreviewContainer
            setSearchValue={setSearchValue}
            resetSearch={resetSearch}
            searchValue={searchValue}
            isLoading={isLoading}
            searchResults={searchResults}
          />
        )}
        {blog.tabs && blogTabModalIsOpen && <BlogTabModal close={closeModals} />}
        {blogPostModalIsOpen && <BlogPostModal close={closeModals} isCreating />}
        {!!isAdminControlMode(ui) && !showBlogPost && (
          <div onClick={() => setBlogPostModalIsOpen(true)} className='blog-admin-button'>
            Create Post
          </div>
        )}
        {!!isAdminControlMode(ui) && !showBlogPost && (
          <div onClick={() => setBlogTabModalIsOpen(true)} className='blog-admin-button'>
            Edit Tabs
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user, blog, ui } = state;
  return { user, blog, ui };
};

export default connect(mapStateToProps, {
  createBlogPost,
  deleteBlogPost,
  editBlogPost
})(Blog);
