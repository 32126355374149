import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './LookbookOrderModalShipping.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';

import { matchScrollHeight } from '../../../../Helpers/helpers';

const { getAddress } = require('../../../../Helpers/user_helpers');

const LookbookOrderModalShipping = props => {
  const { user, lookbook, email, setEmail, additionalNotes, setAdditionalNotes, openAddressModal } = props;
  const address = getAddress(user);
  const parsedAddress = JSON.parse(address?.raw || null);

  let addressParts = [];
  if (address?.isConfirmed && parsedAddress?.firstName && parsedAddress?.lastName)
    addressParts.push(parsedAddress.firstName + ' ' + parsedAddress.lastName);
  if (address?.address) addressParts.push(address.address);
  if (address?.phone) addressParts.push(address.phone);

  const isAddressComplete = !!address?.isConfirmed && !!parsedAddress?.firstName && !!parsedAddress?.lastName;

  const hasFirstAndLastName = !!parsedAddress?.firstName && !!parsedAddress?.lastName;

  const getActionText = () => {
    if (!address?.isConfirmed) return 'Enter Address';
    if (!hasFirstAndLastName) return 'Enter Name';

    return 'Enter Address';
  };

  return (
    <div className='lookbook-order-modal-shipping-outer'>
      <div className='lookbook-order-modal-shipping-inner'>
        <div className='form'>
          <div className='form-group'>
            <div className='form-header'>Where is this going?</div>
            <div className='form-group-items'>
              <div className='form-group-item'>
                <input type='text' id='email' placeholder='Email' value={email} onChange={e => setEmail(e?.target?.value)} />
              </div>
              <div className='form-group-item'>
                <div
                  className={cn('address', {
                    missing: !address?.isConfirmed
                  })}
                  onClick={openAddressModal}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  {!isAddressComplete ? <div className='button'>{getActionText()}</div> : addressParts.join(' • ')}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='form-header'>Additional Note for {lookbook.brand.name}?</div>
            <div className='form-group-items'>
              <div className='form-group-item'>
                <textarea
                  id='additional-notes'
                  placeholder='Write your note to the brand here...'
                  rows={5}
                  value={additionalNotes}
                  onChange={e => setAdditionalNotes(e?.target?.value)}
                  ref={ref => {
                    matchScrollHeight(ref);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookOrderModalShipping.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  additionalNotes: PropTypes.string.isRequired,
  setAdditionalNotes: PropTypes.func.isRequired,
  openAddressModal: PropTypes.func.isRequired
};

export default LookbookOrderModalShipping;
