import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import './Codes.scss';

import { getCodesForBrandId } from '../../APIClient/codes';

import { openArtistModal, openCodesModal } from '../../Actions/UIActions';
import { setCustomCode } from '../../Actions/AnalyticsActions';

import { downloadCsvFromUrl } from '../../Helpers/helpers';
import { getBrandId } from '../../Helpers/user_helpers';
import { insertMetaTags } from '../../Helpers/seo_helpers';

import CodeResults from '../../Components/Codes/CodeResults';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import Loader from '../../Components/Loader/Loader';

let debouncer;

const PAGE_LIMIT = 25;

const Codes = props => {
  const { user, analytics } = props;
  const [codes, setCodes] = useState([]);
  const [page, setPage] = useState(0);
  const [curSearchVal, setCurSearchVal] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [receivedAllResults, setReceivedAllResults] = useState(false);
  const [selectedFilter] = useState(null); // Not currently used

  const Brand_id = getBrandId(user);

  const syncResults = () => {
    setIsFetchingResults(true);
    getCodesForBrandId(Brand_id, { page, limit: PAGE_LIMIT, query: curSearchVal, ...(selectedFilter || {}) })
      .then(resp => {
        setCodes([...codes.slice(0, page * PAGE_LIMIT), ...resp.codes]);
        if (resp.codes.length !== PAGE_LIMIT) setReceivedAllResults(true);
      })
      .catch(error => {
        cogoToast.error(`Could not fetch codes, please reload and try again.`);
      })
      .finally(() => {
        setIsFetchingResults(false);
      });
  };

  // Data Management
  useEffect(() => {
    if (Brand_id) {
      syncResults();
    }
  }, [Brand_id, page, curSearchVal, selectedFilter]);

  const downloadResults = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      const resp = await getCodesForBrandId(Brand_id, { page: 0, limit: 2500, query: curSearchVal, downloadAllToCsv: true });
      if (!resp.downloaded_url) throw new Error('Failure downloading');
      downloadCsvFromUrl(resp.downloaded_url);
    } catch (error) {
      cogoToast.error(`Could not download results, plase try again.`);
    }
    setIsDownloading(false);
  };

  const fetchNextPage = async () => {
    setPage(page + 1);
  };

  const changeSearchVal = e => {
    const newSearchVal = e.target.value;
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      setCurSearchVal(newSearchVal);
      setPage(0);
      setReceivedAllResults(false);
    }, 1000);
  };

  if (!Brand_id) return <RequiresBrandLoginPanel />;

  const syncDataForCode = async code => {
    // Find which page code is on and reload that data;
    const index = _.findIndex(codes, c => c.id === code.id);
    const newPage = Math.floor((index + 1) / PAGE_LIMIT);
    if (page === newPage) {
      syncResults();
    } else {
      setPage(newPage);
    }
  };

  const isFetchingFirstPage = isFetchingResults && !codes.length && !curSearchVal && page === 0;
  const isFetchingNextPage = isFetchingResults && page > 0;

  return (
    <div className='codes-outer-container'>
      {insertMetaTags({
        title: 'Custom Discount Codes',
        description: '',
        image: ''
      })}

      <div className='codes-inner-container'>
        {isFetchingFirstPage ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className='codes-header'>
              <div className='search-input'>
                <input onChange={changeSearchVal} placeholder='Search by Name or Code' />
              </div>
              <div className='actions'>
                <div className='action' onClick={downloadResults}>
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  {isDownloading ? 'Downloading...' : 'Download'}
                </div>
              </div>
            </div>
            {codes.length ? (
              <>
                <CodeResults
                  user={user}
                  codes={codes}
                  analytics={analytics}
                  setCustomCode={props.setCustomCode}
                  openCodesModal={props.openCodesModal}
                  syncDataForCode={syncDataForCode}
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  receivedAllResults={receivedAllResults}
                  openArtistModal={props.openArtistModal}
                />
              </>
            ) : (
              <div className='empty-results'>No custom codes found</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Codes.propTypes = {
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openCodesModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  setCustomCode,
  openArtistModal,
  openCodesModal
})(Codes);
