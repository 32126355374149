import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import './UnpaidInvoicesModal.scss';

import Modal from '../General/Modal';

import { closeUnpaidInvoicesModal, setUIKeyValue } from '../../Actions/UIActions';

import {
  getUnpaidBrandSubscriptionInvoicesPastDue,
  getUnpaidBrandAffiliateInvoicesPastDue,
  getUnpaidBrandBudgetInvoicesPastDue
} from '../../Helpers/user_helpers';
import { getInvoiceTableData } from '../../Helpers/subscription_helpers';

const UnpaidInvoicesModal = props => {
  const { user } = props;

  const closeModal = () => props.closeUnpaidInvoicesModal();

  // Subscription Invoices
  const unpaidSubscriptionInvoices = getUnpaidBrandSubscriptionInvoicesPastDue(user);
  const sortedUnpaidSubscriptionInvoices = _.orderBy(unpaidSubscriptionInvoices, 'sentOn', 'desc');
  const isSubscriptionInvoicePastDue = invoice => invoice.payment_status === 'unpaid' && invoice.dueOn && new Date(invoice.dueOn) < new Date();
  const isSubscriptionInvoiceWarning = invoice => invoice.payment_status === 'unpaid' && !isSubscriptionInvoicePastDue(invoice);

  // Affiliate Invoices
  const unpaidAffiliateInvoices = getUnpaidBrandAffiliateInvoicesPastDue(user);
  const sortedUnpaidAffiliateInvoices = _.orderBy(unpaidAffiliateInvoices, 'createdAt', 'desc');
  const isAffiliateInvoicePastDue = invoice => invoice.status === 'unpaid' && invoice.dueOn && new Date(invoice.dueOn) < new Date();
  const isAffiliateInvoiceWarning = invoice => invoice.status === 'unpaid' && !isAffiliateInvoicePastDue(invoice);

  // Budget Invoices
  const unpaidBudgetInvoices = getUnpaidBrandBudgetInvoicesPastDue(user);
  const sortedUnpaidBudgetInvoices = _.orderBy(unpaidBudgetInvoices, 'createdAt', 'desc');
  const isBudgetInvoicePastDue = invoice => invoice.status === 'unpaid' && invoice.dueOn && new Date(invoice.dueOn) < new Date();
  const isBudgetInvoiceWarning = invoice => invoice.status === 'unpaid' && !isBudgetInvoicePastDue(invoice);

  // UI Helpers
  const totalUnpaidInvoices = unpaidSubscriptionInvoices.length + unpaidAffiliateInvoices.length;

  // Allow Dismissing of Modal for short delay
  const ALLOWED_DISMISSAL_DELAY = 1000 * 60 * 60; // 1 hour
  const dismissModalTemporarily = () => {
    props.setUIKeyValue('dismissUnpaidInvoicesModalUntil', new Date().getTime() + ALLOWED_DISMISSAL_DELAY);
    closeModal();
  };

  return (
    <Modal
      visible
      showClose
      close={closeModal}
      title='Unpaid Invoices'
      subtitle={`You have ${totalUnpaidInvoices} unpaid invoice${totalUnpaidInvoices > 1 ? 's' : ''} that ${
        totalUnpaidInvoices > 1 ? 'are' : 'is'
      } past due. Please complete payment to continue using the platform.`}
      className='unpaid-invoices-modal-outer-container'
      innerClassName='unpaid-invoices-modal-inner-container'
      contentClassName='unpaid-invoices-modal-content'
    >
      <div className='invoices-sections'>
        {sortedUnpaidSubscriptionInvoices.length > 0 && (
          <div className='invoices-section'>
            <div className='section-header'>Monthly Subscription Invoices</div>
            <InvoicesTable
              invoices={sortedUnpaidSubscriptionInvoices}
              isPastDueFn={isSubscriptionInvoicePastDue}
              isWarningFn={isSubscriptionInvoiceWarning}
              user={user}
            />
          </div>
        )}
        {sortedUnpaidAffiliateInvoices.length > 0 && (
          <div className='invoices-section'>
            <div className='section-header'>Affiliate Invoices</div>
            <InvoicesTable invoices={sortedUnpaidAffiliateInvoices} isPastDueFn={isAffiliateInvoicePastDue} isWarningFn={isAffiliateInvoiceWarning} />
          </div>
        )}
        {sortedUnpaidBudgetInvoices.length > 0 && (
          <div className='invoices-section'>
            <div className='section-header'>Budget Invoices</div>
            <InvoicesTable invoices={sortedUnpaidBudgetInvoices} isPastDueFn={isBudgetInvoicePastDue} isWarningFn={isBudgetInvoiceWarning} />
          </div>
        )}
      </div>
      <div className='dismiss-btn' onClick={dismissModalTemporarily}>
        dismiss for 1 hour
      </div>
    </Modal>
  );
};

const InvoicesTable = props => {
  const { invoices, isPastDueFn, isWarningFn } = props;
  const data = getInvoiceTableData();
  return (
    <div className='invoices-table'>
      <React.Fragment>
        {data.map(d => (
          <div className='header cell' key={d.header}>
            {d.header}
          </div>
        ))}
      </React.Fragment>
      {invoices.map(invoice => {
        return (
          <React.Fragment key={invoice.id}>
            {data.map(d => (
              <div
                className={cn('cell', {
                  warning: isWarningFn(invoice),
                  alert: isPastDueFn(invoice)
                })}
                key={d.header}
              >
                {d.getValue(invoice)}
              </div>
            ))}
          </React.Fragment>
        );
      })}
    </div>
  );
};

UnpaidInvoicesModal.propTypes = {
  user: PropTypes.object.isRequired,
  closeUnpaidInvoicesModal: PropTypes.func.isRequired,
  setUIKeyValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  closeUnpaidInvoicesModal,
  setUIKeyValue
})(UnpaidInvoicesModal);
