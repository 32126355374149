import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLink, faPlus } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ContractUpload.scss';

import { getPrettyFileTypeDisplay } from '../../../Helpers/formatting';
import { isBrand } from '../../../Helpers/user_helpers';
import { matchScrollHeight } from '../../../Helpers/helpers';

let titleDebouncers = {};
let descriptionDebouncers = {};
const ContractUpload = props => {
  const { user, upload, contract, isTemplate } = props;
  const { url, uploadedByUser, uploadedByBrand } = upload;
  const [title, setTitle] = useState(upload.title);
  const [description, setDescription] = useState(upload.description || '');
  const [isCreatingFromPast, setIsCreatingFromPast] = useState(false);

  const canMakeEdits = props.canEdit && ((uploadedByBrand && isBrand(user)) || (uploadedByUser && !isBrand(user)));

  const remove = () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to delete this upload?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteContractUpload(upload) }
      ]
    });
  };

  const open = () => {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', 'blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const createFromPast = async () => {
    setIsCreatingFromPast(true);
    await props.createContractUpload(
      {
        ...(title ? { title } : {}),
        ...(description ? { description } : {}),
        uploadedByUser: !!uploadedByUser,
        uploadedByBrand: !!uploadedByBrand,
        url: upload.url
      },
      contract
    );
    setIsCreatingFromPast(true);
    props.postCreationCallback && props.postCreationCallback();
  };

  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(titleDebouncers[upload.id]);
    titleDebouncers[upload.id] = setTimeout(() => {
      props.updateContractUpload(upload, { title: newTitle });
    }, 500);
  };

  const updateDescription = newDescription => {
    setDescription(newDescription);
    clearTimeout(descriptionDebouncers[upload.id]);
    descriptionDebouncers[upload.id] = setTimeout(() => {
      props.updateContractUpload(upload, { description: newDescription });
    }, 500);
  };

  const extraClasses = { editable: canMakeEdits, template: isTemplate };
  const uploaderName = isBrand(user) ? (uploadedByBrand ? 'you' : contract.user.name) : uploadedByUser ? 'you' : contract.brand.name;
  return (
    <div className={cn('contract-upload', extraClasses)}>
      <div className='main'>
        <div className='data'>
          {canMakeEdits ? (
            <textarea
              rows={1}
              ref={ref => matchScrollHeight(ref)}
              value={title}
              className='title'
              placeholder='Title this upload'
              onChange={e => updateTitle(e.target.value)}
            />
          ) : (
            <div className='title'>{title}</div>
          )}
          <div className='meta'>
            {getPrettyFileTypeDisplay(upload.url)}
            <div className='bullet'>•</div>
            Uploaded by {uploaderName}
            <div className='bullet'>•</div>
            {moment(upload.createdAt).format('MMMM Do, YYYY')}
          </div>

          {canMakeEdits ? (
            <textarea
              rows={1}
              ref={ref => matchScrollHeight(ref)}
              value={description}
              className='description'
              placeholder='Add more detail about this upload'
              onChange={e => updateDescription(e.target.value)}
            />
          ) : (
            description && <div className='description'>{description}</div>
          )}
        </div>
      </div>
      <div className='secondary'>
        {canMakeEdits ? (
          <div className='upload-actions'>
            <div onClick={open} className='upload-action open'>
              <FontAwesomeIcon icon={faExternalLink} />
            </div>
            <div onClick={remove} className='upload-action delete'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ) : isTemplate ? (
          <div onClick={createFromPast} className='add-from-template-btn'>
            <FontAwesomeIcon icon={faPlus} />
            {isCreatingFromPast ? 'Adding...' : 'Add Upload'}
          </div>
        ) : (
          <div className='upload-actions'>
            <div onClick={open} className='upload-action open'>
              <FontAwesomeIcon icon={faExternalLink} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContractUpload.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  upload: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  isTemplate: PropTypes.bool,
  deleteContractUpload: PropTypes.func,
  updateContractUpload: PropTypes.func,
  createContractUpload: PropTypes.func
};

export default ContractUpload;
