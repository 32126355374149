import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './Product.scss';

import { getHierarchy, getVisibleProduct, addReview, updateReview, deleteReview } from '../../Actions/ShopActions';
import { addEvent } from '../../APIClient/events';
import { isAdmin } from '../../Helpers/user_helpers';
import { getSEODescriptionForProduct, getSeoSchemaForProductPage } from '../../Helpers/seo_helpers';
import ScrollToTop from '../../Components/General/ScrollToTop';

import ProductReviews from '../../Components/Product/ProductReviews';
import ProductTopArtists from '../../Components/Product/ProductTopArtists';
import ProductSocialContent from '../../Components/Product/ProductSocialContent';
import ProductHeader from '../../Components/Product/ProductHeader';

const Product = props => {
  const { match, shop, user, getVisibleProduct, getHierarchy, addReview, updateReview, deleteReview } = props;
  const { visibleProduct } = shop;

  useEffect(() => {
    getHierarchy();
    getVisibleProduct(_.get(match, 'params.stub')).then(resp => {
      resp &&
        resp.product &&
        addEvent('Shop - View Product Page', {
          title: _.get(resp.product, 'title'),
          brand: _.get(resp.product, 'brand'),
          urlStub: _.get(resp.product, 'urlStub')
        });
    });
  }, [match, getVisibleProduct, getHierarchy]);

  const expertElRef = useRef(null);
  const scrollToExperts = () => {
    const top = _.get(expertElRef, 'current.offsetTop');
    top &&
      window.scrollTo({
        top: top - 64,
        behavior: 'smooth'
      });
  };

  return (
    <div className='product-page-outer-container'>
      {visibleProduct && (
        <MetaTags>
          <title>{`${visibleProduct.brand} | ${visibleProduct.title}`}</title>
          <meta name='description' content={getSEODescriptionForProduct(visibleProduct, shop)} />
          <meta property='og:title' content={`${visibleProduct.brand} | ${visibleProduct.title}`} />
          <meta property='og:description' content={getSEODescriptionForProduct(visibleProduct, shop)} />
          <meta property='og:image' content={visibleProduct.image} />
          {getSeoSchemaForProductPage(visibleProduct, shop)}
        </MetaTags>
      )}

      <div className='product-page-inner-container'>
        <ScrollToTop from={['/shop', '/home']} />
        <ProductHeader scrollToExperts={scrollToExperts} shop={shop} user={user} product={visibleProduct} />
        {visibleProduct && (
          <ProductReviews addReview={addReview} updateReview={updateReview} deleteReview={deleteReview} user={user} product={visibleProduct} />
        )}
        {visibleProduct && <ProductSocialContent product={visibleProduct} />}
        {visibleProduct && <ProductTopArtists expertElRef={expertElRef} product={visibleProduct} />}
        {isAdmin(user) && visibleProduct && (
          <div className='extra-data'>
            <div className='admin-header'>For Admins</div>
            <div className='product-id'>
              Product #<b>{visibleProduct.id}</b>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Product.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, shop } = state;
  return { user, shop };
};

export default connect(mapStateToProps, {
  getVisibleProduct,
  addReview,
  updateReview,
  deleteReview,
  getHierarchy
})(withRouter(Product));
