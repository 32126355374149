import APIClient from './index';
import _ from 'lodash';

export const getAllJobs = () => {
  return new Promise((resolve, reject) => {
    APIClient.get('Greenhouse')
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
