import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faStar, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityLeaderboardCard.scss';

import {
  getSpendDataOnOpportunityRequest,
  getDisplayStatsOnOpportunityResult,
  getOpportunityRequestAdjustedExpectations
} from '../../../Helpers/opportunity_helpers';
import { getPrettyNumber, getInitialsForUser } from '../../../Helpers/formatting';

import OpportunityExpectations from './OpportunityExpectations';

const OpportunityLeaderboardCard = props => {
  const { request, opportunity, sortFn, rank } = props;
  const { id, result } = request;
  const stats = result?.stats ? JSON.parse(result.stats) : {};
  const { linksExpected, linkingDaysExpected, mentionsExpected, mentionDaysExpected } = getOpportunityRequestAdjustedExpectations(
    opportunity,
    request
  );
  const hasResult = !!result?.id;

  // Allow toggling
  const [isExpanded, setIsExpanded] = React.useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Get General Data
  const spendData = getSpendDataOnOpportunityRequest(request, opportunity);

  // Get Progress Steps
  let progressSteps = [];
  if (linksExpected) {
    progressSteps.push({
      label: linksExpected === 1 ? 'link' : 'links',
      numCompleted: stats.links + stats.missingLinks,
      numExpected: linksExpected
    });
  }
  if (linkingDaysExpected) {
    progressSteps.push({
      label: linkingDaysExpected === 1 ? 'day with link' : 'days with links',
      numCompleted: stats.linkingDays + stats.missingLinkingDays,
      numExpected: linkingDaysExpected
    });
  }
  if (mentionsExpected) {
    progressSteps.push({
      label: mentionsExpected === 1 ? 'mention' : 'mentions',
      numCompleted: stats.mentions + stats.missingMentions,
      numExpected: mentionsExpected
    });
  }
  if (mentionDaysExpected) {
    progressSteps.push({
      label: mentionDaysExpected === 1 ? 'day with mention' : 'days with mentions',
      numCompleted: stats.mentionDays + stats.missingMentionDays,
      numExpected: mentionDaysExpected
    });
  }

  // Get stats
  let resultStats = getDisplayStatsOnOpportunityResult(result, opportunity);

  // Specify which is the sort, or add it if it's not there
  resultStats = resultStats.map(resultStat => ({ ...resultStat, isSort: resultStat.label === sortFn.display }));
  sortFn &&
    !resultStats.find(stat => stat.isSort) &&
    resultStats.push({
      label: sortFn.display,
      display: !hasResult ? null : sortFn.getValueDisplay(request),
      value: !hasResult ? null : sortFn.getValue(request),
      isSort: true
    });

  const openProfile = e => {
    e.stopPropagation();
    props.openArtistModal(request.user);
  };

  // Ranking
  const isTopThree = rank <= 3;
  const isFirst = rank === 1;
  const st = isFirst ? 'st' : rank === 2 ? 'nd' : rank === 3 ? 'rd' : 'th';
  const rankSt = `${rank}${st}`;

  const additionalClasses = { [`rank-${props.rank}`]: true };
  return (
    <div key={id} className={cn('opportunity-leaderboard-card', additionalClasses)}>
      <div onClick={toggleExpanded} className='collapsed-card'>
        <div className={cn('ranking-status', additionalClasses)}>
          {isTopThree ? (
            <div className='rank'>
              <span className='number'>{props.rank}</span>
              <span className='st'>{st}</span>
            </div>
          ) : (
            <div className='rank'>{props.rank}</div>
          )}
          <div className={cn('user-image-container', additionalClasses)}>
            <div className='image-badge-wrapper' />
            {request.user.image ? (
              <img onClick={openProfile} src={request.user.image} alt={request.user.name} />
            ) : (
              <div className='empty-image'>
                <div className='initials'>{getInitialsForUser(request.user)}</div>
              </div>
            )}
            {isTopThree && (
              <div className={cn('ribbon-container', additionalClasses)}>
                <div className={cn('ribbon-outer', additionalClasses)}>
                  <div className={cn('ribbon-inner', additionalClasses)}>
                    {isFirst && <FontAwesomeIcon icon={faStar} />}
                    <span className='label'>{rankSt} place</span>
                    {isFirst && <FontAwesomeIcon icon={faStar} />}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={cn('main-container', additionalClasses)}>
          <div className='section user-info'>
            <div className='section-data'>
              <div onClick={openProfile} className='name'>
                {request.user.name}
              </div>
              {!!progressSteps.length && (
                <div className='progress-steps'>
                  {progressSteps.map(step => {
                    const { numExpected, label, numCompleted } = step;
                    const isComplete = numCompleted >= numExpected;
                    return (
                      <div key={label} className={cn('progress-step', { complete: isComplete })}>
                        {isComplete && <FontAwesomeIcon icon={faBadgeCheck} />}
                        <div>
                          {_.min([numCompleted, numExpected])} of {numExpected} {label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className='section spend'>
            <div className='section-stats'>
              <div className='section-stat'>
                {spendData.locked ? <div className='value'>${getPrettyNumber(spendData.locked)}</div> : <div className='value empty'>-</div>}
                <div className={cn('label', { empty: !spendData.total })}>{spendData.status}</div>
              </div>
            </div>
          </div>
          <div className='section results'>
            <div className='section-stats'>
              {resultStats.map(({ label, value, display, isSort }) => {
                const additionalClasses = { featured: isSort, empty: !value };
                return (
                  <div key={label} className={cn('section-stat', additionalClasses)}>
                    {isSort && <div className='background-highlight' />}
                    <div className={cn('value', additionalClasses)}>{value ? display : '-'}</div>
                    <div className={cn('label', additionalClasses)}>{label}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {isExpanded ? (
        <div className='expanded-card'>
          <OpportunityExpectations opportunity={opportunity} request={request} />
        </div>
      ) : (
        <div className='toggle-expanded-overlay'>
          <div>View Expectations</div>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      )}
    </div>
  );
};

OpportunityLeaderboardCard.propTypes = {
  sortFn: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  rank: PropTypes.number.isRequired
};

export default OpportunityLeaderboardCard;
