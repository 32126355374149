import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './OpportunitySetupGuideFooter.scss';

import icons from '../../../static/icons';

const OpportunitySetupGuideFooter = props => {
  const { step, disclaimer, opportunity } = props;
  const { isLast, isActive, isComplete, isUpcoming, isPlanningPanel } = step;
  const additionalClasses = { last: isLast, active: isActive, complete: isComplete, upcoming: isUpcoming };
  const isExperiential = opportunity.type === 'experience';
  return (
    <div className='opportunity-setup-guide-footer'>
      <div className='actions'>
        {isLast ? (
          isPlanningPanel ? (
            <>
              <div onClick={props.completeSetupAndStartSmartPlanning} className={cn('action primary', additionalClasses)}>
                <div className={cn('action-label', additionalClasses)}>Generate Plan</div>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={props.completeSetupWithoutPlanning}
                className={cn('action', { secondary: !isExperiential, primary: isExperiential }, additionalClasses)}
              >
                <div className={cn('action-label', additionalClasses)}>{isExperiential ? 'Show Event Listing' : 'Show Listing'}</div>
              </div>
              {!isExperiential && (
                <div onClick={props.completeSetupAndStartStandardPlanning} className={cn('action primary', additionalClasses)}>
                  <div className={cn('action-label', additionalClasses)}>Start Planning</div>
                </div>
              )}
            </>
          )
        ) : (
          <div onClick={props.goToNextStep} className={cn('action', additionalClasses)}>
            <div className={cn('action-label', additionalClasses)}>Next</div>
            <div className={cn('action-icon', additionalClasses)}>
              <img src={icons.misc.arrowRight} alt='Arrow' />
            </div>
          </div>
        )}
      </div>
      {disclaimer && <div className='disclaimer'>{disclaimer}</div>}
    </div>
  );
};

OpportunitySetupGuideFooter.propTypes = {
  opportunity: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  disclaimer: PropTypes.string,
  goToNextStep: PropTypes.func.isRequired,
  completeSetupAndStartSmartPlanning: PropTypes.func.isRequired,
  completeSetupAndStartStandardPlanning: PropTypes.func.isRequired,
  completeSetupWithoutPlanning: PropTypes.func.isRequired
};

export default OpportunitySetupGuideFooter;
