import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLock, faPlus, faInfoCircle, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ContractProposeReceiptCard.scss';

import Tooltip from '../../General/Tooltip';

import { getDisplayForCollaborationType, isTask, isBrandOnlyTemplate, getAllTemplates } from '../../../Helpers/collaboration_helpers';
import { getPrettyPriceDisplay } from '../../../Helpers/formatting';
import { isBrand, getBrandBudgetRemaining } from '../../../Helpers/user_helpers';
import { scrollToTop } from '../../../Helpers/helpers';
import { getPlatformFee, getPlatformFeeRate } from '../../../Helpers/contract_helpers';

const ContractProposeReceiptCard = props => {
  const { user, contract, canEdit, updateContractTask, deleteContractTask, collaborations, getContractTaskTemplates } = props;
  const { isCreatedByUser, invoice } = contract;
  const hasTasks = !!contract?.tasks?.length;
  const [showAllTasks, setShowAllTasks] = useState(!hasTasks);

  const budgetRemaining = isBrand(user) ? getBrandBudgetRemaining(user) || 0 : 0;
  const networkFee = isBrand(user) ? (invoice ? invoice.sms_amount : getPlatformFee(user, contract)) : 0;
  const networkFeeRate = isBrand(user) ? (invoice ? (100 * invoice.sms_amount) / invoice.amount : getPlatformFeeRate(user, contract)) : 0;

  const totalPrice =
    _.sum(
      contract.tasks.map(t => {
        const isFixed = t.template?.fee_type === 'fixed';
        if (isFixed) return t.price || 0;
        if (t.taskDuration) return t.taskDuration * (t.price || 0);
        return 0;
      })
    ) + (isBrand(user) ? networkFee : 0);
  const totalDurationPrice = _.sum(
    contract.tasks.map(t => {
      const isFixed = t.template?.fee_type === 'fixed';
      if (isFixed) return 0;
      if (t.taskDuration) return 0;
      return t.price || 0;
    })
  );

  const totalUsedFromBudget = invoice ? invoice.budget_amount : _.min([totalPrice, budgetRemaining]);

  let sortedGroups = [];
  const allTemplates = getAllTemplates(collaborations);
  if (showAllTasks) {
    const groups = _.values(_.groupBy(allTemplates, 'type'));
    sortedGroups = _.orderBy(groups, group => group[0].sortOrderRank);
  } else {
    const groups = _.values(_.groupBy(contract.user.tasks, 'template.type'));
    sortedGroups = [
      ..._.orderBy(groups, group => group[0].sortOrderRank),
      _.orderBy(allTemplates.filter(isBrandOnlyTemplate), t => ['custom', 'addon', 'stipend'].indexOf(t.type))
    ];
  }

  useEffect(() => {
    canEdit && getContractTaskTemplates();
  }, [getContractTaskTemplates, canEdit]);

  if (!canEdit && !hasTasks) return null;
  return (
    <>
      <div className='contract-propose-receipt-card card'>
        <div className='card-header-container'>
          <div className='card-header'>Price</div>
          {canEdit && (
            <div className='card-subheader'>
              {hasTasks
                ? 'You can edit any of the titles and prices below to finalize the pricing of your proposal.'
                : `Build your proposal with deliverables by selecting from the options below. These are the deliverables that will correspond with the collaboration and how much you want to charge for each. You can edit the title of each deliverable and adjust the prices here.`}
            </div>
          )}
        </div>
        {hasTasks && (
          <div className='card-body-container'>
            <div className='tasks'>
              {contract.tasks?.map(task => (
                <ContractProposedTask
                  key={task.id}
                  contract={contract}
                  updateContractTask={updateContractTask}
                  deleteContractTask={deleteContractTask}
                  task={task}
                  canEdit={canEdit}
                />
              ))}
              {!!networkFee && !canEdit && (
                <div className='task fee'>
                  <div className='main'>
                    <div className='title'>
                      {networkFeeRate.toFixed(0)}% Platform Fee
                      <Tooltip
                        message={`The rate is dependent on your monthly package and the total price of the contract. Learn more by clicking the link icon to the right.`}
                        getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle} />}
                      />
                      <a href='https://brands.shopmy.us/brand-packages' target='_blank' rel='noopener noreferrer'>
                        <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    </div>
                    {totalUsedFromBudget ? <div className='subtitle'>Amount only owed on spend beyond budget</div> : ''}
                  </div>
                  <div className='secondary'>
                    <div className='pricing'>{networkFee ? getPrettyPriceDisplay(networkFee, { precision: 0 }) : '-'}</div>
                  </div>
                </div>
              )}
              {!!totalUsedFromBudget && isBrand(user) && (
                <div className='task fee'>
                  <div className='main'>
                    <div className='title'>Deducted From Budget</div>
                  </div>
                  <div className='secondary'>
                    <div className='pricing'>-{getPrettyPriceDisplay(totalUsedFromBudget, { precision: 0 })}</div>
                  </div>
                </div>
              )}
              <div className='task total'>
                <div className='main'>
                  <div className='title'>Total</div>
                </div>
                <div className='secondary'>
                  <div className='pricing'>
                    <div className='price'>
                      {totalPrice
                        ? isBrand(user)
                          ? getPrettyPriceDisplay(totalPrice - totalUsedFromBudget, { precision: 0 })
                          : getPrettyPriceDisplay(totalPrice, { precision: 0 })
                        : totalDurationPrice
                        ? null
                        : '-'}
                      {!!totalDurationPrice && (
                        <>
                          {!!totalPrice && <span> + </span>}
                          <span>{getPrettyPriceDisplay(totalDurationPrice, { precision: 0 })}</span>
                          <span className='per-day'> per day</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {canEdit && (
        <div className='potential-tasks-container'>
          <div className='potential-tasks-container-header-container'>
            <div className='potential-tasks-container-header-wrapper'>
              <div className='potential-tasks-container-header'>ADD DELIVERABLES</div>
              {!!isCreatedByUser && (
                <div className='potential-tasks-container-subheader'>
                  You can configure your offers and pricing on the{' '}
                  <Link onClick={scrollToTop} to={`/${contract.user.username}/collaborations`}>
                    collaborations tab
                  </Link>{' '}
                  of your shop. Your default prices are added below if configured, but they can be adjusted above once you add them to the proposal.
                </div>
              )}
            </div>
            <div className='header-actions'>
              <div className='show-all-switch'>
                <div>Show All Tasks</div>
                <Switch
                  onChange={() => setShowAllTasks(!showAllTasks)}
                  checked={showAllTasks}
                  height={14}
                  width={24}
                  onColor='#333'
                  offColor='#ccc'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
            </div>
          </div>
          <div className='potential-tasks-groups'>
            {_.map(sortedGroups, taskOrTemplates => {
              const type = taskOrTemplates[0]?.type || taskOrTemplates[0]?.template?.type;
              return (
                <div key={type} className='potential-tasks-group'>
                  <div className='task-type-header'>{getDisplayForCollaborationType(type)}</div>
                  <div className='tasks'>
                    {taskOrTemplates.map(taskOrTemplate => {
                      const objectIsTask = isTask(taskOrTemplate);
                      const task = objectIsTask ? taskOrTemplate : _.find(contract.user.tasks, t => t.ContractTaskTemplate_id === taskOrTemplate.id);
                      const template = objectIsTask ? task.template : taskOrTemplate;
                      const { fee_type, id } = template;
                      const { price } = task || {};
                      const create = () => props.createContractTask(task || taskOrTemplate, contract);
                      return (
                        <div onClick={create} key={id} className={cn('task', { 'unselected-template': !objectIsTask && !task })}>
                          <div className='task-main'>
                            <div className='title'>{template?.title}</div>
                            {!!price && <div className='bullet'>•</div>}
                            {!!price && (
                              <div className='price'>{getPrettyPriceDisplay(price, { perDay: fee_type === 'duration', precision: 0 })}</div>
                            )}
                          </div>
                          <div className='task-actions'>
                            <div className='action add'>
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

let titleDebouncers = {};
let priceDebouncers = {};
const ContractProposedTask = props => {
  const { task, canEdit, contract, updateContractTask } = props;
  const { template, taskDuration, isPriceLocked, isAddOn, id } = task;
  const { type, fee_type } = template || {};
  const [title, setTitle] = useState(task.title);
  const [price, setPrice] = useState(task.price ? (taskDuration ? task.price * taskDuration : task.price) : '');
  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(titleDebouncers[id]);
    titleDebouncers[id] = setTimeout(() => {
      updateContractTask(task, { title: newTitle });
    }, 500);
  };
  const updatePrice = newPrice => {
    const officialPrice = newPrice.replace(/[^0-9]+/g, '').slice(0, 8);
    setPrice(officialPrice);
    clearTimeout(priceDebouncers[id]);
    priceDebouncers[id] = setTimeout(() => {
      updateContractTask(task, { price: officialPrice ? parseFloat(officialPrice / (taskDuration || 1)) : null });
    }, 500);
  };

  const removeTask = () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to delete this ${isAddOn ? 'add on' : 'deliverable'}?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteContractTask(task) }
      ]
    });
  };

  const alertAboutLockedPrice = e => {
    cogoToast.warn(`${contract.user?.name || 'This creator'} does not allow editing of this price.`);
  };

  const warnAboutWhereToSetDays = () => {
    cogoToast.info('You can update this value on the Schedule Tab');
  };

  return (
    <div className='task'>
      <div className='main'>
        {canEdit ? (
          <input value={title} className='title' placeholder='Title this deliverable' onChange={e => updateTitle(e.target.value)} />
        ) : (
          <div className='title'>{task.title}</div>
        )}
        <div className='subtitle'>{getDisplayForCollaborationType(type)}</div>
      </div>
      <div className='secondary'>
        <div className='pricing'>
          {canEdit && !isPriceLocked ? (
            <div className='price-container'>
              <input
                value={getPrettyPriceDisplay(price, { precision: 0 })}
                className='price'
                placeholder='Set a Price'
                onChange={e => updatePrice(e.target.value)}
              />
              {fee_type === 'fixed' ? null : taskDuration ? (
                <div onClick={warnAboutWhereToSetDays} className='per-day'>
                  for {taskDuration} day{taskDuration === 1 ? '' : 's'}
                </div>
              ) : (
                <div onClick={warnAboutWhereToSetDays} className='per-day'>
                  per day
                </div>
              )}
            </div>
          ) : (
            <div onClick={isPriceLocked ? alertAboutLockedPrice : null} className='price-container'>
              <div className='price'>
                {canEdit && isPriceLocked && <FontAwesomeIcon icon={faLock} />}
                {task.price ? getPrettyPriceDisplay(price, { precision: 0 }) : 'No Price Set'}
              </div>
              {fee_type === 'fixed' ? null : taskDuration ? (
                <div className='per-day'>
                  for {taskDuration} day{taskDuration === 1 ? '' : 's'}
                </div>
              ) : (
                <div className='per-day'>per day</div>
              )}
            </div>
          )}
        </div>
        {canEdit && (
          <div className='task-actions'>
            <div onClick={removeTask} className='action delete'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ContractProposeReceiptCard.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  collaborations: PropTypes.object.isRequired,

  // Editing
  createContractTask: PropTypes.func,
  updateContractTask: PropTypes.func,
  deleteContractTask: PropTypes.func,
  getContractTaskTemplates: PropTypes.func
};

export default ContractProposeReceiptCard;
