import _ from 'lodash';

import { hasManagers, getManagers } from './user_helpers';

export const getCurrentManager = manager => _.get(manager, 'currentManager');
export const getUserBeingManaged = manager => _.get(manager, 'currentlyManaging');
export const isSwitchingManaging = manager => _.get(manager, 'isSwitchingManaging');
export const getNotificationCounts = manager => _.get(manager, 'notificationCounts');
export const getNotificationCountForManagesUser = (user, manager) => _.get(getNotificationCounts(manager), user.id) || 0;
export const getCurrentManagerPermissions = manager => {
  const currentManager = getCurrentManager(manager);
  const currentlyManaging = getUserBeingManaged(manager);
  const managesUserObject = currentManager?.manages?.find(m => m.User_id === currentlyManaging?.id);
  return currentManager ? getPermissionsForSpecificUserManager(managesUserObject) || [] : null;
};
export const isCurrentlyManaging = manager => !!getUserBeingManaged(manager);
export const getManagerManages = manager => getCurrentManager(manager)?.manages || [];
export const getManagerPermission = (manager, permission) => getCurrentManagerPermissions(manager)?.find(p => p.variable === permission);
export const managerHasPermission = (manager, permission) => getManagerPermission(manager, permission)?.selected;
export const getUsersManagerPermissionRestriction = (user, manager, permission) => {
  /*
    Certain permissions can be restricted based on the managers access levels, for example a manager
    might be the only one who can manage collaborations.
  */
  if (!hasManagers(user)) return null;

  // Grab metadata for that permission type
  const permissionObject = userManagerPermissions.find(p => p.userRestrictionVariable === permission);
  if (!permissionObject) return null;

  // Check if one of your managers has that permission
  const managerWithPermission = getManagers(user).find(m => !!m[permissionObject.variable]);
  if (!managerWithPermission) return null;

  // Make sure you aren't the manager with that permission
  if (managerWithPermission.Manager_id === getCurrentManager(manager)?.id) return null;

  return permissionObject;
};
export const hasPermission = (user, manager, permission) => {
  /*
    Specifies whether a given user/manager can access the requested permission.
  */

  // They are blocked by a manager only permission
  const managerWithSuperPermission = getUsersManagerPermissionRestriction(user, manager, permission);
  if (managerWithSuperPermission) return false;

  // They are a manager without permissions
  const isRestrictedByManagerPermissions = isCurrentlyManaging(manager) && !managerHasPermission(manager, permission);
  return !isRestrictedByManagerPermissions;
};

export const userManagerPermissions = [
  {
    display: 'Can View Earnings',
    default: true,
    variable: 'canViewEarnings',
    error: 'You do not currently have permission to view earnings.'
  },
  {
    display: 'Can Chat With Brands',
    default: true,
    variable: 'canAnswerChats',
    error: 'You do not currently have permission to chat with brands for this content creator.'
  },
  {
    display: 'Can Manage Collaborations',
    default: true,
    variable: 'canAnswerCollaborations',
    error: 'You do not currently have permission to manage collaborations for this content creator.'
  },
  {
    display: 'Can Manage Opportunities',
    default: true,
    variable: 'canAnswerOpportunities',
    error: 'You do not currently have permission to respond to opportunities for this content creator.'
  },
  {
    display: 'Can Edit Shop',
    default: true,
    variable: 'canEditShop',
    error: "You do not currently have permission to manage this content creator's shop."
  },
  {
    display: 'Can Create Links',
    default: true,
    variable: 'canCreateLinks',
    error: 'You do not currently have permission to generate links for this content creator.'
  },
  {
    display: 'Can Accept Gifting',
    default: true,
    variable: 'canAcceptGifting',
    error: 'You do not currently have permission to accept gifting on behalf of this content creator.'
  },
  {
    display: 'Can Edit Settings',
    default: true,
    variable: 'canEditSettings',
    error: 'You do not currently have permission to edit settings for this content creator.'
  },
  { display: 'Receives Email Notifications', default: true, variable: 'receiveEmails' },
  {
    display: 'Super Admin: Can Only Handle Collaborations',
    default: false,
    variable: 'canOnlyAnswerCollaborations',
    error: 'Only your shop manager is permitted to handle collaborations.',

    isRestrictionOnUser: true,
    userRestrictionVariable: 'canAnswerCollaborations'
  }
];

export const userManagerPayoutConfigs = [
  {
    display: 'Take Rate on Opportunities',
    placeholder: '0%',
    variable: 'takeRateOnOpportunities',
    formatValueForDisplay: val => `${val}% Cut on Opportunities`,
    formatValueForInput: val => {
      const value = String(val)
        .replace(/[^0-9.]/g, '')
        .trim();
      const valueCapped = _.min([100, _.max([0, parseFloat(value)])]);
      return String(valueCapped || '');
    },
    formatValueForServer: val => {
      return _.min([100, _.max([0, parseFloat(val)])]) || null;
    }
  }
];

export const getPermissionsForSpecificUserManager = userManager =>
  userManagerPermissions.map(data => {
    const selected = !!userManager[data.variable];
    return { ...data, selected };
  });

export const getPayoutConfigsForSpecificUserManager = userManager =>
  userManagerPayoutConfigs.map(data => {
    const value = userManager[data.variable];
    return { ...data, value };
  });
