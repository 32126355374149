import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import _ from 'lodash';
import './CodeResult.scss';

import { getCodeRateDisplay } from '../../Helpers/formatting';
import { getAllShopifyIntegrations } from '../../Helpers/user_helpers';
import { ACTIVE, EXPIRED, SCHEDULED } from '../../Helpers/code_constants';
import { getBrandDomain } from '../../Helpers/brand_helpers';

const CodeResult = props => {
  const { user, analytics, code } = props;
  const shopifyIntegrations = getAllShopifyIntegrations(user);
  const shopifyIntegration = _.find(shopifyIntegrations, i => i.id === code.BrandIntegration_id) || {};
  const { shopName, domain } = shopifyIntegration;

  const { createdAt, User_id, displayText, shopifyPriceRuleId, name, startsAt, endsAt } = code;

  const selectUser = async () =>
    props.openArtistModal({ id: User_id }, () => {
      const existingCode = _.find(analytics.brandAnalytics?.customCodes, r => r.User_id === User_id);
      existingCode && props.syncDataForCode(existingCode);
    });

  const getStatus = () => {
    const now = moment();
    let status = ACTIVE;

    if (now.isBefore(startsAt)) status = SCHEDULED;
    if (now.isAfter(endsAt)) status = EXPIRED;

    return status;
  };

  const status = getStatus();

  const shopifyCodeUrl = shopName && shopifyPriceRuleId ? `https://admin.shopify.com/store/${shopName}/discounts/${shopifyPriceRuleId}` : null;
  const showShopifyCodeDomain = shopifyCodeUrl && domain && domain !== getBrandDomain(user);

  return (
    <div className='code-result row'>
      <div className='cell'>{moment(createdAt).format('MMM Do, YYYY')}</div>
      <div onClick={selectUser} className='cell name'>
        {name}
      </div>
      <div className='cell address'>{displayText}</div>
      <div className='cell address'>{getCodeRateDisplay(code)}</div>
      {shopifyCodeUrl ? (
        <a href={shopifyCodeUrl} target='_blank' rel='noopener noreferrer' className='cell status shopify-link'>
          {status} on Shopify{showShopifyCodeDomain ? ` (${domain})` : ''}
        </a>
      ) : (
        <div className='cell status'>{status}</div>
      )}
      <div onClick={props.editCode} className={cn('cell action')}>
        EDIT
      </div>
    </div>
  );
};

CodeResult.propTypes = {
  user: PropTypes.object.isRequired,
  code: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  editCode: PropTypes.func.isRequired,
  syncDataForCode: PropTypes.func.isRequired
};

export default CodeResult;
