import React from 'react';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faExclamationTriangle, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunityPlannerPastPerformance.scss';

import Loader from '../../Loader/Loader';
import Tooltip from '../../General/Tooltip';

import { getPlanUserFromUser } from '../../../Helpers/planning_helpers';
import { getUserTierDisplay } from '../../../Helpers/tier_helpers';
import { opportunityExpectsLinks } from '../../../Helpers/opportunity_helpers';

const OpportunityPlannerPastPerformance = props => {
  const { opportunity, activePlan, changeSort, sortBy, isFetchingFirstPage, results, isFetchingNextPage } = props;

  const getStatsForUser = user => JSON.parse(user.stats);

  const getPlanUserFromResult = result =>
    activePlan?.users.find(planUser => planUser.User_id === result.User_id && planUser.recommendedFixedFee === result.fixedFee);

  // UI State Management
  const [modifyingResultUserIds, setModifyingResultUserIds] = React.useState([]);
  const startModifyingResult = result => setModifyingResultUserIds([...modifyingResultUserIds, result.User_id]);
  const stopModifyingResult = result => setModifyingResultUserIds(modifyingResultUserIds.filter(id => id !== result.User_id));
  const isResultBeingModified = result => modifyingResultUserIds.includes(result.User_id);

  // Grid / Columns
  const pretty = num => (num ? '$' + commaNumber(num.toFixed(0)) : '-');
  const columns = [
    {
      display: 'Creator',
      getValue: user => user.name,
      getDisplay: user => {
        const openProfile = () =>
          props.openArtistModal({
            id: user.User_id,
            ...user
          });
        return (
          <div className='creator-cell'>
            <div className='image-container'>
              <img src={user.image} alt={user.name} />
            </div>
            <div className='data-container'>
              <div onClick={openProfile} className='name'>
                {user.name}
              </div>
              <div className='tier'>{getUserTierDisplay(user.tier)}</div>
            </div>
          </div>
        );
      }
    },
    {
      display: 'Opportunity',
      getValue: user => user.Opportunity_title,
      getDisplay: user => <div className='display secondary small'>{user.Opportunity_title}</div>
    },
    {
      display: 'Expectations',
      getValue: () => null,
      getDisplay: user => {
        const { Opportunity_linksExpected, Opportunity_linkingDaysExpected, Opportunity_mentionsExpected, Opportunity_mentionDaysExpected } = user;
        const getDisplay = (value, word) => (value ? `${value} ${word}${value === 1 ? '' : 's'}` : '');
        const previousExpectations = _.filter([
          getDisplay(Opportunity_linksExpected, 'link'),
          getDisplay(Opportunity_linkingDaysExpected, 'linking day'),
          getDisplay(Opportunity_mentionsExpected, 'mention'),
          getDisplay(Opportunity_mentionDaysExpected, 'mention day')
        ]);
        const previousExpectationsDisplay = previousExpectations.length ? previousExpectations.join(', ') : '-';
        const currentExpectations = _.filter([
          getDisplay(opportunity.linksExpected, 'link'),
          getDisplay(opportunity.linkingDaysExpected, 'linking day'),
          getDisplay(opportunity.mentionsExpected, 'mention'),
          getDisplay(opportunity.mentionDaysExpected, 'mention day')
        ]);
        const currentExpectationsDisplay = currentExpectations.length ? currentExpectations.join(', ') : '-';
        const showDifferentExpectationsAlert = previousExpectationsDisplay !== currentExpectationsDisplay;
        return (
          <div className='display with-icon small'>
            <span>{previousExpectationsDisplay}</span>
            {showDifferentExpectationsAlert && (
              <Tooltip
                message={`The expectations for this opportunity (${previousExpectationsDisplay}) differ from the current opportunity's expectations (${currentExpectationsDisplay}) and therefore the fees likely need to be adjusted.`}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Tooltip>
            )}
          </div>
        );
      }
    },
    ...(opportunityExpectsLinks(opportunity)
      ? [
          {
            display: 'Volume',
            sortByValue: 'volume',
            getValue: user => getStatsForUser(user).volume,
            getDisplay: user => pretty(getStatsForUser(user).volume)
          },
          {
            display: 'Volume ROI',
            sortByValue: 'volume_roi',
            getValue: user => getStatsForUser(user).volume / user.fixedFee,
            getDisplay: user => (getStatsForUser(user).volume ? (getStatsForUser(user).volume / user.fixedFee).toFixed(1) + 'x' : '-')
          }
        ]
      : [
          ({
            display: 'EMV',
            sortByValue: 'emv',
            getValue: user => getStatsForUser(user).emv,
            getDisplay: user => pretty(getStatsForUser(user).emv)
          },
          {
            display: 'EMV ROI',
            sortByValue: 'emv_roi',
            getValue: user => getStatsForUser(user).emv / user.fixedFee,
            getDisplay: user => (getStatsForUser(user).emv ? (getStatsForUser(user).emv / user.fixedFee).toFixed(1) + 'x' : '-')
          })
        ]),
    {
      display: 'Cost',
      sortByValue: 'recommended_cost',
      getValue: user => user.fixedFee,
      getDisplay: user => {
        const planUser = getPlanUserFromUser(activePlan, user);
        const cost = planUser?.recommendedFixedFee || user.fixedFee;
        const selectCost = () => props.specifyCostForUser(user);
        return (
          <div onClick={selectCost} className='cell icon-cell'>
            <div className='display'>
              <div className={cn('icon', { active: !!planUser })}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </div>
              {pretty(cost)}
            </div>
          </div>
        );
      }
    },
    {
      display: 'Actions',
      getValue: () => null,
      getDisplay: result => {
        const planUser = getPlanUserFromResult(result);
        const isModifying = isResultBeingModified(result);
        const isInPlan = !!planUser;

        const add = async () => {
          startModifyingResult(result);
          await props.addCreatorToPlan({
            id: result.User_id,
            recommended_cost: result.fixedFee
          });
          stopModifyingResult(result);
        };
        const remove = async () => {
          await props.removeCreatorFromPlan(planUser);
        };

        return (
          <div className='actions'>
            <div
              onClick={isModifying ? () => {} : isInPlan ? remove : add}
              className={cn('action', {
                remove: isInPlan,
                add: !isInPlan,
                loading: isModifying
              })}
            >
              {isInPlan ? 'Remove' : 'Add'}
              {isModifying && <Loader size={32} />}
            </div>
          </div>
        );
      }
    }
  ];

  // Loading
  const getLoadingRow = idx => (
    <div key={idx} className={cn('row loading', `grid-${columns.length}`)}>
      {columns.map((c, idx) => {
        return (
          <div key={idx} className='cell'>
            <Loader size={40} />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className='opportunity-planner-past-performance'>
      <div className={cn('results-header', `grid-${columns.length}`)}>
        {columns.map(column => {
          const { display, sortByValue } = column;
          const click = () => sortByValue && changeSort(sortByValue);
          const clickable = !!sortByValue;
          const isCurrentSort = sortBy === sortByValue;
          return (
            <div key={display} onClick={click} className={cn('header-cell', { clickable })}>
              <div className='display'>
                {display}
                {sortByValue && (
                  <FontAwesomeIcon
                    className={cn('sort-icn', {
                      'active-sort': isCurrentSort
                    })}
                    icon={props.sortDir === 'desc' ? faArrowDown : faArrowUp}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={cn('results-container', {
          'fetching-first-page': isFetchingFirstPage,
          'fetching-results': !results.length
        })}
      >
        {isFetchingFirstPage && !results.length
          ? _.range(4).map(getLoadingRow)
          : results.map(result => {
              const isInPlan = !!getPlanUserFromResult(result);
              return (
                <div key={result.id} className={cn('row', `grid-${columns.length}`, { 'in-plan': isInPlan })}>
                  {columns.map(column => {
                    const { getDisplay } = column;
                    const display = getDisplay(result);
                    return <div className='cell'>{typeof display === 'object' ? display : <div className={cn('display', {})}>{display}</div>}</div>;
                  })}
                </div>
              );
            })}
        {isFetchingNextPage && (
          <div className={cn('row loading', `grid-${columns.length}`)}>
            {columns.map(c => {
              return (
                <div className='cell'>
                  <Loader size={40} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

OpportunityPlannerPastPerformance.propTypes = {
  // Data
  results: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  activePlan: PropTypes.object,

  // UI
  changeSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  isFetchingFirstPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,

  // Actions
  addCreatorToPlan: PropTypes.func.isRequired,
  removeCreatorFromPlan: PropTypes.func.isRequired,
  updateCreatorInPlan: PropTypes.func.isRequired
};

export default OpportunityPlannerPastPerformance;
