import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { getMentions } from '../../../../APIClient/mentions';
import { getPins } from '../../../../APIClient/pins';
import { getBrandId } from '../../../../Helpers/user_helpers';

const LookbookReportData = props => {
  const { requests, lookbook, user } = props;
  const { setMentions, setPins, setMentionUserStats, setIsFetchingMentions, setIsFetchingPins } = props;

  const Brand_id = getBrandId(user);
  const Lookbook_id = lookbook.id;

  useEffect(() => {
    // fetch all the stats for the page
    if (!requests) return;
    const earliestAcceptedAt = _.minBy(requests, 'userAcceptedAt')?.userAcceptedAt;
    const startDate = props.startDate ? props.startDate : earliestAcceptedAt ? moment(earliestAcceptedAt).format('YYYY-MM-DD') : null;
    setIsFetchingMentions(true);
    setIsFetchingPins(true);

    getMentions(_.omitBy({ Lookbook_id, Brand_id, startDate, limit: 64, withFullUserStats: true }, _.isNil))
      .then(res => {
        setMentionUserStats(res.mention_user_stats);
        setMentions(res.mentions);
      })
      .catch(() => window.ALERT.error('Error fetching mentions'))
      .finally(() => setIsFetchingMentions(false));

    getPins(new URLSearchParams(_.omitBy({ Lookbook_id, Brand_id, limit: 1000, startDate, groupByMode: 'users' }, _.isNil)).toString())
      .then(res => setPins(res.results))
      .catch(() => window.ALERT.error('Error fetching pins'))
      .finally(() => setIsFetchingPins(false));
  }, []);

  return null;
};

LookbookReportData.propTypes = {
  lookbook: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,

  // state setters
  setMentions: PropTypes.func.isRequired,
  setMentionUserStats: PropTypes.func.isRequired,
  setPins: PropTypes.func.isRequired,
  setIsFetchingMentions: PropTypes.func.isRequired,
  setIsFetchingPins: PropTypes.func.isRequired
};

export default LookbookReportData;
