import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import './TalentResults.scss';

import TalentResult from './TalentResult';

import { isSubscribedToFeatureOrAdmin } from '../../Helpers/subscription_helpers';

const TalentResults = props => {
  const { filteredResults, user, ui } = props;
  const [numVisibleResults, setNumVisibleResults] = useState(25);
  const hasMoreResults = filteredResults.length > numVisibleResults;

  const showMoreResults = () => {
    setNumVisibleResults(numVisibleResults + 25);
  };

  const visibleResults = filteredResults.slice(0, numVisibleResults);
  const canAccess = isSubscribedToFeatureOrAdmin(user, 'TALENT_TAB');

  return (
    <div
      className={cn('talent-results-outer-container', {
        'cannot-view': !canAccess
      })}
    >
      {visibleResults.length ? (
        _.map(visibleResults, result => (
          <TalentResult
            key={result.id}
            user={user}
            ui={ui}
            analytics={props.analytics}
            history={props.history}
            result={result}
            curListId={props.curListId}
            curActionEnum={props.curActionEnum}
            curStatusEnum={props.curStatusEnum}
            openArtistModal={props.openArtistModal}
            showTalentDetails={props.showTalentDetails}
            syncTalentResult={props.syncTalentResult}
            createSamplesRequest={props.createSamplesRequest}
            updateRequest={props.updateRequest}
            setCustomCode={props.setCustomCode}
            updateCode={props.updateCode}
            setCustomCommissionRate={props.setCustomCommissionRate}
            updateChat={props.updateChat}
            openChatOverlay={props.openChatOverlay}
            openCodesModal={props.openCodesModal}
            openRequestModal={props.openRequestModal}
            openFulfillmentModal={props.openFulfillmentModal}
            augmentBrandLists={props.augmentBrandLists}
          />
        ))
      ) : props.curSearchVal ? (
        <div className='empty-results'>
          <div className='explanation'>
            No Results For "{props.curSearchVal}". You can search the entire talent pool, including non-promoters from the Discover page.
          </div>
          <div onClick={props.clearFiltersAndSearch} className='action-btn'>
            Clear Search
          </div>
        </div>
      ) : (
        <div className='empty-results'>
          <div className='explanation'>No More Results</div>
          <div onClick={props.clearFiltersAndSearch} className='action-btn'>
            Clear Filters
          </div>
        </div>
      )}
      {hasMoreResults && (
        <div className='show-more-btn' onClick={showMoreResults}>
          Show More
        </div>
      )}
    </div>
  );
};

TalentResults.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  curSearchVal: PropTypes.string.isRequired,
  curListId: PropTypes.number,
  curActionEnum: PropTypes.string,
  curStatusEnum: PropTypes.string,
  filteredResults: PropTypes.array.isRequired,
  talent: PropTypes.object.isRequired,
  syncTalentResult: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  clearFiltersAndSearch: PropTypes.func.isRequired,
  augmentBrandLists: PropTypes.func.isRequired,

  // Gifting
  createSamplesRequest: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,

  // Codes
  setCustomCode: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,

  // Rates
  setCustomCommissionRate: PropTypes.func.isRequired,

  // Chat
  updateChat: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,

  // Modals
  openCodesModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired
};

export default withRouter(TalentResults);
