import _ from 'lodash';
import { getSmartImage } from './helpers';

const socialSharingValidPinFilter = pin => {
  const { image } = pin;

  if (!image) return false;
  else if (image?.includes('sephora.com')) return false;
  else return true;
};

/**
 * Here is the prioritization logic:
 * 1. shopmy images (shopmy or myshlf)
 * 2. shopify images
 * 3. other images
 *
 * @param {string=} image - a pin image
 * @returns
 */
const getImagePriority = image => {
  if (_.includes(image, 'shopmy') || _.includes(image, 'myshlf')) return 1;
  if (_.includes(image, 'shopify')) return 2;
  return 3;
};

export const filterAndSortPinsForSocialSharing = pins => {
  if (!pins || !pins.length) return [];

  const filteredPins = pins.filter(socialSharingValidPinFilter);
  const sortedPins = _.orderBy(filteredPins, [pin => getImagePriority(_.get(pin, 'image'))], ['asc']);
  const convertedPins = sortedPins.map(pin => ({ ...pin, image: getSmartImage(pin.image) }));

  return convertedPins;
};
