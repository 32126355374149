import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faEye,
  faCopy,
  faCabinetFiling,
  faEyeSlash,
  faArrowAltUp,
  faLevelDown,
  faChevronLeft,
  faCheck
} from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './CollectionActionItemsContainer.scss';

import Loader from '../Loader/Loader';

const CollectionActionItemsContainer = props => {
  const { collection, sections } = props;

  const [isTakingActionIdx, setIsTakingActionIdx] = useState(false);
  const [isMovingSections, setIsMovingSections] = useState(null);

  const actions = isMovingSections
    ? [
        {
          display: 'Back',
          actionFn: () => setIsMovingSections(false),
          icon: faChevronLeft
        },
        ...sections.map(section => {
          const isActive = section.id === collection.Section_id;
          return {
            display: section.title?.toUpperCase(),
            actingDisplay: 'Moving...',
            actionFn: isActive ? null : () => props.updateSection(section),
            icon: isActive ? faCheck : faLevelDown
          };
        })
      ]
    : [
        { display: 'Delete', actingDisplay: 'Deleting...', actionFn: props.deleteCollection, icon: faTrash },
        {
          display: collection.private ? `Show` : `Hide`,
          actingDisplay: collection.private ? 'Showing...' : 'Hiding...',
          actionFn: props.toggleVisibility,
          icon: collection.private ? faEye : faEyeSlash
        },
        {
          display: 'Change Section',
          actionFn: () => setIsMovingSections(true),
          icon: faLevelDown
        },
        {
          display: collection.isArchived ? 'Unarchive' : `Archive`,
          actingDisplay: collection.isArchived ? 'Unarchiving...' : `Archiving...`,
          actionFn: props.toggleArchive,
          icon: faCabinetFiling
        },
        { display: 'Duplicate', actingDisplay: 'Duplicating...', actionFn: props.duplicateCollection, icon: faCopy },
        { display: 'Move To Top', actingDisplay: 'Moving...', actionFn: props.index ? props.moveToTop : null, icon: faArrowAltUp }
      ];

  return (
    <OutsideClickHandler onOutsideClick={props.hideActionItems}>
      <div className='collection-action-items-container'>
        {actions.map((action, idx) => {
          const { display, actingDisplay, icon, actionFn } = action;
          const isTakingAction = isTakingActionIdx === idx;
          const click = async e => {
            e.preventDefault();
            e.stopPropagation();
            setIsTakingActionIdx(idx);
            await actionFn();
            setIsTakingActionIdx(null);
          };
          if (!actionFn) return null;
          return (
            <div onClick={click} key={`collection-action-item-container-${idx}`} className={cn('item', { loading: isTakingAction })}>
              {isTakingAction ? <Loader size={20} /> : <FontAwesomeIcon icon={icon} />}
              {isTakingAction ? actingDisplay || display : display}
            </div>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
};

CollectionActionItemsContainer.propTypes = {
  collection: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  hideActionItems: PropTypes.func.isRequired,
  deleteCollection: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  toggleArchive: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  moveToTop: PropTypes.func
};

export default CollectionActionItemsContainer;
