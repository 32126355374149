import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './StripeConfigurationPanel.scss';

import { connect } from 'react-redux';
import { updateBrandSettings } from '../../Actions/BrandActions';
import { getBrandSettings } from '../../Helpers/user_helpers';
import { isAdminControlMode } from '../../Helpers/ui_helpers';

import { confirmAlert } from 'react-confirm-alert';

const StripeConfigurationPanel = props => {
  const { user, ui, updateBrandSettings } = props;
  const brandSettings = getBrandSettings(user);

  const { mustInvoiceManually, canAutoCharge } = brandSettings;

  const turnOnAutoCharge = () => {
    if (isAdminControlMode(ui)) updateBrandSettings({ canAutoCharge: true });
    else {
      return confirmAlert({
        title: 'Just confirming',
        message:
          'By enabling this feature, your monthly invoice will be automatically paid by your default payment method without your review. Are you sure you want to enable this?',
        buttons: [
          { label: 'No', onClick: () => {}, className: 'cancel' },
          {
            label: 'Yes',
            onClick: () => {
              updateBrandSettings({ canAutoCharge: true });
            }
          }
        ]
      });
    }
  };

  const turnOffAutoCharge = () => {
    if (isAdminControlMode(ui)) updateBrandSettings({ canAutoCharge: false });
    else
      return confirmAlert({
        title: 'Confirm With Your Manager',
        message: `Before turning this feature off, please first confirm with your brand success manager. By disabling this, you will have to manually review and pay your invoice each month.`,
        buttons: [{ label: 'Ok', onClick: () => {}, className: 'cancel' }]
      });
  };

  return (
    <div className='stripe-configuration-panel-outer'>
      <div className='stripe-configuration-panel-inner'>
        <div className='label'>Configure Invoicing</div>

        <div className='checkboxes'>
          {isAdminControlMode(ui) && (
            <div
              className={cn('checkbox-container', { selected: !!mustInvoiceManually })}
              onClick={() => {
                updateBrandSettings({ mustInvoiceManually: !mustInvoiceManually });
              }}
            >
              <div className='checkbox' />
              <div className='checkbox-text'>
                <div className='checkbox-label'>Invoice Manually</div>
                <div className='checkbox-description'>
                  Instead of being automatically sent an invoice, you will have to manually pay in some other method.
                </div>
              </div>
            </div>
          )}
          <div className={cn('checkbox-container', { selected: !!canAutoCharge })} onClick={!!canAutoCharge ? turnOffAutoCharge : turnOnAutoCharge}>
            <div className='checkbox' />
            <div className='checkbox-text'>
              <div className='checkbox-label'>Allow Auto Charging</div>
              <div className='checkbox-description'>
                When enabled, instead of receiving an invoice that you must pay manually, we will automatically charge your card for the amount due.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StripeConfigurationPanel.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  updateBrandSettings
})(StripeConfigurationPanel);
