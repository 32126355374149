import _ from 'lodash';
import moment from 'moment';
import * as ActionTypes from '../Actions/AttributionActions';

const DAYS_TO_STORE_ATTRIBUTION = 30;

export const attribution = (
  state = {
    params: {},
    params_expiration: {}
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.STORE_ATTRIBUTION_FROM_URL:
      // Merge new params with existing ones
      const new_params = { ...state.params, ...action.attribution_params };

      // Set expiration for new params
      const new_params_expiration = {
        ...state.params_expiration,
        ..._.mapValues(action.attribution_params, () =>
          moment()
            .add(DAYS_TO_STORE_ATTRIBUTION, 'days')
            .valueOf()
        )
      };

      // Remove expired params
      _.forEach(state.params_expiration, (expiration, key) => {
        if (expiration < moment().valueOf()) {
          delete new_params[key];
          delete new_params_expiration[key];
        }
      });

      return {
        ...state,
        params: new_params,
        params_expiration: new_params_expiration
      };

    default:
      return state;
  }
};
