import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencil, faExternalLink, faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import './ContractCollection.scss';

import ContractPin from './ContractPin';

import { getRootUrlForUser } from '../../../Helpers/user_helpers';
import { getRepresentativeImageForCollection } from '../../../Helpers/store_helpers';
import { copyToClipboard } from '../../../Helpers/helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';
import { getShortCollectionLink } from '../../../Helpers/attribution_helpers';

const ContractCollection = props => {
  const { contract, collection, canEdit, isPreviewMode } = props;
  const { name, analytics, pins, createdAt } = collection;
  const { clicks, volume } = analytics || {};
  const [showingAllPins, setShowingAllPins] = useState(false);
  const image = getRepresentativeImageForCollection(collection);
  const scrollToTop = () => window.scrollTo(0, 0);
  const longLink = getRootUrlForUser(contract.user) + `/collections/${collection.id}`;
  const shortLink = getShortCollectionLink(collection);
  return (
    <>
      <div className='contract-content-element collection'>
        <div className={cn('main', { 'has-shareable-link': canEdit })}>
          <div className='image-container'>{image ? <img src={image} alt={name} /> : <div className='empty-img' />}</div>
          <div className='data'>
            <div className='title'>{name}</div>
            {analytics ? (
              <div className='performance-analytics'>
                {clicks ? `${getPrettyNumber(clicks)} click${clicks === 1 ? '' : 's'}` : ''}
                {volume ? `${clicks ? `, ` : ''}$${getPrettyNumber(volume)} order volume` : ''}
                {!clicks && !volume ? (canEdit ? `Created On ${moment(createdAt).format('MMMM Do')}` : `No click activity yet`) : ''}
              </div>
            ) : (
              <div className='performance-analytics'>Calculating Performance...</div>
            )}
            {!!pins?.length && (
              <div onClick={() => setShowingAllPins(!showingAllPins)} className='see-more'>
                {showingAllPins ? 'Hide' : 'Show'} Product Breakdown
                <FontAwesomeIcon icon={showingAllPins ? faChevronUp : faChevronDown} />
              </div>
            )}
          </div>
        </div>
        <div className='secondary'>
          {canEdit ? (
            <>
              <div className='cell lg short-link'>
                <div className='link'>{shortLink}</div>
                <div className='copy-btn' onClick={() => copyToClipboard(shortLink, true)}>
                  Copy
                </div>
              </div>
              {!isPreviewMode && (
                <div className='link-actions'>
                  <a href={longLink} target='_blank' rel='noopener noreferrer' className='link-action link'>
                    <FontAwesomeIcon icon={faExternalLink} />
                  </a>
                  <Link onClick={scrollToTop} to={`/collections/${collection.id}`} rel='noopener noreferrer' className='link-action edit'>
                    <FontAwesomeIcon icon={faPencil} />
                  </Link>
                  <div onClick={() => props.deleteCollection(collection)} className='link-action delete'>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className='link-actions'>
              <a href={longLink} target='_blank' rel='noopener noreferrer' className='link-action link'>
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </div>
          )}
        </div>
      </div>
      {showingAllPins && (
        <div className='contract-collection-pins'>
          {pins.map(pin => (
            <ContractPin contract={contract} pin={pin} key={pin.id} isPreviewMode={isPreviewMode} canEdit={false} />
          ))}
        </div>
      )}
    </>
  );
};

ContractCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  deleteCollection: PropTypes.func.isRequired,

  isPreviewMode: PropTypes.bool
};

export default ContractCollection;
