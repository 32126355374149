import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import cn from 'classnames';
import './Select.scss';

import SelectOption from './SelectOption';

const ShopMySelect = props => {
  /*
    A Styled Select Component that can be used to select options from a dropdown.

      Example:
        <Select
          noStyling
          options={[
            { value: 1, label: 'Option 1', sublabel: 'Sublabel 1'},
            { value: 2, label: 'Option 2', sublabel: 'Sublabel 2'},
          ]}
          value={value}
          placeholder='Select an option'
          onChangeValue={newValue => setValue(newValue)}
          isSearching={false}
          isDisabled={false}
          isLoading={false}
        />
  */
  const selectedOption =
    props.value && // Not set
    (props.options.find(option => option.value === props.value) || // One level
      _.flatten(_.map(props.options, o => o.options || [])).find(option => option.value === props.value)); // Two levels

  const onChange = option => props.onChangeValue(option.value);
  return (
    <>
      <Select
        className={cn('shopmy-select', props.className, {
          'with-styling': !props.noStyling,
          'without-styling': props.noStyling,
          'is-dark': props.isDark
        })}
        classNamePrefix='shopmy-select'
        options={props.options}
        value={selectedOption}
        onChange={onChange}
        components={{ Option: SelectOption }}
        placeholder={props.placeholder}
        isSearchable={!props.disableSearch}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
      />
    </>
  );
};

ShopMySelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,

  // Optional Functionality
  disableSearch: PropTypes.bool, // To turn off the ability to search filters

  // Optional Styling
  isDark: PropTypes.bool, // To make the select dark for white backgrounds
  className: PropTypes.string, // To add a class to the select
  noStyling: PropTypes.bool // To turn off the styling
};

export default ShopMySelect;
