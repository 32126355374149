import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEyeSlash, faComment, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faComment as faCommentSolid, faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './OtherFeatures.scss';

import { getTalentById } from '../../../Helpers/talent_helpers';
import { getSmartArtistBio } from '../../../Helpers/user_helpers';
import { getInitialsForUser } from '../../../Helpers/formatting';

import DiscoverFeatureActions from './DiscoverFeatureActions';
import SortableList from '../../General/SortableList';
import BrandListsOverlay from '../../General/BrandListsOverlay';

const OtherFeatures = props => {
  const { features } = props;

  const getCard = (feature, additionalProps = {}) => {
    const { list, preview_users } = feature;
    const { title, description, lookalike } = list;
    const { rearrangeHandle } = additionalProps;
    const openLookalikeProfile = () => (lookalike ? props.openArtistModal({ id: lookalike.id }) : null);

    return (
      <div className={cn('other-feature feature', { hidden: feature.isHidden })} key={list.id}>
        <div className='header-section'>
          <div onClick={openLookalikeProfile} className={cn('title-container', { 'is-lookalike': lookalike })}>
            {rearrangeHandle}
            <div className='title'>
              {lookalike?.image && <img alt={lookalike.name} className='lookalike-image' src={lookalike.image} />}
              {title}
              {!!feature.isHidden && <FontAwesomeIcon className='hidden-icon' icon={faEyeSlash} />}
            </div>
            {description && <div className='description'>{description}</div>}
          </div>
          <div onClick={() => props.goToList(list)} className='view-btn'>
            SHOW ALL
          </div>
        </div>
        <div className='preview-users'>
          {preview_users?.length
            ? preview_users.slice(0, 5).map((user, index) => <PreviewUser key={user.User_id} user={user} {...props} />)
            : [...Array(5).keys()].map((user, index) => <PreviewUser key={index} isPlaceholder {...props} />)}
          {props.isEditing && (
            <DiscoverFeatureActions
              feature={feature}
              deleteDiscoverFeature={props.deleteDiscoverFeature}
              updateDiscoverFeature={props.updateDiscoverFeature}
            />
          )}
        </div>
      </div>
    );
  };

  if (!features.length) return null;
  return (
    <SortableList
      isEditing={props.isEditing}
      containerClassName='other-features'
      items={features}
      updateItem={props.updateDiscoverFeature}
      props={props}
      getCard={getCard}
    />
  );
};

const PreviewUser = props => {
  const { user, isPlaceholder } = props;
  const talent = user && getTalentById(props.talent, user.id);
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false);
  const toggleOverlay = () =>
    props.isEditing ? window.ALERT.warn('You cannot add users to a list while editing the discover page.') : setIsOverlayOpen(!isOverlayOpen);
  const openProfile = () => props.openArtistModal({ id: user.User_id || user.id }, null, 'Discover Preview List');
  const openChat = () => props.openChatOverlay({ id: user.User_id || user.id, image: user.image, name: user.name }, 'Discover Preview List');

  const description = getSmartArtistBio(user);

  return (
    <div className='preview-user'>
      <div className='image-container'>
        {isPlaceholder ? (
          <div className='placeholder' />
        ) : user.image ? (
          <img src={user.image} alt={user.name} />
        ) : (
          <div className='initials'>{getInitialsForUser(user)}</div>
        )}
        {user && (
          <div className='action-btns'>
            <FontAwesomeIcon onClick={openProfile} className='action-icon' icon={faUser} />
            <FontAwesomeIcon onClick={openChat} className='action-icon' icon={talent?.has_chatted ? faCommentSolid : faComment} />
            <BrandListsOverlay closeOverlay={toggleOverlay} isActive={isOverlayOpen} selectedUser_id={user.User_id || user.id}>
              <FontAwesomeIcon onClick={toggleOverlay} className='action-icon' icon={talent?.listUsers?.length ? faHeartSolid : faHeart} />
            </BrandListsOverlay>
          </div>
        )}
      </div>
      <div onClick={openProfile} className='data'>
        <div className={cn('name', { placeholder: isPlaceholder })}>{isPlaceholder ? '-' : user.name}</div>
        <div className={cn('description', { placeholder: isPlaceholder })}>{isPlaceholder ? '-' : description}</div>
      </div>
    </div>
  );
};

OtherFeatures.propTypes = {
  talent: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
  deleteDiscoverFeature: PropTypes.func.isRequired,
  updateDiscoverFeature: PropTypes.func.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  goToList: PropTypes.func.isRequired
};

export default OtherFeatures;
