import React, { Component } from 'react';
// import PropTypes from 'prop-types'

import './Terms.scss';
import './Basic.scss';

class Terms extends Component {
  render() {
    return (
      <div className='terms-outer-container basic-outer-container'>
        <div className='basic-header-container narrow'>
          <div className='basic-header-title-badge-container'>
            <h1 className='basic-header-title-badge'>TERMS & PRIVACY</h1>
          </div>
          <h2 className='basic-header-body simple'>
            Click <a href='https://static.shopmy.us/Miscellaneous/Shop+My+Shelf+End+User+Agreement.pdf'>here</a> to view our terms and conditions and{' '}
            <a href='https://static.shopmy.us/Privacy_Policy.pdf'>here</a> to view our Privacy Policy.
          </h2>
        </div>
      </div>
    );
  }
}

export default Terms;
