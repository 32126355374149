import React from 'react';
import PropTypes from 'prop-types';
import './LookbookOrderModalRegister.scss';
import SimpleAuthPanel from '../../../Auth/SimpleAuthPanel';
import { useLocation } from 'react-router-dom';

const LookbookOrderModalRegister = props => {
  const { lookbook } = props;
  const { brand } = lookbook;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const LookbookInvite_hash = queryParams.get('invite');

  const login = () => {
    props.openAuthModal();
  };

  return (
    <div className='lookbook-order-modal-register-container'>
      <div className='main-container'>
        <div className='title-container'>
          <div className='title'>Complete Your Order</div>
          <div className='subtitle'>
            Please fill out the below information to have your gift shipped out to you. By completing the form, you'll also be joining our affiliate
            platform, ShopMy, where you'll be able to create commissionable links to {brand.name} products.
            <br />
            <br /> If you already have a ShopMy account, please click{' '}
            <span onClick={login} className='login'>
              here
            </span>{' '}
            to log in.
          </div>
        </div>
        <div className='registration-form'>
          <SimpleAuthPanel inverted ReferringLookbook_id={lookbook.id} LookbookInvite_hash={LookbookInvite_hash} />
        </div>
      </div>
    </div>
  );
};

LookbookOrderModalRegister.propTypes = {
  user: PropTypes.object.isRequired
};

export default LookbookOrderModalRegister;
