import Amplify from 'aws-amplify';
import { getUrlParam } from './Helpers/helpers';

let AWSConfig;

/*
  We need to handle conversion of URL parameters from various Oauth sources to scoped tokens
  in order to bypass Cognito which always strips the code parameter from the URL.
*/

// Youtube and Tiktok
if (window.location.href && window.location.href.includes('/social_auth/')) {
  if (window.location.href.includes('social_auth/youtube')) {
    window.history.pushState(null, null, window.location.href.replace('?code=', '?yt_code='));
  }
  if (window.location.href.includes('social_auth/tiktok')) {
    window.history.pushState(null, null, window.location.href.replace('?code=', '?tiktok_code='));
  }
}

// Handle Shopify Authorization
const isFromShopify = window.location.href && getUrlParam('shop') && getUrlParam('hmac') && getUrlParam('code');
if (isFromShopify) {
  window.history.pushState(null, null, window.location.href.replace('code=', 'shopify_code='));
}

if (process.env.REACT_APP_NODE_ENV === 'production') {
  AWSConfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_j8WO80PTk',
      userPoolWebClientId: 'bhrcscd30tp6nkn3gqohb6rbe',
      oauth: {
        redirectSignIn: 'https://shopmy.us/',
        redirectSignOut: 'https://shopmy.us/',
        responseType: 'token',
        domain: 'staging-ask-emma.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'username', 'aws.cognito.signin.user.admin']
      },
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  };
} else {
  AWSConfig = {
    Auth: {
      region: 'us-east-2',
      userPoolId: 'us-east-2_j8WO80PTk',
      userPoolWebClientId: 'bhrcscd30tp6nkn3gqohb6rbe',
      oauth: {
        redirectSignIn: 'http://staging.shopmyshelf.io/',
        redirectSignOut: 'http://staging.shopmyshelf.io/',
        responseType: 'token',
        domain: 'staging-ask-emma.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'username', 'aws.cognito.signin.user.admin']
      },
      authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  };
}

Amplify.configure(AWSConfig);

const socialParams = {
  ClientId: AWSConfig.Auth.userPoolWebClientId,
  UserPoolId: AWSConfig.Auth.userPoolId,
  AppWebDomain: AWSConfig.Auth.oauth.domain,
  TokenScopesArray: ['email', 'profile'],
  RedirectUriSignIn: AWSConfig.Auth.oauth.redirectSignIn,
  RedirectUriSignOut: AWSConfig.Auth.oauth.redirectSignOut,
  ResponseType: 'token'
};

// These are custom url's that you can direct the user to which
// begin the login process. Alternatively:
// https://aws-amplify.github.io/docs/js/authentication#oauth-and-hosted-ui
// That has a better approach
AWSConfig.facebookUrl =
  'https://' +
  socialParams.AppWebDomain +
  '/oauth2/authorize' +
  '?redirect_uri=' +
  socialParams.RedirectUriSignIn +
  '&response_type=token' +
  '&client_id=' +
  socialParams.ClientId +
  '&identity_provider=Facebook';

AWSConfig.googleUrl =
  'https://' +
  socialParams.AppWebDomain +
  '/oauth2/authorize' +
  '?redirect_uri=' +
  socialParams.RedirectUriSignIn +
  '&response_type=token' +
  '&client_id=' +
  socialParams.ClientId +
  '&identity_provider=Google';

AWSConfig.socialParams = socialParams;

export default AWSConfig;
