import React from 'react';
import PropTypes from 'prop-types';
import './LookbooksAnnouncement.scss';

import ArrowButton from '../../General/Buttons/ArrowButton';

const LookbooksAnnouncement = props => {
  // const {} = props;
  const pressActionOne = () => props.closeModal();
  const pressActionTwo = () => props.openChat(`Could we schedule a demo of Lookbooks?`);
  return (
    <div className='lookbooks-announcement-outer-container'>
      <div className='main-panel'>
        <div className='badge'>New Feature!</div>
        <div className='title'>Automated Gifting via Lookbooks</div>
        <div className='body'>
          Our new Lookbooks feature simplifies the influencer gifting process for you and your creators. You can effortlessly create digital product
          catalogs and send them to targeted influencers, while creators can conveniently select the products they want to try.
          <br />
          <br />
          This streamlined approach makes product seeding more straightforward, boosts engagement, and most importantly, leads to time and cost
          savings for you, all while ensuring transparency in gifting campaigns.
        </div>
        <div className='continue-btn-container'>
          <ArrowButton displayText='Explore Now' onClick={pressActionOne} />
          <ArrowButton displayText='Book Demo With Us' onlyText onClick={pressActionTwo} />
        </div>
      </div>
      <img src='https://static.shopmy.us/Website/lookbooks_preview.png' alt='lookbooks' />
    </div>
  );
};

LookbooksAnnouncement.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  openChat: PropTypes.func.isRequired
};

export default LookbooksAnnouncement;
