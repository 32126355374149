import { updateAnnouncement as updateAnnouncementAPI } from '../APIClient/announcements';

export const MARK_ANNOUNCEMENT_AS_READ_REQUEST = 'MARK_ANNOUNCEMENT_AS_READ_REQUEST';
export const MARK_ANNOUNCEMENT_AS_READ_SUCCESS = 'MARK_ANNOUNCEMENT_AS_READ_SUCCESS';
export const MARK_ANNOUNCEMENT_AS_READ_ERROR = 'MARK_ANNOUNCEMENT_AS_READ_ERROR';

export const markAnnouncementAsRead = announcement => async (dispatch, getState) => {
  dispatch({ type: MARK_ANNOUNCEMENT_AS_READ_REQUEST, announcement });
  try {
    await updateAnnouncementAPI(announcement, { hasBeenSeen: true });
    return dispatch({ type: MARK_ANNOUNCEMENT_AS_READ_SUCCESS, announcement });
  } catch (error) {
    return dispatch({ type: MARK_ANNOUNCEMENT_AS_READ_ERROR, announcement });
  }
};
