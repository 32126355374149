import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import './OpportunityPlanner.scss';

import Modal from '../General/Modal';
import OpportunityPlannerControls from './Elements/OpportunityPlannerControls';
import OpportunityPlannerData from './Elements/OpportunityPlannerData';
import ConfirmPrompt from '../General/ConfirmPrompt';

import OpportunityPlannerMonthlyPromoters from './Planners/OpportunityPlannerMonthlyPromoters';
import OpportunityPlannerAllTalent from './Planners/OpportunityPlannerAllTalent';
import OpportunityPlannerPastPerformance from './Planners/OpportunityPlannerPastPerformance';

import {
  createOpportunityPlan,
  createOpportunityPlanUser,
  updateOpportunityPlanUser,
  deleteOpportunityPlanUser
} from '../../Actions/OpportunityActions';
import { openArtistModal } from '../../Actions/UIActions';
import { blockOnRequiringSubscriptionToFeature } from '../../Helpers/subscription_helpers';
import { getPlanUserFromUser } from '../../Helpers/planning_helpers';
import { prepopulateIntercomMessage } from '../../Helpers/chat_helpers';

const OpportunityPlanner = props => {
  const { user, analytics, activePlan, opportunity, openArtistModal } = props;

  // Actions
  const addCreatorToPlan = async creator => {
    // Temporary validation
    const requiredFields = ['id', 'recommended_cost'];
    if (!requiredFields.every(field => creator[field])) return console.error('Creator is missing required fields:', creator);

    // Add plan if it doesn't exist
    let plan = props.activePlan;
    if (!props.activePlan) {
      const resp = await props.createOpportunityPlan({
        Opportunity_id: opportunity.id,
        title: 'New Plan',
        linksExpected: opportunity.linksExpected || 0,
        linkingDaysExpected: opportunity.linkingDaysExpected || 0,
        mentionDaysExpected: opportunity.mentionDaysExpected || 0,
        mentionsExpected: opportunity.mentionsExpected || 0
      });
      if (!resp.plan) return; // Error messaging handled in reducer
      plan = resp.plan;
    }

    // Add user to plan
    await props.createOpportunityPlanUser({
      User_id: creator.id,
      OpportunityPlan_id: plan.id,
      recommendedFixedFee: creator.recommended_cost
    });
  };

  const removeCreatorFromPlan = async planUser => {
    await props.deleteOpportunityPlanUser(planUser);
  };

  const updateCreatorInPlan = async (planUser, updates) => {
    await props.updateOpportunityPlanUser(planUser, updates);
  };

  const specifyCostForUser = async user => {
    const planUser = getPlanUserFromUser(activePlan, user);
    const cost = planUser?.recommendedFixedFee || user.recommended_cost || user.fixedFee;
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header={planUser ? 'Update Cost' : 'Customize Cost and Add to Plan'}
          subheader={
            planUser
              ? `Update how much you want to pay ${user.name} for this opportunity. This will not affect the cost for other creators in the plan.`
              : `Customize how much you want to pay ${user.name} for this opportunity`
          }
          onCancel={onClose}
          customInputFields={[
            {
              preloaded: cost,
              value: 'cost',
              isSingleLine: true
            }
          ]}
          onSubmit={({ cost }) => {
            if (!cost) return;
            planUser
              ? updateCreatorInPlan(planUser, { recommendedFixedFee: +cost })
              : addCreatorToPlan({ id: user.User_id || user.id, recommended_cost: +cost });
            onClose();
          }}
        />
      )
    });
  };

  // Data Management
  const [curSearchVal, setCurSearchVal] = React.useState('');
  const [curFilters, setCurFilters] = React.useState({});
  const [page, setPage] = React.useState(0);
  const [results, setResults] = React.useState([]);
  const [hasAllResults, setHasAllResults] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [generosityMultiple, setGenerosityMultiple] = React.useState(1); // Used to adjust cost for generosity
  const isFetchingRef = React.useRef(false); // Used to prevent multiple fetches on scroll
  const isFetchingFirstPage = isFetching && page === 0;
  const isFetchingNextPage = isFetching && page > 0;
  const [sortBy, setSortBy] = React.useState('total_volume');
  const [sortByMonth, setSortByMonth] = React.useState(null);
  const [sortDir, setSortDir] = React.useState('desc');
  const changeSort = (newSortBy, newSortByMonth) => {
    setSortByMonth(newSortByMonth);
    setSortBy(newSortBy);
    setHasAllResults(false);
    setPage(0);

    // If clicking twice, change direction, otherwise default desc
    const isClickingTwice = sortBy === newSortBy && sortByMonth === newSortByMonth;
    isClickingTwice ? setSortDir(sortDir === 'desc' ? 'asc' : 'desc') : setSortDir('desc');
  };

  // Scrolling
  const strategyResultsRef = React.useRef();
  const scrollToTopOfResults = () => {
    if (strategyResultsRef.current) strategyResultsRef.current.scrollTop = 0;
  };
  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = strategyResultsRef.current;
    const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
    if (distanceFromBottom < 250) {
      if (hasAllResults || isFetchingRef.current) return;
      setPage(page + 1);
    }
  };

  // Planning Strategies
  const [planningStrategyTitle, setPlanningStrategyTitle] = React.useState();
  const selectStrategy = strategy => {
    if (strategy?.noPanelOnClickInstead) return strategy.noPanelOnClickInstead();

    scrollToTopOfResults();
    setPlanningStrategyTitle(strategy?.title || null);

    // Reset values
    setCurSearchVal('');
    setCurFilters({});
    setPage(0);
    setGenerosityMultiple(1);
    setResults([]);
    setHasAllResults(false);
    setIsFetching(false);

    // Set Default Sort
    if (strategy?.defaultSort) {
      setSortBy(strategy.defaultSort);
      setSortByMonth(null);
      setSortDir('desc');
    }
  };
  const resetSettingsDueToFilterChange = () => {};
  const goBackToStrategySelection = () => selectStrategy(null);
  const propsForStrategies = {
    // Main Data
    user,
    opportunity,
    activePlan,
    results,

    // UI Actions
    openArtistModal,

    // Planning Actions
    specifyCostForUser,
    addCreatorToPlan,
    removeCreatorFromPlan,
    updateCreatorInPlan,

    // Statuses
    hasAllResults,

    // Sorting and Filtering
    changeSort,
    setPage,
    setHasAllResults,
    sortBy,
    sortByMonth,
    sortDir,
    isFetchingFirstPage,
    isFetchingNextPage,

    // Advanced Configuration
    generosityMultiple,
    setGenerosityMultiple
  };
  const strategies = [
    {
      type: 'monthly',
      defaultSort: 'total_volume',
      title: 'Monthly Promoter Performance',
      visibleFilterOptions: ['opportunities', 'lookbooks', 'lists'],
      image: 'https://static.shopmy.us/Opportunities/Planning/monthly_promoter_performance.png',
      subtitle: 'View and manage your monthly promoter performance to ensure consistent promotion of your brand.',
      panel: <OpportunityPlannerMonthlyPromoters {...propsForStrategies} />
    },
    {
      type: 'all',
      defaultSort: null,
      title: 'All Creators',
      visibleFilterOptions: ['lookbooks', 'lists', 'opportunities'],
      image: 'https://static.shopmy.us/Opportunities/Planning/all_creators.png',
      subtitle: 'Search the entire creator pool for specific creators to add to your opportunity plan.',
      panel: <OpportunityPlannerAllTalent {...propsForStrategies} />
    },
    {
      type: 'past_performance',
      defaultSort: 'volume_roi',
      title: 'Past Opportunity Performance',
      visibleFilterOptions: ['opportunities'], // Only filter by opportunities here for simplicity
      image: 'https://static.shopmy.us/Opportunities/Planning/past_performance.png',
      subtitle: 'View and manage your past performance on previous opportunities',
      panel: <OpportunityPlannerPastPerformance {...propsForStrategies} />
    },
    {
      type: 'custom',
      title: 'ShopMy Expert Planning',
      image: 'https://static.shopmy.us/Opportunities/Planning/custom.png',
      subtitle: 'Our team of experts will create a custom strategy for your opportunity to ensure maximal return.',
      noPanelOnClickInstead: () => {
        if (blockOnRequiringSubscriptionToFeature('OPPORTUNITIES_PLANNING')) return null;
        prepopulateIntercomMessage(`I'd like to create a custom strategy for my opportunity: ${opportunity.title}`);
      }
    }
  ];
  const planningStrategy = planningStrategyTitle ? strategies.find(strategy => strategy.title === planningStrategyTitle) : null;

  return (
    <Modal
      visible
      noPadding
      fitContent
      close={props.closePlanner}
      className='opportunity-planner-modal'
      innerClassName='opportunity-planner-inner'
      contentClassName='opportunity-planner-content'
    >
      {planningStrategy ? (
        <div ref={strategyResultsRef} onScroll={handleScroll} className='strategy-panel'>
          <div className='strategy-panel-header'>
            <div className='header-container'>
              <div className='back-btn' onClick={goBackToStrategySelection}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span>Back To Strategies</span>
              </div>
              <div className='header'>{planningStrategy.title}</div>
              <div className='subheader'>{planningStrategy.subtitle}</div>
            </div>
            <OpportunityPlannerControls
              user={user}
              analytics={analytics}
              planningStrategy={planningStrategy}
              activePlan={activePlan}
              curSearchVal={curSearchVal}
              setCurSearchVal={setCurSearchVal}
              curFilters={curFilters}
              setCurFilters={setCurFilters}
              setPage={setPage}
              setHasAllResults={setHasAllResults}
            />
          </div>
          <OpportunityPlannerData
            user={user}
            analytics={analytics}
            opportunity={opportunity}
            isFetching={isFetching}
            isFetchingRef={isFetchingRef}
            setIsFetching={setIsFetching}
            page={page}
            setPage={setPage}
            results={results}
            setResults={setResults}
            curSearchVal={curSearchVal}
            sortBy={sortBy}
            sortByMonth={sortByMonth}
            sortDir={sortDir}
            generosityMultiple={generosityMultiple}
            hasAllResults={hasAllResults}
            setHasAllResults={setHasAllResults}
            curFilters={curFilters}
            planningStrategy={planningStrategy}
          />
          {planningStrategy.panel}
        </div>
      ) : (
        <div className='strategy-selection-panel'>
          <div className='strategy-selection-header'>
            <div className='header'>Select your planning strategy</div>
            <div className='subheader'>How do you want to source talent for your opportunity?</div>
          </div>
          <div className='strategies'>
            {strategies.map((strategy, index) => (
              <div key={strategy.type} className='strategy' onClick={() => selectStrategy(strategy)}>
                <img src={strategy.image} alt={strategy.title} />
                <div className='data'>
                  <div className='title'>{strategy.title}</div>
                  <div className='subtitle'>{strategy.subtitle}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='close-icn'>
        <FontAwesomeIcon icon={faTimes} onClick={props.closePlanner} />
      </div>
    </Modal>
  );
};

OpportunityPlanner.propTypes = {
  // From inside
  user: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  createOpportunityPlan: PropTypes.func.isRequired,
  createOpportunityPlanUser: PropTypes.func.isRequired,
  updateOpportunityPlanUser: PropTypes.func.isRequired,
  deleteOpportunityPlanUser: PropTypes.func.isRequired,

  // From outside
  opportunity: PropTypes.object.isRequired,
  closePlanner: PropTypes.func.isRequired,
  activePlan: PropTypes.object
};

const mapStateToProps = state => {
  const { user, analytics } = state;
  return { user, analytics };
};

export default connect(mapStateToProps, {
  openArtistModal,
  createOpportunityPlan,
  createOpportunityPlanUser,
  updateOpportunityPlanUser,
  deleteOpportunityPlanUser
})(OpportunityPlanner);
