import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import './BlogPreviewContainer.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import * as blogHelpers from '../../Helpers/blog_helpers';

import BlogPostPreview from './BlogPostPreview';
import { Link } from 'react-router-dom';
import { isAdminControlMode } from '../../Helpers/ui_helpers';
import OutsideClickHandler from 'react-outside-click-handler';
import InputActions from '../../Components/General/InputActions';

const BlogPreviewContainer = props => {
  const { blog, ui } = props;
  const { isLoading, searchResults, searchValue, resetSearch, setSearchValue } = props;
  const { tabs } = blog;
  const [params, lastLocation, history] = [useParams(), useLastLocation(), useHistory()];
  const { tab_title } = params || {};

  const filteredTabs = tabs?.length ? tabs.filter(tab => !tab.isHidden && !blogHelpers.allPostsAreHiddenOnTab(tab)) : [];
  const needMoreButton = true;
  const [visibleTabTitle, setVisibleTabTitle] = useState(tab_title ? tab_title : filteredTabs?.length ? filteredTabs[0].title : '');
  const visibleTab = filteredTabs.find(tab => tab.title === visibleTabTitle) || {};
  const [viewAllTabsViewOpen, setViewAllTabsViewOpen] = useState(false);

  const isSearching = !!searchValue;
  const isFetchingTabs = !tabs?.length;
  const backupPosts = blogHelpers.getBlogBackupPosts(blog);

  useEffect(() => {
    const newVisibleTab = filteredTabs.find(tab => tab.title === tab_title) ? tab_title : filteredTabs?.length ? filteredTabs[0].title : '';
    if (newVisibleTab) setVisibleTabTitle(newVisibleTab);
  }, [tab_title, filteredTabs]);

  const updateTab = tab => {
    setVisibleTabTitle(tab.title);
    resetSearch();
  };

  const handleGoBack = () => {
    if (lastLocation?.pathname === '/blog') history.goBack();
    else history.push('/blog');
  };

  return (
    <div className='blog-preview-container-outer'>
      <div className='blog-preview-container-inner'>
        <div className='blog-header'>
          <div className='blog-title' onClick={handleGoBack}>
            The Brief
          </div>
          <div className='blog-button-container'>
            <div className='blog-search-container has-actions'>
              <input className='blog-search-input' placeholder='Search' value={searchValue} onChange={e => setSearchValue(e.target.value)} />
              <InputActions searchVal={searchValue} showSearchIcon onCancel={resetSearch} />
            </div>
          </div>
        </div>
        {!searchValue && (
          <div className='tab-link-container'>
            <div className='tab-link-container-tabs'>
              {(filteredTabs || []).map(tab => {
                return (
                  <Link
                    onClick={() => updateTab(tab)}
                    to={`/blog/${tab.title}`}
                    className={cn('tab-link', { selected: visibleTab?.title === tab.title })}
                    key={tab.title}
                  >
                    {tab.title}
                  </Link>
                );
              })}
            </div>

            {needMoreButton && (
              <OutsideClickHandler onOutsideClick={() => setViewAllTabsViewOpen(false)}>
                <div onClick={() => setViewAllTabsViewOpen(!viewAllTabsViewOpen)} className='tab-link view-all-tabs'>
                  All Tabs <FontAwesomeIcon icon={faChevronDown} />
                  {viewAllTabsViewOpen && (
                    <div className='view-all-tabs-container'>
                      {tabs.map(tab => {
                        if (!isAdminControlMode(ui) && (tab.isHidden || blogHelpers.allPostsAreHiddenOnTab(tab))) return null;
                        return (
                          <Link
                            onClick={() => updateTab(tab)}
                            to={`/blog/${tab.title}`}
                            className={cn('tab-link', { selected: visibleTab?.title === tab.title })}
                            key={`view all tabs ${tab.title}`}
                          >
                            {tab.title}
                          </Link>
                        );
                      })}
                    </div>
                  )}
                </div>
              </OutsideClickHandler>
            )}
          </div>
        )}
        <div className={cn('blog-tab-content-container', { isLoading })}>
          {isSearching && (
            <>
              <div className='search-results-header'>
                {isLoading
                  ? `Searching for "${searchValue}"...`
                  : `${searchResults?.length} ${searchResults?.length === 1 ? 'result' : 'results'} for "${searchValue}"`}
              </div>
              {searchValue && !searchResults?.length && <div className='explore-other-stories'>Explore Other Stories</div>}
            </>
          )}

          <div className='blog-post-preview-container'>
            {!!(isFetchingTabs || (searchValue && isLoading)) ? (
              <>
                {new Array(20).fill(0).map((_, index) => {
                  return (
                    <div key={index} className={cn('blog-preview-container-skeleton', { large: index === 0 })}>
                      <div className='skeleton-image'></div>
                      <div className='skeleton-text-content'>
                        <div className='skeleton-tag'></div>
                        <div className='skeleton-title'></div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : !!searchResults?.length ? (
              <>
                {searchResults.map((post, idx) => (
                  <>
                    <BlogPostPreview
                      resetSearch={resetSearch}
                      key={`blog post preview ${post.id}`}
                      post={post}
                      size={idx === 0 ? 'large' : 'small'}
                    />
                  </>
                ))}
              </>
            ) : !!(searchValue && !searchResults?.length) ? (
              <>
                {backupPosts.map(post => (
                  <BlogPostPreview resetSearch={resetSearch} key={`blog post preview ${post.id}`} post={post} size={'small'} />
                ))}
              </>
            ) : (
              _.orderBy(
                (visibleTab.posts || []).filter(post => (isAdminControlMode(ui) ? true : !post.isHidden)),
                'createdAt',
                'desc'
              ).map((blogPost, idx) => {
                const { isHidden } = blogPost;

                if (!isHidden) {
                  return (
                    <BlogPostPreview
                      resetSearch={resetSearch}
                      key={`blog post preview ${blogPost?.id}`}
                      post={blogPost}
                      size={idx === 0 ? 'large' : 'small'}
                    />
                  );
                } else if (isAdminControlMode(ui)) {
                  return (
                    <BlogPostPreview
                      resetSearch={resetSearch}
                      key={`blog post preview ${blogPost?.id}`}
                      post={blogPost}
                      size={idx === 0 ? 'large' : 'small'}
                    />
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

BlogPreviewContainer.propTypes = {
  // redux
  blog: PropTypes.object.isRequired,

  // state
  isLoading: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  searchResults: PropTypes.array,
  resetSearch: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog, ui } = state;
  return { blog, ui };
};

export default connect(mapStateToProps, {
  // deleteBlogPost,
})(BlogPreviewContainer);
