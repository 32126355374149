import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ConfirmPrompt from '../../General/ConfirmPrompt';
import './ContractLink.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';

let titleDebouncers = {};
let urlDebouncers = {};
let descriptionDebouncers = {};
const ContractLink = props => {
  const { link, contract, canEdit, isTemplate, updateContractLink, createContractLink } = props;
  const { product, Product_id } = link;

  const initialTitle = link.title || product?.title || '';
  const initialImage = link.image || product?.image || '';
  const initialUrl = link.url || product?.variants.find(v => v.retailer === contract.brand.name)?.url || '';
  const initialDescription = link.description || '';

  const [title, setTitle] = useState(initialTitle);
  const [image, setImage] = useState(initialImage);
  const [url, setUrl] = useState(initialUrl);
  const [description, setDescription] = useState(initialDescription);
  const [isCreatingFromTemplate, setIsCreatingFromTemplate] = useState(false);

  const changeImage = () => {
    confirmAlert({
      customUI: ({ onClose }) => (
        <ConfirmPrompt
          header='Please paste a new Image URL'
          preloaded={''}
          placeholder={'https://domain.com/product.png'}
          onCancel={onClose}
          isSingleLine
          onSubmit={newImageUrl => {
            newImageUrl && setImage(newImageUrl);
            newImageUrl && updateContractLink(link, { image: newImageUrl || null });
          }}
        />
      )
    });
  };

  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(titleDebouncers[link.id]);
    titleDebouncers[link.id] = setTimeout(() => {
      updateContractLink(link, { title: newTitle });
    }, 500);
  };

  const updateUrl = newUrl => {
    setUrl(newUrl);
    clearTimeout(urlDebouncers[link.id]);
    urlDebouncers[link.id] = setTimeout(() => {
      updateContractLink(link, { url: newUrl });
    }, 500);
  };

  const updateDescription = newDescription => {
    setDescription(newDescription);
    clearTimeout(descriptionDebouncers[link.id]);
    descriptionDebouncers[link.id] = setTimeout(() => {
      updateContractLink(link, { description: newDescription });
    }, 500);
  };

  const remove = () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to delete this link?`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        { label: 'Yes', onClick: () => props.deleteContractLink(link) }
      ]
    });
  };

  const addFromTemplate = async () => {
    setIsCreatingFromTemplate(true);
    await createContractLink(
      {
        ...(Product_id ? { Product_id } : {}),
        ...(initialTitle ? { title: initialTitle } : {}),
        ...(initialDescription ? { description: initialDescription } : {}),
        ...(initialImage ? { image: initialImage } : {}),
        ...(initialUrl ? { url: initialUrl } : {})
      },
      contract
    );
    setIsCreatingFromTemplate(true);
    props.postCreationCallback && props.postCreationCallback();
  };

  const extraClasses = { editable: canEdit, template: isTemplate };
  return (
    <div className={cn('contract-link', extraClasses)}>
      <div className='main'>
        <div className='image-container'>
          {(canEdit ? image : initialImage) ? <img src={canEdit ? image : initialImage} alt={title} /> : <div className='empty-img' />}
          {canEdit && (
            <div onClick={changeImage} className='edit-overlay'>
              Edit Image
            </div>
          )}
        </div>
        <div className='data'>
          {canEdit ? (
            <textarea
              rows={1}
              ref={ref => matchScrollHeight(ref)}
              value={title}
              className='title'
              placeholder='Title this Link'
              onChange={e => updateTitle(e.target.value)}
            />
          ) : (
            <div className='title'>{initialTitle}</div>
          )}
          {canEdit ? (
            <textarea
              rows={1}
              ref={ref => matchScrollHeight(ref)}
              value={url}
              className='url'
              placeholder='Paste in a URL'
              onChange={e => updateUrl(e.target.value)}
            />
          ) : (
            initialUrl && (
              <a href={initialUrl} target='_blank' rel='noopener noreferrer' className='url'>
                {initialUrl}
              </a>
            )
          )}

          {canEdit ? (
            <textarea
              rows={1}
              ref={ref => matchScrollHeight(ref)}
              value={description}
              className='description'
              placeholder='Add more detail about this link'
              onChange={e => updateDescription(e.target.value)}
            />
          ) : (
            initialDescription && <div className='description'>{initialDescription}</div>
          )}
        </div>
      </div>
      <div className='secondary'>
        {canEdit ? (
          <div className='link-actions'>
            <div onClick={remove} className='link-action delete'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        ) : isTemplate ? (
          <div onClick={addFromTemplate} className='add-from-template-btn'>
            <FontAwesomeIcon icon={faPlus} />
            {isCreatingFromTemplate ? 'Adding...' : 'Add Link'}
          </div>
        ) : (
          <div>{/*LINK HERE*/}</div>
        )}
      </div>
    </div>
  );
};

ContractLink.propTypes = {
  link: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,

  // For editing
  canEdit: PropTypes.bool,
  updateContractLink: PropTypes.func,
  deleteContractLink: PropTypes.func,
  createContractLink: PropTypes.func,
  postCreationCallback: PropTypes.func,
  isTemplate: PropTypes.bool
};

export default ContractLink;
