import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import './Header-PRO.scss';
import './Header-SMS.scss';

import MoreOptionsMenu from './MoreOptions/MoreOptionsMenu';
import InviteTalentModal from '../../Components/Modals/InviteTalentModal';

import { isPublicPage, isEmbedPage, isBeautyShopPage, isHomePage, isPromotePage, isReferralPage } from '../../Helpers/helpers';
import { getBrandTheme } from '../../Helpers/ui_helpers';
import { getUsername, isBrand, getNotificationCount } from '../../Helpers/user_helpers';
import { addEvent } from '../../APIClient/events';

const SCROLL_BREAK = 2;

class HeaderOld extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    openAuthModal: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    requireAuth: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  };

  state = {
    moreOptionsMenuOpen: false,
    isScrolled: window.pageYOffset > SCROLL_BREAK,

    // Modals
    showingInviteTalentModal: false
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.setOffset);
    this.setOffset();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.setOffset);
  }

  setOffset = () => {
    const { isScrolled } = this.state;
    if ((isScrolled && window.pageYOffset < SCROLL_BREAK) || (!isScrolled && window.pageYOffset > SCROLL_BREAK)) {
      this.setState({ isScrolled: !isScrolled });
    }
  };

  toggleMoreOptionsMenu = () => {
    this.setState({ moreOptionsMenuOpen: !this.state.moreOptionsMenuOpen });
    !this.state.moreOptionsMenuOpen && addEvent('Navigation - Open More Options Menu');
  };

  openInviteTalentModal = () => this.setState({ showingInviteTalentModal: true });
  closeInviteTalentModal = () => this.setState({ showingInviteTalentModal: false });

  clickLogo = () => {
    const scrollToTop = () =>
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    if (this.showBrandLogo()) {
      scrollToTop();
    } else if (isMobile && window.pageYOffset) {
      scrollToTop();
    } else {
      this.props.history.push('/');
      window.scrollTo(0, 0);
      addEvent('Header - Navigate To Home Page');
    }
  };

  getTab = (label, to) => {
    const active = window.location.pathname
      .split('/')
      .slice(0, 2)
      .join('/')
      .includes(to);
    const click = e => {
      e.preventDefault();
      this.props.history.push(to);
      window.scrollTo(0, 0);
      addEvent('Navigation - Switch Tab', { tab: label, user: this.props.user });
    };
    return (
      <Link to={to} className={classnames('nav-tab', { active })} onClick={click}>
        {label}
      </Link>
    );
  };

  showBrandLogo = () => {
    const { ui } = this.props;
    const isPromote = isPromotePage();
    const isBestInBeauty = isBeautyShopPage();
    const isHome = isHomePage();
    const brandTheme = getBrandTheme(ui);

    // Shop My Shelf pages
    if (isHome || isBestInBeauty) return false;

    // Promotion Pages
    if (isPromote && brandTheme?.allow_theming) return true;

    // Premium Plus Pages
    if (brandTheme?.allow_advanced_theming) return true;

    return false;
  };

  render() {
    const { user, ui, isLoggedIn } = this.props;
    const { isScrolled, moreOptionsMenuOpen } = this.state;
    if (isPublicPage() || isEmbedPage()) return null;
    const isHome = isHomePage();
    const isPromote = isPromotePage();
    const isReferral = isReferralPage();
    const brandTheme = getBrandTheme(ui);
    const additionalClasses = {
      scrolled: isScrolled,
      home: isHome || isPromote,
      promote: isPromote,
      referral: isReferral
    };
    const notificationCount = getNotificationCount(user);
    const showLogo = this.showBrandLogo();
    return window.__IS_PRO__ ? (
      <div className={classnames('nav-top-old', additionalClasses)}>
        <div className={classnames('nav-top-outer-container', additionalClasses)}>
          {(isHome || isPromote) && <div className='before' />}
          <div className={classnames('nav-top-inner-container', additionalClasses)}>
            <div className='header-main-container'>
              <div onClick={this.clickLogo} className={classnames('logo-text', additionalClasses)}>
                {showLogo && brandTheme.logo.includes('.png') ? (
                  <img className='brand-theme-logo' src={brandTheme.logo} alt={`${brandTheme?.name} Logo`} />
                ) : (
                  <>
                    <span className='em'>shop my shelf</span>
                    <span>professional</span>
                  </>
                )}
              </div>
              {!isLoggedIn ? (
                <div className='nav-tabs'>
                  {!isPromote && this.getTab('Shop Guide', `/shop-guide`)}
                  {/* {this.getTab('Popular Shops', `/popular`)} */}
                </div>
              ) : (
                <div className='nav-tabs'>
                  {isBrand(user) ? (
                    <>
                      {this.getTab('Talent', `/dashboard`)}
                      {this.getTab('Discover', `/discover`)}
                      {this.getTab('Chat', `/chat`)}
                      {this.getTab('Collaborations', `/collaborations`)}
                    </>
                  ) : (
                    <>
                      {this.getTab('My Shop', `/${getUsername(user)}`)}
                      {this.getTab('Links', `/links`)}
                      {this.getTab('Earnings', `/payouts`)}
                      {this.getTab('Collaborations', `/collaborations`)}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={classnames('header-secondary-container', additionalClasses)}>
              <button onClick={this.toggleMoreOptionsMenu} className='nav-hamburger-wrapper'>
                <FontAwesomeIcon
                  icon={moreOptionsMenuOpen ? faTimes : faBars}
                  className={classnames('nav-hamburger', {
                    ...additionalClasses,
                    close: moreOptionsMenuOpen
                  })}
                />
                {!!notificationCount && (
                  <div
                    className={classnames('notification-count', {
                      ...additionalClasses
                    })}
                  >
                    {notificationCount}
                  </div>
                )}
              </button>
            </div>
            <MoreOptionsMenu
              isLoggedIn={isLoggedIn}
              user={user}
              isScrolled={isScrolled}
              active={moreOptionsMenuOpen}
              openAuthModal={this.props.openAuthModal}
              logoutUser={this.props.logoutUser}
              close={this.toggleMoreOptionsMenu}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className={classnames('nav-top', { ...additionalClasses })}>
        <div
          className={classnames('nav-top-outer-container', {
            ...additionalClasses
          })}
        >
          {isReferral && <div className='before' />}
          <div
            className={classnames('nav-top-inner-container', {
              ...additionalClasses
            })}
          >
            <div className='header-main-container'>
              <div onClick={this.clickLogo} className={classnames('logo-text', { ...additionalClasses })}>
                {showLogo && brandTheme.logo.includes('.png') ? (
                  <img className='brand-theme-logo' src={brandTheme.logo} alt={`${brandTheme?.name} Logo`} />
                ) : (
                  'shop my shelf'
                )}
              </div>
              {!isLoggedIn ? null : (
                <div className='nav-tabs'>
                  {isBrand(user) ? (
                    <>
                      {this.getTab('Talent', `/dashboard`)}
                      {this.getTab('Discover', `/discover`)}
                      {this.getTab('Analytics', `/links`)}
                      {this.getTab('Chat', `/chat`)}
                      {this.getTab('Collaborations', `/collaborations`)}
                    </>
                  ) : (
                    <>
                      {this.getTab('My Shop', `/${getUsername(user)}`)}
                      {this.getTab('Links', `/links`)}
                      {this.getTab('Earnings', `/payouts`)}
                      {this.getTab('Collaborations', `/collaborations`)}
                    </>
                  )}
                </div>
              )}
            </div>
            <div
              className={classnames('header-secondary-container', {
                ...additionalClasses
              })}
            >
              {isBrand(user) && (
                <div
                  onClick={this.openInviteTalentModal}
                  className={classnames('action-btn', {
                    ...additionalClasses
                  })}
                >
                  Invite Talent
                </div>
              )}
              <button onClick={this.toggleMoreOptionsMenu} className='nav-hamburger-wrapper'>
                <FontAwesomeIcon
                  icon={moreOptionsMenuOpen ? faTimes : faBars}
                  className={classnames('nav-hamburger', {
                    ...additionalClasses,
                    close: moreOptionsMenuOpen
                  })}
                />

                {!!notificationCount && (
                  <div
                    className={classnames('notification-count', {
                      ...additionalClasses
                    })}
                  >
                    {notificationCount}
                  </div>
                )}
              </button>
            </div>
            <MoreOptionsMenu
              isLoggedIn={isLoggedIn}
              user={user}
              isScrolled={isScrolled}
              active={moreOptionsMenuOpen}
              openAuthModal={this.props.openAuthModal}
              logoutUser={this.props.logoutUser}
              close={this.toggleMoreOptionsMenu}
            />
            {this.state.showingInviteTalentModal && <InviteTalentModal closeModal={this.closeInviteTalentModal} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HeaderOld);
