import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import './BrandReport.scss';

import { refreshBrandTier } from '../../Actions/BrandActions';

import { getBrand, getBrandId } from '../../Helpers/user_helpers';
import {
  ONE_MONTHS_COMPARE,
  ONE_WEEK_COMPARE,
  PERFORMANCE_COMPARE_OPTIONS,
  PERFORMANCE_COMPARE_OPTION_GROUP,
  THREE_MONTHS_COMPARE,
  cleanBrandTier,
  cleanChartData
} from '../../Helpers/brand_tier_helpers';
import BrandReportCompareDropdown from './BrandReportCompareDropdown';
import ReportOverviewCard from './ReportOverviewCard';
import ReportSectionCard from './ReportSectionCard';
import { getBrandExampleProgram, getBrandTierSnapshots } from '../../APIClient/brands';
import Tooltip from '../General/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import AnnouncementModal from '../Announcements/AnnouncementModal';

const BrandReport = props => {
  const { user } = props;
  const [data, setData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [pastPerformanceData, setPastPerformanceData] = useState({});
  const [isRefreshingScore, setIsRefreshingScore] = useState(false);
  const [compareData, setCompareData] = useState(null);

  const brand = getBrand(props.user);

  useEffect(() => {
    const data = cleanBrandTier(brand.tier);
    setData(data);
    loadHistoricalData();
  }, [brand.tier]);

  const splitSectionsIntoColumns = (sections = [], splitIndex) => {
    return [sections.slice(0, splitIndex), sections.slice(splitIndex)];
  };

  const sectionSplitIndex = Math.ceil(data?.sections.length / 2);
  const [leftColumnSections, rightColumnSections] = splitSectionsIntoColumns(data?.sections, sectionSplitIndex);

  const loadHistoricalData = async () => {
    // load historical tier snapshots to be used in charts for each section
    const brandId = getBrandId(user);
    const response = await getBrandTierSnapshots(brandId);
    const { snapshots } = response;

    if (!snapshots?.length) return;

    const curBrandTier = getBrand(props.user).tier;
    const mostRecentData = {
      ...curBrandTier,
      snapshottedAt: curBrandTier.confirmedTierDate
    };

    const mostRecentSnapshotDate = snapshots[snapshots.length - 1]?.snapshottedAt;
    const isSameDay = moment(mostRecentSnapshotDate).format('YYYY-MM-DD') === moment(mostRecentData.snapshottedAt).format('YYYY-MM-DD');

    let allSnapshotData = snapshots;

    // Replace last snapshot with current tier data IF same day overlap, otherwise add current tier data as a snapshot to the end of the array
    if (isSameDay) {
      allSnapshotData[allSnapshotData.length - 1] = mostRecentData;
    } else {
      allSnapshotData.push(mostRecentData);
    }

    const formattedChartData = cleanChartData(allSnapshotData);
    setChartData(formattedChartData);

    // store performance compare snapshots in state
    const performanceCompareSnapshots = {};

    PERFORMANCE_COMPARE_OPTIONS.forEach(option => {
      let targetDate = null;

      // target date in UTC to match snapshot date formate from server
      switch (option) {
        case THREE_MONTHS_COMPARE:
          targetDate = moment()
            .utc()
            .subtract(3, 'months');
          break;
        case ONE_MONTHS_COMPARE:
          targetDate = moment()
            .utc()
            .subtract(1, 'months');
          break;
        case ONE_WEEK_COMPARE:
          targetDate = moment()
            .utc()
            .subtract(1, 'weeks');
          break;
        default:
          break;
      }

      if (!targetDate) return;

      const snapshotIdx = snapshots.findIndex(s => moment(s.snapshottedAt).format('YYYY-MM-DD') === targetDate.format('YYYY-MM-DD'));

      if (snapshotIdx >= 0) {
        performanceCompareSnapshots[option] = snapshots[snapshotIdx];
      }
    });

    setPastPerformanceData(performanceCompareSnapshots);
  };

  const refreshTier = async () => {
    if (isRefreshingScore) return window.ALERT.warn(`Please wait for the current refresh to finish.`);
    setIsRefreshingScore(true);
    await props.refreshBrandTier();
    setIsRefreshingScore(false);
  };

  const handleCompareChange = async compareOption => {
    if (!compareOption) return setCompareData(null);

    const { type, value } = compareOption;

    if (type === PERFORMANCE_COMPARE_OPTION_GROUP) {
      // past performance
      const pastTier = pastPerformanceData[value];

      if (!pastTier) {
        window.ALERT.warn('No available data yet to compare with. Please check back later!');
        return;
      }
      const cleanPastTier = cleanBrandTier(pastTier);
      setCompareData(cleanPastTier);
    } else {
      // other programs, other account manager programs
      try {
        const Brand_id = value;
        const response = await getBrandExampleProgram(Brand_id);
        const brandTier = response.tier;
        const cleanPastTier = cleanBrandTier(brandTier);
        setCompareData(cleanPastTier);
      } catch (error) {
        window.ALERT.error('No available data yet to compare with. Please check back later!');
        return;
      }
    }

    return { success: true };
  };

  const renderSectionColumn = sections => (
    <div className='section-column'>
      {sections.map(section => (
        <div className='section' key={section.key}>
          <ReportSectionCard
            sectionData={section}
            chartData={chartData?.[section.key] || null}
            sectionCompareData={compareData?.sections.find(s => s.key === section.key) || null}
          />
        </div>
      ))}
    </div>
  );

  const isNewBrand = !!data && data.score === 0 && data.components.length === 0;

  const announcement = getAnnouncementByType(user, 'BRAND_REPORT');

  return (
    <div className='brand-report-container'>
      {!!announcement && <AnnouncementModal announcement={announcement} extra={{}} />}
      <ReportOverviewCard tierData={data} compareData={compareData} />
      <div className='actions-container'>
        {!isNewBrand && (
          <div className='action'>
            Compare with
            <BrandReportCompareDropdown user={user} onCompareChange={handleCompareChange} />
          </div>
        )}
        <div className='actions-right'>
          {process.env.NODE_ENV === 'development' && (
            <div className='action'>
              <div onClick={refreshTier} className='refresh-btn'>
                {isRefreshingScore ? 'Refreshing...' : 'Refresh data'}
              </div>
              <Tooltip
                message={'This is not optimized for performance and will take some time to complete.'}
                getIconDiv={() => <FontAwesomeIcon icon={faQuestionCircle} />}
              />
            </div>
          )}
          <div className='action'>
            Learn more about
            <a className='link' target='_blank' rel='noopener noreferrer' href={'https://brands.shopmy.us/program-reports'}>
              Program Reports
            </a>
          </div>
        </div>
      </div>
      {isNewBrand ? (
        <div className='new-brand-message'>
          <div className='message'>
            Welcome to ShopMy! Your program report provides detailed insights into your brand's performance and activity on our platform. This report
            is updated nightly and will be visible shortly. Please check back soon!
          </div>
        </div>
      ) : (
        <div className='sections-container'>
          {renderSectionColumn(leftColumnSections)}
          {renderSectionColumn(rightColumnSections)}
        </div>
      )}
    </div>
  );
};

BrandReport.propTypes = {
  user: PropTypes.object.isRequired,
  refreshBrandTier: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  refreshBrandTier
})(BrandReport);
