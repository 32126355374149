import cogoToast from 'cogo-toast';
import { goToStripeOnboarding as goToStripeOnboardingAPI, goToStripeBillingDashboard as goToStripeBillingDashboardAPI } from '../APIClient/stripe';

export const goToStripeBillingDashboard = user =>
  goToStripeBillingDashboardAPI(user)
    .then(resp => window.open(resp.redirect_uri, '_blank'))
    .catch(err => cogoToast.error(String(typeof err === 'string' ? err : err.error)));

export const goToStripeOnboarding = user =>
  goToStripeOnboardingAPI(user)
    .then(resp => (window.location.href = resp.redirect_uri))
    .catch(err => cogoToast.error(String(typeof err === 'string' ? err : err.error)));

export const formatStripeRequirement = requirement =>
  ({
    'business_profile.support_phone': 'Support Phone Number',
    email: 'Email Address',
    external_account: 'Account for Payments',
    'individual.dob.day': 'Day of Birth',
    'individual.dob.month': 'Month of Birth',
    'individual.dob.year': 'Year of Birth',
    'individual.phone': 'Phone Number',
    'individual.ssn_last_4': 'Last 4 of SSN',
    'tos_acceptance.date': 'Accepting Terms',
    'tos_acceptance.ip': 'Accepting Terms',
    'individual.id_number': 'Full SSN',
    'individual.verification.document': 'Verification Document (Passport, ID, etc.)'
  }[requirement] || requirement);
