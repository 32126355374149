import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import './Spotlight.scss';

import { clearSpotlightKey } from '../../Actions/UIActions';
import { getSelectedSpotlightKey } from '../../Helpers/spotlight_helpers';

const SPOTLIGHT_ANIMATION_DELAY = 250;

const Spotlight = props => {
  /*
    The Spotlight is a wrapper component that allows us to point out specific UI
    elements to assist in onboarding and training our users.

    To Use it, simply wrap a component as follows:

      <Spotlight key={spotlightKeys.KEYNAMEHERE}>
        <div>Button</div>
      </Spotlight>


    Key things to note:

    1) You cannot put a spotlight on an absolutely positioned element, instead you should make an "inner" class and put spotlight around that object
    for example:
      <div className='absolute-outer'>
        <Spotlight>
          <div className='inner'></div>
        </Spotlight>
      </div>

  */
  const { ui, children, clearSpotlightKey, spotlightKey } = props;
  const isActive = spotlightKey === getSelectedSpotlightKey(ui);
  const spotlightRef = useRef(null);

  // Set up the spotlight boolean to focus after a delay
  const [spotlightTurnedOn, setSpotlightTurnedOn] = useState(false);

  // Ensure we clear out the spotlight on any child click
  useEffect(() => {
    if (!isActive) return;
    const onClick = e => {
      const isSpotlightFade = !!e.target?.className?.includes && e.target?.className?.includes('spotlight-fade');
      if (!isSpotlightFade) {
        clearSpotlightKey();
        document.removeEventListener('click', onClick);
      }
    };
    document.addEventListener('click', onClick);

    setTimeout(() => {
      spotlightRef.current &&
        spotlightRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
    }, 400); // Let it load
  }, [isActive, clearSpotlightKey]);

  useEffect(() => {
    if (isActive) {
      let showSpotlight = setTimeout(() => setSpotlightTurnedOn(true), SPOTLIGHT_ANIMATION_DELAY);
      return () => clearTimeout(showSpotlight);
    }
  }, [isActive, setSpotlightTurnedOn]);

  // Ensure we should show the spotlight
  if (!isActive) return children;

  const clearSpotlight = () => clearSpotlightKey();

  return (
    <>
      <div ref={spotlightRef} className={cn('spotlight-element-wrapper', { on: spotlightTurnedOn })}>
        {children}
        <div className='arrow'>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
      <div onClick={clearSpotlight} className={cn('spotlight-fade', { on: spotlightTurnedOn })}>
        <div className='clear-btn'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </>
  );
};

Spotlight.propTypes = {
  spotlightKey: PropTypes.string,
  children: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { ui } = state;
  return { ui };
};

export default connect(mapStateToProps, {
  clearSpotlightKey
})(Spotlight);
