import React, { useCallback, useState } from 'react';
import Select, { components } from 'react-select';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

const CustomNoOptionsMessage = props => {
  const canCreateNewOption = !!props.onNewOptionClick && props.inputVal;

  const handleNewOptionClick = () => {
    if (!canCreateNewOption) return;
    props.onNewOptionClick(props.inputVal);
  };

  return (
    <components.NoOptionsMessage {...props}>
      <div style={{ textAlign: 'center' }} onClick={handleNewOptionClick}>
        {canCreateNewOption ? `Create '${props.inputVal}'` : 'No options found'}
      </div>
    </components.NoOptionsMessage>
  );
};

const InfiniteScrollSelect = props => {
  const {
    loadMoreOptions,
    onSelectOptions,
    menuHeight,
    value,
    isLoading,
    placeholder,
    handleBlur,
    isMultiple = false,
    closeMenuOnSelect = true,
    className = null,
    autoFocus = false,
    openMenuOnFocus = false,
    isOptionDisabled = null
  } = props;

  const [options, setOptions] = useState([]);
  const [inputVal, setInputVal] = useState('');

  const handleLoadInitialOptions = async () => {
    if (options.length) return;

    await handleLoadMoreOptions(null, true);
  };

  const handleLoadMoreOptions = async (searchTerm, isNewSearch) => {
    const results = await loadMoreOptions(searchTerm, isNewSearch);
    setOptions(isNewSearch ? [...results] : [...options, ...results]);
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === 'input-blur') {
      // clear out current search options to be reloaded on menu open
      setOptions([]);
      setInputVal('');
      return newValue;
    } else if (action === 'set-value') {
      // if selecting a value and have an old search term, clear it and reload options
      if (!inputVal) {
        setInputVal('');
        return newValue;
      }
    } else if (action !== 'input-change') {
      setInputVal('');
      return newValue;
    }

    debouncedLoadMoreOptions(newValue, true);
    setInputVal(newValue);
    return newValue;
  };

  const debouncedLoadMoreOptions = useCallback(debounce(handleLoadMoreOptions, 750), [options]);

  const formatOptionLabel = (option, { context }) => {
    const { label, subLabel, isDisabled } = option;

    const extraStyles = isDisabled ? { opacity: 0.5, cursor: 'not-allowed' } : {};

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...extraStyles }}>
        <div style={{ fontSize: '14px', color: '#1f1f1f' }}>{label}</div>
        {context === 'menu' && subLabel && (
          <div style={{ fontSize: '12px', color: '#4a4a4a' }} className='sub-label'>
            {subLabel}
          </div>
        )}
      </div>
    );
  };

  const customStyles = {
    menuList: provided => ({
      ...provided,
      maxHeight: menuHeight || '200px'
    }),
    multiValue: styles => ({
      ...styles,
      backgroundColor: '#f2f2f2',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center'
    }),
    multiValueRemove: styles => ({
      ...styles,
      color: '#000',
      borderRadius: '50%',
      backgroundColor: '#cccccc',
      height: '16px',
      width: '16px',
      marginLeft: '2px',
      marginRight: '6px',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#9c9a9c',
        color: 'white'
      }
    }),
    option: provided => ({
      ...provided,
      cursor: 'pointer'
    }),
    clearIndicator: provided => ({
      ...provided,
      cursor: 'pointer'
    })
  };

  return (
    <Select
      className={className}
      classNamePrefix={'infinite-scroll-select'}
      autoFocus={autoFocus}
      openMenuOnFocus={openMenuOnFocus}
      isOptionDisabled={isOptionDisabled}
      placeholder={placeholder}
      value={value}
      isMulti={isMultiple}
      closeMenuOnSelect={closeMenuOnSelect}
      options={options}
      formatOptionLabel={formatOptionLabel}
      onMenuOpen={handleLoadInitialOptions}
      onMenuScrollToBottom={() => {
        handleLoadMoreOptions(inputVal || null, false);
      }}
      onChange={onSelectOptions}
      styles={customStyles}
      isLoading={isLoading}
      filterOption={null}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleBlur && handleBlur();
      }}
      components={{
        NoOptionsMessage: noOptionsMessageProps => (
          <CustomNoOptionsMessage {...noOptionsMessageProps} inputVal={inputVal} onNewOptionClick={props.onNewOptionClick} />
        )
      }}
    />
  );
};

InfiniteScrollSelect.propTypes = {
  isMultiple: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  loadMoreOptions: PropTypes.func.isRequired,
  onSelectOptions: PropTypes.func.isRequired,
  menuHeight: PropTypes.string,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  handleBlur: PropTypes.func,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
  onNewOptionClick: PropTypes.func
};

export default InfiniteScrollSelect;
