import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import './ShopResults.scss';

import ShopResult from './ShopResult';
import EmptyShopResult from './elements/EmptyShopResult';

const PLACEHOLDER_PRODUCTS = isMobile ? 3 : 9;

class ShopResults extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    shop: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    topArtists: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    setVisibleProduct: PropTypes.func.isRequired,
    activeFilters: PropTypes.object.isRequired
  };

  state = {
    showMoreExperts: false
  };

  getLongFormDescription = () => {
    const { activeFilters } = this.props;
    const { tag, department, category } = activeFilters || {};
    const relevantFilter = tag || category || department;
    const { seoDescriptionLong } = relevantFilter || {};
    if (!seoDescriptionLong) return null;

    const parts = seoDescriptionLong.split('|');
    const partsEls = parts.map((part, idx) => {
      const isNormal = idx % 3 === 0;
      const isHyper = idx % 3 === 1;
      if (isNormal) return part;
      if (isHyper) {
        const hyperPartLink = isHyper && parts[idx + 1];
        return (
          <Link onClick={() => window.scrollTo(0, 0)} key={idx} to={`/${hyperPartLink}`}>
            {part}
          </Link>
        );
      }
      return null;
    });

    return partsEls;
  };

  getLongFormDescriptionLabel = () => {
    const { activeFilters } = this.props;
    const { tag, department, category } = activeFilters || {};
    const relevantFilter = tag || category || department;
    return relevantFilter && relevantFilter.name;
  };

  render() {
    const { products } = this.props;
    const longFormDescription = this.getLongFormDescription();
    const longFormDescriptionLabel = this.getLongFormDescriptionLabel();
    return (
      <div className='shop-results-outer-container'>
        <div className='shop-results-container'>
          {(products && products.length ? products : [...Array(PLACEHOLDER_PRODUCTS).keys()].map(() => null)).map((product, idx) =>
            product ? (
              <ShopResult
                key={product.id}
                shop={this.props.shop}
                ui={this.props.ui}
                user={this.props.user}
                activeFilters={this.props.activeFilters}
                setVisibleProduct={this.props.setVisibleProduct}
                product={product}
              />
            ) : (
              <EmptyShopResult key={idx} />
            )
          )}
        </div>
        {longFormDescription && (
          <>
            <div className='long-description-header'>More about {longFormDescriptionLabel}</div>
            <div className='long-description-container'>{longFormDescription}</div>
          </>
        )}
      </div>
    );
  }
}

export default ShopResults;
