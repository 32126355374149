import _ from 'lodash';
import { getPayouts, getPayments, linkPaypal, linkInvoiceEmail, unlinkAccount } from '../APIClient/payouts';

export const GET_USER_PAYOUTS_SUCCESS = 'GET_USER_PAYOUTS_SUCCESS';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const LINK_PAYPAL_ACCOUNT_SUCCESS = 'LINK_PAYPAL_ACCOUNT_SUCCESS';
export const LINK_PAYPAL_ACCOUNT_ERROR = 'LINK_PAYPAL_ACCOUNT_ERROR';
export const LINK_INVOICE_EMAIL_ACCOUNT_SUCCESS = 'LINK_INVOICE_EMAIL_ACCOUNT_SUCCESS';
export const LINK_INVOICE_EMAIL_ACCOUNT_ERROR = 'LINK_INVOICE_EMAIL_ACCOUNT_ERROR';
export const UNLINK_PAYMENT_ACCOUNT_SUCCESS = 'UNLINK_PAYMENT_ACCOUNT_SUCCESS';
export const GET_CLIENT_PAYOUTS_SUCCESS = 'GET_CLIENT_PAYOUTS_SUCCESS';

export const getUserPayouts = user => async (dispatch, getState) => {
  const resp = await getPayouts(user);
  return dispatch({
    type: GET_USER_PAYOUTS_SUCCESS,
    payout_summaries: _.get(resp, 'data', {})
  });
};

export const getUserPayments = user => async (dispatch, getState) => {
  const resp = await getPayments(user);
  return dispatch({
    type: GET_USER_PAYMENTS_SUCCESS,
    payments: _.get(resp, 'payments', [])
  });
};

export const unlinkPaymentAccount = account => async (dispatch, getState) => {
  await unlinkAccount(account);
  return dispatch({
    type: UNLINK_PAYMENT_ACCOUNT_SUCCESS,
    account
  });
};

export const linkPaypalAccount = (user, code) => async (dispatch, getState) => {
  try {
    const resp = await linkPaypal(user, code);
    return dispatch({
      type: LINK_PAYPAL_ACCOUNT_SUCCESS,
      account: _.get(resp, 'account', {})
    });
  } catch (error) {
    return dispatch({
      type: LINK_PAYPAL_ACCOUNT_ERROR,
      error
    });
  }
};

export const linkInvoiceEmailAccount = (user, email) => async (dispatch, getState) => {
  try {
    const resp = await linkInvoiceEmail(user, email);
    return dispatch({
      type: LINK_INVOICE_EMAIL_ACCOUNT_SUCCESS,
      account: _.get(resp, 'account', {})
    });
  } catch (error) {
    return dispatch({
      type: LINK_INVOICE_EMAIL_ACCOUNT_ERROR,
      error
    });
  }
};
