import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ProgressCircle.scss';
import * as d3 from 'd3';

const ProgressCircle = props => {
  const { radius = 20, stroke = 4, progress = 0, secondaryProgress = null, shouldDraw = false } = props;
  const normalizedRadius = radius - stroke;
  const diameter = normalizedRadius * 2;
  const circumference = diameter * Math.PI;
  const ref = useRef();

  useEffect(() => {
    const svg = d3.select(ref.current);
    svg.selectAll('*').remove();

    // Background circle
    svg
      .append('circle')
      .attr('class', 'path-background')
      .attr('stroke-width', stroke)
      .attr('r', normalizedRadius)
      .attr('cx', radius)
      .attr('cy', radius);

    const addProgressCircle = (p, isSecondary) => {
      const progressCircle = svg
        .append('circle')
        .attr('class', isSecondary ? 'path-secondary-progress' : 'path-progress')
        .attr('stroke-width', stroke)
        .attr('stroke-dasharray', circumference + ' ' + circumference)
        .attr('stroke-dashoffset', circumference)
        .attr('r', normalizedRadius)
        .attr('cx', radius)
        .attr('cy', radius);

      return progressCircle;
    };

    // Add larger progress first so it's behind the smaller progress

    let secondaryProgressCircle = null;

    if (secondaryProgress !== null && secondaryProgress > progress) {
      secondaryProgressCircle = addProgressCircle(secondaryProgress, true);
    }

    const primaryProgressCircle = addProgressCircle(progress);

    if (secondaryProgress !== null && secondaryProgress < progress) {
      secondaryProgressCircle = addProgressCircle(secondaryProgress, true);
    }

    if (shouldDraw) {
      // Draw progress circle
      primaryProgressCircle
        .transition()
        .duration(750) // Animation duration
        .attr('stroke-dashoffset', circumference - (progress / 100) * circumference);

      if (secondaryProgressCircle) {
        secondaryProgressCircle
          .transition()
          .duration(750) // Animation duration
          .attr('stroke-dashoffset', circumference - (secondaryProgress / 100) * circumference);
      }
    }
  }, [progress, secondaryProgress]);

  return <svg ref={ref} className='progress-circle' height={radius * 2} width={radius * 2}></svg>;
};

ProgressCircle.propTypes = {
  progress: PropTypes.number.isRequired,
  secondaryProgress: PropTypes.number,
  radius: PropTypes.number,
  stroke: PropTypes.number
};

export default ProgressCircle;
