import React from 'react';
import PropTypes from 'prop-types';
import './BrandPromoters.scss';

import { getShopUrl } from '../../Helpers/helpers';
import { getPrettyNumber } from '../../Helpers/formatting';

const BrandPromoters = props => {
  const { brand, isFetching } = props;
  const { num_promoters } = brand;
  const promoters = brand?.featured_promoters || [];
  const visible_promoters = promoters.slice(0, 3);
  const num_promoters_after_visible = num_promoters - visible_promoters.length;
  if (visible_promoters.length === 0) return null;
  return (
    <div className='brand-promoters-outer-container'>
      <div className='brand-promoters'>
        <div className='badges'>
          {isFetching ? (
            <>
              <div className='badge empty' />
              <div className='badge empty' />
              <div className='badge empty' />
              <div className='badge empty' />
            </>
          ) : (
            <>
              {visible_promoters.map(promoter => (
                <div key={promoter.id} className='badge'>
                  <img src={promoter.image} alt={promoter.name} />
                </div>
              ))}
              <div className='badge additional'>+{getPrettyNumber(num_promoters_after_visible)}</div>
            </>
          )}
        </div>
        <div className='labels'>
          Promoted by{' '}
          {visible_promoters.map((p, idx) => {
            const goToPromoter = () => window.open(getShopUrl(p.username), '_blank');
            return (
              <span key={idx} onClick={goToPromoter} className='promoter'>
                {p.name}
                {idx === visible_promoters.length - 1 ? ' ' : `, `}
              </span>
            );
          })}
          {num_promoters_after_visible > 2 ? `and ${getPrettyNumber(num_promoters_after_visible)} more.` : ``}
        </div>
      </div>
    </div>
  );
};

BrandPromoters.propTypes = {
  brand: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default BrandPromoters;
