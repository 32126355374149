import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltToBottom } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import './MentionControls.scss';

import MentionSortControl from './Elements/MentionSortControl';
import MentionDateRangePicker from './Elements/MentionDateRangePicker';
import Loader from '../Loader/Loader';

const MentionControls = props => {
  const { curFilters, sortOptions, setCurFilters, isDownloading } = props;

  const toggleTagFilter = () => {
    if (props.blockUsage()) return null;
    props.setCurFilters({
      ...props.curFilters,
      topTalentOnly: !props.curFilters.topTalentOnly,
      page: 0
    });
  };
  const clickToDownload = () => {
    if (props.blockUsage()) return null;
    props.setIsDownloading(true);
  };
  return (
    <div className='mention-controls-outer-container'>
      <div className='mention-controls-container'>
        <div className='secondary'>
          <div className='toggle-switch'>
            <div className='toggle-label'>Top Talent Only</div>
            <Switch
              onChange={toggleTagFilter}
              checked={!!curFilters.topTalentOnly}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch desktop-only'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <div>
            <MentionDateRangePicker blockUsage={props.blockUsage} curFilters={curFilters} setCurFilters={setCurFilters} />
          </div>
          <div>
            <MentionSortControl blockUsage={props.blockUsage} curFilters={curFilters} setCurFilters={setCurFilters} sortOptions={sortOptions} />
          </div>
          <div className={cn('download-btn', { downloading: isDownloading })} onClick={clickToDownload}>
            <FontAwesomeIcon icon={faArrowAltToBottom} />
            DOWNLOAD
            {isDownloading && <Loader size={40} />}
          </div>
        </div>
      </div>
    </div>
  );
};

MentionControls.propTypes = {
  curFilters: PropTypes.object.isRequired,
  sortOptions: PropTypes.array.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  blockUsage: PropTypes.func.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  setIsDownloading: PropTypes.func.isRequired
};

export default MentionControls;
