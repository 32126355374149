import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as constants from './social_template_constants';
import shopMyLogoWhite from '../../../../static/images/logos/shop_my_white.png';
import './SocialSharingMiddleText.scss';

import SocialSharingTemplateControls from './SocialSharingTemplateControls';
import { filterAndSortPinsForSocialSharing } from '../../../../Helpers/social_sharing_template_helpers';
import _ from 'lodash';

const SocialSharingMiddleText = props => {
  const { elementId, collection } = props;
  const { ASPECT_RATIO } = constants;
  const { pins, name } = collection;

  const [backgroundColor, setBackgroundColor] = useState('#0a472e');
  const [textColor, setTextColor] = useState('#ffffff');
  const [logoColor, setLogoColor] = useState('#ffffff');
  const logoBrightness = logoColor === '#ffffff' ? 1 : 0;
  const logoHidden = logoColor === 'transparent';

  const tempPins = filterAndSortPinsForSocialSharing(pins);
  const allPins = [];
  for (let i of _.range(6)) {
    const modIndex = i % tempPins.length;
    tempPins[modIndex] && allPins.push(tempPins[modIndex]);
  }

  const topPins = allPins.slice(0, 3);
  const bottomPins = allPins.slice(3, 6);

  if (!allPins.length) return null;
  return (
    <div className='social-sharing-template'>
      <div className='downloadable-content-container social-sharing-middle-text-outer'>
        <div className='downloadable-content' id={elementId} style={{ aspectRatio: ASPECT_RATIO, background: backgroundColor, color: textColor }}>
          <div className='downloadable-content-inner'>
            <div className='middle-text-content-container'>
              <div className='middle-text-pin-row top'>
                {topPins.map((pin, idx) => {
                  const { image, title } = pin;
                  return (
                    <div className='pin-image-container' key={idx}>
                      <img className='pin-image' alt={title} src={image} />
                    </div>
                  );
                })}
              </div>

              <div className='middle-text-title-container'>
                <div className='middle-text-big-title'>{name}</div>
              </div>

              <div className='middle-text-pin-row bottom'>
                {bottomPins.map((pin, idx) => {
                  const { image, title } = pin;
                  return (
                    <div className='pin-image-container' key={idx}>
                      <img className='pin-image' alt={title} src={image} />
                    </div>
                  );
                })}
              </div>
              {!logoHidden && (
                <img
                  style={{ filter: `brightness(${logoBrightness})` }}
                  height={15}
                  className='middle-text-logo'
                  src={shopMyLogoWhite}
                  alt='shopmy_logo'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <SocialSharingTemplateControls
        collection={collection}
        elementId={elementId}
        backgroundColor={backgroundColor}
        setBackgroundColor={setBackgroundColor}
        textColor={textColor}
        setTextColor={setTextColor}
        logoColor={logoColor}
        setLogoColor={setLogoColor}
      />
    </div>
  );
};

SocialSharingMiddleText.propTypes = {
  elementId: PropTypes.string.isRequired,
  collection: PropTypes.object.isRequired
};

export default SocialSharingMiddleText;
