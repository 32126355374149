import APIClient from './index';
import _ from 'lodash';

export const sendReferralEmails = (emails, User_id) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Users/send_referral_emails`, { emails, User_id })
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
