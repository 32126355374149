import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faGift, faHandshake } from '@fortawesome/pro-light-svg-icons';

import './OnboardingPanelTasks.scss';

import { spotlightKeys } from '../../../Helpers/spotlight_helpers';
import { getUserTiers } from '../../../Helpers/tier_helpers';
import { getUserTier, getProfileFromObject, getOpportunityRequestForOpportunityId, getReferringRequest } from '../../../Helpers/user_helpers';

import vipIcon from '../../../static/images/onboarding/tasks/vip.png';
import chatIcon from '../../../static/images/onboarding/tasks/chat.png';
import collabIcon from '../../../static/images/onboarding/tasks/collab.png';
import consultIcon from '../../../static/images/onboarding/tasks/consult.png';
import linkIcon from '../../../static/images/onboarding/tasks/link.png';
import postIcon from '../../../static/images/onboarding/tasks/post.png';
import shelfIcon from '../../../static/images/onboarding/tasks/shelf.png';

const OnboardingPanelTasks = props => {
  const { user, selectedTaskKey, selectedTaskPath, isOnlyPanel } = props;
  const userTier = getUserTier(user);
  const userTiers = getUserTiers();

  const { ReferringOpportunity_id } = getProfileFromObject(user);
  const referredFromOpportunity = ReferringOpportunity_id && getOpportunityRequestForOpportunityId(user, ReferringOpportunity_id)?.opportunity;
  const referredFromRequest = getReferringRequest(user);

  const taskSections = [
    {
      header: null,
      tier: 4,
      tasks: [
        ...(referredFromOpportunity
          ? [
              {
                path: `/opportunity/${referredFromOpportunity.id}`,
                header: 'View Opportunity',
                faIcon: faHandshake,
                subheader: `View the opportunity "${referredFromOpportunity.title}"`
              }
            ]
          : []),
        ...(referredFromRequest
          ? referredFromRequest.lookbook_order
            ? [
                {
                  path: `/lookbooks/order/${referredFromRequest.lookbook_order.id}`,
                  header: 'View Order Confirmation',
                  faIcon: faGift,
                  subheader: `View confirmation for your order from "${referredFromRequest.lookbook.title}" by ${referredFromRequest.brand.name}.`
                }
              ]
            : [
                {
                  path: `/lookbooks/${referredFromRequest.lookbook.id}`,
                  header: 'Make Gifting Selections',
                  faIcon: faGift,
                  subheader: `Choose what you want from the lookbook "${referredFromRequest.lookbook.title}"`
                }
              ]
          : []),
        {
          key: spotlightKeys.CREATE_PRODUCT_LINK,
          header: 'Create a commissionable link',
          icon: linkIcon,
          hiddenOnApp: true,
          subheader: `Search our comprehensive product catalog and create your first commissionable link. Use your link wherever you engage with your audience.`
        },
        {
          key: spotlightKeys.CREATE_SHELF,
          header: 'Create a List',
          icon: shelfIcon,
          subheader: `Create a shoppable collection for your favorite products.`
        },
        {
          key: spotlightKeys.CREATE_POST,
          header: 'Create a shoppable social post',
          icon: postIcon,
          subheader: `Make your Instagram, TikTok or Youtube content shoppable`
        },
        {
          key: spotlightKeys.CREATE_CONSULT,
          header: 'Build a digital consultation',
          icon: consultIcon,
          subheader: 'Offer a paid digital product consultation to your audience'
        }
      ]
    },
    {
      header: `For ${userTiers[2].label} tier or higher`,
      tier: 3,
      hiddenOnApp: true,
      tasks: [
        {
          key: spotlightKeys.CHAT_WITH_BRANDS,
          header: 'Chat with brands',
          icon: chatIcon,
          subheader: 'Start a conversation with your favorite brand'
        },
        {
          key: spotlightKeys.CREATE_COLLABORATION,
          icon: collabIcon,
          header: 'Propose paid collaborations',
          subheader: 'Create a custom proposal with our easy proposal builder'
        }
      ]
    },
    {
      header: `For ${userTiers[3].label} tier`,
      tier: 1,
      tasks: [
        {
          key: spotlightKeys.SELECT_SHOP_MANAGER,
          header: 'Select VIP shop manager',
          icon: vipIcon,
          subheader: 'Work with our creator strategy team to help you monetize your content.'
        }
      ]
    }
  ].map(section => ({
    ...section,
    tasks: section.tasks.map(task => ({
      ...task,
      selected: (task.key && selectedTaskKey === task.key) || (task.path && selectedTaskPath === task.path),
      active: userTier.tier <= section.tier
    }))
  }));

  // On first load, select the first option
  React.useEffect(() => {
    props.setSelectedTaskKey(taskSections[0].tasks[0].key);
    props.setSelectedTaskPath(taskSections[0].tasks[0].path);
  }, []);

  return (
    <div className='onboarding-panel-tasks-container'>
      {isOnlyPanel ? (
        <>
          <div className='onboarding-header'>What would you like to do?</div>
          <div className='onboarding-subheader'>Select what you want to do and we will show you how!</div>
        </>
      ) : (
        <>
          <div className='onboarding-header'>Let's get started</div>
          <div className='onboarding-subheader'>What do you want to do first?</div>
        </>
      )}
      <div className='onboarding-tasks-sections'>
        {taskSections.map(section => {
          const { header, tasks, hiddenOnApp } = section;
          return (
            <div className='tasks-section' key={header}>
              {header && <div className='section-header'>{header}</div>}
              {tasks.map(task => {
                const { header, subheader, selected, active, icon, faIcon, key, path } = task;
                const select = () => {
                  if (!active) return window.ALERT.warn(`You cannot yet access this.`);
                  props.setSelectedTaskKey(key || null);
                  props.setSelectedTaskPath(path || null);
                };
                if (hiddenOnApp) return null;
                return (
                  <div onClick={select} key={header} className={cn('task', { selected, active })}>
                    <div className={cn('radio-btn', { selected, active })}>{selected && <div className='selected-mark' />}</div>
                    <div className='icon'>
                      {faIcon ? (
                        <div className='fa-icon-wrapper'>
                          <FontAwesomeIcon icon={faIcon} />
                        </div>
                      ) : (
                        <img src={icon} alt={header} />
                      )}
                    </div>
                    <div className='text'>
                      <div className='header'>{header}</div>
                      <div className='subheader'>{subheader}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div onClick={props.closeModalWithoutSpotlight} className='close-icn'>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </div>
  );
};

OnboardingPanelTasks.propTypes = {
  user: PropTypes.object.isRequired,
  selectedTaskKey: PropTypes.string,
  setSelectedTaskKey: PropTypes.func.isRequired,
  selectedTaskPath: PropTypes.string,
  setSelectedTaskPath: PropTypes.func.isRequired,
  isOnlyPanel: PropTypes.bool,
  closeModalWithoutSpotlight: PropTypes.func.isRequired
};

export default OnboardingPanelTasks;
