import _ from 'lodash';

export const getBrandTiers = () => {
  /*
    Get tier data.

    Make sure to keep this in sync with brand_tier_helpers.js on the backend.
  */
  return [
    {
      label: 'Upstart',
      description: `
        Venturing into creator marketing, you are at the exciting stage of establishing your affiliate program.
        You may not be actively leveraging all the core features of our platform, and your existing promotional activities are still developing.
        This tier represents your initial steps in the dynamic world of creator marketing as you progress towards the Established tier.
      `,
      tier: 4,
      minScore: 0,
      unlocked: []
    },
    {
      label: 'Established',
      description: `
        Your program stands as a solid, well-functioning entity in the realm of creator marketing.
        While you are actively utilizing a selection of tools available to creator marketers, there's room to expand and diversify your toolkit further.
        Additionally, you are in the process of building a robust network of promoters, a step that will significantly enhance your brand's outreach and impact.
      `,
      tier: 3,
      minScore: 30,
      unlocked: []
    },
    {
      label: 'Advanced',
      description: `
        As an Advanced Program on our platform, you represent the upper echelon of success in creator marketing.
        Your brand skillfully utilizes a comprehensive array of tools — including gifting, custom rates and codes, and chat — to drive engagement and results.
        While you are on the cusp of achieving world-class status, there's still room to enhance your performance across various marketing channels.
      `,
      tier: 2,
      minScore: 70,
      unlocked: []
    },
    {
      label: 'World Class',
      description: `
        As a World Class Program, your brand is a paragon of excellence in creator marketing.
        You masterfully leverage every tool in the creator marketing arsenal, including gifting, codes, custom rates, and chat to create impactful campaigns.
        With a substantial network of dedicated promoters driving significant traffic, your brand stands out for its quality products and dynamic marketing strategies.
      `,
      tier: 1,
      minScore: 85,
      unlocked: []
    }
  ];
};

export const getBrandTierData = tierLevel => getBrandTiers().find(t => t.tier === tierLevel);

export const BINARY_NO = 'Not Yet';
export const BINARY_YES = 'Yes';

/*
  Scoring Information
*/
export const cleanBrandTier = brandTier => {
  /*
    We want to pull apart the raw value from the backend to a format that is easier to work with.
  */
  if (!brandTier) return null;

  const tierData = getBrandTierData(brandTier.tier);
  const parsedRaw = JSON.parse(brandTier.raw);

  const scoreComponentKeys = _.keys(parsedRaw)
    .filter(key => key.includes('-MAX'))
    .map(key => key.split('-MAX')[0]);

  const getDataForComponentKey = key =>
    ({
      BIO: {
        title: 'Provided Brand Description',
        explainer: 'Whether you have provided a bio for creators to learn about your brand.',
        isBinaryScoring: true,
        additionalFields: []
      },
      LOOKBOOK: {
        title: 'Configured and Sent Lookbook',
        explainer: 'Whether you have configured and sent a lookbook to creators.',
        isBinaryScoring: true,
        additionalFields: []
      },
      COMMISSION: {
        title: 'Offering Commission',
        explainer: 'Whether you are offering commission to creators.',
        isBinaryScoring: true,
        additionalFields: ['Rate']
      },
      SHOPMYNETWORK: {
        title: 'On ShopMy Network',
        explainer: 'Whether you have joined the ShopMy Network that offers the most robust affiliate experience.',
        isBinaryScoring: true,
        additionalFields: []
      },
      CHAT: {
        title: 'Chat Reponse Rate',
        explainer:
          'The percentage of chat messages in the last 30 days that you have either read or responded to. This allows for up to 48 hours before negatively impacting your score.',
        additionalFields: []
      },
      GIFTING: {
        title: 'Monthly Gifting',
        explainer: 'The monthly average of gifting requests sent to creators in the last 3 months.',
        additionalFields: []
      },
      INVOICES: {
        title: 'Paid Invoices',
        explainer: 'The percentage of your invoices that have been paid over the last 4 months.',
        additionalFields: []
      },
      'CODES-TOTAL': {
        title: 'Monthly Discount Codes',
        explainer: 'The number of custom discount codes created per month over the last 3 months.',
        additionalFields: []
      },
      RATES: {
        title: 'Monthly Custom Rates',
        explainer: 'The number of custom rates created per month over the last 3 months.',
        additionalFields: []
      },
      'CODES-PERCENTAGE': {
        title: '% Promoters With Codes',
        explainer: 'The percentage of promoters driving traffic who have custom discount codes.',
        additionalFields: []
      },
      CONTRACTS: {
        title: 'Opportunities & Collaborations',
        explainer: 'Whether you are actively leveraging the Opportunities or Collaborations features to manage your campaigns.',
        isBinaryScoring: true,
        additionalFields: []
      },
      'LIST-USERS': {
        title: 'Building Lists',
        explainer: 'The number of creators you have added to lists per month over the last 3 months.',
        additionalFields: []
      },
      VOLUME: {
        title: 'Order Volume',
        explainer: 'The average monthly order volume over the last 3 months.',
        additionalFields: []
      },
      'ELITE-PROMOTERS': {
        title: 'Elite Promoters',
        explainer: 'The number of promoters driving traffic who are considered elite by the ShopMy algorithm.',
        additionalFields: []
      },
      'EARNING-ELITE-PROMOTERS': {
        title: 'Earning Elite Promoters',
        explainer: 'The number of promoters who have earned commission and are considered elite by the ShopMy algorithm.',
        additionalFields: []
      },
      DTC: {
        title: 'Traffic % DTC',
        explainer: 'The percentage of traffic driven to your DTC site as opposed to retailers.',
        additionalFields: []
      },
      CLICKS: {
        title: 'Traffic Driven',
        explainer: 'The total number of monthly clicks driven to your site over the last 3 months.',
        additionalFields: []
      },
      PROMOTERS: {
        title: 'Total Promoters',
        explainer: 'The total number of promoters driving traffic to your products in the last 3 months.',
        additionalFields: []
      },
      'EARNING-PROMOTERS': {
        title: 'Earning Promoters',
        explainer: 'The number of promoters who have earned commission in the last 3 months.',
        additionalFields: []
      },
      'GIFTING-PROMOTION': {
        title: 'Gifting Promotion Rate',
        explainer: 'The percentage of promoters who have been sent a gifting request and have promoted it over the last 3 months.',
        additionalFields: []
      },
      'GIFTING-ACCEPTANCE': {
        title: 'Gifting Acceptance Rate',
        explainer: 'The percentage of promoters who have been sent a gifting request and have accepted it over the last 3 months.',
        additionalFields: []
      },
      'BONUS-PROMOTERS': {
        title: 'Earning Promoters Onboarded',
        explainer: 'A bonus for the number of creators onboarded by your team who earn commission.',
        additionalFields: []
      },
      'BONUS-ELITE-PROMOTERS': {
        title: 'Earning, Elite Promoters Onboarded',
        explainer: 'A bonus for the number of creators onboarded by your team who earn commission and are considered elite by the ShopMy algorithm.',
        additionalFields: []
      }
    }[key] || {
      title: _.startCase(key)
    });

  // Parse Raw into a more usable format
  const scoreComponents = scoreComponentKeys.map(key => {
    const points = parsedRaw[key];
    const max = parsedRaw[`${key}-MAX`];
    const marks = parsedRaw[`${key}-MARKS`] ? [0, ...parsedRaw[`${key}-MARKS`]] : null;
    const section = parsedRaw[`${key}-SECTION`];
    const markValue = parsedRaw[`${key}-VALUE`];
    const additionalData = getDataForComponentKey(key);

    return {
      ...additionalData,
      key,
      points,
      markValue,
      marks,
      section,
      max
    };
  });

  // Group into sections
  const SECTION_DATA_WITH_ORDER = [
    {
      title: 'Activity',
      key: 'ACTIVITY',
      subsections: [
        {
          title: 'Creator Outreach',
          key: 'OUTREACH',
          componentKeys: ['CHAT', 'GIFTING']
        },
        {
          title: 'Affiliate Management',
          key: 'PROMOS',
          componentKeys: ['CODES-TOTAL', 'RATES', 'CODES-PERCENTAGE']
        },
        {
          title: 'Manage',
          key: 'MANAGE',
          componentKeys: ['CONTRACTS', 'LIST-USERS']
        }
      ]
    },
    {
      title: 'Account Setup',
      key: 'SETUP'
    },
    {
      title: 'Performance',
      key: 'PERFORMANCE',
      subsections: [
        {
          title: 'Orders & Traffic',
          key: 'ORDERS',
          componentKeys: ['VOLUME', 'DTC', 'CLICKS']
        },
        {
          title: '# of Promoters',
          key: 'PROMOTERS',
          componentKeys: ['ELITE-PROMOTERS', 'EARNING-ELITE-PROMOTERS', 'EARNING-PROMOTERS', 'PROMOTERS']
        },
        {
          title: 'Gifting',
          key: 'GIFTING',
          componentKeys: ['GIFTING-PROMOTION', 'GIFTING-ACCEPTANCE']
        }
      ]
    },
    {
      title: 'Bonus Points',
      key: 'BONUS'
    }
  ];
  const groupedComponents = _.groupBy(scoreComponents, 'section');
  const sections = SECTION_DATA_WITH_ORDER.map(section => {
    const all_components = groupedComponents[section.key] || [];

    if (section.subsections) {
      const subsections = section.subsections.map(subsection => {
        const subsectionComponents = subsection.componentKeys.map(key => all_components.find(c => c.key === key)).filter(Boolean);
        const nonZeroComponents = subsectionComponents.filter(c => c.max > 0);
        const sortedSubsectionComponents = _.orderBy(nonZeroComponents, c => c.max, 'desc');

        return {
          ...subsection,
          components: sortedSubsectionComponents
        };
      });

      return {
        ...section,
        subsections
      };
    } else {
      const nonZeroComponents = all_components.filter(c => c.max > 0);
      const sortedComponents = _.orderBy(nonZeroComponents, c => c.max, 'desc');
      return {
        ...section,
        components: sortedComponents
      };
    }
  });

  return {
    currentTier: { ...tierData },
    score: brandTier.score,
    components: scoreComponents,
    sections
  };
};

export const cleanChartData = snapshots => {
  /*
    Clean daily snapshots into a format ready for section charts
    {
      ACTIVITY: {
        sectionPoints: [{ date: '2023-01-01', value: 10 }, ...],
        GIFTING: [{ date: '2023-01-01', value: 10 }, ...],
        ...
      },
      ...
    }
  */
  if (!snapshots?.length) return null;

  const parsedSnapshots = snapshots.map(s => JSON.parse(s.raw));
  const snapshotDates = snapshots.map(s => s.snapshottedAt);

  const sectionChartData = {};

  parsedSnapshots.forEach((ps, idx) => {
    const psDate = snapshotDates[idx];

    const scoreComponentKeys = _.keys(ps)
      .filter(key => key.includes('-MAX'))
      .map(key => key.split('-MAX')[0]);

    const sectionTotalPoints = {};

    scoreComponentKeys.forEach(key => {
      const section = ps[`${key}-SECTION`];
      const points = ps[key];
      const value = ps[`${key}-VALUE`];

      if (!sectionChartData[section]) {
        sectionChartData[section] = {};
      }

      sectionChartData[section][key] = [...(sectionChartData[section][key] || []), { date: psDate, value }];
      sectionTotalPoints[section] = parseFloat(((sectionTotalPoints[section] || 0) + points).toFixed(2));
    });

    Object.keys(sectionTotalPoints).forEach(section => {
      sectionChartData[section].sectionPoints = [
        ...(sectionChartData[section]?.sectionPoints || []),
        { date: psDate, value: sectionTotalPoints[section] }
      ];
    });
  });

  return sectionChartData;
};

export const THREE_MONTHS_COMPARE = '3M';
export const ONE_MONTHS_COMPARE = '1M';
export const ONE_WEEK_COMPARE = '1W';

export const PERFORMANCE_COMPARE_OPTIONS_DISPLAY = {
  [THREE_MONTHS_COMPARE]: '3 Months Ago',
  [ONE_MONTHS_COMPARE]: '1 Month Ago',
  [ONE_WEEK_COMPARE]: '1 Week Ago'
};

export const PERFORMANCE_COMPARE_OPTIONS = [THREE_MONTHS_COMPARE, ONE_MONTHS_COMPARE, ONE_WEEK_COMPARE];

export const PERFORMANCE_COMPARE_OPTION_GROUP = 'Past Performance';
export const OTHER_PROGRAMS_OPTION_GROUP = 'Top Programs';
export const OTHER_ACCOUNT_MANAGER_PROGRAMS_OPTION_GROUP = 'Managed Programs';
