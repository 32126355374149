import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Tooltip from '../General/Tooltip';
import './RequestsControlBar.scss';

import Select from '../General/Select';

import { getAnnouncementByType } from '../../Helpers/announcement_helpers';
import { isSubscribedToFeature } from '../../Helpers/subscription_helpers';

const GiftingControlBar = props => {
  const {
    tab,
    user,
    handleTabChange,
    createNewEmptyLookbook,
    createNewOpportunity,
    loadGiftingRecommendations,
    isLoadingRecommendations,
    isLoading
  } = props;

  const [shouldExcludeBrandSellers, setShouldExcludeBrandSellers] = useState(false);
  const canAccessTab = isSubscribedToFeature(user, 'OPPORTUNITIES') || isSubscribedToFeature(user, 'GIFTING');

  // For space concerns, allow hiding and showing the search bar
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const showSearchBar = () => setIsSearchBarVisible(true);
  const hideSearchBar = () => setIsSearchBarVisible(false);

  const handleRefreshRecommendations = async () => {
    await loadGiftingRecommendations(shouldExcludeBrandSellers);
  };

  const toggleExcludeBrandSellers = async () => {
    setShouldExcludeBrandSellers(!shouldExcludeBrandSellers);
    await loadGiftingRecommendations(!shouldExcludeBrandSellers);
  };

  const getNewTalentExplanation = () => {
    return `When enabled, talent who have already driven sales for ${user.profile.name} will not appear in recommendations.`;
  };

  return (
    <div className='requests-control-bar'>
      <div className='control-bar-section' id='tabs'>
        <div className={cn('tab-container', { active: tab === 'requests' })} onClick={() => handleTabChange('/requests')}>
          <span className='tab-container-tab'>All Requests</span>
        </div>
        <div className={cn('tab-container', { active: tab === 'recommendations' })} onClick={() => handleTabChange('/recommendations')}>
          <span className='tab-container-tab'>Recommendations</span>
        </div>
        <div className={cn('tab-container', { active: tab === 'lookbooks' })} onClick={() => handleTabChange('/lookbooks')}>
          {!!getAnnouncementByType(user, 'LOOKBOOKS') && <span className='new-badge'>NEW</span>}
          <span className='tab-container-tab'>Lookbooks</span>
        </div>
        <div className={cn('tab-container', { active: tab === 'opportunities' })} onClick={() => handleTabChange('/opportunities')}>
          {!!getAnnouncementByType(user, 'OPPORTUNITIES') && <span className='new-badge'>NEW</span>}
          <span className='tab-container-tab'>Opportunities</span>
        </div>
      </div>
      <div className='control-bar-section' id='search-and-filter'>
        {tab === 'requests' && (
          <div className='select-and-search-container'>
            <div className='select-container'>
              <Select
                noStyling
                placeholder='Only Show'
                classNamePrefix='sort-control'
                onChangeValue={props.setSelectedFilterValue}
                className='sort-control'
                options={props.filterOptionGroups}
                value={props.selectedFilter.value}
              />
            </div>
            <div className='search-container'>
              <input
                type='text'
                disabled={!canAccessTab}
                value={props.curSearchVal}
                className='gifting-search-input'
                onChange={e => props.changeSearchVal(e.target.value)}
                placeholder='Search'
              />
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </div>
        )}
        {tab === 'lookbooks' && (
          <>
            <div className={cn('add-button', { isLoading })} onClick={createNewEmptyLookbook}>
              + New Lookbook
            </div>
          </>
        )}
        {tab === 'opportunities' && (
          <div className='select-and-search-container'>
            <div className='search-container'>
              {isSearchBarVisible && (
                <input
                  type='text'
                  autoFocus
                  value={props.curSearchVal}
                  className='gifting-search-input'
                  onBlur={() => !props.curSearchVal && setTimeout(() => hideSearchBar(), 100)}
                  onChange={e => props.changeSearchVal(e.target.value)}
                  placeholder='Search'
                />
              )}
              <FontAwesomeIcon
                onClick={() => {
                  if (isSearchBarVisible) {
                    hideSearchBar();
                    props.changeSearchVal('');
                  } else {
                    showSearchBar();
                  }
                }}
                icon={isSearchBarVisible ? faTimes : faSearch}
              />
            </div>
            <div className={cn('add-button', { isLoading })} onClick={createNewOpportunity}>
              + New Opportunity
            </div>
          </div>
        )}
        {tab === 'recommendations' && (
          <>
            <div className='recommendation-actions'>
              <div className='recommendation-control-switch'>
                <div className='recommendation-control-label'>
                  New Talent Only
                  <Tooltip className='info-tooltip' message={getNewTalentExplanation()} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle} />} />
                </div>
                <Switch
                  onChange={toggleExcludeBrandSellers}
                  checked={shouldExcludeBrandSellers}
                  height={14}
                  width={24}
                  onColor='#333'
                  offColor='#ccc'
                  className='switch'
                  checkedIcon={false}
                  uncheckedIcon={false}
                />
              </div>
              <button
                className={cn('recommendation-control-button', { disabled: isLoadingRecommendations })}
                onClick={handleRefreshRecommendations}
                disabled={isLoadingRecommendations}
              >
                <FontAwesomeIcon icon={faSyncAlt} className={cn({ 'fa-spin': isLoadingRecommendations })} />
                Refresh
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

GiftingControlBar.propTypes = {
  user: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  loadGiftingRecommendations: PropTypes.func.isRequired,
  isLoadingRecommendations: PropTypes.bool.isRequired,

  curSearchVal: PropTypes.string,
  changeSearchVal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,

  // Filtering
  selectedFilter: PropTypes.object.isRequired,
  setSelectedFilterValue: PropTypes.func.isRequired,
  filterOptionGroups: PropTypes.array.isRequired,

  createNewEmptyLookbook: PropTypes.func.isRequired,
  createNewOpportunity: PropTypes.func.isRequired
};

export default GiftingControlBar;
