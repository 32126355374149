import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import './ContractSearchLinks.scss';

import ContractLink from './ContractLink';
import InputActions from '../../General/InputActions';
import Loader from '../../Loader/Loader';

import { getUrlBase } from '../../../Helpers/helpers';
import { isUrlString } from '../../../Helpers/formatting';
import { getContractLinkOptionsFromSearchQuery } from '../../../APIClient/collaborations';

let searchDebouncer;
const ContractSearchLinks = props => {
  const { contract, createContractLink } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [results, setResults] = useState([]);
  const isUrlSearch = isUrlString(searchVal);

  const updateSearch = async newVal => {
    setSearchVal(newVal);
    if (!newVal) {
      setIsSearching(false);
      setResults([]);
      return;
    }
    setIsSearching(true);
    clearTimeout(searchDebouncer);
    searchDebouncer = setTimeout(async () => {
      try {
        const resp = await getContractLinkOptionsFromSearchQuery(newVal, contract.brand.id);
        setResults(resp.links);
      } catch (error) {
        cogoToast.error(`There was an error, please try again.`);
      }
      setIsSearching(false);
    }, 500);
  };
  const extraClasses = { searching: isSearching };
  const hasMatchingUrls = (l, l2) => getUrlBase(l.url) === getUrlBase(l2.url);
  const allResults = [
    ...(isUrlSearch && !results.find(result => hasMatchingUrls(result, { url: searchVal }))
      ? [
          {
            title: 'New Link',
            url: searchVal
          }
        ]
      : []),
    ...results
  ].filter(link => {
    const alreadyHasLink = !!contract.links.find(l => {
      const sameUrl = link.url && hasMatchingUrls(l, link);
      const sameProductId = link.Product_id && l.Product_id === link.Product_id;
      return sameUrl || sameProductId;
    });
    return !alreadyHasLink;
  });
  const inCatalog = !!contract.brand.scraper;
  return (
    <div className={cn('contract-search-links-outer-container', extraClasses)}>
      <div className='search-input-container has-actions'>
        <input
          value={searchVal}
          className='title'
          placeholder={inCatalog ? 'Search your products or paste a URL' : 'Paste a URL'}
          onChange={e => updateSearch(e.target.value)}
        />
        <InputActions searchVal={searchVal} onCancel={() => updateSearch('')} />
      </div>
      {!searchVal ? null : isSearching ? (
        <div className='searching-loader'>
          <Loader size={80} />
        </div>
      ) : allResults.length ? (
        <div className='results'>
          {allResults.map((link, idx) => {
            return (
              <ContractLink
                isTemplate
                link={link}
                key={link.id || idx}
                createContractLink={createContractLink}
                contract={contract}
                postCreationCallback={() => updateSearch('')}
              />
            );
          })}
        </div>
      ) : (
        <div className='empty-results-msg'>We could not find any results{isUrlSearch ? '' : ', please add using a URL'}</div>
      )}
    </div>
  );
};

ContractSearchLinks.propTypes = {
  contract: PropTypes.object.isRequired,
  createContractLink: PropTypes.func.isRequired
};

export default ContractSearchLinks;
