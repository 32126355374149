import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RequiresLoginPanel.scss';

import { openAuthModal } from '../../Actions/UIActions';

class RequiresLoginPanel extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    openAuthModal: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className='requires-login-outer-container'>
        <div className='requires-login-inner-container'>
          <div>You must be logged in to view this page.</div>
          <div onClick={() => this.props.openAuthModal('login')} className='login-btn'>
            Log in
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(RequiresLoginPanel);
