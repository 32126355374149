import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './EditShopSection.scss';

import { getSectionPreviewTypeTemplates } from '../../Helpers/section_helpers';

import SectionTemplate from './Templates/SectionTemplate';

const EditShopSection = props => {
  const { section } = props;

  const [showDoneButton, setShowDoneButton] = useState(false);
  const [title, setTitle] = useState(section?.title || '');
  const [isHidden, setIsHidden] = useState(section?.isHidden || false);
  const [socialSources, setSocialSources] = useState(section?.socialSources?.split(',') || []);

  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(window.shopSectionTitleDebounce);
    window.shopSectionTitleDebounce = setTimeout(() => {
      props.updateSection(section, { title: newTitle });
    }, 750);
    setShowDoneButton(true);
  };

  const templates = getSectionPreviewTypeTemplates();

  const selectTemplate = template => {
    props.updateSection(section, { previewType: template.previewType });
    setShowDoneButton(true);
  };

  const updateSocialSources = newSources => {
    setSocialSources(newSources);
    props.updateSection(section, { socialSources: newSources.join(',') });
    setShowDoneButton(true);
  };

  const updateVisibility = newVisibility => {
    setIsHidden(newVisibility);
    props.updateSection(section, { isHidden: newVisibility });
    setShowDoneButton(true);
  };
  const toggleVisibility = () => updateVisibility(!isHidden);

  const panels = [
    {
      header: 'Edit Section Title',
      body: (
        <div>
          <input placeholder='Section Title' onChange={({ target }) => updateTitle(target.value)} value={title.toUpperCase()} />
        </div>
      )
    },
    {
      header: 'Visible to Shoppers',
      // subheader: 'Choose whether to show this section on your shop.',
      body: (
        <div className='checkbox-wrappers'>
          <div onClick={toggleVisibility} className={cn('checkbox-wrapper', { selected: !isHidden })}>
            <div className={cn('checkbox', { selected: !isHidden })}>{!isHidden && <FontAwesomeIcon icon={faCheck} />}</div>
            <div className={cn('display', { selected: !isHidden })}>Visible</div>
          </div>
          <div onClick={toggleVisibility} className={cn('checkbox-wrapper', { selected: isHidden })}>
            <div className={cn('checkbox', { selected: isHidden })}>{isHidden && <FontAwesomeIcon icon={faCheck} />}</div>
            <div className={cn('display', { selected: isHidden })}>Hidden</div>
          </div>
        </div>
      )
    },
    {
      header: 'Edit Preview Look',
      subheader: 'Specify how you want the collections in this section to appear on the your shop.',
      body: (
        <div className='shop-section-templates'>
          {templates.map(template => (
            <SectionTemplate key={template.previewType} section={section} template={template} selectTemplate={selectTemplate} />
          ))}
        </div>
      )
    },
    {
      header: 'Social Sources',
      subheader: 'By selecting a social platform below, we will prompt you to upload from that platform when you click to add a new collection.',
      body: (
        <div className='checkbox-wrappers'>
          {[
            { display: 'Instagram', source: 'instagram' },
            { display: 'TikTok', source: 'tiktok' },
            { display: 'Youtube', source: 'youtube' }
          ].map(data => {
            const { source, display } = data;
            const isSelected = socialSources.includes(source);
            const toggle = () => updateSocialSources(isSelected ? socialSources.filter(s => s !== source) : [...socialSources, source]);
            return (
              <div key={source} onClick={toggle} className={cn('checkbox-wrapper', { selected: isSelected })}>
                <div className={cn('checkbox', { selected: isSelected })}>{isSelected && <FontAwesomeIcon icon={faCheck} />}</div>
                <div className={cn('display', { selected: isSelected })}>{display}</div>
              </div>
            );
          })}
        </div>
      )
    }
  ];

  if (!section) {
    return (
      <div className='edit-shop-section-outer-container'>
        <div className='none-selected'>Please select from the recommended section templates</div>
      </div>
    );
  }

  return (
    <div className='edit-shop-section-outer-container'>
      <div className='back-to-sections-btn-mobile-only' onClick={props.clearActiveSection}>
        <FontAwesomeIcon icon={faChevronLeft} />
        All Sections
      </div>
      <div className='panels'>
        {panels.map(panel => {
          const { header, subheader, body } = panel;
          return (
            <div key={header} className='panel'>
              <div className='panel-header-container'>
                <div className='panel-header'>{header}</div>
                <div className='panel-subheader'>{subheader}</div>
              </div>
              <div className='panel-body'>{body}</div>
            </div>
          );
        })}
        {showDoneButton && (
          <div className='action-btns'>
            <div onClick={() => setShowDoneButton(false)} className='action done'>
              DONE
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

EditShopSection.propTypes = {
  addSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  clearActiveSection: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  section: PropTypes.object
};

export default EditShopSection;
