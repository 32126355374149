import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPencil, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './ContractPin.scss';

import { copyToClipboard } from '../../../Helpers/helpers';
import { getShortPinLink } from '../../../Helpers/attribution_helpers';
import { getPrettyNumber } from '../../../Helpers/formatting';

const ContractPin = props => {
  const { pin, canEdit, isPreviewMode } = props;
  const { image, title, analytics, link, createdAt } = pin;
  const { clicks, volume } = analytics || {};

  const edit = () => {
    props.toggleAddOrEditPinMode(pin);
  };

  const shortLink = getShortPinLink(pin);
  return (
    <div className='contract-content-element pin'>
      <div className={cn('main', { 'has-shareable-link': canEdit })}>
        <div className='image-container'>{image ? <img src={image} alt={title} /> : <div className='empty-img' />}</div>
        <div className='data'>
          <div className='title'>{title}</div>
          {analytics ? (
            <div className='performance-analytics'>
              {clicks ? `${getPrettyNumber(clicks)} click${clicks === 1 ? '' : 's'}` : ''}
              {volume ? `${clicks ? `, ` : ''}$${getPrettyNumber(volume)} order volume` : ''}
              {!clicks && !volume ? (canEdit ? `Created On ${moment(createdAt).format('MMMM Do')}` : `No click activity yet`) : ''}
            </div>
          ) : (
            <div className='performance-analytics'>Calculating Performance...</div>
          )}
        </div>
      </div>
      <div className='secondary'>
        {canEdit ? (
          <>
            <div className='cell lg short-link'>
              <div className='link'>{shortLink}</div>
              <div className='copy-btn' onClick={() => copyToClipboard(shortLink, true)}>
                Copy
              </div>
            </div>
            {!isPreviewMode && (
              <div className='link-actions'>
                <a href={link} target='_blank' rel='noopener noreferrer' className='url '>
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
                <div onClick={edit} className='link-action edit'>
                  <FontAwesomeIcon icon={faPencil} />
                </div>
                <div onClick={() => props.deletePin(pin)} className='link-action delete'>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className='link-actions'>
            <a href={link} target='_blank' rel='noopener noreferrer' className='url '>
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

ContractPin.propTypes = {
  pin: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  toggleAddOrEditPinMode: PropTypes.func.isRequired,
  deletePin: PropTypes.func.isRequired,

  isPreviewMode: PropTypes.bool
};

export default ContractPin;
