import React from 'react';
import PropTypes from 'prop-types';
import './LookbookReportStats.scss';
import _ from 'lodash';
import { getPrettyNumber } from '../../../../Helpers/formatting';
import Loader from '../../../Loader/Loader';
import { getLookbookRequests } from '../../../../Helpers/lookbook_helpers';
import { lookbookReportItemFilter } from '../../../../Helpers/lookbook_helpers';

const LookbookReportStats = props => {
  const { lookbook, analytics, pins, mentionUserStats, startDate, endDate, isFetchingPins } = props;
  const lookbookRequests = getLookbookRequests(analytics, lookbook);
  const requests = lookbookRequests.filter(r => lookbookReportItemFilter(r, 'request', startDate, endDate));

  const totalRequestCount = requests.length;
  const requestsPromotedCount = requests.filter(request => request.userPromoted).length;
  const promotedPercentage = totalRequestCount ? Math.round((requestsPromotedCount / totalRequestCount) * 100).toFixed() : 0;
  const acceptedCount = requests.filter(request => request.userAccepted).length;
  const acceptedPercentage = totalRequestCount ? Math.round((acceptedCount / totalRequestCount) * 100).toFixed() : 0;

  const clicks = _.sumBy(pins, 'views');
  const volume = _.sumBy(pins, 'orderVolumeTotal');

  const views = _.sumBy(mentionUserStats, 'views');
  const estimatedEmv = _.sumBy(pins, 'estimatedMediaValue');

  return (
    <div className='lookbook-report-stats-outer'>
      <div className='lookbook-report-stats-inner'>
        <div className='stat-container'>
          <div className='stat-title'>Users Invited</div>
          <div className='stat-value'>{totalRequestCount ? getPrettyNumber(totalRequestCount || 0, { precision: 0 }) : '-'}</div>
        </div>

        <div className='stat-container'>
          <div className='stat-title'>% Accepted</div>
          <div className='stat-value'>{acceptedPercentage ? `${acceptedPercentage}%` : '-'}</div>
        </div>

        <div className='stat-container less-important'>
          <div className='stat-title'>% Promoted</div>
          <div className='stat-value'>{promotedPercentage ? `${promotedPercentage}%` : '-'}</div>
        </div>

        <div className='stat-container'>
          <div className='stat-title'>Clicks</div>
          <div className='stat-value'>{!isFetchingPins ? getPrettyNumber(clicks, { precision: 0 }) || '-' : <Loader size={20} />}</div>
        </div>

        <div className='stat-container'>
          <div className='stat-title'>Volume</div>
          <div className='stat-value'>
            {!isFetchingPins ? volume ? `$${getPrettyNumber(volume || 0, { precision: 0 })}` : '-' : <Loader size={20} />}
          </div>
        </div>

        <div className='stat-container'>
          <div className='stat-title'>Views</div>
          <div className='stat-value'>{!isFetchingPins ? getPrettyNumber(views, { precision: 0 }) || '-' : <Loader size={20} />}</div>
        </div>

        <div className='stat-container'>
          <div className='stat-title'>EMV</div>
          <div className='stat-value'>
            {!isFetchingPins ? estimatedEmv ? `$${getPrettyNumber(estimatedEmv, { precision: 0 })}` : '-' : <Loader size={20} />}
          </div>
        </div>
      </div>
    </div>
  );
};

LookbookReportStats.propTypes = {
  mentionUserStats: PropTypes.array.isRequired,
  pins: PropTypes.array.isRequired,
  analytics: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,

  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isFetchingPins: PropTypes.bool.isRequired
};

export default LookbookReportStats;
