import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomeAnimations.scss';

const HomeAnimations = props => {
  const [isInAnimation, setIsInAnimation] = useState(false);
  const [currentlyAnimatedSectionIdx, setCurrentlyAnimatedSectionIdx] = useState(0);
  const [isTransitioningBetweenIdxs, setIsTransitioningBetweenIdxs] = useState(false);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const sectionThreeRef = useRef(null);
  const sectionFourRef = useRef(null);
  const refs = [sectionOneRef, sectionTwoRef, sectionThreeRef, sectionFourRef];
  const sections = props.animations.map((a, idx) => ({ ...a, ref: refs[idx] }));

  const hasVideos = !!sections.find(s => !!s.video);
  const getIdForSection = (idx, type) => `section-${idx}-${type}`;
  const checkPanelInView = () => {
    // Start/end the animation when the fixed div hits the relational div
    const startElement = document.getElementById(getIdForSection(0, 'fixed'));
    const startElementTouch = document.getElementById(getIdForSection(0, 'not-fixed'));
    const endElement = document.getElementById(getIdForSection(sections.length - 1, 'fixed'));
    const endElementTouch = document.getElementById(getIdForSection(sections.length - 1, 'not-fixed'));
    const startElementPosition = startElement?.getBoundingClientRect() || {};
    const startElementTouchPosition = startElementTouch?.getBoundingClientRect() || {};
    const endElementPosition = endElement?.getBoundingClientRect() || {};
    const endElementTouchPosition = endElementTouch?.getBoundingClientRect() || {};
    const startElementTop = startElementPosition.top;
    const startElementTouchTop = startElementTouchPosition.top;
    const endElementTop = endElementPosition.top;
    const endElementBottom = endElementPosition.bottom;
    const endElementTouchTop = endElementTouchPosition.top;
    const hasOverlapStarted = startElementTop < startElementTouchTop;
    const hasOverlapEnded = endElementTop < endElementTouchTop;

    const shouldBeInOverlapAnimation = hasOverlapStarted && !hasOverlapEnded;
    const isTransitioningAnimation = shouldBeInOverlapAnimation !== isInAnimation;
    if (shouldBeInOverlapAnimation) {
      if (!isInAnimation) {
        setIsInAnimation(true);
      }
    } else if (isInAnimation) {
      setIsInAnimation(false);
    }

    // Play the static video if in view
    const isVideoPlaying = video => !video.paused && video.currentTime;
    const isVideoPaused = video => video.paused || !video.currentTime;
    const playVideo = (video, startTime = 0) => {
      video.currentTime = startTime;
      video.play();
    };
    const pauseVideo = video => {
      video.pause();
      video.currentTime = 0;
    };
    const isFirstElementInWindow = startElementTop > 0 && startElementTop < window.innerHeight;
    const isLastElementInWindow = endElementBottom > 0 && endElementBottom < window.innerHeight;
    const isStaticVideoPlaying = (isFirstElementInWindow || isLastElementInWindow) && !isInAnimation;
    if (!isInAnimation && hasVideos) {
      // Check if we are on the fringes
      const videoInView = isFirstElementInWindow ? startElement : isLastElementInWindow ? endElement : null;
      const touchInView = isFirstElementInWindow ? startElementTouch : isLastElementInWindow ? endElementTouch : null;
      const video = videoInView && videoInView.querySelector('video');

      if (video) {
        if (isVideoPaused(video)) playVideo(video, touchInView.querySelector('video').currentTime);
      } else {
        const startStaticVideo = startElement.querySelector('video');
        if (isVideoPlaying(startStaticVideo)) pauseVideo(startStaticVideo);

        const endStaticVideo = endElement.querySelector('video');
        if (isVideoPlaying(endStaticVideo)) pauseVideo(endStaticVideo);
      }
    }

    // Handle which section we are in by looking at the closest to the middle of the page
    const sectionOnePosition = sectionOneRef.current?.querySelector('.title')?.getBoundingClientRect() || {};
    const sectionTwoPosition = sectionTwoRef.current?.querySelector('.title')?.getBoundingClientRect() || {};
    const sectionThreePosition = sectionThreeRef.current?.querySelector('.title')?.getBoundingClientRect() || {};
    const sectionFourPosition = sectionFourRef.current?.querySelector('.title')?.getBoundingClientRect() || {};
    const sectionPositions = [sectionOnePosition, sectionTwoPosition, sectionThreePosition, sectionFourPosition];
    const sectionMiddlePoints = sectionPositions.map(section => {
      const { top, bottom } = section;
      return (top + bottom) / 2;
    });
    const middleOfPage = window.innerHeight / 2;
    const closestSectionIdx = sectionMiddlePoints.reduce((prev, curr, idx) => {
      return Math.abs(curr - middleOfPage) < Math.abs(sectionMiddlePoints[prev] - middleOfPage) ? idx : prev;
    }, 0);

    // Play the fixed video
    const isTransitioningBetweenPoints = currentlyAnimatedSectionIdx !== closestSectionIdx || isStaticVideoPlaying;
    if ((shouldBeInOverlapAnimation || isTransitioningAnimation) && isTransitioningBetweenPoints) {
      setCurrentlyAnimatedSectionIdx(closestSectionIdx);

      if ((currentlyAnimatedSectionIdx || 0) !== closestSectionIdx) {
        setIsTransitioningBetweenIdxs(true);
        setTimeout(() => setIsTransitioningBetweenIdxs(false), 100);
      }
      const video = sections[closestSectionIdx].ref.current.querySelector('.video');
      if (video) {
        const isFirst = closestSectionIdx === 0;
        const isLast = closestSectionIdx === sections.length - 1;

        // Start the video at the right time
        let startTime = 0;
        if (isFirst && currentlyAnimatedSectionIdx === 0) startTime = startElement.querySelector('video').currentTime;
        if (isLast && currentlyAnimatedSectionIdx === sections.length - 1) startTime = endElement.querySelector('video').currentTime;
        playVideo(video, startTime);
      }
    }
  };

  // Add scroll listener
  useEffect(() => {
    window.addEventListener('scroll', checkPanelInView);
    return () => window.removeEventListener('scroll', checkPanelInView);
  }, [isInAnimation, currentlyAnimatedSectionIdx, props.animations]);

  // Due to a bug in React not showing muted on the DOM, we need to force react to mark the element as muted, otherwise
  // SNAP will not pick up the muted attribute and will throw an error due to autoplay limitations.
  useEffect(() => {
    document.querySelectorAll('video').forEach(video => {
      video.setAttribute('muted', ''); // Set the attribute for SNAP to pick up
    });
  }, []);

  const additionalClasses = {
    animating: isInAnimation,
    'transitioning-idxs': isTransitioningBetweenIdxs,
    'hide-numbers': props.hideNumbers,
    'dark-mode': props.isDarkMode
  };
  return (
    <div className={cn('home-animations-outer-container', additionalClasses)}>
      <div className='home-animations-inner-container'>
        {!props.hideHeader && (
          <div className={cn(additionalClasses, 'main-statement')}>
            <div className={cn(additionalClasses, 'badge')}>ShopMy's full-funnel approach</div>
            <h2 className={cn(additionalClasses, 'title')}>Creator marketing is your key to boost brand capital and drive revenue.</h2>
          </div>
        )}
        <div className={cn('sections', additionalClasses)}>
          {sections.map((section, idx) => {
            const { ref, title, subtitle, video, image } = section;
            const isSectionActive = currentlyAnimatedSectionIdx === idx;
            const isVideoSection = !!video;
            const isImageSection = !isVideoSection && !!image;
            const sectionClasses = { ...additionalClasses, active: isSectionActive, 'is-video-section': isVideoSection };
            const isMobile = window.innerWidth < 768;
            const showPreview = !idx || idx === sections.length - 1 || isMobile;
            return (
              <div ref={ref} className={cn(sectionClasses, 'section')} key={idx}>
                <div className={cn(sectionClasses, 'panel left')}>
                  <div
                    id={getIdForSection(idx, 'fixed')}
                    className={cn(sectionClasses, { 'video-container': isVideoSection, 'image-container': isImageSection }, 'not-fixed')}
                  >
                    {showPreview && (
                      <>
                        {video ? (
                          <video
                            muted
                            loop={isMobile}
                            autoPlay={isMobile}
                            className={cn(sectionClasses, 'static-video')}
                            playsInline
                            src={section.video}
                            type='video/mp4'
                          />
                        ) : (
                          <img alt={title} className={cn(sectionClasses, 'static-image')} src={image} />
                        )}
                      </>
                    )}
                  </div>
                  <div
                    id={getIdForSection(idx, 'not-fixed')}
                    className={cn(sectionClasses, { 'video-container': isVideoSection, 'image-container': isImageSection }, 'fixed')}
                  >
                    {video ? (
                      <video className={cn(sectionClasses, 'video')} loop muted playsInline src={video} type='video/mp4' />
                    ) : (
                      <img alt={title} className={cn(sectionClasses, 'image')} src={image} />
                    )}
                  </div>
                </div>
                <div className={cn(sectionClasses, 'panel right')}>
                  <div className={cn(sectionClasses, 'content')}>
                    <div className={cn(sectionClasses, 'dot')}>{idx + 1}</div>
                    <div className={cn(sectionClasses, 'text')}>
                      <h3 className='title'>{title}</h3>
                      <h4 className='description'>{subtitle}</h4>
                      {section.to && (
                        <Link to={section.to} className='learn-more-btn'>
                          Learn More
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

HomeAnimations.propTypes = {
  animations: PropTypes.array.isRequired,

  // Optional UI
  hideHeader: PropTypes.bool,
  hideNumbers: PropTypes.bool,
  isDarkMode: PropTypes.bool
};

export default HomeAnimations;
