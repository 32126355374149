import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './TiktokPlayer.scss';

import Loader from '../Loader/Loader';

const TiktokPlayer = props => {
  const { videoId } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    document.body.appendChild(script);

    const slowLoadVideo = iframe => {
      setTimeout(() => {
        setIsLoaded(true); // Glitchy load on Tiktok's end so delay for a smoother experience
      }, 2000);
    };

    let lookForIframe;
    lookForIframe = setInterval(() => {
      const iframe = document.querySelector('iframe[src*="tiktok.com"]');
      iframe && clearInterval(lookForIframe);
      iframe && slowLoadVideo(iframe);
    }, 50);
  }, []);

  return (
    <div className='tiktok-player-outer-container'>
      <div className='video-loader-container'>
        <div
          className={cn('tiktok-video-shell', { loaded: isLoaded })}
          dangerouslySetInnerHTML={{
            __html: `
              <blockquote 
                class="tiktok-embed" 
                cite="https://www.tiktok.com/video/${videoId}" 
                data-video-id="${videoId}" 
                style="max-width: 605px;min-width: 325px;" >
                <section></section>
              </blockquote> 
            `
          }}
        />
        <div className='loader-container'>
          <div className='loader-container'>
            <Loader size={105} />
          </div>
        </div>
      </div>
    </div>
  );
};

TiktokPlayer.propTypes = {
  videoId: PropTypes.string.isRequired
};

export default TiktokPlayer;
