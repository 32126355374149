import APIClient from './index';
import _ from 'lodash';

import { getUserId, getBrandId } from '../Helpers/user_helpers';

export const fetchBrandAnalytics = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Brands/analytics_summary/${getBrandId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const fetchUserAnalytics = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Users/analytics_summary/${getUserId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
