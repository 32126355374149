import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

import { addEvent } from '../../APIClient/events';

import { getVariantAffiliateLink, getProductAffiliateLink } from '../../Helpers/attribution_helpers';
import { getVisibleVariantsForProduct, getCatalogObjectsFromProduct } from '../../Helpers/shop_helpers';
import { isAdmin } from '../../Helpers/user_helpers';
import { getSmartImage } from '../../Helpers/helpers';
import { getDomainFromUrl } from '../../Helpers/formatting';

import './ProductHeader.scss';

const ProductHeader = props => {
  const { product, user, scrollToExperts, shop } = props;
  const { id, fallbackUrl, image, title, rank, brand } = product || {};
  const { totalMentionCount, departmentRank, categoryRank } = rank || {};
  const { category, department } = getCatalogObjectsFromProduct(product, shop);
  const selectVariant = variant => {
    if (!isAdmin(user)) {
      addEvent('PRODUCT_VIEW', {
        ...product,
        productId: id,
        variantId: variant.id,
        retailer: variant.retailer,
        variantUrl: variant.url,
        from: 'Product Page'
      });
    }
  };

  const selectFallback = () => {
    if (!isAdmin(user)) {
      addEvent('PRODUCT_VIEW', {
        ...product,
        productId: id,
        retailer: getDomainFromUrl(fallbackUrl),
        variantUrl: fallbackUrl,
        from: 'Product Page'
      });
    }
  };

  const visibleVariants = getVisibleVariantsForProduct(product);
  return (
    <div className='product-main'>
      <div className='image-container'>
        {image ? <img className='primary-img' src={getSmartImage(image)} alt={title} /> : <div className='empty-img' />}
        {product && (
          <div onClick={scrollToExperts} className='recommended'>
            <span className='count'>
              <b>{totalMentionCount}</b> creator{totalMentionCount === 1 ? '' : 's'}
            </span>{' '}
            recommend{totalMentionCount === 1 ? 's' : ''} this product
          </div>
        )}
      </div>
      <div className='meta'>
        <h2 className='brand'>{brand}</h2>
        <h1 className='title'>{title}</h1>
        {product && (
          <>
            <div className='accent' />
            {category && (
              <Link to={`/shop/${category.urlStub}`} className='category-rank rank'>
                <div className='num'>#{categoryRank}</div> <div className='name'>{category.name}</div>
              </Link>
            )}
            {department && (
              <Link to={`/shop/${department.urlStub}`} className='department-rank rank'>
                <div className='num'>#{departmentRank}</div> <div className='name'>{department.name}</div>
              </Link>
            )}
            <div className='retailer-options'>
              <div className='buy-at'>{visibleVariants.length || fallbackUrl ? 'AVAILABLE AT' : 'CURRENTLY UNAVAILABLE'}</div>
              <div>
                {visibleVariants.length
                  ? visibleVariants.map(variant => {
                      const affiliateLink = getVariantAffiliateLink(variant, user);
                      return (
                        <a
                          key={variant.id}
                          href={affiliateLink}
                          onClick={() => selectVariant(variant)}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='retailer-option'
                        >
                          <div className='retailer'>{variant.retailer}</div>
                          <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                        </a>
                      );
                    })
                  : fallbackUrl && (
                      <a
                        href={getProductAffiliateLink(product)}
                        onClick={selectFallback}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='retailer-option'
                      >
                        <div className='retailer'>{getDomainFromUrl(fallbackUrl)}</div>
                        <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                      </a>
                    )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ProductHeader.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  scrollToExperts: PropTypes.func.isRequired,
  product: PropTypes.object
};

export default ProductHeader;
