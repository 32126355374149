import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ReportOverviewCard.scss';
import cn from 'classnames';
import _ from 'lodash';
import { getBrandTiers } from '../../Helpers/brand_tier_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

const COIN_SIZE = 28;
const COIN_BUFFER = 1;

const ReportOverviewCard = props => {
  const { tierData, compareData } = props;
  const { currentTier, score = 0 } = tierData || {};

  const [hoverScoreLabel, setHoverScoreLabel] = useState(null);

  const allTiers = getBrandTiers();

  const getSkeletonTiers = () => {
    let skeletonTiers = { before: [], after: [] };

    if (!allTiers || !currentTier?.tier) return skeletonTiers;

    if (currentTier.tier === allTiers[0].tier) {
      // show next 2 tiers
      skeletonTiers.after = allTiers.slice(1, 3);
    } else if (currentTier.tier === allTiers[allTiers.length - 1].tier) {
      // show previous 2 tiers
      skeletonTiers.before = allTiers.slice(-3, -1);
    } else {
      // show all other tiers before/after
      const currentIndex = allTiers.findIndex(tier => tier.tier === currentTier.tier);
      skeletonTiers.before = allTiers.slice(0, currentIndex);
      skeletonTiers.after = allTiers.slice(currentIndex + 1);
    }

    return skeletonTiers;
  };

  const coinMarks = [0, 30, 70, 85, 100];

  const getMarkStyle = mark => {
    let leftCalc = `max(calc(${mark}% - ${COIN_SIZE}px - ${COIN_BUFFER}px), ${COIN_BUFFER}px)`;

    const style = {
      left: leftCalc
    };

    return style;
  };

  const skeletonTiers = getSkeletonTiers();
  const declined = !_.isNil(compareData?.score) && score < compareData.score;
  const improved = !_.isNil(compareData?.score) && score > compareData.score;

  return (
    <div className='brand-tier-overview-container'>
      <div className='overview-header'>Your Program tier</div>
      <div className='tier-label-container'>
        <div className='skeleton-tier before'>
          {skeletonTiers.before.map(tier => (
            <div className={cn('tier-label skeleton', { pointsHovered: hoverScoreLabel === tier.label })} key={`skeleton-tier-${tier.tier}`}>
              <FontAwesomeIcon icon={faCheckCircle} />
              {tier.label}
            </div>
          ))}
        </div>
        <div className='tier-label main'>{currentTier?.label}</div>
        <div className='skeleton-tier after'>
          {skeletonTiers.after.map(tier => (
            <div className={cn('tier-label skeleton', { pointsHovered: hoverScoreLabel === tier.label })} key={`skeleton-tier-${tier.tier}`}>
              {tier.label}
            </div>
          ))}
        </div>
      </div>
      <div className='tier-info'>
        <div className='tier-description'>{currentTier?.description}</div>
      </div>
      <div className='point-bar-container'>
        <div className='point-bar'>
          {coinMarks.map(mark => {
            const style = getMarkStyle(mark);

            return (
              <div
                key={`coinmark-${mark}`}
                className={cn('point-bar-coin', { covered: score > mark, compare: !_.isNil(compareData?.score) })}
                style={style}
                onMouseEnter={() => setHoverScoreLabel(allTiers.find(tier => tier.minScore === mark)?.label)}
                onMouseLeave={() => setHoverScoreLabel(null)}
              >
                {mark}
              </div>
            );
          })}
          <div
            className={cn('point-bar-coin cur-score', { declined })}
            style={{
              left: `max(calc(${Math.min(score, 100)}% - ${COIN_SIZE}px - ${COIN_BUFFER}px), ${COIN_BUFFER}px)`
            }}
          >
            {Math.floor(score)}
          </div>
          <div
            className={cn('point-bar-progress', { improved, declined })}
            style={{
              width: `max(calc(${Math.min(score, 100)}% + ${COIN_BUFFER}px), ${COIN_SIZE + COIN_BUFFER}px)`
            }}
          />
          {!!compareData?.score && (
            <div
              className={cn('point-bar-progress compare', { improved })}
              style={{
                width: `max(calc(${Math.min(compareData?.score || 0, 100)}% + ${COIN_BUFFER}px), ${COIN_SIZE + COIN_BUFFER}px)`
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ReportOverviewCard.propTypes = {
  tierData: PropTypes.object
};

export default ReportOverviewCard;
