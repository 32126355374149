import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import './ContractPreview.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { updateContract, deleteContract } from '../../APIClient/collaborations';
import { scrollToTop } from '../../Helpers/helpers';
import { getInitialsForUser, getInitialsForBrand } from '../../Helpers/formatting';
import { isBrand } from '../../Helpers/user_helpers';
import { goToStripeBillingDashboard } from '../../Helpers/stripe_helpers';
import {
  DAYS_UNTIL_CONTRACT_INVOICES_DUE,
  getStatusTimeDisplayForContract,
  getAnalyticsDisplay,
  getSubmissionDueDateDaysRemaining,
  getGoLiveDueDateDaysRemaining
} from '../../Helpers/contract_helpers';

const ContractPreview = props => {
  const { contract, syncExternal, user } = props;
  const { status, tasks, Payment_id, payment, invoice, isCreatedByBrand, isCreatedByUser } = contract;

  const isCreatedByYou = isBrand(user) ? isCreatedByBrand : isCreatedByUser;
  const isPending = status === 'pending';
  const isProposed = status === 'proposed';
  const isActive = status === 'active';
  const isAccepted = status === 'accepted';
  const analyticsDisplays = getAnalyticsDisplay(contract);

  const requiresResponse = isProposed && !isCreatedByYou;

  const collabName = isBrand(user) ? contract.user?.name : contract.brand?.name;
  const collabImage = isBrand(user) ? contract.user?.image : contract.brand?.logo;
  const collabInitials = isBrand(user) ? getInitialsForUser(contract.user) : getInitialsForBrand(contract.brand);

  const deleteCollaboration = () => {
    const confirm = () => {
      deleteContract(contract).then(resp => {
        syncExternal();
        cogoToast.success(`Succesfully deleted this proposal.`);
      });
    };
    const needsConfirmation = contract.description || tasks.length > 1;
    needsConfirmation
      ? confirmAlert({
          title: 'Just confirming',
          message: `Are you sure you want to remove this proposal?`,
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: confirm
            }
          ]
        })
      : confirm();
  };

  const dismissCollaboration = () => {
    confirmAlert({
      title: 'Just confirming',
      message: `Are you sure you want to dismiss this proposal? We will not notify ${collabName}.`,
      buttons: [
        { label: 'No', className: 'cancel', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            updateContract(contract, { status: 'rejected' }).then(resp => {
              syncExternal();
              cogoToast.success(`Succesfully dismissed this proposal.`);
            });
          }
        }
      ]
    });
  };

  let warnings = [];
  let errors = [];
  if (isAccepted) {
    tasks.forEach(task => {
      const { status, template } = task;
      const { isAddOn, requiresApproval } = template;

      // If the task is an add on, nothing needed
      if (isAddOn) return;

      // If the task is complete, nothing needed
      if (status === 'complete') return;

      // New tasks
      if (status === 'pending' && !isBrand(user)) {
        if (requiresApproval) {
          // Instagram Post
          const dueDateDaysRemaining = getSubmissionDueDateDaysRemaining(task, contract);
          if (!_.isNil(dueDateDaysRemaining) && dueDateDaysRemaining < 7) {
            dueDateDaysRemaining === 0 && warnings.push(`${task.title} due today`);
            dueDateDaysRemaining > 0 && warnings.push(`${task.title} due in ${dueDateDaysRemaining} day${dueDateDaysRemaining === 1 ? '' : 's'}`);
            dueDateDaysRemaining < 0 && errors.push(`${task.title} due ${-1 * dueDateDaysRemaining} day${dueDateDaysRemaining === 1 ? '' : 's'} ago`);
          }
        }
      }

      // Approved tasks
      if (status === 'approved' && !isBrand(user)) {
        // Instagram Post
        const goLiveDueDateDaysRemaining = getGoLiveDueDateDaysRemaining(task, contract);
        if (!_.isNil(goLiveDueDateDaysRemaining) && goLiveDueDateDaysRemaining < 7) {
          goLiveDueDateDaysRemaining === 0 && warnings.push(`${task.title} to go live today`);
          goLiveDueDateDaysRemaining > 0 &&
            warnings.push(`${task.title} to go live in ${goLiveDueDateDaysRemaining} day${goLiveDueDateDaysRemaining === 1 ? '' : 's'}`);
          goLiveDueDateDaysRemaining < 0 &&
            errors.push(`${task.title} to go live due ${-1 * goLiveDueDateDaysRemaining} day${goLiveDueDateDaysRemaining === 1 ? '' : 's'} ago`);
        }
      }
    });
  }

  if (invoice?.id) {
    if (invoice.status === 'unpaid') {
      const daysUntilDue = DAYS_UNTIL_CONTRACT_INVOICES_DUE - moment().diff(moment(invoice.createdAt), 'days');
      daysUntilDue === 0 && warnings.push(`Invoice due today.`);
      daysUntilDue > 0 && daysUntilDue <= 7 && warnings.push(`Invoice due in ${daysUntilDue} day${daysUntilDue === 1 ? '' : 's'}`);
      daysUntilDue < 0 && errors.push(`Invoice due ${daysUntilDue * -1} day${daysUntilDue === -1 ? '' : 's'} ago`);
    }
  }

  const warningsAndErrors = [...warnings, ...errors];
  return (
    <div className='contract-preview-outer-container'>
      <div className={cn('contract-preview-container', { 'requires-response': requiresResponse, 'has-warnings': warnings.length })}>
        <div className={cn('image-container', { brand: !isBrand(user), user: isBrand(user) })}>
          {collabImage ? <img src={collabImage} alt={collabName} /> : collabInitials}
        </div>
        <div className='main'>
          <div className='content'>
            <div className='title'>{collabName}</div>
            <div className='subtitle'>
              {analyticsDisplays.length ? (
                analyticsDisplays.map((d, idx) => (
                  <Fragment key={idx}>
                    {d}
                    {idx !== analyticsDisplays.length - 1 && <span className='bullet'>•</span>}
                  </Fragment>
                ))
              ) : (
                <>{getStatusTimeDisplayForContract(contract)}</>
              )}
              {!isBrand(user) && Payment_id && (
                <>
                  <span className='bullet'>•</span>
                  Paid on {moment(payment.createdAt).format('MMMM Do')}
                </>
              )}
            </div>
            {!!warningsAndErrors.length && (
              <div className='warnings-and-errors'>
                {errors.map((error, idx) => (
                  <div key={idx} className='error'>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    {error}
                  </div>
                ))}
                {warnings.map((warning, idx) => (
                  <div key={idx} className='warning'>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    {warning}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className='actions'>
            {(isPending || isProposed) && !!isCreatedByYou && (
              <div onClick={deleteCollaboration} className='action'>
                Remove
              </div>
            )}
            {isProposed && !isCreatedByYou && (
              <div onClick={dismissCollaboration} className='action'>
                Dismiss
              </div>
            )}
            {invoice?.id && invoice.status === 'unpaid' && (
              <div onClick={() => goToStripeBillingDashboard(user)} className='action'>
                Pay Invoice
              </div>
            )}
            <Link onClick={scrollToTop} to={`/collaboration/${contract.id}`} className='action'>
              {isPending
                ? 'Continue Editing'
                : isProposed
                ? 'View Proposal'
                : isActive
                ? 'View Analytics'
                : isAccepted
                ? 'View Collaboration'
                : 'View Collaboration'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ContractPreview.propTypes = {
  contract: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  syncExternal: PropTypes.func.isRequired
};

export default ContractPreview;
