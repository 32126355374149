import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CareerPerk.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';

const CareerPerk = props => {
  const { title, perks } = props;
  const [isPerksVisible, setIsPerksVisible] = useState(true);

  return (
    <div className='career-perk-outer'>
      <div className='career-perk-inner'>
        <div className='career-perk-header' onClick={() => setIsPerksVisible(!isPerksVisible)}>
          <div>{title}</div>
          {isPerksVisible ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
        </div>
        {isPerksVisible && (
          <div className='career-perk-body'>
            {perks.map(perk => (
              <div className='career-perk' key={perk}>
                {perk}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CareerPerk.propTypes = {
  title: PropTypes.string.isRequired,
  perks: PropTypes.array.isRequired
};

export default CareerPerk;
