import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './CollectionEmbed.scss';

import Loader from '../../Components/Loader/Loader';

import { getUrlParam } from '../../Helpers/helpers';
import { getAffiliateLink } from '../../Helpers/attribution_helpers';
import { getCollection } from '../../APIClient/collections';
import { addEvent } from '../../APIClient/events';

class CollectionEmbed extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  componentDidMount() {
    getCollection(this.props.match.params.id).then(resp => {
      this.setState(
        {
          collection: {
            ...resp,
            pins: _.filter(_.orderBy(resp.pins, 'sortOrderRank'), p => !p.isHidden)
          }
        },
        () => {
          const canScroll = this.containerRef.getBoundingClientRect().width < this.containerRef.scrollWidth;
          this.setState({ scrollRightArrowVisible: canScroll });
          canScroll &&
            this.containerRef.addEventListener('scroll', e => {
              if (isMobile) {
                this.setState({ scrollLeftArrowVisible: false, scrollRightArrowVisible: false });
              } else {
                this.setState({ scrollLeftArrowVisible: true, scrollRightArrowVisible: true });
                if (!this.containerRef.scrollLeft) {
                  this.setState({ scrollLeftArrowVisible: false });
                }
                if (this.containerRef.getBoundingClientRect().width + this.containerRef.scrollLeft === this.containerRef.scrollWidth) {
                  this.setState({ scrollRightArrowVisible: false });
                }
              }
            });
        }
      );
    });
  }

  state = {
    collection: null,
    scrollLeftArrowVisible: false,
    scrollRightArrowVisible: false
  };

  scrollBy = amount => {
    window.a = this.containerRef;
    const maxScroll = this.containerRef.children[this.containerRef.children.length - 1]?.offsetLeft;
    const newScrollPos = this.containerRef.scrollLeft;
    const newScroll = _.max([_.min([maxScroll, newScrollPos + amount]), 0]);
    return this.containerRef.scrollTo({
      top: 0,
      left: newScroll,
      behavior: 'smooth'
    });
  };
  scrollLeft = () => this.scrollBy(this.containerRef.clientWidth > 600 ? -600 : -360);
  scrollRight = () => this.scrollBy(this.containerRef.clientWidth > 600 ? 600 : 360);

  navigateToProduct = async pin => {
    const { id, Collection_id, User_id } = pin;
    addEvent('PIN_VIEW', {
      pinId: id,
      fromEmbed: true,
      collectionId: Collection_id,
      userId: User_id,
      pinTitle: pin.title,
      pinLink: pin.selectedGeoLink || pin.link,
      collectionName: pin?.user?.name,
      shopUsername: pin?.user?.username,
      shopName: pin?.user?.name,
      isShortenedLink: false
    });
  };

  isMobileOnly = () => getUrlParam('mobileOnly') === 'true'; // Used by linkinbio iframes
  showTitle = () => getUrlParam('showTitle') === 'true'; // Used by linkinbio iframes
  getSize = () => getUrlParam('size') || 'lg'; // Used with mobileOnly, sm/md/lg

  render() {
    const { user } = this.props;
    const { collection, scrollLeftArrowVisible, scrollRightArrowVisible } = this.state;
    const { name, pins } = collection || {};
    const isLoading = !collection;
    const isMobileOnly = this.isMobileOnly();
    const showTitle = this.showTitle();
    const size = this.getSize();
    const additionalClasses = { mobileonly: isMobileOnly, showtitle: showTitle, [size]: true };
    return (
      <div className='collection-embed-outer-container'>
        {name && showTitle && (
          <div className='collection-title-container'>
            <h1 className='collection-title'>{name}</h1>
            <h2 className='collection-subtitle'>
              {pins.length} Product{pins.length === 1 ? '' : 's'}
            </h2>
          </div>
        )}
        <div className={cn('collection-embed-inner-container', additionalClasses)} ref={ref => (this.containerRef = ref)}>
          {!isLoading ? (
            pins?.map(pin =>
              pin.isHidden ? null : (
                <a
                  key={pin.id}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={getAffiliateLink(pin, user, collection.user, collection)}
                  className={cn('pin', additionalClasses)}
                  onClick={() => this.navigateToProduct(pin)}
                >
                  <div>
                    <div className='image-container'>
                      <img src={pin.image} alt={pin.title} />
                    </div>
                    <div className='title'>{pin.title}</div>
                  </div>
                  <div className='link-to-product-btn'>SHOP NOW</div>
                </a>
              )
            )
          ) : (
            <div className='loader'>
              <Loader />
            </div>
          )}
        </div>
        {!isLoading && scrollLeftArrowVisible && (
          <div className={cn('scroll-arrow-visible left', { visible: scrollLeftArrowVisible })}>
            <FontAwesomeIcon onClick={this.scrollLeft} icon={faChevronLeft} />
          </div>
        )}
        {!isLoading && scrollRightArrowVisible && (
          <div className={cn('scroll-arrow-visible', { visible: scrollRightArrowVisible })}>
            <FontAwesomeIcon onClick={this.scrollRight} icon={faChevronRight} />
          </div>
        )}
      </div>
    );
  }
}

CollectionEmbed.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  // functions go here
})(withRouter(CollectionEmbed));
