import APIClient from './index';
import _ from 'lodash';

import { getUserId } from '../Helpers/user_helpers';

const getPayouts = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Payouts/payout_summary/${getUserId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

const getPayments = user => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Payments/by_user/${getUserId(user)}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

const linkPaypal = (user, code) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`PaymentAccounts`, {
      code,
      userid: getUserId(user),
      source: 'PAYPAL'
    })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

const linkInvoiceEmail = (user, email) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`PaymentAccounts`, {
      userid: getUserId(user),
      email,
      source: 'INVOICE_EMAIL'
    })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

const unlinkAccount = account => {
  return new Promise((resolve, reject) => {
    return APIClient.delete(`/PaymentAccounts/${account.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const downloadCommissions = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Payouts/download_commissions`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export { getPayouts, getPayments, linkPaypal, linkInvoiceEmail, unlinkAccount };
