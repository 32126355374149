import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTrash, faPlus, faPencil, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import './ShopSections.scss';

import Loader from '../Loader/Loader';
import Tooltip from '../General/Tooltip';

import { getGMTTime } from '../../Helpers/formatting';
import { getSectionTemplates } from '../../Helpers/section_helpers';

const ShopSections = props => {
  const { sections, activeSectionId } = props;

  const [addingSectionDisplay, setAddingSectionDisplay] = useState(null);

  const newSectionTemplates = getSectionTemplates(sections).filter(s => s.isVisible);

  const SortableItem = SortableElement(({ value }) => {
    const section = value;
    const { title, id, isHidden } = section;
    const isSelected = activeSectionId === id;
    const select = () => props.setActiveSectionId(id);
    const remove = e => {
      e.stopPropagation();
      props.deleteSection(section);
    };

    const DragHandle = sortableHandle(() => (
      <div className='action reorder'>
        <FontAwesomeIcon icon={faBars} />
      </div>
    ));
    return (
      <div onClick={select} className={cn('shop-section-section', { selected: isSelected, hidden: isHidden })}>
        {title ? <div className='title'>{title}</div> : <div className='title empty'>Custom Section</div>}
        <div className='actions'>
          {!!isHidden && (
            <Tooltip message='This section is currently hidden'>
              <div onClick={select} className='edit'>
                <FontAwesomeIcon icon={faEyeSlash} />
              </div>
            </Tooltip>
          )}
          <div onClick={select} className='edit'>
            <FontAwesomeIcon icon={faPencil} />
          </div>
          <div onClick={remove} className='delete'>
            <FontAwesomeIcon icon={faTrash} />
          </div>
          <DragHandle />
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    const sections = items;
    return (
      <div>
        {sections.map((section, idx) => (
          <SortableItem axis='xy' key={`element-${section.id}`} index={idx} value={section} />
        ))}
      </div>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    const section = sections[oldIndex];
    const isMovingDown = newIndex > oldIndex;
    const sectionBefore = sections[isMovingDown ? newIndex : newIndex - 1];
    const sectionAfter = sections[isMovingDown ? newIndex + 1 : newIndex];
    const newValue = !sectionBefore
      ? getGMTTime() * -1
      : !sectionAfter
      ? getGMTTime()
      : (sectionAfter.sortOrderRank + sectionBefore.sortOrderRank) / 2;
    props.updateSection(section, { sortOrderRank: newValue });
  };

  return (
    <div className='shop-sections-outer-container'>
      <div className='section-header'>Your Shop Sections</div>
      <div className='shop-sections-sections'>
        <SortableList axis='y' items={sections} onSortEnd={onSortEnd} useDragHandle />
      </div>
      <div className='shop-sections-add-new-section'>
        <div className='add-new-section-header-container'>
          <div className='add-new-section-header'>Add a new section</div>
        </div>
        <div className='section-options'>
          {newSectionTemplates.map(template => {
            const { display, section } = template;
            const isAdding = addingSectionDisplay === display;
            const select = async () => {
              if (isAdding) return;
              setAddingSectionDisplay(display);
              const resp = await props.addSection({
                User_id: props.sectionsUser.id,
                ...section,
                sortOrderRank: getGMTTime()
              });
              resp.section && props.setActiveSectionId(resp.section.id);
              setAddingSectionDisplay(null);
            };
            return (
              <div key={display} className='section-option' onClick={select}>
                <div className='title'>{display}</div>
                <div className='actions'>
                  <div className={cn('action', { adding: isAdding })}>
                    <FontAwesomeIcon className='add-icon' icon={faPlus} />
                    {isAdding && <Loader size={40} />}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ShopSections.propTypes = {
  sectionsUser: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  addSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  activeSectionId: PropTypes.number.isRequired,
  setActiveSectionId: PropTypes.func.isRequired
};

export default ShopSections;
