import React from 'react';
import PropTypes from 'prop-types';
import './SelectApplicationType.scss';

import ArrowButton from '../General/Buttons/ArrowButton';

const SelectApplicationType = props => {
  return (
    <div className='select-application-type-container'>
      <div className='panel left'>
        <div className='header'>Creators</div>
        <div className='subheader'>
          Supercharge your business in seconds. Start monetizing every product you talk about and collaborate with the world’s top brands.
        </div>
        <div className='actions'>
          <ArrowButton onClick={props.selectCreators} displayText='Apply' />
          <ArrowButton onClick={props.goToLogin} onlyText displayText='Log In' />
        </div>
      </div>
      <div className='panel right'>
        <div className='header'>Brands</div>
        <div className='subheader'>
          Everything you need to build a world-class creator marketing program. Gain access to top content creators and industry experts around the
          world.
          <div className='actions'>
            <ArrowButton onClick={props.selectBrands} displayText='Get a demo' />
            <ArrowButton onClick={props.goToLogin} onlyText displayText='Log In' />
          </div>
        </div>
      </div>
    </div>
  );
};

SelectApplicationType.propTypes = {
  selectBrands: PropTypes.func.isRequired,
  selectCreators: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired
};

export default SelectApplicationType;
