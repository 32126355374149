import React, { useState } from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import cn from 'classnames';
import * as htmlToImage from 'html-to-image';
import OutsideClickHandler from 'react-outside-click-handler';

import './SocialSharingTemplateControls.scss';
import cogoToast from 'cogo-toast';

const SocialSharingTemplateControls = props => {
  const { collection, elementId, backgroundColor, setBackgroundColor, textColor, setTextColor, logoColor, setLogoColor } = props;

  const downloadName = `${slugify(collection.name)}.png`;
  const downloadToPng = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    const node = document.getElementById(elementId);
    const config = {
      cacheBust: true,
      canvasWidth: 1000,
      canvasHeight: (1000 * 16) / 9
    };

    try {
      const dataUrl = await buildPng(node, config);

      if (!dataUrl) {
        cogoToast.error('This template cannot be downloaded. Please try a different collection');
        return setIsDownloading(false);
      }

      saveAs(dataUrl, downloadName);
      window.__ADD_EVENT__(`Collections - Copy Social Sharing Template`, { type: elementId, user: collection.user });
    } catch (e) {
      cogoToast.error('This template cannot be downloaded, some images are being blocked. Please try a different collection');
    } finally {
      setIsDownloading(false);
    }
  };

  const buildPng = async (node, config) => {
    /*
      Sept 2024: known bug for drawing images to canvas on safari.
      Safari has performance optimizations that lead to images not being fully rendered when converting HTML image to a canvas.
      Safari manages image decoding and rendering asynchronously, causing images to be drawn incompletely or not at all when using html-to-image or canvas.
      Workaround fix to resolve this across all browsers - run the conversion multiple times until the data URL length stops increasing.
    */
    let dataUrl = null;
    const maxBuildAttempts = 10;
    let previousDataLength = 0;

    for (let i = 0; i < maxBuildAttempts; i++) {
      const canvasAttempt = await htmlToImage.toPng(node, config);
      const currentCanvasLength = canvasAttempt.length;

      if (currentCanvasLength > previousDataLength) {
        dataUrl = canvasAttempt;
        previousDataLength = currentCanvasLength;
      } else {
        // If the length of the data URL has not increased (i.e. no more images loaded), break the loop
        break;
      }
    }

    return dataUrl;
  };

  const saveAs = (dataUrl, name) => {
    const image_element = document.createElement('a');
    image_element.href = dataUrl;
    image_element.download = name;
    document.body.appendChild(image_element);
    image_element.click();
    document.body.removeChild(image_element);
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const [showPalettes, setShowPalettes] = useState(false);
  const togglePalettes = () => setShowPalettes(!showPalettes);

  const backgroundColorOptions = [
    '#171919', // Darkest
    '#333333', // Dark
    '#08472E', // Emerald 6
    '#11835a', // Primary
    '#DCF6EA', // Emerald 01
    '#cccccc', // lightest
    '#ffffff', // White
    '#1b3975', // Blue 6
    '#a10027' // Red 6
  ];
  const textColorOptions = [
    '#171919', // Darkest
    '#ffffff' // White
  ];
  const logoColorOptions = [
    '#000000', // Darkest
    '#ffffff', // White
    'transparent'
  ];
  return (
    <div className='social-sharing-template-controls'>
      <div className={cn('control-button', { disabled: showPalettes })} onClick={togglePalettes}>
        {showPalettes ? 'hide colors' : 'change colors'}
      </div>
      <div onClick={downloadToPng} className='control-button primary'>
        {isDownloading ? 'Downloading...' : 'Download'}
      </div>
      {showPalettes && (
        <OutsideClickHandler onOutsideClick={togglePalettes}>
          <div className='color-palettes'>
            {setBackgroundColor && (
              <div className='palette-section'>
                <div className='palette-section-header'>Background Color:</div>
                <div className='colors'>
                  {backgroundColorOptions.map(color => {
                    const active = color === backgroundColor;
                    return (
                      <div key={color} className={cn('color-container', { active })} onClick={() => setBackgroundColor(color)}>
                        <div className='color' style={{ backgroundColor: color }} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {setTextColor && (
              <div className='palette-section'>
                <div className='palette-section-header'>Text Color:</div>
                <div className='colors'>
                  {textColorOptions.map(color => {
                    const active = color === textColor;
                    return (
                      <div key={color} className={cn('color-container', { active })} onClick={() => setTextColor(color)}>
                        <div className='color' style={{ backgroundColor: color }} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {setLogoColor && (
              <div className='palette-section'>
                <div className='palette-section-header'>ShopMy Logo Color:</div>
                <div className='colors'>
                  {logoColorOptions.map(color => {
                    const active = color === logoColor;
                    const isTransparent = color === 'transparent';
                    return (
                      <div key={color} className={cn('color-container', { active })} onClick={() => setLogoColor(color)}>
                        <div className='color' style={{ backgroundColor: color }} />
                        {isTransparent && <div className='transparent-bar' />}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

SocialSharingTemplateControls.propTypes = {
  collection: PropTypes.object.isRequired,

  setBackgroundColor: PropTypes.func,
  backgroundColor: PropTypes.string,

  setTextColor: PropTypes.func,
  textColor: PropTypes.string,

  setLogoColor: PropTypes.func,
  logoColor: PropTypes.string
};

export default SocialSharingTemplateControls;
