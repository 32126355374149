import APIClient from './index';
import _ from 'lodash';

/**
 * @param {Object} params
 * @param {number} params.limit - the amount of accounts to return (default 50)
 */
export const getUsersNeedingScrape = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Extensions/users_needing_update?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};

/**
 * @param {Object} data
 * @param {Object} data.instagram_profile - full profile from instagram api
 */
export const postInstagramProfile = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Extensions/instagram_profile`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, 'data.error')));
  });
};
