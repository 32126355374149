import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { getLookbookOrderStatusDisplay } from '../../Helpers/lookbook_helpers';
import { useHistory } from 'react-router-dom';
import { faTimes, faUndo } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import './PartnerPortalRequest.scss';

import { getRelativePrettyDate } from '../../Helpers/formatting';
import { hasRequestExpired } from '../../Helpers/gifting_helpers';

const PartnerPortalRequest = props => {
  const { request, openRequestModal, updateRequest } = props;

  const history = useHistory();

  const { brand, brandAccepted, brandRejected, userAccepted, userRejected, expiresOn, lookbook, lookbook_order } = request;
  const { name: Brand_name, mobileCoverImage } = brand;
  const { coverImage, title: Lookbook_title } = lookbook || {};

  const hasLookbook = !!lookbook;
  const hasLookbookOrder = !!lookbook_order?.id;

  const waitingForUser = brandAccepted && !userAccepted && !userRejected;
  const waitingForBrand = userAccepted && !brandAccepted && !brandRejected;
  const bothAccepted = userAccepted && brandAccepted;
  const isExpired = hasRequestExpired(request);

  const { createdAt, userRejectedAt, userAcceptedAt } = request;
  const dateToCompare = userRejected ? userRejectedAt : userAccepted ? userAcceptedAt : isExpired ? expiresOn : createdAt;
  const displayDate = bothAccepted
    ? `Accepted ${getRelativePrettyDate(dateToCompare)}`
    : isExpired
    ? `Expired ${getRelativePrettyDate(dateToCompare)}`
    : waitingForUser || waitingForBrand
    ? `Received ${getRelativePrettyDate(dateToCompare)}`
    : `Dismissed ${getRelativePrettyDate(dateToCompare)}`;

  const lookbookStatus = hasLookbookOrder ? getLookbookOrderStatusDisplay(lookbook_order) : null;

  const handleClick = () => {
    if (isExpired)
      return window.ALERT.warn(
        `This request has expired and can no longer be viewed. Please reach out to ${Brand_name} via chat for more information.`
      );
    if (brandRejected || userRejected) openRequestModal(request);
    else if (hasLookbookOrder) history.push(`/lookbooks/order/${lookbook_order.id}`);
    else if (hasLookbook) history.push(`/lookbooks/${lookbook.id}`);
    else return openRequestModal(request);
  };

  const dismissGiftingRequest = () => {
    return confirmAlert({
      title: 'Dismiss Gifting Request',
      message: 'Are you sure you want to dismiss this gifting request?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => updateRequest(request, { userRejected: true })
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const resetGiftingRequest = () => {
    return confirmAlert({
      title: 'Reset Gifting Request',
      message: 'Are you sure you want to reset this gifting request?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => updateRequest(request, { userRejected: false, userAccepted: false, userAcceptedAt: null, userRejectedAt: null })
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const disableViewing = brandRejected || userRejected || isExpired;
  const canUserDismiss = waitingForUser && !isExpired;
  const canUserResetDismissal = userRejected && !isExpired;

  return (
    <div className='partner-portal-request-outer' onClick={handleClick}>
      <div className='full-image-overlay-container'>
        <div className='full-image-overlay'></div>
        <img src={coverImage || mobileCoverImage} alt={lookbook?.title} />
      </div>
      <div className='partner-portal-request-inner'>
        {canUserDismiss ? (
          <div
            className='dismiss-container'
            onClick={e => {
              e.stopPropagation();
              dismissGiftingRequest();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        ) : canUserResetDismissal ? (
          <div
            className='dismiss-container'
            onClick={e => {
              e.stopPropagation();
              resetGiftingRequest();
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </div>
        ) : null}
        <div className='text-container'>
          <div className='request-details'>
            <div className='request-heading'>{Brand_name}</div>
            <div className='request-subheading'>
              <span>{Lookbook_title || 'General Gifting'}</span>
              <span className='spacer'>•</span>
              <span>{displayDate}</span>
            </div>
          </div>
          <div className='request-status-details'>
            {lookbook && lookbookStatus ? (
              <div className='lookbook-status-container'>
                <FontAwesomeIcon icon={lookbookStatus.icon} />
                <span>{lookbookStatus.display}</span>
              </div>
            ) : bothAccepted ? (
              <div className='accepted-status-container'>
                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 10 10' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M1.43555 3.8628L0.0703125 5.22804L1.43555 6.59327V8.52459H3.36686L4.73292 9.89065L6.09898 8.52459H8.02947V6.5941L9.39553 5.22804L8.02947 3.86198V1.93066H6.09816L4.73292 0.56543L3.36769 1.93066H1.43555V3.8628Z'
                    fill='#11835A'
                  />
                  <path d='M6.13359 4.25762L4.36607 6.0251L3.33602 4.99504' stroke='white' strokeWidth='0.666087' />
                </svg>
                <span>Accepted</span>
              </div>
            ) : disableViewing ? null : (
              <div className='actions'>
                <div className='action'>
                  {lookbook?.id ? <span>View Lookbook</span> : <span>View Request</span>}
                  {lookbook?.id && <FontAwesomeIcon icon={faChevronRight} />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PartnerPortalRequest.propTypes = {
  request: PropTypes.object.isRequired,
  openRequestModal: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired
};

export default PartnerPortalRequest;
