import _ from 'lodash';

export const BLOG_ELEMENT_TYPES = {
  PARAGRAPH: 'PARAGRAPH',
  HEADER: 'HEADER',
  HEADER_LARGE: 'HEADER_LARGE',
  BANNER_IMAGE: 'BANNER_IMAGE',
  QUOTE_HALF: 'QUOTE_HALF',
  QUOTE_FULL: 'QUOTE_FULL',
  PIN_HALF: 'PIN_HALF',
  PIN_FULL: 'PIN_FULL',
  COLLECTION_SHELF: 'COLLECTION_SHELF',
  SHOP: 'SHOP',
  YOUTUBE: 'YOUTUBE',
  INSTAGRAM: 'INSTAGRAM',
  INSTAGRAM_LARGE: 'INSTAGRAM_LARGE',
  LINE: 'LINE',
  SPACER: 'SPACER',
  SPACER_SMALL: 'SPACER_SMALL',
  DISCLAIMER: 'DISCLAIMER'
};

export const getFormattedBlogText = text => {
  const parts = _.filter(_.split(text, '(('));
  const allowEnters = text => {
    const parts = text.split('\n');
    return _.map(_.map(parts, (el, idx) => [<span key={idx + '-'}>{el}</span>, idx !== parts.length - 1 ? <br key={idx + 'br'} /> : null]));
  };
  const partsEl = _.flatten(
    parts.map((part, idx) => {
      let url;
      const els = part.split('))');
      if (els.length <= 1) return allowEnters(part);
      const partToChange = els[0];
      const type = els[1].split(' ')[0].split('=')[0];
      let partToKeep = els[1].slice(type.length);

      switch (type) {
        case 'i':
          // ((name in italics))i
          return [<i key={idx}>{allowEnters(partToChange)}</i>, allowEnters(partToKeep)];
        case 'b':
          // ((name in bold))i
          return [<b key={idx}>{allowEnters(partToChange)}</b>, allowEnters(partToKeep)];
        case 'bi':
          // ((name in bold italics))bi
          return [
            <i>
              <b key={idx}>{allowEnters(partToChange)}</b>
            </i>,
            allowEnters(partToKeep)
          ];
        case 'u':
          // ((name in underline))u
          return [<u key={idx}>{allowEnters(partToChange)}</u>, allowEnters(partToKeep)];
        case 'l':
          // ((link name))l=https://google.com
          url = partToKeep.split(' ')[0].slice(1);
          partToKeep = partToKeep.slice(url.length + 1);
          return [
            <a target='_blank' rel='noopener noreferrer' href={url}>
              {partToChange}
            </a>,
            allowEnters(partToKeep)
          ];

        case 'il':
          // ((link name))li=https://google.com.
          url = partToKeep
            .split(' ')[0]
            .slice(1)
            .split('.')[0];
          partToKeep = partToKeep.slice(url.length + 1);
          return [
            <i>
              <a target='_blank' rel='noopener noreferrer' href={url}>
                {partToChange}
              </a>
            </i>,
            allowEnters(partToKeep)
          ];

        default:
          return els;
      }
    })
  );

  return partsEl;
};

export const allPostsAreHiddenOnTab = tab => {
  const posts = tab?.posts || [];
  return !posts.length || (posts.every(post => post.isHidden) && !window.__ADMIN_CONTROL_MODE__);
};

export const getBlogBackupPosts = blog => {
  /*
    In the case there are no results after searching in the blog, we will show
    posts from redux sorted by created at descending.
  */

  const tabs = _.get(blog, 'tabs', []);
  const allBlogPosts = [];
  for (const tab of tabs) allBlogPosts.push(...tab.posts);
  const uniquePosts = _.uniqBy(allBlogPosts, 'id');

  return _.orderBy(uniquePosts, ['createdAt'], ['desc']).slice(0, 20);
};
