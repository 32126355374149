import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isMobile, isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';
import './AppPromotionBanner.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

import { setUIKeyValue } from '../../Actions/UIActions';

import icons from '../../static/icons';
import { isLoggedIn, isBrand, isManager, hasMobileApp } from '../../Helpers/user_helpers';
import { getUIKeyValue } from '../../Helpers/ui_helpers';

const MAX_NUMBER_SHOWING_THIS = 3;

const AppPromotionBanner = props => {
  const { user, ui } = props;

  const dismissedAppPromotionPanelCount = getUIKeyValue(ui, 'dismissedAppPromotionPanelCount', 'general') || 0;

  // Allow them to close
  const [isVisible, setIsVisible] = useState(true);
  if (!isVisible) return null;

  // Must be on mobile and ios
  if (!isMobile) return null;
  if (!isIOS) return null;

  // Must be logged in
  if (!isLoggedIn(user)) return null;

  // Must be a user
  if (isBrand(user)) return null;
  if (isManager(user)) return null;

  // Must not have downloaded the app
  if (hasMobileApp(user)) return null;

  // Must not have cancelled this too many times
  if (dismissedAppPromotionPanelCount > MAX_NUMBER_SHOWING_THIS) return null;

  // Allow them to close
  const close = () => {
    props.setUIKeyValue('dismissedAppPromotionPanelCount', dismissedAppPromotionPanelCount + 1, 'general');
    setIsVisible(false);
  };

  return (
    <div className='app-promotion-banner-outer-container'>
      <div className='app-promotion-banner-inner-container'>
        <div className='image-container'>
          <img alt='Shop My Logo' src={icons.brand.app_icon} />
        </div>
        <div className='main'>
          <div className='data'>
            <div className='name'>ShopMy</div>
            <div className='developer'>Shop My Shelf, Inc.</div>
            <div className='stars'>
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
            </div>
            <div className='app-store'>Download On The App Store</div>
          </div>
          <div className='action'>
            <a href='https://apps.apple.com/us/app/shopmy/id6443850511' className='view-btn'>
              View
            </a>
          </div>
        </div>
        <div onClick={close} className='close-btn'>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
};

AppPromotionBanner.propTypes = {
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  setUIKeyValue
})(AppPromotionBanner);
