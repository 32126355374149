import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './AddressModal.scss';
import cn from 'classnames';
import _ from 'lodash';
import { isValidPhoneNumber } from 'libphonenumber-js';
import Modal from '../General/Modal';
import SelectOption from '../General/SelectOption';
import Select from 'react-select';
import { setAddress } from '../../Actions/UserActions';
import { countryToRegionMapping } from '../../Helpers/geo_helpers';
import { getAddress } from '../../Helpers/user_helpers';
import PlacesAutocompleteLoader from '../General/PlacesAutocompleteLoader';

const AddressModal = props => {
  const { user, close, setAddress } = props;

  const existingAddress = getAddress(user);
  const fullExistingAddress = existingAddress?.isConfirmed ? JSON.parse(existingAddress?.raw) : null;

  const [searchingAddress, setSearchingAddress] = useState('');

  const [hasSelectedAddress, setHasSelectedAddress] = useState(null);
  const [firstName, setFirstName] = useState(fullExistingAddress?.firstName || '');
  const [lastName, setLastName] = useState(fullExistingAddress?.lastName || '');
  const [address1, setAddress1] = useState(fullExistingAddress?.address1 || '');
  const [address2, setAddress2] = useState(fullExistingAddress?.address2 || '');
  const [city, setCity] = useState(fullExistingAddress?.city || '');
  const [state, setState] = useState(fullExistingAddress?.state || '');
  const [zip, setZip] = useState(fullExistingAddress?.zip || '');
  const [country, setCountry] = useState(fullExistingAddress?.country || 'United States');
  const [phone, setPhone] = useState(existingAddress?.phone || '');

  const handleAddressInput = address => setSearchingAddress(address);

  const handleSelectAddress = components => {
    const countryComp = components.find(comp => comp.types.includes('country'));
    const countryOption = countryToRegionMapping.find(option => option.label === countryComp?.long_name);
    setCountry(countryOption?.label || '');

    const streetNumber = components.find(comp => comp.types.includes('street_number'));
    const route = components.find(comp => comp.types.includes('route'));
    setAddress1(streetNumber || route ? `${streetNumber?.long_name || ''} ${route?.long_name || ''}` : '');

    const cityComp = components.find(
      comp => comp.types.includes('locality') || comp.types.includes('sublocality') || comp.types.includes('sublocality_level_1')
    );
    const stateComp = components.find(comp => comp.types.includes('administrative_area_level_1'));
    const zipComp = components.find(comp => comp.types.includes('postal_code'));
    setCity(cityComp ? cityComp.long_name : '');
    setState(stateComp ? stateComp.long_name : '');
    setZip(zipComp ? zipComp.long_name : '');
    setHasSelectedAddress(true);
  };

  const handleCountryChange = countryOption => {
    const { label: country } = countryOption;
    setCountry(country);
  };

  const clearInputs = () => {
    setFirstName('');
    setLastName('');
    setAddress1('');
    setAddress2('');
    setCity('');
    setState('');
    setZip('');
    setCountry('');
  };

  const [isSaving, setIsSaving] = useState(false);

  const formatAddress = () => {
    const parts = [address1, address2, city, state, zip, country];
    return _.filter(parts).join(', ');
  };

  const fields = [
    {
      display: 'First Name',
      fieldGrouping: 'name',
      value: firstName,
      setValue: setFirstName,
      variable: 'firstName',
      autoCompleteType: 'first-name',
      isValid: val => !!val && val.length > 2 && val.length < 40
    },
    {
      display: 'Last Name',
      fieldGrouping: 'name',
      value: lastName,
      setValue: setLastName,
      variable: 'lastName',
      autoCompleteType: 'last-name',
      isValid: val => !!val && val.length > 1 && val.length < 40
    },
    {
      display: 'Address',
      value: address1,
      setValue: setAddress1,
      variable: 'address1',
      autoCompleteType: 'street-address',
      isValid: val => !!val
    },
    {
      display: 'Apartment, Suite, PO Box',
      value: address2,
      setValue: setAddress2,
      variable: 'address2',
      isValid: val => !!val,
      isOptional: true
    },
    {
      display: 'City',
      value: city,
      setValue: setCity,
      variable: 'city',
      isValid: val => !!val
    },
    {
      display: 'State',
      value: state,
      setValue: setState,
      variable: 'state',
      isValid: val => !!val
    },
    {
      display: 'Country',
      value: country,
      setValue: setCountry,
      isCountrySelect: true,
      variable: 'country',
      isValid: val => val && !!countryToRegionMapping.find(option => option.label === val)
    },
    {
      display: 'Zip',
      value: zip,
      setValue: setZip,
      variable: 'zip',
      isValid: val => !!val
    },
    {
      display: 'Phone',
      className: 'phone-input-formatted',
      value: phone,
      setValue: setPhone,
      variable: 'phone',
      isValid: val => (val ? isValidPhoneNumber(val, 'US') : true)
    }
  ];

  const missingFields = fields.filter(field => !field.isValid(field.value) && !field.isOptional);
  const hasMissingFields = missingFields.length > 0;

  const saveAndExit = async () => {
    if (hasMissingFields) {
      return window.ALERT.error(`Please fill in ${missingFields[0]?.display}`);
    }

    const rawAddress = { firstName, lastName, address1, address2, city, state, zip, country };
    for (let key in rawAddress) rawAddress[key] = rawAddress[key].trim();

    setIsSaving(true);
    const address = { ...getAddress(user), raw: JSON.stringify(rawAddress), address: formatAddress(rawAddress), isConfirmed: true };

    if (phone) address.phone = phone;

    const { error } = await setAddress(user, address);

    if (error) {
      setIsSaving(false);
      return window.ALERT.error(`${error}`);
    }
    setIsSaving(false);
    close();
  };

  const renderField = field => {
    const { display, value, setValue, variable, isValid, isCountrySelect, className } = field;
    return isCountrySelect ? (
      <Select
        key={variable}
        unstyled
        classNamePrefix='country-select'
        className='country-select'
        options={countryToRegionMapping}
        onChange={handleCountryChange}
        placeholder='Country'
        components={{ Option: SelectOption }}
        value={countryToRegionMapping.find(option => option.label === country) || countryToRegionMapping[0]}
      />
    ) : (
      <input
        key={variable}
        type='text'
        placeholder={display}
        onChange={e => setValue(e.target.value)}
        value={value}
        className={cn(`${className || 'address-field-input'}`, { invalid: !isValid(value) })}
      />
    );
  };

  const groupedFields = _.groupBy(fields, 'fieldGrouping');

  return (
    <Modal close={close} visible={true} showClose={true} className='address-modal-outer' contentClassName='address-modal-inner'>
      <div className='heading'>
        <div className='address-header'>Enter Your Address</div>
      </div>
      <div className='section'>
        <div className='section-header'>Search</div>
        <PlacesAutocompleteLoader
          searchValue={searchingAddress}
          onSearchChange={handleAddressInput}
          onAddressSelect={handleSelectAddress}
          searchOptions={{ types: ['address'] }}
        />
      </div>
      {(fullExistingAddress || hasSelectedAddress) && (
        <div className='section'>
          <div className='section-header'>Your Address</div>
          <div className='form'>
            {Object.keys(groupedFields).map(group => {
              const groupFields = groupedFields[group];

              if (group !== 'undefined') {
                return (
                  <div key={group} className='horizontal-form-group'>
                    {groupedFields[group].map(field => renderField(field))}
                  </div>
                );
              }

              return groupFields.map(field => renderField(field));
            })}
            <div
              className={cn('save-button-inline', {
                disabled: hasMissingFields
              })}
              onClick={saveAndExit}
            >
              {hasMissingFields ? `Please Fill in ${missingFields[0]?.display}` : isSaving ? 'Saving...' : 'Save'}
            </div>
          </div>
          {(hasSelectedAddress || existingAddress) && (
            <div className='buttons'>
              {(address1 || hasSelectedAddress) && (
                <button className='clear-button' onClick={clearInputs}>
                  Clear
                </button>
              )}
              <button
                className={cn('save-button', {
                  disabled: hasMissingFields
                })}
                onClick={saveAndExit}
              >
                {hasMissingFields ? `Please Fill in ${missingFields[0]?.display}` : isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

AddressModal.propTypes = {
  user: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  setAddress
})(AddressModal);
