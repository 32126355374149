export const AMOUNT_OFF_ORDER = 'amount_off_order';
export const AMOUNT_OFF_PRODUCTS = 'amount_off_products';

export const SHOPIFY_CODE_TYPE_DISPLAY_NAMES = {
  [AMOUNT_OFF_ORDER]: 'Discount for order',
  [AMOUNT_OFF_PRODUCTS]: 'Discount for products'
};

export const SHOPIFY_CODE_TYPE_TIP = `
Code syncing is active for your account with Shopify. Choose a code type below and it will also be reflected on the created code in Shopify.
If you choose '${SHOPIFY_CODE_TYPE_DISPLAY_NAMES[AMOUNT_OFF_PRODUCTS]}', select Shopify Collections the discount code applies to.
This type cannot be changed after the code is created.
`;

export const ACTIVE = 'Active';
export const EXPIRED = 'Expired';
export const SCHEDULED = 'Scheduled';
