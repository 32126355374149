import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';
import './MentionSortControl.scss';

const MentionSortControl = props => {
  const { curFilters, setCurFilters, sortOptions } = props;

  const sortSections = _.groupBy(sortOptions, 'section');
  const groupedOptions = _.keys(sortSections).map(section => {
    return {
      label: section,
      options: sortSections[section].map(option => {
        return { value: option.value, label: option.display };
      })
    };
  });

  const selectedOption = _.flatten(_.map(groupedOptions, 'options')).find(o => o.value[0] === curFilters.sortBy);

  const changeSortValue = ({ value }) => {
    if (props.blockUsage()) return null;
    setCurFilters({
      ...curFilters,
      sortBy: value,
      page: 0
    });
  };
  return (
    <div className='mentions-sort-control-container'>
      <Select
        unstyled
        placeholder='Sort Order'
        classNamePrefix='sort-control'
        onChange={changeSortValue}
        className='sort-control'
        isOptionDisabled={option => option.disabled}
        options={groupedOptions}
        value={selectedOption}
      />
    </div>
  );
};

MentionSortControl.propTypes = {
  curFilters: PropTypes.object.isRequired,
  setCurFilters: PropTypes.func.isRequired,
  blockUsage: PropTypes.func.isRequired,
  sortOptions: PropTypes.array.isRequired
};

export default MentionSortControl;
