import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './LookbookReportMentions.scss';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { connect } from 'react-redux';
import { openArtistModal, openChatOverlay, openAuthModal } from '../../../../Actions/UIActions';
import { getMentions } from '../../../../APIClient/mentions';

import { updateMention } from '../../../../APIClient/mentions';
import { getBrandId } from '../../../../Helpers/user_helpers';
import { isAdminControlMode } from '../../../../Helpers/ui_helpers';

import MentionResult from '../../../Mentions/MentionResult';
import { lookbookReportItemFilter } from '../../../../Helpers/lookbook_helpers';
import { downloadCsvFromUrl } from '../../../../Helpers/helpers';

const MAX_MENTIONS_POSSIBLE = 64;

const LookbookReportMentions = props => {
  const { lookbook, user, ui, startDate, endDate, requests } = props;
  const { openArtistModal, openChatOverlay, openAuthModal } = props;
  const mentions = props.mentions.filter(m => lookbookReportItemFilter(m, 'mention', startDate, endDate));
  const history = useHistory();

  const [resultsToView, setResultsToView] = useState(16); // [16, 32, 48, 64]
  const [removedIds, setRemovedIds] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const viewMoreResults = () => {
    // if we are at the max results, redirect to the mentions page with Lookbook_id filter
    if (resultsToView === MAX_MENTIONS_POSSIBLE) history.push(`/mentions?Lookbook_id=${lookbook.id}`);
    else setResultsToView(resultsToView + 16);
  };

  const filteredMentions = mentions.slice(0, resultsToView);
  let columns = [[], [], [], []];
  const columnChunks = _.chunk(filteredMentions, 4);
  columnChunks.forEach((chunk, i) => {
    chunk[0] && columns[0]?.push(chunk[0]);
    chunk[1] && columns[1]?.push(chunk[1]);
    chunk[2] && columns[2]?.push(chunk[2]);
    chunk[3] && columns[3]?.push(chunk[3]);
  });

  const removeMentionIfAllowedTo = isAdminControlMode(ui)
    ? async mention => {
        const confirm = window.confirm('Are you sure you want to remove this mention?');
        if (confirm) {
          await updateMention(mention, { isHidden: true });
          window.ALERT.success('Mention Removed');
          setRemovedIds([...removedIds, mention.id]);
        }
      }
    : null;

  const downloadLookbookMentionReport = async () => {
    setIsDownloading(true);
    const earliestAcceptedAt = _.minBy(requests, 'userAcceptedAt')?.userAcceptedAt;
    const startDate = props.startDate ? props.startDate : earliestAcceptedAt ? moment(earliestAcceptedAt).format('YYYY-MM-DD') : null;
    const downloadUrl = await getMentions({
      Lookbook_id: lookbook.id,
      Brand_id: lookbook.Brand_id,
      startDate,
      limit: 10000,
      downloadToCsv: true
    });
    downloadCsvFromUrl(downloadUrl.url);
    setIsDownloading(false);
  };

  return (
    <div className='lookbook-report-mentions-outer'>
      <div className='lookbook-report-mentions-inner'>
        <div className='lookbook-report-mention-button-container'>
          <div className='lookbook-report-download-button' onClick={downloadLookbookMentionReport}>
            {isDownloading ? 'Downloading...' : 'Download'}
          </div>
        </div>
        <div className='mention-results-columns'>
          {columns.map((column, columnIdx) => {
            return (
              <div key={columnIdx} className='mention-results-column'>
                {column.map((mention, idx) => {
                  return (
                    <MentionResult
                      key={mention.id}
                      mention={mention}
                      user={user}
                      ui={ui}
                      curFilters={{ Brand_id: getBrandId(user) }}
                      openArtistModal={openArtistModal}
                      openChatOverlay={openChatOverlay}
                      openAuthModal={openAuthModal}
                      removeMentionIfAllowedTo={removeMentionIfAllowedTo}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>

        {resultsToView >= mentions.length && resultsToView !== MAX_MENTIONS_POSSIBLE ? null : (
          <div className='view-more-button' onClick={viewMoreResults}>
            {resultsToView === MAX_MENTIONS_POSSIBLE ? 'View All' : 'View More'}
          </div>
        )}
      </div>
    </div>
  );
};

LookbookReportMentions.propTypes = {
  requests: PropTypes.array.isRequired,
  lookbook: PropTypes.object.isRequired,
  mentions: PropTypes.array.isRequired,
  isFetchingMentions: PropTypes.bool.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,

  // redux
  user: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openChatOverlay: PropTypes.func.isRequired,
  openAuthModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user, ui } = state;
  return { user, ui };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openChatOverlay,
  openAuthModal
})(LookbookReportMentions);
