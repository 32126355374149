import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Result.scss';

import ScrollToTop from '../../Components/General/ScrollToTop';
import ResultHeader from '../../Components/Result/ResultHeader';
import ResultPins from '../../Components/Result/ResultPins';

import { toggleBodyScrollLock } from '../../Actions/UIActions';
import { getVisibleConsultResult, editResult, editPin, deletePin } from '../../Actions/StoreActions';
import { getServerFormattedDate } from '../../Helpers/formatting';
import { canEditConsult, isYou, getNameWithS } from '../../Helpers/user_helpers';
import { isPublicPage } from '../../Helpers/helpers';

const Result = props => {
  const { user, ui, store, match, editResult, getVisibleConsultResult } = props;
  const { visibleConsultResult } = store || {};
  const [viewAsPublic, setViewAsPublic] = useState(false);
  const result = visibleConsultResult?.stub === match.params.stub ? visibleConsultResult : null;
  const syncConsultResult = useCallback(async () => {
    await getVisibleConsultResult(match.params.stub);
  }, [match.params.stub, getVisibleConsultResult]);

  useEffect(() => {
    syncConsultResult();
  }, [syncConsultResult]);

  const warnTurnOffPublicIfNecessary = () => {
    canEdit && viewAsPublic && cogoToast.warn(`Turn off "View As ${result?.clientName || 'Public'}" to make edits`);
  };

  const submitResult = () => {
    confirmAlert({
      title: 'Final Confirmation',
      message: `We will send an email confirmation to ${result?.clientName} with these results. Would you like to proceed?`,
      buttons: [
        { label: 'No', onClick: () => {} },
        {
          label: 'Yes',
          onClick: () => {
            editResult(result.id, { completedAt: getServerFormattedDate() });
          }
        }
      ]
    });
  };

  const isComplete = !!result?.completedAt;
  const canEdit = canEditConsult(result?.consult, user);
  const allowEditing = !viewAsPublic && canEdit && !isComplete;
  const showConfig = allowEditing || (canEdit && !isComplete);
  const showHeader = result && ((!viewAsPublic && !(isComplete && !canEdit)) || (!viewAsPublic && canEdit && !isComplete));
  const showBody = result && !(!isComplete && !canEdit);

  const resultUser = result?.user;
  const pluralizedName = getNameWithS(resultUser);
  const you = isYou(resultUser?.username, user);
  const lastLocation = _.get(props.lastLocation, 'pathname');
  const comingFromShop = _.includes(lastLocation, `/${resultUser?.username}`) || _.includes(lastLocation, `/public/${resultUser?.username}`);
  return (
    <div className='result-outer-container'>
      <ScrollToTop />
      <div className={cn('result-inner-container', { 'config-visible': showConfig })}>
        <div className='top-row'>
          {you ? null : comingFromShop ? (
            <div onClick={() => props.history.goBack()} className='back-link'>
              <FontAwesomeIcon icon={faArrowLeft} className='back' />
              Back to {you ? 'your shop' : pluralizedName ? `${pluralizedName.full} shop` : 'shop'}
            </div>
          ) : (
            <Link to={`${isPublicPage() ? '/public' : ''}/${result?.user?.username}/consults`} className='back-link'>
              <FontAwesomeIcon icon={faArrowLeft} className='back' />
              Back to {you ? 'your shop' : pluralizedName ? `${pluralizedName.full} shop` : 'shop'}
            </Link>
          )}
        </div>
        {showConfig && (
          <div className='settings-management-btns'>
            <div className='view-as-public-switch'>
              <div>View As {result?.clientName || 'Public'}</div>
              <Switch
                onChange={() => setViewAsPublic(!viewAsPublic)}
                checked={viewAsPublic}
                height={14}
                width={24}
                onColor='#333'
                className='switch'
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
            <div onClick={submitResult} className='submit-btn'>
              Submit
            </div>
          </div>
        )}
        {showHeader && <ResultHeader user={user} allowEditing={allowEditing} canEdit={canEdit} result={result} />}
        {showBody && (
          <ResultPins
            canEdit={canEdit}
            allowEditing={allowEditing}
            user={user}
            ui={ui}
            warnTurnOffPublicIfNecessary={warnTurnOffPublicIfNecessary}
            syncConsultResult={syncConsultResult}
            toggleBodyScrollLock={props.toggleBodyScrollLock}
            editResult={editResult}
            editPin={props.editPin}
            deletePin={props.deletePin}
            result={result}
          />
        )}
      </div>
      {canEdit && !viewAsPublic ? null : (
        <div className='result-disclaimer'>
          Please be advised that the product recommendations and advice featured here is not intended to provide diagnosis, treatment or medical
          advice. Please consult with a physician or other healthcare professional regarding any medical or health related diagnosis or treatment
          options. Information on this site should not be considered a substitute for advice from a healthcare professional. Also note that the
          products featured here may contain affiliate links, meaning the publisher of this content, or Shop My Shelf, Inc. may earn a commission on
          products purchased through links to retailer sites.
        </div>
      )}
    </div>
  );
};

Result.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { user, ui, store } = state;
  return { user, ui, store };
};

export default connect(mapStateToProps, {
  toggleBodyScrollLock,
  getVisibleConsultResult,
  editPin,
  deletePin,
  editResult
})(withLastLocation(withRouter(Result)));
