import React from 'react';
import PropTypes from 'prop-types';
import './OpportunityPlanPlanningOptions.scss';

const OpportunityPlanPlanningOptions = props => {
  const planningOptions = [
    {
      title: 'Build My Own',
      description: 'Create a custom plan for your opportunity.',
      image: 'https://static.shopmy.us/Opportunities/Planning/build_my_own.png',
      actionFn: props.openPlanner,
      actionDisplay: 'Get Started'
    },
    {
      title: 'Smart Planning Engine',
      description: 'Let us create a plan for you based on your opportunity goals.',
      image: 'https://static.shopmy.us/Opportunities/Planning/smart_planner.png',
      actionFn: props.openSmartPlanner,
      actionDisplay: 'Get Started'
    }
  ];
  return (
    <div className='opportunity-plan-planning-options'>
      <div className='no-active-plans-message-container'>
        <div className='header'>How would you like to plan your spend?</div>
        <div className='message'>
          Create your own plan using our various planning strategies or work with our planning engine to quickly optimize your opportunity.
        </div>
      </div>
      <div className='planning-options'>
        {planningOptions.map((option, idx) => {
          return (
            <div key={idx} className='planning-option'>
              <div className='main'>
                <img src={option.image} alt='planning option' />
                <div className='content'>
                  <div className='title'>{option.title}</div>
                  <div className='description'>{option.description}</div>
                  <div onClick={option.actionFn} className='generate-btn'>
                    {option.actionDisplay}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OpportunityPlanPlanningOptions.propTypes = {
  openPlanner: PropTypes.func.isRequired,
  openSmartPlanner: PropTypes.func.isRequired
};

export default OpportunityPlanPlanningOptions;
