import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomeCreatorAnimationSteps.scss';

import icons from '../../../../static/icons';

import { HOME_CREATOR_ANIMATION_STEPS } from '../../../../Helpers/home_helpers';

const HomeCreatorAnimationSteps = props => {
  const { stepInFocus, setStepInFocus } = props;

  const stepRef1 = React.useRef(null);
  const stepRef2 = React.useRef(null);
  const stepRef3 = React.useRef(null);
  const stepRefs = [stepRef1, stepRef2, stepRef3];

  const scrollHandler = () => {
    const middleOfPage = window.innerHeight / 2;
    const stepPositions = stepRefs.map(step => {
      const { top, bottom } = step.current.getBoundingClientRect();
      return (top + bottom) / 2;
    });
    const closestStepIdx = stepPositions.reduce((prev, curr, idx) => {
      return Math.abs(curr - middleOfPage) < Math.abs(stepPositions[prev] - middleOfPage) ? idx : prev;
    }, 0);
    setStepInFocus(closestStepIdx);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [stepInFocus]);

  return (
    <div className='home-creator-animation-steps-outer-container'>
      <div className='steps'>
        {HOME_CREATOR_ANIMATION_STEPS.map((step, idx) => (
          <Step stepRefs={stepRefs} key={idx} stepInFocus={stepInFocus} index={idx} step={step} />
        ))}
      </div>
    </div>
  );
};

const Step = props => {
  const { step, index, stepInFocus } = props;
  const { title, subtitle, image, to } = step;
  const additionalClasses = { focused: stepInFocus === index };
  return (
    <div ref={props.stepRefs[index]} className={cn('step', additionalClasses)}>
      <div className='image-container'>
        <img src={image} alt={title} />
      </div>
      <div className={cn(additionalClasses, 'title-container')}>
        <div className={cn(additionalClasses, 'step-number')}>{props.index + 1}</div>
        <h3 className={cn(additionalClasses, 'title fade-in-on-active')}>{title}</h3>
        <h4 className={cn(additionalClasses, 'subtitle fade-in-on-active')}>{subtitle}</h4>
        <Link to={to} className={cn(additionalClasses, 'action fade-in-on-active')}>
          <div className='display'>{step.action}</div>
          <img src={icons.misc.arrowRight} alt='Arrow Right' />
        </Link>
      </div>
    </div>
  );
};

HomeCreatorAnimationSteps.propTypes = {
  stepInFocus: PropTypes.number.isRequired,
  setStepInFocus: PropTypes.func.isRequired
};

export default HomeCreatorAnimationSteps;
