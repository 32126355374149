import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import arrayMove from 'array-move';
import './SortableList.scss';

import { getGMTTime } from '../../Helpers/formatting';

const SortableList = props => {
  /*
    This component is used to reorder items in a list.

    Example usage:

      import SortableList from '../../General/SortableList';

      ...
      const items = [];
      const getCard = (item, additionalProps = {}) => {
        const { rearrangeHandle } = additionalProps;
        return (
          <div className='item' key={item.id}>
            {item.title}
            {rearrangeHandle}
          </div>
        );
      };
      return <SortableList containerClassName='items' items={items} updateItem={props.updateFn} props={props} getCard={getCard} />

    IMPORTANT NOTES:
      ** If the formatting messes up when you drag, this means your SCSS is nested inside a wrapper component (ex: .items-container)
        The SCSS for the item card must be at the top level of your SCSS file ('.item-container'). 

      ** Also, if your sortable item is in a Modal, the item will disappear under the modal regardless. To fix add:
      position: relative; z-index: $zindex-modal-sortable-item;

      inside of the sortable item. This will keep the sortable item above the modal when sorting.


    Example SCSS (See "OtherFeatures.scss" for an example.):
    (NORMAL)
    component-outer {
      component-inner {
        ...rest-of-scss 

        .class-of-item-you-are-trying-to-sort {

        }
      }
    }
    
    (REQUIRED FOR SORTING: Won't make your sortable items disappear)
    .class-of-item-you-are-trying-to-sort {

    }
    
    .component-outer {
      .component-inner {
        ...rest-of-scss 
      }
    }
  */
  const [tempItems, setTempItems] = useState(null);
  const items = _.orderBy(tempItems || props.items, 'sortOrderRank');

  const moveItemToFirstPosition = async item => {
    const oldIndex = items.findIndex(i => i.id === item.id);
    const newIndex = 0;
    await onSortEnd({ oldIndex, newIndex });
  };

  const moveItemToLastPosition = async item => {
    const oldIndex = items.findIndex(i => i.id === item.id);
    const newIndex = items.length - 1;
    await onSortEnd({ oldIndex, newIndex });
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    setTempItems(arrayMove(items, oldIndex, newIndex));

    // If Custom
    if (props.customUpdateItem) {
      await props.customUpdateItem({ oldIndex, newIndex });
      setTimeout(() => setTempItems(null), 100); // Delay to let react propagate props
    } else if (props.updateItem) {
      const movingForward = newIndex > oldIndex;
      const lowerBound = movingForward ? newIndex : newIndex - 1;
      const upperBound = movingForward ? newIndex + 1 : newIndex;
      const lowerBoundSort = items[lowerBound]?.sortOrderRank || -1 * getGMTTime();
      const upperBoundSort = items[upperBound]?.sortOrderRank || getGMTTime();
      const newSort = (lowerBoundSort + upperBoundSort) / 2;
      await props
        .updateItem(items[oldIndex], {
          sortOrderRank: newSort
        })
        .then(() => setTempItems(null));
    } else {
      console.error('SortableList: No update function provided');
    }
  };

  const DragHandle = sortableHandle(() => (
    <div className={cn('sortable-handle', { 'sortable-handle-default': props.useSortableHandleDefault })}>
      <FontAwesomeIcon icon={faBars} />
    </div>
  ));

  // Reordering functions
  const SortableItem = SortableElement(({ value, props }) =>
    props.getCard(value, { ...props, rearrangeHandle: <DragHandle />, moveItemToFirstPosition, moveItemToLastPosition })
  );
  const SortableList = SortableContainer(({ items, props }) => {
    return (
      <div className={cn(props.containerClassName || 'items', 'sortable-items')}>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id || index}`} axis='xy' index={index} value={value} props={props} />
        ))}
        {props.additionalCard}
      </div>
    );
  });

  return props.isEditing ? (
    <SortableList
      axis={props.axis || 'xy'}
      items={tempItems || items}
      onSortEnd={onSortEnd}
      props={props}
      shouldCancelStart={e => !e.target.closest('.sortable-handle')}
    />
  ) : (
    <div className={props.containerClassName || 'items'}>{items.map(props.getCard)}</div>
  );
};

SortableList.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  containerClassName: PropTypes.string.isRequired,
  getCard: PropTypes.func.isRequired,

  // For updating, one of these must be provided
  updateItem: PropTypes.func, // Update function that uses sortOrderRank
  customUpdateItem: PropTypes.func, // If you want to do something other than the default sort end function

  // Optional UI
  useSortableHandleDefault: PropTypes.bool, // Handle in top right corner
  additionalCard: PropTypes.object,
  axis: PropTypes.oneOf(['x', 'y', 'xy'])
};

export default SortableList;
