import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import quoteImg from '../../static/images/misc/quote.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { SortableElement, SortableContainer, sortableHandle } from 'react-sortable-hoc';
import './ResultPins.scss';

import { matchScrollHeight } from '../../Helpers/helpers';
import { getGMTTime } from '../../Helpers/formatting';

import AddPinModal from '../AddPinModal/AddPinModal';
import ProfilePhoto from '../Profile/ProfilePhoto';
import ResultPin from './ResultPin';

const ResultPins = props => {
  const { canEdit, allowEditing, editResult, deletePin, editPin, user, ui, result } = props;
  const { consult, pins } = result;
  const [response, setResponse] = useState(result.response || '');
  const [inAddMode, setAddMode] = useState(false);
  const [tempPins, setTempPins] = useState(null);
  const [pinBeingEdited, setPinBeingEdited] = useState(null);
  const saveResult = () => {
    editResult(result.id, { response }); // - AUTOEDITING
  };

  const toggleAddingMode = (newPinBeingEdited = null) => {
    const toggleOn = !pinBeingEdited && !inAddMode;
    setAddMode(!!toggleOn);
    setPinBeingEdited(newPinBeingEdited);
    props.toggleBodyScrollLock(toggleOn);
  };

  const SortableItem = SortableElement(({ value }) => {
    const pin = value;
    const DragHandle = sortableHandle(() => (
      <div className='reorder-icn icn'>
        <FontAwesomeIcon icon={faBars} />
      </div>
    ));
    return (
      <ResultPin
        key={pin.id}
        dragHandle={<DragHandle />}
        canEdit={canEdit}
        allowEditing={allowEditing}
        warnTurnOffPublicIfNecessary={props.warnTurnOffPublicIfNecessary}
        toggleAddingMode={toggleAddingMode}
        editPin={editPin}
        user={user}
        deletePin={deletePin}
        result={result}
        pin={pin}
        idx={pin.idx}
      />
    );
  });

  const onSortEnd = async ({ newIndex, oldIndex }) => {
    const movingBack = newIndex > oldIndex;
    const pinToUpdate = _.get(pins, [oldIndex]);
    const stampBeforeRank = _.get(pins, [movingBack ? newIndex : newIndex - 1, 'sortOrderRank'], 0);
    const stampAfterRank = _.get(pins, [movingBack ? newIndex + 1 : newIndex, 'sortOrderRank'], getGMTTime());
    const newStampRank = (stampAfterRank + stampBeforeRank) / 2;
    setTempPins(arrayMove(pins, oldIndex, newIndex));
    await editPin(pinToUpdate, { sortOrderRank: newStampRank });
    setTempPins(null);
  };

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((element, idx) => (
          <SortableItem axis='y' key={`item-${element.id}`} index={idx} value={{ ...element, idx }} />
        ))}
      </div>
    );
  });

  const consultStillExists = !!consult;
  const isExample = consult?.ExampleConsultResult_Id === result?.id;
  return (
    <div className='result-pins-container'>
      <div className='result-pins-header'>
        <h1>{consult?.title || 'Consultation Results'}</h1>
        {!isExample && <h2>for {result?.clientName}</h2>}
      </div>
      <div className='result-response-container result-card'>
        {allowEditing ? (
          <div className='edit-result-response'>
            <textarea
              className='answer placeholder'
              rows={4}
              value={response}
              placeholder={`Write your note to ${result.clientName} here`}
              ref={ref => matchScrollHeight(ref)}
              onBlur={e => saveResult()}
              onChange={e => setResponse(e.target.value)}
            />
          </div>
        ) : (
          <div onClick={props.warnTurnOffPublicIfNecessary} className='view-result-response'>
            <img src={quoteImg} alt='' className='quote first' />
            <div>{result.response}</div>
            <ProfilePhoto ui={ui} profile={result?.user} basic />
            <img src={quoteImg} alt='' className='quote last' />
          </div>
        )}
      </div>
      <div className='result-pins'>
        {allowEditing ? (
          <SortableList axis='y' items={tempPins || pins} onSortEnd={onSortEnd} useDragHandle />
        ) : (
          pins.map((pin, idx) => (
            <ResultPin
              key={pin.id}
              canEdit={canEdit}
              allowEditing={allowEditing}
              warnTurnOffPublicIfNecessary={props.warnTurnOffPublicIfNecessary}
              toggleAddingMode={toggleAddingMode}
              editPin={editPin}
              deletePin={deletePin}
              result={result}
              user={user}
              pin={pin}
              idx={idx}
            />
          ))
        )}
        {allowEditing && (
          <ResultPin empty user={user} canEdit={canEdit} allowEditing={allowEditing} toggleAddingMode={toggleAddingMode} idx={pins.length} />
        )}
      </div>

      {allowEditing && inAddMode && consultStillExists && (
        <AddPinModal
          inAddMode={inAddMode}
          pinBeingEdited={pinBeingEdited}
          toggleAddingMode={toggleAddingMode}
          syncExternalState={props.syncConsultResult}
          Editing_User_id={result?.user?.id}
          Editing_ConsultResult_id={result.id}
        />
      )}
    </div>
  );
};

ResultPins.propTypes = {
  result: PropTypes.object.isRequired,
  toggleBodyScrollLock: PropTypes.func.isRequired,
  syncConsultResult: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  editPin: PropTypes.func.isRequired,
  deletePin: PropTypes.func.isRequired,
  warnTurnOffPublicIfNecessary: PropTypes.func.isRequired,

  canEdit: PropTypes.bool.isRequired,
  allowEditing: PropTypes.bool.isRequired
};

export default ResultPins;
