import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import _ from 'lodash';
import classnames from 'classnames';

import './LandingHeaderPanel.scss';

import { IMAGE_OPTIONS_LEFT, IMAGE_OPTIONS_RIGHT } from '../../Helpers/home_helpers';

class LandingHeaderPanel extends Component {
  static propTypes = {
    openAuthModal: PropTypes.func.isRequired
  };

  componentDidMount() {
    setTimeout(this.startMotion, 100);
  }

  componentWillUnmount() {
    clearInterval(this.motionInterval);
  }

  state = {
    tilesLeft: _.map(
      [
        { images: IMAGE_OPTIONS_LEFT.slice(0, 2) },
        { images: IMAGE_OPTIONS_LEFT.slice(2, 4) },
        { images: IMAGE_OPTIONS_LEFT.slice(4, 6) },
        { images: IMAGE_OPTIONS_LEFT.slice(6, 8) },
        { images: IMAGE_OPTIONS_LEFT.slice(0, 2) },
        { images: IMAGE_OPTIONS_LEFT.slice(2, 4) }
      ],
      d => ({ ...d, inMotion: false })
    ),
    tilesRight: _.map(
      [
        { images: IMAGE_OPTIONS_RIGHT.slice(0, 2) },
        { images: IMAGE_OPTIONS_RIGHT.slice(2, 4) },
        { images: IMAGE_OPTIONS_RIGHT.slice(4, 6) },
        { images: IMAGE_OPTIONS_RIGHT.slice(6, 8) },
        { images: IMAGE_OPTIONS_RIGHT.slice(0, 2) },
        { images: IMAGE_OPTIONS_RIGHT.slice(2, 4) }
      ],
      d => ({ ...d, inMotion: false })
    ),
    curImageIndex: 0
  };

  startMotion = () => {
    this.setState({
      tilesLeft: _.map(this.state.tilesLeft, t => ({ ...t, inMotion: true })),
      tilesRight: _.map(this.state.tilesRight, t => ({ ...t, inMotion: true }))
    });
    this.motionInterval = setInterval(() => {
      this.setState({
        curImageIndex: this.state.curImageIndex < this.state.tilesLeft[0].images.length - 1 ? this.state.curImageIndex + 1 : 0
      });
    }, 5400);
  };

  render() {
    const { tilesLeft, tilesRight, curImageIndex } = this.state;
    const title = `Bring your practice into the digital age.`;
    const subtitle = `Build an inventory-free digital storefront and offer paid online consultations.`;
    return (
      <div className='landing-header-panel-outer-container'>
        <MetaTags>
          <meta name='description' content={subtitle}></meta>
          <meta property='og:description' content={subtitle}></meta>
        </MetaTags>
        <div className='landing-header-panel-container'>
          <div className='landing-header-panel-left'>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <div className='btns'>
              <div className='build-btn btn' onClick={() => this.props.openAuthModal('apply')}>
                GET STARTED
              </div>
            </div>
          </div>
        </div>
        <div className='landing-header-floating-products'>
          <div className='floating-products-col left'>
            {tilesLeft.map((tile, idx) => {
              return (
                <div
                  key={idx}
                  className={classnames('floating-product', {
                    moving: tile.inMotion
                  })}
                >
                  {tile.images.map((img, idx) => (
                    <img
                      alt={`Popular Product ${idx} Left`}
                      key={idx}
                      className={classnames({ active: idx === curImageIndex })}
                      src={tile.images[idx]}
                    />
                  ))}
                </div>
              );
            })}
          </div>
          <div className='floating-products-col right'>
            {tilesRight.map((tile, idx) => {
              return (
                <div
                  key={idx}
                  className={classnames('floating-product', {
                    moving: tile.inMotion
                  })}
                >
                  {tile.images.map((img, idx) => (
                    <img
                      alt={`Popular Product ${idx} Right`}
                      key={idx}
                      className={classnames({ active: idx === curImageIndex })}
                      src={tile.images[idx]}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default LandingHeaderPanel;
