import { loadStripe } from '@stripe/stripe-js';

export const stripePromise =
  window.__IS_SNAP__ || false // Need to fix this - we need to Reduce API load so chromium can perform without too many resources because it is failing without it
    ? null
    : loadStripe(
        window.__IS_PROD__
          ? 'pk_live_51J7i4jDCBmPejELxHc3PeeF2CsuDF4ls8NvwyzmsYXE4hw6yPtmipWZeQ9Oq9fWnBar9MzMpLpX5WIlnciSFgfCp00YNSwI3XY'
          : 'pk_test_51J7i4jDCBmPejELx0n0qCRksi2odUfEliif65Dhtt8fcq5ae3qffcszRrVJzxgplzXxUFB0tNMQaRCods6teWQv800hFpyQJ79',
        {
          stripeAccount: 'acct_1J7i4jDCBmPejELx'
        }
      );

export const getStripeTransactionFee = price => (price ? parseFloat(((price * 0.029 + 0.3) / (1 - 0.029)).toFixed(2)) : 0);
