import APIClient from './index';
import _ from 'lodash';

export const addSection = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('/Sections', data)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const updateSection = (section, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Sections/${section.id}`, updates)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};

export const deleteSection = section => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Sections/${section.id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(_.get(error, ['data', 'error'])));
  });
};
