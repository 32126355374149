import React from 'react';
import cn from 'classnames';
import './HomeBrandLogos.scss';

import { HOME_BRAND_LOGOS } from '../../../Helpers/home_helpers';

const HomeBrandLogos = props => {
  const goToSite = logo => {
    window.open(`https://${logo.domain}`, '_blank');
  };
  return (
    <div className='home-brand-logos-outer-container'>
      <div className='home-brand-logos-inner-container'>
        <div className='badge'>TRUSTED BY</div>
        <div className='logos'>
          {HOME_BRAND_LOGOS.map((logo, index) => (
            <img
              onClick={() => goToSite(logo)}
              key={index}
              className={cn('logo', logo.logoClass)}
              style={{ aspectRatio: logo.aspectRatio }}
              src={logo.logo}
              alt={logo.brand}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBrandLogos;
