import React from 'react';
import PropTypes from 'prop-types';
import './ContractProposeTasks.scss';

import ContractProposeOverviewCard from './Elements/ContractProposeOverviewCard';
import ContractProposeReceiptCard from './Elements/ContractProposeReceiptCard';

const ContractProposeTasks = props => {
  const { user, contract, canEdit, collaborations, updateContract } = props;
  return (
    <div className='contract-propose-tasks-outer-container'>
      <ContractProposeOverviewCard user={user} contract={contract} canEdit={canEdit} updateContract={updateContract} />
      <ContractProposeReceiptCard
        user={user}
        contract={contract}
        canEdit={canEdit}
        collaborations={collaborations}
        updateContractTask={props.updateContractTask}
        deleteContractTask={props.deleteContractTask}
        createContractTask={props.createContractTask}
        getContractTaskTemplates={props.getContractTaskTemplates}
      />
    </div>
  );
};

ContractProposeTasks.propTypes = {
  user: PropTypes.object.isRequired,
  contract: PropTypes.object.isRequired,
  collaborations: PropTypes.object.isRequired,

  // Editing
  canEdit: PropTypes.bool.isRequired,
  updateContract: PropTypes.func.isRequired,
  updateContractTask: PropTypes.func.isRequired,
  createContractTask: PropTypes.func.isRequired,
  getContractTaskTemplates: PropTypes.func.isRequired
};

export default ContractProposeTasks;
