import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './ArtistModalPinMention.scss';

import { getCompetitorBrandForPin } from '../../../Helpers/brand_helpers';
import { getSocialIconForUrl } from '../../../Helpers/social_helpers';
import { portalBugOpenUrl } from '../../../Helpers/helpers';
import { getPrettyNumber, getPrettyTimeAgoFromNow } from '../../../Helpers/formatting';

const ArtistModalPinMention = props => {
  const { user, mention, pinsWithStats } = props;
  const { createdAt, name, all_basic_pins, brand_pins, competitor_pins } = mention;

  const select = () => props.selectMention(mention);

  const social_link = props.getSocialLinkForMention(mention);
  const social_platform = props.getSocialPlatformForMention(mention);

  let stats = [];
  const mentions = brand_pins.length;
  const views = _.sum(_.map(pinsWithStats, 'views'));
  const orders = _.sum(_.map(pinsWithStats, 'ordersTotal'));
  const volume = _.sum(_.map(pinsWithStats, 'orderVolumeTotal'));

  views && stats.push(`${getPrettyNumber(views)} click${views === 1 ? '' : 's'}`);
  orders && stats.push(`${getPrettyNumber(orders)} order${orders === 1 ? '' : 's'}`);
  volume && stats.push(`$${getPrettyNumber(volume)} volume`);

  let preview_pins = _.concat(brand_pins.slice(0, 1), competitor_pins, brand_pins.slice(1), all_basic_pins).slice(0, 4);

  let details = [];
  details.push(getPrettyTimeAgoFromNow(createdAt, { longForm: true }));
  mentions && details.push(`${mentions} mention${mentions === 1 ? '' : 's'}`);

  const competitorPinNames = _.uniq(competitor_pins.map(p => getCompetitorBrandForPin(p, user)?.name)).join(', ');

  return (
    <div className='artist-modal-pin-mention'>
      <div className='data'>
        <div className='details'>
          <div className='date'>{details.join(' • ')}</div>
          {!mentions && <div className='competitor'>{competitorPinNames}</div>}
        </div>
        <div className='title'>{props.getMentionTitle(mention)}</div>
        {!!stats.length && <div className='subtitle'>{stats.join(' • ')}</div>}
        <div className='view-more-btns'>
          <div onClick={select} className='view-more-btn'>
            View Collection
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          {!!social_link && !!social_platform && (
            <a onClick={portalBugOpenUrl} href={social_link} className='view-more-btn'>
              View post on {social_platform}
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
          )}
        </div>
      </div>
      <div className='images'>
        {mention.image && (
          <div className='post image-container'>
            <img className='image post' alt={name} src={mention.image} />
            {mention.social_links && (
              <div className='social-icon'>
                <img src={getSocialIconForUrl(mention.social_links, 'white')} alt='Social Icon' />
              </div>
            )}
          </div>
        )}
        {preview_pins.slice(0, mention.image ? 2 : 3).map((pin, idx) => {
          const { image, id } = pin;
          const competitor = getCompetitorBrandForPin(pin, user);
          return (
            <div key={id + `${idx}`} className='pin image-container'>
              <img className='image pin' alt={name} src={image} />
              {competitor && <div className='competitor-badge'>{competitor.name}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

ArtistModalPinMention.propTypes = {
  user: PropTypes.object.isRequired,
  mention: PropTypes.object.isRequired,
  pinsWithStats: PropTypes.array.isRequired,
  selectMention: PropTypes.func.isRequired,
  getMentionTitle: PropTypes.func.isRequired,
  getSocialLinkForMention: PropTypes.func.isRequired,
  getSocialPlatformForMention: PropTypes.func.isRequired
};

export default ArtistModalPinMention;
