import React from 'react';
import PropTypes from 'prop-types';
import './PartnerPortalCodes.scss';
import PartnerPortalCode from './PartnerPortalCode';

import { getPrefferredCodeFormat, getProfileFromObject } from '../../Helpers/user_helpers';
import { getCodeDisplayForUserWithFormat } from '../../Helpers/code_helpers';

import PartnerPortalTopNotification from './PartnerPortalTopNotification';

const PartnerPortalCodes = props => {
  const { user, discountCodes, curSearchVal } = props;

  const sortByCodeRevenue = (a, b) => {
    if (a.orderVolumeDriven > b.orderVolumeDriven) return -1;
    if (a.orderVolumeDriven < b.orderVolumeDriven) return 1;
    return 0;
  };

  const filterBySearchValue = code => {
    if (!curSearchVal) return true;
    const { brand, displayText } = code;
    const { name, domain } = brand;

    return (
      name?.toLowerCase().includes(curSearchVal?.toLowerCase()) ||
      domain?.toLowerCase().includes(curSearchVal?.toLowerCase()) ||
      displayText?.toLowerCase().includes(curSearchVal?.toLowerCase())
    );
  };

  const currentCodeFormat = getCodeDisplayForUserWithFormat(getProfileFromObject(user), getPrefferredCodeFormat(user), 20);

  return (
    <div className='partner-portal-codes-outer'>
      <PartnerPortalTopNotification
        ui={props.ui}
        setUIKeyValue={props.setUIKeyValue}
        notificationEnum='hasSeenCodeFormatNotification'
        header='Did you know?'
        subheader={
          <span>
            You can specify your preferred code format in Account Settings. Your current preferred format is set to <b>{currentCodeFormat}</b>.
          </span>
        }
        action={{
          display: 'Go to Account Settings',
          link: '/settings#codeformatting'
        }}
      />
      <div className='partner-portal-codes-inner'>
        {discountCodes
          ?.filter(filterBySearchValue)
          ?.sort(sortByCodeRevenue)
          ?.map(code => {
            return <PartnerPortalCode key={code.id} code={code} />;
          })}
      </div>
    </div>
  );
};

PartnerPortalCodes.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  discountCodes: PropTypes.array.isRequired,
  curSearchVal: PropTypes.string,
  setUIKeyValue: PropTypes.func.isRequired
};

export default PartnerPortalCodes;
