import APIClient from './index';
import _ from 'lodash';
import { Auth } from 'aws-amplify';

import { getUserId } from '../Helpers/user_helpers';

export const fetchEmailFromUserHash = userHash => {
  return new Promise((resolve, reject) => {
    APIClient.get(`Users/email_from_hash/${userHash}`)
      .then(response => resolve({ email: response.data.email }))
      .catch(err => resolve({ email: null, error: err?.data?.error }));
  });
};

export const fetchUserByEmail = data => {
  return new Promise((resolve, reject) => {
    APIClient.post('Users/find_by_email', data)
      .then(response => resolve({ user: response.data }))
      .catch(err => resolve({ user: null, error: err?.data?.error }));
  });
};

export const fetchUsersOfType = type => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Users/get_users_of_type`, { type })
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const fetchUserThroughUsername = (username, params = {}) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Users/username/${username}${_.isEmpty(params) ? '' : '?'}${new URLSearchParams(params).toString()}`)
      .then(response => {
        const { user } = response.data;
        resolve({ user });
      })
      .catch(err => reject(false));
  });
};

export const useCodePreRegistration = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`RegistrationCodes/use_code`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
};

export const createUser = user => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Users`, user)
      .then(response => resolve({ user: response.data.user }))
      .catch(error => reject(_.get(error, 'response.data.error')));
  });
};

export const updateUser = (newUserInfo, id) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Users/${id}`, newUserInfo)
      .then(response => resolve({ user: response.data }))
      .catch(err => reject(false));
  });
};

export const updateUserSettings = (updates, currentSettings) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/UserSettings/${currentSettings.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const checkUsername = username => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Users/username/${username}`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};

export const applyForWaitlist = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`Waitlist/apply_for_waitlist`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'response.data.error')));
  });
};

export const joinMailingList = data => {
  return new Promise((resolve, reject) => {
    APIClient.post(`MailingList/join`, data)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data', err)));
  });
};

export const deleteUser = async id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`Users/${id}`)
      .then(response => {
        Auth.currentAuthenticatedUser()
          .then(
            user =>
              new Promise((resolve, reject) => {
                Auth.signOut();
              })
          )
          .catch(err => reject(false));
      })
      .catch(err => reject(false));
  });
};

export const getRequests = userId => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Requests/for_user/${userId}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateRequest = (request, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/Requests/${request.id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const deleteRequest = Request_id => {
  return new Promise((resolve, reject) => {
    APIClient.delete(`/Requests/${Request_id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateAddress = (user, data) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/UserAddresses`, _.omitBy({ User_id: getUserId(user), ...data }, _.isNil))
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getUserTier = tier => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/UserTiers/${tier.id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const doesUserExist = ({ username, email }) => {
  return new Promise((resolve, reject) => {
    APIClient.post(`/Users/does_exist`, { username, email })
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getReferringUser = username => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Users/referring/${username}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const getShopAnalytics = id => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/Users/${id}/shop_analytics`)
      .then(response => resolve(response.data))
      .catch(err => reject(false));
  });
};
