import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import './Requests.scss';

import { updateRequest as updateRequestAPI, getRequestsForBrandId } from '../../APIClient/requests';
import { openArtistModal, openFulfillmentModal } from '../../Actions/UIActions';

import { downloadCsvFromUrl } from '../../Helpers/helpers';
import { getBrandId } from '../../Helpers/user_helpers';

import RequestResultsOld from '../../Components/Requests/RequestResultsOld';
import RequiresBrandLoginPanel from '../../Components/General/RequiresBrandLoginPanel';
import Loader from '../../Components/Loader/Loader';

let debouncer;

const Requests = props => {
  const { user } = props;
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [curSearchVal, setCurSearchVal] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [receivedAllResults, setReceivedAllResults] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const Brand_id = getBrandId(user);

  useEffect(() => {
    if (Brand_id) {
      const PAGE_LIMIT = 50;
      setIsFetchingResults(true);
      getRequestsForBrandId(Brand_id, { page, limit: PAGE_LIMIT, query: curSearchVal, ...(selectedFilter || {}) })
        .then(resp => {
          setRequests([...requests.slice(0, page * PAGE_LIMIT), ...resp.requests]);
          if (resp.requests.length !== PAGE_LIMIT) setReceivedAllResults(true);
        })
        .catch(error => {
          cogoToast.error(`Could not fetch requests, please reload and try again.`);
        })
        .finally(() => {
          setIsFetchingResults(false);
        });
    }
  }, [Brand_id, page, curSearchVal, selectedFilter]);

  const downloadResults = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      const resp = await getRequestsForBrandId(Brand_id, { page: 0, limit: 2500, query: curSearchVal, downloadAllToCsv: true });
      if (!resp.downloaded_url) throw new Error('Failure downloading');
      downloadCsvFromUrl(resp.downloaded_url);
    } catch (error) {
      cogoToast.error(`Could not download results, plase try again.`);
    }
    setIsDownloading(false);
  };

  const fetchNextPage = async () => {
    setPage(page + 1);
  };

  const changeSearchVal = e => {
    const newSearchVal = e.target.value;
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      setCurSearchVal(newSearchVal);
      setPage(0);
      setReceivedAllResults(false);
    }, 1000);
  };

  if (!Brand_id) return <RequiresBrandLoginPanel />;

  const updateRequest = async (request, updates) => {
    const newRequest = await updateRequestAPI(request, updates);
    setRequests(requests.map(r => (r.id === newRequest.id ? { ...r, ...newRequest } : r)));
  };

  const isFetchingFirstPage = isFetchingResults && !requests.length && !curSearchVal && page === 0;
  const isFetchingNextPage = isFetchingResults && page > 0;

  const filterOptions = [
    { value: null, label: 'Show All' },
    { value: { userRejected: false, userAccepted: false, brandAccepted: true }, label: 'Awaiting Response' },
    { value: { isComplete: false, userAccepted: true, brandAccepted: true }, label: 'Need To Mark Sent' },
    { value: { isComplete: true }, label: 'Sent Product' }
  ];
  const selectedOption = filterOptions.find(o => _.isEqual(o.value, selectedFilter)) || filterOptions[0];

  const changeFilter = ({ value }) => {
    setPage(0);
    setCurSearchVal('');
    setReceivedAllResults(false);
    setSelectedFilter(value);
  };

  return (
    <div className='requests-outer-container'>
      <div className='requests-inner-container'>
        {isFetchingFirstPage ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className='requests-header'>
              <div className='search-input'>
                <input onChange={changeSearchVal} placeholder='Search by Name' />
              </div>
              <div className='actions'>
                <Select
                  unstyled
                  placeholder='Only Show'
                  classNamePrefix='sort-control'
                  onChange={changeFilter}
                  className='sort-control'
                  options={filterOptions}
                  value={selectedOption}
                />

                <div className='action' onClick={downloadResults}>
                  <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                  {isDownloading ? 'Downloading...' : 'Download'}
                </div>
              </div>
            </div>
            {requests.length ? (
              <>
                <RequestResultsOld
                  user={user}
                  requests={requests}
                  updateRequest={updateRequest}
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  receivedAllResults={receivedAllResults}
                  openFulfillmentModal={props.openFulfillmentModal}
                  openArtistModal={props.openArtistModal}
                />
              </>
            ) : (
              <div className='empty-results'>No gifting requests found</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

Requests.propTypes = {
  user: PropTypes.object.isRequired,
  openArtistModal: PropTypes.func.isRequired,
  openFulfillmentModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openArtistModal,
  openFulfillmentModal
})(Requests);
