import APIClient from './index';
import _ from 'lodash';

export const getAffiliateCommissionsForBrandId = (Brand_id, params) => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/AffiliateCommissions/for_brand/${Brand_id}?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};

export const updateAffiliateCommission = (id, updates) => {
  return new Promise((resolve, reject) => {
    APIClient.put(`/AffiliateCommissions/${id}`, updates)
      .then(response => resolve(response.data))
      .catch(err => reject(_.get(err, 'data.error')));
  });
};
