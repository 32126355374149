import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import './ConsultSettings.scss';

import { getPrettyPriceDisplay } from '../../Helpers/formatting';

import Modal from '../General/Modal';

const ConsultSettings = props => {
  const { editConsult, consult, closeModal } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [curPrice, setCurPrice] = useState(consult.price || '');
  const [curNumRemaining, setCurNumRemaining] = useState(consult.numRemaining || '');
  const isFree = !parseFloat(curPrice);
  const isUnlimited = _.isNaN(parseInt(curNumRemaining));

  const save = async () => {
    const newPrice = curPrice && (parseFloat(curPrice) || null);
    if (newPrice && newPrice < 2) {
      return cogoToast.error('The minimum price you can set is $2, or you can make it free.');
    }
    if (newPrice && newPrice > 99999) {
      return cogoToast.error('The maximum price you can set is $99,999.');
    }
    setIsSaving(true);
    const updates = { price: parseFloat(curPrice) || null, numRemaining: isUnlimited ? null : parseInt(curNumRemaining) };
    await editConsult(consult.id, updates);
    setIsSaving(false);
    closeModal();
  };

  const setPrice = newVal => {
    setCurPrice(newVal.replace(/[^0-9]/, ''));
  };

  return (
    <Modal innerClassName='consult-settings-modal' title='Consult Settings' showClose visible={props.visible} close={closeModal}>
      <div className='section'>
        <div className='section-header-container'>
          <div className='section-header'>Price: {isFree ? 'FREE' : getPrettyPriceDisplay(curPrice)}</div>
          {!isFree && (
            <div className='section-subheader'>
              After the 20% transaction fee, you will receive {isFree ? 'FREE' : getPrettyPriceDisplay(curPrice * 0.8)} for every completed consult.
              Consults are charge in USD.
            </div>
          )}
        </div>
        <div className='input-wrapper'>
          <input value={curPrice} onChange={e => setPrice(e.target.value)} placeholder='Set Price Here' />
          {!isFree && (
            <div className='reset-btn' onClick={() => setCurPrice('')}>
              Make Free
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
      <div className='section'>
        <div className='section-header-container'>
          <div className='section-header'>How Many Available: {isUnlimited ? 'Unlimited' : parseInt(curNumRemaining)}</div>
          <div className='section-subheader'>
            Use this field to set a cap on the number of consultations you are willing to accept. You can always add more later.
          </div>
        </div>
        <div className='input-wrapper'>
          <input value={curNumRemaining} onChange={e => setCurNumRemaining(e.target.value)} placeholder='Set Number Remaining' />
          {!isUnlimited && (
            <div className='reset-btn' onClick={() => setCurNumRemaining('')}>
              Make Unlimited
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
      </div>
      <div onClick={save} className='save-btn'>
        {isSaving ? 'Saving Settings...' : 'Save Settings'}
      </div>
    </Modal>
  );
};

ConsultSettings.propTypes = {
  visible: PropTypes.bool.isRequired,
  editConsult: PropTypes.func.isRequired,
  consult: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default ConsultSettings;
