import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import _ from 'lodash';
import cn from 'classnames';
import './PartnerActionTemplates.scss';

import { getChatFromIds, getChat } from '../../APIClient/chats';

import { getBrandId, getBrandName } from '../../Helpers/user_helpers';
import { copyToClipboard } from '../../Helpers/helpers';
import { getFirstName, getPrettyTimeAgoFromNow } from '../../Helpers/formatting';

import Loader from '../Loader/Loader';

const PartnerActionTemplates = props => {
  const { user, talent, templatesOne, templatesTwo, sectionHeaderOne, sectionHeaderTwo } = props;
  const templates = _.concat(templatesOne || [], templatesTwo || []);

  // Load Chat for User
  const [chat, setChat] = useState([]);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const getChatMessages = async () => {
    try {
      const { Chat_id } = await getChatFromIds({ Brand_id: getBrandId(user), User_id: talent.id });
      const { chat } = Chat_id ? await getChat(Chat_id) : {};
      setChat(chat);
      setIsLoadingChat(false);
    } catch (error) {
      cogoToast.error(`Had an issue loading the recent chat for this user.`);
    }
  };
  useEffect(() => {
    getChatMessages();
  }, []);
  const messages = _.orderBy(chat?.messages, 'createdAt', 'desc');

  // Allow for multiple Tabs
  const tabs = [
    { display: 'Templates', variable: 'templates', isLoading: false },
    { display: 'View Current Chat', variable: 'messages', isLoading: isLoadingChat }
  ];
  const [activeTab, setActiveTab] = useState(templates.length ? 'templates' : 'messages');
  const switchTab = newTab => setActiveTab(newTab);
  const isTemplatesTab = activeTab === 'templates';
  const isMessagesTab = activeTab === 'messages';

  // Allow for multiple Tabs
  const getTemplate = (template, idx) => {
    const { header, description } = template;
    const copy = () => copyToClipboard(description, 'Copied!');
    return (
      <div key={idx} className='template-container'>
        {header && <div className='template-header'>{header}</div>}
        <div className='template'>
          <div className='description'>{description}</div>
        </div>
        <div onClick={copy} className='copy-btn'>
          COPY
        </div>
      </div>
    );
  };

  return (
    <div className={cn('partner-action-templates-list', activeTab)}>
      <div className='tabs'>
        {tabs.map(tab => (
          <div
            key={tab.variable}
            onClick={() => switchTab(tab.variable)}
            className={cn('tab', { active: activeTab === tab.variable, loading: tab.isLoading })}
          >
            {tab.display}
            {tab.isLoading && (
              <div className='loader'>
                <Loader size={48} />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='body'>
        {isTemplatesTab && (
          <>
            {!templates.length && (
              <div className='empty-section'>
                You do not have any templates defined for this action, please reach out to your account manager and we will create one together!
              </div>
            )}
            {!!templatesOne?.length && (
              <div className='templates-section'>
                <div className='templates-header'>{sectionHeaderOne}:</div>
                <div className='templates'>{templatesOne.map(getTemplate)}</div>
              </div>
            )}
            {!!templatesTwo?.length && (
              <div className='templates-section'>
                <div className='templates-header'>{sectionHeaderTwo}:</div>
                <div className='templates'>{templatesTwo.map(getTemplate)}</div>
              </div>
            )}
          </>
        )}
        {isMessagesTab && (
          <>
            {isLoadingChat ? (
              <div className='empty-section'>Loading Chat...</div>
            ) : chat ? (
              <div className='messages-section'>
                {messages.map(message => {
                  const { id, createdAt, isUserMessage, isAutomated } = message;
                  return (
                    <div key={id} className={cn('message', { automated: isAutomated })}>
                      {!isAutomated && <div className='author'>{isUserMessage ? getFirstName(talent.name) : getBrandName(user)}</div>}
                      <div className='date'>{getPrettyTimeAgoFromNow(createdAt, { longForm: true })}</div>
                      <div className='text'>{message.message}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='empty-section'>You have not yet started a chat with {getFirstName(talent.name)}.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

PartnerActionTemplates.propTypes = {
  user: PropTypes.object,
  talent: PropTypes.object,
  templatesOne: PropTypes.array.isRequired,
  templatesTwo: PropTypes.array,
  sectionHeaderOne: PropTypes.string.isRequired,
  sectionHeaderTwo: PropTypes.string
};

export default PartnerActionTemplates;
