import _ from 'lodash';
import * as ActionTypes from '../Actions/DiscoverActions';
import * as UserActionTypes from '../Actions/UserActions';

export const discover = (
  state = {
    // Discover 2.0
    tags: [],
    tagCounts: [],
    groups: [],
    activeGroup: null,

    // Deprecated
    lists: [],
    num_code_recommendations: null,
    num_collab_recommendations: null
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.tags,
        lists: action.lists,
        num_gifting_recommendations: action.num_gifting_recommendations,
        num_code_recommendations: action.num_code_recommendations,
        num_collab_recommendations: action.num_collab_recommendations
      };

    case ActionTypes.SYNC_TAGS_SUCCESS:
      return {
        ...state,
        tagCounts: action.tags
      };

    case ActionTypes.GET_DISCOVER_GROUPS_SUCCESS:
      return {
        ...state,
        groups: _.orderBy(action.groups, 'sortOrderRank', 'asc').map(group => ({
          ...group,
          isDefault: group.id === action.defaultGroup?.id
        })),
        activeGroup: state.activeGroup || action.defaultGroup
      };

    case ActionTypes.GET_DISCOVER_GROUPS_FAILURE:
      window.ALERT.warn('Error syncing the discover page, please check your internet connection and try again.');
      return {
        ...state
      };

    case ActionTypes.SET_ACTIVE_DISCOVER_GROUP_REQUEST:
      return {
        ...state,
        activeGroup: action.group
      };

    case ActionTypes.SET_ACTIVE_DISCOVER_GROUP_SUCCESS:
      return {
        ...state,
        activeGroup: action.group
      };

    case ActionTypes.SET_ACTIVE_DISCOVER_GROUP_FAILURE:
      window.ALERT.warn('Error syncing the discover page, please check your internet connection and try again.');
      return {
        ...state
      };

    case ActionTypes.CREATE_DISCOVER_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, action.group]
      };

    case ActionTypes.UPDATE_DISCOVER_GROUP_SUCCESS:
      return {
        ...state,
        groups: _.orderBy(
          state.groups.map(group => (group.id === action.group.id ? { ...group, ...action.group } : group)),
          'sortOrderRank',
          'asc'
        )
      };

    case ActionTypes.DELETE_DISCOVER_GROUP_REQUEST:
      return {
        ...state,
        groups: state.groups.filter(group => group.id !== action.group.id)
      };

    case ActionTypes.CREATE_DISCOVER_GROUP_FAILURE:
    case ActionTypes.UPDATE_DISCOVER_GROUP_FAILURE:
    case ActionTypes.DELETE_DISCOVER_GROUP_FAILURE:
      // Admin only, so we can use a single error message
      window.ALERT.warn('Error updating the discover group, please check your internet connection and try again.');
      return {
        ...state
      };

    case ActionTypes.CREATE_DISCOVER_FEATURE_SUCCESS:
      return {
        ...state,
        activeGroup: {
          ...state.activeGroup,
          features: [...state.activeGroup.features, action.feature]
        }
      };

    case ActionTypes.UPDATE_DISCOVER_FEATURE_SUCCESS:
      return {
        ...state,
        activeGroup: {
          ...state.activeGroup,
          features: state.activeGroup.features.map(feature => (feature.id === action.feature.id ? { ...feature, ...action.feature } : feature))
        }
      };

    case ActionTypes.DELETE_DISCOVER_FEATURE_REQUEST:
      return {
        ...state,
        activeGroup: {
          ...state.activeGroup,
          features: state.activeGroup.features.filter(feature => feature.id !== action.feature.id)
        }
      };

    case ActionTypes.CREATE_DISCOVER_FEATURE_FAILURE:
    case ActionTypes.UPDATE_DISCOVER_FEATURE_FAILURE:
    case ActionTypes.DELETE_DISCOVER_FEATURE_FAILURE:
      // Admin only, so we can use a single error message
      window.ALERT.warn('Error updating the discover feature, please check your internet connection and try again.');
      return {
        ...state
      };

    case UserActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        tags: [],
        tagCounts: [],
        groups: [],
        activeGroup: null,

        // Deprecated
        lists: []
      };

    default:
      return state;
  }
};
