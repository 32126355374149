import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OpportunityLeaderboardControls.scss';

import { getSpendDataOnOpportunityRequest } from '../../../Helpers/opportunity_helpers';
import { isOpportunityRequestAccepted, isOpportunityRequestOutstanding } from '../../../Helpers/opportunity_helpers';
import { getOpportunityRequestsForOpportunity } from '../../../Helpers/brand_helpers';

import Select from '../../General/Select';
import Loader from '../../Loader/Loader';

const OpportunityLeaderboardControls = props => {
  const { opportunity, analytics, curSearchVal, setCurSearchVal, sortFnValue, sortFnOptions, setSortFnValue } = props;

  // Allow searching
  const [isSearching, setIsSearching] = React.useState(false);
  const focusSearch = () => {
    setIsSearching(true);
  };
  const blurInput = () => {
    if (!curSearchVal) setIsSearching(false);
  };

  /*
    Allow bulk messaging each group of requests.
  */
  const requests = getOpportunityRequestsForOpportunity(analytics, opportunity);
  const acceptedRequests = requests.filter(isOpportunityRequestAccepted);
  const acceptedNotYetCompleted = acceptedRequests.filter(request => !request.result?.isCompleted);
  const completedRequests = acceptedRequests.filter(request => request.result?.isCompleted);
  const outstandingRequests = requests.filter(isOpportunityRequestOutstanding);
  const messageOptions = [
    {
      value: acceptedRequests,
      label: 'All Accepted Requests',
      sublabel: acceptedRequests.length ? `${acceptedRequests.length} request${acceptedRequests.length === 1 ? '' : 's'}` : 'None',
      isDisabled: !acceptedRequests.length
    },
    {
      value: acceptedNotYetCompleted,
      label: 'Accepted & Not Yet Completed',
      sublabel: acceptedNotYetCompleted.length
        ? `${acceptedNotYetCompleted.length} request${acceptedNotYetCompleted.length === 1 ? '' : 's'}`
        : 'None',
      isDisabled: !acceptedNotYetCompleted.length
    },
    {
      value: completedRequests,
      label: 'Accepted & Completed Expectations',
      sublabel: completedRequests.length ? `${completedRequests.length} request${completedRequests.length === 1 ? '' : 's'}` : 'None',
      isDisabled: !completedRequests.length
    },
    {
      value: outstandingRequests,
      label: 'Awaiting Response',
      sublabel: outstandingRequests.length ? `${outstandingRequests.length} request${outstandingRequests.length === 1 ? '' : 's'}` : 'None',
      isDisabled: !outstandingRequests.length
    }
  ];
  const selectBulkMessageRequests = requests => {
    props.openBulkTalentModal({
      talent: requests.map(request => request.user)
    });
  };

  // Allow downloading results
  const [isDownloading, setIsDownloading] = React.useState(false);
  const downloadResults = async () => {
    setIsDownloading(true);
    const resultsDisplayArray = acceptedRequests.map(request => {
      const { result, user } = request;
      const stats = JSON.parse(result.stats || '{}');
      const spendData = getSpendDataOnOpportunityRequest(request, opportunity);
      return {
        Creator: user.name,
        Completed: result.isCompleted ? 'Yes' : 'No',
        'Total Spent': spendData.total,
        'Total Paid': spendData.paid,
        'Total Pending Completion': spendData.pending,
        Clicks: stats.clicks,
        Links: stats.links,
        Mentions: stats.mentions,
        Comments: stats.comments,
        Views: stats.views,
        Likes: stats.likes,
        Saves: stats.saves,
        Shares: stats.shares,
        'Estimated Media Value': stats.emv,
        Orders: stats.orders,
        Volume: stats.volume
      };
    });

    // Get the headers
    const csvRows = [];
    const headers = Object.keys(resultsDisplayArray[0]);
    csvRows.push(headers.join(','));

    // Format the data
    for (const row of resultsDisplayArray) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    }

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', 'Results.csv');
    link.style.visibility = 'hidden';

    await new Promise(resolve => setTimeout(resolve, 600)); // Simulate a delay
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsDownloading(false);
  };

  return (
    <div className='opportunity-leaderboard-controls'>
      <div className='search-container'>
        {isSearching ? (
          <input
            autoFocus
            onBlur={blurInput}
            type='text'
            placeholder='Search'
            className='search'
            value={curSearchVal}
            onChange={e => setCurSearchVal(e.target.value)}
          />
        ) : (
          <div className='search-icon' onClick={focusSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
        )}
      </div>
      {!!acceptedRequests.length && (
        <div onClick={downloadResults} className={cn('download-btn', { downloading: isDownloading })}>
          Download
          {isDownloading && <Loader size={54} />}
        </div>
      )}
      <div className='sort-container message'>
        <Select
          noStyling
          disableSearch
          placeholder='Message'
          value={null}
          options={messageOptions}
          onChangeValue={requests => selectBulkMessageRequests(requests)}
        />
      </div>
      <div className='sort-container'>
        <div className='sort-label'>Sort By:</div>
        <Select noStyling options={sortFnOptions} value={sortFnValue} onChangeValue={value => setSortFnValue(value)} />
      </div>
    </div>
  );
};

OpportunityLeaderboardControls.propTypes = {
  // Data
  opportunity: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,

  // Searching
  curSearchVal: PropTypes.string.isRequired,
  setCurSearchVal: PropTypes.func.isRequired,

  // Sorting
  sortFnOptions: PropTypes.array.isRequired,
  sortFnValue: PropTypes.string.isRequired,
  setSortFnValue: PropTypes.func.isRequired
};

export default OpportunityLeaderboardControls;
