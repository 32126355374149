import React from 'react';
import Switch from 'react-switch';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import _ from 'lodash';
import cn from 'classnames';
import './TalentControls.scss';

import Tooltip from '../General/Tooltip';

import { isAdminControlMode } from '../../Helpers/ui_helpers';
import { isTopTalentExplanation } from '../../Helpers/talent_helpers';
import { isSubscribedToFeatureOrAdmin, blockOnRequiringSubscriptionToFeature } from '../../Helpers/subscription_helpers';

const TalentControls = props => {
  const { ui, user, sortOrder, setUIKeyValue, showTopTalentOnly, showBottomTalentOnly } = props;

  // Sorting
  const canAccessTalentTab = isSubscribedToFeatureOrAdmin(user, 'TALENT_TAB');
  const canFilterBySocial = isSubscribedToFeatureOrAdmin(user, 'ANALYTICS');
  const canFilterByPerformance = isSubscribedToFeatureOrAdmin(user, 'ADVANCED_TALENT_ANALYTICS');
  // const canFilterByCrossRetailer = isSubscribedToFeatureOrAdmin(user, 'CROSS_RETAILER_ANALYTICS');
  const groupedOptions = [
    {
      label: 'General',
      options: [{ value: [null, 'desc'], label: 'Recommended Actions' }]
    },
    {
      label: 'Social',
      options: [
        { value: ['instagramCount', 'desc'], label: 'Most Instagram Followers', disabled: !canFilterBySocial },
        { value: ['tiktokCount', 'desc'], label: 'Most Tiktok Followers', disabled: !canFilterBySocial },
        { value: ['youtubeCount', 'desc'], label: 'Most Youtube Followers', disabled: !canFilterBySocial }
        // { value: ['weighted_like_engagement', 'desc'], label: 'Best Social Engagement', disabled: !canFilterBySocial },
        // { value: ['posts_per_week', 'desc'], label: 'Social Post Frequency', disabled: !canFilterBySocial }
      ]
    },
    {
      label: 'Creator Score',
      options: [{ value: ['trueScore', 'desc'], label: 'ShopMy Creator Score', disabled: !canFilterByPerformance }]
    }
    // {
    //   label: 'Gifting',
    //   options: [
    //     { value: ['gifting_acceptance_rate', 'desc'], label: 'Gifting Acceptance Rate', disabled: !canFilterByPerformance },
    //     { value: ['gifting_promotion_rate', 'desc'], label: 'Gifting Promotion Rate', disabled: !canFilterByPerformance }
    //   ]
    // },
    // {
    //   label: 'Codes',
    //   options: [{ value: ['codes_promotion_rate', 'desc'], label: 'Code Promotion Rate', disabled: !canFilterByPerformance }]
    // },
    // {
    //   label: 'Volume & Performance',
    //   options: [
    //     { value: ['monthly_order_volume', 'desc'], label: 'Order Volume', disabled: !canFilterByPerformance },
    //     { value: ['conversion_rate', 'desc'], label: 'Volume Per Click', disabled: !canFilterByPerformance },
    //     { value: ['volume.indirect', 'desc'], label: 'Most Non-DTC Volume', disabled: !canFilterByCrossRetailer }
    //   ]
    // }
  ];

  const selectedOption = _.flatten(_.map(groupedOptions, 'options')).find(o => o.value[0] === sortOrder);

  const changeSortValue = ({ value, label }) => {
    if (blockOnRequiringSubscriptionToFeature('TALENT_TAB')) return null;
    props.setSortOrder(value[0]);
    props.setSortDirection(value[1]);
  };

  // Top Talent Toggle
  const toggleTopTalentOnly = () => {
    if (blockOnRequiringSubscriptionToFeature('TALENT_TAB')) return null;
    setUIKeyValue('showTopTalentOnly', !showTopTalentOnly, 'talent');
    showBottomTalentOnly && setUIKeyValue('showBottomTalentOnly', false, 'talent');
  };
  const toggleBottomTalentOnly = () => {
    if (blockOnRequiringSubscriptionToFeature('TALENT_TAB')) return null;
    setUIKeyValue('showBottomTalentOnly', !showBottomTalentOnly, 'talent');
    showTopTalentOnly && setUIKeyValue('showTopTalentOnly', false, 'talent');
  };

  return (
    <div className='talent-controls-outer-container'>
      <div className='talent-controls-inner-container'>
        <div className='search'>
          <input
            disabled={!canAccessTalentTab}
            value={props.curSearchVal}
            placeholder='Search For Specific Talent'
            onChange={({ target }) => props.updateSearchVal(target.value)}
          />
        </div>
        <div className='actions'>
          {isAdminControlMode(ui) && (
            <div className={cn('toggle-container', { active: showBottomTalentOnly })}>
              <div className='toggle-label-container'>
                <div className='toggle-label mobile-only'>Bottom Talent</div>
                <div className='toggle-label desktop-only'>Bottom Talent Only</div>
              </div>
              <Switch
                onChange={toggleBottomTalentOnly}
                checked={!!showBottomTalentOnly}
                height={14}
                width={24}
                onColor='#333'
                offColor='#ccc'
                className='switch'
                checkedIcon={false}
                uncheckedIcon={false}
              />
            </div>
          )}
          <div className={cn('toggle-container', { active: showTopTalentOnly })}>
            <div className='toggle-label-container'>
              <div className='toggle-label mobile-only'>Top Talent</div>
              <div className='toggle-label desktop-only'>Top Talent Only</div>
              <Tooltip className='info-tooltip' message={isTopTalentExplanation} getIconDiv={() => <FontAwesomeIcon icon={faInfoCircle} />} />
            </div>
            <Switch
              onChange={toggleTopTalentOnly}
              checked={!!showTopTalentOnly}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          {/* <div className={cn('toggle-container', { active: props.showTalentDetails })}>
            <div className='toggle-label-container'>
              <div className='toggle-label mobile-only'>Analytics</div>
              <div className='toggle-label desktop-only'>Detailed Analytics</div>
            </div>
            <Switch
              onChange={() => {
                if (blockOnRequiringSubscriptionToFeature('TALENT_TAB')) return null;
                props.setShowTalentDetails(!props.showTalentDetails);
              }}
              checked={props.showTalentDetails}
              height={14}
              width={24}
              onColor='#333'
              offColor='#ccc'
              className='switch'
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div> */}
          <div className='sort-controls'>
            <div className='label'>Sort Order: </div>
            <Select
              unstyled
              placeholder='Sort Order'
              classNamePrefix='sort-control'
              onChange={changeSortValue}
              className='sort-control'
              isOptionDisabled={option => option.disabled}
              options={groupedOptions}
              value={selectedOption}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

TalentControls.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showTalentDetails: PropTypes.bool.isRequired,
  sortOrder: PropTypes.string,
  curSearchVal: PropTypes.string,
  setShowTalentDetails: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  updateSearchVal: PropTypes.func.isRequired,
  setUIKeyValue: PropTypes.func.isRequired,
  showTopTalentOnly: PropTypes.bool,
  showBottomTalentOnly: PropTypes.bool
};

export default TalentControls;
