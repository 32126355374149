import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import './OpportunityListingUpload.scss';

import { matchScrollHeight } from '../../../Helpers/helpers';
import { isPDFUrl, isImageUrl } from '../../../Helpers/formatting';

const OpportunityListingUpload = props => {
  const { isEditing, upload } = props;
  const { url, createdAt } = upload;
  const justCreated = moment().diff(createdAt, 'seconds') < 30;

  const [title, setTitle] = React.useState(upload.title || '');
  const [description, setDescription] = React.useState(upload.description || '');

  const urlEnding =
    url.split('.').pop()?.length < 10
      ? url
          .split('.')
          .pop()
          .toUpperCase()
      : null;
  const isImage = isImageUrl(url);
  const isPDF = isPDFUrl(url);
  const isVideo =
    url.includes('.mp4') || url.includes('.mov') || url.includes('.avi') || url.includes('.wmv') || url.includes('.flv') || url.includes('.webm');
  const urlType = isImage ? 'image' : isPDF ? 'pdf' : isVideo ? 'video' : 'file';
  const fallbackTitle = isImage ? 'Uploaded Image' : isPDF ? 'Uploaded PDF' : isVideo ? 'Uploaded Video' : 'Uploaded File';

  const remove = () => props.deleteOpportunityUpload(upload);
  const download = () => window.open(url, '_blank');

  const titleDebounce = React.useRef(null);
  const updateTitle = newTitle => {
    setTitle(newTitle);
    clearTimeout(titleDebounce.current);
    titleDebounce.current = setTimeout(() => props.updateOpportunityUpload(upload, { title: newTitle }), 500);
  };

  const descriptionDebounce = React.useRef(null);
  const updateDescription = newDescription => {
    setDescription(newDescription);
    clearTimeout(descriptionDebounce.current);
    descriptionDebounce.current = setTimeout(() => props.updateOpportunityUpload(upload, { description: newDescription }), 500);
  };

  const additionalClasses = { editing: isEditing, 'has-description': description };
  return (
    <div onClick={isEditing ? null : download} className={cn('opportunity-listing-upload', additionalClasses)}>
      <div className={cn('upload-data', additionalClasses)}>
        <div className='preview'>{isImage ? <img src={url} alt={title || fallbackTitle} /> : urlEnding}</div>
        <div className={cn('main', additionalClasses)}>
          {isEditing ? (
            <>
              <input
                autoFocus={!title && justCreated}
                className='title'
                value={title}
                onChange={e => updateTitle(e.target.value)}
                placeholder={`Title this ${urlType}`}
              />
              <textarea
                className='description'
                value={description}
                onChange={e => updateDescription(e.target.value)}
                placeholder={`Describe this ${urlType} (optional)`}
                ref={ref => matchScrollHeight(ref)}
              />
            </>
          ) : (
            <>
              <div className='title'>{title || fallbackTitle}</div>
              {description && <div className='description'>{description}</div>}
            </>
          )}
        </div>
      </div>
      <div className='upload-actions'>
        <div onClick={download} className='action link'>
          <FontAwesomeIcon icon={faExternalLink} />
        </div>
        {isEditing && (
          <>
            <div onClick={remove} className='action delete'>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

OpportunityListingUpload.propTypes = {
  upload: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateOpportunityUpload: PropTypes.func.isRequired,
  deleteOpportunityUpload: PropTypes.func.isRequired
};

export default OpportunityListingUpload;
