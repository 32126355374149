import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faPencilAlt, faTrash, faLink } from '@fortawesome/pro-solid-svg-icons';
import './ResultPin.scss';

import { addEvent } from '../../APIClient/events';
import { getUserId } from '../../Helpers/user_helpers';
import { getSmartImage, copyToClipboard, matchScrollHeight } from '../../Helpers/helpers';
import { getAffiliateLink, getShortPinLink } from '../../Helpers/attribution_helpers';

const ResultPin = props => {
  const { user, pin, result, empty, canEdit, allowEditing, deletePin, editPin, warnTurnOffPublicIfNecessary, toggleAddingMode, idx } = props;

  const [title, setTitle] = useState(pin?.title || '');
  const [description, setDescription] = useState(pin?.description || '');

  const copyLink = () => {
    copyToClipboard(getShortPinLink(pin));
    cogoToast.success(`Copied product link to clipboard`, { hideAfter: 1 });
  };

  const saveTitle = newTitle => {
    setTitle(newTitle); // AUTOEDITING
  };

  const saveDescription = newDescription => {
    setDescription(newDescription); // AUTOEDITING
  };

  const saveData = () => {
    editPin(pin, { title, description });
  };

  const clickItem = () => {
    !canEdit &&
      addEvent('PIN_VIEW', {
        eventUserId: getUserId(user),
        pinId: pin.id,
        userId: pin.user.id,
        pinTitle: pin.title,
        pinLink: pin.link,
        consultClientName: result.clientName,
        consultTitle: result?.consult?.title,
        shopUsername: pin.user.username,
        shopName: pin.user.name,
        isShortenedLink: false
      });
  };

  const affiliateLink = !empty && getAffiliateLink(pin, user, pin.user);

  return (
    <div className={cn('result-pin-outer-container', { empty, odd: idx % 2 })}>
      {empty ? (
        <div className='result-pin-container'>
          <div className='product-container'>
            <div onClick={() => toggleAddingMode()} className='image-container'>
              <div className='empty-image'>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className='add-product-msg'>Add Product</div>
            </div>
          </div>
          <div className='data-container'></div>
        </div>
      ) : (
        pin && (
          <div className='result-pin-container'>
            <div className='product-container'>
              <div className='image-container'>
                <a onClick={clickItem} href={affiliateLink} target='_blank' rel='noopener noreferrer'>
                  <img className='product-image' alt={pin.title} src={getSmartImage(pin.image)} />
                </a>
                {allowEditing && (
                  <div className='controls'>
                    <div className='control'>
                      <FontAwesomeIcon onClick={() => deletePin(pin)} icon={faTrash} />
                    </div>
                    <div className='control'>
                      <FontAwesomeIcon onClick={copyLink} icon={faLink} />
                    </div>
                    <div className='control'>
                      <FontAwesomeIcon onClick={() => toggleAddingMode(pin)} icon={faPencilAlt} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {allowEditing ? (
              <div className='data-container'>
                <textarea
                  ref={e => matchScrollHeight(e)}
                  rows={1}
                  className='title'
                  value={title}
                  placeholder={`Product Title`}
                  onBlur={saveData}
                  onChange={e => saveTitle(e.target.value)}
                />
                <textarea
                  className='description'
                  ref={e => matchScrollHeight(e)}
                  rows={1}
                  value={description}
                  placeholder={`Add a description here.`}
                  onBlur={saveData}
                  onChange={e => saveDescription(e.target.value)}
                />
              </div>
            ) : (
              <div onClick={warnTurnOffPublicIfNecessary} className='data-container'>
                <a onClick={clickItem} href={affiliateLink} target='_blank' rel='noopener noreferrer'>
                  <h3 className='title'>{title}</h3>
                </a>
                <h4 className='description'>{description}</h4>
                <a className='shop-now-btn' onClick={clickItem} href={affiliateLink} target='_blank' rel='noopener noreferrer'>
                  SHOP NOW
                </a>
              </div>
            )}
          </div>
        )
      )}
      {allowEditing && <div className='action-els'>{props.dragHandle}</div>}
    </div>
  );
};

ResultPin.propTypes = {
  idx: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  toggleAddingMode: PropTypes.func.isRequired,

  canEdit: PropTypes.bool.isRequired,
  allowEditing: PropTypes.bool.isRequired,

  // Fake Pins
  empty: PropTypes.bool,

  // Real Pins
  dragHandle: PropTypes.object,
  pin: PropTypes.object,
  deletePin: PropTypes.func,
  editPin: PropTypes.func
};

export default ResultPin;
