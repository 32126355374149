import React from 'react';
import _ from 'lodash';
import './SectionTemplate_Post.scss';

const SectionTemplate_PostHorizontal = props => {
  return (
    <div className='section-template post horizontal'>
      {_.range(6).map(idx => (
        <div className='collection' key={idx} />
      ))}
    </div>
  );
};

export default SectionTemplate_PostHorizontal;
