import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RequiresBrandLoginPanel.scss';

import { getName, isLoggedIn } from '../../Helpers/user_helpers';

class RequiresBrandLoginPanel extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;

    const errorMessage = !isLoggedIn(user) ? (
      <span>You must be logged in to view this page.</span>
    ) : (
      <span>
        You must be logged in with a brand account to view this page.
        <br />
        You are currently logged in as {getName(user)}.
      </span>
    );

    return (
      <div className='requires-brand-login-outer-container'>
        <div className='requires-brand-login-inner-container'>{errorMessage}</div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {})(RequiresBrandLoginPanel);
