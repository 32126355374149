import _ from 'lodash';

export const STORE_ATTRIBUTION_FROM_URL = 'STORE_ATTRIBUTION_FROM_URL';

export const storeAttributionFromUrl = () => async (dispatch, getState) => {
  /*
    We want to store the attribution from the url for certain URL parameters in order to correctly understand
    the source of all of our applications.

    Right now we only store values starting in sm_ and utm_.
  */

  const validPrefixes = ['sm_', 'utm_'];
  const validNonPrefixedValues = ['source'];
  const urlParams = new URLSearchParams(window.location.search);
  const validParams = Array.from(urlParams.entries()).reduce((acc, [key, value]) => {
    if (validPrefixes.some(prefix => key.startsWith(prefix)) || validNonPrefixedValues.includes(key)) acc[key] = value;
    return acc;
  }, {});

  return dispatch({
    attribution_params: validParams,
    type: STORE_ATTRIBUTION_FROM_URL
  });
};
