import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import './OpportunityHeader.scss';

import { isBrand } from '../../Helpers/user_helpers';

import OpportunityHeaderTitle from './Elements/OpportunityHeaderTitle';
import OpportunityHeaderActionButtons from './Elements/OpportunityHeaderActionButtons';
import OpportunityHeaderTabs from './Elements/OpportunityHeaderTabs';

const OpportunityHeader = props => {
  const { isLoadingOpportunity, opportunity, opportunityRequest } = props;
  const showLoading = isLoadingOpportunity || !opportunity;

  const history = useHistory();
  const goBack = () => {
    history.push(isBrand(props.user) ? '/opportunities' : '/partners/opportunities');
  };

  /*
    We want to hide the header section when the user has not yet accepted and is just viewing the listing page.
  */
  const hideHeaderSection = !isBrand(props.user) && !opportunityRequest?.userAccepted;
  return (
    <div className={cn('opportunity-header-container', { 'header-hidden': hideHeaderSection, 'header-visible': !hideHeaderSection })}>
      <div onClick={goBack} className='back-to-opportunities'>
        <FontAwesomeIcon icon={faChevronLeft} />
        Back To All Opportunities
      </div>
      {!hideHeaderSection && (
        <>
          <div className='title-and-actions-container'>
            <OpportunityHeaderTitle {...props} showLoading={showLoading} />
            <OpportunityHeaderActionButtons {...props} showLoading={showLoading} />
          </div>
          <div className='tabs-container'>
            <OpportunityHeaderTabs {...props} />
          </div>
        </>
      )}
    </div>
  );
};

OpportunityHeader.propTypes = {
  user: PropTypes.object.isRequired,
  opportunity: PropTypes.object,
  opportunityRequest: PropTypes.object,
  isLoadingOpportunity: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default OpportunityHeader;
