import React from 'react';
import _ from 'lodash';
import './SectionTemplate_Post.scss';

const SectionTemplate_Post = props => {
  return (
    <div className='section-template post'>
      {_.range(8).map(idx => (
        <div className='collection' key={idx} />
      ))}
    </div>
  );
};

export default SectionTemplate_Post;
