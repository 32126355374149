export const cleanTag = tag => {
  /*
    Clean up the tags for display. 

    For example, we may want to show brands the shortened "Lives in US" but when the creator
    selects it we may want them to select "United States".
  */
  let cleanedValue = tag.value;

  if (tag.type === 'geo') {
    cleanedValue = cleanedValue.replace('Lives in ', '');
    cleanedValue = cleanedValue.replace('US', 'United States');
    cleanedValue = cleanedValue.replace('UK', 'United Kingdom');
    cleanedValue = cleanedValue.trim();
  }
  return {
    ...tag,
    value: cleanedValue
  };
};
