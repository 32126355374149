import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import commaNumber from 'comma-number';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import _ from 'lodash';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { getConsultUrl, formatPublicConsultDisplay } from '../../Helpers/consult_helpers';
import { getPrettyPriceDisplay } from '../../Helpers/formatting';

import ConsultPreviewFooter from './elements/ConsultPreviewFooter';
import Loader from '../Loader/Loader';

import './ConsultPreview.scss';

const ConsultPreview = props => {
  const { deleteConsult, setVisibleConsult, previewOnly, analytics, blankPreview, onPreviewClick, consult, canEdit } = props;
  const [showingActionItems, setShowingActionItems] = useState(false);
  const [showCompletedResults, setShowCompletedResults] = useState(false);

  const clickDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    const needsSecondCheck = consult.example || _.get(consult, 'results.length') || consult.description;
    needsSecondCheck
      ? confirmAlert({
          title: 'Just confirming',
          message: 'Are you sure you want to delete this consultation?',
          buttons: [
            { label: 'No', className: 'cancel', onClick: () => {} },
            {
              label: 'Yes',
              onClick: () => {
                deleteConsult(consult);
              }
            }
          ]
        })
      : deleteConsult(consult);
  };

  const updateImage = e => {
    e.preventDefault();
    e.stopPropagation();
    props.editImage(consult);
  };

  const toggleVisibility = e => {
    e.preventDefault();
    e.stopPropagation();
    props.editConsult(consult.id, { isPrivate: !consult.isPrivate });
  };

  const toggleExample = result => {
    props.editConsult(consult.id, { ExampleConsultResult_Id: consult.ExampleConsultResult_Id === result.id ? null : result.id });
  };

  const numCompleted = _.filter(consult?.results, r => r.completedAt).length;
  const numPending = _.filter(consult?.results, r => !r.completedAt).length;
  const hasResults = !!consult?.results?.length;

  if (previewOnly) {
    return (
      <div className='consult-preview-outer-container'>
        <div className='consult-preview-container'>
          <div onClick={() => onPreviewClick(consult)} className='consult-preview-inner-container'>
            <div className={cn('content', { 'has-image': consult?.coverImage })}>
              <div className='headline'>
                <div className='el price'>{blankPreview ? '' : consult.price ? getPrettyPriceDisplay(consult.price) : 'FREE'}</div>
                {!_.isNil(consult?.numRemaining) && consult?.numRemaining <= 25 && (
                  <div className='el remaining-count'>
                    {consult ? (consult.numRemaining ? `Only ${consult.numRemaining} remaining` : 'Not Currently Available') : '...'}
                  </div>
                )}
              </div>
              <h2 className='title'>{blankPreview ? 'Blank Template' : consult?.title || '-'}</h2>
              <h3 className={cn('subtitle', { empty: !consult?.description })}>
                {blankPreview ? '' : formatPublicConsultDisplay(consult?.description) || '-'}
              </h3>
              <div className='get-started-link'>
                GET STARTED
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            {blankPreview ? (
              <div className='background-image-container'>
                <div onClick={canEdit ? updateImage : null} className={cn('background-image empty', { public: !canEdit })}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            ) : (
              consult?.coverImage && (
                <div className='background-image-container'>
                  <img
                    onClick={canEdit ? updateImage : null}
                    className={cn('background-image', { public: !canEdit })}
                    src={consult.coverImage}
                    alt='Products'
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='consult-preview-outer-container'>
      <div className='consult-preview-container'>
        <Link
          onClick={() => setVisibleConsult(consult)}
          to={getConsultUrl(consult)}
          className={cn('consult-preview-inner-container', { hidden: consult.isPrivate })}
        >
          <div className={cn('content', { 'has-image': consult.coverImage || canEdit })}>
            <div className='headline'>
              <div className='el price'>{consult.price ? getPrettyPriceDisplay(consult.price) : 'FREE'}</div>
              {!_.isNil(consult?.numRemaining) && consult?.numRemaining <= 25 && (
                <div className='el remaining-count'>
                  {consult.numRemaining ? `Only ${consult.numRemaining} remaining` : 'Not Currently Available'}
                </div>
              )}
            </div>
            <h2 className='title'>{consult.title || '-'}</h2>
            <h3 className={cn('subtitle', { empty: !consult.description })}>
              {formatPublicConsultDisplay(consult.description) || (canEdit ? 'This is where you can add a description about the consultation.' : '')}
            </h3>
            <div className='get-started-link'>
              {canEdit ? 'EDIT' : 'START'} CONSULT
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
          {consult.coverImage ? (
            <div className='background-image-container'>
              <img
                onClick={canEdit ? updateImage : null}
                className={cn('background-image', { public: !canEdit })}
                src={consult.coverImage}
                alt='Products'
              />
              {canEdit && (
                <div className='update-background-image-label-container'>
                  <div className='update-background-image-label'>
                    <div>CHANGE IMAGE</div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            canEdit && (
              <div className='background-image-container'>
                <div onClick={canEdit ? updateImage : null} className={cn('background-image empty', { public: !canEdit })}>
                  <FontAwesomeIcon icon={faPlus} />
                  <div>ADD IMAGE</div>
                </div>
              </div>
            )
          )}
          {canEdit && (
            <div className='icn-show'>
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowingActionItems(!showingActionItems);
                }}
                className='icn show-action-items'
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
              {showingActionItems && (
                <OutsideClickHandler
                  onOutsideClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowingActionItems(!showingActionItems);
                  }}
                >
                  <div className='action-items-container'>
                    <div onClick={clickDelete} className='delete item'>
                      Delete
                    </div>
                    <div onClick={toggleVisibility} className='visibility item'>
                      {consult.isPrivate ? 'Show' : 'Hide'} Consult
                    </div>
                    {props.moveToTop && !!props.idx && (
                      <div
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.moveToTop();
                        }}
                        className='move item'
                      >
                        Move To Top
                      </div>
                    )}
                    {!!numCompleted && (
                      <div
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowCompletedResults(!showCompletedResults);
                          setShowingActionItems(false);
                        }}
                        className='visibility item'
                      >
                        {showCompletedResults ? 'Hide' : 'Show'} Completed{showCompletedResults ? '' : ` (${numCompleted})`}
                      </div>
                    )}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          )}
        </Link>
        {consult?.example && <ConsultPreviewFooter toggleExample={toggleExample} canEdit={canEdit} exampleOnly consult={consult} />}
        {canEdit && hasResults && (numPending || showCompletedResults) && (
          <ConsultPreviewFooter canEdit toggleExample={toggleExample} showCompleted={showCompletedResults} consult={consult} />
        )}
        {!!consult.isPrivate && canEdit && (
          <div className='tag-container'>
            <div className='notification-tag'>HIDDEN</div>
          </div>
        )}
        {canEdit && props.analyticsOn && (
          <div className='analytics-preview-container loaded'>
            {props.fetchingAnalytics ? (
              <Loader size={20} />
            ) : (
              <div className='header'>
                <div>
                  {commaNumber(analytics?.num_views) || '0'} view
                  {analytics?.num_views === 1 ? '' : 's'}
                </div>
                <div>{analytics?.earnings ? `$${analytics?.earnings.toFixed(2)}` : ''}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ConsultPreview.propTypes = {
  canEdit: PropTypes.bool.isRequired,

  // For Viewing
  consult: PropTypes.object, // not needed if blank view

  // For editing
  idx: PropTypes.number,
  setVisibleConsult: PropTypes.func,
  editImage: PropTypes.func,
  moveToTop: PropTypes.func,
  deleteConsult: PropTypes.func,
  editConsult: PropTypes.func,
  analytics: PropTypes.object,
  analyticsOn: PropTypes.bool,
  fetchingAnalytics: PropTypes.bool,

  // For previewing
  blankPreview: PropTypes.bool,
  previewOnly: PropTypes.bool,
  onPreviewClick: PropTypes.func
};

export default ConsultPreview;
