import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cogoToast from 'cogo-toast';
import cn from 'classnames';
import { isSMSAffiliatePartnerBrand } from '../../Helpers/user_helpers';
import { getCodeRateDisplayLong, getFirstName } from '../../Helpers/formatting';
import { getCodesForUser, getCustomRateForUser, getStandardRateForArtist } from '../../Helpers/brand_helpers';

import './ContractProposeRatesCodes.scss';

const ContractProposeRatesCodes = props => {
  const { user, analytics, contract, canEdit } = props;
  const { rate_code } = contract;
  const [isEditing, setIsEditing] = useState(false);

  // Handle Basic, Premium, Premium Plus handling
  const isSMSAffiliatePartner = isSMSAffiliatePartnerBrand(user);

  const brandCanOfferRate = isSMSAffiliatePartner;
  const brandCannotOfferRateWarning = !isSMSAffiliatePartner
    ? `You must be directly integrated to set a custom commission rate.`
    : `You must first subscribe to one of the brand packages.`;

  const brandCanOfferCodes = isSMSAffiliatePartnerBrand(user);
  const brandCannotOfferCodesWarning = `You must first subscribe to one of the brand packages.`;

  const standardRate = getStandardRateForArtist(contract.brand, contract.user);
  const customRate = getCustomRateForUser(analytics, contract.user);
  const customCodes = getCodesForUser(analytics, contract.user);
  const currentCode = customCodes.length ? customCodes[0] : null;

  const currentRate = customRate ? customRate.rate : standardRate;
  const collaborationRate = rate_code ? rate_code.rateRate || currentRate : currentRate;
  const currentCodeDisplay = currentCode ? getCodeRateDisplayLong(currentCode) : '';
  const collaborationCodeDisplay = rate_code?.codeDisplayText ? getCodeRateDisplayLong(rate_code) : currentCodeDisplay;

  // For editing purposes
  const [rateRate, setRateRate] = useState(String(collaborationRate) || '');
  const [codeRate, setCodeRate] = useState(currentCode ? String(currentCode.rate) : '');
  const [codeDisplayText, setCodeDisplayText] = useState(currentCode ? currentCode.displayText : '');

  const saveRateCodes = async () => {
    const newRate = rateRate.replace(/[^0-9.]+/g, '');
    const newCodeRate = codeRate.replace(/[^0-9.]+/g, '');
    const isDeleting = !newRate && !newCodeRate;
    const newObject = {
      ...(brandCanOfferRate ? { rateRate: newRate === '' ? null : parseFloat(newRate) } : {}),
      ...(brandCanOfferCodes && newCodeRate && codeDisplayText
        ? {
            codeRate: newCodeRate === '' ? null : parseFloat(newCodeRate),
            codeIsPercentage: codeRate.includes('$') ? false : true,
            codeDisplayText: codeDisplayText === '' ? null : codeDisplayText
          }
        : {})
    };
    contract.rate_code
      ? isDeleting
        ? props.deleteContractRateCode(rate_code)
        : props.updateContractRateCode(rate_code, newObject)
      : !isDeleting && !_.isEmpty(newObject) && props.createContractRateCode(newObject, contract);
    setIsEditing(false);
  };

  const isRateTheSame = !rate_code || (currentRate === collaborationRate && currentCodeDisplay === collaborationCodeDisplay);
  if (!canEdit && isRateTheSame) return null;
  return (
    <div className='contract-propose-rates-codes-card card'>
      <div className='card-header-container'>
        <div className='card-header'>{canEdit ? 'Set Rates & Codes' : 'Rates & Codes'}</div>
        {canEdit && (
          <div className='card-subheader'>We will automatically apply these rates and attach these codes once the proposal is accepted.</div>
        )}
      </div>
      <div className='card-body-container'>
        <div className={cn('contract-rates-codes', { locked: !canEdit })}>
          {canEdit && (
            <div className='contract-rate-code old'>
              <div className='header'>Current</div>
              {canEdit && (
                <div onClick={props.openUserModal} className='edit-btn'>
                  EDIT
                </div>
              )}
              <div className='section'>
                <div className='section-header'>COMMISSION RATE</div>
                <div className='section-value'>{currentRate ? `${currentRate}%` : '-'}</div>
              </div>
              <div className='section'>
                <div className='section-header'>AUDIENCE DISCOUNT CODE</div>
                <div className='section-value'>{currentCodeDisplay || '-'}</div>
              </div>
            </div>
          )}
          <div className='contract-rate-code new'>
            {canEdit && <div className='header'>During Collaboration</div>}
            {canEdit && (
              <>
                {isEditing ? (
                  <div onClick={() => saveRateCodes()} className='edit-btn save'>
                    SAVE
                  </div>
                ) : (
                  <div onClick={() => setIsEditing(true)} className='edit-btn'>
                    EDIT
                  </div>
                )}
              </>
            )}
            <div className='section'>
              <div className='section-header'>COMMISSION RATE</div>
              {!isEditing ? (
                <div className='section-value'>{collaborationRate ? `${collaborationRate}%` : '-'}</div>
              ) : (
                <div onClick={() => !brandCanOfferRate && cogoToast.warn(brandCannotOfferRateWarning)} className='section-edit'>
                  <input
                    disabled={!brandCanOfferRate}
                    value={rateRate}
                    className='title'
                    placeholder='Commission Rate'
                    onChange={e => setRateRate(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className='section'>
              <div className='section-header'>AUDIENCE DISCOUNT CODE</div>
              {!isEditing ? (
                <div className='section-value'>{collaborationCodeDisplay || '-'}</div>
              ) : (
                <div onClick={() => !brandCanOfferCodes && cogoToast.warn(brandCannotOfferCodesWarning)} className='section-edit'>
                  <input
                    disabled={!brandCanOfferCodes}
                    value={codeDisplayText}
                    className='title'
                    placeholder={`${getFirstName(contract.user?.name).toUpperCase()}${codeRate || 20}`}
                    onChange={e => setCodeDisplayText(e.target.value)}
                  />
                  <input
                    disabled={!brandCanOfferCodes}
                    value={codeRate}
                    className='title'
                    placeholder='20%'
                    onChange={e => setCodeRate(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContractProposeRatesCodes.propTypes = {
  contract: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,

  // For Editing
  canEdit: PropTypes.bool.isRequired,
  openUserModal: PropTypes.func,
  createContractRateCode: PropTypes.func,
  updateContractRateCode: PropTypes.func,
  deleteContractRateCode: PropTypes.func
};

export default ContractProposeRatesCodes;
