import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './UserLookbookProducts.scss';

import LookbookItem from '../LookbookItem';

const UserLookbookProducts = props => {
  return (
    <div className='user-lookbook-products-outer'>
      <div className='user-lookbook-products-inner'>
        {_.orderBy(props.lookbook.items, 'sortOrderRank').map((item, index) => (
          <LookbookItem
            key={item.id}
            index={index}
            item={item}
            user={props.user}
            lookbook={props.lookbook}
            lookbooks={props.lookbooks}
            updateLookbookCart={props.updateLookbookCart}
          />
        ))}
      </div>
    </div>
  );
};

UserLookbookProducts.propTypes = {
  user: PropTypes.object.isRequired,
  lookbook: PropTypes.object.isRequired,
  lookbooks: PropTypes.object.isRequired,
  updateLookbookCart: PropTypes.func.isRequired
};

export default UserLookbookProducts;
