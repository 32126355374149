import React from 'react';
import './HomeCreatorAnimations.scss';

import HomeCreatorAnimationSteps from './Elements/HomeCreatorAnimationSteps';
import HomeCreatorAnimationPhones from './Elements/HomeCreatorAnimationPhones';

const HomeCreatorAnimations = props => {
  const [stepInFocus, setStepInFocus] = React.useState(0);
  return (
    <div className='home-creator-animations-outer-container'>
      <div className='home-creator-animations-inner-container'>
        <div className='home-creator-animations-steps'>
          <HomeCreatorAnimationSteps stepInFocus={stepInFocus} setStepInFocus={setStepInFocus} />
        </div>
        <div className='home-creator-animations-phones'>
          <HomeCreatorAnimationPhones stepInFocus={stepInFocus} />
        </div>
      </div>
    </div>
  );
};

HomeCreatorAnimations.propTypes = {
  // user: PropTypes.object.isRequired,
};

export default HomeCreatorAnimations;
