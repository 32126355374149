import _ from 'lodash';
import { getTags as getTagsAPI } from '../APIClient/tags';
import {
  getDiscoverGroups as getDiscoverGroupsAPI,
  getDiscoverGroup as getDiscoverGroupAPI,
  createDiscoverGroup as createDiscoverGroupAPI,
  updateDiscoverGroup as updateDiscoverGroupAPI,
  deleteDiscoverGroup as deleteDiscoverGroupAPI,
  createDiscoverFeature as createDiscoverFeatureAPI,
  updateDiscoverFeature as updateDiscoverFeatureAPI,
  deleteDiscoverFeature as deleteDiscoverFeatureAPI,
  discoverTags as discoverTagsAPI
} from '../APIClient/discover';

import { getBrand, getBrandId } from '../Helpers/user_helpers';

export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILURE = 'GET_TAGS_FAILURE';

export const SYNC_TAGS_SUCCESS = 'SYNC_TAGS_SUCCESS';
export const SYNC_TAGS_FAILURE = 'SYNC_TAGS_FAILURE';

export const GET_DISCOVER_GROUPS_SUCCESS = 'GET_DISCOVER_GROUPS_SUCCESS';
export const GET_DISCOVER_GROUPS_FAILURE = 'GET_DISCOVER_GROUPS_FAILURE';

export const SET_ACTIVE_DISCOVER_GROUP_REQUEST = 'SET_ACTIVE_DISCOVER_GROUP_REQUEST';
export const SET_ACTIVE_DISCOVER_GROUP_SUCCESS = 'SET_ACTIVE_DISCOVER_GROUP_SUCCESS';
export const SET_ACTIVE_DISCOVER_GROUP_FAILURE = 'SET_ACTIVE_DISCOVER_GROUP_FAILURE';

export const CREATE_DISCOVER_GROUP_SUCCESS = 'CREATE_DISCOVER_GROUP_SUCCESS';
export const CREATE_DISCOVER_GROUP_FAILURE = 'CREATE_DISCOVER_GROUP_FAILURE';

export const UPDATE_DISCOVER_GROUP_SUCCESS = 'UPDATE_DISCOVER_GROUP_SUCCESS';
export const UPDATE_DISCOVER_GROUP_FAILURE = 'UPDATE_DISCOVER_GROUP_FAILURE';

export const DELETE_DISCOVER_GROUP_REQUEST = 'DELETE_DISCOVER_GROUP_REQUEST';
export const DELETE_DISCOVER_GROUP_SUCCESS = 'DELETE_DISCOVER_GROUP_SUCCESS';
export const DELETE_DISCOVER_GROUP_FAILURE = 'DELETE_DISCOVER_GROUP_FAILURE';

export const CREATE_DISCOVER_FEATURE_SUCCESS = 'CREATE_DISCOVER_FEATURE_SUCCESS';
export const CREATE_DISCOVER_FEATURE_FAILURE = 'CREATE_DISCOVER_FEATURE_FAILURE';

export const UPDATE_DISCOVER_FEATURE_SUCCESS = 'UPDATE_DISCOVER_FEATURE_SUCCESS';
export const UPDATE_DISCOVER_FEATURE_FAILURE = 'UPDATE_DISCOVER_FEATURE_FAILURE';

export const DELETE_DISCOVER_FEATURE_REQUEST = 'DELETE_DISCOVER_FEATURE_REQUEST';
export const DELETE_DISCOVER_FEATURE_SUCCESS = 'DELETE_DISCOVER_FEATURE_SUCCESS';
export const DELETE_DISCOVER_FEATURE_FAILURE = 'DELETE_DISCOVER_FEATURE_FAILURE';

export const getTags = options => async (dispatch, getState) => {
  /*
    Deprecated: use syncDiscoverTags instead.

    Remove once Discover 2.0 goes live or after November, 2023 without concern.
  */
  const brand = getBrand(getState().user);
  try {
    const resp = await getTagsAPI({ ...options, Brand_id: brand?.id, showCounts: true, hideUnused: true });
    return dispatch({
      type: GET_TAGS_SUCCESS,
      tags: resp.tags || [],
      lists: resp.lists || [],
      num_gifting_recommendations: resp.num_gifting_recommendations || 0,
      num_code_recommendations: resp.num_code_recommendations || 0,
      num_collab_recommendations: resp.num_collab_recommendations || 0
    });
  } catch (error) {
    return dispatch({
      type: GET_TAGS_FAILURE
    });
  }
};

export const syncTags = options => async (dispatch, getState) => {
  const brand = getBrand(getState().user);
  try {
    const resp = await discoverTagsAPI({ ...options, Brand_id: brand?.id, showCounts: true });
    return dispatch({
      type: SYNC_TAGS_SUCCESS,
      tags: resp.tags || []
    });
  } catch (error) {
    return dispatch({
      type: SYNC_TAGS_FAILURE
    });
  }
};

export const syncDiscoverGroups = () => async (dispatch, getState) => {
  try {
    const groups = await getDiscoverGroupsAPI({ Brand_id: getBrandId(getState().user) });

    /* Set a default list */
    const brand = getBrand(getState().user);
    const groupsWithFeatures = groups.filter(g => g.features?.filter(f => !f.isHidden)?.length);
    const newAndForYou = _.find(groupsWithFeatures, { title: 'New & For You' });
    const brandTags = brand?.tags?.map(t => t.value) || [];
    const firstTagMatchedGroup = _.find(groupsWithFeatures, g => brandTags.includes(g.title));
    const firstValidGroup = groupsWithFeatures[0];
    const defaultGroup = newAndForYou || firstTagMatchedGroup || firstValidGroup || groups[0];

    // If this is the first time syncing, we want to fetch the active one as well
    if (!getState().discover.activeGroup) {
      dispatch(syncActiveDiscoverGroup(defaultGroup));
    }

    return dispatch({
      type: GET_DISCOVER_GROUPS_SUCCESS,
      groups: groups,
      defaultGroup
    });
  } catch (error) {
    return dispatch({
      type: GET_DISCOVER_GROUPS_FAILURE
    });
  }
};

export const syncActiveDiscoverGroup = group => async (dispatch, getState) => {
  dispatch({ type: SET_ACTIVE_DISCOVER_GROUP_REQUEST, group });
  try {
    const resp = await getDiscoverGroupAPI(group, { Brand_id: getBrandId(getState().user) });
    return dispatch({
      type: SET_ACTIVE_DISCOVER_GROUP_SUCCESS,
      group: resp
    });
  } catch (error) {
    return dispatch({
      type: SET_ACTIVE_DISCOVER_GROUP_FAILURE
    });
  }
};

export const createDiscoverGroup = data => async dispatch => {
  try {
    const group = await createDiscoverGroupAPI(data);
    return dispatch({
      type: CREATE_DISCOVER_GROUP_SUCCESS,
      group
    });
  } catch (error) {
    return dispatch({
      type: CREATE_DISCOVER_GROUP_FAILURE
    });
  }
};

export const updateDiscoverGroup = (group, updates) => async dispatch => {
  try {
    const updatedGroup = await updateDiscoverGroupAPI(group, updates);
    return dispatch({
      type: UPDATE_DISCOVER_GROUP_SUCCESS,
      group: updatedGroup
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_DISCOVER_GROUP_FAILURE
    });
  }
};

export const deleteDiscoverGroup = group => async dispatch => {
  dispatch({ type: DELETE_DISCOVER_GROUP_REQUEST, group });
  try {
    await deleteDiscoverGroupAPI(group);
    return dispatch({
      type: DELETE_DISCOVER_GROUP_SUCCESS,
      group
    });
  } catch (error) {
    return dispatch({
      type: DELETE_DISCOVER_GROUP_FAILURE
    });
  }
};

export const createDiscoverFeature = data => async dispatch => {
  try {
    const feature = await createDiscoverFeatureAPI(data);
    return dispatch({
      type: CREATE_DISCOVER_FEATURE_SUCCESS,
      feature
    });
  } catch (error) {
    return dispatch({
      type: CREATE_DISCOVER_FEATURE_FAILURE
    });
  }
};

export const updateDiscoverFeature = (feature, updates) => async dispatch => {
  try {
    const updatedFeature = await updateDiscoverFeatureAPI(feature, updates);
    return dispatch({
      type: UPDATE_DISCOVER_FEATURE_SUCCESS,
      feature: updatedFeature
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_DISCOVER_FEATURE_FAILURE
    });
  }
};

export const deleteDiscoverFeature = feature => async dispatch => {
  dispatch({ type: DELETE_DISCOVER_FEATURE_REQUEST, feature });
  try {
    await deleteDiscoverFeatureAPI(feature);
    return dispatch({
      type: DELETE_DISCOVER_FEATURE_SUCCESS,
      feature
    });
  } catch (error) {
    return dispatch({
      type: DELETE_DISCOVER_FEATURE_FAILURE
    });
  }
};
