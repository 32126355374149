import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';
import './HomeFAQ.scss';

const HomeFAQ = props => {
  const { faqs } = props;
  const { questions, subheader } = faqs;
  const [expandedIndex, setExpandedIndex] = React.useState(null);

  useEffect(() => {
    const structuredData = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: questions.map(faq => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer
        }
      }))
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(structuredData);
    document.head.appendChild(script);
  }, []);

  return (
    <div className='home-faq-outer-container'>
      <section className='home-faq-inner-container'>
        <div className='main'>
          <h2 className='header'>FAQ</h2>
          <p className='subheader'>{subheader}</p>
        </div>
        <div className='faqs'>
          {questions?.map((faq, index) => {
            const isExpanded = index === expandedIndex;
            const toggle = () => setExpandedIndex(isExpanded ? null : index);
            return (
              <div className={cn('faq', { expanded: isExpanded })} key={index}>
                <div onClick={toggle} className='question'>
                  <h3 className='text'>{faq.question}</h3>
                  <div className='icon'>
                    <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
                  </div>
                </div>
                <div className='answer' style={{ display: isExpanded ? 'block' : 'none' }}>
                  <p className='text'>{faq.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

HomeFAQ.propTypes = {
  faqs: PropTypes.object.isRequired
};

export default HomeFAQ;
