import APIClient from './index';
import _ from 'lodash';

export const validateOAuthParams = params => {
  return new Promise((resolve, reject) => {
    APIClient.get(`/OAuthApplications/validate-oauth-params?${new URLSearchParams(params).toString()}`)
      .then(response => resolve(response.data))
      .catch(error => {
        reject(_.get(error?.response, ['data', 'error']));
      });
  });
};
