import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import commaNumber from 'comma-number';
import './ChatMessageOpportunityPreview.scss';

import { getCoverImageForOpportunity } from '../../../Helpers/opportunity_helpers';

const ChatMessageOpportunityPreview = props => {
  const { request } = props;
  const { opportunity, brand, payment_tier } = request;
  const { title } = opportunity;
  const { fixedFee, adjCommissionRate } = payment_tier || {};

  let metadata = [];
  if (fixedFee) metadata.push(`$${commaNumber(fixedFee)}`);
  if (adjCommissionRate) metadata.push(`${adjCommissionRate}% Commission`);
  return (
    <div className='chat-message-opportunity-preview-card'>
      <div className='opportunity-image-container'>
        <img className='opportunity-image' src={getCoverImageForOpportunity(opportunity, brand)} alt={title} />
      </div>
      <div className='opportunity-info'>
        <div className='display-data'>
          <div className='opportunity-title'>{title}</div>
          {!!metadata.length && <div className='opportunity-metadata'>{metadata.join(' • ')}</div>}
        </div>
        <div className='actions'>
          <Link to={`/opportunity/${request.Opportunity_id}`} className='view-button'>
            View Opportunity
          </Link>
        </div>
      </div>
    </div>
  );
};

ChatMessageOpportunityPreview.propTypes = {
  request: PropTypes.object.isRequired
};

export default ChatMessageOpportunityPreview;
