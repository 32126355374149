import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';
import { withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import './ListHeader.scss';

import ListHeaderActions from './ListHeaderActions';

import { updateDiscoverList } from '../../APIClient/discover';
import { updateBrandList } from '../../APIClient/lists';
import { matchScrollHeight } from '../../Helpers/helpers';

let debounce;
const ListHeader = props => {
  const { isBrandList, user, ui, list, history, isEditing } = props;

  // Editing Content
  const [title, setTitle] = useState(list.title || '');
  const [description, setDescription] = useState(list.description || '');
  const updateTitle = newTitle => {
    setTitle(newTitle);
    debounceSave(newTitle, description);
  };
  const updateDescription = newDescription => {
    setDescription(newDescription);
    debounceSave(title, newDescription);
  };
  const debounceSave = (title, description) => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      isBrandList
        ? updateBrandList(list, { title, description }).catch(err => cogoToast.error(err?.message || 'Could not update list, please try again.'))
        : updateDiscoverList(list, { title, description }).catch(err => cogoToast.error(err?.message || 'Could not update list, please try again.'));
    }, 750);
  };

  // Handle Back Button
  const lastLocation = useLastLocation();
  const goBack = () =>
    props.ensureLoggedInThenCall(() =>
      lastLocation?.pathname?.includes(`/discover`) && !lastLocation?.pathname?.includes(`/discover/`) ? history.goBack() : history.push('/discover')
    );

  return (
    <div className='list-header-container'>
      <div className='title-container'>
        <div onClick={goBack} className='back-btn'>
          <FontAwesomeIcon icon={faChevronLeft} />
          Back to Discover
        </div>
        {isEditing ? (
          <>
            <textarea
              ref={e => matchScrollHeight(e)}
              autoFocus={!title.length}
              placeholder='List Name'
              rows={1}
              value={title}
              onChange={e => updateTitle(e.target.value)}
              className='title'
            />
            <textarea
              ref={e => matchScrollHeight(e)}
              placeholder='Describe the list'
              rows={1}
              value={description}
              onChange={e => updateDescription(e.target.value)}
            />
          </>
        ) : (
          <>
            <div className='title'>{title || 'Talent List'}</div>
            {description && <div className='description'>{description}</div>}
          </>
        )}
      </div>
      <ListHeaderActions
        ui={ui}
        list={list}
        user={user}
        sortBy={props.sortBy}
        setSortBy={props.setSortBy}
        sortDirection={props.sortDirection}
        setSortDirection={props.setSortDirection}
        syncList={props.syncList}
        isBrandList={isBrandList}
        isEditing={isEditing}
        setIsEditing={props.setIsEditing}
        setHideCurrentPromoters={props.setHideCurrentPromoters}
        hideCurrentPromoters={props.hideCurrentPromoters}
        ensureLoggedInThenCall={props.ensureLoggedInThenCall}
        ensureValidPackageThenCall={props.ensureValidPackageThenCall}
        openBulkTalentModal={props.openBulkTalentModal}
        openRequestModal={props.openRequestModal}
      />
    </div>
  );
};

ListHeader.propTypes = {
  ui: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  sortBy: PropTypes.string,
  setSortBy: PropTypes.func.isRequired,
  sortDirection: PropTypes.string,
  setSortDirection: PropTypes.func.isRequired,
  ensureLoggedInThenCall: PropTypes.func.isRequired,
  ensureValidPackageThenCall: PropTypes.func.isRequired,
  syncList: PropTypes.func.isRequired,
  isBrandList: PropTypes.bool,
  setHideCurrentPromoters: PropTypes.func.isRequired,
  hideCurrentPromoters: PropTypes.bool.isRequired,
  openBulkTalentModal: PropTypes.func.isRequired,
  openRequestModal: PropTypes.func.isRequired
};

export default withRouter(ListHeader);
