import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import _ from 'lodash';
import './OpportunitySetupGuideSearch.scss';

import { fetchAlgoliaIndex } from '../../../Helpers/search_helpers';
import { getBrand } from '../../../Helpers/user_helpers';
import { getDomainFromUrl, getPrettyNumber } from '../../../Helpers/formatting';

import Loader from '../../Loader/Loader';
import InputActions from '../../General/InputActions';

const index = fetchAlgoliaIndex(!window.__IS_PROD__ ? 'merchants_staging' : 'merchants_production');
const OpportunitySetupGuideSearch = props => {
  const { user, results, setResults, performSearch, getDefaultResults } = props;
  const brand = getBrand(user);

  const [curSearchVal, setCurSearchVal] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);
  const searchDebounce = React.useRef(null);

  const changeSearch = newSearchVal => {
    setIsSearching(true);
    setCurSearchVal(newSearchVal);
    clearTimeout(searchDebounce.current);
    if (newSearchVal === '') {
      setIsSearching(false);
      return setSearchResults(defaultResults);
    }

    searchDebounce.current = setTimeout(() => {
      if (performSearch) {
        performSearch(newSearchVal).then(results => {
          setSearchResults(results);
          setIsSearching(false);
        });
      } else {
        index.search(newSearchVal).then(({ hits }) => {
          setSearchResults(hits.map(hit => ({ name: hit.name, domain: hit.domain, promoter_count: hit.promoter_count })));
          setIsSearching(false);
        });
      }
    }, 500);
  };

  // Get large retailers as default results
  const [defaultResults, setDefaultResults] = React.useState([]);
  React.useEffect(() => {
    if (getDefaultResults) {
      getDefaultResults().then(defaultResults => {
        setDefaultResults(defaultResults);
        !curSearchVal && setSearchResults(defaultResults);
      });
    } else {
      index.search('').then(({ hits }) => {
        const defaultResults = hits.map(hit => ({ name: hit.name, domain: hit.domain, promoter_count: hit.promoter_count }));
        setDefaultResults(defaultResults);
        !curSearchVal && setSearchResults(defaultResults);
      });
    }
  }, []);

  // Helpers
  const getResultIdentifier = result => result.id || result.domain || result.name;
  const getDisplayForResult = result => result.value || result.name || result.domain || `Option`;
  const getSubtitlesForResult = (result, idx) => {
    let subtitles = [];
    const { domain, promoter_count } = result;
    if (result.domain) subtitles.push(domain);
    if (result.promoter_count) subtitles.push(`${getPrettyNumber(promoter_count)}${idx < 3 ? ` promoter${promoter_count === 1 ? '' : 's'}` : ''}`);
    return subtitles;
  };

  // Filter out already active
  const isActive = resultOrRec => results.some(r => getResultIdentifier(r) === getResultIdentifier(resultOrRec));

  // Allow custom ones by searching domain
  const searchValDomain = _.filter(curSearchVal.split('.')).length > 1 && getDomainFromUrl(curSearchVal);
  const filteredSearchResults = _.uniqBy(
    [
      ...(searchValDomain
        ? searchResults.find(result => result.domain === searchValDomain)
          ? searchResults.filter(result => result.domain === searchValDomain)
          : [{ domain: searchValDomain }]
        : []),
      ...searchResults
    ].filter(result => !isActive(result)),
    getResultIdentifier
  );

  const pressEnter = e => {
    e.preventDefault();
    if (filteredSearchResults.length) {
      setResults([...results, filteredSearchResults[0]]);
      changeSearch('');
    }
  };

  return (
    <div className='opportunity-setup-guide-search'>
      <div className='results-section'>
        <div className='results-section-title'>{props.resultsLabel || 'Selected Targets'}:</div>
        {!results.length ? (
          <div className='empty-results'>
            <div className='main-message'>None Selected</div>
          </div>
        ) : (
          <div className='active-results result-pills'>
            {results.map((result, idx) => {
              const isYourBrand = result.domain === brand.domain || result.name === brand.domain;
              const display = getDisplayForResult(result);
              const remove = () => setResults(results.filter((_, i) => i !== idx));
              return (
                <div key={idx} onClick={remove} className='active-result active result-pill'>
                  <div className='content'>
                    <div className='title'>
                      {isYourBrand && <FontAwesomeIcon icon={faUsers} />}
                      {display}
                    </div>
                  </div>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className='results-section'>
        <div className='results-section-title'>{props.searchMoreResultsLabel || 'Add New Targets'}:</div>
        <div className='search-new-results'>
          <form onSubmit={pressEnter} className='search-input-container has-actions'>
            <input
              type='text'
              value={curSearchVal}
              onChange={e => changeSearch(e.target.value)}
              placeholder={props.searchPlaceholder || 'Search by Brand or Domain'}
            />
            <InputActions searchVal={curSearchVal} onCancel={() => changeSearch('')} />
          </form>
          <div className={cn('search-results result-pills', { searching: isSearching })}>
            {!filteredSearchResults.length && isSearching ? (
              <div className='is-searching-results'>
                <Loader size={48} />
              </div>
            ) : filteredSearchResults.length ? (
              filteredSearchResults.map((result, idx) => {
                const display = getDisplayForResult(result, idx);
                const subtitles = getSubtitlesForResult(result, idx);
                const add = () => setResults([...results, result]);
                return (
                  <div
                    key={idx}
                    onClick={add}
                    className={cn('search-result inactive result-pill', {
                      'select-on-press-enter': idx === 0 && curSearchVal
                    })}
                  >
                    <div className='content'>
                      <div className='title'>{display}</div>
                      <div className='subtitle'>{subtitles.join(' • ')}</div>
                    </div>
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                );
              })
            ) : searchResults.length ? (
              <div className='empty-results'>
                <div className='main-message'>No More Results</div>
              </div>
            ) : (
              !!curSearchVal && (
                <div className='empty-results'>
                  <div className='main-message'>No Results for Search Term "{curSearchVal}".</div>
                  {props.emptySearchResultSecondaryMessage && <div className='secondary-message'>{props.emptySearchResultSecondaryMessage}</div>}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

OpportunitySetupGuideSearch.propTypes = {
  results: PropTypes.array.isRequired,
  setResults: PropTypes.func.isRequired,

  // Optional
  performSearch: PropTypes.func,
  getDefaultResults: PropTypes.func,
  resultsLabel: PropTypes.string, // 'Selected Results'
  searchMoreResultsLabel: PropTypes.string, // 'Search More Results'
  searchPlaceholder: PropTypes.string, // 'Search by Keyword'
  emptySearchResultSecondaryMessage: PropTypes.string // 'Try searching for a different keyword.'
};

export default OpportunitySetupGuideSearch;
