import React, { useState } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ListSimilarLists.scss';

import { getGMTTime } from '../../Helpers/formatting';
import { scrollToTop } from '../../Helpers/helpers';
import {
  addDiscoverListSimilarList,
  getAllDiscoverLists,
  deleteDiscoverListSimilarList,
  updateDiscoverListSimilarList
} from '../../APIClient/discover';

import SelectOption from '../General/SelectOption';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const ListSimilarLists = props => {
  const { list, isEditing, syncList } = props;

  // Fetch all lists for the select dropdown
  const [allLists, setAllLists] = React.useState([]);
  const listOptions = allLists.map(list => ({ value: list.id, label: list.title }));
  React.useEffect(() => {
    isEditing && getAllDiscoverLists().then(lists => setAllLists(_.orderBy(lists, 'createdAt', 'desc').filter(l => l.title)));
  }, [isEditing]);

  // Reordering state
  const [tempLists, setTempLists] = useState(null);
  const similarLists = tempLists || list.similarLists;

  // Actions
  const addListFromSelect = newList => {
    addDiscoverListSimilarList({
      DiscoverList_id: list.id,
      SimilarList_id: newList.value
    }).then(syncList);
  };

  // Reordering functions
  const DragHandle = sortableHandle(() => (
    <div className='sortable-handle'>
      <FontAwesomeIcon icon={faBars} />
    </div>
  ));
  const SortableItem = SortableElement(({ value, props }) => (
    <SimilarList similarList={value} syncList={syncList} isEditing={isEditing} rearrangeHandle={<DragHandle />} {...props} />
  ));
  const SortableList = SortableContainer(({ items, props }) => {
    return (
      <div className='lists'>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} axis='xy' index={index} value={value} props={props} />
        ))}
      </div>
    );
  });
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;
    setTempLists(arrayMove(similarLists, oldIndex, newIndex));
    const movingForward = newIndex > oldIndex;
    const lowerBound = movingForward ? newIndex : newIndex - 1;
    const upperBound = movingForward ? newIndex + 1 : newIndex;
    const lowerBoundSort = similarLists[lowerBound]?.sortOrderRank || -1 * getGMTTime();
    const upperBoundSort = similarLists[upperBound]?.sortOrderRank || getGMTTime();
    const newSort = (lowerBoundSort + upperBoundSort) / 2;
    await updateDiscoverListSimilarList(similarLists[oldIndex], { sortOrderRank: newSort }).then(() =>
      syncList().then(() => setTimeout(() => setTempLists(null), 500))
    );
  };

  if (!isEditing && !similarLists.length) return null;

  return (
    <div className='list-similar-lists-container'>
      <div className='section-header-container'>
        <div className='section-header'>View Additional Lists</div>
        <div className='section-subheader'>Continue to discover talent for your program</div>
        {isEditing && (
          <div className='header-actions'>
            <Select
              value={null}
              onChange={addListFromSelect}
              className='select'
              classNamePrefix='select'
              options={listOptions}
              placeholder='Add Similar List'
              components={{ Option: SelectOption }}
            />
          </div>
        )}
      </div>
      {isEditing ? (
        <SortableList axis='xy' items={similarLists} onSortEnd={onSortEnd} />
      ) : (
        <div className='lists'>
          {similarLists.map((similarList, idx) => (
            <SimilarList key={similarList.id} similarList={similarList} syncList={syncList} isEditing={isEditing} {...props} />
          ))}
        </div>
      )}
    </div>
  );
};

const SimilarList = ({ similarList, syncList, isEditing, rearrangeHandle }) => {
  const deleteList = e => {
    e.preventDefault();
    e.stopPropagation();
    deleteDiscoverListSimilarList(similarList).then(syncList);
  };
  return (
    <Link onClick={scrollToTop} to={`/discover/${similarList.id}`} className='list'>
      {rearrangeHandle}
      <div className='title'>{similarList.title}</div>
      {isEditing && (
        <div className='delete-list'>
          <FontAwesomeIcon icon={faTimes} onClick={deleteList} />
        </div>
      )}
    </Link>
  );
};

ListSimilarLists.propTypes = {
  user: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  syncList: PropTypes.func.isRequired
};

export default ListSimilarLists;
