import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import icons from '../../../static/icons';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './HomeHeaderStep.scss';

import { openAuthModal } from '../../../Actions/UIActions';

const HomeHeaderStep = props => {
  const location = useLocation();
  const STEPS = props.steps.map(step => ({
    ...step,
    isActive: location.pathname.includes(step.to)
  }));
  const ACTIVE_STEP = STEPS.find(step => step.isActive);
  const STEP_INDEX = STEPS.indexOf(ACTIVE_STEP);
  const NEXT_STEP = STEPS[STEP_INDEX + 1];
  const PREV_STEP = STEPS[STEP_INDEX - 1];

  const additionalClasses = { 'white-background': props.isOnWhite, 'header-longform': props.headerLongForm };

  const seo_title = `ShopMy | ${ACTIVE_STEP?.header_longform || ACTIVE_STEP?.header}`;
  const seo_description = ACTIVE_STEP?.subheader;
  const seo_image = ACTIVE_STEP?.hero_image;
  return (
    <div className={cn('home-header-step-outer-container', additionalClasses)}>
      <MetaTags>
        <title>{seo_title}</title>
        <meta property='og:title' content={seo_title} />
        <meta property='description' content={seo_description} />
        <meta property='og:description' content={seo_description} />
        <meta property='og:image' content={seo_image} />
      </MetaTags>
      <div className='home-header-step-inner-container'>
        <div className='main-content'>
          <div className='step-numbers'>
            {STEPS.map((step, idx) => {
              return (
                <Link to={step.to} className={cn('step-number', { active: step.isActive, ...additionalClasses })} key={idx}>
                  <div className='number'>{idx + 1}</div>
                </Link>
              );
            })}
          </div>
          {ACTIVE_STEP && (
            <div className={cn('header-container', { ...additionalClasses })}>
              <h1 className='header'>{props.headerLongForm ? ACTIVE_STEP.header_longform : ACTIVE_STEP.header}</h1>
              <h2 className='subheader'>{ACTIVE_STEP.subheader}</h2>
            </div>
          )}

          <div className={cn('step-arrows', additionalClasses)}>
            <div className={cn('step-arrow left')}>
              {PREV_STEP && (
                <Link to={PREV_STEP.to} className={cn('link left', additionalClasses)}>
                  <img src={icons.misc.arrowRight} alt='Arrow Left' />
                  <div className='text'>{PREV_STEP.header}</div>
                </Link>
              )}
            </div>
            <div className={cn('step-arrow right')}>
              {NEXT_STEP && (
                <Link to={NEXT_STEP.to} className={cn('link right', additionalClasses)}>
                  <div className='text'>{NEXT_STEP.header}</div>
                  <img src={icons.misc.arrowRight} alt='Arrow Right' />
                </Link>
              )}
            </div>
          </div>
        </div>
        {ACTIVE_STEP && (
          <div className='hero-container'>
            <div className='hero-image-container'>
              <img alt={ACTIVE_STEP.header} className='hero-image' src={ACTIVE_STEP.hero_image} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

HomeHeaderStep.propTypes = {
  // Inside
  user: PropTypes.object.isRequired,
  openAuthModal: PropTypes.func.isRequired,

  // Outside
  steps: PropTypes.array.isRequired,

  // Outside UI (optional)
  isOnWhite: PropTypes.bool,
  headerLongForm: PropTypes.bool
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

export default connect(mapStateToProps, {
  openAuthModal
})(HomeHeaderStep);
