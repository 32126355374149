import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import './HeaderLogo.scss';

import shopMyLogoWhite from '../../static/images/logos/shop_my_white.png';

import { isHomePage, isPromotePage } from '../../Helpers/helpers';
import { getBrandTheme } from '../../Helpers/ui_helpers';

const HeaderLogo = props => {
  const { clickLogo, additionalClasses, ui } = props;
  const isPromote = isPromotePage();
  const isHome = isHomePage();
  const brandTheme = getBrandTheme(ui);

  // Decide whether we are showing a brand logo
  let showBrandLogo;
  if (isHome || !brandTheme) showBrandLogo = false;
  else if (!brandTheme.logo.includes('.png')) showBrandLogo = false;
  else if (isPromote && brandTheme?.allow_theming) showBrandLogo = true;
  else if (brandTheme?.allow_advanced_theming) showBrandLogo = true;
  else showBrandLogo = false;

  return (
    <div onClick={clickLogo} className={cn('header-logo-outer-container shrink-on-scroll', additionalClasses)}>
      {showBrandLogo ? (
        <img className='brand-theme-logo' src={brandTheme.logo} alt={`${brandTheme?.name} Logo`} />
      ) : (
        <img className='logo-img' alt='ShopMy Logo' src={shopMyLogoWhite} />
      )}
    </div>
  );
};

HeaderLogo.propTypes = {
  ui: PropTypes.object.isRequired,
  additionalClasses: PropTypes.object.isRequired,
  setShopMyLabel: PropTypes.func.isRequired,
  clickLogo: PropTypes.func.isRequired,
  isScrolled: PropTypes.bool.isRequired
};

export default HeaderLogo;
