import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import './BlogPostModal.scss';

import { createBlogPost, editBlogPost, deleteBlogPost, addBlogPostToTab, removeBlogPostFromTab } from '../../Actions/BlogActions';

import { matchScrollHeight } from '../../Helpers/helpers';
import { confirmAlert } from 'react-confirm-alert';

import Modal from '../General/Modal';
import Image from '../General/Image';
import Tooltip from '../General/Tooltip';
import ImageUploader from '../General/ImageUploader';
import { getUserId } from '../../Helpers/user_helpers';

const BlogPostModal = props => {
  const { blog, user, createBlogPost, editBlogPost, close, isCreating, addBlogPostToTab, removeBlogPostFromTab } = props;
  const { tabs } = blog;
  const history = useHistory();
  const blogPost = isCreating ? {} : blog.blogPost || {};
  const { isHidden, publishedAt } = blogPost;

  const [title, setTitle] = useState(blogPost.title || '');
  const [titleStub, setTitleStub] = useState(blogPost.titleStub || '');
  const [subtitle, setSubtitle] = useState(blogPost.subtitle || '');
  const [tag, setTag] = useState(blogPost.tag || '');
  const [isSaving, setIsSaving] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [coverImage, setCoverImage] = useState(blogPost.coverImage || '');

  const validateBeforeSaving = () => {
    if (!title) window.ALERT.error('Please enter a title.');
    else if (!titleStub) window.ALERT.error('Please enter a url stub.');
    else if (/\s/.test(titleStub)) window.ALERT.error('Please remove all spaces from the url stub.');
    else if (!coverImage) window.ALERT.error('Please upload a cover image.');
    else return true;

    return false;
  };

  const createPost = async () => {
    if (!validateBeforeSaving()) return;

    const post = _.omitBy({ title, titleStub, subtitle, tag, coverImage, User_id: getUserId(user) }, _.isNil);
    const response = await createBlogPost(post);
    if (response.error) window.ALERT.error('Error creating post');
    else {
      history.push(`/blog/post/${titleStub}`);
      close();
    }
  };

  const editPost = async () => {
    if (!validateBeforeSaving()) return;
    setIsSaving(true);

    const updates = _.omitBy({ title, titleStub, subtitle, tag, coverImage, User_id: props.user.id }, _.isNil);
    const response = await editBlogPost(blogPost, updates);
    if (response.error) window.ALERT.error('Error updating post');
    else close();
  };

  const updatePostVisibility = async () => {
    if (isHidden) {
      return confirmAlert({
        title: 'Publish Post?',
        message: `Are you sure you want to publish this post? By doing so, all parts of this post will be immediately viewable to the public.`,
        buttons: [
          { label: 'No', className: 'cancel', onClick: () => {} },
          { label: 'Yes', onClick: () => editBlogPost(blogPost, { isHidden: false, publishedAt: moment().format('YYYY-MM-DD') }) }
        ]
      });
    } else {
      return editBlogPost(blogPost, { isHidden: true });
    }
  };

  const addOrDeleteFromTab = async tab => {
    if (!tab?.id) return window.ALERT.error('Invalid tab, cannot add to tab');
    else if (!blogPost?.id) return window.ALERT.error('Invalid post, cannot add to tab');

    const shouldAdd = !blogPost.tabs?.find(t => t.id === tab.id);
    const shouldRemove = blogPost.tabs?.find(t => t.id === tab.id);

    if (shouldAdd) {
      const response = await addBlogPostToTab(tab, blogPost);
      if (response.error) window.ALERT.error(`Error adding post to ${tab.title}.`);
      else window.ALERT.success(`Successfully added post to ${tab.title}.`);
    } else if (shouldRemove) {
      const response = removeBlogPostFromTab(tab, blogPost);
      if (response.error) window.ALERT.error(`Error removing post from ${tab.title}.`);
      else window.ALERT.success(`Successfully removed post from ${tab.title}.`);
    }
  };

  return (
    <Modal visible={true} innerClassName='blog-post-modal-outer' showClose={true} close={props.close}>
      <div className='blog-post-modal-inner'>
        <div className='blog-post-modal-title'>Edit Post Details</div>
        <div className='blog-post-container'>
          <ImageUploader
            initialImageUrl={coverImage}
            onSaveCallback={imageUrl => setCoverImage(imageUrl)}
            isVisible={showImageUploader}
            setIsVisible={setShowImageUploader}
          />
          <div className='blog-cover-image-container' onClick={() => setShowImageUploader(true)}>
            <Image src={coverImage} alt='Blog Cover Image' className={'blog-cover-image'} useGenericFailedStyles />
            <div className='visibility-disclaimer-container'>
              {isCreating ? (
                <span>WARNING: Whichever user you are universally logged in as will become the author of the blog post.</span>
              ) : isHidden ? (
                <span>This post is not publically viewable.</span>
              ) : (
                <span>This post is publically viewable, any updates will be reflected immediately.</span>
              )}
            </div>
          </div>
          <div className='blog-post-info'>
            <div className='product-section-container'>
              <div>
                <div className='product-section-header'>Blog Title</div>
                <textarea
                  id='title'
                  defaultValue={title}
                  onChange={e => setTitle(e.target.value)}
                  placeholder='Title here'
                  rows={1}
                  ref={matchScrollHeight}
                />
              </div>
            </div>

            <div className='product-section-container'>
              <div>
                <div className='product-section-header'>Subtitle</div>
                <textarea
                  id='description'
                  defaultValue={subtitle}
                  onChange={e => setSubtitle(e.target.value)}
                  placeholder='Desription here (optional)'
                  rows={1}
                  ref={matchScrollHeight}
                />
              </div>
            </div>

            <div className='product-section-container'>
              <div>
                <div className='product-section-header'>Tag</div>
                <input id='tag' type='text' defaultValue={tag} onChange={e => setTag(e.target.value)} placeholder='Get The Look' />
              </div>
            </div>

            <div className='product-section-container'>
              <div>
                <div className='product-section-header'>Url Stub</div>
                <textarea
                  id='url'
                  value={titleStub}
                  onChange={e => {
                    let titleStub = e.target.value;
                    titleStub = titleStub.replace(/\s/g, '-');
                    titleStub = titleStub.toLowerCase();
                    setTitleStub(titleStub);
                  }}
                  placeholder='q-and-a-with-your-favorite-artist'
                  rows={1}
                  ref={matchScrollHeight}
                />
              </div>
            </div>

            {!isCreating && (
              <div className='product-section-container'>
                <div>
                  <div className='product-section-header'>Tabs</div>
                  <div className='select-tabs-container'>
                    {tabs.map(tab => {
                      const { id, title, isHidden } = tab;
                      const isSelected = blogPost.tabs?.find(t => t.id === id);

                      return (
                        <>
                          {isHidden ? (
                            <Tooltip key={id} message='This tab is hidden'>
                              <div className={cn('tab', { isSelected, isHidden })} onClick={() => addOrDeleteFromTab(tab)}>
                                {title}
                              </div>
                            </Tooltip>
                          ) : (
                            <div key={id} className={cn('tab', { isSelected, isHidden })} onClick={() => addOrDeleteFromTab(tab)}>
                              {title}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='blog-post-buttons'>
          <button className={cn('hide-post', { isSaving })} onClick={updatePostVisibility}>
            {isHidden ? (publishedAt ? 'Re-Publish Post' : 'Publish Post') : 'Hide Post'}
          </button>
          {isCreating ? (
            <button className={cn('create', { isSaving })} onClick={createPost}>
              Create Post
            </button>
          ) : (
            <button className={cn('create', { isSaving })} onClick={editPost}>
              Update Post
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

BlogPostModal.propTypes = {
  close: PropTypes.func.isRequired,
  isCreating: PropTypes.bool,

  blog: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  createBlogPost: PropTypes.func.isRequired,
  editBlogPost: PropTypes.func.isRequired,
  deleteBlogPost: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { blog, user } = state;
  return { blog, user };
};

export default connect(mapStateToProps, {
  createBlogPost,
  editBlogPost,
  deleteBlogPost,
  addBlogPostToTab,
  removeBlogPostFromTab
})(BlogPostModal);
