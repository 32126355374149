import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';

import './Apply.scss';
import './Basic.scss';

class Apply extends Component {
  render() {
    return (
      <div className='about-outer-container basic-outer-container'>
        <MetaTags>
          <title>Apply | ShopMy</title>
          <meta property='description' content='To apply to host a shop on the site, please contact shops@shopmy.us' />
        </MetaTags>
        <div className='basic-header-container narrow'>
          <div className='basic-header-title-badge-container'>
            <h1 className='basic-header-title-badge'>APPLY</h1>
          </div>
          <h2 className='basic-header-body simple'>
            To apply to host a shop on the site, please contact <span className='email'>shops@shopmy.us</span>
          </h2>
        </div>
      </div>
    );
  }
}

export default Apply;
