// Force Reload 2
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Elements } from '@stripe/react-stripe-js';
import './App.css';

import { stripePromise } from './Helpers/payment_helpers';

// All Views
import Chat from './Pages/Chat/Chat';
import Collection from './Pages/Collection/Collection';
import Home from './Pages/Home/Home';
import MainContainer from './Containers/MainContainer/MainContainer';
import Result from './Pages/Result/Result';
import Contract from './Pages/Contract/Contract';
import Collaborations from './Pages/Collaborations/Collaborations';
import Consult from './Pages/Consult/Consult';
import Talent from './Pages/Talent/Talent';
import Blog from './Pages/Blog/Blog';
import Discover from './Pages/Discover/Discover';
import DiscoverOld from './Pages/Discover/DiscoverOld';
import List from './Pages/Lists/List';
import Guide from './Pages/Guide/Guide';
import SnapshopExtension from './Pages/Extensions/Snapshop';
import InstagramExtension from './Pages/Extensions/InstagramExtension';
import PartnerPortal from './Pages/Partners/PartnerPortal';
import Profile from './Pages/Profile/Profile';
import Links from './Pages/Links/Links';
import Latest from './Pages/Latest/Latest';
import Settings from './Pages/Settings/Settings';
import About from './Pages/Basic/About/About';
import Apply from './Pages/Basic/Apply';
import Careers from './Pages/Basic/Careers';
import Contact from './Pages/Basic/Contact';
import BrandPartners from './Pages/Basic/BrandPartners';
import Directory from './Pages/Basic/Directory';
import Press from './Pages/Basic/Press';
import Terms from './Pages/Basic/Terms';
import FAQ from './Pages/Basic/FAQ';
import BrandFAQ from './Pages/Basic/BrandFAQ';
import Shop from './Pages/Shop/Shop';
import Product from './Pages/Shop/Product';
import Report from './Pages/Report/Report';
import Snapshop from './Pages/Explainers/Snapshop';
import Subscriptions from './Pages/Subscriptions/Subscriptions';
import AllProducts from './Pages/Shop/AllProducts';
import CustomLogin from './Pages/Landing/CustomLogin';
import LandingLogin from './Pages/Landing/LandingLogin';
import OAuthLanding from './Components/OAuth/OAuthLanding';
import BrandApply from './Pages/Landing/BrandApply';
import MobileLanding from './Pages/Landing/MobileLanding';
import CollectionEmbed from './Pages/Embed/CollectionEmbed';
import SocialAuth from './Components/Redirects/SocialAuth';
import Logout from './Components/Redirects/Logout';
import Commissions from './Pages/Commissions/Commissions';
import Requests from './Pages/Requests/Requests';
import RequestsPortal from './Pages/Requests/RequestsPortal';
import Codes from './Pages/Codes/Codes';
import Mentions from './Pages/Mentions/Mentions';
import Lookbooks from './Pages/Lookbooks/Lookbooks';
import Opportunity from './Pages/Opportunity/Opportunity';
import LookbookOrderDetails from './Pages/Lookbooks/LookbookOrderDetails';
import RootRedirect from './Pages/Redirects/RootRedirect';
import NYFW from './Pages/Landing/NYFW';
import EarningsPortal from './Components/Payouts/EarningsPortal';
import BrandSetupRedirect from './Pages/Landing/BrandSetupRedirect';

class App extends Component {
  render() {
    const PublicRouteRender = ({ component: Component, componentProps = {}, theme = 'light', ...rest }) => (
      <Route
        {...rest}
        render={props => (
          <MainContainer theme={theme}>
            <Component {...props} {...componentProps} />
          </MainContainer>
        )}
      />
    );

    const StripeWrapper = props => {
      return <Elements stripe={stripePromise}>{props.children}</Elements>;
    };

    return window.__IS_PRO__ ? (
      <StripeWrapper>
        <Router>
          <LastLocationProvider>
            <Switch>
              <Route path='/' exact render={() => <Redirect to={{ pathname: '/welcome' }} />} />
              <PublicRouteRender exact path='/chat' component={Chat} />
              <PublicRouteRender exact path='/faq' component={FAQ} />
              <PublicRouteRender exact path='/welcome' component={LandingLogin} />
              <PublicRouteRender exact path='/shop-guide' component={Guide} />
              <PublicRouteRender exact path='/shop-guide/:howto' component={Guide} />
              <PublicRouteRender exact path='/links/:tab?' component={Links} />
              <PublicRouteRender exact path='/partners/:tab?' component={PartnerPortal} />
              <PublicRouteRender exact path='/settings' component={Settings} />
              <PublicRouteRender exact path='/allproducts' component={AllProducts} />
              <PublicRouteRender exact path='/invite/:username' component={CustomLogin} componentProps={{ isReferralPage: true }} />
              <PublicRouteRender exact path='/join/:username' component={CustomLogin} componentProps={{ isReferralPage: true }} />
              <PublicRouteRender exact path='/promote/:username' component={CustomLogin} />
              <PublicRouteRender exact path='/payouts' component={EarningsPortal} />
              <PublicRouteRender exact path='/latest' component={Latest} />
              <PublicRouteRender exact path='/collections/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/public/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/quick/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/embed/:id' component={CollectionEmbed} />
              <PublicRouteRender exact path='/collaborations/:tab?' component={Collaborations} />
              <PublicRouteRender exact path='/collaboration/:id/:type?' component={Contract} />
              <PublicRouteRender exact path='/consults/results/:stub' component={Result} />
              <PublicRouteRender exact path='/consults/:id' component={Consult} />
              <PublicRouteRender exact path='/consults/:id/:stub' component={Consult} />
              <PublicRouteRender exact path='/public/consults/results/:stub' component={Result} />
              <PublicRouteRender exact path='/public/consults/:id' component={Consult} />
              <PublicRouteRender exact path='/public/consults/:id/:stub' component={Consult} />
              <PublicRouteRender exact path='/shops/public/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/shops/public/:username' component={Profile} />
              <PublicRouteRender exact path='/social_auth/:type' component={SocialAuth} />
              <PublicRouteRender exact path='/team' component={About} />
              <PublicRouteRender exact path='/shop/:urlStub?' component={Shop} />
              <PublicRouteRender exact path='/blog' component={Blog} />
              <PublicRouteRender exact path='/blog/:tab_title' component={Blog} />
              <PublicRouteRender exact path='/blog/:tab_title/:post_url_stub' component={Blog} />
              <PublicRouteRender path='/careers' component={Careers} />
              <PublicRouteRender path='/contact' component={Contact} />
              <PublicRouteRender exact path='/home/creators/:step?' component={Home} componentProps={{ isCreatorLandingPage: true }} />
              <PublicRouteRender exact path='/home/brands/:step?' component={Home} componentProps={{ isBrandsLandingPage: true }} />
              <PublicRouteRender exact path='/nyfw' component={NYFW} />

              {/* For Clean Social URLs */}
              <PublicRouteRender exact path='/public/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/public/:username' component={Profile} />
              <PublicRouteRender exact path='/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/:username' component={Profile} />
            </Switch>
          </LastLocationProvider>
        </Router>
      </StripeWrapper>
    ) : (
      <StripeWrapper>
        <Router>
          <LastLocationProvider>
            <Switch>
              <PublicRouteRender exact path='/' component={RootRedirect} />
              <PublicRouteRender exact path='/home' component={Home} />
              <PublicRouteRender exact path='/home/creators/:step?' component={Home} componentProps={{ isCreatorLandingPage: true }} />
              <PublicRouteRender exact path='/home/brands/:step?' component={Home} componentProps={{ isBrandsLandingPage: true }} />
              <PublicRouteRender exact path='/nyfw' component={NYFW} />
              <PublicRouteRender exact path='/mobile/redirect' component={MobileLanding} />
              <PublicRouteRender exact path='/chat' component={Chat} />
              <PublicRouteRender exact path='/shop/:urlStub?' component={Shop} />
              <PublicRouteRender exact path='/logout' component={Logout} />
              <PublicRouteRender exact path='/welcome' component={LandingLogin} />
              <PublicRouteRender exact path='/oauth' component={OAuthLanding} theme='dark' />
              <PublicRouteRender exact path='/invite/:username' component={CustomLogin} componentProps={{ isReferralPage: true }} />
              <PublicRouteRender exact path='/join/:username' component={CustomLogin} componentProps={{ isReferralPage: true }} />
              <PublicRouteRender exact path='/promote/:username' component={CustomLogin} />
              <PublicRouteRender exact path='/shops/public/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/shops/public/:username' component={Profile} />
              <PublicRouteRender exact path='/shops/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/shops/:username' component={Profile} />
              <PublicRouteRender exact path='/products/:stub' component={Product} />
              <PublicRouteRender exact path='/s/:username' component={Profile} />
              <PublicRouteRender exact path='/s/public/:username' component={Profile} />
              <PublicRouteRender exact path='/shop-guide' component={Guide} />
              <PublicRouteRender exact path='/shop-guide/:howto' component={Guide} />
              <PublicRouteRender exact path='/links/:tab?' component={Links} />
              <PublicRouteRender exact path='/allproducts' component={AllProducts} />
              <PublicRouteRender exact path='/discover' component={Discover} />
              <PublicRouteRender exact path='/discover/:id' component={List} />
              <PublicRouteRender exact path='/new/discover' component={Discover} />
              {/* Since we used this for testing, keep these - remove after October 2023 */}
              <PublicRouteRender exact path='/new/discover/:id' component={List} />
              {/* Since we used this for testing, keep these - remove after October 2023 */}
              <PublicRouteRender exact path='/list/:id' component={List} componentProps={{ isBrandList: true }} />
              <PublicRouteRender exact path='/v1/discover' component={DiscoverOld} />
              <PublicRouteRender exact path='/payouts' component={EarningsPortal} />
              <PublicRouteRender exact path='/settings' component={Settings} />
              <PublicRouteRender exact path='/mentions' component={Mentions} />
              <PublicRouteRender exact path='/report' component={Report} />
              <PublicRouteRender exact path='/commissions' component={Commissions} />
              <PublicRouteRender exact path='/partners/:tab?' component={PartnerPortal} />
              <PublicRouteRender exact path='/v0/gifting' component={Requests} />
              <PublicRouteRender exact path='/gifting/:tab?' component={RequestsPortal} /> {/* Deprecated for /requests, remove after June 2024 */}
              <PublicRouteRender exact path='/requests/:tab?' component={RequestsPortal} /> {/* Deprecated for below, remove after June 2024 */}
              <PublicRouteRender exact path='/requests' component={RequestsPortal} componentProps={{ tab: 'requests' }} />
              <PublicRouteRender exact path='/lookbooks' component={RequestsPortal} componentProps={{ tab: 'lookbooks' }} />
              <PublicRouteRender exact path='/opportunities' component={RequestsPortal} componentProps={{ tab: 'opportunities' }} />
              <PublicRouteRender exact path='/recommendations' component={RequestsPortal} componentProps={{ tab: 'recommendations' }} />
              <PublicRouteRender exact path='/opportunity/:id/:tab?' component={Opportunity} />
              <PublicRouteRender exact path='/lookbooks/:id' component={Lookbooks} />
              <PublicRouteRender exact path='/lookbooks/:id/analytics' component={Lookbooks} />
              <PublicRouteRender exact path='/lookbooks/order/:LookbookOrder_id' component={LookbookOrderDetails} />
              <PublicRouteRender exact path='/codes' component={Codes} />
              <PublicRouteRender exact path='/collections/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/public/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/quick/:id' component={Collection} />
              <PublicRouteRender exact path='/collections/embed/:id' component={CollectionEmbed} />
              <PublicRouteRender exact path='/collaboration/:id/:type?' component={Contract} />
              <PublicRouteRender exact path='/collaborations/:tab?' component={Collaborations} />
              <PublicRouteRender exact path='/consults/results/:stub' component={Result} />
              <PublicRouteRender exact path='/consults/:id' component={Consult} />
              <PublicRouteRender exact path='/consults/:id/:stub' component={Consult} />
              <PublicRouteRender exact path='/public/consults/results/:stub' component={Result} />
              <PublicRouteRender exact path='/public/consults/:id' component={Consult} />
              <PublicRouteRender exact path='/public/consults/:id/:stub' component={Consult} />
              <PublicRouteRender path='/latest' component={Latest} />
              <PublicRouteRender path='/dashboard' component={Talent} />
              <PublicRouteRender path='/talent' component={Talent} />
              <PublicRouteRender exact path='/subscriptions/:tab?' component={Subscriptions} />
              <PublicRouteRender exact path='/blog' component={Blog} />
              <PublicRouteRender exact path='/blog/:tab_title' component={Blog} />
              <PublicRouteRender exact path='/blog/:tab_title/:post_url_stub' component={Blog} />
              <PublicRouteRender path='/about' component={About} />
              <PublicRouteRender path='/team' component={About} />
              <PublicRouteRender path='/apply' component={Apply} />
              <PublicRouteRender path='/careers' component={Careers} />
              <PublicRouteRender path='/contact' component={Contact} />
              <PublicRouteRender path='/shops' component={Directory} />
              <PublicRouteRender path='/directory' component={Directory} />
              <PublicRouteRender path='/press' component={Press} />
              <PublicRouteRender path='/terms' component={Terms} />
              <PublicRouteRender path='/snapshop' component={Snapshop} />
              <PublicRouteRender path='/forbrands' component={BrandApply} />
              <PublicRouteRender path='/brand-faq' component={BrandFAQ} />
              <PublicRouteRender path='/brand-partners' component={BrandPartners} />
              <PublicRouteRender exact path='/shopify-integration' component={BrandSetupRedirect} />
              <PublicRouteRender exact path='/brand-setup' component={BrandSetupRedirect} />
              <PublicRouteRender exact path='/social_auth/:type' component={SocialAuth} />
              {/* extensions */}
              <PublicRouteRender path='/quick-links-extension' component={SnapshopExtension} />
              <PublicRouteRender path='/instagram-extension' component={InstagramExtension} />
              {/* For Clean Social URLs */}
              <PublicRouteRender exact path='/c/:id' component={Collection} />
              <PublicRouteRender exact path='/public/:username/:tab' component={Profile} />
              <PublicRouteRender exact path='/public/:username' component={Profile} />
              <PublicRouteRender exact path='/:username/:tab?' component={Profile} />
            </Switch>
          </LastLocationProvider>
        </Router>
      </StripeWrapper>
    );
  }
}

export default App;
